import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import BottomBlock from "../../components/BottomActionsBlock/BottomBlock";
import NorelaButton from "../../components/Buttons/NorelaButton";
import { NorelaQuoteIcon } from "../../components/Icons/NorelaQuoteIcon";
import { PencilIcon } from "../../components/Icons/PencilIcon";
import { ValidationIcon } from "../../components/Icons/ValidationIcon";
import { WhitePlusIcon } from "../../components/Icons/WhitePlusIcon";
import ContentLayout from "../../components/Layout/ContentLayout";
import MailTo from "../../components/MailTo";
import { useCustomerContext } from "../../context/CustomerContext";
import { useSegment } from "../../context/SegmentContext";
import { useSessionContext } from "../../context/SessionContext";
import { useUserContext } from "../../context/UserContext";
import { useResetOrder } from "../../hooks/useResetOrder";
import { PageURL } from "../../types/pages";
import { colors } from "../../ui/theme";

function ValidationContent() {
  const { userInfos } = useUserContext();
  const { customerInfo } = useCustomerContext();
  const navigate = useNavigate();
  const { sessionInfo, updateHistory } = useSessionContext();
  const { track } = useSegment();

  const updateOrder = () => {
    track("Modify order after quote", {
      page: sessionInfo.history[sessionInfo.history.length - 3],
    });
    if (sessionInfo.history.length >= 2) {
      const previousPage = sessionInfo.history[sessionInfo.history.length - 3];
      navigate(previousPage);
      updateHistory(previousPage);
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const ref = useRef(null);
  const { partialResetOrder } = useResetOrder();
  function onResetOrder() {
    track("Reset on refresh");
    partialResetOrder();
    updateHistory(PageURL.HOME);
    navigate(PageURL.HOME);
    onClose();
  }
  return (
    <ContentLayout>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={ref}
        onClose={() => {}}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            Démarrer une nouvelle commande va réinitialiser la commande actuelle
            et votre devis ne pourra être modifié qu’auprès de nos services.
          </AlertDialogHeader>

          <AlertDialogFooter gap="1rem">
            <Button color="red" backgroundColor="white" onClick={onResetOrder}>
              Continuer
            </Button>
            <NorelaButton onClick={onClose}>Annuler</NorelaButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap="1rem"
        pb="150px"
      >
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap="2rem"
        >
          {userInfos?.quoteNumber ? (
            <Flex my="4rem">
              <Box
                sx={{
                  "@media(max-width: 1600px)": {
                    svg: {
                      width: "400px",
                      height: "200px",
                    },
                  },
                }}
              >
                {userInfos?.quoteNumber ? (
                  <ValidationIcon width="418px" height="254px" />
                ) : (
                  <ValidationIcon />
                )}
              </Box>
              <Text
                margin="5rem 0 3rem"
                className="Text1"
                fontWeight="700"
                fontSize={{ md: "21px", "2xl": "26px" }}
                lineHeight="30px"
                display="block"
                maxWidth="900px"
              >
                <span style={{ color: colors.secondaryFontColor.lightMode }}>
                  F
                </span>
                élicitations, votre devis vous a été transmis par mail à
                l'adresse : <br /> {customerInfo?.email} {" ! "}
                <br />
              </Text>
            </Flex>
          ) : (
            <> </>
          )}

          <Text
            margin="0"
            className="Text1"
            fontWeight="700"
            fontSize={{ md: "17px", "2xl": "24px" }}
            lineHeight="30px"
            display="block"
          >
            {userInfos?.quoteNumber ? (
              <Flex flexDir="row" w="1200px">
                <NorelaQuoteIcon width="576px" height="383px" />
                <Box
                  mt="1rem"
                  paddingLeft="5rem"
                  flexDirection="column"
                  display="flex"
                  alignContent="left"
                  alignItems="left"
                  textAlign="left"
                  minW="200px"
                >
                  <span>
                    Pour{" "}
                    <span
                      style={{ color: colors.secondaryFontColor.lightMode }}
                    >
                      valider la commande
                    </span>{" "}
                    , signez le devis et réglez l'acompte de votre commande.
                  </span>
                  <br />
                  Une fois validée, vous recevrez un mail de confirmation et nos
                  services prendront contact avec vous afin de poursuivre votre
                  événement avec vous.
                  <br />
                  <br />
                  Vous pouvez modifier votre commande, ou en effectuer une
                  nouvelle. Votre nouvelle commande n'annulera pas votre devis
                  actuel.
                  <br />
                  <br />
                  <Box w="530px">
                    <NorelaButton onClick={updateOrder} w="260px" mr="5px">
                      <PencilIcon color="white" mr="0.5rem" />
                      <Text color="white">MODIFIER MA COMMANDE</Text>
                    </NorelaButton>
                    <NorelaButton onClick={onOpen} w="260px" ml="5px">
                      <WhitePlusIcon mr="0.5rem" />
                      <Text color="white">NOUVELLE COMMANDE</Text>
                    </NorelaButton>
                  </Box>
                </Box>
              </Flex>
            ) : (
              <>
                Votre commande est validée, votre numéro de validation de
                paiement est : <br /> {userInfos?.paymentIntentId} <br />
                Vous aller recevoir un mail de confirmation avec toutes les
                informations nécessaires pour poursuivre votre événement avec
                nous
              </>
            )}
          </Text>
          {userInfos?.quoteNumber ? (
            <></>
          ) : (
            <>
              <Text
                margin="0"
                className="Text2"
                display="block"
                maxWidth="900px"
                fontWeight="700"
                fontSize={{ md: "17px", "2xl": "24px" }}
                lineHeight="30px"
              >
                Nos services reprendront contact avec vous dans les plus brefs
                délais.
              </Text>
              <Text
                display="block"
                maxWidth="900px"
                margin="0"
                className="Text3"
                fontWeight="700"
                fontSize={{ md: "17px", "2xl": "24px" }}
                lineHeight="30px"
              >
                Pour toute information ou requête supplémentaire (le logo sur la
                tenue imprimée, vos horaires pour les formations, etc.), envoyez
                nous un mail sur l'adresse suivante :
                <br />
                <MailTo
                  email="contact@e-norela.com"
                  subject="Informations supplémentaire pour l'événement ..." // TODO: add order number
                  body="Bonjour,\n\nVoici les informations supplémentaires sur ma commande numéro ..." // TODO: add order number & some details
                  color="#FFb80b"
                >
                  contact@e-norela.com
                </MailTo>
              </Text>
            </>
          )}
        </Flex>
      </Flex>

      <BottomBlock
        showQuitPage
        hideNeedHelp
        nextPageText="RETOUR À L'ACCUEIL"
        onNextPage={() => {
          partialResetOrder();
          updateHistory(PageURL.HOME);
          navigate(PageURL.HOME);
        }}
      />
    </ContentLayout>
  );
}

export default ValidationContent;
