import { useNavigate } from "react-router-dom";
import BottomBlock from "../../components/BottomActionsBlock/BottomBlock";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import ContentLayout from "../../components/Layout/ContentLayout";
import GrayLayout from "../../components/Layout/GrayLayout";
import MainContainer from "../../components/Layout/MainContainer";
import HoverCard, {
  HoverCardElement,
} from "../../components/Multisteps/Simple/components/HoverCard";
import { Icons } from "../../components/PicturesVariant/PicturesVariant";
import { useOnboardingContext } from "../../context/OnboardingContext";
import { EventCategory, EventType } from "../../modules/Onboarding/EventTypes";
import { PageURL } from "../../types/pages";
import { useSessionContext } from "../../context/SessionContext";

const SERVICE_ACCUEIL_ELEMENTS: HoverCardElement<EventCategory>[] = [
  {
    title: "Professionnel (btob)",
    tooltipText: "Salon, Convention, Congrès, Séminaire...",
    value: EventCategory.PROFESSIONNEL,
  },
  {
    title: "Grand Public",
    tooltipText:
      "Inauguration, Village événementiel, Événement sportif, Salon.",
    value: EventCategory.GRAND_PUBLIC,
  },
];

const ANIMATION_ELEMENTS: HoverCardElement<EventCategory>[] = [
  {
    title: "Street Marketing",
    value: EventCategory.STREET_MARKETING,
    tooltipText:
      "Allez au contact de votre cible. Distribuez, échantillonez et faites connaître votre marque ou produit.",
  },
  {
    title: "Animation magasin",
    value: EventCategory.ANIMATION_PROMOTIONNELLE,
    tooltipText:
      "Animation en magasin, test et démonstration produit auprès d'une clientèle captive.",
  },
  {
    title: "Animation Evénementielle",
    value: EventCategory.ANIMATION_EVENT,
    tooltipText: "Des profils ayant un sens aigu du contact et de l'animation.",
  },
  {
    title: "Road Show",
    value: EventCategory.ROADSHOW,
    tooltipText:
      "Des profils et compétences vous permettant de répondre à tous les besoins de votre tournée.",
  },
];

const EVENT_CATEGORY_URLS: {
  [key in EventCategory]: PageURL;
} = {
  [EventCategory.PROFESSIONNEL]: PageURL.ONBOARDING_PRO,
  [EventCategory.GRAND_PUBLIC]: PageURL.ONBOARDING_PUBLIC,
  [EventCategory.STREET_MARKETING]: PageURL.ONBOARDING_STREET_MARKETING,
  [EventCategory.ANIMATION_PROMOTIONNELLE]: PageURL.ONBOARDING_ANIMATION_PROMO,
  [EventCategory.ANIMATION_EVENT]: PageURL.ONBOARDING_ANIMATION_EVENT,
  [EventCategory.ROADSHOW]: PageURL.ONBOARDING_ROADSHOW,
};

export default function OnboardingStart() {
  const { setEventType } = useOnboardingContext();
  const navigate = useNavigate();
  const { updateHistory } = useSessionContext();

  return (
    <Layout>
      <ContentLayout>
        <MainContainer
          gap="20px"
          justifyContent="space-between"
          w="100%"
          flexDir="column"
        >
          <Header
            title="CHOISISSEZ VOTRE EVENEMENT"
            subtitle="Pour quel événement avez-vous besoin de personnel ?"
          />
          <GrayLayout display="flex">
            <HoverCard
              icon={Icons.CONVERSATION}
              elements={SERVICE_ACCUEIL_ELEMENTS}
              onChoose={(elem) => {
                setEventType(EventType.ACCUEIL, elem.value);
                updateHistory(EVENT_CATEGORY_URLS[elem.value]);
                navigate(EVENT_CATEGORY_URLS[elem.value]);
              }}
              title="Service d'accueil"
              subtitle="Evenement / Salon / Congrès / Convention"
            />
            <HoverCard
              icon={Icons.ROADSHOW}
              elements={ANIMATION_ELEMENTS}
              onChoose={(elem) => {
                setEventType(EventType.STREET_MARKETING, elem.value);
                updateHistory(EVENT_CATEGORY_URLS[elem.value]);
                navigate(EVENT_CATEGORY_URLS[elem.value]);
              }}
              title="Animation et distribution"
              subtitle="Événementiel, Magasin, Road Show"
            />
          </GrayLayout>
          <BottomBlock />
        </MainContainer>
      </ContentLayout>
    </Layout>
  );
}
