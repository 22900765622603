import {
  BoxProps,
  Checkbox,
  Flex,
  Grid,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/react";
import { FC, ReactElement } from "react";
import { colors } from "../../ui/theme";
import ColorBoxButton from "../Buttons/ColorBoxButton";
import { DressingTopIcon } from "../Icons/DressingTopIcon";

const WHITE = "#fff";
const BLACK = "#000";

interface TopOutfitSelectionCardProps {
  selectedOutfit: any;
  onSelectTop: (value: string | undefined) => void;
  onSelectBlazer: (value: boolean) => void;
}
const TopOutfitSelectionCard: FC<TopOutfitSelectionCardProps & BoxProps> = ({
  selectedOutfit,
  onSelectTop,
  onSelectBlazer,
  ...props
}): ReactElement => {
  return (
    <Flex
      gap="14px"
      width="100%"
      minWidth="250px"
      border={`1px solid ${colors.gray.lightMode}`}
      padding="14px"
      borderRadius="12px"
      {...props}
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap="14px"
      >
        <DressingTopIcon />
        <Text variant="xl" fontWeight="500">
          Haut
        </Text>
      </Flex>
      <Flex direction="column" width="100%" ml="0.5rem">
        <Grid alignItems="center" gridTemplateColumns="80% 20%" mb="0.5rem">
          <Checkbox
            display="flex"
            isChecked={selectedOutfit?.blazer}
            onChange={(v) => onSelectBlazer(v.currentTarget.checked)}
            gridColumn={1}
          >
            Blaser
          </Checkbox>
          <ColorBoxButton
            buttonColor={BLACK}
            isActive={selectedOutfit?.blazer}
          />
        </Grid>
        <RadioGroup
          onChange={onSelectTop}
          value={selectedOutfit?.top ?? ""}
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          gap="0.5rem"
        >
          <Grid width="100%" templateColumns="80% 20%" alignItems="center">
            <Radio value="Chemise" key={1} gridColumn={1}>
              <Text variant="lg" whiteSpace="nowrap">
                Chemise
              </Text>
            </Radio>
            <ColorBoxButton
              buttonColor={WHITE}
              isActive={selectedOutfit?.top === "Chemise"}
            />
          </Grid>

          <Grid width="100%" templateColumns="80% 20%" alignItems="center">
            <Radio value="T-shirt" key={2} gridColumn={1}>
              <Text variant="lg" whiteSpace="nowrap">
                T-shirt
              </Text>
            </Radio>
            <ColorBoxButton
              buttonColor={WHITE}
              isActive={selectedOutfit?.top === "T-shirt"}
            />
          </Grid>
        </RadioGroup>
      </Flex>
    </Flex>
  );
};

export default TopOutfitSelectionCard;
