import { Flex, Text } from "@chakra-ui/react";
import { FC, ReactElement } from "react";

import { colors } from "../../ui/theme";
import ShoesOutfitSelectionCard from "../DressingBlock/ShoesOutfitSelectionCard";
import { DressCode } from "../DressingBlock/DressingBlock";

interface PersonalCustomMenuFirstBlockProps {
  dressCode: DressCode;
  setDressCode: (element: DressCode) => void;
}
const PersonalCustomMenuFirstBlock: FC<PersonalCustomMenuFirstBlockProps> = ({
  dressCode,
  setDressCode,
}): ReactElement => {
  const onSetShoes = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      personalOutfit: {
        ...dressCode?.personalOutfit,
        custom: {
          ...dressCode?.personalOutfit?.custom,
          shoes: value,
          shoesColor: "#000",
        },
      },
    });
  };
  const onSetShoesColor = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      personalOutfit: {
        ...dressCode?.personalOutfit,
        custom: {
          ...dressCode?.personalOutfit?.custom,
          shoesColor: value,
        },
      },
    });
  };
  return (
    <Flex
      border={`1px solid ${colors.gray.lightMode}`}
      padding="24px"
      borderRadius="32px"
      width="100%"
      gap="24px"
    >
      <Flex
        width={{ md: "280px", "2xl": "430px" }}
        direction="column"
        gap={{ md: "10px", "2xl": "24px" }}
      >
        <Text variant="2xl" fontWeight="700" textAlign="left">
          Tenues
        </Text>

        <ShoesOutfitSelectionCard
          selectedOutfit={dressCode?.personalOutfit?.custom}
          onSelectShoes={onSetShoes}
          onSelectShoesColor={onSetShoesColor}
        />
      </Flex>
    </Flex>
  );
};

export default PersonalCustomMenuFirstBlock;
