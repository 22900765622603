import { FC } from "react";
import Layout from "../../components/Layout";
import OptionsContent from "../../modules/Options/OptionsContent";

const Options: FC = () => {
  return (
    <Layout>
      <OptionsContent />
    </Layout>
  );
};

export default Options;
