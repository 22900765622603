import { Flex } from "@chakra-ui/react";
import { useMemo } from "react";
import { Navigate } from "react-router-dom";
import BottomBlock from "../../components/BottomActionsBlock/BottomBlock";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import ContentLayout from "../../components/Layout/ContentLayout";
import GrayLayout from "../../components/Layout/GrayLayout";
import MainContainer from "../../components/Layout/MainContainer";
import TotalCard from "../../components/TotalCard/TotalCard";
import { useOnboardingContext } from "../../context/OnboardingContext";
import { useUserContext } from "../../context/UserContext";
import ChooseEventName from "../../modules/Calendar/CalendarChooseEventName";
import ChooseEventSchedule from "../../modules/Calendar/CalendarEventSchedule";
import { EventSubCategory } from "../../modules/Onboarding/EventTypes";
import { PageURL } from "../../types/pages";

const NEXT_PAGE: {
  [key in EventSubCategory]?: PageURL;
} = {
  [EventSubCategory.SALON_PRO]: PageURL.ONBOARDING_TAILLE,
  [EventSubCategory.CONGRES]: PageURL.ONBOARDING_TAILLE,
  [EventSubCategory.SOIREE_PRESSE]: PageURL.ONBOARDING_TAILLE,
  [EventSubCategory.REMISE_DE_PRIX]: PageURL.ONBOARDING_TAILLE,
  [EventSubCategory.CINEMA]: PageURL.ONBOARDING_TAILLE,
  [EventSubCategory.AUTRE_PRO]: PageURL.ONBOARDING_TAILLE,

  [EventSubCategory.SALON_PUBLIC]: PageURL.ONBOARDING_TAILLE,
  [EventSubCategory.INAUGURATION]: PageURL.ONBOARDING_TAILLE,
  [EventSubCategory.VILLAGE_EVENEMENTIEL]: PageURL.ONBOARDING_TAILLE,
  [EventSubCategory.EVENEMENT_SPORTIF]: PageURL.ONBOARDING_TAILLE,
  [EventSubCategory.AUTRE_PUBLIC]: PageURL.ONBOARDING_TAILLE,

  [EventSubCategory.FLYERS]: PageURL.ONBOARDING_FLYERS,
  [EventSubCategory.TESTING]: PageURL.ONBOARDING_FLYERS,

  [EventSubCategory.HIGH_TECH]: PageURL.ONBOARDING_POINTS_DE_VENTE,
  [EventSubCategory.GAMING]: PageURL.ONBOARDING_POINTS_DE_VENTE,
  [EventSubCategory.FOOD]: PageURL.ONBOARDING_POINTS_DE_VENTE,
  [EventSubCategory.TELEPHONIE]: PageURL.ONBOARDING_POINTS_DE_VENTE,
  [EventSubCategory.INSTITUIONNEL]: PageURL.ONBOARDING_POINTS_DE_VENTE,
  [EventSubCategory.TEXTILE]: PageURL.ONBOARDING_POINTS_DE_VENTE,
  [EventSubCategory.DRINK]: PageURL.ONBOARDING_POINTS_DE_VENTE,
  [EventSubCategory.COSMETIQUE]: PageURL.ONBOARDING_POINTS_DE_VENTE,
};

export default function OnboardingDetails() {
  const {
    eventName,
    setEventName,
    eventLocations,
    setEventLocations,
    eventSubcategory,
  } = useOnboardingContext();
  const { userInfos, setUserInfos } = useUserContext();
  if (!userInfos?.calendarInfo)
    setUserInfos({
      ...userInfos,
      calendarInfo: {
        days: null,
        period: null,
        eventSchedule: [],
      },
    });

  const dateChosen = useMemo(
    () =>
      (userInfos.calendarInfo?.days?.length ?? 0) > 0 ||
      (userInfos.calendarInfo?.period ?? null) !== null,
    [userInfos]
  );

  const missingHours = useMemo(() => {
    let answer = false;
    userInfos.calendarInfo?.eventSchedule?.forEach((schedule) => {
      if (
        schedule.schedule.defaultEndHour === undefined ||
        schedule.schedule.defaultStartHour === undefined
      ) {
        answer = true;
      }
    });
    return answer;
  }, [userInfos]);

  if (!eventSubcategory || !NEXT_PAGE[eventSubcategory])
    return <Navigate to="/onboarding" />;
  return (
    <Layout>
      <ContentLayout>
        <MainContainer>
          <Flex flexDir="column">
            <Header
              title="Détails globaux de votre événement"
              subtitle="Renseignez les premières informations de votre événement"
            />
            <GrayLayout>
              <ChooseEventName
                eventName={eventName ?? ""}
                setEventName={setEventName}
                eventLocations={
                  eventLocations ?? [
                    {
                      address: "",
                      isInGreatParis: false,
                      isAddressKnown: true,
                      city: "",
                      postalCode: "",
                    },
                  ]
                }
                setEventLocations={setEventLocations}
                mb="1rem"
              />
              <ChooseEventSchedule />
            </GrayLayout>
          </Flex>
          <TotalCard />
          <BottomBlock
            disableNextPage={
              !dateChosen || (eventName ?? "") === "" || missingHours
            }
            nextPageLink={NEXT_PAGE[eventSubcategory]}
          />
        </MainContainer>
      </ContentLayout>
    </Layout>
  );
}
