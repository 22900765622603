import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  // Checkbox,
  Flex,
  Heading,
  Radio,
  RadioGroup,
  Select,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { FaChevronDown, FaChevronLeft, FaPlus, FaTimes } from "react-icons/fa";
import NorelaButton from "../../components/Buttons/NorelaButton";
import CustomTooltip, {
  setBreaktimeTooltipContent,
  setRotationTooltipContent,
} from "../../components/CustomTooltip";
import { AvatarIcon } from "../../components/Icons/AvatarIcon";
import { MinusCircleIcon } from "../../components/Icons/MinusCircleIcon";
import { PauseIcon } from "../../components/Icons/PauseIcon";
import { RotationPersonnelIcon } from "../../components/Icons/RotationPersonnelIcon";
import { UserIcon } from "../../components/Icons/UserIcon";
import SmallOutlineContainer from "../../components/Layout/SmallOutlineContainer";
import { HelpType, useHelpContext } from "../../context/HelpContext";
import { useSegment } from "../../context/SegmentContext";
import {
  EventDateInfo,
  ORGANISATION_TYPE,
  PersonDateOverride,
  PersonInfo,
  PersonnelSelectionInfo,
  PersonnelType,
  useUserContext,
} from "../../context/UserContext";
import { colors } from "../../ui/theme";
import {
  calcDiffHours,
  checkIntervalTooSmall,
  computeSwapHour,
  determineOrganisationType,
  formatBreaktimeToDisplay,
  formatBreaktimeToStore,
  getDayListFromPerdiod,
  getDaySchedule,
  getDayScheduleFromEventDateInfo,
  getSpecialDayScheduleFromEventDateInfo,
  is3HoursShift,
} from "../../utils/utils";
import {
  COMPLETE_BREAKTIME_ARRAY,
  TIME_ARRAY,
  TMPGetTimeArrayAfterTimeTMP,
  TMPGetTimeArrayBeforeTimeTMP,
  getTimeArrBetweenTime,
} from "./CalendarEventSchedule";

export type ChosenPerson = {
  role: PersonnelType;
  nb: number;
};

function RoleMenuLine({
  expanded,
  isChosen,
  onChoose,
  expandable,
  title,
  modified,
  error,
  showCheckbox,
  disabled,
  addPerson,
  removePerson,
  ...props
}: {
  isChosen: boolean;
  title: string;
  onChoose: () => void;
  expandable?: boolean;
  expanded?: boolean;
  modified?: boolean;
  error?: boolean;
  showCheckbox?: boolean;
  disabled?: boolean;
  addPerson?: () => void;
  removePerson?: () => void;
} & BoxProps) {
  return (
    <Flex
      p="0.5rem 1rem"
      borderTop={
        isChosen ? `1px solid ${colors.gray.lightMode}` : `1px solid #E2E8F0`
      }
      borderBottom={
        isChosen ? `1px solid ${colors.gray.lightMode}` : `1px solid #E2E8F0`
      }
      gap="1rem"
      justifyContent="flex-start"
      alignItems="center"
      bg={isChosen ? `${colors.gray.transparent} !important` : "transparent"}
      _hover={{
        bg: "#fafcfe",
      }}
      key={title}
      cursor={disabled ? "not-allowed" : "pointer"}
      userSelect="none"
      // pl={expandable ? undefined : "2rem"}
      position="relative"
      {...props}
    >
      {expandable ? (
        <>
          <Flex
            onClick={disabled !== true ? onChoose : undefined}
            cursor={disabled ? "not-allowed" : "pointer"}
            alignItems="center"
          >
            <AvatarIcon />
            <Text ml="1rem" variant="sm" fontWeight="bold">
              {title}
            </Text>
            <Button
              ml="auto"
              variant="unstyled"
              display="flex"
              gap="0.25rem"
              size="sm"
              fontWeight="normal"
            >
              {expanded ? <FaChevronDown /> : <FaChevronLeft />}
            </Button>
          </Flex>
          <NorelaButton
            ml="auto"
            variant="unstyled"
            display="flex"
            gap="0.25rem"
            size="sm"
            padding="8px 10px"
            fontSize="14px"
            fontWeight="bold"
            onClick={addPerson}
          >
            + Ajouter
          </NorelaButton>
        </>
      ) : (
        <Box w="100%">
          <Flex alignItems="center" gap="1rem" flex={1}>
            {!showCheckbox && (
              <Button
                ml="0"
                variant="unstyled"
                display="flex"
                gap="0.25rem"
                size="sm"
                fontWeight="normal"
                onClick={removePerson}
              >
                <MinusCircleIcon />
              </Button>
            )}
            <Flex
              onClick={disabled !== true ? onChoose : undefined}
              cursor={disabled ? "not-allowed" : "pointer"}
              alignItems="center"
              w="100%"
            >
              {showCheckbox && (
                <Checkbox
                  pointerEvents="none"
                  isDisabled={disabled}
                  isChecked={isChosen}
                  mr="1rem"
                />
              )}
              <AvatarIcon />
              <Text ml="1rem" variant="sm" fontWeight="bold">
                {title}
              </Text>
              {modified && !error && (
                <Text
                  ml="1rem"
                  fontWeight="bold"
                  color="white"
                  bg="#38A169"
                  px="0.25rem"
                  borderRadius="2px"
                >
                  Modifié
                </Text>
              )}
              {error && (
                <Text
                  ml="1rem"
                  fontWeight="bold"
                  color="white"
                  bg={colors.red.default}
                  px="0.25rem"
                  borderRadius="2px"
                >
                  Erreur
                </Text>
              )}
            </Flex>
          </Flex>
        </Box>
      )}
    </Flex>
  );
}

function isDateInEvent(date: string, eventDateInfo: EventDateInfo): boolean {
  const dateMoment = moment(date);
  if (
    moment(eventDateInfo.period?.startDate).isSameOrBefore(dateMoment) &&
    moment(eventDateInfo.period?.endDate).isSameOrAfter(dateMoment)
  )
    return true;
  return (
    eventDateInfo.days?.some((day) => moment(day).isSame(dateMoment, "day")) ??
    false
  );
}

export function checkIsModifiedForEmargement(
  eventDateInfo: EventDateInfo,
  person: PersonInfo,
  i: number
): boolean {
  //handle period
  if (eventDateInfo.period) {
    const dayList = getDayListFromPerdiod(eventDateInfo.period);
    if (dayList.length === 0) return false; // useless ?
    for (let j = 0; j < dayList.length; j++) {
      const day = dayList[j];
      const emargementDefaultDaySchedule =
        getSpecialDayScheduleFromEventDateInfo(
          day,
          eventDateInfo,
          PersonnelType.emargement
        );
      const staffDefaultDaySchedule = getDayScheduleFromEventDateInfo(
        day,
        eventDateInfo
      );
      const daySchedule = getDaySchedule(
        person,
        day,
        i,
        emargementDefaultDaySchedule?.startHour ??
          staffDefaultDaySchedule?.defaultStartHour ??
          "",
        emargementDefaultDaySchedule?.endHour ??
          staffDefaultDaySchedule?.defaultEndHour ??
          ""
      );
      if (daySchedule === null) {
        return true;
      } else if (!is3HoursShift(daySchedule.end, daySchedule.begin)) {
        return true;
      }
    }
  }
  if (eventDateInfo.days) {
    for (let j = 0; j < eventDateInfo!.days!.length; j++) {
      const day = eventDateInfo!.days![j];
      const emargementDefaultDaySchedule =
        getSpecialDayScheduleFromEventDateInfo(
          day,
          eventDateInfo,
          PersonnelType.emargement
        );
      const staffDefaultDaySchedule = getDayScheduleFromEventDateInfo(
        day,
        eventDateInfo
      );
      const daySchedule = getDaySchedule(
        person,
        day,
        i,
        emargementDefaultDaySchedule?.startHour ??
          staffDefaultDaySchedule?.defaultStartHour ??
          "",
        emargementDefaultDaySchedule?.endHour ??
          staffDefaultDaySchedule?.defaultEndHour ??
          ""
      );
      if (daySchedule === null) {
        return true;
      } else if (!is3HoursShift(daySchedule.end, daySchedule.begin)) {
        return true;
      }
    }
  }
  return false;
}

function RoleMenuElem({
  person,
  personnelType,
  isChosen,
  onChoose,
  onAddPerson,
  onRemovePerson,
  chosenPersons,
  showCheckbox,
  eventDateInfo,
}: {
  person: PersonInfo;
  personnelType: PersonnelType;
  isChosen: boolean;
  onChoose: (personNumber: number) => void;
  onAddPerson: () => void;
  onRemovePerson: (personNumber: number) => void;
  chosenPersons: ChosenPerson[];
  showCheckbox: boolean;
  eventDateInfo: EventDateInfo;
}) {
  const [expanded, setExpanded] = useState(isChosen);
  let expandedContent: React.ReactNode[] = [];
  // if (expanded || person.count === 1) {
  if (expanded) {
    for (let i = person.count - 1; i >= 0; i--) {
      let modified = false;
      let error = false;

      if (person.role === PersonnelType.emargement)
        modified = checkIsModifiedForEmargement(eventDateInfo, person, i);
      for (const date in person.dateOverrides?.at(i)) {
        if (!isDateInEvent(date, eventDateInfo)) continue;
        if (
          person.role !== PersonnelType.emargement &&
          (!person.dateOverrides?.at(i)?.[date].isWorking ||
            person.dateOverrides?.at(i)?.[date].organisationType ||
            person.dateOverrides?.at(i)?.[date].startHour ||
            person.dateOverrides?.at(i)?.[date].swapHour ||
            person.dateOverrides?.at(i)?.[date].breakTime ||
            person.dateOverrides?.at(i)?.[date].endHour)
        )
          modified = true;

        const defaultSpecialDaySchedule =
          getSpecialDayScheduleFromEventDateInfo(
            date,
            eventDateInfo,
            personnelType
          );
        const defaultDaySchedule = getDayScheduleFromEventDateInfo(
          date,
          eventDateInfo
        );
        const end =
          person.dateOverrides?.at(i)?.[date].endHour ??
          defaultSpecialDaySchedule?.endHour ??
          defaultDaySchedule?.defaultEndHour ??
          "";
        const start =
          person.dateOverrides?.at(i)?.[date].startHour ??
          defaultSpecialDaySchedule?.startHour ??
          defaultDaySchedule?.defaultStartHour ??
          "";
        if (
          person.dateOverrides?.at(i)?.[date].isWorking &&
          checkIntervalTooSmall(end, start)
        )
          error = true;
      }

      expandedContent.push(
        <RoleMenuLine
          key={`${person.title}-${i + 1}`}
          onChoose={() => onChoose(i)}
          isChosen={
            isChosen &&
            chosenPersons.some((cp) => cp.nb === i && cp.role === personnelType)
          }
          title={
            person.count === 1
              ? `${person.title}`
              : `${person.title} N°${i + 1}`
          }
          modified={modified}
          showCheckbox={showCheckbox} // TODO I think we should remove that
          error={error}
          disabled={
            isChosen &&
            showCheckbox &&
            chosenPersons[0].nb === i &&
            chosenPersons[0].role === personnelType
          }
          removePerson={() => {
            onRemovePerson(i);
          }}
        />
      );
    }
  }
  return (
    <>
      {/* {person.count !== 1 && ( */}
      <RoleMenuLine
        expanded={expanded}
        isChosen={isChosen && !expanded}
        onChoose={() => setExpanded(!expanded)}
        // expandable={person.count !== 1}
        expandable={true}
        title={person.title}
        addPerson={onAddPerson}
      />
      {/* )} */}
      {expandedContent}
    </>
  );
}

function RolesMenu({
  selectDePersonnel,
  onClickPerson,
  onAddPerson,
  onRemovePerson,
  chosenPersons,
  showCheckbox,
  eventDateInfo,
}: {
  selectDePersonnel: PersonnelSelectionInfo | undefined;
  chosenPersons: ChosenPerson[];
  onClickPerson: (cp: ChosenPerson) => void;
  onAddPerson: (role: PersonnelType) => void;
  onRemovePerson: (cp: ChosenPerson) => void;
  showCheckbox: boolean;
  eventDateInfo: EventDateInfo;
}) {
  if (!selectDePersonnel) return null;

  return (
    <Flex
      direction="column"
      alignItems="center"
      height="fit-content"
      minW="300px"
      border={`1px solid ${colors.black}`}
      borderRadius="12px"
      overflow="hidden"
    >
      <Flex
        width="100%"
        p="12px 24px"
        gap="10px"
        borderBottom={`1px solid ${colors.gray.lightMode}`}
        backgroundColor={colors.gray.lighter}
        borderRadius="12px 12px 0 0"
        alignItems="center"
        justifyContent="flex-start"
      >
        <UserIcon />
        <Text variant="xs" fontWeight="700" lineHeight="20px">
          RÔLE
        </Text>
      </Flex>
      <Box width="100%" mt="-1px" maxH="250px" overflowY="auto">
        <Flex direction="column" width="100%" h="100%">
          {Object.keys(selectDePersonnel.persons ?? {}).map((key) => {
            let person = selectDePersonnel.persons[key as PersonnelType];
            if (!person) return null;
            const isChosen = chosenPersons.some(
              (cp) => cp.role === (key as PersonnelType)
            );
            return (
              person.count > 0 && (
                <RoleMenuElem
                  personnelType={key as PersonnelType}
                  chosenPersons={chosenPersons}
                  key={key}
                  person={person}
                  isChosen={isChosen}
                  onChoose={(personNb) => {
                    onClickPerson({ role: key as PersonnelType, nb: personNb });
                  }}
                  onAddPerson={() => {
                    onAddPerson(key as PersonnelType);
                  }}
                  onRemovePerson={(personNb) => {
                    onRemovePerson({
                      role: key as PersonnelType,
                      nb: personNb,
                    });
                  }}
                  showCheckbox={showCheckbox}
                  eventDateInfo={eventDateInfo}
                />
              )
            );
          })}
        </Flex>
      </Box>
    </Flex>
  );
}

function getScheduleOverride(
  eventDateInfo: EventDateInfo,
  personNumber: number,
  person: PersonInfo,
  date: string,
  keepUndefined = false
): PersonDateOverride {
  const override = {
    ...(person?.dateOverrides?.at(personNumber)?.[date] ?? {
      isWorking: true,
      startHour: undefined,
      swapHour: undefined,
      endHour: undefined,
      breakTime: undefined,
      organisationType: undefined,
    }),
  };
  const defaultDaySchedule = getDayScheduleFromEventDateInfo(
    date,
    eventDateInfo
  );
  const defaultSpecialDaySchedule = getSpecialDayScheduleFromEventDateInfo(
    date,
    eventDateInfo,
    person?.role
  );
  const start =
    override.startHour ??
    defaultSpecialDaySchedule?.startHour ??
    defaultDaySchedule?.defaultStartHour;
  const end =
    override.endHour ??
    defaultSpecialDaySchedule?.endHour ??
    defaultDaySchedule?.defaultEndHour;
  const organisation =
    override.organisationType ??
    defaultSpecialDaySchedule?.organisationType ??
    defaultDaySchedule?.organisationType ??
    ORGANISATION_TYPE.NONE;
  const swap =
    override.swapHour ??
    defaultSpecialDaySchedule?.swapHour ??
    defaultDaySchedule?.defaultSwapHour ??
    undefined;
  const breakTime =
    override.breakTime ??
    defaultSpecialDaySchedule?.breakTime ??
    defaultDaySchedule?.defaultBreakTime ??
    "00:00";
  if (keepUndefined === false) {
    override.startHour = start;
    override.endHour = end;
    override.organisationType = organisation;
    override.swapHour = swap;
    override.breakTime = breakTime;
  }
  return override;
}

function setScheduleOverride(
  role: PersonInfo,
  date: string,
  personNumber: number,
  newSchedule: PersonDateOverride
): PersonInfo {
  if (!role.dateOverrides) role.dateOverrides = [];
  while (role.dateOverrides.length <= personNumber) role.dateOverrides.push({});
  role.dateOverrides!.at(personNumber)![date] = newSchedule;
  return role;
}

function DayScheduleElem({
  role,
  personNumber,
  eventDateInfo,
  day,
  onChangeHours,
  onChangeRotation,
  setIsWorking,
  onChangeBreakTime,
}: {
  role: PersonInfo;
  personNumber: number;
  eventDateInfo: EventDateInfo;
  day: { format: string; date: string };
  onChangeHours: (
    startHour: string | undefined,
    swapHour: string | undefined,
    endHour: string | undefined
  ) => void;
  onChangeRotation: (organisationType: ORGANISATION_TYPE) => void;
  setIsWorking: (isWorking: boolean) => void;
  onChangeBreakTime: (breakTime: string) => void;
}) {
  const dayToShow =
    day.format.slice(0, 4) + day.format.slice(day.format.indexOf(" "));

  const scheduleOverride = useMemo(() => {
    return getScheduleOverride(eventDateInfo, personNumber, role, day.date);
  }, [eventDateInfo, personNumber, role, day]);

  const [startArray, setStartArray] = useState<string[]>(
    scheduleOverride.endHour
      ? TMPGetTimeArrayBeforeTimeTMP(scheduleOverride?.endHour)
      : TIME_ARRAY
  );
  const [swapArray, setSwapArray] = useState<string[]>(
    scheduleOverride.endHour && scheduleOverride.startHour
      ? getTimeArrBetweenTime(
          scheduleOverride?.startHour,
          scheduleOverride?.endHour
        )
      : TIME_ARRAY
  );
  const [endArray, setEndArray] = useState<string[]>(
    scheduleOverride.startHour
      ? TMPGetTimeArrayAfterTimeTMP(scheduleOverride?.startHour)
      : TIME_ARRAY
  );
  const [missionShift, setMissionShift] = useState<number>(
    calcDiffHours(scheduleOverride.endHour, scheduleOverride.startHour)
  );

  useEffect(() => {
    setStartArray(
      scheduleOverride.endHour
        ? TMPGetTimeArrayBeforeTimeTMP(scheduleOverride?.endHour)
        : TIME_ARRAY
    );

    setSwapArray(
      scheduleOverride.endHour && scheduleOverride.startHour
        ? getTimeArrBetweenTime(
            scheduleOverride?.startHour,
            scheduleOverride?.endHour
          )
        : TIME_ARRAY
    );

    setEndArray(
      scheduleOverride.startHour
        ? TMPGetTimeArrayAfterTimeTMP(scheduleOverride?.startHour)
        : TIME_ARRAY
    );

    setMissionShift(
      calcDiffHours(scheduleOverride.endHour, scheduleOverride.startHour)
    );

    //TODO setDefaultBreaktime
  }, [scheduleOverride]);

  const [availableBreaktimeArray, setAvailableBreaktimeArray] = useState<
    string[]
  >(COMPLETE_BREAKTIME_ARRAY);
  useEffect(() => {
    const shift = calcDiffHours(
      scheduleOverride.endHour,
      scheduleOverride.startHour
    );
    if (
      scheduleOverride.isWorking &&
      (scheduleOverride?.organisationType ?? ORGANISATION_TYPE.NONE) !==
        ORGANISATION_TYPE.ROTATION &&
      shift >= 14
    ) {
      scheduleOverride.organisationType = ORGANISATION_TYPE.ROTATION;
      scheduleOverride.swapHour = computeSwapHour(
        scheduleOverride.endHour ?? "18:00",
        scheduleOverride.startHour ?? "08:00"
      );
    }
    if (
      scheduleOverride.isWorking &&
      (scheduleOverride?.organisationType ?? ORGANISATION_TYPE.ROTATION) !==
        ORGANISATION_TYPE.NONE &&
      shift <= 6
    ) {
      scheduleOverride.organisationType = ORGANISATION_TYPE.NONE;
      scheduleOverride.swapHour = undefined;
      scheduleOverride.breakTime = undefined;
    }

    if (shift === 6.5) {
      setAvailableBreaktimeArray(COMPLETE_BREAKTIME_ARRAY.slice(0, 1));
    } else if (shift === 7) {
      setAvailableBreaktimeArray(COMPLETE_BREAKTIME_ARRAY.slice(1, 2));
    } else if (shift === 8) {
      setAvailableBreaktimeArray(COMPLETE_BREAKTIME_ARRAY.slice(1, 3));
    } else if (shift >= 9) {
      setAvailableBreaktimeArray(COMPLETE_BREAKTIME_ARRAY.slice(1, 4));
    }
  }, [scheduleOverride]);

  return (
    <Flex alignItems="center" gap="0.5rem" pr="0.5rem">
      <Flex direction="column" mb="0.75rem">
        <Flex direction="row" mb="0.25rem">
          <Box
            alignSelf="center"
            fontSize="12px"
            borderRadius="4px"
            border={`solid 1px ${colors.primaryFontColor.lightMode}`}
            h="fit-content"
            cursor="pointer"
            onClick={() => setIsWorking(!scheduleOverride.isWorking)}
            padding="4px"
            mr="4px"
          >
            {scheduleOverride.isWorking ? <FaTimes /> : <FaPlus />}
          </Box>
          <Text
            border="solid 1px"
            borderColor={
              scheduleOverride.isWorking
                ? colors.primaryFontColor.lightMode
                : colors.red.default
            }
            bg={scheduleOverride.isWorking ? "transparent" : colors.red.default}
            color={
              scheduleOverride.isWorking
                ? colors.primaryFontColor.lightMode
                : colors.white
            }
            p="0.25rem 0.5rem"
            borderRadius="8px"
            w="100px"
            textAlign="left"
            mr="4px"
          >
            {dayToShow}
          </Text>
          {scheduleOverride.organisationType === ORGANISATION_TYPE.ROTATION ? (
            <>
              <Flex
                mr="4px"
                display={scheduleOverride.isWorking ? "flex" : "none"}
                border="solid 1px"
                borderColor={colors.black}
                borderRadius="8px"
                justifyContent="space-evenly"
                py="0.25rem"
                sx={{
                  ".chakra-select__wrapper": {},
                  ".chakra-select": {
                    padding: "0 0.25rem",
                    textAlign: "center",
                  },
                  ".chakra-select__icon-wrapper": {
                    display: "none",
                  },
                }}
              >
                <Select
                  variant="unstyled"
                  value={scheduleOverride.startHour}
                  placeholder={scheduleOverride.startHour || "Par défaut"}
                  onChange={(e) =>
                    onChangeHours(
                      e.target.value === "" ? undefined : e.target.value,
                      scheduleOverride.swapHour,
                      scheduleOverride.endHour
                    )
                  }
                  w="auto"
                  iconSize="0"
                  color={colors.black}
                >
                  {startArray?.map((time) => (
                    <option key={time}>{time}</option>
                  ))}
                </Select>
                <Text color={colors.black}>-</Text>
                <Select
                  variant="unstyled"
                  value={scheduleOverride.swapHour}
                  placeholder="Par défaut"
                  onChange={(e) =>
                    onChangeHours(
                      scheduleOverride.startHour,
                      e.target.value === "" ? undefined : e.target.value,
                      scheduleOverride.endHour
                    )
                  }
                  color={colors.black}
                  w="auto"
                  iconSize="0"
                >
                  {swapArray?.map((time) => (
                    <option key={time}>{time}</option>
                  ))}
                </Select>
              </Flex>
              <Flex
                display={scheduleOverride.isWorking ? "flex" : "none"}
                border="solid 1px"
                borderColor={colors.black}
                borderRadius="8px"
                justifyContent="space-evenly"
                py="0.25rem"
                sx={{
                  ".chakra-select__wrapper": {},
                  ".chakra-select": {
                    padding: "0 0.25rem",
                    textAlign: "center",
                  },
                  ".chakra-select__icon-wrapper": {
                    display: "none",
                  },
                }}
              >
                <Select
                  mr="4px"
                  variant="unstyled"
                  value={scheduleOverride.swapHour}
                  placeholder="Par défaut"
                  onChange={(e) =>
                    onChangeHours(
                      scheduleOverride.startHour,
                      e.target.value === "" ? undefined : e.target.value,
                      scheduleOverride.endHour
                    )
                  }
                  w="auto"
                  iconSize="0"
                  color={colors.black}
                >
                  {swapArray?.map((time) => (
                    <option key={time}>{time}</option>
                  ))}
                </Select>
                <Text color={colors.black}>-</Text>
                <Select
                  variant="unstyled"
                  value={scheduleOverride.endHour}
                  placeholder={scheduleOverride.endHour || "Par défaut"}
                  onChange={(e) =>
                    onChangeHours(
                      scheduleOverride.startHour,
                      scheduleOverride.swapHour,
                      e.target.value === "" ? undefined : e.target.value
                    )
                  }
                  color={colors.black}
                  w="auto"
                  iconSize="0"
                >
                  {endArray?.map((time) => (
                    <option key={time}>{time}</option>
                  ))}
                </Select>
              </Flex>
            </>
          ) : (
            <Flex
              mr="4px"
              display={scheduleOverride.isWorking ? "flex" : "none"}
              border="solid 1px"
              borderColor={colors.black}
              borderRadius="8px"
              justifyContent="space-evenly"
              py="0.25rem"
              sx={{
                ".chakra-select__wrapper": {},
                ".chakra-select": {
                  padding: "0 0.25rem",
                  textAlign: "center",
                },
                ".chakra-select__icon-wrapper": {
                  display: "none",
                },
              }}
            >
              <Select
                variant="unstyled"
                value={scheduleOverride.startHour}
                placeholder="Par défaut"
                onChange={(e) =>
                  onChangeHours(
                    e.target.value === "" ? undefined : e.target.value,
                    undefined,
                    scheduleOverride.endHour
                  )
                }
                w="auto"
                iconSize="0"
                color={colors.black}
              >
                {startArray?.map((time) => (
                  <option key={time}>{time}</option>
                ))}
              </Select>
              <Text color={colors.black}>-</Text>
              <Select
                variant="unstyled"
                value={scheduleOverride.endHour}
                placeholder="Par défaut"
                onChange={(e) =>
                  onChangeHours(
                    scheduleOverride.startHour,
                    undefined,
                    e.target.value === "" ? undefined : e.target.value
                  )
                }
                color={colors.black}
                w="auto"
                iconSize="0"
              >
                {endArray?.map((time) => (
                  <option key={time}>{time}</option>
                ))}
              </Select>
            </Flex>
          )}
        </Flex>
        {scheduleOverride.isWorking && missionShift > 6 && (
          <RadioGroup
            onChange={(value) => onChangeRotation(value as ORGANISATION_TYPE)}
            value={scheduleOverride.organisationType}
            display="flex"
            mr="0.25rem"
          >
            <Radio value={ORGANISATION_TYPE.BREAKTIME} display="flex">
              <CustomTooltip content={setBreaktimeTooltipContent(missionShift)}>
                <Flex>
                  <PauseIcon />

                  {/* <Text
                    textDecoration="underline"
                    variant="lg"
                    whiteSpace="nowrap"
                    pl="0.5rem"
                    opacity={missionShift < 6 || missionShift >= 14 ? 0.3 : 1}
                  >
                    Pause
                  </Text> */}
                </Flex>
              </CustomTooltip>
            </Radio>
            <Select
              mr="2.25rem"
              disabled={missionShift < 6 || missionShift >= 14}
              value={
                formatBreaktimeToDisplay(
                  scheduleOverride.breakTime ?? "00:00"
                ) + " de pause"
              }
              onChange={(e) =>
                onChangeBreakTime(e.target.value.replace(" de pause", ""))
              }
              w="auto"
              iconSize="0"
              color={colors.black}
            >
              {availableBreaktimeArray?.map((time) => (
                <option key={time}>
                  {formatBreaktimeToDisplay(time)} de pause
                </option>
              ))}
            </Select>
            <Radio value={ORGANISATION_TYPE.ROTATION} display="flex">
              <CustomTooltip content={setRotationTooltipContent(missionShift)}>
                <Flex>
                  <RotationPersonnelIcon />
                  <Text
                    textDecoration="underline"
                    variant="lg"
                    whiteSpace="nowrap"
                    pl="0.5rem"
                    opacity={missionShift < 6 ? 0.5 : 1}
                  >
                    Rotation
                  </Text>
                </Flex>
              </CustomTooltip>
            </Radio>
          </RadioGroup>
        )}
        {scheduleOverride.isWorking && missionShift <= 6 && (
          <Text textAlign="left">Sans pause</Text>
        )}
      </Flex>
    </Flex>
  );
}

export function getMonthDayDateFormat(d: string) {
  const date = moment(d).toDate();
  let month = date.toLocaleDateString("FR-fr", { month: "long" });
  let day = date.getDate();
  month = month.slice(0, 1).toUpperCase() + month.slice(1);

  return `${month} ${day}`;
}

function ChangeDaySchedule({
  role,
  personNumber,
  eventDateInfo,
  copying,
  setIsWorking,
  setNewHours,
  changeRotation,
  onChangeBreakTime,
}: {
  role: PersonInfo;
  personNumber: number;
  eventDateInfo: EventDateInfo;
  copying: boolean;
  setIsWorking: (isWorking: boolean, date: string) => void;
  setNewHours: (
    startHour: string | undefined,
    swapHour: string | undefined,
    endHour: string | undefined,
    date: string
  ) => void;
  changeRotation: (date: string, organisationType: ORGANISATION_TYPE) => void;
  onChangeBreakTime: (date: string, breakTime: string) => void;
}) {
  const days = useMemo<{ format: string; date: string }[]>(() => {
    if (eventDateInfo.period) {
      const days = [];
      const dayDiff = moment(eventDateInfo.period.endDate).diff(
        eventDateInfo.period.startDate,
        "days"
      );
      const itMoment = moment(eventDateInfo.period.startDate).clone();
      for (let i = 0; i <= dayDiff; i++, itMoment.add(1, "days")) {
        days.push({
          format: getMonthDayDateFormat(itMoment.toISOString()),
          date: itMoment.toISOString(),
        });
      }
      return days;
    } else if (eventDateInfo.days) {
      return eventDateInfo.days.map((moment) => {
        return {
          format: getMonthDayDateFormat(moment),
          date: moment,
        };
      });
    }
    return [];
  }, [eventDateInfo]);

  return (
    <SmallOutlineContainer p="1rem" maxW="500px" position="relative">
      <Flex textAlign="left">
        <Heading size="sm">Modifier l'horaire d'une journée précise</Heading>
        <CustomTooltip
          content={[
            "Les salaires sont valorisés sur 3hrs pour inciter les profils à répondre présents sur ce type d'amplitude horaire.",
          ]}
        >
          <Box
            w="20px"
            h="20px"
            color={colors.white}
            bg={colors.additional.lightMode}
            borderRadius="50%"
            ml="1rem"
            cursor="pointer"
          >
            <Heading size="xs" ml="7px" mt="2px">
              ?
            </Heading>
          </Box>
        </CustomTooltip>
      </Flex>
      <Text size="sm" textAlign="left">
        Horaires de la personne "{role?.title} N°{personNumber + 1}"
      </Text>
      <Flex
        alignItems="center"
        gap="1rem"
        mb="1rem"
        marginTop="10px"
        marginBottom="10px"
      >
        <Flex gap="0.5rem" alignItems="center">
          <Box w="18px" h="14px" bg={colors.primaryFontColor.lightMode} />
          <Text fontSize="12px">Horaire standard</Text>
        </Flex>
        <Flex gap="0.25rem" alignItems="center">
          <Text fontSize="20px" fontWeight="bold">
            !
          </Text>
          <Text fontSize="12px">Jour férié, tarif spécial</Text>
        </Flex>
        <Flex gap="0.25rem" alignItems="center">
          <Box w="18px" h="14px" bg={colors.red.default} />
          <Text fontSize="12px">Pas de travail ce jour</Text>
        </Flex>
      </Flex>
      <Flex gap="0.5rem" flexDir="column" maxH="175px" overflow="auto">
        {days.map((day) => (
          <DayScheduleElem
            key={day.format}
            role={role}
            personNumber={personNumber}
            eventDateInfo={eventDateInfo}
            day={day}
            onChangeHours={(startHour, swapHour, endHour) =>
              setNewHours(startHour, swapHour, endHour, day.date)
            }
            onChangeRotation={(organisationType: ORGANISATION_TYPE) =>
              changeRotation(day.date, organisationType)
            }
            setIsWorking={(working) => setIsWorking(working, day.date)}
            onChangeBreakTime={(breakTime) =>
              onChangeBreakTime(day.date, breakTime)
            }
          />
        ))}
      </Flex>
      {copying && (
        <Flex
          position="absolute"
          w="100%"
          h="100%"
          bg="rgba(0,0,0,0.7)"
          left="0"
          top="0"
          borderRadius="12px"
          cursor="not-allowed"
          alignItems="center"
          justifyContent="center"
        >
          <Text color="white" fontWeight="bold" fontSize="24px">
            Vous copiez les horaires
          </Text>
        </Flex>
      )}
    </SmallOutlineContainer>
  );
}

export default function SchedulePersonnalisation({
  eventDateInfo,
  onAddPerson,
  onRemovePerson,
}: {
  eventDateInfo: EventDateInfo;
  onAddPerson: (role: PersonnelType) => void;
  onRemovePerson: (cp: ChosenPerson) => void;
}) {
  const getFirstChosenPerson = (): ChosenPerson | null => {
    const keys = Object.keys(userInfos?.selectDePersonnelInfo?.persons ?? {});
    return keys.length > 0 ? { role: keys[0] as PersonnelType, nb: 0 } : null;
  };

  const { userInfos, setUserInfos } = useUserContext();
  const [chosenPerson, setChosenPerson] = useState<ChosenPerson | null>(
    getFirstChosenPerson()
  );
  const [copying, setCopying] = useState(false);
  const [copyingPersons, setCopyingPersons] = useState<ChosenPerson[]>([]);
  const { setHelp } = useHelpContext();
  const { track } = useSegment();

  useEffect(() => {
    setCopying(false);
    setCopyingPersons([]);
    if (!chosenPerson) {
      return setChosenPerson(getFirstChosenPerson());
    }
    if (!userInfos?.selectDePersonnelInfo?.persons?.[chosenPerson.role]) {
      return setChosenPerson(getFirstChosenPerson());
    }
    if (
      chosenPerson.nb >=
      userInfos?.selectDePersonnelInfo?.persons?.[chosenPerson.role]?.count!
    ) {
      setChosenPerson({ ...chosenPerson, nb: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfos]);

  if (
    !userInfos ||
    !userInfos.selectDePersonnelInfo ||
    !userInfos.calendarInfo ||
    !chosenPerson
  )
    return null;

  const saveRole = (role: PersonnelType, info: PersonInfo) => {
    setUserInfos({
      ...userInfos,
      selectDePersonnelInfo: {
        ...userInfos.selectDePersonnelInfo,
        persons: {
          ...userInfos.selectDePersonnelInfo!.persons,
          [role]: info,
        },
      },
    });
  };

  const setNewHours = (
    startHour: string | undefined,
    swapHour: string | undefined,
    endHour: string | undefined,
    date: string
    //TODO breaktime ?
  ) => {
    const roleOverride = getScheduleOverride(
      eventDateInfo,
      chosenPerson.nb,
      userInfos.selectDePersonnelInfo!.persons[chosenPerson.role]!,
      date,
      true
    );
    const defaultDaySchedule = getDayScheduleFromEventDateInfo(
      date,
      eventDateInfo
    );
    const defaultSpecialDaySchedule = getSpecialDayScheduleFromEventDateInfo(
      date,
      eventDateInfo,
      chosenPerson.role
    );

    const start =
      startHour ??
      defaultSpecialDaySchedule?.startHour ??
      defaultDaySchedule?.defaultStartHour;
    const end =
      endHour ??
      defaultSpecialDaySchedule?.endHour ??
      defaultDaySchedule?.defaultEndHour;

    const defaultStart =
      defaultSpecialDaySchedule?.startHour ??
      defaultDaySchedule?.defaultStartHour;
    const defaultEnd =
      defaultSpecialDaySchedule?.endHour ?? defaultDaySchedule?.defaultEndHour;

    if (start && start !== defaultStart) roleOverride.startHour = start;
    else roleOverride.startHour = undefined;

    if (end && end !== defaultEnd) roleOverride.endHour = end;
    else roleOverride.endHour = undefined;

    const defaultOrganisationType =
      defaultSpecialDaySchedule?.organisationType ??
      defaultDaySchedule?.organisationType;
    let validatedOrganisationType = determineOrganisationType(
      start,
      end,
      roleOverride.organisationType ?? defaultOrganisationType
    );
    if (validatedOrganisationType !== defaultOrganisationType)
      roleOverride.organisationType = validatedOrganisationType;
    else roleOverride.organisationType = undefined;

    const actualOrganisationType =
      roleOverride.organisationType ?? defaultOrganisationType;
    if (swapHour) {
      if (actualOrganisationType === ORGANISATION_TYPE.ROTATION)
        roleOverride.swapHour = swapHour;
      else roleOverride.swapHour = undefined;
    } else {
      if (actualOrganisationType === ORGANISATION_TYPE.ROTATION)
        roleOverride.swapHour = computeSwapHour(end, start);
    }
    const newPerson = setScheduleOverride(
      { ...userInfos.selectDePersonnelInfo?.persons[chosenPerson.role]! },
      date,
      chosenPerson.nb,
      roleOverride
    );
    saveRole(chosenPerson.role, newPerson);
  };
  const changeRotation = (
    date: string,
    organisationType: ORGANISATION_TYPE
  ) => {
    const roleOverride = getScheduleOverride(
      eventDateInfo,
      chosenPerson.nb,
      userInfos.selectDePersonnelInfo!.persons[chosenPerson.role]!,
      date,
      true
    );

    const defaultDaySchedule = getDayScheduleFromEventDateInfo(
      //get default for everybody
      date,
      eventDateInfo
    );

    const defaultSpecialDaySchedule = getSpecialDayScheduleFromEventDateInfo(
      //get default for personnelType (may not exist)
      date,
      eventDateInfo,
      chosenPerson.role
    );

    const start =
      roleOverride.startHour ??
      defaultSpecialDaySchedule?.startHour ??
      defaultDaySchedule?.defaultStartHour;
    const end =
      roleOverride.endHour ??
      defaultSpecialDaySchedule?.endHour ??
      defaultDaySchedule?.defaultEndHour;

    let validatedOrganisationType = determineOrganisationType(
      start,
      end,
      organisationType
    );

    if (validatedOrganisationType !== organisationType) {
      validatedOrganisationType = determineOrganisationType(
        start,
        end,
        roleOverride.organisationType
      );
    }

    const defaultOrganisationType =
      defaultSpecialDaySchedule?.organisationType ??
      defaultDaySchedule?.organisationType;
    if (
      defaultSpecialDaySchedule &&
      defaultOrganisationType === validatedOrganisationType
    )
      roleOverride.organisationType = undefined;
    else roleOverride.organisationType = validatedOrganisationType;

    if (validatedOrganisationType && !roleOverride.swapHour)
      roleOverride.swapHour = computeSwapHour(end ?? "18:00", start ?? "08:00");
    const newPerson = setScheduleOverride(
      { ...userInfos.selectDePersonnelInfo?.persons[chosenPerson.role]! },
      date,
      chosenPerson.nb,
      roleOverride
    );
    saveRole(chosenPerson.role, newPerson);
  };

  const changeBreaktime = (date: string, breakTime: string) => {
    const roleOverride = getScheduleOverride(
      eventDateInfo,
      chosenPerson.nb,
      userInfos.selectDePersonnelInfo?.persons[chosenPerson.role]!,
      date,
      true
    );
    roleOverride.breakTime = formatBreaktimeToStore(breakTime);
    const newPerson = setScheduleOverride(
      { ...userInfos.selectDePersonnelInfo?.persons[chosenPerson.role]! },
      date,
      chosenPerson.nb,
      roleOverride
    );
    saveRole(chosenPerson.role, newPerson);
  };

  const setIsWorking = (working: boolean, date: string) => {
    const roleOverride = getScheduleOverride(
      eventDateInfo,
      chosenPerson.nb,
      userInfos.selectDePersonnelInfo?.persons[chosenPerson.role]!,
      date,
      true
    );
    roleOverride.isWorking = working;
    const newPerson = setScheduleOverride(
      { ...userInfos.selectDePersonnelInfo?.persons[chosenPerson.role]! },
      date,
      chosenPerson.nb,
      roleOverride
    );
    saveRole(chosenPerson.role, newPerson);
  };

  const toggleCopying = () => {
    if (copying) {
      setCopyingPersons([]);
      setHelp(null);
      track("Paste schedule");
    }
    if (!copying) {
      setHelp(HelpType.WORKTIME_COPY);
      track("Copy schedule");
    }
    setCopying(!copying);
  };

  const paste = () => {
    const roleOverride =
      (userInfos?.selectDePersonnelInfo as PersonnelSelectionInfo)?.persons?.[
        chosenPerson.role as PersonnelType
      ]?.dateOverrides?.at(chosenPerson.nb) ?? {};
    const persons =
      (userInfos?.selectDePersonnelInfo as PersonnelSelectionInfo)?.persons ??
      {};
    copyingPersons.forEach((p) => {
      if (!persons[p.role]!.dateOverrides) {
        persons[p.role]!.dateOverrides = [];
      }
      while (persons[p.role]!.dateOverrides!.length <= p.nb)
        persons[p.role]!.dateOverrides!.push({});
      persons[p.role]!.dateOverrides![p.nb] = { ...roleOverride };
    });

    setUserInfos({
      ...userInfos,
      selectDePersonnelInfo: {
        ...userInfos.selectDePersonnelInfo,
        persons,
      },
    });
    toggleCopying();
  };
  return (
    <Box>
      <Flex gap="1rem">
        <RolesMenu // LEFT PART
          selectDePersonnel={userInfos.selectDePersonnelInfo}
          onClickPerson={(person) => {
            if (!copying) {
              setChosenPerson(person);
            } else if (
              copyingPersons.some(
                (p) => p.nb === person.nb && p.role === person.role
              )
            ) {
              setCopyingPersons(
                copyingPersons.filter(
                  (p) => p.nb !== person.nb || p.role !== person.role
                )
              );
            } else {
              setCopyingPersons([...copyingPersons, person]);
            }
          }}
          onAddPerson={(role) => {
            onAddPerson(role);
          }}
          onRemovePerson={(person) => {
            onRemovePerson(person);
          }}
          chosenPersons={[chosenPerson, ...copyingPersons]}
          showCheckbox={copying}
          eventDateInfo={eventDateInfo}
        />
        <ChangeDaySchedule // RIGHT PART
          setIsWorking={setIsWorking}
          role={userInfos.selectDePersonnelInfo.persons[chosenPerson.role]!}
          personNumber={chosenPerson.nb}
          eventDateInfo={eventDateInfo}
          copying={copying}
          setNewHours={setNewHours}
          changeRotation={changeRotation}
          onChangeBreakTime={changeBreaktime}
        />
      </Flex>
      <Flex
        mt="1rem"
        gap="1rem" // COPY PASTE
      >
        {copying ? (
          <>
            <NorelaButton isDisabled={!copying} variant="ghost" onClick={paste}>
              Coller
            </NorelaButton>
            <Button variant="ghost" onClick={toggleCopying}>
              Annuler
            </Button>
          </>
        ) : (
          <NorelaButton onClick={toggleCopying}>
            {copying ? "Annuler" : "Copier les horaires"}
          </NorelaButton>
        )}
      </Flex>
    </Box>
  );
}
