import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useSessionContext } from "../../context/SessionContext";
import { useStepContext } from "../../context/StepContext";
import { useResetOrder } from "../../hooks/useResetOrder";
import { PageURL } from "../../types/pages";
import NorelaButton from "../Buttons/NorelaButton";

const STEPS = [
  "Votre événement",
  "Définition de votre besoin",
  "Configuration de votre événement",
  "Dressing",
  "Options",
  "Récapitulatif",
  "Paiement",
];

function Step({
  isActive,
  isDone,
  text,
  index,
}: {
  text: string;
  isDone: boolean;
  isActive: boolean;
  index: number;
}) {
  let colorFirstSeparator = "#a6a6a6";
  let colorSecondSeparator = "#a6a6a6";

  if (isActive) {
    colorFirstSeparator = "#29B558";
    colorSecondSeparator = "#a6a6a6";
  } else if (isDone) {
    colorFirstSeparator = "#29B558";
    colorSecondSeparator = "#29B558";
  }

  if (index === 0) {
    colorFirstSeparator = "transparent";
  }

  if (index === STEPS.length - 1) {
    colorSecondSeparator = "transparent";
  }

  return (
    <Box flexGrow={1} alignSelf="center">
      <Flex>
        <Box width="100%" height="2px" bg={colorFirstSeparator} my="auto" />
        {isDone && (
          <FaCheckCircle
            style={{ height: 32, width: 32, minHeight: 32, minWidth: 32 }}
            color="#29B558"
          />
        )}
        {isActive && (
          <Flex
            width="32px"
            minW="32px"
            minH="32px"
            height="32px"
            border="solid 2px #F7B80B"
            borderRadius="32px"
          >
            <Box h="10px" w="10px" borderRadius="10px" bg="#F7B80B" m="auto" />
          </Flex>
        )}
        {!isActive && !isDone && (
          <Flex
            width="32px"
            minW="32px"
            minH="32px"
            height="32px"
            border="solid 2px #A6A6A6"
            borderRadius="32px"
          >
            <Text fontSize="14px" m="auto" color="#A6A6A6" fontWeight="bold">
              {index + 1}
            </Text>
          </Flex>
        )}
        <Box width="100%" height="2px" bg={colorSecondSeparator} my="auto" />
      </Flex>
      <Text px="0.25rem" fontSize="15px">
        {/* we may need to reduce fontsize if we add more content to the progressBar */}
        {text}
      </Text>
    </Box>
  );
}

export default function ProgressBar() {
  const { step } = useStepContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { sessionInfo, setReset } = useSessionContext();
  const ref = useRef(null);
  const { completeResetOrder } = useResetOrder();
  const navigate = useNavigate();
  const timer = () => {
    navigate(PageURL.HOME);
    setReset(false);
  };
  function onResetOrder() {
    completeResetOrder();
    onClose();
    setTimeout(timer, 500);
  }
  function continueOrder() {
    setReset(false);
    onClose();
  }
  useEffect(() => {
    // catch reload
    const handleBeforeUnload = () => {
      localStorage.setItem("reset", "true");
    };
    const handleLoad = () => {
      if (sessionInfo?.reset) {
        onOpen();
      }
      localStorage.setItem("reset", "false");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("load", handleLoad);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("load", handleLoad);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (step < 0 || step > STEPS.length) return <></>;
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={ref}
        onClose={continueOrder}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            Voulez-vous continuer votre commande ?
          </AlertDialogHeader>
          <AlertDialogCloseButton />

          <AlertDialogFooter>
            <Button color="red" backgroundColor="white" onClick={onResetOrder}>
              Réinitialiser la commande
            </Button>
            <NorelaButton onClick={continueOrder}>Continuer</NorelaButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Flex flexGrow={1} maxW="1000px" mr="auto" mt="auto">
        {STEPS.map((text, index) => (
          <Step
            key={text}
            text={text}
            isActive={index === step}
            isDone={index < step}
            index={index}
          />
        ))}
      </Flex>
    </>
  );
}
