import { PlacementWithLogical, Text, Tooltip } from "@chakra-ui/react";
import { FC, ReactElement } from "react";
import { colors } from "../ui/theme";

interface CustomTooltipProps {
  placement?: PlacementWithLogical;
  content?: string[];
  children?: ReactElement;
  underline?: boolean;
  padding?: string;
  fontSize?: string;
  color?: string;
}

export const setMealTooltipContent = (
  lunch: number,
  dinner: number
): string[] => {
  const price = lunch * 15 + dinner * 25;
  const output = " : " + price.toString() + "€";

  return lunch === 0 && dinner === 0
    ? [""]
    : lunch === dinner
    ? [dinner + " Déjeuner & dîner" + output]
    : dinner === 0
    ? [lunch + " Déjeuner" + output]
    : lunch === 0
    ? [dinner + " Dîner" + output]
    : [lunch + " Déjeuner + " + dinner + " dîner" + output];
};
export const setBreaktimeTooltipContent = (missionShift: number): string[] => {
  return missionShift < 6
    ? ["Indisponible - le creneau est trop court pour une pause"]
    : missionShift === 6
    ? ["Une pause de 30 mins est nécessaire"]
    : missionShift >= 14
    ? ["Indisponible - une rotation est nécessaire"]
    : missionShift >= 12
    ? ["deux pauses d'1h sont nécessaires"]
    : ["Une pause de 1h est nécessaire"];
};

export const setRotationTooltipContent = (missionShift: number): string[] => {
  return missionShift < 6
    ? [
        "Indisponible - le creneau est trop court pour une rotation de personnel",
      ]
    : missionShift <= 12
    ? [
        "2 personnes seront appelées pour se relayer et couvrir les heures de pause",
      ]
    : missionShift < 14
    ? [
        "2 personnes seront appelées pour se relayer et ainsi réduire les heures de pause",
      ]
    : [
        "2 personnes seront appelées pour se relayer et couvrir l'ensemble de l'evenement",
      ];
};

const CustomTooltip: FC<CustomTooltipProps> = ({
  placement,
  content,
  children,
  underline,
  padding,
  fontSize,
  color,
}): ReactElement => {
  if (content?.length === 0) return <>{children}</>;

  const tooltipTextStyle = {
    fontSize: fontSize ?? "16px",
    color: color ?? "black",
  };
  return (
    <Tooltip
      backgroundColor={"#fff"}
      width="auto"
      placement={placement ?? "left-end"}
      border={`1px solid ${colors.secondaryFontColor.lightMode}`}
      borderRadius={"16px"}
      padding={padding ?? "2rem"}
      label={
        content
          ? content.map((item, index) => (
              <Text
                key={index}
                sx={tooltipTextStyle}
                textDecoration={underline ? "underline" : ""}
              >
                {item}
              </Text>
            ))
          : ""
      }
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
