import { Flex, Input, Text, theme } from "@chakra-ui/react";
import { useRef, useState } from "react";
import {
  setAdviceOnHover,
  useAdviceContext,
} from "../../../../context/AdviceContext";
import { colors } from "../../../../ui/theme";
import NorelaButton from "../../../Buttons/NorelaButton";
import PicturesVariant, {
  Icons,
} from "../../../PicturesVariant/PicturesVariant";
import { useOnboardingContext } from "../../../../context/OnboardingContext";

export default function SimpleCard({
  icon,
  title,
  tooltipText,
  isSelected,
  onChoose,
  disabled,
  isInputText,
}: {
  title: string;
  icon: Icons;
  tooltipText?: string;
  isSelected: boolean;
  onChoose: () => void;
  disabled?: boolean;
  isInputText?: boolean;
}) {
  const inputTextRef = useRef<any>(null);
  const [customType, setCustomType] = useState<string>("");
  const { setEventCustomSubcategory } = useOnboardingContext();

  const onChangeCustomType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomType(event.target.value);
  };
  const handleBriefClick = () => {
    if (inputTextRef.current) {
      inputTextRef.current.focus();
    }
  };

  const { setAdvice } = useAdviceContext();
  if (disabled) {
    tooltipText += " Prochainement disponible";
  }
  const backgroundColor = disabled ? "#DDD" : "transparent";
  return (
    <Flex
      flexDirection="column"
      borderRadius="20px"
      alignItems="center"
      padding="12px"
      gap="14px"
      width="200px"
      height="200px"
      _hover={{
        border: `1px solid ${colors.secondaryFontColor.lightMode}`,
        backgroundColor: backgroundColor,
      }}
      onClick={
        disabled
          ? () => {}
          : isInputText
          ? () => handleBriefClick()
          : () => {
              setEventCustomSubcategory("");
              onChoose();
            }
      }
      cursor={disabled ? "not-allowed" : "pointer"}
      {...(tooltipText && setAdviceOnHover(tooltipText, setAdvice))}
    >
      <PicturesVariant icon={icon} />
      <Text
        variant="xl"
        textAlign="center"
        color={isSelected ? colors.white : theme.colors.blue["900"]}
      >
        {title}
      </Text>
      {(isInputText ?? false) === true && (
        <>
          <Input
            ref={inputTextRef}
            minHeight="40px"
            value={customType}
            fontSize="12px"
            onChange={onChangeCustomType}
            placeholder="Ex: un séminaire d’entreprise."
            _focusVisible={{
              borderColor: colors.secondaryFontColor.lightMode,
              boxShadow: `0 0 0 1px ${colors.secondaryFontColor.lightMode}`,
            }}
            errorBorderColor={colors.red.default}
            focusBorderColor={colors.blue.default}
          />
          <NorelaButton
            onClick={() => {
              setEventCustomSubcategory(customType ?? "");
              onChoose();
            }}
            h="25px"
            m="0.5rem"
            disabled={(customType ?? "") === ""} // handles undefined and ""
          >
            Valider
          </NorelaButton>
        </>
      )}
    </Flex>
  );
}
