import { Icons } from "../components/PicturesVariant/PicturesVariant";

export enum OrderFields {
  COMPANY_NAME = "companyName",
  TEXT = "text",
  TEXTAREA = "textarea",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  EMAIL = "email",
  SIREN = "siren",
  PHONE = "phone",
  PHONE_OWNER = "telOwner",
  PASSWORD = "password",
  ADDRESS = "address",
  PAYMENT_TYPE = "paymentType",
  CREDIT_CARD_NUMBER = "creditCardNumber",
  CREDIT_CARD_CVV = "creditCardCVV",
  CREDIT_CARD_DATE = "creditCardDate",
  CREDIT_CARD_OWNER_NAME = "creditCardOwnerName",
}

export const enum OrderPaymentTypes {
  PAYMENT_CARD = "paymentCard",
  PAYMENT_P2P = "2p2",
}
export interface OrderFormProps {
  defaultIndex: number;
  titleOfSection: string;
  nameOfTab: string;
  description?: string;
  fields: OrderFormFieldProps[];
  stripe?: boolean;
  buttonText: string;
  checkBox?: OrderFormFieldProps;
  typePayment?: boolean;
}

export interface OrderFormFieldProps {
  type: "text" | "email" | "password" | "tel" | "checkbox";
  name: OrderFields | OrderPaymentTypes;
  errors?: any;
  formatNumberSpace?: number;
  title: string;
  id: OrderFields;
  maxLength?: any;
  mask?: Array<string | RegExp>;
  placeholder?: string;
  tooltipInfo?: {
    description: string;
    icon?: Icons;
  };
  inputAddonLeft?: Icons;
  icon?: Icons;
  helperText?: string;
  handleChange?: any;
  setTouched?: any;
  values?: any;
  inputHeight?: string;
  touched?: any;
  linkText?: string;
  stripe?: boolean;
  validateField?: any;
  failedValidation?: boolean;
}

export interface CheckboxWrapperProps {
  handleChange: any;
  name: string;
  title: string;
  linkText?: string;
}
