export const OrientationIcon = () => (
  <svg
    width="181"
    height="120"
    viewBox="0 0 181 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2336_52804)">
      <path
        d="M37.7299 94.9278C37.4994 94.1251 37.1047 93.4404 36.6347 92.952L34.9271 85.168L31.2051 86.0798L33.338 93.8778C33.1977 94.5375 33.2258 95.3252 33.4563 96.1279C33.9829 97.9617 35.3665 99.1796 36.5467 98.8482C37.7268 98.5168 38.2566 96.7616 37.7299 94.9278H37.7299Z"
        fill="#FFB6B6"
      />
      <path
        d="M37.2141 43.8665C37.2141 43.8665 33.194 43.7277 30.8643 46.3487C28.1952 49.6092 26.3707 62.3849 27.7403 63.2452L31.4512 89.5956L36.7427 88.8685L35.3847 57.2519L37.2141 43.8665V43.8665Z"
        fill="#F7B80B"
      />
      <path
        d="M64.3531 88.2832L63.39 120.444H49.5574L48.9983 108.047L46.5404 120.444H31.7148L33.564 99.133L35.4744 94.6512L36.7674 91.6139L64.3531 88.2832Z"
        fill="#1A202C"
      />
      <path
        d="M53.3662 38.6137L43.7562 38.0312L40.5528 42.1082C37.7862 42.7006 35.2874 43.4653 33.5638 44.7291C33.9761 56.9385 33.0703 72.8141 36.7671 75.8888L33.4844 99.0584C33.4844 99.0584 34.8082 97.9242 37.0196 98.3247C37.7836 98.463 38.7058 100.184 39.6446 100.288C46.8578 101.09 58.5551 100.266 64.3528 88.2836L62.1025 70.9382L62.685 45.6028C60.8642 44.1635 58.6005 43.2282 55.9871 42.6906L53.3662 38.6137V38.6137H53.3662Z"
        fill="#F7B80B"
      />
      <path
        d="M71.866 31.5262C71.9703 32.5358 71.8379 33.4852 71.5329 34.2465L73.1338 43.8017L68.5647 44.4304L67.4328 34.6699C66.9787 33.9871 66.6551 33.0847 66.5508 32.0751C66.3126 29.7686 67.3093 27.776 68.7771 27.6244C70.2448 27.4728 71.6278 29.2197 71.866 31.5262V31.5262H71.866H71.866Z"
        fill="#FFB6B6"
      />
      <path
        d="M56.6763 46.3323C56.1979 47.0382 56.3312 47.8316 56.217 48.7848C55.7321 52.8316 63.4869 64.7898 68.8528 66.1667C69.5569 66.3474 69.958 67.1069 70.6687 67.2596H70.6687C72.774 67.7121 74.7342 66.0411 74.6206 63.8907L73.163 36.3105L67.0397 38.3991L67.4316 52.7182C67.3926 49.7518 64.6687 46.7313 62.6055 45.475C62.6055 45.475 58.4263 43.7504 56.6763 46.3323L56.6763 46.3323Z"
        fill="#F7B80B"
      />
      <path
        d="M49.5586 37.2774C54.2336 37.2774 58.0235 33.4875 58.0235 28.8125C58.0235 24.1375 54.2336 20.3477 49.5586 20.3477C44.8836 20.3477 41.0938 24.1375 41.0938 28.8125C41.0938 33.4875 44.8836 37.2774 49.5586 37.2774Z"
        fill="#FFB6B6"
      />
      <path
        d="M57.4437 22.597C57.3709 22.6145 58.558 21.4575 58.6086 21.4322C59.7734 20.8498 58.5765 26.543 57.7349 26.3828C55.6644 25.9897 54.7704 25.2092 53.9666 27.1574C53.7424 27.6991 53.6026 28.3106 53.1658 28.6979C52.5659 29.225 51.57 29.1813 51.0807 29.8104C50.6876 30.3171 50.8012 31.0626 51.0924 31.6333C51.3836 32.207 51.8262 32.6934 52.0912 33.2787C52.3883 33.9368 51.1215 36.0306 50.6381 37.2071V37.2101L50.6352 37.2169C50.5068 37.5255 50.2015 36.6366 49.8792 36.5478C49.4936 36.4417 48.9255 37.3522 48.3113 37.1868C48.3084 37.1868 48.3055 37.1838 48.3026 37.1838C47.7172 37.0208 47.0649 36.846 46.4184 36.6713H46.4155C44.5954 36.1763 44.1353 36.8868 44.048 36.8664L42.9181 35.6841C42.5303 35.4018 42.5741 34.4903 42.3007 34.2455C42.0771 34.0453 41.5196 34.5079 41.3553 34.3155C40.5497 33.372 40.3643 32.4281 39.8894 30.5646C39.4555 28.861 38.5557 23.7619 40.4049 23.5318C42.1492 23.3134 40.8737 20.8847 42.6298 20.975C42.5249 20.5323 42.72 20.0577 43.0375 19.7315C43.352 19.4024 43.7713 19.1986 44.1936 19.0239C46.2757 18.1735 45.9292 17.4426 48.1249 17.9377C48.3433 17.6319 51.144 16.8347 51.5401 16.9367C51.5692 16.9425 50.9293 18.1852 52.4931 17.9377C52.4349 18.2959 53.2874 17.5191 53.3667 18.8113C55.0346 17.8103 55.9323 20.2062 55.9876 20.5586C56.0604 20.9983 55.3965 20.7741 55.8391 20.841C56.1012 20.8818 56.5497 21.4147 56.506 21.6797C56.6953 21.4235 56.8846 21.1701 57.0768 20.9138C57.1117 20.9167 57.1437 20.9226 57.1787 20.9284C58.064 21.0973 57.0491 23.9913 57.0855 23.0892C57.1525 21.4322 58.3261 22.4019 57.4437 22.597H57.4437Z"
        fill="#1A202C"
      />
      <path
        d="M131.932 20.8609C131.62 23.4316 131.278 25.9987 130.907 28.5616C130.757 29.6025 130.598 30.6567 130.179 31.6215C129.893 32.2801 129.493 32.8814 129.094 33.4788C128.624 34.1847 128.145 34.8996 127.506 35.4577C126.867 36.0157 126.037 36.4075 125.191 36.3419C127.436 34.8312 128.944 32.2761 129.182 29.5813C129.294 28.3146 129.141 27.0414 129.111 25.77C129.082 24.4987 129.188 23.1832 129.775 22.0549C130.361 20.9266 131.52 20.0234 132.791 20.0555L131.932 20.8609H131.932Z"
        fill="#1A202C"
      />
      <path
        d="M109.188 75.6883C107.362 77.1693 105.117 77.4279 104.175 76.2659C103.232 75.1038 103.949 72.9612 105.775 71.4802C106.574 70.8319 107.454 70.4179 108.269 70.2547L116.085 64.0762L118.906 67.8059L110.902 73.5008C110.574 74.2649 109.987 75.04 109.188 75.6883H109.188L109.188 75.6883L109.188 75.6883Z"
        fill="#A0616A"
      />
      <path
        d="M110.664 67.3005L122.311 56.2495L123.475 55.3759C123.475 55.3759 130.161 48.8625 131.892 48.7134C132.625 48.6503 133.359 48.8331 133.976 49.2344C134.974 49.8815 135.491 50.9467 135.491 52.0202C135.491 52.9173 135.13 53.8187 134.39 54.4826L123.728 64.0545L119.192 68.1261L113.551 73.1915L112.977 72.0234L111.038 68.0652L110.664 67.3005V67.3005L110.664 67.3005H110.664Z"
        fill="#F7B80B"
      />
      <path
        d="M135.567 59.8223L123.906 63.3939C125.859 63.9438 126.676 75.8401 124.859 76.2097C124.859 76.2097 144.185 77.5842 143.131 76.2097C141.845 74.534 143.569 63.3987 144.391 63.3939L135.567 59.8223Z"
        fill="#A0616A"
      />
      <path
        d="M151.085 120.445L150.065 108.144C150.065 103.744 149.78 99.7687 149.323 96.2247C147.491 81.999 142.899 74.707 142.899 74.707H125.717C125.694 74.7332 125.671 74.7536 125.647 74.7798L125.644 74.7827C121.591 79.0432 119.482 89.7569 118.545 96.2247C118.099 99.3028 117.919 101.42 117.919 101.42L116.238 116.353L117.453 120.445H127.45L127.293 119.568L133.726 96.2247L133.804 95.9393L133.874 96.2247L139.838 120.445H151.085V120.445Z"
        fill="#1A202C"
      />
      <path
        d="M126.07 52.7255L129.701 45.3965L132.76 40.0719L135.234 37.5508H142.004L142.635 40.0719L146.416 42.1729L147.754 52.6776L142.988 75.4693C137.316 70.8472 125.624 69.5671 125.624 69.5671L123.73 64.0542L122.477 57.4131L122.326 56.619L126.07 52.7255V52.7255V52.7255Z"
        fill="#F7B80B"
      />
      <path
        d="M125.634 65.9832C123.284 65.9165 121.413 64.65 121.456 63.1544C121.498 61.6589 123.438 60.5005 125.788 60.5672C126.817 60.5964 127.754 60.8555 128.478 61.2631L138.433 61.6706L138.143 66.3381L128.36 65.441C127.613 65.8068 126.663 66.0124 125.634 65.9832L125.634 65.9832V65.9832Z"
        fill="#A0616A"
      />
      <path
        d="M138.365 50.0513L147.756 59.3742L131.399 61.0492L127.598 66.3959L150.396 67.1519C152.453 67.8844 154.644 66.4628 154.814 64.2859V64.2859C154.871 63.5508 150.187 44.8993 147.351 43.1677C144.028 41.1383 138.365 50.0513 138.365 50.0513L140.22 46.9635L138.365 50.0513Z"
        fill="#F7B80B"
      />
      <path
        d="M144.888 28.404C144.957 24.1694 141.579 20.6812 137.344 20.6129C133.11 20.5446 129.622 23.9221 129.553 28.1567C129.485 32.3914 132.863 35.8796 137.097 35.9479C141.332 36.0161 144.82 32.6386 144.888 28.404Z"
        fill="#A0616A"
      />
      <path
        d="M154.054 52.1718C153.862 52.8998 152.365 53.2755 152.016 54.2103C150.903 54.8451 151.506 49.6586 151.058 49.3179C150.711 49.0588 150.618 49.6848 150.539 49.1461C150.242 47.1251 148.3 47.5765 146.861 47.2503C146.698 46.9329 146.573 46.6242 146.491 46.3243C146.346 45.7826 145.682 45.5933 145.289 45.9923C145.111 45.835 144.983 45.2788 144.858 44.7226C144.767 44.3178 144.677 43.913 144.572 43.6568L143.571 41.397L143.894 43.0802C143.239 42.8443 142.578 43.0889 142.196 42.2793C141.707 41.2397 141.512 40.0224 141.867 38.9304C142.19 37.9403 142.735 35.9571 143.349 34.3147C142.653 34.8185 141.817 35.1854 140.848 35.3689C141.191 34.7166 141.541 34.0526 141.686 33.3275C141.829 32.6024 141.742 31.7957 141.282 31.2191C140.903 30.7444 140.344 30.8638 139.872 30.4823C139.505 30.1853 139.188 29.4252 138.967 29.0088C139.91 32.1452 140.624 35.4766 139.424 38.4703C138.594 40.5437 136.846 42.2298 134.75 42.9957C135.865 42.1978 136.954 41.3474 137.79 40.2641C138.684 39.1022 139.266 37.6345 139.089 36.1784C138.809 33.8924 134.569 26.6616 134.048 25.2405C134.333 26.6296 134.223 27.7129 133.471 28.3099C133.276 26.2947 131.081 26.4782 129.884 25.6715C129.884 25.6715 128.958 20.4384 131.13 20.4064C132.178 20.3918 133.276 17.9282 134.229 17.4884C135.749 16.7866 136.681 17.2642 138.367 17.3166C140.05 17.3661 141.666 18.0913 142.694 19.4221C143.233 20.1181 143.431 19.5648 144.24 19.9987C145.009 20.4093 145.528 20.0278 146.398 20.054C148.143 20.1006 149.835 21.0325 150.813 22.474C151.791 23.9184 152.394 23.7844 152.007 26.0181C152.001 26.0443 152.001 26.0967 152.007 26.1753C152.22 29.6932 151.675 33.2197 150.28 36.458C149.849 37.4539 151.957 38.4819 151.954 39.7254C151.951 41.1698 149.625 42.4861 149.84 43.1035C150.458 46.0767 153.926 45.4157 153.108 50.3925C153.041 50.7973 154.182 51.6855 154.054 52.1718Z"
        fill="#1A202C"
      />
      <path
        d="M132.809 43.0662C132.874 43.0458 132.938 43.0224 133.002 42.9961C132.92 43.0545 132.842 43.11 132.76 43.1685L132.809 43.0662H132.809Z"
        fill="#1A202C"
      />
      <path
        d="M143.61 18.5328C142.776 18.6568 141.928 17.2728 142.402 15.3535C143.434 16.9318 143.962 18.7085 144.045 20.66L143.488 20.8324L143.61 18.5328Z"
        fill="#F7B80B"
      />
      <path
        d="M147.873 16.7402C147.304 18.7574 144.413 20.1073 143.902 19.4053L143.844 20.6082L143.486 20.1485C144.523 18.6067 145.936 17.4239 147.873 16.7402H147.873Z"
        fill="#F7B80B"
      />
    </g>
    <defs>
      <clipPath id="clip0_2336_52804">
        <rect
          width="180"
          height="120"
          fill="white"
          transform="translate(0.888672)"
        />
      </clipPath>
    </defs>
  </svg>
);
