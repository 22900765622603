import { Flex, Select, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import BottomBlock from "../../components/BottomActionsBlock/BottomBlock";
import Header from "../../components/Header";
import { GroupOfPeopleIcon } from "../../components/Icons/GroupOfPeopleIcon";
import { OkIcon } from "../../components/Icons/OkIcon";
import Layout from "../../components/Layout";
import ContentLayout from "../../components/Layout/ContentLayout";
import GrayLayout from "../../components/Layout/GrayLayout";
import MainContainer from "../../components/Layout/MainContainer";
import TotalCard from "../../components/TotalCard/TotalCard";
import { useOnboardingContext } from "../../context/OnboardingContext";
import { useOptionContext } from "../../context/OptionsContext";
import { useSessionContext } from "../../context/SessionContext";
import { useUserContext } from "../../context/UserContext";
import { ONBOARDING_MULTICHOICE_CONFIGS } from "../../modules/Onboarding/onboardingSizeConfigs";
import { PageURL } from "../../types/pages";
import { colors } from "../../ui/theme";
import { useSegment } from "../../context/SegmentContext";

export default function OnboardingSize() {
  const {
    eventSubcategory,
    getAllInfo,
    getAllSetters,
    salonSize,
    nbParticipants,
  } = useOnboardingContext();
  const navigate = useNavigate();
  const { updateHistory } = useSessionContext();

  const config = useMemo(() => {
    if (!eventSubcategory) {
      updateHistory(PageURL.ONBOARDING);
      navigate(PageURL.ONBOARDING);
      return;
    }
    return ONBOARDING_MULTICHOICE_CONFIGS.find(
      (a) => a.id === eventSubcategory
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventSubcategory]);
  const { track } = useSegment();
  const { userInfos, setUserInfos } = useUserContext();
  const { optionInfo, setOptionInfo } = useOptionContext();

  const value = config?.getOptionValue(getAllInfo());

  return (
    <Layout>
      <ContentLayout>
        <MainContainer>
          <Flex flexDir="column">
            <Header
              title={config?.title ?? ""}
              subtitle={config?.subtitle ?? ""}
            />
            <GrayLayout display="flex" mr="auto">
              <GroupOfPeopleIcon />
              <Flex
                direction="column"
                gap="23px"
                alignItems="center"
                border={`1px solid ${colors.black}`}
                borderRadius="20px"
                padding="19px"
                m="auto 50px"
              >
                <Select
                  onChange={(e) =>
                    config?.setOptionValue(e.target.value, getAllSetters())
                  }
                  className="select-roooort"
                  placeholder="Sélectionner"
                  value={value ?? ""}
                  icon={<OkIcon />}
                  _focusVisible={{
                    boxShadow: "none",
                  }}
                  sx={{
                    border: "none !important",
                    padding: "0 40px 0 30px",
                  }}
                  fontWeight="700"
                  fontSize="20px"
                  lineHeight="24px"
                  textAlign="center"
                >
                  {config?.options.map((option) => (
                    <option key={option}>{option}</option>
                  ))}
                </Select>
                <Text
                  fontWeight={700}
                  variant="sm"
                  textTransform="uppercase"
                  color={colors.gray.darkMode}
                >
                  {config?.question}
                </Text>
              </Flex>
            </GrayLayout>
          </Flex>
          <BottomBlock
            disableNextPage={!value}
            onNextPage={() => {
              if (salonSize || nbParticipants)
                track("Event size", {
                  selection: salonSize
                    ? salonSize + "m²"
                    : nbParticipants
                    ? nbParticipants + "ppl estimated"
                    : "unknown (error)",
                });
              if (userInfos) {
                const page = config?.onNextPage(
                  getAllInfo(),
                  userInfos,
                  setUserInfos,
                  optionInfo,
                  setOptionInfo
                );
                if (page) {
                  updateHistory(page as PageURL);
                  navigate(page as PageURL);
                }
              }
            }}
          />
          <TotalCard />
        </MainContainer>
      </ContentLayout>
    </Layout>
  );
}
