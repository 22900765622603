import { BoxProps, Button, Flex, Heading, Text } from "@chakra-ui/react";
import {
  setAdviceOnHover,
  useAdviceContext,
} from "../../../../context/AdviceContext";
import { colors } from "../../../../ui/theme";
import PicturesVariant, {
  Icons,
} from "../../../PicturesVariant/PicturesVariant";

export type HoverCardElement<T> = {
  title: string;
  tooltipText?: string;
  value: T;
};

export default function HoverCard<T>({
  onChoose,
  icon,
  subtitle,
  title,
  elements,
  ...props
}: {
  onChoose: (value: HoverCardElement<T>) => void;
  title?: string;
  icon: Icons;
  subtitle: string;
  elements: HoverCardElement<T>[];
} & BoxProps) {
  const { setAdvice } = useAdviceContext();

  return (
    <Flex
      w="200px"
      backgroundColor="transparent"
      justifyContent="center"
      _hover={{
        "& .newHover": {
          top: 0,
        },
      }}
      overflow="hidden"
      position="relative"
      {...props}
    >
      <Flex direction="column" alignItems="center" padding="12px" gap="1rem">
        {title && <Heading size="sm">{title}</Heading>}
        <PicturesVariant icon={icon} />
        <Text variant="xl" textAlign="center">
          {subtitle}
        </Text>
      </Flex>

      <Flex
        transition="0.3s"
        className="newHover"
        top="-150%"
        position="absolute"
        backgroundColor="white"
        height="100%"
        width="100%"
        direction="column"
        alignItems="center"
      >
        {elements.map((element) => {
          return (
            <Button
              key={element.title}
              {...(element.tooltipText &&
                setAdviceOnHover(element.tooltipText, setAdvice))}
              margin="8px 5px"
              transition="0.5s"
              _hover={{
                backgroundColor: colors.secondaryFontColor.lightMode,
                "& p": {
                  color: colors.white,
                },
              }}
              width="90%"
              whiteSpace="break-spaces"
              height="fit-content"
              border="1px solid #033349"
              borderRadius="8px"
              padding="8px 12px"
              backgroundColor="transparent"
              onClick={() => onChoose(element)}
            >
              <Text variant="sm">{element.title}</Text>
            </Button>
          );
        })}
      </Flex>
    </Flex>
  );
}
