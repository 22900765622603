import { useQuery } from "@apollo/client";
import { options } from "../apollo/calendar/mutations";

const useOptionsData = (optionName: string) => {
  const { loading, error, data } = useQuery(options, {
    variables: { name: optionName },
  });

  return {
    data,
    error,
    loading,
  };
};

export default useOptionsData;
