export const DressingCardPopupIcon = () => (
  <svg width="57" height="190" viewBox="0 0 57 190" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.9989 184.198H22.7816L20.2988 143.627H27.9989V184.198Z" fill="#A0616A"/>
    <path d="M19.0556 182.705H29.1171V189.043H12.7207C12.7207 188.21 12.8846 187.386 13.2029 186.617C13.5213 185.848 13.9879 185.15 14.5761 184.561C15.1644 183.973 15.8628 183.506 16.6314 183.188C17.4 182.869 18.2237 182.705 19.0556 182.705Z" fill="#2F2E41"/>
    <path d="M54.027 178.708L49.2339 180.769L28.6621 142.555L35.7365 139.514L54.027 178.708Z" fill="#A0616A"/>
    <path d="M45.2216 180.871L54.4655 176.896L56.9668 182.719L41.9027 189.196C41.5742 188.431 41.3995 187.609 41.3885 186.777C41.3775 185.945 41.5305 185.119 41.8387 184.346C42.1469 183.572 42.6043 182.868 43.1847 182.272C43.7651 181.675 44.4573 181.199 45.2216 180.871Z" fill="#2F2E41"/>
    <path d="M54.7933 143.688C54.7933 143.688 44.4524 90.9388 42.822 86.4926C41.924 84.0626 41.3323 82.2949 41.3323 82.2949L10.9426 84.0336L10.6943 86.4926L6.59766 148.184C6.59766 148.184 8.04597 144.263 9.34531 143.543C10.6447 142.822 10.5081 148.175 10.5081 148.175" fill="#2F2E41"/>
    <path d="M11.1758 84.013H37.7747C37.028 81.3392 37.7747 78.861 38.8418 76.9598C39.5876 75.624 40.5259 74.4055 41.6267 73.3434L41.8093 73.1799L51.1029 71.1184L51.6028 71.0094C51.6028 71.0094 51.6765 54.1169 45.6998 51.7989C42.4162 50.5426 39.3309 48.8187 36.5394 46.6807C36.2646 46.4638 35.9278 46.3404 35.578 46.3283L22.5314 45.8506C22.1302 45.837 21.7377 45.969 21.4261 46.2222C19.2692 47.9573 12.4099 51.4404 8.72182 53.2625C8.07406 53.5819 7.57421 54.1388 7.32614 54.8173C7.07807 55.4959 7.10084 56.2439 7.38971 56.9061C8.86249 60.2892 11.3649 65.9471 11.6277 65.9471C12.0027 65.9471 11.1758 84.013 11.1758 84.013Z" fill="#F7B80B"/>
    <path d="M29.209 56.6179L41.7093 88.9557C41.7093 88.9557 33.258 90.9052 25.1067 80.9385L22.165 74.515L15.5371 50.0332L29.209 56.6179Z" fill="#F6F8FB"/>
    <path d="M32.5274 39.3035C41.8975 39.3035 49.4934 31.7044 49.4934 22.3305C49.4934 12.9565 41.8975 5.35742 32.5274 5.35742C23.1574 5.35742 15.5615 12.9565 15.5615 22.3305C15.5615 31.7044 23.1574 39.3035 32.5274 39.3035Z" fill="#2F2E41"/>
    <path d="M21.7656 79.2004C22.2299 79.7823 22.8692 80.199 23.5888 80.3889C24.3084 80.5788 25.07 80.5318 25.7608 80.2548L34.8498 83.8131L37.8781 76.9633L27.6369 75.6402C27.4381 75.1897 27.1455 74.7869 26.7787 74.4585C26.4119 74.1302 25.9794 73.8839 25.5099 73.736C25.0404 73.5881 24.5448 73.5421 24.0562 73.6011C23.5675 73.66 23.097 73.8225 22.6761 74.0777C22.2552 74.333 21.8935 74.6751 21.6152 75.0812C21.3369 75.4873 21.1484 75.9482 21.0622 76.433C20.9761 76.9178 20.9942 77.4154 21.1155 77.8926C21.2368 78.3698 21.4584 78.8157 21.7656 79.2004Z" fill="#A0616A"/>
    <path d="M29.9554 43.3944C35.9519 43.3944 40.813 38.5312 40.813 32.5322C40.813 26.5331 35.9519 21.6699 29.9554 21.6699C23.9588 21.6699 19.0977 26.5331 19.0977 32.5322C19.0977 38.5312 23.9588 43.3944 29.9554 43.3944Z" fill="#A0616A"/>
    <path d="M45.2239 25.339C39.4499 28.4841 32.9508 30.055 26.3784 29.8943C23.8611 29.829 21.0874 29.3709 19.4932 27.4208C17.6943 25.2204 18.1445 21.7824 19.8797 19.5314C21.6149 17.2805 24.3296 16.0115 27.0377 15.1509C29.5999 14.3367 32.3455 13.8123 34.973 14.3815C36.3597 14.6819 37.6654 15.2775 38.8014 16.1278C39.9374 16.9781 40.8771 18.0632 41.5565 19.3093C42.2359 20.5553 42.6391 21.9332 42.7387 23.349C42.8383 24.7649 42.6319 26.1856 42.1336 27.5146" fill="#2F2E41"/>
    <path d="M44.5282 19.8457C49.7846 19.8457 54.0457 15.5828 54.0457 10.3242C54.0457 5.06564 49.7846 0.802734 44.5282 0.802734C39.2719 0.802734 35.0107 5.06564 35.0107 10.3242C35.0107 15.5828 39.2719 19.8457 44.5282 19.8457Z" fill="#2F2E41"/>
    <path d="M47.9365 66.5215L51.5995 71.0099L52.3462 83.1928C52.3462 83.1928 53.3076 89.3965 46.2188 87.9024C39.1301 86.4084 30.0068 83.5031 30.0068 83.5031C30.0068 83.5031 34.8615 76.4634 32.6951 74.4468L42.8571 77.7361C42.8571 77.7361 44.5748 77.361 43.2288 75.867C41.8829 74.373 41.6457 71.68 41.6457 71.68L47.9365 66.5215Z" fill="#F7B80B"/>
    <path d="M29.1274 77.0716C28.4219 77.3084 27.6588 77.3115 26.9514 77.0804C26.2439 76.8494 25.6296 76.3965 25.1996 75.7889L15.6348 73.845L16.8054 66.4473L26.126 70.8942C26.5373 70.6238 27.0015 70.4442 27.4877 70.3674C27.9739 70.2906 28.4708 70.3185 28.9454 70.4491C29.42 70.5797 29.8612 70.81 30.2398 71.1247C30.6183 71.4395 30.9254 71.8313 31.1406 72.2742C31.3558 72.717 31.4741 73.2007 31.4876 73.6929C31.5011 74.1851 31.4096 74.6746 31.2191 75.1286C31.0285 75.5826 30.7434 75.9908 30.3827 76.3258C30.0221 76.6609 29.5941 76.9151 29.1274 77.0716Z" fill="#A0616A"/>
    <path d="M16.5044 59.9645C16.5044 59.9645 14.8448 62.0981 12.9039 62.6226C10.9631 63.1471 12.2026 64.3944 12.2026 64.3944L22.5229 67.1466C19.6095 67.6649 19.8699 76.2134 19.8699 76.2134C19.8699 76.2134 10.6306 73.7024 3.86551 71.11C-2.89955 68.5176 1.27229 63.8281 1.27229 63.8281L8.50622 53.9992L14.0173 52.2168L16.5044 59.9645Z" fill="#F7B80B"/>
  </svg>

);