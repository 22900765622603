export const DressingOutfitEnsembleIcon = () => (
  <svg
    width="88"
    height="124"
    viewBox="0 0 88 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1977_80439)">
      <path
        d="M62.8997 39.4717C62.6993 38.8651 62.368 38.3096 61.9292 37.8444C61.4903 37.3793 60.9545 37.0158 60.3595 36.7795C59.7646 36.5433 59.1249 36.44 58.4856 36.477C57.8463 36.514 57.2229 36.6904 56.6593 36.9937L48.8933 30.6942L43.9043 34.468L54.9967 43.2104C55.5703 44.121 56.4589 44.7901 57.494 45.0909C58.5291 45.3917 59.6389 45.3033 60.6131 44.8425C61.5872 44.3816 62.358 43.5804 62.7795 42.5905C63.2009 41.6007 63.2437 40.4909 62.8997 39.4717Z"
        fill="#FFB6B6"
      />
      <path
        d="M39.3913 35.8871C35.1872 34.0697 30.5676 29.1514 25.8849 21.4697C25.6216 21.0469 25.4492 20.5742 25.3786 20.0815C25.308 19.5887 25.3408 19.0867 25.4748 18.6073C26.3447 15.2445 31.2719 12.3204 31.751 12.043L38.8386 4.57926C38.9385 4.45134 41.6478 1.0676 44.8585 0.835201C45.5322 0.791567 46.2074 0.893907 46.8377 1.13521C47.468 1.3765 48.0385 1.75104 48.5099 2.23307C52.709 6.20025 42.7372 18.5017 41.4057 20.1046L46.6639 26.0627L49.6998 30.5441L55.1844 33.628L50.495 40.1538L41.6848 36.5767C40.8947 36.4422 40.1243 36.2106 39.3913 35.8871Z"
        fill="#2F2E41"
      />
      <path
        d="M57.5575 32.6445C57.5575 32.6445 72.1003 55.7505 69.4656 58.3786C66.8309 61.0066 62.7309 61.6967 62.7309 61.6967L53.6572 49.9689L57.5575 32.6445Z"
        fill="#2F2E41"
      />
      <path
        d="M44.6992 1.08789L51.5534 5.71274C54.7392 7.86235 57.1353 10.9896 58.3783 14.6202C59.6214 18.2508 59.6436 22.1867 58.4416 25.831L56.512 31.6812L44.6992 1.08789Z"
        fill="#2F2E41"
      />
      <path
        d="M32.5792 64.1597L31.6628 67.3433L28.5359 105.734L12.9141 134.774L20.3698 140.086L41.6727 112.462L50.0985 85.0347L63.3307 145.752L73.272 145.044L66.3675 60.86L53.266 37.1641L32.5792 64.1597Z"
        fill="#2F2E41"
      />
      <path
        d="M43.5326 2.25L53.0722 10.0264C55.1659 11.7331 56.6735 14.0488 57.3849 16.6508C57.708 17.8323 57.8615 19.0536 57.8409 20.278L57.5566 37.1643L66.3031 60.4805C66.3031 60.4805 42.5905 69.4158 39.4288 61.5317C36.2672 53.6476 43.5326 2.25 43.5326 2.25Z"
        fill="white"
      />
      <path
        d="M46.3864 65.6251C34.5406 65.6251 31.6537 65.7141 31.4667 65.6763L31.2793 65.6385V58.5318L34.16 45.7644C33.8233 41.8006 33.0346 36.5739 33.1061 32.0986C33.3643 15.9229 32.5671 0.122138 32.5671 0.122138L38.8367 -5.65039L41.685 -3.80159L46.9683 1.46814C53.1096 16.5433 57.9828 30.7358 58.0134 31.6476L58.3995 47.3412L56.8187 53.6485C49.6673 58.6838 56.2015 65.6251 46.3864 65.6251Z"
        fill="#2F2E41"
      />
      <path
        opacity="0.2"
        d="M35.2243 14.2129L33.7842 23.1787L43.5332 27.1896L35.2243 14.2129Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M29.0518 31.4531C31.5921 34.3871 35.0378 36.396 38.8474 37.164C42.657 37.9321 46.6144 37.4158 50.0978 35.6962L52.1751 34.6707L29.0518 31.4531Z"
        fill="black"
      />
      <path
        d="M62.2654 29.262C61.8396 28.7849 61.3141 28.4067 60.7259 28.1541C60.1378 27.9014 59.5013 27.7805 58.8612 27.7997C58.2211 27.819 57.593 27.9781 57.0212 28.2657C56.4494 28.5532 55.9478 28.9624 55.5517 29.4643L45.9143 26.7656L42.8418 32.2073L56.5052 35.8277C57.3946 36.4355 58.4766 36.6969 59.5463 36.5622C60.616 36.4275 61.5989 35.9061 62.3089 35.0968C63.019 34.2875 63.4067 33.2465 63.3986 32.1712C63.3906 31.0958 62.9874 30.0607 62.2654 29.262Z"
        fill="#FFB6B6"
      />
      <path
        d="M39.2679 35.3001C34.686 35.3006 28.4863 32.6209 21.1267 27.4308C20.7165 27.1474 20.3699 26.782 20.1086 26.358C19.8474 25.9339 19.6773 25.4604 19.6091 24.9673C19.0662 21.5368 22.421 16.8992 22.7499 16.4546L26.2764 6.79472C26.317 6.63773 27.4535 2.45826 30.3067 0.971213C30.9074 0.663898 31.5677 0.489932 32.2422 0.461262C32.9167 0.432591 33.5894 0.549907 34.2142 0.805132C39.6488 2.77906 35.4052 18.0217 34.8226 20.0206L42.0229 23.4011L46.5953 26.3083L52.8571 26.9617L51.157 34.8096L41.6472 35.023C40.8687 35.213 40.0695 35.3061 39.2679 35.3001Z"
        fill="#2F2E41"
      />
    </g>
    <defs>
      <clipPath id="clip0_1977_80439">
        <rect
          width="88"
          height="123"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
