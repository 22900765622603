export const InaugurationIcon = () => (
  <svg width="85" height="101" viewBox="0 0 85 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1001_5858)">
      <path d="M23.159 96.6199C22.6414 96.8898 22.1235 97.2551 21.7313 97.9015C21.6574 98.0232 21.5915 98.1558 21.5299 98.2933C21.7038 97.0573 22.0508 95.8944 22.5509 94.9256C22.7353 94.5684 22.4254 94.0446 22.2397 94.4043V94.4043C21.4469 94.8155 21.1129 95.777 20.9854 96.9153C20.8278 96.5076 20.634 96.1397 20.408 95.8336C20.3156 95.7085 20.1871 95.6823 20.0968 95.8336C20.0196 95.9629 20.0039 96.2291 20.0968 96.3549C19.7558 98.1438 20.6362 98.698 20.9403 99.5887C20.947 99.7862 20.9543 99.9812 20.9611 100.172C20.9678 100.36 21.0396 100.473 21.1232 100.511C21.1637 100.617 21.2367 100.689 21.3111 100.689C21.4358 100.689 21.5245 100.52 21.5311 100.321C21.5556 99.5884 21.6307 98.3169 22.0662 98.3843C22.9938 98.5281 23.0367 97.779 23.276 97.3309C23.4642 96.9785 23.4305 96.4784 23.159 96.6199H23.159Z" fill="#E6E6E6"/>
      <path d="M69.8078 96.6199C70.3254 96.8898 70.8433 97.2551 71.2355 97.9015C71.3094 98.0232 71.3752 98.1558 71.4369 98.2933C71.263 97.0573 70.916 95.8944 70.4159 94.9256C70.2315 94.5684 70.5414 94.0446 70.7271 94.4043V94.4043C71.5199 94.8155 71.8539 95.777 71.9814 96.9153C72.139 96.5076 72.3328 96.1397 72.5588 95.8336C72.6511 95.7085 72.7797 95.6823 72.87 95.8336C72.9472 95.9629 72.9629 96.2291 72.87 96.3549C73.211 98.1438 72.3306 98.698 72.0265 99.5887C72.0198 99.7862 72.0124 99.9812 72.0057 100.172C71.999 100.36 71.9272 100.473 71.8436 100.511C71.8031 100.617 71.73 100.689 71.6557 100.689C71.531 100.689 71.4423 100.52 71.4356 100.321C71.4112 99.5884 71.3361 98.3169 70.9006 98.3843C69.973 98.5281 69.9301 97.779 69.6908 97.3309C69.5026 96.9785 69.5363 96.4784 69.8078 96.6199Z" fill="#E6E6E6"/>
      <path d="M-15.8145 100.568C-15.8145 100.73 -15.684 100.86 -15.5215 100.86H83.7588C83.9213 100.86 84.0518 100.73 84.0518 100.568C84.0518 100.405 83.9213 100.275 83.7588 100.275H-15.5215C-15.684 100.275 -15.8145 100.405 -15.8145 100.568Z" fill="#CCCCCC"/>
      <path d="M74.9773 95.3305L76.8524 94.859L75.5719 86.2163L72.8047 86.9118L74.9773 95.3305Z" fill="#FFB6B6"/>
      <path d="M82.4448 97.4427C82.5319 97.7569 82.3793 98.0734 82.1038 98.1496L78.4061 99.1724C78.4061 99.1724 77.6369 97.8128 75.9789 97.5947L76.4562 99.7118L74.5487 100.239L73.8477 96.8169C73.8477 96.8169 72.8387 95.1612 73.6435 93.9495C74.4483 92.7379 73.6255 93.1032 73.6255 93.1032L73.4654 90.9091L76.4418 90.4502L77.3687 93.7075L79.6664 96.5595L81.999 97.0038C82.2066 97.0433 82.3818 97.2158 82.4448 97.4427H82.4448Z" fill="#1A202C"/>
      <path d="M57.0195 93.8286L58.8273 94.5136L62.733 86.6952L60.0652 85.6841L57.0195 93.8286Z" fill="#FFB6B6"/>
      <path d="M61.9315 99.8286C61.8229 100.136 61.5162 100.308 61.2466 100.213L57.6282 98.9389C57.6282 98.9389 57.7771 97.3844 56.5427 96.2581L55.7201 98.2668L53.8535 97.6093L55.2412 94.4024C55.2412 94.4024 55.3632 92.4681 56.7179 91.9346C58.0725 91.401 57.1884 91.2303 57.1884 91.2303L58.3152 89.3398L61.0188 90.6645L59.9111 93.865L60.1598 97.5166L61.8177 99.214C61.9652 99.365 62.01 99.6066 61.9315 99.8286L61.9315 99.8286Z" fill="#1A202C"/>
      <path d="M64.8678 16.5325H55.2738C55.2879 16.0831 55.1918 15.6337 54.9948 15.2311C54.9901 15.6782 54.8893 16.1253 54.7041 16.5325H54.268C53.6021 16.5325 53.0605 15.9918 53.0605 15.3271V9.10316C53.0605 6.05556 55.459 3.544 58.507 3.39888C58.5984 3.39419 58.6875 3.39185 58.779 3.39185C61.2619 3.39185 63.4799 4.9999 64.2348 7.38272C65.3438 6.87246 66.0073 9.7726 66.4481 14.3791C66.7834 15.4464 65.9862 16.5325 64.8678 16.5325Z" fill="#1A202C"/>
      <path d="M83.6932 52.1162C84.1688 53.2418 83.9727 54.3992 83.2552 54.7014C82.5377 55.0035 81.5705 54.3359 81.0949 53.2103C80.8867 52.7176 80.8073 52.2188 80.8439 51.7882L78.8887 46.9933L81.1621 46.1252L82.8482 50.9442C83.1826 51.2185 83.485 51.6234 83.6932 52.1162Z" fill="#FFB6B6"/>
      <path d="M62.672 14.4258L58.0862 15.3835L58.7896 17.4901L57.3828 33.6022L59.7274 38.7905L74.0293 37.6201L70.5125 30.598C70.5125 30.598 71.9192 24.7463 70.0435 23.5759C68.1679 22.4056 65.1199 16.5538 65.1199 16.5538L62.672 14.4258V14.4258Z" fill="#F7B80B"/>
      <path d="M58.397 16.3152L54.3346 17.9583L55.7413 38.5564L52.6934 56.3458L62.5406 57.9843C62.5406 57.9843 61.3683 18.6605 60.6649 17.9583C59.9616 17.2561 58.397 16.3152 58.397 16.3152V16.3152Z" fill="#1A202C"/>
      <path d="M66.3535 18.885C66.3535 18.885 68.9969 17.2152 70.4251 18.4515C71.8532 19.6878 82.8488 49.5724 82.8488 49.5724L79.883 50.8589L73.0813 36.6212L66.3535 18.885H66.3535Z" fill="#1A202C"/>
      <path d="M73.9272 69.2361L76.6094 89.8178L72.8581 90.0519C72.8581 90.0519 70.9074 84.7385 68.6824 78.4795C68.2088 77.1523 67.7234 75.783 67.2428 74.4113C66.7223 72.9227 66.2042 71.434 65.7118 69.9968C64.495 66.453 63.4282 63.2275 62.8186 61.1208C62.7131 60.7511 62.6193 60.4163 62.5419 60.1214C60.9007 53.8296 59.9629 37.3862 59.9629 37.3862L73.5615 36.2158L73.7068 36.3961L76.6094 40.0171L76.4734 40.7825L72.8581 61.0272L73.9272 69.2361L73.9272 69.2361Z" fill="#1A202C"/>
      <path d="M72.8585 61.0273L73.9276 69.2362L69.1189 77.6884L68.6828 78.4796L62.0617 90.492L58.791 88.6405C58.791 88.6405 63.0464 78.8775 65.7122 69.9969C66.0076 69.0208 66.2819 68.0541 66.5281 67.1131C67.2526 64.3581 66.0663 59.3303 66.5305 57.9446C68.099 53.2679 73.2102 43.9121 75.5829 39.6731L76.4738 40.7826L72.8585 61.0273Z" fill="#1A202C"/>
      <path d="M65.0605 16.376L69.8092 18.1925L82.2354 54.4734L74.7328 55.6437L71.2159 34.5774L65.0605 16.376Z" fill="#1A202C"/>
      <path d="M53.1886 55.5383C53.0677 56.754 52.3416 57.6772 51.5667 57.6005C50.7917 57.5237 50.2615 56.4759 50.3824 55.2602C50.4353 54.728 50.6042 54.2519 50.8424 53.8912L51.4193 48.7463L53.8319 49.068L53.0071 54.1057C53.1696 54.5062 53.2415 55.0061 53.1886 55.5383V55.5383Z" fill="#FFB6B6"/>
      <path d="M55.9765 17.9584C55.9765 17.9584 52.9286 17.2562 51.9907 18.8947C51.0529 20.5332 50.584 52.3667 50.584 52.3667L53.8092 52.6008L55.5076 36.9181L55.9765 17.9584V17.9584Z" fill="#1A202C"/>
      <path d="M59.866 13.8581C62.5577 13.8581 64.7397 11.6797 64.7397 8.99242C64.7397 6.30516 62.5577 4.12671 59.866 4.12671C57.1742 4.12671 54.9922 6.30516 54.9922 8.99242C54.9922 11.6797 57.1742 13.8581 59.866 13.8581Z" fill="#FFB6B6"/>
      <path d="M65.9846 8.57413C65.9846 8.57413 63.6104 7.87192 62.1889 8.57413C60.2134 9.54999 55.1699 8.57413 55.1699 8.57413V6.37454C55.1699 5.09043 56.1277 4.00728 57.404 3.848L61.0436 3.39381C63.9005 3.03729 66.4239 5.26122 66.4239 8.13559V8.1356C66.4239 8.37779 66.2272 8.57413 65.9846 8.57413Z" fill="#1A202C"/>
      <path d="M60.7972 16.5326H55.2733C55.2874 16.0832 55.1913 15.6338 54.9943 15.2312C54.9896 15.6782 54.8888 16.1253 54.7036 16.5326H54.4668V6.70166H58.687L60.7972 16.5326Z" fill="#1A202C"/>
      <path d="M32.9034 93.5943L30.4326 95.2867L22.7285 86.5621L26.3751 84.0642L32.9034 93.5943Z" fill="#FFB6B6"/>
      <path d="M24.4001 99.3375C24.4119 99.5198 24.6578 99.9658 24.761 100.116C25.0783 100.58 25.7109 100.698 26.1741 100.38L33.8254 95.1386C34.1413 94.9221 34.222 94.4906 34.0055 94.1746L33.7873 93.8561C33.7873 93.8561 33.5099 92.6393 31.9221 91.9932C31.9221 91.9932 31.5866 93.5807 29.1479 93.125L28.1915 92.6442L26.65 97.02L25.0821 98.4039C24.7391 98.7067 24.3705 98.8809 24.4001 99.3375L24.4001 99.3375L24.4001 99.3375Z" fill="#1A202C"/>
      <path d="M14.6871 98.0871L11.6922 98.0868L10.2676 86.5352L13.9334 86.9916L14.6871 98.0871Z" fill="#FFB6B6"/>
      <path d="M4.42566 98.0193C4.33241 98.1764 4.2832 98.6833 4.2832 98.8659C4.2832 99.4273 4.73828 99.8823 5.29966 99.8823H14.5744C14.9573 99.8823 15.2678 99.5719 15.2678 99.1889V98.8028C15.2678 98.8028 15.7266 97.6422 14.782 96.2118C14.782 96.2118 13.608 97.3319 11.8537 95.5776L11.3364 94.6404L7.59164 97.3791L5.516 97.6346C5.06189 97.6905 4.65929 97.6259 4.4257 98.0193H4.42566V98.0193Z" fill="#1A202C"/>
      <path d="M4.18602 54.1873L4.41139 56.9113L3.96289 59.0775L4.77954 61.3613L5.77853 73.4364L10.9851 94.2549L14.9606 95.0918L13.0521 74.2965L14.5325 57.1438L4.18602 54.1873Z" fill="#1A202C"/>
      <path d="M21.0792 42.3613L5.87492 42.5724L4.18555 54.1867L14.532 59.0437L14.202 75.6311L25.7249 92.3112L31.862 88.8049L21.5783 74.6396L24.458 51.0191L21.0792 42.3613H21.0792Z" fill="#1A202C"/>
      <path d="M9.82498 15.0566L7.14258 18.4991L8.40972 44.8952L18.3346 44.6841L20.024 21.6667L17.9124 15.7539L17.1483 14.4289L16.2344 12.7131L10.3758 13.1316L9.82501 15.0566H9.82498V15.0566Z" fill="#E6E6E6"/>
      <path d="M5.86344 31.3804L6.39983 36.7752L5.74083 42.9831L4.91706 44.6661L3.03394 51.3617C3.03394 51.3617 1.11211 54.2897 5.33553 54.5002C9.55896 54.7107 23.4022 53.3408 23.4022 53.3408C23.4022 53.3408 25.1282 53.2982 24.376 49.6878C23.1609 47.5995 23.9443 44.657 23.9443 44.657L22.3464 38.77L22.7686 33.2797L25.7251 18.9201C23.0995 16.8567 20.1586 15.3798 16.9615 14.3796L14.1614 21.7427L9.99228 15.0132L8.40857 16.1747C6.27243 16.7348 4.39249 17.5962 2.90567 18.9198L5.86344 31.3804Z" fill="#1A202C"/>
      <path d="M12.7822 12.469C15.6769 12.469 18.0234 10.1224 18.0234 7.22778C18.0234 4.33314 15.6769 1.98657 12.7822 1.98657C9.88758 1.98657 7.54102 4.33314 7.54102 7.22778C7.54102 10.1224 9.88758 12.469 12.7822 12.469Z" fill="#FFB6B6"/>
      <path d="M16.6311 11.5341C16.9198 11.5834 17.2587 10.8836 17.5826 10.2021C18.2606 8.77537 18.6916 7.86841 18.7244 6.58642C18.7523 5.4938 18.4488 5.78056 18.1535 3.35138C18.0561 2.55017 16.6873 2.55171 16.4087 2.17125C15.9381 1.52861 16.6601 1.26357 15.4893 0.877519C13.2963 0.154428 12.3046 1.27772 10.9119 1.3745C10.8767 1.37694 10.1863 2.05215 9.80071 2.08706C9.12512 2.14823 8.15052 2.043 7.7136 1.01369C7.30506 0.0512742 7.03445 2.86366 7.39959 3.21171C7.70961 3.50721 8.2444 4.24892 8.25805 4.30286C8.2735 4.36391 6.98643 2.93816 6.77159 3.52571C6.61696 3.94856 7.10369 6.12406 7.49687 6.20583C7.82566 6.27419 8.62977 5.19046 8.44835 5.63494C8.02759 6.66573 10.5576 7.32345 13.5864 5.25434C13.6938 5.18096 13.5923 5.29799 13.967 6.20583C14.4395 7.35081 14.5461 7.41284 14.5378 7.7282C14.52 8.41087 14.0074 8.61188 14.1572 9.06028C14.2786 9.42345 14.7528 9.70459 15.1087 9.63116C15.5103 9.54828 15.5792 9.05145 15.8699 9.06028C16.1189 9.06785 16.4145 9.44291 16.4408 9.82146C16.4963 10.619 15.3156 10.8383 15.1087 11.9147C14.992 12.5222 16.3658 11.4889 16.6311 11.5341Z" fill="#1A202C"/>
      <path d="M28.4928 53.4288C28.5155 52.8418 28.3973 52.258 28.1482 51.7261L28.688 45.6095L25.7691 45.438L25.5359 51.6622C25.2605 52.181 25.1134 52.7582 25.1068 53.3456C25.0702 54.8149 25.7985 56.0246 26.7332 56.0481C27.6679 56.0715 28.4556 54.8992 28.4923 53.4302L28.4928 53.4288Z" fill="#FFB6B6"/>
      <path d="M22.2841 28.7642L24.5857 35.8782L25.2465 50.4077L29.0992 50.329L29.3981 34.4136C29.3981 34.4136 27.478 22.6534 26.835 20.0101C26.1921 17.3669 20.2637 18.3464 20.2637 18.3464L22.2841 28.7642V28.7642V28.7642Z" fill="#1A202C"/>
      <path d="M2.74559 52.9406C2.89695 54.4023 3.77387 55.5094 4.70389 55.413C5.63392 55.3166 6.26546 54.0536 6.11417 52.5917C6.0617 52.0066 5.86991 51.4427 5.55481 50.947L4.83601 44.76L1.93945 45.1591L2.95556 51.2148C2.7487 51.7646 2.67651 52.3558 2.74498 52.9392L2.74559 52.9406Z" fill="#FFB6B6"/>
      <path d="M7.00824 27.865L8.20846 17.3212C8.20846 17.3212 2.22163 16.8079 1.78718 19.4933C1.35273 22.1787 0.357422 34.053 0.357422 34.053L1.89903 49.8964L5.74616 49.6738L5.26957 35.1371L7.00824 27.865V27.865Z" fill="#1A202C"/>
    </g>
    <defs>
      <clipPath id="clip0_1001_5858">
        <rect width="84.2697" height="100" fill="white" transform="translate(0.226562 0.718994)"/>
      </clipPath>
    </defs>
  </svg>
);