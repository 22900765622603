import { Flex, Text } from "@chakra-ui/react";
import { FC, useState } from "react";
import { PictureTextBlock } from "../../modules/Onboarding/type";
import NorelaButton from "../Buttons/NorelaButton";
import PicturesVariant from "../PicturesVariant/PicturesVariant";
import BottomPopup from "../Popus/BottomPopup/BottomPopup";

const ContactUsBlock: FC<PictureTextBlock> = ({ icon, title, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Flex
      direction="column"
      gap={{ md: "50px", "2xl": "100px" }}
      maxWidth={{ md: "450px", "2xl": "550px" }}
      pt={{ md: "10px", "2xl": "22px" }}
      sx={{
        "@media(max-width: 1600px)": {
          svg: {
            height: "135px",
            width: "70%",
          },
        },
      }}
    >
      <BottomPopup close={() => setIsOpen(false)} open={isOpen} />
      <PicturesVariant icon={icon} />
      <Flex
        direction="column"
        fontSize={{ md: "14px", "2xl": "18px" }}
        lineHeight="30px"
        textAlign="left"
      >
        <Text fontWeight={700} variant="lg">
          {title}
        </Text>
        <Text fontWeight={500} variant="lg">
          {description}
        </Text>
        <NorelaButton mt="1rem" onClick={() => setIsOpen(true)}>
          Contactez-nous !
        </NorelaButton>
      </Flex>
    </Flex>
  );
};

export default ContactUsBlock;
