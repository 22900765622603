export const DressingTenueProIcon = () => (
  <svg
    width="190"
    height="100"
    viewBox="0 0 190 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M108.165 67.421L112.391 62.5851L112.435 62.6237L113.212 61.7342L113.168 61.6957L114.465 60.211C114.514 60.1555 114.551 60.0911 114.574 60.0213C114.598 59.9515 114.608 59.8778 114.603 59.8043C114.598 59.7308 114.578 59.659 114.546 59.593C114.513 59.5271 114.468 59.4681 114.412 59.4197L111.27 56.6737C111.214 56.6252 111.15 56.5882 111.08 56.5646C111.01 56.5411 110.937 56.5315 110.863 56.5364C110.79 56.5414 110.718 56.5607 110.652 56.5934C110.586 56.6261 110.527 56.6715 110.478 56.7269L109.574 57.7622L109.502 57.7024L108.973 58.3365L109.03 58.3844L108.91 58.5216L108.849 58.4707L108.32 59.1048L108.366 59.1438L104.178 63.9369C104.129 63.9923 104.092 64.0568 104.069 64.1266C104.045 64.1964 104.035 64.2701 104.04 64.3436C104.045 64.4172 104.065 64.489 104.097 64.555C104.13 64.621 104.176 64.6798 104.231 64.7282L107.374 67.4742C107.486 67.5719 107.632 67.6212 107.78 67.6112C107.929 67.6012 108.067 67.5328 108.165 67.421Z"
      fill="#3F3D56"
    />
    <path
      d="M104.307 64.451C104.28 64.4026 104.269 64.3471 104.275 64.2921C104.281 64.2371 104.303 64.1853 104.34 64.1436L110.707 56.8575C110.754 56.8039 110.82 56.771 110.891 56.7662C110.962 56.7614 111.032 56.7851 111.086 56.832L111.755 57.4163L111.728 57.4294C111.683 57.4522 111.644 57.4858 111.615 57.5272C111.585 57.5687 111.566 57.6167 111.56 57.6671C111.554 57.7175 111.56 57.7687 111.578 57.8162C111.596 57.8637 111.625 57.906 111.664 57.9394L113.147 59.2361C113.187 59.2652 113.233 59.2846 113.282 59.2927C113.33 59.3008 113.38 59.2973 113.427 59.2826C113.473 59.2682 113.515 59.243 113.55 59.2092C113.585 59.1753 113.611 59.1338 113.626 59.0879L113.636 59.0603L114.326 59.6637C114.38 59.7107 114.413 59.777 114.418 59.8481C114.422 59.9192 114.399 59.9892 114.352 60.0429L108.277 66.9945C108.153 67.1367 107.977 67.2237 107.788 67.2363C107.6 67.249 107.414 67.1864 107.271 67.0622L104.365 64.5228C104.342 64.5024 104.322 64.4781 104.307 64.451Z"
      fill="white"
    />
    <path
      d="M110.722 61.5793C111.309 61.5793 111.784 61.1038 111.784 60.5172C111.784 59.9306 111.309 59.4551 110.722 59.4551C110.136 59.4551 109.66 59.9306 109.66 60.5172C109.66 61.1038 110.136 61.5793 110.722 61.5793Z"
      fill="#F7B80B"
    />
    <path
      d="M109.92 64.5923L106.977 62.0204C106.968 62.0124 106.96 62.0026 106.955 61.9917C106.95 61.9808 106.946 61.9689 106.946 61.9567C106.945 61.9445 106.946 61.9323 106.95 61.9207C106.954 61.9091 106.96 61.8984 106.968 61.8892C106.976 61.88 106.986 61.8725 106.997 61.867C107.008 61.8616 107.02 61.8584 107.032 61.8576C107.044 61.8568 107.057 61.8584 107.068 61.8624C107.08 61.8663 107.09 61.8724 107.1 61.8805L110.043 64.4524C110.052 64.4604 110.059 64.4702 110.065 64.4811C110.07 64.492 110.074 64.504 110.074 64.5161C110.075 64.5283 110.074 64.5406 110.07 64.5521C110.066 64.5637 110.06 64.5744 110.052 64.5836C110.044 64.5928 110.034 64.6004 110.023 64.6058C110.012 64.6112 110 64.6144 109.988 64.6152C109.976 64.616 109.963 64.6144 109.952 64.6105C109.94 64.6065 109.93 64.6004 109.92 64.5923Z"
      fill="white"
    />
    <path
      d="M108.656 64.0459L107.688 63.2C107.669 63.1838 107.658 63.1608 107.656 63.1363C107.655 63.1117 107.663 63.0874 107.679 63.0689C107.695 63.0503 107.718 63.039 107.743 63.0373C107.768 63.0357 107.792 63.0438 107.81 63.0601L108.778 63.906C108.797 63.9222 108.808 63.9452 108.81 63.9697C108.812 63.9943 108.803 64.0186 108.787 64.0371C108.771 64.0557 108.748 64.067 108.723 64.0687C108.699 64.0703 108.675 64.0621 108.656 64.0459Z"
      fill="white"
    />
    <path
      d="M108.098 64.6866L107.129 63.8406C107.12 63.8326 107.113 63.8228 107.107 63.8119C107.102 63.801 107.099 63.7891 107.098 63.7769C107.097 63.7647 107.099 63.7525 107.103 63.7409C107.106 63.7294 107.113 63.7187 107.121 63.7095C107.129 63.7003 107.138 63.6928 107.149 63.6874C107.16 63.682 107.172 63.6788 107.184 63.6779C107.197 63.6771 107.209 63.6787 107.22 63.6826C107.232 63.6865 107.243 63.6927 107.252 63.7007L108.22 64.5466C108.238 64.5629 108.25 64.5858 108.251 64.6104C108.253 64.635 108.245 64.6592 108.229 64.6777C108.212 64.6963 108.189 64.7077 108.165 64.7093C108.14 64.711 108.116 64.7028 108.098 64.6866Z"
      fill="white"
    />
    <path
      d="M107.809 65.0148L106.841 64.1689C106.831 64.1608 106.824 64.1511 106.818 64.1401C106.813 64.1292 106.81 64.1173 106.809 64.1051C106.808 64.0929 106.81 64.0807 106.813 64.0691C106.817 64.0575 106.823 64.0468 106.832 64.0376C106.84 64.0284 106.849 64.0209 106.86 64.0155C106.871 64.0101 106.883 64.0069 106.895 64.0061C106.908 64.0053 106.92 64.0069 106.931 64.0108C106.943 64.0147 106.954 64.0209 106.963 64.0289L107.931 64.8749C107.94 64.8829 107.948 64.8927 107.953 64.9036C107.958 64.9146 107.962 64.9265 107.962 64.9386C107.963 64.9508 107.962 64.963 107.958 64.9746C107.954 64.9861 107.948 64.9968 107.94 65.006C107.932 65.0152 107.922 65.0227 107.911 65.0281C107.9 65.0335 107.888 65.0367 107.876 65.0376C107.864 65.0384 107.852 65.0368 107.84 65.0329C107.828 65.029 107.818 65.0228 107.809 65.0148Z"
      fill="white"
    />
    <path
      d="M108.922 64.8348L106.868 63.04C106.859 63.0319 106.851 63.0222 106.846 63.0112C106.84 63.0003 106.837 62.9884 106.836 62.9762C106.835 62.964 106.837 62.9518 106.841 62.9402C106.845 62.9286 106.851 62.9179 106.859 62.9087C106.867 62.8995 106.877 62.892 106.888 62.8866C106.899 62.8812 106.911 62.878 106.923 62.8772C106.935 62.8763 106.947 62.878 106.959 62.8819C106.97 62.8858 106.981 62.892 106.99 62.9L109.044 64.6949C109.053 64.7029 109.061 64.7127 109.066 64.7236C109.072 64.7345 109.075 64.7464 109.076 64.7586C109.076 64.7708 109.075 64.783 109.071 64.7946C109.067 64.8061 109.061 64.8168 109.053 64.826C109.045 64.8352 109.035 64.8427 109.024 64.8481C109.013 64.8535 109.001 64.8567 108.989 64.8575C108.977 64.8584 108.965 64.8568 108.953 64.8529C108.942 64.849 108.931 64.8428 108.922 64.8348Z"
      fill="white"
    />
    <path
      d="M106.874 65.3829L106.523 65.0758C106.507 65.0621 106.494 65.0455 106.485 65.0269C106.476 65.0083 106.471 64.9881 106.469 64.9674C106.468 64.9467 106.47 64.926 106.477 64.9063C106.484 64.8866 106.494 64.8685 106.508 64.8529C106.521 64.8372 106.538 64.8245 106.557 64.8152C106.575 64.806 106.595 64.8006 106.616 64.7992C106.637 64.7978 106.658 64.8005 106.677 64.8071C106.697 64.8138 106.715 64.8242 106.731 64.8378L107.082 65.145C107.114 65.1726 107.133 65.2116 107.136 65.2534C107.139 65.2952 107.125 65.3364 107.097 65.3679C107.07 65.3995 107.031 65.4188 106.989 65.4216C106.947 65.4244 106.906 65.4105 106.874 65.3829Z"
      fill="white"
    />
    <path
      d="M102.437 59.635C102.259 59.4577 102.122 59.2434 102.035 59.0073C101.949 58.7712 101.915 58.519 101.937 58.2685C101.958 58.018 102.034 57.7752 102.16 57.5573C102.285 57.3395 102.457 57.1517 102.663 57.0074L99.8555 43.4199L102.884 44.3755L105.143 57.5203C105.363 57.8823 105.442 58.3122 105.366 58.7286C105.289 59.145 105.063 59.519 104.729 59.7796C104.396 60.0403 103.978 60.1695 103.556 60.1429C103.133 60.1162 102.735 59.9355 102.437 59.635Z"
      fill="#9E616A"
    />
    <path
      d="M95.9481 23.3154L97.8243 21.8991C97.8243 21.8991 99.4795 21.7818 99.7727 24.7668C100.066 27.7517 101.901 39.2078 101.901 39.2078L104.772 52.5056L100.798 53.378L97.7229 39.0273L94.6445 29.1267L95.9481 23.3154Z"
      fill="#2F2E41"
    />
    <path
      d="M96.4364 47.8031L90.3127 39.9274C90.2038 39.787 90.155 39.6091 90.1771 39.4327C90.1991 39.2564 90.2903 39.096 90.4305 38.9867L99.4308 31.9885C99.5712 31.8796 99.7491 31.8308 99.9254 31.8529C100.102 31.8749 100.262 31.966 100.371 32.1062L106.495 39.9819C106.604 40.1223 106.653 40.3002 106.631 40.4766C106.609 40.6529 106.518 40.8133 106.377 40.9226L97.3772 47.9208C97.2367 48.0297 97.0588 48.0785 96.8825 48.0564C96.7061 48.0344 96.5457 47.9433 96.4364 47.8031Z"
      fill="#3F3D56"
    />
    <path
      d="M96.5905 47.0924L91.0391 39.9527C90.9424 39.8281 90.8991 39.6701 90.9187 39.5135C90.9383 39.3569 91.0192 39.2145 91.1436 39.1175L99.3804 32.713C99.5051 32.6162 99.6631 32.5729 99.8197 32.5925C99.9763 32.6121 100.119 32.693 100.216 32.8175L105.767 39.9571C105.864 40.0818 105.907 40.2398 105.888 40.3963C105.868 40.5529 105.787 40.6954 105.663 40.7924L97.4258 47.1969C97.3011 47.2936 97.1431 47.3369 96.9866 47.3173C96.83 47.2977 96.6876 47.2168 96.5905 47.0924Z"
      fill="white"
    />
    <path
      d="M104.375 40.5841L101.574 36.9824C101.562 36.9669 101.553 36.9493 101.548 36.9305C101.543 36.9116 101.542 36.8919 101.544 36.8725C101.547 36.8531 101.553 36.8344 101.562 36.8174C101.572 36.8004 101.585 36.7855 101.6 36.7735C101.616 36.7615 101.634 36.7527 101.652 36.7475C101.671 36.7423 101.691 36.7409 101.71 36.7433C101.73 36.7458 101.748 36.752 101.765 36.7617C101.782 36.7713 101.797 36.7843 101.809 36.7997L104.61 40.4014C104.622 40.4169 104.631 40.4345 104.636 40.4534C104.641 40.4722 104.643 40.4919 104.64 40.5114C104.638 40.5308 104.632 40.5495 104.622 40.5666C104.612 40.5836 104.599 40.5985 104.584 40.6105C104.568 40.6226 104.551 40.6314 104.532 40.6366C104.513 40.6417 104.493 40.6431 104.474 40.6407C104.454 40.6382 104.436 40.632 104.419 40.6223C104.402 40.6126 104.387 40.5996 104.375 40.5841V40.5841Z"
      fill="#E4E4E4"
    />
    <path
      d="M103.91 40.9457L101.11 37.3439C101.098 37.3285 101.089 37.3109 101.084 37.292C101.078 37.2732 101.077 37.2534 101.079 37.234C101.082 37.2146 101.088 37.1958 101.098 37.1788C101.107 37.1618 101.12 37.1469 101.136 37.1348C101.151 37.1228 101.169 37.114 101.188 37.1088C101.206 37.1036 101.226 37.1022 101.246 37.1047C101.265 37.1071 101.284 37.1134 101.301 37.1231C101.318 37.1328 101.333 37.1458 101.345 37.1613L104.145 40.763C104.169 40.7942 104.18 40.8336 104.175 40.8727C104.17 40.9118 104.15 40.9473 104.119 40.9715C104.088 40.9957 104.048 41.0066 104.009 41.0017C103.97 40.9969 103.934 40.9767 103.91 40.9457Z"
      fill="#E4E4E4"
    />
    <path
      d="M101.859 39.2665L100.645 37.7053C100.633 37.6899 100.624 37.6722 100.619 37.6533C100.613 37.6345 100.612 37.6148 100.614 37.5954C100.617 37.5759 100.623 37.5572 100.633 37.5401C100.642 37.5231 100.655 37.5082 100.671 37.4962C100.686 37.4842 100.704 37.4753 100.723 37.4701C100.742 37.465 100.761 37.4636 100.781 37.466C100.8 37.4685 100.819 37.4747 100.836 37.4844C100.853 37.4942 100.868 37.5071 100.88 37.5226L102.094 39.0839C102.118 39.115 102.129 39.1545 102.124 39.1937C102.119 39.2328 102.099 39.2684 102.068 39.2926C102.036 39.3168 101.997 39.3277 101.958 39.3228C101.919 39.3179 101.883 39.2977 101.859 39.2665V39.2665Z"
      fill="#E4E4E4"
    />
    <path
      d="M101.234 42.3307L96.8128 36.6447C96.8008 36.6293 96.7919 36.6117 96.7866 36.5928C96.7814 36.5739 96.78 36.5542 96.7824 36.5348C96.7848 36.5154 96.791 36.4966 96.8007 36.4796C96.8103 36.4626 96.8233 36.4476 96.8387 36.4356C96.8542 36.4236 96.8718 36.4148 96.8907 36.4096C96.9096 36.4044 96.9293 36.403 96.9487 36.4055C96.9681 36.4079 96.9869 36.4142 97.0039 36.4239C97.0209 36.4336 97.0358 36.4466 97.0477 36.4621L101.469 42.1481C101.481 42.1635 101.49 42.1811 101.495 42.2C101.5 42.2189 101.502 42.2386 101.499 42.258C101.497 42.2774 101.491 42.2962 101.481 42.3132C101.471 42.3302 101.458 42.3451 101.443 42.3572C101.428 42.3692 101.41 42.378 101.391 42.3832C101.372 42.3884 101.352 42.3898 101.333 42.3873C101.314 42.3849 101.295 42.3786 101.278 42.3689C101.261 42.3592 101.246 42.3462 101.234 42.3307Z"
      fill="#E4E4E4"
    />
    <path
      d="M100.769 42.6917L96.3477 37.0057C96.3235 36.9746 96.3127 36.9351 96.3176 36.8959C96.3225 36.8568 96.3427 36.8212 96.3739 36.797C96.405 36.7727 96.4445 36.7619 96.4836 36.7668C96.5228 36.7717 96.5584 36.7919 96.5826 36.823L101.004 42.509C101.016 42.5245 101.025 42.5421 101.03 42.561C101.035 42.5798 101.036 42.5995 101.034 42.6189C101.032 42.6383 101.025 42.657 101.016 42.674C101.006 42.691 100.993 42.7059 100.978 42.7179C100.962 42.7299 100.945 42.7388 100.926 42.7439C100.907 42.7491 100.887 42.7505 100.868 42.7481C100.848 42.7457 100.83 42.7394 100.813 42.7298C100.796 42.7201 100.781 42.7072 100.769 42.6917Z"
      fill="#E4E4E4"
    />
    <path
      d="M99.7145 43.5104L95.2933 37.8244C95.2812 37.809 95.2723 37.7913 95.2671 37.7725C95.2619 37.7536 95.2604 37.7339 95.2628 37.7145C95.2653 37.6951 95.2715 37.6763 95.2811 37.6593C95.2908 37.6423 95.3037 37.6273 95.3192 37.6153C95.3346 37.6033 95.3523 37.5945 95.3712 37.5893C95.39 37.5841 95.4098 37.5827 95.4292 37.5852C95.4486 37.5876 95.4673 37.5939 95.4843 37.6036C95.5013 37.6133 95.5162 37.6263 95.5282 37.6417L99.9494 43.3278C99.9614 43.3432 99.9702 43.3608 99.9754 43.3797C99.9806 43.3985 99.982 43.4182 99.9796 43.4376C99.9772 43.457 99.971 43.4757 99.9613 43.4927C99.9516 43.5097 99.9387 43.5246 99.9233 43.5366C99.9079 43.5486 99.8902 43.5575 99.8714 43.5626C99.8525 43.5678 99.8328 43.5692 99.8134 43.5668C99.7941 43.5644 99.7753 43.5581 99.7583 43.5485C99.7413 43.5388 99.7264 43.5259 99.7145 43.5104V43.5104Z"
      fill="#E4E4E4"
    />
    <path
      d="M99.2493 43.8714L94.8282 38.1854C94.804 38.1542 94.7931 38.1147 94.798 38.0756C94.8029 38.0365 94.8232 38.0009 94.8543 37.9766C94.8855 37.9524 94.925 37.9416 94.9641 37.9465C95.0033 37.9513 95.0389 37.9716 95.0631 38.0027L99.4843 43.6887C99.5085 43.7199 99.5193 43.7594 99.5144 43.7985C99.5095 43.8377 99.4892 43.8733 99.4581 43.8975C99.427 43.9217 99.3875 43.9326 99.3483 43.9277C99.3092 43.9228 99.2736 43.9025 99.2493 43.8714V43.8714Z"
      fill="#E4E4E4"
    />
    <path
      d="M98.1986 44.6899L93.7775 39.0039C93.7655 38.9884 93.7566 38.9708 93.7514 38.9519C93.7462 38.9331 93.7448 38.9134 93.7472 38.894C93.7497 38.8746 93.7559 38.8559 93.7655 38.8389C93.7752 38.8219 93.7881 38.807 93.8036 38.795C93.819 38.783 93.8366 38.7742 93.8555 38.769C93.8743 38.7638 93.894 38.7624 93.9134 38.7648C93.9328 38.7673 93.9515 38.7735 93.9685 38.7832C93.9855 38.7928 94.0004 38.8058 94.0124 38.8212L98.4336 44.5072C98.4456 44.5226 98.4544 44.5403 98.4596 44.5591C98.4648 44.578 98.4662 44.5976 98.4638 44.617C98.4614 44.6364 98.4552 44.6552 98.4455 44.6722C98.4358 44.6891 98.4229 44.7041 98.4075 44.7161C98.3921 44.7281 98.3744 44.7369 98.3556 44.7421C98.3367 44.7473 98.317 44.7487 98.2976 44.7462C98.2782 44.7438 98.2595 44.7376 98.2425 44.7279C98.2255 44.7182 98.2106 44.7053 98.1986 44.6899Z"
      fill="#E4E4E4"
    />
    <path
      d="M97.7338 45.0512L93.3126 39.3652C93.3006 39.3498 93.2918 39.3321 93.2866 39.3133C93.2814 39.2944 93.28 39.2748 93.2824 39.2554C93.2848 39.236 93.291 39.2172 93.3007 39.2002C93.3104 39.1833 93.3233 39.1683 93.3387 39.1563C93.3541 39.1443 93.3718 39.1355 93.3906 39.1303C93.4095 39.1251 93.4292 39.1237 93.4486 39.1262C93.468 39.1286 93.4867 39.1348 93.5037 39.1445C93.5207 39.1542 93.5356 39.1671 93.5476 39.1825L97.9687 44.8685C97.9928 44.8997 98.0036 44.9392 97.9987 44.9782C97.9937 45.0173 97.9735 45.0529 97.9424 45.0771C97.9113 45.1012 97.8718 45.1121 97.8327 45.1073C97.7936 45.1024 97.7581 45.0823 97.7338 45.0512Z"
      fill="#E4E4E4"
    />
    <path
      d="M105.331 51.6808C105.364 51.6808 105.392 51.6534 105.392 51.6197C105.392 51.5859 105.364 51.5586 105.331 51.5586C105.297 51.5586 105.27 51.5859 105.27 51.6197C105.27 51.6534 105.297 51.6808 105.331 51.6808Z"
      fill="white"
    />
    <path
      d="M109.772 67.6853H109.332V63.7315C109.332 62.3139 108.769 60.9544 107.767 59.9521C106.764 58.9497 105.405 58.3866 103.987 58.3866C102.57 58.3866 101.21 58.9497 100.208 59.9521C99.2056 60.9544 98.6424 62.3139 98.6424 63.7315V67.6853H98.2031V63.7315C98.2031 62.1974 98.8125 60.7262 99.8973 59.6414C100.982 58.5567 102.453 57.9473 103.987 57.9473C105.521 57.9473 106.993 58.5567 108.077 59.6414C109.162 60.7262 109.772 62.1974 109.772 63.7315V67.6853Z"
      fill="#2F2E41"
    />
    <path
      d="M116.311 64.1018C116.165 63.4744 115.811 62.915 115.305 62.5154C114.8 62.1158 114.174 61.8996 113.53 61.9024H93.6118C92.9292 61.9044 92.2697 62.1499 91.7519 62.5947C91.2342 63.0395 90.892 63.6543 90.787 64.3288C90.4881 66.4101 90.3896 68.5152 90.4927 70.6153C90.5264 71.4236 90.5864 72.2534 90.6728 73.1047C90.7285 73.6612 90.7948 74.2274 90.872 74.8034C90.9663 75.488 91.3049 76.1155 91.8253 76.5702C92.3457 77.025 93.0129 77.2764 93.704 77.2781H113.492C114.141 77.2812 114.772 77.0615 115.279 76.6556C115.787 76.2498 116.139 75.6823 116.279 75.0479C116.425 74.3977 116.546 73.75 116.642 73.1047C116.766 72.2796 116.849 71.4487 116.891 70.6153C117.003 68.4277 116.808 66.2351 116.311 64.1018Z"
      fill="#F7B80B"
    />
    <path
      d="M109.772 66.4411H109.332V62.4873C109.332 61.0698 108.769 59.7103 107.767 58.7079C106.764 57.7056 105.405 57.1424 103.987 57.1424C102.57 57.1424 101.21 57.7056 100.208 58.7079C99.2056 59.7103 98.6424 61.0698 98.6424 62.4873V66.4411H98.2031V62.4873C98.2031 60.9533 98.8125 59.482 99.8973 58.3973C100.982 57.3125 102.453 56.7031 103.987 56.7031C105.521 56.7031 106.993 57.3125 108.077 58.3973C109.162 59.482 109.772 60.9533 109.772 62.4873V66.4411Z"
      fill="#2F2E41"
    />
    <path
      d="M104.359 63.0107L103.627 61.7425C103.559 61.6157 103.455 61.5124 103.327 61.446C103.199 61.3796 103.055 61.3532 102.912 61.3703C102.769 61.3874 102.635 61.4471 102.526 61.5418C102.418 61.6364 102.341 61.7615 102.305 61.9007V63.3651C102.305 63.501 102.359 63.6314 102.455 63.7275C102.551 63.8236 102.681 63.8776 102.817 63.8776C102.953 63.8776 103.084 63.8236 103.18 63.7275C103.276 63.6314 103.33 63.501 103.33 63.3651V63.2775L103.472 63.5232C103.54 63.6409 103.652 63.7268 103.783 63.762C103.914 63.7972 104.054 63.7788 104.172 63.7108C104.289 63.6429 104.375 63.5309 104.411 63.3996C104.446 63.2683 104.427 63.1284 104.359 63.0107Z"
      fill="#2F2E41"
    />
    <path
      d="M98.57 67.3197C99.0553 67.3197 99.4486 66.9264 99.4486 66.4411C99.4486 65.9559 99.0553 65.5625 98.57 65.5625C98.0848 65.5625 97.6914 65.9559 97.6914 66.4411C97.6914 66.9264 98.0848 67.3197 98.57 67.3197Z"
      fill="#2F2E41"
    />
    <path
      d="M109.48 67.392C109.965 67.392 110.359 66.9986 110.359 66.5134C110.359 66.0281 109.965 65.6348 109.48 65.6348C108.995 65.6348 108.602 66.0281 108.602 66.5134C108.602 66.9986 108.995 67.392 109.48 67.392Z"
      fill="#2F2E41"
    />
    <path
      d="M116.89 70.6152C116.849 71.4487 116.766 72.2795 116.641 73.1046H90.6723C90.5859 72.2524 90.5259 71.4226 90.4922 70.6152H116.89Z"
      fill="#3F3D56"
    />
    <path
      d="M120.898 77.7175C120.206 77.7062 119.541 77.4495 119.021 76.9931C118.501 76.5367 118.161 75.9104 118.06 75.226C118.04 75.0875 118.254 75.05 118.274 75.1888C118.367 75.8263 118.684 76.4096 119.169 76.8336C119.654 77.2576 120.275 77.4943 120.919 77.5009C121.059 77.5025 121.037 77.7191 120.898 77.7175Z"
      fill="white"
    />
    <path
      d="M121.658 71.4258C121.925 71.486 122.202 71.4789 122.466 71.4052C122.729 71.3316 122.97 71.1937 123.167 71.004C123.267 70.9064 123.405 71.075 123.305 71.172C123.086 71.3806 122.82 71.5326 122.529 71.6146C122.239 71.6966 121.932 71.7061 121.637 71.6424C121.609 71.6387 121.583 71.6244 121.565 71.6023C121.547 71.5802 121.537 71.5521 121.539 71.5236C121.542 71.4949 121.556 71.4686 121.579 71.4503C121.601 71.432 121.629 71.4232 121.658 71.4258Z"
      fill="white"
    />
    <path
      d="M86.0481 8.52589C87.2612 8.52589 88.2446 7.54247 88.2446 6.32935C88.2446 5.11624 87.2612 4.13281 86.0481 4.13281C84.835 4.13281 83.8516 5.11624 83.8516 6.32935C83.8516 7.54247 84.835 8.52589 86.0481 8.52589Z"
      fill="#2F2E41"
    />
    <path
      d="M89.5646 16.8733C92.6378 16.8733 95.1291 14.3819 95.1291 11.3087C95.1291 8.23548 92.6378 5.74414 89.5646 5.74414C86.4913 5.74414 84 8.23548 84 11.3087C84 14.3819 86.4913 16.8733 89.5646 16.8733Z"
      fill="#2F2E41"
    />
    <path
      d="M86.6992 95.2771H89.3176L90.5638 85.1777H86.6992V95.2771Z"
      fill="#9E616A"
    />
    <path
      d="M85.8439 93.4841L90.0271 93.2344V95.0266L94.0041 97.7733C94.2006 97.909 94.3485 98.1039 94.4264 98.3295C94.5042 98.5552 94.5079 98.7998 94.4368 99.0278C94.3658 99.2557 94.2238 99.4549 94.0315 99.5964C93.8392 99.7378 93.6067 99.8141 93.368 99.8141H88.3877L87.5293 98.0413L87.1942 99.8141H85.3164L85.8439 93.4841Z"
      fill="#2F2E41"
    />
    <path
      d="M71.1445 91.9715L73.2457 93.534L80.2722 86.1731L77.1711 83.8672L71.1445 91.9715Z"
      fill="#9E616A"
    />
    <path
      d="M71.5286 90.0234L75.0344 92.3193L73.965 93.7575L75.5174 98.3347C75.5941 98.5608 75.5965 98.8055 75.5243 99.033C75.4521 99.2606 75.3091 99.4591 75.1161 99.5996C74.9231 99.74 74.6902 99.8152 74.4515 99.8139C74.2128 99.8127 73.9807 99.7352 73.7891 99.5928L69.7927 96.621L70.1618 94.6861L68.8349 95.9087L67.3281 94.7883L71.5286 90.0234Z"
      fill="#2F2E41"
    />
    <path d="M95.274 39.4238H85.3164V48.7957H95.274V39.4238Z" fill="#9E616A" />
    <path
      d="M83.6522 43.168C83.6522 43.168 78.6842 47.4601 80.9764 59.6959L83.7748 71.6849L74.6484 86.8254L77.5584 90.3654L89.4228 73.9777L93.7222 63.8927L95.9963 46.2179L83.6522 43.168Z"
      fill="#2F2E41"
    />
    <path
      d="M91.3504 66.7676L91.6491 71.3952L90.7346 91.1156L86.4316 90.3663L85.6094 76.3256L91.3504 66.7676Z"
      fill="#2F2E41"
    />
    <path
      d="M92.6858 18.4121L87.8812 18.5995L85.9663 21.7781L81.5977 22.6309L84.2057 35.8248L81.6299 45.4003L90.8854 49.4507L92.945 43.2784L95.6822 50.7613L100.077 45.9826L96.4597 36.7582L98.3309 29.4812L97.9103 21.8507L94.3959 21.4114L92.6858 18.4121Z"
      fill="#2F2E41"
    />
    <path
      d="M90.0257 16.8718C92.5231 16.8718 94.5476 14.8473 94.5476 12.35C94.5476 9.85262 92.5231 7.82812 90.0257 7.82812C87.5284 7.82812 85.5039 9.85262 85.5039 12.35C85.5039 14.8473 87.5284 16.8718 90.0257 16.8718Z"
      fill="#9E616A"
    />
    <path
      d="M93.0945 7.73947C92.3022 7.25641 91.3946 6.99511 90.4666 6.98287C89.5387 6.97064 88.6246 7.20793 87.8197 7.66993C87.0149 8.13192 86.349 8.80166 85.8916 9.60911C85.4342 10.4166 85.2021 11.3321 85.2197 12.2599C87.5238 12.8384 89.9444 13.2811 92.4016 12.3983L92.9818 10.9781L93.3239 12.3993C94.0722 12.7052 94.8225 12.964 95.5727 12.3964C95.6033 11.4712 95.3906 10.5541 94.9557 9.73689C94.5208 8.91967 93.879 8.23091 93.0945 7.73947Z"
      fill="#2F2E41"
    />
    <path
      d="M84.0051 0.366395C82.4862 -0.330042 80.5804 -0.00325194 79.3157 1.0888C78.051 2.18085 77.4498 3.95818 77.6939 5.6112C77.943 7.29752 78.9682 8.74967 79.9131 10.1684C80.8581 11.5871 81.7813 13.1417 81.7803 14.8463C81.7795 16.0741 81.2919 17.2514 80.4243 18.1202C79.5568 18.9889 78.3801 19.4781 77.1523 19.4806C78.5933 20.1009 80.0973 20.7334 81.6644 20.6599C83.2315 20.5864 84.8741 19.5789 85.1297 18.0311C85.3139 16.9158 84.7667 15.8282 84.3103 14.794C83.567 13.1089 83.0297 11.3402 82.7101 9.52627C82.5577 8.66054 82.4584 7.7504 82.7407 6.9179C82.9654 6.30099 83.3825 5.77264 83.9303 5.41075C84.4782 5.04886 85.1278 4.87259 85.7834 4.90794L86.3012 4.44209C86.5316 2.78708 85.5241 1.06282 84.0051 0.366395Z"
      fill="#2F2E41"
    />
    <path
      d="M114.527 99.9679H54.692C54.6701 99.9677 54.6491 99.9493 54.6336 99.9166C54.6181 99.8839 54.6094 99.8397 54.6094 99.7935C54.6094 99.7474 54.6181 99.7031 54.6336 99.6704C54.6491 99.6377 54.6701 99.6193 54.692 99.6191H114.527C114.549 99.6193 114.57 99.6377 114.585 99.6704C114.601 99.7031 114.609 99.7474 114.609 99.7935C114.609 99.8397 114.601 99.8839 114.585 99.9166C114.57 99.9493 114.549 99.9677 114.527 99.9679Z"
      fill="#CCCCCC"
    />
    <path
      d="M81.7903 51.5781H77.4686C77.3711 51.5779 77.2747 51.599 77.1862 51.6399C77.0977 51.6808 77.0192 51.7406 76.9563 51.815C76.7969 51.7857 76.6328 51.7946 76.4775 51.841C76.3222 51.8874 76.1801 51.97 76.063 52.082C75.9458 52.1939 75.8568 52.3321 75.8034 52.4851C75.75 52.6381 75.7336 52.8016 75.7556 52.9622C75.7776 53.1228 75.8374 53.2758 75.93 53.4088C76.0226 53.5418 76.1455 53.6509 76.2885 53.7272C76.4315 53.8035 76.5905 53.8448 76.7526 53.8477C76.9146 53.8506 77.075 53.8151 77.2207 53.744L77.7255 57.9839C77.768 58.122 77.8536 58.2427 77.9698 58.3285C78.086 58.4143 78.2266 58.4606 78.371 58.4606H81.1808C81.3252 58.4606 81.4658 58.4143 81.582 58.3285C81.6981 58.2427 81.7837 58.1219 81.8262 57.9839L82.4357 52.452C82.4668 52.351 82.4737 52.2441 82.456 52.14C82.4382 52.0358 82.3963 51.9372 82.3335 51.8522C82.2707 51.7672 82.1888 51.6982 82.0945 51.6505C82.0002 51.6029 81.896 51.5781 81.7903 51.5781ZM76.7739 53.555C76.5797 53.555 76.3935 53.4779 76.2562 53.3406C76.1189 53.2033 76.0417 53.017 76.0417 52.8228C76.0417 52.6286 76.1189 52.4424 76.2562 52.3051C76.3935 52.1678 76.5797 52.0907 76.7739 52.0907C76.7873 52.0907 76.7997 52.0939 76.813 52.0946C76.7838 52.2125 76.7874 52.336 76.8232 52.452L77.1324 53.4573C77.0235 53.5205 76.8999 53.5542 76.7739 53.555Z"
      fill="#F7B80B"
    />
    <path
      d="M78.1974 58.4643C78.3747 58.2861 78.5108 58.0711 78.5961 57.8346C78.6814 57.5981 78.7137 57.3457 78.6909 57.0953C78.6681 56.8449 78.5907 56.6025 78.4641 56.3853C78.3375 56.168 78.1648 55.9812 77.9582 55.8379L80.6966 42.2363L77.6725 43.2073L75.4805 56.3634C75.2629 56.7265 75.1859 57.1568 75.2643 57.5728C75.3426 57.9888 75.5709 58.3616 75.9058 58.6206C76.2407 58.8796 76.6589 59.0067 77.0813 58.9779C77.5036 58.9491 77.9007 58.7664 78.1973 58.4643H78.1974Z"
      fill="#9E616A"
    />
    <path
      d="M83.4767 24.0478L81.6004 22.6315C81.6004 22.6315 79.9452 22.5143 79.6521 25.4992C79.3589 28.4841 77.5234 39.9402 77.5234 39.9402L74.6523 53.2381L78.6267 54.1104L81.7019 39.7597L84.7802 29.8591L83.4767 24.0478Z"
      fill="#2F2E41"
    />
    <path
      d="M99.6676 36.4289C100.254 36.4289 100.73 35.9534 100.73 35.3668C100.73 34.7802 100.254 34.3047 99.6676 34.3047C99.081 34.3047 98.6055 34.7802 98.6055 35.3668C98.6055 35.9534 99.081 36.4289 99.6676 36.4289Z"
      fill="#F7B80B"
    />
  </svg>
);
