export const ArrowsItemIcon = () => (
  <svg
    width="72"
    height="57"
    viewBox="0 0 72 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M71.25 16.6253L55.4167 0.791992V12.667H27.7083V20.5837H55.4167V32.4587M15.8333 24.542L0 40.3753L15.8333 56.2087V44.3337H43.5417V36.417H15.8333V24.542Z"
      fill="#091C25"
    />
  </svg>
);
