import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import BottomBlock from "../../components/BottomActionsBlock/BottomBlock";
import NorelaButton from "../../components/Buttons/NorelaButton";
import Header from "../../components/Header";
import { DigitalEmargementIcon } from "../../components/Icons/DigitalEmargementIcon";
import { EmargementIcon } from "../../components/Icons/EmargementIcon";
import Layout from "../../components/Layout";
import ContentLayout from "../../components/Layout/ContentLayout";
import MainContainer from "../../components/Layout/MainContainer";
import TotalCard from "../../components/TotalCard/TotalCard";
import { useOnboardingContext } from "../../context/OnboardingContext";
import { PageURL } from "../../types/pages";
import { useSessionContext } from "../../context/SessionContext";
import { useNavigate } from "react-router-dom";
import { useSegment } from "../../context/SegmentContext";

export default function OnboardingReception() {
  const { setEmargementConfig } = useOnboardingContext();
  const [emargement, setEmargement] = useState(true);
  const [digital, setdigital] = useState(true);
  const { updateHistory } = useSessionContext();
  const navigate = useNavigate();
  const { track } = useSegment();
  useEffect(() => {
    setEmargementConfig({
      emargement: emargement,
      digital: digital,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emargement, digital]);

  return (
    <Layout>
      <ContentLayout>
        <MainContainer>
          <Flex flexDir="column">
            <Header
              title="Optimisez l'accueil de vos invités"
              subtitle="Désengorgez la fil d'attente à l'accueil de votre événement avec des hôtesses dédiées à l'émargement"
            />
            <Flex
              justifyContent="space-between"
              gap="2rem"
              boxShadow="2px 2px 10px rgba(0, 0, 0, 0.25)"
              borderRadius="16px"
              p="1rem"
              mb="3rem"
            >
              <Box>
                <Text variant="xl" fontWeight="700" textAlign="left">
                  Voulez-vous de l'émargement ?
                </Text>
                <Text
                  variant="xl"
                  fontWeight="500"
                  textAlign="left"
                  maxW="460px"
                  whiteSpace="pre-wrap"
                >
                  Nous renforcerons vos équipes en début
                  <br />
                  d'événement pour éviter les engorgements
                </Text>
                <Flex justifyContent="space-evenly" mt="1rem">
                  <NorelaButton
                    onClick={() => {
                      setEmargement(true);
                    }}
                    light={!emargement}
                  >
                    Oui
                  </NorelaButton>
                  <NorelaButton
                    onClick={() => {
                      setEmargement(false);
                      setdigital(false);
                    }}
                    light={emargement}
                  >
                    Non
                  </NorelaButton>
                </Flex>
              </Box>

              <EmargementIcon />
            </Flex>
            {emargement === true && (
              <Flex
                justifyContent="space-between"
                gap="2rem"
                boxShadow="2px 2px 10px rgba(0, 0, 0, 0.25)"
                borderRadius="16px"
                p="1rem"
              >
                <Box>
                  <Text variant="xl" fontWeight="700" textAlign="left">
                    Optez pour l'émargement digital
                  </Text>
                  <Text
                    variant="xl"
                    fontWeight="500"
                    textAlign="left"
                    maxW="460px"
                    whiteSpace="pre-wrap"
                  >
                    Optimisez votre accueil grâce à l'émargement à la
                    <br />
                    tablette! Vous gagnerez en efficacité et suivrez
                    <br />
                    votre événement en temps réel.
                  </Text>
                  <Flex justifyContent="space-evenly" mt="1rem">
                    <NorelaButton
                      onClick={() => {
                        setEmargement(true);
                        setdigital(true);
                      }}
                      light={!digital}
                    >
                      Oui
                    </NorelaButton>
                    <NorelaButton
                      onClick={() => {
                        setdigital(false);
                      }}
                      light={digital}
                    >
                      Non
                    </NorelaButton>
                  </Flex>
                </Box>

                <DigitalEmargementIcon />
              </Flex>
            )}
          </Flex>
          <TotalCard />
          <BottomBlock
            onNextPage={() => {
              track("Emargement selection", {
                emargement: emargement,
                digital: digital,
              });
              updateHistory(PageURL.ONBOARDING_DETAILS as PageURL);
              navigate(PageURL.ONBOARDING_DETAILS as PageURL);
            }}
          />
        </MainContainer>
      </ContentLayout>
    </Layout>
  );
}
