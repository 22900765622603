import {
  Accordion,
  AccordionPanel,
  Button,
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  Text,
} from "@chakra-ui/react";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import AccordionWrapper from "../../../components/Accordion/AccordionWrapper";
import ButtonCheckbox from "../../../components/Buttons/CheckboxButton";
import { useCustomerContext } from "../../../context/CustomerContext";
import { useOnboardingContext } from "../../../context/OnboardingContext";
import { useUserContext } from "../../../context/UserContext";
import { OrderFields, OrderPaymentTypes } from "../../../types/order";
import { colors } from "../../../ui/theme";
import GetQuote from "./GetQuote";
import OrderSingleField from "./OrderSingleField";
import OrderTypePayment from "./OrderTypePayment";
import Pay from "./Pay";

const VALID = `${colors.green.default} !important`;
const INVALID = `${colors.gray.lightMode} !important`;
interface OrderFormValuesStep2Props {
  [OrderFields.PAYMENT_TYPE]: OrderPaymentTypes | "";
}

const validationFormSchemaStep1 = Yup.object().shape({
  pronoun: Yup.string().required(
    "Veuillez choisir le pronom par lequel nous pouvons vous appeler"
  ),
  companyName: Yup.string().required("La raison sociale est requise"),
  firstName: Yup.string().required("Le prénom est requis"),
  lastName: Yup.string().required("Le nom est requis"),
  siren: Yup.string()
    .required("Le numéro de SIREN est requis")
    .length(11, "Le numéro de SIREN doit contenir 9 chiffres"),
  email: Yup.string()
    .email("Votre adresse mail n'est pas valide")
    .required("Votre adresse mail est requise"),
  phone: Yup.string()
    .required("Votre numéro de téléphone est requis")
    .length(14, "Numéro de téléphone invalide. Il doit contenir 10 chiffres."),
  address: Yup.string().required("Votre adresse est requise"),
});

const validationFormSchemaStep2 = Yup.object().shape({
  [OrderFields.PAYMENT_TYPE]: Yup.string().required("Field is required"),
});

export const initialFormValuesStep2: OrderFormValuesStep2Props = {
  [OrderFields.PAYMENT_TYPE]: "",
};

const fields1 = [
  {
    id: OrderFields.COMPANY_NAME,
    type: OrderFields.TEXT,
    name: OrderFields.COMPANY_NAME,
    title: "Raison sociale",
    placeholder: "Nom de l’entreprise",
  },
];
const fields2 = [
  {
    id: OrderFields.FIRST_NAME,
    type: OrderFields.TEXT,
    name: OrderFields.FIRST_NAME,
    title: "Prénom de l’interlocuteur",
    placeholder: "Prénom de la personne que nous contacterons",
  },
  {
    id: OrderFields.LAST_NAME,
    type: OrderFields.TEXT,
    name: OrderFields.LAST_NAME,
    title: "Nom de l’interlocuteur",
    placeholder: "Nom de la personne que nous contacterons",
  },
  {
    id: OrderFields.SIREN,
    type: OrderFields.SIREN,
    name: OrderFields.SIREN,
    title: "Numéro Siren",
    placeholder: "012 345 678",
    formatNumberSpace: 3,
    maxLength: 11,
    helperText: "Entrez votre numéro Siren à 9 chiffres",
  },
  {
    id: OrderFields.EMAIL,
    type: OrderFields.EMAIL,
    name: OrderFields.EMAIL,
    title: "Email PRO (Nécessaire pour vous envoyer le devis)",
    placeholder: "Nom@societe.com",
    helperText: "Entrez une adresse email valide",
  },
  {
    id: OrderFields.PHONE,
    type: OrderFields.PHONE,
    name: OrderFields.PHONE,
    title: "Téléphone de contact",
    formatNumberSpace: 2,
    placeholder: "06 XX XX XX XX",
    helperText: "Entrez votre numéro de téléphone à 10 chiffres",
  },
  {
    id: OrderFields.ADDRESS,
    type: OrderFields.ADDRESS,
    name: OrderFields.ADDRESS,
    title: "Adresse",
    placeholder: "N°, rue, code postal et ville",
    helperText: "Entrez une adresse valide",
  },
];

const OrderBlock = () => {
  const [defaultIndex, setDefaultIndex] = useState(0);
  const { userInfos, setUserInfos } = useUserContext();
  const {
    customerInfo,
    setCompanyName,
    setFirstName,
    setLastName,
    setSiren,
    setEmail,
    setPhone,
    setAddress,
    setPaymentType,
    setPronoun,
  } = useCustomerContext();

  const { eventLocations } = useOnboardingContext(); // Donatien's idea, default billing address is event location
  const [activeThirdPart, setActiveThirdPart] = useState(false);

  useEffect(() => {
    setPaymentType("");
    setActiveThirdPart(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValuesStep1 = {
    pronoun: customerInfo?.pronoun || "",
    companyName: customerInfo?.companyName || "",
    firstName: customerInfo?.firstname || "",
    lastName: customerInfo?.lastname || "",
    siren: customerInfo?.siren || "",
    email: customerInfo?.email || "",
    phone: customerInfo?.phone || "",
    address: eventLocations?.[0].isAddressKnown
      ? customerInfo?.address || eventLocations?.[0].address || ""
      : "",
  };
  const validateStep1 = (values: FormikValues) => {
    setPronoun(values.pronoun);
    setCompanyName(values.companyName);
    setFirstName(values.firstName);
    setLastName(values.lastName);
    setSiren(values.siren);
    setEmail(values.email);
    setPhone(values.phone);
    setAddress(values.address);
  };
  const validateStep2 = (values: FormikValues) => {
    setPaymentType(values.paymentType);
  };
  const validateStep3 = (values: FormikValues) => {
    setUserInfos({
      ...userInfos,
      orderInfos: {
        ...userInfos?.orderInfos,
        data: {
          ...userInfos?.orderInfos?.data,
          ...values,
        },
      },
    });
  };

  const [step1Valid, setEnableStep2] = useState(false);
  function CustomerInfoForm({
    handleChange,
    setFieldValue,
    values,
    setTouched,
    errors,
    isValid: valid,
    touched,
    validateField,
  }: FormikProps<any>) {
    const isValid = valid;
    const [failedValidation, setFailedValidation] = useState(false);

    useEffect(() => {
      setEnableStep2(isValid);
    }, [isValid]);
    return (
      <Form>
        <AccordionWrapper
          borderColor={defaultIndex > 0 ? VALID : INVALID}
          title="1. Renseignez vos informations"
          fakeButton={defaultIndex > 0 ? "Modifier" : undefined}
        >
          <FormControl>
            <InputGroup marginBottom="1rem">
              <Flex flexDir="column">
                <FormLabel>
                  {fields1.map((item: any) => {
                    return (
                      <OrderSingleField
                        touched={touched}
                        setTouched={setTouched}
                        errors={(errors as any)[item.name]}
                        handleChange={handleChange}
                        values={values}
                        key={item.title}
                        validateField={validateField}
                        failedValidation={failedValidation}
                        {...item}
                      />
                    );
                  })}
                  <Text variant="xl" fontWeight="700" display="inline">
                    Quel pronom voulez-vous utiliser
                  </Text>
                  <Text
                    variant="xl"
                    fontWeight="700"
                    display="inline"
                    color="red"
                  >
                    *
                  </Text>
                </FormLabel>
                <Flex gap="2em">
                  <ButtonCheckbox
                    handleChange={() => {
                      setFieldValue("pronoun", "Monsieur");
                      validateField("pronoun");
                    }}
                    active={values.pronoun === "Monsieur"}
                    value="Monsieur"
                    name="pronoun"
                    text="Monsieur"
                  />
                  <ButtonCheckbox
                    handleChange={() => {
                      setFieldValue("pronoun", "Madame");
                      validateField("pronoun");
                    }}
                    active={values.pronoun === "Madame"}
                    value="Madame"
                    name="pronoun"
                    text="Madame"
                  />
                </Flex>
              </Flex>
            </InputGroup>
          </FormControl>
          {fields2.map((item: any) => {
            return (
              <OrderSingleField
                touched={touched}
                setTouched={setTouched}
                errors={(errors as any)[item.name]}
                handleChange={handleChange}
                values={values}
                key={item.title}
                validateField={validateField}
                failedValidation={failedValidation}
                {...item}
              />
            );
          })}
          <Button
            padding="0"
            width="100%"
            type="submit"
            onClick={() => {
              !isValid ? setFailedValidation(true) : setDefaultIndex(1);
            }}
            // disabled={!isValid} // removed to set faieldValidation to true and show the error messages
            backgroundColor={colors.blue.transparent}
          >
            <Text variant="xl" color={colors.white}>
              Suivant
            </Text>
          </Button>
        </AccordionWrapper>
      </Form>
    );
  }

  return (
    <Accordion
      display="flex"
      flexDirection="column"
      allowToggle
      maxWidth="700px"
      width="100%"
      gap="14px"
      index={defaultIndex}
      onChange={(i) => setDefaultIndex(Number(i))}
    >
      <Formik
        key={0}
        initialValues={initialValuesStep1}
        validationSchema={validationFormSchemaStep1}
        validateOnMount
        onSubmit={(values) => {
          validateStep1(values);
        }}
      >
        {CustomerInfoForm}
      </Formik>
      <Formik
        key={1}
        initialValues={initialFormValuesStep2}
        validationSchema={validationFormSchemaStep2}
        onSubmit={(values) => {
          validateStep2(values);
        }}
      >
        {({ setFieldValue, dirty, isValid: valid }) => {
          const isValid = dirty && valid;
          return (
            <Form>
              <AccordionWrapper
                isDisabled={!step1Valid}
                title="2. Choisir le type de paiement"
                borderColor={defaultIndex > 1 ? VALID : INVALID}
                fakeButton={defaultIndex > 1 ? "Modifier" : undefined}
              >
                <OrderTypePayment setFieldValue={setFieldValue} />
                <Button
                  padding="0"
                  width="100%"
                  type="submit"
                  onClick={() => setDefaultIndex(2)}
                  disabled={!isValid}
                  backgroundColor={colors.blue.transparent}
                >
                  <Text variant="xl" color={colors.white}>
                    Suivant
                  </Text>
                </Button>
              </AccordionWrapper>
            </Form>
          );
        }}
      </Formik>
      <Formik
        key={2}
        initialValues={[]}
        validationSchema={[]}
        onSubmit={(values) => {
          validateStep3(values);
        }}
      >
        {({ dirty, isValid: valid }) => {
          const isValid = dirty && valid;
          return (
            <Form>
              <AccordionWrapper
                isDisabled={!step1Valid || !customerInfo?.paymentType}
                title="3. Procédez au paiement"
                borderColor={isValid ? VALID : INVALID}
              >
                {activeThirdPart &&
                customerInfo?.paymentType === "paymentCard" ? (
                  <AccordionPanel padding="12px 32px" key={1}>
                    <Pay />
                  </AccordionPanel>
                ) : activeThirdPart && customerInfo?.paymentType === "2p2" ? (
                  <AccordionPanel padding="12px 32px" key={2}>
                    <GetQuote />
                  </AccordionPanel>
                ) : (
                  <></>
                )}
              </AccordionWrapper>
            </Form>
          );
        }}
      </Formik>
    </Accordion>
  );
};

export default OrderBlock;
