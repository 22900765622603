import { Image, ImageProps } from "@chakra-ui/react";

export enum PicturesPngTypes {
  DRESSING_ENSEMBLE_TAILLEUR = "DRESSING_ENSEMBLE_TAILLEUR",
  DRESSING_ROBE_AJUSEE = "DRESSING_ROBE_AJUSEE",
  DRESSING_ROBE_COL = "DRESSING_ROBE_COL",
  DRESSING_ROBE_EVASEE = "DRESSING_ROBE_EVASEE",
  DRESSING_ROBE_FOURREAU = "DRESSING_ROBE_FOURREAU",
  DRESSING_FOURREAU_MANCHES = "DRESSING_FOURREAU_MANCHES",
  DRESSING_ENSEMBLE_JUPE = "DRESSING_ENSEMBLE_JUPE",
  DRESSING_ENSEMBLE_TAILLEUR_NOIR = "DRESSING_ENSEMBLE_TAILLEUR_NOIR",
  DRESSING_ENSEMBLE_TAILLEUR_BLUE_MARINE = "DRESSING_ENSEMBLE_TAILLEUR_BLUE_MARINE",
}

const PicturePngVariants = ({
  image,
  ...props
}: {
  image: PicturesPngTypes | undefined;
} & ImageProps) => {
  const picture = {
    [PicturesPngTypes.DRESSING_ENSEMBLE_TAILLEUR]:
      "/images/dressing/ensembleTailleurPastel.png",
    [PicturesPngTypes.DRESSING_ROBE_AJUSEE]:
      "/images/dressing/robeAjusteeEncolureDroite.png",
    [PicturesPngTypes.DRESSING_ROBE_COL]:
      "/images/dressing/robeColClaudine.png",
    [PicturesPngTypes.DRESSING_ROBE_EVASEE]: "/images/dressing/robeEvasee.png",
    [PicturesPngTypes.DRESSING_FOURREAU_MANCHES]:
      "/images/dressing/robeFourreauManchesCourtes.png",
    [PicturesPngTypes.DRESSING_ROBE_FOURREAU]:
      "/images/dressing/robeFourreauSansManche.png",
    [PicturesPngTypes.DRESSING_ENSEMBLE_JUPE]:
      "/images/dressing/ensembleJupeTailleur.png",
    [PicturesPngTypes.DRESSING_ENSEMBLE_TAILLEUR_NOIR]:
      "/images/dressing/ensembleTailleurNoir.png",
    [PicturesPngTypes.DRESSING_ENSEMBLE_TAILLEUR_BLUE_MARINE]:
      "/images/dressing/ensembleTailleurBleuMarine.png",
  };
  const url = image
    ? picture[image]
    : "/images/dressing/ensembleTailleurPastel.png";
  return <Image src={url} alt="dressingPhoto" {...props} />;
};

export default PicturePngVariants;
