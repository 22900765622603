import { FC } from "react";
import Layout from "../../components/Layout";
import CalendarPage from "../../modules/Calendar/CalendarPage";

const Calendar: FC = () => {
  return (
    <Layout>
      <CalendarPage />
    </Layout>
  );
};

export default Calendar;
