export const DressingShoesIcon = () => (
  <svg
    width="56"
    height="39"
    viewBox="0 0 56 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.66322 1.47995L28.7501 0V10.6232L52.6008 26.9038C53.7789 27.7078 54.6661 28.8629 55.133 30.2005C55.5998 31.5381 55.6219 32.9882 55.1958 34.3391C54.7698 35.6899 53.918 36.8708 52.7648 37.7093C51.6117 38.5478 50.2174 39.0001 48.7858 39H18.919L13.7709 28.492L11.761 39H0.5L3.66322 1.47995Z"
      fill="#2F2E41"
    />
  </svg>
);
