export const OptionsTabletteNumeriqueIcon = () => (
  <svg
    width="63"
    height="100"
    viewBox="0 0 63 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1454_52547)">
      <path
        d="M7.39767 25.2988H7.19727V27.159H7.39767V25.2988Z"
        fill="#F2F2F2"
      />
      <path
        d="M19.9231 29.8223H19.7227V31.6824H19.9231V29.8223Z"
        fill="#F2F2F2"
      />
      <path
        d="M18.5207 76.4766H18.3203V78.3367H18.5207V76.4766Z"
        fill="#F2F2F2"
      />
      <path
        d="M21.427 61.0938H21.2266V62.9539H21.427V61.0938Z"
        fill="#F2F2F2"
      />
      <path
        d="M8.41602 20.0673C8.41602 20.0673 9.8808 24.2507 8.97939 24.8943C8.07798 25.5379 12.0216 30.7939 12.0216 30.7939L16.754 29.5068L14.1625 25.1088C14.1625 25.1088 13.8245 20.7109 13.8245 20.0673C13.8245 19.4237 8.41602 20.0673 8.41602 20.0673Z"
        fill="#A0616A"
      />
      <path
        opacity="0.1"
        d="M8.41602 20.0673C8.41602 20.0673 9.8808 24.2507 8.97939 24.8943C8.07798 25.5379 12.0216 30.7939 12.0216 30.7939L16.754 29.5068L14.1625 25.1088C14.1625 25.1088 13.8245 20.7109 13.8245 20.0673C13.8245 19.4237 8.41602 20.0673 8.41602 20.0673Z"
        fill="black"
      />
      <path
        d="M5.95762 49.8867C5.95762 49.8867 5.53003 55.25 5.95762 58.039C6.38521 60.8279 7.0266 68.5511 7.0266 68.5511C7.0266 68.5511 7.0266 83.1394 9.37835 83.3539C11.7301 83.5684 13.4405 83.783 13.6543 82.9248C13.8681 82.0667 12.5853 81.6376 13.2267 81.2086C13.868 80.7795 14.0818 79.2778 13.2267 77.5615C12.3715 75.8452 13.2267 58.468 13.2267 58.468L17.2888 69.4092C17.2888 69.4092 17.7164 78.4196 18.144 79.7068C18.5716 80.994 17.7164 83.3539 19.2129 83.5684C20.7095 83.783 22.4199 82.4958 23.2751 82.0667C24.1302 81.6376 21.9923 81.6376 22.4199 81.4231C22.8475 81.2085 24.1302 80.565 23.7026 80.3504C23.275 80.1359 22.8475 69.8383 22.8475 69.8383C22.8475 69.8383 21.6716 47.8487 20.175 46.9905C18.6784 46.1324 17.7164 47.6105 17.7164 47.6105L5.95762 49.8867Z"
        fill="#2F2E41"
      />
      <path
        d="M9.80635 82.4961V84.2124C9.80635 84.2124 7.88219 88.8777 9.80635 88.8777C11.7305 88.8777 13.2271 89.3611 13.2271 88.7176V82.9252L9.80635 82.4961Z"
        fill="#2F2E41"
      />
      <path
        d="M22.4207 82.4941V84.2104C22.4207 84.2104 24.3449 88.8757 22.4207 88.8757C20.4966 88.8757 19 89.3592 19 88.7156V82.9232L22.4207 82.4941Z"
        fill="#2F2E41"
      />
      <path
        d="M11.4089 23.1763C13.5342 23.1763 15.2572 21.4474 15.2572 19.3147C15.2572 17.182 13.5342 15.4531 11.4089 15.4531C9.2835 15.4531 7.56055 17.182 7.56055 19.3147C7.56055 21.4474 9.2835 23.1763 11.4089 23.1763Z"
        fill="#A0616A"
      />
      <path
        d="M10.2342 26.502C10.2342 26.502 11.3032 29.7199 13.0136 29.0763L14.7239 28.4327L17.7171 49.0279C17.7171 49.0279 15.3653 52.4605 11.9446 50.3151C8.52387 48.1698 10.2342 26.502 10.2342 26.502Z"
        fill="#F7B80B"
      />
      <path
        d="M13.2266 25.8603L14.1886 23.8223C14.1886 23.8223 19.8542 26.5039 20.7094 27.3621C21.5646 28.2202 21.5646 29.5074 21.5646 29.5074L20.068 34.8707C20.068 34.8707 20.4956 46.67 20.4956 47.0991C20.4956 47.5282 21.9922 49.888 20.9232 49.0299C19.8542 48.1718 19.6404 47.3136 18.7852 48.6008C17.9301 49.888 16.0059 51.3898 16.0059 51.3898L13.2266 25.8603Z"
        fill="#3F3D56"
      />
      <path
        d="M20.7097 41.3066L20.0683 47.3136C20.0683 47.3136 16.22 50.7461 17.0752 50.9606C17.9304 51.1752 18.3579 50.317 18.3579 50.317C18.3579 50.317 19.8545 51.8188 20.7097 50.9606C21.5649 50.1025 23.7028 41.9502 23.7028 41.9502L20.7097 41.3066Z"
        fill="#A0616A"
      />
      <path
        d="M11.492 13.8588C10.6389 13.8282 9.72592 13.813 9.00662 14.2743C8.67566 14.5059 8.38538 14.791 8.14766 15.1181C7.4471 16.0068 6.84178 17.1249 7.102 18.2275L7.40424 18.1093C7.40423 18.4036 7.33903 18.6942 7.21334 18.9602C7.2559 18.836 7.39844 19.0367 7.36028 19.1623L7.02734 20.2584C7.57465 20.057 8.25547 20.4647 8.33879 21.0436C8.37685 19.7706 8.50846 18.3108 9.53761 17.5654C10.0566 17.1894 10.7134 17.0747 11.3454 16.9728C11.9284 16.8788 12.5396 16.7891 13.098 16.9817C13.6564 17.1743 14.132 17.7474 14.0054 18.3261C14.2628 18.2371 14.5508 18.4172 14.6781 18.6588C14.8053 18.9004 14.812 19.1854 14.8158 19.4587C15.0903 19.6533 15.4026 19.2668 15.5145 18.9488C15.7771 18.2023 16.0104 17.4076 15.869 16.6288C15.7275 15.85 15.0951 15.1058 14.3062 15.1031C14.402 14.9976 14.4531 14.8588 14.4487 14.7162L13.7984 14.661C13.9635 14.6376 14.1153 14.5573 14.2279 14.4338C13.9668 14.7221 11.9142 13.8739 11.492 13.8588Z"
        fill="#2F2E41"
      />
      <path
        d="M11.3034 25.2167C11.3034 25.2167 9.56294 23.5053 8.93658 23.6101C8.31023 23.7149 7.45504 25.2167 7.45504 25.2167C7.45504 25.2167 2.32396 26.9329 2.53775 28.6492C2.75155 30.3655 5.10329 38.7322 5.10329 38.7322C5.10329 38.7322 7.02745 48.8153 5.31709 49.888C3.60672 50.9606 13.4413 53.7496 13.6551 52.4624C13.8689 51.1752 14.2965 38.3032 13.6551 36.3724C13.0137 34.4416 11.3034 25.2167 11.3034 25.2167Z"
        fill="#3F3D56"
      />
      <path
        d="M18.7852 28.6484H21.4287C21.4287 28.6484 23.2749 36.8007 23.4887 37.6588C23.7025 38.517 24.13 42.5931 23.9162 42.5931C23.7024 42.5931 19.4265 41.735 19.4265 42.3786L18.7852 28.6484Z"
        fill="#3F3D56"
      />
      <path
        d="M18.697 48.8357H8.57664C7.58904 44.2769 7.50653 39.8723 8.57664 35.6641H18.697C17.0638 39.7966 16.9617 44.1711 18.697 48.8357Z"
        fill="#F2F2F2"
      />
      <path
        d="M4.46094 43.2366L7.45407 44.5238C7.45407 44.5238 13.2266 45.3819 13.2266 43.0221C13.2266 40.6622 7.45407 41.9494 7.45407 41.9494L5.52979 41.332L4.46094 43.2366Z"
        fill="#A0616A"
      />
      <path
        d="M5.1024 27.3613L2.53686 28.0049L0.612704 39.3752C0.612704 39.3752 -0.0286894 42.3786 1.04029 42.5932C2.10927 42.8077 5.1024 44.524 5.1024 44.524C5.1024 44.524 5.53 41.306 6.38518 41.306L4.24722 39.5897L5.53 32.0811L5.1024 27.3613Z"
        fill="#3F3D56"
      />
    </g>
    <g clipPath="url(#clip1_1454_52547)">
      <path
        d="M25.7436 56.9997C25.9326 56.8121 26.078 56.5855 26.1697 56.3358C26.2614 56.0861 26.297 55.8194 26.2742 55.5544C26.2514 55.2894 26.1706 55.0327 26.0376 54.8022C25.9046 54.5718 25.7225 54.3732 25.5043 54.2206L28.4802 39.8496L25.2695 40.8603L22.8751 54.7631C22.6424 55.1459 22.5585 55.6006 22.6394 56.041C22.7202 56.4814 22.9601 56.877 23.3137 57.1527C23.6673 57.4284 24.11 57.5651 24.5578 57.5369C25.0057 57.5087 25.4276 57.3176 25.7436 56.9997Z"
        fill="#FFB6B6"
      />
      <path
        d="M32.6215 18.5846L30.6326 17.0866C30.6326 17.0866 28.8781 16.9626 28.5674 20.1197C28.2566 23.2767 26.311 35.3934 26.311 35.3934L23.2676 49.458L27.4805 50.3807L30.7402 35.2025L34.0033 24.731L32.6215 18.5846Z"
        fill="#E6E6E6"
      />
      <path
        d="M32.111 4.06236C31.8832 5.28346 31.5097 6.47296 30.9984 7.60544C28.0708 13.7851 36.4082 11.7709 39.3878 11.7709C42.6454 11.7709 45.7623 13.4801 45.2863 5.88543C45.0829 2.64133 42.6454 0 39.3878 0C36.6052 0 32.6929 1.23406 32.111 4.06236Z"
        fill="#2F2E41"
      />
      <path
        d="M42.4245 94.6956H39.6489L38.3281 84.0137H42.4245V94.6956Z"
        fill="#FFB6B6"
      />
      <path
        d="M43.8896 99.4943H41.8991L41.5438 97.6193L40.6339 99.4943H35.3548C35.1017 99.4943 34.8553 99.4136 34.6514 99.264C34.4476 99.1144 34.297 98.9036 34.2217 98.6626C34.1464 98.4215 34.1503 98.1628 34.2328 97.9241C34.3154 97.6854 34.4722 97.4793 34.6804 97.3358L38.8962 94.4308V92.5352L43.3305 92.7992L43.8896 99.4943Z"
        fill="#2F2E41"
      />
      <path
        d="M58.9136 91.2006L56.6863 92.8532L49.2383 85.068L52.5255 82.6289L58.9136 91.2006Z"
        fill="#FFB6B6"
      />
      <path
        d="M62.9585 94.1782L61.3613 95.3633L59.9548 94.0702L60.346 96.1166L56.1097 99.2598C55.9067 99.4105 55.6607 99.4924 55.4076 99.4937C55.1546 99.495 54.9077 99.4156 54.7031 99.267C54.4986 99.1184 54.3469 98.9084 54.2704 98.6678C54.1939 98.4271 54.1965 98.1683 54.2778 97.9292L55.9233 93.088L54.7897 91.5669L58.5059 89.1387L62.9585 94.1782Z"
        fill="#2F2E41"
      />
      <path
        d="M33.334 45.5354L46.5095 43.4878L43.8893 35.623H33.334V45.5354Z"
        fill="#FFB6B6"
      />
      <path
        d="M45.6553 39.584C45.6553 39.584 50.9215 44.1236 48.4917 57.0649L45.5254 69.7453L53.6072 83.7453L49.4978 85.9818L39.5384 72.1702L34.981 61.5038L32.5703 42.8098L45.6553 39.584Z"
        fill="#2F2E41"
      />
      <path
        d="M37.4926 64.543L37.1758 69.4374L38.103 83.9311L42.7064 85.5126L43.5781 70.6623L37.4926 64.543Z"
        fill="#2F2E41"
      />
      <path
        d="M36.0777 13.4004L41.1706 13.5986L43.2003 16.9604L47.8313 17.8625L45.0667 31.8172L45.5751 39.76L40.964 39.732C37.6408 39.7117 34.427 40.9164 31.9392 43.1148L32.0772 32.8044L30.0938 25.1078L30.5395 17.0374L34.2649 16.5727L36.0777 13.4004Z"
        fill="#E6E6E6"
      />
      <path
        d="M38.8987 11.7683C41.5459 11.7683 43.6919 9.62705 43.6919 6.9857C43.6919 4.34436 41.5459 2.20312 38.8987 2.20312C36.2515 2.20312 34.1055 4.34436 34.1055 6.9857C34.1055 9.62705 36.2515 11.7683 38.8987 11.7683Z"
        fill="#FFB6B6"
      />
      <path
        d="M35.6436 2.11124C36.4836 1.60034 37.4456 1.32396 38.4292 1.31102C39.4129 1.29808 40.3819 1.54906 41.235 2.03769C42.0882 2.52633 42.794 3.23469 43.2789 4.0887C43.7637 4.94271 44.0097 5.91101 43.9911 6.89235C41.5487 7.5042 38.9828 7.97243 36.3782 7.03869L35.7631 5.53662L35.4005 7.03981C34.6073 7.3633 33.812 7.637 33.0167 7.03668C32.9843 6.05811 33.2098 5.08818 33.6708 4.22384C34.1318 3.3595 34.812 2.63103 35.6436 2.11124Z"
        fill="#2F2E41"
      />
      <path
        d="M51.4364 55.7614C51.2484 55.5729 51.1041 55.3455 51.0137 55.0953C50.9233 54.8452 50.889 54.5783 50.9132 54.3134C50.9373 54.0486 51.0194 53.7922 51.1536 53.5625C51.2878 53.3327 51.4709 53.1351 51.6899 52.9835L48.7871 38.5977L51.9927 39.6246L54.3162 53.5393C54.547 53.9233 54.6285 54.3785 54.5455 54.8185C54.4624 55.2585 54.2204 55.6528 53.8655 55.9266C53.5105 56.2005 53.0671 56.335 52.6194 56.3046C52.1717 56.2741 51.7508 56.0808 51.4364 55.7614Z"
        fill="#FFB6B6"
      />
      <path
        d="M45.8427 19.36L47.8316 17.862C47.8316 17.862 49.5861 17.738 49.8969 20.8951C50.2076 24.0521 52.1532 36.1688 52.1532 36.1688L55.1967 50.2334L50.9837 51.1561L47.724 35.9778L44.4609 25.5064L45.8427 19.36Z"
        fill="#E6E6E6"
      />
      <path
        d="M25.2755 56.6992L15.735 49.2974C15.5864 49.1819 15.4898 49.0122 15.4664 48.8257C15.4431 48.6392 15.4948 48.451 15.6102 48.3025L22.1015 39.9727C22.2173 39.8244 22.3873 39.7281 22.5743 39.7047C22.7612 39.6814 22.9498 39.733 23.0987 39.8482L32.6392 47.2499C32.7878 47.3655 32.8843 47.5351 32.9077 47.7217C32.9311 47.9082 32.8794 48.0964 32.7639 48.2449L26.2727 56.5747C26.1569 56.7229 25.9868 56.8193 25.7999 56.8426C25.613 56.866 25.4244 56.8144 25.2755 56.6992Z"
        fill="#3F3D56"
      />
      <path
        d="M25.2226 55.9338L16.4914 49.16C16.3595 49.0574 16.2737 48.9067 16.2529 48.7411C16.2322 48.5755 16.2781 48.4084 16.3806 48.2766L22.2652 40.7252C22.3681 40.5936 22.519 40.508 22.685 40.4873C22.851 40.4666 23.0184 40.5124 23.1506 40.6147L31.8818 47.3885C32.0137 47.4911 32.0995 47.6418 32.1203 47.8074C32.141 47.973 32.0951 48.1401 31.9926 48.272L26.108 55.8233C26.0051 55.9549 25.8542 56.0405 25.6882 56.0612C25.5222 56.0819 25.3547 56.0361 25.2226 55.9338Z"
        fill="white"
      />
      <path
        d="M17.637 48.966C17.604 48.9404 17.5826 48.9027 17.5774 48.8613C17.5722 48.8199 17.5837 48.7782 17.6093 48.7452L20.5779 44.9358C20.6036 44.9028 20.6413 44.8814 20.6829 44.8762C20.7034 44.8737 20.7243 44.8751 20.7442 44.8806C20.7642 44.8861 20.7829 44.8955 20.7993 44.9081C20.8156 44.9208 20.8293 44.9366 20.8395 44.9546C20.8498 44.9725 20.8564 44.9923 20.8589 45.0128C20.8615 45.0333 20.86 45.0542 20.8545 45.0741C20.849 45.094 20.8397 45.1127 20.827 45.129L17.8583 48.9384C17.8326 48.9713 17.7949 48.9927 17.7534 48.9979C17.7119 49.0031 17.67 48.9916 17.637 48.966Z"
        fill="#E4E4E4"
      />
      <path
        d="M18.1292 49.3489C18.0962 49.3232 18.0748 49.2856 18.0696 49.2441C18.0644 49.2027 18.0759 49.161 18.1015 49.128L21.0701 45.3186C21.0958 45.2856 21.1335 45.2642 21.175 45.259C21.2166 45.2538 21.2584 45.2653 21.2915 45.291C21.3245 45.3166 21.3459 45.3542 21.3511 45.3957C21.3563 45.4371 21.3448 45.4788 21.3192 45.5118L18.3505 49.3212C18.3248 49.3541 18.2871 49.3755 18.2456 49.3807C18.2041 49.3859 18.1622 49.3744 18.1292 49.3489Z"
        fill="#E4E4E4"
      />
      <path
        d="M20.3011 47.5718C20.2681 47.5462 20.2466 47.5085 20.2415 47.4671C20.2363 47.4257 20.2477 47.3839 20.2734 47.3509L21.5602 45.6997C21.5729 45.6833 21.5887 45.6696 21.6067 45.6594C21.6247 45.6491 21.6446 45.6425 21.6652 45.6399C21.6857 45.6373 21.7066 45.6388 21.7266 45.6443C21.7466 45.6498 21.7653 45.6591 21.7817 45.6718C21.7981 45.6845 21.8118 45.7003 21.822 45.7183C21.8323 45.7363 21.8389 45.7561 21.8414 45.7766C21.844 45.7972 21.8424 45.818 21.8369 45.838C21.8314 45.8579 21.822 45.8766 21.8092 45.8929L20.5224 47.5442C20.4967 47.5771 20.459 47.5985 20.4175 47.6036C20.376 47.6088 20.3341 47.5974 20.3011 47.5718Z"
        fill="#E4E4E4"
      />
      <path
        d="M20.9651 50.8131C20.9322 50.7874 20.9107 50.7498 20.9055 50.7084C20.9003 50.667 20.9118 50.6252 20.9374 50.5922L25.624 44.5783C25.6367 44.562 25.6525 44.5484 25.6705 44.5382C25.6885 44.5279 25.7083 44.5214 25.7289 44.5188C25.7495 44.5162 25.7703 44.5177 25.7903 44.5232C25.8103 44.5287 25.8289 44.538 25.8453 44.5507C25.8616 44.5634 25.8753 44.5792 25.8856 44.5971C25.8958 44.6151 25.9024 44.6349 25.905 44.6554C25.9076 44.6759 25.9061 44.6967 25.9006 44.7167C25.8951 44.7366 25.8857 44.7552 25.873 44.7716L21.1865 50.7854C21.1608 50.8183 21.123 50.8397 21.0815 50.8449C21.04 50.8501 20.9982 50.8386 20.9651 50.8131Z"
        fill="#E4E4E4"
      />
      <path
        d="M21.4573 51.194C21.4243 51.1683 21.4029 51.1306 21.3977 51.0892C21.3925 51.0478 21.404 51.0061 21.4296 50.9731L26.1162 44.9592C26.1289 44.9429 26.1447 44.9292 26.1627 44.919C26.1807 44.9088 26.2005 44.9022 26.2211 44.8997C26.2416 44.8971 26.2625 44.8986 26.2825 44.9041C26.3024 44.9095 26.3211 44.9189 26.3375 44.9316C26.3538 44.9443 26.3675 44.96 26.3778 44.978C26.388 44.996 26.3946 45.0158 26.3972 45.0363C26.3998 45.0568 26.3983 45.0776 26.3928 45.0975C26.3873 45.1175 26.3779 45.1361 26.3652 45.1524L21.6787 51.1663C21.653 51.1992 21.6152 51.2206 21.5737 51.2258C21.5322 51.231 21.4904 51.2195 21.4573 51.194Z"
        fill="#E4E4E4"
      />
      <path
        d="M22.5745 52.0611C22.5415 52.0355 22.5201 51.9978 22.5149 51.9564C22.5097 51.915 22.5212 51.8732 22.5468 51.8403L27.2333 45.8264C27.2461 45.8101 27.2619 45.7964 27.2799 45.7862C27.2979 45.776 27.3177 45.7694 27.3383 45.7668C27.3588 45.7643 27.3797 45.7658 27.3997 45.7713C27.4196 45.7767 27.4383 45.7861 27.4547 45.7988C27.471 45.8114 27.4847 45.8272 27.4949 45.8452C27.5052 45.8632 27.5118 45.883 27.5144 45.9035C27.5169 45.924 27.5154 45.9448 27.5099 45.9647C27.5045 45.9846 27.4951 46.0033 27.4824 46.0196L22.7958 52.0335C22.7701 52.0664 22.7324 52.0878 22.6909 52.093C22.6494 52.0981 22.6076 52.0867 22.5745 52.0611Z"
        fill="#E4E4E4"
      />
      <path
        d="M23.0667 52.444C23.0337 52.4183 23.0123 52.3806 23.0071 52.3392C23.0019 52.2978 23.0134 52.2561 23.039 52.2231L27.7255 46.2092C27.7382 46.1929 27.7541 46.1792 27.7721 46.169C27.7901 46.1588 27.8099 46.1522 27.8305 46.1497C27.851 46.1471 27.8719 46.1486 27.8918 46.1541C27.9118 46.1595 27.9305 46.1689 27.9469 46.1816C27.9632 46.1943 27.9769 46.21 27.9871 46.228C27.9974 46.246 28.004 46.2658 28.0066 46.2863C28.0091 46.3068 28.0076 46.3276 28.0021 46.3475C27.9967 46.3675 27.9873 46.3861 27.9746 46.4024L23.288 52.4163C23.2623 52.4492 23.2246 52.4706 23.1831 52.4758C23.1416 52.481 23.0998 52.4695 23.0667 52.444Z"
        fill="#E4E4E4"
      />
      <path
        d="M24.1839 53.3092C24.1509 53.2835 24.1295 53.2459 24.1243 53.2045C24.1191 53.1631 24.1306 53.1213 24.1562 53.0883L28.8427 47.0744C28.8554 47.0581 28.8712 47.0445 28.8892 47.0343C28.9072 47.024 28.9271 47.0175 28.9477 47.0149C28.9682 47.0123 28.989 47.0138 29.009 47.0193C29.029 47.0248 29.0477 47.0341 29.064 47.0468C29.0804 47.0595 29.0941 47.0753 29.1043 47.0932C29.1146 47.1112 29.1212 47.131 29.1237 47.1515C29.1263 47.172 29.1248 47.1928 29.1193 47.2128C29.1138 47.2327 29.1045 47.2513 29.0917 47.2677L24.4052 53.2815C24.3795 53.3144 24.3418 53.3358 24.3003 53.341C24.2588 53.3462 24.2169 53.3347 24.1839 53.3092Z"
        fill="#E4E4E4"
      />
      <path
        d="M24.6761 53.6916C24.6431 53.6659 24.6216 53.6283 24.6165 53.5869C24.6113 53.5455 24.6227 53.5037 24.6484 53.4707L29.3349 47.4569C29.3606 47.4241 29.3983 47.4028 29.4398 47.3977C29.4812 47.3926 29.523 47.4041 29.5559 47.4296C29.5889 47.4552 29.6103 47.4928 29.6156 47.5341C29.6208 47.5754 29.6095 47.6171 29.5839 47.6501L24.8974 53.664C24.8717 53.6969 24.834 53.7183 24.7925 53.7234C24.751 53.7286 24.7091 53.7172 24.6761 53.6916Z"
        fill="#E4E4E4"
      />
      <path
        d="M22.8466 44.5436C23.4684 44.5436 23.9724 44.0406 23.9724 43.4202C23.9724 42.7998 23.4684 42.2969 22.8466 42.2969C22.2248 42.2969 21.7207 42.7998 21.7207 43.4202C21.7207 44.0406 22.2248 44.5436 22.8466 44.5436Z"
        fill="#F7B80B"
      />
    </g>
    <defs>
      <clipPath id="clip0_1454_52547">
        <rect
          width="25.2508"
          height="75.2093"
          fill="white"
          transform="translate(0.458984 13.8457)"
        />
      </clipPath>
      <clipPath id="clip1_1454_52547">
        <rect
          width="49.4465"
          height="100"
          fill="white"
          transform="translate(13.375)"
        />
      </clipPath>
    </defs>
  </svg>
);
