export const RoleBarmanIcon = () => (
  <svg
    width="180"
    height="120"
    viewBox="0 0 180 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M151.284 119.989L151.275 119.906C151.132 118.499 151.296 117.078 151.758 115.741C152.219 114.405 152.966 113.185 153.946 112.166C154.501 111.599 155.124 111.104 155.803 110.693L155.867 110.653L155.888 110.726C156.142 111.593 156.479 112.468 156.634 112.855L156.975 110.078L157.049 110.046C157.387 109.898 157.761 109.854 158.124 109.92C158.487 109.987 158.822 110.16 159.085 110.418C159.358 110.675 159.548 111.008 159.631 111.374C159.713 111.74 159.684 112.123 159.547 112.472C159.425 112.792 159.307 113.124 159.194 113.446C158.803 114.551 158.4 115.694 157.717 116.629C157.193 117.332 156.519 117.91 155.744 118.319C154.969 118.728 154.112 118.959 153.236 118.994L151.284 119.989Z"
      fill="#F2F2F2"
    />
    <path
      d="M81.1209 118.994C80.245 118.959 79.3882 118.728 78.6129 118.319C77.8376 117.909 77.1635 117.332 76.6399 116.629C75.9572 115.694 75.5534 114.551 75.163 113.446C75.0493 113.124 74.9318 112.792 74.8099 112.472C74.673 112.123 74.6439 111.74 74.7262 111.374C74.8085 111.008 74.9986 110.675 75.2718 110.418C75.5352 110.16 75.8699 109.987 76.2327 109.92C76.5956 109.854 76.9699 109.898 77.3076 110.046L77.3813 110.078L77.7228 112.855C77.8773 112.468 78.2147 111.593 78.4683 110.726L78.4896 110.653L78.5543 110.693C79.2324 111.104 79.8561 111.599 80.4105 112.166C81.391 113.185 82.1377 114.405 82.599 115.741C83.0603 117.078 83.2251 118.499 83.0818 119.906L83.0728 119.989L81.1209 118.994Z"
      fill="#F2F2F2"
    />
    <path
      d="M58.8934 62.0904C58.8934 62.0904 57.9866 54.7593 55.9436 52.5254L51.135 54.3879L54.9319 64.0772L62.6149 73.7694C62.6536 74.1289 62.7867 74.4718 63.0007 74.7632C63.2147 75.0546 63.502 75.2841 63.8335 75.4286C64.1649 75.5731 64.5286 75.6273 64.8878 75.5857C65.2469 75.5441 65.5887 75.4082 65.8783 75.1918C66.168 74.9755 66.3952 74.6863 66.537 74.3537C66.6788 74.0211 66.73 73.6569 66.6855 73.2981C66.6411 72.9393 66.5025 72.5986 66.2838 72.3107C66.0651 72.0227 65.7741 71.7978 65.4404 71.6587L58.8934 62.0904Z"
      fill="#A0616A"
    />
    <path
      d="M34.3846 66.1759C34.3846 66.1759 37.6737 59.5617 37.2026 56.5712L32.1647 55.4707L29.9846 65.6465L31.0436 77.9694C30.8775 78.2906 30.7994 78.65 30.817 79.0111C30.8347 79.3722 30.9476 79.7223 31.1443 80.0256C31.341 80.329 31.6144 80.575 31.9369 80.7385C32.2593 80.902 32.6193 80.9773 32.9803 80.9567C33.3412 80.9361 33.6903 80.8204 33.992 80.6212C34.2938 80.4221 34.5375 80.1467 34.6984 79.8229C34.8593 79.4991 34.9317 79.1385 34.9082 78.7777C34.8847 78.4169 34.7662 78.0688 34.5647 77.7686L34.3846 66.1759Z"
      fill="#A0616A"
    />
    <path
      d="M40.2026 116.861H42.5859L43.7196 107.667L40.2021 107.667L40.2026 116.861Z"
      fill="#9E616A"
    />
    <path
      d="M40.1188 119.347L47.4476 119.347V119.254C47.4476 118.498 47.147 117.772 46.6121 117.237C46.0771 116.702 45.3516 116.401 44.595 116.401L43.2561 115.386L40.7584 116.402L40.1187 116.402L40.1188 119.347Z"
      fill="#2F2E41"
    />
    <path
      d="M46.7322 115.512L49.0568 116.037L52.1892 107.32L48.7581 106.545L46.7322 115.512Z"
      fill="#9E616A"
    />
    <path
      d="M46.1023 117.919L53.251 119.534L53.2714 119.444C53.4381 118.706 53.3049 117.932 52.901 117.292C52.4971 116.652 51.8557 116.199 51.1177 116.032L50.0357 114.747L47.3755 115.187L46.7514 115.046L46.1023 117.919Z"
      fill="#2F2E41"
    />
    <path
      d="M57.4678 91.8074C56.0525 96.3167 52.4324 108.286 52.4324 108.286C52.4324 108.286 49.3512 109.055 48.3791 108.286C47.4071 107.516 48.6176 104.947 48.6176 104.947C47.9768 102.069 49.4187 99.1605 49.5123 96.3703C49.3179 95.1993 50.3485 94.194 50.4428 93.0773C49.7616 92.5588 46.4472 84.4225 45.7714 82.149C46.1187 89.0765 45.2796 99.4632 44.3259 106.14C43.8745 109.3 43.2451 108.376 40.0343 108.524C38.6027 108.099 38.8422 104.947 38.8422 104.947V102.325C38.1463 99.3874 38.1371 98.753 38.3653 97.3173C38.2607 95.7933 36.949 80.0365 35.7851 74.7034C35.0094 70.4552 44.5158 69.52 47.2497 68.507C51.8117 66.3089 51.0418 69.4339 53.4443 72.3126C55.7608 77.275 57.1253 86.1362 57.4678 91.8074Z"
      fill="#2F2E41"
    />
    <path
      d="M48.3153 45.7399L46.0088 42.4427H41.9556L38.3793 45.7809L38.0786 75.4401L54.2628 77.2547L48.3153 45.7399Z"
      fill="#F5B806"
    />
    <path
      d="M46.7119 43.2178L53.1338 46.1569L54.3538 65.0949C54.3538 65.0949 55.3836 67.5274 55.4656 67.767C55.5516 68.0183 55.723 69.2586 55.6488 69.4649C55.4211 70.1305 55.3058 70.8294 55.3075 71.5328C55.3632 72.0727 55.9397 73.0361 56.0904 73.5575C56.1944 73.9169 55.546 75.1095 55.546 75.1095C55.812 75.5099 55.9869 75.964 56.0582 76.4395C56.1295 76.915 56.0955 77.4003 55.9586 77.8612L55.7844 78.4477L52.4465 77.9708L51.0073 70.8945L50.8554 70.1476L50.6982 69.3747L50.5754 68.7708L50.4662 68.2339L50.3746 67.7832L46.7119 43.2178Z"
      fill="#3F3D56"
    />
    <path
      d="M49.0806 47.1106L53.1338 46.1569C55.0893 48.4314 56.6784 52.9168 57.9313 57.0034C58.0205 57.2943 57.9505 57.6961 58.1937 57.869C58.6357 58.1832 58.8993 58.5624 58.5797 59.1709C58.4576 59.4034 58.7263 59.671 58.7974 59.9149C58.8573 60.1202 58.7759 60.4619 58.9742 60.5209C59.5413 60.6898 59.5686 61.1746 59.3467 61.7893L54.5782 64.1737L54.4423 63.9542L53.9067 63.0893L51.6021 59.3671L49.0806 47.1106Z"
      fill="#3F3D56"
    />
    <path
      d="M41.0021 43.3966L37.3978 46.6338C37.3978 46.6338 36.5544 60.3388 35.9952 65.5718C35.9714 65.7943 36.2818 66.3257 36.258 66.5483L36.1183 67.855L36.0133 68.8382C35.9755 69.1917 35.5184 72.7251 35.5184 72.7251L34.8032 73.2019C34.4991 74.5665 34.6692 75.994 35.2853 77.2489L35.7568 78.2092L40.9742 77.6313L41.0021 43.3966Z"
      fill="#3F3D56"
    />
    <path
      d="M38.8529 48.6642L37.3977 46.6337L32.8676 49.495C31.868 52.2606 30.7717 57.086 30.1662 61.2825C30.1525 61.3774 30.2166 61.5376 30.1258 61.5664C29.7996 61.6699 29.701 61.8336 30.0512 62.1171C30.0919 62.1679 30.122 62.2263 30.1398 62.289C30.1575 62.3517 30.1625 62.4172 30.1544 62.4819C30.1463 62.5465 30.1254 62.6088 30.0928 62.6652C30.0602 62.7216 30.0166 62.7708 29.9646 62.81C29.5806 62.9926 29.2854 63.173 29.9034 63.3494C30.338 63.4736 29.8141 64.2339 29.782 64.6505L35.0273 65.8427C35.5066 65.7048 35.5975 65.4241 35.3422 65.016C35.7373 64.8968 35.8869 64.6849 35.6104 64.3119L35.7401 63.9714C36.1808 63.9034 36.1747 63.7207 35.9287 63.4765L36.1075 63.0071L36.9347 60.8354L38.8529 48.6642Z"
      fill="#3F3D56"
    />
    <path
      d="M43.41 41.5131C46.3541 41.5131 48.7407 39.1263 48.7407 36.182C48.7407 33.2378 46.3541 30.851 43.41 30.851C40.466 30.851 38.0793 33.2378 38.0793 36.182C38.0793 39.1263 40.466 41.5131 43.41 41.5131Z"
      fill="#A0616A"
    />
    <path
      d="M38.0119 39.6172C38.0119 39.6172 39.186 39.9391 39.8692 40.1184C39.8339 39.6099 39.6137 39.1339 39.5019 38.6366C39.3901 38.1392 39.4144 37.5542 39.784 37.203C40.1536 36.8518 40.9234 36.9886 40.983 37.494C41.2957 36.8252 41.609 36.1553 41.923 35.4842C42.8183 37.3271 44.6487 35.7566 46.6445 36.2223C47.6626 36.4621 48.9386 36.3591 49.4765 35.4619C49.1742 35.2082 48.9196 34.9026 48.7244 34.5596C49.0347 34.6506 49.3574 34.6915 49.6805 34.6807C49.6732 34.3598 49.5888 34.0453 49.4346 33.7638C49.2804 33.4823 49.0608 33.2419 48.7943 33.0629C48.5073 32.8753 49.2178 32.2074 48.9672 31.9724C48.4534 31.4926 48.1086 31.8184 47.8051 31.1859C47.6094 30.7949 47.3078 30.4666 46.9347 30.2385C46.5616 30.0104 46.132 29.8916 45.6947 29.8956C44.8224 29.916 43.2592 29.9076 42.9208 30.7114C41.8421 30.0052 40.3405 30.2047 39.2861 30.9455C38.4097 31.5589 37.4123 33.4129 37.1922 34.4595C36.9721 35.5061 37.4572 38.7051 38.0119 39.6172Z"
      fill="#2F2E41"
    />
    <path
      d="M62.3001 63.1622C62.209 63.1622 62.1188 63.1811 62.0353 63.2177C61.9518 63.2543 61.8768 63.3078 61.8151 63.3749C61.7533 63.4419 61.7061 63.5211 61.6765 63.6073C61.6468 63.6935 61.6354 63.7849 61.6428 63.8758C62.6497 66.4784 62.5134 69.4912 62.3845 72.9001C62.3989 73.0648 62.4743 73.2182 62.5959 73.3302C62.7175 73.4422 62.8765 73.5047 63.0418 73.5056H67.2966C67.4619 73.5047 67.6209 73.4422 67.7425 73.3302C67.8641 73.2182 67.9395 73.0648 67.9539 72.9001C67.6083 69.5884 67.7948 66.5492 68.6956 63.8758C68.703 63.7849 68.6916 63.6935 68.6619 63.6073C68.6323 63.5211 68.5851 63.4419 68.5233 63.3749C68.4616 63.3078 68.3866 63.2543 68.3031 63.2177C68.2196 63.1811 68.1294 63.1622 68.0383 63.1622H62.3001Z"
      fill="#F5B806"
    />
    <path
      d="M69.0363 64.3651L68.7188 62.8571C68.69 62.7199 68.6148 62.5969 68.506 62.5086C68.3972 62.4203 68.2613 62.3721 68.1212 62.3721H66.1395C66.1318 62.3458 66.1192 62.3212 66.1023 62.2996L65.8821 62.0243C65.8614 61.9984 65.8351 61.9774 65.8051 61.963C65.7752 61.9486 65.7424 61.9412 65.7092 61.9412H64.4856C64.4524 61.9412 64.4196 61.9486 64.3897 61.963C64.3597 61.9774 64.3334 61.9984 64.3127 62.0243L64.0925 62.2996C64.0756 62.3212 64.063 62.3458 64.0552 62.3721H62.2172C62.0771 62.3721 61.9412 62.4203 61.8324 62.5086C61.7236 62.5969 61.6485 62.7199 61.6196 62.8571L61.3022 64.3651C61.2834 64.454 61.2848 64.546 61.3061 64.6343C61.3275 64.7227 61.3683 64.8051 61.4255 64.8757C61.4828 64.9463 61.5551 65.0032 61.6371 65.0423C61.7192 65.0814 61.8089 65.1016 61.8998 65.1016C63.9272 66.1418 66.1031 66.1672 68.4386 65.1016C68.5295 65.1016 68.6193 65.0814 68.7013 65.0423C68.7833 65.0032 68.8556 64.9463 68.9129 64.8757C68.9701 64.8051 69.0109 64.7227 69.0323 64.6343C69.0536 64.546 69.055 64.454 69.0363 64.3651Z"
      fill="#3F3D56"
    />
    <path
      d="M123.006 65.1274C122.902 64.7302 122.532 63.517 122.063 64.0529C121.993 64.1325 121.938 63.8767 121.873 63.7827C121.762 63.6202 121.694 62.926 121.575 62.743C121.334 62.3708 121.035 62.4808 120.79 62.049C120.089 60.8141 119.435 59.4041 119.191 58.2126C118.445 54.5648 119.473 49.5766 119.473 49.5766L119.14 42.4714L116.712 42.3898V42.2301C116.712 41.9094 116.585 41.6018 116.358 41.375C116.131 41.1483 115.824 41.0209 115.503 41.0209H110.879C110.572 41.021 110.276 41.1382 110.053 41.3485C109.829 41.5588 109.693 41.8466 109.674 42.1532L107.77 42.0892L104.473 52.5658C104.473 52.5658 106.012 56.5204 106.555 60.3586C106.702 61.3937 106.737 62.4416 106.661 63.4844C106.587 64.5415 106.38 65.5852 106.045 66.5904C106.045 66.5904 120.032 69.52 122.767 66.7965C122.767 66.7965 123.193 65.8411 123.006 65.1274Z"
      fill="#3F3D56"
    />
    <path
      d="M140.916 65.8912H137.611V12.6947C137.383 12.5254 137.151 12.3585 136.918 12.1939C136.87 12.163 136.825 12.1296 136.779 12.0986C135.372 11.1137 133.902 10.2201 132.38 9.42325C131.699 8.51083 130.954 7.64862 130.149 6.84331C127.776 4.47068 124.92 2.63693 121.775 1.4668C118.63 0.296662 115.271 -0.182415 111.924 0.0621481C108.578 0.306712 105.323 1.26918 102.382 2.88409C99.4404 4.499 96.8814 6.72847 94.8787 9.42087C93.8487 9.95974 92.8457 10.5415 91.8698 11.1663C91.7768 11.2259 91.6838 11.2855 91.5932 11.3451C91.5908 11.3475 91.5884 11.3475 91.586 11.3499C91.417 11.4604 91.2783 11.6115 91.1826 11.7893C91.0868 11.9671 91.037 12.1661 91.0377 12.368V65.8913H86.3407C84.2664 65.8913 82.4759 67.5866 82.4854 69.661C82.487 70.1341 82.5784 70.6026 82.7548 71.0416C82.8227 71.2143 82.9048 71.381 83.0004 71.54C83.1222 71.7484 83.2635 71.9448 83.4224 72.1265C83.7738 72.5274 84.2069 72.8486 84.6925 73.0686C85.1781 73.2886 85.7051 73.4024 86.2382 73.4022H141.018C142.011 73.4009 142.963 73.0067 143.666 72.3057C144.37 71.6047 144.767 70.6539 144.771 69.661C144.781 67.5866 142.99 65.8912 140.916 65.8912ZM123.871 65.8912H104.637L101.986 29.2545H126.522L123.871 65.8912Z"
      fill="#E6E6E6"
    />
    <path
      d="M126.972 52.2834L135.192 44.9596L137.617 46.1611L127.758 56.5017L126.972 52.2834Z"
      fill="#FFB6B6"
    />
    <path
      d="M117.782 43.1989L119.14 42.4714C119.14 42.4714 122.485 42.1861 123.85 46.116L127.471 51.5849L133.339 46.4985L135.882 48.2592L128.486 57.0868L125.411 57.4798L118.76 48.8721L117.782 43.1989Z"
      fill="#3F3D56"
    />
    <path
      d="M119.995 36.9127C119.253 41.943 115.56 42.4732 112.855 39.8472C110.026 37.0999 105.716 40.3701 105.716 36.9127C105.716 33.4554 107.277 27.3269 112.855 27.3269C118.626 27.3269 120.5 33.4924 119.995 36.9127Z"
      fill="#2F2E41"
    />
    <path
      d="M112.832 39.8414C115.485 39.8414 117.636 37.6902 117.636 35.0365C117.636 32.3829 115.485 30.2317 112.832 30.2317C110.178 30.2317 108.027 32.3829 108.027 35.0365C108.027 37.6902 110.178 39.8414 112.832 39.8414Z"
      fill="#FFB6B6"
    />
    <path
      d="M107.28 34.5651H109.181L110.019 32.2176L110.186 34.5651H111.095L111.584 33.1957L111.682 34.5651C113.067 35.8178 115.633 35.3594 118.43 34.5651C118.43 33.8972 118.299 33.2358 118.043 32.6187C117.788 32.0015 117.413 31.4408 116.941 30.9685C116.468 30.4962 115.908 30.1216 115.291 29.8659C114.674 29.6103 114.012 29.4788 113.344 29.4788H112.366C111.698 29.4788 111.037 29.6103 110.42 29.8659C109.803 30.1216 109.242 30.4962 108.77 30.9685C108.298 31.4408 107.923 32.0015 107.667 32.6187C107.412 33.2358 107.28 33.8972 107.28 34.5651Z"
      fill="#2F2E41"
    />
    <path
      d="M98.4411 69.0143C98.8213 69.0133 99.193 68.9013 99.5106 68.6922C99.8281 68.4831 100.078 68.1858 100.229 67.8369C100.38 67.4879 100.426 67.1025 100.361 66.7278C100.297 66.3531 100.124 66.0054 99.8646 65.7273L102.539 58.6645L103.087 56.3039C102.783 56.1131 102.44 55.994 102.084 55.9554C101.727 55.9168 101.367 55.9598 101.029 56.0811C100.692 56.2024 100.387 56.3989 100.136 56.6557C99.886 56.9125 99.6975 57.2228 99.5849 57.5632L96.7986 65.9852C96.6045 66.2807 96.4942 66.6232 96.4793 66.9764C96.4644 67.3296 96.5455 67.6803 96.714 67.991C96.8825 68.3018 97.1321 68.561 97.4362 68.7412C97.7404 68.9214 98.0876 69.0158 98.4411 69.0143Z"
      fill="#FFB6B6"
    />
    <path
      d="M130.823 37.9413C130.732 37.9413 130.642 37.9602 130.558 37.9968C130.475 38.0334 130.4 38.0869 130.338 38.1539C130.276 38.221 130.229 38.3001 130.199 38.3863C130.17 38.4725 130.158 38.5639 130.166 38.6548C130.906 41.847 131.22 44.88 130.907 47.6792C130.922 47.8439 130.997 47.9972 131.119 48.1092C131.24 48.2212 131.399 48.2838 131.565 48.2847H135.82C135.985 48.2838 136.144 48.2212 136.265 48.1092C136.387 47.9972 136.462 47.8439 136.477 47.6792C136.24 44.6507 136.49 41.6039 137.219 38.6548C137.226 38.5639 137.215 38.4725 137.185 38.3863C137.155 38.3001 137.108 38.221 137.046 38.1539C136.985 38.0869 136.91 38.0334 136.826 37.9968C136.743 37.9602 136.652 37.9413 136.561 37.9413H130.823Z"
      fill="#F5B806"
    />
    <path
      d="M137.559 39.1441L137.242 37.6361C137.213 37.499 137.138 37.376 137.029 37.2877C136.92 37.1994 136.784 37.1512 136.644 37.1512H134.663C134.655 37.1249 134.642 37.1003 134.625 37.0786L134.405 36.8034C134.384 36.7774 134.358 36.7565 134.328 36.7421C134.298 36.7277 134.265 36.7202 134.232 36.7202H133.009C132.975 36.7202 132.943 36.7277 132.913 36.7421C132.883 36.7565 132.856 36.7774 132.836 36.8034L132.615 37.0786C132.599 37.1003 132.586 37.1249 132.578 37.1512H130.74C130.6 37.1512 130.464 37.1994 130.355 37.2877C130.247 37.376 130.171 37.499 130.143 37.6361L129.825 39.1441C129.806 39.233 129.808 39.325 129.829 39.4134C129.85 39.5017 129.891 39.5842 129.948 39.6548C130.006 39.7253 130.078 39.7822 130.16 39.8213C130.242 39.8604 130.332 39.8807 130.423 39.8807C132.614 40.7945 134.797 40.9748 136.962 39.8807C137.052 39.8807 137.142 39.8604 137.224 39.8213C137.306 39.7822 137.379 39.7253 137.436 39.6548C137.493 39.5842 137.534 39.5017 137.555 39.4134C137.577 39.325 137.578 39.233 137.559 39.1441Z"
      fill="#3F3D56"
    />
    <path
      d="M111.503 9.1637C111.442 9.16369 111.382 9.17635 111.326 9.20088C111.27 9.2254 111.22 9.26126 111.178 9.30619C111.137 9.35112 111.105 9.40414 111.086 9.46191C111.066 9.51968 111.058 9.58094 111.063 9.64183L111.56 15.6891C111.57 15.7994 111.62 15.9022 111.702 15.9773C111.783 16.0523 111.89 16.0942 112 16.0948H114.852C114.962 16.0942 115.069 16.0523 115.15 15.9773C115.232 15.9022 115.282 15.7994 115.292 15.6891L115.789 9.64183C115.794 9.58094 115.786 9.51968 115.767 9.46191C115.747 9.40414 115.715 9.35112 115.674 9.30619C115.632 9.26126 115.582 9.2254 115.526 9.20088C115.47 9.17635 115.41 9.16369 115.349 9.1637H111.503Z"
      fill="white"
    />
    <path
      d="M116.017 9.96972L115.805 8.95923C115.785 8.86734 115.735 8.78489 115.662 8.72572C115.589 8.66656 115.498 8.63427 115.404 8.63427H114.076C114.071 8.61663 114.063 8.60013 114.051 8.58562L113.904 8.40117C113.89 8.38378 113.872 8.36975 113.852 8.36011C113.832 8.35046 113.81 8.34546 113.788 8.34546H112.968C112.946 8.34546 112.924 8.35046 112.904 8.36011C112.884 8.36975 112.866 8.38378 112.852 8.40117L112.704 8.58562C112.693 8.60013 112.685 8.61663 112.68 8.63427H111.448C111.354 8.63427 111.263 8.66656 111.19 8.72572C111.117 8.78489 111.067 8.86734 111.047 8.95923L110.835 9.96972C110.822 10.0293 110.823 10.091 110.837 10.1502C110.852 10.2094 110.879 10.2646 110.917 10.3119C110.956 10.3592 111.004 10.3973 111.059 10.4235C111.114 10.4497 111.174 10.4633 111.235 10.4633H115.617C115.678 10.4633 115.738 10.4497 115.793 10.4235C115.848 10.3973 115.896 10.3592 115.935 10.3119C115.973 10.2646 116 10.2094 116.015 10.1502C116.029 10.091 116.03 10.0293 116.017 9.96972Z"
      fill="#CCCCCC"
    />
    <path
      d="M113.483 19.5523C111.927 19.5523 110.406 19.0908 109.112 18.2262C107.818 17.3616 106.809 16.1327 106.214 14.6949C105.618 13.2571 105.463 11.6749 105.766 10.1486C106.07 8.62223 106.819 7.22017 107.919 6.11973C109.02 5.01928 110.422 4.26987 111.948 3.96626C113.474 3.66265 115.056 3.81847 116.494 4.41403C117.932 5.00958 119.16 6.01812 120.025 7.31211C120.89 8.6061 121.351 10.1274 121.351 11.6837C121.349 13.7698 120.519 15.7699 119.044 17.245C117.569 18.7202 115.569 19.5499 113.483 19.5523ZM113.483 4.29195C112.021 4.29195 110.592 4.72547 109.377 5.53768C108.161 6.34989 107.214 7.50432 106.654 8.85498C106.095 10.2056 105.949 11.6919 106.234 13.1257C106.519 14.5596 107.223 15.8767 108.257 16.9104C109.29 17.9442 110.607 18.6482 112.041 18.9334C113.475 19.2186 114.961 19.0722 116.311 18.5127C117.662 17.9533 118.816 17.0059 119.628 15.7903C120.441 14.5747 120.874 13.1456 120.874 11.6837C120.872 9.72395 120.092 7.84513 118.707 6.4594C117.321 5.07367 115.443 4.29418 113.483 4.29195Z"
      fill="#F2F2F2"
    />
    <path
      d="M137.564 119.698H90.5942L90.6467 114.241L90.6515 113.766L91.0711 71.0559H137.325L137.385 83.3944L137.387 83.8691L137.564 119.698Z"
      fill="#E6E6E6"
    />
    <path
      d="M164.716 119.927L15.2838 120C15.2086 120 15.1366 119.97 15.0836 119.917C15.0305 119.863 15.0007 119.791 15.0007 119.716C15.0007 119.641 15.0305 119.569 15.0836 119.516C15.1366 119.462 15.2086 119.432 15.2838 119.432L164.716 119.359C164.791 119.359 164.864 119.389 164.917 119.442C164.97 119.495 165 119.567 165 119.643C165 119.718 164.97 119.79 164.917 119.844C164.864 119.897 164.791 119.927 164.716 119.927Z"
      fill="#CACACA"
    />
    <path
      d="M88.2884 56.2473C88.1972 56.2473 88.1071 56.2662 88.0236 56.3028C87.9401 56.3394 87.8651 56.3929 87.8033 56.46C87.7416 56.527 87.6944 56.6061 87.6648 56.6923C87.6351 56.7786 87.6237 56.87 87.6311 56.9608C88.3047 60.1354 88.6009 63.1627 88.3728 65.9852C88.3872 66.1499 88.4626 66.3033 88.5842 66.4153C88.7057 66.5273 88.8648 66.5898 89.0301 66.5907H93.2849C93.4502 66.5898 93.6092 66.5273 93.7308 66.4153C93.8524 66.3033 93.9278 66.1499 93.9422 65.9852C93.5985 63.1382 93.9619 60.0984 94.6838 56.9608C94.6913 56.87 94.6799 56.7786 94.6502 56.6923C94.6206 56.6061 94.5734 56.527 94.5116 56.46C94.4499 56.3929 94.3749 56.3394 94.2914 56.3028C94.2079 56.2662 94.1177 56.2473 94.0266 56.2473H88.2884Z"
      fill="#F5B806"
    />
    <path
      d="M95.0245 57.4501L94.7071 55.9422C94.6782 55.805 94.6031 55.682 94.4943 55.5937C94.3855 55.5054 94.2496 55.4572 94.1095 55.4572H92.1278C92.1201 55.4309 92.1075 55.4063 92.0906 55.3846L91.8704 55.1094C91.8497 55.0834 91.8233 55.0625 91.7934 55.0481C91.7635 55.0337 91.7307 55.0262 91.6974 55.0262H90.4739C90.4407 55.0262 90.4079 55.0337 90.3779 55.0481C90.348 55.0625 90.3217 55.0834 90.3009 55.1094L90.0807 55.3846C90.0639 55.4063 90.0513 55.4309 90.0435 55.4572H88.2055C88.0654 55.4572 87.9295 55.5054 87.8207 55.5937C87.7119 55.682 87.6367 55.805 87.6079 55.9422L87.2904 57.4501C87.2717 57.5391 87.2731 57.6311 87.2944 57.7194C87.3158 57.8077 87.3565 57.8902 87.4138 57.9608C87.4711 58.0314 87.5434 58.0883 87.6254 58.1274C87.7074 58.1664 87.7972 58.1867 87.8881 58.1867C90.018 58.8449 92.297 58.8449 94.4269 58.1867C94.5178 58.1867 94.6075 58.1664 94.6896 58.1274C94.7716 58.0883 94.8439 58.0314 94.9012 57.9608C94.9584 57.8902 94.9992 57.8077 95.0206 57.7194C95.0419 57.6311 95.0433 57.5391 95.0245 57.4501Z"
      fill="#3F3D56"
    />
    <path
      d="M113.173 90.8467H91.3096V91.3236H113.173V90.8467Z"
      fill="#F2F2F2"
    />
    <path d="M137.254 73.2019H115.39V73.6788H137.254V73.2019Z" fill="#F2F2F2" />
    <path
      d="M125.094 106.107H103.231V106.584H125.094V106.107Z"
      fill="#F2F2F2"
    />
    <path
      d="M137.387 83.9318H127.073V83.455H137.385L137.387 83.9318Z"
      fill="#F2F2F2"
    />
    <path
      d="M93.5173 69.8637H83.2031V69.3868H93.515L93.5173 69.8637Z"
      fill="#F2F2F2"
    />
    <path
      d="M101.624 22.8905H91.3096V22.4136H101.621L101.624 22.8905Z"
      fill="#F2F2F2"
    />
    <path
      d="M136.195 13.1143H125.881V12.6375H136.193L136.195 13.1143Z"
      fill="#F2F2F2"
    />
    <path
      d="M125.705 22.8905H115.39V22.4136H125.702L125.705 22.8905Z"
      fill="#F2F2F2"
    />
    <path
      d="M97.1986 113.976V114.452H90.6467L90.6515 113.976H97.1986Z"
      fill="#F2F2F2"
    />
    <path
      d="M136.751 47.9536C137.831 47.9536 138.707 47.0777 138.707 45.9973C138.707 44.9169 137.831 44.041 136.751 44.041C135.67 44.041 134.794 44.9169 134.794 45.9973C134.794 47.0777 135.67 47.9536 136.751 47.9536Z"
      fill="#FFB6B6"
    />
    <path
      d="M108.588 43.3945L107.77 42.0892C107.77 42.0892 105.108 41.4309 104.012 43.1952C102.916 44.9596 97.0176 63.9353 97.0176 63.9353L100.356 65.1275L106.793 50.4022L108.588 43.3945Z"
      fill="#3F3D56"
    />
  </svg>
);
