import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Radio,
  RadioGroup,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { FC, ReactElement } from "react";
import { HelpType, useHelpContext } from "../../context/HelpContext";
import ColorBoxButton from "../Buttons/ColorBoxButton";
import { DressingTopIcon } from "../Icons/DressingTopIcon";
import { MinusCircleIcon } from "../Icons/MinusCircleIcon";
import { PlusCircleIcon } from "../Icons/PlusCircleIcon";
import GrayLayout from "../Layout/GrayLayout";
import { DressCode } from "./DressingBlock";

export interface PrintedOutfitMenuProps {
  dressCode?: DressCode;
  setDressCode: (element: DressCode) => void;
  totalStaff: number;
  tshirtInfoNeeded: boolean;
}

const OUTFIT_COLORS = [
  "#B80000", //red
  "#db3e00", //orange
  "#F7B80B", //yellow
  "#004dcf", //blue
  "#008b02", //green
  "#5300eb", //purple
  "#fff", //white
  "#000", //black
];

const PrintedCardItem: FC<PrintedOutfitMenuProps> = ({
  dressCode,
  setDressCode,
  totalStaff,
  tshirtInfoNeeded,
}): ReactElement => {
  const { setHelp } = useHelpContext();

  const selectPrintedCap = (value: boolean) => {
    setDressCode({
      ...dressCode,
      printedOutfit: {
        ...dressCode?.printedOutfit,
        printedCap: value,
      },
    });
  };
  const selectPrintedTshirt = (value: boolean) => {
    setDressCode({
      ...dressCode,
      printedOutfit: {
        ...dressCode?.printedOutfit,
        printedTshirt: value,
        tshirtCount: 1,
        top: undefined,
        topColor: undefined,
      },
    });
    if (tshirtInfoNeeded) {
      if (value) {
        setHelp(HelpType.PRINTED_TSHIRT);
      } else {
        setHelp(null);
      }
    }
  };
  const selectPrintedWindbreaker = (value: boolean) => {
    setDressCode({
      ...dressCode,
      printedOutfit: {
        ...dressCode?.printedOutfit,
        printedWindbreaker: value,
      },
    });
  };
  const selectLogoFormat = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      printedOutfit: {
        ...dressCode?.printedOutfit,
        logoFormat: value,
      },
    });
  };
  const selectPrintedColor = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      printedOutfit: {
        ...dressCode?.printedOutfit,
        color: value,
      },
    });
  };

  const updateTshirtCount = (value: number) => {
    const tmp = dressCode?.printedOutfit?.tshirtCount;
    if (!tmp || tmp + value < 1) {
      setDressCode({
        ...dressCode,
        printedOutfit: {
          ...dressCode?.printedOutfit,
          tshirtCount: 1,
        },
      });
      return;
    }
    setDressCode({
      ...dressCode,
      printedOutfit: {
        ...dressCode?.printedOutfit,
        tshirtCount: tmp + value,
      },
    });
  };
  return (
    <GrayLayout>
      <SimpleGrid columns={3} gap="1em">
        <Flex flexDir="column">
          <Text variant="2xl" fontWeight="700" textAlign="left" mb="1em">
            Impression sur
          </Text>
          <Flex gap="1em">
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap="14px"
            >
              <DressingTopIcon />
              <Text variant="xl" fontWeight="500">
                Haut
              </Text>
            </Flex>
            <Box>
              <Flex flexDir="column">
                <Checkbox
                  isChecked={dressCode?.printedOutfit?.printedCap === true}
                  marginTop="1rem"
                  marginLeft="1rem"
                  onChange={(v) => selectPrintedCap(v.currentTarget.checked)}
                >
                  Casquette
                </Checkbox>
                <Checkbox
                  isChecked={dressCode?.printedOutfit?.printedTshirt === true}
                  marginTop="1rem"
                  marginLeft="1rem"
                  onChange={(v) => selectPrintedTshirt(v.currentTarget.checked)}
                >
                  T-shirt
                </Checkbox>
                <Checkbox
                  isChecked={
                    dressCode?.printedOutfit?.printedWindbreaker === true
                  }
                  marginTop="1rem"
                  marginLeft="1rem"
                  onChange={(v) =>
                    selectPrintedWindbreaker(v.currentTarget.checked)
                  }
                >
                  Coupe-vent
                </Checkbox>
              </Flex>
            </Box>
          </Flex>
        </Flex>

        <Flex flexDir="column">
          <Flex>
            <Text
              display="inline"
              variant="2xl"
              fontWeight="700"
              textAlign="left"
              mb="1em"
            >
              Votre logo
            </Text>
            <Text
              marginTop="10px"
              marginLeft="12px"
              display="inline"
              fontWeight="600"
              textAlign="left"
              mb="1em"
            >
              (Impression quadri)
            </Text>
          </Flex>
          <RadioGroup // LOGO FORMAT
            defaultChecked={false}
            value={dressCode?.printedOutfit?.logoFormat ?? ""}
            flexWrap="wrap"
            flexDirection="column"
            display="flex"
            onChange={selectLogoFormat}
          >
            <Radio value="Format Logo Coeur" marginLeft="1rem" marginTop="2rem">
              <Text variant="lg" whiteSpace="nowrap">
                Format Logo Coeur
              </Text>
            </Radio>
            <Radio
              value="Format Logo Coeur A4 Dos"
              marginLeft="1rem"
              marginTop="2rem"
            >
              <Text variant="lg" whiteSpace="nowrap">
                Format Logo Coeur A4 Dos
              </Text>
            </Radio>
          </RadioGroup>
        </Flex>

        <Flex flexDir="column" marginLeft="1rem">
          <Text variant="2xl" fontWeight="700" textAlign="left" mb="1em">
            Couleur de la tenue
          </Text>

          <Grid // COLORS
            position="relative"
            marginTop="1rem"
            gridTemplateColumns={"1fr 1fr 1fr 1fr"}
            gridTemplateRows={"1fr 1fr"}
          >
            {OUTFIT_COLORS.map((color, i) => (
              <ColorBoxButton
                key={color}
                buttonColor={color}
                isActive={dressCode?.printedOutfit?.color === color}
                onClick={() => selectPrintedColor(color)}
                mx="auto"
              />
            ))}
          </Grid>
        </Flex>
      </SimpleGrid>
      {dressCode?.printedOutfit?.printedTshirt ? (
        <Flex mt="2rem" flexDir="column" textAlign="left">
          <Text ml="0.25rem">Nombre de T-shirt par personne</Text>
          <Flex flexDir="row" ml="2rem">
            <Button bg="white" onClick={() => updateTshirtCount(-1)}>
              <MinusCircleIcon />
            </Button>
            <Text m="0.4rem 0.5rem 0">
              {dressCode?.printedOutfit?.tshirtCount}
            </Text>
            <Button bg="white" onClick={() => updateTshirtCount(1)}>
              <PlusCircleIcon />
            </Button>
            <Text mt="0.4rem" fontWeight="bold">
              Total : {dressCode.printedOutfit.tshirtCount! * totalStaff}
            </Text>
          </Flex>
        </Flex>
      ) : (
        <></>
      )}
    </GrayLayout>
  );
};

export default PrintedCardItem;
