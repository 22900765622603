import { PageContentProps, PageTypes } from "../Onboarding/type";

export const cgvPageContent: PageContentProps[] = [
  {
    id: "cvgId",
    previousPageId: "",
    title: "Conditions générales de vente",
    nameForTotalCard: "cvg",
    subtitle:
      "Vous êtes prié de bien vouloir prendre connaissance de nos CGV avant de procéder à la commande.",
    cvgContent: [
      {
        title: "ARTICLE 1 : CHAMP D’APPLICATION",
        values: [
          "Les présentes Conditions Générales de Vente (ci-après, les CGV) s’appliquent à toutes les prestations et services et ventes conclues par la société NORELA auprès de ses clients (ci-après, le Client), dans le cadre de toutes les opérations vendues par NORELA sauf à ce qu’une dérogation aux CGV soient expressément acceptées par NORELA et le Client. Les CGV et/ou les accords dérogatoires expressément acceptés par le NORELA et le Client prévaudront sur toutes clauses et conditions contraires pouvant figurer sur les conditions générales d’achat ou tout autre document émanant du Client ou d’un tiers.",
        ],
      },
      {
        title: "ARTICLE 2 : PRISE DE COMMANDE",
        values: [
          "La prise de commande est effectuée et réputée acceptée par le Client :",
          "- soit par la signature avec apposition de la mention « bon pour accord » avec cachet du",
          "Client sur la proposition commerciale adressée par NORELA à ce dernier :",
          "- soit par envoi d’un bon de commande accepté par NORELA ;",
          "La proposition commerciale et/ou le bon de commande sont désignés aux termes des présentes CGV comme le Document d’Acceptation.",
          "Les prestations à réaliser par NORELA sont explicitement listées sur le Document d’Acceptation. Les tarifs mentionnés sur le Document d’Acceptation s’entendent en euros et Hors Taxe. Toute prestation ne figurant pas le Document d’Acceptation fera l’objet d’une facturation complémentaire donnant lieu à une proposition commerciale et/ou un bon de commandes complémentaires.",
          "Les prix et délais figurant sur la proposition commerciale sont valables pendant un délai de un mois à compter de sa transmission au Client. Ils deviennent fermes et non révisables à compter de la signature du Document d’Acceptation sous ce délai.",
          "La signature de la proposition commerciale et/ou l’envoi d’un bon de commande constituent une acceptation sans réserve par le Client du prix, de l’étendue des prestations diligentées par NORELA et des termes de présentes CGV",
        ],
      },
      {
        title: "ARTICLE 3 : CONDITIONS DE PAIEMENT",
        values: [
          "Les paiements sont effectués au choix au Client par chèque ou virement bancaire.",
          "Le paiement intervient selon les conditions suivantes évoluant selon le prix des prestations commandées :",
          "Pour tout volume de commande inférieur à 1.500 € HT :",
          "- 100 % dû lors de l’acceptation de la proposition commerciale",
          "Pour tout volume de commande supérieur ou égal à 1.500 € HT :",
          "Acompte de 50% lors de l’acceptation de la proposition commerciale ou l’envoi du bon de commande ;",
          "Solde de 50% à 10 jours d’échéance à réception de la facture.",
          "Pour toute commande venant d’une société étrangère (hors France).\n" +
            "Règlement de 100 % lors de l’acceptation de la proposition commerciale ou l’envoi du bon de commande ;",
          "Pour tout volume de commande d’un nouveau client quel qu’en soit le volume de commande :",
          "- Acompte de 70 % lors de l’acceptation de la proposition commerciale ou l’envoi du bon de commande",
          "- Solde de 30 % à 10 jours d’échéance à réception de la facture",
          "La facture est émise la semaine de la fin d’opération, soit entre 1 et 4jr suivant la fin de l’opération",
          "La facture est à réglée à 10 jours à partir de la date d’émission de la facture\n" +
            "Toute somme non réglée à sa date d’échéance fait l’objet de pénalités de retard commençant à courir au lendemain de cette date dont le taux est égal au taux d'intérêt appliqué par la Banque centrale européenne à son opération de refinancement la plus récente majoré de 10 points de pourcentage selon les conditions de l’article L.441-6 du Code de commerce sans qu’une mise en demeure de NORELA n’ait à intervenir.",
          "L’indemnité forfaitaire pour frais de recouvrement prévue audit article sera également applicable étant entendu que lorsque les frais de recouvrement exposés seront supérieurs au montant de cette indemnité forfaitaire, NORELA pourra demander une indemnisation complémentaire, sur justification.\n",
          "A titre de clause pénale, et ce, sans préjudice de ces dispositions ci-dessus, tout retard de paiement de plus de 3 mois entrainera une indemnité forfaitaire égale à 15% du montant de la facture impayé.",
        ],
      },
      {
        title: "ARTICLE 4 : ANNULATION",
        values: [
          "En cas d’annulation du fait Client des prestations commandées NORELA conserve les montants suivants selon les conditions suivantes ",
          "Pour tout volume de commande supérieur ou égal à 90.000 € HT :",
          "- L’intégralité (100%) des montants dus à NORELA dans le Document d’Acceptation en cas d’annulation intervenant moins de 7 jours francs avant la date de début d’opération prévue dans le Document d’Acceptation ;",
          "- La moitié (50%) des montants dus à NORELA dans le Document d’Acceptation en cas d’annulation intervenant entre 15 jours et 7 jours francs avant la date de début d’opération prévue dans le Document d’Acceptation ;",
          "- Un tiers (33,33%) des montants dus à NORELA dans le Document d’Acceptation en cas d’annulation intervenant entre la date de signature de la proposition commerciale ou d’envoi du bon de commande et 15 jours francs avant la date de début d’opération prévue dans le Document d’Acceptation;",
          "Pour tout volume de commande entre 20.000 € HT et 90.000 € HT :",
          "- L’intégralité (100%) des montants dus à NORELA dans le Document d’Acceptation en cas d’annulation intervenant moins de 48 heures avant la date de début d’opération prévue dans le Document d’Acceptation ;",
          "- La moitié (50%) des montants dus à NORELA dans le Document d’Acceptation en cas d’annulation intervenant entre 10 jours francs à 48 heures avant la date de début d’opération prévue dans le Document d’Acceptation ;",
          "- Un tiers (33,33%) des montants dus à NORELA dans le Document d’Acceptation en cas d’annulation intervenant entre la date de signature de la proposition commerciale ou d’envoi du bon de commande et 10 jours francs avant la date de début d’opération prévue dans le Document d’Acceptation;",
          "Pour tout volume de commande inférieur à 10.000 € HT :",
          "- L’intégralité (100%) des montants dus à NORELA dans le Document d’Acceptation en cas d’annulation intervenant moins de 48 heures avant la date de début d’opération prévue dans le Document d’Acceptation ;",
          "- La moitié (50%) des montants dus à NORELA dans le Document d’Acceptation en cas d’annulation intervenant entre 5 jours francs à 48 heures avant la date de début d’opération prévue dans le Document d’Acceptation ;",
        ],
      },
      {
        title: "ARTICLE 5 : PENALITES DE RETARD",
        values: [
          "Conformément aux articles 441-6 c. com. et D. 441-5 c. com., tout retard de paiement entraîne de plein droit, outre les pénalités de retard au taux légal de 10% par jour de retard exigibles a partir du 1er jour de retard, une obligation pour le débiteur de payer une indemnité forfaitaire de 40 € pour frais de recouvrement.",
          "Une indemnité complémentaire pourra être réclamée, sur justificatifs, lorsque les frais de recouvrement exposés sont supérieurs au montant de l'indemnité forfaitaire.",
        ],
      },
      {
        title: "ARTICLE 6 : HEURES SUPPLEMENTAIRES",
        values: [
          "Toute heure supplémentaire à celles prévues dans le Document d’Acceptation et commencée sera due en son intégralité par le Client. Elle fera par ailleurs l’objet cas échéant des majorations prévues par la règlementation issue de la Convention collective nationale du personnel des prestataires de services dans le domaine du secteur – n°3301.",
        ],
      },
      {
        title: "ARTICLE 7 : NON DEBAUCHAGE",
        values: [
          "Le Client s’interdit de prendre à son service directement ou indirectement sous quelque forme que ce soit tout préposé de NORELA pour une mission équivalente à celle exécutée pour son compte par cette dernière, et ce pour une durée de 3 ans commençant à courir à compter de la fin de l’exécution des prestations fournies par NORELA. Le Client devra par ailleurs prendre toutes les dispositions nécessaires afin que le cas échéant, ses propres clients pour le compte desquels les prestations de NORELA seraient finalement exécutées respectent ce même engagement.",
        ],
      },
      {
        title: "ARTICLE 8 : AUTORISATIONS",
        values: [
          "Le Client reste seul responsable de l’obtention et du respect de toutes les autorisations nécessaires à l’exécution de la mission objet du contrat. Le Client fait son affaire personnelle desdites autorisations qu’il s’oblige à fournir à NORELA par tous moyens à première demande. Le Client s’engage en conséquence à garantir NORELA de tous les préjudices directs et indirects que NORELA devrait subir du fait de toute défaillance dans l’obtention mise à sa charge des autorisations nécessaires au cadre de la mission.",
          " Toute non attribution ou retrait d’une autorisation, ainsi que toute interdiction faute d’autorisation sollicitée qui rendrait impossible l’exécution des prestations de NORELA ne pourra en aucun cas constituer un cas d’exonération des obligations du Client visées à l’article 4 des présentes CGV.",
        ],
      },
      {
        title: "ARTICLE 9 : MATERIEL",
        values: [
          "NORELA sera seulement responsable de tout matériel, dont notamment les uniformes, qui seront confiés spécifiquement à sa garde dans le cadre du Document d’Acceptation pour autant que les installations dans lesquelles sont remisés lesdits matériels soient seules accessibles à NORELA et à ses effectifs",
          "Tout matériel mis à disposition par NORELA au Client devra être restitué dans l’état dans lequel il lui aura été remis sauf à indemniser NORELA de toute détérioration.",
        ],
      },
      {
        title: "ARTICLE 10 : VEHICULES",
        values: [
          "Le Client s’assurera que tout véhicule dans lequel les préposés de NORELA devraient se trouver dans le cadre de l’exécution des prestations est en parfait état d’entretien et est régulièrement assuré conformément à l’ensemble des obligations relatives au régime d’assurance des véhicules automobiles (dommages, accidents, vols, incendies, bris de glace….) ; le Client s’assure en outre que le véhicule est assuré pour être mis à disposition des préposés de NORELA dans le cadre prestations fournies par cette dernière qui induiraient que ces derniers doivent en assurer la conduite. Le Client devra justifier à première demande et par tout moyen de cette obligation.",
        ],
      },
      {
        title: "ARTICLE 11 : MAJORATION JOURS SPECIFIQUES",
        values: [
          "Conformément aux dispositions de la Convention collective nationale du personnel des prestataires de services dans le domaine du secteur – n°3301, à l’exception des Dimanche, les jours fériés font l’objet d’une majoration spécifiquement prévues par ce texte.",
        ],
      },
      {
        title: "ARTICLE 12 : LITIGE – DROIT APPLICABLE",
        values: [
          "Les présentes CGV sont soumises au droit français. Tout litige résultant de leur exécution ou de leur interprétation sont soumis à la compétence du Tribunal de commerce de PARIS",
        ],
      },
    ],
    type: PageTypes.CGV,
  },
];
