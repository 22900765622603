import { Omit } from "@stripe/stripe-js/types/utils";
import { Icons } from "../../components/PicturesVariant/PicturesVariant";
import OnboardingListButtonLayout, {
  OnboardingButton,
} from "./OnboardingListButtonLayout";
import { useOnboardingContext } from "../../context/OnboardingContext";
import { EventSubCategory } from "../../modules/Onboarding/EventTypes";
import { useNavigate } from "react-router-dom";
import { PageURL } from "../../types/pages";
import { useSessionContext } from "../../context/SessionContext";

const PUBLIC_ELEMENTS: Omit<OnboardingButton<EventSubCategory>, "onChoose">[] =
  [
    {
      title: "Inauguration",
      value: EventSubCategory.INAUGURATION,
      icon: Icons.INAGURATION_VARIANT,
    },
    {
      title: "Village événementiel",
      value: EventSubCategory.VILLAGE_EVENEMENTIEL,
      icon: Icons.VILLAGEEVENEMENTIEL_ICON,
    },
    {
      title: "Salon",
      value: EventSubCategory.SALON_PUBLIC,
      icon: Icons.SALON_NEW_ICON,
    },
    {
      title: "Evénement sportif",
      value: EventSubCategory.EVENEMENT_SPORTIF,
      icon: Icons.EVENEMENT_SPORTIF_NEW,
    },
    {
      title: "Autre ?",
      value: EventSubCategory.AUTRE_PUBLIC,
      icon: Icons.GOODIES_ICON,
      isInputText: true,
    },
  ];

export default function OnboardingPublic() {
  const { setEventSubcategory } = useOnboardingContext();
  const navigate = useNavigate();
  const { updateHistory } = useSessionContext();

  return (
    <OnboardingListButtonLayout
      title="QUEL TYPE D’ÉVÉNEMENT GRAND PUBLIC"
      subtitle="Vous souhaitez cibler le grand public dans quel type événement ?"
      elements={PUBLIC_ELEMENTS.map(
        (e) =>
          ({
            ...e,
            onChoose: (value) => {
              setEventSubcategory(value);
              updateHistory(PageURL.ONBOARDING_RECEPTION);
              navigate(PageURL.ONBOARDING_RECEPTION);
            },
          } as OnboardingButton<EventSubCategory>)
      )}
    />
  );
}
