import {
  Accordion,
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import "react-dates/lib/css/_datepicker.css";
import { useNavigate } from "react-router-dom";
import AccordionWrapper from "../../components/Accordion/AccordionWrapper";
import NorelaButton from "../../components/Buttons/NorelaButton";
import { needToCallNorela } from "../../components/Popus/CalendarPopup";
import SelectionDePersonnelBlock from "../../components/SelectionDePersonnelBlock/SelectionDePersonnelBlock";
import { HelpType, useHelpContext } from "../../context/HelpContext";
import {
  HIDE_ACCUEIL_OPTIONS,
  HIDE_STREET_MARKETING_OPTIONS,
  useOnboardingContext,
} from "../../context/OnboardingContext";
import { useOptionContext } from "../../context/OptionsContext";
import { useSegment } from "../../context/SegmentContext";
import { useSessionContext } from "../../context/SessionContext";
import { PersonnelType, useUserContext } from "../../context/UserContext";
import { PageURL } from "../../types/pages";
import { colors } from "../../ui/theme";
import { createDetailedPersonnelArray } from "../../utils/parseUserData";
import { guessEventTypeFromPersonel, isMissingHours } from "../../utils/utils";
import { EventType } from "../Onboarding/EventTypes";
import ChooseEventName from "./CalendarChooseEventName";
import ChooseEventSchedule from "./CalendarEventSchedule";
import SchedulePersonnalisation from "./SchedulePersonnalisation";
import { DownCrossIcon } from "../../components/Icons/DownCrossIcon";
import { MultiAddressPopupIcon } from "../../components/Icons/MultiAddressIcon";

const VALID = `${colors.green.default} !important`;
const INVALID = `${colors.gray.lightMode} !important`;

export default function CalendarAccordion() {
  const { track } = useSegment();
  const { userInfos, setUserInfos, incrementWorker, decrementWorker } =
    useUserContext();
  const [index, setIndex] = useState(0);
  const {
    eventName,
    setEventName,
    eventLocations,
    setEventLocations,
    setEventType,
  } = useOnboardingContext();
  const navigate = useNavigate();
  const { updateHistory, sessionInfo } = useSessionContext();
  const { optionInfo, setOptionInfo } = useOptionContext();
  const dateChosen = useMemo(
    () =>
      (userInfos.calendarInfo?.days?.length ?? 0) > 0 ||
      (userInfos.calendarInfo?.period ?? null) !== null,
    [userInfos]
  );
  const isEventAdressSelected = useMemo(
    () =>
      eventLocations &&
      eventLocations.length > 0 &&
      // eventLocations[0].address !== "" &&
      eventLocations[0].city !== "" &&
      eventLocations[0].postalCode !== "",
    [eventLocations]
  );
  const [multiAddressPopup, setMultiAddressPopup] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ref = useRef(null);

  const finalStaffSelection = useMemo(
    () =>
      createDetailedPersonnelArray(
        userInfos.selectDePersonnelInfo,
        userInfos.calendarInfo
      ),
    [userInfos]
  );

  const [allHoursValid, setAllHoursValid] = useState(true);
  useEffect(() => {
    for (const elem of finalStaffSelection) {
      if (elem.workedHours < 3) {
        setAllHoursValid(false);
        return;
      }
    }
    setAllHoursValid(true);
  }, [finalStaffSelection]);
  const missingHours = useMemo(() => {
    return isMissingHours(userInfos.calendarInfo?.eventSchedule);
  }, [userInfos]);

  const eventTooSoon = useMemo(
    () =>
      ((userInfos.calendarInfo?.days?.length ?? 0) > 0 &&
        needToCallNorela(userInfos.calendarInfo!.days!)) ||
      ((userInfos.calendarInfo?.period?.startDate ?? null) !== null &&
        needToCallNorela([userInfos.calendarInfo!.period!.startDate!])),
    [userInfos]
  );

  const personnelSelected = useMemo(() => {
    for (const elem in userInfos?.selectDePersonnelInfo?.persons) {
      if (
        userInfos?.selectDePersonnelInfo?.persons[elem as PersonnelType]
          ?.count !== 0
      )
        return true;
    }
  }, [userInfos]);

  const { setHelp } = useHelpContext();
  const [showHelp0, setShowHelp0] = useState(true);
  const [showHelp1, setShowHelp1] = useState(true);
  const [showHelp2, setShowHelp2] = useState(true);

  useEffect(() => {
    if (index === 0 && showHelp0) {
      setHelp(HelpType.QUOTE_WARNING);
      setShowHelp0(false);
    } else if (index === 1 && showHelp1 && personnelSelected) {
      setHelp(HelpType.EQUIPE_PRESELECTION);
      setShowHelp1(false);
    } else if (index === 2 && showHelp2) {
      setHelp(HelpType.DEFAULT_WORKTIME);
      setShowHelp2(false);
    } else setHelp(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  if (!userInfos?.calendarInfo) return <></>;

  const setFakeEventType = () => {
    const eventType = guessEventTypeFromPersonel(
      userInfos.selectDePersonnelInfo.persons
    );
    if (!eventType) {
      return;
    }
    setEventType(eventType);
    if (eventType === EventType.ACCUEIL)
      setOptionInfo({
        ...optionInfo,
        optionsToHide: HIDE_ACCUEIL_OPTIONS,
      });
    if (eventType === EventType.STREET_MARKETING)
      setOptionInfo({
        ...optionInfo,
        optionsToHide: HIDE_STREET_MARKETING_OPTIONS,
      });
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={ref}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent minW="fit-content" p="2rem 1rem 1rem 1rem">
          <Flex gap="2rem">
            <Box w="400px">
              <Flex>
                <AlertDialogHeader minW="200px" my="auto">
                  Plusieurs adresses
                </AlertDialogHeader>
                <MultiAddressPopupIcon />
              </Flex>
              <Text p="3rem 2rem">
                Vous avez indiqué <b>plusieurs adresses</b> pour votre
                événement.
                <br />
                <br />
                Sélectionnez <b>l’intégralité de votre personnel</b> ainsi que
                leurs horaires respectifs.
                <br />
                (9 personnes dans l’exemple ci-contre).
                <br />
                <br />
                Vous nous communiquerez la <b>répartition vos équipes</b> sur
                les différentes adresses dans votre brief. Notre équipe vous
                re-contactera à ce sujet après la commande
              </Text>
            </Box>
            <Flex flexDir="column" mt="2rem" gap="2rem">
              <Box
                border="1px solid black"
                borderRadius="8px"
                p="1rem"
                w="100%"
              >
                <Text fontWeight="bold">Votre commande :</Text>
                <Text>7 hôtesses d'accueil et 2 animateurs</Text>
              </Box>
              <DownCrossIcon mx="auto" />
              <Box
                border="1px solid black"
                borderRadius="8px"
                p="1rem"
                w="100%"
              >
                <Text>
                  Votre brief :
                  <br />
                  J’ai besoin de 3 équipes sur 3 adresses différentes :
                  <br />
                  - Adresse A : 3 hôtesses d’accueil et 1 Animateur micro de 9h
                  à 18H
                  <br />
                  - Adresse B : 2 hôtesses d’accueil de 8h30 à 17h
                  <br />- Adresse C : 2 hôtesses d’accueil et 1 Animateur micro
                  de 9h à 18h
                </Text>
              </Box>
            </Flex>
          </Flex>
          <AlertDialogFooter justifyContent="center">
            <NorelaButton onClick={onClose}>J'ai compris</NorelaButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Accordion index={index} onChange={(i) => setIndex(Number(i))} w="100%">
        <AccordionWrapper
          borderColor={index > 0 ? VALID : INVALID}
          title="1. Définissez les détails de votre événement"
          fakeButton={index > 0 ? "Modifier l'événement" : undefined}
          w="100%"
        >
          <ChooseEventName
            eventName={eventName ?? ""}
            setEventName={setEventName}
            eventLocations={
              eventLocations ?? [
                {
                  address: "",
                  isInGreatParis: false,
                  isAddressKnown: true,
                  city: "",
                  postalCode: "",
                },
              ]
            }
            setEventLocations={setEventLocations}
            mb="1rem"
          />
          <ChooseEventSchedule />
          <NorelaButton
            mt="1rem"
            w="100%"
            isDisabled={
              !dateChosen ||
              (eventName ?? "") === "" ||
              eventTooSoon ||
              missingHours ||
              !isEventAdressSelected
            }
            onClick={() => {
              setIndex(1);
              if (
                eventLocations &&
                eventLocations?.length > 1 &&
                multiAddressPopup
              ) {
                onOpen();
                setMultiAddressPopup(false);
              }
            }}
          >
            Suivant
          </NorelaButton>
        </AccordionWrapper>
        <AccordionWrapper
          isDisabled={
            !dateChosen ||
            (eventName ?? "") === "" ||
            eventTooSoon ||
            missingHours
          }
          title="2. Choisir la configuration de votre équipe"
          borderColor={index > 1 ? VALID : INVALID}
          fakeButton={index > 1 ? "Modifier l'équipe" : undefined}
        >
          <SelectionDePersonnelBlock />
          <NorelaButton
            mt="1rem"
            w="100%"
            isDisabled={!dateChosen || !personnelSelected}
            onClick={() => {
              if (!sessionInfo.needHelp) {
                setFakeEventType();
              }
              setIndex(2);
            }}
          >
            Suivant
          </NorelaButton>
        </AccordionWrapper>
        <AccordionWrapper
          isDisabled={
            !dateChosen ||
            (eventName ?? "") === "" ||
            eventTooSoon ||
            missingHours ||
            !personnelSelected
          }
          title="3. Modifiez les horaires de votre personnel"
          detail={[
            "Les tarifs des forfaits sont dégressifs au prorata du nombre d'heures.",
          ]}
          borderColor={index > 2 ? VALID : INVALID}
        >
          <SchedulePersonnalisation
            eventDateInfo={userInfos.calendarInfo}
            onAddPerson={(role) => {
              incrementWorker(role);
            }}
            onRemovePerson={(person) => {
              const dateOverrides =
                userInfos.selectDePersonnelInfo.persons[person.role]
                  ?.dateOverrides;
              if (dateOverrides && dateOverrides.length > person.nb) {
                for (let i = person.nb; dateOverrides?.length > i + 1; i++) {
                  dateOverrides[i] = { ...dateOverrides[i + 1] };
                }

                dateOverrides[dateOverrides.length - 1] = {};
                setUserInfos(userInfos);
              }
              decrementWorker(person.role);
            }}
          />
          <NorelaButton
            onClick={() => {
              updateHistory(PageURL.DRESSING);
              navigate(PageURL.DRESSING);
              if (eventLocations)
                track("Address filled", {
                  adresse: JSON.stringify(eventLocations),
                });
            }}
            mt="1rem"
            w="100%"
            isDisabled={
              !dateChosen ||
              !personnelSelected ||
              finalStaffSelection.length === 0 ||
              !allHoursValid
            }
          >
            Valider la configuration
          </NorelaButton>
        </AccordionWrapper>
      </Accordion>
    </>
  );
}
