import Icon, { IconProps } from "@chakra-ui/icon";

export const PencilIcon = (props: IconProps) => (
  <Icon
    width="24px"
    height="25px"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.71 7.54055C21.1 7.15055 21.1 6.50055 20.71 6.13055L18.37 3.79055C18 3.40055 17.35 3.40055 16.96 3.79055L15.12 5.62055L18.87 9.37055M3 17.7505V21.5005H6.75L17.81 10.4305L14.06 6.68055L3 17.7505Z"
      fill="currentColor"
    />
  </Icon>
);
