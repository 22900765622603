import { useEffect } from "react";
import { useStepContext } from "../context/StepContext";
import { useLocation } from "react-router-dom";
import { PageURL } from "../types/pages";

export const STEP_LIST: {
  [key in PageURL]: number;
} = {
  [PageURL.HOME]: 0,
  [PageURL.ONBOARDING]: 0,
  [PageURL.ONBOARDING_PUBLIC]: 1,
  [PageURL.ONBOARDING_PRO]: 1,
  [PageURL.ONBOARDING_STREET_MARKETING]: 1,
  [PageURL.ONBOARDING_ANIMATION_PROMO]: 1,
  [PageURL.ONBOARDING_ANIMATION_EVENT]: 1,
  [PageURL.ONBOARDING_ROADSHOW]: 1,
  [PageURL.ONBOARDING_TAILLE]: 1,
  [PageURL.ONBOARDING_TRANSPORT]: 1,
  [PageURL.ONBOARDING_EVENT_OPTIONS]: 1,
  [PageURL.ONBOARDING_FLYERS]: 1,
  [PageURL.ONBOARDING_POINTS_DE_VENTE]: 1,
  [PageURL.ONBOARDING_RECEPTION]: 1,
  [PageURL.ONBOARDING_DETAILS]: 1,
  [PageURL.CALENDAR]: 2,
  [PageURL.DRESSING]: 3,
  [PageURL.OPTIONS]: 4,
  [PageURL.RECAPITULATIF]: 5,
  [PageURL.ORDER]: 6,
  [PageURL.VALIDATION]: 7,
  [PageURL.CVG]: -1,
  [PageURL.ABOUT]: -1,
  [PageURL.CONTACT]: -1,
};

export const useProgressBar = () => {
  const { setStep } = useStepContext();
  const location = useLocation();
  const useUpdateProgressBar = () => {
    useEffect(() => {
      if (STEP_LIST[location.pathname as PageURL] !== -1) {
        setStep(STEP_LIST[location.pathname as PageURL]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
  };
  return useUpdateProgressBar;
};
