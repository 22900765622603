export const SoireePresseIcon = () => (
  <svg
    width="194"
    height="121"
    viewBox="0 0 194 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_946_48055)">
      <path
        d="M69.5263 52.5843C72.0765 52.01 74.8422 54.041 75.2037 56.6032C75.64 59.695 72.7788 62.0699 70.462 63.5339C67.9129 65.1447 65.1471 66.5839 63.7434 69.4C62.5303 71.8338 62.3545 74.7971 63.352 77.3362C65.2827 82.2508 70.8022 83.9422 74.4651 87.2256C75.3607 88.0284 76.1554 88.9426 76.7308 90.0036C77.4861 91.396 77.7953 92.9849 77.7241 94.5612C77.5792 97.7702 75.8422 100.634 73.4842 102.73C71.0558 104.887 67.7407 105.973 65.6868 108.56C64.8061 109.669 64.139 111.071 64.2384 112.52C64.3244 113.774 64.9465 115.009 65.9724 115.752C66.4722 116.114 67.0483 116.317 67.6623 116.361C68.0375 116.389 68.0358 115.804 67.6623 115.777C66.3243 115.679 65.3292 114.543 64.9699 113.316C64.5514 111.887 65.063 110.417 65.8981 109.243C66.8493 107.905 68.2175 106.958 69.5939 106.104C70.9889 105.238 72.4073 104.419 73.6605 103.349C76.008 101.344 77.8 98.6175 78.2201 95.5181C78.4332 93.9461 78.303 92.3114 77.742 90.8209C77.2916 89.6239 76.5794 88.5524 75.7076 87.6211C73.9986 85.7954 71.7931 84.5295 69.7207 83.1702C67.6469 81.81 65.5253 80.2785 64.3207 78.0473C63.0007 75.6021 62.9604 72.5107 64.1066 69.9914C64.7203 68.6426 65.6585 67.5301 66.8132 66.6097C68.0709 65.6072 69.4958 64.841 70.849 63.9801C73.2054 62.4807 75.737 60.2889 75.8221 57.2667C75.8942 54.7063 73.7226 52.3912 71.254 51.973C70.6245 51.8663 69.994 51.8805 69.3709 52.0208C69.0042 52.1034 69.1594 52.6669 69.5263 52.5843L69.5263 52.5843Z"
        fill="#3F3D56"
      />
      <path
        d="M131.201 52.8441C128.65 52.2697 125.885 54.3007 125.523 56.863C125.087 59.9548 127.948 62.3297 130.265 63.7937C132.814 65.4045 135.58 66.8437 136.984 69.6598C138.197 72.0935 138.373 75.0568 137.375 77.5959C135.444 82.5105 129.925 84.202 126.262 87.4853C125.366 88.2882 124.572 89.2024 123.996 90.2633C123.241 91.6557 122.932 93.2447 123.003 94.821C123.148 98.03 124.885 100.894 127.243 102.989C129.671 105.147 132.986 106.233 135.04 108.819C135.921 109.928 136.588 111.331 136.489 112.779C136.403 114.033 135.781 115.269 134.755 116.012C134.255 116.374 133.679 116.576 133.065 116.621C132.69 116.649 132.691 116.064 133.065 116.037C134.403 115.939 135.398 114.803 135.757 113.576C136.176 112.147 135.664 110.677 134.829 109.502C133.878 108.165 132.51 107.218 131.133 106.363C129.738 105.498 128.32 104.679 127.067 103.609C124.719 101.604 122.927 98.8773 122.507 95.7779C122.294 94.2058 122.424 92.5711 122.985 91.0806C123.435 89.8837 124.148 88.8121 125.019 87.8808C126.728 86.0551 128.934 84.7892 131.006 83.4299C133.08 82.0697 135.202 80.5383 136.406 78.307C137.726 75.8619 137.767 72.7704 136.62 70.2511C136.007 68.9023 135.069 67.7898 133.914 66.8694C132.656 65.867 131.231 65.1008 129.878 64.2399C127.522 62.7405 124.99 60.5486 124.905 57.5265C124.833 54.966 127.004 52.651 129.473 52.2327C130.102 52.1261 130.733 52.1403 131.356 52.2806C131.723 52.3632 131.568 52.9267 131.201 52.8441V52.8441Z"
        fill="#3F3D56"
      />
      <path
        d="M172.301 36.0696L171.096 44.3225L164.51 44.4597V37.2593L172.301 36.0696Z"
        fill="#FFB6B6"
      />
      <path
        opacity="0.1"
        d="M172.301 36.0696L171.201 44.5822L164.615 44.7194V37.519L172.301 36.0696Z"
        fill="black"
      />
      <path
        d="M4.92713 119.707H7.25977L7.25977 81.9728H4.92713L4.92713 119.707Z"
        fill="#F2F2F2"
      />
      <path
        d="M14.2572 119.707H16.5898L16.5898 81.9728H14.2572L14.2572 119.707Z"
        fill="#F2F2F2"
      />
      <path
        d="M28.8021 119.707H31.1348L31.1348 81.9728H28.8021L28.8021 119.707Z"
        fill="#F2F2F2"
      />
      <path
        d="M33.1928 119.707H35.5254L35.5254 81.9728H33.1928L33.1928 119.707Z"
        fill="#F2F2F2"
      />
      <path
        d="M3.69144 81.424L0.517175 64.9383C-0.0663953 61.9076 2.04097 59.0171 5.105 58.6457L22.0781 56.5884C22.0781 56.5884 24.4107 75.6611 24.4107 74.7006C24.4107 73.7401 47.5312 77.1018 38.8867 81.9043C30.2423 86.7068 6.91596 85.3346 3.69144 81.424H3.69144Z"
        fill="#F2F2F2"
      />
      <path
        d="M41.5007 109.559L44.3751 112.433L48.3446 114.486L47.9339 118.045L40.4057 117.224L39.4476 115.308L39.7213 113.665L39.1738 111.064L41.5007 109.559Z"
        fill="#A0616A"
      />
      <path
        d="M47.3863 116.677L47.6214 113.657L55.7358 117.498C55.7358 117.498 56.3641 119.277 54.4074 119.551C52.4507 119.825 39.4475 119.825 39.4475 119.14C39.4475 118.456 39.0918 114.952 39.0918 114.952L46.9757 117.224L47.3863 116.677V116.677Z"
        fill="#2F2E41"
      />
      <path
        d="M31.1638 101.575L29.0847 105.068L28.071 109.421L24.5205 109.898L23.4648 102.399L25.0866 100.999L26.746 100.86L29.132 99.6899L31.1638 101.575Z"
        fill="#A0616A"
      />
      <path
        d="M25.712 109.03L28.6968 108.516L26.9697 117.325C26.9697 117.325 25.3995 118.372 24.6529 116.543C23.9063 114.714 20.7078 102.11 21.3712 101.942C22.0346 101.773 25.3433 100.567 25.3433 100.567L25.0803 108.767L25.712 109.03Z"
        fill="#2F2E41"
      />
      <path
        d="M22.146 36.2122L21.7344 44.5822L28.3206 44.7194V37.5189L22.146 36.2122Z"
        fill="#A0616A"
      />
      <path
        opacity="0.1"
        d="M22.146 36.2122L21.7344 44.5822L28.3206 44.7194V37.5189L22.146 36.2122Z"
        fill="black"
      />
      <path
        d="M11.0316 70.79C11.0316 70.79 8.01289 78.6112 10.2083 81.7671C12.4037 84.923 22.6948 86.7068 22.6948 86.7068L26.9484 90.686L38.1999 112.366L44.7861 110.856L35.4556 88.6278C35.4556 88.6278 29.9671 79.7089 27.9089 78.474C25.8507 77.2391 22.0182 72.6024 22.0182 72.6024L11.0316 70.79H11.0316Z"
        fill="#2F2E41"
      />
      <path
        d="M29.6939 71.4761L33.9207 74.4694C33.9207 74.4694 48.9038 78.474 44.1013 87.8045C39.2989 97.135 32.1638 103.858 32.1638 103.858L25.8519 100.291L36.0057 86.0207L18.168 75.1808L29.6939 71.4761V71.4761Z"
        fill="#2F2E41"
      />
      <path
        d="M68.1111 60.098C70.0974 60.4977 71.5012 61.8464 71.2466 63.1104C70.9921 64.3744 69.1758 65.0748 67.1889 64.6748C66.3931 64.5259 65.6444 64.1885 65.0057 63.691L56.6113 61.8914L57.3314 58.5737L65.7165 60.1606C66.4983 59.9488 67.3194 59.9273 68.1111 60.098V60.098Z"
        fill="#A0616A"
      />
      <path
        d="M36.4146 81.5373C37.8663 82.9506 38.3138 84.8452 37.4143 85.7689C36.5147 86.6927 34.609 86.2957 33.1569 84.8817C32.5691 84.3248 32.1233 83.6353 31.8566 82.8708L25.7812 76.8051L28.1861 74.4087L34.3691 80.2907C35.1407 80.5369 35.8421 80.9644 36.4146 81.5373Z"
        fill="#A0616A"
      />
      <path
        d="M27.9773 41.6321H19.4387L13.1582 45.8857L13.7071 62.6258C13.7071 62.6258 14.8547 66.8295 10.9628 70.7214C8.63018 73.054 9.45347 74.4261 9.45347 74.4261L23.312 75.5238L31.6135 73.1226L28.5948 65.5759L29.1436 63.2432C29.1436 63.2432 34.7694 59.264 33.8089 55.1476C32.8484 51.0312 31.6135 42.524 31.6135 42.524L27.9773 41.6321Z"
        fill="#CCCCCC"
      />
      <path
        d="M22.0099 66.262L15.8353 45.9544C15.8353 45.9544 12.6794 44.8567 11.3073 47.6009C9.93513 50.3452 11.6611 60.8421 11.6611 60.8421L18.854 72.711L30.38 81.4927L33.2614 78.3368L22.0099 66.262Z"
        fill="#CCCCCC"
      />
      <path
        d="M48.0678 55.7883L31.5047 42.5146C31.5047 42.5146 28.2803 43.3907 28.6974 46.4304C29.1146 49.4701 36.4662 57.1588 36.4662 57.1588L49.1047 62.8929L63.576 63.6322L64.1721 59.4005L48.0678 55.7883L48.0678 55.7883Z"
        fill="#CCCCCC"
      />
      <path
        d="M26.0579 40.1226C29.2407 40.1226 31.8209 37.5425 31.8209 34.3597C31.8209 31.1768 29.2407 28.5967 26.0579 28.5967C22.8751 28.5967 20.2949 31.1768 20.2949 34.3597C20.2949 37.5425 22.8751 40.1226 26.0579 40.1226Z"
        fill="#A0616A"
      />
      <path
        d="M188.01 81.9729H185.678V119.707H188.01V81.9729Z"
        fill="#F2F2F2"
      />
      <path
        d="M178.68 81.9729H176.348V119.707H178.68V81.9729Z"
        fill="#F2F2F2"
      />
      <path
        d="M164.135 81.9729H161.803V119.707H164.135V81.9729Z"
        fill="#F2F2F2"
      />
      <path
        d="M159.745 81.9729H157.412V119.707H159.745V81.9729Z"
        fill="#F2F2F2"
      />
      <path
        d="M189.246 81.424L192.42 64.9383C193.003 61.9076 190.896 59.0171 187.832 58.6457L170.859 56.5884C170.859 56.5884 168.526 75.6611 168.526 74.7006C168.526 73.7401 145.406 77.1018 154.05 81.9043C162.695 86.7068 186.021 85.3346 189.246 81.424Z"
        fill="#F2F2F2"
      />
      <path
        d="M151.436 109.559L148.561 112.433L144.592 114.486L145.002 118.045L152.531 117.224L153.489 115.308L153.215 113.665L153.763 111.064L151.436 109.559Z"
        fill="#FFB6B6"
      />
      <path
        d="M145.549 116.677L145.314 113.657L137.2 117.498C137.2 117.498 136.571 119.277 138.528 119.551C140.485 119.825 153.488 119.825 153.488 119.14C153.488 118.456 153.844 114.952 153.844 114.952L145.96 117.224L145.549 116.677V116.677Z"
        fill="#2F2E41"
      />
      <path
        d="M144.635 90.3408L142.944 94.0372L139.939 97.3458L141.565 100.538L148.335 97.1427L148.564 95.0126L147.735 93.5687L147.341 90.9404L144.635 90.3408Z"
        fill="#FFB6B6"
      />
      <path
        d="M141.601 99.0641L140.328 96.3159L134.062 102.746C134.062 102.746 134.094 104.633 136.023 104.207C137.953 103.781 150.14 99.2459 149.901 98.6045C149.662 97.963 148.774 94.5552 148.774 94.5552L142.177 99.434L141.601 99.0641Z"
        fill="#2F2E41"
      />
      <path
        d="M181.903 70.79C181.903 70.79 184.922 78.6112 182.726 81.7671C180.531 84.923 170.24 86.7068 170.24 86.7068L165.986 90.686L154.735 112.366L148.148 110.856L157.479 88.6278C157.479 88.6278 162.968 79.7089 165.026 78.474C167.084 77.2391 170.916 72.6024 170.916 72.6024L181.903 70.79H181.903Z"
        fill="#2F2E41"
      />
      <path
        d="M170.231 74.4363L165.365 72.6612C165.365 72.6612 153.552 62.6124 148.607 71.8681C143.662 81.1238 142.188 90.8162 142.188 90.8162L148.711 93.9801L154.677 77.5136L173.736 86.0244L170.231 74.4363V74.4363Z"
        fill="#2F2E41"
      />
      <path
        d="M142.8 79.6229C141.495 81.1734 141.238 83.103 142.225 83.9329C143.212 84.7628 145.069 84.1785 146.373 82.6274C146.903 82.0149 147.278 81.2846 147.467 80.4974L152.911 73.8584L150.28 71.7126L144.711 78.1795C143.968 78.501 143.312 78.996 142.8 79.623L142.8 79.6229Z"
        fill="#FFB6B6"
      />
      <path
        d="M156.522 81.5373C155.071 82.9506 154.623 84.8452 155.523 85.7689C156.422 86.6927 158.328 86.2957 159.78 84.8817C160.368 84.3248 160.814 83.6353 161.08 82.8708L167.156 76.8051L164.751 74.4087L158.568 80.2907C157.796 80.5369 157.095 80.9644 156.522 81.5373Z"
        fill="#FFB6B6"
      />
      <path
        d="M164.958 41.6321H173.496L179.777 45.8857L179.228 62.6258C179.228 62.6258 178.08 66.8295 181.972 70.7214C184.305 73.054 183.481 74.4261 183.481 74.4261L169.623 75.5238L161.321 73.1226L164.34 65.5759L163.791 63.2432C163.791 63.2432 158.165 59.264 159.126 55.1476C160.086 51.0312 161.321 42.524 161.321 42.524L164.958 41.6321Z"
        fill="#F7B80B"
      />
      <path
        d="M170.927 66.262L177.102 45.9544C177.102 45.9544 180.258 44.8567 181.63 47.6009C183.002 50.3452 181.276 60.8421 181.276 60.8421L174.083 72.711L162.557 81.4927L159.676 78.3368L170.927 66.262Z"
        fill="#F7B80B"
      />
      <path
        d="M155.79 63.7598L160.471 43.0567C160.471 43.0567 163.538 41.7323 165.107 44.3694C166.675 47.0065 165.717 57.6012 165.717 57.6012L159.407 69.9621L148.551 79.5593L145.447 76.6214L155.79 63.7598V63.7598Z"
        fill="#F7B80B"
      />
      <path d="M69.1988 52.95H67.3809V119.433H69.1988V52.95Z" fill="#3F3D56" />
      <path
        d="M67.823 62.1907L51.332 48.6903L51.7487 48.3799L68.2397 61.8802L67.823 62.1907Z"
        fill="#3F3D56"
      />
      <path
        d="M68.6796 54.1187H67.6408C65.8508 54.1187 64.3945 52.6625 64.3945 50.8725V42.0426C64.3945 40.2526 65.8508 38.7964 67.6408 38.7964H68.6796C70.4696 38.7964 71.9258 40.2526 71.9258 42.0426V50.8725C71.9258 52.6625 70.4696 54.1187 68.6796 54.1187Z"
        fill="#F7B80B"
      />
      <path
        d="M51.1502 53.4694C53.8036 53.4694 55.9546 49.9231 55.9546 45.5485C55.9546 41.174 53.8036 37.6277 51.1502 37.6277C48.4967 37.6277 46.3457 41.174 46.3457 45.5485C46.3457 49.9231 48.4967 53.4694 51.1502 53.4694Z"
        fill="#F7B80B"
      />
      <path
        d="M68.4199 50.0934C65.6353 50.0934 60.3691 49.7135 60.3691 48.2755C60.3691 47.5695 61.6187 47.0457 64.0831 46.7188L64.1513 47.2337C61.488 47.5872 60.8885 48.1175 60.8885 48.2755C60.8885 48.7163 63.5468 49.574 68.4199 49.574C73.2929 49.574 75.9512 48.7163 75.9512 48.2755C75.9512 48.0536 75.0341 47.4921 72.1996 47.1741L72.2574 46.6577C74.1794 46.8735 76.4706 47.3293 76.4706 48.2755C76.4706 49.7135 71.2044 50.0934 68.4199 50.0934Z"
        fill="#3F3D56"
      />
      <path
        d="M131.008 119.433H132.826V52.9501H131.008V119.433Z"
        fill="#3F3D56"
      />
      <path
        d="M132.385 62.1907L148.876 48.6903L148.46 48.3799L131.969 61.8802L132.385 62.1907Z"
        fill="#3F3D56"
      />
      <path
        d="M128.281 50.8725V42.0426C128.281 40.2526 129.737 38.7964 131.528 38.7964H132.566C134.356 38.7964 135.813 40.2526 135.813 42.0426V50.8725C135.813 52.6625 134.356 54.1187 132.566 54.1187H131.528C129.737 54.1187 128.281 52.6625 128.281 50.8725Z"
        fill="#F7B80B"
      />
      <path
        d="M149.056 53.4694C151.71 53.4694 153.861 49.9231 153.861 45.5485C153.861 41.174 151.71 37.6277 149.056 37.6277C146.403 37.6277 144.252 41.174 144.252 45.5485C144.252 49.9231 146.403 53.4694 149.056 53.4694Z"
        fill="#F7B80B"
      />
      <path
        d="M131.787 50.0934C129.002 50.0934 123.736 49.7135 123.736 48.2755C123.736 47.3293 126.027 46.8735 127.949 46.6577L128.007 47.1741C125.173 47.4921 124.256 48.0536 124.256 48.2755C124.256 48.7163 126.914 49.574 131.787 49.574C136.66 49.574 139.318 48.7163 139.318 48.2755C139.318 48.1175 138.719 47.5872 136.056 47.2337L136.124 46.7188C138.588 47.0457 139.838 47.5695 139.838 48.2755C139.838 49.7135 134.572 50.0934 131.787 50.0934Z"
        fill="#3F3D56"
      />
      <path
        d="M167.891 39.3967C171.28 39.3967 174.027 36.6498 174.027 33.2613C174.027 29.8729 171.28 27.126 167.891 27.126C164.503 27.126 161.756 29.8729 161.756 33.2613C161.756 36.6498 164.503 39.3967 167.891 39.3967Z"
        fill="#FFB6B6"
      />
      <path
        d="M168.381 25.0129C168.203 25.1165 167.965 24.9597 167.909 24.7622C167.852 24.5647 167.918 24.3549 167.984 24.1602L168.315 23.1797C168.55 22.4843 168.799 21.7646 169.307 21.2354C170.075 20.4365 171.294 20.2333 172.392 20.3805C173.802 20.5696 175.193 21.3331 175.849 22.5956C176.505 23.8581 176.225 25.6198 175.041 26.4075C176.729 28.3426 177.318 30.4992 177.225 33.0656C177.132 35.6321 174.335 37.994 172.511 39.8019C172.104 39.555 171.734 38.398 171.958 37.9776C172.182 37.5573 171.861 37.0703 172.138 36.6831C172.415 36.2959 172.647 36.9124 172.367 36.5274C172.19 36.2845 172.881 35.7256 172.618 35.5802C171.345 34.877 170.922 33.2911 170.123 32.0767C169.159 30.6119 167.51 29.62 165.764 29.4554C164.802 29.3647 163.787 29.5289 162.999 30.0872C162.21 30.6455 161.7 31.6432 161.883 32.5916C161.409 32.111 161.174 31.4066 161.263 30.7379C161.352 30.0691 161.763 29.4507 162.345 29.1104C161.991 27.9392 162.295 26.5919 163.117 25.6855C163.939 24.7792 167.273 24.9336 168.474 25.1721"
        fill="#2F2E41"
      />
      <path
        d="M168.566 29.491C170.156 29.6626 171.304 31.0395 172.273 32.311C172.831 33.0439 173.417 33.8533 173.402 34.7747C173.388 35.7063 172.765 36.5053 172.468 37.3881C171.981 38.8311 172.455 40.548 173.614 41.5367C172.469 41.7539 171.232 40.8958 171.034 39.7478C170.805 38.4115 171.818 37.1217 171.698 35.771C171.592 34.581 170.654 33.6651 169.857 32.7754C169.06 31.8856 168.311 30.7049 168.678 29.568"
        fill="#2F2E41"
      />
      <path
        d="M23.1032 24.7115C23.1032 24.7115 20.793 23.0431 18.7396 26.6367C17.3369 29.0914 16.5929 33.7618 16.2622 36.4755C16.1101 37.7245 16.7576 38.9341 17.8798 39.5031L25.1567 43.1928C25.1567 43.1928 22.4615 32.9254 26.1834 31.3853C29.9053 29.8452 29.777 30.2303 29.777 30.2303C29.777 30.2303 32.7289 36.6474 28.8786 39.4709L32.0871 41.5244C32.0871 41.5244 34.269 29.4602 30.2904 27.15C26.3117 24.8399 27.2101 23.1714 23.1032 24.7115Z"
        fill="#2F2E41"
      />
      <path
        d="M215.93 119.953H-23.2539V119.433H215.93C216.074 119.433 216.19 119.55 216.19 119.693C216.19 119.836 216.074 119.953 215.93 119.953Z"
        fill="#3F3D56"
      />
    </g>
    <defs>
      <clipPath id="clip0_946_48055">
        <rect
          width="192.828"
          height="120"
          fill="white"
          transform="translate(0.585938 0.718994)"
        />
      </clipPath>
    </defs>
  </svg>
);
