import { Box, Flex, Text } from "@chakra-ui/react";
import { colors } from "../../ui/theme";
import { FC, ReactElement } from "react";

interface HeaderProps {
  title: string;
  subtitle?: string;
}

const Header: FC<HeaderProps> = ({ title, subtitle }): ReactElement => {
  return (
    <Flex
      direction="column"
      align="flex-start"
      mb={{ md: "20px", "2xl": "35px" }}
      textAlign="left"
    >
      <Box>
        <Text
          variant="2xl"
          fontWeight="700"
          as="span"
          width="fit-content"
          color={colors.secondaryFontColor.lightMode}
        >
          {title[0]}
        </Text>
        <Text
          variant="2xl"
          as="span"
          textTransform="uppercase"
          fontWeight="700"
        >
          {title.slice(1)}
        </Text>
      </Box>
      {subtitle && (
        <Text
          fontWeight="400"
          display="block"
          textAlign="left"
          maxWidth="800px"
          variant="lg"
          mt="7px"
        >
          {subtitle}
        </Text>
      )}
    </Flex>
  );
};

export default Header;
