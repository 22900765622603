import { Flex } from "@chakra-ui/react";
import { FC } from "react";
import DressingSubMenu from "../../../components/PersonalGenericMenuCards/DressingSubMenu";
import { colors } from "../../../ui/theme";

export interface DressingTabMenuItemProps {
  menuTitle: string;
  menuIcon: any;
  tooltipText: string;
}

const DressingTabMenu: FC<DressingTabMenuItemProps> = ({
  menuTitle,
  menuIcon,
  tooltipText,
}) => {
  return (
    <Flex
      border={`1px solid ${colors.gray.lightMode}`}
      className="headingTabItem"
      backgroundColor="transparent"
      width="100%"
      height="100%"
      justifyContent="center"
      cursor="pointer"
      borderRadius={{ md: "16px", "2xl": "3xl" }}
      overflow="hidden"
      position="relative"
      _hover={{
        "& .newHover": {
          top: 0,
        },
      }}
      sx={{
        maxHeight: "100%",
        width: "220px",
        svg: {
          height: "140px",
        },
        "@media(max-width: 1600px), @media(max-height: 850px)": {
          width: "180px",
          svg: {
            height: "70px",
          },
        },
      }}
    >
      <DressingSubMenu
        menuTitle={menuTitle}
        menuIcon={menuIcon}
        tooltipText={tooltipText}
      />
    </Flex>
  );
};

export default DressingTabMenu;
