import "styled-components";
import { extendTheme, theme as baseTheme } from "@chakra-ui/react";

type ArrayLengthMutationKeys =
  | "splice"
  | "push"
  | "pop"
  | "shift"
  | "unshift"
  | number;

type ArrayItems<T extends any[]> = T extends (infer TItems)[] ? TItems : never;

type FixedLengthArray<T extends any[]> = Pick<
  T,
  Exclude<keyof T, ArrayLengthMutationKeys>
> & {
  [Symbol.iterator]: () => IterableIterator<ArrayItems<T>>;
};

type SpacesFixedLenghtArray = FixedLengthArray<
  [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
  ]
>;

export const colors = {
  white: "#fff",
  black: "#000000",
  gray: {
    lighter: "#F7FAFC",
    lightMode: "#D9D9D9",
    darkMode: "#404040",
    darkerMode: "#1A202C",
    transparent: "#EDF2F7",
  },
  red: {
    default: "#E53E3E",
  },
  green: {
    default: "#04B800",
  },
  blue: {
    lightMode: "#386F8D",
    default: "#0085FF",
    transparent: "#559EC2",
  },
  primaryFontColor: {
    lightMode: "#0D2734",
    darkMode: baseTheme.colors.gray["200"],
  },
  secondaryFontColor: {
    lightMode: "#F7B80B",
    darkMode: baseTheme.colors.gray["400"],
  },
  additional: {
    lightMode: "#033349",
  },
  plainOldBlue: "blue",
};

// 3. Call `extendTheme` and pass your custom values
export const customTheme = extendTheme({
  colors: colors,
  fonts: {
    body: "Brandon, sans-serif",
    heading: "Brandon, serif",
    mono: "Brandon",
  },
  components: {
    Switch: {
      variants: {
        calendarSwitch: {
          track: {
            bg: "blue.500",
          },
        },
      },
    },
    Text: {
      baseStyle: () => ({
        fonts: {
          heading: `Brandon`,
          body: `Brandon`,
        },
        color: colors.primaryFontColor.lightMode,
      }),
      variants: {
        secondary: () => ({
          color: colors.primaryFontColor.lightMode,
        }),
        xs: () => ({
          fontWeight: "390",
          fontSize: "12px",
          lineHeight: "18px",
        }),
        sm: () => ({
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "20px",
          "@media (max-width: 1600px)": {
            fontSize: "12px",
            lineHeight: "18px",
          },
        }),
        md: () => ({
          fontWeight: "450",
          fontSize: "16px",
          lineHeight: "20px",
          "@media (max-width: 1600px)": {
            fontSize: "15px",
            lineHeight: "20px",
          },
        }),
        lg: () => ({
          fontWeight: "400",
          fontSize: "18px",
          lineHeight: "28px",
          "@media (max-width: 1600px)": {
            fontSize: "15px",
            lineHeight: "22px",
          },
        }),

        xl: () => ({
          fontWeight: "400",
          fontSize: "20px",
          lineHeight: "28px",
          "@media (max-width: 1600px)": {
            fontSize: "15px",
            lineHeight: "20px",
          },
          "@media (max-width: 1400px)": {
            fontSize: "14px",
            lineHeight: "18px",
          },
        }),
        xls: () => ({
          fontWeight: "400",
          fontSize: "24px",
          lineHeight: "36px",
        }),
        "2xl": () => ({
          fontWeight: "500",
          fontSize: "32px",
          lineHeight: "32px",
          "@media (max-width: 1600px)": {
            fontSize: "28px",
            lineHeight: "30px",
          },
        }),
        "3xl": () => ({
          fontWeight: "500",
          fontSize: "40px",
          lineHeight: "57px",
        }),

        "4xl": () => ({
          fontWeight: "500",
          fontSize: "30px",
          lineHeight: "36px",
        }),
      },
    },
  },

  styles: {
    global: () => ({
      body: {
        color: colors.primaryFontColor.lightMode,
      },
    }),
  },
});

declare module "styled-components" {
  export interface DefaultTheme {
    fontFamilies: {
      heading: string;
      text: string;
    };
    colors: {
      text: string;
      borders: string;
      background: string;
      error: string;
      white: string;
      black: string;
      gray50: string;
      gray100: string;
      gray200: string;
      gray300: string;
      gray400: string;
      gray500: string;
      gray600: string;
      gray700: string;
      gray800: string;
      gray900: string;
      red100: string;
      red400: string;
      red500: string;
    };
    space: SpacesFixedLenghtArray;
    fontWeights: {
      hairline: number;
      thin: number;
      light: number;
      normal: number;
      medium: number;
      semibold: number;
      bold: number;
      extrabold: number;
      black: number;
    };
    paddings: {
      top: number;
      horizontal: number;
      vertical: number;
    };
    dimensions: {
      isSmall: boolean;
      screenWidth: number;
      screenHeight: number;
      statusBar: number;
      bottomTabs: {
        height: number;
        paddingBottom: number;
      };
      headerHeight: number;
      headerContentHeight: number;
      headerMarginBottom: number;
      artworkSummary: number;
      cubeProgressBarHeight: number;
    };
    animations: {
      bottomTabBar: {
        duration: number;
      };
      discoverOverlay: {
        duration: number;
      };
      discoverSwipe: {
        duration: number;
      };
    };
  }
}
