import { Icon, IconProps } from "@chakra-ui/react";

export default function SPeechBubbleArrowIcon(props: IconProps) {
  return (
    <Icon
      width="65"
      height="37"
      viewBox="0 0 65 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.9106 0.32251C28.9106 0.32251 37.0878 24.256 65 36.4119C65 36.4119 18.976 37.4615 0.6689 10.2572L28.9106 0.32251Z"
        fill="white"
      />
    </Icon>
  );
}
