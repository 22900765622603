import { Flex } from "@chakra-ui/react";
import BottomBlock from "../../components/BottomActionsBlock/BottomBlock";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import ContentLayout from "../../components/Layout/ContentLayout";
import GrayLayout from "../../components/Layout/GrayLayout";
import MainContainer from "../../components/Layout/MainContainer";
import HoverCard from "../../components/Multisteps/Simple/components/HoverCard";
import SimpleCard from "../../components/Multisteps/Simple/components/SimpleCard";
import { Icons } from "../../components/PicturesVariant/PicturesVariant";
import TotalCard from "../../components/TotalCard/TotalCard";

export type OnboardingButton<T> = {
  title: string;
  onChoose: (value: T) => void;
  icon: Icons;
  tooltipText?: string;
  isInputText?: boolean;
} & (
  | {
      value: T;
      elements?: never;
    }
  | {
      value?: never;
      elements: {
        title: string;
        value: T;
      }[];
    }
);

export default function OnboardingListButtonLayout<T>({
  title,
  subtitle,
  elements,
}: {
  title: string;
  subtitle: string;
  elements: OnboardingButton<T>[];
}) {
  return (
    <Layout>
      <ContentLayout>
        <MainContainer>
          <Flex flexDir="column">
            <Header title={title} subtitle={subtitle} />
            <GrayLayout
              display="flex"
              flexWrap="wrap"
              w="fit-content"
              gap="1rem"
              sx={{
                svg: {
                  height: "140px",
                },
              }}
            >
              {elements.map((elem) =>
                elem.value ? (
                  <SimpleCard
                    key={elem.title}
                    icon={elem.icon}
                    isSelected={false}
                    onChoose={() => {
                      elem.onChoose(elem.value!);
                    }}
                    title={elem.title}
                    tooltipText={elem.tooltipText ?? ""}
                    disabled={
                      elem.title === "Echantillonnage et test produit"
                        ? true
                        : false
                    }
                    isInputText={elem.isInputText}
                  />
                ) : (
                  <HoverCard
                    key={elem.title}
                    elements={elem.elements!}
                    onChoose={(e) => elem.onChoose(e.value)}
                    icon={elem.icon}
                    subtitle={elem.title}
                  />
                )
              )}
            </GrayLayout>
          </Flex>
          <BottomBlock />
          <TotalCard />
        </MainContainer>
      </ContentLayout>
    </Layout>
  );
}
