export const IntroductionGirlIcon = () => (
  <svg
    width="205"
    height="553"
    viewBox="0 0 205 553"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M172.039 42.6319V78.6835H109.301C108.059 78.6835 106.843 78.5271 105.688 78.2317C105.653 78.0927 105.61 77.9537 105.575 77.8147C104.941 75.643 104.02 73.5493 102.822 71.6295C102.839 73.3409 102.509 75.0609 101.893 76.6594C97.6197 74.1227 94.7451 69.4578 94.7451 64.1239C94.7451 64.1239 98.219 3.10556 133.392 3.97428C144.062 4.23781 153.732 8.30044 160.723 15.2936C167.714 22.2867 172.039 31.9554 172.039 42.6319V42.6319Z"
      fill="#1A202C"
    />
    <path
      d="M153.913 533.592L145.802 533.784L141.281 497.336L153.25 497.052L153.913 533.592Z"
      fill="#FCB4B4"
    />
    <path
      d="M126.086 550.518C126.086 551.889 127.058 553 128.258 553H144.357C144.357 553 145.941 546.63 152.4 543.888L152.846 553H161.151L160.145 538.349C160.145 538.349 162.366 530.511 157.753 526.504C153.139 522.497 156.876 523.055 156.876 523.055L155.061 513.987L142.511 515.463L142.419 529.698L136.329 543.827L127.396 548.241C126.601 548.634 126.086 549.529 126.086 550.518Z"
      fill="#1A202C"
    />
    <path
      d="M36.1321 532.306L28.3574 529.987L35.2896 493.919L46.7633 497.341L36.1321 532.306Z"
      fill="#FCB4B4"
    />
    <path
      d="M4.44425 539.827C4.02187 541.13 4.60444 542.487 5.74547 542.857L21.0604 547.821C21.0604 547.821 24.5307 542.25 31.5204 541.634L29.1364 550.439L37.0369 553L40.5949 538.752C40.5949 538.752 45.124 531.981 41.9697 526.747C38.8154 521.512 42.1985 523.195 42.1985 523.195L43.2665 514.009L30.8732 511.544L26.3985 525.056L16.2504 536.619L6.39225 538.064C5.51485 538.192 4.74929 538.885 4.44425 539.827Z"
      fill="#1A202C"
    />
    <path
      d="M161.617 172.504L97.3501 184.666L67.822 298.468C67.822 298.468 54.795 331.479 59.1373 341.903L21.793 500.877L47.8472 505.221L129.484 276.75L135.799 270.433L131.474 505.221H161.617L190.277 243.739C190.277 243.739 187.671 195.96 161.617 172.504Z"
      fill="#1A202C"
    />
    <path
      d="M204.568 234.916C205.55 241.044 203.185 246.519 199.286 247.144C195.387 247.77 191.43 243.308 190.448 237.179C190.019 234.496 190.23 231.939 190.931 229.859L187.096 203.837L199.342 202.297L201.823 228.112C203.138 229.87 204.138 232.233 204.568 234.916V234.916Z"
      fill="#FCB4B4"
    />
    <path
      d="M14.5323 217.536C10.4632 222.223 4.74775 223.922 1.76647 221.331C-1.21481 218.741 -0.332915 212.843 3.73624 208.157C5.51744 206.105 7.61411 204.627 9.65882 203.829L27.15 184.189L36.1893 192.596L17.987 211.065C17.4839 213.201 16.3136 215.485 14.5324 217.536H14.5323Z"
      fill="#FCB4B4"
    />
    <path
      d="M141.642 63.0466H113.902L99.087 82.1583L87.7969 119.513L91.2708 143.837C91.2708 143.837 89.5339 152.031 91.2708 153.581C93.0078 155.13 94.7447 153.393 94.7447 156.867C94.7447 160.342 89.5339 159.474 93.0078 162.948C96.4817 166.423 97.3501 186.404 97.3501 186.404L132.089 181.191L161.617 174.242L170.302 163.817C170.302 163.817 177.25 150.786 175.513 136.887C173.776 122.988 175.513 135.194 175.513 135.194L175.791 109.913L164.223 74.3399L146.853 73.4712L141.642 63.0466L141.642 63.0466Z"
      fill="#F7B80B"
    />
    <path
      d="M159.012 76.0773C159.012 76.0773 178.987 67.3902 186.803 83.027C194.619 98.6638 198.093 152.524 198.093 152.524L202.435 217.677L187.671 220.283L182.46 161.211L171.17 103.007L159.012 76.0773V76.0773Z"
      fill="#F7B80B"
    />
    <path
      d="M119.585 87.6034C119.585 87.6034 105.631 70.874 91.6312 81.3395C77.6311 91.805 50.3638 138.378 50.3638 138.378L17.2539 194.654L29.2788 203.61L60.4367 153.159L99.5801 115.357L119.585 87.6034Z"
      fill="#F7B80B"
    />
    <path
      d="M130.352 58.703C142.823 58.703 152.932 48.5907 152.932 36.1165C152.932 23.6423 142.823 13.53 130.352 13.53C117.881 13.53 107.771 23.6423 107.771 36.1165C107.771 48.5907 117.881 58.703 130.352 58.703Z"
      fill="#FCB4B4"
    />
    <path
      d="M158.144 88.2393C155.521 87.9092 152.69 88.0135 149.867 88.2393C149.233 86.0676 148.313 83.9739 147.114 82.0541C147.131 84.3301 146.55 86.6062 145.507 88.6303C139.046 89.2297 133.305 89.5858 131.221 85.6332C124.742 73.3409 160.08 56.4879 152.933 36.9853C150.996 31.7036 149.676 35.9429 143.38 35.2479C141.061 34.996 133.974 32.8503 125.837 31.165C125.758 30.7827 125.654 30.4092 125.55 30.0357C124.916 27.8639 123.995 25.7703 122.797 23.8504C122.814 26.0743 122.258 28.2982 121.26 30.2876C116.023 29.3841 110.656 28.8195 106.035 29.167C101.337 29.5231 102.153 25.918 102.561 24.8234C105.167 17.8737 103.632 3.10561 115.589 3.10561C128.616 3.10561 127.382 -1.4899 133.826 0.499473C139.35 2.21081 155.174 4.89516 163.355 16.1363C171.9 27.8812 175.748 44.8819 175.513 50.8847C175.209 58.5554 178.987 90.8455 158.144 88.2393V88.2393Z"
      fill="#1A202C"
    />
  </svg>
);
