import { PersonnelType } from "../../context/UserContext";

const STAFF_ID_TO_TYPE: {
  [key in PersonnelType]: string;
} = {
  // ACCUEIL
  chefHotesse: "CHEF(FE) HOTE(SSE)",
  hotessePolyvalente: "HOTE(SSE) POLYVALENT(E)",
  accueil: "HOTE(SSE) ACCUEIL",
  barman: "BARMAN",
  maitreHotel: "MAITRE HOTEL",
  orientation: "ORIENTATION",
  placementEnSalle: "PLACEMENT EN SALLE",
  accompagnementTransfert: "ACCOMPAGNEMENT TRANSFERT",
  hotesseBilingue: "HOTE(SSE) BILINGUE",
  animateurBilingue: "ANIMATEUR(TRICE) BILINGUE",
  hotesseTrilingue: "HOTE(SSE) TRILINGUE",
  plateau: "PLATEAU",
  // manutentionnaire: "MANUTENTIONNAIRE",
  voiturier: "VOITURIER",
  vestiaire: "HOTE(SSE) VESTIAIRE",
  responsableVestiaire: "RESPONSABLE VESTIAIRE",
  // petitesMains: "PETITES MAINS",
  emargement: "EMARGEMENT",
  passageMicro: "PASSAGE MICRO",
  regisseur: "REGISSEUR",

  // STREET MARKETING
  chef: "CHEF D'EQUIPE",
  distribution: "DIFFUSEURS/EUSES",
  animationEvenementielle: "ANIMATEUR/TRICE EVENEMENTIELLE",
  animationMicroStreetMarketing: "ANIMATEUR/TRICE MICRO",
  roads: "ROADS", // ?

  // ANIMATION MAGASIN
  demonstrationProduit: "DEMONSTRATEUR/TRICE PRODUIT",
  animationMicroRetails: "ANIMATEUR/TRICE_EVENT MICRO MAGASIN",
  brandAmbassadeur: "BRAND AMBASSADEUR",
  animateurMascotte: "ANIMATEUR MASCOTTE",
  autreDitesNousTout: "AUTRE",
};

export default STAFF_ID_TO_TYPE;
