import { Flex } from "@chakra-ui/react";
import { FC } from "react";
import { OptionDetails } from "../../../context/OptionsContext";
import { BackendPrice } from "./OptionsBlock";
import OptionsSingleCard from "./OptionsSingleCard";

interface SingleOptionsCardProps {
  onIncrement: () => void;
  onDecrement: () => void;
  onEditValue: (value: number) => void;
  optionData: OptionDetails;
  price: BackendPrice;
  amount: number;
}

const SingleOptionsCardWrapper: FC<SingleOptionsCardProps> = ({
  onIncrement,
  onDecrement,
  onEditValue,
  optionData,
  price,
  amount,
}) => {
  return (
    <Flex
      backgroundColor="transparent"
      width="190px"
      height="fit-content"
      justifyContent="center"
      minWidth="190px"
      _hover={{
        "& .newHover": {
          top: 0,
        },
      }}
      overflow="hidden"
      position="relative"
      sx={{
        maxHeight: "100%",
        width: "220px",
        "@media(max-width: 1600px), @media(max-height: 850px)": {
          width: "180px",
          svg: {
            height: "70px",
          },
        },
      }}
    >
      <OptionsSingleCard
        onIncrement={onIncrement}
        onDecrement={onDecrement}
        onEditValue={onEditValue}
        optionData={optionData}
        price={price}
        amount={amount}
      />
    </Flex>
  );
};

export default SingleOptionsCardWrapper;
