import {Flex, Text} from "@chakra-ui/react";
import {FC, ReactElement} from "react";
import {CvgContentProps, PageProps} from "../../Onboarding/type";
import {colors} from "../../../ui/theme";

const CvgContentBlock: FC<PageProps> = ({page}): ReactElement => (
  <Flex
    border={`1px solid ${colors.gray.lightMode}`}
    borderRadius='28px'
    p={{md: '20px', '2xl': '37px'}}
  >
    <Flex
      direction='column'
      alignItems='flex-start'
      gap='50px'
      maxHeight={{md: '350px', '2xl': "500px"}}
      overflowY="auto">
      <Text variant='xl' fontWeight='700'>
        CONDITIONS GENERALES DE VENTE - E-NORELA
      </Text>
      <Flex direction='column' alignItems='flex-start' textAlign='left' gap='20px'>
        {page.cvgContent?.map(({title, values}: CvgContentProps) => (
          <Flex direction='column' key={title}>
            <Text variant='xl' fontWeight='700'>{title}</Text>
            {values?.map((item, i) => (
              <Text variant='md' key={i}>{item}</Text>
            ))}
          </Flex>
        ))}
      </Flex>
    </Flex>
  </Flex>
);

export default CvgContentBlock