import { Flex } from "@chakra-ui/react";
import DressingBlock from "../../components/DressingBlock/DressingBlock";
import Header from "../../components/Header";
import ContentLayout from "../../components/Layout/ContentLayout";
import MainContainer from "../../components/Layout/MainContainer";
import TotalCard from "../../components/TotalCard/TotalCard";
import useServerSubmission from "../../hooks/useServerSubmission";
import { useEffect, useState } from "react";

export default function DressingContent() {
  const { multipleRequestData } = useServerSubmission();
  const [previousHt, setPreviousHt] = useState<any>(undefined);
  const [previousTtc, setPreviousTtc] = useState<any>(undefined);
  const [previousTva, setPreviousTva] = useState<any>(undefined);
  useEffect(() => {
    if (multipleRequestData?.computePriceMultiple?.priceHt)
      setPreviousHt(multipleRequestData?.computePriceMultiple?.priceHt);
    if (multipleRequestData?.computePriceMultiple?.priceTtc)
      setPreviousTtc(multipleRequestData?.computePriceMultiple?.priceTtc);
    if (multipleRequestData?.computePriceMultiple?.priceTva)
      setPreviousTva(multipleRequestData?.computePriceMultiple?.priceTva);
  }, [multipleRequestData]);
  return (
    <ContentLayout>
      <MainContainer>
        <Flex direction="column">
          <Header
            title="Le Dressing"
            subtitle="Habillez votre équipe directement depuis notre dressing ou fournissez vous-même leur tenue."
          />
          <DressingBlock />
        </Flex>
        <TotalCard
          priceHt={previousHt}
          priceTtc={previousTtc}
          priceTva={previousTva}
          priceHtDiscounted={
            multipleRequestData?.computePriceMultiple?.priceHtDiscounted
          }
          discountPercent={
            multipleRequestData?.computePriceMultiple?.discountPercent
          }
          discountErrorMessage={
            multipleRequestData?.computePriceMultiple?.discountErrorMessage
          }
        />
      </MainContainer>
    </ContentLayout>
  );
}
