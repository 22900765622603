export const AnimerIcon = () => (
  <svg width="98" height="101" viewBox="0 0 98 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1017_48670)">
      <path d="M42.4711 99.0956L40.1758 99.0953L39.084 90.2422L42.4715 90.2424L42.4711 99.0956Z" fill="#A0616A"/>
      <path d="M34.6072 99.0434C34.5358 99.1638 34.498 99.5523 34.498 99.6923C34.498 100.122 34.8468 100.471 35.2771 100.471H42.3851C42.6786 100.471 42.9166 100.233 42.9166 99.9398V99.6439C42.9166 99.6439 43.2682 98.7545 42.5443 97.6582C42.5443 97.6582 41.6445 98.5166 40.3 97.1721L39.9035 96.4539L37.0336 98.5528L35.4429 98.7486C35.0948 98.7914 34.7863 98.7419 34.6072 99.0434H34.6072Z" fill="#2F2E41"/>
      <path d="M32.1859 99.0956L29.8907 99.0953L28.7988 90.2422L32.1864 90.2424L32.1859 99.0956Z" fill="#A0616A"/>
      <path d="M24.3221 99.0434C24.2506 99.1638 24.2129 99.5523 24.2129 99.6923C24.2129 100.122 24.5617 100.471 24.9919 100.471H32.1C32.3935 100.471 32.6314 100.233 32.6314 99.9398V99.6439C32.6314 99.6439 32.9831 98.7545 32.2591 97.6582C32.2591 97.6582 31.3593 98.5166 30.0149 97.1721L29.6184 96.4539L26.7485 98.5528L25.1577 98.7486C24.8097 98.7914 24.5011 98.7419 24.3221 99.0434H24.3221Z" fill="#2F2E41"/>
      <path d="M42.6678 43.5537L44.8047 49.0295L43.1352 92.7692H38.4607L34.2537 53.3033L33.252 92.9028L28.7111 92.7692L23.7695 48.8292L27.0417 44.8893L42.6678 43.5537Z" fill="#2F2E41"/>
      <path d="M32.583 12.5017L38.1924 12.1011L39.9286 14.9058L45.1373 16.3749L43.2008 29.9309L45.2709 50.1646C45.2709 50.1646 37.7917 49.0962 37.2575 51.2331C36.7233 53.37 23.6348 49.0962 23.6348 49.0962L28.1089 35.2731L27.7082 24.455L26.5062 18.1779L31.982 15.6403L32.583 12.5017Z" fill="#E6E6E6"/>
      <path d="M30.0455 16.5084C30.0455 16.5084 25.5755 16.4416 24.9724 18.3114C24.3693 20.1812 22.366 33.1361 22.366 33.1361L20.8301 38.8123L27.7082 35.4066L26.2391 33.1398L31.0471 23.9207L30.0455 16.5084H30.0455Z" fill="#E6E6E6"/>
      <path d="M32.7811 37.2765C33.5202 37.2773 34.2073 36.8513 34.4947 36.1704C34.712 35.6554 34.6915 35.1613 34.5289 34.7377C34.5812 34.716 34.6331 34.6935 34.6867 34.6743C34.608 34.6424 34.5315 34.6064 34.455 34.5703C34.1888 34.0396 33.6784 33.6546 33.0704 33.561C32.3174 32.7544 31.8545 31.6735 31.8496 30.4839C31.8397 28.0322 33.8285 25.9843 36.2795 25.9255C36.2988 25.9251 36.3181 25.9247 36.3373 25.9245C37.4348 25.9119 38.4305 26.5258 38.9865 27.4721C39.311 28.0243 39.9106 28.395 40.5976 28.395C41.1235 28.395 41.5979 28.1776 41.937 27.8278C42.6759 27.0658 43.6011 26.4989 44.6482 26.3249C45.3358 26.2107 46.0474 26.1676 46.7755 26.2038C51.7599 26.4516 56.0481 30.8692 56.1546 35.8585C56.2731 41.4156 51.8038 45.9577 46.2737 45.9577C43.3962 45.9577 40.8071 44.7271 39.0015 42.7641C38.1999 41.8926 37.0765 41.4045 35.8925 41.4166C35.8804 41.4167 35.8684 41.4167 35.8563 41.4167C33.9385 41.4167 32.3838 39.8621 32.3838 37.9443C32.3838 37.8379 32.3891 37.7329 32.399 37.6291C32.4176 37.4352 32.5863 37.2763 32.7812 37.2765L32.7811 37.2765Z" fill="#F7B80B"/>
      <path d="M97.3772 74.2569C97.1346 73.8478 96.0851 74.2858 95.8922 73.9146C95.6999 73.5447 96.675 72.981 97.2321 71.6935C97.3326 71.4613 97.9657 69.9981 97.497 69.5466C96.6086 68.6908 92.5434 72.2225 91.1207 71.0667C90.8085 70.8131 90.5904 70.3068 89.6454 69.4847C89.2695 69.1577 89.0422 69.01 88.8222 69.0649C88.5101 69.1429 88.4853 69.5607 88.1506 70.3821C87.6486 71.614 87.3517 71.5396 87.0592 72.5225C86.8421 73.2521 86.8917 73.6763 86.6362 73.759C86.2747 73.8759 85.9419 73.102 85.4837 73.1713C85.0161 73.242 84.7335 74.1431 84.6442 74.8177C84.4766 76.0838 84.9416 76.9192 85.1993 77.7976C85.4793 78.7523 85.6455 80.2041 84.9988 82.2594L78.5977 100.295C80.0108 96.5938 84.1026 86.449 85.822 82.6791C86.3184 81.5908 86.8664 80.487 87.9917 80.0513C89.0751 79.6318 90.563 79.8823 92.4599 79.4253C92.6815 79.3719 93.2962 79.2155 93.3736 78.8539C93.4376 78.555 93.076 78.3881 93.1316 78.1081C93.2061 77.7326 93.9172 77.728 94.791 77.2947C95.4072 76.9892 95.7911 76.6258 96.1212 76.3133C96.2207 76.2191 97.7002 74.8014 97.3772 74.2569Z" fill="#F2F2F2"/>
      <path d="M72.1928 83.7514C71.9221 83.7524 71.8342 84.3939 71.5963 84.3815C71.3593 84.3691 71.364 83.7279 70.8928 83.083C70.8079 82.9667 70.2724 82.2338 69.9156 82.3335C69.2392 82.5226 69.7996 85.5369 68.8219 85.9017C68.6073 85.9817 68.2959 85.9427 67.6197 86.1693C67.3508 86.2594 67.2127 86.3284 67.1762 86.4522C67.1244 86.6279 67.3223 86.7606 67.6289 87.1619C68.0887 87.7637 67.9665 87.888 68.3645 88.3151C68.66 88.6322 68.8825 88.7302 68.8493 88.8795C68.8024 89.0907 68.3265 89.0307 68.2283 89.2756C68.1282 89.5255 68.4888 89.9242 68.7942 90.1627C69.3672 90.6102 69.9114 90.6231 70.4169 90.75C70.9663 90.888 71.7268 91.2254 72.5489 92.1358L79.5538 100.481C78.1452 98.7199 74.3467 93.7845 72.9925 91.8528C72.6015 91.2952 72.2179 90.7077 72.3287 90.0297C72.4355 89.377 72.9877 88.7189 73.3107 87.6561C73.3485 87.5319 73.4491 87.1851 73.2939 87.0428C73.1657 86.9251 72.9794 87.0545 72.858 86.9464C72.6952 86.8015 72.8981 86.4511 72.9376 85.8972C72.9654 85.5066 72.8979 85.2134 72.8398 84.9612C72.8222 84.8852 72.5533 83.75 72.1928 83.7514V83.7514Z" fill="#F2F2F2"/>
      <path d="M77.5948 84.9639L77.4316 83.5077L77.5062 83.3973C77.8511 82.8867 78.0269 82.3882 78.029 81.915C78.0293 81.8397 78.0258 81.7645 78.0223 81.6878C78.0082 81.384 77.9907 81.0064 78.1876 80.5662C78.298 80.3208 78.6081 79.7527 79.0743 79.823C79.1998 79.8406 79.2947 79.8982 79.3668 79.9657C79.3773 79.9489 79.3882 79.932 79.3998 79.9137C79.545 79.6922 79.66 79.5973 79.7708 79.5059C79.8559 79.4355 79.9438 79.3631 80.0816 79.1915C80.142 79.1163 80.1892 79.0502 80.2289 78.9946C80.3495 78.8266 80.507 78.6262 80.7848 78.6297C81.0815 78.6437 81.2369 78.8892 81.3399 79.0516C81.5238 79.3413 81.6071 79.5501 81.6623 79.6887C81.6824 79.7393 81.7052 79.7963 81.7168 79.8138C81.8125 79.9545 82.5898 79.823 82.8827 79.7752C83.5405 79.6655 84.1098 79.5705 84.3443 79.9854C84.5123 80.2822 84.3875 80.6745 83.9621 81.1829C83.8295 81.3412 83.6885 81.4614 83.5634 81.5556C83.6671 81.6175 83.7599 81.7124 83.7975 81.8615C83.8861 82.2145 83.5827 82.5682 82.8961 82.9142C82.7255 83.0007 82.496 83.116 82.1742 83.1715C82.0227 83.1976 81.8863 83.2039 81.7696 83.2074C81.7671 83.2749 81.7513 83.348 81.7101 83.4233C81.5895 83.6448 81.3385 83.7502 80.9602 83.7249C80.5439 83.7031 80.2011 83.6237 79.8988 83.5541C79.6347 83.4936 79.4072 83.4423 79.2286 83.4542C78.8974 83.4809 78.6422 83.746 78.3426 84.0899L77.5948 84.9639Z" fill="#F2F2F2"/>
      <path d="M78.3521 76.293L76.9849 76.8202L76.8592 76.7761C76.2779 76.572 75.751 76.5284 75.2927 76.6464C75.2199 76.6651 75.148 76.6876 75.0747 76.7105C74.7845 76.8011 74.4237 76.9139 73.9479 76.8351C73.6825 76.7906 73.0543 76.6347 73.004 76.1659C72.9892 76.04 73.0209 75.9336 73.0679 75.8468C73.0489 75.8408 73.0298 75.8346 73.0092 75.828C72.7581 75.7437 72.6371 75.6566 72.5206 75.5726C72.431 75.5082 72.3387 75.4415 72.1377 75.3517C72.0496 75.3123 71.9737 75.2835 71.9099 75.2592C71.7168 75.1851 71.483 75.0836 71.4159 74.8141C71.3542 74.5234 71.5522 74.3109 71.6832 74.17C71.9168 73.9187 72.0977 73.7851 72.2177 73.6966C72.2616 73.6643 72.3109 73.6278 72.3249 73.6121C72.4367 73.4839 72.1123 72.7653 71.9918 72.4942C71.7188 71.8857 71.4826 71.3591 71.8244 71.0271C72.0688 70.7892 72.48 70.8105 73.0797 71.093C73.2664 71.1811 73.4185 71.287 73.5414 71.3841C73.5749 71.2681 73.6432 71.1542 73.7778 71.08C74.0968 70.9048 74.5159 71.1086 75.0247 71.685C75.1517 71.828 75.3214 72.0209 75.4568 72.318C75.5204 72.4579 75.5611 72.5883 75.5941 72.7003C75.66 72.6856 75.7348 72.6823 75.818 72.703C76.0629 72.7635 76.2286 72.9796 76.3 73.3519C76.3845 73.7601 76.3946 74.1119 76.404 74.422C76.4125 74.6928 76.4205 74.9258 76.4774 75.0956C76.5872 75.4091 76.9084 75.5888 77.317 75.7914L78.3522 76.293L78.3521 76.293Z" fill="#F2F2F2"/>
      <path d="M77.5948 72.0028L77.4316 70.5465L77.5062 70.4361C77.8511 69.9256 78.0269 69.427 78.029 68.9538C78.0293 68.8785 78.0258 68.8033 78.0223 68.7266C78.0082 68.4229 77.9907 68.0453 78.1876 67.6051C78.298 67.3596 78.6081 66.7915 79.0743 66.8618C79.1998 66.8794 79.2947 66.937 79.3668 67.0045C79.3773 66.9877 79.3882 66.9708 79.3998 66.9525C79.545 66.731 79.66 66.6361 79.7708 66.5447C79.8559 66.4744 79.9438 66.4019 80.0816 66.2303C80.142 66.1551 80.1892 66.089 80.2289 66.0335C80.3495 65.8654 80.507 65.665 80.7848 65.6685C81.0815 65.6826 81.2369 65.928 81.3399 66.0904C81.5238 66.3801 81.6071 66.589 81.6623 66.7275C81.6824 66.7781 81.7052 66.8351 81.7168 66.8527C81.8125 66.9933 82.5898 66.8618 82.8827 66.814C83.5405 66.7043 84.1098 66.6094 84.3443 67.0242C84.5123 67.321 84.3875 67.7133 83.9621 68.2217C83.8295 68.38 83.6885 68.5002 83.5634 68.5944C83.6671 68.6563 83.7599 68.7512 83.7975 68.9003C83.8861 69.2533 83.5827 69.607 82.8961 69.953C82.7255 70.0395 82.496 70.1548 82.1742 70.2104C82.0227 70.2364 81.8863 70.2427 81.7696 70.2462C81.7671 70.3137 81.7513 70.3869 81.7101 70.4621C81.5895 70.6836 81.3385 70.7891 80.9602 70.7638C80.5439 70.742 80.2011 70.6625 79.8988 70.5929C79.6347 70.5324 79.4072 70.4811 79.2286 70.493C78.8974 70.5197 78.6422 70.7848 78.3426 71.1287L77.5948 72.0028Z" fill="#F2F2F2"/>
      <path d="M79.0846 100.271L78.7794 100.079L78.7056 99.7271L78.7794 100.079L78.4236 100.12C78.418 100.087 78.3987 100.011 78.3695 99.8923C78.2102 99.2412 77.7247 97.2596 77.3196 94.1389C77.037 91.9604 76.877 89.7208 76.8439 87.4812C76.8109 85.238 76.9171 83.5398 77.0022 82.175C77.0665 81.1455 77.1446 80.1653 77.2212 79.2103C77.4251 76.6592 77.6174 74.2494 77.4743 71.5815C77.4427 70.9859 77.3762 69.7462 76.652 68.4271C76.2318 67.662 75.6531 66.9827 74.9316 66.4089L75.3803 65.845C76.1766 66.4792 76.8169 67.2316 77.2834 68.0811C78.0865 69.5437 78.1589 70.8945 78.1937 71.5436C78.3393 74.2585 78.1448 76.6915 77.9388 79.2673C77.8628 80.2187 77.7848 81.1947 77.7208 82.22C77.6364 83.5722 77.5313 85.2556 77.564 87.4699C77.5963 89.6828 77.7546 91.895 78.0334 94.0461C78.4335 97.1274 78.912 99.0794 79.0692 99.7207C79.1528 100.063 79.1704 100.135 79.0846 100.271Z" fill="#F2F2F2"/>
      <path d="M73.6121 67.5531C73.5826 67.5531 73.5527 67.5524 73.5225 67.5503C72.9093 67.5173 72.3415 67.1502 71.8345 66.4597C71.5968 66.1348 71.4752 65.7642 71.2326 65.0252C71.1949 64.9113 71.0121 64.3333 70.901 63.538C70.8282 63.0183 70.8374 62.801 70.9404 62.6091C71.0547 62.3953 71.2396 62.2462 71.4477 62.1457C71.4386 62.0767 71.446 62.0057 71.4759 61.9333C71.5986 61.633 71.9248 61.6724 72.1013 61.6914C72.1906 61.7027 72.3021 61.7174 72.4223 61.7111C72.6115 61.702 72.7131 61.6457 72.8671 61.5613C73.0144 61.4804 73.1976 61.3799 73.4694 61.3243C74.0052 61.2125 74.4542 61.3644 74.6022 61.4143C75.381 61.6738 75.7649 62.2793 76.2093 62.9803C76.2979 63.121 76.6021 63.6329 76.8028 64.3347C76.9477 64.841 76.9273 65.066 76.8837 65.2333C76.7951 65.5772 76.5859 65.7741 76.0546 66.2086C75.4998 66.6636 75.2214 66.8914 74.9823 67.03C74.4257 67.3513 74.0762 67.5531 73.6121 67.5531V67.5531Z" fill="white"/>
      <path d="M49.2114 34.3382L15.9558 28.061L15.8223 30.4651L48.8107 38.3449L49.2114 34.3382Z" fill="#F7B80B"/>
      <path d="M16.3562 28.4618L15.9556 28.0611C15.9556 28.0611 14.2193 27.2598 14.2193 27.1262C14.2193 27.0426 11.6555 26.1742 9.73734 25.5362C8.66607 25.1798 7.53473 25.885 7.38219 27.0036L7.14181 28.7663C6.99808 29.8203 7.79335 30.769 8.85627 30.8116L13.5516 30.9994L15.822 30.4651L16.3562 28.4618Z" fill="#F7B80B"/>
      <path d="M49.1442 35.0727L49.2235 34.74L15.9467 28.3782L15.8887 28.7955L49.1442 35.0727Z" fill="white"/>
      <path d="M49.068 35.7046L49.1442 35.34L15.9105 29.0369L15.8887 29.4635L49.068 35.7046Z" fill="#3F3D56"/>
      <path d="M48.9921 36.4616L49.011 35.874L15.8848 29.5161L15.889 29.864L48.9921 36.4616Z" fill="#3F3D56"/>
      <path d="M48.9039 37.3411L48.878 36.8091L15.837 29.9956L15.8086 30.126L48.9039 37.3411Z" fill="#3F3D56"/>
      <path d="M48.9036 37.8743L48.8783 37.4769L15.887 30.2161L15.8574 30.3462L48.9036 37.8743Z" fill="#3F3D56"/>
      <path d="M29.7933 15.657L31.448 14.7407L32.1657 29.3967L31.9154 31.1997C31.9154 31.1997 27.7379 19.6803 29.7933 15.657H29.7933Z" fill="#3F3D56"/>
      <path d="M35.4547 11.0993C37.7413 11.0993 39.595 9.24569 39.595 6.9591C39.595 4.6725 37.7413 2.81885 35.4547 2.81885C33.1681 2.81885 31.3145 4.6725 31.3145 6.9591C31.3145 9.24569 33.1681 11.0993 35.4547 11.0993Z" fill="#A0616A"/>
      <path d="M31.9166 9.42993L32.9064 10.2201L31.7831 5.95746C31.7831 5.95746 34.9216 5.89068 35.1887 4.15445C35.1887 4.15445 37.6595 4.08767 38.3273 4.08767C38.9951 4.08767 39.2964 8.5063 39.2964 8.5063C39.2964 8.5063 40.5978 4.88901 39.2622 3.01922C37.9267 1.14943 38.3941 2.28466 38.127 1.61688C37.8599 0.949098 35.122 0.334948 33.3857 1.01588C31.6495 1.6968 31.5827 2.66745 31.0485 2.67639C30.5143 2.68533 28.6445 5.35646 30.3807 7.49336C32.117 9.63026 31.9166 9.42993 31.9166 9.42993Z" fill="#2F2E41"/>
      <path d="M44.5656 36.5992C45.0563 36.2076 45.4179 35.7377 45.6217 35.2732L50.5322 31.8384L48.8295 29.5496L44.0316 33.2803C43.5334 33.3759 42.9949 33.6241 42.5042 34.0157C41.3831 34.9102 40.9357 36.2137 41.505 36.9271C42.0742 37.6405 43.4445 37.4937 44.5656 36.5992Z" fill="#A0616A"/>
      <path d="M25.7515 31.3743C25.3874 31.8857 25.169 32.437 25.1008 32.9396L21.3242 37.5925L23.5908 39.3247L27.1777 34.4184C27.6304 34.1895 28.0798 33.8027 28.4439 33.2913C29.2758 32.1229 29.3474 30.7467 28.6039 30.2173C27.8604 29.688 26.5834 30.206 25.7515 31.3743L25.7515 31.3743Z" fill="#A0616A"/>
      <path d="M43.0014 17.243C43.0014 17.243 44.2034 15.2397 45.4054 16.4417C46.3566 17.3928 51.5727 23.4452 53.7085 25.9301C54.2974 26.6153 54.6208 27.4884 54.6208 28.392C54.6208 29.7896 53.8491 31.0731 52.6147 31.7286L45.9396 35.2731L44.0698 32.4685L49.8128 28.3282L40.998 23.5202L43.0014 17.243V17.243Z" fill="#E6E6E6"/>
      <path d="M21.8933 35.7046L20.8301 38.8124L23.9019 40.4151L26.2391 38.8124L27.7082 35.4067L23.3676 34.1379" fill="#E6E6E6"/>
      <path d="M71.5551 5.91821L61.4652 8.65905L62.9333 15.2062C62.4586 14.8876 61.8876 14.7014 61.273 14.7014C59.625 14.7014 58.2891 16.0374 58.2891 17.6854C58.2891 19.3333 59.625 20.6693 61.273 20.6693C62.921 20.6693 64.2569 19.3333 64.2569 17.6854C64.2569 17.1126 64.0927 16.5794 63.8129 16.125L62.6489 10.9342L71.3534 8.19756L72.1494 12.0642C71.6716 11.7391 71.0948 11.5488 70.4734 11.5488C68.8254 11.5488 67.4895 12.8848 67.4895 14.5327C67.4895 16.1807 68.8254 17.5166 70.4734 17.5166C72.1214 17.5166 73.4573 16.1807 73.4573 14.5327C73.4573 13.9538 73.2898 13.4153 73.0044 12.9578L71.5551 5.91821Z" fill="#F7B80B"/>
      <path d="M81.451 12.0383C79.8201 12.0383 78.498 13.3604 78.498 14.9913C78.498 15.4523 78.6067 15.8869 78.7951 16.2757L80.4805 23.2278L81.4372 22.9959L80.137 17.6329C80.5332 17.8303 80.9783 17.9442 81.451 17.9442C83.0819 17.9442 84.4039 16.6221 84.4039 14.9913C84.4039 13.3604 83.0819 12.0383 81.451 12.0383Z" fill="#F7B80B"/>
      <path d="M93.4677 27.3607L91.7946 20.6692L90.7788 20.9079L92.0875 26.142C91.685 25.9597 91.2377 25.855 90.7642 25.855C89.0326 25.855 87.6289 27.216 87.6289 28.8949C87.6289 30.5737 89.0326 31.9347 90.7642 31.9347C92.4957 31.9347 93.8994 30.5737 93.8994 28.8949C93.8994 28.3346 93.7404 27.8115 93.4677 27.3608V27.3607Z" fill="#F7B80B"/>
      <path d="M97.8449 100.513C97.8449 100.627 97.7525 100.719 97.6385 100.719H0.154297V100.306H97.6385C97.7525 100.306 97.8449 100.399 97.8449 100.513L97.8449 100.513Z" fill="#E6E6E6"/>
    </g>
    <defs>
      <clipPath id="clip0_1017_48670">
        <rect width="97.6906" height="100" fill="white" transform="translate(0.154297 0.718994)"/>
      </clipPath>
    </defs>
  </svg>


);