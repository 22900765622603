import { BoxProps, Flex } from "@chakra-ui/react";

export default function MainContainer({ ...props }: BoxProps) {
  return (
    <Flex
      alignItems="flex-start"
      justifyContent="space-between"
      position="relative"
      gap="20px"
      width="100%"
      sx={{
        marginTop: "40px",
        "@media(max-width: 1600px)": {
          marginTop: "20px",
        },
      }}
      className="main-container"
      paddingBottom={{ md: "80px", "2xl": "120px" }}
      {...props}
    />
  );
}
