export const TestProductIcon = () => (
  <svg width="126" height="101" viewBox="0 0 126 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1017_5693)">
      <path d="M114.073 100.71L114.065 100.64C113.946 99.4682 114.083 98.284 114.468 97.1702C114.852 96.0564 115.474 95.0396 116.291 94.1906C116.754 93.718 117.273 93.3055 117.838 92.9628L117.892 92.93L117.91 92.9905C118.121 93.7131 118.403 94.4422 118.531 94.7652L118.816 92.4509L118.877 92.4242C119.159 92.3006 119.471 92.264 119.773 92.3193C120.076 92.3745 120.355 92.5191 120.574 92.7343C120.802 92.9485 120.96 93.2259 121.029 93.531C121.097 93.836 121.073 94.1545 120.959 94.4456C120.857 94.7122 120.759 94.9894 120.665 95.2574C120.339 96.1783 120.003 97.1306 119.434 97.9102C118.997 98.496 118.436 98.977 117.79 99.318C117.143 99.6589 116.429 99.8513 115.699 99.8809L114.073 100.71Z" fill="#F2F2F2"/>
      <path d="M55.5999 99.8809C54.87 99.8513 54.1559 99.6589 53.5098 99.318C52.8636 98.977 52.3019 98.496 51.8654 97.9101C51.2965 97.1306 50.96 96.1783 50.6346 95.2573C50.5398 94.9893 50.4419 94.7122 50.3403 94.4456C50.2263 94.1545 50.202 93.836 50.2706 93.5309C50.3392 93.2259 50.4976 92.9485 50.7253 92.7342C50.9448 92.519 51.2237 92.3745 51.5261 92.3193C51.8285 92.264 52.1405 92.3006 52.4219 92.4242L52.4833 92.4509L52.7679 94.7652C52.8967 94.4422 53.1779 93.7131 53.3893 92.9905L53.407 92.93L53.4609 92.9628C54.026 93.3055 54.5458 93.718 55.0079 94.1906C55.825 95.0396 56.4473 96.0563 56.8318 97.1702C57.2162 98.284 57.3535 99.4682 57.2341 100.64L57.2266 100.71L55.5999 99.8809Z" fill="#F2F2F2"/>
      <path d="M37.0752 52.4611C37.0752 52.4611 36.3195 46.3518 34.6168 44.4902L30.6094 46.0424L33.7737 54.1167L40.1767 62.1936C40.2089 62.4932 40.3199 62.7789 40.4982 63.0217C40.6766 63.2645 40.916 63.4559 41.1923 63.5762C41.4685 63.6966 41.7716 63.7418 42.0709 63.7071C42.3702 63.6725 42.655 63.5593 42.8964 63.3789C43.1378 63.1986 43.3272 62.9577 43.4454 62.6805C43.5635 62.4033 43.6062 62.0998 43.5692 61.8008C43.5321 61.5018 43.4166 61.2179 43.2343 60.978C43.0521 60.738 42.8096 60.5506 42.5315 60.4347L37.0752 52.4611Z" fill="#A0616A"/>
      <path d="M16.6494 55.8656C16.6494 55.8656 19.3905 50.3537 18.9978 47.8617L14.7993 46.9446L12.9824 55.4244L13.865 65.6935C13.7266 65.9612 13.6614 66.2606 13.6762 66.5616C13.6909 66.8625 13.785 67.1542 13.9489 67.407C14.1128 67.6598 14.3407 67.8648 14.6094 68.0011C14.8782 68.1373 15.1782 68.2001 15.479 68.1829C15.7798 68.1657 16.0707 68.0693 16.3222 67.9034C16.5737 67.7374 16.7768 67.5079 16.9109 67.2381C17.045 66.9683 17.1053 66.6678 17.0857 66.3671C17.0662 66.0664 16.9674 65.7763 16.7994 65.5262L16.6494 55.8656Z" fill="#A0616A"/>
      <path d="M21.4984 98.103H23.4847L24.4295 90.4417L21.498 90.4419L21.4984 98.103Z" fill="#9E616A"/>
      <path d="M21.4278 100.175L27.5357 100.175V100.097C27.5357 99.467 27.2852 98.8623 26.8393 98.4165C26.3935 97.9707 25.7888 97.7202 25.1583 97.7202H25.1582L24.0425 96.8738L21.9609 97.7203L21.4277 97.7203L21.4278 100.175Z" fill="#2F2E41"/>
      <path d="M26.9395 96.9791L28.8768 97.4169L31.4873 90.1523L28.6278 89.5063L26.9395 96.9791Z" fill="#9E616A"/>
      <path d="M26.4141 98.9847L32.3718 100.331L32.3888 100.255C32.5277 99.6405 32.4167 98.9955 32.0801 98.4624C31.7435 97.9292 31.2089 97.5516 30.5939 97.4126L30.5938 97.4126L29.6921 96.3411L27.4751 96.7079L26.955 96.5904L26.4141 98.9847Z" fill="#2F2E41"/>
      <path d="M35.8871 77.2251C34.7075 80.9829 31.6905 90.957 31.6905 90.957C31.6905 90.957 29.1226 91.598 28.3126 90.957C27.5025 90.3159 28.5113 88.1751 28.5113 88.1751C27.9772 85.7767 29.1789 83.3526 29.2569 81.0275C29.0949 80.0517 29.9538 79.214 30.0324 78.2833C29.4647 77.8512 26.7025 71.071 26.1392 69.1764C26.4287 74.9493 25.7294 83.605 24.9346 89.1686C24.5584 91.8021 24.0339 91.0326 21.358 91.1557C20.1649 90.8013 20.3645 88.1751 20.3645 88.1751V85.9894C19.7845 83.5418 19.7768 83.0131 19.9671 81.8166C19.8799 80.5466 18.7867 67.416 17.8167 62.9718C17.1702 59.4316 25.0928 58.6523 27.3713 57.8081C31.1733 55.9764 30.5316 58.5805 32.5338 60.9794C34.4644 65.1147 35.6016 72.4991 35.8871 77.2251Z" fill="#2F2E41"/>
      <path d="M28.2598 38.8355L26.3376 36.0879H22.9596L19.9791 38.8697L19.7285 63.5857L33.2164 65.0978L28.2598 38.8355Z" fill="#F7B80B"/>
      <path d="M26.9219 36.7339L32.2738 39.1831L33.2906 54.9648C33.2906 54.9648 34.1489 56.9919 34.2172 57.1915C34.2889 57.401 34.4317 58.4345 34.3699 58.6065C34.1801 59.1611 34.084 59.7435 34.0855 60.3298C34.1318 60.7796 34.6123 61.5825 34.7379 62.017C34.8245 62.3165 34.2842 63.3103 34.2842 63.3103C34.5059 63.644 34.6516 64.0224 34.7111 64.4186C34.7705 64.8149 34.7421 65.2193 34.628 65.6034L34.4829 66.0921L31.701 65.6947L30.5017 59.7978L30.3751 59.1754L30.2441 58.5314L30.1417 58.0281L30.0507 57.5806L29.9743 57.2051L26.9219 36.7339Z" fill="#3F3D56"/>
      <path d="M28.8965 39.9779L32.2744 39.1831C33.9042 41.0785 35.2285 44.8164 36.2727 48.2219C36.347 48.4643 36.2887 48.7991 36.4914 48.9432C36.8597 49.205 37.0794 49.5211 36.8131 50.0281C36.7113 50.2219 36.9352 50.4449 36.9945 50.6481C37.0444 50.8192 36.9766 51.104 37.1418 51.1532C37.6145 51.2939 37.6372 51.6979 37.4522 52.2101L33.4782 54.1972L33.3649 54.0142L32.9186 53.2935L30.9979 50.1916L28.8965 39.9779Z" fill="#3F3D56"/>
      <path d="M22.1638 36.8828L19.16 39.5804C19.16 39.5804 18.4571 51.0013 17.9911 55.3621C17.9713 55.5476 18.2299 55.9904 18.2101 56.1759L18.0937 57.2648L18.0061 58.0842C17.9747 58.3788 17.5937 61.3232 17.5937 61.3232L16.9977 61.7206C16.7443 62.8577 16.886 64.0473 17.3995 65.0931L17.7924 65.8933L22.1405 65.4117L22.1638 36.8828Z" fill="#3F3D56"/>
      <path d="M20.3728 41.2724L19.1601 39.5803L15.3847 41.9647C14.5516 44.2695 13.6379 48.2906 13.1333 51.7877C13.1219 51.8668 13.1753 52.0003 13.0997 52.0242C12.8278 52.1105 12.7457 52.2469 13.0375 52.4832C13.0714 52.5255 13.0965 52.5742 13.1113 52.6264C13.1261 52.6787 13.1302 52.7333 13.1235 52.7872C13.1168 52.841 13.0993 52.893 13.0721 52.9399C13.045 52.9869 13.0086 53.0279 12.9653 53.0606C12.6453 53.2128 12.3993 53.3631 12.9143 53.5101C13.2765 53.6136 12.8399 54.2472 12.8131 54.5944L17.1846 55.5879C17.584 55.4729 17.6598 55.239 17.447 54.8989C17.7763 54.7996 17.9009 54.623 17.6706 54.3122L17.7786 54.0284C18.1459 53.9718 18.1408 53.8195 17.9358 53.616L18.0848 53.2248L18.7742 51.4151L20.3728 41.2724Z" fill="#3F3D56"/>
      <path d="M24.1711 35.3134C26.6247 35.3134 28.6137 33.3244 28.6137 30.8708C28.6137 28.4172 26.6247 26.4282 24.1711 26.4282C21.7175 26.4282 19.7285 28.4172 19.7285 30.8708C19.7285 33.3244 21.7175 35.3134 24.1711 35.3134Z" fill="#A0616A"/>
      <path d="M19.6718 33.7332C19.6718 33.7332 20.6502 34.0015 21.2196 34.1509C21.1902 33.7272 21.0067 33.3305 20.9135 32.916C20.8203 32.5016 20.8405 32.0141 21.1486 31.7214C21.4566 31.4287 22.0982 31.5428 22.1478 31.9639C22.4084 31.4066 22.6696 30.8483 22.9312 30.2891C23.6774 31.8249 25.2028 30.5161 26.8661 30.9041C27.7146 31.104 28.778 31.0182 29.2263 30.2705C28.9744 30.0591 28.7621 29.8044 28.5995 29.5186C28.8581 29.5944 29.1271 29.6285 29.3964 29.6195C29.3902 29.3521 29.3199 29.09 29.1914 28.8554C29.0628 28.6208 28.8798 28.4205 28.6578 28.2714C28.4186 28.115 29.0107 27.5584 28.8019 27.3625C28.3737 26.9627 28.0863 27.2342 27.8333 26.7072C27.6702 26.3813 27.4189 26.1077 27.108 25.9176C26.7971 25.7275 26.439 25.6285 26.0746 25.6319C25.3476 25.6489 24.0448 25.6419 23.7627 26.3117C22.8638 25.7232 21.6124 25.8895 20.7336 26.5069C20.0032 27.018 19.172 28.563 18.9886 29.4352C18.8051 30.3074 19.2094 32.9731 19.6718 33.7332Z" fill="#2F2E41"/>
      <path d="M39.9149 53.3542C39.8389 53.3542 39.7637 53.37 39.6942 53.4005C39.6246 53.431 39.5621 53.4756 39.5106 53.5315C39.4591 53.5873 39.4198 53.6533 39.3951 53.7251C39.3704 53.797 39.3609 53.8731 39.3671 53.9489C40.2062 56.1177 40.0926 58.6284 39.9852 61.4691C39.9972 61.6064 40.06 61.7342 40.1613 61.8275C40.2627 61.9209 40.3952 61.973 40.533 61.9737H44.0789C44.2167 61.973 44.3492 61.9209 44.4506 61.8275C44.5519 61.7342 44.6147 61.6064 44.6267 61.4691C44.3387 58.7094 44.4941 56.1767 45.2448 53.9489C45.251 53.8731 45.2415 53.797 45.2168 53.7251C45.1921 53.6533 45.1527 53.5873 45.1013 53.5315C45.0498 53.4756 44.9873 53.431 44.9177 53.4005C44.8481 53.37 44.773 53.3542 44.697 53.3542H39.9149Z" fill="#F7B80B"/>
      <path d="M45.5288 54.3566L45.2642 53.0999C45.2402 52.9857 45.1775 52.8831 45.0868 52.8096C44.9962 52.736 44.8829 52.6958 44.7662 52.6958H43.1147C43.1082 52.6739 43.0977 52.6534 43.0836 52.6353L42.9001 52.406C42.8828 52.3843 42.8609 52.3669 42.8359 52.3549C42.811 52.3429 42.7837 52.3367 42.756 52.3367H41.7363C41.7086 52.3367 41.6813 52.3429 41.6563 52.3549C41.6314 52.3669 41.6094 52.3843 41.5921 52.406L41.4086 52.6353C41.3946 52.6534 41.384 52.6739 41.3776 52.6958H39.8458C39.729 52.6958 39.6158 52.736 39.5251 52.8096C39.4344 52.8831 39.3718 52.9857 39.3477 53.0999L39.0832 54.3566C39.0676 54.4307 39.0687 54.5073 39.0865 54.581C39.1043 54.6546 39.1383 54.7233 39.186 54.7821C39.2337 54.8409 39.294 54.8884 39.3623 54.9209C39.4307 54.9535 39.5055 54.9704 39.5812 54.9704C41.2709 55.8372 43.0843 55.8584 45.0307 54.9704C45.1065 54.9704 45.1812 54.9535 45.2496 54.9209C45.318 54.8884 45.3782 54.8409 45.4259 54.7821C45.4737 54.7233 45.5077 54.6546 45.5254 54.581C45.5432 54.5074 45.5444 54.4307 45.5288 54.3566Z" fill="#3F3D56"/>
      <path d="M90.5058 54.9919C90.4191 54.6608 90.1113 53.6499 89.7201 54.0965C89.662 54.1627 89.6161 53.9496 89.5622 53.8713C89.4689 53.7359 89.4124 53.1574 89.3136 53.0049C89.1127 52.6947 88.8633 52.7864 88.659 52.4265C88.0748 51.3974 87.5297 50.2225 87.3266 49.2295C86.7047 46.1897 87.5613 42.0329 87.5613 42.0329L87.2841 36.1118L85.2608 36.0438V35.9107C85.2608 35.6435 85.1546 35.3872 84.9656 35.1982C84.7767 35.0092 84.5204 34.9031 84.2531 34.9031H80.3996C80.1436 34.9032 79.8973 35.0008 79.7107 35.1761C79.5241 35.3514 79.4113 35.5912 79.3952 35.8466L77.8085 35.7933L75.0605 44.5239C75.0605 44.5239 76.3429 47.8194 76.7954 51.0178C76.9182 51.8804 76.9479 52.7537 76.8839 53.6227C76.8226 54.5036 76.6501 55.3733 76.3705 56.211C76.3705 56.211 88.0276 58.6523 90.3071 56.3828C90.3071 56.3828 90.6615 55.5866 90.5058 54.9919Z" fill="#3F3D56"/>
      <path d="M105.432 55.6284H102.678V11.2979C102.488 11.1568 102.295 11.0177 102.1 10.8806C102.06 10.8548 102.023 10.827 101.985 10.8011C100.812 9.98042 99.5872 9.23575 98.3188 8.5717C97.7512 7.81135 97.1297 7.09284 96.4589 6.42175C94.4814 4.44456 92.1013 2.91644 89.4804 1.94133C86.8596 0.966212 84.0594 0.566981 81.2705 0.770784C78.4815 0.974587 75.7692 1.77664 73.3179 3.1224C70.8666 4.46816 68.734 6.32606 67.0649 8.56972C66.2065 9.01878 65.3706 9.50362 64.5573 10.0242C64.4798 10.0739 64.4023 10.1236 64.3268 10.1733C64.3248 10.1752 64.3228 10.1752 64.3208 10.1772C64.18 10.2694 64.0644 10.3952 63.9846 10.5434C63.9048 10.6916 63.8632 10.8574 63.8638 11.0257V55.6284H59.9494C58.2207 55.6284 56.7284 57.0412 56.7364 58.7699C56.7377 59.1641 56.8139 59.5545 56.9609 59.9203C57.0174 60.0642 57.0859 60.2031 57.1656 60.3356C57.2671 60.5093 57.3848 60.673 57.5173 60.8244C57.8102 61.1585 58.171 61.4262 58.5757 61.6095C58.9804 61.7929 59.4196 61.8876 59.8639 61.8875H105.518C106.345 61.8864 107.139 61.5579 107.725 60.9738C108.311 60.3896 108.642 59.5973 108.645 58.7699C108.653 57.0411 107.161 55.6284 105.432 55.6284ZM91.2271 55.6284H75.1978L72.9882 25.0978H93.4367L91.2271 55.6284Z" fill="#E6E6E6"/>
      <path d="M93.8125 44.2884L100.663 38.1853L102.684 39.1866L94.4678 47.8037L93.8125 44.2884Z" fill="#FFB6B6"/>
      <path d="M86.1523 36.718L87.2839 36.1117C87.2839 36.1117 90.0718 35.874 91.2095 39.1489L94.2269 43.7063L99.1177 39.4677L101.237 40.9349L95.0732 48.2913L92.5103 48.6187L86.9675 41.4457L86.1523 36.718Z" fill="#3F3D56"/>
      <path d="M87.9965 31.4796C87.3779 35.6715 84.2997 36.1133 82.0461 33.925C79.6884 31.6357 76.0957 34.3608 76.0957 31.4796C76.0957 28.5985 77.397 23.4915 82.0461 23.4915C86.8553 23.4915 88.417 28.6294 87.9965 31.4796Z" fill="#2F2E41"/>
      <path d="M82.0275 33.9202C84.2389 33.9202 86.0315 32.1275 86.0315 29.9162C86.0315 27.7048 84.2389 25.9121 82.0275 25.9121C79.8161 25.9121 78.0234 27.7048 78.0234 29.9162C78.0234 32.1275 79.8161 33.9202 82.0275 33.9202Z" fill="#FFB6B6"/>
      <path d="M77.4004 29.5233H78.9841L79.6827 27.567L79.8224 29.5233H80.5794L80.9869 28.3821L81.0684 29.5233C82.2233 30.5672 84.3615 30.1852 86.6928 29.5233C86.6928 28.9667 86.5831 28.4155 86.3701 27.9012C86.1571 27.387 85.8449 26.9197 85.4513 26.5261C85.0577 26.1325 84.5905 25.8203 84.0762 25.6073C83.5619 25.3943 83.0108 25.2847 82.4542 25.2847H81.639C81.0824 25.2847 80.5312 25.3943 80.017 25.6073C79.5027 25.8203 79.0354 26.1325 78.6419 26.5261C78.2483 26.9197 77.9361 27.387 77.723 27.9012C77.51 28.4155 77.4004 28.9667 77.4004 29.5233Z" fill="#2F2E41"/>
      <path d="M70.0329 58.231C70.3498 58.2302 70.6596 58.1369 70.9242 57.9626C71.1888 57.7883 71.3969 57.5406 71.5228 57.2498C71.6487 56.9591 71.6871 56.6379 71.6332 56.3256C71.5793 56.0134 71.4354 55.7236 71.2193 55.4919L73.4482 49.6062L73.9045 47.639C73.6515 47.4801 73.3656 47.3808 73.0686 47.3486C72.7715 47.3164 72.471 47.3523 72.1899 47.4534C71.9087 47.5545 71.6542 47.7182 71.4457 47.9322C71.2371 48.1462 71.08 48.4048 70.9861 48.6884L68.6641 55.7068C68.5023 55.953 68.4103 56.2385 68.3979 56.5328C68.3855 56.8271 68.4531 57.1193 68.5936 57.3783C68.734 57.6373 68.942 57.8533 69.1955 58.0035C69.4489 58.1536 69.7383 58.2323 70.0329 58.231H70.0329Z" fill="#FFB6B6"/>
      <path d="M97.0203 32.3367C96.9444 32.3367 96.8692 32.3524 96.7996 32.3829C96.7301 32.4134 96.6676 32.458 96.6161 32.5139C96.5646 32.5697 96.5253 32.6357 96.5006 32.7075C96.4759 32.7794 96.4663 32.8556 96.4726 32.9313C97.0893 35.5914 97.3508 38.1189 97.0906 40.4516C97.1027 40.5888 97.1655 40.7166 97.2668 40.81C97.3681 40.9033 97.5007 40.9554 97.6384 40.9561H101.184C101.322 40.9554 101.455 40.9033 101.556 40.81C101.657 40.7166 101.72 40.5888 101.732 40.4516C101.535 37.9279 101.744 35.3889 102.35 32.9313C102.357 32.8556 102.347 32.7794 102.322 32.7075C102.298 32.6357 102.258 32.5697 102.207 32.5139C102.155 32.458 102.093 32.4134 102.023 32.3829C101.954 32.3524 101.878 32.3367 101.802 32.3367H97.0203Z" fill="#F7B80B"/>
      <path d="M102.634 33.339L102.37 32.0824C102.346 31.9681 102.283 31.8656 102.192 31.792C102.102 31.7184 101.988 31.6782 101.872 31.6782H100.22C100.214 31.6563 100.203 31.6358 100.189 31.6178L100.006 31.3884C99.9883 31.3668 99.9664 31.3493 99.9414 31.3373C99.9165 31.3253 99.8891 31.3191 99.8614 31.3191H98.8417C98.8141 31.3191 98.7867 31.3253 98.7618 31.3373C98.7368 31.3493 98.7149 31.3668 98.6976 31.3884L98.5141 31.6178C98.5 31.6358 98.4895 31.6563 98.4831 31.6782H96.9513C96.8345 31.6782 96.7213 31.7184 96.6306 31.792C96.5399 31.8656 96.4773 31.9681 96.4532 32.0824L96.1887 33.339C96.1731 33.4131 96.1742 33.4898 96.192 33.5634C96.2098 33.637 96.2437 33.7057 96.2915 33.7645C96.3392 33.8234 96.3994 33.8708 96.4678 33.9033C96.5362 33.9359 96.611 33.9528 96.6867 33.9528C98.5133 34.7143 100.332 34.8646 102.136 33.9528C102.212 33.9528 102.287 33.9359 102.355 33.9033C102.423 33.8708 102.484 33.8234 102.531 33.7645C102.579 33.7057 102.613 33.637 102.631 33.5634C102.649 33.4898 102.65 33.4131 102.634 33.339Z" fill="#3F3D56"/>
      <path d="M80.9191 8.35547C80.8682 8.35546 80.8178 8.36601 80.7712 8.38645C80.7246 8.40689 80.6827 8.43677 80.6482 8.47422C80.6137 8.51166 80.5873 8.55584 80.5708 8.60398C80.5542 8.65212 80.5478 8.70317 80.552 8.75391L80.9662 13.7933C80.9743 13.8853 81.0163 13.9709 81.0842 14.0334C81.1521 14.096 81.241 14.1309 81.3333 14.1314H83.7094C83.8018 14.1309 83.8906 14.096 83.9585 14.0334C84.0264 13.9709 84.0685 13.8853 84.0765 13.7933L84.4907 8.75391C84.4949 8.70317 84.4885 8.65212 84.4719 8.60398C84.4554 8.55584 84.429 8.51166 84.3945 8.47422C84.36 8.43677 84.3182 8.40689 84.2715 8.38645C84.2249 8.36601 84.1745 8.35546 84.1236 8.35547H80.9191Z" fill="white"/>
      <path d="M84.682 9.02714L84.5047 8.18506C84.4886 8.10848 84.4466 8.03978 84.3859 7.99047C84.3251 7.94117 84.2492 7.91426 84.171 7.91426H83.0643C83.06 7.89956 83.0529 7.88581 83.0435 7.87372L82.9205 7.72001C82.9089 7.70552 82.8942 7.69383 82.8775 7.68579C82.8608 7.67775 82.8425 7.67358 82.8239 7.67358H82.1406C82.1221 7.67358 82.1038 7.67775 82.087 7.68579C82.0703 7.69383 82.0556 7.70552 82.044 7.72001L81.9211 7.87372C81.9117 7.88581 81.9046 7.89956 81.9003 7.91426H80.8738C80.7956 7.91426 80.7197 7.94117 80.6589 7.99047C80.5981 8.03978 80.5562 8.10848 80.5401 8.18506L80.3628 9.02714C80.3523 9.0768 80.3531 9.12817 80.365 9.1775C80.3769 9.22683 80.3997 9.27288 80.4317 9.3123C80.4637 9.35171 80.504 9.38348 80.5498 9.40531C80.5957 9.42714 80.6458 9.43846 80.6965 9.43846H84.3482C84.399 9.43846 84.4491 9.42714 84.4949 9.40531C84.5407 9.38348 84.5811 9.35171 84.6131 9.3123C84.6451 9.27288 84.6679 9.22683 84.6798 9.1775C84.6917 9.12817 84.6924 9.0768 84.682 9.02714Z" fill="#CCCCCC"/>
      <path d="M82.5689 17.0125C81.272 17.0125 80.0042 16.628 78.9259 15.9075C77.8476 15.1869 77.0072 14.1629 76.5109 12.9647C76.0146 11.7665 75.8847 10.4481 76.1377 9.17613C76.3907 7.90416 77.0152 6.73578 77.9323 5.81875C78.8493 4.90171 80.0177 4.2772 81.2897 4.02419C82.5616 3.77118 83.88 3.90103 85.0782 4.39733C86.2764 4.89363 87.3005 5.73408 88.021 6.8124C88.7415 7.89072 89.1261 9.15848 89.1261 10.4554C89.1241 12.1938 88.4326 13.8605 87.2033 15.0898C85.9741 16.3191 84.3074 17.0106 82.5689 17.0125ZM82.5689 4.2956C81.3506 4.2956 80.1597 4.65686 79.1467 5.33371C78.1337 6.01055 77.3442 6.97258 76.878 8.09813C76.4118 9.22368 76.2898 10.4622 76.5275 11.6571C76.7652 12.852 77.3518 13.9495 78.2133 14.811C79.0747 15.6724 80.1723 16.2591 81.3672 16.4968C82.5621 16.7345 83.8006 16.6125 84.9261 16.1463C86.0517 15.68 87.0137 14.8905 87.6906 13.8776C88.3674 12.8646 88.7287 11.6737 88.7287 10.4554C88.7268 8.82227 88.0772 7.25659 86.9225 6.10181C85.7677 4.94703 84.202 4.29746 82.5689 4.2956Z" fill="#F2F2F2"/>
      <path d="M102.638 100.468H63.4941L63.5379 95.9197L63.5418 95.5243L63.8915 59.9324H102.44L102.489 70.2144L102.491 70.61L102.638 100.468Z" fill="#E6E6E6"/>
      <path d="M125.267 100.658L0.730045 100.719C0.667416 100.719 0.607412 100.694 0.56319 100.65C0.518969 100.605 0.494141 100.545 0.494141 100.482C0.494141 100.42 0.518969 100.36 0.56319 100.315C0.607412 100.271 0.667416 100.246 0.730045 100.246L125.267 100.185C125.329 100.185 125.39 100.21 125.434 100.254C125.478 100.298 125.503 100.359 125.503 100.421C125.503 100.484 125.478 100.544 125.434 100.589C125.39 100.633 125.329 100.658 125.267 100.658Z" fill="#CACACA"/>
      <path d="M61.5731 47.5918C61.4971 47.5918 61.422 47.6075 61.3524 47.638C61.2828 47.6685 61.2203 47.7131 61.1688 47.769C61.1173 47.8249 61.078 47.8908 61.0533 47.9627C61.0286 48.0345 61.0191 48.1107 61.0253 48.1864C61.5866 50.8319 61.8335 53.3546 61.6434 55.7067C61.6554 55.8439 61.7182 55.9718 61.8195 56.0651C61.9209 56.1584 62.0534 56.2106 62.1912 56.2113H65.7371C65.8749 56.2106 66.0074 56.1584 66.1088 56.0651C66.2101 55.9718 66.2729 55.8439 66.2849 55.7067C65.9985 53.3342 66.3014 50.801 66.903 48.1864C66.9092 48.1107 66.8997 48.0345 66.875 47.9627C66.8503 47.8908 66.811 47.8249 66.7595 47.769C66.708 47.7131 66.6455 47.6685 66.5759 47.638C66.5064 47.6075 66.4312 47.5918 66.3552 47.5918H61.5731Z" fill="#F7B80B"/>
      <path d="M67.187 48.5941L66.9224 47.3375C66.8984 47.2232 66.8357 47.1207 66.7451 47.0471C66.6544 46.9735 66.5411 46.9334 66.4244 46.9334H64.7729C64.7664 46.9114 64.7559 46.8909 64.7418 46.8729L64.5583 46.6435C64.541 46.6219 64.5191 46.6044 64.4941 46.5924C64.4692 46.5804 64.4419 46.5742 64.4142 46.5742H63.3945C63.3668 46.5742 63.3395 46.5804 63.3145 46.5924C63.2896 46.6044 63.2676 46.6219 63.2503 46.6435L63.0668 46.8729C63.0528 46.8909 63.0423 46.9114 63.0358 46.9334H61.504C61.3872 46.9334 61.274 46.9735 61.1833 47.0471C61.0926 47.1207 61.03 47.2232 61.0059 47.3375L60.7414 48.5941C60.7258 48.6682 60.7269 48.7449 60.7447 48.8185C60.7625 48.8921 60.7965 48.9609 60.8442 49.0197C60.8919 49.0785 60.9522 49.1259 61.0205 49.1585C61.0889 49.191 61.1637 49.208 61.2394 49.208C63.0145 49.7564 64.9138 49.7564 66.6889 49.208C66.7647 49.208 66.8394 49.191 66.9078 49.1585C66.9762 49.1259 67.0364 49.0785 67.0842 49.0197C67.1319 48.9609 67.1659 48.8921 67.1837 48.8185C67.2014 48.7449 67.2026 48.6682 67.187 48.5941Z" fill="#3F3D56"/>
      <path d="M82.311 76.4246H64.0898V76.822H82.311V76.4246Z" fill="#F2F2F2"/>
      <path d="M102.381 61.7205H84.1602V62.1179H102.381V61.7205Z" fill="#F2F2F2"/>
      <path d="M92.2465 89.1416H74.0254V89.539H92.2465V89.1416Z" fill="#F2F2F2"/>
      <path d="M102.492 70.6621H93.8965V70.2646H102.49L102.492 70.6621Z" fill="#F2F2F2"/>
      <path d="M65.9298 58.9387H57.334V58.5413H65.9279L65.9298 58.9387Z" fill="#F2F2F2"/>
      <path d="M72.6857 19.7944H64.0898V19.397H72.6837L72.6857 19.7944Z" fill="#F2F2F2"/>
      <path d="M101.498 11.6476H92.9023V11.2502H101.496L101.498 11.6476Z" fill="#F2F2F2"/>
      <path d="M92.756 19.7944H84.1602V19.397H92.754L92.756 19.7944Z" fill="#F2F2F2"/>
      <path d="M68.9974 95.6987V96.0961H63.5371L63.5411 95.6987H68.9974Z" fill="#F2F2F2"/>
      <path d="M101.96 40.6802C102.861 40.6802 103.591 39.9503 103.591 39.0499C103.591 38.1496 102.861 37.4197 101.96 37.4197C101.06 37.4197 100.33 38.1496 100.33 39.0499C100.33 39.9503 101.06 40.6802 101.96 40.6802Z" fill="#FFB6B6"/>
      <path d="M78.4908 36.8811L77.8088 35.7933C77.8088 35.7933 75.59 35.2447 74.6766 36.715C73.7631 38.1853 68.8477 53.9983 68.8477 53.9983L71.6295 54.9919L76.9944 42.7208L78.4908 36.8811Z" fill="#3F3D56"/>
    </g>
    <defs>
      <clipPath id="clip0_1017_5693">
        <rect width="125.01" height="100" fill="white" transform="translate(0.494141 0.718994)"/>
      </clipPath>
    </defs>
  </svg>

);