export const AttentionIcon = () => (
  <svg width="206" height="160" viewBox="0 0 206 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1055_69749)">
      <path d="M184.518 158.53L177.537 137.393C177.537 137.393 185.215 145.097 185.215 151.419L183.837 136.801C183.837 136.801 187.775 142.134 187.381 150.234C186.987 158.333 184.518 158.53 184.518 158.53Z" fill="#E6E6E6"/>
      <path d="M34.6097 139.283L27.7969 118.655C27.7969 118.655 35.2906 126.173 35.2906 132.343L33.9455 118.076C33.9455 118.076 37.7885 123.282 37.4042 131.186C37.0199 139.09 34.6097 139.283 34.6097 139.283Z" fill="#E6E6E6"/>
      <path d="M140.946 148.802C140.958 162.372 114.134 158.198 81.2945 158.227C48.4555 158.255 22.0371 162.475 22.0254 148.905C22.0137 135.335 48.828 132.361 81.667 132.332C114.506 132.304 140.934 135.232 140.946 148.802Z" fill="#E6E6E6"/>
      <path d="M206.001 158.219H-38.4102V158.84H206.001V158.219Z" fill="#3F3D56"/>
      <path d="M117.93 130.477H113.637L111.594 113.861H117.93V130.477Z" fill="#A0616A"/>
      <path d="M110.094 129.246H118.851V134.477H105.357V133.998C105.357 132.738 105.856 131.529 106.745 130.638C107.633 129.747 108.838 129.246 110.094 129.246Z" fill="#2F2E41"/>
      <path d="M149.487 155H145.194L143.15 138.385H149.487V155Z" fill="#A0616A"/>
      <path d="M141.65 153.77H150.407V159H136.914V158.522C136.914 157.261 137.413 156.053 138.301 155.161C139.19 154.27 140.394 153.77 141.65 153.77Z" fill="#2F2E41"/>
      <path d="M169.292 105.638C169.589 105.277 169.803 104.855 169.92 104.401C170.036 103.948 170.052 103.475 169.967 103.014C169.882 102.554 169.697 102.118 169.425 101.738C169.154 101.357 168.802 101.04 168.396 100.811L158.424 60.0547L152.049 62.807L163.909 102.215C163.57 102.925 163.502 103.736 163.719 104.494C163.935 105.252 164.421 105.903 165.085 106.325C165.748 106.747 166.543 106.91 167.318 106.784C168.094 106.657 168.796 106.249 169.292 105.638Z" fill="#A0616A"/>
      <path d="M161.513 23.2706C161.157 22.8973 160.724 22.6069 160.244 22.4198C159.764 22.2326 159.249 22.1533 158.736 22.1874C158.222 22.2215 157.722 22.3682 157.271 22.6172C156.82 22.8661 156.429 23.2113 156.125 23.6284L140.691 25.4062L143.039 31.3791L157.075 28.7434C157.807 29.2089 158.684 29.3888 159.54 29.249C160.395 29.1092 161.17 28.6593 161.717 27.9848C162.264 27.3102 162.545 26.4577 162.508 25.5888C162.47 24.72 162.116 23.8951 161.513 23.2706Z" fill="#A0616A"/>
      <path d="M136.08 101.412L142.815 152.127L151.197 150.395C151.197 150.395 149.964 113.461 154.155 109.008C158.346 104.556 148.765 96.7559 148.765 96.7559L136.08 101.412Z" fill="#2F2E41"/>
      <path d="M138.865 87.443L135.771 92.0993C135.771 92.0993 111.949 82.166 111.949 97.9973C111.949 113.829 110.585 124.572 110.092 125.314C109.599 126.056 115.796 127.982 118.755 127.487C118.755 127.487 122.405 101.965 122.158 100.481C122.158 100.481 145.527 111.977 149.964 110.987C154.402 109.998 155.45 106.495 156.19 104.516C156.93 102.537 155.881 86.8221 155.881 86.8221L141.089 82.293L138.865 87.443Z" fill="#2F2E41"/>
      <path d="M139.282 59.4881L138.4 58.4263C138.4 58.4263 128.523 36.1294 132.403 30.1127C136.283 24.0961 153.215 23.2113 153.744 23.5652C154.273 23.9191 152.48 28.5142 153.714 30.6377C153.714 30.6377 140.599 33.4909 139.717 33.8448C138.835 34.1987 146.513 47.2778 146.513 47.2778L145.631 55.241L139.282 59.4881Z" fill="#CCCCCC"/>
      <path d="M156.566 48.5161C156.566 48.5161 147.395 49.5778 147.042 49.5778C146.69 49.5778 146.513 47.2773 146.513 47.2773L138.4 58.4258C138.4 58.4258 134.403 90.7689 135.462 90.238C136.52 89.7071 156.037 92.756 156.213 91.3403C156.39 89.9247 156.037 82.8463 156.566 82.4924C157.095 82.1384 165.737 60.1954 165.737 60.1954C165.737 60.1954 164.855 55.5945 161.857 54.0018C158.859 52.4092 156.566 48.5161 156.566 48.5161Z" fill="#CCCCCC"/>
      <path d="M162.914 57.1875L165.736 60.1958C165.736 60.1958 169.929 94.7642 167.636 95.826C165.343 96.8877 160.83 96.7572 160.83 96.7572L156.389 80.3692L162.914 57.1875Z" fill="#CCCCCC"/>
      <path d="M147.753 46.3272C152.236 46.3272 155.87 42.6806 155.87 38.1822C155.87 33.6838 152.236 30.0371 147.753 30.0371C143.269 30.0371 139.635 33.6838 139.635 38.1822C139.635 42.6806 143.269 46.3272 147.753 46.3272Z" fill="#A0616A"/>
      <path d="M145.848 39.4518C145.738 37.7617 143.613 37.7038 141.925 37.6878C140.237 37.6718 138.219 37.7323 137.264 36.3358C136.633 35.4129 136.754 34.1317 137.275 33.1423C137.796 32.1528 138.654 31.3918 139.498 30.6597C141.676 28.7696 143.922 26.8945 146.537 25.6847C149.152 24.4749 152.217 23.9861 154.93 24.9572C158.262 26.1502 162.766 32.2912 163.155 35.8194C163.544 39.3476 162.139 42.9443 159.77 45.5809C157.401 48.2174 151.979 47.1536 148.567 48.0915C150.641 45.1458 149.274 39.7932 145.951 38.4176L145.848 39.4518Z" fill="#2F2E41"/>
      <path d="M73.4994 144.809C84.2622 144.809 92.9871 136.055 92.9871 125.256C92.9871 114.457 84.2622 105.703 73.4994 105.703C62.7367 105.703 54.0117 114.457 54.0117 125.256C54.0117 136.055 62.7367 144.809 73.4994 144.809Z" fill="#F7B80B"/>
      <path d="M60.4849 104.314C57.7841 104.314 55.141 103.529 52.8758 102.053C50.6105 100.577 48.8201 98.4744 47.7213 95.9989L16.798 26.1257C15.7737 23.8112 15.2319 21.3109 15.2061 18.7785C15.1802 16.2462 15.6708 13.7353 16.6476 11.4001C17.6245 9.06501 19.067 6.955 20.8865 5.1998C22.7059 3.44461 24.8639 2.08131 27.2279 1.19372C29.5918 0.306116 32.1116 -0.0870342 34.6325 0.0384334C37.1534 0.163901 39.6221 0.805334 41.8869 1.92331C44.1516 3.04128 46.1646 4.61217 47.802 6.53942C49.4394 8.46667 50.6667 10.7096 51.4085 13.1302L73.8007 86.207C74.4417 88.2987 74.5849 90.5121 74.2188 92.6694C73.8526 94.8268 72.9873 96.8678 71.6924 98.6287C70.3974 100.39 68.7089 101.821 66.7625 102.809C64.816 103.796 62.666 104.312 60.4849 104.314H60.4849Z" fill="#F7B80B"/>
    </g>
    <defs>
      <clipPath id="clip0_1055_69749">
        <rect width="206" height="159" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>

);