export const GamingIcon = () => (
  <svg width="150" height="101" viewBox="0 0 150 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1063_4381)">
      <path d="M146.068 97.3112L144.589 97.9413L141.453 92.5358L143.636 91.6055L146.068 97.3112Z" fill="#FFB6B6"/>
      <path d="M133.844 98.711L132.236 98.7108L131.471 92.5088H133.844L133.844 98.711Z" fill="#FFB6B6"/>
      <path d="M133.9 100.388L128.955 100.388V100.326C128.955 99.8152 129.158 99.3257 129.519 98.9648C129.88 98.6038 130.369 98.4011 130.88 98.401H130.88L131.783 97.7158L133.468 98.4011L133.9 98.4012L133.9 100.388Z" fill="#2F2E41"/>
      <path d="M146.968 98.3852L142.419 100.324L142.394 100.266C142.194 99.7966 142.189 99.2668 142.379 98.7933C142.57 98.3197 142.941 97.9413 143.41 97.7411L143.41 97.7411L143.973 96.7566L145.792 96.7263L146.189 96.5571L146.968 98.3852Z" fill="#2F2E41"/>
      <path d="M132.267 62.0045L129.823 76.8848L130.488 79.1022L129.615 80.6726L130.897 94.4646L130.377 95.6099L131.83 96.5063H134.788L134.945 84.0203L135.675 82.1871V81.1768L137.163 73.5631L137.436 82.2722L137.869 84.1083L137.506 85.4156L137.98 86.3315L142.05 95.0692H145.366L145.11 93.0932C145.704 92.7923 144.218 91.9131 144.075 91.2626L144.563 90.1638L144.075 88.0884L143.627 84.0329L144.099 75.4724L143.877 63.9447C142.763 62.4518 141.528 61.3005 139.997 60.978L132.267 62.0045Z" fill="#2F2E41"/>
      <path d="M138.218 42.4082C140.069 42.4082 141.569 40.9076 141.569 39.0566C141.569 37.2056 140.069 35.7051 138.218 35.7051C136.367 35.7051 134.866 37.2056 134.866 39.0566C134.866 40.9076 136.367 42.4082 138.218 42.4082Z" fill="#FFB6B6"/>
      <path d="M124.399 65.5204C124.358 65.3322 124.358 65.1371 124.4 64.9488C124.441 64.7606 124.524 64.5838 124.641 64.4309C124.759 64.2781 124.908 64.1528 125.08 64.064C125.251 63.9752 125.439 63.925 125.632 63.917L126.982 59.4287L128.543 61.2984L127.022 65.2739C127.014 65.5984 126.887 65.9087 126.665 66.1461C126.444 66.3835 126.143 66.5314 125.82 66.5617C125.497 66.5921 125.174 66.5029 124.912 66.311C124.65 66.119 124.468 65.8378 124.399 65.5204Z" fill="#FFB6B6"/>
      <path d="M133.158 47.0723L132.553 45.9668L131.773 46.2271C131.773 46.2271 130.912 46.7825 129.707 48.8792C129.149 49.849 127.944 56.0677 127.944 56.0677L125.128 63.5256L127.999 64.0111L130.395 57.8649L133.173 51.9831L133.158 47.0723Z" fill="#F7B80B"/>
      <path d="M138.093 61.9578C140.453 61.9578 142.834 62.4224 143.809 64.0834C143.783 63.7222 143.719 63.096 143.653 62.5214L143.409 60.9777L143.656 59.5622L143.983 59.2354L143.398 58.6502L143.797 55.6955L145.115 48.735C145.218 48.1874 145.137 47.621 144.886 47.1239C144.634 46.6268 144.225 46.2269 143.722 45.9864L142.456 45.3776L140.404 43.474L136.691 43.4463L135.48 45.0208L132.292 46.1037L132.827 57.1748L132.476 57.9933C132.555 58.0655 132.721 58.2198 132.811 58.3192C132.938 58.4597 132.776 59.1836 132.668 59.6025L132.52 60.7185L132.986 61.0517L132.38 61.768L132.259 62.6773C132.861 62.5349 135.464 61.9578 138.093 61.9578Z" fill="#F7B80B"/>
      <path d="M139.075 42.2443C139.09 42.2128 139.104 42.1812 139.118 42.1494C139.113 42.1496 139.107 42.1504 139.102 42.1505L139.075 42.2443Z" fill="#2F2E41"/>
      <path d="M141.662 35.8577C141.503 35.7076 141.296 35.6182 141.078 35.6051C140.861 35.6121 140.641 35.8078 140.686 36.0199C140.288 35.3151 139.655 34.773 138.897 34.4888C138.139 34.2046 137.305 34.1964 136.541 34.4658C136.003 34.6558 135.475 35.036 135.351 35.5936C135.339 35.74 135.295 35.8821 135.223 36.01C135.16 36.0783 135.079 36.1269 134.989 36.1497C134.899 36.1724 134.805 36.1684 134.717 36.1381L134.712 36.1365C134.682 36.1263 134.649 36.1253 134.619 36.1337C134.588 36.142 134.561 36.1594 134.54 36.1834C134.52 36.2075 134.507 36.2372 134.503 36.2687C134.5 36.3002 134.506 36.332 134.52 36.3601L134.673 36.6443C134.481 36.6872 134.281 36.6829 134.091 36.632C134.059 36.6237 134.026 36.6254 133.995 36.6368C133.965 36.6482 133.939 36.6689 133.92 36.6958C133.902 36.7228 133.892 36.7548 133.893 36.7874C133.893 36.8201 133.904 36.8518 133.923 36.8782C134.176 37.2247 134.507 37.5073 134.889 37.7036C135.27 37.8998 135.693 38.0042 136.122 38.0085C136.731 38.0048 137.346 37.7949 137.939 37.9349C138.168 37.9891 138.382 38.0939 138.566 38.2417C138.749 38.3895 138.897 38.5764 138.999 38.7889C139.101 39.0014 139.154 39.2339 139.154 39.4695C139.154 39.7051 139.102 39.9377 139 40.1504C139.182 39.9512 139.534 39.9984 139.72 40.1936C139.806 40.2982 139.87 40.4192 139.907 40.5492C139.944 40.6792 139.954 40.8155 139.936 40.9495C139.886 41.3601 139.765 41.7586 139.576 42.1267C140.296 42.1045 140.992 41.8619 141.57 41.4316C142.148 41.0012 142.58 40.4039 142.807 39.7203C143.035 39.0367 143.047 38.2998 142.842 37.609C142.637 36.9182 142.226 36.307 141.662 35.8577H141.662Z" fill="#2F2E41"/>
      <path d="M33.3443 23.3444C37.9463 23.3444 41.6769 19.6137 41.6769 15.0118C41.6769 10.4098 37.9463 6.6792 33.3443 6.6792C28.7423 6.6792 25.0117 10.4098 25.0117 15.0118C25.0117 19.6137 28.7423 23.3444 33.3443 23.3444Z" fill="#F7B80B"/>
      <path d="M9.92553 39.7989C5.74453 30.5508 5.89869 18.8284 12.3875 11.0244C14.5583 21.8459 20.4869 31.5506 29.1243 38.4217C32.465 41.0595 36.4553 43.6456 37.3679 47.8032C37.9358 50.3901 37.1309 53.1264 35.7393 55.3798C34.3477 57.6333 32.4079 59.487 30.4902 61.3139L30.2751 61.9391C21.9306 56.1618 14.1065 49.047 9.92553 39.7989Z" fill="#F2F2F2"/>
      <path d="M12.5677 11.1372C11.0744 19.3142 12.1838 28.0214 15.8135 35.5131C16.5982 37.1328 17.5352 38.7076 18.7897 40.0103C20.0448 41.3135 21.5454 42.1339 23.2717 42.638C24.8498 43.0988 26.5175 43.3873 27.9562 44.2237C29.4726 45.1053 30.4137 46.5845 30.7803 48.2809C31.2289 50.3564 30.9049 52.4694 30.5163 54.5246C30.0848 56.8066 29.6005 59.17 30.2257 61.464C30.3015 61.742 29.8658 61.8433 29.7901 61.5657C28.7023 57.5746 30.8173 53.6382 30.5217 49.6256C30.3838 47.7532 29.6807 45.911 28.0753 44.8262C26.6714 43.8775 24.9519 43.5785 23.35 43.1258C21.6679 42.6504 20.1573 41.9242 18.8718 40.7197C17.5569 39.4876 16.5564 37.9461 15.7337 36.3536C13.8783 32.762 12.6552 28.805 12.0081 24.821C11.264 20.2419 11.3087 15.5692 12.1403 11.0051C12.1918 10.7227 12.619 10.8565 12.5677 11.1372H12.5677Z" fill="white"/>
      <path d="M14.5054 33.14C13.1508 33.5741 11.6861 33.5126 10.3728 32.9665C9.0594 32.4203 7.98294 31.4251 7.33562 30.1586C7.20552 29.9018 7.59834 29.6876 7.72862 29.9447C8.32923 31.1252 9.33204 32.0521 10.5561 32.5582C11.7801 33.0643 13.1447 33.1161 14.4036 32.7044C14.6773 32.6153 14.7775 33.0514 14.5054 33.14H14.5054Z" fill="white"/>
      <path d="M22.4404 42.5098C23.7939 39.9364 24.1278 36.9474 23.3754 34.1388C23.3006 33.8605 23.7363 33.7592 23.811 34.037C24.5885 36.9612 24.2348 40.071 22.8204 42.7459C22.6854 43.0007 22.3062 42.7633 22.4404 42.5098H22.4404Z" fill="white"/>
      <path d="M11.8386 20.3873C12.3977 20.3285 12.9334 20.1318 13.3977 19.8148C13.862 19.4978 14.2403 19.0705 14.4986 18.5712C14.6303 18.3148 15.0094 18.5525 14.8786 18.8073C14.5906 19.3576 14.1722 19.8291 13.66 20.1804C13.1479 20.5318 12.5574 20.7523 11.9403 20.8229C11.8827 20.8344 11.8229 20.8235 11.773 20.7925C11.7231 20.7615 11.6868 20.7127 11.6717 20.6559C11.6585 20.5982 11.6686 20.5376 11.6999 20.4873C11.7311 20.437 11.781 20.401 11.8386 20.3873Z" fill="white"/>
      <path d="M46.7506 11.1147C46.6729 11.2772 46.5953 11.4397 46.5194 11.6055C45.4864 13.7951 44.633 16.0651 43.9677 18.3929C43.9134 18.5721 43.8611 18.7545 43.812 18.9348C42.2688 24.5602 41.7834 30.4233 42.3805 36.2259C42.6202 38.5347 43.0352 40.8218 43.6219 43.0677C44.4324 46.1692 45.5579 49.4675 45.304 52.5645C45.2811 52.8879 45.2382 53.2095 45.1756 53.5276L31.0543 62.1528C31.0124 62.1563 30.9722 62.1631 30.9302 62.1667L30.3821 62.5369C30.3437 62.4232 30.3064 62.3044 30.2681 62.1907C30.2455 62.1248 30.2262 62.057 30.2036 61.9912C30.1896 61.9466 30.1758 61.902 30.1606 61.8626C30.1558 61.8478 30.1513 61.8329 30.1485 61.8213C30.1333 61.782 30.1234 61.7438 30.1102 61.7076C29.8997 61.0443 29.6934 60.3784 29.4915 59.7099C29.4896 59.7067 29.4896 59.7067 29.4907 59.7015C27.9653 54.6037 26.852 49.3336 26.574 44.0622C26.5657 43.9036 26.5553 43.7419 26.5534 43.5793C26.4413 41.2025 26.5236 38.8205 26.7995 36.4571C26.9548 35.1634 27.1755 33.8785 27.4607 32.6072C28.2039 29.3237 29.4589 26.1775 31.1798 23.284C34.6113 17.5163 39.8491 12.9103 46.2596 11.2377C46.4236 11.1951 46.5846 11.1542 46.7506 11.1147Z" fill="#F2F2F2"/>
      <path d="M46.8281 11.3133C40.7126 16.9432 36.3561 24.5632 34.7437 32.7303C34.395 34.496 34.1951 36.3175 34.4124 38.1129C34.6299 39.9091 35.3341 41.4677 36.409 42.9095C37.3916 44.2275 38.5494 45.462 39.1946 46.996C39.8746 48.6129 39.7354 50.3606 39.0068 51.9357C38.1153 53.863 36.5845 55.355 35.0368 56.762C33.3184 58.3243 31.5087 59.9197 30.6268 62.1278C30.52 62.3954 30.1111 62.2139 30.2178 61.9468C31.7522 58.1051 35.8109 56.2356 37.9907 52.8537C39.0079 51.2756 39.5556 49.3815 38.9269 47.5487C38.3772 45.946 37.1843 44.672 36.1778 43.3461C35.121 41.9538 34.352 40.4645 34.0509 38.7288C33.7428 36.9533 33.872 35.1202 34.1739 33.3533C34.8549 29.3686 36.2607 25.4728 38.1427 21.9021C40.3055 17.798 43.1545 14.094 46.5663 10.9505C46.7776 10.756 47.038 11.1201 46.8281 11.3133L46.8281 11.3133Z" fill="white"/>
      <path d="M35.1281 30.0482C33.7853 29.5793 32.6528 28.6483 31.933 27.4215C31.2132 26.1947 30.9529 24.752 31.1986 23.351C31.2492 23.0677 31.6919 23.1331 31.6411 23.4168C31.4099 24.721 31.6525 26.0649 32.3252 27.2059C32.9978 28.3469 34.0561 29.2099 35.3092 29.6392C35.5814 29.7327 35.3988 30.1412 35.1281 30.0482H35.1281Z" fill="white"/>
      <path d="M35.8227 42.3069C38.4528 41.067 40.5189 38.8815 41.6091 36.1859C41.7169 35.9188 42.1259 36.1002 42.0182 36.367C40.8784 39.1699 38.7237 41.4399 35.9839 42.7241C35.7227 42.8463 35.5629 42.4284 35.8227 42.3068L35.8227 42.3069Z" fill="white"/>
      <path d="M40.6772 18.2605C41.159 18.5502 41.7051 18.7157 42.2667 18.7421C42.8282 18.7686 43.3876 18.6551 43.8944 18.412C44.154 18.2865 44.3135 18.7046 44.0557 18.8292C43.4944 19.0953 42.8765 19.2198 42.256 19.192C41.6355 19.1641 41.0313 18.9847 40.4961 18.6695C40.4432 18.644 40.4019 18.5994 40.3808 18.5445C40.3596 18.4897 40.3601 18.4289 40.3821 18.3745C40.4064 18.3204 40.451 18.2782 40.5062 18.2568C40.5615 18.2355 40.6229 18.2368 40.6772 18.2605Z" fill="white"/>
      <path d="M80.1804 29.669C75.9994 20.4209 76.1536 8.69847 82.6423 0.894531C84.8132 11.716 90.7418 21.4208 99.3792 28.2918C102.72 30.9297 106.71 33.5157 107.623 37.6733C108.191 40.2602 107.386 42.9965 105.994 45.2499C104.603 47.5034 102.663 49.3572 100.745 51.184L100.53 51.8092C92.1855 46.0319 84.3614 38.9171 80.1804 29.669Z" fill="#F2F2F2"/>
      <path d="M82.8226 1.00727C81.3293 9.18437 82.4387 17.8915 86.0683 25.3832C86.8531 27.0029 87.7901 28.5777 89.0446 29.8804C90.2997 31.1837 91.8003 32.0041 93.5266 32.5081C95.1047 32.9689 96.7724 33.2574 98.2111 34.0938C99.7275 34.9754 100.669 36.4547 101.035 38.151C101.484 40.2266 101.16 42.3395 100.771 44.3947C100.34 46.6767 99.8554 49.0401 100.481 51.3341C100.556 51.6121 100.121 51.7134 100.045 51.4358C98.9572 47.4447 101.072 43.5084 100.777 39.4957C100.639 37.6233 99.9356 35.7812 98.3302 34.6963C96.9263 33.7477 95.2068 33.4486 93.6049 32.9959C91.9228 32.5205 90.4121 31.7944 89.1267 30.5898C87.8118 29.3577 86.8112 27.8162 85.9886 26.2237C84.1332 22.6322 82.9101 18.6751 82.263 14.6911C81.5189 10.112 81.5636 5.4393 82.3951 0.875257C82.4467 0.592792 82.8739 0.726659 82.8226 1.00727H82.8226Z" fill="white"/>
      <path d="M84.7602 23.0101C83.4057 23.4442 81.941 23.3827 80.6276 22.8366C79.3143 22.2904 78.2378 21.2952 77.5905 20.0287C77.4604 19.7719 77.8532 19.5577 77.9835 19.8148C78.5841 20.9953 79.5869 21.9223 80.811 22.4283C82.035 22.9344 83.3996 22.9862 84.6585 22.5745C84.9322 22.4854 85.0324 22.9215 84.7602 23.0101Z" fill="white"/>
      <path d="M92.6963 32.3799C94.0498 29.8065 94.3836 26.8175 93.6312 24.0089C93.5565 23.7307 93.9922 23.6293 94.0668 23.9072C94.8444 26.8313 94.4906 29.9411 93.0762 32.616C92.9413 32.8708 92.562 32.6334 92.6963 32.3799H92.6963Z" fill="white"/>
      <path d="M82.0945 10.2574C82.6536 10.1986 83.1893 10.0019 83.6536 9.68495C84.1179 9.36797 84.4962 8.94063 84.7545 8.44132C84.8862 8.18488 85.2652 8.42263 85.1344 8.67741C84.8464 9.22774 84.4281 9.69918 83.9159 10.0505C83.4037 10.4019 82.8133 10.6225 82.1962 10.693C82.1386 10.7045 82.0787 10.6937 82.0288 10.6627C81.9789 10.6317 81.9427 10.5828 81.9275 10.526C81.9143 10.4683 81.9245 10.4077 81.9557 10.3574C81.987 10.3071 82.0368 10.2711 82.0945 10.2574V10.2574Z" fill="white"/>
      <path d="M117.005 0.984863C116.928 1.14733 116.85 1.3098 116.774 1.47558C115.741 3.66524 114.888 5.93519 114.223 8.26305C114.168 8.4422 114.116 8.62459 114.067 8.80494C112.524 14.4304 112.038 20.2934 112.635 26.096C112.875 28.4048 113.29 30.692 113.877 32.9378C114.687 36.0393 115.813 39.3376 115.559 42.4346C115.536 42.758 115.493 43.0797 115.43 43.3978L101.309 52.023C101.267 52.0265 101.227 52.0333 101.185 52.0368L100.637 52.407C100.599 52.2933 100.561 52.1745 100.523 52.0608C100.5 51.9949 100.481 51.9272 100.459 51.8613C100.445 51.8168 100.431 51.7721 100.415 51.7328C100.411 51.718 100.406 51.7031 100.403 51.6915C100.388 51.6521 100.378 51.6139 100.365 51.5777C100.155 50.9145 99.9483 50.2486 99.7464 49.58C99.7445 49.5768 99.7445 49.5768 99.7456 49.5717C98.2202 44.4738 97.1069 39.2038 96.8289 33.9323C96.8206 33.7737 96.8101 33.612 96.8083 33.4494C96.6962 31.0726 96.7785 28.6906 97.0544 26.3272C97.2097 25.0336 97.4303 23.7486 97.7155 22.4773C98.4588 19.1938 99.7138 16.0476 101.435 13.1541C104.866 7.38647 110.104 2.78046 116.514 1.10785C116.678 1.06518 116.84 1.02431 117.005 0.984863Z" fill="#F0F0F0"/>
      <path d="M117.083 1.1834C110.968 6.81328 106.611 14.4333 104.999 22.6004C104.65 24.3661 104.45 26.1876 104.667 27.983C104.885 29.7793 105.589 31.3378 106.664 32.7796C107.647 34.0976 108.804 35.3321 109.449 36.8661C110.129 38.483 109.99 40.2307 109.262 41.8058C108.37 43.7331 106.839 45.2251 105.292 46.6321C103.573 48.1944 101.764 49.7899 100.882 51.9979C100.775 52.2655 100.366 52.084 100.473 51.8169C102.007 47.9752 106.066 46.1057 108.246 42.7238C109.263 41.1457 109.81 39.2516 109.182 37.4188C108.632 35.8162 107.439 34.5422 106.433 33.2162C105.376 31.8239 104.607 30.3346 104.306 28.5989C103.998 26.8235 104.127 24.9903 104.429 23.2234C105.11 19.2387 106.516 15.3429 108.398 11.7722C110.56 7.66807 113.409 3.96411 116.821 0.820634C117.032 0.62616 117.293 0.990206 117.083 1.18341L117.083 1.1834Z" fill="white"/>
      <path d="M105.383 19.9183C104.04 19.4494 102.908 18.5184 102.188 17.2916C101.468 16.0648 101.208 14.6221 101.453 13.2211C101.504 12.9378 101.947 13.0033 101.896 13.2869C101.665 14.5911 101.907 15.935 102.58 17.076C103.253 18.217 104.311 19.08 105.564 19.5093C105.836 19.6029 105.654 20.0114 105.383 19.9183H105.383Z" fill="white"/>
      <path d="M106.078 32.177C108.708 30.9372 110.774 28.7516 111.864 26.0561C111.972 25.7889 112.381 25.9703 112.273 26.2371C111.133 29.04 108.979 31.3101 106.239 32.5942C105.978 32.7164 105.818 32.2985 106.078 32.177L106.078 32.177Z" fill="white"/>
      <path d="M110.932 8.1306C111.414 8.4203 111.96 8.5858 112.522 8.61224C113.083 8.63868 113.642 8.52523 114.149 8.28208C114.409 8.15661 114.568 8.57468 114.311 8.69934C113.749 8.96537 113.131 9.08991 112.511 9.06207C111.89 9.03423 111.286 8.85486 110.751 8.53964C110.698 8.51415 110.657 8.46947 110.636 8.41467C110.614 8.35986 110.615 8.29905 110.637 8.2446C110.661 8.19056 110.706 8.14827 110.761 8.12692C110.816 8.10558 110.878 8.1069 110.932 8.1306Z" fill="white"/>
      <path d="M26.8906 98.2561L28.4341 99.4514L34.435 100.493L41.105 83.9124C41.105 83.9124 45.1896 71.1344 56.6265 73.7485L93.3879 73.095C93.3879 73.095 104.675 71.9382 106.54 77.2613C106.894 78.2695 116.425 100.543 116.425 100.543L123.108 99.6003L122.307 80.6106L106.435 43.7675L78.8467 42.0449L50.4179 44.0126L26.8906 98.2561Z" fill="#3F3D56"/>
      <path d="M57.4442 74.8918C63.3094 74.8918 68.0641 70.1371 68.0641 64.2718C68.0641 58.4066 63.3094 53.6519 57.4442 53.6519C51.5789 53.6519 46.8242 58.4066 46.8242 64.2718C46.8242 70.1371 51.5789 74.8918 57.4442 74.8918Z" fill="#2F2E41"/>
      <path d="M93.0614 74.8918C98.9266 74.8918 103.681 70.1371 103.681 64.2718C103.681 58.4066 98.9266 53.6519 93.0614 53.6519C87.1961 53.6519 82.4414 58.4066 82.4414 64.2718C82.4414 70.1371 87.1961 74.8918 93.0614 74.8918Z" fill="#2F2E41"/>
      <path d="M45.0264 29.4714L49.3991 28.614C49.3991 28.614 82.3217 23.9164 103.131 29.4714L101.557 33.8828L46.4968 33.556L45.0264 29.4714Z" fill="#E6E6E6"/>
      <path d="M89.4166 52.2633H61.4765C58.0841 52.2633 55.1521 49.9142 54.5049 46.6777L51.4472 31.3844C51.3796 31.0506 51.3823 30.7064 51.4552 30.3738C51.528 30.0411 51.6695 29.7274 51.8704 29.4525C52.0807 29.1641 52.348 28.9219 52.6557 28.7412C52.9634 28.5604 53.305 28.4448 53.6593 28.4016C68.3953 26.5724 82.987 26.5693 97.0296 28.3921C97.3789 28.4348 97.7157 28.549 98.019 28.7276C98.3223 28.9061 98.5856 29.1451 98.7925 29.4298C98.9922 29.7007 99.134 30.0099 99.2091 30.3379C99.2842 30.666 99.291 31.0061 99.229 31.3369L96.4064 46.5834C95.7971 49.8745 92.8575 52.2633 89.4166 52.2633Z" fill="#F7B80B"/>
      <path d="M32.5977 31.961L32.7193 29.7725C32.7383 29.4315 32.8396 29.1002 33.0145 28.807C33.1895 28.5137 33.4329 28.2672 33.7239 28.0886C35.4881 27.0063 40.2468 24.6236 46.3196 25.8726C46.746 25.9609 47.135 26.1783 47.4335 26.4954C47.732 26.8124 47.9257 27.2137 47.9881 27.6447L48.1507 28.7835L32.5977 31.961Z" fill="#3F3D56"/>
      <path d="M118.235 31.9612L102.682 28.7837L102.943 26.9542C102.973 26.7382 103.067 26.5361 103.213 26.374C103.359 26.2118 103.55 26.097 103.762 26.0443C110.765 24.2867 116.126 27.4079 117.602 28.4064C117.742 28.5015 117.859 28.6276 117.942 28.7748C118.026 28.9221 118.074 29.0865 118.084 29.2556L118.235 31.9612Z" fill="#3F3D56"/>
      <path d="M27.6597 99.6832C27.2849 99.6872 26.9168 99.5836 26.599 99.3848C22.4346 96.953 19.8383 92.4967 18.8883 86.1432C18.1035 80.8951 18.4312 74.3079 19.8622 66.5644C22.3994 52.8358 27.7171 39.1132 29.8856 33.8507C30.1728 33.156 30.6192 32.5386 31.1888 32.0481C31.7584 31.5576 32.4353 31.2077 33.1648 31.0267C37.6353 29.9271 45.4393 28.0825 47.4774 28.0825C50.2704 28.0825 51.7816 30.7117 51.7966 30.7383L51.805 30.76L54.2578 41.3964V41.5499C55.611 46.1283 54.6056 49.4356 53.5231 51.4041C52.3482 53.5407 50.828 54.6074 50.8128 54.6179C48.4256 56.4352 44.5364 62.2898 40.6658 69.8948C36.6435 77.798 33.6617 85.5962 32.6897 90.7554C31.5 97.0701 29.756 99.0033 28.5024 99.5129C28.2353 99.624 27.949 99.6818 27.6597 99.6832Z" fill="#E6E6E6"/>
      <path d="M88.4951 50.9562H62.0955C60.5373 50.954 59.0283 50.411 57.826 49.4198C56.6238 48.4286 55.803 47.0508 55.5038 45.5217L52.6147 30.6478C52.5505 30.3186 52.5545 29.9797 52.6264 29.6521C52.6984 29.3244 52.8367 29.015 53.0329 28.743C53.2292 28.4709 53.4791 28.242 53.7672 28.0703C54.0554 27.8986 54.3757 27.7878 54.7083 27.7447C68.6326 25.9658 82.4205 25.9627 95.6891 27.7355C96.0171 27.7801 96.3327 27.8907 96.6168 28.0606C96.9009 28.2305 97.1476 28.4562 97.3421 28.724C97.5367 28.9918 97.6749 29.2963 97.7486 29.619C97.8223 29.9417 97.8299 30.276 97.7709 30.6017L95.1039 45.4299C94.8262 46.9803 94.0119 48.3835 92.8036 49.3939C91.5953 50.4042 90.0701 50.9573 88.4951 50.9562Z" fill="#E6E6E6"/>
      <path d="M123.173 99.6833C122.883 99.682 122.597 99.6241 122.329 99.5129C121.076 99.0033 119.332 97.0701 118.142 90.7554C117.17 85.5962 114.188 77.798 110.166 69.8948C106.295 62.2898 102.406 56.4352 100.016 54.6157C100.004 54.6074 98.4835 53.5407 97.3087 51.4041C96.2262 49.4356 95.2207 46.1283 96.5739 41.5499L96.576 41.378L99.0294 30.7485L99.0351 30.7383C99.0501 30.7117 100.561 28.0825 103.354 28.0825C105.392 28.0825 113.196 29.9271 117.667 31.0267C118.396 31.2078 119.073 31.5577 119.643 32.0482C120.212 32.5387 120.659 33.1561 120.946 33.8507C123.115 39.1133 128.432 52.8357 130.969 66.5644C132.401 74.3079 132.728 80.8951 131.944 86.1432C130.993 92.4967 128.397 96.953 124.227 99.3885C123.911 99.5853 123.545 99.6876 123.173 99.6833Z" fill="#E6E6E6"/>
      <path d="M57.4442 68.847C60.9634 68.847 63.8162 65.9941 63.8162 62.475C63.8162 58.9559 60.9634 56.103 57.4442 56.103C53.9251 56.103 51.0723 58.9559 51.0723 62.475C51.0723 65.9941 53.9251 68.847 57.4442 68.847Z" fill="#3F3D56"/>
      <path d="M57.4443 66.723C59.7904 66.723 61.6923 64.8211 61.6923 62.475C61.6923 60.1289 59.7904 58.2271 57.4443 58.2271C55.0982 58.2271 53.1963 60.1289 53.1963 62.475C53.1963 64.8211 55.0982 66.723 57.4443 66.723Z" fill="#2F2E41"/>
      <path d="M93.0614 68.847C96.5806 68.847 99.4334 65.9941 99.4334 62.475C99.4334 58.9559 96.5806 56.103 93.0614 56.103C89.5423 56.103 86.6895 58.9559 86.6895 62.475C86.6895 65.9941 89.5423 68.847 93.0614 68.847Z" fill="#3F3D56"/>
      <path d="M93.0615 66.723C95.4076 66.723 97.3094 64.8211 97.3094 62.475C97.3094 60.1289 95.4076 58.2271 93.0615 58.2271C90.7154 58.2271 88.8135 60.1289 88.8135 62.475C88.8135 64.8211 90.7154 66.723 93.0615 66.723Z" fill="#2F2E41"/>
      <path d="M71.3319 54.7957C71.6027 54.7957 71.8221 54.5763 71.8221 54.3056C71.8221 54.0349 71.6027 53.8154 71.3319 53.8154C71.0612 53.8154 70.8418 54.0349 70.8418 54.3056C70.8418 54.5763 71.0612 54.7957 71.3319 54.7957Z" fill="#2F2E41"/>
      <path d="M73.456 54.7957C73.7267 54.7957 73.9461 54.5763 73.9461 54.3056C73.9461 54.0349 73.7267 53.8154 73.456 53.8154C73.1853 53.8154 72.9658 54.0349 72.9658 54.3056C72.9658 54.5763 73.1853 54.7957 73.456 54.7957Z" fill="#2F2E41"/>
      <path d="M72.4755 56.5931C72.7462 56.5931 72.9657 56.3736 72.9657 56.1029C72.9657 55.8322 72.7462 55.6128 72.4755 55.6128C72.2048 55.6128 71.9854 55.8322 71.9854 56.1029C71.9854 56.3736 72.2048 56.5931 72.4755 56.5931Z" fill="#2F2E41"/>
      <path d="M74.4355 56.5931C74.7062 56.5931 74.9256 56.3736 74.9256 56.1029C74.9256 55.8322 74.7062 55.6128 74.4355 55.6128C74.1648 55.6128 73.9453 55.8322 73.9453 56.1029C73.9453 56.3736 74.1648 56.5931 74.4355 56.5931Z" fill="#2F2E41"/>
      <path d="M76.3964 56.5931C76.6671 56.5931 76.8866 56.3736 76.8866 56.1029C76.8866 55.8322 76.6671 55.6128 76.3964 55.6128C76.1257 55.6128 75.9062 55.8322 75.9062 56.1029C75.9062 56.3736 76.1257 56.5931 76.3964 56.5931Z" fill="#2F2E41"/>
      <path d="M78.3573 56.5931C78.628 56.5931 78.8475 56.3736 78.8475 56.1029C78.8475 55.8322 78.628 55.6128 78.3573 55.6128C78.0866 55.6128 77.8672 55.8322 77.8672 56.1029C77.8672 56.3736 78.0866 56.5931 78.3573 56.5931Z" fill="#2F2E41"/>
      <path d="M75.58 54.7957C75.8507 54.7957 76.0701 54.5763 76.0701 54.3056C76.0701 54.0349 75.8507 53.8154 75.58 53.8154C75.3093 53.8154 75.0898 54.0349 75.0898 54.3056C75.0898 54.5763 75.3093 54.7957 75.58 54.7957Z" fill="#2F2E41"/>
      <path d="M77.703 54.7957C77.9737 54.7957 78.1932 54.5763 78.1932 54.3056C78.1932 54.0349 77.9737 53.8154 77.703 53.8154C77.4323 53.8154 77.2129 54.0349 77.2129 54.3056C77.2129 54.5763 77.4323 54.7957 77.703 54.7957Z" fill="#2F2E41"/>
      <path d="M79.8271 54.7957C80.0978 54.7957 80.3172 54.5763 80.3172 54.3056C80.3172 54.0349 80.0978 53.8154 79.8271 53.8154C79.5564 53.8154 79.3369 54.0349 79.3369 54.3056C79.3369 54.5763 79.5564 54.7957 79.8271 54.7957Z" fill="#2F2E41"/>
      <path d="M77.5399 69.4187H73.2919C73.0969 69.4187 72.9099 69.3412 72.772 69.2033C72.6341 69.0655 72.5566 68.8785 72.5566 68.6835C72.5566 68.4885 72.6341 68.3015 72.772 68.1636C72.9099 68.0257 73.0969 67.9482 73.2919 67.9482H77.5399C77.7348 67.9482 77.9219 68.0257 78.0597 68.1636C78.1976 68.3015 78.2751 68.4885 78.2751 68.6835C78.2751 68.8785 78.1976 69.0655 78.0597 69.2033C77.9219 69.3412 77.7348 69.4187 77.5399 69.4187Z" fill="#2F2E41"/>
      <path d="M49.4121 37.4585C49.138 37.4577 48.8721 37.3646 48.6573 37.1942C48.4426 37.0238 48.2915 36.7859 48.2286 36.5191L47.5771 33.7533C47.5034 33.4389 47.5575 33.1081 47.7275 32.8336C47.8976 32.5591 48.1696 32.3633 48.4839 32.2893C48.7982 32.2153 49.1291 32.269 49.4038 32.4388C49.6785 32.6085 49.8745 32.8804 49.9489 33.1946L50.6003 35.9604C50.6422 36.1395 50.6431 36.3257 50.603 36.5051C50.5628 36.6845 50.4826 36.8526 50.3683 36.9966C50.254 37.1407 50.1087 37.2571 49.9431 37.337C49.7775 37.417 49.596 37.4585 49.4121 37.4585Z" fill="#3F3D56"/>
      <path d="M101.583 37.2949C101.399 37.295 101.217 37.2534 101.052 37.1735C100.886 37.0935 100.741 36.9771 100.626 36.8331C100.512 36.689 100.432 36.521 100.392 36.3415C100.352 36.1621 100.353 35.9759 100.394 35.7968L101.046 33.0311C101.12 32.7168 101.316 32.445 101.591 32.2752C101.866 32.1055 102.197 32.0517 102.511 32.1257C102.825 32.1998 103.097 32.3955 103.267 32.6701C103.437 32.9446 103.491 33.2753 103.418 33.5897L102.766 36.3555C102.703 36.6224 102.552 36.8602 102.337 37.0306C102.123 37.201 101.857 37.2942 101.583 37.2949Z" fill="#3F3D56"/>
      <path d="M40.4239 45.6222C40.023 45.6223 39.6317 45.4988 39.3034 45.2687L37.936 44.3114C37.6787 44.1312 37.4686 43.8916 37.3235 43.613C37.1785 43.3343 37.1027 43.0248 37.1025 42.7107V39.779C37.1032 39.1903 37.3374 38.626 37.7536 38.2097C38.1699 37.7934 38.7343 37.5593 39.323 37.5586H41.4175C42.0061 37.5593 42.5705 37.7934 42.9868 38.2097C43.4031 38.626 43.6372 39.1903 43.6379 39.779V42.7395C43.6373 43.0455 43.5651 43.3471 43.4271 43.6203C43.2891 43.8934 43.089 44.1304 42.843 44.3124L41.5829 45.2409C41.2473 45.4886 40.8411 45.6223 40.4239 45.6222Z" fill="#3F3D56"/>
      <path d="M41.4175 56.3477H39.323C38.7343 56.3471 38.1699 56.1129 37.7536 55.6967C37.3374 55.2804 37.1032 54.716 37.1025 54.1273V51.1956C37.1027 50.8815 37.1785 50.572 37.3235 50.2934C37.4686 50.0147 37.6787 49.7752 37.936 49.5949L39.3034 48.6377C39.6384 48.4029 40.0386 48.2793 40.4476 48.2843C40.8566 48.2893 41.2538 48.4226 41.5829 48.6654L42.843 49.594C43.089 49.7759 43.2891 50.013 43.4271 50.2861C43.5651 50.5592 43.6373 50.8608 43.6379 51.1668V54.1273C43.6372 54.716 43.4031 55.2804 42.9868 55.6967C42.5705 56.1129 42.0061 56.3471 41.4175 56.3477Z" fill="#3F3D56"/>
      <path d="M47.9525 50.2209H45.0209C44.7067 50.2208 44.3972 50.145 44.1186 49.9999C43.84 49.8548 43.6004 49.6448 43.4201 49.3875L42.4629 48.02C42.2281 47.685 42.1045 47.2848 42.1095 46.8758C42.1145 46.4668 42.2478 46.0697 42.4907 45.7405L43.4192 44.4804C43.6012 44.2344 43.8382 44.0344 44.1113 43.8963C44.3844 43.7583 44.6861 43.6861 44.9921 43.6855H47.9525C48.5412 43.6862 49.1056 43.9204 49.5219 44.3366C49.9381 44.7529 50.1723 45.3173 50.173 45.906V48.0005C50.1723 48.5892 49.9381 49.1535 49.5219 49.5698C49.1056 49.9861 48.5412 50.2202 47.9525 50.2209Z" fill="#3F3D56"/>
      <path d="M35.5564 50.2209H32.6247C32.036 50.2202 31.4717 49.9861 31.0554 49.5698C30.6391 49.1535 30.405 48.5892 30.4043 48.0005V45.906C30.405 45.3173 30.6391 44.7529 31.0554 44.3366C31.4717 43.9204 32.036 43.6862 32.6247 43.6855H35.5852C35.8912 43.6861 36.1929 43.7583 36.466 43.8963C36.7391 44.0344 36.9761 44.2344 37.1581 44.4804L38.0866 45.7405C38.3295 46.0697 38.4628 46.4668 38.4678 46.8758C38.4727 47.2848 38.3491 47.685 38.1144 48.02L37.1571 49.3875C36.9769 49.6448 36.7373 49.8548 36.4587 49.9999C36.18 50.145 35.8705 50.2208 35.5564 50.2209Z" fill="#3F3D56"/>
      <path d="M110.544 42.3785C112.619 42.3785 114.302 40.696 114.302 38.6206C114.302 36.5452 112.619 34.8628 110.544 34.8628C108.469 34.8628 106.786 36.5452 106.786 38.6206C106.786 40.696 108.469 42.3785 110.544 42.3785Z" fill="#3F3D56"/>
      <path d="M118.876 51.0381C120.951 51.0381 122.634 49.3557 122.634 47.2803C122.634 45.2049 120.951 43.5225 118.876 43.5225C116.801 43.5225 115.118 45.2049 115.118 47.2803C115.118 49.3557 116.801 51.0381 118.876 51.0381Z" fill="#3F3D56"/>
      <path d="M110.544 59.2071C112.619 59.2071 114.302 57.5246 114.302 55.4492C114.302 53.3738 112.619 51.6914 110.544 51.6914C108.469 51.6914 106.786 53.3738 106.786 55.4492C106.786 57.5246 108.469 59.2071 110.544 59.2071Z" fill="#3F3D56"/>
      <path d="M102.211 50.8746C104.286 50.8746 105.969 49.1921 105.969 47.1167C105.969 45.0413 104.286 43.3589 102.211 43.3589C100.136 43.3589 98.4531 45.0413 98.4531 47.1167C98.4531 49.1921 100.136 50.8746 102.211 50.8746Z" fill="#3F3D56"/>
      <path d="M118.877 49.7306C118.392 49.7306 117.918 49.5869 117.515 49.3176C117.112 49.0483 116.798 48.6655 116.612 48.2177C116.427 47.7699 116.378 47.2771 116.473 46.8017C116.567 46.3263 116.801 45.8897 117.144 45.5469C117.486 45.2042 117.923 44.9708 118.398 44.8762C118.874 44.7816 119.367 44.8302 119.814 45.0157C120.262 45.2011 120.645 45.5153 120.914 45.9183C121.184 46.3213 121.327 46.7951 121.327 47.2799C121.327 47.9296 121.068 48.5526 120.609 49.012C120.149 49.4714 119.526 49.7299 118.877 49.7306ZM118.877 45.1559C118.456 45.1559 118.046 45.2804 117.697 45.5138C117.347 45.7472 117.075 46.0789 116.914 46.467C116.753 46.8552 116.711 47.2822 116.793 47.6942C116.875 48.1062 117.078 48.4847 117.375 48.7817C117.672 49.0788 118.05 49.2811 118.462 49.363C118.874 49.445 119.301 49.4029 119.689 49.2422C120.077 49.0814 120.409 48.8092 120.643 48.4599C120.876 48.1106 121.001 47.6999 121.001 47.2799C121 46.7167 120.776 46.1769 120.378 45.7787C119.98 45.3805 119.44 45.1565 118.877 45.1559Z" fill="#E6E6E6"/>
      <path d="M113.169 40.4442L108.244 40.3746L110.767 36.144L113.169 40.4442ZM108.815 40.0558L112.608 40.1095L110.758 36.7976L108.815 40.0558Z" fill="#E6E6E6"/>
      <path d="M104.172 49.0775H100.251V45.1562H104.172V49.0775ZM100.578 48.7507H103.845V45.483H100.578V48.7507Z" fill="#E6E6E6"/>
      <path d="M112.571 57.1363L110.775 55.3433L112.359 53.7621L112.128 53.5308L110.543 55.1123L108.959 53.5308L108.728 53.7621L110.312 55.3433L108.516 57.1363L108.746 57.3677L110.543 55.5742L112.34 57.3677L112.571 57.1363Z" fill="#E6E6E6"/>
      <path d="M40.4185 12.7829C39.9338 12.7829 39.46 12.6391 39.057 12.3698C38.6539 12.1005 38.3398 11.7178 38.1543 11.27C37.9688 10.8222 37.9203 10.3294 38.0149 9.85399C38.1094 9.37859 38.3428 8.9419 38.6856 8.59916C39.0283 8.25641 39.465 8.023 39.9404 7.92844C40.4158 7.83388 40.9086 7.88241 41.3564 8.0679C41.8042 8.25339 42.187 8.56751 42.4563 8.97054C42.7256 9.37356 42.8693 9.84739 42.8693 10.3321C42.8686 10.9819 42.6101 11.6048 42.1507 12.0642C41.6912 12.5237 41.0683 12.7821 40.4185 12.7829ZM40.4185 8.20812C39.9984 8.20812 39.5878 8.33269 39.2385 8.56607C38.8892 8.79946 38.617 9.13118 38.4562 9.51929C38.2955 9.9074 38.2534 10.3345 38.3354 10.7465C38.4173 11.1585 38.6196 11.537 38.9166 11.834C39.2137 12.131 39.5921 12.3333 40.0042 12.4153C40.4162 12.4972 40.8432 12.4552 41.2313 12.2944C41.6195 12.1337 41.9512 11.8614 42.1846 11.5121C42.418 11.1628 42.5425 10.7522 42.5425 10.3321C42.5419 9.76898 42.3179 9.2291 41.9197 8.83091C41.5215 8.43272 40.9817 8.20874 40.4185 8.20812Z" fill="#E6E6E6"/>
      <path d="M34.8753 8.72447L29.9502 8.65483L32.4732 4.42432L34.8753 8.72447ZM30.5208 8.33604L34.314 8.38973L32.4639 5.07785L30.5208 8.33604Z" fill="#E6E6E6"/>
      <path d="M27.6263 14.6849H23.7051V10.7637H27.6263V14.6849ZM24.0318 14.3581H27.2995V11.0904H24.0318V14.3581Z" fill="#E6E6E6"/>
      <path d="M31.1727 21.9858L29.3763 20.1928L30.9605 18.6117L30.7296 18.3804L29.1449 19.9619L27.5603 18.3804L27.3294 18.6117L28.9136 20.1928L27.1172 21.9858L27.348 22.2171L29.1449 20.4237L30.9418 22.2171L31.1727 21.9858Z" fill="#E6E6E6"/>
      <path opacity="0.2" d="M141.832 57.0015L135.49 61.7744L142.649 58.6353L141.832 57.0015Z" fill="black"/>
      <path d="M24.5223 62.8832L16.5164 63.21L15.5361 57.8183L22.3983 55.6943L24.5223 62.8832Z" fill="#A0616A"/>
      <path d="M21.5786 49.6694L19.793 47.8512L21.9824 44.5034L25.7298 38.2656C25.6073 37.9969 25.5694 37.6973 25.6209 37.4066C25.6724 37.1158 25.811 36.8475 26.0183 36.6372C26.2256 36.4269 26.4919 36.2845 26.7819 36.2288C27.0719 36.1731 27.3719 36.2068 27.6424 36.3254C27.9129 36.4439 28.1409 36.6418 28.2965 36.8928C28.452 37.1439 28.5277 37.4362 28.5135 37.7311C28.4993 38.0261 28.3959 38.3098 28.2169 38.5447C28.038 38.7796 27.7919 38.9547 27.5113 39.0467L24.4182 45.7061L21.5786 49.6694Z" fill="#A0616A"/>
      <path d="M19.6003 53.8201C19.3906 54.1448 19.0919 54.4022 18.7398 54.5616C18.3877 54.721 17.9972 54.7757 17.6148 54.7191C17.2509 54.6761 16.9075 54.5278 16.6267 54.2924C16.3459 54.057 16.1399 53.7448 16.0341 53.394C15.9561 53.145 15.9334 52.882 15.9676 52.6233C16.0017 52.3647 16.0919 52.1165 16.2317 51.8963C17.4263 49.8826 19.6294 47.2232 19.6515 47.1966L19.6866 47.1543L23.4025 47.9594L19.6003 53.8201Z" fill="#F7B80B"/>
      <path d="M22.6138 98.3852L24.834 98.3851L25.8901 89.8213L22.6133 89.8214L22.6138 98.3852Z" fill="#A0616A"/>
      <path d="M22.0479 97.6603L26.4203 97.6602H26.4205C27.1595 97.6602 27.8682 97.9538 28.3908 98.4763C28.9133 98.9989 29.2069 99.7076 29.207 100.447V100.537L22.048 100.537L22.0479 97.6603Z" fill="#2F2E41"/>
      <path d="M5.32617 97.4242L7.5214 97.7559L9.84603 89.4464L6.96818 88.9565L5.32617 97.4242Z" fill="#A0616A"/>
      <path d="M4.87429 96.6226L9.19765 97.276L9.19783 97.276C9.92852 97.3866 10.5854 97.7828 11.024 98.3776C11.4625 98.9724 11.6469 99.717 11.5365 100.448L11.5229 100.537L4.44434 99.4673L4.87429 96.6226Z" fill="#2F2E41"/>
      <path d="M16.1898 61.5761L23.8689 60.269L26.2449 65.1667L26.3354 97.1306L22.1209 97.1043L20.3591 71.2335L9.13099 95.1386L4.60352 94.5953L14.4734 68.9697C14.4734 68.9697 13.9301 64.4423 15.6506 62.1785L16.1898 61.5761Z" fill="#2F2E41"/>
      <path d="M13.3385 51.9271C13.2211 51.5746 13.2086 51.1955 13.3026 50.836C13.3965 50.4765 13.5928 50.152 13.8677 49.902L14.959 48.91L18.0357 48.3916L19.9594 48.042L23.0514 57.0017L15.5417 60.4519C14.8827 60.3096 14.6194 59.9491 15.0029 59.2323L13.3385 51.9271Z" fill="#F7B80B"/>
      <path d="M20.2305 54.95L22.3787 53.5791L25.2011 56.4137L30.5289 61.3703C30.8171 61.3061 31.1181 61.3309 31.3919 61.4415C31.6657 61.552 31.8995 61.7431 32.0624 61.9894C32.2253 62.2357 32.3095 62.5257 32.304 62.821C32.2985 63.1162 32.2035 63.4028 32.0315 63.6429C31.8596 63.883 31.6188 64.0652 31.341 64.1655C31.0633 64.2657 30.7616 64.2793 30.476 64.2044C30.1903 64.1295 29.9342 63.9696 29.7414 63.7459C29.5486 63.5222 29.4282 63.2453 29.3962 62.9517L23.5206 58.548L20.2305 54.95Z" fill="#A0616A"/>
      <path d="M16.5789 52.1559C16.3047 51.8837 16.1146 51.5382 16.0315 51.1607C15.9483 50.7833 15.9757 50.3899 16.1102 50.0276C16.2276 49.6805 16.4437 49.3753 16.7321 49.1492C17.0205 48.9232 17.3685 48.7863 17.7336 48.7552C17.9933 48.7304 18.2553 48.7626 18.5013 48.8495C18.7473 48.9364 18.9714 49.0759 19.158 49.2583C20.881 50.8435 23.0272 53.5492 23.0486 53.5763L23.0827 53.6194L21.5264 57.0883L16.5789 52.1559Z" fill="#F7B80B"/>
      <path d="M16.6859 47.3015C18.5511 47.3015 20.0632 45.7894 20.0632 43.9242C20.0632 42.0589 18.5511 40.5469 16.6859 40.5469C14.8207 40.5469 13.3086 42.0589 13.3086 43.9242C13.3086 45.7894 14.8207 47.3015 16.6859 47.3015Z" fill="#A0616A"/>
      <path d="M13.9512 48.7353C13.4274 48.7352 12.9042 48.7033 12.3843 48.6398L12.3131 48.6311L11.7691 45.6777C11.5383 44.4258 11.7956 43.1329 12.488 42.0647C13.1804 40.9964 14.2554 40.2336 15.4925 39.933C15.575 39.9121 15.6618 39.9144 15.7431 39.9395C15.8244 39.9647 15.8973 40.0119 15.9536 40.0757L16.0833 40.0305C16.58 39.8573 17.106 39.7836 17.6312 39.8137C18.1564 39.8437 18.6706 39.9769 19.1443 40.2057C19.6181 40.4345 20.0421 40.7543 20.3922 41.147C20.7423 41.5396 21.0117 41.9974 21.1849 42.4941L21.217 42.5864L20.3512 42.8883C20.0814 42.9824 19.836 43.1354 19.6328 43.3362C19.4296 43.5371 19.2738 43.7807 19.1765 44.0494C19.0793 44.318 19.0432 44.605 19.0709 44.8893C19.0985 45.1737 19.1892 45.4484 19.3363 45.6933L20.2215 47.167L20.1273 47.2142C18.2166 48.1993 16.1008 48.7204 13.9512 48.7353Z" fill="#2F2E41"/>
      <path d="M129.98 62.8683C130.019 62.6795 130.099 62.5015 130.214 62.347C130.329 62.1924 130.477 62.0651 130.647 61.9738C130.817 61.8826 131.004 61.8297 131.197 61.8188C131.389 61.808 131.582 61.8395 131.761 61.9112L134.833 58.3711L135.49 60.7164L132.472 63.7185C132.332 64.0113 132.089 64.2424 131.79 64.3681C131.49 64.4938 131.155 64.5053 130.848 64.4005C130.541 64.2957 130.283 64.0819 130.123 63.7995C129.963 63.5171 129.912 63.1858 129.979 62.8683H129.98Z" fill="#FFB6B6"/>
      <path d="M143.687 47.3044L144.945 47.2363L145.134 48.0362C145.134 48.0362 145.116 49.0604 143.968 51.1894C142.821 53.3183 133.931 62.797 133.931 62.797L132.006 60.612L136.765 55.7104L139.646 50.5731L143.687 47.3044Z" fill="#F7B80B"/>
      <path d="M0.310187 100.719H149.689C149.743 100.719 149.795 100.698 149.833 100.659C149.871 100.621 149.893 100.569 149.893 100.515C149.893 100.461 149.871 100.409 149.833 100.371C149.795 100.333 149.743 100.312 149.689 100.312H0.310187C0.256152 100.312 0.20433 100.333 0.166121 100.371C0.127913 100.409 0.106445 100.461 0.106445 100.515C0.106445 100.569 0.127913 100.621 0.166121 100.659C0.20433 100.698 0.256152 100.719 0.310187 100.719Z" fill="#CCCCCC"/>
    </g>
    <defs>
      <clipPath id="clip0_1063_4381">
        <rect width="149.786" height="100" fill="white" transform="translate(0.106445 0.71875)"/>
      </clipPath>
    </defs>
  </svg>

);