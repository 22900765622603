import { Button, ButtonProps } from "@chakra-ui/react";
import { FC, ReactElement, ReactNode } from "react";
import { colors } from "../../ui/theme";

interface Props {
  children: ReactNode;
  onClick?: any;
  active?: boolean;
}

const OutlineButton: FC<Props & ButtonProps> = ({
  onClick,
  children,
  active,
  ...props
}): ReactElement => (
  <Button
    cursor="pointer"
    p="20px"
    border={`1px solid ${colors.black}`}
    borderRadius="20px"
    width="240px"
    height="auto"
    gap="4px"
    backgroundColor={colors.white}
    isActive={active}
    _hover={{
      backgroundColor: colors.additional.lightMode,
      opacity: 0.5,
      color: colors.white,
      p: {
        color: colors.white,
      },
    }}
    _active={{
      backgroundColor: colors.additional.lightMode,
      color: colors.white,
    }}
    onClick={onClick}
    {...props}
  >
    {children}
  </Button>
);
export default OutlineButton;
