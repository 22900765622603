export const BusIcon = () => (
  <svg
    width="166"
    height="125"
    viewBox="0 0 166 125"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M163.817 47.2937C163.508 47.2937 163.258 47.0421 163.258 46.7309V44.0521C163.258 43.7799 163.076 43.5369 162.817 43.4621L153.308 40.7041C153.011 40.6185 152.84 40.3061 152.926 40.0079C153.011 39.7086 153.321 39.5372 153.617 39.6229L163.126 42.3808C163.862 42.5945 164.375 43.2821 164.375 44.0532V46.732C164.375 47.0432 164.125 47.2948 163.817 47.2948V47.2937Z"
      fill="#737373"
    />
    <path
      d="M164.477 45.5108H163.156C162.827 45.5108 162.56 45.7798 162.56 46.1116V53.8996C162.56 54.2314 162.827 54.5004 163.156 54.5004H164.477C164.806 54.5004 165.073 54.2314 165.073 53.8996V46.1116C165.073 45.7798 164.806 45.5108 164.477 45.5108Z"
      fill="#5A5A5A"
    />
    <path
      d="M40.5297 31.8316H19.8414C19.2518 31.8316 18.7731 32.3132 18.7731 32.9086V34.7284H41.598V32.9086C41.598 32.3142 41.1203 31.8316 40.5297 31.8316Z"
      fill="#F7B80B"
    />
    <path
      d="M120.951 31.875H100.263C99.673 31.875 99.1943 32.3576 99.1943 32.9519V34.7718H122.019V32.9519C122.019 32.3576 121.542 31.875 120.951 31.875Z"
      fill="#F7B80B"
    />
    <path
      d="M159.303 66.5918V84.2946H2.94299V36.8421C2.94299 35.4323 4.06072 34.2902 5.44095 34.2902H150.626C151.784 34.2902 152.79 35.1037 153.057 36.2533L154.383 41.9839L158.548 59.9849C158.932 61.641 159.169 63.3285 159.261 65.028C159.289 65.5485 159.303 66.0691 159.303 66.5918Z"
      fill="#386F8D"
    />
    <path
      d="M26.4983 41.9839H14.1903C13.2521 41.9839 12.4916 42.7506 12.4916 43.6964V57.2367C12.4916 58.1825 13.2521 58.9492 14.1903 58.9492H26.4983C27.4365 58.9492 28.197 58.1825 28.197 57.2367V43.6964C28.197 42.7506 27.4365 41.9839 26.4983 41.9839Z"
      fill="#A8E1E7"
    />
    <path
      d="M44.9942 41.9839H32.6862C31.7481 41.9839 30.9876 42.7506 30.9876 43.6964V57.2367C30.9876 58.1825 31.7481 58.9492 32.6862 58.9492H44.9942C45.9324 58.9492 46.6929 58.1825 46.6929 57.2367V43.6964C46.6929 42.7506 45.9324 41.9839 44.9942 41.9839Z"
      fill="#A8E1E7"
    />
    <path
      d="M63.4202 41.9839H51.1122C50.1741 41.9839 49.4135 42.7506 49.4135 43.6964V57.2367C49.4135 58.1825 50.1741 58.9492 51.1122 58.9492H63.4202C64.3584 58.9492 65.1189 58.1825 65.1189 57.2367V43.6964C65.1189 42.7506 64.3584 41.9839 63.4202 41.9839Z"
      fill="#A8E1E7"
    />
    <path
      d="M81.9162 41.9839H69.6081C68.67 41.9839 67.9095 42.7506 67.9095 43.6964V57.2367C67.9095 58.1825 68.67 58.9492 69.6081 58.9492H81.9162C82.8543 58.9492 83.6148 58.1825 83.6148 57.2367V43.6964C83.6148 42.7506 82.8543 41.9839 81.9162 41.9839Z"
      fill="#A8E1E7"
    />
    <path
      d="M123.933 41.9839H111.625C110.687 41.9839 109.926 42.7506 109.926 43.6964V57.2367C109.926 58.1825 110.687 58.9492 111.625 58.9492H123.933C124.871 58.9492 125.632 58.1825 125.632 57.2367V43.6964C125.632 42.7506 124.871 41.9839 123.933 41.9839Z"
      fill="#A8E1E7"
    />
    <path
      d="M148.912 60.2658C149.028 60.7939 148.878 61.3471 148.512 61.7386C148.147 62.1301 147.613 62.3069 147.092 62.2114L129.705 59.0012C128.953 58.8624 128.406 58.1933 128.406 57.4124V43.5998C128.406 42.7084 129.113 41.985 129.984 41.985H143.614C144.352 41.985 144.991 42.5077 145.154 43.2441L148.912 60.2669V60.2658Z"
      fill="#A8E1E7"
    />
    <path
      d="M158.548 59.9849L154.383 41.985L151.226 41.9839C150.882 41.9839 150.559 42.1422 150.346 42.4123C150.133 42.6834 150.054 43.037 150.133 43.3732L154.925 64.0117C155.063 64.6061 155.59 65.0269 156.196 65.0269H159.262C159.171 63.3285 158.934 61.641 158.549 59.9838L158.548 59.9849Z"
      fill="#A8E1E7"
    />
    <path
      d="M9.14164 34.2902V83.6081H2.94299V36.8074C2.94299 35.4171 4.06072 34.2913 5.44095 34.2913H9.14164V34.2902Z"
      fill="#559EC2"
    />
    <path
      d="M159.303 71.6186H2.94299V84.2935H159.303V71.6186Z"
      fill="#559EC2"
    />
    <path
      d="M8.78341 71.6186H2.94299V77.0923H8.78341V71.6186Z"
      fill="#FAC51C"
    />
    <path
      d="M7.08475 77.0922H8.78341C9.72149 77.0922 10.4821 76.5044 10.4821 75.7778V72.932C10.4821 72.2064 9.72149 71.6175 8.78341 71.6175H7.08475V77.0912V77.0922Z"
      fill="#E7E7E7"
    />
    <path
      d="M2.62563 78.4468C1.68755 78.4468 0.926971 79.2136 0.926971 80.1593V82.7372C0.926971 83.6829 1.68755 84.4497 2.62563 84.4497H35.0519V78.4468H2.62563Z"
      fill="#898989"
    />
    <path
      d="M159.303 71.3139H153.462V78.4468H159.303V71.3139Z"
      fill="#FAC51C"
    />
    <path
      d="M153.462 71.3139C152.524 71.3139 151.763 72.0806 151.763 73.0263V76.7344C151.763 77.6801 152.524 78.4468 153.462 78.4468H155.161V71.3139H153.462Z"
      fill="#E7E7E7"
    />
    <path
      d="M128.045 84.4497H160.471C161.409 84.4497 162.169 83.6829 162.169 82.7372V80.1593C162.169 79.2136 161.409 78.4468 160.471 78.4468H128.045V84.4497Z"
      fill="#898989"
    />
    <path
      d="M45.6292 86.4824C49.3788 82.7024 49.3788 76.5737 45.6292 72.7936C41.8797 69.0136 35.8004 69.0136 32.0509 72.7936C28.3013 76.5737 28.3013 82.7024 32.0509 86.4824C35.8004 90.2624 41.8797 90.2624 45.6292 86.4824Z"
      fill="#5A5A5A"
    />
    <path
      d="M43.3985 80.3925C43.807 77.8549 42.0977 75.4639 39.5807 75.052C37.0636 74.6401 34.6918 76.3633 34.2833 78.9009C33.8747 81.4384 35.5839 83.8294 38.101 84.2413C40.6181 84.6532 42.9899 82.9301 43.3985 80.3925Z"
      fill="#E7E7E7"
    />
    <path
      d="M134.833 86.4832C138.583 82.7031 138.583 76.5745 134.833 72.7944C131.084 69.0144 125.005 69.0144 121.255 72.7944C117.505 76.5745 117.505 82.7031 121.255 86.4832C125.005 90.2632 131.084 90.2632 134.833 86.4832Z"
      fill="#5A5A5A"
    />
    <path
      d="M131.309 82.9302C133.112 81.1124 133.112 78.1652 131.309 76.3474C129.506 74.5296 126.582 74.5296 124.779 76.3474C122.976 78.1652 122.976 81.1124 124.779 82.9302C126.582 84.748 129.506 84.748 131.309 82.9302Z"
      fill="#E7E7E7"
    />
    <path
      d="M103.731 41.9839H89.9576C89.0195 41.9839 88.259 42.7506 88.259 43.6964V79.7363C88.259 80.6821 89.0195 81.4488 89.9576 81.4488H103.731C104.669 81.4488 105.43 80.6821 105.43 79.7363V43.6964C105.43 42.7506 104.669 41.9839 103.731 41.9839Z"
      fill="#F7B80B"
    />
    <path
      d="M102.887 45.5108H98.2702C97.9018 45.5108 97.6032 45.8118 97.6032 46.1832V69.2869C97.6032 69.6583 97.9018 69.9593 98.2702 69.9593H102.887C103.256 69.9593 103.554 69.6583 103.554 69.2869V46.1832C103.554 45.8118 103.256 45.5108 102.887 45.5108Z"
      fill="#A8E1E7"
    />
    <path
      d="M95.4183 45.5108H90.801C90.4327 45.5108 90.1341 45.8118 90.1341 46.1832V69.2869C90.1341 69.6583 90.4327 69.9593 90.801 69.9593H95.4183C95.7867 69.9593 96.0853 69.6583 96.0853 69.2869V46.1832C96.0853 45.8118 95.7867 45.5108 95.4183 45.5108Z"
      fill="#A8E1E7"
    />
    <path
      d="M13.6589 62.7992H82.4465C83.0909 62.7992 83.6137 63.3263 83.6137 63.976V67.2111C83.6137 67.8607 83.0909 68.3878 82.4465 68.3878H13.6589C13.0145 68.3878 12.4916 67.8607 12.4916 67.2111V63.976C12.4916 63.3263 13.0145 62.7992 13.6589 62.7992Z"
      fill="#F7B80B"
    />
  </svg>
);
