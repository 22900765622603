import { Flex } from "@chakra-ui/react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import BottomBlock from "../../components/BottomActionsBlock/BottomBlock";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import ContentLayout from "../../components/Layout/ContentLayout";
import MainContainer from "../../components/Layout/MainContainer";
import TotalCard from "../../components/TotalCard/TotalCard";
import {
  OnboardingInfo,
  useOnboardingContext,
} from "../../context/OnboardingContext";
import {
  OptionInformation,
  useOptionContext,
} from "../../context/OptionsContext";
import { UserInformation, useUserContext } from "../../context/UserContext";
import { EventSubCategory } from "../../modules/Onboarding/EventTypes";
import OnboardingOptions from "../../modules/Onboarding/OnboardingOptions";
import {
  preselectAwards,
  preselectCongress,
  preselectEventVillage,
  preselectInauguration,
  preselectOther,
  preselectPremiere,
  preselectPress,
  preselectPublicOther,
  preselectSportEvent,
} from "../../modules/Onboarding/onboardingConfig";
import { PageURL } from "../../types/pages";
import { useSessionContext } from "../../context/SessionContext";

const EVENT_PRESELECTION_FUNCTION: {
  [key in EventSubCategory]?: (
    onboardingInfo: OnboardingInfo,
    userInfos: UserInformation | null,
    setUserInfos: (userContent: UserInformation) => any,
    optionInfo: OptionInformation | null,
    setOptionInfo: (optionContent: OptionInformation) => any
  ) => string;
} = {
  [EventSubCategory.CONGRES]: preselectCongress,
  [EventSubCategory.SOIREE_PRESSE]: preselectPress,
  [EventSubCategory.REMISE_DE_PRIX]: preselectAwards,
  [EventSubCategory.CINEMA]: preselectPremiere,
  [EventSubCategory.AUTRE_PRO]: preselectOther,
  [EventSubCategory.INAUGURATION]: preselectInauguration,
  [EventSubCategory.VILLAGE_EVENEMENTIEL]: preselectEventVillage,
  [EventSubCategory.EVENEMENT_SPORTIF]: preselectSportEvent,
  [EventSubCategory.AUTRE_PUBLIC]: preselectPublicOther,
};

export default function OnboardingEventOptions() {
  const {
    eventSubcategory,
    parking,
    setParking,
    eventInWinter,
    setEventInWinter,
    getAllInfo,
  } = useOnboardingContext();
  const { userInfos, setUserInfos } = useUserContext();
  const { optionInfo, setOptionInfo } = useOptionContext();
  const navigate = useNavigate();
  const { updateHistory } = useSessionContext();

  const preselectFct = useMemo(() => {
    if (!eventSubcategory || !EVENT_PRESELECTION_FUNCTION[eventSubcategory]) {
      updateHistory(PageURL.ONBOARDING);
      navigate(PageURL.ONBOARDING);
      return;
    }
    return EVENT_PRESELECTION_FUNCTION[eventSubcategory];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventSubcategory]);

  return (
    <Layout>
      <ContentLayout>
        <MainContainer>
          <Flex flexDir="column">
            <Header
              title={"UNE DERNIÈRE CHOSE ?"}
              subtitle={
                "Afin d’affiner votre demande, répondez en deux clics à nos dernières questions ?"
              }
            />
            <OnboardingOptions
              eventInWinter={eventInWinter}
              parking={parking}
              setEventInWinter={setEventInWinter}
              setParking={setParking}
            />
          </Flex>
          <BottomBlock
            onNextPage={() => {
              if (!preselectFct) return;
              const page = preselectFct(
                getAllInfo(),
                userInfos,
                setUserInfos,
                optionInfo,
                setOptionInfo
              );
              updateHistory(page as PageURL);
              navigate(page);
            }}
          />
          <TotalCard />
        </MainContainer>
      </ContentLayout>
    </Layout>
  );
}
