import { Box, Flex, Text } from "@chakra-ui/react";
import { FC, useState } from "react";
import NorelaButton from "../../../components/Buttons/NorelaButton";
import { ConsultationBlockIcon } from "../../../components/Icons/ConsultationBlockIcon";
import BottomPopup from "../../../components/Popus/BottomPopup/BottomPopup";

const ConsultationBlock: FC = () => {
  const [open, setOpen] = useState(false);
  return (
    <Box>
      <Flex
        border="1px solid #D9D9D9"
        borderRadius="24px"
        alignItems="center"
        padding={{ md: "14px", "2xl": "24px" }}
        width="fit-content"
        onClick={() => {}}
        flexDir="column"
      >
        <Flex gap="1rem" alignItems="center">
          <ConsultationBlockIcon />
          <Text variant="xl" display="block" textAlign="left" maxWidth="420px">
            Vous souhaitez faire appel à un de nos talents ?
          </Text>
        </Flex>
        <NorelaButton w="100%" mt="1rem" onClick={() => setOpen(true)}>
          Contacter un conseiller
        </NorelaButton>
      </Flex>
      <BottomPopup open={open} close={() => setOpen(false)} />
    </Box>
  );
};

export default ConsultationBlock;
