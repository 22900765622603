export enum EventType {
  ACCUEIL = "event-accueil",
  STREET_MARKETING = "event-street-marketing",
}

export const EVENT_TYPE_TEXTS: {
  [key in EventType]: {
    totalCard: string;
    title: string;
  };
} = {
  [EventType.ACCUEIL]: {
    totalCard: "Service d'accueil",
    title: "Service d'accueil",
  },
  [EventType.STREET_MARKETING]: {
    totalCard: "Street marketing",
    title: "Animation et distribution",
  },
};

export type Location = {
  name?: string;
  address: string;
  city: string;
  postalCode: string;
  long?: number;
  lat?: number;
  isInGreatParis: boolean;
  isAddressKnown: boolean;
};

export enum EventCategory {
  PROFESSIONNEL = "event-category-professionnel",
  GRAND_PUBLIC = "event-category-grand-public",
  STREET_MARKETING = "event-category-street-marketing",
  ANIMATION_PROMOTIONNELLE = "event-category-animation-promotionnelle",
  ANIMATION_EVENT = "event-category-animation-evenementielle",
  ROADSHOW = "event-category-roadshow",
}

export const EVENT_CATEGORY_TEXTS: {
  [key in EventCategory]: {
    totalCard: string;
    title: string;
  };
} = {
  [EventCategory.PROFESSIONNEL]: {
    totalCard: "Professionnel",
    title: "Professionnel",
  },
  [EventCategory.GRAND_PUBLIC]: {
    totalCard: "Grand public",
    title: "Grand public",
  },
  [EventCategory.STREET_MARKETING]: {
    totalCard: "Street marketing",
    title: "Street marketing",
  },
  [EventCategory.ANIMATION_PROMOTIONNELLE]: {
    totalCard: "Animation magasin",
    title: "Animation magasin",
  },

  [EventCategory.ANIMATION_EVENT]: {
    totalCard: "Animation événementielle",
    title: "Animation événementielle",
  },
  [EventCategory.ROADSHOW]: {
    totalCard: "Roadshow",
    title: "Roadshow",
  },
};

export enum EventSubCategory {
  SALON_PRO = "salon",
  SOIREE_PRESSE = "soiree-press",
  CONGRES = "congres",
  REMISE_DE_PRIX = "remise-de-prix",
  CINEMA = "avant-première / projection privée",
  AUTRE_PRO = "autre-pro",

  SALON_PUBLIC = "salon-public",
  INAUGURATION = "inauguration",
  VILLAGE_EVENEMENTIEL = "village-evenementiel",
  EVENEMENT_SPORTIF = "evenement-sportif",
  AUTRE_PUBLIC = "autre-public",

  FLYERS = "flyers",
  TESTING = "testing",

  HIGH_TECH = "high-tech",
  GAMING = "gaming",
  FOOD = "food",
  TELEPHONIE = "telephonie",
  INSTITUIONNEL = "instituionnel",
  TEXTILE = "textile",
  DRINK = "drink",
  COSMETIQUE = "cosmetique",

  EVENEMENT_SPORTIF_ANIMATION = "evenement-sportif-animation",
  VILLAGE_EVENEMENTIEL_ANIMATION = "village-evenementiel-animation",
  SALON_ANIMATION = "salon-animation",
  FESTIVAL = "festival",
  CONCERT = "concert",
  ESCAPE_GAME = "escape-game",
  AUTRE_ANIMATION = "autre-animation",
}
export const EventSubCategory_ANIMATION = [
  EventSubCategory.FLYERS,
  EventSubCategory.TESTING,
  EventSubCategory.HIGH_TECH,
  EventSubCategory.GAMING,
  EventSubCategory.FOOD,
  EventSubCategory.TELEPHONIE,
  EventSubCategory.INSTITUIONNEL,
  EventSubCategory.TEXTILE,
  EventSubCategory.DRINK,
  EventSubCategory.COSMETIQUE,
  EventSubCategory.EVENEMENT_SPORTIF_ANIMATION,
  EventSubCategory.VILLAGE_EVENEMENTIEL_ANIMATION,
  EventSubCategory.SALON_ANIMATION,
  EventSubCategory.FESTIVAL,
  EventSubCategory.CONCERT,
  EventSubCategory.ESCAPE_GAME,
  EventSubCategory.AUTRE_ANIMATION,
];

export const EVENT_SUBCATEGORY_TEXTS: {
  [key in EventSubCategory]: {
    totalCard: string;
    title: string;
  };
} = {
  [EventSubCategory.SALON_PRO]: {
    totalCard: "Salon",
    title: "Salon",
  },
  [EventSubCategory.CONGRES]: {
    totalCard: "Congrès / Convention / Séminaire",
    title: "Congrès / Convention / Séminaire",
  },
  [EventSubCategory.SOIREE_PRESSE]: {
    totalCard: "Soirée press",
    title: "Soirée press",
  },
  [EventSubCategory.REMISE_DE_PRIX]: {
    totalCard: "Remise de prix",
    title: "Remise de prix",
  },
  [EventSubCategory.CINEMA]: {
    totalCard: "Avant-première / projection privée",
    title: "Avant-première / projection privée",
  },
  [EventSubCategory.AUTRE_PRO]: {
    totalCard: "Autre",
    title: "Autre ?",
  },
  [EventSubCategory.SALON_PUBLIC]: {
    totalCard: "Salon",
    title: "Salon",
  },
  [EventSubCategory.INAUGURATION]: {
    totalCard: "Inauguration",
    title: "Inauguration",
  },
  [EventSubCategory.VILLAGE_EVENEMENTIEL]: {
    totalCard: "Village événementiel",
    title: "Village événementiel",
  },
  [EventSubCategory.EVENEMENT_SPORTIF]: {
    totalCard: "Événement sportif",
    title: "Événement sportif",
  },
  [EventSubCategory.AUTRE_PUBLIC]: {
    totalCard: "Autre",
    title: "Autre ?",
  },
  [EventSubCategory.FLYERS]: {
    totalCard: "Flyers / Goodies",
    title: "Dispositif de distribution de flyers ou goodies",
  },
  [EventSubCategory.TESTING]: {
    totalCard: "Tests produits",
    title: "Echantillonnage et test produit",
  },
  [EventSubCategory.HIGH_TECH]: {
    totalCard: "High tech",
    title: "High tech",
  },
  [EventSubCategory.GAMING]: {
    totalCard: "Gaming",
    title: "Gaming",
  },
  [EventSubCategory.FOOD]: {
    totalCard: "Food",
    title: "Food",
  },
  [EventSubCategory.TELEPHONIE]: {
    totalCard: "Téléphonie",
    title: "Téléphonie",
  },
  [EventSubCategory.INSTITUIONNEL]: {
    totalCard: "Institutionnel",
    title: "Institutionnel",
  },
  [EventSubCategory.TEXTILE]: {
    totalCard: "Textile",
    title: "Textile",
  },
  [EventSubCategory.DRINK]: {
    totalCard: "Drink",
    title: "Drink",
  },
  [EventSubCategory.COSMETIQUE]: {
    totalCard: "Cosmétique",
    title: "Cosmétique",
  },
  [EventSubCategory.EVENEMENT_SPORTIF_ANIMATION]: {
    totalCard: "Événement sportif",
    title: "Événement sportif",
  },
  [EventSubCategory.VILLAGE_EVENEMENTIEL_ANIMATION]: {
    totalCard: "Village événementiel",
    title: "Village événementiel",
  },
  [EventSubCategory.SALON_ANIMATION]: {
    totalCard: "Salon",
    title: "Salon",
  },
  [EventSubCategory.FESTIVAL]: {
    totalCard: "Festival",
    title: "Festival",
  },
  [EventSubCategory.CONCERT]: {
    totalCard: "Concert",
    title: "Concert",
  },
  [EventSubCategory.ESCAPE_GAME]: {
    totalCard: "Escape game",
    title: "Escape game",
  },
  [EventSubCategory.AUTRE_ANIMATION]: {
    totalCard: "Autre",
    title: "Autre ?",
  },
};
