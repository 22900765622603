export const OptionsPanneauDirectionnelIcon = () => (
  <svg
    width="120"
    height="100"
    viewBox="0 0 120 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1454_52373)">
      <path
        d="M18.3477 95.7917L20.6451 96.4339L24.2162 87.8777L20.8254 86.9297L18.3477 95.7917Z"
        fill="#FFB8B8"
      />
      <path
        d="M24.4805 99.8047L17.2596 97.7859L18.0396 94.996L22.7498 96.3129C23.4157 96.499 23.9803 96.9421 24.3195 97.5446C24.6587 98.147 24.7447 98.8596 24.5585 99.5255L24.4805 99.8047Z"
        fill="#2F2E41"
      />
      <path
        d="M45.7547 97.5907L48.1404 97.5906L49.2748 88.3887L45.7539 88.3889L45.7547 97.5907Z"
        fill="#FFB8B8"
      />
      <path
        d="M52.7422 99.8047L45.2444 99.8054L45.2441 96.9085L50.135 96.9081C50.4773 96.908 50.8163 96.9754 51.1326 97.1064C51.4489 97.2374 51.7363 97.4294 51.9784 97.6714C52.2205 97.9135 52.4126 98.2009 52.5436 98.5171C52.6747 98.8334 52.7421 99.1724 52.7422 99.5148L52.7422 99.8047Z"
        fill="#2F2E41"
      />
      <path
        d="M28.0262 59.3867L22.5352 72.8386L44.9571 77.8757L45.2431 70.3497L35.0617 59.5052L28.0262 59.3867Z"
        fill="#B3B3B3"
      />
      <path
        d="M40.1915 38.4326C42.6753 37.0749 43.5881 33.9607 42.2304 31.4769C40.8726 28.9931 37.7584 28.0803 35.2746 29.438C32.7908 30.7958 31.878 33.91 33.2358 36.3938C34.5935 38.8776 37.7077 39.7904 40.1915 38.4326Z"
        fill="#FFB8B8"
      />
      <path
        d="M41.395 58.6433L31.2459 54.4888L30.3422 48.5305C30.0761 46.789 30.4917 45.0118 31.5024 43.5688C32.5132 42.1258 34.0413 41.128 35.769 40.783L37.1114 40.5134C38.2142 40.2926 39.3542 40.3443 40.4325 40.6641C41.5107 40.9839 42.4946 41.5621 43.2987 42.3485C44.0863 43.101 44.6753 44.0369 45.013 45.0726C45.3508 46.1082 45.4268 47.2114 45.2343 48.2836C45.0826 49.2023 44.71 50.0704 44.1485 50.8132C40.9602 54.9825 41.3695 58.4485 41.3741 58.483L41.395 58.6433Z"
        fill="#F7B80B"
      />
      <path
        d="M45.5274 74.1815L40.441 66.0924L42.0431 51.3779L39.8301 40.373L39.9477 40.376C41.2349 40.4078 42.4605 40.9334 43.3708 41.844C44.281 42.7547 44.8061 43.9805 44.8373 45.2677L46.9902 56.0515L45.5274 74.1815Z"
        fill="#2F2E41"
      />
      <path
        d="M45.6442 95.8514C45.4298 95.8512 45.2221 95.7768 45.0563 95.6409C44.8905 95.505 44.7768 95.316 44.7345 95.1058L42.5062 84.0251C42.0992 81.9949 41.3327 80.0539 40.243 78.2933L33.9554 68.1221C33.9241 68.0715 33.8794 68.0305 33.8263 68.0037C33.7733 67.9768 33.7138 67.9651 33.6545 67.9698C33.5952 67.9746 33.5383 67.9956 33.4902 68.0305C33.442 68.0655 33.4045 68.1131 33.3816 68.168L23.0441 93.0013C22.961 93.1998 22.8117 93.3634 22.6216 93.4642C22.4314 93.565 22.2123 93.5968 22.0013 93.5542L19.2398 92.9912C19.0032 92.944 18.7944 92.8062 18.658 92.6072C18.5217 92.4082 18.4684 92.1637 18.5098 91.9261L20.7625 78.6495C21.2221 75.9434 22.0051 73.3023 23.0948 70.783L30.7243 53.1504L41.9834 56.8481L42.7264 64.7321L47.9287 77.3291C48.2882 78.199 48.5031 79.1219 48.5649 80.0611L49.526 94.6198C49.5409 94.8628 49.4603 95.102 49.3013 95.2863C49.1424 95.4707 48.9176 95.5856 48.6751 95.6065L45.7207 95.8483C45.6951 95.8503 45.6695 95.8514 45.6442 95.8514Z"
        fill="#2F2E41"
      />
      <path
        d="M25.1046 77.7883L21.5352 74.4002L28.281 48.3875C28.709 46.752 29.5114 45.2384 30.6249 43.9664C31.7384 42.6943 33.1324 41.6985 34.6969 41.0578L34.8293 41.0039L34.4689 56.8358L25.1046 77.7883Z"
        fill="#2F2E41"
      />
      <path
        d="M36.5389 39.0414C35.2727 37.9454 34.8876 35.9008 35.6634 34.3935C36.4393 32.8862 38.2945 32.0747 39.8809 32.5489C41.3749 32.9954 43.0734 31.5059 42.8914 29.9087C42.7093 28.3115 40.7226 27.2711 39.3698 28.0646C38.3453 27.6534 37.2239 27.547 36.1404 27.758C35.0568 27.9691 34.0573 28.4886 33.262 29.2542C31.6727 30.8111 31.0698 33.3564 31.7833 35.4967C32.4968 37.6369 34.4884 39.2573 36.5389 39.0414Z"
        fill="#2F2E41"
      />
      <path
        d="M32.0559 80.6974C31.9933 80.7214 31.9282 80.7382 31.8619 80.7474L23.7126 81.885C23.5154 81.9132 23.3145 81.8735 23.143 81.7724C22.9714 81.6712 22.8394 81.5147 22.7686 81.3286L15.754 63.076C15.7085 62.9578 15.6891 62.8313 15.697 62.7049C15.705 62.5786 15.74 62.4554 15.7998 62.3438C15.8597 62.2322 15.9428 62.1349 16.0437 62.0583C16.1445 61.9818 16.2607 61.9279 16.3842 61.9004L16.3843 61.9003L24.0478 60.1902C24.107 60.1769 24.1642 60.156 24.2179 60.1279L31.6718 56.2308C31.7294 56.2006 31.7902 56.177 31.853 56.1603L40.0561 53.9734C40.2662 53.917 40.4897 53.9405 40.6835 54.0393C40.8773 54.1382 41.0275 54.3053 41.1052 54.5085L47.2215 70.4236C47.2752 70.5623 47.2928 70.7123 47.2726 70.8596C47.2523 71.0069 47.1949 71.1467 47.1058 71.2657L44.9905 74.1062C44.891 74.2399 44.7556 74.3426 44.6 74.4023L39.9773 76.1788C39.9441 76.1916 39.912 76.2069 39.8812 76.2245L32.1781 80.6393C32.1389 80.6618 32.0981 80.6812 32.0559 80.6974Z"
        fill="white"
      />
      <path
        d="M35.8459 56.4072L34.8043 56.685L32.0438 57.4212L32.0389 57.4224L28.2596 59.3985L26.857 60.132L26.8564 60.1322L24.7499 61.2334L24.6922 61.2465L22.5039 61.7345L21.4463 61.9709L17.1328 62.9334L23.9045 80.5539L28.3495 79.9336L29.4394 79.7812L31.7624 79.4565L31.7894 79.4531L39.254 75.1748L43.7957 73.4294L45.8694 70.6449L39.9747 55.3066L35.8459 56.4072Z"
        fill="#E6E6E6"
      />
      <path
        d="M40.9466 57.8327L18.4902 66.4629L18.8748 67.4636L41.3311 58.8334L40.9466 57.8327Z"
        fill="white"
      />
      <path
        d="M42.2278 61.1687L19.7715 69.7988L20.156 70.7995L42.6124 62.1694L42.2278 61.1687Z"
        fill="white"
      />
      <path
        d="M44.8177 67.907L22.3613 76.5371L22.7459 77.5378L45.2022 68.9076L44.8177 67.907Z"
        fill="white"
      />
      <path
        d="M35.8464 56.4062L42.5694 73.9L41.5688 74.2846L34.8047 56.6842L35.8464 56.4062Z"
        fill="white"
      />
      <path
        d="M28.2599 59.3984L26.8794 63.2396L26.4007 64.572L25.2841 67.6809L25.0449 68.3455L24.8054 69.0133L24.4901 69.8901L22.227 76.1883L21.6387 74.6572L23.4839 69.5213L23.9014 68.3584L23.9626 68.1887L24.4563 66.8138L25.0792 65.0799L25.5579 63.7474L26.8567 60.1321L26.8573 60.132L28.2599 59.3984Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M39.4181 75.111L39.2552 75.1735L31.7906 79.4518L31.7636 79.4552L24.6934 61.2451L24.7512 61.2321L26.8577 60.1309L26.8582 60.1307L28.2609 59.3972L32.0402 57.421L32.0451 57.4199L39.4181 75.111Z"
        fill="black"
      />
      <path
        d="M32.0897 80.7852C32.0205 80.8118 31.9484 80.8304 31.875 80.8406L23.7257 81.9782C23.5075 82.0094 23.2852 81.9655 23.0954 81.8535C22.9055 81.7416 22.7594 81.5685 22.6811 81.3625L15.6664 63.1099C15.6162 62.9791 15.5947 62.839 15.6035 62.6992C15.6122 62.5594 15.651 62.4231 15.7172 62.2996C15.7834 62.1762 15.8755 62.0684 15.9871 61.9837C16.0987 61.899 16.2272 61.8394 16.3639 61.8089L24.0275 60.0987C24.0786 60.0872 24.1281 60.0691 24.1746 60.0449L31.6285 56.1477C31.6922 56.1144 31.7594 56.0882 31.8289 56.0697L40.0321 53.8829C40.2646 53.8205 40.5119 53.8464 40.7264 53.9558C40.9408 54.0652 41.107 54.2502 41.193 54.475L47.3093 70.3901C47.3687 70.5436 47.3882 70.7096 47.3658 70.8726C47.3434 71.0356 47.2799 71.1903 47.1812 71.322L45.066 74.1625C44.9559 74.3104 44.806 74.424 44.6339 74.4901L40.0111 76.2667C39.9825 76.2777 39.9547 76.2909 39.928 76.3062L32.225 80.721C32.1816 80.7458 32.1363 80.7673 32.0897 80.7852ZM16.3612 62.201C16.2129 62.2581 16.0934 62.3717 16.0289 62.5168C15.9643 62.662 15.9601 62.8268 16.017 62.9751L23.0316 81.2277C23.0798 81.3544 23.1696 81.4608 23.2863 81.5296C23.403 81.5984 23.5397 81.6254 23.6738 81.6062L31.8231 80.4686C31.8988 80.458 31.9719 80.433 32.0383 80.3951L39.7413 75.9803C39.7847 75.9555 39.8298 75.934 39.8764 75.9161L44.4991 74.1395C44.6049 74.0989 44.6971 74.0291 44.7648 73.9381L46.88 71.0977C46.9406 71.0167 46.9797 70.9217 46.9934 70.8215C47.0072 70.7212 46.9952 70.6192 46.9587 70.5249L40.8424 54.6097C40.7896 54.4715 40.6874 54.3578 40.5556 54.2906C40.4238 54.2234 40.2718 54.2074 40.1289 54.2457L31.9257 56.4326C31.8831 56.4441 31.8418 56.4602 31.8026 56.4805L24.3486 60.3778C24.273 60.4171 24.1926 60.4465 24.1094 60.4653L16.4458 62.1755C16.417 62.1819 16.3887 62.1904 16.3612 62.201Z"
        fill="#E6E6E6"
      />
      <path
        d="M22.5049 61.7344L29.4403 79.7811L28.3506 79.9334L21.4473 61.9708L22.5049 61.7344Z"
        fill="white"
      />
      <path
        d="M24.5003 69.0181C24.7766 69.0181 25.0006 68.7941 25.0006 68.5179C25.0006 68.2416 24.7766 68.0176 24.5003 68.0176C24.224 68.0176 24 68.2416 24 68.5179C24 68.7941 24.224 69.0181 24.5003 69.0181Z"
        fill="#3F3D56"
      />
      <path
        d="M42.2366 69.5513C42.5129 69.5513 42.7369 69.3274 42.7369 69.0511C42.7369 68.7748 42.5129 68.5508 42.2366 68.5508C41.9603 68.5508 41.7363 68.7748 41.7363 69.0511C41.7363 69.3274 41.9603 69.5513 42.2366 69.5513Z"
        fill="#F7B80B"
      />
      <path
        d="M42.4392 60.0163C43.007 61.4937 42.3238 66.3898 42.0835 67.9615C42.0759 68.0113 42.0552 68.0583 42.0236 68.0975C41.992 68.1368 41.9506 68.167 41.9035 68.1851C41.8565 68.2032 41.8055 68.2085 41.7557 68.2005C41.7059 68.1925 41.6591 68.1715 41.6201 68.1395C40.3891 67.1333 36.6028 63.9549 36.035 62.4775C35.8734 62.057 35.7962 61.6087 35.8078 61.1584C35.8194 60.7081 35.9196 60.2644 36.1027 59.8528C36.2857 59.4412 36.5481 59.0696 36.8748 58.7594C37.2014 58.4492 37.586 58.2063 38.0065 58.0447C38.427 57.8831 38.8752 57.8059 39.3256 57.8175C39.7759 57.8292 40.2196 57.9294 40.6312 58.1124C41.0428 58.2955 41.4143 58.5579 41.7246 58.8845C42.0348 59.2112 42.2776 59.5958 42.4392 60.0163V60.0163Z"
        fill="#F7B80B"
      />
      <path
        d="M39.2371 63.105C40.2633 63.105 41.0952 62.2731 41.0952 61.2468C41.0952 60.2206 40.2633 59.3887 39.2371 59.3887C38.2108 59.3887 37.3789 60.2206 37.3789 61.2468C37.3789 62.2731 38.2108 63.105 39.2371 63.105Z"
        fill="white"
      />
      <path
        d="M36.8519 57.2368C36.9271 56.9608 37.0619 56.7046 37.2468 56.4864C37.4317 56.2681 37.6622 56.093 37.9221 55.9735C38.182 55.8539 38.465 55.7928 38.751 55.7945C39.0371 55.7961 39.3193 55.8604 39.5778 55.9829L42.895 52.9844L45.2089 54.5558L40.4649 58.7245C40.2303 59.1452 39.8496 59.4651 39.3948 59.6238C38.9401 59.7825 38.4429 59.7688 37.9976 59.5855C37.5522 59.4021 37.1896 59.0617 36.9785 58.6288C36.7673 58.196 36.7223 57.7007 36.8519 57.2368Z"
        fill="#FFB8B8"
      />
      <path
        d="M42.1959 58.2151L39.5957 54.6862L45.5237 50.8923L41.2749 44.8296C40.9277 44.3342 40.7755 43.7282 40.8474 43.1276C40.9193 42.527 41.2103 41.974 41.6645 41.5746C42.1188 41.1752 42.7045 40.9574 43.3093 40.963C43.9142 40.9686 44.4957 41.1971 44.9426 41.6048L50.2625 46.4585C50.7693 46.9209 51.1624 47.494 51.4114 48.1331C51.6604 48.7723 51.7584 49.4604 51.6979 50.1436C51.6374 50.8269 51.42 51.487 51.0625 52.0725C50.7051 52.658 50.2173 53.1531 49.6372 53.5191L42.1959 58.2151Z"
        fill="#2F2E41"
      />
      <path
        d="M21.8238 67.4737C22.0588 67.3107 22.2553 67.0981 22.3993 66.8509C22.5433 66.6037 22.6313 66.328 22.6572 66.0431C22.6831 65.7582 22.6463 65.4711 22.5492 65.202C22.4521 64.9329 22.2973 64.6883 22.0955 64.4856L23.8143 60.3575L21.5605 58.7012L19.215 64.5648C18.8968 64.9264 18.7224 65.3921 18.7246 65.8738C18.7269 66.3554 18.9058 66.8195 19.2273 67.178C19.5489 67.5366 19.9908 67.7647 20.4694 67.8192C20.9479 67.8737 21.4298 67.7508 21.8238 67.4737V67.4737Z"
        fill="#FFB8B8"
      />
      <path
        d="M22.985 63.2836L18.9199 62.3369L21.9492 53.6017C22.4712 52.0945 23.2413 50.6851 24.2278 49.4316L29.1698 43.1472C29.6218 42.5724 30.2831 42.2001 31.009 42.112C31.735 42.0238 32.4662 42.2269 33.0426 42.6769C33.6191 43.1268 33.9937 43.7869 34.0844 44.5125C34.1751 45.2381 33.9745 45.9701 33.5266 46.5481L28.0428 53.6257L22.985 63.2836Z"
        fill="#2F2E41"
      />
      <path
        d="M72.5242 100.001H0.974903C0.925097 100.001 0.877328 99.9808 0.84211 99.9456C0.806892 99.9104 0.787109 99.8626 0.787109 99.8128C0.787109 99.763 0.806892 99.7152 0.84211 99.68C0.877328 99.6448 0.925097 99.625 0.974903 99.625H72.5242C72.574 99.625 72.6218 99.6448 72.657 99.68C72.6922 99.7152 72.712 99.763 72.712 99.8128C72.712 99.8626 72.6922 99.9104 72.657 99.9456C72.6218 99.9808 72.574 100.001 72.5242 100.001Z"
        fill="#CCCCCC"
      />
      <path
        d="M100.278 99.9061H98.5879V0.84507C98.5879 0.620944 98.6769 0.405998 98.8354 0.247516C98.9939 0.0890347 99.2088 0 99.433 0C99.6571 0 99.872 0.0890347 100.031 0.247516C100.189 0.405998 100.278 0.620944 100.278 0.84507V99.9061Z"
        fill="#E6E6E6"
      />
      <path
        d="M112.524 100.001H86.2327C86.1829 100.001 86.1351 99.9808 86.0999 99.9456C86.0647 99.9104 86.0449 99.8626 86.0449 99.8128C86.0449 99.763 86.0647 99.7152 86.0999 99.68C86.1351 99.6448 86.1829 99.625 86.2327 99.625H112.524C112.574 99.625 112.621 99.6448 112.657 99.68C112.692 99.7152 112.712 99.763 112.712 99.8128C112.712 99.8626 112.692 99.9104 112.657 99.9456C112.621 99.9808 112.574 100.001 112.524 100.001Z"
        fill="#CCCCCC"
      />
      <path
        d="M112.005 12.9572H87.4134C87.1894 12.9569 86.9746 12.8678 86.8161 12.7094C86.6577 12.5509 86.5686 12.3361 86.5684 12.1121V4.97593C86.5686 4.75188 86.6577 4.53708 86.8161 4.37865C86.9746 4.22023 87.1894 4.13111 87.4134 4.13086H112.007C112.151 4.13096 112.293 4.16785 112.419 4.23805L118.778 7.78715C118.909 7.86029 119.018 7.96704 119.095 8.09641C119.171 8.22577 119.211 8.37309 119.211 8.5232C119.212 8.67331 119.172 8.8208 119.096 8.9505C119.021 9.08021 118.912 9.18743 118.781 9.26116L112.42 12.8482C112.293 12.9195 112.15 12.957 112.005 12.9572Z"
        fill="#F7B80B"
      />
      <path
        d="M109.761 28.3575H85.1693C85.0239 28.3574 84.881 28.3199 84.7543 28.2486L78.3929 24.6616C78.2621 24.5878 78.1534 24.4806 78.0778 24.3509C78.0022 24.2212 77.9626 24.0737 77.9629 23.9236C77.9632 23.7735 78.0035 23.6262 78.0797 23.4968C78.1558 23.3674 78.2651 23.2607 78.3962 23.1875L84.7557 19.6384C84.8816 19.5682 85.0234 19.5313 85.1675 19.5312H109.761C109.985 19.5315 110.2 19.6206 110.358 19.779C110.517 19.9375 110.606 20.1523 110.606 20.3763V27.5125C110.606 27.7365 110.517 27.9513 110.358 28.1097C110.2 28.2682 109.985 28.3573 109.761 28.3575Z"
        fill="#E6E6E6"
      />
      <path
        d="M109.855 43.4747H85.2631C85.1177 43.4746 84.9748 43.4371 84.8481 43.3658L78.4867 39.7787C78.3559 39.705 78.2471 39.5978 78.1715 39.4681C78.096 39.3384 78.0563 39.1909 78.0566 39.0408C78.057 38.8907 78.0973 38.7433 78.1734 38.614C78.2496 38.4846 78.3588 38.3779 78.4899 38.3047L84.8495 34.7556C84.9754 34.6854 85.1171 34.6485 85.2613 34.6484H109.855C110.079 34.6487 110.293 34.7378 110.452 34.8962C110.61 35.0547 110.699 35.2695 110.7 35.4935V42.6297C110.699 42.8537 110.61 43.0685 110.452 43.2269C110.293 43.3854 110.079 43.4745 109.855 43.4747Z"
        fill="#E6E6E6"
      />
    </g>
    <defs>
      <clipPath id="clip0_1454_52373">
        <rect
          width="118.425"
          height="100"
          fill="white"
          transform="translate(0.787109)"
        />
      </clipPath>
    </defs>
  </svg>
);
