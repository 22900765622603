import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { computePriceMultiple } from "../apollo/calendar/mutations";
import { useOnboardingContext } from "../context/OnboardingContext";
import { useOptionContext } from "../context/OptionsContext";
import {
  PersonInfo,
  PersonnelType,
  useUserContext,
} from "../context/UserContext";
import {
  parseOperationInfo,
  parseOptions,
  parsePersonnel,
} from "../utils/parseUserData";
import { isMissingHours } from "../utils/utils";
import { useSessionContext } from "../context/SessionContext";

const useServerSubmission = () => {
  const [multipleData, { data: multipleRequestData, loading }] =
    useMutation(computePriceMultiple);
  const { userInfos, setUserInfos } = useUserContext();
  const { optionInfo } = useOptionContext();
  const { getAllInfo } = useOnboardingContext();
  const { sessionInfo } = useSessionContext();
  const handleSendDataToBackend = (personelModule: {
    persons: {
      [key in PersonnelType]?: PersonInfo;
    };
  }) => {
    if (!personelModule || !userInfos) return;
    if (!optionInfo) return;
    const options = parseOptions(optionInfo, getAllInfo());
    const personnelInfo = parsePersonnel(
      userInfos.selectDePersonnelInfo ?? { persons: {} },
      userInfos.calendarInfo ?? { days: [], period: null }
    );
    const operationInfo = parseOperationInfo(
      userInfos,
      getAllInfo(),
      sessionInfo
    );
    setUserInfos({
      ...userInfos,
      cache: {
        ...userInfos?.cache,
        personelModule,
      },
    }); // TODO remove this, why is it even a thing??
    multipleData({
      variables: {
        input: personnelInfo,
        options,
        outfit: userInfos?.outfitInfos,
        operationInput: operationInfo,
        discountCode: userInfos?.discountCode,
      },
    });
  };

  useEffect(() => {
    if (
      (userInfos?.calendarInfo?.days || userInfos?.calendarInfo?.period) &&
      !isMissingHours(userInfos?.calendarInfo?.eventSchedule)
    ) {
      handleSendDataToBackend({
        persons: userInfos?.selectDePersonnelInfo?.persons ?? {},
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userInfos?.selectDePersonnelInfo,
    optionInfo,
    userInfos?.calendarInfo,
    userInfos?.outfitInfos,
    userInfos?.discountCode,
  ]);

  useEffect(() => {
    if (!multipleRequestData) return;
    setUserInfos({
      ...userInfos,
      cache: {
        ...userInfos?.cache,
        multipleRequestData,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleRequestData]);

  return {
    multipleRequestData,
    loading,
  };
};

export default useServerSubmission;
