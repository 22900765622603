export const RolePlacementIcon = () => (
  <svg
    width="181"
    height="120"
    viewBox="0 0 181 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2336_63484)">
      <path
        d="M87.8361 60.9262C88.92 59.5532 90.507 58.9995 91.3808 59.6894C92.2545 60.3794 92.0839 62.0515 90.9994 63.4249C90.5737 63.9794 90.0214 64.424 89.3887 64.7213L84.7226 70.4809L82.0664 68.2337L86.9484 62.7943C87.0908 62.1096 87.3952 61.4691 87.8361 60.9262H87.8361V60.9262V60.9262Z"
        fill="#FFB6B6"
      />
      <path
        d="M70.6413 58.4613L77.4495 70.0314L85.6915 64.338L89.0068 65.6604C89.0068 65.6604 83.1498 78.4085 78.0995 77.5793C73.0493 76.7501 63.0004 65.3428 64.1539 61.3592C65.3074 57.3756 70.6413 58.4613 70.6413 58.4613H70.6413V58.4613V58.4613Z"
        fill="#F7B80B"
      />
      <path
        d="M91.1637 54.896L87.3594 56.0195L89.9808 64.8962L93.7851 63.7727L91.1637 54.896Z"
        fill="#F7B80B"
      />
      <path
        d="M69.7874 58.7495L69.8687 53.3239L66.5229 51.2383L62.8848 55.6885L69.7874 58.7495Z"
        fill="#FFB6B6"
      />
      <path
        opacity="0.1"
        d="M69.7879 58.7495L70.0044 53.4591L66.5234 51.2383L67.7061 57.7854L69.7879 58.7495Z"
        fill="black"
      />
      <path
        d="M67.9953 53.8656C71.2816 53.8656 73.9457 51.2015 73.9457 47.9152C73.9457 44.6289 71.2816 41.9648 67.9953 41.9648C64.709 41.9648 62.0449 44.6289 62.0449 47.9152C62.0449 51.2015 64.709 53.8656 67.9953 53.8656Z"
        fill="#FFB6B6"
      />
      <path
        d="M77.9127 94.6273L76.8644 101.754L76.3374 105.343L74.1424 120.277C71.3733 120.19 68.64 120.065 65.9483 119.909L66.3393 110.815L66.5755 105.343L66.7303 101.754L66.8267 99.4951L65.4157 94.106C65.4157 94.106 64.951 93.2428 64.4788 92.0924C64.3957 91.8884 64.3106 91.675 64.2294 91.4559C63.6439 89.8899 63.1792 88.0256 63.7629 87.0509C63.7912 87.0056 63.8195 86.9621 63.8516 86.9206C64.1161 86.5787 64.8018 86.3237 65.733 86.1348C66.3016 86.0177 66.9646 85.927 67.6786 85.8571C69.8395 85.6437 72.4783 85.6116 74.4976 85.6323C76.3241 85.6474 77.6426 85.7041 77.6426 85.7041L77.6615 86.3067L77.6634 86.3652L77.6766 86.8224L77.7087 87.9141L77.7899 90.6115L77.873 93.3523L77.9127 94.6273Z"
        fill="#1A202C"
      />
      <path
        d="M77.8739 93.3512L74.9329 99.2503L73.0723 101.753L70.4071 105.342L66.3402 110.814L59.8915 119.494C56.7899 119.252 53.7487 118.963 50.7812 118.634L58.675 105.342L60.8057 101.753L62.8627 98.2907L64.1699 92.8752C64.1699 92.8752 64.1699 92.5862 64.1906 92.1234C64.2001 91.9288 64.2133 91.7022 64.2303 91.4547C64.3455 89.8019 64.695 87.1669 65.7339 86.1337C65.8737 85.992 66.0267 85.8824 66.1929 85.8069C66.5046 85.6671 67.024 85.7011 67.6795 85.856C69.3002 86.2432 71.7482 87.3841 73.9469 88.5175C75.4674 89.3033 76.8671 90.0834 77.7908 90.6104L77.8739 93.3512Z"
        fill="#1A202C"
      />
      <path
        d="M63.6505 54.541L70.4103 57.7857C70.4103 57.7857 77.5757 79.0115 77.5757 80.0931C77.5757 81.1746 79.6036 81.8506 78.3869 83.0674C77.1701 84.2842 80.6516 85.3146 78.7757 86.2865C76.8997 87.2585 65.1973 92.5748 63.896 92.0953C60.3071 90.7731 64.0481 85.9145 64.0481 85.9145C64.0481 85.9145 61.3522 83.7434 61.7577 82.6618C62.1633 81.5802 59.3242 60.8952 59.3242 60.8952L63.6505 54.541L63.6505 54.541V54.541V54.541Z"
        fill="#F7B80B"
      />
      <path
        d="M68.4957 49.2987L69.9892 50.1343C69.9892 50.1343 69.0732 47.4254 69.7417 46.2307C70.4103 45.0359 70.0206 44.1903 70.0206 44.1903C70.0206 44.1903 72.4051 44.9755 73.0331 43.9934C73.6611 43.0113 73.8688 42.4999 71.8234 41.6692C69.7781 40.8385 70.5476 41.426 70.5476 41.426C70.5476 41.426 68.8009 40.7624 69.1857 41.0561C69.5704 41.3499 67.7377 40.5597 67.9959 40.9395C68.254 41.3193 66.8466 40.6102 67.1048 40.99C67.3629 41.3697 63.844 41.9109 64.568 42.1591C65.292 42.4073 61.601 42.6953 61.7119 45.5812C61.8227 48.4672 59.8128 49.2858 61.296 50.1246C62.7792 50.9634 64.8494 54.5535 64.8494 54.5535L66.5952 53.3667C66.5952 53.3667 66.0706 48.7261 68.4957 49.2987H68.4957V49.2987V49.2987Z"
        fill="#1A202C"
      />
      <path
        d="M55.8206 80.2188C57.5555 79.994 58.8457 78.9167 58.7025 77.8127C58.5593 76.7087 57.0371 75.9962 55.3016 76.2212C54.6072 76.3016 53.9421 76.5471 53.362 76.9372L46.0234 77.9823L46.5872 81.4156L53.7619 80.0208C54.4225 80.2503 55.1284 80.3182 55.8206 80.2188V80.2188Z"
        fill="#A0616A"
      />
      <path
        d="M33.3861 65.1534L35.399 80.1378C35.399 80.1378 35.9527 83.3605 40.9926 82.886C46.0324 82.4115 52.6557 80.4569 52.6557 80.4569L52.2247 76.9578L46.5955 77.5676C46.5955 77.5676 43.9678 76.9301 43.4461 77.3601C42.9245 77.79 43.1641 78.2933 42.6482 77.6673C42.1323 77.0413 42.3913 76.6526 41.9396 77.2001C41.4879 77.7475 41.3962 78.6991 41.1257 77.5205C40.8551 76.3419 40.3518 76.5815 40.3518 76.5815L40.0654 65.6052C40.0654 65.6052 38.5533 60.3693 35.7915 62.1199C33.0296 63.8705 33.3861 65.1534 33.3861 65.1534H33.3861V65.1534H33.3861Z"
        fill="#E2E8F0"
      />
      <path
        d="M42.2073 53.1973H32.2383V67.9275H42.2073V53.1973Z"
        fill="#1A202C"
      />
      <path
        d="M34.4766 63.4377L34.8257 59.2188H39.4677L40.4967 63.6887L34.4766 63.4377Z"
        fill="#A0616A"
      />
      <path
        opacity="0.1"
        d="M34.4766 63.4377L34.8257 59.2188H39.4677L40.4967 63.6887L34.4766 63.4377Z"
        fill="black"
      />
      <path
        d="M42.8866 56.2986C43.3355 53.5324 41.457 50.9261 38.6908 50.4772C35.9246 50.0283 33.3183 51.9069 32.8694 54.673C32.4205 57.4392 34.2991 60.0455 37.0652 60.4944C39.8314 60.9433 42.4377 59.0648 42.8866 56.2986Z"
        fill="#A0616A"
      />
      <path
        d="M39.1582 50.1486C39.1582 50.1486 39.1582 48.0656 37.2239 48.5119C35.2896 48.9583 34.6945 49.7022 34.6945 49.7022C34.6945 49.7022 33.9505 50.8926 32.7602 51.0414C31.5698 51.1902 30.5283 54.6123 30.6771 55.3563C30.8259 56.1002 31.5698 58.0345 30.5283 58.9273C29.4868 59.82 27.9989 61.1591 28.4452 62.4982C28.8916 63.8374 30.2307 67.2595 30.2307 67.2595C30.2307 67.2595 26.4957 67.8055 26.8009 70.1364C26.8009 70.1364 28.594 68.7475 30.9747 68.7475C33.3553 68.7475 33.0578 64.4284 33.0578 64.4284C33.0578 64.4284 33.2065 61.7543 33.6529 61.0103C34.0993 60.2664 33.5041 56.1002 35.736 53.8684C37.9679 51.6365 39.1582 50.1486 39.1582 50.1486H39.1582Z"
        fill="#1A202C"
      />
      <path
        d="M38.5623 49.7012C38.5623 49.7012 41.3893 50.2963 42.4309 53.1234C43.4724 55.9504 42.9464 56.4764 42.9464 56.4764C42.9464 56.4764 43.1766 58.1787 44.4404 58.7756C45.7043 59.3726 44.6627 61.902 44.6627 61.902C44.6627 61.902 44.9603 64.4315 48.9777 65.3242C52.995 66.2169 43.9398 73.0629 43.9398 73.0629C43.9398 73.0629 44.5909 69.8318 40.4247 69.683C36.2586 69.5343 41.2405 61.902 41.2405 61.902L41.6869 56.9919C41.6869 56.9919 40.7942 51.3379 38.1159 50.5939C35.4377 49.85 38.5623 49.7012 38.5623 49.7012L38.5623 49.7012H38.5623Z"
        fill="#1A202C"
      />
      <path
        d="M45.7668 94.7622L44.7392 101.753L44.2122 105.342L43.3188 111.413L42.414 117.565C41.343 117.41 40.2852 117.25 39.2406 117.083C37.4461 116.798 35.6894 116.498 33.9705 116.181L34.3049 108.374L34.4352 105.342L34.5901 101.753L34.6808 99.628L33.2679 94.2389C33.2679 94.2389 30.3533 88.8121 31.7057 87.0554C31.7681 86.9761 31.8512 86.9005 31.9569 86.8306C32.3007 86.5983 32.8674 86.4132 33.5795 86.2696C35.2625 85.9258 37.7559 85.8012 40.0283 85.769C42.9447 85.7275 45.4948 85.8389 45.4948 85.8389L45.5099 86.2828L45.5382 87.2462L45.7668 94.7622Z"
        fill="#1A202C"
      />
      <path
        d="M45.7653 94.7625L44.7377 101.753L44.2107 105.342L43.3172 111.413L39.2391 117.084C37.4446 116.799 35.6879 116.498 33.969 116.181C32.7053 115.949 31.4643 115.707 30.2422 115.457L34.3033 108.374L36.0411 105.342L38.0963 101.753L39.0842 100.031L39.9436 98.5309L40.5726 95.7353L41.0562 93.5914L41.1677 93.0965C41.1677 93.0965 41.1563 92.3182 41.23 91.2737C41.3433 89.6908 41.655 87.4921 42.5126 86.4588C42.6788 86.2548 42.8677 86.098 43.0811 85.9979C43.5269 85.7883 44.4071 85.9318 45.5084 86.2832L45.5367 87.2465L45.7653 94.7625L45.7653 94.7625Z"
        fill="#1A202C"
      />
      <path
        d="M40.8302 63.4646L40.7064 63.4296L34.6112 61.707L29.7441 68.3316L32.9888 82.1216L31.7721 87.6647C31.7721 87.6647 41.6414 85.0959 45.5621 87.2591C45.5621 87.2591 45.8325 86.0263 45.5621 85.4259C45.2917 84.8255 44.8861 82.3271 45.2917 81.2104C45.6973 80.0937 44.4805 74.821 44.4805 74.821C44.4805 74.821 44.8861 71.3059 43.1286 69.5484C41.371 67.7908 40.8302 63.4646 40.8302 63.4646L40.8302 63.4646V63.4646Z"
        fill="#E2E8F0"
      />
      <path
        d="M39.1394 100.25C39.5764 101.944 40.8047 103.092 41.8826 102.813C42.9606 102.535 43.4798 100.936 43.0424 99.242C42.8769 98.5627 42.5512 97.933 42.0925 97.4055L40.15 90.252L36.8125 91.2351L39.0818 98.1828C38.9356 98.8666 38.9553 99.5756 39.1394 100.25V100.25H39.1394Z"
        fill="#A0616A"
      />
      <path
        d="M40.0226 69.5532C40.0226 69.5532 38.1322 64.4447 36.0604 64.7773C33.9885 65.1099 32.0996 68.321 32.0996 68.321L33.5974 86.4936L37.7113 96.864L42.0751 95.1436L39.8085 84.7796L40.0226 69.5532V69.5532V69.5532Z"
        fill="#E2E8F0"
      />
      <path
        opacity="0.7"
        d="M134.54 38.0039V116.324C134.478 116.337 134.415 116.348 134.351 116.358C133.393 116.532 132.422 116.698 131.44 116.86C130.273 117.053 129.089 117.24 127.889 117.419C126.892 117.567 125.885 117.71 124.867 117.848C121.903 118.254 118.856 118.615 115.736 118.929C114.737 119.031 113.728 119.127 112.714 119.218C103.845 120.015 94.4194 120.445 84.6462 120.445C83.2616 120.445 81.8865 120.436 80.517 120.419C80.4528 120.419 80.3905 120.419 80.3281 120.417V38.0039H134.54Z"
        fill="#EDF2F7"
      />
      <path
        d="M134.35 63.8418V64.0741L134.375 64.025C134.373 63.9646 134.364 63.9041 134.35 63.8418ZM80.3276 88.5185L80.1387 88.3655V105.343H80.3276V120.417C80.3899 120.418 80.4522 120.418 80.5165 120.418V77.0679C80.4541 77.1019 80.3918 77.134 80.3276 77.1662V88.5185ZM80.1387 37.8145V120.413C80.201 120.415 80.2633 120.417 80.3276 120.417C80.3899 120.418 80.4522 120.418 80.5165 120.418V38.1922H134.35V116.357C134.415 116.348 134.477 116.337 134.539 116.323C134.602 116.314 134.666 116.303 134.728 116.289V37.8145H80.1387Z"
        fill="#2D3748"
      />
      <path
        d="M84.9542 97.2208C86.9363 97.2208 88.5431 95.614 88.5431 93.6319C88.5431 91.6498 86.9363 90.043 84.9542 90.043C82.9721 90.043 81.3652 91.6498 81.3652 93.6319C81.3652 95.614 82.9721 97.2208 84.9542 97.2208Z"
        fill="#2D3748"
      />
      <path
        d="M84.4902 92.6379C85.7786 93.8213 87.4334 94.1158 88.1864 93.2957C88.9394 92.4757 88.5053 90.852 87.2163 89.6683C86.708 89.1885 86.092 88.8373 85.4201 88.6443L79.8982 83.6992L77.6328 86.34L83.317 90.9346C83.5664 91.5879 83.9688 92.172 84.4903 92.6379H84.4902V92.6379Z"
        fill="#FFB6B6"
      />
      <path
        d="M68.5299 66.4009C68.5299 66.4009 64.1643 63.1433 62.6069 64.5496C61.0495 65.956 61.2119 69.6779 61.2119 69.6779L72.3561 84.1103L81.4503 90.5729L84.1756 86.755L76.6348 79.2927L68.5299 66.4009L68.5299 66.4009V66.4009Z"
        fill="#F7B80B"
      />
      <path
        d="M115.735 98.9199V118.927C114.736 119.029 113.727 119.126 112.713 119.216V98.9199H115.735Z"
        fill="#2D3748"
      />
      <path
        d="M30.534 98.9199V108.006C29.4781 107.539 28.4694 107.059 27.5117 106.57V98.9199H30.534Z"
        fill="#2D3748"
      />
      <path d="M114.804 101.312H28.707V104.9H114.804V101.312Z" fill="#F7B80B" />
      <path
        d="M139.003 48.709C139.003 48.709 136.989 49.8906 137.649 51.0591C138.31 52.2277 139.231 53.8194 138.606 54.3206C137.981 54.8219 136.95 57.0321 136.95 57.0321C136.95 57.0321 132.546 56.417 132.959 59.838C133.372 63.259 135.441 63.7254 133.668 65.2304C131.895 66.7354 135.511 68.5354 135.511 68.5354C135.511 68.5354 138.008 67.7574 138.577 65.3649C139.147 62.9725 138.07 67.1942 138.431 67.4969C138.792 67.7997 140.195 69.7949 141.188 67.1421C142.181 64.4892 141.198 63.4607 142.509 61.895C143.821 60.3293 144.713 57.7969 144.674 57.3542C144.635 56.9115 146.174 52.872 143.805 51.2966C141.435 49.7212 139.003 48.709 139.003 48.709H139.003Z"
        fill="#1A202C"
      />
      <path
        d="M121.9 86.0166C120.89 87.1547 119.484 87.556 118.759 86.9131C118.035 86.2701 118.267 84.8266 119.278 83.6882C119.675 83.228 120.177 82.8696 120.741 82.6431L125.082 77.875L127.276 79.9596L122.764 84.4388C122.606 85.0262 122.31 85.5672 121.9 86.0166H121.9Z"
        fill="#FFB6B6"
      />
      <path
        d="M134.593 63.5822C134.593 63.5822 137.226 61.1639 138.336 61.978C139.447 62.7922 139.59 65.2329 139.59 65.2329L133.278 75.3993L124.175 83.0721L122.141 80.7617L130.163 72.54L134.593 63.5822V63.5822V63.5822Z"
        fill="#2D3748"
      />
      <path
        d="M139.928 58.1025L138.502 63.1714H146.329L143.752 56.9258L139.928 58.1025Z"
        fill="#FFB6B6"
      />
      <path
        opacity="0.1"
        d="M139.928 58.1025L138.502 63.1714H146.329L143.752 56.9258L139.928 58.1025Z"
        fill="black"
      />
      <path
        d="M143.897 74.8301L148.487 85.6722L132.955 84.9052L134.011 74.8301H143.897Z"
        fill="#FFB6B6"
      />
      <path
        d="M139.408 60.5765L145.884 60.3066L150.202 67.8624L146.424 80.0056L133.201 81.3548L134.633 69.6438L135.36 63.0051L139.408 60.5765Z"
        fill="#2D3748"
      />
      <path
        d="M150.202 95.8429C149.891 97.3521 149.552 99.4205 149.222 101.663C149.05 102.83 148.88 104.045 148.718 105.252C148.298 108.347 147.924 111.394 147.677 113.468C145.622 114.001 143.485 114.509 141.273 114.989L140.131 105.252L139.709 101.663L139.273 97.949L139.077 101.663L138.886 105.252L138.338 115.601C137.154 115.839 135.95 116.067 134.728 116.288C134.666 116.302 134.602 116.313 134.539 116.322C134.477 116.336 134.415 116.347 134.35 116.356C133.393 116.53 132.422 116.696 131.44 116.859L131.277 105.252L131.226 101.663L131.043 88.6404L132.639 83.3666L133.121 83.3231L134.35 83.2117L134.539 83.1947L134.728 83.1758L146.961 82.0651L147.369 82.0273C147.369 82.0273 147.66 82.5959 148.06 83.5423C148.094 83.6216 148.13 83.7047 148.166 83.7897C148.512 84.6208 148.923 85.6994 149.299 86.9177C149.796 88.5195 150.232 90.3631 150.378 92.1972C150.476 93.442 150.442 94.6812 150.202 95.8429Z"
        fill="#1A202C"
      />
      <path
        d="M150.679 90.9205C150.565 92.4379 149.69 93.6092 148.724 93.5366C147.759 93.464 147.068 92.1752 147.182 90.6573C147.219 90.0503 147.402 89.4611 147.713 88.9389L148.276 82.5156L151.285 82.8442L150.41 89.1415C150.641 89.7046 150.733 90.3146 150.679 90.9205Z"
        fill="#FFB6B6"
      />
      <path
        d="M147.18 65.3826C147.18 65.3826 147.808 61.8636 149.185 61.8382C150.562 61.8127 152.154 63.6678 152.154 63.6678L153.295 75.5796L150.703 87.1996L147.685 86.5945L149.084 75.1929L147.18 65.3826L147.18 65.3826V65.3826Z"
        fill="#2D3748"
      />
      <path
        d="M141.788 59.2045C144.391 59.2045 146.501 57.0942 146.501 54.4909C146.501 51.8877 144.391 49.7773 141.788 49.7773C139.185 49.7773 137.074 51.8877 137.074 54.4909C137.074 57.0942 139.185 59.2045 141.788 59.2045Z"
        fill="#FFB6B6"
      />
      <path
        d="M139.981 49.2264C139.981 49.2264 139.195 51.4261 140.452 51.8975C141.709 52.3688 143.437 52.9973 143.28 53.7829C143.123 54.5685 143.752 56.9253 143.752 56.9253C143.752 56.9253 139.981 59.2821 142.495 61.6389C145.009 63.9957 146.894 63.0245 146.501 65.317C146.108 67.6095 150.036 66.6667 150.036 66.6667C150.036 66.6667 151.451 64.4671 150.351 62.2674C149.251 60.0677 151.136 63.9957 151.608 63.9957C152.079 63.9957 154.436 64.6242 153.493 61.9531C152.55 59.2821 151.136 59.125 151.136 57.0824C151.136 55.0399 150.194 52.526 149.879 52.2117C149.565 51.8975 148.151 47.8124 145.323 48.1266C142.495 48.4408 139.981 49.2264 139.981 49.2264H139.981V49.2264V49.2264Z"
        fill="#1A202C"
      />
      <path
        d="M118.875 79.4703L115.07 80.5938L117.692 89.4704L121.496 88.3469L118.875 79.4703Z"
        fill="#F7B80B"
      />
      <path
        d="M56.5426 71.9156L52.7383 73.0391L55.3597 81.9157L59.164 80.7923L56.5426 71.9156Z"
        fill="#F7B80B"
      />
      <path
        d="M127.887 98.8301V117.419C126.89 117.566 125.883 117.71 124.865 117.848V98.8301H127.887Z"
        fill="#2D3748"
      />
      <path
        d="M165.537 101.664H126.943V105.253H165.537V101.664Z"
        fill="#F7B80B"
      />
      <path
        d="M68.2964 39.2432C70.8084 39.2432 72.8448 37.2068 72.8448 34.6948C72.8448 32.1829 70.8084 30.1465 68.2964 30.1465C65.7844 30.1465 63.748 32.1829 63.748 34.6948C63.748 37.2068 65.7844 39.2432 68.2964 39.2432Z"
        fill="#E2E8F0"
      />
      <path
        d="M69.5405 35.8548C69.2968 35.8422 69.0532 35.8366 68.8095 35.8347C68.9712 34.9249 69.1329 34.015 69.2946 33.1052C69.3318 32.8963 69.27 32.6845 69.0776 32.572C68.8941 32.4646 68.6571 32.5007 68.5053 32.6458C68.0563 33.0752 67.6073 33.5046 67.1583 33.934C66.974 34.1103 66.9808 34.4243 67.1583 34.6019C67.3425 34.786 67.6413 34.7787 67.8261 34.6019C67.9254 34.5069 68.0246 34.412 68.1239 34.3171L67.8504 35.8555C67.5841 35.8688 67.318 35.8861 67.0523 35.9122C66.7985 35.9371 66.5801 36.1114 66.5801 36.3844C66.5801 36.6451 66.7963 36.851 67.0523 36.8567C67.4447 36.8654 67.837 36.8741 68.2294 36.8829C68.3271 36.885 68.4346 36.8456 68.5213 36.7804C68.861 36.7772 69.2008 36.7817 69.5405 36.7993C69.7954 36.8125 70.0127 36.5728 70.0127 36.3271C70.0127 36.0603 69.7962 35.8681 69.5405 35.8548V35.8548Z"
        fill="white"
      />
      <path
        d="M104.98 33.878C107.492 33.878 109.528 31.8416 109.528 29.3296C109.528 26.8176 107.492 24.7812 104.98 24.7812C102.468 24.7812 100.432 26.8176 100.432 29.3296C100.432 31.8416 102.468 33.878 104.98 33.878Z"
        fill="#F7B80B"
      />
      <path
        d="M106.224 30.4876C105.98 30.475 105.737 30.4694 105.493 30.4675C105.655 29.5577 105.816 28.6479 105.978 27.738C106.015 27.5291 105.954 27.3173 105.761 27.2048C105.578 27.0974 105.341 27.1335 105.189 27.2786C104.74 27.708 104.291 28.1374 103.842 28.5668C103.658 28.7431 103.664 29.0572 103.842 29.2347C104.026 29.4188 104.325 29.4115 104.51 29.2347C104.609 29.1398 104.708 29.0449 104.807 28.9499L104.534 30.4883C104.268 30.5016 104.002 30.5189 103.736 30.545C103.482 30.57 103.264 30.7442 103.264 31.0172C103.264 31.2779 103.48 31.4838 103.736 31.4895C104.128 31.4982 104.521 31.5069 104.913 31.5157C105.011 31.5178 105.118 31.4784 105.205 31.4132C105.545 31.41 105.884 31.4145 106.224 31.4321C106.479 31.4453 106.696 31.2056 106.696 30.9599C106.696 30.6931 106.48 30.5009 106.224 30.4876L106.224 30.4876Z"
        fill="white"
      />
      <path
        d="M38.1187 46.4092C40.6307 46.4092 42.667 44.3728 42.667 41.8609C42.667 39.3489 40.6307 37.3125 38.1187 37.3125C35.6067 37.3125 33.5703 39.3489 33.5703 41.8609C33.5703 44.3728 35.6067 46.4092 38.1187 46.4092Z"
        fill="#E2E8F0"
      />
      <path
        d="M39.5012 43.151C39.1101 42.9489 38.6832 42.8412 38.2545 42.8261C38.5888 42.2878 38.8193 41.6833 38.9099 41.06C38.9628 40.6879 38.9685 40.3158 38.7059 40.0154C38.5057 39.785 38.2167 39.6962 37.9239 39.6679C37.6859 39.6452 37.4026 39.6603 37.1778 39.7359C36.783 39.8643 36.6527 40.2043 36.6621 40.5915C36.6716 40.9599 36.7282 41.3339 36.9757 41.6229C37.1457 41.8212 37.3931 41.9516 37.6519 41.9723C37.4139 42.4635 37.072 42.8979 36.6394 43.2625C36.46 43.4117 36.4657 43.712 36.6017 43.882C36.749 44.0671 37.0002 44.12 37.2118 44.0029C37.7671 43.6931 38.4622 43.6761 39.0251 43.967C39.2518 44.0841 39.537 44.0256 39.6711 43.797C39.7958 43.5855 39.7297 43.2681 39.5012 43.151V43.151ZM38.0014 40.7332C38.0014 40.7634 37.9995 40.7918 37.9957 40.822C37.9938 40.839 37.9919 40.8541 37.9919 40.8635C37.99 40.873 37.99 40.8805 37.9881 40.8824V40.8843C37.9806 40.9315 37.9692 40.9769 37.9617 41.0222C37.8861 41.0014 37.8068 40.9995 37.7293 41.026C37.7218 41.0279 37.718 41.0279 37.7123 41.0298H37.7067C37.6916 41.0184 37.6802 41.009 37.7105 41.0279C37.6935 41.009 37.684 40.9977 37.6783 40.9939L37.6651 40.9693C37.667 40.9769 37.667 40.9826 37.667 40.9844C37.6632 40.9788 37.6576 40.9618 37.6481 40.9315C37.6387 40.8975 37.6292 40.8617 37.6217 40.8258C37.6103 40.7691 37.6085 40.6954 37.6066 40.6123C37.6141 40.6123 37.6217 40.6104 37.6236 40.6104C37.6783 40.6066 37.7312 40.6048 37.786 40.6048C37.8219 40.6048 37.8578 40.6085 37.8937 40.6104C37.9031 40.6142 37.9636 40.6274 37.9862 40.6331C37.99 40.6369 37.9957 40.6407 37.9995 40.6425C38.0032 40.6482 38.0051 40.652 38.0108 40.6577L38.0014 40.7332L38.0014 40.7332Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2336_63484">
        <rect
          width="180"
          height="120"
          fill="white"
          transform="translate(0.888672)"
        />
      </clipPath>
    </defs>
  </svg>
);
