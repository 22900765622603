export const EscapeGameIcon = () => (
  <svg width="138" height="101" viewBox="0 0 138 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1017_5532)">
      <path d="M27.9309 81.6473C27.8574 81.4226 27.3007 81.5215 27.2472 81.3205C27.194 81.1201 27.7282 80.9522 28.1379 80.3876C28.2118 80.2858 28.6774 79.6443 28.4989 79.3744C28.1604 78.863 25.8053 80.1368 25.24 79.422C25.1159 79.2651 25.0648 78.9958 24.6953 78.4945C24.5482 78.2951 24.4539 78.1989 24.3412 78.2017C24.1813 78.2058 24.124 78.4058 23.8727 78.7682C23.4957 79.3117 23.3597 79.2435 23.1114 79.6888C22.927 80.0194 22.9052 80.2306 22.7723 80.2431C22.5842 80.2607 22.5064 79.8491 22.2766 79.8331C22.042 79.8169 21.8073 80.2235 21.6909 80.5413C21.4726 81.1375 21.6078 81.5933 21.6378 82.0475C21.6704 82.5411 21.5939 83.2636 21.0576 84.191L15.999 92.2505C17.0854 90.6075 20.1692 86.1272 21.4116 84.4838C21.7703 84.0094 22.1557 83.533 22.749 83.4434C23.3202 83.3571 24.0151 83.6397 24.9851 83.6233C25.0984 83.6213 25.4137 83.612 25.4904 83.4449C25.5538 83.3067 25.3964 83.1866 25.4536 83.0567C25.5305 82.8826 25.8761 82.9573 26.347 82.8416C26.6791 82.76 26.9048 82.6253 27.0988 82.5093C27.1573 82.4744 28.0287 81.9466 27.9309 81.6473H27.9309Z" fill="#F2F2F2"/>
      <path d="M14.6815 83.5295C14.55 83.5007 14.4379 83.8025 14.3238 83.7707C14.2101 83.739 14.2818 83.4284 14.123 83.0644C14.0943 82.9987 13.9138 82.5851 13.7298 82.5949C13.3811 82.6135 13.3268 84.1369 12.8129 84.2081C12.7001 84.2237 12.5531 84.1711 12.2005 84.2079C12.0602 84.2225 11.9857 84.241 11.9546 84.2971C11.9105 84.3768 11.9921 84.4626 12.0975 84.6905C12.2555 85.0324 12.1827 85.0794 12.3297 85.3298C12.4387 85.5157 12.5361 85.5873 12.5038 85.6562C12.4582 85.7536 12.2338 85.673 12.1596 85.7812C12.084 85.8916 12.2158 86.1241 12.3382 86.2729C12.5679 86.5521 12.8306 86.6173 13.0621 86.7336C13.3138 86.86 13.6463 87.1061 13.9468 87.6369L16.4429 92.4451C15.9499 91.4378 14.6407 88.6315 14.1926 87.5476C14.0633 87.2346 13.9407 86.908 14.0679 86.591C14.1903 86.2858 14.5295 86.0262 14.8014 85.5454C14.8331 85.4892 14.9195 85.3318 14.8596 85.2459C14.8101 85.1749 14.7057 85.2175 14.6585 85.152C14.5952 85.064 14.7316 84.9159 14.8107 84.6514C14.8664 84.4649 14.8654 84.3152 14.8645 84.1866C14.8642 84.1478 14.8566 83.5678 14.6815 83.5294V83.5295L14.6815 83.5295Z" fill="#F2F2F2"/>
      <path d="M17.1719 84.7026L17.2503 83.9783L17.2985 83.9327C17.5211 83.7223 17.6604 83.4994 17.7126 83.27C17.7209 83.2335 17.7274 83.1966 17.734 83.159C17.76 83.0101 17.7924 82.8249 17.9356 82.6326C18.0157 82.5255 18.2277 82.2833 18.4463 82.3679C18.5053 82.39 18.5452 82.4283 18.5728 82.4688C18.5798 82.4618 18.5869 82.4548 18.5945 82.4472C18.6889 82.3554 18.755 82.3218 18.8187 82.2894C18.8676 82.2645 18.918 82.2388 19.0035 82.1705C19.041 82.1405 19.071 82.1136 19.0963 82.0909C19.173 82.0224 19.2712 81.9422 19.4056 81.974C19.548 82.0129 19.5969 82.1488 19.6293 82.2388C19.6872 82.3993 19.705 82.5097 19.7168 82.5829C19.7211 82.6096 19.726 82.6397 19.7297 82.6495C19.7609 82.7281 20.1524 82.7484 20.2997 82.7569C20.6308 82.7749 20.9173 82.7904 20.9862 83.0172C21.0357 83.1794 20.9326 83.3563 20.6711 83.5569C20.5897 83.6194 20.5082 83.6625 20.4373 83.6946C20.4809 83.7359 20.5157 83.792 20.5178 83.8684C20.5226 84.0493 20.3371 84.1881 19.9664 84.2817C19.8743 84.3052 19.7504 84.3363 19.5883 84.3285C19.5119 84.3247 19.445 84.313 19.388 84.3021C19.3795 84.3346 19.3639 84.3684 19.3358 84.4004C19.2533 84.4949 19.1201 84.5189 18.9392 84.4656C18.7395 84.41 18.5818 84.3343 18.4426 84.2678C18.321 84.2099 18.2162 84.1604 18.1282 84.1468C17.9646 84.124 17.812 84.225 17.6294 84.3594L17.1719 84.7026H17.1719L17.1719 84.7026Z" fill="#F2F2F2"/>
      <path d="M18.4775 80.5767L17.7569 80.6845L17.7007 80.6495C17.4407 80.4876 17.1897 80.4094 16.9545 80.417C16.9171 80.4182 16.8798 80.4214 16.8418 80.4245C16.6911 80.4371 16.5038 80.4528 16.2815 80.3631C16.1575 80.3127 15.8695 80.1691 15.8959 79.9361C15.9023 79.8735 15.9292 79.8252 15.9614 79.7882C15.9528 79.7832 15.9442 79.7781 15.935 79.7727C15.8222 79.7046 15.7729 79.6492 15.7255 79.5959C15.689 79.5549 15.6514 79.5126 15.5636 79.4473C15.5251 79.4186 15.4914 79.3964 15.463 79.3777C15.3773 79.3209 15.2748 79.2463 15.2715 79.1082C15.273 78.9605 15.3921 78.8788 15.4709 78.8246C15.6115 78.7279 15.7137 78.6826 15.7815 78.6527C15.8063 78.6418 15.8342 78.6294 15.8427 78.6233C15.9108 78.5732 15.8312 78.1893 15.8021 78.0447C15.7355 77.7199 15.6778 77.4388 15.8796 77.3146C16.024 77.2257 16.2213 77.2805 16.4817 77.4825C16.5627 77.5454 16.6251 77.6133 16.6742 77.6738C16.703 77.6211 16.7485 77.5732 16.8219 77.5518C16.9957 77.5013 17.177 77.6455 17.3615 77.9803C17.4076 78.0635 17.4692 78.1754 17.5027 78.3342C17.5184 78.4091 17.524 78.4767 17.5279 78.5347C17.5615 78.5347 17.5981 78.5412 17.6363 78.5602C17.7486 78.6161 17.8056 78.7389 17.8 78.9273C17.7968 79.1345 17.7636 79.3063 17.7346 79.4579C17.7094 79.5902 17.6881 79.7041 17.6973 79.7927C17.7167 79.9567 17.8531 80.0787 18.0295 80.2212L18.4775 80.5767H18.4775L18.4775 80.5767Z" fill="#F2F2F2"/>
      <path d="M18.5742 78.4126L18.6527 77.6882L18.7008 77.6427C18.9234 77.4323 19.0627 77.2094 19.115 76.9799C19.1233 76.9435 19.1297 76.9066 19.1363 76.869C19.1624 76.7201 19.1947 76.5349 19.3379 76.3426C19.418 76.2355 19.63 75.9933 19.8487 76.0779C19.9077 76.1 19.9475 76.1382 19.9752 76.1788C19.9821 76.1718 19.9892 76.1648 19.9968 76.1571C20.0913 76.0654 20.1574 76.0317 20.221 75.9994C20.2699 75.9744 20.3204 75.9488 20.4058 75.8805C20.4433 75.8505 20.4734 75.8235 20.4987 75.8009C20.5754 75.7324 20.6735 75.6521 20.8079 75.6839C20.9504 75.7229 20.9992 75.8588 21.0317 75.9488C21.0895 76.1093 21.1074 76.2196 21.1192 76.2928C21.1234 76.3196 21.1283 76.3497 21.1321 76.3595C21.1632 76.4381 21.5547 76.4584 21.702 76.4669C22.0331 76.4849 22.3197 76.5004 22.3886 76.7271C22.438 76.8893 22.335 77.0662 22.0735 77.2669C21.992 77.3293 21.9106 77.3724 21.8396 77.4046C21.8833 77.4459 21.918 77.502 21.9202 77.5784C21.925 77.7593 21.7394 77.8981 21.3688 77.9917C21.2766 78.0152 21.1527 78.0463 20.9906 78.0384C20.9143 78.0347 20.8474 78.023 20.7903 78.0121C20.7818 78.0445 20.7662 78.0783 20.7381 78.1104C20.6556 78.2048 20.5224 78.2288 20.3415 78.1756C20.1419 78.12 19.9841 78.0443 19.8449 77.9778C19.7233 77.9199 19.6185 77.8703 19.5305 77.8568C19.3669 77.8339 19.2143 77.9349 19.0318 78.0694L18.5742 78.4126H18.5742H18.5742Z" fill="#F2F2F2"/>
      <path d="M16.2381 92.292L16.1107 92.1661L16.113 91.9872L16.1107 92.1661L15.9336 92.1474C15.9345 92.1308 15.9333 92.0918 15.932 92.031C15.9252 91.6978 15.904 90.6836 16.0453 89.1253C16.1439 88.0375 16.3086 86.9333 16.535 85.8428C16.7618 84.7507 16.9971 83.9381 17.1862 83.2849C17.3288 82.7923 17.4728 82.3251 17.6133 81.8699C17.9884 80.654 18.3426 79.5053 18.5619 78.1951C18.611 77.9027 18.713 77.2939 18.5043 76.5753C18.3832 76.1585 18.1759 75.7662 17.8879 75.4097L18.1666 75.1846C18.4844 75.5786 18.7137 76.013 18.8482 76.4757C19.0796 77.2725 18.9685 77.9358 18.9151 78.2546C18.6919 79.5879 18.3342 80.7476 17.9554 81.9752C17.8156 82.4287 17.6721 82.8939 17.53 83.3845C17.3427 84.0316 17.1095 84.8372 16.8857 85.9153C16.6619 86.9927 16.4992 88.0834 16.4017 89.1575C16.2623 90.6961 16.2833 91.6952 16.2901 92.0235C16.2936 92.1987 16.2944 92.2354 16.2381 92.292L16.2381 92.292Z" fill="#F2F2F2"/>
      <path d="M17.1234 75.8219C17.109 75.8187 17.0946 75.8152 17.0802 75.8109C16.7862 75.7285 16.5504 75.4889 16.3791 75.0989C16.2989 74.9155 16.28 74.7225 16.2422 74.3376C16.2363 74.2782 16.2101 73.9779 16.2423 73.58C16.2632 73.3199 16.2912 73.2155 16.3619 73.1334C16.4405 73.0421 16.5464 72.9897 16.6583 72.9635C16.6613 72.929 16.6726 72.8954 16.695 72.8635C16.787 72.731 16.9411 72.7855 17.0247 72.8138C17.0668 72.8289 17.1193 72.8481 17.1783 72.8581C17.2711 72.8741 17.3265 72.8578 17.4104 72.8335C17.4906 72.8102 17.5904 72.7813 17.7283 72.7837C18.0004 72.7875 18.2019 72.9098 18.2683 72.95C18.6182 73.1602 18.7389 73.4956 18.8787 73.8839C18.9065 73.9618 18.9987 74.2431 19.0201 74.6054C19.0356 74.8668 19.0014 74.9738 18.9621 75.0503C18.8819 75.2075 18.7591 75.2805 18.4542 75.4338C18.1357 75.5946 17.9759 75.675 17.8449 75.7163C17.54 75.8121 17.3486 75.8722 17.1234 75.8219V75.8219Z" fill="#F2F2F2"/>
      <path d="M57.5716 53.8117C58.5223 53.0086 58.2699 51.1465 57.0078 49.6525C56.4553 48.9986 55.801 48.5236 55.1601 48.2636L54.4234 47.4135L44.9112 35.4948C44.9112 35.4948 39.6434 23.6383 38.0884 22.3574C36.5334 21.0765 34.7038 21.7434 34.7038 21.7434L33.2764 23.5094L42.1717 40.0641L51.2754 49.2258L52.5044 50.5072C52.6537 51.1825 53.0126 51.907 53.5651 52.5609C54.8272 54.0548 56.6209 54.6148 57.5716 53.8117Z" fill="#FFB6B6"/>
      <path d="M39.6159 24.562L41.9997 28.058L45.4219 33.1228L39.2729 35.25L33.8438 25.9341L39.6159 24.562Z" fill="#E6E6E6"/>
      <path d="M137.023 91.9697H0.976562V92.3845H137.023V91.9697Z" fill="#3F3D56"/>
      <path d="M97.29 63.1392H86.4435V59.456C86.4435 53.5313 91.2536 48.7212 97.1783 48.7212H97.29V63.1392Z" fill="#2F2E41"/>
      <path d="M88.8613 61.2107L90.6591 55.3266L95.2711 55.0957L97.2907 63.1395L88.8613 61.2107Z" fill="#A0616A"/>
      <path opacity="0.1" d="M88.8613 61.2107L90.6591 55.3266L95.2711 55.0957L97.2907 63.1395L88.8613 61.2107Z" fill="black"/>
      <path d="M93.0571 58.7743C96.0522 58.7743 98.4803 56.3462 98.4803 53.351C98.4803 50.3558 96.0522 47.9277 93.0571 47.9277C90.0619 47.9277 87.6338 50.3558 87.6338 53.351C87.6338 56.3462 90.0619 58.7743 93.0571 58.7743Z" fill="#A0616A"/>
      <path d="M81.5625 83.6641L80.127 85.0995C80.127 85.0995 72.457 79.9549 69.5153 81.8736C66.5736 83.7922 66.4455 87.5074 69.9044 89.5572C73.3634 91.6069 82.203 96.6032 82.203 96.6032C82.203 96.6032 93.9892 98.653 101.932 92.3756C109.875 86.0982 111.348 86.4185 109.939 83.0876C108.53 79.7567 105.455 80.5254 105.455 80.5254L99.562 83.9844L97.1279 83.4719L81.5625 83.6641Z" fill="#2F2E41"/>
      <path d="M81.8834 85.2656L88.0327 87.956L85.7267 93.4647L76.6309 91.2868" fill="#2F2E41"/>
      <path d="M97.6008 85.2656L89.3135 89.1089L92.5162 95.7707L101.74 91.7993" fill="#2F2E41"/>
      <path d="M89.3135 60.145L81.3066 63.6787L83.8688 77.7708L81.5629 83.6639L93.8615 85.5855L100.168 83.6286L99.6264 80.8455L100.168 70.4584L102.317 64.3192L96.5518 60.3478L89.3135 60.145Z" fill="#E6E6E6"/>
      <path d="M87.2633 88.4683L91.2347 89.6213L89.3131 94.3296L84.0605 92.3116L87.2633 88.4683Z" fill="#A0616A"/>
      <path d="M92.7831 88.4683L87.9043 90.9024V94.6201L93.413 93.977L92.7831 88.4683Z" fill="#A0616A"/>
      <path d="M85.7266 95.5145C85.7266 95.5145 94.1819 101.151 97.3846 98.8453C100.587 96.5393 102.381 93.5928 101.74 93.2085C101.1 92.8241 99.1782 92.1836 99.1782 92.1836L88.4169 94.3615L85.7266 95.5145Z" fill="#2F2E41"/>
      <path d="M74.9653 91.2866C74.9653 91.2866 70.6095 97.9483 74.7091 98.9732C78.8086 99.9981 89.1396 95.7705 89.1396 95.7705L88.4169 90.2617L86.4952 92.1834L74.9653 91.2866Z" fill="#2F2E41"/>
      <path d="M102.112 45.1762C100.914 44.0685 100.514 42.5523 101.219 41.7898C101.924 41.0273 103.467 41.3072 104.666 42.4155C105.15 42.8516 105.524 43.3975 105.754 44.0075L110.773 48.765L108.49 51.0723L103.785 46.1373C103.159 45.9551 102.585 45.6255 102.112 45.1762Z" fill="#A0616A"/>
      <path d="M100.747 59.9281L109.827 55.4969L105.13 47.4185L106.601 44.4312C106.601 44.4312 118.032 50.8033 116.894 55.44C115.757 60.0767 104.418 68.5947 100.797 67.2329C99.698 66.8195 99.0612 66.0817 98.7073 65.2546C97.8432 63.2349 98.7731 60.8915 100.747 59.9281Z" fill="#E6E6E6"/>
      <path d="M82.6748 57.7383C82.9507 56.8995 83.4952 56.1652 83.7389 55.3165C84.0624 54.1896 84.7512 56.6895 84.8184 55.5191C84.9461 53.2944 88.3414 47.5717 90.2798 46.4725C92.2182 45.3733 93.3315 45.7047 95.3063 46.737C95.3063 46.737 96.2649 46.798 97.4583 47.3872C98.6517 47.9764 99.7259 48.92 100.19 50.1674C100.558 51.1561 100.517 52.2418 100.64 53.2896C100.983 56.2086 102.619 58.9846 102.268 61.9026C102.158 62.8129 101.812 63.7544 101.068 64.2898C100.323 64.8253 99.1209 64.767 98.6309 63.992C98.4637 63.7275 98.393 63.4137 98.2497 63.1355C98.1065 62.8574 97.8476 62.598 97.5348 62.6067C97.0646 62.6197 96.8253 63.2505 97.0107 63.6828C97.1961 64.115 97.6318 64.376 98.0408 64.6081C97.0666 65.0464 95.8581 64.9099 95.0062 64.2654C94.1543 63.6209 93.6942 62.4951 93.851 61.4384C94.0719 59.9494 96.2412 60.2576 96.4967 58.7741C96.6828 57.6941 97.3361 55.8871 97.1581 54.8058C96.9802 53.7245 96.1255 55.0338 97.0258 54.409C96.0354 54.8865 93.9167 51.62 92.8798 51.2541C91.8429 50.8882 90.9637 50.096 90.4922 49.1027C89.3924 50.6969 88.425 50.264 88.0311 52.1603C87.6373 54.0567 88.2912 59.9061 89.913 60.9648C89.264 61.4077 88.4568 60.4998 88.1523 61.2241C87.9797 61.6345 87.8679 62.0834 87.5815 62.4242C87.0346 63.0751 86.0248 63.1147 85.2199 62.841C84.2218 62.5017 83.3685 61.7564 82.898 60.813C82.4275 59.8697 82.3455 58.7397 82.6748 57.7383H82.6748Z" fill="#2F2E41"/>
      <path d="M68.8017 56.4674C68.5086 54.8621 67.4427 53.7121 66.4211 53.8988C65.3995 54.0855 64.8092 55.538 65.1026 57.1439C65.211 57.787 65.4719 58.3947 65.8633 58.9164L67.1912 65.7025L70.3624 65.0115L68.7167 58.3949C68.8986 57.7684 68.9277 57.1075 68.8017 56.4674Z" fill="#A0616A"/>
      <path d="M81.9883 63.2199L73.3813 68.5118L69.0776 60.2172L65.7675 59.8579C65.7675 59.8579 65.0221 72.9238 69.5465 74.448C74.0708 75.9721 87.3384 70.9636 88.129 67.1765C88.369 66.0268 88.0874 65.0938 87.5786 64.3518C86.3365 62.54 83.8596 62.0694 81.9883 63.2199Z" fill="#E6E6E6"/>
      <path d="M128.946 73.0999L98.1152 82.8443V45.3044L128.946 35.5601V73.0999Z" fill="white"/>
      <path d="M97.9072 83.1273V45.1524L129.153 35.2769V73.2517L97.9072 83.1273ZM98.322 45.4562V82.5611L128.738 72.9479V35.8429L98.322 45.4562Z" fill="#3F3D56"/>
      <path d="M53.4453 81.4272L78.1706 89.242V59.136L53.4453 51.3213V81.4272Z" fill="white"/>
      <path d="M78.3784 89.5255L78.1085 89.44L53.2383 81.5795V51.0386L53.5083 51.124L78.3784 58.9845V89.5255ZM53.6531 81.2757L77.9636 88.9592V59.2883L53.6531 51.6048V81.2757Z" fill="#3F3D56"/>
      <path d="M105.608 47.6245C105.256 49.4612 105.898 51.4582 107.256 52.7445C108.613 54.0307 110.642 54.5651 112.457 54.1144C114.18 53.6865 115.792 51.8644 115.063 50.2454C114.543 49.0912 112.958 48.6546 111.82 49.2096C110.683 49.7646 110.05 51.0988 110.116 52.3629C110.181 53.6271 110.854 54.8023 111.751 55.695C114.092 58.0235 117.842 58.6273 119.905 61.2051C121.707 63.4561 121.652 66.9891 119.78 69.1825C117.909 71.3759 114.428 71.9867 111.922 70.5617C110.889 69.9744 110 69.0321 109.743 67.8717C109.486 66.7114 110.004 65.3504 111.089 64.8672C113.137 63.9557 115.199 66.4039 117.439 66.4397C118.723 66.4602 119.97 65.5725 120.37 64.3528C120.77 63.1331 120.292 61.6792 119.246 60.9352" fill="#F7B80B"/>
      <path d="M62.2203 73.8903C62.6166 75.0536 61.7953 76.4723 60.5891 76.7078C59.3829 76.9433 58.0881 75.9379 58.0177 74.7109C57.9472 73.484 59.1183 72.3369 60.3435 72.4328C62.3176 72.5873 63.4833 75.2892 65.4593 75.1629C66.8114 75.0765 67.6654 73.6936 68.1645 72.4341C68.7392 70.9838 69.1594 69.4724 69.4159 67.9337C69.6299 66.6501 69.7281 65.3211 69.4195 64.057C69.1108 62.7929 68.3461 61.5926 67.1866 61.002C65.4253 60.1048 63.0144 60.98 62.1733 62.7688C61.3323 64.5575 62.1425 66.9208 63.8692 67.8828C66.0286 69.0858 69.0029 68.2195 70.9648 69.7232C72.5371 70.9284 72.8074 73.1509 72.9509 75.1268C73.0425 76.3871 73.1328 77.6668 72.8748 78.9038C72.6168 80.1407 71.9597 81.3513 70.8699 81.9908C70.0444 82.4751 68.9424 82.571 68.148 82.0373C67.3536 81.5036 67.0554 80.2687 67.6731 79.5377C68.1788 78.9393 69.0994 78.844 69.8394 79.1009C70.5795 79.3579 71.1819 79.8965 71.7628 80.422" fill="#F7B80B"/>
      <path d="M42.0362 12.6635L40.3097 19.2456L33.7275 17.9508C33.7275 17.9508 36.3172 13.203 35.6698 11.0449L42.0362 12.6635H42.0362Z" fill="#FFB6B6"/>
      <path d="M35.0877 98.3429L39.0514 98.3425L40.937 83.0537L35.0869 83.0541L35.0877 98.3429Z" fill="#FFB6B6"/>
      <path d="M48.6681 98.2528C48.7915 98.4607 48.8566 99.1315 48.8566 99.3733C48.8566 100.116 48.2543 100.719 47.5113 100.719H35.2361C34.7293 100.719 34.3183 100.308 34.3183 99.8008V99.2897C34.3183 99.2897 33.7111 97.7537 34.9613 95.8606C34.9613 95.8606 36.5151 97.3429 38.837 95.0211L39.5216 93.7808L44.4778 97.4055L47.225 97.7436C47.826 97.8176 48.3589 97.7321 48.668 98.2528H48.6681Z" fill="#2F2E41"/>
      <path d="M46.0936 54.0966L40.6317 91.2638H33.7791L34.6903 70.0478L35.1699 58.8923L33.7791 53.5823C33.7791 53.5823 33.145 52.402 32.6108 50.9713C32.0087 49.3567 31.5331 47.4211 32.2391 46.5032C32.4989 46.1649 33.1743 45.9144 34.0921 45.7279C34.653 45.6134 35.3057 45.5241 36.0105 45.4548C40.0629 45.0539 45.8271 45.3043 45.8271 45.3043L45.9737 50.14L46.0549 52.8403L46.0936 54.0966H46.0936Z" fill="#2F2E41"/>
      <path d="M28.1055 96.5252L32.0271 97.1014L36.1165 82.2495L30.3285 81.3989L28.1055 96.5252Z" fill="#FFB6B6"/>
      <path d="M41.5542 98.4116C41.6461 98.6352 41.6129 99.3084 41.5778 99.5476C41.4697 100.283 40.7862 100.791 40.0511 100.683L27.9065 98.8974C27.405 98.8237 27.0582 98.3574 27.132 97.8559L27.2063 97.3503C27.2063 97.3503 26.8289 95.7423 28.3412 94.0511C28.3412 94.0511 29.6629 95.7437 32.2978 93.7843L33.1556 92.6567L37.5318 96.9638L40.2006 97.6979C40.7844 97.8586 41.324 97.8515 41.5542 98.4116L41.5542 98.4116H41.5542Z" fill="#2F2E41"/>
      <path d="M46.1798 47.8983L45.4306 54.35L41.3452 67.9453L34.6198 90.3279L27.8408 89.3316L33.9249 57.5069L33.3225 52.0499C33.3225 52.0499 33.1263 51.5074 32.9014 50.7133C32.455 49.1422 31.8952 46.5838 32.5111 45.2787C32.5943 45.1013 32.6987 44.9474 32.8278 44.8227C33.0701 44.5878 33.5625 44.4451 34.2222 44.3694C36.9795 44.0578 42.6581 44.9491 45.1849 45.3867L46.1798 47.8983V47.8983Z" fill="#2F2E41"/>
      <path d="M40.957 17.3037L48.4024 26.6913L47.0659 36.4788L45.9206 46.4378L32.4326 44.3876C32.4326 44.3876 35.346 37.3739 35.6697 37.0501C35.9934 36.7264 36.8065 37.5259 36.2381 36.4788C35.6697 35.4316 35.0223 35.4316 35.5618 34.8921C36.1013 34.3525 31.3535 27.3388 31.3535 23.5621C31.3535 19.7855 33.6195 16.7642 33.6195 16.7642L40.957 17.3037L40.957 17.3037Z" fill="#E6E6E6"/>
      <path d="M39.2197 13.9067C41.9897 13.9067 44.2353 11.6611 44.2353 8.89107C44.2353 6.12104 41.9897 3.87549 39.2197 3.87549C36.4497 3.87549 34.2041 6.12104 34.2041 8.89107C34.2041 11.6611 36.4497 13.9067 39.2197 13.9067Z" fill="#FFB6B6"/>
      <path d="M38.9857 18.4807C38.9857 18.4807 39.7362 12.5422 39.869 10.8153C40.0019 9.08832 42.4396 6.39203 42.4396 6.39203L44.1665 7.78689C44.1665 7.78689 45.6278 7.78689 44.6315 5.6614C43.6352 3.53591 41.7754 3.20381 41.7754 3.20381C41.7754 3.20381 41.5761 0.414103 38.1222 0.746213C34.6683 1.07832 33.1406 3.86802 33.1406 3.86802C33.1406 3.86802 32.9413 0.347685 30.7494 2.93812C28.5575 5.52856 27.2955 6.5913 29.6202 7.45478C31.945 8.31826 33.6719 7.65405 33.6719 7.65405C33.6719 7.65405 31.4136 8.11899 31.8121 9.71311C32.2107 11.3072 26.5649 18.5472 29.1553 19.3442C31.7457 20.1413 37.5244 22.0675 38.1886 20.8055C38.8528 19.5435 38.9857 18.4807 38.9857 18.4807V18.4807Z" fill="#2F2E41"/>
      <path d="M59.6605 54.3091C60.6179 53.514 60.3811 51.6499 59.1316 50.1454C58.5846 49.4868 57.9343 49.0064 57.2956 48.741L56.5661 47.8848L47.1541 35.8868C47.1541 35.8868 41.9859 23.9865 40.4416 22.6926C38.8974 21.3987 37.0623 22.0503 37.0623 22.0503L35.6201 23.8043L44.3764 40.4329L53.403 49.6707L54.6212 50.9622C54.7648 51.6388 55.1177 52.3663 55.6646 53.0248C56.9141 54.5293 58.7032 55.1043 59.6605 54.3091H59.6605Z" fill="#FFB6B6"/>
      <path d="M35.0742 21.1279C35.0742 21.1279 37.3783 33.0848 40.0002 34.8474L45.225 30.7734C45.225 30.7734 45.6822 30.7538 44.8312 29.7926C43.9803 28.8314 44.1981 26.7672 44.1981 26.7672C44.1981 26.7672 44.581 27.2627 43.7478 26.5749C42.9147 25.8872 43.6903 21.1574 43.6903 21.1574L35.0742 21.1279L35.0742 21.1279Z" fill="#E6E6E6"/>
    </g>
    <defs>
      <clipPath id="clip0_1017_5532">
        <rect width="136.047" height="100" fill="white" transform="translate(0.976562 0.71875)"/>
      </clipPath>
    </defs>
  </svg>

);