import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  Text,
} from "@chakra-ui/react";
import { FC, ReactElement } from "react";
import { OrderFormFieldProps } from "../../../types/order";
import { colors } from "../../../ui/theme";
import { maskInputField } from "../../../utils/utils";
import { isIncludeId } from "../settingField";

const OrderSingleField: FC<OrderFormFieldProps> = ({
  touched,
  setTouched,
  errors,
  handleChange,
  values,
  title,
  validateField,
  id,
  type,
  name,
  placeholder,
  helperText,
  formatNumberSpace,
  failedValidation,
}): ReactElement => {
  const onChangeHandle = (e: any) => {
    let value = e;
    if (isIncludeId.includes(id)) {
      value = maskInputField(e, formatNumberSpace);
    }
    handleChange(value);
    validateField();
  };
  return (
    <FormControl mb="0.5rem">
      <FormLabel>
        <Text variant="xl" fontWeight="700" display="inline">
          {title}
        </Text>
        <Text variant="xl" fontWeight="700" display="inline" color="red">
          *
        </Text>
      </FormLabel>
      <FormHelperText textAlign="left" mb="4px">
        <Text variant="sm" opacity="0.7">
          {helperText}
        </Text>
      </FormHelperText>
      <InputGroup>
        <Input
          id={id}
          type={type}
          name={name}
          value={values[name]}
          onBlur={() =>
            setTouched({
              ...touched,
              [name]: true,
            })
          }
          onChange={onChangeHandle}
          placeholder={placeholder}
          _focusVisible={{
            borderColor: colors.secondaryFontColor.lightMode,
            boxShadow: `0 0 0 1px ${colors.secondaryFontColor.lightMode}`,
          }}
          errorBorderColor={colors.red.default}
          focusBorderColor={colors.blue.default}
        />
      </InputGroup>
      <Text textAlign="left" color="red" variant="sm" opacity="0.7" mt="4px">
        {errors && (touched[name] || failedValidation) && errors}
      </Text>
    </FormControl>
  );
};
export default OrderSingleField;
