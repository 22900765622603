export const AccueilIcon = () => (
  <svg width="80" height="121" viewBox="0 0 80 121" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_942_38643)">
      <path d="M77.6691 82.325C79.7498 84.0766 80.5321 86.5691 79.4164 87.8922C78.3007 89.2152 75.7099 88.8676 73.6287 87.1152C72.7877 86.4266 72.124 85.5467 71.6931 84.5492L62.9707 77.0168L66.5953 73.002L74.8091 80.8539C75.8664 81.109 76.8471 81.6135 77.6691 82.325Z" fill="#FCB4B4"/>
      <path d="M70.6256 76.752L59.9627 63.0698L49.8634 44.5996L49.6016 70.2159L64.7399 82.6406L70.6256 76.752Z" fill="#091C25"/>
      <path d="M24.2422 39.0922L26.6849 27.9941L34.8493 28.3866L36.9451 36.4376L24.2422 39.0922Z" fill="#FCB4B4"/>
      <path opacity="0.1" d="M24.2422 39.0922L26.6849 27.9941L34.8493 28.3866L36.9451 36.4376L24.2422 39.0922Z" fill="black"/>
      <path d="M30.5662 30.9707C35.6143 30.9707 39.7066 26.8813 39.7066 21.8369C39.7066 16.7925 35.6143 12.7031 30.5662 12.7031C25.5181 12.7031 21.4258 16.7925 21.4258 21.8369C21.4258 26.8813 25.5181 30.9707 30.5662 30.9707Z" fill="#FCB4B4"/>
      <path d="M26.4772 25.3647V25.3647C26.4772 25.3647 25.5551 25.3558 23.637 24.9993C21.719 24.6429 25.0492 29.1216 25.0492 29.1216C16.0232 25.3554 18.9115 15.8027 18.9115 15.8027C9.75109 14.0521 13.5886 5.91463 13.5886 5.91463C13.5886 5.91463 16.6081 0.0917402 25.8905 0.462275C35.1729 0.83281 30.7877 9.9812 30.7877 9.9812C36.8354 9.99265 37.5159 15.4391 37.5159 15.4391C37.5159 15.4391 35.3357 15.7111 31.3736 16.7159C27.4115 17.7207 26.4772 25.3647 26.4772 25.3647L26.4772 25.3647L26.4772 25.3647H26.4772Z" fill="#091C25"/>
      <path d="M26.1145 35.5963L25.7017 34.793C25.7017 34.793 18.3566 35.6437 17.1514 36.7668C15.9462 37.8898 13.1914 42.8792 13.1914 42.8792L19.2175 48.0407L26.1145 35.5963Z" fill="#FFB6B6"/>
      <path d="M35.2305 35.4007C35.2305 35.4007 40.74 33.9322 43.8391 37.029C46.9382 40.1259 50.5539 45.8035 50.5539 45.8035L50.3817 50.2767L41.773 46.13L35.2305 35.4007V35.4007Z" fill="#FFB6B6"/>
      <path d="M36.6332 34.7937C36.6332 34.7937 43.8373 32.213 48.486 37.7185C53.1347 43.224 54.5121 60.6009 54.5121 60.6009C54.5121 60.6009 65.0146 92.7739 62.432 93.118C59.8494 93.4621 44.0887 94.4131 44.0887 94.4131L38.6721 59.3966L36.2617 34.7937H36.6332Z" fill="#091C25"/>
      <path d="M20.0792 65.7617L18.3574 71.9555L41.773 73.6759C41.773 73.6759 40.9121 68.4982 41.773 66.6138L20.0792 65.7617Z" fill="#FCB4B4"/>
      <path d="M33.3359 106.02L34.369 120.816L47.3714 120.472L46.9376 105.504L33.3359 106.02Z" fill="#FCB4B4"/>
      <path d="M13.0485 104.557L9.23047 120.644L22.8422 120.552L26.3102 107.403L13.0485 104.557Z" fill="#FCB4B4"/>
      <path d="M36.2635 34.793H25.7027L17.1523 46.1481L19.0462 69.3746C19.0462 69.3746 23.3506 68.1703 29.3766 68.1703H42.2896C42.2896 68.1703 40.3957 62.1486 42.8061 59.7399C45.2165 57.3313 43.4948 46.1481 43.4948 46.1481L36.2635 34.793Z" fill="#F7B80B"/>
      <path d="M17.4952 70.0625L7.85352 104.988C7.85352 104.988 22.0509 110.689 30.62 109.043C39.189 107.397 50.8968 106.881 50.8968 106.881L42.6325 71.4389L17.4952 70.0625Z" fill="#091C25"/>
      <path d="M39.7921 82.8407C41.8729 84.5922 42.6551 87.0847 41.5394 88.4078C40.4237 89.7308 37.8329 89.3832 35.7517 87.6308C34.9107 86.9422 34.247 86.0624 33.8161 85.0649L25.0938 77.5324L28.7183 73.5176L36.9322 81.3695C37.9894 81.6246 38.9701 82.1291 39.7921 82.8407Z" fill="#FCB4B4"/>
      <path d="M34.4693 76.752L23.8065 63.0698L13.7072 44.5996L13.4453 70.2159L28.5837 82.6406L34.4693 76.752Z" fill="#091C25"/>
      <path d="M26.4224 34.7937C26.4224 34.7937 19.2182 32.213 14.5695 37.7185C9.92086 43.224 8.54347 60.6009 8.54347 60.6009C8.54347 60.6009 -1.95908 92.7739 0.623513 93.118C3.20611 93.4621 18.9668 94.4131 18.9668 94.4131L24.3834 59.3966L26.7938 34.7937H26.4224Z" fill="#091C25"/>
    </g>
    <defs>
      <clipPath id="clip0_942_38643">
        <rect width="79.9548" height="120.372" fill="white" transform="translate(0.0234375 0.445312)"/>
      </clipPath>
    </defs>
  </svg>

);