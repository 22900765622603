import { Flex } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { HelpType, useHelpContext } from "../../../context/HelpContext";
import { useOnboardingContext } from "../../../context/OnboardingContext";
import {
  BackendOptionType,
  OPTIONS_LIST,
  OptionType,
} from "../../../context/OptionsContext";
import { EventCategory } from "../../Onboarding/EventTypes";
import SingleOptionsCardWrapper from "./SingleOptionsCardWrapper";
import useOptionsData from "../../../hooks/getOptionsData";

export interface BackendPrice {
  before: string;
  data: any;
  after: string;
}

interface OptionsBlockProps {
  selectedOptions: { [option in OptionType]?: number };
  optionsToHide: OptionType[];
  onIncrement: (option: OptionType) => void;
  onDecrement: (option: OptionType) => void;
  onEdit: (option: OptionType, value: number) => void;
}

const OptionsBlock: FC<OptionsBlockProps> = ({
  selectedOptions,
  optionsToHide,
  onIncrement,
  onDecrement,
  onEdit,
}) => {
  const { setHelp } = useHelpContext();
  const { eventCategory } = useOnboardingContext();
  const [showHelpMrkt, setShowHelpMrkt] = useState(true);
  const [showHelpPrinted, setShowHelpPrinted] = useState(true);

  useEffect(() => {
    if (eventCategory === EventCategory.STREET_MARKETING && showHelpMrkt) {
      setHelp(HelpType.DISTRIBUTION_BOOST);
      setShowHelpMrkt(false);
    }
    if (
      !(eventCategory === EventCategory.STREET_MARKETING && showHelpMrkt) &&
      (selectedOptions[OptionType.Oriflamme] !== 0 ||
        selectedOptions[OptionType.Ballonbag] !== 0 ||
        selectedOptions[OptionType.TotbagR] !== 0 ||
        selectedOptions[OptionType.TotbagRV] !== 0 ||
        selectedOptions[OptionType.BesacePrint] !== 0) &&
      showHelpPrinted === true
    ) {
      setHelp(HelpType.PRINTED_ELEMENT);
      setShowHelpPrinted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const optionsPrices: {
    [key in OptionType]: BackendPrice;
  } = {
    [OptionType.Portant]: {
      before: "50 cintres - ",
      data: useOptionsData(BackendOptionType.Portant),
      after: " € / jour",
    },
    [OptionType.TicketsVestiaires]: {
      before: "50 tickets - ",
      data: useOptionsData(BackendOptionType.TicketsVestiaires),
      after: " €",
    },
    [OptionType.TabletteNumérique]: {
      before: "",
      data: useOptionsData(BackendOptionType.TabletteNumérique),
      after: " € / jour",
    },
    [OptionType.TalkyWalky]: {
      before: "",
      data: useOptionsData(BackendOptionType.TalkyWalky),
      after: " € / jour (2 par 2)",
    },
    [OptionType.Panières]: {
      before: "",
      data: useOptionsData(BackendOptionType.Panières),
      after: " € / jour",
    },
    [OptionType.Cabas]: {
      before: "",
      data: useOptionsData(BackendOptionType.Cabas),
      after: " € / jour",
    },
    [OptionType.PanneauDirectionnel]: {
      before: "",
      data: useOptionsData(BackendOptionType.PanneauDirectionnel),
      after: " € / jour",
    },
    [OptionType.Formation]: {
      before: "",
      data: useOptionsData(BackendOptionType.Formation),
      after: " € / staff (1h)",
    },
    [OptionType.TotbagR]: {
      before: "",
      data: useOptionsData(BackendOptionType.TotbagR),
      after: " €",
    },
    [OptionType.TotbagRV]: {
      before: "",
      data: useOptionsData(BackendOptionType.TotbagRV),
      after: " €",
    },
    [OptionType.Besace]: {
      before: "Sans print - ",
      data: useOptionsData(BackendOptionType.Besace),
      after: " €",
    },
    [OptionType.BesacePrint]: {
      before: "Avec print - ",
      data: useOptionsData(BackendOptionType.BesacePrint),
      after: " €",
    },
    [OptionType.Ballonbag]: {
      before: "",
      data: useOptionsData(BackendOptionType.Ballonbag),
      after: " € + 82 € / jour",
    },
    [OptionType.Oriflamme]: {
      before: "",
      data: useOptionsData(BackendOptionType.Oriflamme),
      after: " €",
    },
    // [OptionType.TourDeCou]: {
    //   before: "",
    //   data: useOptionsData("tour_de_cou"),
    //   after: " €",
    // },
    // [OptionType.Badge]: {
    //   before: "",
    //   data: useOptionsData("badge"),
    //   after: " €",
    // },
  };

  const editHandler = (option: OptionType, value: number) => {
    onEdit(option, value);
  };

  return (
    <Flex
      borderRadius="24px"
      wrap="wrap"
      width="100%"
      display="flex"
      flexWrap="wrap"
      gap="8px"
    >
      {Object.keys(selectedOptions)
        .filter((option) => {
          return !optionsToHide.includes(option as OptionType);
          // return option;
        })
        .map((opt) => (
          <SingleOptionsCardWrapper
            onIncrement={() => {
              onIncrement(opt as OptionType);
            }}
            onDecrement={() => {
              onDecrement(opt as OptionType);
            }}
            onEditValue={(value: number) => {
              editHandler(opt as OptionType, value);
              onEdit(opt as OptionType, value);
            }}
            optionData={OPTIONS_LIST[opt as OptionType]}
            price={optionsPrices[opt as OptionType]}
            amount={selectedOptions[opt as OptionType]!}
            key={opt}
          />
        ))}
    </Flex>
  );
};

export default OptionsBlock;
