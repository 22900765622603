import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import NorelaButton from "../../components/Buttons/NorelaButton";
import { DressingRoomIcon } from "../../components/Icons/DressingRoomIcon";
import ParkingWideIcon from "../../components/Icons/ParkingWideIcon";
import {
  setAdviceOnHover,
  useAdviceContext,
} from "../../context/AdviceContext";
import CustomTooltip from "../../components/CustomTooltip";
import { useSegment } from "../../context/SegmentContext";

function ChangingRoomOption({
  setEventInWinter,
  eventInWinter,
}: {
  setEventInWinter: (eventInWinter: boolean | undefined) => void;
  eventInWinter: boolean | undefined;
}) {
  const { track } = useSegment();
  useEffect(() => {
    const date = new Date().getMonth();
    setEventInWinter(date > 8 || date < 3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      justifyContent="space-between"
      gap="4rem"
      boxShadow="2px 2px 10px rgba(0, 0, 0, 0.25)"
      borderRadius="4px"
      p="1rem"
      w="100%"
    >
      <Flex direction="column" maxWidth="100%" gap="20px">
        <Text variant="xl" fontWeight="700" textAlign="left">
          Avez-vous besoin d'un vestiaire ?
        </Text>
        <Text
          variant="xl"
          fontWeight="500"
          textAlign="left"
          maxW="460px"
          whiteSpace="pre-wrap"
        >
          <b>• En période chaude</b>, nous vous conseillons 1 hôte(sse) <br />
          {"  "}tous les 200 invités. <br />
        </Text>
        <CustomTooltip
          placement="right"
          padding="1rem"
          content={[
            "En période froide, vos invités porterons plus de vêtements à mettre au vestiaire, il faudra donc plus de personnel pour gérer la charge de travail.",
          ]}
        >
          <Text
            variant="xl"
            fontWeight="500"
            textAlign="left"
            maxW="460px"
            whiteSpace="pre-wrap"
          >
            <b>
              • <u>En période froide</u>
            </b>
            , nous vous conseillons 1 hôte(sse) <br />
            {"  "}tous les 100 invités. <br />
          </Text>
        </CustomTooltip>
        <Flex gap="1rem" mt="auto">
          <NorelaButton
            width="fit-content"
            onClick={() => {
              setEventInWinter(false);
              track("Locker room", { selection: "Période chaude." });
            }}
            _hover={{
              opacity: 0.5,
            }}
            light={eventInWinter !== false}
          >
            Période chaude
          </NorelaButton>
          <NorelaButton
            onClick={() => {
              setEventInWinter(true);
              track("Locker room", { selection: "Période froide." });
            }}
            light={eventInWinter !== true}
          >
            Période froide
          </NorelaButton>
          <NorelaButton
            onClick={() => {
              setEventInWinter(undefined);
              track("Locker room", { selection: "Pas de vestiaire." });
            }}
            light={eventInWinter !== undefined}
          >
            Pas de vestiaire
          </NorelaButton>
        </Flex>
      </Flex>
      <Flex
        width="fit-content"
        alignItems="center"
        justifyContent="center"
        sx={{
          svg: {
            height: "200px",
            "@media(max-width: 1600px)": {
              height: "200px",
            },
          },
        }}
      >
        <DressingRoomIcon />
      </Flex>
    </Flex>
  );
}

function ParkingOption({
  parking,
  setParking,
}: {
  parking: boolean | undefined;
  setParking: (parking: boolean) => void;
}) {
  const { setAdvice } = useAdviceContext();
  return (
    <Flex
      justifyContent="space-between"
      gap="2rem"
      boxShadow="2px 2px 10px rgba(0, 0, 0, 0.25)"
      opacity="0.5" // BECAUSE DESABLED
      backgroundColor="DDD" // BECAUSE DESABLED
      cursor="not-allowed" // BECAUSE DESABLED
      borderRadius="4px"
      p="1rem"
      {...setAdviceOnHover("Prochainement disponible", setAdvice)}
    >
      <Box>
        <Text variant="xl" fontWeight="700" textAlign="left">
          Avez-vous un parking pour vos invités ?
        </Text>
        <Text
          variant="xl"
          fontWeight="500"
          textAlign="left"
          maxW="460px"
          whiteSpace="pre-wrap"
        >
          Nous vous proposons d’ajouter à votre
          <br />
          équipe un voiturier pour accueillir vos invités
          <br />
          dès leur arrivée sur place.
        </Text>
        <Flex justifyContent="space-evenly" mt="1rem">
          <NorelaButton
            // onClick={() => {
            //   setParking(true);
            // }}
            // light={parking !== true}
            light={true}
          >
            Oui
          </NorelaButton>
          <NorelaButton
            // onClick={() => {
            //   setParking(false);
            // }}
            // light={parking}
            light={true}
          >
            Non
          </NorelaButton>
        </Flex>
      </Box>

      <ParkingWideIcon />
    </Flex>
  );
}

export default function OnboardingOptions({
  setEventInWinter,
  eventInWinter,
  parking,
  setParking,
}: {
  setEventInWinter: (eventInWinter: boolean | undefined) => void;
  eventInWinter: boolean | undefined;
  parking: boolean | undefined;
  setParking: (parking: boolean) => void;
}) {
  return (
    <Flex gap="1rem" flexDir="column">
      <ChangingRoomOption
        eventInWinter={eventInWinter}
        setEventInWinter={setEventInWinter}
      />
      <ParkingOption parking={parking} setParking={setParking} />
    </Flex>
  );
}
