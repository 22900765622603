import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import NorelaButton from "../../components/Buttons/NorelaButton";
import { LogoEnorelaBeta } from "../../components/Icons/LogoEnorelaBeta";
import { useSegment } from "../../context/SegmentContext";
import { useSessionContext } from "../../context/SessionContext";
import { PageURL } from "../../types/pages";
import { useResetOrder } from "../../hooks/useResetOrder";
import { useRef } from "react";

const IntroductionContent = () => {
  const navigate = useNavigate();
  const { updateHistory, setNeedHelp, sessionInfo } = useSessionContext();
  const onClick = (link: PageURL) => {
    updateHistory(link);
    navigate(link);
  };
  const { partialResetOrder } = useResetOrder();
  const { track } = useSegment();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const ref = useRef(null);

  function newOrder() {
    const text = sessionInfo?.needHelp ? "Don't need help" : "Need help";
    track(text);
    partialResetOrder();
    setNeedHelp(!sessionInfo?.needHelp);
    onClick(sessionInfo?.needHelp ? PageURL.CALENDAR : PageURL.ONBOARDING);
  }
  function continueOrder() {
    onClick(sessionInfo?.needHelp ? PageURL.ONBOARDING : PageURL.CALENDAR);
  }

  return (
    <Flex
      direction="column"
      justifyContent="center"
      mt={{ md: "20px", "2xl": "100px" }}
      alignItems="center"
      gap={{ md: "16px", "2xl": "20px" }}
    >
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={ref}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            Vous avez une commande en cours, en continuant sur{" "}
            {sessionInfo?.needHelp
              ? "*Je n'ai pas besoin d'aide*"
              : "*J'ai besoin d'aide*"}
            , vous acceptez de la réinitialiser.
          </AlertDialogHeader>
          <AlertDialogCloseButton />

          <AlertDialogFooter>
            <Button color="red" backgroundColor="white" onClick={newOrder}>
              Nouvelle commande
            </Button>
            <NorelaButton onClick={continueOrder}>
              Reprendre la commande
            </NorelaButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <LogoEnorelaBeta maxWidth="770px" maxHeight="235px" alignSelf="top" />
      <Flex direction="row">
        <Flex
          direction="column"
          alignItems="center"
          maxWidth="600px"
          sx={{
            button: {
              width: "fit-content",
              marginBottom: "15px",
            },
          }}
        >
          <Text textAlign="center" variant="xls">
            {"BIENVENUE !"}
          </Text>
          <Text
            mt="30px"
            textAlign="center"
            variant="xls"
            whiteSpace="pre-wrap"
          >
            {
              "Nous allons vous accompagner et vous conseiller tout au long de votre commande."
            }
          </Text>
          <Text
            mt="10px"
            textAlign="center"
            variant="xls"
            whiteSpace="pre-wrap"
          >
            {"Tout d'abord, connaissez vous vos besoins ?"}
          </Text>
          <Text
            mt="10px"
            textAlign="center"
            variant="xls"
            whiteSpace="pre-wrap"
          >
            {"Si oui, composez directement votre équipe."}
          </Text>
          <Text
            mt="10px"
            textAlign="center"
            variant="xls"
            whiteSpace="pre-wrap"
          >
            {
              "Dans le cas contraire, laissez vous guidez par nos pré compositions d'équipes, gagner du temps et assurez vous de ne passer à côté d'aucun détail."
            }
          </Text>
          <Flex>
            <Flex mt="50px" gap="25px" direction="row">
              <NorelaButton
                onClick={() => {
                  if (sessionInfo.needHelp === false) {
                    onOpen();
                  } else {
                    track("Need help");
                    setNeedHelp(true);
                    onClick(PageURL.ONBOARDING);
                  }
                }}
              >
                J'ai besoin d'aide
              </NorelaButton>
              <NorelaButton
                onClick={() => {
                  if (sessionInfo.needHelp === true) {
                    onOpen();
                  } else {
                    track("Don't need help");
                    setNeedHelp(false);
                    onClick(PageURL.CALENDAR);
                  }
                }}
                light
              >
                Je n'ai pas besoin d'aide
              </NorelaButton>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default IntroductionContent;
