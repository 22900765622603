import { AccompagnementTransfertIcon } from "../Icons/AccompagnementTransfertIcon";
import { AccueilIcon } from "../Icons/AccueilIcon";
import { AircraftIcon } from "../Icons/AircraftIcon";
import { BusIcon } from "../Icons/BusIcon";
import { AlertPopupIcon } from "../Icons/AlertPopupIcon";
import { RoleAnimateurMascotteIcon } from "../Icons/RoleAnimateurMascotteIcon";
import { AnimationEnfansIcon } from "../Icons/AnimationEnfansIcon";
import { AnimationEvenemIcon } from "../Icons/AnimationEvenemIcon";
import { AnimationGamingIcon } from "../Icons/AnimationGamingIcon";
import { AnimationMicroIcon } from "../Icons/AnimationMicroIcon";
import { AnimationPromoIcon } from "../Icons/AnimationPromoIcon";
import { AnimationRollerIcon } from "../Icons/AnimationRollerIcon";
import { AnimerIcon } from "../Icons/AnimerIcon";
import { ArrowsItemIcon } from "../Icons/ArrowsItemIcon";
import { AttentionIcon } from "../Icons/AttentionIcon";
import { CinemaIcon } from "../Icons/CinemaIcon";
import { AvatarIcon } from "../Icons/AvatarIcon";
import { DressingPantsIcon } from "../Icons/DressingPantsIcon";
import OptionsBesacesIcon from "../Icons/OptionsBesacesIcon";
import OptionsBesacesPrintIcon from "../Icons/OptionsBesacesPrintIcon";
import { OptionsCabasIcon } from "../Icons/OptionsCabasIcon";
import { OptionsBallonbagIcon } from "../Icons/OptionsBallonbagIcon";
import RoleBilingualHostessIcon from "../Icons/RoleBilingualHostessIcon";
import { BottomPopupsIcon } from "../Icons/BottomPopupsIcon";
import { RoleBrandAmbasIcon } from "../Icons/RoleBrandAmbasIcon";
import { BriefIcon } from "../Icons/BriefIcon";
import { CalendarPopupsLevel2Icon } from "../Icons/CalendarPopupsLevel2Icon";
import { CalendarSelectionDetailleIcon } from "../Icons/CalendarSelectionDetailleIcon";
import { CalendarSelectionGroupeIcon } from "../Icons/CalendarSelectionGroupeIcon";
import { CalendarSelectionSimplifieIcon } from "../Icons/CalendarSelectionSimplifieIcon";
import { DressingShoesIcon } from "../Icons/DressingShoesIcon";
import { DressingCheveuxIcon } from "../Icons/DressingCheveuxIcon";
import { DressingMakeupIcon } from "../Icons/DressingMakeupIcon";
import { ConsultationBlockIcon } from "../Icons/ConsultationBlockIcon";
import { ContactUsIcon } from "../Icons/ContactUsIcon";
import { ConventionIcon } from "../Icons/ConventionIcon";
import { ConversationIcon } from "../Icons/ConversationIcon";
import { CosmeticIcon } from "../Icons/CosmeticIcon";
import { CoupleIcon } from "../Icons/CoupleIcon";
import { DressingCardPopupIcon } from "../Icons/DressingCardPopupIcon";
import { DressingPopupStartIcon } from "../Icons/DressingPopupStartIcon";
import { DressingTenuePersoClassiqueIcon } from "../Icons/DressingTenuePersoClassiqueIcon";
import { DressingTenuePersoCasualIcon } from "../Icons/DressingTenuePersoCasualIcon";
import { DressingTenuePersoMoreOptionsIcon } from "../Icons/DressingTenuePersoMoreOptionsIcon";
import { DrinkIcon } from "../Icons/DrinkIcon";
import { EmargementIcon } from "../Icons/EmargementIcon";
import { EscapeGameIcon } from "../Icons/EscapeGameIcon";
import { EvenementSportifIcon } from "../Icons/EvenementSportifIcon";
import { EvenementSportifNewIcon } from "../Icons/EvenementSportifNewIcon";
import { EvenementielIcon } from "../Icons/EvenementielIcon";
import { FlyersGirlIcon } from "../Icons/FlyersGirlIcon";
import { FlyersMenIcon } from "../Icons/FlyersMenIcon";
import { FlyersPopupIcon } from "../Icons/FlyersPopupIcon";
import { FoodIcon } from "../Icons/FoodIcon";
import { GamingIcon } from "../Icons/GamingIcon";
import { GoodiesIcon } from "../Icons/GoodiesIcon";
import { GroupOfGirlsIcon } from "../Icons/GroupOfGirlsIcon";
import { GroupOfPeopleIcon } from "../Icons/GroupOfPeopleIcon";
import { DressingTopIcon } from "../Icons/DressingTopIcon";
import { HelpIcon } from "../Icons/HelpIcon";
import { HighTechIcon } from "../Icons/HighTechIcon";
import { HighTechTechnologyIcon } from "../Icons/HighTechTechnologyIcon";
import { InagurationIcon } from "../Icons/InagurationIcon";
import { InaugurationIcon } from "../Icons/InaugurationIcon";
import { InstitutionelIcon } from "../Icons/InstitutionelIcon";
import { InstitutionnelIcon } from "../Icons/InstitutionnelIcon";
import { IntroductionGirlIcon } from "../Icons/IntroductionGirlIcon";
import { DressingProvidedOutfitIcon } from "../Icons/DressingProvidedOutfitIcon";
import { LoadingIcon } from "../Icons/LoadingIcon";
import { MarqueIcon } from "../Icons/MarqueIcon";
import { NotificationAlertGirlIcon } from "../Icons/NotificationAlertGirlIcon";
import { OptionsFormationIcon } from "../Icons/OptionsFormationIcon";
import { OptionsPaniereIcon } from "../Icons/OptionsPaniereIcon";
import { OptionsPanneauDirectionnelIcon } from "../Icons/OptionsPanneauDirectionnelIcon";
import { OptionsPortantIcon } from "../Icons/OptionsPortantIcon";
import { OptionsTabletteNumeriqueIcon } from "../Icons/OptionsTabletteNumeriqueIcon";
import { DressingPrintedOutfitIcon } from "../Icons/DressingPrintedOutfitIcon";
import { OptionsTicketIcon } from "../Icons/OptionsTicketIcon";
import { OptionsTotbagRIcon } from "../Icons/OptionsTotbagRIcon";
import { OptionsTotbagRvIcon } from "../Icons/OptionsTotbagRVIcon";
import { OptionsTourDeCouIcon } from "../Icons/OptionsTourDeCouIcon";
import { OptionsBadgeIcon } from "../Icons/OptionsBadgeIcon";
import { OptionsOriflammeIcon } from "../Icons/OptionsOriflammeIcon";
import { OptionsTalkyWalkyIcon } from "../Icons/OptionsTalkyWalkyIcon";

import { OrderCardWithGirlIcon } from "../Icons/OrderCardWithGirlIcon";
import { OrderMastercardLogoIcon } from "../Icons/OrderMastercardLogoIcon";
import { OrderTooltipCreditCardIcon } from "../Icons/OrderTooltipCreditCardIcon";
import { OrderVisaIcon } from "../Icons/OrderVisaIcon";
import { OrientationIcon } from "../Icons/OrientationIcon";
import { OurAdviceIcon } from "../Icons/OurAdviceIcon";
import { ParkingTooltipIcon } from "../Icons/ParkingTooltipIcon";
import { RolePassageMicroIcon } from "../Icons/RolePassageMicroIcon";
import { RolePlacementIcon } from "../Icons/RolePlacementIcon";
import { RolePlateauIcon } from "../Icons/RolePlateauIcon";
import { RoleRegisseutIcon } from "../Icons/RoleRegisseutIcon";
import { RemiseIcon } from "../Icons/RemiseIcon";
import { RetailsIcon } from "../Icons/RetailsIcon";
import { RoadShowIcon } from "../Icons/RoadShowIcon";
import { SalonIcon } from "../Icons/SalonIcon";
import { SalonNewIcon } from "../Icons/SalonNewIcon";
import { SalonIcon2 } from "../Icons/SalonIcon2";
import ScarfPersonIcon from "../Icons/ScarfPersonIcon";
import { RoleOtherIcon } from "../Icons/RoleOtherIcon";
import { RoleBrandAmbassIcon } from "../Icons/RoleBrandAmbassIcon";
import { RoleChefIcon } from "../Icons/RoleChefIcon";
import { RoleHotessBilingueIcon } from "../Icons/RoleHotessBilingueIcon";
import { RoleHotessTrilingueIcon } from "../Icons/RoleHotessTrilingueIcon";
import { RoleAnimateurBilingueIcon } from "../Icons/RoleAnimateurBilingueIcon";
import { RoleDemonstrationProduitIcon } from "../Icons/RoleDemonstrationProduitIcon";
import { RoleDistributionIcon } from "../Icons/RoleDistributionIcon";
import { RoleRoadsIcon } from "../Icons/RoleRoadsIcon";
import { SoireePresseIcon } from "../Icons/SoireePresseIcon";
import { StreetMarketingIcon } from "../Icons/StreetMarketingIcon";
import { TailleDuStandIcon } from "../Icons/TailleDuStandIcon";
import { TelephoneIcon } from "../Icons/TelephoneIcon";
import { TelephonieIcon } from "../Icons/TelephonieIcon";
import { DressingTenuePersoIcon } from "../Icons/DressingTenuePersoIcon";
import { DressingTenueProIcon } from "../Icons/DressingTenueProIcon";
import { TestProductIcon } from "../Icons/TestProductIcon";
import { TextileIcon } from "../Icons/TextileIcon";
import { UrbanIcon } from "../Icons/UrbanIcon";
import { ValidationIcon } from "../Icons/ValidationIcon";
import { VestiaireIcon } from "../Icons/VestiaireIcon";
import { VillageEvenementielIcon } from "../Icons/VillageEvenementielIcon";
import { RoleVoiturierIcon } from "../Icons/RoleVoiturierIcon";
import { WalkGirlIcon } from "../Icons/walkGirlIcon";
import { DressingOutfitEnsembleIcon } from "../Icons/DressingOutfitEnsembleIcon";
import { DressingRoomIcon } from "../Icons/DressingRoomIcon";
import { RotationPersonnelIcon } from "../Icons/RotationPersonnelIcon";
import { PauseIcon } from "../Icons/PauseIcon";
import { NorelaQuoteIcon } from "../Icons/NorelaQuoteIcon";
import { RoleBarmanIcon } from "../Icons/RoleBarmanIcon";
import { RoleMaitreHotelIcon } from "../Icons/RoleMaitreHotelIcon";

export enum Icons {
  CONVERSATION = "CONVERSATION",
  BRIEF_MAIL_ICON = "BRIEF_MAIL_ICON",
  ROADSHOW = "ROADSHOW",
  SALON = "SALON",
  SOIREEPRESSE = "SOIREEPRESSE",
  CONVENTIONO_ICON = "CONVENTIONO_ICON",
  REMISE_ICON = "REMISE_ICON",
  CINEMA_ICON = "CINEMA_ICON",
  INAUGURATION_ICON = "INAUGURATION_ICON",
  ANIMATION_PROMO_ICON = "ANIMATION_PROMO_ICON",
  VILAGE_EVENT_ICON = "VILAGE_EVENT_ICON",
  EVENEMENT_SPORT_ICON = "EVENEMENT_SPORT_ICON",
  SALONS_ICON = "SALONS_ICON",
  GOODIES_ICON = "GOODIES_ICON",
  TEST_PRODUCT_ICON = "TEST_PRODUCT_ICON",
  ANIMER_ICON = "ANIMER_ICON",
  EVENEMENT_ICON = "EVENEMENT_ICON",
  VILLAGEEVENEMENTIEL_ICON = "VILLAGEEVENEMENTIEL_ICON",
  SALON_SVG_ICON = "SALON_SVG_ICON",
  FESIVAL_ICON = "FESIVAL_ICON",
  ESCAPE_GAME_ICON = "ESCAPE_GAME_ICON",
  GROUP_OF_PEOPLE_ICON = "GROUP_OF_PEOPLE_ICON",
  COUPLE_ICON = "COUPLE_ICON",
  HIGHT_TECH_ICON = "HIGHT_TECH_ICON",
  GAMING_ICON = "GAMING_ICON",
  TEXTILE_ICON = "TEXTILE_ICON",
  FOOD_ICON = "FOOD_ICON",
  COSMETIC_ICON = "COSMETIC_ICON",
  DRINK_ICON = "DRINK_ICON",
  INSTITUIONELL_ICON = "INSTITUIONELL_ICON",
  URBAN_ICON = "URBAN_ICON",
  INSTITUTIONNEL_ICON = "INSTITUTIONNEL_ICON",
  EVENEMENTIEL_ICON = "EVENEMENTIEL_ICON",
  CONTACT_US_ICON = "CONTACT_US_ICON",
  LOADING_ICON = "LOADING_ICON",
  HIGTH_TECH_TECHNOLOGY_ICON = "HIGTH_TECH_TECHNOLOGY_ICON",
  MARQUE_ICON = "MARQUE_ICON",
  TELEPHONE_ICON = "TELEPHONE_ICON",
  FLYERS_GIRL_ICON = "FLYERS_GIRL_ICON",
  OUR_ADVICE_ICON = "OUR_ADVICE_ICON",
  FLYERS_MEN_ICON = "FLYERS_MEN_ICON",
  AIRCRAFT_ICON = "AIRCRAFT_ICON",
  BUS_ICON = "BUS_ICON",
  WALK_GIRL_ICON = "WALK_GIRL_ICON",
  ACCUEIL_ICON = "ACCUEIL_ICON",
  VESTIAIRE_ICON = "VESTIAIRE_ICON",
  PLATEAU_ICON = "PLATEAU_ICON",
  VOITURIER_ICON = "VOITURIER_ICON",
  PLACEMENENT_ICON = "PLACEMENENT_ICON",
  ACCOMPAGNEMENT_ICON = "ACCOMPAGNEMENT_ICON",
  ORIENTATION_ICON = "ORIENTATION_ICON",
  EMARGEMENT_ICON = "EMARGEMENT_ICON",
  HOTESSE_BILINGUE_ICON = "HOTESSE_BILINGUE",
  HOTESSE_TRILINGUE_ICON = "HOTESSE_TRILINGUE",
  ANIMATEUR_BILINGUE_ICON = "ANIMATEUR_BILINGUE",
  PASSAGE_ICON = "PASSAGE_ICON",
  STREET_MARKETING_ICON = "STREET_MARKETING_ICON",
  RETAIL_ICON = "RETAIL_ICON",
  REGISSEUR_ICON = "REGISSEUR_ICON",
  ANIMATION_ICON = "ANIMATION_ICON",
  BRAND_AMBOTTOM_OUTFIT_ICON = "BRAND_AMBOTTOM_OUTFIT_ICON",
  ANIMATION_MICRO_ICON = "ANIMATION_MICRO_ICON",
  ANIMATION_ENFANTS_ICON = "ANIMATION_ENFANTS_ICON",
  ANIMATION_GAMING_ICON = "ANIMATION_GAMING_ICON",
  ANIMATION_ROLLER_ICON = "ANIMATION_ROLLER_ICON",
  CONSULTATIONS_BLOCK_ICON = "CONSULTATIONS_BLOCK_ICON",
  HELP_ICON = "HELP_ICON",
  GROUP_OF_GIRLS_ICON = "GROUP_OF_GIRLS_ICON",
  AVATAR_ICON = "AVATAR_ICON",
  ALERT_POPUP_WINDOW = "ALERT_POPUP_WINDOW",
  PARKING_TOOLIP_ICON = "PARKING_TOOLIP_ICON",
  NOIFICATION_GIRL_ICON = "NOIFICATION_GIRL_ICON",
  INAGURATION_VARIANT = "INAGURATION_VARIANT",
  SALON_NEW_ICON = "SALON_NEW_ICON",
  EVENEMENT_SPORTIF_NEW = "EVENEMENT_SPORTIF_NEW",
  TELEPHONENIEL_ICON = "TELEPHONENIEL_ICON",
  FLYERS_POPUPS_ICON = "FLYERS_POPUPS_ICON",
  ATTENTION_POPUPS_ICON = "ATTENTION_POPUPS_ICON",
  PROFESSIONAL_OUTFIT_ICON = "PROFESSIONAL_OUTFIT_ICON",
  PERSONAL_OUTFIT_ICON = "PERSONAL_OUTFIT_ICON",
  JE_FOURNIS_LES_TENUES = "JE_FOURNIS_LES_TENUES",
  DRESSING_TENUE_PERSONELLE_CLASSIQUE = "DRESSING_TENUE_PERSONELLE_CLASSIQUE",
  DRESSING_TENUE_PERSONELLE_CASUAL = "DRESSING_TENUE_PERSONELLE_CASUAL",
  DRESSING_TENUE_PERSONELLE_MORE_OPTIONS = "DRESSING_TENUE_PERSONELLE_MORE_OPTIONS",
  SHOES_ICON = "SHOES_ICON",
  TOP_OUTFIT_ICON = "TOP_OUTFIT_ICON",
  BOTTOM_OUTFIT_ICON = "BOTTOM_OUTFIT_ICON",
  HAIR_ICON = "HAIR_ICON",
  MAKEUP_ICON = "MAKEUP_ICON",
  DRESSING_POPUP_START = "DRESSING_POPUP_START",
  OPTIONS_FORMATING = "OPTIONS_FORMATING",
  OPTIONS_PANNEAU_DIRECTIONNEL = "OPTIONS_PANNEAU_DIRECTIONNEL",
  OPTIONS_PANIERE = "OPTIONS_PANIERE",
  OPTIONS_PORTANT = "OPTIONS_PORTANT",
  OPTIONS_TABLETTE_NUMERIQUE = "OPTIONS_TABLETTE_NUMERIQUE",
  OPTIONS_TICKET = "OPTIONS_TICKET",
  OPTIONS_TOTBAG_R = "OPTIONS_TOTBAG_R",
  OPTIONS_TOTBAG_RV = "OPTIONS_TOTBAG_RV",
  TOUR_DE_COU = "TOUR_DE_COU",
  BADGE = "BADGE",
  ORIFLAMME = "ORIFLAMME",
  TALKY_WALKY = "TALKY_WALKY",
  OPTIONS_PRINTED_CLOTHING = "OPTIONS_PRINTED_CLOTHING",
  VALIDATION_ICON = "VALIDATION_ICON",
  ORDER_VISA_ICON = "ORDER_VISA_ICON",
  ORDER_CARD_WITH_GIRL_ICON = "ORDER_CARD_WITH_GIRL_ICON",
  ORDER_MASTERCARD_ICON = "ORDER_MASTERCARD_ICON",
  ORDER_TOOLIP_CREDIT_CARD_ICON = "ORDER_TOOLIP_CREDIT_CARD_ICON",
  SCARF_PERSON_ICON = "SCARF_PERSON_ICON",
  SELECT_DE_PERSONNEL_DISTRIBUTION_ICON = "SELECT_DE_PERSONNEL_DISTRIBUTION_ICON",
  SELECT_DE_PERSONNEL_CHEF_ICON = "SELECT_DE_PERSONNEL_CHEF_ICON",
  SELECT_DE_PERSONNEL_BARMAN_ICON = "SELECT_DE_PERSONNEL_BARMAN_ICON",
  SELECT_DE_PERSONNEL_MAITRE_HOTEL_ICON = "SELECT_DE_PERSONNEL_MAITRE_HOTEL_ICON",
  SELECT_DE_PERSONNEL_ROADS_ICON = "SELECT_DE_PERSONNEL_ROADS_ICON",
  SELECT_DE_PERSONNEL_DEMONSTRATION_PRODUIT_ICON = "SELECT_DE_PERSONNEL_DEMONSTRATION_PRODUIT_ICON",
  SELECT_DE_PERSONNEL_BRAND_AMBASSADEUR_ICON = "SELECT_DE_PERSONNEL_BRAND_AMBASSADEUR_ICON",
  ANIMATEUR_MASCOTTE_ICON = "ANIMATEUR_MASCOTTE_ICON",
  SELECT_DE_PERSONNEL_AUTRE_ICON = "SELECT_DE_PERSONNEL_AUTRE_ICON",
  DRESSING_CARD_POPUP_ICON = "DRESSING_CARD_POPUP_ICON",
  CALENDAR_SELECTION_SIMPLIFIE_ICON = "CALENDAR_SELECTION_SIMPLIFIE_ICON",
  CALENDAR_SELECTION_GROUP_ICON = "CALENDAR_SELECTION_GROUP_ICON",
  CALENDAR_SELECTION_DETAILLE_ICON = "CALENDAR_SELECTION_DETAILLE_ICON",
  TAILLE_DU_STAND_ICON = "TAILLE_DU_STAND_ICON",
  FLYERS_ARROWS_ICON = "FLYERS_ARROWS_ICON",
  INTRODUCTION = "INTRODUCTION",
  BOTTOM_POPUPS_ICON = "BOTTOM_POPUPS_ICON",
  CALENDAR_POPUPS_LEVEL_2_ICON = "CALENDAR_POPUPS_LEVEL_2_ICON",
  BESACES = "BESACES",
  BESACES_PRINT = "BESACES_PRINT",
  CABAS = "CABAS",
  BALLONBAG = "BALLONBAG",
  BILINGUAL_HOSTESS = "BILINGUAL_HOSTESS",
  OUTFIT_ENSEMBLE = "OUTFIT_ENSEMBLE",
  DRESSING_ROOM = "DRESSING_ROOM",
  ROTATION_PERSONNEL = "ROTATION_PERSONNEL",
  PAUSE = "PAUSE",
  NORELA_QUOTE = "NORELA_QUOTE",
}

export interface PicturesProps {
  icon: Icons | undefined;
}

const PicturesVariant = ({ icon }: PicturesProps) => {
  switch (icon) {
    case Icons.CONVERSATION:
      return <ConversationIcon />;
    case Icons.ROADSHOW:
      return <RoadShowIcon />;
    case Icons.SALON:
      return <SalonIcon />;
    case Icons.SOIREEPRESSE:
      return <SoireePresseIcon />;
    case Icons.CONVENTIONO_ICON:
      return <ConventionIcon />;
    case Icons.REMISE_ICON:
      return <RemiseIcon />;
    case Icons.CINEMA_ICON:
      return <CinemaIcon />;
    case Icons.INAUGURATION_ICON:
      return <InaugurationIcon />;
    case Icons.ANIMATION_PROMO_ICON:
      return <AnimationPromoIcon />;
    case Icons.VILAGE_EVENT_ICON:
      return <InaugurationIcon />;
    case Icons.EVENEMENT_SPORT_ICON:
      return <InaugurationIcon />;
    case Icons.SALONS_ICON:
      return <InaugurationIcon />;
    case Icons.GOODIES_ICON:
      return <GoodiesIcon />;
    case Icons.TEST_PRODUCT_ICON:
      return <TestProductIcon />;
    case Icons.ANIMER_ICON:
      return <AnimerIcon />;
    case Icons.EVENEMENT_ICON:
      return <EvenementSportifIcon />;
    case Icons.VILLAGEEVENEMENTIEL_ICON:
      return <VillageEvenementielIcon />;
    case Icons.SALON_SVG_ICON:
      return <SalonIcon2 />;
    case Icons.FESIVAL_ICON:
      return <AnimerIcon />;
    case Icons.ESCAPE_GAME_ICON:
      return <EscapeGameIcon />;
    case Icons.GROUP_OF_PEOPLE_ICON:
      return <GroupOfPeopleIcon />;
    case Icons.COUPLE_ICON:
      return <CoupleIcon />;
    case Icons.HIGHT_TECH_ICON:
      return <HighTechIcon />;
    case Icons.GAMING_ICON:
      return <GamingIcon />;
    case Icons.TEXTILE_ICON:
      return <TextileIcon />;
    case Icons.FOOD_ICON:
      return <FoodIcon />;
    case Icons.COSMETIC_ICON:
      return <CosmeticIcon />;
    case Icons.DRINK_ICON:
      return <DrinkIcon />;
    case Icons.INSTITUIONELL_ICON:
      return <InstitutionelIcon />;
    case Icons.URBAN_ICON:
      return <UrbanIcon />;
    case Icons.INSTITUTIONNEL_ICON:
      return <InstitutionnelIcon />;
    case Icons.EVENEMENTIEL_ICON:
      return <EvenementielIcon />;
    case Icons.CONTACT_US_ICON:
      return <ContactUsIcon />;
    case Icons.LOADING_ICON:
      return <LoadingIcon />;
    case Icons.HIGTH_TECH_TECHNOLOGY_ICON:
      return <HighTechTechnologyIcon />;
    case Icons.MARQUE_ICON:
      return <MarqueIcon />;
    case Icons.TELEPHONE_ICON:
      return <TelephoneIcon />;
    case Icons.FLYERS_GIRL_ICON:
      return <FlyersGirlIcon />;
    case Icons.OUR_ADVICE_ICON:
      return <OurAdviceIcon />;
    case Icons.FLYERS_MEN_ICON:
      return <FlyersMenIcon />;
    case Icons.AIRCRAFT_ICON:
      return <AircraftIcon />;
    case Icons.BUS_ICON:
      return <BusIcon />;
    case Icons.WALK_GIRL_ICON:
      return <WalkGirlIcon />;
    case Icons.ACCUEIL_ICON:
      return <AccueilIcon />;
    case Icons.VESTIAIRE_ICON:
      return <VestiaireIcon />;
    case Icons.PLATEAU_ICON:
      return <RolePlateauIcon />;
    case Icons.VOITURIER_ICON:
      return <RoleVoiturierIcon />;
    case Icons.PLACEMENENT_ICON:
      return <RolePlacementIcon />;
    case Icons.ACCOMPAGNEMENT_ICON:
      return <AccompagnementTransfertIcon />;
    case Icons.ORIENTATION_ICON:
      return <OrientationIcon />;
    case Icons.EMARGEMENT_ICON:
      return <EmargementIcon />;
    case Icons.PASSAGE_ICON:
      return <RolePassageMicroIcon />;
    case Icons.STREET_MARKETING_ICON:
      return <StreetMarketingIcon />;
    case Icons.RETAIL_ICON:
      return <RetailsIcon />;
    case Icons.REGISSEUR_ICON:
      return <RoleRegisseutIcon />;
    case Icons.ANIMATION_ICON:
      return <AnimationEvenemIcon />;
    case Icons.BRAND_AMBOTTOM_OUTFIT_ICON:
      return <RoleBrandAmbasIcon />;
    case Icons.ANIMATION_MICRO_ICON:
      return <AnimationMicroIcon />;
    case Icons.HOTESSE_BILINGUE_ICON:
      return <RoleHotessBilingueIcon />;
    case Icons.HOTESSE_TRILINGUE_ICON:
      return <RoleHotessTrilingueIcon />;
    case Icons.ANIMATEUR_BILINGUE_ICON:
      return <RoleAnimateurBilingueIcon />;
    case Icons.ANIMATION_ENFANTS_ICON:
      return <AnimationEnfansIcon />;
    case Icons.ANIMATION_GAMING_ICON:
      return <AnimationGamingIcon />;
    case Icons.ANIMATION_ROLLER_ICON:
      return <AnimationRollerIcon />;
    case Icons.CONSULTATIONS_BLOCK_ICON:
      return <ConsultationBlockIcon />;
    case Icons.HELP_ICON:
      return <HelpIcon />;
    case Icons.GROUP_OF_GIRLS_ICON:
      return <GroupOfGirlsIcon />;
    case Icons.AVATAR_ICON:
      return <AvatarIcon />;
    case Icons.ALERT_POPUP_WINDOW:
      return <AlertPopupIcon />;
    case Icons.PARKING_TOOLIP_ICON:
      return <ParkingTooltipIcon />;
    case Icons.NOIFICATION_GIRL_ICON:
      return <NotificationAlertGirlIcon />;
    case Icons.INAGURATION_VARIANT:
      return <InagurationIcon />;
    case Icons.SALON_NEW_ICON:
      return <SalonNewIcon />;
    case Icons.EVENEMENT_SPORTIF_NEW:
      return <EvenementSportifNewIcon />;
    case Icons.TELEPHONENIEL_ICON:
      return <TelephonieIcon />;
    case Icons.FLYERS_POPUPS_ICON:
      return <FlyersPopupIcon />;
    case Icons.ATTENTION_POPUPS_ICON:
      return <AttentionIcon />;
    case Icons.PROFESSIONAL_OUTFIT_ICON:
      return <DressingTenueProIcon />;
    case Icons.PERSONAL_OUTFIT_ICON:
      return <DressingTenuePersoIcon />;
    case Icons.JE_FOURNIS_LES_TENUES:
      return <DressingProvidedOutfitIcon />;
    case Icons.SHOES_ICON:
      return <DressingShoesIcon />;
    case Icons.TOP_OUTFIT_ICON:
      return <DressingTopIcon />;
    case Icons.BOTTOM_OUTFIT_ICON:
      return <DressingPantsIcon />;
    case Icons.HAIR_ICON:
      return <DressingCheveuxIcon />;
    case Icons.MAKEUP_ICON:
      return <DressingMakeupIcon />;
    case Icons.DRESSING_POPUP_START:
      return <DressingPopupStartIcon />;
    case Icons.OPTIONS_FORMATING:
      return <OptionsFormationIcon />;
    case Icons.OPTIONS_PANNEAU_DIRECTIONNEL:
      return <OptionsPanneauDirectionnelIcon />;
    case Icons.OPTIONS_PANIERE:
      return <OptionsPaniereIcon />;
    case Icons.OPTIONS_PORTANT:
      return <OptionsPortantIcon />;
    case Icons.OPTIONS_TABLETTE_NUMERIQUE:
      return <OptionsTabletteNumeriqueIcon />;
    case Icons.OPTIONS_TICKET:
      return <OptionsTicketIcon />;
    case Icons.OPTIONS_TOTBAG_R:
      return <OptionsTotbagRIcon />;
    case Icons.OPTIONS_TOTBAG_RV:
      return <OptionsTotbagRvIcon />;
    case Icons.TOUR_DE_COU:
      return <OptionsTourDeCouIcon />;
    case Icons.BADGE:
      return <OptionsBadgeIcon />;
    case Icons.ORIFLAMME:
      return <OptionsOriflammeIcon />;
    case Icons.TALKY_WALKY:
      return <OptionsTalkyWalkyIcon />;
    case Icons.OPTIONS_PRINTED_CLOTHING:
      return <DressingPrintedOutfitIcon />;
    case Icons.VALIDATION_ICON:
      return <ValidationIcon />;
    case Icons.ORDER_VISA_ICON:
      return <OrderVisaIcon />;
    case Icons.ORDER_CARD_WITH_GIRL_ICON:
      return <OrderCardWithGirlIcon />;
    case Icons.ORDER_MASTERCARD_ICON:
      return <OrderMastercardLogoIcon />;
    case Icons.ORDER_TOOLIP_CREDIT_CARD_ICON:
      return <OrderTooltipCreditCardIcon />;
    case Icons.SELECT_DE_PERSONNEL_DISTRIBUTION_ICON:
      return <RoleDistributionIcon />;
    case Icons.SELECT_DE_PERSONNEL_CHEF_ICON:
      return <RoleChefIcon />;
    case Icons.SELECT_DE_PERSONNEL_BARMAN_ICON:
      return <RoleBarmanIcon />; //TODO: change icon
    case Icons.SELECT_DE_PERSONNEL_MAITRE_HOTEL_ICON:
      return <RoleMaitreHotelIcon />;
    case Icons.SELECT_DE_PERSONNEL_ROADS_ICON:
      return <RoleRoadsIcon />;
    case Icons.SELECT_DE_PERSONNEL_DEMONSTRATION_PRODUIT_ICON:
      return <RoleDemonstrationProduitIcon />;
    case Icons.SELECT_DE_PERSONNEL_BRAND_AMBASSADEUR_ICON:
      return <RoleBrandAmbassIcon />;
    case Icons.ANIMATEUR_MASCOTTE_ICON:
      return <RoleAnimateurMascotteIcon />;
    case Icons.SELECT_DE_PERSONNEL_AUTRE_ICON:
      return <RoleOtherIcon />;
    case Icons.DRESSING_CARD_POPUP_ICON:
      return <DressingCardPopupIcon />;
    case Icons.CALENDAR_SELECTION_SIMPLIFIE_ICON:
      return <CalendarSelectionSimplifieIcon />;
    case Icons.CALENDAR_SELECTION_GROUP_ICON:
      return <CalendarSelectionGroupeIcon />;
    case Icons.CALENDAR_SELECTION_DETAILLE_ICON:
      return <CalendarSelectionDetailleIcon />;
    case Icons.TAILLE_DU_STAND_ICON:
      return <TailleDuStandIcon />;
    case Icons.DRESSING_TENUE_PERSONELLE_CLASSIQUE:
      return <DressingTenuePersoClassiqueIcon />;
    case Icons.DRESSING_TENUE_PERSONELLE_CASUAL:
      return <DressingTenuePersoCasualIcon />;
    case Icons.DRESSING_TENUE_PERSONELLE_MORE_OPTIONS:
      return <DressingTenuePersoMoreOptionsIcon />;
    case Icons.FLYERS_ARROWS_ICON:
      return <ArrowsItemIcon />;
    case Icons.INTRODUCTION:
      return <IntroductionGirlIcon />;
    case Icons.BRIEF_MAIL_ICON:
      return <BriefIcon />;
    case Icons.BOTTOM_POPUPS_ICON:
      return <BottomPopupsIcon />;
    case Icons.CALENDAR_POPUPS_LEVEL_2_ICON:
      return <CalendarPopupsLevel2Icon />;
    case Icons.SCARF_PERSON_ICON:
      return <ScarfPersonIcon />;
    case Icons.BESACES:
      return <OptionsBesacesIcon />;
    case Icons.BESACES_PRINT:
      return <OptionsBesacesPrintIcon />;
    case Icons.CABAS:
      return <OptionsCabasIcon />;
    case Icons.BALLONBAG:
      return <OptionsBallonbagIcon />;
    case Icons.BILINGUAL_HOSTESS:
      return <RoleBilingualHostessIcon />;
    case Icons.OUTFIT_ENSEMBLE:
      return <DressingOutfitEnsembleIcon />;
    case Icons.DRESSING_ROOM:
      return <DressingRoomIcon />;
    case Icons.ROTATION_PERSONNEL:
      return <RotationPersonnelIcon />;
    case Icons.PAUSE:
      return <PauseIcon />;
    case Icons.NORELA_QUOTE:
      return <NorelaQuoteIcon />;
    default:
      return <ConventionIcon />;
  }
};

export default PicturesVariant;
