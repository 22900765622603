import { FC } from "react";
import Layout from "../../components/Layout";
import DressingContent from "../../modules/Dressing/DressingContent";

const Dressing: FC = () => {
  return (
    <Layout>
      <DressingContent />
    </Layout>
  );
};

export default Dressing;
