import { Flex } from "@chakra-ui/react";
import BottomBlock from "../../components/BottomActionsBlock/BottomBlock";
import Header from "../../components/Header";
import ContentLayout from "../../components/Layout/ContentLayout";
import MainContainer from "../../components/Layout/MainContainer";
import TotalCard from "../../components/TotalCard/TotalCard";
import OrderBlock from "./components/OrderBlock";
import useServerSubmission from "../../hooks/useServerSubmission";
import { useEffect, useState } from "react";

export default function OrderContent() {
  const { multipleRequestData } = useServerSubmission();
  const [previousHt, setPreviousHt] = useState<any>(undefined);
  const [previousTtc, setPreviousTtc] = useState<any>(undefined);
  const [previousTva, setPreviousTva] = useState<any>(undefined);
  useEffect(() => {
    if (multipleRequestData?.computePriceMultiple?.priceHt)
      setPreviousHt(multipleRequestData?.computePriceMultiple?.priceHt);
    if (multipleRequestData?.computePriceMultiple?.priceTtc)
      setPreviousTtc(multipleRequestData?.computePriceMultiple?.priceTtc);
    if (multipleRequestData?.computePriceMultiple?.priceTva)
      setPreviousTva(multipleRequestData?.computePriceMultiple?.priceTva);
  }, [multipleRequestData]);
  return (
    <ContentLayout>
      <MainContainer>
        <Flex direction="column">
          <Header
            title="Paiement de la commande"
            subtitle={
              "Renseignez vos informations ainsi que votre moyen de paiement pour finaliser la commande.\n" +
              "Vous pouvez modifier votre commande en cliquant sur     à droite dans le récapitulatif de commande."
            }
          />
          <OrderBlock />
        </Flex>

        <Flex direction="column" alignItems="flex-end">
          <TotalCard
            priceHt={previousHt}
            priceTtc={previousTtc}
            priceTva={previousTva}
            priceHtDiscounted={
              multipleRequestData?.computePriceMultiple?.priceHtDiscounted
            }
            discountPercent={
              multipleRequestData?.computePriceMultiple?.discountPercent
            }
            discountErrorMessage={
              multipleRequestData?.computePriceMultiple?.discountErrorMessage
            }
          />
        </Flex>
      </MainContainer>
      <BottomBlock />
    </ContentLayout>
  );
}
