import React, { createContext, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

export type SpecialSchedulesType = {
  PersonnelType: PersonnelType;
  schedule: PersonDateOverride;
}[];

export enum ORGANISATION_TYPE {
  BREAKTIME = "Pause",
  ROTATION = "Rotation",
  NONE = "Aucune",
}

export type DaySchedule = {
  defaultStartHour?: string;
  defaultEndHour?: string;
  defaultSwapHour?: string;
  defaultBreakTime?: string;
  specialSchedules: SpecialSchedulesType;
  organisationType: ORGANISATION_TYPE;
};

export type EventSchedule = {
  day: string;
  schedule: DaySchedule;
};

export type EventDateInfo = {
  days: string[] | null;
  period: {
    startDate: string;
    endDate: string;
  } | null;
  eventSchedule?: EventSchedule[];
};

export enum PersonnelType {
  chefHotesse = "chefHotesse",
  hotessePolyvalente = "hotessePolyvalente",
  hotesseBilingue = "hotesseBilingue",
  animateurBilingue = "animateurBilingue",
  hotesseTrilingue = "hotesseTrilingue",
  accueil = "accueil",
  barman = "barman",
  maitreHotel = "maitreHotel",
  orientation = "orientation",
  placementEnSalle = "placementEnSalle",
  accompagnementTransfert = "accompagnementTransfert",
  voiturier = "voiturier",
  plateau = "plateau",
  vestiaire = "vestiaire",
  responsableVestiaire = "responsableVestiaire",
  emargement = "emargement",
  passageMicro = "passageMicro",
  chef = "chef",
  distribution = "distribution",
  animationEvenementielle = "animationEvenementielle",
  animationMicroStreetMarketing = "animationMicroStreetMarketing",
  roads = "roads",
  demonstrationProduit = "demonstrationProduit",
  animationMicroRetails = "animationMicroRetails",
  brandAmbassadeur = "brandAmbassadeur",
  animateurMascotte = "animateurMascotte",
  regisseur = "regisseur",
  autreDitesNousTout = "autreDitesNousTout",
}

export enum SectionTitle {
  ARTIST = "ARTISTE / TALENT",
  SERVICE = "SERVICE D'ACCUEIL",
  ANIMATION = "ANIMATION MAGASIN",
  STREET_MARKETING = "STREET MARKETING",
}

export const PERSONNEL_TYPE_LABELS: {
  [key in PersonnelType]: string;
} = {
  [PersonnelType.chefHotesse]: "Cheffe hôtesse",
  [PersonnelType.hotessePolyvalente]: "Hôte(sse) polyvalent(e)",
  [PersonnelType.accueil]: "Hôte(sse) Accueil",
  [PersonnelType.barman]: "Barman",
  [PersonnelType.hotesseBilingue]: "Hôte(sse) Bilingue",
  [PersonnelType.animateurBilingue]: "Animateur(trice) Bilingue",
  [PersonnelType.hotesseTrilingue]: "Hôte(sse) Trilingue",
  [PersonnelType.maitreHotel]: "Maître(sse) d’hôtel",
  [PersonnelType.orientation]: "Hôte(sse) Orientation",
  [PersonnelType.placementEnSalle]: "Hôte(sse) Placement en salle",
  [PersonnelType.accompagnementTransfert]: "Accompagnateur / transfert",
  [PersonnelType.voiturier]: "Voiturier",
  [PersonnelType.plateau]: "Hôte(sse) Plateau",
  [PersonnelType.vestiaire]: "Hôte(sse) Vestiaire",
  [PersonnelType.responsableVestiaire]: "Responsable vestiaire",
  [PersonnelType.emargement]: "Hôte(sse) Emargement",
  [PersonnelType.passageMicro]: "Passage micro",
  [PersonnelType.chef]: "Chef d’équipe",
  [PersonnelType.distribution]: "Distribueur / Diffuseur",
  [PersonnelType.animationEvenementielle]: "Animatrice événementielle",
  [PersonnelType.animationMicroStreetMarketing]:
    "Animateur Micro Street Marketing",
  [PersonnelType.roads]: "Roads",
  [PersonnelType.demonstrationProduit]: "Démonstrateur produit",
  [PersonnelType.animationMicroRetails]: "Animateur Micro Magasin",
  [PersonnelType.brandAmbassadeur]: "Brand ambassadeur",
  [PersonnelType.animateurMascotte]: "Animateur mascotte",
  [PersonnelType.regisseur]: "Régisseur",
  [PersonnelType.autreDitesNousTout]: "Autre ?",
};

export type PersonnelSelectionInfo = {
  persons: {
    [key in PersonnelType]?: PersonInfo;
  };
  rolesToShow?: PersonnelType[];
  tabsToHide?: SectionTitle[];
};

export type PersonDateOverride = {
  isWorking: boolean;
  startHour?: string;
  endHour?: string;
  organisationType?: ORGANISATION_TYPE;
  swapHour?: string;
  breakTime?: string;
};

export type PersonInfo = {
  title: string;
  count: number;
  role: PersonnelType;
  // the index of the array is the number of the person
  dateOverrides?: ({ [date: string]: PersonDateOverride } | undefined)[];
};

export interface UserInformation {
  id?: number | undefined;
  selectDePersonnelInfo: PersonnelSelectionInfo;
  historyPageContainer?: any;
  calendarInfo: EventDateInfo;
  outfitInfos?: Record<string, any>;
  orderInfos?: Record<string, any>;
  cache?: Record<string, any>;
  calendarSelectionInfos?: Record<string, any>;
  activePage?: Record<string, any>;
  paymentIntentId?: string;
  quoteId?: string;
  quoteNumber?: string;
  logo?: string;
  discountCode?: string;
}

const defaultUserInfos: UserInformation = {
  id: undefined,
  outfitInfos: {},
  selectDePersonnelInfo: { persons: {} },
  historyPageContainer: {},
  calendarInfo: {
    days: null,
    period: null,
    eventSchedule: [],
  },
  orderInfos: {},
  calendarSelectionInfos: {},
  activePage: {
    route: "",
    pageId: "",
  },
};

const UserContext = createContext<ContextProps>({
  userInfos: defaultUserInfos,
  setUserInfos: () => {},
  setCalendarInfo: () => {},
  decrementWorker: () => {},
  incrementWorker: () => {},
  resetInfos: () => {},
});

export const UserProvider: React.FunctionComponent<ProviderProps> = ({
  children,
}) => {
  const [userInfos, setUserInfos] = useState<UserInformation>(() => {
    const userInfosFromLocalStorage = localStorage.getItem("userInfos");
    if (!userInfosFromLocalStorage) return { ...defaultUserInfos };
    try {
      return JSON.parse(userInfosFromLocalStorage);
    } catch (e) {
      return { ...defaultUserInfos };
    }
  });

  useEffect(() => {
    localStorage.setItem("userInfos", JSON.stringify(userInfos));

    Sentry.setContext("Commande", {
      userInfos: JSON.stringify(userInfos),
    });
  }, [userInfos]);

  return (
    <UserContext.Provider
      value={{
        userInfos,
        setUserInfos,
        setCalendarInfo: (calendarInfo: EventDateInfo) => {
          setUserInfos({
            ...userInfos,
            calendarInfo,
          });
        },
        decrementWorker: (personnelType: PersonnelType) => {
          const selectDePersonnel = userInfos?.selectDePersonnelInfo?.persons;
          const count = selectDePersonnel?.[personnelType]
            ? selectDePersonnel?.[personnelType || ""]?.count ?? 0
            : 0;
          if (selectDePersonnel) {
            if (count - 1 <= 0) {
              delete selectDePersonnel?.[personnelType];
            } else if (selectDePersonnel[personnelType]) {
              selectDePersonnel![personnelType]!.count =
                count > 0 ? count - 1 : count;
            }
          }
          setUserInfos({
            ...userInfos,
            selectDePersonnelInfo: {
              ...userInfos?.selectDePersonnelInfo,
              persons: {
                ...selectDePersonnel,
              },
            },
          });
        },
        incrementWorker: (personnelType: PersonnelType) => {
          const selectDePersonnel = userInfos?.selectDePersonnelInfo?.persons;
          const count = selectDePersonnel?.[personnelType]
            ? selectDePersonnel?.[personnelType || ""]?.count ?? 0
            : 0;
          setUserInfos({
            ...userInfos,
            selectDePersonnelInfo: {
              ...userInfos?.selectDePersonnelInfo,
              persons: {
                ...selectDePersonnel,
                [personnelType]: {
                  ...selectDePersonnel?.[personnelType],
                  title: PERSONNEL_TYPE_LABELS[personnelType],
                  count: count + 1,
                  role: personnelType,
                },
              },
            },
          });
        },
        resetInfos() {
          setUserInfos({ ...defaultUserInfos });
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = (): ContextProps => useContext(UserContext);

export interface ContextProps {
  userInfos: UserInformation;
  setUserInfos: (userInfos: UserInformation) => void;
  setCalendarInfo: (calendarInfo: EventDateInfo) => void;
  decrementWorker: (personnelType: PersonnelType) => void;
  incrementWorker: (personnelType: PersonnelType) => void;
  resetInfos: () => void;
}

export interface ProviderProps {
  children: JSX.Element;
}
