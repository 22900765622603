import { useMutation } from "@apollo/client";
import { Box, Button, Checkbox, Flex, Link, Text } from "@chakra-ui/react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  PaymentIntentResult,
  Stripe,
  StripeCardElement,
  StripeCardNumberElement,
  StripeElementChangeEvent,
} from "@stripe/stripe-js";
import { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  PaymentIntentOutput,
  ResponseOutput,
  getPaymentIntentForOrder,
  sendDepositEmails,
} from "../../../apollo/payment/mutation";
import CustomTooltip from "../../../components/CustomTooltip";
import { useCustomerContext } from "../../../context/CustomerContext";
import { useOnboardingContext } from "../../../context/OnboardingContext";
import { useOptionContext } from "../../../context/OptionsContext";
import { useSegment } from "../../../context/SegmentContext";
import { useSessionContext } from "../../../context/SessionContext";
import { useUserContext } from "../../../context/UserContext";
import { PageURL } from "../../../types/pages";
import { colors } from "../../../ui/theme";
import {
  getContactInfo,
  parseOperationInfo,
  parseOptions,
  parsePersonnel,
} from "../../../utils/parseUserData";
import { HelpType, useHelpContext } from "../../../context/HelpContext";

async function payWithCard(
  paymentIntentClientSecret: string,
  card: StripeCardNumberElement,
  stripe: Stripe
): Promise<PaymentIntentResult> {
  return await stripe.confirmCardPayment(paymentIntentClientSecret, {
    payment_method: {
      card,
    },
  });
}

const CardFields = (): ReactElement => {
  const [paymentError, setPaymentError] = useState(null as null | string);
  const [cvcError, setCvcError] = useState(null as null | string);
  const [expiryError, setExpiryError] = useState(null as null | string);
  const [cardError, setCardError] = useState(null as null | string);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { userInfos, setUserInfos } = useUserContext();
  const { customerInfo } = useCustomerContext();
  const { optionInfo } = useOptionContext();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState(
    null as null | string
  );
  const [createInvoice] = useMutation<{
    getPaymentIntentForOrder: PaymentIntentOutput;
  }>(getPaymentIntentForOrder);
  const [doSendDepositEmails] = useMutation<{
    sendDepositEmails: ResponseOutput;
  }>(sendDepositEmails);

  const stripe = useStripe();
  const elements = useElements();
  const { getAllInfo } = useOnboardingContext();
  const { sessionInfo, updateHistory } = useSessionContext();
  const submit = () => {
    if (isLoading || !userInfos || !customerInfo || !acceptedTerms) {
      return;
    }
    setIsLoading(true);

    const cardElement = elements?.getElement(CardNumberElement);

    stripe
      ?.createPaymentMethod({
        type: "card",
        card: cardElement as
          | StripeCardNumberElement
          | StripeCardElement
          | { token: string },
      })
      .then(async (paymentMethod) => {
        if (paymentMethod.error) {
          setIsLoading(false);
          setPaymentError(paymentMethod.error.message ?? null);
        } else {
          const args = {
            input: parsePersonnel(
              userInfos.selectDePersonnelInfo || { persons: {} },
              userInfos.calendarInfo || {
                days: null,
                period: null,
                eventSchedule: undefined,
              }
            ),
            options: parseOptions(optionInfo!, getAllInfo()),
            contact: getContactInfo(customerInfo!),
            outfit: userInfos.outfitInfos,
            operationInfo: parseOperationInfo(
              userInfos,
              getAllInfo(),
              sessionInfo
            ),
            discountCode: userInfos.discountCode,
          };
          let paymentIntentResult: PaymentIntentResult | null = null;
          if (paymentIntentClientSecret === null) {
            paymentIntentResult = await createInvoice({ variables: args })
              .then(async (res) => {
                let clientSecret =
                  res.data?.getPaymentIntentForOrder.paymentIntentClientSecret;
                if (clientSecret === undefined) return null;
                setPaymentIntentClientSecret(clientSecret);
                return await payWithCard(clientSecret, cardElement!, stripe);
              })
              .finally(() => {
                setIsLoading(false);
              });
          } else {
            paymentIntentResult = await payWithCard(
              paymentIntentClientSecret,
              cardElement!,
              stripe
            );
          }

          setIsLoading(false);
          if (paymentIntentResult?.error) {
            setPaymentError(
              paymentIntentResult.error.message ??
                "Erreur lors du paiement, veuillez réessayer."
            );
          } else if (paymentIntentResult) {
            setUserInfos({
              ...userInfos,
              paymentIntentId: paymentIntentResult.paymentIntent.id,
            });
            // Send deposit emails
            const newArgs = {
              paymentIntentId: paymentIntentResult.paymentIntent.id,
              input: args.input,
              options: args.options,
              outfit: args.outfit,
              contact: args.contact,
              operationInfo: parseOperationInfo(
                userInfos,
                getAllInfo(),
                sessionInfo
              ),
              discountCode: userInfos.discountCode,
            };
            doSendDepositEmails({ variables: newArgs });
            updateHistory(PageURL.VALIDATION);
            navigate(PageURL.VALIDATION);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createOnChangeHandler =
    (errorSetter: (str: string | null) => any) =>
    (event: StripeElementChangeEvent) => {
      errorSetter(event.error ? event.error.message : null);
    };
  const reassuranceText =
    "Nous nous efforçons de protéger votre sécurité et votre vie privée. Notre système de paiement sécurisé passe par la plateforme Stripe et chiffre vos données lors de la transmission. Nous ne partageons pas les détails de votre carte de crédit avec les vendeurs tiers, et nous ne vendons pas vos données personnelles à autrui.";

  const { track } = useSegment();

  const { setHelp } = useHelpContext();
  const [showHelp, setShowHelp] = useState(true);
  useEffect(() => {
    if (showHelp) {
      setHelp(HelpType.PAIMENT_WARNING);
      setShowHelp(false);
    } else setHelp(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex pb="32px" direction="column" borderRadius="24px">
      <Flex direction="column" gap="24px">
        <Flex direction="column">
          <Text variant="xl" fontWeight="700" textAlign="left">
            Numéro de carte bancaire
          </Text>
          <Text variant="sm" opacity="0.7" textAlign="left">
            Entrez le numéro à 16 chiffres sur votre carte
          </Text>
          <FieldWrapper
            style={{
              borderColor: cardError ? colors.red.default : undefined,
            }}
          >
            <CardNumberElement
              onChange={createOnChangeHandler(setCardError)}
              options={{
                showIcon: true,
              }}
            />
          </FieldWrapper>
          <Text textAlign="left" color={colors.red.default} variant="sm">
            {cardError}
          </Text>
        </Flex>

        <Flex direction="column">
          <Text variant="xl" fontWeight="700" textAlign="left">
            Numéro CVV
          </Text>
          <Text variant="sm" opacity="0.7" textAlign="left">
            Entrez les 3 ou 4 numéros de votre carte
          </Text>
          <FieldWrapper
            style={{
              borderColor: cvcError ? colors.red.default : undefined,
            }}
          >
            <CardCvcElement onChange={createOnChangeHandler(setCvcError)} />
          </FieldWrapper>
          <Text textAlign="left" color={colors.red.default} variant="sm">
            {cvcError}
          </Text>
        </Flex>
        <Flex direction="column">
          <Text variant="xl" fontWeight="700" textAlign="left">
            Date d’expiration (MM/YY)
          </Text>
          <Text variant="sm" opacity="0.7" textAlign="left">
            Entrez la date d’expiration de votre catre
          </Text>
          <FieldWrapper
            style={{
              borderColor: expiryError ? colors.red.default : undefined,
            }}
          >
            <CardExpiryElement
              onChange={createOnChangeHandler(setExpiryError)}
            />
          </FieldWrapper>
          <Text textAlign="left" color={colors.red.default} variant="sm">
            {expiryError}
          </Text>
        </Flex>
        <Checkbox
          onChange={(e) => setAcceptedTerms(e.target.checked)}
          display="flex"
          flexWrap="nowrap"
          width="100%"
        >
          <Flex gap="5px">
            <Text variant="md">J’ai lu et j’accepte les</Text>
            <Link href="/cgv" target="_blank">
              <Text variant="md" fontWeight="700" textDecoration="underline">
                'Conditions Générales de Vente'
              </Text>
            </Link>
          </Flex>
        </Checkbox>
        <Box>
          <Text
            textAlign="center"
            color={colors.red.default}
            variant="md"
            mb="0.5em"
          >
            {paymentError}
          </Text>
          <CustomTooltip content={[reassuranceText]} padding="1rem">
            <Text fontWeight="bold" textDecoration="underline" mb="1rem">
              Votre transaction est sécurisée.
            </Text>
          </CustomTooltip>
          <Button
            w="100%"
            padding="16px 0"
            backgroundColor={colors.additional.lightMode}
            type="button"
            color="white"
            isLoading={isLoading}
            onClick={() => {
              submit();
              track("Pay order");
              setPaymentError(null);
            }}
            disabled={acceptedTerms === false}
          >
            Finaliser ma commande
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

const FieldWrapper = styled("label")`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  padding: 11px 16px;
`;

export default CardFields;
