export const DressingTenuePersoIcon = () => (
  <svg
    width="190"
    height="100"
    viewBox="0 0 190 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M117.923 28.4823C119.298 27.7948 120.058 26.528 119.62 25.6529C119.183 24.7778 117.713 24.6259 116.338 25.3138C115.785 25.581 115.303 25.9769 114.934 26.4682L109.145 29.4545L110.604 32.1417L116.157 28.9124C116.771 28.9122 117.377 28.7647 117.923 28.4823Z"
      fill="#FFB6B6"
    />
    <path
      d="M85.1177 97.9512L87.8738 97.951L89.1849 87.3203L85.1172 87.3206L85.1177 97.9512Z"
      fill="#FFB6B6"
    />
    <path
      d="M94.5607 97.8888C94.6465 98.0333 94.6918 98.4998 94.6918 98.6679C94.6918 99.1845 94.273 99.6033 93.7563 99.6033H85.2211C84.8687 99.6033 84.583 99.3176 84.583 98.9652V98.6098C84.583 98.6098 84.1608 97.5418 85.0301 96.2254C85.0301 96.2254 86.1105 97.2562 87.7249 95.6417L88.201 94.7793L91.6471 97.2996L93.5573 97.5348C93.9752 97.5862 94.3457 97.5268 94.5606 97.8888H94.5607H94.5607Z"
      fill="#2F2E41"
    />
    <path
      d="M89.4473 86.261L91.8949 87.5279L97.9468 78.6902L94.3344 76.8203L89.4473 86.261Z"
      fill="#FFB6B6"
    />
    <path
      d="M97.8606 90.5465C97.8704 90.7143 97.6961 91.1494 97.6189 91.2986C97.3813 91.7574 96.8169 91.9368 96.3581 91.6992L88.7785 87.775C88.4655 87.613 88.3431 87.2279 88.5052 86.9149L88.6685 86.5994C88.6685 86.5994 88.7846 85.4568 90.1618 84.6875C90.1618 84.6875 90.6474 86.0996 92.8233 85.4082L93.6426 84.8612L95.5442 88.6837L97.1323 89.7708C97.4798 90.0086 97.8362 90.1261 97.8606 90.5465L97.8606 90.5465V90.5465Z"
      fill="#2F2E41"
    />
    <path
      d="M82.32 13.5723H89.5478L89.39 15.929L94.1034 30.9528L94.9872 40.9687L86.1496 42.4417L82.0254 23.8828L82.9091 15.3398L82.32 13.5723Z"
      fill="#F7B80B"
    />
    <path
      d="M82.7608 15.4863L75.1016 19.3159L79.8149 35.2236L78.9312 51.4258C78.9312 51.4258 91.0092 55.2554 90.42 44.3557C89.8308 33.4561 89.8308 31.6885 89.8308 31.6885L82.7608 15.4863H82.7608Z"
      fill="#2F2E41"
    />
    <path
      d="M94.9863 17.4711C94.9863 17.4711 96.9011 16.0759 97.7849 18.138C98.6686 20.2001 104.413 30.6579 104.413 30.6579L113.398 26.681L114.871 29.9214C114.871 29.9214 107.506 36.9915 103.087 36.1077C98.6686 35.224 95.1336 27.5648 95.1336 27.5648L94.9863 17.4711H94.9863Z"
      fill="#2F2E41"
    />
    <path
      d="M79.8158 48.4808C79.8158 48.4808 79.5212 53.1942 80.1104 57.0238C80.6996 60.8534 84.6765 93.4051 84.6765 93.4051L89.6845 93.1105L90.1263 54.9617L97.1964 66.156L91.0101 82.9473L95.2816 84.8621L104.266 66.4505L98.0801 45.535L96.3126 40.2324L83.0563 41.7054L79.8158 48.4808V48.4808Z"
      fill="#2F2E41"
    />
    <path
      d="M99.5528 49.6578C99.5528 49.6578 91.5989 52.0145 89.8314 31.6881L89.4316 15.2988L96.9015 17.2534L96.0177 31.9826L99.5528 49.6578Z"
      fill="#2F2E41"
    />
    <path
      d="M86.0018 11.6578C88.9303 11.6578 91.3043 9.28379 91.3043 6.35528C91.3043 3.42676 88.9303 1.05273 86.0018 1.05273C83.0732 1.05273 80.6992 3.42676 80.6992 6.35528C80.6992 9.28379 83.0732 11.6578 86.0018 11.6578Z"
      fill="#FFB6B6"
    />
    <path
      d="M82.6144 11.5095C82.6144 11.5095 80.2577 8.85825 79.9631 7.6799C79.6685 6.50156 79.6685 3.26112 79.9631 2.96653C80.2577 2.67195 83.4982 -0.273908 84.6765 0.020676C85.8548 0.31526 88.1906 1.78819 90.2736 1.78819C90.8628 1.78819 91.1574 4.73405 91.1574 4.73405L88.8007 5.02863C88.8007 5.02863 85.8548 3.5557 86.1494 5.02863C86.444 6.50156 84.0873 6.50156 84.0873 6.50156L84.3819 7.6799L83.4982 7.97449L82.909 6.79615C82.909 6.79615 83.2036 9.44742 84.0873 10.6258C84.9711 11.8041 82.6144 11.5095 82.6144 11.5095H82.6144Z"
      fill="#2F2E41"
    />
    <path
      d="M80.2663 53.9248C81.0748 55.2325 82.4048 55.8754 83.237 55.3607C84.0692 54.8461 84.0882 53.369 83.2793 52.0609C82.9633 51.534 82.5258 51.0903 82.0032 50.7672L78.5078 45.2695L75.9629 46.9648L79.6789 52.2045C79.7345 52.8166 79.9359 53.4065 80.2663 53.9248V53.9248Z"
      fill="#FFB6B6"
    />
    <path
      d="M76.28 19.3164H74.807C74.807 19.3164 68.9153 32.5728 69.2099 35.224C69.2099 35.224 69.2099 37.5807 70.6828 39.3482C72.1558 41.1157 78.3421 51.1317 78.3421 51.1317L80.9933 49.0696L75.6908 36.697L78.9312 23.7352L76.28 19.3164Z"
      fill="#2F2E41"
    />
    <path
      d="M108.082 99.5935C108.082 99.7055 108.048 99.7963 108.006 99.7963H69.2131C69.1709 99.7963 69.1367 99.7055 69.1367 99.5935C69.1367 99.4814 69.1709 99.3906 69.2131 99.3906H108.006C108.048 99.3906 108.082 99.4814 108.082 99.5935Z"
      fill="#E6E6E6"
    />
  </svg>
);
