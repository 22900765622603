export const AnimationPromoIcon = () => (
  <svg width="152" height="101" viewBox="0 0 152 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_961_5184)">
      <path d="M21.6949 88.1641L24.3689 88.2273L23.3588 96.3678L21.5469 96.3251L21.6949 88.1641Z" fill="#FFB6B6"/>
      <path d="M27.7634 100.105L27.7634 100.105C27.7634 99.8842 27.6484 99.6843 27.4708 99.5965L25.4751 98.6107L24.1145 95.4551L24.0939 92.276L21.2903 91.9463L20.8848 93.9715C20.8848 93.9715 21.7197 93.847 20.6889 94.7418C19.6582 95.6367 20.1545 97.3873 20.1545 97.3873L19.9297 100.66H21.785L21.8846 98.6245C23.3277 99.2368 23.6816 100.66 23.6816 100.66H27.2782C27.5462 100.66 27.7634 100.411 27.7634 100.105H27.7634H27.7634Z" fill="#1A202C"/>
      <path d="M25.6109 88.2534L28.2849 88.3167L27.2749 96.4572L25.4629 96.4144L25.6109 88.2534Z" fill="#FFB6B6"/>
      <path d="M31.6794 100.195H31.6794C31.6794 99.9736 31.5644 99.7736 31.3868 99.6859L29.3911 98.7001L28.0306 95.5445L28.0099 92.3653L25.2063 92.0356L24.8008 94.0609C24.8008 94.0609 25.6357 93.9363 24.6049 94.8312C23.5742 95.7261 24.0705 97.4767 24.0705 97.4767L23.8457 100.749H25.7011L25.8007 98.7139C27.2437 99.3261 27.5976 100.749 27.5976 100.749H31.1942C31.4622 100.749 31.6794 100.501 31.6794 100.195L31.6794 100.195H31.6794Z" fill="#1A202C"/>
      <path d="M22.5452 40.5049L19.5138 47.7431C18.5033 50.1559 18.1831 52.802 18.5888 55.3862L20.0622 90.9449H25.8873L28.4299 59.2798L28.9316 71.7879L23.1002 89.609H28.8356L36.1973 71.7879L36.7431 58.0655L34.1277 47.417L22.5452 40.5049L22.5452 40.5049Z" fill="#1A202C"/>
      <path d="M89.4415 21.8781H60.5086C58.5254 21.8759 56.9183 20.2688 56.916 18.2856V5.84891C56.9183 3.86571 58.5254 2.25858 60.5086 2.25634C69.7409 -0.301479 79.4237 -0.0617849 89.4415 2.25634C91.4246 2.25869 93.0316 3.86579 93.0338 5.84891V18.2856C93.0316 20.2687 91.4246 21.8758 89.4415 21.8781H89.4415Z" fill="#F2F2F2"/>
      <path d="M73.4377 36.3255H38.6098C38.2537 36.3252 37.9652 36.0366 37.9648 35.6806V27.0414C37.9653 26.6854 38.2538 26.3969 38.6098 26.3965H73.4377C73.7937 26.3969 74.0822 26.6854 74.0827 27.0414V35.6808C74.0822 36.0368 73.7937 36.3253 73.4377 36.3257V36.3255ZM38.6098 26.6545C38.3962 26.6547 38.223 26.8278 38.2228 27.0414V35.6808C38.223 35.8944 38.3962 36.0675 38.6098 36.0677H73.4377C73.6513 36.0675 73.8245 35.8944 73.8247 35.6808V27.0414C73.8245 26.8278 73.6513 26.6547 73.4377 26.6545H38.6098Z" fill="#E6E6E6"/>
      <path d="M49.3815 29.5542C49.1321 29.5595 48.9343 29.7659 48.9396 30.0152C48.9447 30.2571 49.1396 30.452 49.3815 30.4571H70.6651C70.9143 30.4667 71.1241 30.2723 71.1336 30.0231C71.1431 29.7739 70.9488 29.5641 70.6996 29.5546H49.3815V29.5542Z" fill="#E6E6E6"/>
      <path d="M49.3815 32.2637C49.1321 32.2689 48.9343 32.4753 48.9396 32.7247C48.9447 32.9666 49.1396 33.1615 49.3815 33.1666H70.6651C70.9143 33.1761 71.1241 32.9818 71.1336 32.7326C71.1431 32.4834 70.9488 32.2736 70.6996 32.2641H49.3815V32.2637Z" fill="#E6E6E6"/>
      <path d="M43.4017 33.1672C44.3992 33.1672 45.2078 32.3587 45.2078 31.3612C45.2078 30.3638 44.3992 29.5552 43.4017 29.5552C42.4043 29.5552 41.5957 30.3638 41.5957 31.3612C41.5957 32.3587 42.4043 33.1672 43.4017 33.1672Z" fill="#E6E6E6"/>
      <path d="M85.4865 64.0364H50.6586C50.3026 64.0361 50.014 63.7475 50.0137 63.3915V54.7524C50.0141 54.3964 50.3026 54.1079 50.6586 54.1074H85.4865C85.8425 54.1079 86.131 54.3964 86.1315 54.7524V63.3917C86.131 63.7477 85.8425 64.0362 85.4865 64.0366V64.0364ZM50.6586 54.3654C50.445 54.3656 50.2719 54.5387 50.2716 54.7524V63.3917C50.2719 63.6053 50.445 63.7784 50.6586 63.7787L81.5302 63.4744C81.7438 63.4742 84.9832 62.2661 84.9834 62.0525L85.8735 54.7524C85.8733 54.5387 85.7002 54.3656 85.4865 54.3654H50.6586Z" fill="#E6E6E6"/>
      <path d="M61.4299 58.5533C61.1805 58.5481 60.9741 58.7459 60.9689 58.9952C60.9636 59.2446 61.1614 59.451 61.4108 59.4562C61.4171 59.4564 61.4235 59.4564 61.4299 59.4562H82.7135C82.9627 59.4658 83.1724 59.2715 83.182 59.0223C83.1915 58.773 82.9972 58.5633 82.748 58.5537H61.4299V58.5533V58.5533Z" fill="#E6E6E6"/>
      <path d="M55.4506 60.8782C56.448 60.8782 57.2566 60.0696 57.2566 59.0721C57.2566 58.0747 56.448 57.2661 55.4506 57.2661C54.4531 57.2661 53.6445 58.0747 53.6445 59.0721C53.6445 60.0696 54.4531 60.8782 55.4506 60.8782Z" fill="#E6E6E6"/>
      <path d="M98.7385 49.5784H63.9106C63.5545 49.5781 63.266 49.2895 63.2656 48.9335V40.2944C63.2661 39.9384 63.5546 39.6499 63.9106 39.6494H98.7385C99.0945 39.6499 99.383 39.9384 99.3834 40.2944V48.9337C99.383 49.2897 99.0945 49.5782 98.7385 49.5786V49.5784ZM63.9106 39.9074C63.6969 39.9076 63.5238 40.0807 63.5236 40.2944V48.9337C63.5238 49.1473 63.6969 49.3204 63.9106 49.3207L94.7821 49.0164C94.9958 49.0162 98.2351 47.8081 98.2353 47.5945L99.1255 40.2944C99.1252 40.0807 98.9521 39.9076 98.7385 39.9074H63.9106Z" fill="#E6E6E6"/>
      <path d="M74.6818 44.0953C74.4325 44.09 74.2261 44.2879 74.2208 44.5372C74.2155 44.7866 74.4134 44.993 74.6627 44.9982C74.6691 44.9984 74.6754 44.9984 74.6818 44.9982H95.9654C96.2146 45.0078 96.4244 44.8135 96.4339 44.5643C96.4435 44.315 96.2492 44.1053 95.9999 44.0957H74.6818V44.0953V44.0953Z" fill="#E6E6E6"/>
      <path d="M68.7025 46.4202C69.7 46.4202 70.5085 45.6116 70.5085 44.6141C70.5085 43.6167 69.7 42.8081 68.7025 42.8081C67.7051 42.8081 66.8965 43.6167 66.8965 44.6141C66.8965 45.6116 67.7051 46.4202 68.7025 46.4202Z" fill="#E6E6E6"/>
      <path d="M61.3651 13.7662C62.4218 14.117 63.5296 14.4722 64.6575 14.4122C65.1708 14.385 65.6646 14.2626 66.1302 14.0448C66.6078 13.8215 67.0442 13.5183 67.5275 13.3067C69.304 12.5292 71.0658 13.8574 72.7226 14.356C73.5492 14.6047 74.4184 14.6642 75.227 14.3178C76.1203 13.935 76.7941 13.2046 77.5296 12.5947C79.1787 11.2274 81.3304 10.4902 83.4707 10.5459C85.8187 10.6071 88.0913 11.6441 89.7082 13.3405C89.8437 13.4827 90.059 13.267 89.9236 13.125C88.5106 11.6425 86.6066 10.643 84.579 10.3402C82.5471 10.0366 80.4377 10.4422 78.6511 11.4541C77.747 11.9661 77.0219 12.667 76.2255 13.3212C75.4499 13.9582 74.5876 14.3687 73.5651 14.2333C71.8035 14 70.2883 12.6427 68.4613 12.7657C67.2945 12.8443 66.4612 13.7222 65.3632 14.0021C64.0533 14.336 62.6842 13.8834 61.4461 13.4724C61.2596 13.4104 61.1796 13.7046 61.3651 13.7662L61.3651 13.7662V13.7662Z" fill="#3F3D56"/>
      <path d="M64.6716 15.3895C65.2949 15.3895 65.8002 14.8842 65.8002 14.2609C65.8002 13.6376 65.2949 13.1323 64.6716 13.1323C64.0483 13.1323 63.543 13.6376 63.543 14.2609C63.543 14.8842 64.0483 15.3895 64.6716 15.3895Z" fill="#F7B80B"/>
      <path d="M74.4137 15.4393C75.0371 15.4393 75.5423 14.934 75.5423 14.3107C75.5423 13.6874 75.0371 13.1821 74.4137 13.1821C73.7904 13.1821 73.2852 13.6874 73.2852 14.3107C73.2852 14.934 73.7904 15.4393 74.4137 15.4393Z" fill="#F7B80B"/>
      <path d="M84.3083 11.6112C84.9316 11.6112 85.4369 11.1059 85.4369 10.4826C85.4369 9.85929 84.9316 9.354 84.3083 9.354C83.685 9.354 83.1797 9.85929 83.1797 10.4826C83.1797 11.1059 83.685 11.6112 84.3083 11.6112Z" fill="#F7B80B"/>
      <path d="M18.8223 49.6375C15.4855 59.1382 19.7369 66.6234 18.8547 61.7949C18.1173 57.7597 30.6815 45.4805 34.4634 48.0125C34.3841 47.6027 31.9803 40.2148 31.7447 40.1807C32.0743 39.3505 31.6149 39.3426 31.5962 38.9173C31.5247 37.2743 31.9066 35.5689 32.6504 33.8136C33.1379 32.4575 33.1465 31.2923 32.674 30.3529C32.2674 29.5807 31.5844 28.9904 30.7615 28.7L28.2833 25.7071C27.6378 24.9281 26.6857 23.2472 25.6741 23.2535L20.2438 23.0595C20.0886 22.9662 18.1819 24.6059 16.3252 25.4105C14.3116 26.284 14.727 28.6679 14.7321 28.6984L14.7368 28.7268L14.7589 28.7471L17.7187 37.4589L20.3929 39.9652C20.2574 40.3581 20.2914 40.653 20.5451 40.8222C20.1809 41.0916 18.5607 49.152 18.8223 49.4344V49.6375L18.8223 49.6375Z" fill="#F7B80B"/>
      <path d="M46.7252 32.3955C46.3214 32.5566 45.9621 32.8121 45.6772 33.1406L41.377 34.6147L42.1383 36.6403L46.4184 34.9269C46.8522 34.9572 47.2869 34.8833 47.6862 34.7112C48.6911 34.2942 49.2909 33.4378 49.0252 32.7983C48.7595 32.1589 47.7301 31.9785 46.7252 32.3955V32.3955Z" fill="#FFB6B6"/>
      <path d="M23.7852 25.8949L33.5953 39.137L45.0948 35.9702L44.5949 32.9704L35.8383 34.6289L30.9532 27.1188C29.358 24.0353 26.214 23.4143 23.7852 25.8947V25.8949Z" fill="#F7B80B"/>
      <path d="M26.9381 21.9319L19.0853 22.6469L18.8535 14.4851L27.4087 14.2422L26.9381 21.9319Z" fill="#1A202C"/>
      <path d="M22.9669 20.8366C25.243 20.8366 27.0881 18.9915 27.0881 16.7154C27.0881 14.4394 25.243 12.5942 22.9669 12.5942C20.6908 12.5942 18.8457 14.4394 18.8457 16.7154C18.8457 18.9915 20.6908 20.8366 22.9669 20.8366Z" fill="#FFB6B6"/>
      <path d="M26.2823 12.6997C26.2533 11.6771 24.9809 11.5664 23.9895 11.314L23.0905 10.8475C22.2081 10.6228 21.2719 10.7808 20.5121 11.2826L18.1149 12.7348C17.0386 13.4455 16.8676 14.5944 16.4299 15.8077L15.6377 17.5838C15.2068 18.7782 15.7762 20.3608 16.2118 21.5535L16.3943 22.0533L16.1089 23.689L21.1044 23.4557L21.5484 20.9306L20.6957 19.9721C20.3932 19.632 20.3686 19.1269 20.6368 18.7591H20.6368C21.6014 17.4359 21.4226 15.5816 21.4226 15.5816L22.6433 16.2475C23.4454 16.3569 24.1602 16.345 24.7891 16.2135C25.4808 16.069 26.0686 15.78 26.5544 15.3492L26.9896 16.813C27.1017 18.6349 26.7613 19.1185 25.1374 20.9006L27.9851 22.6884L27.8798 21.7209C27.6292 19.4194 28.3931 17.6045 28.286 15.7314C28.2119 14.4361 27.4416 13.2821 26.2823 12.6997H26.2823H26.2823Z" fill="#1A202C"/>
      <path d="M26.965 47.4496C26.5626 47.2849 26.1267 47.2189 25.6936 47.2572L21.5769 45.3291L20.7168 47.3148L24.9854 49.0568C25.2762 49.3801 25.6402 49.6289 26.047 49.7826C27.0594 50.181 28.0858 49.982 28.3391 49.3375C28.5924 48.693 27.9774 47.848 26.965 47.4496H26.965Z" fill="#FFB6B6"/>
      <path d="M14.9867 26.8347L12.8398 43.1744L23.3099 48.8877L25.035 46.3831L17.5854 41.4911L19.291 32.6958C20.2866 29.3699 18.4571 26.7386 14.9868 26.8346L14.9867 26.8347Z" fill="#F7B80B"/>
      <path d="M125.153 97.7585L122.939 97.7583L121.887 89.2363L125.153 89.2366L125.153 97.7585Z" fill="#FFB6B6"/>
      <path d="M117.57 97.7085C117.501 97.8244 117.465 98.1983 117.465 98.3331C117.465 98.7472 117.801 99.0829 118.216 99.0829H125.069C125.352 99.0829 125.582 98.8539 125.582 98.5714V98.2865C125.582 98.2865 125.921 97.4304 125.223 96.3751C125.223 96.3751 124.355 97.2014 123.059 95.9072L122.677 95.2158L119.91 97.2362L118.376 97.4247C118.04 97.466 117.743 97.4183 117.57 97.7085Z" fill="#1A202C"/>
      <path d="M115.09 97.7585L112.877 97.7583L111.824 89.2363L115.09 89.2366L115.09 97.7585Z" fill="#FFB6B6"/>
      <path d="M107.508 97.7085C107.439 97.8244 107.402 98.1983 107.402 98.3331C107.402 98.7472 107.739 99.0829 108.153 99.0829H115.007C115.29 99.0829 115.519 98.8539 115.519 98.5714V98.2865C115.519 98.2865 115.858 97.4304 115.16 96.3751C115.16 96.3751 114.293 97.2014 112.997 95.9072L112.614 95.2158L109.847 97.2362L108.313 97.4247C107.978 97.466 107.68 97.4183 107.508 97.7085Z" fill="#1A202C"/>
      <path d="M128.704 44.5537L113.303 44.9637L111.733 48.6538L109.812 72.6392L111.455 93.7545H116.251V73.8256L119.874 61.569L121.312 93.1395L126.263 93.7545L128.293 72.0241L130.141 51.3188L128.704 44.5537Z" fill="#1A202C"/>
      <path d="M123.231 17.0835L117.892 17.2885L117.276 19.7485C117.276 19.7485 110.705 21.5936 111.116 24.0536C111.527 26.5136 113.58 37.3788 113.58 37.3788C113.58 37.3788 111.321 44.3489 112.759 45.1689C114.196 45.989 129.008 45.1689 129.008 45.1689C129.008 45.1689 130.213 42.0939 129.597 41.6839C128.981 41.2739 131.445 21.5936 131.034 21.1836C130.624 20.7736 123.847 18.7235 123.847 18.7235L123.231 17.0835Z" fill="#F7B80B"/>
      <path d="M133.705 37.584L134.116 40.454L129.599 50.7042L127.34 49.4741L129.804 39.839L130.215 36.1489L133.705 37.584Z" fill="#FFB6B6"/>
      <path d="M129.394 21.3886C129.394 21.3886 131.653 20.3635 133.296 24.0536C134.938 27.7437 134.528 35.7388 134.528 35.7388C134.528 35.7388 135.144 36.3538 134.733 36.7638C134.322 37.1738 133.542 36.209 134.322 37.5128C134.733 38.1988 135.965 37.9938 134.733 38.1988C133.501 38.4038 133.933 37.8309 134.322 38.6088C134.528 39.0188 135.349 39.0188 134.528 39.2238C133.706 39.4288 129.573 39.0769 129.573 39.0769C129.573 39.0769 128.162 38.4038 128.983 37.9938C129.805 37.5838 130.215 37.5031 129.805 37.3384C129.394 37.1738 128.367 37.1738 128.983 36.5588C129.599 35.9438 130.01 35.9438 129.599 35.9438C129.189 35.9438 128.727 34.9559 128.727 34.9559L129.394 21.3886Z" fill="#F7B80B"/>
      <path d="M129.341 52.4243C129.841 50.929 129.526 49.4765 128.637 49.1799C127.748 48.8833 126.621 49.855 126.121 51.3502C125.62 52.8455 125.936 54.2981 126.825 54.5946C127.714 54.8912 128.841 53.9195 129.341 52.4243Z" fill="#FFB6B6"/>
      <path d="M105.099 36.6126L103.466 39.0097L93.21 43.5451L92.3711 41.1168L100.695 35.6546L103.488 33.2041L105.099 36.6126Z" fill="#FFB6B6"/>
      <path d="M112.843 21.7446C112.843 21.7446 115.204 22.5066 113.927 26.3383C112.651 30.17 106.952 35.8017 106.952 35.8017C106.952 35.8017 106.992 36.6705 106.412 36.6972C105.831 36.7239 105.906 35.486 105.603 36.9744C105.443 37.7576 106.492 38.4349 105.443 37.7576C104.394 37.0803 105.1 36.9474 104.863 37.7843C104.738 38.2254 105.345 38.7779 104.6 38.3769C103.854 37.9759 101.038 34.9358 101.038 34.9358C101.038 34.9358 100.449 33.4891 101.333 33.7386C102.216 33.9881 102.574 34.2047 102.382 33.8068C102.19 33.4089 101.431 32.7183 102.301 32.6782C103.172 32.6381 103.475 32.9144 103.172 32.6381C102.868 32.3619 103.193 31.321 103.193 31.321L112.843 21.7446Z" fill="#F7B80B"/>
      <path d="M91.8584 44.6429C93.2373 43.8746 93.9846 42.5891 93.5277 41.7718C93.0707 40.9544 91.5825 40.9147 90.2037 41.683C88.8248 42.4514 88.0775 43.7368 88.5344 44.5541C88.9914 45.3715 90.4796 45.4112 91.8584 44.6429Z" fill="#FFB6B6"/>
      <path d="M120.795 16.0814C123.518 16.0814 125.726 13.8771 125.726 11.1581C125.726 8.43908 123.518 6.23486 120.795 6.23486C118.071 6.23486 115.863 8.43908 115.863 11.1581C115.863 13.8771 118.071 16.0814 120.795 16.0814Z" fill="#FFB6B6"/>
      <path d="M116.857 7.19813C117.108 6.48763 117.804 6.05659 118.559 6.07338C118.591 6.07408 118.622 6.07379 118.654 6.07249C119.034 6.05698 119.399 5.92743 119.771 5.85142C120.466 5.70976 121.198 5.75719 121.868 5.98723C122.076 6.05874 122.285 6.14866 122.505 6.14869C122.725 6.14872 122.965 6.02813 123.019 5.81486C123.032 6.00795 123.044 6.20103 123.057 6.3941C123.129 6.23783 123.201 6.08155 123.272 5.92527L123.416 6.5485L123.876 6.02764C123.835 6.25721 123.969 6.4886 124.15 6.63538C124.332 6.78216 124.556 6.86336 124.771 6.9548C125.698 7.34951 126.569 8.04834 126.836 9.01895C127.005 9.63218 126.917 10.283 126.828 10.9127C126.748 11.4693 126.662 12.0452 126.359 12.5191C126.165 12.8228 125.885 13.0784 125.767 13.4189C125.657 13.7363 125.698 14.0997 125.541 14.3969C125.384 14.6955 125.059 14.8644 124.74 14.9778C124.422 15.0913 124.084 15.1715 123.81 15.3689C123.535 15.5663 123.217 16.135 122.62 16.1044L122.427 15.7428C122.229 15.5815 122.963 15.7094 122.97 15.4542C122.978 15.1989 122.087 15.0412 122.214 14.8191C122.407 14.4786 122.669 14.1822 122.887 13.8567C123.142 13.4761 123.343 13.0313 123.255 12.5668C123.221 12.386 123.136 12.2153 123.007 12.0841C122.635 11.7056 122.22 11.872 121.817 11.9261C121.539 11.9634 121.207 11.9203 121.063 11.6803C120.894 11.3996 121.082 11.0424 121.059 10.7157C121.035 10.3696 120.772 10.0858 120.487 9.88847C120.201 9.69115 119.876 9.54739 119.619 9.31401C119.362 9.08061 119.403 9.20166 119.515 8.87325C117.603 10.3048 117.885 7.833 116.602 8.14198C116.663 7.82151 116.748 7.50577 116.857 7.19812L116.857 7.19813Z" fill="#1A202C"/>
    </g>
    <defs>
      <clipPath id="clip0_961_5184">
        <rect width="150.602" height="100" fill="white" transform="translate(0.783203 0.71875)"/>
      </clipPath>
    </defs>
  </svg>

);