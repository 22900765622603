import { Icon, IconProps } from "@chakra-ui/react";

export const MinusCircleIcon = (props: IconProps) => (
  <Icon
    width="22px"
    height="21px"
    viewBox="0 0 34 33"
    fill=""
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.2221 1.35352C25.449 1.35352 32.1181 8.02267 32.1181 16.2495C32.1181 24.4763 25.449 31.1455 17.2221 31.1455C8.99533 31.1455 2.32617 24.4763 2.32617 16.2495C2.32617 8.02267 8.99533 1.35352 17.2221 1.35352ZM17.2221 3.38479C10.1172 3.38479 4.35744 9.14451 4.35744 16.2495C4.35744 23.3545 10.1172 29.1142 17.2221 29.1142C24.3271 29.1142 30.0869 23.3545 30.0869 16.2495C30.0869 9.14451 24.3271 3.38479 17.2221 3.38479ZM23.6545 15.5047V16.9943C23.6545 17.1439 23.5332 17.2651 23.3837 17.2651H11.0606C10.9111 17.2651 10.7898 17.1439 10.7898 16.9943V15.5047C10.7898 15.3551 10.9111 15.2339 11.0606 15.2339H23.3837C23.5332 15.2339 23.6545 15.3551 23.6545 15.5047Z"
      fill=""
    />
  </Icon>
);
