import { Accordion, Flex } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  HIDE_STREET_MARKETING_OPTIONS,
  useOnboardingContext,
} from "../../context/OnboardingContext";
import { OptionType, useOptionContext } from "../../context/OptionsContext";
import { useSessionContext } from "../../context/SessionContext";
import { useUserContext } from "../../context/UserContext";
import {
  setFlyerPreselection,
  setOptionConfig,
} from "../../modules/Onboarding/onboardingConfig";
import { Abc } from "../../modules/Onboarding/type";
import { PageURL } from "../../types/pages";
import AccordionWrapper from "../Accordion/AccordionWrapper";
import NorelaButton from "../Buttons/NorelaButton";
import EventConfigBlock from "./EventConfigBlock";
import EventHoursBlock from "./EventHoursBlock";
import FlyerSelectionBlock from "./FlyerSelectionBlock";
import {
  FlyerDistributionSchedule,
  FlyersDistributionConfig,
} from "./flyersDistributionConfig";
import { useSegment } from "../../context/SegmentContext";

export default function FlyersBlock() {
  const { track } = useSegment();
  const { setFlyerConfig, flyerConfig } = useOnboardingContext();
  const [flyers, setFlyers] = useState(flyerConfig?.nbFlyers ?? 3000);
  const { userInfos, setUserInfos } = useUserContext();
  const [ownFlyers, setOwnFlyers] = useState(flyerConfig?.ownFlyers ?? false);
  const [index, setIndex] = useState(0);
  const [chosenOption, setChosenOption] = useState(
    undefined as FlyersDistributionConfig | undefined
  );
  const [chosenOptionType, setChosenOptionType] = useState(
    undefined as Abc | undefined
  );
  const [hours, setHours] = useState(
    undefined as FlyerDistributionSchedule | undefined
  );
  const [days, setDays] = useState([] as string[]);
  const navigate = useNavigate();
  const { updateHistory } = useSessionContext();

  const { optionInfo, setOptionInfo } = useOptionContext();

  function handleChosenOption(
    chosenOption: FlyersDistributionConfig | undefined,
    index: number
  ): void {
    if (chosenOption) setChosenOption(chosenOption);
    setChosenOptionType(index === 0 ? Abc.A : index === 1 ? Abc.B : Abc.C);
  }
  useEffect(() => {
    if (index > 0)
      setFlyerConfig({
        nbFlyers: flyers,
        ownFlyers,
        optionSelected: chosenOptionType,
      });
    else
      setFlyerConfig({
        nbFlyers: undefined,
        ownFlyers: undefined,
        optionSelected: undefined,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flyers, ownFlyers, chosenOptionType, index]);

  useEffect(() => {
    if (!chosenOption) return;
    const hourEnd = `${8 + Math.floor(chosenOption.hoursPerDay)}:${
      chosenOption.hoursPerDay % 1 === 0 ? "00" : "30"
    }}`;
    let eventDays: string[] = [];
    if (days.length === chosenOption.hoursPerDay) {
      eventDays = days;
    } else {
      for (let i = 0; i < chosenOption.days; i++) {
        eventDays.push(moment().add(2, "weeks").add(i, "days").toISOString());
      }
    }

    setFlyerPreselection(
      userInfos || {},
      setUserInfos,
      eventDays,
      hours ?? {
        startHour: "08:00",
        endHour: hourEnd,
      },
      chosenOption
    );
    const amount = chosenOption.personsPerTeam * chosenOption.teams;
    const tmpSelectedOptions = {
      ...optionInfo?.selectedOptions,
      [OptionType.BesacePrint]: amount,
    };
    setOptionConfig(
      HIDE_STREET_MARKETING_OPTIONS,
      optionInfo,
      tmpSelectedOptions,
      setOptionInfo
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hours, days]);

  return (
    <Accordion index={index} w="100%" onChange={(i) => setIndex(Number(i))}>
      <AccordionWrapper
        title="1. Avez-vous vos propres flyers ?"
        fakeButton={index > 0 ? "Modifier" : undefined}
      >
        <Flex gap="1rem">
          <NorelaButton onClick={() => setOwnFlyers(false)} light={ownFlyers}>
            Non
          </NorelaButton>
          <NorelaButton onClick={() => setOwnFlyers(true)} light={!ownFlyers}>
            Oui
          </NorelaButton>
        </Flex>
        <NorelaButton
          onClick={() => {
            track("Flyers step 1: own flyers ?", {
              buyFlyers: ownFlyers ? "non" : "oui",
            });
            setIndex(1);
          }}
          w="100%"
          mt="1rem"
        >
          SUIVANT
        </NorelaButton>
      </AccordionWrapper>

      <AccordionWrapper
        // isDisabled={index < 1}
        title="2. Définissez votre objectif"
        fakeButton={index > 1 ? "Modifier" : undefined}
      >
        <FlyerSelectionBlock
          flyers={flyers}
          setFlyers={(flyers) => {
            setFlyers(flyers);
            setHours(undefined);
            setChosenOption(undefined);
            setDays([]);
          }}
        />
        <NorelaButton
          onClick={() => {
            track("Flyers selection", { flyers: flyers });
            setIndex(2);
          }}
          w="100%"
          mt="1rem"
        >
          SUIVANT
        </NorelaButton>
      </AccordionWrapper>

      <AccordionWrapper
        // isDisabled={index < 2}
        title="3. Choisissez la configuration de votre événement"
        fakeButton={index > 2 ? "Modifier" : undefined}
      >
        <EventConfigBlock
          flyers={flyers}
          onChange={(a, b) => {
            handleChosenOption(a, b);
          }}
        />
        <NorelaButton
          disabled={chosenOption === undefined}
          onClick={() => {
            track("Flyers option selected", {
              option: index === 0 ? "A" : index === 1 ? "B" : "C",
            });
            setIndex(3);
          }}
          w="100%"
          mt="1rem"
        >
          SUIVANT
        </NorelaButton>
      </AccordionWrapper>
      <AccordionWrapper
        isDisabled={chosenOption === undefined}
        title="4. Suggestions de plage horaire"
      >
        <EventHoursBlock
          days={days}
          setDays={setDays}
          hours={chosenOption?.hoursPerDay ?? 3}
          chosenHours={hours}
          onChange={(h) => setHours(h)}
          nbDays={chosenOption?.days ?? 1}
        />
        <NorelaButton
          w="100%"
          mt="1rem"
          disabled={hours === undefined || days.length !== chosenOption!.days}
          onClick={() => {
            setFlyerPreselection(
              userInfos || {},
              setUserInfos,
              days!,
              hours!,
              chosenOption!
            );
            updateHistory(PageURL.CALENDAR);
            navigate(PageURL.CALENDAR);
          }}
        >
          ETAPE SUIVANTE
        </NorelaButton>
      </AccordionWrapper>
    </Accordion>
  );
}
