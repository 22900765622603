import { Container, Flex } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { LogoEnorelaBeta } from "../Icons/LogoEnorelaBeta";
import ProgressBar from "./ProgressBar";
import { useSegment } from "../../context/SegmentContext";

interface LayoutProps {
  children: ReactNode;
  progressBar?: boolean;
}

const Layout: FC<LayoutProps> = ({ children, progressBar = true }) => {
  const { track } = useSegment();
  const redirectOnWebsite = () => {
    track("Go to website");
    window.location.href = "https://e-norela.com/";
  };
  return (
    <Container
      maxWidth="1780px"
      width="100%"
      maxHeight="1080px"
      height="100vh"
      minWidth="350px"
      margin="0 auto"
      p={{ "2xl": "49px 103px 0px 111px", sm: "30px 50px 10px" }}
    >
      <Flex direction="column" justifyContent="space-between">
        <Flex direction="column">
          <Flex
            align="flex-start"
            sx={{
              img: {
                height: "100%",
                "@media(max-width: 1600px)": {
                  height: "50px",
                },
              },
            }}
          >
            <LogoEnorelaBeta
              cursor="pointer"
              width="235px"
              height="75px"
              alignSelf="top"
              onClick={redirectOnWebsite}
            />
            {progressBar && <ProgressBar />}
          </Flex>
          {children}
        </Flex>
      </Flex>
    </Container>
  );
};

export default Layout;
