import { Icon, IconProps } from "@chakra-ui/react";

export default function DownloadIcon(props: IconProps) {
  return (
    <Icon
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 9L2.25 5.25L3.3 4.1625L5.25 6.1125V0H6.75V6.1125L8.7 4.1625L9.75 5.25L6 9ZM0 12V8.25H1.5V10.5H10.5V8.25H12V12H0Z"
        fill="currentColor"
      />
    </Icon>
  );
}
