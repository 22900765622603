export const FLYER_PER_PERSON_PER_HOUR = 150;
export const FLYER_CONVERSION_RATE = 0.16;

export type FlyersDistributionConfig = {
  teams: number;
  personsPerTeam: number;
  hoursPerDay: number;
  days: number;
};

export const FLYER_DISTRIBUTION_CONFIG: {
  flyers: number;
  configs: [
    FlyersDistributionConfig,
    FlyersDistributionConfig,
    FlyersDistributionConfig
  ];
}[] = [
  // {
  //   flyers: 500,
  //   configs: [
  //     {teams: 1, personsPerTeam: 1, hoursPerDay: 3.5, days: 1 },
  //   ],
  // },
  // {
  //   flyers: 1000,
  //   configs: [
  //     {teams: 1, personsPerTeam: 1, hoursPerDay: 3.5, days: 2 },
  //     {teams: 1, personsPerTeam: 2, hoursPerDay: 3.5, days: 1 },
  //     {teams: 1, personsPerTeam: 2, hoursPerDay: 3.5, days: 1 },
  //   ],
  // },
  // {
  //   flyers: 1500,
  //   configs: [
  //     {teams: 1, personsPerTeam: 1, hoursPerDay: 5, days: 2 },
  //     {teams: 1, personsPerTeam: 3, hoursPerDay: 3.5, days: 1 },
  //     {teams: 1, personsPerTeam: 2, hoursPerDay: 5, days: 1 },
  //   ],
  // },
  // {
  //   flyers: 2000,
  //   configs: [
  //     {teams: 1, personsPerTeam: 3, hoursPerDay: 4.5, days: 1 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 3.5, days: 1 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 3.5, days: 1 },
  //   ],
  // },
  // {
  //   flyers: 2500,
  //   configs: [
  //     {teams: 1, personsPerTeam: 3, hoursPerDay: 5.5, days: 1 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 4, days: 1 },
  //     {teams: 1, personsPerTeam: 3, hoursPerDay: 5.5, days: 1 },
  //   ],
  // },
  // {
  //   flyers: 3000,
  //   configs: [
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 5, days: 1 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 5, days: 1 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 5, days: 1 },
  //   ],
  // },
  // {
  //   flyers: 3500,
  //   configs: [
  //     {teams: 1, personsPerTeam: 3, hoursPerDay: 4, days: 2 },
  //     {teams: 1, personsPerTeam: 6, hoursPerDay: 4, days: 1 },
  //     {teams: 1, personsPerTeam: 2, hoursPerDay: 4, days: 3 },
  //   ],
  // },
  // {
  //   flyers: 4000,
  //   configs: [
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 6.5, days: 1 },
  //     {teams: 1, personsPerTeam: 3, hoursPerDay: 4.5, days: 2 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 6.5, days: 1 },
  //   ],
  // },
  // {
  //   flyers: 4500,
  //   configs: [
  //     {teams: 1, personsPerTeam: 5, hoursPerDay: 6, days: 1 },
  //     {teams: 1, personsPerTeam: 2, hoursPerDay: 5, days: 3 },
  //     {teams: 1, personsPerTeam: 1, hoursPerDay: 5, days: 6 },
  //   ],
  // },
  // {
  //   flyers: 5000,
  //   configs: [
  //     {teams: 1, personsPerTeam: 2, hoursPerDay: 4, days: 4 },
  //     {teams: 1, personsPerTeam: 2, hoursPerDay: 5.5, days: 3 },
  //     {teams: 1, personsPerTeam: 2, hoursPerDay: 5.5, days: 3 },
  //   ],
  // },
  // {
  //   flyers: 5500,
  //   configs: [
  //     {teams: 1, personsPerTeam: 2, hoursPerDay: 3, days: 6 },
  //     {teams: 1, personsPerTeam: 2, hoursPerDay: 6, days: 3 },
  //     {teams: 1, personsPerTeam: 3, hoursPerDay: 4, days: 3 },
  //   ],
  // },
  // {
  //   flyers: 6000,
  //   configs: [
  //     {teams: 1, personsPerTeam: 2, hoursPerDay: 5, days: 4 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 5, days: 2 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 5, days: 2 },
  //   ],
  // },
  // {
  //   flyers: 6500,
  //   configs: [
  //     {teams: 1, personsPerTeam: 2, hoursPerDay: 5.5, days: 4 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 5.5, days: 2 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 5.5, days: 2 },
  //   ],
  // },
  // {
  //   flyers: 7000,
  //   configs: [
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 6, days: 2 },
  //     {teams: 1, personsPerTeam: 6, hoursPerDay: 4, days: 2 },
  //     {teams: 1, personsPerTeam: 8, hoursPerDay: 3, days: 2 },
  //   ],
  // },
  // {
  //   flyers: 7500,
  //   configs: [
  //     {teams: 1, personsPerTeam: 2, hoursPerDay: 5, days: 5 },
  //     {teams: 1, personsPerTeam: 2, hoursPerDay: 5, days: 5 },
  //     {teams: 1, personsPerTeam: 10, hoursPerDay: 5, days: 1 },
  //   ],
  // },
  // {
  //   flyers: 8000,
  //   configs: [
  //     {teams: 1, personsPerTeam: 3, hoursPerDay: 3, days: 6 },
  //     {teams: 1, personsPerTeam: 6, hoursPerDay: 3, days: 3 },
  //     {teams: 1, personsPerTeam: 12, hoursPerDay: 4.5, days: 1 },
  //   ],
  // },
  // {
  //   flyers: 8500,
  //   configs: [
  //     {teams: 1, personsPerTeam: 5, hoursPerDay: 5.5, days: 2 },
  //     {teams: 1, personsPerTeam: 10, hoursPerDay: 5.5, days: 1 },
  //     {teams: 1, personsPerTeam: 9, hoursPerDay: 6.5, days: 1 },
  //   ],
  // },
  // {
  //   flyers: 9000,
  //   configs: [
  //     {teams: 1, personsPerTeam: 6, hoursPerDay: 5, days: 2 },
  //     {teams: 1, personsPerTeam: 6, hoursPerDay: 5, days: 2 },
  //     {teams: 1, personsPerTeam: 12, hoursPerDay: 5, days: 1 },
  //     {teams: 1, personsPerTeam: 12, hoursPerDay: 5, days: 1 },
  //   ],
  // },
  // {
  //   flyers: 9500,
  //   configs: [
  //     {teams: 1, personsPerTeam: 10, hoursPerDay: 6.5, days: 1 },
  //     {teams: 1, personsPerTeam: 8, hoursPerDay: 4, days: 2 },
  //     {teams: 1, personsPerTeam: 8, hoursPerDay: 4, days: 2 },
  //   ],
  // },
  // {
  //   flyers: 10000,
  //   configs: [
  //     {teams: 1, personsPerTeam: 3, hoursPerDay: 4.5, days: 5 },
  //     {teams: 1, personsPerTeam: 15, hoursPerDay: 4.5, days: 1 },
  //     {teams: 1, personsPerTeam: 15, hoursPerDay: 4.5, days: 1 },
  //   ],
  // },
  // {
  //   flyers: 10500,
  //   configs: [
  //     {teams: 1, personsPerTeam: 2, hoursPerDay: 6, days: 6 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 3, days: 6 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 3, days: 6 },
  //   ],
  // },
  // {
  //   flyers: 11500,
  //   configs: [
  //     {teams: 1, personsPerTeam: 11, hoursPerDay: 7, days: 1 },
  //     {teams: 1, personsPerTeam: 6, hoursPerDay: 6.5, days: 2 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 6.5, days: 3 },
  //     {teams: 1, personsPerTeam: 5, hoursPerDay: 7.5, days: 2 },
  //   ],
  // },
  // {
  //   flyers: 12000,
  //   configs: [
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 4, days: 5 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 5, days: 4 },
  //     {teams: 1, personsPerTeam: 10, hoursPerDay: 4, days: 2 },
  //   ],
  // },
  // {
  //   flyers: 13500,
  //   configs: [
  //     {teams: 1, personsPerTeam: 10, hoursPerDay: 4.5, days: 2 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 4.5, days: 5 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 4.5, days: 5 },
  //   ],
  // },
  // {
  //   flyers: 15000,
  //   configs: [
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 6.5, days: 4 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 6.5, days: 4 },
  //     {teams: 1, personsPerTeam: 16, hoursPerDay: 6.5, days: 1 },
  //     {teams: 1, personsPerTeam: 8, hoursPerDay: 6.5, days: 2 },
  //   ],
  // },
  // {
  //   flyers: 16500,
  //   configs: [
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 7, days: 4 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 7, days: 4 },
  //     {teams: 1, personsPerTeam: 8, hoursPerDay: 7, days: 2 },
  //   ],
  // },
  // {
  //   flyers: 18000,
  //   configs: [
  //     {teams: 1, personsPerTeam: 24, hoursPerDay: 5, days: 1 },
  //     {teams: 1, personsPerTeam: 8, hoursPerDay: 5, days: 3 },
  //     {teams: 1, personsPerTeam: 3, hoursPerDay: 5, days: 8 },
  //   ],
  // },
  // {
  //   flyers: 19500,
  //   configs: [
  //     {teams: 1, personsPerTeam: 10, hoursPerDay: 6.5, days: 2 },
  //     {teams: 1, personsPerTeam: 20, hoursPerDay: 6.5, days: 1 },
  //     {teams: 1, personsPerTeam: 4, hoursPerDay: 6.5, days: 5 },
  //   ],
  // },
  // {
  //   flyers: 20000,
  //   configs: [
  //     {teams: 1, personsPerTeam: 5, hoursPerDay: 6.5, days: 4 },
  //     {teams: 1, personsPerTeam: 10, hoursPerDay: 6.5, days: 2 },
  //     {teams: 1, personsPerTeam: 21, hoursPerDay: 6.5, days: 1 },
  //   ],
  // },

  {
    flyers: 1500,
    configs: [
      { teams: 1, personsPerTeam: 1, hoursPerDay: 5, days: 2 },
      { teams: 1, personsPerTeam: 2, hoursPerDay: 5, days: 1 },
      { teams: 2, personsPerTeam: 1, hoursPerDay: 5, days: 1 },
    ],
  },
  {
    flyers: 2000,
    configs: [
      { teams: 1, personsPerTeam: 2, hoursPerDay: 3.5, days: 2 },
      { teams: 1, personsPerTeam: 4, hoursPerDay: 3.5, days: 1 },
      { teams: 2, personsPerTeam: 2, hoursPerDay: 3.5, days: 1 },
    ],
  },
  {
    flyers: 2500,
    configs: [
      { teams: 1, personsPerTeam: 2, hoursPerDay: 4, days: 2 },
      { teams: 2, personsPerTeam: 3, hoursPerDay: 3, days: 1 },
      { teams: 3, personsPerTeam: 2, hoursPerDay: 3, days: 1 },
    ],
  },
  {
    flyers: 3000,
    configs: [
      { teams: 1, personsPerTeam: 2, hoursPerDay: 5, days: 2 },
      { teams: 2, personsPerTeam: 2, hoursPerDay: 5, days: 1 },
      { teams: 3, personsPerTeam: 2, hoursPerDay: 3.5, days: 1 },
    ],
  },
  {
    flyers: 3500,
    configs: [
      { teams: 1, personsPerTeam: 2, hoursPerDay: 4, days: 2 },
      { teams: 2, personsPerTeam: 3, hoursPerDay: 4, days: 1 },
      { teams: 3, personsPerTeam: 2, hoursPerDay: 4, days: 1 },
    ],
  },
  {
    flyers: 4000,
    configs: [
      { teams: 1, personsPerTeam: 2, hoursPerDay: 4.5, days: 3 },
      { teams: 2, personsPerTeam: 2, hoursPerDay: 3.5, days: 2 },
      { teams: 3, personsPerTeam: 3, hoursPerDay: 3, days: 1 },
    ],
  },
  {
    flyers: 4500,
    configs: [
      { teams: 1, personsPerTeam: 2, hoursPerDay: 5, days: 3 },
      { teams: 2, personsPerTeam: 2, hoursPerDay: 4, days: 2 },
      { teams: 3, personsPerTeam: 2, hoursPerDay: 5, days: 1 },
    ],
  },
  {
    flyers: 5000,
    configs: [
      { teams: 1, personsPerTeam: 2, hoursPerDay: 5.5, days: 3 },
      { teams: 2, personsPerTeam: 2, hoursPerDay: 4, days: 2 },
      { teams: 3, personsPerTeam: 2, hoursPerDay: 5.5, days: 1 },
    ],
  },
  {
    flyers: 5500,
    configs: [
      { teams: 1, personsPerTeam: 2, hoursPerDay: 6, days: 3 },
      { teams: 2, personsPerTeam: 3, hoursPerDay: 6, days: 1 },
      { teams: 3, personsPerTeam: 2, hoursPerDay: 6, days: 1 },
    ],
  },
  {
    flyers: 6000,
    configs: [
      { teams: 1, personsPerTeam: 2, hoursPerDay: 5, days: 4 },
      { teams: 2, personsPerTeam: 2, hoursPerDay: 3.5, days: 3 },
      { teams: 3, personsPerTeam: 2, hoursPerDay: 3.5, days: 2 },
    ],
  },
  {
    flyers: 6500,
    configs: [
      { teams: 1, personsPerTeam: 2, hoursPerDay: 5.5, days: 4 },
      { teams: 2, personsPerTeam: 2, hoursPerDay: 5.5, days: 2 },
      { teams: 3, personsPerTeam: 3, hoursPerDay: 5, days: 1 },
    ],
  },
  {
    flyers: 7000,
    configs: [
      { teams: 1, personsPerTeam: 3, hoursPerDay: 4, days: 4 },
      { teams: 2, personsPerTeam: 3, hoursPerDay: 4, days: 2 },
      { teams: 4, personsPerTeam: 2, hoursPerDay: 6, days: 1 },
    ],
  },
  {
    flyers: 7500,
    configs: [
      { teams: 1, personsPerTeam: 3, hoursPerDay: 5.5, days: 3 },
      { teams: 2, personsPerTeam: 3, hoursPerDay: 4, days: 2 },
      { teams: 4, personsPerTeam: 2, hoursPerDay: 6, days: 1 },
    ],
  },
  {
    flyers: 8000,
    configs: [
      { teams: 1, personsPerTeam: 3, hoursPerDay: 4.5, days: 4 },
      { teams: 2, personsPerTeam: 3, hoursPerDay: 4.5, days: 2 },
      { teams: 4, personsPerTeam: 3, hoursPerDay: 4.5, days: 1 },
    ],
  },
  {
    flyers: 8500,
    configs: [
      { teams: 1, personsPerTeam: 4, hoursPerDay: 3.5, days: 4 },
      { teams: 2, personsPerTeam: 4, hoursPerDay: 3.5, days: 2 },
      { teams: 4, personsPerTeam: 3, hoursPerDay: 5, days: 1 },
    ],
  },
  {
    flyers: 9000,
    configs: [
      { teams: 1, personsPerTeam: 3, hoursPerDay: 5, days: 4 },
      { teams: 2, personsPerTeam: 3, hoursPerDay: 5, days: 2 },
      { teams: 4, personsPerTeam: 3, hoursPerDay: 5, days: 1 },
    ],
  },
  {
    flyers: 9500,
    configs: [
      { teams: 1, personsPerTeam: 3, hoursPerDay: 5.5, days: 4 },
      { teams: 2, personsPerTeam: 3, hoursPerDay: 5.5, days: 2 },
      { teams: 4, personsPerTeam: 3, hoursPerDay: 5.5, days: 1 },
    ],
  },
  {
    flyers: 10000,
    configs: [
      { teams: 1, personsPerTeam: 3, hoursPerDay: 5.5, days: 4 },
      { teams: 3, personsPerTeam: 2, hoursPerDay: 5.5, days: 2 },
      { teams: 4, personsPerTeam: 3, hoursPerDay: 5.5, days: 1 },
    ],
  },
  {
    flyers: 10500,
    configs: [
      { teams: 1, personsPerTeam: 4, hoursPerDay: 4.5, days: 4 },
      { teams: 3, personsPerTeam: 2, hoursPerDay: 4, days: 3 },
      { teams: 4, personsPerTeam: 4, hoursPerDay: 4.5, days: 1 },
    ],
  },
  {
    flyers: 11000,
    configs: [
      { teams: 1, personsPerTeam: 4, hoursPerDay: 4.5, days: 4 },
      { teams: 3, personsPerTeam: 2, hoursPerDay: 4, days: 3 },
      { teams: 4, personsPerTeam: 4, hoursPerDay: 5, days: 1 },
    ],
  },
  {
    flyers: 11500,
    configs: [
      { teams: 1, personsPerTeam: 4, hoursPerDay: 5, days: 4 },
      { teams: 3, personsPerTeam: 2, hoursPerDay: 4.5, days: 3 },
      { teams: 4, personsPerTeam: 4, hoursPerDay: 5, days: 1 },
    ],
  },
  {
    flyers: 12000,
    configs: [
      { teams: 1, personsPerTeam: 4, hoursPerDay: 5, days: 4 },
      { teams: 3, personsPerTeam: 2, hoursPerDay: 4.5, days: 3 },
      { teams: 4, personsPerTeam: 4, hoursPerDay: 5, days: 1 },
    ],
  },
];

export type FlyerDistributionSchedule = {
  startHour: string;
  endHour: string;
};

export const DISTRIBUTION_SCHEDULES: {
  hours: number;
  config: FlyerDistributionSchedule[];
}[] = [
  {
    hours: 3,
    config: [
      { startHour: "08:00", endHour: "11:00" },
      { startHour: "10:00", endHour: "13:00" },
      { startHour: "12:00", endHour: "15:00" },
      { startHour: "16:00", endHour: "19:00" },
    ],
  },
  {
    hours: 3.5,
    config: [
      { startHour: "08:00", endHour: "11:30" },
      { startHour: "10:00", endHour: "13:30" },
      { startHour: "11:30", endHour: "15:00" },
      { startHour: "16:00", endHour: "19:30" },
    ],
  },
  {
    hours: 4,
    config: [
      { startHour: "08:00", endHour: "12:00" },
      { startHour: "10:00", endHour: "14:00" },
      { startHour: "12:00", endHour: "16:00" },
      { startHour: "16:00", endHour: "20:00" },
    ],
  },
  {
    hours: 4.5,
    config: [
      { startHour: "08:00", endHour: "12:30" },
      { startHour: "10:00", endHour: "14:30" },
      { startHour: "12:00", endHour: "16:30" },
      { startHour: "16:00", endHour: "20:30" },
    ],
  },
  {
    hours: 5,
    config: [
      { startHour: "08:00", endHour: "13:00" },
      { startHour: "10:00", endHour: "15:00" },
      { startHour: "12:00", endHour: "17:00" },
      { startHour: "16:00", endHour: "21:00" },
    ],
  },
  {
    hours: 5.5,
    config: [
      { startHour: "08:00", endHour: "13:30" },
      { startHour: "10:00", endHour: "15:30" },
      { startHour: "12:00", endHour: "17:30" },
      { startHour: "16:00", endHour: "21:30" },
    ],
  },
  {
    hours: 6,
    config: [
      { startHour: "08:00", endHour: "14:00" },
      { startHour: "10:00", endHour: "16:00" },
      { startHour: "12:00", endHour: "18:00" },
      { startHour: "16:00", endHour: "22:00" },
    ],
  },
];
