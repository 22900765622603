import React, { FC, ReactElement } from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import { colors } from "../../ui/theme";
import PicturePngVariants, {
  PicturesPngTypes,
} from "../PicturePngVariants/PicturePngVariants";
import { DressCode } from "../DressingBlock/DressingBlock";

export interface ProfessionalOutfitMenuProps {
  setDressCode: (element: DressCode) => void;
  dressCode: any;
}

export interface ProfessionnellCardItemValueProps {
  value: Array<string>;
  title: string;
  color: any;
}

const PROFESSIONAL_OUTFIT_OPTIONS = [
  {
    name: "Ensemble Tailleur Pastel",
    colors: ["Rose Pastel", "Bleu Pastel"],
    accessoire: ["Double collier Doré", "Montre fine blanche"],
    image: PicturesPngTypes.DRESSING_ENSEMBLE_TAILLEUR,
  },
  {
    name: "Robe Ajustée Encolure Droite",
    colors: ["Noir"],
    accessoire: ["Ceinture fine noire"],
    image: PicturesPngTypes.DRESSING_ROBE_AJUSEE,
  },
  {
    name: "Robe col claudine",
    colors: ["Blanc-noir"],
    accessoire: ["Bracelet"],
    image: PicturesPngTypes.DRESSING_ROBE_COL,
  },
  {
    name: "Robe évasée",
    colors: ["Noir"],
    accessoire: ["Ceinture noeud bleu roi"],
    image: PicturesPngTypes.DRESSING_ROBE_EVASEE,
  },
  {
    name: "Robe fourreau sans manche",
    colors: ["Noir"],
    accessoire: ["Bracelets", "Colliers"],
    image: PicturesPngTypes.DRESSING_ROBE_FOURREAU,
  },
  {
    name: "Robe fourreau manches courtes",
    colors: ["Bleu marine"],
    accessoire: ["Bracelet Doré"],
    image: PicturesPngTypes.DRESSING_FOURREAU_MANCHES,
  },
  {
    name: "Ensemble Jupe tailleur",
    colors: ["Noir"],
    accessoire: ["Ceinture fine", "Foulard violet"],
    image: PicturesPngTypes.DRESSING_ENSEMBLE_JUPE,
  },
  {
    name: "Ensemble tailleur Noir",
    colors: ["Noir"],
    accessoire: ["Ceinture fine", "Montre fine"],
    image: PicturesPngTypes.DRESSING_ENSEMBLE_TAILLEUR_NOIR,
  },
  {
    name: "Ensemble tailleur Bleu marine",
    colors: ["Bleu marine"],
    accessoire: ["Ceinture fine", "Montre fine"],
    image: PicturesPngTypes.DRESSING_ENSEMBLE_TAILLEUR_BLUE_MARINE,
  },
];

const ProfessionalOutfitMenu: FC<ProfessionalOutfitMenuProps> = ({
  setDressCode,
  dressCode,
}): ReactElement => {
  const selectProfessionalOutfit = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      professionalOutfit: value,
    });
  };
  return (
    <>
      {PROFESSIONAL_OUTFIT_OPTIONS?.map((element) => (
        <Flex
          key={element.name}
          direction="column"
          border={`1px solid ${colors.gray.lightMode}`}
          borderRadius={{ md: "20px", "2xl": "32px" }}
          backgroundColor={
            element.name &&
            dressCode &&
            dressCode?.professionalOutfit === element.name
              ? colors.blue.lightMode
              : "transparent"
          }
          padding={{ md: "20px", "2xl": "32px" }}
          gap={{ md: "10px", "2xl": "20px" }}
        >
          <Text
            variant="xl"
            fontWeight="700"
            textAlign="left"
            color={
              dressCode?.professionalOutfit === element.name
                ? colors.white
                : colors.black
            }
          >
            {element.name}
          </Text>
          <Flex
            gap={{ md: "10px", "2xl": "30px" }}
            sx={{
              svg: {
                maxHeight: { md: "200px", "2xl": "100%" },
              },
            }}
          >
            <PicturePngVariants image={element.image} borderRadius="4px" />
            <Flex direction="column" justifyContent="space-between">
              <Flex direction="column" gap="10px">
                {element.colors && (
                  <ProfessionnellCardItemValue
                    title="Coloris"
                    value={element?.colors}
                    color={
                      dressCode?.professionalOutfit === element.name
                        ? colors.white
                        : colors.black
                    }
                  />
                )}
                {element.accessoire && (
                  <ProfessionnellCardItemValue
                    title="Accessoire"
                    value={element?.accessoire}
                    color={
                      dressCode?.professionalOutfit === element.name
                        ? colors.white
                        : colors.black
                    }
                  />
                )}
              </Flex>
              <Button
                disabled={element.name && dressCode && dressCode[element.name]}
                padding="10px 34px"
                width="fit-content"
                background={colors.additional.lightMode}
                borderRadius="6px"
                onClick={() => selectProfessionalOutfit(element.name)}
                _hover={{
                  opacity: 0.5,
                }}
              >
                <Text fontWeight="500" color={colors.white} variant="sm">
                  Choisir
                </Text>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      ))}
    </>
  );
};

const ProfessionnellCardItemValue: FC<ProfessionnellCardItemValueProps> = ({
  title,
  value,
  color,
}): ReactElement => (
  <Flex direction="column" alignItems="flex-start">
    <Text variant="md" fontWeight="700" textAlign="left" color={color}>
      {title}
    </Text>
    {value.map((item) => (
      <Text variant="sm" key={item} textAlign="left" color={color}>
        {item}
      </Text>
    ))}
  </Flex>
);

export default ProfessionalOutfitMenu;
