import React, { FC } from "react";
import { Flex } from "@chakra-ui/react";
import { DefaultProps } from "../Onboarding/type";
import ContentLayout from "../../components/Layout/ContentLayout";
import CvgContentBlock from "./components/CvgContentBlock";
import Header from "../../components/Header";
import BottomBlock from "../../components/BottomActionsBlock/BottomBlock";

const CgvContent: FC<DefaultProps> = ({ page, pages, setPage }) => {
  return (
    <ContentLayout>
      <Flex
        alignItems="flex-start"
        justifyContent="space-between"
        position="relative"
        gap="20px"
        sx={{
          marginTop: "40px",
          "@media(max-width: 1600px)": {
            marginTop: "30px",
          },
        }}
        paddingBottom="70px"
      >
        <Flex direction="column">
          <Header title={page.title} subtitle={page.subtitle} />
          <CvgContentBlock page={page} />
        </Flex>
      </Flex>

      <BottomBlock />
    </ContentLayout>
  );
};

export default CgvContent;
