export const RoleVoiturierIcon = () => (
  <svg
    width="180"
    height="121"
    viewBox="0 0 180 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2336_53057)">
      <path
        d="M0.759595 -12.9922H-13.7148V1.21771H0.759595V-12.9922Z"
        fill="white"
      />
      <path
        d="M0.359204 19.375H-14.1152V33.5849H0.359204V19.375Z"
        fill="white"
      />
      <path
        d="M177.907 72.4662V111.57C177.906 113.366 177.592 115.134 176.993 116.712L176.478 116.091L175.484 114.897L169.983 108.295L171.35 116.38L169.741 116.503L169.616 116.513L166.697 116.74L164.144 116.939H164.135L160.492 117.224L156.936 117.499L155.751 117.589L148.199 118.176L144.979 118.427L143.52 118.541L139.66 118.84L134.482 119.243L133.521 119.318L118.325 120.498L108.66 121.252L107.161 121.366L107.048 121.375L106.437 121.423H106.434L105.11 121.527L103.208 121.674L100.127 121.911L98.7668 122.015L94.8439 122.323L91.0631 122.617L87.4902 122.323L75.9693 121.375L68.5 120.759L68.2557 120.74L54.9979 119.65L45.052 118.83L38.9762 118.333L29.0337 117.513L24.4504 117.138L22.9546 117.015L19.2761 116.712L2.51478 115.333L1.26659 115.228L1.19725 115.224L-4.87524 114.726L-8.66602 114.413L-0.701427 103.793L-0.592453 103.646L2.5148 99.5036C4.7503 93.7454 6.89664 87.9303 13.2894 85.1342L13.9862 84.2053L23.5853 71.4046L25.2231 71.3714L68.2557 70.5562L74.7542 70.433L88.0681 70.1818L92.8924 70.0918L96.4553 70.3904L127.323 72.9875L129.334 73.1581L139.597 74.0206L149.094 74.8168L153.608 75.196L154.71 75.2908L154.783 75.2955L155.338 75.3429L156.824 75.1533L158.65 74.9211L159.327 74.8358L159.637 74.7931L166.139 73.9685L166.684 73.8974L168.239 73.6984L177.247 91.5179L177.907 72.4662Z"
        fill="#2D3748"
      />
      <path
        d="M142.839 114.119C138.15 104.785 134.733 81.0841 136.07 71.2989C136.011 70.9796 138.809 76.3476 138.818 76.0146C138.862 74.3709 139.714 73.0972 140.72 73.1697L153.364 74.0809C153.677 74.1181 153.977 74.2675 154.235 74.5142C154.492 74.7609 154.699 75.0962 154.833 75.4868L164.392 100.664C164.543 101.066 164.634 101.506 164.658 101.958C164.751 103.596 164.014 105.048 163.011 105.2L146.059 107.775C146.003 107.783 145.947 107.788 145.891 107.788C145.086 107.785 143.072 115.379 142.839 114.119Z"
        fill="white"
      />
      <path
        d="M90.4416 114.951L90.5607 115.893L91.0218 119.517C91.115 120.254 91.2592 120.976 91.4521 121.67C91.5098 121.888 91.5751 122.101 91.6443 122.309C91.6788 122.413 91.7134 122.513 91.7519 122.617H125.02L130.541 121.685L130.611 121.67C131.749 121.442 132.806 120.686 133.627 119.513C134.308 118.524 134.848 117.348 135.217 116.054C135.585 114.759 135.775 113.371 135.775 111.97C135.782 111.578 135.775 111.186 135.752 110.796C135.722 110.243 135.663 109.694 135.575 109.154C136.196 98.862 134.559 88.8286 130.791 79.0393C130.482 76.9468 129.726 75.0514 128.632 73.6258C127.733 72.4645 126.588 71.7867 125.389 71.7045L98.5338 70.0956C97.511 70.0477 96.5018 70.4482 95.6366 71.2455C93.992 72.7314 92.797 75.5328 92.5626 78.902C90.302 89.4927 89.5476 100.645 90.3455 111.676C90.2716 112.769 90.304 113.871 90.4416 114.951V114.951Z"
        fill="white"
      />
      <path
        d="M80.4071 119.229L9.52393 116.303C8.63283 116.266 -4.54631 123.644 -5.18614 122.496C-6.58725 119.984 -6.62146 115.846 -5.26251 113.256L25.7027 69.6901C25.9865 69.1006 26.3639 68.6138 26.8046 68.269C27.2452 67.9243 27.7367 67.7311 28.2396 67.7051H85.2976C85.5675 67.7055 85.8359 67.7621 86.0942 67.8732C87.9958 68.6867 89.1807 72.1966 88.7408 75.7128L83.9292 114.169C83.5535 117.127 82.1287 119.221 80.4855 119.231C80.4595 119.231 80.4332 119.23 80.4071 119.229Z"
        fill="white"
      />
      <path
        d="M153.151 77.2543L93.3855 71.1945L22.6289 71.5312C31.3327 57.9999 69.5881 41.5023 110.559 61.4315C151.548 63.4514 154.525 75.5711 154.525 75.5711L155.036 76.7491L153.151 77.2543Z"
        fill="#F7B80B"
      />
      <path
        d="M177.908 114.526C177.757 115.286 177.546 116.019 177.28 116.712C176.876 117.77 176.35 118.724 175.724 119.532C175.677 119.594 175.631 119.651 175.584 119.703C175.016 120.413 174.374 120.993 173.682 121.423C173.375 121.618 173.059 121.781 172.736 121.912C172.524 121.997 172.305 122.073 172.086 122.13C171.789 122.214 171.488 122.269 171.186 122.296C171.017 122.315 170.844 122.324 170.668 122.324H94.7011L90.9004 122.618L87.3088 122.324H-44.3009C-44.4536 122.324 -44.6096 122.319 -44.7589 122.3C-45.1702 122.267 -45.5788 122.185 -45.9805 122.054L-44.7589 121.798L-42.7341 121.376L-38.648 120.523L-28.6533 118.437L-24.7664 117.627L-15.3261 115.659L-13.7494 115.332L-9.3512 114.413L-5.47086 114.522L0.467501 114.692H0.540527L1.88819 114.73L10.9136 114.986L14.2296 115.081L38.554 115.768L40.7779 115.83L44.094 115.925L54.6529 116.228L67.9735 116.603L68.8631 116.626L83.1264 117.034L83.3123 117.039L90.5884 116.304L97.7084 115.588L103.102 115.048L104.842 114.872L104.908 114.868L105.293 114.83L105.545 114.801L107.663 114.588L115.587 113.792L119.115 113.436L120.635 113.284L122.225 113.123L136.767 111.659L140.817 111.251H140.82L143.034 111.028L147.094 110.62H147.097L151.27 110.199H151.273L152.893 110.037L153.514 109.976L158.24 109.502L161.098 109.213L166.004 108.72L167.521 108.568L169.56 108.359L170.117 108.302L170.233 108.293C170.233 108.293 171.94 108.345 174.163 110.108C174.868 110.669 175.533 111.323 176.152 112.061C176.58 112.57 176.988 113.113 177.373 113.687C177.553 113.953 177.728 114.232 177.908 114.526Z"
        fill="#F7B80B"
      />
      <path
        d="M68.6719 122.062H76.1269L75.4443 121.439L75.0934 121.118L75.0838 121.109L73.5121 119.679L72.2624 118.537L71.2482 119.533L70.8396 119.934L69.6332 121.118L69.3304 121.416L68.6719 122.062Z"
        fill="#CACACA"
      />
      <path
        d="M109.512 122.062H120.975L122.739 121.119L128.007 118.297L129.247 117.632L139.067 109.992H139.072L141.538 108.076L142.816 107.08L144.398 105.849L152.208 99.7758C152.425 99.6075 152.626 99.4212 152.809 99.219C153.235 98.746 153.557 98.1928 153.757 97.5932C153.957 96.9936 154.03 96.3603 153.972 95.7319C153.969 95.6779 153.961 95.6242 153.948 95.5715C153.863 94.88 153.619 94.2164 153.235 93.6305C152.852 93.0446 152.338 92.5515 151.733 92.1881C150.879 91.6744 149.882 91.44 148.884 91.5189C147.886 91.5977 146.94 91.9858 146.181 92.627L140.452 97.3693L138.164 99.2615L129.305 106.594L123.936 111.039H123.931L122.908 111.889L113.453 119.071L110.752 121.119L109.512 122.062Z"
        fill="#FFB6B6"
      />
      <path
        d="M149.202 105.486C149.504 105.527 149.812 105.469 150.078 105.321C150.344 105.174 150.553 104.945 150.673 104.669C150.828 104.332 151.086 104.051 151.412 103.865C151.475 103.829 151.541 103.798 151.608 103.771C152.019 103.62 152.387 103.372 152.68 103.051C152.973 102.73 153.182 102.344 153.291 101.926C153.628 100.547 154.708 99.2079 155.793 98.1755C156.981 97.0458 158.249 95.9624 159.127 94.5864C160.394 92.6014 160.749 89.8156 158.306 88.1596C157.255 87.4711 155.995 87.159 154.738 87.276C152.106 87.4863 149.929 89.0631 147.862 90.5911L148.379 89.5936C146.15 91.3011 143.593 92.5492 140.861 93.263C141.334 93.6849 140.924 94.5286 140.317 94.7249C139.711 94.9211 139.057 94.7143 138.432 94.5841C137.644 94.4199 136.67 94.4339 136.359 95.3573C136.291 95.5802 136.271 95.8145 136.3 96.0455C136.328 96.2765 136.405 96.4992 136.526 96.6995C138.721 100.533 141.699 103.88 145.275 106.53C146.069 107.118 146.992 107.696 147.98 107.581C148.968 107.466 149.808 106.261 149.202 105.486Z"
        fill="#F7B80B"
      />
      <path
        d="M147.023 113.295L147.538 117.259L148.038 121.119L148.091 121.529L148.163 122.062H167.158C167.413 122.062 167.663 122.053 167.908 122.034C168.345 122.008 168.781 121.953 169.211 121.869L169.427 120.864L169.552 120.265L161.607 117.806L158.03 116.702H158.021L152.854 115.098L147.023 113.295Z"
        fill="#FFB6B6"
      />
      <path
        d="M139.645 103.98C140.035 104.825 140.558 105.953 141.145 107.232C141.275 107.505 141.404 107.788 141.539 108.076C141.789 108.624 142.048 109.19 142.318 109.77C143.505 112.351 144.798 115.154 145.812 117.348C146.653 119.184 147.307 120.6 147.542 121.119C147.614 121.265 147.648 121.341 147.648 121.341L148.09 121.529L149.354 122.062H167.158C167.413 122.062 167.663 122.053 167.908 122.034C168.345 122.008 168.78 121.953 169.21 121.869C169.528 121.812 169.845 121.737 170.153 121.652C170.62 121.522 171.078 121.359 171.522 121.166C172.525 120.738 173.454 120.16 174.277 119.453C174.315 119.127 174.392 118.604 174.474 117.967C174.602 117.015 174.671 116.057 174.68 115.098C174.68 114.328 172.758 113.083 172.219 109.902C172.038 108.741 172.037 107.559 172.215 106.396C173.209 99.2333 172.859 88.2717 165.827 86.1294L160.77 77.4375H151.123L149.556 81.9722V81.9769L148.658 84.5769L148.119 86.1294L148.004 86.2804L144.562 90.7113C144.562 90.7113 144.014 91.3955 143.265 92.4761V92.4808C142.182 94.0258 141.241 95.6617 140.453 97.3694C139.938 98.4756 139.567 99.6407 139.347 100.838C139.133 101.891 139.236 102.984 139.645 103.98Z"
        fill="#1A202C"
      />
      <path
        d="M159.977 68.6769C152.889 73.9702 144.995 78.1829 143.507 84.0081C143.507 84.0081 144.399 74.8554 140.241 76.5903C133.256 79.5052 135.619 63.6526 137.578 61.0604C140.843 56.7401 151.852 54.2852 151.852 54.2852L159.662 58.494L159.977 68.6769Z"
        fill="#1A202C"
      />
      <path
        d="M147.632 75.1013C154.392 75.1013 159.873 69.7209 159.873 63.0839C159.873 56.4468 154.392 51.0664 147.632 51.0664C140.871 51.0664 135.391 56.4468 135.391 63.0839C135.391 69.7209 140.871 75.1013 147.632 75.1013Z"
        fill="#FFB6B6"
      />
      <path
        d="M152.042 58.4844L152.513 60.5156L144.278 62.3559C144.271 61.9451 144.177 61.5403 144.003 61.1667C143.829 60.7932 143.578 60.459 143.266 60.1852C143.443 60.9816 143.423 61.8083 143.206 62.5955C138.766 65.0419 139.021 65.9086 135.432 65.2847C135.191 65.3385 134.942 65.3452 134.698 65.3045C134.455 65.2638 134.222 65.1764 134.013 65.0473C133.804 64.9182 133.623 64.7499 133.48 64.5522C133.337 64.3544 133.236 64.1309 133.181 63.8945C131.737 57.6671 131.262 51.3023 137.574 49.7566C139.109 49.3799 140.704 49.3078 142.267 49.5445C143.83 49.7811 145.33 50.3217 146.677 51.1347C148.024 51.9476 149.193 53.0164 150.114 54.2785C151.035 55.5406 151.691 56.9705 152.042 58.4845V58.4844Z"
        fill="#1A202C"
      />
      <path
        d="M150.027 66.589C149.624 64.8542 148.833 63.229 147.713 61.8304C146.592 60.4318 145.17 59.2949 143.547 58.5015C141.925 57.7081 140.143 57.2782 138.331 57.2426C136.519 57.2071 134.722 57.5668 133.068 58.2959C133.641 56.6366 134.8 55.2335 136.336 54.3406L145.604 50.3901C150.176 49.3683 157.414 46.4682 160.716 49.2454C163.758 51.7967 162.278 56.7908 165.079 59.5878C168.15 62.6529 168.41 66.2773 167.54 68.3619C166.532 70.7736 164.517 74.0639 162.002 74.776C161.871 73.9273 161.44 73.1509 160.784 72.5833C160.965 73.3922 160.981 74.2283 160.83 75.0432L145.604 86.9649C147.026 85.7506 148.238 82.622 149.072 79.0981C149.566 77.0123 152.513 76.2802 150.123 72.7401C147.733 69.2 150.351 67.9885 150.027 66.589Z"
        fill="#1A202C"
      />
      <path
        d="M162.222 53.2907L162.18 53.3745C162.127 53.4864 162.075 53.5982 162.032 53.7147L162.028 53.7193C161.795 53.8032 161.534 53.6681 161.278 53.4537C161.318 53.9576 161.346 54.4452 161.368 54.923L153.262 51.809C154.671 52.0479 154.88 47.124 155.891 48.0676C155.948 48.1188 156 48.1701 156.052 48.2213C155.926 47.9244 155.84 47.6128 155.796 47.2941C155.753 46.8942 155.785 46.4898 155.891 46.1013C155.893 46.0995 155.894 46.0973 155.895 46.0949C155.896 46.0924 155.896 46.0899 155.896 46.0873C155.997 45.7361 156.154 45.4027 156.361 45.0996C156.66 44.6588 157.037 44.2751 157.476 43.9673C158.036 43.5474 158.689 43.2628 159.381 43.1365C160.074 43.0103 160.787 43.0459 161.463 43.2405C163.011 43.8155 164.291 44.9278 165.06 46.3669C165.761 47.4744 166.119 48.7581 166.09 50.0618C166.061 50.4039 165.986 50.7407 165.867 51.0635C165.719 51.5169 165.467 51.9305 165.129 52.2724C164.792 52.6142 164.378 52.875 163.921 53.0344C163.807 53.0717 163.675 53.1136 163.532 53.1649C163.456 52.5586 163.451 51.9458 163.518 51.3384C162.99 51.9228 162.554 52.5807 162.222 53.2907V53.2907Z"
        fill="#1A202C"
      />
      <path
        d="M154.371 51.4928C154.382 51.7527 154.414 52.0113 154.466 52.2662C154.47 52.2992 154.477 52.3319 154.485 52.3641C154.817 53.8131 156.616 50.7985 158.007 51.8096C158.873 52.4226 159.835 52.8946 160.854 53.2074C160.897 53.0909 161.277 53.3192 161.329 53.2074C161.343 53.1795 161.315 52.7694 161.329 52.7415C160.462 52.4137 159.659 51.9408 158.956 51.3437C157.651 50.3978 157.836 51.2831 157.532 49.9459C157.181 48.4036 155.112 49.0653 156.175 48.3337L156.051 48.166C155.297 48.6778 154.74 49.4248 154.471 50.286C154.471 50.2885 154.471 50.2911 154.47 50.2935C154.469 50.296 154.468 50.2982 154.466 50.3C154.387 50.6925 154.355 51.0929 154.371 51.4928V51.4928Z"
        fill="#F7B80B"
      />
      <path
        d="M152.34 122.061H161.16L161.261 121.117L161.607 117.805L161.746 116.503L162.342 110.835L162.602 108.367L163.174 102.927L163.366 101.096C163.488 99.8834 163.121 98.6723 162.342 97.7234C161.564 96.7746 160.436 96.164 159.204 96.0233C159.025 96.003 158.845 95.992 158.665 95.9902H158.651C158.432 95.9925 158.214 96.0067 157.997 96.0327C156.886 96.1892 155.868 96.7276 155.123 97.5521C154.419 98.3258 154.002 99.3102 153.94 100.346L153.936 100.416V100.421L153.517 106.102L153.301 109.009L153.186 110.618L152.854 115.096L152.715 116.984L152.412 121.117L152.34 122.061Z"
        fill="#FFB6B6"
      />
      <path
        d="M165.428 107.198C165.669 107.014 165.842 106.758 165.92 106.468C165.997 106.178 165.976 105.871 165.859 105.594C165.721 105.25 165.697 104.872 165.788 104.513C165.807 104.444 165.83 104.376 165.857 104.31C166.033 103.915 166.11 103.484 166.08 103.054C166.05 102.624 165.915 102.207 165.686 101.839C164.915 100.639 164.692 98.9455 164.697 97.4617C164.702 95.8381 164.797 94.1904 164.407 92.6127C163.843 90.3369 162.059 88.1425 159.145 88.7041C157.91 88.9618 156.803 89.6297 156.011 90.5953C154.327 92.5923 153.957 95.2231 153.628 97.7433L153.262 96.6831C152.952 99.4418 152.077 102.111 150.691 104.529C151.328 104.492 151.658 105.369 151.377 105.932C151.097 106.496 150.49 106.811 149.958 107.159C149.289 107.599 148.619 108.293 149.076 109.156C149.191 109.359 149.348 109.537 149.536 109.678C149.725 109.819 149.941 109.921 150.171 109.976C154.499 111.109 159.02 111.352 163.448 110.688C164.432 110.54 165.498 110.295 166.103 109.52C166.709 108.745 166.416 107.313 165.428 107.198Z"
        fill="#F7B80B"
      />
      <path
        d="M104.48 117.432C104.48 118.808 104.856 120.127 105.525 121.099C106.194 122.072 107.102 122.618 108.048 122.618H115.357C116.304 122.618 117.211 122.072 117.88 121.099C118.549 120.127 118.925 118.808 118.925 117.432V113.479C118.925 112.642 118.786 111.818 118.519 111.076C118.252 110.334 117.866 109.698 117.393 109.22C116.92 108.743 116.375 108.439 115.804 108.334C115.233 108.229 114.653 108.327 114.113 108.619L106.804 112.572C106.122 112.941 105.534 113.604 105.118 114.473C104.703 115.342 104.48 116.374 104.48 117.432V117.432Z"
        fill="#CACACA"
      />
    </g>
    <defs>
      <clipPath id="clip0_2336_53057">
        <rect
          width="180"
          height="120"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
