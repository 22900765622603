import { BoxProps, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import {
  setAdviceOnHover,
  useAdviceContext,
} from "../../context/AdviceContext";
import { HelpType, useHelpContext } from "../../context/HelpContext";
import { colors } from "../../ui/theme";
import PicturesVariant, { Icons } from "../PicturesVariant/PicturesVariant";

const PAR_VAGUE = "Par vague";
const EN_CONTINU = "En continu";

const transportOptions = [
  {
    title: PAR_VAGUE,
    tooltipText:
      "Arrivée des visiteurs en masse, via bus, train, tram, navette, nécessitant un renfort pour éviter l'engorgement",
    id: 1,
    icon: Icons.BUS_ICON,
  },
  {
    title: EN_CONTINU,
    tooltipText:
      "Arriviée des visiteurs en continu, pas de renfort à l'accueil nécessaire.",
    id: 2,
    icon: Icons.WALK_GIRL_ICON,
  },
];
export default function GuestArrivalForm({
  nbParticipants,
  transport,
  setTransport,
  ...props
}: {
  nbParticipants: string | undefined;
  transport: string[] | undefined;
  setTransport: (transport: string[]) => void;
} & BoxProps) {
  const { setHelp } = useHelpContext();
  const [showHelp, setShowHelp] = useState(true);

  const handleSetContext = (title: string) => {
    if (transport?.includes(title))
      setTransport(transport.filter((t) => t !== title));
    else setTransport([...(transport ?? []), title]);
    if (
      title === PAR_VAGUE &&
      nbParticipants !== undefined &&
      !["0-50", "50-100"].includes(nbParticipants) &&
      showHelp
    ) {
      setHelp(HelpType.ENGORGEMENT);
      setShowHelp(false);
    } else setHelp(null);
  };
  const { setAdvice } = useAdviceContext();
  return (
    <Flex
      direction="row"
      border={`1px solid ${colors.gray.lightMode}`}
      borderRadius="24px"
      gap="1rem"
      p="1rem"
      sx={{
        svg: {
          margin: "0 auto",
        },
        "@media(max-width: 1600px), @media(max-height: 830px)": {
          svg: {
            height: "100px",
          },
        },
      }}
      {...props}
    >
      {transportOptions?.map(({ title, icon, tooltipText }) => (
        <Flex
          key={title}
          border="1px solid transparent"
          cursor="pointer"
          flex="1"
          direction="column"
          w="250px"
          padding="14px"
          borderRadius="12px"
          _hover={{
            border: `1px solid ${colors.secondaryFontColor.lightMode}`,
          }}
          backgroundColor={
            transport?.includes(title) ? colors.blue.lightMode : "transparent"
          }
          {...(tooltipText && setAdviceOnHover(tooltipText, setAdvice))}
          onClick={() => handleSetContext(title)}
        >
          <PicturesVariant icon={icon} />
          <Text
            margin="18px auto 0"
            display="block"
            width="170px"
            p={{ md: "12px 10px 0", "2xl": "18px 20px 0" }}
            variant="xl"
            mt="18px"
            color={transport?.includes(title) ? colors.white : colors.black}
          >
            {title}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
}
