export const LoadingIcon = () => (
  <svg width="409" height="258" viewBox="0 0 409 258" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_139_52890)">
      <path d="M360.153 235.951L360.552 227.011C364.704 224.795 369.336 223.625 374.044 223.602C367.564 228.89 368.374 239.084 363.98 246.196C361.17 250.667 356.475 253.621 351.221 254.222L345.798 257.536C344.292 249.156 346.538 240.538 351.943 233.953C353.46 232.142 355.199 230.529 357.119 229.152C358.418 232.57 360.153 235.951 360.153 235.951Z" fill="#F2F2F2"/>
      <path d="M86.0212 226.154L72.9348 222.199C71.2185 215.239 71.2303 207.966 72.9691 201.012C78.3581 212.563 93.7561 215.208 102.63 224.367C108.193 230.197 110.797 238.235 109.708 246.212L112.572 255.46C99.5979 254.525 87.6845 247.966 79.9704 237.512C77.8608 234.593 76.1278 231.42 74.8125 228.069C80.3627 227.44 86.0212 226.154 86.0212 226.154H86.0212Z" fill="#F2F2F2"/>
      <path d="M314.81 256.073C314.808 256.344 314.7 256.603 314.509 256.795C314.318 256.985 314.06 257.091 313.79 257.09H309.693C309.132 257.092 308.676 256.64 308.674 256.08V132.398C308.674 129.672 307.757 127.459 306.631 127.454H51.5876C50.4563 127.459 49.5442 129.672 49.5391 132.398V255.315C49.5373 255.839 49.1388 256.276 48.6167 256.327C47.7912 256.409 46.9657 256.48 46.1452 256.546C46.1248 256.546 46.1045 256.551 46.0841 256.551C45.6356 256.592 45.1872 256.622 44.7387 256.658H44.7336C44.4992 256.236 44.275 255.803 44.0457 255.371C43.9722 255.224 43.9338 255.062 43.9336 254.898V125.984C43.9336 125.231 43.9642 124.473 44.0151 123.726C44.0878 122.699 44.2154 121.677 44.3973 120.664C45.2585 115.938 47.2051 112.739 49.4168 112.729H308.796C309.119 112.731 309.438 112.798 309.734 112.927C310.169 113.119 310.562 113.394 310.891 113.736C311.338 114.199 311.72 114.721 312.027 115.287C313.118 117.205 313.897 120.063 314.173 123.39C314.173 123.42 314.178 123.451 314.178 123.482C314.249 124.306 314.285 125.145 314.28 125.984L314.351 136.885L314.397 143.385L314.534 163.782L314.585 171.026L314.81 256.073Z" fill="#E6E6E6"/>
      <path d="M220.154 75.0195H194.162C193.396 75.0207 192.777 75.641 192.777 76.4053V117.39H221.542V76.4053C221.543 75.6403 220.922 75.0199 220.155 75.0195H220.154ZM207.245 98.3064C205.554 98.2985 204.184 96.9315 204.176 95.2428V90.5156C204.176 88.8236 205.55 87.452 207.245 87.452C208.94 87.452 210.315 88.8236 210.315 90.5156V95.2428C210.307 96.9315 208.937 98.2985 207.245 98.3064Z" fill="#CCCCCC"/>
      <path d="M192.688 116.08V120.034C192.688 120.461 193.035 120.806 193.462 120.807H220.856C221.283 120.805 221.629 120.46 221.631 120.034V116.08H192.688Z" fill="#3F3D56"/>
      <path d="M263.194 11.0586H152.089C150.102 11.0618 148.491 12.6691 148.488 14.653V89.49C148.49 91.4743 150.101 93.0825 152.089 93.0843H263.194C265.182 93.0825 266.793 91.4743 266.795 89.49V14.653C266.792 12.6692 265.181 11.0618 263.194 11.0586Z" fill="#E6E6E6"/>
      <path d="M262.267 13.6562H153.018C151.953 13.6586 151.092 14.5203 151.092 15.5825V88.5647C151.094 89.6255 151.955 90.4851 153.018 90.4869H262.267C263.329 90.4851 264.191 89.6255 264.192 88.5647V15.5825C264.192 14.5203 263.331 13.6586 262.267 13.6562Z" fill="white"/>
      <path d="M240.986 122.182H175.989C175.328 122.182 174.793 121.647 174.793 120.988C174.793 120.906 174.802 120.823 174.819 120.742L175.827 115.961C175.945 115.41 176.432 115.016 176.997 115.014H239.977C240.542 115.016 241.029 115.41 241.147 115.961L242.156 120.742C242.292 121.387 241.878 122.02 241.232 122.156C241.151 122.173 241.069 122.182 240.986 122.182Z" fill="#CCCCCC"/>
      <path d="M179.212 115.875H177.708C177.57 115.875 177.459 115.986 177.459 116.123V116.826C177.459 116.963 177.57 117.074 177.708 117.074H179.212C179.349 117.074 179.461 116.963 179.461 116.826V116.123C179.461 115.986 179.349 115.875 179.212 115.875Z" fill="#E6E6E6"/>
      <path d="M183.216 115.875H181.712C181.574 115.875 181.463 115.986 181.463 116.123V116.826C181.463 116.963 181.574 117.074 181.712 117.074H183.216C183.353 117.074 183.465 116.963 183.465 116.826V116.123C183.465 115.986 183.353 115.875 183.216 115.875Z" fill="#E6E6E6"/>
      <path d="M187.22 115.875H185.716C185.578 115.875 185.467 115.986 185.467 116.123V116.826C185.467 116.963 185.578 117.074 185.716 117.074H187.22C187.357 117.074 187.469 116.963 187.469 116.826V116.123C187.469 115.986 187.357 115.875 187.22 115.875Z" fill="#E6E6E6"/>
      <path d="M191.224 115.875H189.719C189.582 115.875 189.471 115.986 189.471 116.123V116.826C189.471 116.963 189.582 117.074 189.719 117.074H191.224C191.361 117.074 191.473 116.963 191.473 116.826V116.123C191.473 115.986 191.361 115.875 191.224 115.875Z" fill="#E6E6E6"/>
      <path d="M195.228 115.875H193.723C193.586 115.875 193.475 115.986 193.475 116.123V116.826C193.475 116.963 193.586 117.074 193.723 117.074H195.228C195.365 117.074 195.476 116.963 195.476 116.826V116.123C195.476 115.986 195.365 115.875 195.228 115.875Z" fill="#E6E6E6"/>
      <path d="M199.23 115.875H197.725C197.588 115.875 197.477 115.986 197.477 116.123V116.826C197.477 116.963 197.588 117.074 197.725 117.074H199.23C199.367 117.074 199.478 116.963 199.478 116.826V116.123C199.478 115.986 199.367 115.875 199.23 115.875Z" fill="#E6E6E6"/>
      <path d="M203.234 115.875H201.729C201.592 115.875 201.48 115.986 201.48 116.123V116.826C201.48 116.963 201.592 117.074 201.729 117.074H203.234C203.371 117.074 203.482 116.963 203.482 116.826V116.123C203.482 115.986 203.371 115.875 203.234 115.875Z" fill="#E6E6E6"/>
      <path d="M207.237 115.875H205.733C205.596 115.875 205.484 115.986 205.484 116.123V116.826C205.484 116.963 205.596 117.074 205.733 117.074H207.237C207.375 117.074 207.486 116.963 207.486 116.826V116.123C207.486 115.986 207.375 115.875 207.237 115.875Z" fill="#E6E6E6"/>
      <path d="M211.241 115.875H209.737C209.6 115.875 209.488 115.986 209.488 116.123V116.826C209.488 116.963 209.6 117.074 209.737 117.074H211.241C211.379 117.074 211.49 116.963 211.49 116.826V116.123C211.49 115.986 211.379 115.875 211.241 115.875Z" fill="#E6E6E6"/>
      <path d="M215.245 115.875H213.741C213.604 115.875 213.492 115.986 213.492 116.123V116.826C213.492 116.963 213.604 117.074 213.741 117.074H215.245C215.383 117.074 215.494 116.963 215.494 116.826V116.123C215.494 115.986 215.383 115.875 215.245 115.875Z" fill="#E6E6E6"/>
      <path d="M219.249 115.875H217.745C217.607 115.875 217.496 115.986 217.496 116.123V116.826C217.496 116.963 217.607 117.074 217.745 117.074H219.249C219.387 117.074 219.498 116.963 219.498 116.826V116.123C219.498 115.986 219.387 115.875 219.249 115.875Z" fill="#E6E6E6"/>
      <path d="M223.253 115.875H221.749C221.611 115.875 221.5 115.986 221.5 116.123V116.826C221.5 116.963 221.611 117.074 221.749 117.074H223.253C223.39 117.074 223.502 116.963 223.502 116.826V116.123C223.502 115.986 223.39 115.875 223.253 115.875Z" fill="#E6E6E6"/>
      <path d="M227.257 115.875H225.753C225.615 115.875 225.504 115.986 225.504 116.123V116.826C225.504 116.963 225.615 117.074 225.753 117.074H227.257C227.394 117.074 227.506 116.963 227.506 116.826V116.123C227.506 115.986 227.394 115.875 227.257 115.875Z" fill="#E6E6E6"/>
      <path d="M231.261 115.875H229.757C229.619 115.875 229.508 115.986 229.508 116.123V116.826C229.508 116.963 229.619 117.074 229.757 117.074H231.261C231.398 117.074 231.51 116.963 231.51 116.826V116.123C231.51 115.986 231.398 115.875 231.261 115.875Z" fill="#E6E6E6"/>
      <path d="M235.265 115.875H233.76C233.623 115.875 233.512 115.986 233.512 116.123V116.826C233.512 116.963 233.623 117.074 233.76 117.074H235.265C235.402 117.074 235.514 116.963 235.514 116.826V116.123C235.514 115.986 235.402 115.875 235.265 115.875Z" fill="#E6E6E6"/>
      <path d="M239.267 115.875H237.762C237.625 115.875 237.514 115.986 237.514 116.123V116.826C237.514 116.963 237.625 117.074 237.762 117.074H239.267C239.404 117.074 239.516 116.963 239.516 116.826V116.123C239.516 115.986 239.404 115.875 239.267 115.875Z" fill="#E6E6E6"/>
      <path d="M179.165 117.873H177.661C177.523 117.873 177.412 117.984 177.412 118.121V118.824C177.412 118.961 177.523 119.072 177.661 119.072H179.165C179.303 119.072 179.414 118.961 179.414 118.824V118.121C179.414 117.984 179.303 117.873 179.165 117.873Z" fill="#E6E6E6"/>
      <path d="M183.169 117.873H181.665C181.527 117.873 181.416 117.984 181.416 118.121V118.824C181.416 118.961 181.527 119.072 181.665 119.072H183.169C183.306 119.072 183.418 118.961 183.418 118.824V118.121C183.418 117.984 183.306 117.873 183.169 117.873Z" fill="#E6E6E6"/>
      <path d="M187.173 117.873H185.669C185.531 117.873 185.42 117.984 185.42 118.121V118.824C185.42 118.961 185.531 119.072 185.669 119.072H187.173C187.31 119.072 187.422 118.961 187.422 118.824V118.121C187.422 117.984 187.31 117.873 187.173 117.873Z" fill="#E6E6E6"/>
      <path d="M191.175 117.873H189.671C189.533 117.873 189.422 117.984 189.422 118.121V118.824C189.422 118.961 189.533 119.072 189.671 119.072H191.175C191.312 119.072 191.424 118.961 191.424 118.824V118.121C191.424 117.984 191.312 117.873 191.175 117.873Z" fill="#E6E6E6"/>
      <path d="M195.179 117.873H193.675C193.537 117.873 193.426 117.984 193.426 118.121V118.824C193.426 118.961 193.537 119.072 193.675 119.072H195.179C195.316 119.072 195.428 118.961 195.428 118.824V118.121C195.428 117.984 195.316 117.873 195.179 117.873Z" fill="#E6E6E6"/>
      <path d="M199.183 117.873H197.678C197.541 117.873 197.43 117.984 197.43 118.121V118.824C197.43 118.961 197.541 119.072 197.678 119.072H199.183C199.32 119.072 199.432 118.961 199.432 118.824V118.121C199.432 117.984 199.32 117.873 199.183 117.873Z" fill="#E6E6E6"/>
      <path d="M203.187 117.873H201.682C201.545 117.873 201.434 117.984 201.434 118.121V118.824C201.434 118.961 201.545 119.072 201.682 119.072H203.187C203.324 119.072 203.435 118.961 203.435 118.824V118.121C203.435 117.984 203.324 117.873 203.187 117.873Z" fill="#E6E6E6"/>
      <path d="M207.191 117.873H205.686C205.549 117.873 205.438 117.984 205.438 118.121V118.824C205.438 118.961 205.549 119.072 205.686 119.072H207.191C207.328 119.072 207.439 118.961 207.439 118.824V118.121C207.439 117.984 207.328 117.873 207.191 117.873Z" fill="#E6E6E6"/>
      <path d="M211.194 117.873H209.69C209.553 117.873 209.441 117.984 209.441 118.121V118.824C209.441 118.961 209.553 119.072 209.69 119.072H211.194C211.332 119.072 211.443 118.961 211.443 118.824V118.121C211.443 117.984 211.332 117.873 211.194 117.873Z" fill="#E6E6E6"/>
      <path d="M215.198 117.873H213.694C213.557 117.873 213.445 117.984 213.445 118.121V118.824C213.445 118.961 213.557 119.072 213.694 119.072H215.198C215.336 119.072 215.447 118.961 215.447 118.824V118.121C215.447 117.984 215.336 117.873 215.198 117.873Z" fill="#E6E6E6"/>
      <path d="M219.202 117.873H217.698C217.561 117.873 217.449 117.984 217.449 118.121V118.824C217.449 118.961 217.561 119.072 217.698 119.072H219.202C219.34 119.072 219.451 118.961 219.451 118.824V118.121C219.451 117.984 219.34 117.873 219.202 117.873Z" fill="#E6E6E6"/>
      <path d="M223.206 117.873H221.702C221.565 117.873 221.453 117.984 221.453 118.121V118.824C221.453 118.961 221.565 119.072 221.702 119.072H223.206C223.344 119.072 223.455 118.961 223.455 118.824V118.121C223.455 117.984 223.344 117.873 223.206 117.873Z" fill="#E6E6E6"/>
      <path d="M227.21 117.873H225.706C225.568 117.873 225.457 117.984 225.457 118.121V118.824C225.457 118.961 225.568 119.072 225.706 119.072H227.21C227.347 119.072 227.459 118.961 227.459 118.824V118.121C227.459 117.984 227.347 117.873 227.21 117.873Z" fill="#E6E6E6"/>
      <path d="M231.212 117.873H229.708C229.57 117.873 229.459 117.984 229.459 118.121V118.824C229.459 118.961 229.57 119.072 229.708 119.072H231.212C231.349 119.072 231.461 118.961 231.461 118.824V118.121C231.461 117.984 231.349 117.873 231.212 117.873Z" fill="#E6E6E6"/>
      <path d="M235.216 117.873H233.712C233.574 117.873 233.463 117.984 233.463 118.121V118.824C233.463 118.961 233.574 119.072 233.712 119.072H235.216C235.353 119.072 235.465 118.961 235.465 118.824V118.121C235.465 117.984 235.353 117.873 235.216 117.873Z" fill="#E6E6E6"/>
      <path d="M239.22 117.873H237.716C237.578 117.873 237.467 117.984 237.467 118.121V118.824C237.467 118.961 237.578 119.072 237.716 119.072H239.22C239.357 119.072 239.469 118.961 239.469 118.824V118.121C239.469 117.984 239.357 117.873 239.22 117.873Z" fill="#E6E6E6"/>
      <path d="M212.395 120.271H196.878C196.74 120.271 196.629 120.383 196.629 120.52V121.222C196.629 121.359 196.74 121.47 196.878 121.47H212.395C212.532 121.47 212.644 121.359 212.644 121.222V120.52C212.644 120.383 212.532 120.271 212.395 120.271Z" fill="#E6E6E6"/>
      <path d="M256.774 23.71H154.55C154.326 23.7051 154.147 23.5194 154.152 23.2953C154.157 23.0781 154.332 22.9032 154.55 22.8984H256.774C256.998 22.9034 257.176 23.089 257.171 23.3131C257.167 23.5303 256.991 23.7052 256.774 23.71Z" fill="#CACACA"/>
      <path d="M161.509 20.8269C162.825 20.8269 163.892 19.7385 163.892 18.3959C163.892 17.0532 162.825 15.9648 161.509 15.9648C160.194 15.9648 159.127 17.0532 159.127 18.3959C159.127 19.7385 160.194 20.8269 161.509 20.8269Z" fill="#CCCCCC"/>
      <path d="M169.74 20.8269C171.056 20.8269 172.122 19.7385 172.122 18.3959C172.122 17.0532 171.056 15.9648 169.74 15.9648C168.424 15.9648 167.357 17.0532 167.357 18.3959C167.357 19.7385 168.424 20.8269 169.74 20.8269Z" fill="#CCCCCC"/>
      <path d="M177.968 20.8269C179.284 20.8269 180.351 19.7385 180.351 18.3959C180.351 17.0532 179.284 15.9648 177.968 15.9648C176.653 15.9648 175.586 17.0532 175.586 18.3959C175.586 19.7385 176.653 20.8269 177.968 20.8269Z" fill="#CCCCCC"/>
      <path d="M205.557 29.8635L205.227 28.9006L247.45 7.00195L247.78 7.96484L205.557 29.8635Z" fill="#3F3D56"/>
      <path d="M332.954 70.4245L332.624 69.4616L358.031 47.6738L358.361 48.6362L332.954 70.4245Z" fill="#3F3D56"/>
      <path d="M284.141 75.4963L241.918 53.5977L242.248 52.6348L284.471 74.5334L284.141 75.4963Z" fill="#3F3D56"/>
      <path d="M305.755 60.2589L281.002 19.6956L281.784 19.043L306.537 59.6063L305.755 60.2589Z" fill="#3F3D56"/>
      <path d="M325.072 7.40359L300.965 7.2927V6.27539L325.072 6.38628V7.40359Z" fill="#3F3D56"/>
      <path d="M368.743 13.7133H341.735C338.076 13.7133 335.11 10.7528 335.11 7.10081C335.11 3.44881 338.076 0.488281 341.735 0.488281H368.743C372.402 0.488281 375.368 3.44881 375.368 7.10081C375.368 10.7528 372.402 13.7133 368.743 13.7133Z" fill="#F7B80B"/>
      <path d="M402.375 52.3715H375.367C371.708 52.3715 368.742 49.411 368.742 45.759C368.742 42.107 371.708 39.1465 375.367 39.1465H402.375C406.034 39.1465 409 42.107 409 45.759C409 49.411 406.034 52.3715 402.375 52.3715Z" fill="#F7B80B"/>
      <path d="M358.706 79.6316L334.599 79.5212V78.5039L358.706 78.6143V79.6316Z" fill="#3F3D56"/>
      <path d="M402.375 85.9419H375.367C371.708 85.9419 368.742 82.9813 368.742 79.3293C368.742 75.6773 371.708 72.7168 375.367 72.7168H402.375C406.034 72.7168 409 75.6773 409 79.3293C409 82.9813 406.034 85.9419 402.375 85.9419Z" fill="#F7B80B"/>
      <path d="M323.389 81.3852H296.38C292.722 81.3852 289.756 78.4247 289.756 74.7727C289.756 71.1207 292.722 68.1602 296.38 68.1602H323.389C327.047 68.1602 330.013 71.1207 330.013 74.7727C330.013 78.4247 327.047 81.3852 323.389 81.3852Z" fill="#F7B80B"/>
      <path d="M286.699 13.2251H259.691C256.032 13.2251 253.066 10.2645 253.066 6.61253C253.066 2.96053 256.032 0 259.691 0H286.699C290.358 0 293.324 2.96053 293.324 6.61253C293.324 10.2645 290.358 13.2251 286.699 13.2251Z" fill="#F7B80B"/>
      <path d="M157.607 126.748H127.032C124.78 126.748 122.955 128.57 122.955 130.817C122.955 133.065 124.78 134.887 127.032 134.887H138.243V166.423H146.396V134.887H157.607C159.858 134.887 161.684 133.065 161.684 130.817C161.684 128.57 159.858 126.748 157.607 126.748Z" fill="#3F3D56"/>
      <path d="M177.786 183.887H96.0059C79.6183 183.828 65.7536 171.781 63.4349 155.588L60.5401 144.959C54.5485 122.934 45.422 101.881 33.4396 82.4438C27.963 73.5658 25.1441 64.972 31.5466 56.698C31.6011 56.6276 31.6547 56.5581 31.7074 56.4896C36.8426 49.818 62.4021 48.3737 70.7344 48.0928C71.0636 48.0816 71.394 48.0761 71.7257 48.0762H122.592C122.924 48.0762 123.254 48.0817 123.583 48.0928C132.734 48.3591 141.001 53.6182 145.106 61.7863C149.487 70.1256 148.928 80.1923 143.651 87.9973C125.783 114.789 120.312 131.588 125.913 142.467C131.331 152.99 147.937 158.37 178.176 159.397C186.302 159.674 190.031 165.99 190.057 171.741C190.082 177.343 186.497 183.529 178.564 183.871C178.307 183.882 178.047 183.887 177.786 183.887Z" fill="#3F3D56"/>
      <path d="M109.899 256.372H140.474C142.726 256.372 144.551 254.55 144.551 252.302C144.551 250.055 142.726 248.233 140.474 248.233H129.263V179.564H121.11V248.233H109.899C107.647 248.233 105.822 250.055 105.822 252.302C105.822 254.55 107.647 256.372 109.899 256.372Z" fill="#3F3D56"/>
      <path d="M137.572 228.689L142.839 231.544L156.783 212.301L149.008 208.086L137.572 228.689Z" fill="#A0616A"/>
      <path d="M140.109 221.83L149.671 224.819L148.149 228.817L154.706 238.316C155.544 239.531 155.238 241.195 154.021 242.032C153.296 242.531 152.373 242.642 151.551 242.33L140.419 238.109L140.006 233.426L137.752 237.097L133.555 235.505L140.109 221.83V221.83Z" fill="#1A202C"/>
      <path d="M150.881 34.6268C151.353 37.8554 149.113 40.8544 145.879 41.3254C145.534 41.3756 145.185 41.3953 144.837 41.3843L124.529 84.5159L118.16 75.6502L139.065 34.8655C139.387 31.6008 142.298 29.2141 145.569 29.5347C148.306 29.803 150.502 31.9081 150.881 34.6268V34.6268Z" fill="#A0616A"/>
      <path d="M83.4874 93.236C83.4874 93.236 82.9432 100.771 91.4804 102.249C100.018 103.727 122.84 89.3214 126.588 87.1095C130.336 84.8976 137.321 57.0679 137.321 57.0679L131.246 46.8125C123.747 56.2821 117.857 65.0143 117.499 71.2127L83.4874 93.236H83.4874Z" fill="#F7B80B"/>
      <path d="M93.3438 119.192L95.1875 127.099C95.1875 127.099 95.2056 134.391 96.4994 135.735C97.784 137.073 126.033 134.387 126.033 134.387C126.033 134.387 123.038 122.637 123.077 113.746L93.3438 119.192Z" fill="#A0616A"/>
      <path d="M153.02 220.339L142.828 214.744C142.635 201.506 150.611 183.592 160.073 164.828C138.913 168.461 118.951 169.625 101.685 165.23C95.7865 163.676 92.2318 157.682 93.7031 151.772C93.7703 151.494 93.848 151.218 93.9362 150.945C96.0027 144.652 91.3691 140.707 95.6309 133.785C106.84 129.964 117.488 128.885 127.225 132.259L133.326 145.724L163.566 141.818C169.681 141.088 175.231 145.444 175.962 151.548C175.965 151.576 175.968 151.604 175.972 151.631C176.012 151.959 176.039 152.288 176.051 152.618C169.937 181.232 163.08 206.978 153.02 220.339Z" fill="#1A202C"/>
      <path d="M89.2381 69.1282L100.888 68.4416C101.895 68.3823 102.829 68.9671 103.213 69.898L106.506 77.8644L120.188 89.1526C120.188 89.1526 125.605 96.0337 125.618 104.92C125.631 113.807 127.54 134.377 127.54 134.377C127.54 134.377 119.634 123.268 114.754 126.223C113.924 126.725 113.632 127.753 112.762 128.273C112.328 128.532 111.326 128.28 110.888 128.54C104.164 132.524 97.1507 136.417 96.4954 135.734C95.2088 134.394 93.6482 127.546 93.6482 127.546L82.9845 88.437C81.8138 84.2705 82.5708 79.7998 85.048 76.2487C85.7929 75.2017 86.5735 74.4228 87.2989 74.294C88.9276 73.9478 88.0612 70.3041 89.2381 69.1282Z" fill="#F7B80B"/>
      <path d="M188.338 245.253L193.255 241.84L182.115 220.861L174.857 225.898L188.338 245.253Z" fill="#A0616A"/>
      <path d="M189.082 231.177L181.375 238.053C169.121 224.423 159.857 205.308 152.006 183.6C131.436 177.586 115.924 167.888 102.381 156.373C97.7872 152.493 94.3433 147.508 98.2615 142.781C98.4447 142.56 98.6359 142.347 98.8353 142.14C103.461 137.395 115.193 138.299 122.065 133.951L137.414 150.328L136.449 154.719L165.28 164.467C171.083 166.497 174.14 172.84 172.105 178.633C172.095 178.661 172.085 178.69 172.075 178.718C171.967 179.03 171.846 179.337 171.712 179.639L189.082 231.177V231.177Z" fill="#1A202C"/>
      <path d="M185.471 243.869L193.343 237.682L195.788 241.194L207.345 241.159C208.823 241.154 210.025 242.346 210.03 243.822C210.033 244.7 209.603 245.523 208.881 246.024L199.104 252.809L194.999 250.504L196.76 254.435L193.074 256.993L185.471 243.869Z" fill="#1A202C"/>
      <path d="M106.442 167.69C103.433 166.415 102.029 162.947 103.306 159.944C103.442 159.624 103.607 159.316 103.797 159.025L77.4238 119.298L88.322 118.471L112.376 157.488C115.004 159.458 115.534 163.181 113.561 165.804C111.909 167.999 108.966 168.779 106.442 167.69V167.69Z" fill="#A0616A"/>
      <path d="M91.2781 79.7621C91.2781 79.7621 85.1105 75.3836 79.4105 81.8974C73.7105 88.4113 74.1865 115.36 74.1329 119.706C74.1162 121.067 76.0849 124.003 78.7972 127.395C80.0149 128.918 81.3825 130.533 82.7876 132.138C83.6007 133.066 82.9001 134.999 83.7166 135.902C84.5286 136.8 86.8578 136.668 87.6301 137.501C91.2962 141.456 94.2744 144.466 94.2744 144.466L106.209 144.613C106.344 135.757 100.268 127.852 92.4626 120.212L91.2781 79.7621Z" fill="#F7B80B"/>
      <path d="M79.2476 41.2042C78.1177 42.0183 76.929 42.8569 75.5502 43.0586C74.1715 43.2604 72.5573 42.5951 72.1758 41.2573C71.7092 39.6216 73.189 38.1323 74.5189 37.0692L78.7362 33.6983C79.5085 33.0147 80.3804 32.452 81.3219 32.0296C82.2799 31.6088 83.3775 31.6455 84.3052 32.1291C85.2268 32.7504 85.7598 33.8042 85.7132 34.9133C85.6531 36.0065 85.2917 37.0619 84.6688 37.9631C83.0868 40.4243 80.5668 42.1342 77.692 42.697" fill="#1A202C"/>
      <path d="M111.234 128.781H80.6587C78.4072 128.781 76.582 130.603 76.582 132.85C76.582 135.098 78.4072 136.92 80.6587 136.92H91.8696V168.456H100.023V136.92H111.234C113.485 136.92 115.311 135.098 115.311 132.85C115.311 130.603 113.485 128.781 111.234 128.781Z" fill="#3F3D56"/>
      <path d="M81.1222 32.6673C81.2452 29.6163 77.9881 27.2005 74.9359 27.3915C71.8838 27.5824 69.2601 29.7254 67.5292 32.2429C65.7988 34.7583 64.7594 37.6718 63.3962 40.4029C62.033 43.134 60.2177 45.8081 57.5088 47.2233C54.8005 48.6365 51.0305 48.3844 49.1586 45.9715C47.978 47.7585 47.7213 50.0001 48.4672 52.0069C49.2336 53.9878 50.6628 55.6431 52.5124 56.6921C53.2487 57.1279 54.0351 57.4731 54.8545 57.7201C55.1924 56.8098 55.6678 55.9564 56.2642 55.1896C55.9948 56.0974 55.8919 57.0463 55.9606 57.9906C57.2236 58.2323 58.5229 58.2118 59.7776 57.9306C62.2758 57.3728 64.3646 55.6724 65.4145 53.3419C66.5592 50.6369 65.9762 47.5529 66.221 44.627C66.4637 41.7004 68.2305 38.3596 71.1722 38.3119C73.4125 38.2751 73.116 38.4595 74.5354 40.1897" fill="#1A202C"/>
      <path d="M101.465 48.6997C101.465 56.0037 95.5331 61.9247 88.2157 61.9247C80.8983 61.9247 73.9473 53.4604 73.9473 46.1564C73.9473 38.8524 80.8983 35.4746 88.2157 35.4746C95.5331 35.4746 101.465 41.3957 101.465 48.6997Z" fill="#1A202C"/>
      <path d="M94.7446 64.5484C101.584 63.1515 105.994 56.4848 104.594 49.658C103.195 42.8311 96.5161 38.4292 89.6767 39.8261C82.8374 41.2231 78.4275 47.8897 79.8269 54.7166C81.2264 61.5435 87.9053 65.9453 94.7446 64.5484Z" fill="#A0616A"/>
      <path d="M108.09 50.2253C104.348 51.2452 100.57 52.3142 99.111 50.2253C98.8275 48.8403 98.2902 47.5193 97.5262 46.329C97.3825 47.6621 97.0691 48.9714 96.5936 50.2253C91.8662 51.251 87.4478 51.4962 83.6298 50.2253C83.1048 51.1731 82.8 52.2266 82.738 53.3078C82.5492 53.1894 82.3516 53.0857 82.1469 52.9975C81.9013 52.859 81.6078 52.833 81.3417 52.9263C81.1577 53.0372 81.0354 53.2269 81.0105 53.44C80.9851 53.6522 80.9955 53.8671 81.0411 54.0759C81.0945 54.8034 81.3123 55.5094 81.678 56.141C81.9706 56.5809 82.4346 56.878 82.9571 56.9599C83.4871 59.9356 87.7065 63.9591 87.7065 63.9591C74.9668 64.4677 74.9668 55.8409 74.9668 47.682L76.4956 44.6301H81.5914V38.0176H95.8599C102.614 38.0193 108.088 43.4839 108.09 50.2253V50.2253Z" fill="#1A202C"/>
      <path d="M224.191 257.844L0.609923 258C0.274823 258.002 0.00175631 257.732 8.43142e-06 257.398C-0.00173945 257.063 0.268494 256.791 0.603593 256.789H0.609923L224.191 256.633C224.526 256.634 224.797 256.906 224.796 257.24C224.795 257.573 224.525 257.843 224.191 257.844Z" fill="#CACACA"/>
      <path d="M373.5 257.844L257.952 258C257.617 258.002 257.344 257.732 257.342 257.398C257.34 257.063 257.61 256.791 257.945 256.789H257.952L373.5 256.633C373.836 256.632 374.108 256.902 374.109 257.237C374.11 257.571 373.84 257.843 373.504 257.844H373.5Z" fill="#CACACA"/>
      <path d="M229.301 75.2818H185.501C181.696 75.2776 178.614 72.2003 178.609 68.403V39.4335C178.614 35.6362 181.697 32.5589 185.501 32.5547H229.301C233.106 32.5589 236.188 35.6362 236.193 39.4335V68.403C236.188 72.2003 233.106 75.2776 229.301 75.2818ZM185.501 33.572C182.259 33.5757 179.632 36.1978 179.629 39.4335V68.403C179.632 71.6387 182.259 74.2608 185.501 74.2645H229.301C232.543 74.2608 235.17 71.6387 235.173 68.403V39.4335C235.17 36.1978 232.543 33.5757 229.301 33.572L185.501 33.572Z" fill="#E6E6E6"/>
      <path d="M198.478 41.709H186.762V42.7263H198.478V41.709Z" fill="#E6E6E6"/>
      <path d="M212.751 45.7793H186.762V46.7966H212.751V45.7793Z" fill="#E6E6E6"/>
      <path d="M212.751 49.3398H186.762V50.3572H212.751V49.3398Z" fill="#E6E6E6"/>
      <path d="M219.885 52.9004H186.762V53.9177H219.885V52.9004Z" fill="#E6E6E6"/>
      <path d="M204.088 56.4609H186.762V57.4783H204.088V56.4609Z" fill="#E6E6E6"/>
      <path d="M218.356 60.0215H186.762V61.0388H218.356V60.0215Z" fill="#E6E6E6"/>
      <path d="M192.367 63.582H186.762V64.5993H192.367V63.582Z" fill="#E6E6E6"/>
      <path d="M186.381 19.8159C186.332 19.8159 186.282 19.7968 186.245 19.7587L185.158 18.6493C185.085 18.5753 185.085 18.4572 185.158 18.3833L186.245 17.2739C186.319 17.1989 186.44 17.1977 186.515 17.2709C186.59 17.3444 186.591 17.4649 186.518 17.5399L185.561 18.5163L186.518 19.4927C186.591 19.5678 186.59 19.6882 186.515 19.7617C186.478 19.7979 186.429 19.8159 186.381 19.8159V19.8159Z" fill="#CCCCCC"/>
      <path d="M188.208 19.8161C188.16 19.8161 188.112 19.7981 188.075 19.7619C188 19.6884 187.998 19.568 188.072 19.4929L189.029 18.5164L188.072 17.54C187.998 17.465 188 17.3445 188.075 17.2711C188.15 17.1975 188.271 17.1989 188.344 17.2741L189.432 18.3834C189.504 18.4574 189.504 18.5755 189.432 18.6494L188.344 19.7589C188.307 19.797 188.258 19.8161 188.208 19.8161V19.8161Z" fill="#CCCCCC"/>
      <path d="M245.089 17.0625H243.068C242.837 17.0625 242.65 17.2489 242.65 17.479V19.499C242.65 19.7292 242.837 19.9156 243.068 19.9156H245.089C245.32 19.9156 245.509 19.7292 245.509 19.499V17.479C245.509 17.2489 245.32 17.0625 245.089 17.0625Z" fill="#CCCCCC"/>
      <path d="M240.134 17.0625H238.113C237.882 17.0625 237.695 17.2489 237.695 17.479V19.499C237.695 19.7292 237.882 19.9156 238.113 19.9156H240.134C240.365 19.9156 240.554 19.7292 240.554 19.499V17.479C240.554 17.2489 240.365 17.0625 240.134 17.0625Z" fill="#CCCCCC"/>
      <path d="M249.757 17.1582H247.736C247.505 17.1582 247.318 17.3446 247.318 17.5748V19.5947C247.318 19.8249 247.505 20.0113 247.736 20.0113H249.757C249.988 20.0113 250.177 19.8249 250.177 19.5947V17.5748C250.177 17.3446 249.988 17.1582 249.757 17.1582Z" fill="#CCCCCC"/>
      <path d="M219.285 17.9258H203.124C202.842 17.9258 202.615 18.154 202.615 18.4336C202.615 18.7132 202.842 18.9415 203.124 18.9415H219.285C219.565 18.9415 219.794 18.7132 219.794 18.4336C219.794 18.154 219.565 17.9258 219.285 17.9258Z" fill="#CCCCCC"/>
    </g>
    <defs>
      <clipPath id="clip0_139_52890">
        <rect width="409" height="258" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);