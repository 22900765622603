import { Box, Flex, Input, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import BottomBlock from "../../components/BottomActionsBlock/BottomBlock";
import NorelaButton from "../../components/Buttons/NorelaButton";
import Header from "../../components/Header";
import { UrbanIcon } from "../../components/Icons/UrbanIcon";
import Layout from "../../components/Layout";
import ContentLayout from "../../components/Layout/ContentLayout";
import GrayLayout from "../../components/Layout/GrayLayout";
import MainContainer from "../../components/Layout/MainContainer";
import TotalCard from "../../components/TotalCard/TotalCard";
import { HelpType, useHelpContext } from "../../context/HelpContext";
import { useOnboardingContext } from "../../context/OnboardingContext";
import { PageURL } from "../../types/pages";
import { colors } from "../../ui/theme";

export default function OnboardingSellingPoints() {
  const { sellingPointConfig, setSellingPointConfig } = useOnboardingContext();
  const { setHelp } = useHelpContext();
  const [showHelp, setShowHelp] = useState(true);
  useEffect(() => {
    if (showHelp) {
      setHelp(HelpType.TEMPS_DE_PREPARATION);
      setShowHelp(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <ContentLayout>
        <MainContainer>
          <Flex flexDir="column">
            <Header
              title="COMBIEN DE POINTS DE VENTE FAUT-IL ANIMER ?"
              subtitle="Précisez combien de points de ventes ou magasins seront concernés par cette mission. N’oubliez pas d’indiquer si la mission se déroule dans la même ville !"
            />
            <Flex mr="auto" gap="2rem">
              <GrayLayout>
                <UrbanIcon />
                <Box
                  w="200px"
                  p="20px"
                  border={`solid 1px ${colors.black}`}
                  borderRadius="16px"
                  m="2rem auto 0"
                >
                  <Input
                    py="0 !important"
                    height="fit-content"
                    fontSize="40px"
                    textAlign="center"
                    borderColor="transparent"
                    _focusVisible={{
                      boxShadow: `0 0 0 1px ${colors.secondaryFontColor.lightMode}`,
                    }}
                    _hover={{
                      boxShadow: `0 0 0 1px ${colors.gray.lightMode}`,
                    }}
                    transition="300ms"
                    placeholder="0"
                    value={sellingPointConfig.sellingPoints || ""}
                    type="number"
                    onChange={(e) => {
                      let nb: number | undefined = parseInt(e.target.value);
                      if (Number.isNaN(nb) || nb === 0) nb = undefined;
                      setSellingPointConfig({
                        sellingPoints: nb,
                        sameLocation:
                          nb === 1
                            ? undefined
                            : sellingPointConfig.sameLocation,
                      });
                    }}
                  />
                  <Text mt="0.25rem">Points de vente</Text>
                </Box>
              </GrayLayout>
              {(sellingPointConfig.sellingPoints ?? 0) > 1 && (
                <GrayLayout flexDir="column" display="flex" gap="1rem">
                  <NorelaButton
                    borderRadius="16px"
                    size="lg"
                    height="fit-content"
                    p="2rem"
                    onClick={() =>
                      setSellingPointConfig({
                        ...sellingPointConfig,
                        sameLocation: true,
                      })
                    }
                    light={sellingPointConfig.sameLocation !== true}
                  >
                    Dans la même ville
                  </NorelaButton>
                  <NorelaButton
                    borderRadius="16px"
                    size="lg"
                    height="fit-content"
                    p="2rem"
                    onClick={() =>
                      setSellingPointConfig({
                        ...sellingPointConfig,
                        sameLocation: false,
                      })
                    }
                    light={sellingPointConfig.sameLocation !== false}
                  >
                    Dans plusieurs villes
                  </NorelaButton>
                </GrayLayout>
              )}
            </Flex>
          </Flex>
          <TotalCard />
          <BottomBlock
            nextPageLink={PageURL.CALENDAR}
            disableNextPage={
              sellingPointConfig.sellingPoints === undefined ||
              (sellingPointConfig.sellingPoints > 1 &&
                sellingPointConfig.sameLocation === undefined)
            }
          />
        </MainContainer>
      </ContentLayout>
    </Layout>
  );
}
