import { AccordionPanel, Flex, Text } from "@chakra-ui/react";
import { FC, ReactElement, useState } from "react";
import PicturesVariant, {
  Icons,
} from "../../../components/PicturesVariant/PicturesVariant";
import { OrderFields, OrderPaymentTypes } from "../../../types/order";
import { colors } from "../../../ui/theme";
import {
  setAdviceOnHover,
  useAdviceContext,
} from "../../../context/AdviceContext";

interface OrderFormToggleChoiceProps {
  setFieldValue: any;
}

const fields = [
  {
    id: OrderFields.PAYMENT_TYPE,
    type: OrderFields.TEXT,
    name: OrderPaymentTypes.PAYMENT_CARD,
    title: "Par carte bancaire",
    icon: Icons.ORDER_VISA_ICON,
    description: "Paiement sécurisé par carte bancaire.",
  },
  {
    id: OrderFields.PAYMENT_TYPE,
    type: OrderFields.TEXT,
    name: OrderPaymentTypes.PAYMENT_P2P,
    title:
      "Recevez votre devis par mail afin de voir tout le détail de votre commande",
    icon: Icons.ORDER_CARD_WITH_GIRL_ICON,
    description: "Téléchargez le devis afin de le visualiser.",
  },
];

const OrderTypePayment: FC<OrderFormToggleChoiceProps> = ({
  setFieldValue,
}): ReactElement => {
  const [activeItem, setActiveItem] = useState("");
  const { setAdvice } = useAdviceContext();

  return (
    <Flex>
      {fields.map((item) => {
        const active = activeItem === item.name;
        return (
          <AccordionPanel key={item.title}>
            <Flex
              {...(item.description &&
                setAdviceOnHover(item.description, setAdvice))}
              id={item.type}
              onClick={() => {
                setFieldValue(item.id, item.name);
                setActiveItem(item.name);
              }}
              flexDirection="column"
              borderRadius="12px"
              alignItems="center"
              width="200px"
              height="150px"
              border="1px solid transparent"
              padding="14px"
              cursor="pointer"
              gap="10px"
              backgroundColor={active ? colors.blue.lightMode : "transparent"}
              _hover={{
                border: `1px solid ${colors.secondaryFontColor.lightMode}`,
                svg: {
                  fill: colors.white,
                },
              }}
            >
              <PicturesVariant icon={item.icon} />
              <Text
                variant={{ l: "xl" }}
                fontWeight="700"
                color={active ? colors.white : colors.black}
              >
                {item.title}
              </Text>
            </Flex>
          </AccordionPanel>
        );
      })}
    </Flex>
  );
};

export default OrderTypePayment;
