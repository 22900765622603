export const FlyersGirlIcon = () => (
  <svg
    width="290"
    height="231"
    viewBox="0 0 290 231"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_446_51579)">
      <path
        d="M68.0052 79.0889H65.9551V101.64H68.0052V79.0889Z"
        fill="#F2F2F2"
      />
      <path
        d="M111.359 230.079H30.5859L30.6761 220.695L30.6843 219.879L31.406 146.437H110.949L111.051 167.653L111.055 168.469L111.359 230.079Z"
        fill="#E6E6E6"
      />
      <path
        d="M208.913 70.3682L221.983 48.2116C222.534 47.2712 222.693 46.152 222.426 45.0953C222.159 44.0387 221.487 43.1294 220.556 42.5637C220.074 42.271 219.537 42.0795 218.979 42.001C218.421 41.9224 217.852 41.9583 217.308 42.1066C216.764 42.2549 216.256 42.5124 215.815 42.8635C215.374 43.2145 215.009 43.6517 214.742 44.1485L201.871 67.58L193.45 81.4882C192.714 81.3809 191.963 81.5029 191.298 81.8375C190.634 82.1721 190.088 82.7032 189.736 83.3586C189.384 84.014 189.242 84.762 189.33 85.5008C189.418 86.2396 189.731 86.9335 190.227 87.4883C190.722 88.043 191.377 88.4316 192.101 88.6014C192.826 88.7711 193.585 88.7138 194.275 88.4371C194.966 88.1604 195.555 87.6779 195.962 87.0549C196.368 86.432 196.574 85.6989 196.549 84.9553L208.913 70.3682Z"
        fill="#A0616A"
      />
      <path
        d="M221.356 53.9276C221.617 53.866 221.851 53.7205 222.021 53.5134C222.191 53.3062 222.289 53.0489 222.299 52.781C222.317 52.4506 222.438 52.1343 222.645 51.8761C222.686 51.8266 222.729 51.7799 222.776 51.7361C223.064 51.4795 223.286 51.1572 223.422 50.7966C223.559 50.436 223.606 50.0477 223.561 49.6648C223.392 48.4068 223.847 46.9481 224.404 45.7435C225.014 44.4254 225.704 43.1204 225.98 41.6946C226.379 39.6379 225.773 37.2006 223.239 36.5906C222.156 36.3478 221.024 36.4855 220.031 36.9804C217.942 37.9876 216.666 39.9914 215.464 41.92C215.464 41.92 212.591 44.9091 210.588 46.3618C211.111 46.5645 211.047 47.3982 210.613 47.7537C210.179 48.1092 209.577 48.1433 209.023 48.2321C208.325 48.344 207.531 48.6634 207.574 49.5322C207.59 49.7397 207.649 49.9417 207.747 50.1254C207.844 50.3091 207.979 50.4706 208.142 50.5998C211.175 53.106 214.692 54.9581 218.475 56.0395C219.315 56.2793 220.257 56.4707 221.029 56.0625C221.802 55.6543 222.102 54.3825 221.356 53.9276Z"
        fill="#F7B80B"
      />
      <path
        d="M241.287 92.3801L214.636 91.9701L213.816 85.8199L216.276 71.8794H237.187L241.287 92.3801Z"
        fill="#A0616A"
      />
      <path
        d="M205.51 221.463H200.984L198.83 204.008H205.51V221.463Z"
        fill="#A0616A"
      />
      <path
        d="M207.898 229.304H204.653L204.073 226.24L202.59 229.304H193.982C193.569 229.304 193.168 229.173 192.835 228.928C192.503 228.684 192.257 228.339 192.135 227.945C192.012 227.551 192.018 227.129 192.153 226.739C192.287 226.348 192.543 226.012 192.882 225.777L199.756 221.03V217.932L206.987 218.364L207.898 229.304Z"
        fill="#1A202C"
      />
      <path
        d="M266.717 206.772L263.005 209.361L251.254 196.274L256.733 192.454L266.717 206.772Z"
        fill="#A0616A"
      />
      <path
        d="M273.16 211.838L270.498 213.694L268.27 211.512L268.806 214.874L261.745 219.797C261.406 220.033 261.001 220.155 260.589 220.145C260.177 220.134 259.778 219.992 259.452 219.739C259.126 219.486 258.889 219.136 258.777 218.739C258.664 218.342 258.681 217.92 258.826 217.533L261.749 209.707L259.977 207.166L266.155 203.385L273.16 211.838Z"
        fill="#1A202C"
      />
      <path
        d="M288.937 230.348L0.959981 230.474C0.830995 230.474 0.707547 230.422 0.616603 230.33C0.525659 230.239 0.474609 230.115 0.474609 229.986C0.474609 229.857 0.525659 229.734 0.616603 229.642C0.707547 229.551 0.830995 229.499 0.959981 229.498L288.937 229.372C289.066 229.373 289.189 229.424 289.28 229.516C289.371 229.607 289.422 229.731 289.422 229.86C289.422 229.989 289.371 230.113 289.28 230.205C289.189 230.296 289.066 230.348 288.937 230.348Z"
        fill="#CACACA"
      />
      <path
        d="M114.637 137.987H26.2793V147.732H114.637V137.987Z"
        fill="#E6E6E6"
      />
      <path
        d="M84.4568 126.246L84.1576 125.197H84.2249C84.4102 125.196 84.5878 125.122 84.7186 124.991C84.8495 124.86 84.923 124.682 84.923 124.497C84.923 124.311 84.8495 124.134 84.7186 124.002C84.5878 123.871 84.4102 123.797 84.2249 123.797C81.0614 123.046 77.7658 123.046 74.6023 123.797C74.4175 123.797 74.2401 123.87 74.109 124C73.9778 124.13 73.9036 124.307 73.9025 124.492C73.9014 124.677 73.9735 124.855 74.1031 124.987C74.2327 125.118 74.4092 125.194 74.594 125.196L74.3094 126.246C72.1016 129.316 73.1817 134.415 74.3094 139.543H84.4569C85.7325 133.953 86.6984 128.645 84.4568 126.246Z"
        fill="#E6E6E6"
      />
      <path
        d="M79.4126 134.469C80.9586 134.469 82.2119 133.216 82.2119 131.67C82.2119 130.124 80.9586 128.871 79.4126 128.871C77.8666 128.871 76.6133 130.124 76.6133 131.67C76.6133 133.216 77.8666 134.469 79.4126 134.469Z"
        fill="white"
      />
      <path
        d="M92.1112 111.268L91.8119 110.218H91.8792C92.0648 110.218 92.2428 110.144 92.374 110.013C92.5053 109.882 92.579 109.704 92.579 109.518C92.579 109.333 92.5053 109.155 92.374 109.023C92.2428 108.892 92.0648 108.818 91.8792 108.818C88.7156 108.067 85.4201 108.067 82.2566 108.818C82.0717 108.818 81.8944 108.891 81.7633 109.022C81.6321 109.152 81.5579 109.329 81.5568 109.514C81.5557 109.699 81.6278 109.876 81.7574 110.008C81.887 110.14 82.0635 110.215 82.2483 110.217L81.9637 111.268C79.7559 114.337 80.836 119.436 81.9637 124.564H92.1112C93.3868 118.975 94.3527 113.666 92.1112 111.268Z"
        fill="#E6E6E6"
      />
      <path
        d="M87.0688 119.491C88.6148 119.491 89.8681 118.237 89.8681 116.691C89.8681 115.145 88.6148 113.892 87.0688 113.892C85.5228 113.892 84.2695 115.145 84.2695 116.691C84.2695 118.237 85.5228 119.491 87.0688 119.491Z"
        fill="white"
      />
      <path
        d="M77.3514 111.268L77.0522 110.218H77.1194C77.305 110.218 77.483 110.144 77.6143 110.013C77.7455 109.882 77.8192 109.704 77.8192 109.518C77.8192 109.333 77.7455 109.155 77.6143 109.023C77.483 108.892 77.305 108.818 77.1194 108.818C73.9559 108.067 70.6603 108.067 67.4968 108.818C67.312 108.818 67.1346 108.891 67.0035 109.022C66.8724 109.152 66.7981 109.329 66.797 109.514C66.7959 109.699 66.8681 109.876 66.9976 110.008C67.1272 110.14 67.3037 110.215 67.4885 110.217L67.204 111.268C64.9961 114.337 66.0762 119.436 67.204 124.564H77.3514C78.6271 118.975 79.593 113.666 77.3514 111.268Z"
        fill="#E6E6E6"
      />
      <path
        d="M72.3071 119.491C73.8531 119.491 75.1064 118.237 75.1064 116.691C75.1064 115.145 73.8531 113.892 72.3071 113.892C70.7611 113.892 69.5078 115.145 69.5078 116.691C69.5078 118.237 70.7611 119.491 72.3071 119.491Z"
        fill="white"
      />
      <path
        d="M70.7908 96.9172L70.4916 95.8675H70.5589C70.7445 95.8675 70.9225 95.7937 71.0537 95.6625C71.1849 95.5313 71.2587 95.3532 71.2587 95.1676C71.2587 94.982 71.1849 94.804 71.0537 94.6728C70.9225 94.5416 70.7445 94.4678 70.5589 94.4678C67.3953 93.7165 64.0998 93.7165 60.9363 94.4678C60.7514 94.4677 60.5741 94.5408 60.4429 94.6711C60.3118 94.8014 60.2376 94.9782 60.2365 95.1631C60.2354 95.3479 60.3075 95.5257 60.4371 95.6575C60.5666 95.7894 60.7431 95.8645 60.928 95.8666L60.6434 96.9172C58.4356 99.9868 59.5157 105.086 60.6434 110.214H70.7909C72.0665 104.624 73.0324 99.3158 70.7908 96.9172Z"
        fill="#E6E6E6"
      />
      <path
        d="M65.7485 105.141C67.2945 105.141 68.5478 103.887 68.5478 102.341C68.5478 100.795 67.2945 99.542 65.7485 99.542C64.2025 99.542 62.9492 100.795 62.9492 102.341C62.9492 103.887 64.2025 105.141 65.7485 105.141Z"
        fill="white"
      />
      <path
        d="M85.5506 96.9172L85.2514 95.8675H85.3186C85.5042 95.8675 85.6822 95.7937 85.8135 95.6625C85.9447 95.5313 86.0184 95.3532 86.0184 95.1676C86.0184 94.982 85.9447 94.804 85.8135 94.6728C85.6822 94.5416 85.5042 94.4678 85.3186 94.4678C82.1551 93.7165 78.8596 93.7165 75.696 94.4678C75.5112 94.4677 75.3338 94.5408 75.2027 94.6711C75.0716 94.8014 74.9974 94.9782 74.9963 95.1631C74.9952 95.3479 75.0673 95.5257 75.1968 95.6575C75.3264 95.7894 75.5029 95.8645 75.6877 95.8666L75.4032 96.9172C73.1953 99.9868 74.2755 105.086 75.4032 110.214H85.5506C86.8263 104.624 87.7922 99.3158 85.5506 96.9172Z"
        fill="#E6E6E6"
      />
      <path
        d="M80.5083 105.141C82.0543 105.141 83.3076 103.887 83.3076 102.341C83.3076 100.795 82.0543 99.542 80.5083 99.542C78.9623 99.542 77.709 100.795 77.709 102.341C77.709 103.887 78.9623 105.141 80.5083 105.141Z"
        fill="white"
      />
      <path
        d="M62.1815 111.268L61.8823 110.218H61.9495C62.1351 110.218 62.3131 110.144 62.4443 110.013C62.5756 109.882 62.6493 109.704 62.6493 109.518C62.6493 109.333 62.5756 109.155 62.4443 109.023C62.3131 108.892 62.1351 108.818 61.9495 108.818C58.786 108.067 55.4904 108.067 52.3269 108.818C52.1421 108.818 51.9647 108.891 51.8336 109.022C51.7024 109.152 51.6282 109.329 51.6271 109.514C51.626 109.699 51.6981 109.876 51.8277 110.008C51.9573 110.14 52.1338 110.215 52.3186 110.217L52.034 111.268C49.8262 114.337 50.9063 119.436 52.034 124.564H62.1815C63.4571 118.975 64.4231 113.666 62.1815 111.268Z"
        fill="#E6E6E6"
      />
      <path
        d="M57.1372 119.491C58.6832 119.491 59.9365 118.237 59.9365 116.691C59.9365 115.145 58.6832 113.892 57.1372 113.892C55.5912 113.892 54.3379 115.145 54.3379 116.691C54.3379 118.237 55.5912 119.491 57.1372 119.491Z"
        fill="white"
      />
      <path
        d="M197.076 70.6765L196.777 69.6267H196.844C197.03 69.6267 197.208 69.553 197.339 69.4218C197.47 69.2905 197.544 69.1125 197.544 68.9269C197.544 68.7413 197.47 68.5633 197.339 68.4321C197.208 68.3008 197.03 68.2271 196.844 68.2271C193.681 67.4757 190.385 67.4757 187.221 68.2271C187.037 68.227 186.859 68.3001 186.728 68.4304C186.597 68.5606 186.523 68.7375 186.522 68.9224C186.521 69.1072 186.593 69.285 186.722 69.4168C186.852 69.5486 187.028 69.6238 187.213 69.6259L186.929 70.6765C184.721 73.746 185.801 78.8449 186.929 83.9731H197.076C198.352 78.3836 199.318 73.075 197.076 70.6765Z"
        fill="#CCCCCC"
      />
      <path
        d="M192.032 78.8994C193.578 78.8994 194.831 77.6461 194.831 76.1001C194.831 74.5541 193.578 73.3008 192.032 73.3008C190.486 73.3008 189.232 74.5541 189.232 76.1001C189.232 77.6461 190.486 78.8994 192.032 78.8994Z"
        fill="white"
      />
      <path
        d="M54.1678 126.246L53.8686 125.197H53.9358C54.1211 125.196 54.2987 125.122 54.4296 124.991C54.5604 124.86 54.6339 124.682 54.6339 124.497C54.6339 124.311 54.5604 124.134 54.4296 124.002C54.2987 123.871 54.1211 123.797 53.9358 123.797C50.7723 123.046 47.4768 123.046 44.3132 123.797C44.1284 123.797 43.951 123.87 43.8199 124C43.6888 124.13 43.6145 124.307 43.6134 124.492C43.6123 124.677 43.6845 124.855 43.814 124.987C43.9436 125.118 44.1201 125.194 44.3049 125.196L44.0204 126.246C41.8125 129.316 42.8926 134.415 44.0204 139.543H54.1678C55.4434 133.953 56.4094 128.645 54.1678 126.246Z"
        fill="#E6E6E6"
      />
      <path
        d="M49.1235 134.469C50.6695 134.469 51.9228 133.216 51.9228 131.67C51.9228 130.124 50.6695 128.871 49.1235 128.871C47.5775 128.871 46.3242 130.124 46.3242 131.67C46.3242 133.216 47.5775 134.469 49.1235 134.469Z"
        fill="white"
      />
      <path
        d="M69.3377 126.246L69.0385 125.197H69.1057C69.291 125.196 69.4686 125.122 69.5995 124.991C69.7304 124.86 69.8039 124.682 69.8039 124.497C69.8039 124.311 69.7304 124.134 69.5995 124.002C69.4686 123.871 69.291 123.797 69.1057 123.797C65.9422 123.046 62.6467 123.046 59.4832 123.797C59.2983 123.797 59.1209 123.87 58.9898 124C58.8587 124.13 58.7845 124.307 58.7834 124.492C58.7823 124.677 58.8544 124.855 58.9839 124.987C59.1135 125.118 59.29 125.194 59.4748 125.196L59.1903 126.246C56.9824 129.316 58.0626 134.415 59.1903 139.543H69.3377C70.6134 133.953 71.5793 128.645 69.3377 126.246Z"
        fill="#E6E6E6"
      />
      <path
        d="M64.2934 134.469C65.8394 134.469 67.0927 133.216 67.0927 131.67C67.0927 130.124 65.8394 128.871 64.2934 128.871C62.7474 128.871 61.4941 130.124 61.4941 131.67C61.4941 133.216 62.7474 134.469 64.2934 134.469Z"
        fill="white"
      />
      <path
        d="M100.433 126.246L100.134 125.197H100.201C100.387 125.196 100.564 125.122 100.695 124.991C100.826 124.86 100.9 124.682 100.9 124.497C100.9 124.311 100.826 124.134 100.695 124.002C100.564 123.871 100.387 123.797 100.201 123.797C97.0379 123.046 93.7424 123.046 90.5789 123.797C90.394 123.797 90.2167 123.87 90.0855 124C89.9544 124.13 89.8802 124.307 89.8791 124.492C89.878 124.677 89.9501 124.855 90.0797 124.987C90.2092 125.118 90.3857 125.194 90.5706 125.196L90.286 126.246C88.0781 129.316 89.1583 134.415 90.286 139.543H100.433C101.709 133.953 102.675 128.645 100.433 126.246Z"
        fill="#E6E6E6"
      />
      <path
        d="M95.3891 134.469C96.9351 134.469 98.1884 133.216 98.1884 131.67C98.1884 130.124 96.9351 128.871 95.3891 128.871C93.8431 128.871 92.5898 130.124 92.5898 131.67C92.5898 133.216 93.8431 134.469 95.3891 134.469Z"
        fill="white"
      />
      <path
        d="M240.778 86.9016L212.947 85.0264C212.947 85.0264 206.498 99.923 206.601 101.892C206.704 103.861 209.365 103.268 206.6 106.229C203.834 109.191 203.834 105.091 203.834 109.191C203.834 113.291 192.653 204.314 197.573 205.134C206.184 206.364 205.773 204.724 205.773 204.724L219.285 162.393C219.285 162.393 243.679 198.057 251.285 196.114C258.891 194.171 257.435 191.604 257.435 191.604L241.139 158.526C241.139 158.526 238.71 158.522 239.052 155.792C239.395 153.062 237.321 153.524 237.321 153.524C237.321 153.524 234.208 152.554 235.366 150.963C236.525 149.372 233.449 148.452 233.449 148.452L242.424 114.098C242.424 114.098 248.816 99.2586 240.778 86.9016Z"
        fill="#1A202C"
      />
      <path
        d="M238.323 30.0771L228.744 30.5624L225.546 35.9499C225.546 35.9499 216.434 36.6182 216.434 43.9985C216.434 51.3787 210.214 48.1671 213.119 57.5632C216.024 66.9593 216.686 68.7704 214.1 70.1199C211.514 71.4694 207.864 71.1789 211.124 73.3742C214.384 75.5696 215.614 76.3896 214.794 77.2096C213.974 78.0296 229.994 71.578 239.204 75.6238C239.204 75.6238 241.781 73.6734 241.203 71.9564C240.625 70.2394 240.31 70.9033 241.288 69.3413C242.265 67.7793 252.639 40.6117 252.639 40.6117C252.639 40.6117 250.533 36.6962 245.989 36.8622C241.445 37.0282 238.323 30.0771 238.323 30.0771Z"
        fill="#F7B80B"
      />
      <path
        d="M271.933 121.32L254.996 130.511L241.991 106.547C240.772 104.301 240.495 101.662 241.222 99.2122C241.948 96.7622 243.618 94.701 245.864 93.4821C248.11 92.2632 250.748 91.9865 253.198 92.7128C255.648 93.4391 257.709 95.109 258.928 97.355L271.933 121.32ZM255.326 129.399L270.822 120.99L258.208 97.7461C257.655 96.7284 256.908 95.8294 256.009 95.1004C255.109 94.3714 254.075 93.8268 252.965 93.4976C251.855 93.1684 250.691 93.0611 249.54 93.1818C248.388 93.3025 247.272 93.6489 246.254 94.2012C245.236 94.7534 244.338 95.5007 243.609 96.4004C242.88 97.3 242.336 98.3345 242.007 99.4445C241.678 100.555 241.571 101.719 241.692 102.87C241.813 104.022 242.159 105.138 242.712 106.155L255.326 129.399Z"
        fill="#3F3D56"
      />
      <path
        d="M286.401 138.425L261.452 151.965C260.851 152.293 260.161 152.421 259.482 152.329C258.804 152.238 258.172 151.932 257.68 151.457L240.832 135.285C240.466 134.933 240.188 134.501 240.02 134.021C239.853 133.542 239.801 133.03 239.869 132.527C239.936 132.024 240.122 131.545 240.41 131.127C240.698 130.709 241.081 130.365 241.527 130.123L278.947 109.816C279.393 109.574 279.89 109.44 280.397 109.426C280.904 109.413 281.408 109.519 281.866 109.736C282.325 109.953 282.726 110.276 283.037 110.677C283.347 111.079 283.559 111.548 283.654 112.046L288.031 134.986C288.161 135.658 288.073 136.354 287.78 136.973C287.487 137.591 287.004 138.1 286.401 138.425Z"
        fill="#F7B80B"
      />
      <path
        d="M260.335 120.15L257.812 121.519L260.159 125.843L262.682 124.474L260.335 120.15Z"
        fill="#3F3D56"
      />
      <path
        d="M253.81 73.2281L253.078 47.5143C253.043 46.425 252.582 45.3928 251.794 44.6405C251.005 43.8882 249.952 43.4761 248.863 43.4932C248.299 43.502 247.743 43.6255 247.228 43.8563C246.714 44.087 246.252 44.42 245.87 44.8352C245.489 45.2503 245.196 45.7388 245.009 46.2708C244.823 46.8028 244.746 47.3673 244.785 47.9298L246.365 74.6168L246.641 90.8733C245.961 91.1744 245.39 91.6777 245.005 92.3148C244.621 92.9518 244.442 93.6917 244.493 94.434C244.544 95.1763 244.822 95.8849 245.289 96.4637C245.757 97.0424 246.391 97.4631 247.106 97.6687C247.821 97.8744 248.582 97.8548 249.286 97.6129C249.99 97.3709 250.602 96.9183 251.039 96.3164C251.476 95.7144 251.718 94.9925 251.731 94.2486C251.743 93.5047 251.527 92.7749 251.11 92.1583L253.81 73.2281Z"
        fill="#A0616A"
      />
      <path
        d="M255.589 52.6869C255.777 52.4958 255.897 52.2483 255.931 51.9824C255.965 51.7164 255.91 51.4466 255.776 51.2146C255.616 50.9251 255.55 50.5929 255.588 50.2642C255.596 50.2007 255.608 50.1378 255.624 50.0759C255.731 49.7055 255.747 49.3146 255.671 48.9366C255.594 48.5587 255.428 48.2047 255.185 47.9049C254.373 46.9295 253.981 45.4528 253.812 44.1365C253.626 42.6961 253.515 41.224 252.99 39.8699C252.233 37.9166 250.423 36.1759 247.952 37.009C246.907 37.3796 246.021 38.0993 245.445 39.0466C244.213 41.0113 244.199 43.3868 244.208 45.6594L243.765 44.7614C243.818 47.2435 243.37 49.7107 242.446 52.0151C242.997 51.9083 243.386 52.6481 243.208 53.1801C243.03 53.7121 242.538 54.0617 242.117 54.4317C241.585 54.8982 241.083 55.591 241.582 56.3037C241.706 56.4709 241.863 56.6106 242.044 56.714C242.225 56.8175 242.425 56.8824 242.632 56.9049C246.533 57.412 250.496 57.1069 254.274 56.0088C255.113 55.7644 256.012 55.425 256.449 54.6682C256.885 53.9114 256.462 52.6748 255.589 52.6869Z"
        fill="#F7B80B"
      />
      <path
        d="M247.514 17.1185C246.965 19.1522 247.596 22.3544 247.834 24.2528C248.088 26.2824 244.571 28.0987 242.557 28.2135C242.607 27.5417 242.418 26.874 242.024 26.3275C242.02 26.9774 241.886 27.6199 241.631 28.2176H224.832C222.717 24.2364 220.917 18.7955 222.86 13.1701C222.959 12.0403 223.389 10.9648 224.096 10.0785C224.804 9.19223 225.757 8.5347 226.837 8.18844L235.997 5.25684C239.609 5.25684 244.042 5.76935 246.038 8.45905C246.922 9.69737 247.514 11.1198 247.77 12.6195C248.025 14.1192 247.938 15.6574 247.514 17.1185Z"
        fill="#1A202C"
      />
      <path
        d="M232.214 27.8079C237.422 27.8079 241.644 23.5858 241.644 18.3776C241.644 13.1694 237.422 8.94727 232.214 8.94727C227.005 8.94727 222.783 13.1694 222.783 18.3776C222.783 23.5858 227.005 27.8079 232.214 27.8079Z"
        fill="#A0616A"
      />
      <path
        d="M244.103 15.9175V17.9676H235.944C236.027 17.5718 236.027 17.1632 235.945 16.7674C235.862 16.3715 235.699 15.997 235.464 15.6674C235.458 16.4697 235.258 17.2587 234.882 17.9676C230.164 19.3627 226.204 19.336 222.959 17.9676C222.721 17.9676 222.484 17.9206 222.264 17.8293C222.044 17.738 221.843 17.6042 221.675 17.4355C221.506 17.2668 221.372 17.0665 221.281 16.8461C221.19 16.6257 221.143 16.3894 221.143 16.1509C221.143 9.86539 226.097 4.56912 232.381 4.43958C233.909 4.40723 235.428 4.68021 236.848 5.24252C238.269 5.80482 239.564 6.64513 240.655 7.71417C241.747 8.78321 242.614 10.0595 243.207 11.4681C243.799 12.8768 244.104 14.3895 244.103 15.9175V15.9175Z"
        fill="#1A202C"
      />
      <path
        d="M242.497 2.02563C241.524 1.57074 240.43 1.37797 239.663 0.474609L239.952 2.00493C238.375 1.1614 236.542 0.932342 234.806 1.36203C234.296 1.46422 233.825 1.70861 233.448 2.06701C233.239 2.28777 233.082 2.55266 232.988 2.84215C232.895 3.13164 232.868 3.43836 232.909 3.73968C232.955 4.00043 233.054 4.24888 233.2 4.46917C233.347 4.68946 233.538 4.87674 233.761 5.01905C234.162 5.24647 234.602 5.39638 235.059 5.46095C236.957 5.84674 238.855 6.23252 240.752 6.61829C241.462 6.82678 242.212 6.85074 242.934 6.68791C243.328 6.55309 243.674 6.30606 243.93 5.97715C244.186 5.64823 244.341 5.25174 244.374 4.83634C244.482 3.52972 243.469 2.48049 242.497 2.02563Z"
        fill="#1A202C"
      />
      <path
        d="M88.6855 73.7299L43.7364 88.2347C43.3357 88.3635 42.9003 88.3281 42.5257 88.1363C42.1511 87.9444 41.8679 87.6119 41.7382 87.2115L36.0528 69.5928C35.924 69.1921 35.9594 68.7568 36.1512 68.3822C36.343 68.0076 36.6756 67.7244 37.076 67.5947L82.0251 53.0899C82.4257 52.9611 82.8611 52.9965 83.2357 53.1883C83.6103 53.3802 83.8935 53.7127 84.0232 54.1131L89.7086 71.7318C89.8374 72.1325 89.802 72.5678 89.6102 72.9424C89.4184 73.317 89.0858 73.6002 88.6855 73.7299Z"
        fill="#F2F2F2"
      />
      <path
        d="M77.8917 76.1493L50.5607 84.9688C48.5233 85.6237 46.3093 85.4437 44.4044 84.4683C42.4996 83.4929 41.0594 81.8017 40.3999 79.7658L39.3194 76.4174C38.6644 74.38 38.8444 72.166 39.8198 70.2611C40.7953 68.3562 42.4864 66.916 44.5224 66.2565L75.2018 56.3564C77.2392 55.7015 79.4532 55.8816 81.3581 56.857C83.2629 57.8324 84.7031 59.5236 85.3626 61.5595C86.303 64.485 86.0445 67.664 84.644 70.3992C83.2434 73.1344 80.815 75.2023 77.8917 76.1493Z"
        fill="white"
      />
      <path
        d="M66.4293 64.7557L48.3632 70.5855C48.3121 70.602 48.2581 70.6083 48.2046 70.604C48.151 70.5996 48.0988 70.5848 48.0509 70.5603C48.0031 70.5358 47.9605 70.5021 47.9257 70.4611C47.8908 70.4202 47.8644 70.3728 47.8479 70.3216C47.8314 70.2705 47.8251 70.2165 47.8295 70.163C47.8338 70.1094 47.8486 70.0572 47.8731 70.0093C47.8976 69.9615 47.9313 69.9189 47.9723 69.8841C48.0132 69.8492 48.0606 69.8228 48.1118 69.8063L66.1779 63.9765C66.2811 63.9435 66.3933 63.9527 66.4897 64.0023C66.5861 64.0518 66.6589 64.1375 66.6922 64.2407C66.7255 64.3438 66.7165 64.456 66.6672 64.5525C66.618 64.6491 66.5324 64.7221 66.4293 64.7557Z"
        fill="#E6E6E6"
      />
      <path
        d="M76.9172 65.9616L49.7057 74.7425C49.6546 74.7592 49.6006 74.7655 49.5469 74.7613C49.4933 74.757 49.441 74.7422 49.3931 74.7177C49.3451 74.6933 49.3025 74.6595 49.2676 74.6186C49.2327 74.5776 49.2062 74.5301 49.1897 74.4789C49.1732 74.4277 49.1669 74.3737 49.1713 74.3201C49.1756 74.2664 49.1905 74.2141 49.2151 74.1662C49.2397 74.1184 49.2734 74.0758 49.3145 74.041C49.3555 74.0062 49.403 73.9798 49.4543 73.9634L76.6658 65.1824C76.7691 65.149 76.8815 65.1581 76.9781 65.2076C77.0748 65.2571 77.1478 65.3429 77.1811 65.4463C77.2145 65.5496 77.2054 65.6619 77.1559 65.7586C77.1064 65.8552 77.0206 65.9282 76.9172 65.9616Z"
        fill="#E6E6E6"
      />
      <path
        d="M78.2583 70.1181L51.0468 78.899C50.9957 78.9155 50.9417 78.9218 50.8882 78.9175C50.8346 78.9132 50.7824 78.8983 50.7345 78.8738C50.6867 78.8493 50.6441 78.8156 50.6093 78.7747C50.5744 78.7337 50.548 78.6863 50.5315 78.6352C50.515 78.584 50.5087 78.5301 50.513 78.4765C50.5174 78.4229 50.5322 78.3707 50.5567 78.3229C50.5812 78.275 50.6149 78.2324 50.6559 78.1976C50.6968 78.1628 50.7442 78.1364 50.7954 78.1199L78.0069 69.3389C78.1101 69.3058 78.2223 69.315 78.3188 69.3645C78.4153 69.414 78.4882 69.4998 78.5215 69.603C78.5548 69.7062 78.5458 69.8184 78.4964 69.915C78.4471 70.0115 78.3615 70.0846 78.2583 70.1181Z"
        fill="#E6E6E6"
      />
      <path
        d="M78.2583 70.1181L51.0468 78.899C50.9957 78.9155 50.9417 78.9218 50.8882 78.9175C50.8346 78.9132 50.7824 78.8983 50.7345 78.8738C50.6867 78.8493 50.6441 78.8156 50.6093 78.7747C50.5744 78.7337 50.548 78.6863 50.5315 78.6352C50.515 78.584 50.5087 78.5301 50.513 78.4765C50.5174 78.4229 50.5322 78.3707 50.5567 78.3229C50.5812 78.275 50.6149 78.2324 50.6559 78.1976C50.6968 78.1628 50.7442 78.1364 50.7954 78.1199L78.0069 69.3389C78.1101 69.3058 78.2223 69.315 78.3188 69.3645C78.4153 69.414 78.4882 69.4998 78.5215 69.603C78.5548 69.7062 78.5458 69.8184 78.4964 69.915C78.4471 70.0115 78.3615 70.0846 78.2583 70.1181Z"
        fill="#E6E6E6"
      />
      <path
        d="M80.4901 60.5094C83.1058 60.5094 85.2262 58.389 85.2262 55.7733C85.2262 53.1576 83.1058 51.0371 80.4901 51.0371C77.8744 51.0371 75.7539 53.1576 75.7539 55.7733C75.7539 58.389 77.8744 60.5094 80.4901 60.5094Z"
        fill="#F7B80B"
      />
      <path
        d="M80.3224 57.6728C80.2588 57.6728 80.1962 57.658 80.1393 57.6296C80.0825 57.6012 80.033 57.5599 79.9949 57.509L78.9907 56.1701C78.9583 56.1271 78.9347 56.0781 78.9212 56.0259C78.9077 55.9738 78.9047 55.9195 78.9122 55.8662C78.9198 55.8129 78.9378 55.7616 78.9652 55.7152C78.9926 55.6688 79.0289 55.6284 79.072 55.596C79.1151 55.5637 79.1641 55.5402 79.2163 55.5269C79.2685 55.5136 79.3228 55.5107 79.3761 55.5183C79.4294 55.526 79.4806 55.5442 79.5269 55.5717C79.5732 55.5992 79.6136 55.6356 79.6458 55.6787L80.3028 56.5547L81.9902 54.0236C82.0504 53.9333 82.1441 53.8706 82.2506 53.8493C82.357 53.828 82.4676 53.8498 82.558 53.9101C82.6483 53.9703 82.7111 54.064 82.7324 54.1705C82.7537 54.2769 82.7318 54.3875 82.6715 54.4779L80.6631 57.4905C80.6268 57.545 80.5779 57.59 80.5206 57.6217C80.4633 57.6534 80.3993 57.6709 80.3338 57.6727L80.3224 57.6728Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_446_51579">
        <rect
          width="288.948"
          height="230"
          fill="white"
          transform="translate(0.474609 0.474609)"
        />
      </clipPath>
    </defs>
  </svg>
);
