import Layout from "../../components/Layout";
import ContactForm from "../../modules/ContactForm/ContactForm";

export default function ContactFormPage() {
  return (
    <Layout progressBar={false}>
      <ContactForm />
    </Layout>
  );
}
