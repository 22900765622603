export const AccompagnementTransfertIcon = () => (
  <svg
    width="181"
    height="120"
    viewBox="0 0 181 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2336_63680)">
      <path
        d="M134.797 22.6297C134.032 31.0539 131.854 39.9368 128.438 48.5077C124.213 59.1066 118.442 68.4422 111.747 75.505L111.375 75.181C118.038 68.1522 123.783 58.8579 127.99 48.3027C131.792 38.7643 134.053 28.838 134.533 19.6016L135.007 19.681C134.957 20.6565 134.887 21.6394 134.797 22.6297Z"
        fill="#EDF2F7"
      />
      <path
        d="M137.842 71.5567C128.438 86.5175 117.029 97.207 105.717 101.656L105.488 101.236C116.722 96.8182 128.064 86.1834 137.425 71.291L137.842 71.5567Z"
        fill="#EDF2F7"
      />
      <path
        d="M134.568 33.118C133.804 41.5422 131.626 50.4251 128.209 58.996C123.985 69.5949 118.213 78.9305 111.518 85.9933L111.146 85.6693C117.809 78.6405 123.555 69.3461 127.762 58.791C131.564 49.2525 133.825 39.3263 134.304 30.0898L134.779 30.1693C134.729 31.1448 134.658 32.1277 134.568 33.118Z"
        fill="#EDF2F7"
      />
      <path
        d="M28.9998 54.7458H22.8298C22.698 54.7461 22.5678 54.7165 22.4491 54.6593C22.3303 54.6021 22.226 54.5187 22.1441 54.4155C22.0631 54.3149 22.006 54.1972 21.977 54.0714C21.948 53.9455 21.9479 53.8147 21.9767 53.6888C22.5643 51.0959 22.5274 48.4005 21.869 45.8247C21.8273 45.6551 21.8374 45.4769 21.8979 45.3131C21.9584 45.1493 22.0665 45.0073 22.2084 44.9055C25.381 42.6236 27.8796 42.6899 29.6347 45.1026C29.7138 45.2126 29.7664 45.3394 29.7883 45.4731C30.2572 48.2923 30.2832 51.1675 29.8652 53.9947C29.8361 54.2034 29.7325 54.3944 29.5734 54.5325C29.4143 54.6706 29.2105 54.7464 28.9998 54.7458Z"
        fill="#E2E8F0"
      />
      <path
        d="M27.0865 48.5667H24.964C24.8467 48.5667 24.7341 48.5201 24.6511 48.4371C24.5681 48.3541 24.5215 48.2415 24.5215 48.1242C24.5215 48.0068 24.5681 47.8943 24.6511 47.8113C24.7341 47.7283 24.8467 47.6816 24.964 47.6816H27.0865C27.2037 47.6819 27.3161 47.7286 27.3988 47.8116C27.4816 47.8945 27.5281 48.007 27.5281 48.1242C27.5281 48.2414 27.4816 48.3538 27.3988 48.4368C27.3161 48.5197 27.2037 48.5665 27.0865 48.5667Z"
        fill="white"
      />
      <path
        d="M27.0865 50.1195H24.964C24.8467 50.1195 24.7341 50.0728 24.6511 49.9898C24.5681 49.9068 24.5215 49.7943 24.5215 49.6769C24.5215 49.5595 24.5681 49.447 24.6511 49.364C24.7341 49.281 24.8467 49.2344 24.964 49.2344H27.0865C27.2037 49.2346 27.3161 49.2814 27.3988 49.3643C27.4816 49.4473 27.5281 49.5597 27.5281 49.6769C27.5281 49.7941 27.4816 49.9065 27.3988 49.9895C27.3161 50.0725 27.2037 50.1192 27.0865 50.1195Z"
        fill="white"
      />
      <path
        d="M27.0865 51.6722H24.964C24.8467 51.6722 24.7341 51.6256 24.6511 51.5426C24.5681 51.4596 24.5215 51.347 24.5215 51.2296C24.5215 51.1123 24.5681 50.9997 24.6511 50.9167C24.7341 50.8337 24.8467 50.7871 24.964 50.7871H27.0865C27.2037 50.7874 27.3161 50.8341 27.3988 50.9171C27.4816 51 27.5281 51.1124 27.5281 51.2296C27.5281 51.3469 27.4816 51.4593 27.3988 51.5422C27.3161 51.6252 27.2037 51.6719 27.0865 51.6722Z"
        fill="white"
      />
      <path
        d="M141.133 82.2946H134.963C134.831 82.2949 134.701 82.2653 134.582 82.2081C134.463 82.1509 134.359 82.0676 134.277 81.9643C134.196 81.8637 134.139 81.746 134.11 81.6202C134.081 81.4943 134.081 81.3636 134.109 81.2377C134.697 78.6448 134.66 75.9494 134.002 73.3735C133.96 73.2039 133.97 73.0257 134.031 72.8619C134.091 72.6981 134.199 72.5561 134.341 72.4543C137.514 70.1725 140.012 70.2387 141.768 72.6514C141.847 72.7614 141.899 72.8882 141.921 73.0219C142.39 75.8411 142.416 78.7163 141.998 81.5435C141.969 81.7522 141.865 81.9433 141.706 82.0814C141.547 82.2195 141.343 82.2952 141.133 82.2946Z"
        fill="#E2E8F0"
      />
      <path
        d="M139.22 76.1156H137.098C136.981 76.1153 136.868 76.0686 136.786 75.9856C136.703 75.9026 136.656 75.7902 136.656 75.673C136.656 75.5558 136.703 75.4434 136.786 75.3604C136.868 75.2774 136.981 75.2307 137.098 75.2305H139.22C139.338 75.2305 139.45 75.2771 139.533 75.3601C139.616 75.4431 139.663 75.5556 139.663 75.673C139.663 75.7904 139.616 75.9029 139.533 75.9859C139.45 76.0689 139.338 76.1156 139.22 76.1156Z"
        fill="white"
      />
      <path
        d="M139.22 77.6683H137.098C136.981 77.668 136.868 77.6213 136.786 77.5383C136.703 77.4554 136.656 77.343 136.656 77.2257C136.656 77.1085 136.703 76.9961 136.786 76.9131C136.868 76.8302 136.981 76.7834 137.098 76.7832H139.22C139.338 76.7832 139.45 76.8298 139.533 76.9128C139.616 76.9958 139.663 77.1084 139.663 77.2257C139.663 77.3431 139.616 77.4557 139.533 77.5387C139.45 77.6217 139.338 77.6683 139.22 77.6683Z"
        fill="white"
      />
      <path
        d="M139.22 79.2191H137.098C136.981 79.2188 136.868 79.1721 136.786 79.0891C136.703 79.0062 136.656 78.8937 136.656 78.7765C136.656 78.6593 136.703 78.5469 136.786 78.4639C136.868 78.381 136.981 78.3342 137.098 78.334H139.22C139.338 78.334 139.45 78.3806 139.533 78.4636C139.616 78.5466 139.663 78.6592 139.663 78.7765C139.663 78.8939 139.616 79.0065 139.533 79.0895C139.45 79.1724 139.338 79.2191 139.22 79.2191Z"
        fill="white"
      />
      <path
        d="M164.481 120.03C164.481 120.06 164.475 120.09 164.464 120.118C164.452 120.146 164.435 120.172 164.414 120.193C164.392 120.215 164.367 120.232 164.339 120.243C164.311 120.255 164.281 120.261 164.25 120.261H17.5277C17.4665 120.261 17.4078 120.236 17.3645 120.193C17.3212 120.15 17.2969 120.091 17.2969 120.03C17.2969 119.968 17.3212 119.91 17.3645 119.866C17.4078 119.823 17.4665 119.799 17.5277 119.799H164.25C164.281 119.799 164.311 119.805 164.339 119.816C164.367 119.828 164.392 119.845 164.414 119.866C164.435 119.888 164.452 119.913 164.464 119.941C164.475 119.969 164.481 119.999 164.481 120.03Z"
        fill="#CCCCCC"
      />
      <path
        d="M56.0016 105.894L55.6168 100.542C53.096 99.2819 50.3058 98.6562 47.4883 98.7192C51.4521 101.783 51.1346 107.907 53.8797 112.098C54.7459 113.398 55.8937 114.487 57.238 115.284C58.5822 116.08 60.0885 116.564 61.6452 116.7L64.9441 118.597C65.3214 116.103 65.1536 113.557 64.4521 111.134C63.7507 108.711 62.5322 106.469 60.8808 104.562C59.9438 103.502 58.8769 102.563 57.7054 101.769C56.9843 103.84 56.0016 105.894 56.0016 105.894Z"
        fill="#EDF2F7"
      />
      <path
        d="M125.776 106.672L126.16 101.319C128.681 100.059 131.471 99.4335 134.289 99.4965C130.325 102.56 130.643 108.684 127.897 112.875C127.031 114.175 125.883 115.264 124.539 116.061C123.195 116.858 121.689 117.342 120.132 117.477L116.833 119.375C116.456 116.881 116.624 114.334 117.325 111.911C118.026 109.488 119.245 107.246 120.896 105.339C121.833 104.279 122.9 103.341 124.072 102.547C124.793 104.617 125.776 106.672 125.776 106.672Z"
        fill="#EDF2F7"
      />
      <path
        d="M89.1431 46.2706C91.1788 46.2706 92.8291 44.6202 92.8291 42.5845C92.8291 40.5487 91.1788 38.8984 89.1431 38.8984C87.1073 38.8984 85.457 40.5487 85.457 42.5845C85.457 44.6202 87.1073 46.2706 89.1431 46.2706Z"
        fill="#F7B80B"
      />
      <path
        d="M91.0351 41.0492C90.9796 40.9963 90.9059 40.9668 90.8293 40.9668C90.7527 40.9668 90.679 40.9963 90.6235 41.0492C89.8414 41.673 89.1389 42.3905 88.5318 43.1857C88.3828 42.8974 88.2337 42.609 88.0847 42.3206C87.9127 41.9878 87.4103 42.2818 87.5822 42.6144C87.8002 43.0362 88.0181 43.4579 88.2361 43.8797C88.2624 43.9228 88.2993 43.9584 88.3433 43.9831C88.3874 44.0077 88.437 44.0207 88.4874 44.0207C88.5379 44.0207 88.5875 44.0077 88.6315 43.9831C88.6755 43.9584 88.7124 43.9228 88.7387 43.8797C89.3856 42.9686 90.1588 42.1541 91.0351 41.4608C91.0892 41.406 91.1196 41.332 91.1196 41.255C91.1196 41.1779 91.0892 41.104 91.0351 41.0492Z"
        fill="white"
      />
      <path
        d="M72.6483 53.0377L67.0898 44.3084L73.5264 40.8613L75.2158 47.5214L87.1189 53.9535C87.5889 53.7844 88.0979 53.7557 88.5839 53.8708C89.0698 53.986 89.5118 54.2401 89.8559 54.6021C90.2 54.9641 90.4313 55.4184 90.5217 55.9096C90.612 56.4008 90.5575 56.9077 90.3648 57.3684C90.172 57.8292 89.8494 58.2239 89.4362 58.5044C89.023 58.785 88.5371 58.9393 88.0377 58.9484C87.5384 58.9576 87.0471 58.8213 86.6239 58.5561C86.2007 58.2909 85.8638 57.9083 85.6542 57.455L72.6483 53.0377Z"
        fill="#A0616A"
      />
      <path
        d="M63.0305 33.8382C61.284 36.4025 67.4907 45.7234 67.4907 45.7234C67.793 45.0716 70.6834 52.3905 71.1675 51.9581C72.5434 50.7294 74.6972 47.3066 75.8944 47.1541C76.579 47.0668 74.3065 42.2112 74.3065 42.2112C74.3065 42.2112 73.2679 39.3439 71.8585 35.6813C71.4793 34.6384 70.8424 33.7084 70.007 32.9779C69.1717 32.2474 68.1651 31.7402 67.081 31.5033C67.081 31.5033 64.7771 31.2739 63.0305 33.8382Z"
        fill="#2D3748"
      />
      <path
        d="M63.1158 117.382L65.7556 117.381L67.0114 107.199L63.1152 107.199L63.1158 117.382Z"
        fill="#A0616A"
      />
      <path
        d="M62.4414 116.522L63.1844 116.522L66.0854 115.342L67.6402 116.521H67.6404C68.5191 116.521 69.3617 116.87 69.983 117.492C70.6044 118.113 70.9534 118.956 70.9535 119.834V119.942L62.4416 119.942L62.4414 116.522Z"
        fill="#1A202C"
      />
      <path
        d="M75.3793 117.382L78.0191 117.381L79.2751 107.199L75.3789 107.199L75.3793 117.382Z"
        fill="#A0616A"
      />
      <path
        d="M74.7051 116.522L75.4481 116.522L78.3491 115.342L79.9039 116.521H79.9041C80.7827 116.521 81.6254 116.87 82.2467 117.492C82.868 118.113 83.2171 118.956 83.2172 119.834V119.942L74.7052 119.942L74.7051 116.522Z"
        fill="#1A202C"
      />
      <path
        d="M76.5227 61.7148L59.352 62.1441C56.0909 63.9154 56.0414 67.749 57.1099 72.3006C57.1099 72.3006 56.7492 75.006 57.4706 75.7274C58.1921 76.4488 58.5528 76.4488 58.1921 77.7113C57.8314 78.9738 59.7372 81.0358 59.5057 81.2673C59.2742 81.4988 58.7331 82.7613 58.7331 82.7613L60.5367 93.0416C60.5367 93.0416 61.7992 110.897 62.1599 111.258C62.5206 111.618 62.8813 111.258 62.5206 112.159C62.1599 113.061 61.7992 112.7 62.1599 113.061C62.4794 113.406 62.7804 113.767 63.0617 114.143H67.4222C67.4222 114.143 67.7509 112.34 67.7509 112.159C67.7509 111.979 68.1117 110.897 68.1117 110.716C68.1117 110.536 67.7928 110.217 67.7928 110.217C67.6827 109.851 67.6082 109.474 67.5706 109.093C67.5706 108.733 66.4884 94.8451 66.4884 94.8451L68.6527 76.8095L73.883 94.4844C73.883 94.4844 73.883 109.995 74.2437 110.356C74.6045 110.716 74.6045 110.536 74.4241 111.258C74.2437 111.979 73.5223 111.799 74.0634 112.34C74.6045 112.881 74.7848 111.979 74.6045 112.881L74.4241 113.783L79.8348 113.86C79.8348 113.86 80.5562 112.34 80.1955 111.799C79.8348 111.258 79.8564 111.405 80.2965 110.52C80.7366 109.634 80.9169 109.454 80.7366 109.274C80.5562 109.093 80.5562 108.134 80.5562 108.134L79.6544 90.8773C79.6544 90.8773 79.4741 72.6613 79.4741 72.1203C79.4605 71.8419 79.5118 71.5641 79.6239 71.3089V70.5745L78.933 67.9721L76.5227 61.7148Z"
        fill="#1A202C"
      />
      <path
        d="M66.2081 26.0119C69.1545 26.0119 71.5431 23.6233 71.5431 20.6768C71.5431 17.7304 69.1545 15.3418 66.2081 15.3418C63.2616 15.3418 60.873 17.7304 60.873 20.6768C60.873 23.6233 63.2616 26.0119 66.2081 26.0119Z"
        fill="#A0616A"
      />
      <path
        d="M68.875 29.5383C70.0244 30.2216 70.7119 31.4771 71.1066 32.7546C71.8373 35.1198 72.2771 37.5653 72.4164 40.0369L72.8332 47.4365L77.9962 63.1922C73.5216 66.9783 56.7025 64.0527 56.7025 64.0527C56.7025 64.0527 56.1862 63.8806 56.7025 63.3643C57.2188 62.848 57.7214 63.3054 57.2051 62.7891C56.6888 62.2728 57.0448 62.848 57.2169 62.3317C57.389 61.8154 57.2169 62.1596 57.0448 61.9875C56.8727 61.8154 58.377 60.2665 58.377 60.2665L57.0003 51.2226L55.2793 32.9802C57.3445 30.3988 61.4978 28.877 61.4978 28.877L62.571 26.9453L67.9368 27.3746L68.875 29.5383Z"
        fill="#2D3748"
      />
      <path
        d="M70.606 17.8428C71.0207 18.1521 71.3279 18.5836 71.4844 19.0766C71.5445 18.2709 71.5483 17.462 71.4958 16.6557C71.4329 16.0891 71.0341 15.9617 70.5722 15.814C70.1551 15.6806 69.6834 15.5299 69.5565 14.9799C67.8794 13.3485 64.9601 13.4307 62.9621 13.9998C61.8628 14.313 61.2302 14.7153 61.1736 14.951L61.1619 14.9995L61.1172 15.0218C60.2698 15.4456 60.1048 16.7836 60.1157 17.8314C60.1364 19.8055 60.818 22.0476 61.202 22.2298C61.2223 22.2394 61.2275 22.2356 61.2363 22.2283C61.8145 21.7662 62.5199 22.2634 62.8299 22.5337C63.0127 21.3067 63.6015 20.1764 64.502 19.3233C65.4026 18.4702 66.5631 17.9435 67.7982 17.8273C68.2015 17.7883 68.6017 17.7212 68.9957 17.6265C69.1955 17.5748 69.401 17.5478 69.6074 17.5462C69.9627 17.5408 70.3112 17.6443 70.606 17.8428Z"
        fill="#1A202C"
      />
      <path
        d="M103.724 65.9657L113.986 55.5442L116.69 47.2092L112.339 45.4492C110.184 47.6234 107.965 54.2793 107.965 54.2793L100.96 63.6803C100.904 63.7011 100.848 63.7231 100.794 63.7491C100.471 63.9013 100.193 64.1342 99.9871 64.4252C99.7809 64.7162 99.6533 65.0554 99.6165 65.4102C99.5798 65.7649 99.6351 66.1231 99.7773 66.4502C99.9194 66.7773 100.144 67.0622 100.428 67.2773C100.712 67.4925 101.047 67.6306 101.401 67.6784C101.754 67.7262 102.114 67.6821 102.445 67.5502C102.777 67.4183 103.068 67.2032 103.292 66.9256C103.516 66.6481 103.665 66.3174 103.724 65.9657Z"
        fill="#FFB8B8"
      />
      <path
        d="M111.152 46.4029L116.655 47.9947L116.675 47.9907C118.698 47.598 120.298 44.2367 121.284 41.4861C121.608 40.5789 121.576 39.5823 121.194 38.6979C120.812 37.8135 120.108 37.1072 119.225 36.7219C118.571 36.442 117.853 36.3505 117.15 36.4576C116.447 36.5647 115.789 36.8663 115.248 37.3282L112.505 39.6906L111.152 46.4029Z"
        fill="#F7B80B"
      />
      <path
        d="M106.445 117.073L103.486 117.072L102.078 105.658L106.446 105.658L106.445 117.073Z"
        fill="#FFB8B8"
      />
      <path
        d="M107.2 116.108L106.367 116.108L103.115 114.785L101.372 116.107H101.372C100.387 116.107 99.4426 116.499 98.7461 117.195C98.0496 117.892 97.6583 118.836 97.6582 119.821V119.942L107.2 119.942L107.2 116.108Z"
        fill="#1A202C"
      />
      <path
        d="M119.576 117.073L116.617 117.072L115.209 105.658L119.577 105.658L119.576 117.073Z"
        fill="#FFB8B8"
      />
      <path
        d="M120.331 116.108L119.498 116.108L116.246 114.785L114.503 116.107H114.503C113.518 116.107 112.573 116.499 111.877 117.195C111.18 117.892 110.789 118.836 110.789 119.821V119.942L120.331 119.942L120.331 116.108Z"
        fill="#1A202C"
      />
      <path
        d="M124.088 67.9067C124.088 67.9067 124.434 72.0532 123.57 76.027C122.706 80.0008 122.706 91.1922 122.706 91.1922L120.806 113L115.104 112.482L113.549 76.027L108.021 88.2939L108.539 90.0216L107.848 90.4535C107.848 90.4535 108.366 93.8226 108.021 93.8226C107.675 93.8226 106.811 94.3409 106.811 94.3409L107.502 112.137L101.628 111.964L99.5547 86.9117L101.801 72.5715L101.974 69.8072L102.751 69.8391L103.147 68.6842L103.874 66.6973L124.088 67.9067Z"
        fill="#1A202C"
      />
      <path
        d="M124.387 68.778L124.417 68.7348C126.062 66.4324 123.588 60.8998 123.215 60.0978L124.787 59.5879L124.433 56.5803L124.265 55.2395L125.466 54.0386L125.469 54.0087L126.161 47.9616L127.165 43.9452C127.544 42.4269 127.512 40.8351 127.072 39.3334C126.632 37.8316 125.801 36.474 124.663 35.3998L122.34 33.2099L119.517 29.0332H114.965L113.404 31.9813C111.891 32.331 110.549 33.2005 109.611 34.4384C108.673 35.6762 108.199 37.2038 108.271 38.7552L108.625 46.4872L107.24 56.1143L107.239 57.8565L105.681 59.5884L106.021 61.2899L104.835 61.7982L104.311 64.242C104.095 64.528 102.664 66.4354 102.664 66.9569C102.664 67.0351 102.722 67.1108 102.846 67.1948C103.821 67.8549 109.209 69.2007 111.594 68.2464C114.134 67.2307 124.232 68.7544 124.334 68.77L124.387 68.778Z"
        fill="#F7B80B"
      />
      <path
        d="M116.654 27.686C119.983 27.686 122.682 24.9869 122.682 21.6574C122.682 18.328 119.983 15.6289 116.654 15.6289C113.324 15.6289 110.625 18.328 110.625 21.6574C110.625 24.9869 113.324 27.686 116.654 27.686Z"
        fill="#FFB8B8"
      />
      <path
        d="M116.075 27.784C117.009 27.7745 117.94 27.6852 118.859 27.5172C119.768 27.3077 121.774 25.7565 122.321 24.8401C122.727 24.1592 122.971 23.106 123.104 22.3424C123.283 21.3319 123.249 20.295 123.002 19.2987C122.755 18.3024 122.302 17.369 121.672 16.5589C121.429 16.2196 121.099 15.9522 120.717 15.7851C120.683 15.7729 120.648 15.7621 120.613 15.7531C120.058 15.6225 119.556 15.3291 119.169 14.9107C119.094 14.826 119.013 14.7468 118.927 14.6737C118.493 14.3266 117.984 14.0871 117.44 13.9746C116.562 13.7636 115.305 14.028 113.705 14.7611C112.901 15.1294 112.009 15.0267 111.321 14.896C111.283 14.8896 111.243 14.8934 111.207 14.907C111.17 14.9206 111.138 14.9436 111.113 14.9736C110.829 15.2543 110.449 15.417 110.05 15.4293C109.806 15.4463 109.558 15.7913 109.26 16.2406C109.192 16.3426 109.113 16.4617 109.056 16.5343L109.044 16.3979L108.91 16.5539C108.699 16.799 108.553 17.0936 108.486 17.4102C108.42 17.7268 108.434 18.0551 108.529 18.3646C108.623 18.6741 108.794 18.9546 109.026 19.1801C109.258 19.4055 109.544 19.5686 109.856 19.6542C110.103 19.7132 110.356 19.7469 110.611 19.7546C110.765 19.7644 110.924 19.7746 111.078 19.7972C111.57 19.8848 112.031 20.0985 112.416 20.4174C112.8 20.7364 113.096 21.1497 113.273 21.6169C113.315 21.7249 113.381 21.8218 113.467 21.9002C113.552 21.9786 113.654 22.0363 113.765 22.0689C113.876 22.1015 113.994 22.1081 114.108 22.0883C114.222 22.0684 114.33 22.0226 114.423 21.9544C114.579 21.8266 114.763 21.7383 114.961 21.6971C115.158 21.6559 115.362 21.663 115.556 21.7178C115.724 21.8059 115.858 21.9493 115.933 22.1239C116.024 22.3183 116.171 22.4811 116.355 22.5916C116.983 22.8949 117.062 24.3086 116.87 25.4937C116.685 26.636 116.282 27.4694 115.891 27.5201C115.59 27.5592 115.556 27.5816 115.535 27.6356L115.517 27.6839L115.552 27.7278C115.72 27.784 115.899 27.8031 116.075 27.784Z"
        fill="#1A202C"
      />
      <path
        d="M111.075 47.0477L108.177 43.3555C105.225 44.1683 99.9878 48.8366 99.9878 48.8366L89.232 53.5042C88.873 53.4439 88.5044 53.4792 88.1634 53.6066C87.8224 53.7339 87.521 53.9489 87.2895 54.2298C87.058 54.5108 86.9045 54.8477 86.8446 55.2068C86.7848 55.5658 86.8205 55.9343 86.9483 56.2752C87.0761 56.6161 87.2914 56.9172 87.5726 57.1484C87.8538 57.3796 88.191 57.5326 88.5501 57.5921C88.9092 57.6515 89.2777 57.6153 89.6184 57.4871C89.9591 57.3589 90.26 57.1432 90.4909 56.8617L104.581 52.9309L111.075 47.0477Z"
        fill="#FFB8B8"
      />
      <path
        d="M110.381 47.8931L114.953 42.7962L115.207 39.1846C115.254 38.4754 115.102 37.7671 114.768 37.1398C114.434 36.5125 113.931 35.9912 113.316 35.6347C112.479 35.1571 111.492 35.015 110.554 35.2373C109.617 35.4595 108.799 36.0295 108.265 36.8319C106.65 39.2668 104.912 42.5588 105.806 44.4149L105.815 44.4337L110.381 47.8931Z"
        fill="#F7B80B"
      />
      <path
        d="M68.2369 67.0493L56.3534 60.9632C55.5081 60.5303 55.2281 59.382 55.7292 58.4035L63.4776 43.2744C63.9787 42.296 65.0741 41.8521 65.9193 42.285L77.8029 48.3711C78.6482 48.804 78.9282 49.9523 78.427 50.9308L70.6787 66.0599C70.1776 67.0383 69.0822 67.4822 68.2369 67.0493Z"
        fill="#E2E8F0"
      />
      <path
        d="M66.2078 64.2515L58.134 60.1165C57.3423 59.7111 57.08 58.6357 57.5494 57.7193L63.9865 45.1503C64.4559 44.2339 65.4818 43.8183 66.2734 44.2237L76.025 49.2179C76.8166 49.6234 77.0788 50.6987 76.6095 51.6151L73.1125 58.4433C71.022 62.5252 69.7341 66.0575 66.2078 64.2515Z"
        fill="white"
      />
      <path
        d="M70.1983 57.3881L63.0471 53.7256C62.9597 53.6633 62.8975 53.5716 62.8721 53.4673C62.8467 53.363 62.8596 53.253 62.9085 53.1574C62.9575 53.0619 63.0392 52.9871 63.1387 52.9468C63.2382 52.9065 63.3489 52.9033 63.4505 52.9378L70.6018 56.6003C70.6892 56.6626 70.7513 56.7543 70.7767 56.8586C70.8022 56.9629 70.7893 57.0729 70.7403 57.1685C70.6914 57.264 70.6097 57.3388 70.5102 57.3791C70.4107 57.4194 70.3 57.4226 70.1983 57.3881Z"
        fill="#F7B80B"
      />
      <path
        d="M63.8822 56.1181L62.2502 55.2823C62.1628 55.2199 62.1007 55.1283 62.0752 55.024C62.0498 54.9196 62.0627 54.8097 62.1117 54.7141C62.1606 54.6185 62.2423 54.5438 62.3418 54.5034C62.4413 54.4631 62.552 54.4599 62.6537 54.4945L64.2857 55.3303C64.3731 55.3926 64.4352 55.4843 64.4606 55.5886C64.4861 55.6929 64.4731 55.8029 64.4242 55.8984C64.3753 55.994 64.2936 56.0688 64.1941 56.1091C64.0946 56.1494 63.9839 56.1526 63.8822 56.1181Z"
        fill="#F7B80B"
      />
      <path
        d="M65.9758 59.155L61.4533 56.8389C61.3659 56.7766 61.3038 56.6849 61.2784 56.5806C61.2529 56.4763 61.2659 56.3663 61.3148 56.2707C61.3637 56.1752 61.4454 56.1004 61.5449 56.0601C61.6444 56.0198 61.7551 56.0166 61.8568 56.0511L66.3792 58.3673C66.4667 58.4296 66.5288 58.5213 66.5542 58.6256C66.5796 58.7299 66.5667 58.8399 66.5178 58.9354C66.4688 59.031 66.3871 59.1058 66.2876 59.1461C66.1881 59.1864 66.0774 59.1896 65.9758 59.155V59.155Z"
        fill="#F7B80B"
      />
      <path
        d="M73.0284 51.8627L65.8772 48.2002C65.7897 48.1379 65.7276 48.0462 65.7022 47.9419C65.6768 47.8376 65.6897 47.7276 65.7386 47.6321C65.7876 47.5365 65.8693 47.4617 65.9688 47.4214C66.0683 47.3811 66.179 47.3779 66.2806 47.4125L73.4318 51.0749C73.5193 51.1372 73.5814 51.2289 73.6068 51.3332C73.6323 51.4375 73.6193 51.5475 73.5704 51.6431C73.5214 51.7386 73.4398 51.8134 73.3403 51.8537C73.2408 51.894 73.1301 51.8972 73.0284 51.8627Z"
        fill="#F7B80B"
      />
      <path
        d="M66.7123 50.5927L65.0803 49.7569C64.9929 49.6946 64.9307 49.6029 64.9053 49.4986C64.8799 49.3942 64.8928 49.2843 64.9418 49.1887C64.9907 49.0931 65.0724 49.0184 65.1719 48.9781C65.2714 48.9377 65.3821 48.9345 65.4838 48.9691L67.1157 49.8049C67.2032 49.8672 67.2653 49.9589 67.2907 50.0632C67.3162 50.1675 67.3032 50.2775 67.2543 50.3731C67.2053 50.4686 67.1236 50.5434 67.0241 50.5837C66.9246 50.624 66.8139 50.6272 66.7123 50.5927Z"
        fill="#F7B80B"
      />
      <path
        d="M68.8058 53.6297L64.2834 51.3135C64.196 51.2512 64.1339 51.1595 64.1084 51.0552C64.083 50.9509 64.0959 50.8409 64.1449 50.7453C64.1938 50.6498 64.2755 50.575 64.375 50.5347C64.4745 50.4944 64.5852 50.4912 64.6869 50.5257L69.2093 52.8419C69.2967 52.9042 69.3588 52.9959 69.3843 53.1002C69.4097 53.2045 69.3968 53.3145 69.3478 53.41C69.2989 53.5056 69.2172 53.5804 69.1177 53.6207C69.0182 53.661 68.9075 53.6642 68.8058 53.6297L68.8058 53.6297Z"
        fill="#F7B80B"
      />
      <path
        d="M58.3897 53.878L55.7051 43.8835L62.8792 42.5254L62.4934 49.3855L71.9196 59.0914C72.4186 59.071 72.9128 59.1963 73.3419 59.4519C73.7709 59.7075 74.1164 60.0824 74.3361 60.5309C74.5557 60.9795 74.6402 61.4822 74.5791 61.9779C74.5179 62.4736 74.3139 62.9408 73.9919 63.3226C73.6698 63.7043 73.2436 63.9841 72.7653 64.1278C72.287 64.2715 71.7772 64.273 71.2981 64.132C70.8189 63.991 70.3912 63.7136 70.067 63.3337C69.7428 62.9538 69.5361 62.4877 69.4722 61.9924L58.3897 53.878Z"
        fill="#A0616A"
      />
      <path
        d="M54.9713 32.6789C52.5362 34.6014 55.6617 45.3546 55.6617 45.3546C56.1456 44.8235 56.7079 52.6724 57.2994 52.4051C58.9805 51.6455 62.0616 49.0263 63.2495 49.2398C63.9288 49.3619 63.2171 44.0482 63.2171 44.0482C63.2171 44.0482 63.0862 41.0014 62.8402 37.0847C62.7912 35.9761 62.4624 34.8979 61.8846 33.9506C61.3068 33.0032 60.4987 32.2174 59.5356 31.6663C59.5356 31.6663 57.4065 30.7565 54.9713 32.6789Z"
        fill="#2D3748"
      />
      <path
        d="M95.4911 76.0618L89.0063 72.9997C88.8676 72.9345 88.7455 72.8389 88.649 72.7198C88.5526 72.6007 88.4844 72.4614 88.4495 72.3122C88.4144 72.1662 88.4127 72.0142 88.4447 71.8676C88.4767 71.7209 88.5415 71.5834 88.6342 71.4654C90.5127 69.0646 91.8025 66.2571 92.4004 63.268C92.4455 63.0391 92.5687 62.833 92.7489 62.6848C92.929 62.5365 93.155 62.4553 93.3883 62.4551C96.6956 62.4663 99.1674 63.674 100.735 66.044C100.817 66.1673 100.872 66.3071 100.895 66.4537C100.918 66.6003 100.909 66.7502 100.868 66.8929C99.9608 70.021 98.5798 72.9917 96.7733 75.7019C96.6392 75.9068 96.4354 76.0561 96.1997 76.1223C95.9639 76.1885 95.7122 76.167 95.4911 76.0618Z"
        fill="#E2E8F0"
      />
      <path
        d="M97.3976 71.7681L92.4393 69.4267C92.3159 69.3685 92.2207 69.2636 92.1747 69.1352C92.1287 69.0068 92.1355 68.8654 92.1938 68.742C92.252 68.6186 92.3569 68.5235 92.4853 68.4774C92.6137 68.4314 92.7552 68.4383 92.8785 68.4965L97.8369 70.8379C97.898 70.8667 97.9528 70.9073 97.9981 70.9574C98.0435 71.0074 98.0786 71.0658 98.1014 71.1294C98.1242 71.193 98.1343 71.2605 98.131 71.3279C98.1277 71.3954 98.1112 71.4616 98.0824 71.5226C98.0535 71.5837 98.0129 71.6385 97.9629 71.6839C97.9129 71.7293 97.8544 71.7644 97.7908 71.7872C97.7272 71.81 97.6598 71.82 97.5923 71.8168C97.5249 71.8135 97.4587 71.797 97.3976 71.7681H97.3976Z"
        fill="white"
      />
      <path
        d="M98.1671 70.1373L93.2088 67.7959C93.0854 67.7376 92.9903 67.6328 92.9442 67.5044C92.8982 67.3759 92.9051 67.2345 92.9633 67.1111C93.0216 66.9878 93.1264 66.8926 93.2548 66.8466C93.3833 66.8005 93.5247 66.8074 93.6481 66.8657L98.6064 69.207C98.7298 69.2653 98.8249 69.3702 98.871 69.4986C98.917 69.627 98.9101 69.7684 98.8519 69.8918C98.7936 70.0151 98.6888 70.1103 98.5604 70.1563C98.432 70.2024 98.2905 70.1955 98.1671 70.1373V70.1373Z"
        fill="white"
      />
      <path
        d="M91.5427 18.93L91.0811 18.9805C89.0232 10.8936 85.0344 5.29113 79.5454 2.77857C72.5675 -0.415348 63.9391 1.72942 55.2496 8.81788C46.5159 15.9425 38.7842 27.1964 33.4791 40.5069L33.0312 40.3019C38.3597 26.9333 46.131 15.6251 54.9137 8.46081C63.7403 1.26035 72.5448 -0.900151 79.7052 2.37739C85.3517 4.96195 89.4449 10.6858 91.5427 18.93Z"
        fill="#EDF2F7"
      />
      <path
        d="M40.622 29.4634C39.415 32.0061 38.267 34.6404 37.2097 37.293C31.1257 52.5575 28.0425 68.1468 28.2945 82.3761L27.819 82.3253C27.5668 68.0431 30.6588 52.4002 36.7619 37.088C37.8221 34.4279 38.9735 31.7862 40.184 29.2363L40.622 29.4634Z"
        fill="#EDF2F7"
      />
      <path
        d="M40.3935 39.9517C39.1865 42.4944 38.0384 45.1287 36.9812 47.7812C30.8971 63.0458 27.814 78.635 28.066 92.8643L27.5905 92.8136C27.3383 78.5314 30.4303 62.8885 36.5333 47.5763C37.5936 44.9162 38.745 42.2745 39.9555 39.7246L40.3935 39.9517Z"
        fill="#EDF2F7"
      />
      <path
        d="M27.6142 43.7664C28.1399 42.9763 27.8287 41.8453 26.9193 41.2403C26.0099 40.6352 24.8465 40.7852 24.3209 41.5753C23.7953 42.3654 24.1064 43.4963 25.0158 44.1014C25.9252 44.7064 27.0886 44.5564 27.6142 43.7664Z"
        fill="#CCCCCC"
      />
      <path
        d="M139.542 72.2976C140.068 71.5076 139.756 70.3766 138.847 69.7715C137.938 69.1665 136.774 69.3165 136.249 70.1066C135.723 70.8966 136.034 72.0276 136.944 72.6326C137.853 73.2377 139.016 73.0877 139.542 72.2976Z"
        fill="#CCCCCC"
      />
    </g>
    <defs>
      <clipPath id="clip0_2336_63680">
        <rect
          width="180"
          height="120"
          fill="white"
          transform="translate(0.888672)"
        />
      </clipPath>
    </defs>
  </svg>
);
