export const CalendarSelectionSimplifieIcon = () => (
  <svg width="166" height="100" viewBox="0 0 166 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1506_78632)">
      <path
        d="M164.209 94.9607H8.69055C8.2905 94.9603 7.90695 94.8012 7.62407 94.5183C7.34119 94.2354 7.18208 93.8519 7.18164 93.4518V1.50891C7.18208 1.10886 7.34119 0.725312 7.62407 0.442431C7.90695 0.15955 8.2905 0.000436717 8.69055 0H164.209C164.609 0.000436718 164.992 0.159551 165.275 0.442432C165.558 0.725313 165.717 1.10886 165.718 1.50891V93.4518C165.717 93.8519 165.558 94.2354 165.275 94.5183C164.992 94.8012 164.609 94.9603 164.209 94.9607ZM8.69055 0.603563C8.45052 0.603825 8.22039 0.699294 8.05066 0.869022C7.88093 1.03875 7.78547 1.26888 7.7852 1.50891V93.4518C7.78547 93.6918 7.88093 93.922 8.05066 94.0917C8.22039 94.2614 8.45052 94.3569 8.69055 94.3571H164.209C164.449 94.3569 164.679 94.2614 164.849 94.0917C165.018 93.922 165.114 93.6918 165.114 93.4518V1.50891C165.114 1.26888 165.018 1.03875 164.849 0.869023C164.679 0.699295 164.449 0.603825 164.209 0.603563H8.69055Z"
        fill="#E6E6E6"/>
      <path
        d="M71.4577 7.34392C71.1439 7.34917 70.8447 7.47752 70.6246 7.70129C70.4046 7.92506 70.2812 8.22634 70.2812 8.54019C70.2812 8.85403 70.4046 9.15531 70.6246 9.37908C70.8447 9.60285 71.1439 9.7312 71.4577 9.73645H101.452C101.769 9.74113 102.074 9.62009 102.302 9.39985C102.529 9.17962 102.66 8.87816 102.666 8.56154C102.671 8.24492 102.551 7.93897 102.332 7.71075C102.112 7.48252 101.811 7.35063 101.495 7.34398L101.491 7.34392C101.478 7.34369 101.465 7.34369 101.452 7.34392H71.4577Z"
        fill="#3F3D56"/>
      <path
        d="M108.253 7.01035C108.209 7.05449 108.184 7.11436 108.184 7.17679C108.184 7.23922 108.209 7.29909 108.253 7.34324L108.253 7.34326L109.264 8.3538H106.772C106.709 8.35399 106.65 8.37887 106.606 8.423C106.562 8.46712 106.537 8.52689 106.537 8.5892C106.537 8.65151 106.562 8.71128 106.606 8.75541C106.65 8.79954 106.709 8.82442 106.772 8.8246H109.264L108.253 9.83515C108.231 9.85701 108.214 9.88296 108.202 9.91151C108.19 9.94007 108.184 9.97069 108.184 10.0016C108.184 10.0325 108.19 10.0631 108.202 10.0917C108.214 10.1202 108.231 10.1462 108.253 10.1681C108.275 10.1899 108.301 10.2073 108.33 10.2191C108.358 10.2309 108.389 10.237 108.42 10.237C108.451 10.237 108.481 10.2309 108.51 10.2191C108.538 10.2073 108.564 10.1899 108.586 10.1681L109.998 8.75566C110.043 8.71151 110.067 8.65164 110.067 8.58921C110.067 8.52677 110.043 8.4669 109.998 8.42275L108.586 7.01035C108.564 6.98849 108.538 6.97115 108.51 6.95932C108.481 6.9475 108.451 6.94141 108.42 6.94141C108.389 6.94141 108.358 6.9475 108.33 6.95932C108.301 6.97115 108.275 6.98849 108.253 7.01035L108.253 7.01035Z"
        fill="#E6E6E6"/>
      <path
        d="M64.6424 7.01035C64.6865 7.0545 64.7113 7.11437 64.7113 7.1768C64.7113 7.23923 64.6865 7.2991 64.6424 7.34325L64.6424 7.34327L63.6318 8.35381H66.1237C66.1547 8.35368 66.1854 8.35967 66.214 8.37143C66.2427 8.38319 66.2687 8.40049 66.2907 8.42233C66.3126 8.44417 66.33 8.47013 66.3419 8.49872C66.3539 8.5273 66.36 8.55796 66.36 8.58893C66.3601 8.6199 66.354 8.65057 66.3422 8.67918C66.3303 8.7078 66.313 8.7338 66.2911 8.75569C66.2692 8.77759 66.2431 8.79494 66.2145 8.80677C66.1859 8.8186 66.1552 8.82466 66.1243 8.82461H63.6318L64.6424 9.83515C64.6642 9.85701 64.6816 9.88296 64.6934 9.91152C64.7052 9.94008 64.7113 9.97069 64.7113 10.0016C64.7113 10.0325 64.7052 10.0631 64.6934 10.0917C64.6816 10.1203 64.6642 10.1462 64.6424 10.1681C64.6205 10.1899 64.5946 10.2073 64.566 10.2191C64.5374 10.2309 64.5068 10.237 64.4759 10.237C64.445 10.237 64.4144 10.2309 64.3858 10.2191C64.3573 10.2073 64.3313 10.1899 64.3095 10.1681L62.8971 8.75567C62.8529 8.71152 62.8281 8.65164 62.8281 8.58921C62.8281 8.52678 62.8529 8.4669 62.8971 8.42275L64.3095 7.01036C64.3536 6.96621 64.4135 6.94141 64.4759 6.94141C64.5383 6.9414 64.5982 6.9662 64.6424 7.01034L64.6424 7.01035Z"
        fill="#E6E6E6"/>
      <path
        d="M7.09053 100.025C5.72678 100.025 4.39366 99.6204 3.25975 98.8627C2.12584 98.1051 1.24206 97.0282 0.720183 95.7683C0.198301 94.5083 0.0617515 93.1219 0.327805 91.7844C0.593858 90.4469 1.25057 89.2182 2.21488 88.2539C3.17919 87.2896 4.4078 86.6329 5.74534 86.3669C7.08288 86.1008 8.46927 86.2374 9.72921 86.7592C10.9891 87.2811 12.066 88.1649 12.8237 89.2988C13.5813 90.4327 13.9857 91.7658 13.9857 93.1296C13.9857 94.9583 13.2593 96.7121 11.9662 98.0052C10.6731 99.2983 8.91925 100.025 7.09053 100.025Z"
        fill="#F7B80B"/>
      <path
        d="M9.81098 92.4492H7.77049V90.4087C7.77049 90.2283 7.69883 90.0553 7.57127 89.9277C7.44371 89.8002 7.27071 89.7285 7.09031 89.7285C6.90992 89.7285 6.73692 89.8002 6.60936 89.9277C6.4818 90.0553 6.41014 90.2283 6.41014 90.4087V92.4492H4.36962C4.18923 92.4492 4.01622 92.5209 3.88867 92.6484C3.76111 92.776 3.68945 92.949 3.68945 93.1294C3.68945 93.3098 3.76111 93.4828 3.88867 93.6103C4.01622 93.7379 4.18923 93.8095 4.36962 93.8095H6.41013V95.8501C6.41013 96.0305 6.48179 96.2035 6.60935 96.331C6.73691 96.4586 6.90991 96.5302 7.09031 96.5302C7.2707 96.5302 7.44371 96.4586 7.57127 96.331C7.69883 96.2035 7.77048 96.0305 7.77048 95.8501V93.8095H9.81098C9.99137 93.8095 10.1644 93.7379 10.2919 93.6103C10.4195 93.4828 10.4911 93.3098 10.4911 93.1294C10.4911 92.949 10.4195 92.776 10.2919 92.6484C10.1644 92.5209 9.99137 92.4492 9.81098 92.4492Z"
        fill="white"/>
      <path d="M40.4782 19.5918H19.7559V33.675H40.4782V19.5918Z" fill="#F1F1F1"/>
      <path d="M68.6442 19.5918H47.9219V33.675H68.6442V19.5918Z" fill="#F1F1F1"/>
      <path d="M96.8102 19.5918H76.0879V33.675H96.8102V19.5918Z" fill="#F1F1F1"/>
      <path d="M124.976 19.5918H104.254V33.675H124.976V19.5918Z" fill="#F1F1F1"/>
      <path d="M153.142 19.5918H132.42V33.675H153.142V19.5918Z" fill="#F1F1F1"/>
      <path d="M40.5524 44.3379H19.8301V58.421H40.5524V44.3379Z" fill="#F1F1F1"/>
      <path d="M68.7204 44.3379H47.998V58.421H68.7204V44.3379Z" fill="#F1F1F1"/>
      <path d="M96.8864 44.3379H76.1641V58.421H96.8864V44.3379Z" fill="#F1F1F1"/>
      <path d="M125.052 44.3379H104.33V58.421H125.052V44.3379Z" fill="#F1F1F1"/>
      <path d="M153.218 44.3379H132.496V58.421H153.218V44.3379Z" fill="#F1F1F1"/>
      <path d="M40.6286 69.084H19.9062V83.1671H40.6286V69.084Z" fill="#F1F1F1"/>
      <path d="M68.7966 69.084H48.0742V83.1671H68.7966V69.084Z" fill="#F1F1F1"/>
      <path d="M96.9626 69.084H76.2402V83.1671H96.9626V69.084Z" fill="#F1F1F1"/>
      <path d="M125.129 69.084H104.406V83.1671H125.129V69.084Z" fill="#F1F1F1"/>
      <path d="M153.295 69.084H132.572V83.1671H153.295V69.084Z" fill="#F1F1F1"/>
      <path
        d="M80.3889 26.6332C81.8334 26.6332 83.0043 25.4623 83.0043 24.0178C83.0043 22.5733 81.8334 21.4023 80.3889 21.4023C78.9444 21.4023 77.7734 22.5733 77.7734 24.0178C77.7734 25.4623 78.9444 26.6332 80.3889 26.6332Z"
        fill="#F7B80B"/>
      <path
        d="M51.8205 51.3793C53.265 51.3793 54.436 50.2083 54.436 48.7639C54.436 47.3194 53.265 46.1484 51.8205 46.1484C50.376 46.1484 49.2051 47.3194 49.2051 48.7639C49.2051 50.2083 50.376 51.3793 51.8205 51.3793Z"
        fill="#F7B80B"/>
      <path
        d="M80.3889 76.1254C81.8334 76.1254 83.0043 74.9544 83.0043 73.51C83.0043 72.0655 81.8334 70.8945 80.3889 70.8945C78.9444 70.8945 77.7734 72.0655 77.7734 73.51C77.7734 74.9544 78.9444 76.1254 80.3889 76.1254Z"
        fill="#F7B80B"/>
      <path
        d="M109.158 51.3793C110.603 51.3793 111.774 50.2083 111.774 48.7639C111.774 47.3194 110.603 46.1484 109.158 46.1484C107.714 46.1484 106.543 47.3194 106.543 48.7639C106.543 50.2083 107.714 51.3793 109.158 51.3793Z"
        fill="#3F3D56"/>
      <path
        d="M137.123 51.3793C138.568 51.3793 139.739 50.2083 139.739 48.7639C139.739 47.3194 138.568 46.1484 137.123 46.1484C135.679 46.1484 134.508 47.3194 134.508 48.7639C134.508 50.2083 135.679 51.3793 137.123 51.3793Z"
        fill="#3F3D56"/>
      <path
        d="M51.8205 76.1254C53.265 76.1254 54.436 74.9544 54.436 73.51C54.436 72.0655 53.265 70.8945 51.8205 70.8945C50.376 70.8945 49.2051 72.0655 49.2051 73.51C49.2051 74.9544 50.376 76.1254 51.8205 76.1254Z"
        fill="#3F3D56"/>
    </g>
    <defs>
      <clipPath id="clip0_1506_78632">
        <rect width="165.618" height="100" fill="white" transform="translate(0.191406)"/>
      </clipPath>
    </defs>
  </svg>

);