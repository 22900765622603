import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Container,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NorelaButton from "../../components/Buttons/NorelaButton";
import { useSessionContext } from "../../context/SessionContext";
import { useResetOrder } from "../../hooks/useResetOrder";
import IntroductionContent from "../../modules/Introduction/IntroductionContent";
import { PageURL } from "../../types/pages";
import { useSegment } from "../../context/SegmentContext";

const Introduction = () => {
  const location = useLocation();
  const { sessionInfo, updateHistory } = useSessionContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ref = useRef(null);
  const { completeResetOrder } = useResetOrder();
  const navigate = useNavigate();
  const { track } = useSegment();
  function onResetOrder() {
    track("Reset on refresh");
    completeResetOrder();
    updateHistory(PageURL.HOME);
    navigate(PageURL.HOME);
    onClose();
  }
  function continueOrder() {
    track("Continue on refresh");
    const previousPage = sessionInfo.history[sessionInfo.history.length - 1];
    navigate(previousPage);
    updateHistory(previousPage);
    onClose();
  }
  useEffect(() => {
    if (
      sessionInfo.history.length >= 2 &&
      sessionInfo.history[sessionInfo.history.length - 1] === "/" &&
      sessionInfo.history[sessionInfo.history.length - 2] !== "/"
    )
      onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={ref}
        onClose={() => {}}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            Voulez-vous reprendre votre commande précédente ?
          </AlertDialogHeader>
          <AlertDialogCloseButton />

          <AlertDialogFooter gap="1rem">
            <Button color="red" backgroundColor="white" onClick={onResetOrder}>
              Recommencer la commande
            </Button>
            <NorelaButton onClick={continueOrder}>
              Reprendre la commande
            </NorelaButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Container
        maxWidth="1780px"
        width="100%"
        maxHeight="1080px"
        height="100vh"
        minWidth="350px"
        margin="0 auto"
        p={{ "2xl": "49px 103px 0px 111px", sm: "30px 50px 10px" }}
      >
        <Flex direction="column" justifyContent="space-between">
          <Flex direction="column">
            <IntroductionContent />
          </Flex>
        </Flex>
      </Container>
    </>
  );
};

export default Introduction;
