import { Icons } from "../../components/PicturesVariant/PicturesVariant";
import { PersonnelType } from "../../context/UserContext";

export type PersonnelConfig = {
  icon: Icons;
  type: PersonnelType;
  tooltipText: string;
  textWithField?: boolean;
  detail?: string;
};

export type TeamConfig = {
  leader: string;
  countForLeader: number;
  personnel: PersonnelConfig[];
};

export const ACCUEIL_CONFIG: TeamConfig = {
  leader: "chefHotesse",
  countForLeader: 5,
  personnel: [
    {
      icon: Icons.REGISSEUR_ICON,
      type: PersonnelType.regisseur,
      tooltipText:
        "Le top manager de votre opération. Le régisseur est indispensable sur les dispositifs de grandes envergures qui nécessitent, compétences techniques, sociales, opérationnelles, ...   ",
    },
    {
      icon: Icons.SELECT_DE_PERSONNEL_CHEF_ICON,
      type: PersonnelType.chefHotesse,
      tooltipText:
        "Indispensable pour gérer plus de 5 personnes durant l'événement",
    },
    {
      icon: Icons.SELECT_DE_PERSONNEL_BARMAN_ICON,
      type: PersonnelType.barman,
      tooltipText:
        "Il/elle s'occupe de la préparation et du service des boissons",
    },
    {
      icon: Icons.SELECT_DE_PERSONNEL_MAITRE_HOTEL_ICON,
      type: PersonnelType.maitreHotel,
      tooltipText: "Un maître d'hôtel",
    },
    {
      icon: Icons.ACCUEIL_ICON,
      type: PersonnelType.hotessePolyvalente,
      tooltipText: "Accueillez vos invités avec charme et distinction",
    },
    {
      icon: Icons.ACCUEIL_ICON,
      type: PersonnelType.accueil,
      tooltipText: "Accueillez vos invités avec une hôtesse d'accueil",
    },
    {
      icon: Icons.ORIENTATION_ICON,
      type: PersonnelType.orientation,
      tooltipText: "Guidez vos invités dans votre événement",
    },
    {
      icon: Icons.PLACEMENENT_ICON,
      type: PersonnelType.placementEnSalle,
      tooltipText:
        "Guidez et placer vos invités selon un plan de table ou de salle définie",
    },
    {
      icon: Icons.ACCOMPAGNEMENT_ICON,
      type: PersonnelType.accompagnementTransfert,
      tooltipText:
        "Encadrer et accompagner vos invités entre deux sites de réceptions",
    },
    {
      icon: Icons.PLATEAU_ICON,
      type: PersonnelType.plateau,
      tooltipText: "Service de boissons durant votre événement",
    },
    {
      icon: Icons.VOITURIER_ICON,
      type: PersonnelType.voiturier,
      tooltipText:
        "Gestion de votre parking - 1 voiturier pour 5 véhicules / 30 min",
    },
    {
      icon: Icons.VESTIAIRE_ICON,
      type: PersonnelType.vestiaire,
      tooltipText:
        "Chaque hôtesse peut gérer jusqu'à 100 personnes en hiver, 200 en été",
    },
    {
      icon: Icons.VESTIAIRE_ICON,
      type: PersonnelType.responsableVestiaire,
      tooltipText:
        "Indispensable pour organiser et gérer votre équipe vestiaire. Nous la recommandons au-delà de 5 hôtesses vestiaire.",
    },
    {
      icon: Icons.EMARGEMENT_ICON,
      type: PersonnelType.emargement,
      tooltipText:
        "Augmentez la fludité de votre emmargement avec l'option tablettes digitales. Contrôlez votre jauge en temps réel, identifiez les invités présents et abscents.",
      detail:
        "Par défaut, sa mission ne sera que sur les 3 premières heures de l'évenement (modifiable dans la section suivante).",
    },
    {
      icon: Icons.HOTESSE_BILINGUE_ICON,
      type: PersonnelType.hotesseBilingue,
      tooltipText:
        "Accueillez vos invités avec une hôtesse bilingue qui saura les guider et communiquer avec eux en anglais, ou dans la langue de votre choix.",
      detail: "Par défaut, l'hotesse bilingue parle anglais.",
    },
    {
      icon: Icons.HOTESSE_TRILINGUE_ICON,
      type: PersonnelType.hotesseTrilingue,
      tooltipText:
        "Accueillez vos invités avec une hôtesse trilingue qui saura les guider et communiquer avec eux en anglais, ou dans les langue de votre choix.",
      detail: "Par défaut, l'hotesse trilingue parle anglais et espagnol.",
    },
    // {
    //   icon: Icons.PASSAGE_ICON,
    //   type: PersonnelType.passageMicro,
    //   tooltipText:
    //     "Gestion du micro et passage de main en main sur scène ou en salle.",
    // },
  ],
};

export const STREET_MARKETING_CONFIG: TeamConfig = {
  leader: "chef",
  countForLeader: 5,
  personnel: [
    {
      icon: Icons.SELECT_DE_PERSONNEL_CHEF_ICON,
      type: PersonnelType.chef,
      tooltipText:
        "Indispensable pour gérer votre opération. Il s'ocuppe des présences, des stocks, de la cadence de distribution, du comportemental terrain et de tous les aspects opérationnels…",
    },
    {
      icon: Icons.SELECT_DE_PERSONNEL_DISTRIBUTION_ICON,
      type: PersonnelType.distribution,
      tooltipText:
        "Diffusez jusqu’à 150 flyers / heure / profil . Ce taux de diffusion peut augmentez en cas d'échantillonage ou de distribution de goodies !",
    },
    {
      icon: Icons.ANIMATION_ICON,
      type: PersonnelType.animationEvenementielle,
      tooltipText:
        "Profils ayant une forte appétence à l'animation et au divertissement",
    },
    // {
    //   icon: Icons.ANIMATION_MICRO_ICON,
    //   type: PersonnelType.animationMicroStreetMarketing,
    //   tooltipText:
    //     "Spécialiste de cet exercice particulier, souvent comédien, il anime votre événement derrière son micro",
    // },
    {
      icon: Icons.SELECT_DE_PERSONNEL_ROADS_ICON,
      type: PersonnelType.roads,
      tooltipText:
        "Profils dédiés au montage, mise en place et logistique terrain",
    },
  ],
};

export const ANIMATION_MAGASIN_CONFIG: TeamConfig = {
  leader: "chef",
  countForLeader: 5,
  personnel: [
    {
      icon: Icons.SELECT_DE_PERSONNEL_DEMONSTRATION_PRODUIT_ICON,
      type: PersonnelType.demonstrationProduit,
      tooltipText:
        "Formé à votre produit selon un brief précis l'animateur le fait découvrir et tester, ",
    },
    {
      icon: Icons.ANIMATION_MICRO_ICON,
      type: PersonnelType.animationMicroRetails,
      tooltipText:
        "Spécialiste de cet exercice particulier, souvent comédien, il anime votre événement derrière son micro",
    },
    {
      icon: Icons.SELECT_DE_PERSONNEL_BRAND_AMBASSADEUR_ICON,
      type: PersonnelType.brandAmbassadeur,
      tooltipText:
        "Personnel formé et qualifié pour représenter votre marque et votre produit. LA formation est poussée et le profil spécialisé",
    },
    {
      icon: Icons.ANIMATEUR_MASCOTTE_ICON,
      type: PersonnelType.animateurMascotte,
      tooltipText: "Spécialiste de cet exercice particulier",
    },
    {
      icon: Icons.ANIMATEUR_BILINGUE_ICON,
      type: PersonnelType.animateurBilingue,
      tooltipText: "Personnel bilingue",
      textWithField: false,
    },
    {
      icon: Icons.SELECT_DE_PERSONNEL_AUTRE_ICON,
      type: PersonnelType.autreDitesNousTout,
      tooltipText: "Renseignez le type de profil souhaité",
      textWithField: true,
    },
  ],
};

export const ANIMATION_MAGASIN_ONLY_CONFIG: TeamConfig = {
  leader: "chef",
  countForLeader: 5,
  personnel: ANIMATION_MAGASIN_CONFIG.personnel.concat([
    {
      icon: Icons.REGISSEUR_ICON,
      type: PersonnelType.regisseur,
      tooltipText:
        "Le top manager de votre opération. Le régisseur est indispensable sur les dispositifs de grandes envergures qui nécessitent, compétences techniques, sociales, opérationnelles, ...   ",
    },
  ]),
};

export const ANIMATION_STREET_ONLY_CONFIG: TeamConfig = {
  leader: "chef",
  countForLeader: 5,
  personnel: STREET_MARKETING_CONFIG.personnel.concat([
    {
      icon: Icons.REGISSEUR_ICON,
      type: PersonnelType.regisseur,
      tooltipText:
        "Le top manager de votre opération. Le régisseur est indispensable sur les dispositifs de grandes envergures qui nécessitent, compétences techniques, sociales, opérationnelles, ...   ",
    },
  ]),
};

export const ANIMATION_CONFIG: TeamConfig = {
  leader: "chef",
  countForLeader: 5,
  personnel: STREET_MARKETING_CONFIG.personnel
    .concat([
      {
        icon: Icons.REGISSEUR_ICON,
        type: PersonnelType.regisseur,
        tooltipText:
          "Le top manager de votre opération. Le régisseur est indispensable sur les dispositifs de grandes envergures qui nécessitent, compétences techniques, sociales, opérationnelles, ...   ",
      },
    ])
    .concat(ANIMATION_MAGASIN_CONFIG.personnel),
};
