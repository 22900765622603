import { Icon, IconProps } from "@chakra-ui/react";

export const WhitePlusIcon = (props: IconProps) => (
  <Icon
    width="8"
    height="8"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.9998 13.2008C5.68155 13.2008 5.37632 13.0744 5.15128 12.8493C4.92623 12.6243 4.7998 12.319 4.7998 12.0008C4.7998 11.6825 4.92623 11.3773 5.15128 11.1523C5.37632 10.9272 5.68155 10.8008 5.9998 10.8008H17.9998C18.3181 10.8008 18.6233 10.9272 18.8483 11.1523C19.0734 11.3773 19.1998 11.6825 19.1998 12.0008C19.1998 12.319 19.0734 12.6243 18.8483 12.8493C18.6233 13.0744 18.3181 13.2008 17.9998 13.2008H5.9998Z"
      fill="white"
    />
    <path
      d="M10.7998 6.00078C10.7998 5.68252 10.9262 5.3773 11.1513 5.15225C11.3763 4.92721 11.6815 4.80078 11.9998 4.80078C12.3181 4.80078 12.6233 4.92721 12.8483 5.15225C13.0734 5.3773 13.1998 5.68252 13.1998 6.00078V18.0008C13.1998 18.319 13.0734 18.6243 12.8483 18.8493C12.6233 19.0744 12.3181 19.2008 11.9998 19.2008C11.6815 19.2008 11.3763 19.0744 11.1513 18.8493C10.9262 18.6243 10.7998 18.319 10.7998 18.0008V6.00078Z"
      fill="white"
    />
  </Icon>
);
