export const RoleMaitreHotelIcon = () => (
  <svg
    width="180"
    height="120"
    viewBox="0 0 180 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.5791 116.259H68.6745L70.147 104.32L65.5786 104.32L65.5791 116.259Z"
      fill="#FFB6B6"
    />
    <path
      d="M64.7896 115.249L70.8855 115.248C71.9157 115.248 72.9041 115.658 73.6326 116.386C74.3611 117.115 74.7704 118.103 74.7705 119.133V119.259L64.7897 119.26L64.7896 115.249Z"
      fill="#2F2E41"
    />
    <path
      d="M76.1836 116.259H79.279L80.7515 104.32L76.1831 104.32L76.1836 116.259Z"
      fill="#FFB6B6"
    />
    <path
      d="M75.394 115.249L81.4899 115.248H81.4902C82.5205 115.248 83.5086 115.658 84.2371 116.386C84.9656 117.115 85.3749 118.103 85.375 119.133V119.259L75.3942 119.26L75.394 115.249Z"
      fill="#2F2E41"
    />
    <path
      d="M87.9456 64.2476L80.3841 110.953L75.3345 110.7L75.4597 67.8151L87.9456 64.2476Z"
      fill="#2F2E41"
    />
    <path
      d="M67.5604 49.9779L85.9071 50.2327L87.9457 64.2475L75.4597 70.1083L70.0324 109.69L65.2353 109.438L63.4834 60.4253L67.5604 49.9779Z"
      fill="#2F2E41"
    />
    <path
      d="M78.5176 13.7943C81.6136 13.7943 84.1235 11.2844 84.1235 8.18833C84.1235 5.09226 81.6136 2.5824 78.5176 2.5824C75.4215 2.5824 72.9116 5.09226 72.9116 8.18833C72.9116 11.2844 75.4215 13.7943 78.5176 13.7943Z"
      fill="#FFB9B9"
    />
    <path
      d="M81.1419 17.0298L84.3787 21.1839L82.8497 53.0356L70.8735 52.7808L68.835 25.0062L71.383 17.8713L72.3051 16.2723L81.1419 17.0298Z"
      fill="#E4E4E4"
    />
    <path
      d="M73.6696 9.64033L73.5368 11.9065C73.5368 11.9065 71.8925 8.18829 71.6377 4.36607C71.3829 0.543851 75.7148 -0.220596 78.2629 -0.475412C80.811 -0.730229 85.1429 1.05347 85.1429 3.09199C85.1429 5.13052 80.5562 4.62089 78.7725 4.11126C76.9888 3.60163 76.2244 7.93349 76.2244 7.93349L75.2051 8.18831L73.6696 9.64033Z"
      fill="#2F2E41"
    />
    <path
      d="M87.4361 36.7276L86.4168 46.4106L88.4553 52.2713L89.7294 57.8772C89.7294 57.8772 89.7294 63.2284 84.6331 63.4832C79.5368 63.738 64.7575 63.2284 64.7575 63.2284C64.7575 63.2284 62.719 62.9735 60.9353 58.8965C59.1516 54.8195 64.5027 50.9972 64.5027 50.9972L66.0316 45.6461L65.522 39.0209L61.9545 21.6935L72.5294 16.215L75.2049 42.3335L80.9383 16.9794L82.8494 18.3809L91.0035 21.6935L87.4361 36.7276Z"
      fill="#2F2E41"
    />
    <path
      d="M113.131 119.5H48.0571V118.969H113.228L113.131 119.5Z"
      fill="#3F3D56"
    />
    <path
      d="M109.69 25.1499L114.198 22.2055C114.335 21.7544 114.597 21.352 114.954 21.0461C115.311 20.7402 115.748 20.5434 116.213 20.4792C116.678 20.4149 117.152 20.4859 117.578 20.6836C118.004 20.8813 118.365 21.1975 118.617 21.5947C118.87 21.992 119.005 22.4538 119.004 22.9254C119.004 23.397 118.87 23.8586 118.617 24.2558C118.364 24.6529 118.003 24.9689 117.576 25.1663C117.15 25.3638 116.677 25.4344 116.212 25.3699C116.179 25.3653 116.148 25.3573 116.115 25.3515L111.283 28.8836L109.69 25.1499Z"
      fill="#FFB9B9"
    />
    <path
      d="M103.664 35.0933L114.347 27.1909L110.474 24.1852L105.769 27.7515L88.4173 20.7053C88.1619 20.6618 87.9003 20.6703 87.6483 20.7303C87.3962 20.7903 87.1589 20.9006 86.9504 21.0546C86.7445 21.2053 86.571 21.3961 86.4404 21.6154C86.3098 21.8348 86.2247 22.0783 86.1903 22.3313L86.1819 22.3911L89.3624 27.3667L103.664 35.0933Z"
      fill="#2F2E41"
    />
    <path
      d="M115.748 21.6761C122.577 21.6761 128.114 21.0209 128.114 20.2127C128.114 19.4045 122.577 18.7493 115.748 18.7493C108.919 18.7493 103.382 19.4045 103.382 20.2127C103.382 21.0209 108.919 21.6761 115.748 21.6761Z"
      fill="#2A2939"
    />
    <path
      d="M115.748 20.9444C125.245 20.9444 132.943 20.191 132.943 19.2615C132.943 18.3321 125.245 17.5786 115.748 17.5786C106.252 17.5786 98.5532 18.3321 98.5532 19.2615C98.5532 20.191 106.252 20.9444 115.748 20.9444Z"
      fill="#1A202C"
    />
    <path
      d="M116.883 2.91645C116.56 2.94899 116.235 2.95656 115.911 2.93911C115.856 2.93308 115.8 2.94898 115.757 2.9834C115.713 3.01782 115.684 3.068 115.677 3.12315L115.057 7.77464C115.01 8.11229 114.871 8.43073 114.657 8.69613C114.481 8.90734 114.35 9.15259 114.272 9.41663C114.194 9.68068 114.171 9.9579 114.205 10.2311V18.1325C114.205 18.1325 114.165 19.186 114.975 19.186C115.943 19.3077 116.924 19.3077 117.893 19.186C117.893 19.186 118.541 19.0633 118.541 18.0911V10.3111C118.59 10.0396 118.58 9.7607 118.511 9.49359C118.442 9.22647 118.315 8.97758 118.141 8.76414C117.934 8.49777 117.8 8.18196 117.752 7.84799L117.108 3.09915C117.101 3.04576 117.074 2.99716 117.032 2.96322C116.99 2.92928 116.937 2.91256 116.883 2.91645Z"
      fill="white"
    />
    <path
      d="M116.431 19.4103C115.941 19.4104 115.452 19.3799 114.966 19.3192C114.858 19.3218 114.75 19.3019 114.649 19.2607C114.549 19.2196 114.458 19.1581 114.382 19.0802C114.053 18.7381 114.071 18.1521 114.072 18.1273L114.072 10.2391C114.038 9.94908 114.063 9.65509 114.146 9.37508C114.229 9.09508 114.368 8.83495 114.555 8.61057C114.753 8.36428 114.881 8.06898 114.926 7.75593L115.546 3.10544C115.558 3.01599 115.604 2.93476 115.675 2.87896C115.746 2.82315 115.836 2.79716 115.926 2.80649C116.24 2.8231 116.556 2.81548 116.87 2.7837L116.874 2.78338C116.961 2.77702 117.048 2.80425 117.116 2.85953C117.184 2.91481 117.229 2.99398 117.241 3.08095L117.885 7.83003C117.929 8.14066 118.054 8.43437 118.246 8.6822C118.431 8.90869 118.566 9.17254 118.64 9.45572C118.713 9.73889 118.725 10.0347 118.674 10.3229V18.091C118.674 19.1613 117.925 19.3155 117.918 19.317C117.424 19.3791 116.928 19.4103 116.431 19.4103ZM115.893 3.07164C115.873 3.07069 115.854 3.07715 115.838 3.08976C115.823 3.10238 115.813 3.12024 115.81 3.13988L115.19 7.79219C115.139 8.15405 114.991 8.49534 114.761 8.7798C114.596 8.97732 114.474 9.20654 114.401 9.45331C114.328 9.70008 114.307 9.95917 114.338 10.2146L114.339 10.231V18.1324C114.339 18.1422 114.324 18.6363 114.575 18.8958C114.627 18.9486 114.689 18.9899 114.758 19.0169C114.827 19.0439 114.901 19.0561 114.975 19.0526L114.992 19.0536C115.949 19.1738 116.918 19.1738 117.876 19.0536C117.889 19.0505 118.407 18.9322 118.407 18.091L118.41 10.2871C118.456 10.0347 118.446 9.77525 118.382 9.52683C118.318 9.2784 118.2 9.04695 118.038 8.84857C117.815 8.56318 117.672 8.22467 117.621 7.86662L116.976 3.11696C116.974 3.09748 116.964 3.07975 116.948 3.06724C116.933 3.05473 116.914 3.04834 116.894 3.04931C116.565 3.08223 116.234 3.08989 115.904 3.07224C115.9 3.07184 115.897 3.07165 115.893 3.07164Z"
      fill="#2D3748"
    />
    <path
      d="M115.904 10.3724H116.887C117.278 10.3724 117.653 10.5277 117.929 10.804C118.205 11.0804 118.361 11.4552 118.361 11.846V15.544C118.361 15.9348 118.205 16.3097 117.929 16.586C117.653 16.8624 117.278 17.0176 116.887 17.0176H115.904C115.513 17.0176 115.139 16.8624 114.862 16.586C114.586 16.3097 114.431 15.9348 114.431 15.544V11.846C114.431 11.4552 114.586 11.0804 114.862 10.804C115.139 10.5277 115.513 10.3724 115.904 10.3724Z"
      fill="#F7B80B"
    />
    <path
      d="M124.421 2.91645C124.098 2.94899 123.773 2.95656 123.448 2.93911C123.393 2.93308 123.338 2.94898 123.294 2.9834C123.25 3.01782 123.222 3.068 123.215 3.12315L122.595 7.77464C122.547 8.11229 122.409 8.43073 122.195 8.69613C122.018 8.90734 121.887 9.15259 121.809 9.41663C121.732 9.68068 121.709 9.9579 121.743 10.2311V18.1325C121.743 18.1325 121.703 19.186 122.512 19.186C123.481 19.3077 124.461 19.3077 125.43 19.186C125.43 19.186 126.078 19.0633 126.078 18.0911V10.3111C126.128 10.0396 126.117 9.7607 126.048 9.49359C125.979 9.22647 125.853 8.97758 125.678 8.76414C125.471 8.49777 125.337 8.18196 125.29 7.84799L124.646 3.09915C124.639 3.04576 124.611 2.99716 124.569 2.96322C124.528 2.92928 124.474 2.91256 124.421 2.91645Z"
      fill="white"
    />
    <path
      d="M123.968 19.4103C123.478 19.4104 122.989 19.3799 122.503 19.3192C122.395 19.3218 122.287 19.3019 122.186 19.2607C122.086 19.2196 121.995 19.1581 121.919 19.0802C121.59 18.7381 121.608 18.1521 121.609 18.1273L121.609 10.2391C121.575 9.94908 121.6 9.65509 121.683 9.37508C121.766 9.09508 121.905 8.83495 122.092 8.61057C122.29 8.36428 122.418 8.06898 122.463 7.75593L123.083 3.10544C123.095 3.01599 123.141 2.93476 123.212 2.87896C123.283 2.82315 123.373 2.79716 123.463 2.80649C123.778 2.8231 124.093 2.81548 124.407 2.7837L124.411 2.78338C124.498 2.77702 124.585 2.80425 124.653 2.85953C124.721 2.91481 124.766 2.99398 124.778 3.08095L125.422 7.83003C125.466 8.14066 125.591 8.43437 125.783 8.6822C125.969 8.90869 126.103 9.17254 126.177 9.45572C126.251 9.73889 126.262 10.0347 126.211 10.3229V18.091C126.211 19.1613 125.462 19.3155 125.455 19.317C124.962 19.3791 124.465 19.4103 123.968 19.4103ZM123.43 3.07164C123.41 3.07069 123.391 3.07715 123.375 3.08976C123.36 3.10238 123.35 3.12024 123.347 3.13988L122.727 7.79219C122.676 8.15405 122.528 8.49534 122.298 8.7798C122.134 8.97732 122.011 9.20654 121.938 9.45331C121.865 9.70008 121.844 9.95917 121.875 10.2146L121.876 10.231V18.1324C121.876 18.1422 121.861 18.6363 122.112 18.8958C122.164 18.9486 122.226 18.9899 122.295 19.0169C122.364 19.0439 122.438 19.0561 122.512 19.0526L122.529 19.0536C123.486 19.1738 124.455 19.1738 125.413 19.0536C125.426 19.0505 125.945 18.9322 125.945 18.091L125.947 10.2871C125.993 10.0347 125.984 9.77525 125.919 9.52683C125.855 9.2784 125.737 9.04695 125.575 8.84857C125.353 8.56318 125.209 8.22467 125.158 7.86662L124.514 3.11696C124.511 3.09748 124.501 3.07975 124.486 3.06724C124.47 3.05473 124.451 3.04834 124.431 3.04931C124.102 3.08223 123.771 3.08989 123.441 3.07224C123.437 3.07184 123.434 3.07165 123.43 3.07164Z"
      fill="#2D3748"
    />
    <path
      d="M123.442 10.3724H124.425C124.816 10.3724 125.19 10.5277 125.467 10.804C125.743 11.0804 125.898 11.4552 125.898 11.846V15.544C125.898 15.9348 125.743 16.3097 125.467 16.586C125.19 16.8624 124.816 17.0176 124.425 17.0176H123.442C123.051 17.0176 122.676 16.8624 122.4 16.586C122.124 16.3097 121.968 15.9348 121.968 15.544V11.846C121.968 11.4552 122.124 11.0804 122.4 10.804C122.676 10.5277 123.051 10.3724 123.442 10.3724Z"
      fill="#F7B80B"
    />
    <path
      d="M108.404 3.29328C108.081 3.32582 107.756 3.33339 107.432 3.31595C107.377 3.30991 107.321 3.32581 107.278 3.36023C107.234 3.39465 107.205 3.44484 107.198 3.49998L106.578 8.15147C106.531 8.48912 106.392 8.80756 106.178 9.07296C106.002 9.28418 105.871 9.52942 105.793 9.79347C105.715 10.0575 105.692 10.3347 105.726 10.6079V18.5093C105.726 18.5093 105.686 19.5628 106.496 19.5628C107.464 19.6845 108.445 19.6845 109.414 19.5628C109.414 19.5628 110.062 19.4401 110.062 18.468V10.6879C110.111 10.4165 110.101 10.1375 110.032 9.87042C109.963 9.6033 109.836 9.35441 109.662 9.14098C109.455 8.8746 109.321 8.55879 109.273 8.22482L108.629 3.47598C108.622 3.42259 108.595 3.37399 108.553 3.34005C108.511 3.30611 108.458 3.28939 108.404 3.29328Z"
      fill="white"
    />
    <path
      d="M107.951 19.7873C107.462 19.7873 106.973 19.7569 106.487 19.6962C106.378 19.6987 106.27 19.6788 106.17 19.6377C106.069 19.5966 105.978 19.5351 105.903 19.4571C105.573 19.115 105.592 18.529 105.593 18.5042L105.593 10.6161C105.558 10.326 105.583 10.032 105.666 9.75204C105.749 9.47203 105.889 9.2119 106.076 8.98753C106.274 8.74123 106.402 8.44594 106.446 8.13288L107.066 3.48239C107.078 3.39294 107.125 3.31171 107.195 3.25591C107.266 3.20011 107.356 3.17412 107.446 3.18345C107.761 3.20006 108.077 3.19243 108.39 3.16065L108.394 3.16033C108.482 3.15398 108.568 3.18121 108.637 3.23648C108.705 3.29176 108.749 3.37093 108.761 3.45791L109.405 8.20699C109.45 8.51761 109.574 8.81133 109.767 9.05915C109.952 9.28564 110.086 9.5495 110.16 9.83267C110.234 10.1158 110.246 10.4116 110.195 10.6998V18.468C110.195 19.5382 109.446 19.6925 109.438 19.6939C108.945 19.756 108.448 19.7872 107.951 19.7873ZM107.413 3.4486C107.394 3.44764 107.374 3.4541 107.359 3.46672C107.343 3.47933 107.333 3.49719 107.33 3.51684L106.71 8.16915C106.659 8.53101 106.511 8.87229 106.282 9.15676C106.117 9.35428 105.994 9.5835 105.921 9.83027C105.849 10.077 105.827 10.3361 105.858 10.5915L105.859 10.6079V18.5093C105.859 18.5191 105.845 19.0132 106.096 19.2728C106.147 19.3256 106.21 19.3668 106.279 19.3938C106.348 19.4208 106.421 19.433 106.495 19.4295L106.512 19.4306C107.47 19.5508 108.439 19.5508 109.397 19.4306C109.409 19.4274 109.928 19.3091 109.928 18.468L109.93 10.6641C109.976 10.4116 109.967 10.1522 109.903 9.90378C109.838 9.65535 109.721 9.4239 109.558 9.22552C109.336 8.94013 109.192 8.60163 109.141 8.24358L108.497 3.49392C108.494 3.47443 108.484 3.45671 108.469 3.4442C108.454 3.43169 108.434 3.4253 108.415 3.42627C108.086 3.45918 107.755 3.46685 107.424 3.44919C107.421 3.4488 107.417 3.4486 107.413 3.4486Z"
      fill="#2D3748"
    />
    <path
      d="M107.425 10.7494H108.408C108.799 10.7494 109.174 10.9046 109.45 11.181C109.726 11.4573 109.882 11.8322 109.882 12.223V15.921C109.882 16.3118 109.726 16.6866 109.45 16.963C109.174 17.2393 108.799 17.3946 108.408 17.3946H107.425C107.034 17.3946 106.66 17.2393 106.383 16.963C106.107 16.6866 105.952 16.3118 105.952 15.921V12.223C105.952 11.8322 106.107 11.4573 106.383 11.181C106.66 10.9046 107.034 10.7494 107.425 10.7494Z"
      fill="#F7B80B"
    />
    <path
      d="M120.087 4.04706C119.764 4.0796 119.439 4.08718 119.114 4.06973C119.059 4.06369 119.004 4.07959 118.96 4.11401C118.916 4.14843 118.888 4.19862 118.881 4.25376L118.261 8.90525C118.213 9.24291 118.075 9.56134 117.861 9.82675C117.684 10.038 117.553 10.2832 117.475 10.5472C117.398 10.8113 117.375 11.0885 117.409 11.3617V19.2631C117.409 19.2631 117.369 20.3166 118.178 20.3166C119.147 20.4383 120.127 20.4383 121.096 20.3166C121.096 20.3166 121.744 20.1939 121.744 19.2217V11.4417C121.794 11.1703 121.783 10.8913 121.714 10.6242C121.645 10.3571 121.519 10.1082 121.344 9.89476C121.137 9.62839 121.003 9.31258 120.956 8.9786L120.312 4.22977C120.305 4.17637 120.277 4.12777 120.235 4.09384C120.194 4.0599 120.14 4.04317 120.087 4.04706Z"
      fill="white"
    />
    <path
      d="M119.634 20.5409C119.144 20.541 118.655 20.5105 118.169 20.4498C118.061 20.4524 117.953 20.4325 117.852 20.3914C117.752 20.3502 117.661 20.2888 117.585 20.2108C117.256 19.8687 117.274 19.2827 117.275 19.2579L117.275 11.3697C117.241 11.0797 117.266 10.7857 117.349 10.5057C117.432 10.2257 117.571 9.96556 117.758 9.74119C117.957 9.49489 118.084 9.1996 118.129 8.88655L118.749 4.23605C118.761 4.14661 118.807 4.06537 118.878 4.00957C118.949 3.95377 119.039 3.92778 119.129 3.93711C119.444 3.95372 119.759 3.94609 120.073 3.91431L120.077 3.91399C120.164 3.90764 120.251 3.93487 120.319 3.99015C120.387 4.04542 120.432 4.12459 120.444 4.21157L121.088 8.96065C121.132 9.27127 121.257 9.56499 121.449 9.81282C121.635 10.0393 121.769 10.3032 121.843 10.5863C121.917 10.8695 121.928 11.1653 121.877 11.4535V19.2216C121.877 20.2919 121.128 20.4461 121.121 20.4476C120.628 20.5097 120.131 20.5409 119.634 20.5409ZM119.096 4.20226C119.076 4.20131 119.057 4.20776 119.041 4.22038C119.026 4.23299 119.016 4.25086 119.013 4.2705L118.393 8.92281C118.342 9.28467 118.194 9.62596 117.964 9.91042C117.8 10.1079 117.677 10.3372 117.604 10.5839C117.531 10.8307 117.51 11.0898 117.541 11.3452L117.542 11.3616V19.263C117.542 19.2728 117.527 19.7669 117.778 20.0265C117.83 20.0792 117.892 20.1205 117.961 20.1475C118.03 20.1745 118.104 20.1867 118.178 20.1832L118.195 20.1842C119.152 20.3044 120.121 20.3044 121.079 20.1842C121.092 20.1811 121.611 20.0628 121.611 19.2216L121.613 11.4177C121.659 11.1653 121.65 10.9059 121.585 10.6574C121.521 10.409 121.403 10.1776 121.241 9.97918C121.019 9.69379 120.875 9.35529 120.824 8.99724L120.18 4.24758C120.177 4.2281 120.167 4.21037 120.152 4.19786C120.136 4.18535 120.117 4.17896 120.097 4.17993C119.768 4.21285 119.437 4.22051 119.107 4.20285C119.103 4.20246 119.1 4.20226 119.096 4.20226Z"
      fill="#2D3748"
    />
    <path
      d="M119.108 11.5031H120.091C120.482 11.5031 120.856 11.6583 121.133 11.9347C121.409 12.211 121.564 12.5858 121.564 12.9766V16.6746C121.564 17.0655 121.409 17.4403 121.133 17.7166C120.856 17.993 120.482 18.1482 120.091 18.1482H119.108C118.717 18.1482 118.342 17.993 118.066 17.7166C117.79 17.4403 117.634 17.0655 117.634 16.6746V12.9766C117.634 12.5858 117.79 12.211 118.066 11.9347C118.342 11.6583 118.717 11.5031 119.108 11.5031Z"
      fill="#F7B80B"
    />
    <path
      d="M57.1976 60.3922L59.7619 43.184L59.9643 37.3908L64.7429 38.8425L61.2265 61.3787C61.3975 61.8122 61.446 62.2842 61.3668 62.7434C61.2875 63.2025 61.0836 63.631 60.7772 63.982C60.4708 64.3331 60.0738 64.5931 59.6296 64.7337C59.1854 64.8743 58.7111 64.89 58.2585 64.7792C57.806 64.6684 57.3926 64.4352 57.0636 64.1053C56.7347 63.7754 56.5027 63.3614 56.3932 62.9085C56.2837 62.4556 56.3008 61.9814 56.4427 61.5376C56.5845 61.0938 56.8457 60.6975 57.1976 60.3922Z"
      fill="#FFB9B9"
    />
    <path
      d="M62.1947 55.4027L66.9558 33.9969L66.9522 33.9765L64.7114 21.1629L64.4145 21.1053C63.5578 20.9442 62.6718 21.1086 61.9299 21.5662C61.188 22.0238 60.6434 22.7417 60.4028 23.5795L57.7171 55.7298L62.1947 55.4027Z"
      fill="#2F2E41"
    />
  </svg>
);
