import moment from "moment";
import {
  ContactInput,
  DailySchedule,
  OperationInfoInput,
} from "../apollo/payment/mutation";
import STAFF_ID_TO_TYPE from "../apollo/selectDePersonnel/staffIdToType";
import { CustomerType } from "../context/CustomerContext";
import {
  OnboardingInfo,
  generateSummaryDressCode,
  generateSummaryOnboarding,
} from "../context/OnboardingContext";
import {
  OPTIONS_LIST,
  OptionInformation,
  OptionType,
} from "../context/OptionsContext";
import { SessionType } from "../context/SessionContext";
import {
  EventDateInfo,
  EventSchedule,
  ORGANISATION_TYPE,
  PersonnelSelectionInfo,
  PersonnelType,
  UserInformation,
} from "../context/UserContext";
import {
  getHourValue,
  isAfterMidnight,
} from "../modules/Calendar/CalendarEventSchedule";
import { calcDiffHours, computeSwapHour, getDayList } from "./utils";

export const combineDateWithTime = (date: any, time: any) => {
  if (!time) return "";
  var newDate = moment(date).format("YYYY-MM-DD");
  var newTime = time + ":00";

  return moment(newDate + " " + newTime, "YYYY-MM-DD HH:mm:ss").format(
    "YYYY-MM-DD HH:mm:ss"
  );
};

export const isDateArray = (persons: any) =>
  Object.values(persons).find((person: any) => Array.isArray(person.date));

export enum OrderOptionType {
  formation = "formation",
  talkyWalky = "talkyWalky",
  panneauDirectionnel = "panneauDirectionnel",
  paniere = "paniere",
  portant = "portant",
  tabletteNumerique = "tabletteNumerique",
  ticketsVestiaires = "ticketsVestiaires",
  totbagR = "totbagPrintR",
  totbagRV = "totbagPrintRv",
  tenueImprimee = "tenueImprimee",
  besace = "besaceNoPrint",
  besacePrint = "besacePrintRv",
  flyers = "flyers",
  cabas = "cabas",
  ballonbag = "ballonbagPrintRv",
  oriflamme = "oriflammebagPrintRv",
  tourDeCou = "tourDeCou",
  badge = "badge",
}

export type OrderOptions = {
  [key in OrderOptionType]?: number;
};

export type PersonnelInfo = {
  count: number;
  type: string;
  startDatetime: string;
  endDatetime: string;
  breaktime: string;
};

export function countPeople(
  selectPersonnelInfo: PersonnelSelectionInfo
): number {
  let nb = 0;
  for (const key in selectPersonnelInfo.persons) {
    const person = selectPersonnelInfo.persons[key as PersonnelType]!;
    for (let i = 0; i < person.count; i++) {
      nb += 1;
    }
  }
  return nb;
}

export function summariseEventSchedule(
  eventSchedule: EventSchedule[] | undefined
): DailySchedule[] | undefined {
  let result: DailySchedule[] = [];
  if (!eventSchedule) return result;
  for (const elem of eventSchedule) {
    if (elem?.schedule?.defaultStartHour && elem?.schedule?.defaultEndHour)
      result.push({
        day: elem.day,
        startHour: elem.schedule.defaultStartHour,
        endHour: elem.schedule.defaultEndHour,
      });
  }

  return result;
}
export const parseOperationInfo = (
  userInfos: UserInformation,
  onboardingInfo: OnboardingInfo,
  sessionInfo: SessionType
): OperationInfoInput => {
  const operationInfo: OperationInfoInput = {
    type: "",
    name: undefined,
    locations: undefined,
    startDate: undefined,
    endDate: undefined,
    eventSchedule: undefined,
    totalDays: 0,
    totalPeople: 0,
    brief: "",
    logo: "",
    summaryDressCode: generateSummaryDressCode(onboardingInfo.dressCode),
    summaryOnboarding: generateSummaryOnboarding(onboardingInfo),
  };
  operationInfo.name = onboardingInfo.options.eventName;
  operationInfo.locations = onboardingInfo.options.eventLocations?.filter(
    (eventLocation) => eventLocation.address !== ""
  );
  operationInfo.startDate = getDayList(userInfos.calendarInfo)?.at(0);
  operationInfo.endDate = getDayList(userInfos.calendarInfo)?.at(-1);
  operationInfo.eventSchedule = summariseEventSchedule(
    userInfos.calendarInfo.eventSchedule
  );
  operationInfo.totalDays = getDayList(userInfos.calendarInfo)?.length;
  operationInfo.totalPeople = countPeople(
    userInfos.selectDePersonnelInfo ?? { persons: {} }
  );
  operationInfo.brief = sessionInfo.brief ?? "";
  operationInfo.logo = userInfos.logo ?? "";
  return operationInfo;
};

export const parseOptions = (
  optionInfo: OptionInformation,
  onboardingInfo: OnboardingInfo
): OrderOptions => {
  const allOptions = Object.keys(optionInfo.selectedOptions ?? {}).reduce(
    (acc: OrderOptions, current) => {
      if (optionInfo.selectedOptions[current as OptionType]! > 0) {
        return {
          ...acc,
          [OPTIONS_LIST[current as OptionType]?.id]:
            optionInfo.selectedOptions![current as OptionType],
        };
      }
      return acc;
    },
    {}
  );
  allOptions.flyers =
    onboardingInfo.options.flyerConfig.ownFlyers === true
      ? 0
      : onboardingInfo.options.flyerConfig.nbFlyers;
  return allOptions;
};

export type DetailedPersonnel = {
  type: PersonnelType;
  day: string;
  isWorking: boolean;
  organisationType: ORGANISATION_TYPE;
  startHour: string;
  endHour: string;
  workedHours: number;
  breaktime: string;
};

export function createDetailedPersonnelArray(
  selectPersonnelInfo: PersonnelSelectionInfo,
  calendarInfo: EventDateInfo
): DetailedPersonnel[] {
  if (!selectPersonnelInfo.persons) return [];
  if (!calendarInfo) return [];
  let person = undefined;
  let dateOverrides = undefined;
  let dateOverride = undefined;
  let workObject = undefined;

  let organisationType = ORGANISATION_TYPE.NONE;
  let startHour = undefined;
  let swapHour = undefined;
  let breaktime = "00:00";
  let endHour = undefined;
  let result: DetailedPersonnel[] = [];

  let daysList: string[];

  if (calendarInfo.days) {
    daysList = calendarInfo.days.map((day) => day);
  } else {
    const numberOfDays = moment(calendarInfo?.period?.endDate).diff(
      moment(calendarInfo?.period?.startDate),
      "days"
    );
    const firstDay = moment(calendarInfo?.period?.startDate);
    daysList = [];
    for (let i = 0; i <= numberOfDays; i++) {
      daysList.push(firstDay.clone().toISOString());
      firstDay.add(1, "day");
    }
  }

  for (const key in selectPersonnelInfo.persons) {
    person = selectPersonnelInfo.persons[key as PersonnelType]!;
    for (let count = 0; count < person.count!; count++) {
      dateOverrides = person.dateOverrides?.at(count);
      for (const day of daysList) {
        dateOverride = dateOverrides?.[day];
        const DaySchedule = calendarInfo.eventSchedule?.find((elem) => {
          return elem.day === day;
        })?.schedule;

        startHour =
          dateOverride?.startHour ??
          DaySchedule?.specialSchedules.find(
            (elem) => elem.PersonnelType === (key as PersonnelType)
          )?.schedule.startHour ??
          DaySchedule?.defaultStartHour;

        endHour =
          dateOverride?.endHour ??
          DaySchedule?.specialSchedules.find(
            (elem) => elem.PersonnelType === (key as PersonnelType)
          )?.schedule.endHour ??
          DaySchedule?.defaultEndHour;

        if (endHour && startHour) {
          if (getHourValue(endHour) - getHourValue(startHour) <= 6)
            organisationType = ORGANISATION_TYPE.NONE;
          if (getHourValue(endHour) - getHourValue(startHour) > 6)
            organisationType = ORGANISATION_TYPE.BREAKTIME;
          if (getHourValue(endHour) - getHourValue(startHour) > 12)
            organisationType = ORGANISATION_TYPE.ROTATION;
        }
        if (dateOverride?.organisationType)
          organisationType = dateOverride.organisationType;

        swapHour =
          organisationType === ORGANISATION_TYPE.ROTATION
            ? dateOverride?.swapHour ??
              DaySchedule?.specialSchedules.find(
                (elem) => elem.PersonnelType === (key as PersonnelType)
              )?.schedule.swapHour ??
              computeSwapHour(endHour, startHour)
            : undefined;

        breaktime =
          organisationType === ORGANISATION_TYPE.BREAKTIME
            ? dateOverride?.breakTime
              ? dateOverride?.breakTime
              : endHour &&
                startHour &&
                getHourValue(endHour) - getHourValue(startHour) > 6.5 &&
                getHourValue(endHour) - getHourValue(startHour) < 12
              ? "01:00"
              : endHour &&
                startHour &&
                getHourValue(endHour) - getHourValue(startHour) === 6.5
              ? "00:30"
              : "00:00"
            : "00:00";

        workObject = {
          type: (key as PersonnelType)!,
          day,
          isWorking: dateOverride?.isWorking ?? true,
          organisationType: organisationType!,
          startHour: startHour!,
          swapHour: swapHour,
          breaktime: breaktime,
          endHour: endHour!,
        };

        if (workObject.isWorking) {
          const workedHours = calcDiffHours(
            workObject.swapHour,
            workObject.startHour
          );
          if (workObject.organisationType === ORGANISATION_TYPE.ROTATION) {
            result.push({
              type: workObject.type,
              day: workObject.day,
              isWorking: true,
              organisationType: organisationType,
              startHour: workObject.startHour,
              endHour: workObject.swapHour!,
              workedHours: workedHours,
              breaktime:
                workedHours > 6.5 && workedHours < 12
                  ? "01:00"
                  : workedHours === 6.5
                  ? "00:30"
                  : workObject.breaktime,
            });

            result.push({
              type: workObject.type,
              day: workObject.day,
              isWorking: true,
              organisationType: organisationType,
              startHour: workObject.swapHour!,
              endHour: workObject.endHour,
              workedHours: calcDiffHours(
                workObject.endHour,
                workObject.swapHour
              ),
              breaktime:
                workedHours > 6.5 && workedHours < 12
                  ? "01:00"
                  : workedHours === 6.5
                  ? "00:30"
                  : workObject.breaktime,
            });
          } else {
            result.push({
              type: workObject.type,
              day: workObject.day,
              isWorking: true,
              organisationType: organisationType,
              startHour: workObject.startHour,
              endHour: workObject.endHour,
              workedHours: calcDiffHours(
                workObject.endHour,
                workObject.startHour
              ),
              breaktime: workObject.breaktime,
            });
          }
        }
      }
    }
  }
  return result;
}

export function parsePersonnel(
  selectPersonnelInfo: PersonnelSelectionInfo,
  calendarInfo: EventDateInfo
): PersonnelInfo[] {
  const data = createDetailedPersonnelArray(selectPersonnelInfo, calendarInfo);
  const personnelInfos: PersonnelInfo[] = [];
  for (const person in data) {
    const startstr = combineDateWithTime(
      moment(data[person].day),
      data[person].startHour
    )
      .split(" ")
      .join("T");
    const addDay =
      isAfterMidnight(data[person].endHour) &&
      !isAfterMidnight(data[person].startHour)
        ? 1
        : 0;
    const endstr = combineDateWithTime(
      moment(data[person].day).add(addDay, "day"),
      data[person].endHour
    )
      .split(" ")
      .join("T");

    personnelInfos.push({
      count: 1,
      startDatetime: startstr,
      endDatetime: endstr,
      type: STAFF_ID_TO_TYPE[data[person].type as PersonnelType],
      breaktime: data[person].breaktime,
    });
  }

  const result = personnelInfos.reduce((acc, current) => {
    const i = acc.findIndex(
      (pi) =>
        pi.type === current.type &&
        pi.endDatetime === current.endDatetime &&
        pi.startDatetime === current.startDatetime &&
        pi.breaktime === current.breaktime
    );
    if (i === -1) return [...acc, current];
    acc[i].count += current.count;
    return acc;
  }, [] as PersonnelInfo[]);
  return result;
}

export function getContactInfo(customerInfo: CustomerType): ContactInput {
  return {
    pronoun: customerInfo.pronoun,
    company: customerInfo.companyName,
    firstname: customerInfo.firstname,
    lastname: customerInfo.lastname,
    email: customerInfo.email,
    siren: customerInfo.siren,
    phone: customerInfo.phone,
    address: customerInfo.address,
    availablePeriodOfDay: null,
    availableDays: null,
    requestText: null,
  };
}
