import { Box, Flex, Heading, Select, Text } from "@chakra-ui/react";
import moment from "moment";
import { useMemo, useState } from "react";
import "react-dates/lib/css/_datepicker.css";
import NorelaButton from "../../components/Buttons/NorelaButton";
import CustomTooltip from "../../components/CustomTooltip";
import SmallOutlineContainer from "../../components/Layout/SmallOutlineContainer";
import CalendarPopup from "../../components/Popus/CalendarPopup";
import {
  EventDateInfo,
  ORGANISATION_TYPE,
  useUserContext,
} from "../../context/UserContext";
import { colors } from "../../ui/theme";
import {
  calcDiffHours,
  computeSwapHour,
  determineOrganisationType,
  getDayListFromPerdiod,
  getDayScheduleFromEventDateInfo,
} from "../../utils/utils";
import {
  createEventScheduleObject,
  updateEventScheduleObject,
  updateSpecialSchedulesObject,
} from "./EventDateInfo";
import { getMonthDayDateFormat } from "./SchedulePersonnalisation";
import { FaArrowDown } from "react-icons/fa";

export const TIME_ARRAY = [
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
];

export const COMPLETE_BREAKTIME_ARRAY = ["30mins", "1h", "2h", "3h"];

export const modulo = (nb: number, modulo: number) => {
  while (nb < 0) nb += modulo;
  return nb % modulo;
};

export const getHourValue = (time: string) => {
  const nextDay = isAfterMidnight(time) ? 24 : 0;
  return parseFloat(time.replace(":30", ".5")) + nextDay;
};
export const isAfterMidnight = (time: string): boolean => {
  if (TIME_ARRAY.indexOf(time) >= TIME_ARRAY.indexOf("00:00")) return true;
  return false;
};

export const isBeforeMidnight = (time: string): boolean => {
  return isBeforeMidnight(time);
};

export const getTimeArrBeforeTime = (time: string) => {
  const timer = getHourValue(time);
  const minus3hours = timer - 3;
  const timeArrBeforeTime = TIME_ARRAY.filter(
    (hour) => getHourValue(hour) <= minus3hours
  );
  return timeArrBeforeTime;
};

//TEMPORARY: BLOCK ROTATION WITH BREAKTIME
export const TMPGetTimeArrayBeforeTimeTMP = (
  time: string,
  isFullArray = false
) => {
  const timer = getHourValue(time);
  const minus3hours = timer - 3;
  const minus12hours = timer - (!isFullArray ? 12 : 24);
  const timeArrBeforeTime = TIME_ARRAY.filter(
    (hour) =>
      getHourValue(hour) <= minus3hours && getHourValue(hour) >= minus12hours
  );
  return timeArrBeforeTime;
};
export const TMPGetTimeArrayAfterTimeTMP = (
  time: string,
  isFullArray = false
) => {
  const timer = getHourValue(time);
  const plus3hours = timer + 3;
  const plus12hours = timer + (!isFullArray ? 12 : 24);
  const timeArrAfterTime = TIME_ARRAY.filter(
    (hour) =>
      getHourValue(hour) >= plus3hours && getHourValue(hour) <= plus12hours
  );
  return timeArrAfterTime;
};
//TEMPORARY: BLOCK ROTATION WITH BREAKTIME

export const getTimeArrBetweenTime = (start: string, end: string) => {
  const starter = getHourValue(start);
  const ender = getHourValue(end);

  const startChecker = starter + 3;
  const endChecker = ender - 3;
  const timeArrBetweenTime = TIME_ARRAY.filter(
    (hour) =>
      getHourValue(hour) <= endChecker && getHourValue(hour) >= startChecker
  );
  return timeArrBetweenTime;
};

export const getTimeArrAfterTime = (time: string) => {
  const timer = getHourValue(time);
  const plus3hours = timer + 3;
  const timeArrAfterTime = TIME_ARRAY.filter(
    (hour) => getHourValue(hour) >= plus3hours
  );
  return timeArrAfterTime;
};

function ChooseEventDate({
  eventDateInfo,
  onChangeDates,
  onChangePeriod,
}: {
  eventDateInfo: EventDateInfo;
  onChangeDates: (date: string[] | null) => void;
  onChangePeriod: (
    period: { startDate: string; endDate: string } | null
  ) => void;
}) {
  const [openPopup, setOpenPopup] = useState(false);

  let content = (
    <NorelaButton onClick={() => setOpenPopup(true)}>
      Choisir la date
    </NorelaButton>
  );

  if (eventDateInfo.period) {
    content = (
      <Box textAlign="left">
        <Text>
          <b>DEBUT</b>
          <br />
          {moment(eventDateInfo.period.startDate)
            .toDate()
            .toLocaleDateString("fr-FR", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
        </Text>
        <Text mt="0.25rem">
          <b>FIN</b>
          <br />
          {moment(eventDateInfo.period.endDate)
            .toDate()
            .toLocaleDateString("fr-FR", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
        </Text>
        <NorelaButton onClick={() => setOpenPopup(true)} mt="1rem">
          Changer les dates
        </NorelaButton>
      </Box>
    );
  } else if (eventDateInfo.days) {
    content = (
      <Box>
        <Box maxH="100px" overflowY="auto">
          {eventDateInfo.days
            .sort((a: string, b: string) => moment(a).unix() - moment(b).unix())
            .map((day: string) => (
              <Text key={day.toString()}>
                {moment(day).toDate().toLocaleDateString("fr-FR", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })}
              </Text>
            ))}
        </Box>
        <NorelaButton onClick={() => setOpenPopup(true)} mt="1rem">
          Changer les dates
        </NorelaButton>
      </Box>
    );
  }
  return (
    <SmallOutlineContainer p="1rem">
      {openPopup && (
        <CalendarPopup
          isOpen
          selectedDatePeriod={eventDateInfo.period}
          selectedDates={eventDateInfo.days}
          setOpenPopup={setOpenPopup}
          changeDatePeriod={(period) => onChangePeriod(period)}
          changeDates={(dates) => onChangeDates(dates)}
        />
      )}
      <Heading size="md" mb="1rem" textAlign="left">
        Date de l'événement
      </Heading>
      {content}
    </SmallOutlineContainer>
  );
}

function ChooseEventHours({
  eventDateInfo,
  onChangeHours,
}: // onChangeRotation,
{
  eventDateInfo: EventDateInfo;
  onChangeHours: (
    day: string,
    defaultStartHour?: string,
    defaultEndHour?: string
  ) => void;
  // onChangeRotation: (day: string, value: ORGANISATION_TYPE) => void;
}) {
  const days = useMemo<{ format: string; date: string }[]>(() => {
    if (eventDateInfo.period) {
      const days = [];
      const dayDiff = moment(eventDateInfo.period.endDate).diff(
        eventDateInfo.period.startDate,
        "days"
      );
      const itMoment = moment(eventDateInfo.period.startDate).clone();
      for (let i = 0; i <= dayDiff; i++, itMoment.add(1, "days")) {
        days.push({
          format: getMonthDayDateFormat(itMoment.toISOString()),
          date: itMoment.toISOString(),
        });
      }
      return days;
    } else if (eventDateInfo.days) {
      return eventDateInfo.days.map((day: string) => {
        return {
          format: getMonthDayDateFormat(day),
          date: day,
        };
      });
    }
    return [];
  }, [eventDateInfo]);

  const handleReplicate = (
    start: string | undefined,
    end: string | undefined
  ) => {
    days.forEach((day) => {
      onChangeHours(day.date, start, end);
    });
  };

  if (!eventDateInfo) return <></>;

  return (
    <SmallOutlineContainer p="1rem" maxH="230px">
      <Flex justifyContent="space-between">
        <Heading size="md" textAlign="left" h="15%">
          Horaires de l'événement
        </Heading>
        <CustomTooltip
          content={[
            "Vous pouvez modifier la date, l'heure, la rotation et les heures de pause de votre personnel à l'étape \"3. Modifier les horaires de votre personnel.\"",
          ]}
        >
          <Heading
            size="md"
            display="inline"
            color={colors.white}
            bg={colors.additional.lightMode}
            borderRadius="50%"
            w="1.5rem"
            h="1.5rem"
          >
            ?
          </Heading>
        </CustomTooltip>
      </Flex>
      <Box maxHeight="85%" overflowY="auto">
        {days.map((day, index) => {
          const daySchedule = getDayScheduleFromEventDateInfo(
            day.date,
            eventDateInfo
          );
          if (!daySchedule) return null;
          const startArray = daySchedule.defaultEndHour
            ? TMPGetTimeArrayBeforeTimeTMP(daySchedule.defaultEndHour, true)
            : TIME_ARRAY;
          const endArray = daySchedule.defaultStartHour
            ? TMPGetTimeArrayAfterTimeTMP(daySchedule.defaultStartHour, true)
            : TIME_ARRAY;
          return (
            <Flex direction="column" key={day.format} m="0.5rem 0">
              <Flex direction="row">
                <Text display="flex" alignItems="center" w="95px">
                  {moment(day.date).toDate().toLocaleDateString("fr-FR", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </Text>
                <Flex
                  border="solid 1px"
                  borderRadius="8px"
                  justifyContent="space-evenly"
                  mx="1rem"
                  py="0.25rem"
                  sx={{
                    ".chakra-select__wrapper": {},
                    ".chakra-select": {
                      padding: "4px 8px",
                      minWidth: "85px",
                      textAlign: "center",
                    },
                    ".chakra-select__icon-wrapper": {
                      display: "none",
                    },
                  }}
                >
                  <Flex direction="row">
                    <Select
                      variant="unstyled"
                      value={daySchedule.defaultStartHour ?? ""}
                      placeholder="Début"
                      onChange={(e) =>
                        onChangeHours(
                          day.date,
                          e.target.value === "" ? undefined : e.target.value,
                          undefined
                        )
                      }
                      w="auto"
                      iconSize="0"
                    >
                      {startArray?.map((time) => (
                        <option key={time}>{time}</option>
                      ))}
                    </Select>
                    <Text color={colors.black}>-</Text>
                    <Select
                      variant="unstyled"
                      value={daySchedule.defaultEndHour}
                      placeholder="Fin"
                      onChange={(e) =>
                        onChangeHours(
                          day.date,
                          undefined,
                          e.target.value === "" ? undefined : e.target.value
                        )
                      }
                      w="auto"
                      iconSize="0"
                    >
                      {endArray?.map((time) => (
                        <option key={time}>{time}</option>
                      ))}
                    </Select>
                  </Flex>
                </Flex>
              </Flex>
              {days.length > 1 && index === 0 && (
                <NorelaButton
                  mt="1rem"
                  w="70%"
                  display="flex"
                  justifyContent="space-around"
                  isDisabled={
                    daySchedule.defaultStartHour && daySchedule.defaultEndHour
                      ? false
                      : true
                  }
                  onClick={() => {
                    handleReplicate(
                      daySchedule.defaultStartHour,
                      daySchedule.defaultEndHour
                    );
                  }}
                >
                  <FaArrowDown />
                  Répliquer l’horaire
                </NorelaButton>
              )}
            </Flex>
          );
        })}
      </Box>
    </SmallOutlineContainer>
  );
}

export default function ChooseEventSchedule() {
  const { userInfos, setCalendarInfo } = useUserContext();

  const changePeriod = (
    period: { startDate: string; endDate: string } | null
  ) => {
    if (!period) {
      setCalendarInfo({
        period: null,
        days: null,
        eventSchedule: [],
      });
      return;
    }
    let daysList = getDayListFromPerdiod(period);
    const eventSchedule = updateEventScheduleObject(
      userInfos.calendarInfo?.eventSchedule,
      createEventScheduleObject(daysList, undefined, undefined)
    );
    setCalendarInfo({
      period,
      days: null,
      eventSchedule,
    });
  };
  const changeDays = (days: string[] | null) => {
    if (!days) {
      setCalendarInfo({
        period: null,
        days: null,
        eventSchedule: [],
      });
      return;
    }

    const eventSchedule = updateEventScheduleObject(
      userInfos.calendarInfo?.eventSchedule,
      createEventScheduleObject(days)
    );

    setCalendarInfo({
      period: null,
      days,
      eventSchedule,
    });
  };

  const changeHours = (day: string, startHour?: string, endHour?: string) => {
    let eventSchedule = userInfos.calendarInfo?.eventSchedule?.map((elem) => {
      if (elem.day === day) {
        let start = startHour ?? elem.schedule.defaultStartHour;
        let end = endHour ?? elem.schedule.defaultEndHour;
        if (startHour && end && calcDiffHours(end, startHour) < 3)
          end = undefined;
        if (endHour && start && calcDiffHours(endHour, start) < 3)
          start = undefined;
        const specialSchedules = updateSpecialSchedulesObject(
          elem.schedule.specialSchedules,
          start
        );
        const organisationType = determineOrganisationType(
          start,
          end,
          elem.schedule.organisationType
        );
        const defaultSwaptHour =
          organisationType === ORGANISATION_TYPE.ROTATION
            ? computeSwapHour(end, start)
            : undefined;
        const defaultBreakTime =
          organisationType === ORGANISATION_TYPE.BREAKTIME
            ? calcDiffHours(end, start) > 6.5 && calcDiffHours(end, start) < 12
              ? "01:00"
              : calcDiffHours(end, start) === 6.5
              ? "00:30"
              : "02:00"
            : undefined;
        elem.schedule = {
          ...elem.schedule,
          defaultStartHour: start,
          defaultEndHour: end,
          specialSchedules: specialSchedules,
          organisationType: organisationType,
          defaultSwapHour: defaultSwaptHour,
          defaultBreakTime: defaultBreakTime,
        };
      }
      return elem;
    });
    setCalendarInfo({
      period: userInfos.calendarInfo.period,
      days: userInfos.calendarInfo.days,
      eventSchedule: eventSchedule ?? undefined,
    });
  };

  // const changeRotation = (day: string, value: ORGANISATION_TYPE) => {
  //   let eventSchedule = userInfos.calendarInfo?.eventSchedule?.map((elem) => {
  //     if (elem.day === day) {
  //       const organisationType = determineOrganisationType(
  //         elem.schedule.defaultStartHour,
  //         elem.schedule.defaultEndHour,
  //         value
  //       );
  //       elem.schedule = {
  //         ...elem.schedule,
  //         organisationType,
  //       };
  //     }
  //     return elem;
  //   });
  //   setCalendarInfo({
  //     period: userInfos.calendarInfo.period,
  //     days: userInfos.calendarInfo.days,
  //     eventSchedule: eventSchedule ?? undefined,
  //   });
  // };

  return (
    <Flex gap="1rem">
      <ChooseEventDate
        eventDateInfo={userInfos.calendarInfo}
        onChangeDates={changeDays}
        onChangePeriod={changePeriod}
      />
      {(userInfos?.calendarInfo?.days ?? userInfos?.calendarInfo?.period) && (
        <ChooseEventHours
          eventDateInfo={userInfos.calendarInfo}
          onChangeHours={changeHours}
          // onChangeRotation={changeRotation}
        />
      )}
      {/* {hideBreak !== true && needBreak && (
        <ChooseEventBreak
          eventDateInfo={eventDateInfo}
          onChangeBreak={onChangeBreak}
        />
      )} */}
    </Flex>
  );
}
