import { Flex, Text } from "@chakra-ui/react";
import { FC, ReactElement } from "react";
import { colors } from "../../ui/theme";
import { DressingPantsIcon } from "../Icons/DressingPantsIcon";
import { DressingShoesIcon } from "../Icons/DressingShoesIcon";
import { DressingTopIcon } from "../Icons/DressingTopIcon";
import { DressCode } from "./DressingBlock";
import TopOutfitSelectionCard from "./TopOutfitSelectionCard";
import ShoesOutfitSelectionCard from "./ShoesOutfitSelectionCard";
import BottomOutfitSelectionCard from "./BottomOutfitSelectionCard";

export interface ProvidedOutfitMenuProps {
  dressCode?: DressCode;
  setDressCode: (element: DressCode) => void;
}

const ProvidedCardItem: FC<ProvidedOutfitMenuProps> = ({
  dressCode,
  setDressCode,
}): ReactElement => {
  const selectProvidedTop = (value: boolean) => {
    if (value) {
      setDressCode({
        ...dressCode,
        providedOutfit: {
          ...dressCode?.providedOutfit,
          providedTop: false,
        },
      });
    } else {
      setDressCode({
        ...dressCode,
        providedOutfit: {
          ...dressCode?.providedOutfit,
          blazer: false,
          top: undefined,
          topColor: undefined,
          providedTop: true,
        },
      });
    }
  };

  const selectProvidedBottom = (value: boolean) => {
    if (value) {
      setDressCode({
        ...dressCode,
        providedOutfit: {
          ...dressCode?.providedOutfit,
          providedBottom: false,
        },
      });
    } else {
      setDressCode({
        ...dressCode,
        providedOutfit: {
          ...dressCode?.providedOutfit,
          blazer: false,
          bottom: undefined,
          bottomColor: undefined,
          providedBottom: true,
        },
      });
    }
  };

  const selectProvidedShoes = (value: boolean) => {
    if (value) {
      setDressCode({
        ...dressCode,
        providedOutfit: {
          ...dressCode?.providedOutfit,
          providedShoes: false,
        },
      });
    } else {
      setDressCode({
        ...dressCode,
        providedOutfit: {
          ...dressCode?.providedOutfit,
          blazer: false,
          shoes: undefined,
          shoesColor: undefined,
          providedShoes: true,
        },
      });
    }
  };

  const selectTop = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      providedOutfit: {
        ...dressCode?.providedOutfit,
        top: value,
        topColor: "#fff",
      },
    });
  };

  const selectBlazer = (value: boolean) => {
    setDressCode({
      ...dressCode,
      providedOutfit: {
        ...dressCode?.providedOutfit,
        blazer: value,
      },
    });
  };

  const selectBottom = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      providedOutfit: {
        ...dressCode?.providedOutfit,
        bottom: value,
        bottomColor: "#000",
      },
    });
  };

  const selectBottomColor = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      providedOutfit: {
        ...dressCode?.providedOutfit,
        bottomColor: value,
      },
    });
  };

  const selectShoes = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      providedOutfit: {
        ...dressCode?.providedOutfit,
        shoes: value,
        shoesColor: "#000",
      },
    });
  };

  const selectShoesColor = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      providedOutfit: {
        ...dressCode?.providedOutfit,
        shoesColor: value,
      },
    });
  };

  return (
    <Flex flexDir="column" gap="1rem" w="auto" minW="400px">
      <Text variant="2xl" fontWeight="700" textAlign="left">
        Ce que vous fournissez :
      </Text>
      <Flex gap="1rem">
        <Flex
          direction="column"
          justifyContent="center"
          border="1px solid"
          borderColor={
            dressCode?.providedOutfit?.providedTop === true
              ? colors.secondaryFontColor.lightMode
              : colors.gray.lightMode
          }
          backgroundColor="transparent"
          cursor="pointer"
          borderRadius={{ md: "16px", "2xl": "3xl" }}
          w="33%"
          alignItems="center"
          p="0.5rem"
          onClick={() => {
            selectProvidedTop(dressCode?.providedOutfit?.providedTop === true);
          }}
        >
          <DressingTopIcon />
          <Text variant="xl" fontWeight="500">
            Haut
          </Text>
        </Flex>
        <Flex
          direction="column"
          justifyContent="center"
          border="1px solid"
          borderColor={
            dressCode?.providedOutfit?.providedBottom === true
              ? colors.secondaryFontColor.lightMode
              : colors.gray.lightMode
          }
          backgroundColor="transparent"
          cursor="pointer"
          borderRadius={{ md: "16px", "2xl": "3xl" }}
          w="33%"
          alignItems="center"
          p="0.5rem"
          onClick={() => {
            selectProvidedBottom(
              dressCode?.providedOutfit?.providedBottom === true
            );
          }}
        >
          <DressingPantsIcon />
          <Text variant="xl" fontWeight="500">
            Bas
          </Text>
        </Flex>
        <Flex
          direction="column"
          justifyContent="center"
          border="1px solid"
          borderColor={
            dressCode?.providedOutfit?.providedShoes === true
              ? colors.secondaryFontColor.lightMode
              : colors.gray.lightMode
          }
          backgroundColor="transparent"
          cursor="pointer"
          borderRadius={{ md: "16px", "2xl": "3xl" }}
          w="33%"
          alignItems="center"
          p="0.5rem"
          onClick={() => {
            selectProvidedShoes(
              dressCode?.providedOutfit?.providedShoes === true
            );
          }}
        >
          <DressingShoesIcon />
          <Text variant="xl" fontWeight="500">
            Chaussures
          </Text>
        </Flex>
      </Flex>
      {!dressCode?.providedOutfit?.providedTop ||
      !dressCode?.providedOutfit?.providedBottom ||
      !dressCode?.providedOutfit?.providedShoes ? (
        <Flex marginTop="1rem" width="100%" gap="24px" flexDir="column">
          <Text variant="2xl" fontWeight="700" textAlign="left">
            Choisissez le reste de leur tenue
          </Text>
          {!dressCode?.providedOutfit?.providedTop && (
            <TopOutfitSelectionCard
              selectedOutfit={dressCode?.providedOutfit}
              onSelectTop={selectTop}
              onSelectBlazer={selectBlazer}
              width="80%"
            />
          )}
          {!dressCode?.providedOutfit?.providedBottom && (
            <BottomOutfitSelectionCard
              selectedOutfit={dressCode?.providedOutfit}
              onSelectBottom={selectBottom}
              onSelectBottomColor={selectBottomColor}
              width="80%"
            />
          )}
          {!dressCode?.providedOutfit?.providedShoes && (
            <ShoesOutfitSelectionCard
              selectedOutfit={dressCode?.providedOutfit}
              onSelectShoes={selectShoes}
              onSelectShoesColor={selectShoesColor}
            />
          )}
        </Flex>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default ProvidedCardItem;
