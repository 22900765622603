import { Omit } from "@stripe/stripe-js/types/utils";
import { Icons } from "../../components/PicturesVariant/PicturesVariant";
import OnboardingListButtonLayout, {
  OnboardingButton,
} from "./OnboardingListButtonLayout";
import { useOnboardingContext } from "../../context/OnboardingContext";
import { EventSubCategory } from "../../modules/Onboarding/EventTypes";
import { useNavigate } from "react-router-dom";
import { PageURL } from "../../types/pages";
import { useSessionContext } from "../../context/SessionContext";

const PRO_ELEMENTS: Omit<OnboardingButton<EventSubCategory>, "onChoose">[] = [
  {
    title: "Inauguration",
    value: EventSubCategory.INAUGURATION,
    icon: Icons.INAGURATION_VARIANT,
  },
  {
    title: "Salon",
    value: EventSubCategory.SALON_PRO,
    icon: Icons.SALON_NEW_ICON,
  },
  {
    title: "Soirée presse",
    value: EventSubCategory.SOIREE_PRESSE,
    icon: Icons.SOIREEPRESSE,
  },
  {
    title: "Congrès / Convention / Séminaire",
    value: EventSubCategory.CONGRES,
    icon: Icons.CONVENTIONO_ICON,
  },
  {
    title: "Remise de prix",
    value: EventSubCategory.REMISE_DE_PRIX,
    icon: Icons.REMISE_ICON,
  },
  {
    title: "Avant première /projection privée",
    value: EventSubCategory.CINEMA,
    icon: Icons.CINEMA_ICON,
  },
  {
    title: "Autre ?",
    value: EventSubCategory.AUTRE_PRO,
    icon: Icons.GOODIES_ICON,
    isInputText: true,
  },
];

export default function OnboardingProfessionnel() {
  const { setEventSubcategory } = useOnboardingContext();
  const navigate = useNavigate();
  const { updateHistory } = useSessionContext();

  return (
    <OnboardingListButtonLayout
      title="QUEL TYPE D’ÉVÉNEMENT PROFESSIONNEL ?"
      subtitle="Vous souhaitez cibler des professionnels pour quel type d’événement ?"
      elements={PRO_ELEMENTS.map(
        (e) =>
          ({
            ...e,
            onChoose: (value) => {
              setEventSubcategory(value);
              updateHistory(PageURL.ONBOARDING_RECEPTION);
              navigate(PageURL.ONBOARDING_RECEPTION);
            },
          } as OnboardingButton<EventSubCategory>)
      )}
    />
  );
}
