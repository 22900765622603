export const GoodiesIcon = () => (
  <svg width="78" height="101" viewBox="0 0 78 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1001_48712)">
      <path d="M28.0243 13.0336C27.8883 12.5575 29.79 16.9211 29.3376 16.9736C27.2771 17.21 22.7113 18.2757 21.2761 18.8498C21.2115 18.8423 21.1468 18.8348 21.0841 18.8254C20.9301 18.8085 20.78 18.7841 20.6317 18.756C17.29 18.1237 14.7618 15.3788 14.6934 12.0655C14.6896 12.0186 14.6896 11.9698 14.6896 11.9229C14.6892 11.6942 14.7012 11.4656 14.7257 11.2381C14.7572 10.9276 14.8112 10.6197 14.8873 10.3169L14.8797 10.3151C13.5491 9.06554 14.1098 6.74846 15.8054 5.77098C16.5422 5.34735 17.3592 5.08216 18.2043 4.99236C18.4335 4.9675 18.6638 4.95497 18.8943 4.95484H21.0594C21.5074 4.8583 21.9615 4.79248 22.4185 4.75784H22.4223C26.781 4.42576 30.2178 7.08242 29.8965 10.3151C29.8946 10.3188 29.8908 10.3226 29.8889 10.3263C29.7958 10.4652 29.714 10.5965 29.6418 10.7222C29.5715 10.8404 29.5125 10.953 29.4612 11.0599C29.0639 11.8854 30.0852 12.9242 29.408 13.1181C28.5074 13.3759 31.7766 21.8517 28.2119 21.8517C29.9459 22.3446 28.3995 14.3469 28.0243 13.0336Z" fill="#1A202C"/>
      <path d="M30.5419 91.7301L33.1445 92.7933L38.5751 83.0382L34.7346 81.469L30.5419 91.7301Z" fill="#FFB6B6"/>
      <path d="M37.1219 99.3296C36.9956 99.6379 36.7521 99.8836 36.4449 100.013C36.1377 100.142 35.7918 100.144 35.4831 100.018L30.3093 97.9037L30.1722 95.6965L29.0712 97.3966L27.1185 96.6002L30.3541 90.2471L31.0498 90.4853L33.8901 91.4502L34.8058 91.7633L34.0449 93.6255L37.0111 98.1677C37.1222 98.338 37.1903 98.5326 37.2096 98.735C37.2289 98.9374 37.1988 99.1414 37.1219 99.3296Z" fill="#1A202C"/>
      <path d="M32.9743 89.2853C31.995 88.7291 29.4846 87.4183 29.4846 87.4183C29.4846 87.4183 29.3291 84.3391 29.862 83.6245C30.3929 82.905 30.949 82.9466 30.6708 82.3379C31.2743 77.7948 34.2247 71.2399 34.2247 71.2399C34.2247 71.2399 29.4856 65.8746 24.8619 60.0248C20.3428 54.3041 15.9338 48.1211 16.174 46.0353C16.3756 44.5559 16.7058 43.0969 17.1607 41.6748C17.4473 40.7372 17.7613 39.8313 18.0576 39.0258C18.7338 37.1814 19.3266 35.8573 19.3266 35.8573L26.8781 38.8708L31.8931 40.8351L32.4124 41.0523L32.0503 41.9177L32.4268 44.6826C32.4268 44.6826 33.5847 48.2484 33.2178 48.3816C32.7738 48.5436 32.7606 48.8472 33.8079 49.5377C34.1075 49.7296 34.4314 49.8809 34.7709 49.9874C36.6659 50.6156 36.9947 51.9877 36.9434 52.2367C36.5714 53.9908 38.1834 58.8143 39.8164 63.0385L39.8181 63.0392C41.3241 66.9397 42.8476 70.3269 42.8476 70.3269L41.7403 73.3719L36.5657 87.6093C36.5657 87.6093 35.6087 86.3872 36.0942 88.3618C36.579 90.3381 33.9545 89.8398 32.9743 89.2853Z" fill="#1A202C"/>
      <path d="M5.68091 90.3132L7.62286 92.3463L16.5625 85.6576L13.697 82.6572L5.68091 90.3132Z" fill="#FFB6B6"/>
      <path d="M8.58715 99.9369C8.34594 100.167 8.02337 100.292 7.69021 100.284C7.35705 100.277 7.0405 100.137 6.80999 99.8965L2.94996 95.8542L3.72589 93.7833L2.02678 94.886L0.569189 93.3618L6.11641 88.883L6.65425 89.3844L8.85327 91.4247L9.56136 92.0842L8.10658 93.4736L8.9602 98.831C8.99212 99.0318 8.97485 99.2373 8.90987 99.4299C8.84489 99.6225 8.73415 99.7965 8.58715 99.9369Z" fill="#1A202C"/>
      <path d="M13.2938 88.1039C13.2827 88.0923 13.2733 88.08 13.2647 88.0694C12.5738 87.2221 10.8422 85.5875 10.7356 85.4861C10.7339 85.4839 10.7317 85.4821 10.7292 85.4807C10.7292 85.4807 11.9354 82.9141 12.6898 82.4405C13.4485 81.9671 12.2676 82.67 13.9129 81.5358C14.7665 80.9504 16.7086 78.5445 18.3835 76.3631C19.9418 74.3336 21.2649 72.4985 21.2649 72.4985C21.2649 72.4985 20.3621 70.0299 19.1639 66.498L19.1631 66.4963C18.0548 63.2177 16.692 59.0195 15.5655 55.0304C16.3062 47.7779 17.4478 40.2701 19.0728 38.9406C20.2477 38.0192 21.5007 37.2021 22.8175 36.4984L26.7098 40.1412L29.6812 46.7837L29.6704 52.4937L29.6668 54.7276L29.6595 57.8548L29.6271 74.7889L17.4856 88.5966C17.4856 88.5966 17.0381 87.1066 16.7707 89.1247C16.5059 91.1153 14.0398 88.999 13.2938 88.1039Z" fill="#1A202C"/>
      <path d="M22.2088 19.0376H27.8373L28.7235 20.9937L32.4929 22.6732C32.9831 22.892 33.4151 23.2229 33.7539 23.6392C34.0927 24.0556 34.329 24.5458 34.4436 25.0702C35.8008 31.275 34.084 32.5641 34.0287 34.6098C33.8411 41.5517 32.8743 42.9325 32.9825 44.6462C33.0148 45.159 33.3279 47.1869 34.3349 46.9959C35.342 46.805 34.7991 47.6066 35.0375 47.9183C35.2434 48.1876 36.3264 47.8302 35.6466 48.7247C34.9668 49.6192 37.8732 52.0582 36.0925 52.0582C32.7154 52.0582 25.9611 53.7468 23.3345 53.7468C20.7079 53.7468 18.678 54.5256 17.5184 54.4973C16.8115 54.48 17.0325 51.1842 17.315 48.33C17.379 47.6837 18.62 47.7005 17.5063 46.5023C16.3926 45.304 17.7036 44.6813 17.706 44.3659C17.7085 44.0309 18.3373 43.1869 17.5526 41.8065C16.7679 40.426 17.4913 40.9972 17.4572 40.5637C17.1945 37.2232 16.8181 32.9639 16.8181 32.9639L15.1589 28.2941C14.7348 27.1005 14.7909 25.7888 15.3153 24.6357C15.8397 23.4827 16.7915 22.5783 17.9699 22.1135L19.9161 21.3458L22.2088 19.0376Z" fill="#F7B80B"/>
      <path d="M61.7394 17.315C61.6143 17.5483 61.44 17.7517 61.2286 17.9111C61.0172 18.0705 60.7736 18.182 60.5149 18.238C60.2561 18.294 59.9882 18.2931 59.7298 18.2354C59.4715 18.1777 59.2287 18.0646 59.0183 17.9038L51.0894 22.3927L49.3086 20.5049L58.7648 15.2422C59.0618 14.9082 59.4709 14.6948 59.9147 14.6423C60.3585 14.5898 60.8061 14.7019 61.1728 14.9574C61.5394 15.2129 61.7996 15.594 61.904 16.0285C62.0085 16.4631 61.9499 16.9208 61.7394 17.315Z" fill="#FFB6B6"/>
      <path d="M26.0387 27.9109L25.918 29.3564L41.703 29.1535L57.4152 20.3204L55.1182 16.7849L41.308 22.5914L31.4926 22.7905C30.1113 22.8185 28.7894 23.3574 27.7821 24.303C26.7748 25.2487 26.1537 26.5341 26.0387 27.9109Z" fill="#F7B80B"/>
      <path d="M24.1339 18.0069C26.965 18.0069 29.26 15.7118 29.26 12.8807C29.26 10.0497 26.965 7.75464 24.1339 7.75464C21.3029 7.75464 19.0078 10.0497 19.0078 12.8807C19.0078 15.7118 21.3029 18.0069 24.1339 18.0069Z" fill="#FFB6B6"/>
      <path d="M29.8965 10.5948C29.8109 10.6398 29.7273 10.6811 29.6418 10.7224C29.5524 10.7655 29.4631 10.8087 29.3737 10.8481C27.6573 11.6267 26.2601 11.713 25.4123 10.5948C25.3896 10.0064 25.2575 9.42745 25.0226 8.88749C25.0641 9.4909 24.9051 10.0911 24.5702 10.5948H22.8633C21.105 11.7618 20.2021 11.8837 21.4224 15.7149C21.7627 16.7824 23.1156 22.9944 22.5834 23.9156C22.4294 23.8987 20.7799 18.7843 20.6317 18.7562C17.2899 18.1239 14.7618 15.379 14.6934 12.0657C14.6972 11.8969 14.7086 11.728 14.7257 11.5629C14.9458 11.232 15.1878 10.9161 15.4499 10.6173C15.4486 9.73165 15.6522 8.8577 16.0449 8.06385C16.5416 7.05808 17.316 6.21561 18.2765 5.63608C18.849 5.35848 19.4458 5.13427 20.0595 4.96628C20.0747 4.96253 20.088 4.95878 20.1032 4.95503C20.2914 4.90437 20.4834 4.85747 20.6754 4.81807C21.072 4.73432 21.4731 4.67356 21.8767 4.63608C21.9052 4.6342 21.9337 4.63232 21.9604 4.63232C22.1215 4.6327 22.2797 4.6761 22.4185 4.75803C22.4204 4.75803 22.4204 4.75991 22.4223 4.75991C22.5214 4.81786 22.608 4.89504 22.677 4.98692C22.7984 5.14152 22.864 5.33257 22.8633 5.52913H24.7641C24.8345 5.52913 24.9048 5.53101 24.9751 5.53476C26.2438 5.57812 27.4506 6.09334 28.3594 6.97959C29.2683 7.86584 29.8136 9.05934 29.8889 10.3265C29.8946 10.4166 29.8965 10.5047 29.8965 10.5948Z" fill="#1A202C"/>
      <path d="M17.1427 11.5328C19.0079 11.5328 20.5198 10.0208 20.5198 8.15568C20.5198 6.29055 19.0079 4.77856 17.1427 4.77856C15.2776 4.77856 13.7656 6.29055 13.7656 8.15568C13.7656 10.0208 15.2776 11.5328 17.1427 11.5328Z" fill="#1A202C"/>
      <path d="M21.2718 5.52906C21.3363 7.39307 19.7598 8.90617 17.8947 8.90617C16.999 8.90617 16.14 8.55037 15.5067 7.91704C14.8734 7.28371 14.5176 6.42473 14.5176 5.52906C14.5176 3.66393 16.7764 3.64463 17.8947 2.15195C19.4767 0.0403461 21.1848 3.01529 21.2718 5.52906Z" fill="#1A202C"/>
      <path d="M15.4336 52.1048C16.9723 46.6293 19.8136 41.9739 24.3806 38.4048C24.562 38.1278 24.846 37.9339 25.1701 37.8659C25.4942 37.7979 25.8321 37.8612 26.1096 38.042L36.4561 44.799C36.7332 44.9804 36.927 45.2643 36.995 45.5884C37.063 45.9125 36.9997 46.2504 36.8189 46.5279L27.8719 60.2279C27.6905 60.505 27.4066 60.6988 27.0825 60.7668C26.7583 60.8348 26.4205 60.7715 26.143 60.5907L15.7964 53.8337C15.5194 53.6523 15.3256 53.3684 15.2575 53.0443C15.1895 52.7202 15.2528 52.3823 15.4336 52.1048Z" fill="#EDF2F7"/>
      <path opacity="0.2" d="M17.2484 54.0283C16.9713 53.8469 16.7775 53.563 16.7095 53.2389C16.6414 52.9147 16.7048 52.5769 16.8855 52.2994C18.4242 46.8239 21.2656 42.1685 25.8326 38.5994C25.9526 38.418 26.1184 38.2716 26.3131 38.175L26.1095 38.042C25.832 37.8612 25.4942 37.7979 25.1701 37.8659C24.846 37.9339 24.562 38.1277 24.3806 38.4048C19.8136 41.9739 16.9723 46.6293 15.4336 52.1048C15.2528 52.3823 15.1895 52.7202 15.2575 53.0443C15.3255 53.3684 15.5194 53.6523 15.7964 53.8337L26.143 60.5907C26.3271 60.7101 26.5396 60.7787 26.7588 60.7895C26.9781 60.8004 27.1963 60.753 27.3913 60.6523L17.2484 54.0283Z" fill="black"/>
      <path d="M29.7367 51.3584L22.7908 46.8496C22.6804 46.7778 22.603 46.6651 22.5757 46.5363C22.5483 46.4074 22.5732 46.273 22.6449 46.1626C22.7166 46.0521 22.8293 45.9746 22.9581 45.9471C23.0869 45.9197 23.2213 45.9445 23.3318 46.0161L30.2778 50.5248C30.3878 50.5968 30.4649 50.7095 30.492 50.8381C30.5191 50.9668 30.4942 51.1009 30.4226 51.2112C30.351 51.3215 30.2386 51.3989 30.1101 51.4265C29.9815 51.4541 29.8473 51.4296 29.7367 51.3584L29.7367 51.3584Z" fill="white"/>
      <path d="M25.8025 45.5561L24.2744 44.5642C24.1638 44.4927 24.0861 44.3803 24.0584 44.2516C24.0307 44.1229 24.0552 43.9884 24.1265 43.8777C24.1979 43.7671 24.3102 43.6893 24.4389 43.6614C24.5675 43.6335 24.702 43.6578 24.8128 43.729L24.8154 43.7307L26.3435 44.7226C26.4536 44.7946 26.5306 44.9072 26.5577 45.0359C26.5849 45.1645 26.5599 45.2987 26.4883 45.409C26.4167 45.5193 26.3043 45.5967 26.1758 45.6243C26.0472 45.6518 25.913 45.6273 25.8025 45.5561L25.8025 45.5561Z" fill="white"/>
      <path d="M27.3589 55.0228L20.413 50.5141C20.3029 50.4421 20.2258 50.3295 20.1986 50.2008C20.1714 50.0721 20.1964 49.9379 20.268 49.8276C20.3396 49.7173 20.452 49.6398 20.5806 49.6123C20.7092 49.5847 20.8435 49.6093 20.954 49.6805L27.9 54.1893C27.9549 54.2246 28.0024 54.2704 28.0396 54.3241C28.0769 54.3777 28.1031 54.4382 28.117 54.502C28.1308 54.5659 28.132 54.6318 28.1203 54.6961C28.1086 54.7603 28.0844 54.8217 28.049 54.8766C28.0136 54.9315 27.9678 54.9788 27.9141 55.016C27.8603 55.0531 27.7998 55.0793 27.736 55.0931C27.6721 55.1068 27.6062 55.1079 27.5419 55.0961C27.4777 55.0843 27.4164 55.06 27.3616 55.0245L27.3589 55.0228Z" fill="white"/>
      <path d="M28.5482 53.1907L21.6022 48.682C21.4916 48.6103 21.414 48.4977 21.3864 48.3689C21.3588 48.24 21.3836 48.1055 21.4552 47.9948C21.5268 47.8842 21.6394 47.8066 21.7683 47.779C21.8971 47.7514 22.0317 47.7762 22.1423 47.8478L22.1433 47.8484L29.0892 52.3572C29.1993 52.4292 29.2764 52.5418 29.3036 52.6705C29.3308 52.7992 29.3058 52.9334 29.2342 53.0437C29.1626 53.154 29.0502 53.2314 28.9216 53.259C28.793 53.2865 28.6587 53.262 28.5482 53.1907L28.5482 53.1907Z" fill="white"/>
      <path d="M30.2824 43.2165C31.3606 43.5994 32.5451 43.0357 32.928 41.9575C33.3109 40.8793 32.7473 39.6949 31.6691 39.312C30.5909 38.9291 29.4064 39.4927 29.0235 40.5709C28.6406 41.6491 29.2042 42.8336 30.2824 43.2165Z" fill="#2D3748"/>
      <path d="M57.0393 16.1966C57.8371 10.5652 60.035 5.57365 64.0875 1.42961C64.2305 1.13089 64.4861 0.901086 64.7983 0.790617C65.1106 0.680148 65.4539 0.698034 65.7529 0.840349L76.9052 6.16327C77.2039 6.30627 77.4337 6.56191 77.5442 6.87413C77.6547 7.18634 77.6368 7.52963 77.4945 7.82867L70.4463 22.5956C70.3033 22.8943 70.0477 23.1241 69.7355 23.2346C69.4232 23.3451 69.08 23.3272 68.7809 23.1849L57.6286 17.862C57.3299 17.719 57.1001 17.4633 56.9896 17.1511C56.8791 16.8389 56.897 16.4956 57.0393 16.1966Z" fill="#EDF2F7"/>
      <path opacity="0.2" d="M59.0935 17.8619C58.7948 17.7189 58.565 17.4633 58.4545 17.1511C58.344 16.8389 58.3619 16.4956 58.5042 16.1966C59.3021 10.5652 61.4999 5.57362 65.5524 1.42959C65.6473 1.23393 65.7921 1.06679 65.9723 0.945098L65.7529 0.840343C65.4538 0.698034 65.1106 0.68015 64.7983 0.790615C64.4861 0.90108 64.2305 1.13088 64.0875 1.42959C60.035 5.57364 57.8371 10.5652 57.0393 16.1966C56.897 16.4956 56.8791 16.8389 56.9896 17.1511C57.1001 17.4633 57.3299 17.7189 57.6286 17.8619L68.7809 23.1849C68.9793 23.2788 69.199 23.3185 69.4177 23.3001C69.6364 23.2817 69.8464 23.2058 70.0264 23.0801L59.0935 17.8619Z" fill="black"/>
      <path d="M71.117 13.5571L63.6337 10.0108C63.5146 9.95438 63.4228 9.85295 63.3785 9.72885C63.3342 9.60474 63.341 9.46812 63.3975 9.34904C63.4539 9.22995 63.5553 9.13816 63.6794 9.09386C63.8035 9.04956 63.9402 9.05637 64.0593 9.11281L71.5425 12.6591C71.6611 12.7158 71.7524 12.8172 71.7964 12.9411C71.8404 13.065 71.8334 13.2013 71.7771 13.3201C71.7208 13.4389 71.6198 13.5306 71.496 13.575C71.3723 13.6194 71.236 13.613 71.117 13.5571L71.117 13.5571Z" fill="white"/>
      <path d="M66.446 8.32875L64.7996 7.54856C64.7405 7.52085 64.6874 7.48176 64.6434 7.43352C64.5994 7.38528 64.5654 7.32884 64.5432 7.26742C64.521 7.20601 64.5112 7.14083 64.5142 7.07561C64.5172 7.01039 64.5331 6.9464 64.5609 6.88731C64.5886 6.82822 64.6278 6.77519 64.6761 6.73124C64.7244 6.68728 64.7808 6.65328 64.8423 6.63117C64.9037 6.60905 64.9689 6.59927 65.0341 6.60237C65.0993 6.60546 65.1633 6.62139 65.2224 6.64922L65.2252 6.65057L66.8715 7.43076C66.9902 7.48743 67.0816 7.58884 67.1256 7.71278C67.1697 7.83672 67.1628 7.97305 67.1064 8.09191C67.0501 8.21077 66.949 8.30244 66.8251 8.34684C66.7013 8.39123 66.565 8.38473 66.446 8.32875Z" fill="white"/>
      <path d="M69.2465 17.5049L61.7633 13.9587C61.6446 13.902 61.5532 13.8006 61.5091 13.6766C61.4651 13.5527 61.472 13.4164 61.5283 13.2975C61.5847 13.1787 61.6858 13.087 61.8096 13.0426C61.9334 12.9982 62.0698 13.0047 62.1888 13.0607L69.6721 16.607C69.7311 16.6347 69.7841 16.6738 69.8281 16.7221C69.872 16.7703 69.906 16.8267 69.9281 16.8881C69.9503 16.9495 69.9601 17.0146 69.9571 17.0798C69.954 17.145 69.9381 17.2089 69.9104 17.268C69.8826 17.327 69.8435 17.38 69.7953 17.424C69.747 17.4679 69.6906 17.5019 69.6292 17.524C69.5679 17.5462 69.5027 17.556 69.4375 17.553C69.3724 17.5499 69.3084 17.5341 69.2494 17.5063L69.2465 17.5049Z" fill="white"/>
      <path d="M70.182 15.531L62.6987 11.9847C62.5795 11.9284 62.4876 11.8271 62.4432 11.703C62.3987 11.579 62.4054 11.4424 62.4617 11.3232C62.518 11.2041 62.6193 11.1121 62.7433 11.0677C62.8674 11.0232 63.004 11.0299 63.1232 11.0862L63.1242 11.0867L70.6075 14.633C70.7261 14.6897 70.8175 14.7911 70.8615 14.915C70.9055 15.0389 70.8986 15.1752 70.8422 15.294C70.7859 15.4129 70.6848 15.5045 70.561 15.5489C70.4373 15.5933 70.301 15.5869 70.1819 15.531L70.182 15.531Z" fill="white"/>
      <path d="M71.0053 5.45959C72.1495 5.45959 73.077 4.53205 73.077 3.38787C73.077 2.2437 72.1495 1.31616 71.0053 1.31616C69.8611 1.31616 68.9336 2.2437 68.9336 3.38787C68.9336 4.53205 69.8611 5.45959 71.0053 5.45959Z" fill="#2D3748"/>
      <path d="M17.8132 48.0561C21.7074 44.3627 26.3661 41.7437 32.0375 40.5477C32.6373 40.2319 33.3925 40.3698 33.7257 40.856L39.6844 49.5764C40.0163 50.0634 39.7999 50.7141 39.2009 51.0311L24.9766 58.5395C24.3768 58.8554 23.6216 58.7175 23.2884 58.2312L17.3297 49.5108C16.9978 49.0239 17.2142 48.3731 17.8132 48.0561Z" fill="#EDF2F7"/>
      <path opacity="0.2" d="M18.516 50.1543C18.1842 49.6673 18.4005 49.0166 18.9995 48.6996C22.8938 45.0062 27.5525 42.3872 33.2238 41.1912C33.4157 41.0918 33.6272 41.036 33.8432 41.0277L33.726 40.8561C33.3928 40.3699 32.6376 40.232 32.0378 40.5478C26.3664 41.7438 21.7077 44.3628 17.8135 48.0562C17.2144 48.3732 16.9981 49.024 17.3299 49.5109L23.2886 58.2313C23.4124 58.3939 23.5753 58.5224 23.7622 58.6049C23.9491 58.6874 24.1539 58.7212 24.3574 58.7031L18.516 50.1543Z" fill="black"/>
      <path d="M30.733 52.3437L26.72 46.5106C26.5873 46.3171 26.6729 46.058 26.9113 45.9318C27.0194 45.8693 27.1461 45.8471 27.269 45.8692C27.3919 45.8914 27.503 45.9563 27.5825 46.0526L31.5955 51.8858C31.7277 52.0795 31.6415 52.3385 31.4029 52.4644C31.295 52.5265 31.1685 52.5485 31.0459 52.5264C30.9233 52.5043 30.8125 52.4396 30.733 52.3437L30.733 52.3437Z" fill="white"/>
      <path d="M29.9681 46.5382L29.0852 45.2549C28.9515 45.062 29.0358 44.8026 29.2736 44.6755C29.3816 44.6126 29.5086 44.59 29.6317 44.6118C29.7549 44.6337 29.8663 44.6985 29.9462 44.7947L29.9477 44.797L30.8306 46.0803C30.9628 46.2739 30.8766 46.533 30.638 46.6588C30.53 46.721 30.4036 46.7429 30.281 46.7209C30.1584 46.6988 30.0475 46.6341 29.9681 46.5382Z" fill="white"/>
      <path d="M26.941 54.3568L22.928 48.5237C22.7958 48.33 22.882 48.071 23.1206 47.9451C23.2286 47.883 23.355 47.861 23.4776 47.8831C23.6002 47.9051 23.7111 47.9698 23.7905 48.0657L27.8035 53.8989C27.9373 54.0918 27.8529 54.3512 27.6152 54.4782C27.5071 54.5412 27.3802 54.5638 27.257 54.542C27.1338 54.5201 27.0224 54.4553 26.9425 54.359L26.941 54.3568Z" fill="white"/>
      <path d="M28.8368 53.3503L24.8238 47.5171C24.6906 47.324 24.7755 47.0647 25.0135 46.938C25.1217 46.8752 25.2486 46.8528 25.3717 46.8749C25.4948 46.8969 25.6061 46.9619 25.6858 47.0584L25.6864 47.0592L29.6994 52.8923C29.8316 53.086 29.7453 53.3451 29.5068 53.4709C29.3988 53.5331 29.2723 53.555 29.1498 53.533C29.0272 53.5109 28.9163 53.4462 28.8368 53.3503L28.8368 53.3503Z" fill="white"/>
      <path d="M36.8478 46.6872C37.9695 46.4615 38.7262 45.5196 38.5379 44.5833C38.3495 43.647 37.2875 43.0709 36.1658 43.2965C35.0441 43.5222 34.2874 44.4641 34.4758 45.4004C34.6641 46.3367 35.7261 46.9128 36.8478 46.6872Z" fill="#2D3748"/>
      <path d="M26.4717 59.9847C26.24 59.8566 26.0388 59.6797 25.8822 59.4663C25.7255 59.2528 25.6171 59.0079 25.5645 58.7484C25.5118 58.4889 25.5161 58.2211 25.5771 57.9635C25.6381 57.7059 25.7544 57.4646 25.9178 57.2563L21.5312 49.2703L23.4418 47.5139L28.5825 57.037C28.9126 57.3382 29.1208 57.7501 29.1676 58.1945C29.2144 58.6389 29.0965 59.0851 28.8363 59.4484C28.5761 59.8117 28.1917 60.067 27.7559 60.1658C27.3201 60.2647 26.8631 60.2002 26.4717 59.9847H26.4717Z" fill="#FFB6B6"/>
      <path d="M21.349 24.6349L16.631 24.1797C16.4109 24.1585 16.1887 24.1835 15.9789 24.2532C15.769 24.3228 15.576 24.4356 15.4123 24.5843C15.2486 24.7329 15.1178 24.9142 15.0283 25.1164C14.9388 25.3186 14.8926 25.5373 14.8926 25.7584V39.7983L23.523 55.6228L27.0877 53.3714L21.4592 39.4877L21.349 24.6349Z" fill="#F7B80B"/>
      <path d="M96.4319 100.531H-18.8789V100.907H96.4319V100.531Z" fill="#CCCCCC"/>
    </g>
    <defs>
      <clipPath id="clip0_1001_48712">
        <rect width="77.1107" height="100" fill="white" transform="translate(0.445312 0.718994)"/>
      </clipPath>
    </defs>
  </svg>

);