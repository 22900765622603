import { Box, Flex } from "@chakra-ui/react";
import React, { FC, ReactNode } from "react";
import { useHelpContext } from "../../context/HelpContext";
import HelpBubble from "../SelectionDePersonnelBlock/helpBubble";

interface Props {
  children: ReactNode;
}

const ContentLayout: FC<Props> = ({ children }) => {
  const { help, setHelp } = useHelpContext();
  return (
    <Flex direction="column" justifyContent="space-between">
      {children}
      <Box position="fixed" bottom="12rem" right="0" zIndex={3}>
        <HelpBubble onClick={() => setHelp(null)}>{help}</HelpBubble>
      </Box>
    </Flex>
  );
};
export default ContentLayout;
