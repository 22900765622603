import {
  BoxProps,
  Flex,
  Grid,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/react";
import { FC, ReactElement } from "react";
import { colors } from "../../ui/theme";
import ColorBoxButton from "../Buttons/ColorBoxButton";
import { DressingShoesIcon } from "../Icons/DressingShoesIcon";

const WHITE = "#fff";
const BLACK = "#000";

interface ShoesOutfitSelectionCardProps {
  selectedOutfit: any;
  onSelectShoes: (value: string | undefined) => void;
  onSelectShoesColor: (value: string | undefined) => void;
}
const ShoesOutfitSelectionCard: FC<
  ShoesOutfitSelectionCardProps & BoxProps
> = ({
  selectedOutfit,
  onSelectShoes,
  onSelectShoesColor,
  ...props
}): ReactElement => {
  const handleSelectBasketsColor = (value: string) => {
    if (selectedOutfit?.shoes === "Baskets") {
      onSelectShoesColor(value);
    }
  };

  return (
    <Flex
      gap="14px"
      width="80%"
      minWidth="250px"
      border={`1px solid ${colors.gray.lightMode}`}
      padding="14px"
      borderRadius="12px"
      {...props}
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap="14px"
      >
        <DressingShoesIcon />
        <Text variant="xl" fontWeight="500">
          Chaussures
        </Text>
      </Flex>
      <RadioGroup
        onChange={onSelectShoes}
        value={selectedOutfit?.shoes ?? ""}
        display="flex"
        flexWrap="wrap"
        flexDirection={{ md: "column", "2xl": "row" }}
        gap={{ md: "8px", "2xl": "14px" }}
      >
        <Grid width="100%" templateColumns="80% 20%" alignItems="center">
          <Radio value="Escarpins" key={1} gridColumn={1}>
            <Text marginRight="1rem" variant="lg" whiteSpace="nowrap">
              Escarpins
            </Text>
          </Radio>
          <ColorBoxButton
            buttonColor={BLACK}
            isActive={selectedOutfit?.shoes === "Escarpins"}
          />
        </Grid>
        <Grid width="100%" templateColumns="80% 20%" alignItems="center">
          <Radio value="Mocassins" key={1} gridColumn={1}>
            <Text marginRight="1rem" variant="lg" whiteSpace="nowrap">
              Mocassins
            </Text>
          </Radio>
          <ColorBoxButton
            buttonColor={BLACK}
            isActive={selectedOutfit?.shoes === "Mocassins"}
          />
        </Grid>
        <Grid width="100%" templateColumns="60% 20% 20%" alignItems="center">
          <Radio value="Baskets" key={2} gridColumn={1}>
            <Text variant="lg" whiteSpace="nowrap">
              Baskets
            </Text>
          </Radio>
          <ColorBoxButton
            buttonColor={BLACK}
            isActive={
              selectedOutfit?.shoes === "Baskets" &&
              selectedOutfit?.shoesColor === BLACK
            }
            onClick={() => handleSelectBasketsColor(BLACK)}
          />
          <ColorBoxButton
            buttonColor={WHITE}
            isActive={
              selectedOutfit?.shoes === "Baskets" &&
              selectedOutfit?.shoesColor === WHITE
            }
            onClick={() => handleSelectBasketsColor(WHITE)}
          />
        </Grid>
      </RadioGroup>
    </Flex>
  );
};

export default ShoesOutfitSelectionCard;
