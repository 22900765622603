import { useMutation } from "@apollo/client";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  ContactInput,
  ResponseOutput,
  sendContactEmail,
} from "../../../apollo/payment/mutation";
import { useCustomerContext } from "../../../context/CustomerContext";
import { useSegment } from "../../../context/SegmentContext";
import { colors } from "../../../ui/theme";
import ButtonCheckbox from "../../Buttons/CheckboxButton";
import NorelaButton from "../../Buttons/NorelaButton";
import PicturesVariant, { Icons } from "../../PicturesVariant/PicturesVariant";
import CalendarPopup from "../CalendarPopup";
import { MessageSentIcon } from "../../Icons/MessageSentIcon";

const validationScheme = Yup.object().shape({
  pronoun: Yup.array(Yup.string().equals(["Monsieur", "Madame"])).min(
    1,
    "Veuillez choisir le pronom par lequel nous pouvons vous appeler"
  ),
  name: Yup.string().required("Veuillez entrer votre nom"),
  email: Yup.string().email().required("Veuillez entrer votre email"),
  phone: Yup.string().required("Veuillez entrer votre numéro de téléphone"),
  message: Yup.string(),
  contactTime: Yup.array(Yup.string().equals(["Matinée", "Après-midi"]))
    .min(1, "Veuillez choisir une horaire pour vous contacter")
    .default(["Matinée"]),
  date: Yup.date().required("Veuillez choisir une date"),
});

export default function ContactFormContent({
  buttonFullWidth,
  onClose,
  buttonText,
}: {
  buttonFullWidth?: boolean;
  onClose?: () => void;
  buttonText: string;
}) {
  const {
    customerInfo,
    setCompanyName,
    setLastName,
    setFirstName,
    setEmail,
    setPhone,
    setNeedDescription,
    setTime,
    setDate,
    setPronoun,
  } = useCustomerContext();

  const [validationPage, setValidationPage] = useState(false);
  const [validInfo, setValidInfo] = useState(false);
  const [emailSendingState, setEmailSendingState] = useState("waitingUser"); // waitingUser | sending | sent | error

  const [doSendContactEmail] = useMutation<{
    sendContactEmail: ResponseOutput;
  }>(sendContactEmail);

  const [cn, setCn] = useState(customerInfo?.companyName ?? "");
  const [f, setFN] = useState(customerInfo?.firstname ?? "");
  const [l, setL] = useState(customerInfo?.lastname ?? "");
  const [e, setE] = useState(customerInfo?.email ?? "");
  const [p, setP] = useState(customerInfo?.phone ?? "");
  const [n, setN] = useState(customerInfo?.needDescription ?? "");
  const [t, setT] = useState(customerInfo?.time ?? "");
  const [d, setD] = useState(customerInfo?.date ?? undefined);
  const [pn, setPn] = useState(customerInfo?.pronoun ?? undefined);

  const handleChangePN = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPn(event.target.value);
  };

  const handleChangeCN = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCn(event.target.value);
  };

  const handleChangeL = (event: React.ChangeEvent<HTMLInputElement>) => {
    setL(event.target.value);
  };
  const handleChangeFN = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFN(event.target.value);
  };

  const handleChangeE = (event: React.ChangeEvent<HTMLInputElement>) => {
    setE(event.target.value);
  };

  const handleChangeP = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const formattedValue = value
      .replace(/\s/g, "")
      .replace(/(\d{2})(?=\d)/g, "$1 ");

    setP(formattedValue);
  };

  const handleChangeN = (event: React.ChangeEvent<HTMLInputElement>) => {
    setN(event.target.value);
  };

  const handleChangeT = (event: React.ChangeEvent<HTMLInputElement>) => {
    setT(event.target.value);
  };

  const handleChangeD = (date?: Date) => {
    setD(date);
  };

  const { track } = useSegment();
  const onHandleSubmit = () => {
    setCompanyName(cn);
    setLastName(l);
    setFirstName(f);
    setEmail(e);
    setPhone(p);
    setNeedDescription(n);
    setTime(t);
    setDate(d);
    setPronoun(pn!);
    const contactInfo: ContactInput = {
      pronoun: pn || "",
      company: cn,
      firstname: f,
      lastname: l,
      email: e,
      siren: "",
      phone: p,
      address: "",
      availablePeriodOfDay: t,
      availableDays:
        d?.toLocaleDateString("fr-FR", { dateStyle: "short" }) || "",
      requestText: n,
    };
    setEmailSendingState("sending");
    doSendContactEmail({ variables: { contact: contactInfo } })
      .then((res) => {
        if (res.data?.sendContactEmail?.code === 200) {
          setEmailSendingState("sent");
        } else {
          setEmailSendingState("error");
        }
      })
      .catch(() => {
        setEmailSendingState("error");
      });
    track("Contact");
  };

  useEffect(() => {
    if (l && e && p && n && t && d && cn) setValidInfo(true);
    else setValidInfo(false);
  }, [l, e, p, n, t, d, cn]);

  return (
    <>
      {!validationPage ? (
        <Formik
          initialValues={validationScheme.getDefault()}
          validationSchema={validationScheme}
          onSubmit={() => onHandleSubmit()}
          // isInitialValid={false}
        >
          <Form>
            <Flex width="100%" gap="1rem">
              <Box w="100%">
                <FormControl>
                  <FormLabel mt="0.75rem" mb="0.5rem">
                    <Text variant="xl" fontWeight="700" display="inline">
                      Raison sociale
                    </Text>
                    <Text
                      variant="xl"
                      fontWeight="700"
                      display="inline"
                      color="red"
                    >
                      *
                    </Text>
                  </FormLabel>
                  <InputGroup>
                    <Input
                      id="companyName"
                      type="text"
                      autoComplete="on"
                      name="companyName"
                      value={cn ? cn : ""}
                      onChange={handleChangeCN}
                      placeholder="Renseignez le nom de votre entreprise"
                      _focusVisible={{
                        borderColor: colors.secondaryFontColor.lightMode,
                        boxShadow: `0 0 0 1px ${colors.secondaryFontColor.lightMode}`,
                      }}
                      errorBorderColor={colors.red.default}
                      focusBorderColor={colors.blue.default}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <InputGroup>
                    <Flex flexDir="column">
                      <FormLabel mt="0.75rem" mb="0.5rem">
                        <Text variant="xl" fontWeight="700" display="inline">
                          Quel pronom voulez-vous utiliser
                        </Text>
                        <Text
                          variant="xl"
                          fontWeight="700"
                          display="inline"
                          color="red"
                        >
                          *
                        </Text>
                      </FormLabel>
                      <Flex gap="2rem">
                        <ButtonCheckbox
                          handleChange={handleChangePN}
                          active={pn === "Monsieur"}
                          value="Monsieur"
                          name="pronoun"
                          text="Monsieur"
                        />
                        <ButtonCheckbox
                          handleChange={handleChangePN}
                          active={pn === "Madame"}
                          value="Madame"
                          name="pronoun"
                          text="Madame"
                        />
                      </Flex>
                    </Flex>
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel mt="0.75rem" mb="0.5rem">
                    <Text variant="xl" fontWeight="700" display="inline">
                      Prénom
                    </Text>
                    <Text
                      variant="xl"
                      fontWeight="700"
                      display="inline"
                      color="red"
                    >
                      *
                    </Text>
                  </FormLabel>
                  <InputGroup>
                    <Input
                      id="name"
                      type="text"
                      autoComplete="on"
                      name="name"
                      value={f ? f : ""}
                      onChange={handleChangeFN}
                      placeholder="Renseignez votre prénom"
                      _focusVisible={{
                        borderColor: colors.secondaryFontColor.lightMode,
                        boxShadow: `0 0 0 1px ${colors.secondaryFontColor.lightMode}`,
                      }}
                      errorBorderColor={colors.red.default}
                      focusBorderColor={colors.blue.default}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel mt="0.75rem" mb="0.5rem">
                    <Text variant="xl" fontWeight="700" display="inline">
                      Nom
                    </Text>
                    <Text
                      variant="xl"
                      fontWeight="700"
                      display="inline"
                      color="red"
                    >
                      *
                    </Text>
                  </FormLabel>
                  <InputGroup>
                    <Input
                      id="name"
                      type="text"
                      autoComplete="on"
                      name="name"
                      value={l ? l : ""}
                      onChange={handleChangeL}
                      placeholder="Renseignez votre nom"
                      _focusVisible={{
                        borderColor: colors.secondaryFontColor.lightMode,
                        boxShadow: `0 0 0 1px ${colors.secondaryFontColor.lightMode}`,
                      }}
                      errorBorderColor={colors.red.default}
                      focusBorderColor={colors.blue.default}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel mt="0.75rem" mb="0.5rem">
                    <Text variant="xl" fontWeight="700" display="inline">
                      Email
                    </Text>
                    <Text
                      variant="xl"
                      fontWeight="700"
                      display="inline"
                      color="red"
                    >
                      *
                    </Text>
                  </FormLabel>
                  <InputGroup>
                    <Input
                      id="email"
                      type="email"
                      autoComplete="on"
                      name="email"
                      value={e ? e : ""}
                      onChange={handleChangeE}
                      placeholder="Renseignez votre email"
                      _focusVisible={{
                        borderColor: colors.secondaryFontColor.lightMode,
                        boxShadow: `0 0 0 1px ${colors.secondaryFontColor.lightMode}`,
                      }}
                      errorBorderColor={colors.red.default}
                      focusBorderColor={colors.blue.default}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel mt="0.75rem" mb="0.5rem">
                    <Text variant="xl" display="inline" fontWeight="700">
                      Téléphone
                    </Text>
                    <Text
                      variant="xl"
                      fontWeight="700"
                      display="inline"
                      color="red"
                    >
                      *
                    </Text>
                  </FormLabel>
                  <InputGroup>
                    <Input
                      id="phone"
                      type="tel"
                      autoComplete="on"
                      name="phone"
                      value={p ? p : ""}
                      maxLength={14}
                      onChange={handleChangeP}
                      placeholder="06 XX XX XX XX"
                      _focusVisible={{
                        borderColor: colors.secondaryFontColor.lightMode,
                        boxShadow: `0 0 0 1px ${colors.secondaryFontColor.lightMode}`,
                      }}
                      errorBorderColor={colors.red.default}
                      focusBorderColor={colors.blue.default}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel mt="0.75rem" mb="0.5rem">
                    <Text variant="xl" fontWeight="700" display="inline">
                      Expliquez votre besoin brièvement
                    </Text>
                    <Text
                      variant="xl"
                      fontWeight="700"
                      display="inline"
                      color="red"
                    >
                      *
                    </Text>
                  </FormLabel>
                  <InputGroup>
                    <Input
                      as="textarea"
                      minHeight="90px"
                      resize="vertical"
                      overflow="auto"
                      id="message"
                      name="message"
                      value={n ? n : ""}
                      onChange={handleChangeN}
                      placeholder="Décrivez votre événement et vos contraintes"
                      _focusVisible={{
                        borderColor: colors.secondaryFontColor.lightMode,
                        boxShadow: `0 0 0 1px ${colors.secondaryFontColor.lightMode}`,
                      }}
                      errorBorderColor={colors.red.default}
                      focusBorderColor={colors.blue.default}
                    />
                  </InputGroup>
                </FormControl>
              </Box>
              <Flex flexDirection={"column"} gap="1rem">
                <Box w="100%" pt="1.5rem">
                  <PicturesVariant icon={Icons.BOTTOM_POPUPS_ICON} />
                </Box>

                <FormControl pl="2rem">
                  <InputGroup>
                    <Flex flexDir="column">
                      <FormLabel mt="0.75rem" mb="0.5rem">
                        <Text variant="xl" fontWeight="700" display="inline">
                          Quand peut-on vous contacter
                        </Text>
                        <Text
                          variant="xl"
                          fontWeight="700"
                          display="inline"
                          color="red"
                        >
                          *
                        </Text>
                      </FormLabel>
                      <Flex gap="1.5em">
                        <ButtonCheckbox
                          handleChange={handleChangeT}
                          active={t === "Matinée"}
                          value="Matinée"
                          name="contactTime"
                          text="Matinée"
                        />
                        <ButtonCheckbox
                          handleChange={handleChangeT}
                          active={t === "Après-midi"}
                          value="Après-midi"
                          name="contactTime"
                          text="Après-midi"
                        />
                      </Flex>
                    </Flex>
                  </InputGroup>
                </FormControl>

                <FormControl pl="2rem">
                  <InputGroup>
                    <DayOff onChange={(date) => handleChangeD(date)} date={d} />
                  </InputGroup>
                </FormControl>
              </Flex>
            </Flex>

            <Flex justifyContent="center" mt="3rem" mb="2rem">
              <NorelaButton
                backgroundColor={colors.blue.lightMode}
                padding="16px 74px"
                type="submit"
                disabled={!validInfo || emailSendingState !== "waitingUser"}
                width={buttonFullWidth ? "100%" : undefined}
                onClick={() => {
                  onHandleSubmit();
                  setValidationPage(true);
                }}
              >
                Valider
              </NorelaButton>
            </Flex>
            {emailSendingState !== "waitingUser" && (
              <Text variant="m" fontWeight="700" textAlign="center">
                {emailSendingState === "sending" && "Envoi en cours..."}
                {emailSendingState === "sent" &&
                  "Le mail a bien été envoyé, nous vous recontacterons à la date que vous avez indiquée."}
                {emailSendingState === "error" &&
                  "Une erreur est survenue, veuillez réessayer plus tard"}
              </Text>
            )}
          </Form>
        </Formik>
      ) : (
        <Box
          alignItems="center"
          alignContent="center"
          display="flex"
          flexDir="column"
        >
          <MessageSentIcon width="750px" height="540px" />
          <Text variant="2xl" fontWeight="700" textAlign="center" m="1rem 0">
            Message envoyé !
          </Text>
          <Text variant="xl" textAlign="center" mb="1rem">
            Vous allez être recontacté par nos équipes !
          </Text>
          {onClose ? (
            <NorelaButton
              width={buttonFullWidth ? "100%" : "10rem"}
              onClick={() => {
                onClose();
              }}
            >
              {buttonText}
            </NorelaButton>
          ) : (
            <></>
          )}
        </Box>
      )}
    </>
  );
}

const DayOff = (props: {
  onChange: (date: Date | undefined) => void;
  date: Date | undefined;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Flex direction="column">
      <Flex direction={"row"} mt="0.75rem" mb="0.5rem">
        <Text variant="xl" fontWeight="700" display="inline">
          Quel jour êtes-vous disponible ?
        </Text>
        <Text variant="xl" fontWeight="700" display="inline" color="red">
          *
        </Text>
      </Flex>
      <Flex gap="20px" alignItems="center">
        <NorelaButton onClick={() => setOpen(!open)}>
          Choisir une date
        </NorelaButton>
        {props.date && (
          <Text variant="sm">
            Nous vous appellerons le{" "}
            {props.date.toLocaleDateString("fr-FR", { dateStyle: "long" })}
          </Text>
        )}
        {open && (
          <CalendarPopup
            changeDates={(moments: string[] | null) =>
              props.onChange(
                moment(moments?.at(moments.length - 1))?.toDate() ?? undefined
              )
            }
            setOpenPopup={setOpen}
            isOpen={open}
            selectedDates={props.date ? [moment(props.date).toISOString()] : []}
            onlyDaySelection
            selectedDatePeriod={null}
          />
        )}
      </Flex>
    </Flex>
  );
};
