import { Icon, IconProps } from "@chakra-ui/react";

export default function AdviceIcon(props: IconProps) {
  return (
    <Icon width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.50753 0C5.73753 0 2.69753 2.95 2.50753 6.66L0.58753 9.19C0.34753 9.5 0.58753 10 1.00753 10H2.50753V13C2.50753 14.11 3.39753 15 4.50753 15H5.50753V18H12.5075V13.31C14.8775 12.19 16.5075 9.8 16.5075 7C16.5075 3.14 13.3875 0 9.50753 0ZM10.5075 11H8.50753V10H10.5075V11ZM12.1075 6.5C11.8412 6.94967 11.462 7.32196 11.0075 7.58V9H8.00753V7.58C6.57753 6.75 6.07753 4.92 6.90753 3.5C7.73753 2.08 9.57753 1.56 11.0075 2.38C12.4375 3.2 12.9375 5.05 12.1075 6.5Z"
        fill="#091C25"
      />
    </Icon>
  );
}
