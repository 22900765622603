import { Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BottomBlock from "../../components/BottomActionsBlock/BottomBlock";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import ContentLayout from "../../components/Layout/ContentLayout";
import MainContainer from "../../components/Layout/MainContainer";
import TotalCard from "../../components/TotalCard/TotalCard";
import { useOnboardingContext } from "../../context/OnboardingContext";
import { PageURL } from "../../types/pages";
import GuestArrivalForm from "../../components/ArrivalOfGuests/GuestArrivalForm";
import { useSessionContext } from "../../context/SessionContext";
import { useSegment } from "../../context/SegmentContext";

export default function OnboardingTransport() {
  const { eventSubcategory, nbParticipants, transport, setTransport } =
    useOnboardingContext();
  const navigate = useNavigate();
  const { updateHistory } = useSessionContext();
  const { track } = useSegment();

  useEffect(() => {
    if (!eventSubcategory) {
      updateHistory(PageURL.ONBOARDING);
      navigate(PageURL.ONBOARDING);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventSubcategory]);

  return (
    <Layout>
      <ContentLayout>
        <MainContainer>
          <Flex flexDir="column">
            <Header
              title={"COMMENT VOS INVITÉS SE RENDRONT-ILS SUR PLACE ?"}
              subtitle={
                "Déterminez le flux d’arrivée de vos convives. Le rythme est aussi important que le nombre d’invités sur place."
              }
            />
            <GuestArrivalForm
              nbParticipants={nbParticipants}
              transport={transport}
              setTransport={setTransport}
              mr="auto"
            />
          </Flex>
          <BottomBlock
            disableNextPage={transport === undefined || !transport[0]}
            onNextPage={() => {
              let trackContent = "";
              if (transport?.[1]) trackContent = "Par vague ET En continu";
              else
                trackContent =
                  transport?.[0] === "Par vague" ? "Par vague" : "En continu";
              track("Arriving schema", { selection: trackContent });
              updateHistory(PageURL.ORDER as PageURL);
              navigate(PageURL.ONBOARDING_EVENT_OPTIONS);
            }}
          />
          <TotalCard />
        </MainContainer>
      </ContentLayout>
    </Layout>
  );
}
