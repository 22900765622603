import { Box, Heading } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BottomBlock from "../../components/BottomActionsBlock/BottomBlock";
import ContentLayout from "../../components/Layout/ContentLayout";
import ContactFormContent from "../../components/Popus/BottomPopup/ContactFormContent";
import { useResetOrder } from "../../hooks/useResetOrder";
import { PageURL } from "../../types/pages";

export default function ContactForm() {
  const [searchParams] = useSearchParams();
  const complex = searchParams.get("complex") === "true";
  const { completeResetOrder } = useResetOrder();
  const navigate = useNavigate();
  return (
    <ContentLayout>
      <Box maxW="900px" pb="200px">
        {complex && (
          <Heading size="lg" textAlign="left">
            Votre demande est trop complexe, nous vous conseillons de prendre un
            rendez-vous avec un conseiller
          </Heading>
        )}
        <ContactFormContent
          buttonFullWidth
          onClose={() => {
            completeResetOrder();
            navigate(PageURL.HOME);
          }}
          buttonText="Démarrer une nouvelle commande"
        />
      </Box>
      <BottomBlock />
    </ContentLayout>
  );
}
