import { createContext, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import {
  EVENT_CATEGORY_TEXTS,
  EVENT_SUBCATEGORY_TEXTS,
  EVENT_TYPE_TEXTS,
  EventCategory,
  EventSubCategory,
  EventSubCategory_ANIMATION,
  EventType,
} from "../modules/Onboarding/EventTypes";
import {
  manageTablettesHiding,
  setOptionConfig,
} from "../modules/Onboarding/onboardingConfig";
import { OptionType, useOptionContext } from "./OptionsContext";
import { Abc } from "../modules/Onboarding/type";
import { DressCode } from "../components/DressingBlock/DressingBlock";
import { Location } from "../modules/Onboarding/EventTypes";

export const HIDE_ACCUEIL_OPTIONS = [
  OptionType.Cabas,
  OptionType.Besace,
  OptionType.BesacePrint,
  OptionType.Ballonbag,
  OptionType.Oriflamme,
];
export const HIDE_STREET_MARKETING_OPTIONS = [
  OptionType.TabletteNumérique,
  OptionType.Portant,
  OptionType.TicketsVestiaires,
  OptionType.PanneauDirectionnel,
  // OptionType.TourDeCou,
  OptionType.TotbagR,
  OptionType.TotbagRV,
  // OptionType.Badge,
];

export type EmargementInfo = {
  emargement?: boolean;
  digital?: boolean;
};

export type FlyerInfo = {
  nbFlyers?: number;
  ownFlyers?: boolean;
  optionSelected?: Abc;
};

export type SellingPointInfo = {
  sellingPoints?: number;
  sameLocation?: boolean;
};

export type OnboardingInfo = {
  eventType?: EventType;
  eventCategory?: EventCategory;
  eventSubcategory?: EventSubCategory;
  eventCustomSubcategory?: string; // Only if "autre" has been chosen as subCategory
  options: {
    salonSize?: string;
    nbParticipants?: string;
    transport?: string[];
    emargementConfig: EmargementInfo;
    eventName?: string;
    eventLocations?: Location[];
    parking?: boolean;
    eventInWinter?: boolean | undefined;
    flyerConfig: FlyerInfo;
    sellingPointConfig: SellingPointInfo;
  };
  dressCode?: DressCode;
};

export type OnboardingSetters = {
  setEventType: (eventType: EventType, eventCategory?: EventCategory) => void;
  setEventSubcategory: (eventSubtype: EventSubCategory) => void;
  setEventCustomSubcategory: (eventCustomSubcategory: string) => void;
  setSalonSize: (salonSize: string) => void;
  setNbParticipants: (nbParticipants: string) => void;
  setParking: (parking: boolean) => void;
  setEventInWinter: (eventInWinter: boolean | undefined) => void;
  setTransport: (transport: string[]) => void;
  setSellingPointConfig: (sellingPointConfig: SellingPointInfo) => void;
  setFlyerConfig: (flyerConfig: FlyerInfo) => void;
  setEmargementConfig: (emargementConfig: EmargementInfo) => void;
  setEventName: (eventName: string) => void;
  setEventLocations: (eventLocations: Location[]) => void;
  setDressCode: (dressCode: DressCode) => void;
};

export type OnboardingContextType = {
  eventType?: EventType;
  eventCategory?: EventCategory;
  eventSubcategory?: EventSubCategory;
  eventCustomSubcategory?: string; // Only if "autre" has been chosen as subCategory
  dressCode?: DressCode;
  getAllInfo: () => OnboardingInfo;
  getAllSetters: () => OnboardingSetters;
  resetOnboarding: () => void;
} & OnboardingSetters &
  OnboardingInfo["options"];

export const OnboardingContext = createContext({} as OnboardingContextType);

export function generateSummaryDressCode(dressCode?: DressCode) {
  const {
    // outfitType,
    professionalOutfit,
    personalOutfit,
    providedOutfit,
    printedOutfit,
    presentation,
  } = dressCode || {};

  const summaryParts = [];

  // TODO: refactor colors..
  const COLOR_MAPPING: any = {
    "#000": "Noir",
    "#B80000": "Rouge",
    "#fad0c3": "Nude",
    "#fff": "Blanc",
    "#db3e00": "Orange",
    "#F7B80B": "Jaune",
    "#004dcf": "Bleu",
    "#008b02": "Vert",
    "#5300eb": "Violet",
    None: "Aucun",
  };

  function getColorName(hexColor: string) {
    return COLOR_MAPPING[hexColor] || hexColor;
  }

  // if (outfitType) summaryParts.push(`Code vestimentaire: ${outfitType}`);
  if (professionalOutfit)
    summaryParts.push(`Tenue professionnelle: ${professionalOutfit}`);

  if (personalOutfit && (personalOutfit.generic || personalOutfit.custom)) {
    summaryParts.push("Tenue personnelle:");
    if (personalOutfit.generic)
      summaryParts.push(`- Générique: ${personalOutfit.generic}`);
    if (personalOutfit.custom) {
      const customOutfit = personalOutfit.custom;
      const customOutfitSummary = ["- Sur mesure:"];
      if (customOutfit.ensemble)
        customOutfitSummary.push(`  - Ensemble: ${customOutfit.ensemble}`);
      if (customOutfit.blazer) customOutfitSummary.push(`  - Blazer: Oui`);
      if (customOutfit.top)
        customOutfitSummary.push(`  - Haut: ${customOutfit.top}`);
      if (customOutfit.topColor)
        customOutfitSummary.push(
          `  - Couleur du haut: ${getColorName(customOutfit.topColor)}`
        );
      if (customOutfit.bottom)
        customOutfitSummary.push(`  - Bas: ${customOutfit.bottom}`);
      if (customOutfit.bottomColor)
        customOutfitSummary.push(
          `  - Couleur du bas: ${getColorName(customOutfit.bottomColor)}`
        );
      if (customOutfit.shoes)
        customOutfitSummary.push(`  - Chaussures: ${customOutfit.shoes}`);
      if (customOutfit.shoesColor)
        customOutfitSummary.push(
          `  - Couleur des chaussures: ${getColorName(customOutfit.shoesColor)}`
        );
      summaryParts.push(...customOutfitSummary);
    }
  }

  if (
    printedOutfit &&
    (printedOutfit.printedCap ||
      printedOutfit.printedTshirt ||
      printedOutfit.printedWindbreaker)
  ) {
    summaryParts.push("Tenue imprimée:");
    if (printedOutfit.printedCap) summaryParts.push(`- Casquette`);
    if (printedOutfit.printedTshirt) summaryParts.push(`- T-shirt`);
    if (printedOutfit.printedWindbreaker) summaryParts.push(`- Coupe-vent`);
  }

  if (
    printedOutfit &&
    (printedOutfit.logoFormat ||
      printedOutfit.bottom ||
      printedOutfit.bottomColor ||
      printedOutfit.shoes ||
      printedOutfit.shoesColor ||
      printedOutfit.color)
  ) {
    summaryParts.push("Tenue imprimée (détails):");
    if (printedOutfit.logoFormat)
      summaryParts.push(`- Format du logo: ${printedOutfit.logoFormat}`);
    if (printedOutfit.bottom)
      summaryParts.push(`- Bas: ${printedOutfit.bottom}`);
    if (printedOutfit.bottomColor)
      summaryParts.push(
        `- Couleur du bas: ${getColorName(printedOutfit.bottomColor)}`
      );
    if (printedOutfit.shoes)
      summaryParts.push(`- Chaussures: ${printedOutfit.shoes}`);
    if (printedOutfit.shoesColor)
      summaryParts.push(
        `- Couleur des chaussures: ${getColorName(printedOutfit.shoesColor)}`
      );
    if (printedOutfit.color)
      summaryParts.push(`- Couleur: ${getColorName(printedOutfit.color)}`);
  }

  if (providedOutfit) {
    summaryParts.push("Tenue fournie:");
    if (providedOutfit.providedTop) summaryParts.push(`- Haut: fourni`);
    if (providedOutfit.providedBottom) summaryParts.push(`- Bas: fourni`);
    if (providedOutfit.providedShoes)
      summaryParts.push(`- Chaussures: fournies`);

    if (!providedOutfit.providedTop) {
      summaryParts.push(`- Haut attendu:`);
      if (providedOutfit.blazer) summaryParts.push(`Blazer: Noir`);
      if (providedOutfit.top && providedOutfit.topColor)
        summaryParts.push(
          `${providedOutfit.top}: ${getColorName(providedOutfit.topColor)}`
        );
    }

    if (!providedOutfit.providedBottom) {
      summaryParts.push(`- Bas attendu:`);
      if (providedOutfit.bottom && providedOutfit.bottomColor)
        summaryParts.push(
          `${providedOutfit.bottom}: ${getColorName(
            providedOutfit.bottomColor
          )}`
        );
    }

    if (!providedOutfit.providedShoes) {
      summaryParts.push(`- Chaussures attendu:`);
      if (providedOutfit.shoes && providedOutfit.shoesColor)
        summaryParts.push(
          `${providedOutfit.shoes}: ${getColorName(providedOutfit.shoesColor)}`
        );
    }
  }

  if (
    presentation &&
    (presentation.hair || presentation.nails || presentation.lipstick)
  ) {
    summaryParts.push("Présentation:");
    if (presentation.hair) summaryParts.push(`- Cheveux: ${presentation.hair}`);
    if (presentation.nails)
      summaryParts.push(`- Ongles: ${getColorName(presentation.nails)}`);
    if (presentation.lipstick)
      summaryParts.push(
        `- Rouge à lèvres: ${getColorName(presentation.lipstick)}`
      );
  }

  const summary = summaryParts.join("\n");
  return summary;
}

export function generateSummaryOnboarding(info: OnboardingInfo) {
  // Without dressCode
  const {
    eventType,
    eventCategory,
    eventSubcategory,
    eventCustomSubcategory,
    options: {
      salonSize,
      nbParticipants,
      transport,
      emargementConfig: { emargement, digital },
      eventName,
      eventLocations,
      parking,
      eventInWinter,
      flyerConfig: { nbFlyers, ownFlyers, optionSelected },
      sellingPointConfig: { sellingPoints, sameLocation },
    },
  } = info;
  const summaryParts = [];

  if (eventName) summaryParts.push(`Nom de l'événement: ${eventName}`);
  if (eventLocations) {
    summaryParts.push(`Lieu de l'événement:`);
    for (let i = 0; i < eventLocations.length; i++) {
      summaryParts.push(`- ${eventLocations[i].address}`);
    }
  }
  if (eventType)
    summaryParts.push(`Type d'événement: ${EVENT_TYPE_TEXTS[eventType].title}`);
  if (eventCategory)
    summaryParts.push(
      `Catégorie: ${EVENT_CATEGORY_TEXTS[eventCategory].title}`
    );
  if (eventSubcategory) {
    if ((eventCustomSubcategory ?? "") !== "")
      summaryParts.push(`Sous-catégorie: ${eventCustomSubcategory}`);
    else
      summaryParts.push(
        `Sous-catégorie: ${EVENT_SUBCATEGORY_TEXTS[eventSubcategory].title}`
      );
  }
  if (salonSize) summaryParts.push(`Taille du salon: ${salonSize}`);
  if (nbParticipants)
    summaryParts.push(`Nombre de participants: ${nbParticipants}`);
  if (transport && transport.length > 0) {
    const transportString = transport.join(", ");
    summaryParts.push(`Transport: ${transportString}`);
  }
  if (emargement !== undefined)
    summaryParts.push(`Emargement: ${emargement ? "Oui" : "Non"}`);
  if (digital !== undefined)
    summaryParts.push(`Version numérique: ${digital ? "Oui" : "Non"}`);
  if (parking !== undefined)
    summaryParts.push(`Parking: ${parking ? "Oui" : "Non"}`);
  if (eventInWinter !== undefined)
    summaryParts.push(`Événement en hiver: ${eventInWinter ? "Oui" : "Non"}`);
  if (nbFlyers) summaryParts.push(`Nombre de flyers: ${nbFlyers}`);
  if (ownFlyers !== undefined)
    summaryParts.push(`Flyers personnels: ${ownFlyers ? "Oui" : "Non"}`);
  if (optionSelected)
    summaryParts.push(`Option sélectionnée: ${optionSelected}`);
  if (sellingPoints) summaryParts.push(`Points de vente: ${sellingPoints}`);
  if (sameLocation !== undefined)
    summaryParts.push(`Même emplacement: ${sameLocation ? "Oui" : "Non"}`);

  const summary = summaryParts.join("\n");
  return summary;
}

export function OnboardingContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [eventCategory, setEventCategory] = useState(
    (localStorage.getItem("eventCategory") ?? undefined) as
      | EventCategory
      | undefined
  );
  const [eventType, setEventType] = useState(
    (localStorage.getItem("eventType") ?? undefined) as EventType | undefined
  );
  const [eventSubcategory, setEventSubcategory] = useState(
    (localStorage.getItem("eventSubcategory") ?? undefined) as
      | EventSubCategory
      | undefined
  );
  const [eventCustomSubcategory, setEventCustomSubcategory] = useState(
    localStorage.getItem("eventCustomSubcategory") ?? undefined
  );
  const [salonSize, setSalonSize] = useState(
    localStorage.getItem("salonSize") ?? undefined
  );
  const [nbParticipants, setNbParticipants] = useState(
    localStorage.getItem("nbParticipants") ?? undefined
  );
  const [parking, setParking] = useState<boolean | undefined>(
    localStorage.getItem("parking") === "true"
  );
  const [eventInWinter, setEventInWinter] = useState(() => {
    const eventInWinter = localStorage.getItem("eventInWinter");
    if (eventInWinter === "true") return true;
    else if (eventInWinter === "false") return false;
    // else eventInWinter is undefined (AUTOMATICALLY)
  });
  const [transport, setTransport] = useState(() => {
    try {
      const transport: string[] = JSON.parse(
        localStorage.getItem("transport") ?? "[]"
      );
      if (Array.isArray(transport)) return transport;
      else return [];
    } catch (e) {
      return [];
    }
  });
  const [flyerConfig, setFlyerConfig] = useState<FlyerInfo>(() => {
    try {
      return JSON.parse(localStorage.getItem("flyerConfig") ?? "{}");
    } catch (e) {
      return {};
    }
  });
  const [sellingPointConfig, setSellingPointConfig] =
    useState<SellingPointInfo>(() => {
      try {
        return JSON.parse(localStorage.getItem("sellingPointConfig") ?? "{}");
      } catch (e) {
        return {};
      }
    });
  const [emargementConfig, setEmargementConfig] = useState<EmargementInfo>(
    () => {
      try {
        return JSON.parse(localStorage.getItem("emargementConfig") ?? "{}");
      } catch (e) {
        return {};
      }
    }
  );
  const [eventName, setEventName] = useState<string | undefined>(
    localStorage.getItem("eventName") ?? undefined
  );
  const locations = localStorage.getItem("eventLocations");
  const [eventLocations, setEventLocations] = useState<Location[]>(
    locations !== null
      ? JSON.parse(locations)
      : [{ address: "", isInGreatParis: false } as Location]
  );
  const [dressCode, setDressCode] = useState<DressCode>(() => {
    try {
      return JSON.parse(localStorage.getItem("dressCode") ?? "{}");
    } catch (e) {
      return {};
    }
  });

  useEffect(() => {
    if (eventCategory && eventType) {
      localStorage.setItem("eventCategory", eventCategory);
      localStorage.setItem("eventType", eventType);
    } else {
      localStorage.removeItem("eventCategory");
      localStorage.removeItem("eventType");
    }
    if (eventSubcategory) {
      localStorage.setItem("eventSubcategory", eventSubcategory);
    } else {
      localStorage.removeItem("eventSubcategory");
    }
    if (eventCustomSubcategory) {
      localStorage.setItem("eventCustomSubcategory", eventCustomSubcategory);
    } else {
      localStorage.removeItem("eventCustomSubcategory");
    }
    if (salonSize) {
      localStorage.setItem("salonSize", salonSize);
    } else {
      localStorage.removeItem("salonSize");
    }
    if (nbParticipants) {
      localStorage.setItem("nbParticipants", nbParticipants);
    } else {
      localStorage.removeItem("nbParticipants");
    }
    localStorage.setItem("flyerConfig", JSON.stringify(flyerConfig));
    if (parking) {
      localStorage.setItem("parking", "true");
    } else {
      localStorage.removeItem("parking");
    }
    if (eventInWinter !== undefined) {
      localStorage.setItem("eventInWinter", eventInWinter.toString());
    } else {
      localStorage.removeItem("eventInWinter");
    }
    if (transport) {
      localStorage.setItem("transport", JSON.stringify(transport));
    } else {
      localStorage.removeItem("transport");
    }
    localStorage.setItem(
      "sellingPointConfig",
      JSON.stringify(sellingPointConfig)
    );
    if (emargementConfig) {
      localStorage.setItem(
        "emargementConfig",
        JSON.stringify(emargementConfig)
      );
    } else {
      localStorage.removeItem("emargementConfig");
    }
    if (eventName) {
      localStorage.setItem("eventName", eventName);
    } else {
      localStorage.removeItem("eventName");
    }
    if (eventLocations) {
      localStorage.setItem("eventLocations", JSON.stringify(eventLocations));
    } else {
      localStorage.removeItem("eventLocations");
    }
    if (dressCode) {
      localStorage.setItem("dressCode", JSON.stringify(dressCode));
    } else {
      localStorage.removeItem("dressCode");
    }
  }, [
    eventCategory,
    eventSubcategory,
    eventCustomSubcategory,
    eventType,
    flyerConfig,
    nbParticipants,
    salonSize,
    parking,
    eventInWinter,
    transport,
    sellingPointConfig,
    emargementConfig,
    eventName,
    eventLocations,
    dressCode,
  ]);

  useEffect(() => {
    const onboardingInfo = {
      eventCategory,
      eventType,
      eventSubcategory,
      eventCustomSubcategory,
      options: {
        salonSize,
        nbParticipants,
        sellingPointConfig,
        parking,
        eventInWinter,
        transport,
        flyerConfig,
        emargementConfig,
        eventName,
        eventLocations,
      },
      dressCode,
    };

    Sentry.setContext("onBoarding", {
      onboardingInfo: JSON.stringify(onboardingInfo),
    });
  }, [
    eventCategory,
    eventType,
    eventSubcategory,
    eventCustomSubcategory,
    salonSize,
    nbParticipants,
    sellingPointConfig,
    parking,
    eventInWinter,
    transport,
    flyerConfig,
    emargementConfig,
    eventName,
    eventLocations,
    dressCode,
  ]);

  const { optionInfo, setOptionInfo } = useOptionContext();

  return (
    <OnboardingContext.Provider
      value={{
        resetOnboarding() {
          setEventType(undefined);
          setEventCategory(undefined);
          setEventSubcategory(undefined);
          setEventCustomSubcategory(undefined);
          setSalonSize(undefined);
          setNbParticipants(undefined);
          setFlyerConfig({});
          setSellingPointConfig({});
          setParking(undefined);
          setEventInWinter(undefined);
          setEmargementConfig({});
          setTransport([]);
          setEventName(undefined);
          setEventLocations([
            {
              address: "",
              isInGreatParis: false,
              isAddressKnown: true,
              city: "",
              postalCode: "",
            },
          ]);
          setDressCode({});
        },
        setEventType(eventType, eventCategory) {
          setEventType(eventType);
          setEventCategory(eventCategory);
          setEventSubcategory(undefined);
          setEventCustomSubcategory(undefined);
        },
        eventType,
        eventCategory,
        setEventSubcategory(eventSubcategory) {
          if (EventSubCategory_ANIMATION.includes(eventSubcategory)) {
            setOptionConfig(
              HIDE_STREET_MARKETING_OPTIONS,
              optionInfo,
              optionInfo!.selectedOptions,
              setOptionInfo
            );
          } else {
            setOptionConfig(
              manageTablettesHiding(
                HIDE_ACCUEIL_OPTIONS,
                emargementConfig.digital
              ),
              optionInfo,
              optionInfo!.selectedOptions,
              setOptionInfo
            );
          }
          setEventSubcategory(eventSubcategory);
          setSalonSize(undefined);
          setNbParticipants(undefined);
          setFlyerConfig({});
          setSellingPointConfig({});
          setTransport([]);
        },
        setEventCustomSubcategory(eventCustomSubcategory) {
          setEventCustomSubcategory(eventCustomSubcategory);
        },
        eventSubcategory,
        eventCustomSubcategory,
        setSalonSize(salonSize) {
          setSalonSize(salonSize);
          setFlyerConfig({});
          setNbParticipants(undefined);
          setSellingPointConfig({});
        },
        salonSize,
        setNbParticipants(nbParticipants) {
          setNbParticipants(nbParticipants);
          setSalonSize(undefined);
          setFlyerConfig({});
          setSellingPointConfig({});
        },
        nbParticipants,
        setFlyerConfig(flyerInfo) {
          setFlyerConfig(flyerInfo);
          setSalonSize(undefined);
          setNbParticipants(undefined);
          setSellingPointConfig({});
        },
        flyerConfig,
        setSellingPointConfig(sellingPointConfig) {
          setSellingPointConfig(sellingPointConfig);
          setSalonSize(undefined);
          setNbParticipants(undefined);
          setFlyerConfig({});
        },
        setEventLocations,
        eventLocations,
        sellingPointConfig,
        setParking,
        parking,
        setEventInWinter,
        eventInWinter,
        setTransport,
        transport,
        setEmargementConfig,
        emargementConfig,
        setEventName,
        eventName,
        setDressCode,
        dressCode,
        getAllInfo() {
          return {
            eventCategory,
            eventType,
            eventSubcategory,
            eventCustomSubcategory,
            options: {
              salonSize,
              nbParticipants,
              sellingPointConfig,
              parking,
              eventInWinter,
              transport,
              flyerConfig,
              emargementConfig,
              eventName,
              eventLocations,
            },
            dressCode,
          } as OnboardingInfo;
        },
        getAllSetters() {
          return {
            setEventType,
            setEventInWinter,
            setEventSubcategory,
            setEventCustomSubcategory,
            setFlyerConfig,
            setNbParticipants,
            setSellingPointConfig,
            setParking,
            setSalonSize,
            setTransport,
            setEmargementConfig,
            setEventName,
            setEventLocations,
            setDressCode,
          } as OnboardingSetters;
        },
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
}

export function useOnboardingContext() {
  const context = useContext(OnboardingContext);

  return context;
}
