import { Box, BoxProps } from "@chakra-ui/react";

export default function ColorBoxButton({
  buttonColor,
  isActive,
  onClick,
  ...props
}: { isActive?: boolean; buttonColor: string } & BoxProps) {
  return (
    <Box
      width="2rem"
      height="2rem"
      display="flex"
      border={isActive ? "2px solid #FFD700" : "2px solid transparent"}
      borderRadius="10px"
      cursor="pointer"
      {...props}
    >
      <Box
        width="20px"
        height="20px"
        boxSizing="border-box"
        borderRadius="6px"
        border="1.5px solid black"
        background={buttonColor}
        onClick={onClick}
        m="auto"
      />
    </Box>
  );
}
