export const RoleAnimateurBilingueIcon = () => (
  <svg
    width="180"
    height="120"
    viewBox="0 0 180 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1485_2287)">
      <path
        d="M29.8501 60C29.8426 66.5609 30.914 73.0785 33.0214 79.2923C33.1422 79.6473 33.266 80.0012 33.3928 80.3538C35.6244 86.5201 38.8515 92.2798 42.946 97.4041C44.1683 98.9361 45.4635 100.405 46.8316 101.81C48.1996 103.218 49.6357 104.557 51.14 105.827C59.5306 112.937 69.7231 117.593 80.5938 119.283H80.5956C81.9814 119.498 83.3808 119.665 84.7939 119.785C86.4764 119.927 88.179 119.999 89.9018 120C97.9134 120.01 105.846 118.414 113.228 115.305C120.611 112.197 127.294 107.639 132.881 101.902C133.274 101.501 133.661 101.094 134.042 100.681C134.99 99.6533 135.903 98.5935 136.78 97.5018C137.272 96.8876 137.752 96.2639 138.22 95.6308C141.106 91.7358 143.507 87.5048 145.37 83.0308C146.164 81.1253 146.858 79.1794 147.448 77.2012C147.491 77.061 147.532 76.9207 147.573 76.7805C147.662 76.4787 147.748 76.1751 147.83 75.8698C149.244 70.6982 149.958 65.3609 149.954 60C149.954 26.8633 123.067 0 89.9018 0C56.7365 0 29.8501 26.8633 29.8501 60Z"
        fill="#F2F2F2"
      />
      <path
        d="M159.855 23.0233C159.223 22.2734 158.325 21.7959 157.349 21.6899C157.21 21.6749 157.071 21.6673 156.931 21.6672L84.9084 21.6C84.387 21.5982 83.8708 21.7032 83.3917 21.9086C82.9126 22.114 82.4807 22.4154 82.1229 22.7943C81.4333 23.5431 81.0565 24.5271 81.0698 25.5446L81.0589 37.2342C81.0533 39.3522 81.5126 41.4456 82.4045 43.3671C81.9068 45.2524 81.075 47.0334 79.9484 48.6256C79.5979 49.1301 79.397 49.7231 79.3687 50.3365C79.3404 50.9499 79.4859 51.5589 79.7885 52.0934C80.0911 52.6279 80.5385 53.0663 81.0793 53.3581C81.6201 53.65 82.2323 53.7834 82.8456 53.7432C83.6048 53.6963 84.361 53.6104 85.1112 53.4857C87.2949 53.144 89.3784 52.3332 91.218 51.1091C92.6332 51.5541 94.108 51.7809 95.5916 51.7816L146.236 51.8289C148.146 51.8358 150.038 51.4633 151.803 50.7329C153.567 50.0025 155.169 48.9289 156.514 47.5744C159.256 44.7783 160.779 41.0122 160.752 37.0981L160.762 25.6237C160.778 24.6769 160.457 23.7552 159.855 23.0233Z"
        fill="#F7B80B"
      />
      <path
        d="M141.037 32.2077L100.782 32.1701C100.667 32.17 100.553 32.1473 100.447 32.1032C100.341 32.0591 100.244 31.9946 100.163 31.9132C100.082 31.8319 100.018 31.7354 99.9736 31.6291C99.9297 31.5229 99.9071 31.4091 99.9072 31.2942C99.9073 31.1793 99.9301 31.0655 99.9742 30.9594C100.018 30.8532 100.083 30.7568 100.164 30.6757C100.246 30.5945 100.342 30.5301 100.449 30.4862C100.555 30.4423 100.669 30.4198 100.784 30.4199L141.038 30.4575C141.271 30.4577 141.493 30.5501 141.658 30.7144C141.822 30.8786 141.914 31.1013 141.914 31.3334C141.913 31.5655 141.821 31.788 141.656 31.9519C141.492 32.1159 141.269 32.2079 141.037 32.2077Z"
        fill="white"
      />
      <path
        d="M141.032 37.5791L100.777 37.5416C100.545 37.5414 100.322 37.449 100.158 37.2847C99.9942 37.1204 99.9021 36.8978 99.9023 36.6657C99.9026 36.4336 99.995 36.2111 100.159 36.0471C100.324 35.8832 100.547 35.7912 100.779 35.7914L141.034 35.8289C141.266 35.8291 141.489 35.9216 141.653 36.0858C141.817 36.2501 141.909 36.4728 141.909 36.7048C141.908 36.9369 141.816 37.1594 141.652 37.3234C141.487 37.4874 141.264 37.5793 141.032 37.5791Z"
        fill="white"
      />
      <path
        d="M105.464 42.9174L100.772 42.913C100.657 42.9129 100.544 42.8902 100.437 42.8461C100.331 42.802 100.235 42.7375 100.153 42.6561C100.072 42.5748 100.008 42.4783 99.9638 42.3721C99.9199 42.2659 99.8974 42.152 99.8975 42.0371C99.8976 41.9222 99.9203 41.8084 99.9644 41.7023C100.009 41.5962 100.073 41.4998 100.155 41.4186C100.236 41.3374 100.333 41.273 100.439 41.2291C100.545 41.1853 100.659 41.1627 100.774 41.1628L105.465 41.1672C105.697 41.1678 105.92 41.2604 106.083 41.4246C106.247 41.5888 106.339 41.8113 106.339 42.0431C106.339 42.275 106.246 42.4972 106.082 42.6612C105.918 42.8251 105.696 42.9172 105.464 42.9174Z"
        fill="white"
      />
      <path
        d="M16.6382 28.0697L33.3222 65.8422C33.3222 65.8422 49.5012 69.7954 63.8282 38.1277C63.8282 38.1277 73.4378 20.179 87.4993 22.3008L63.3635 0.419875C63.3635 0.419875 50.906 -4.12808 37.6622 16.712C37.6605 16.712 31.1993 30.2247 16.6382 28.0697Z"
        fill="#5D76B7"
      />
      <path
        d="M37.817 23.0658L38.6405 25.3581L37.0557 26.9294L38.9031 26.7026L39.5935 28.8L40.1401 26.4192L41.8598 26.281L40.3353 25.0463L40.6885 22.8L39.3415 24.3023L37.817 23.0658Z"
        fill="#FFD037"
      />
      <path
        d="M41.3474 17.2242L42.1203 19.4289L40.6587 21.1821L42.3973 20.7975L43.3293 22.7999L43.5614 20.3136L45.4628 20.0157L43.814 18.9793L44.1659 16.7999L42.9962 18.2607L41.3474 17.2242Z"
        fill="#FFD037"
      />
      <path
        d="M47.2307 12.9142L48.0223 14.7753L46.6641 16.6957L48.2861 16.1388L49.0776 17.9999L49.6036 15.6969L51.4682 15.3556L49.7328 14.3478L50.3243 11.9999L48.8758 13.7891L47.2307 12.9142Z"
        fill="#FFD037"
      />
      <path
        d="M52.4709 12.6873L53.4027 14.2321L51.4683 15.5935L53.6815 15.2178L54.5364 16.7999L55.2311 14.851L57.4734 14.6228L55.6451 14.004L56.2857 11.9999L54.4053 13.415L52.4709 12.6873Z"
        fill="#FFD037"
      />
      <path
        d="M57.2754 16.2874L58.2055 17.8322L56.2725 19.1936L58.4864 18.8179L59.3416 20.4L60.0345 18.451L62.2776 18.2229L60.4486 17.6041L61.0916 15.6L59.2085 17.0151L57.2754 16.2874Z"
        fill="#FFD037"
      />
      <path
        d="M59.6773 21.2593L60.6074 23.1903L58.6743 24.8921L60.8862 24.4224L61.7435 26.4L62.4364 23.9639L64.6795 23.6787L62.8505 22.9052L63.4934 20.4L61.6103 22.1689L59.6773 21.2593Z"
        fill="#FFD037"
      />
      <path
        d="M59.3183 29.8661L60.147 31.7047L58.6743 33.9694L60.4582 33.1852L61.222 34.8L61.5914 32.4939L63.4785 31.7279L61.8111 31.0034L62.1605 28.8L61.024 30.3867L59.3183 29.8661Z"
        fill="#FFD037"
      />
      <path
        d="M55.7718 37.3038L56.476 39.5377L55.0713 41.9717L56.9466 41.1283L57.7358 43.2L58.1123 40.3887L59.8754 39.5245L58.2843 38.7415L58.7061 36L57.3829 37.9698L55.7718 37.3038Z"
        fill="#FFD037"
      />
      <path
        d="M51.0065 41.7842L51.561 44.2248L50.2671 46.3836L51.5486 45.9781L52.1169 47.9999L52.5418 45.4413L53.8702 44.6855L52.8246 43.6346L53.1115 40.7999L52.1376 42.856L51.0065 41.7842Z"
        fill="#FFD037"
      />
      <path
        d="M45.3341 42.357L45.9724 44.7028L44.2617 46.2105L45.995 46.1351L46.4973 47.9999L47.1669 45.7833L49.0659 45.3974L47.6761 44.1662L48.0039 41.9999L46.7641 43.3675L45.3341 42.357Z"
        fill="#FFD037"
      />
      <path
        d="M39.8139 38.8892L40.5281 40.9076L38.2568 42.2537L40.6491 42.3185L41.1083 44.4L42.0134 42.1192L44.262 42.0059L42.5708 40.9173L43.2851 38.4L41.2745 39.9421L39.8139 38.8892Z"
        fill="#FFD037"
      />
      <path
        d="M38.3013 31.6892L38.8727 33.7075L37.0557 35.0536L38.9695 35.1168L39.3368 37.2L40.0609 34.9176L41.8598 34.8058L40.5053 33.7172L41.0783 31.2L39.4698 32.7404L38.3013 31.6892Z"
        fill="#FFD037"
      />
      <path
        d="M16.6382 26.1921L50.1345 103.2L52.6692 102.38L19.1503 25.2L16.6382 26.1921Z"
        fill="#F0F5FA"
      />
      <path
        d="M73.4978 113.791L74.7131 118.063C76.6517 118.568 78.6145 118.975 80.5939 119.283H80.5957C81.9815 119.498 83.3809 119.665 84.7939 119.785L80.8408 114.142L80.2794 114.092L73.4978 113.791Z"
        fill="#2F2E41"
      />
      <path
        d="M42.9462 97.4042C44.1686 98.9361 45.4638 100.405 46.8318 101.81L51.5187 101.814L76.7245 101.842L81.2621 101.847L84.2789 101.851L88.7668 101.854L92.2189 101.858L98.5954 101.865L102.357 101.87L110.277 101.878L114.058 101.883L124.535 101.894L131.116 101.901L132.882 101.902C133.274 101.501 133.661 101.094 134.042 100.681C134.991 99.6533 135.903 98.5935 136.78 97.5018L133.123 97.4983L130.632 97.4965L124.865 97.4894L113.736 97.4787L109.975 97.4752L108.454 97.4734H108.449L107.255 97.4716L106.72 97.4699L105.35 97.4681H104.771L100.71 97.4645L91.9488 97.4539L88.0934 97.4503L84.4015 97.4468L80.9405 97.4432L75.9747 97.4379L49.4524 97.4113L42.9462 97.4042Z"
        fill="#CCCCCC"
      />
      <path
        d="M106.599 100.351C104.623 100.351 103.022 99.84 103.022 99.21C103.022 98.5801 104.623 98.0695 106.599 98.0695C108.574 98.0695 110.176 98.5801 110.176 99.21C110.176 99.84 108.574 100.351 106.599 100.351Z"
        fill="#3F3D56"
      />
      <path
        d="M106.599 99.5902C104.623 99.5902 103.022 99.0796 103.022 98.4497C103.022 97.8197 104.623 97.3091 106.599 97.3091C108.574 97.3091 110.176 97.8197 110.176 98.4497C110.176 99.0796 108.574 99.5902 106.599 99.5902Z"
        fill="#3F3D56"
      />
      <path
        d="M107.228 99.214L106.695 99.206L106.842 89.2619C106.863 87.6394 106.459 86.0396 105.668 84.6223C104.877 83.205 103.728 82.0198 102.335 81.185L92.1617 75.1094L92.4351 74.6522L102.608 80.728C104.081 81.6109 105.297 82.8643 106.133 84.3632C106.969 85.862 107.398 87.5539 107.375 89.2699L107.228 99.214Z"
        fill="#3F3D56"
      />
      <path
        d="M93.7194 78.2996C93.7194 79.0135 93.1401 79.5923 92.4256 79.5923C91.7111 79.5923 91.1318 79.0135 91.1318 78.2996V70.2396C91.1318 69.5257 91.7111 68.947 92.4256 68.947C93.1401 68.947 93.7194 69.5257 93.7194 70.2396V78.2996Z"
        fill="#F7B80B"
      />
      <path
        d="M92.4708 56.287L78.5194 72.7593L66.6245 77.8468L63.454 71.6892C66.3627 68.2315 75.8533 64.0813 75.8533 64.0813L88.7402 52.5324C88.7633 52.4474 88.7881 52.3626 88.819 52.2783C88.9997 51.7842 89.305 51.3449 89.7053 51.003C90.1056 50.6612 90.5874 50.4283 91.1041 50.3268C91.6209 50.2254 92.155 50.2588 92.655 50.4239C93.1551 50.589 93.604 50.8802 93.9585 51.2693C94.3129 51.6584 94.5609 52.1323 94.6785 52.6451C94.7961 53.158 94.7794 53.6925 94.6298 54.197C94.4802 54.7014 94.2029 55.1589 93.8247 55.525C93.4465 55.8911 92.9802 56.1536 92.4708 56.287Z"
        fill="#A0616A"
      />
      <path
        d="M64.6971 69.8177L67.7744 77.6896L67.7713 77.7202C67.4646 80.7444 62.7359 83.5448 58.823 85.3613C57.5323 85.9592 56.0625 86.0452 54.7108 85.6018C53.359 85.1584 52.2262 84.2188 51.5414 82.9728C51.0423 82.0503 50.8116 81.0068 50.875 79.9601C50.9385 78.9134 51.2938 77.9053 51.9007 77.0498L55.0075 72.7029L64.6971 69.8177Z"
        fill="#F7B80B"
      />
      <path
        d="M46.8599 84.7259L48.4963 90.6106L49.4521 97.4112L49.747 99.5023L49.7542 99.5467L51.5717 101.272L51.5184 101.814L51.3762 103.257L51.14 105.827C59.5318 112.935 69.7237 117.591 80.5937 119.283C80.5955 119.285 80.5955 119.283 80.5955 119.283C82.0471 118.867 83.1859 118.416 83.6443 118.088C83.8255 117.96 83.9073 117.847 83.9037 117.731C83.8842 116.963 81.7006 114.204 81.3702 113.79L80.5031 110.205L78.7336 109.502L79.1706 106.979L76.8041 104.483L76.7366 101.915L76.7241 101.842L75.9744 97.4378L74.4784 88.6668L74.3256 87.7686L74.4642 80.8419L74.5051 78.7437L74.553 76.3508C74.5656 75.7355 74.5204 75.1204 74.418 74.5135C74.3422 74.0564 74.233 73.6055 74.0911 73.1644C73.6969 71.936 73.0639 70.7975 72.2286 69.8139C71.3932 68.8303 70.3719 68.021 69.223 67.4325C69.1324 67.3863 69.0418 67.3419 68.9494 67.2975C68.9494 67.2975 68.9494 67.2958 68.9476 67.2975C68.2369 66.9619 67.4883 66.7134 66.7179 66.5573L66.494 66.1597L64.3016 62.2686L57.5822 62.4407L53.574 68.7088L50.2285 72.0265C48.5901 73.6545 47.4146 75.6887 46.8226 77.9204C46.2306 80.152 46.2435 82.5009 46.8599 84.7259Z"
        fill="#F7B80B"
      />
      <path
        d="M61.5279 60.3467C56.6116 60.3467 52.6261 56.3647 52.6261 51.4526C52.6261 46.5405 56.6116 42.5585 61.5279 42.5585C66.4442 42.5585 70.4297 46.5405 70.4297 51.4526C70.4297 56.3647 66.4442 60.3467 61.5279 60.3467Z"
        fill="#A0616A"
      />
      <path
        d="M62.6146 60.4657C61.236 60.4871 59.8582 60.3909 58.4961 60.1781C57.146 59.9038 54.1256 57.6923 53.2836 56.3616C52.658 55.3728 52.258 53.8288 52.0325 52.7078C51.7289 51.2243 51.7404 49.6936 52.0665 48.2148C52.3926 46.736 53.0259 45.3422 53.9254 44.1235C54.2711 43.6137 54.7482 43.2068 55.3064 42.9458C55.3559 42.9265 55.4068 42.9093 55.4582 42.8947C56.2715 42.6809 57.0023 42.2291 57.5568 41.5973C57.6643 41.4695 57.7809 41.3496 57.9056 41.2385C58.5323 40.7101 59.2752 40.3374 60.0737 40.1508C61.3625 39.8062 63.2276 40.1483 65.6173 41.1686C66.8178 41.6812 68.1308 41.4957 69.1408 41.2768C69.1975 41.2658 69.256 41.2699 69.3106 41.2886C69.3652 41.3073 69.4139 41.3399 69.452 41.3833C69.8817 41.7864 70.4482 42.012 71.0376 42.0148C71.3987 42.0307 71.7774 42.5301 72.2353 43.1814C72.3391 43.3293 72.4604 43.5018 72.5478 43.6067L72.5595 43.4051L72.7637 43.63C73.0847 43.9836 73.311 44.4125 73.4214 44.8769C73.5318 45.3413 73.5228 45.8261 73.3952 46.2861C73.2676 46.7461 73.0256 47.1663 72.6917 47.5077C72.3578 47.8491 71.9429 48.1005 71.4856 48.2385C71.1224 48.3351 70.7503 48.3943 70.3751 48.4153C70.1478 48.4358 69.9128 48.4569 69.6875 48.4959C68.9644 48.6439 68.2921 48.9766 67.7362 49.4617C67.1803 49.9467 66.7597 50.5675 66.5156 51.2634C66.4579 51.4243 66.3639 51.5697 66.241 51.6886C66.1181 51.8074 65.9695 51.8964 65.8067 51.9487C65.6438 52.0011 65.4712 52.0153 65.302 51.9904C65.1328 51.9654 64.9715 51.902 64.8308 51.8049C64.5961 51.6223 64.3207 51.4992 64.0281 51.4459C63.7354 51.3927 63.4343 51.4109 63.1502 51.4991C62.9048 51.6355 62.7137 51.8521 62.609 52.1125C62.4819 52.4026 62.2711 52.6483 62.0036 52.8182C61.0887 53.2895 61.0256 55.3774 61.3542 57.118C61.671 58.7957 62.2971 60.0094 62.8768 60.0694C63.3231 60.1155 63.3742 60.1472 63.4065 60.2261L63.4353 60.2967L63.3852 60.3627C63.1387 60.452 62.8759 60.4871 62.6146 60.4657Z"
        fill="#2F2E41"
      />
      <path
        d="M68.38 85.0517L71.8123 78.2146C76.7943 78.5546 86.6821 84.31 86.6821 84.31L105.39 88.2731C105.945 88.0602 106.545 87.997 107.132 88.0898C107.718 88.1825 108.27 88.428 108.731 88.8015C109.192 89.175 109.547 89.6633 109.759 90.2173C109.972 90.7713 110.034 91.3714 109.941 91.9572C109.847 92.5431 109.601 93.0939 109.226 93.5544C108.852 94.0148 108.363 94.3686 107.808 94.5802C107.253 94.7917 106.653 94.8535 106.067 94.7593C105.48 94.6652 104.929 94.4184 104.469 94.0438L80.6709 92.3394L68.38 85.0517Z"
        fill="#A0616A"
      />
      <path
        d="M69.7643 86.1742L60.8003 79.5184L59.2213 73.8331C58.9154 72.7159 58.9283 71.5354 59.2586 70.4251C59.589 69.3148 60.2235 68.319 61.0906 67.5501C62.2729 66.5158 63.8043 65.9686 65.375 66.0192C66.9458 66.0699 68.4386 66.7145 69.5518 67.8229C72.9251 71.187 76.7726 75.8803 75.9461 79.1347L75.9377 79.1677L69.7643 86.1742Z"
        fill="#F7B80B"
      />
      <path
        d="M134.589 77.416C134.6 77.432 134.61 77.4479 134.623 77.4657C134.978 77.4429 135.334 77.4423 135.689 77.4639C135.891 77.4763 136.094 77.4959 136.297 77.5225C136.551 77.5581 136.789 77.6696 136.979 77.8424C137.169 78.0153 137.302 78.2414 137.361 78.4912C137.42 78.741 137.402 79.0028 137.31 79.2424C137.218 79.4819 137.055 79.688 136.844 79.8337L136.822 79.8479C136.806 79.8586 136.794 79.8675 136.778 79.8781C139.142 81.9739 142.211 83.1 145.37 83.0308C146.165 81.1253 146.858 79.1794 147.449 77.2012C146.483 76.2955 145.3 75.653 144.014 75.3355C141.438 74.7142 138.564 75.4154 135.986 75.1598C134.745 75.0373 133.884 76.3882 134.589 77.416Z"
        fill="white"
      />
      <path
        d="M132.334 57.6373C132.341 57.6568 132.349 57.6781 132.357 57.6995C132.56 57.7119 132.763 57.7332 132.965 57.7598C133.219 57.7952 133.457 57.9065 133.647 58.0792C133.837 58.2519 133.97 58.4779 134.029 58.7275C134.089 58.9772 134.071 59.2389 133.979 59.4783C133.886 59.7177 133.724 59.9237 133.512 60.0693L133.491 60.0853C133.826 60.6327 134.203 61.1538 134.617 61.6438C135.79 63.0066 137.232 64.1125 138.853 64.8924C140.474 65.6722 142.238 66.1092 144.036 66.1758C144.153 66.1793 144.267 66.1829 144.382 66.1829C144.681 68.8602 145.329 71.4871 146.308 73.9971C146.679 74.9469 147.102 75.876 147.573 76.7805C147.662 76.4787 147.748 76.1752 147.831 75.8699C147.809 75.829 147.79 75.79 147.77 75.7491C147.301 74.7808 146.886 73.787 146.528 72.7722C146.235 71.9397 145.979 71.0965 145.761 70.2426C144.58 65.6053 144.517 60.7542 145.576 56.0876C146.534 54.7486 147.059 53.1484 147.079 51.5024C147.088 50.9098 147.01 50.3191 146.85 49.7486C146.713 49.8391 146.569 49.9243 146.427 50.0042C146.383 50.029 146.336 50.0539 146.29 50.0787L146.274 50.0876C146.116 50.173 145.937 50.2109 145.758 50.1968C145.579 50.1827 145.408 50.1172 145.266 50.0082C145.123 49.8992 145.015 49.7513 144.955 49.5824C144.895 49.4135 144.884 49.2308 144.926 49.0563C144.951 48.948 144.977 48.8397 145.002 48.7314C145.041 48.5645 145.082 48.3977 145.119 48.2308C145.126 48.2141 145.13 48.1969 145.134 48.1793C145.178 47.9894 145.224 47.8012 145.269 47.6113C144.914 47.3951 144.515 47.2638 144.101 47.2279C142.678 47.1142 141.363 48.3054 141.049 49.6953C140.729 51.087 141.26 52.5675 142.145 53.6858C142.904 54.6426 143.901 55.3758 144.938 56.029C144.915 56.1355 144.888 56.2403 144.865 56.345C144.451 58.2908 144.227 60.2724 144.197 62.2616C143.565 60.3764 142.195 58.4006 140.955 57.2983C139.542 56.0432 137.68 55.3101 135.946 54.4722C133.866 53.4657 131.56 55.4592 132.334 57.6373Z"
        fill="white"
      />
      <path
        d="M36.7931 85.1129C36.4964 85.4893 36.1891 85.8638 35.8817 86.2402C36.9404 88.4123 38.1307 90.518 39.4457 92.5455C39.5434 92.4763 39.6411 92.407 39.7388 92.3378C39.7543 92.3294 39.7691 92.3199 39.7833 92.3094C40.1475 92.0502 40.5135 91.7946 40.8777 91.5354V91.5337C41.1782 90.512 41.346 89.456 41.377 88.3916C41.1891 88.3486 41.019 88.2489 40.8898 88.106C40.7606 87.9631 40.6785 87.784 40.6547 87.5929C40.6308 87.4018 40.6664 87.208 40.7565 87.0378C40.8466 86.8676 40.9869 86.7292 41.1584 86.6413C41.2064 86.5579 41.2401 86.4976 41.2881 86.4124C41.2863 86.3911 41.2828 86.368 41.281 86.3467C41.0021 84.1295 38.1736 83.3556 36.7931 85.1129Z"
        fill="white"
      />
      <path
        d="M160.126 5.06415C159.654 4.50352 158.983 4.1465 158.254 4.06723C158.15 4.05601 158.046 4.05034 157.942 4.05026L104.141 4.00002C103.751 3.99864 103.366 4.07717 103.008 4.23074C102.65 4.38432 102.327 4.60969 102.06 4.8929C101.545 5.45276 101.263 6.18844 101.273 6.94915L101.265 15.6888C101.261 17.2724 101.604 18.8375 102.27 20.2741C101.899 21.6837 101.277 23.0152 100.436 24.2056C100.174 24.5827 100.024 25.0261 100.003 25.4847C99.9815 25.9434 100.09 26.3987 100.316 26.7983C100.542 27.1979 100.876 27.5257 101.28 27.7438C101.684 27.962 102.142 28.0618 102.6 28.0317C103.167 27.9967 103.732 27.9324 104.292 27.8392C105.923 27.5838 107.48 26.9775 108.854 26.0624C109.911 26.3951 111.013 26.5646 112.121 26.5652L149.952 26.6005C151.379 26.6057 152.793 26.3272 154.111 25.7811C155.429 25.2351 156.625 24.4323 157.631 23.4197C159.679 21.3292 160.816 18.5135 160.796 15.5871L160.804 7.00835C160.816 6.30044 160.576 5.61134 160.126 5.06415Z"
        fill="#2F2E41"
      />
      <path
        d="M146.069 11.931L115.999 11.9029C115.913 11.9028 115.828 11.8858 115.749 11.8528C115.669 11.8199 115.597 11.7716 115.536 11.7108C115.476 11.65 115.428 11.5778 115.395 11.4984C115.362 11.419 115.345 11.3339 115.345 11.248C115.345 11.1621 115.362 11.077 115.395 10.9977C115.428 10.9183 115.476 10.8463 115.537 10.7856C115.598 10.7249 115.67 10.6767 115.75 10.6439C115.829 10.6111 115.914 10.5943 116 10.5944L146.07 10.6224C146.244 10.6226 146.41 10.6917 146.533 10.8145C146.655 10.9373 146.724 11.1038 146.724 11.2773C146.724 11.4508 146.655 11.6172 146.532 11.7398C146.409 11.8623 146.243 11.9311 146.069 11.931Z"
        fill="white"
      />
      <path
        d="M146.066 15.9468L115.995 15.9188C115.822 15.9186 115.656 15.8495 115.533 15.7267C115.41 15.6039 115.342 15.4374 115.342 15.2639C115.342 15.0904 115.411 14.924 115.534 14.8014C115.657 14.6788 115.823 14.6101 115.997 14.6102L146.067 14.6383C146.24 14.6385 146.407 14.7076 146.529 14.8304C146.652 14.9532 146.721 15.1197 146.721 15.2932C146.72 15.4667 146.651 15.633 146.528 15.7556C146.406 15.8782 146.239 15.947 146.066 15.9468Z"
        fill="white"
      />
      <path
        d="M119.496 19.9379L115.992 19.9346C115.906 19.9345 115.821 19.9175 115.741 19.8846C115.662 19.8516 115.59 19.8034 115.529 19.7426C115.468 19.6818 115.42 19.6096 115.387 19.5302C115.355 19.4508 115.338 19.3657 115.338 19.2798C115.338 19.1938 115.355 19.1088 115.388 19.0294C115.421 18.9501 115.469 18.878 115.53 18.8173C115.591 18.7566 115.663 18.7085 115.742 18.6757C115.822 18.6429 115.907 18.626 115.993 18.6261L119.497 18.6294C119.67 18.6298 119.836 18.699 119.959 18.8218C120.081 18.9446 120.15 19.1109 120.15 19.2842C120.149 19.4576 120.08 19.6238 119.958 19.7463C119.835 19.8689 119.669 19.9378 119.496 19.9379Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1485_2287">
        <rect
          width="180"
          height="120"
          fill="white"
          transform="matrix(-1 0 0 1 180 0)"
        />
      </clipPath>
    </defs>
  </svg>
);
