import { FC } from "react";
import Layout from "../../components/Layout";
import OrderContent from "../../modules/Order/OrderContent";

const Order: FC = () => {
  return (
    <Layout>
      <OrderContent />
    </Layout>
  );
};

export default Order;
