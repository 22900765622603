import Icon, { IconProps } from "@chakra-ui/icon";

export const CustomerModalIcon = (props: IconProps) => (
  <Icon
    width="390px"
    height="259px"
    viewBox="0 0 390 259"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3234_90641)">
      <path
        d="M293.334 10.0062C290.388 11.6575 285.632 5.12595 284.555 8.32081C283.477 11.5157 286.087 24.4067 287.434 27.4984C288.103 29.0352 289.049 30.4407 289.706 31.9828C290.363 33.525 290.715 35.2981 290.134 36.8704C289.124 39.6066 285.79 40.7196 284.137 43.1245C282.211 45.9255 282.989 49.6981 283.889 52.9732C284.665 55.7962 285.492 58.6448 286.985 61.1646C288.479 63.6845 290.73 65.8786 293.543 66.7123C297.126 67.7738 300.954 66.5123 304.407 65.0881C307.727 63.7188 311.012 62.1665 313.835 59.9513C316.659 57.736 319.015 54.7956 320.002 51.3504C321.24 47.0288 320.206 42.2995 318.062 38.3461C315.918 34.3927 312.747 31.0991 309.493 27.9896C304.013 22.7526 298.181 17.8818 292.049 13.4207L293.334 10.0062Z"
        fill="#2F2E41"
      />
      <path
        d="M337.154 231.75L334.372 257.643L334.246 258.827L302.774 258.705L302.608 257.517L299.276 233.79L337.154 231.75Z"
        fill="#FFB6B6"
      />
      <path
        d="M289.034 257.464C289.034 257.855 289.03 258.25 289.027 258.648L256.356 258.522L256.25 257.334L254.235 234.811L288.017 230.727C288.017 230.727 289.106 242.673 289.034 257.464Z"
        fill="#FFB6B6"
      />
      <path
        d="M285.398 55.875C290.588 62.3947 299.737 64.284 307.121 60.4033C317.661 54.8639 332.052 48.5152 334.595 53.5881C338.69 61.756 338.69 69.6826 338.69 69.6826L342.785 164.875C342.785 164.875 344.833 203.31 319.24 199.917C293.647 196.525 253.722 210.819 253.722 210.819L257.8 168.648L268.689 152.748L273.101 107.132L265.494 70.4343L274.708 66.3504C274.708 66.3504 275.732 59.2035 278.803 59.2035C281.874 59.2035 282.616 52.338 285.316 55.7708C285.343 55.8051 285.37 55.8399 285.398 55.875V55.875Z"
        fill="#F7B80B"
      />
      <path
        d="M340.92 258.854L334.245 258.826L302.773 258.704L298.678 258.688L298.44 257.5L292.109 225.624L291.12 257.473L291.084 258.657L289.026 258.649L256.356 258.523L254.014 258.511L254.006 257.327L253.867 234.816L253.741 213.97L253.721 210.817L253.725 210.813L263.507 191.037L266.36 185.267L266.945 184.083L272.66 172.531L324.364 165.392L325.155 165.281L339.203 163.344L339.306 169.086L339.365 172.346L339.575 184.083L339.599 185.267L339.622 186.62L340.897 257.666L340.92 258.854Z"
        fill="#2F2E41"
      />
      <path
        d="M300.694 52.8476C312.501 52.8476 322.073 43.3013 322.073 31.5254C322.073 19.7494 312.501 10.2031 300.694 10.2031C288.886 10.2031 279.314 19.7494 279.314 31.5254C279.314 43.3013 288.886 52.8476 300.694 52.8476Z"
        fill="#FFB6B6"
      />
      <path
        d="M324.795 64.9182C319.805 72.0173 317.253 80.7887 317.658 89.449C312.001 86.7157 308.182 80.9408 306.882 74.8065C305.583 68.6722 306.574 62.2313 308.663 56.3176C308.684 56.2585 308.705 56.1994 308.726 56.1403C311.897 47.264 308.95 37.3435 301.291 31.8257C296.125 28.1038 291.645 23.4345 288.206 18.0822C286.265 15.0605 284.614 11.2701 286.065 7.98576C287.401 4.95938 290.854 3.54489 294.031 2.59844C301.285 0.437292 308.993 -0.792432 316.439 0.575082C323.885 1.9426 331.054 6.21319 334.573 12.899C338.092 19.5848 337.17 28.7092 331.458 33.6656C333.259 34.2465 334.534 36.151 334.381 38.0331C334.228 39.9152 332.662 41.59 330.791 41.8738C332.012 41.8307 332.733 43.4348 332.297 44.5735C331.862 45.7123 330.739 46.4196 329.667 47.0057C328.141 47.8406 326.449 48.7137 326.443 50.9C326.44 51.7767 326.754 52.6275 327.266 53.34C328.507 55.0666 330.51 55.8442 332.439 56.5742C328.36 57.7725 325.107 61.4091 324.376 65.5875L324.795 64.9182Z"
        fill="#2F2E41"
      />
      <path
        d="M233.629 56.6693C233.046 50.5646 235.731 45.3161 239.627 44.9464C243.523 44.5767 247.152 49.2255 247.735 55.3325C248.001 57.7688 247.679 60.2333 246.795 62.5198L248.939 88.4168L236.732 89.1614L235.914 63.5525C234.612 61.473 233.829 59.1128 233.629 56.6693Z"
        fill="#FFB6B6"
      />
      <path
        d="M300.135 68.9541C300.135 68.9541 280.192 57.2545 276.086 59.0095C271.981 60.7644 252.038 90.5983 252.038 90.5983L250.204 85.1539L236.201 86.5035C236.201 86.5035 227.989 124.527 246.172 121.017C264.355 117.507 294.269 84.7485 294.269 84.7485L300.135 68.9541H300.135Z"
        fill="#F7B80B"
      />
      <path
        d="M115.111 131.818C115.111 131.818 126.371 125.234 129.404 131.339C132.436 137.443 160.451 210.271 160.451 210.271L142.398 213.776L115.111 131.817V131.818Z"
        fill="#F7B80B"
      />
      <path
        d="M90.5515 88.3127L91.0822 89.0663C89.1034 90.2056 88.8116 93.08 89.7395 95.1657C90.6674 97.2514 92.4635 98.7973 94.0171 100.473C95.5706 102.148 96.9871 104.224 96.843 106.502C96.6813 109.065 94.5865 111.175 94.2947 113.727C93.8729 117.442 97.3421 120.703 97.1698 124.439C97.0398 127.293 94.8395 129.585 92.6568 131.436C90.9486 132.887 89.0893 134.345 87.9364 136.217C87.6201 136.733 87.3565 137.283 87.1632 137.872C86.3021 140.529 87.114 143.424 88.2071 145.994C89.2967 148.563 90.685 151.073 91.026 153.842C91.3634 156.612 90.316 159.809 87.7607 160.944C92.8501 160.51 97.8166 158.74 102.031 155.862C102.41 155.602 102.779 155.336 103.145 155.062C109.12 150.575 113.317 144.094 117.355 137.777C117.689 137.258 118.02 136.736 118.354 136.217C119.25 134.812 120.15 133.41 121.049 132.004C123.615 127.987 126.213 123.91 127.612 119.36C128.898 115.164 129.116 110.73 129.643 106.372C129.9 104.238 130.237 102.11 130.3 99.9611C130.402 96.6555 129.836 93.3359 128.684 90.2336C127.555 87.1839 125.861 84.3411 123.678 81.9223C121.443 79.444 118.709 77.4284 115.7 75.9772C112.786 74.5715 109.616 73.6987 106.386 73.4568C105.303 73.3762 104.224 73.3622 103.145 73.4112C98.8886 73.5865 94.6814 74.6591 90.5901 75.9772C87.5814 77.4284 84.8469 79.444 82.6115 81.9223C80.4288 84.3411 78.7347 87.1839 77.6064 90.2336C81.529 88.1444 86.1896 87.4258 90.5515 88.3127Z"
        fill="#2F2E41"
      />
      <path
        d="M121.057 126.661L100.875 118.768L100.364 126.661L82.6723 129.423L74.3623 166.915L81.8809 190.2L138.072 191.779L136.094 183.886C136.094 183.886 145.195 173.625 139.655 157.049L139.04 152.831C137.431 141.801 130.79 132.137 121.057 126.661L121.057 126.661Z"
        fill="#F7B80B"
      />
      <path
        d="M109.824 118.413C119.724 118.413 127.749 110.409 127.749 100.536C127.749 90.6622 119.724 82.6582 109.824 82.6582C99.924 82.6582 91.8984 90.6622 91.8984 100.536C91.8984 110.409 99.924 118.413 109.824 118.413Z"
        fill="#A0616A"
      />
      <path
        d="M75.9902 99.9611C76.0535 102.11 76.3909 104.238 76.6475 106.372C77.1747 110.73 77.3926 115.164 78.6791 119.36C80.0779 123.91 82.6754 127.987 85.2412 132.004C86.141 133.41 87.0408 134.812 87.937 136.217C88.271 136.736 88.6013 137.258 88.9353 137.777C92.9738 144.094 97.1704 150.575 103.146 155.062C103.511 155.336 103.88 155.602 104.26 155.862C108.474 158.74 113.44 160.51 118.53 160.944C115.975 159.809 114.927 156.612 115.265 153.843C115.606 151.073 116.994 148.563 118.084 145.994C119.177 143.424 119.989 140.529 119.127 137.872C118.934 137.283 118.67 136.733 118.354 136.217C117.201 134.345 115.342 132.887 113.634 131.436C111.451 129.585 109.251 127.293 109.121 124.439C108.949 120.703 106.555 121.954 106.133 118.238C105.841 115.686 102.429 111.336 102.267 108.774C102.123 106.495 103.525 100.985 105.079 99.3091C106.632 97.6335 105.205 92.6313 106.133 90.5456C107.061 88.4599 117.187 90.2056 115.208 89.0663L115.739 88.3127C120.101 87.4258 124.762 88.1444 128.684 90.2336C129.538 90.6858 130.354 91.2011 131.127 91.783C132.993 88.3828 130.779 84.0676 127.767 81.6174C124.818 79.2126 121.184 77.8245 117.577 76.6011C116.955 76.3908 116.33 76.1805 115.7 75.9772C111.609 74.6591 107.402 73.5865 103.146 73.4112C102.067 73.3622 100.987 73.3762 99.905 73.4568C96.6749 73.6987 93.5045 74.5715 90.5907 75.9772C87.582 77.4284 84.8475 79.444 82.6121 81.9223C80.4294 84.3411 78.7353 87.1839 77.607 90.2336C76.4542 93.3359 75.8883 96.6555 75.9902 99.9611H75.9902Z"
        fill="#2F2E41"
      />
      <path
        d="M388.893 242.874L363.908 190.515C362.044 186.608 358.035 184.082 353.694 184.082H38.1043C36.9052 184.082 35.7379 184.268 34.6377 184.623C31.856 185.511 29.4896 187.452 28.0888 190.12L1.30675 241.039C-0.545168 244.555 -0.422473 248.691 1.63522 252.093C3.68895 255.499 7.29395 257.543 11.2788 257.559L254.014 258.51L256.356 258.522L289.027 258.648L291.084 258.656L298.678 258.688L302.774 258.703L334.245 258.826L340.92 258.853L378.636 258.999H378.684C382.577 258.999 386.143 257.042 388.232 253.762C390.329 250.467 390.575 246.394 388.893 242.874Z"
        fill="white"
      />
      <path
        d="M388.893 242.874L363.908 190.515C362.044 186.608 358.035 184.082 353.694 184.082H38.1043C36.9052 184.082 35.7379 184.268 34.6377 184.623C31.856 185.511 29.4896 187.452 28.0888 190.12L1.30675 241.039C-0.545168 244.555 -0.422473 248.691 1.63522 252.093C3.68895 255.499 7.29395 257.543 11.2788 257.559L254.014 258.51L256.356 258.522L289.027 258.648L291.084 258.656L298.678 258.688L302.774 258.703L334.245 258.826L340.92 258.853L378.636 258.999H378.684C382.577 258.999 386.143 257.042 388.232 253.762C390.329 250.467 390.575 246.394 388.893 242.874ZM387.231 253.127C385.359 256.067 382.17 257.815 378.684 257.815H378.64L340.897 257.665L334.371 257.642L302.608 257.515L298.441 257.5L291.12 257.472L289.034 257.464L256.25 257.334L254.006 257.326L11.2827 256.375C7.71739 256.359 4.4883 254.532 2.65223 251.485C0.8121 248.438 0.701387 244.737 2.35941 241.587L29.1413 190.673C30.1068 188.838 31.5789 187.385 33.3399 186.45C34.7763 185.684 36.4067 185.266 38.1043 185.266H353.694C357.58 185.266 361.165 187.527 362.835 191.024L387.821 243.379C389.328 246.536 389.107 250.179 387.231 253.127Z"
        fill="#3F3D56"
      />
      <path
        d="M112.153 221.969L171.906 200.263L142.03 188.279L82.4746 203.815L112.153 221.969Z"
        fill="#F2F2F2"
      />
      <path
        d="M203.167 189.213L196.439 210.524L275.187 215.26L268.459 191.186L203.167 189.213Z"
        fill="#F2F2F2"
      />
      <path
        d="M110.343 192.858C115.88 193.406 120.085 196.699 119.735 200.213C119.385 203.727 114.613 206.131 109.075 205.582C106.859 205.393 104.721 204.678 102.84 203.496L79.4102 200.881L80.8752 189.941L103.817 193.681C105.895 192.891 108.133 192.609 110.343 192.858H110.343Z"
        fill="#A0616A"
      />
      <path
        d="M86.8272 131.594L82.6722 129.424C82.6722 129.424 76.1429 129.621 71.7901 133.962C67.4372 138.304 32.2187 188.03 32.2187 188.03C32.2187 188.03 23.513 201.448 45.2772 203.422C67.0415 205.395 91.1801 205.395 91.1801 205.395L93.1586 189.214L60.3144 185.662L83.3141 155.274L86.8272 131.594Z"
        fill="#F7B80B"
      />
      <path
        d="M210.302 126.336C206.309 127.516 202.091 128.183 197.734 128.246C197.477 128.254 197.22 128.258 196.962 128.258C193.021 128.258 189.195 127.772 185.534 126.857L185.629 125.921L187.758 105.443L187.893 104.133L185.918 98.2246H210.144L209.756 103.998L209.613 106.102L209.823 106.769L215.165 123.747L215.403 124.505C213.749 125.211 212.047 125.823 210.302 126.336H210.302Z"
        fill="#F7B80B"
      />
      <path
        d="M185.534 126.858C179.784 125.425 174.45 122.931 169.753 119.604C167.818 118.558 165.938 117.426 164.138 116.226L165.028 115.78L187.398 104.623L187.758 105.444L197.39 127.466L197.734 128.247C197.477 128.255 197.219 128.259 196.962 128.259C193.021 128.259 189.194 127.774 185.534 126.858Z"
        fill="#2F2E41"
      />
      <path
        d="M234.27 109.919C234.171 110.053 234.068 110.183 233.966 110.313C232.296 112.429 230.444 114.394 228.441 116.19L228.426 115.136L228.37 111.257C228.37 111.257 229.969 110.092 231.943 109.789C232.414 109.714 232.909 109.69 233.408 109.745C233.696 109.773 233.981 109.828 234.27 109.919Z"
        fill="#2F2E41"
      />
      <path
        d="M228.441 116.189C224.611 119.634 220.211 122.46 215.403 124.504C213.749 125.211 212.047 125.822 210.302 126.335L210.282 125.518L209.823 106.769L209.756 103.998L231.944 109.788L233.123 110.095L233.966 110.313C232.296 112.428 230.444 114.393 228.441 116.189Z"
        fill="#2F2E41"
      />
      <path
        d="M198.259 91.8229C208.074 91.8229 216.03 83.8876 216.03 74.099C216.03 64.3103 208.074 56.375 198.259 56.375C188.444 56.375 180.487 64.3103 180.487 74.099C180.487 83.8876 188.444 91.8229 198.259 91.8229Z"
        fill="#A0616A"
      />
      <path
        d="M210.283 125.519C206.192 126.75 201.867 127.425 197.391 127.465L197.735 128.246C202.092 128.183 206.31 127.516 210.303 126.336L210.283 125.519ZM165.03 115.779C155.908 107.373 150.119 95.4226 149.886 82.1464C140.574 92.8968 149.826 106.694 164.139 116.225C165.94 117.425 167.819 118.557 169.754 119.603C168.096 118.431 166.517 117.152 165.03 115.779ZM233.813 52.0856C225.178 41.2721 211.87 34.3301 196.964 34.3301C171.001 34.3301 149.874 55.3967 149.874 81.294C149.874 81.5781 149.878 81.8623 149.886 82.1464C150.131 81.8583 150.392 81.5742 150.665 81.294C163.724 67.8757 171.436 35.1194 196.964 35.1194C211.886 35.1194 225.182 42.1955 233.654 53.163C239.677 60.9535 243.262 70.7133 243.262 81.294C243.262 82.8292 243.187 84.3486 243.037 85.8443C242.942 86.8389 242.811 87.8255 242.649 88.8003C241.47 95.9633 238.632 102.582 234.545 108.237C234.177 108.75 233.797 109.251 233.409 109.745C233.314 109.863 233.223 109.981 233.124 110.096C231.684 111.892 230.113 113.577 228.427 115.136C224.561 118.719 220.081 121.647 215.167 123.747C213.584 124.43 211.953 125.022 210.283 125.519C206.192 126.75 201.867 127.425 197.391 127.465C197.249 127.469 197.106 127.469 196.964 127.469C193.378 127.469 189.536 126.92 185.63 125.922C180.296 124.56 174.839 122.366 169.754 119.603C174.451 122.93 179.786 125.424 185.535 126.857C189.196 127.772 193.022 128.258 196.964 128.258C197.221 128.258 197.478 128.254 197.735 128.246C202.092 128.183 206.31 127.516 210.303 126.336C212.048 125.823 213.75 125.211 215.404 124.505C220.212 122.46 224.612 119.635 228.443 116.189C230.445 114.394 232.297 112.428 233.967 110.313C234.07 110.183 234.173 110.052 234.272 109.918C234.406 109.745 234.541 109.567 234.671 109.389C239.147 103.442 242.233 96.4014 243.46 88.749C243.618 87.7663 243.745 86.7718 243.84 85.7654C243.982 84.2934 244.054 82.8016 244.054 81.294C244.054 70.2634 240.223 60.1089 233.813 52.0856V52.0856Z"
        fill="#E6E6E6"
      />
      <path
        d="M182.342 83.5272C179.144 80.5185 176.914 76.5303 179.146 68.0287C179.312 67.3958 178.867 66.7164 178.437 66.2222C178.007 65.7281 177.196 65.4984 176.686 65.9096C177.049 64.8423 182.029 61.0301 182.392 59.9627C181.968 60.5243 178.73 61.7282 178.47 61.0743C178.211 60.4205 178.438 59.6858 178.666 59.0204L180.025 55.0538C180.367 54.0561 181.716 55.2481 182.392 54.4376C183.603 52.9855 182.221 51.4549 184.071 51.0478C190.124 49.7154 196.54 48.525 202.421 50.4806C204.989 51.3349 207.325 52.7541 209.636 54.1615L213.864 56.7367C216.29 58.2141 218.925 59.9667 219.589 62.7227L215.149 61.1402C216.138 63.2403 217.136 65.3665 217.588 67.6425C218.041 69.9185 216.817 78.4882 215.632 80.4848C214.591 82.2388 213.854 77.528 213.511 79.5376C213.903 75.5901 212.122 71.4952 208.963 69.0844C208.315 68.5899 207.576 68.1144 207.288 67.3532C206.967 66.5043 207.289 65.4835 206.85 64.6886C206.305 63.7003 204.88 63.6061 203.818 63.994C202.756 64.3819 201.827 65.111 200.727 65.3723C197.809 66.065 195.155 63.314 192.164 63.1071C189.314 62.9101 186.764 65.1351 185.579 67.7276C184.394 70.3201 181.116 69.7589 180.809 72.5917C180.503 75.4245 184.978 81.0306 183.184 83.2474L182.342 83.5272Z"
        fill="#2F2E41"
      />
      <path
        d="M180.098 109.954L178.773 108.924L178.464 108.687C178.167 108.454 177.776 108.375 177.408 108.466L175.856 108.853C175.694 108.892 175.52 108.845 175.401 108.727L173.66 106.99C173.541 106.872 173.494 106.698 173.533 106.536L173.921 104.989C174.016 104.622 173.933 104.236 173.7 103.936L172.43 102.306C172.398 102.266 172.362 102.227 172.327 102.191C171.844 101.71 171.064 101.71 170.581 102.191L169.849 102.921C169.323 103.442 169.094 104.228 169.351 104.954C170.008 106.817 171.076 108.51 172.481 109.903C172.98 110.4 173.514 110.858 174.08 111.268C175.108 112.01 176.24 112.602 177.443 113.024C178.175 113.281 178.959 113.052 179.481 112.531L180.213 111.797C180.74 111.276 180.684 110.412 180.098 109.954ZM179.679 111.268L178.947 111.998C178.603 112.338 178.12 112.464 177.693 112.314C176.683 111.963 175.73 111.481 174.847 110.881C174.19 110.443 173.577 109.934 173.015 109.37C171.693 108.056 170.684 106.461 170.063 104.705C169.913 104.279 170.039 103.797 170.38 103.454L171.116 102.724C171.128 102.708 171.143 102.696 171.159 102.685C171.369 102.523 171.67 102.558 171.832 102.767L173.102 104.397C173.193 104.512 173.225 104.666 173.189 104.808L172.801 106.355C172.695 106.773 172.821 107.219 173.126 107.523L174.867 109.259C175.172 109.567 175.619 109.69 176.038 109.583L177.59 109.196C177.732 109.161 177.886 109.192 178.001 109.283L178.021 109.299L179.635 110.55C179.651 110.562 179.667 110.578 179.679 110.589C179.869 110.779 179.869 111.079 179.679 111.268Z"
        fill="#CCCCCC"
      />
      <path
        d="M195.546 106.387C195.742 106.387 195.901 106.545 195.901 106.74V107.446C195.901 109.006 197.169 110.271 198.733 110.271C200.298 110.271 201.566 109.006 201.566 107.446V106.74C201.566 106.545 201.724 106.387 201.92 106.387C202.115 106.387 202.274 106.545 202.274 106.74V107.446C202.274 109.26 200.896 110.778 199.087 110.96V112.39H201.212C201.407 112.39 201.566 112.548 201.566 112.743C201.566 112.938 201.407 113.096 201.212 113.096H196.255C196.059 113.096 195.901 112.938 195.901 112.743C195.901 112.548 196.059 112.39 196.255 112.39H198.379V110.96C196.57 110.778 195.192 109.26 195.192 107.446V106.74C195.192 106.545 195.351 106.387 195.546 106.387Z"
        fill="#CCCCCC"
      />
      <path
        d="M198.733 101.797C197.562 101.797 196.608 102.744 196.608 103.916V107.448C196.608 108.616 197.562 109.564 198.733 109.564C199.909 109.564 200.858 108.616 200.858 107.448V103.916C200.858 102.744 199.909 101.797 198.733 101.797ZM200.15 107.448C200.15 108.226 199.517 108.861 198.733 108.861C197.95 108.861 197.317 108.226 197.317 107.448V103.916C197.317 103.135 197.95 102.503 198.733 102.503C199.517 102.503 200.15 103.135 200.15 103.916V107.448Z"
        fill="#CCCCCC"
      />
      <path
        d="M223.24 101.935C222.128 101.56 220.921 102.152 220.545 103.261L221.143 103.857C221.182 103.107 221.807 102.519 222.563 102.523C223.343 102.527 223.976 103.162 223.972 103.94V106.679L224.68 107.385V103.94C224.68 103.036 224.099 102.227 223.24 101.935ZM225.036 112.417H222.911V110.984C223.533 110.925 224.126 110.7 224.633 110.333L224.122 109.824C223.675 110.124 223.137 110.298 222.555 110.298C220.992 110.298 219.722 109.031 219.722 107.472V106.765C219.722 106.702 219.706 106.639 219.671 106.588C219.611 106.485 219.5 106.414 219.37 106.414C219.306 106.414 219.247 106.43 219.196 106.462C219.089 106.521 219.014 106.635 219.014 106.765V107.472C219.014 109.283 220.395 110.803 222.203 110.984V112.417H220.078C219.88 112.417 219.722 112.575 219.722 112.768C219.722 112.965 219.88 113.123 220.078 113.123H225.036C225.23 113.123 225.389 112.965 225.389 112.768C225.389 112.575 225.23 112.417 225.036 112.417ZM225.745 106.414C225.547 106.414 225.389 106.572 225.389 106.765V107.472C225.389 107.661 225.369 107.851 225.333 108.036L225.369 108.072L225.907 108.612C225.947 108.49 225.982 108.368 226.01 108.241C226.069 107.993 226.097 107.736 226.097 107.472V106.765C226.097 106.572 225.939 106.414 225.745 106.414V106.414Z"
        fill="#CCCCCC"
      />
      <path
        d="M224.015 107.72L218.558 102.277L218.056 102.779L222.654 107.364L226.556 111.252L227.054 110.751L224.015 107.72ZM223.077 108.785C222.353 109.073 221.53 108.718 221.241 107.992C221.174 107.826 221.138 107.649 221.138 107.471V106.851L220.43 106.145V107.471C220.43 108.639 221.384 109.59 222.555 109.59C222.923 109.59 223.287 109.496 223.607 109.314L223.077 108.785Z"
        fill="#CCCCCC"
      />
      <path
        d="M318.529 184.863C313.831 188.342 308.224 188.744 306.004 185.762C303.785 182.781 305.794 177.544 310.494 174.065C312.352 172.649 314.523 171.698 316.825 171.29L336.915 156.801L343.532 166.348L323.025 179.619C321.967 181.7 320.426 183.498 318.529 184.863Z"
        fill="#FFB6B6"
      />
      <path
        d="M331.185 66.8613C331.185 66.8613 347.391 67.3718 350.463 78.6026C353.534 89.8333 359.676 113.771 358.652 147.008C358.652 147.008 363.771 168.449 350.463 167.428L332.036 175.595L319.751 159.26L329.988 147.008L331.185 66.8613Z"
        fill="#F7B80B"
      />
    </g>
    <defs>
      <clipPath id="clip0_3234_90641">
        <rect width="390" height="259" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
