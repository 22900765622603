export default function ScarfPersonIcon() {
  return (
    <svg
      width="143"
      height="285"
      viewBox="0 0 143 285"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.3403 274.605H27.5918L24.8563 252.432H33.3408L33.3403 274.605Z"
        fill="#9E616A"
      />
      <path
        d="M35.2169 270.667L26.0328 270.119V274.054L17.3012 280.084C16.8699 280.382 16.5451 280.81 16.3742 281.305C16.2033 281.801 16.1952 282.338 16.3512 282.838C16.5072 283.339 16.819 283.776 17.2412 284.087C17.6633 284.397 18.1737 284.565 18.6978 284.565H29.6319L31.5166 280.673L32.2524 284.565H36.375L35.2169 270.667Z"
        fill="#1A202C"
      />
      <path
        d="M105.39 269.72L100.049 271.846L89.3063 252.257L97.1889 249.119L105.39 269.72Z"
        fill="#9E616A"
      />
      <path
        d="M105.678 265.371L96.942 268.258L98.3973 271.914L92.5151 280.746C92.2246 281.183 92.081 281.7 92.1055 282.224C92.1299 282.747 92.3211 283.249 92.6511 283.657C92.9811 284.064 93.4325 284.355 93.9396 284.487C94.4467 284.62 94.9829 284.587 95.4698 284.393L105.629 280.349L105.94 276.036L108.063 279.38L111.894 277.855L105.678 265.371Z"
        fill="#1A202C"
      />
      <path
        d="M44.9258 208.625L54.5118 163.89L69.4806 213.594L93.1831 260.727L93.3086 260.977L100.817 256.934C101.876 237.796 99.3598 219.863 90.1553 204.181L85.7968 161.138C85.6632 159.668 82.412 125.14 72.1172 119.017L69.1023 112.142L39.1159 104.736L39.0068 104.859C36.1233 108.1 34.2185 112.093 33.5138 116.373L24.5536 170.654L24.5485 170.685L26.951 208.552L23.6647 264.418H35.5558C42.9121 250.577 46.0354 231.979 44.9258 208.625Z"
        fill="#1A202C"
      />
      <path
        d="M77.9406 126.703C78.7482 126.703 79.2069 126.545 79.3845 126.205C79.6451 125.707 79.1929 125.056 78.6693 124.301C78.304 123.776 77.2372 122.731 77.7618 122.667C85.7165 121.695 81.898 72.6519 78.4507 66.1692C77.7502 64.852 78.4913 62.831 77.7026 60.5958C75.5479 54.4911 72.6069 52.615 66.7479 48.8778C66.2723 48.5744 65.7771 48.2575 65.2621 47.9271C64.5961 47.4991 64.6332 46.0799 64.6724 44.5771C64.716 42.9068 64.7611 41.1795 63.9429 40.0999L63.8896 40.0295L63.8038 40.0095C61.8246 39.552 55.1681 38.7026 51.226 40.0157L51.1715 40.0343L51.1308 40.0747C50.1944 41.0111 50.1091 42.825 50.0265 44.579C49.959 46.0191 49.895 47.3788 49.3652 47.9694C45.3149 52.4842 40.7212 52.3962 40.6746 52.3952L40.447 52.3886L40.4244 52.615C37.5847 58.0199 39.8081 78.0932 37.8343 90.5621C35.829 103.23 18.7031 114.013 33.3675 115.928C33.3359 115.976 33.3183 116.033 33.3165 116.091C33.3148 116.149 33.3289 116.206 33.3575 116.257C34.2435 117.912 74.8969 126.703 77.9406 126.703Z"
        fill="#F7B80B"
      />
      <path
        d="M44.9873 43.2293C45.4074 42.4622 46.1305 40.2685 46.9416 40.2978C47.7712 40.3342 48.6021 40.2636 49.4137 40.0878C49.5017 40.0731 49.5896 40.0536 49.6727 40.034C53.0989 39.179 56.6119 38.72 60.1428 38.666C62.9912 38.6269 66.1181 38.9689 68.1066 41.0063C70.0951 43.0436 69.8019 47.2453 67.061 48.0222C76.2207 49.6082 85.6365 47.521 93.2681 42.213C98.1196 38.8028 102.009 34.2346 106.122 29.9645C110.236 25.6895 114.77 21.5806 120.316 19.4895C125.866 17.3935 132.652 17.6866 137.122 21.5903C142.575 26.349 142.995 34.5375 143 41.7733C143.005 44.48 142.16 43.9084 139.771 45.1835C137.552 46.3659 135.222 48.799 133.869 46.6835C132.515 44.568 132.276 41.9541 131.997 39.4575C131.724 36.9609 131.318 34.3323 129.735 32.3829C127.014 29.0313 121.654 28.9971 117.897 31.1224C114.14 33.2477 111.614 36.9657 109.225 40.5616C106.836 44.1527 104.315 47.8805 100.562 50.0204C95.6328 52.8297 89.4426 52.4144 84.2979 54.8085C81.0293 56.3328 78.3861 58.888 75.6746 61.2576C72.9581 63.6272 69.9827 65.9088 66.4601 66.6759C62.9423 67.4479 58.765 66.285 57.0355 63.124C55.4769 60.2658 56.2391 56.6064 54.8516 53.6554C53.7816 51.3787 51.5977 49.869 49.6287 48.2958C48.8177 47.646 48.6076 49.8836 47.9187 49.0921C45.9302 48.6964 44.0638 44.9149 44.9873 43.2293Z"
        fill="#3F3D56"
      />
      <path
        d="M58.688 39.1845C50.1618 39.1845 43.25 32.2727 43.25 23.7466C43.25 15.2204 50.1618 8.30859 58.688 8.30859C67.2142 8.30859 74.126 15.2204 74.126 23.7466C74.126 32.2727 67.2142 39.1845 58.688 39.1845Z"
        fill="#1A202C"
      />
      <path
        d="M57.4426 40.4606C51.3178 40.2871 46.4932 35.1812 46.6668 29.0564C46.8403 22.9315 51.9462 18.107 58.0711 18.2805C64.1959 18.454 69.0205 23.5599 68.8469 29.6848C68.6734 35.8097 63.5675 40.6342 57.4426 40.4606Z"
        fill="#9E616A"
      />
      <path
        d="M57.6496 12.4503L62.147 12.5778C64.9813 12.6604 67.667 13.8641 69.6147 15.9247C71.5624 17.9853 72.6131 20.7344 72.5361 23.5688L72.5333 23.6684L72.4513 23.7198C70.0068 25.3372 67.1214 26.1581 64.1916 26.0698L63.9388 26.0627L61.4537 25.9501C61.0358 24.8738 60.896 23.7094 61.0474 22.5647C60.6721 23.6498 60.3791 24.7616 60.171 25.8907L49.021 25.3831C48.3722 25.351 47.7617 25.0666 47.3196 24.5906C46.8776 24.1147 46.639 23.4849 46.6548 22.8355C46.7384 20.0013 47.9431 17.316 50.0044 15.369C52.0657 13.422 54.8152 12.3723 57.6496 12.4503Z"
        fill="#1A202C"
      />
      <path
        d="M66.6121 9.66658C67.6271 10.9184 69.2571 11.44 70.7216 12.1179C73.3056 13.3399 75.5525 15.1738 77.2675 17.4605C78.9825 19.7472 80.1138 22.4177 80.5634 25.2405C80.9085 27.5391 80.7878 29.905 81.372 32.153C81.9524 34.4008 83.4841 36.6334 85.7601 37.0851C87.3003 37.3933 88.8687 36.8396 90.3412 36.2909C91.151 35.9918 91.9608 35.6914 92.7706 35.3897C91.9948 33.2706 91.2204 31.1515 90.4472 29.0324C91.7292 31.0479 93.2338 32.9129 94.9325 34.5922C97.8346 33.5166 100.739 32.441 103.646 31.3656C96.5739 31.192 97.9752 24.3613 96.5882 17.4286C95.8259 13.6191 88.4934 10.8464 87.1435 7.20421C86.4144 5.22881 85.1345 3.50366 83.4555 2.23294C81.7764 0.962219 79.7685 0.199117 77.6693 0.033957C75.5701 -0.131203 73.4675 0.308495 71.6104 1.301C69.7532 2.29351 68.2194 3.79727 67.1902 5.63433C65.7219 6.17165 65.6157 8.43068 66.6121 9.66658Z"
        fill="#1A202C"
      />
      <path
        d="M65.9086 14.2589C62.7338 14.2589 60.16 11.6852 60.16 8.51032C60.16 5.33545 62.7338 2.76172 65.9086 2.76172C69.0835 2.76172 71.6572 5.33545 71.6572 8.51032C71.6572 11.6852 69.0835 14.2589 65.9086 14.2589Z"
        fill="#1A202C"
      />
      <path
        d="M6.6055 133.804C7.13821 133.507 7.59932 133.097 7.95637 132.603C8.31341 132.108 8.55771 131.541 8.67204 130.942C8.78637 130.343 8.76796 129.726 8.6181 129.135C8.46824 128.544 8.19057 127.993 7.80467 127.521L15.5751 114.895L7.87503 115.136L1.67657 127.088C0.926206 127.789 0.459 128.741 0.363482 129.763C0.267964 130.786 0.550765 131.808 1.1583 132.635C1.76584 133.463 2.65591 134.039 3.65985 134.255C4.6638 134.47 5.71188 134.31 6.6055 133.804Z"
        fill="#9E616A"
      />
      <path
        d="M42.0866 81.8613L36.9871 88.0414C36.9871 88.0414 35.827 95.5225 33.3369 96.4477C30.8468 97.3729 31.6347 97.6331 31.2444 98.8149C30.854 99.9967 27.1994 100.308 27.4496 102.368C28.0124 107 12.0241 127.179 10.4562 124.717L3.3654 122.375C4.19927 120.619 4.81115 118.767 5.18689 116.86C5.70371 113.972 9.62136 107.402 10.7996 106.48C11.9777 105.559 11.7103 103.723 12.4909 101.359C13.2716 98.9956 17.226 98.4863 16.5387 94.3949C15.5717 88.6382 31.9282 60.6593 33.4924 57.6145C35.209 54.273 39.6435 52.2188 39.6435 52.2188L43.5829 53.5198L42.0866 81.8613Z"
        fill="#F7B80B"
      />
      <path
        d="M121.03 34.2854C121.196 34.8723 121.231 35.4884 121.134 36.0904C121.036 36.6924 120.807 37.2656 120.464 37.7697C120.121 38.2738 119.671 38.6965 119.147 39.0081C118.623 39.3196 118.036 39.5124 117.429 39.5729L113.982 53.9915L108.713 48.371L112.795 35.5405C112.761 34.5143 113.104 33.5112 113.761 32.7215C114.417 31.9317 115.34 31.41 116.355 31.2553C117.37 31.1006 118.407 31.3236 119.268 31.8821C120.13 32.4406 120.757 33.2957 121.03 34.2854L121.03 34.2854Z"
        fill="#9E616A"
      />
      <path
        d="M107.73 82.9206L98.6608 83.4741C81.8684 82.9241 74.8879 78.3838 71.9849 74.6719C68.8874 70.7113 69.6429 66.9632 69.6769 66.806L69.7041 66.7346L73.7224 59.7984C74.2329 58.9173 74.9248 58.1549 75.7522 57.5615C76.5797 56.9682 77.5238 56.5575 78.522 56.3567C79.5203 56.1559 80.5498 56.1696 81.5423 56.3969C82.5348 56.6241 83.4677 57.0598 84.2791 57.6749L99.6766 69.3485L102.898 60.8416L104.853 58.1145L105.142 53.918L108.765 46.7748L115.593 47.8729L117.175 62.2394L114.654 64.9558L115.079 67.017L115.045 67.0901L107.73 82.9206Z"
        fill="#F7B80B"
      />
    </svg>
  );
}
