import { FC } from "react";
import Layout from "../../components/Layout";
import RecapContent from "../../modules/Recapitulatif/RecapContent";
// import {
//   generateSummaryDressCode,
//   generateSummaryOnboarding,
//   useOnboardingContext,
// } from "../../context/OnboardingContext";
// import { useEffect } from "react";

const Recapitulatif: FC = () => {
  // const { getAllInfo } = useOnboardingContext();

  // useEffect(() => {
  //   const info = getAllInfo();
  //   console.log(
  //     `=== Recapitulatif ===\n\n${generateSummaryOnboarding(
  //       info
  //     )}\n\n${generateSummaryDressCode(info.dressCode)}`
  //   );
  // }, [getAllInfo]);

  return (
    <Layout>
      <RecapContent />
    </Layout>
  );
};

export default Recapitulatif;
