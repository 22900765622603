import {
  OnboardingInfo,
  OnboardingSetters,
} from "../../context/OnboardingContext";
import { OptionInformation } from "../../context/OptionsContext";
import { UserInformation } from "../../context/UserContext";
import { PageURL } from "../../types/pages";
import { EventSubCategory } from "./EventTypes";
import { preselectSalonB2B, preselectSalonPublic } from "./onboardingConfig";

export interface OnboardingMultichoiceConfig {
  id: EventSubCategory;
  title: string;
  subtitle: string;
  options: string[];
  question: string;
  getOptionValue: (option: OnboardingInfo) => string | undefined;
  setOptionValue: (option: string, setters: OnboardingSetters) => void;
  onNextPage: (
    onboardingInfo: OnboardingInfo,
    userInfo: UserInformation,
    setUserInfos: (userInfos: UserInformation) => void,
    optionsInfo: OptionInformation,
    setOptionsInfo: (optionsInfo: OptionInformation) => void
  ) => string;
}
export const IDK = "Je ne sais pas";

export const STAND_0_50 = "0 - 50 m²";
export const STAND_50_100 = "50 - 100 m²";
export const STAND_100_200 = "100 - 200 m²";
export const STAND_200_300 = "200 - 300 m²";
export const STAND_300_400 = "300 - 400 m²";
export const STAND_300_450 = "300 - 450 m²";
export const STAND_450_600 = "450 - 600 m²";
export const STAND_600_800 = "600 - 800 m²";
export const STAND_OVER_400 = "> 400 m²";

export const SHIFT_0_50 = "0-50";
export const SHIFT_50_100 = "50-100";
export const SHIFT_100_150 = "100-150";
export const SHIFT_100_200 = "100-200";
export const SHIFT_100_250 = "100-250";
export const SHIFT_100_300 = "100-300";
export const SHIFT_150_250 = "150-250";
export const SHIFT_200_300 = "200-300";
export const SHIFT_200_400 = "200-400";
export const SHIFT_250_300 = "250-300";
export const SHIFT_250_350 = "250-350";
export const SHIFT_250_500 = "250-500";
export const SHIFT_300_400 = "300-400";
export const SHIFT_300_600 = "300-600";
export const SHIFT_350_500 = "350-500";
export const SHIFT_400_500 = "400-500";
export const SHIFT_400_550 = "400-550";
export const SHIFT_400_600 = "400-600";
export const SHIFT_400_700 = "400-700";
export const SHIFT_500_600 = "500-600";
export const SHIFT_500_650 = "500-650";
export const SHIFT_500_700 = "500-700";
export const SHIFT_500_800 = "500-800";
export const SHIFT_550_750 = "550-750";
export const SHIFT_600_700 = "600-700";
export const SHIFT_600_1000 = "600-1000";
export const SHIFT_650_800 = "650-800";
export const SHIFT_700_800 = "700-800";
export const SHIFT_700_900 = "700-900";
export const SHIFT_800_1500 = "800-1500";
export const SHIFT_800_900 = "800-900";
export const SHIFT_800_1200 = "800-1200";
export const SHIFT_900_1200 = "900-1200";
export const SHIFT_900_1500 = "900-1500";
export const SHIFT_1000_2000 = "1000-2000";
export const SHIFT_1200_1500 = "1200-1500";
export const SHIFT_1500_2000 = "1500-2000";
export const SHIFT_2000_3000 = "2000-3000";

export const OVER_600 = "> 600";
export const OVER_700 = "> 700";
export const OVER_750 = "> 750";
export const OVER_800 = "> 800";
export const OVER_1200 = "> 1200";
export const OVER_1500 = "> 1500";
export const OVER_3000 = "> 3000";

export const ONBOARDING_MULTICHOICE_CONFIGS: OnboardingMultichoiceConfig[] = [
  {
    id: EventSubCategory.SALON_PRO,
    options: [
      STAND_0_50,
      STAND_50_100,
      STAND_100_200,
      STAND_200_300,
      STAND_300_400,
      STAND_OVER_400,
    ],
    question: "TAILLE DU STAND",
    subtitle:
      "Précisez la taille de votre stand, cela nous permettra de mieux vous conseiller sur le personnel\n" +
      "nécessaire au bon fonctionnement de votre événement.",
    title: "Quelle est la taille de votre stand ?",
    onNextPage: preselectSalonB2B,
    getOptionValue(option) {
      return option.options.salonSize;
    },
    setOptionValue(option, setters) {
      setters.setSalonSize(option);
    },
  },
  {
    id: EventSubCategory.SOIREE_PRESSE,
    options: [
      IDK,
      SHIFT_50_100,
      SHIFT_100_150,
      SHIFT_150_250,
      SHIFT_250_300,
      SHIFT_300_400,
      SHIFT_400_550,
      SHIFT_550_750,
      OVER_750,
    ],
    title: "Combien de personnes sont attendues sur l’événement ?",
    subtitle:
      "Précisez-nous le nombre de personnes attendus dans la soirée presse afin que nous puissions vous conseiller.",
    question: "Nombre d'invités prévus par jour",
    onNextPage() {
      return PageURL.ONBOARDING_TRANSPORT;
    },
    getOptionValue(option) {
      return option.options.nbParticipants;
    },
    setOptionValue(option, setters) {
      setters.setNbParticipants(option);
    },
  },
  {
    id: EventSubCategory.CONGRES,
    options: [
      IDK,
      SHIFT_50_100,
      SHIFT_100_200,
      SHIFT_200_300,
      SHIFT_300_400,
      SHIFT_400_500,
      SHIFT_500_700,
      SHIFT_700_900,
      SHIFT_900_1200,
      SHIFT_1200_1500,
      OVER_1500,
    ],
    title: "Combien de personnes sont attendues sur l’événement ?",
    subtitle:
      "Précisez-nous le  attendus dans le congrès afin que nous puissions vous conseiller.",
    question: "Nombre d'invités prévus par jour",
    onNextPage() {
      return PageURL.ONBOARDING_TRANSPORT;
    },
    getOptionValue(option) {
      return option.options.nbParticipants;
    },
    setOptionValue(option, setters) {
      setters.setNbParticipants(option);
    },
  },
  {
    id: EventSubCategory.REMISE_DE_PRIX,
    options: [
      SHIFT_50_100,
      SHIFT_100_150,
      SHIFT_150_250,
      SHIFT_250_350,
      SHIFT_350_500,
      SHIFT_500_650,
      SHIFT_650_800,
      OVER_800,
    ],
    title: "Combien de personnes sont attendues sur l’événement ?",
    subtitle:
      "Précisez-nous le nombre de personnes attendus pour la remise de prix afin que nous puissions vous conseiller",
    question: "Nombre d'invités prévus par jour",
    onNextPage() {
      return PageURL.ONBOARDING_TRANSPORT;
    },
    getOptionValue(option) {
      return option.options.nbParticipants;
    },
    setOptionValue(option, setters) {
      setters.setNbParticipants(option);
    },
  },
  {
    id: EventSubCategory.CINEMA,
    options: [
      IDK,
      SHIFT_0_50,
      SHIFT_100_200,
      SHIFT_200_400,
      SHIFT_400_600,
      OVER_600,
    ],
    title: "Combien de personnes sont attendues sur l’événement ?",
    subtitle:
      "Précisez-nous le nombre de personnes attendus pour l'avant première afin que nous puissions vous conseiller.",
    question: "Nombre d'invités prévus par jour",
    onNextPage() {
      return PageURL.ONBOARDING_TRANSPORT;
    },
    getOptionValue(option) {
      return option.options.nbParticipants;
    },
    setOptionValue(option, setters) {
      setters.setNbParticipants(option);
    },
  },
  {
    id: EventSubCategory.AUTRE_PRO,
    options: [
      IDK,
      SHIFT_50_100,
      SHIFT_100_200,
      SHIFT_200_300,
      SHIFT_300_400,
      SHIFT_400_700,
      OVER_700,
    ],
    title: "Combien de personnes sont attendues sur l’événement ?",
    subtitle:
      "Précisez-nous le nombre de personnes attendus pour votre événement afin que nous puissions vous conseiller.",
    question: "Nombre d'invités prévus par jour",
    onNextPage() {
      return PageURL.ONBOARDING_TRANSPORT;
    },
    getOptionValue(option) {
      return option.options.nbParticipants;
    },
    setOptionValue(option, setters) {
      setters.setNbParticipants(option);
    },
  },
  {
    id: EventSubCategory.INAUGURATION,
    options: [
      IDK,
      SHIFT_50_100,
      SHIFT_100_200,
      SHIFT_200_300,
      SHIFT_300_400,
      SHIFT_400_500,
      SHIFT_500_600,
      SHIFT_600_700,
      SHIFT_700_800,
      SHIFT_800_900,
      SHIFT_900_1500,
      OVER_1500,
    ],
    title: "Combien de personnes sont attendues sur l’événement ?",
    subtitle:
      "Précisez-nous le nombre de personnes attendus pour votre inauguration afin que nous puissions vous conseiller.",
    question: "Nombre d'invités prévus par jour",
    onNextPage() {
      return PageURL.ONBOARDING_TRANSPORT;
    },
    getOptionValue(option) {
      return option.options.nbParticipants;
    },
    setOptionValue(option, setters) {
      setters.setNbParticipants(option);
    },
  },
  {
    id: EventSubCategory.VILLAGE_EVENEMENTIEL,
    options: [
      IDK,
      SHIFT_300_600,
      SHIFT_600_1000,
      SHIFT_1000_2000,
      SHIFT_2000_3000,
      OVER_3000,
    ],
    title: "Combien de personnes sont attendues sur l’événement ?",
    subtitle:
      "Précisez-nous le nombre de personnes attendus dans le village événementiel afin que nous puissions vous conseiller.",
    question: "Nombre d'invités prévus par jour",
    onNextPage() {
      return PageURL.ONBOARDING_EVENT_OPTIONS;
    },
    getOptionValue(option) {
      return option.options.nbParticipants;
    },
    setOptionValue(option, setters) {
      setters.setNbParticipants(option);
    },
  },
  {
    id: EventSubCategory.SALON_PUBLIC,
    options: [
      IDK,
      STAND_0_50,
      STAND_50_100,
      STAND_100_200,
      STAND_200_300,
      STAND_300_450,
      STAND_450_600,
      STAND_600_800,
    ],
    question: "TAILLE DU STAND",
    subtitle:
      "Précisez la taille de votre stand, cela nous permettra de mieux vous conseiller sur le personnel\n" +
      "nécessaire au bon fonctionnement de votre événement.",
    title: "Quelle est la taille de votre stand ?",
    onNextPage: preselectSalonPublic,
    getOptionValue(option) {
      return option.options.salonSize;
    },
    setOptionValue(option, setters) {
      setters.setSalonSize(option);
    },
  },
  {
    id: EventSubCategory.EVENEMENT_SPORTIF,
    options: [
      IDK,
      SHIFT_0_50,
      SHIFT_50_100,
      SHIFT_100_250,
      SHIFT_250_500,
      SHIFT_500_800,
      SHIFT_800_1500,
      SHIFT_1500_2000,
      SHIFT_2000_3000,
      OVER_3000,
    ],
    title: "Combien de personnes sont attendues sur l’événement ?",
    subtitle:
      "Précisez-nous le nombre de personnes attendus pour votre événement sportif afin que nous puissions vous conseiller.",
    question: "Nombre d'invités prévus par jour",
    onNextPage() {
      return PageURL.ONBOARDING_TRANSPORT;
    },
    getOptionValue(option) {
      return option.options.nbParticipants;
    },
    setOptionValue(option, setters) {
      setters.setNbParticipants(option);
    },
  },
  {
    id: EventSubCategory.AUTRE_PUBLIC,
    options: [
      IDK,
      SHIFT_50_100,
      SHIFT_100_200,
      SHIFT_200_300,
      SHIFT_300_400,
      SHIFT_400_700,
      SHIFT_700_800,
      SHIFT_800_1200,
      OVER_1200,
    ],
    title: "Combien de personnes sont attendues sur l’événement ?",
    subtitle:
      "Précisez-nous le nombre de personnes attendus pour votre événement afin que nous puissions vous conseiller.",
    question: "Nombre d'invités prévus par jour",
    onNextPage() {
      return PageURL.ONBOARDING_TRANSPORT;
    },
    getOptionValue(option) {
      return option.options.nbParticipants;
    },
    setOptionValue(option, setters) {
      setters.setNbParticipants(option);
    },
  },
];
