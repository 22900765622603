import { Icon, IconProps } from "@chakra-ui/react";

export const DownCrossIcon = (props: IconProps) => (
  <Icon
    width="41"
    height="42"
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.6142 0.969633L23.3865 0.969634L23.3865 17.9994L33.6895 17.9994L17.0003 34.6885L0.311208 17.9993L10.6142 17.9993L10.6142 0.969633Z"
      fill="#033349"
    />
  </Icon>
);
