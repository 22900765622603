export const DrinkIcon = () => (
  <svg width="126" height="101" viewBox="0 0 126 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1063_5643)">
      <path d="M114.074 100.139L114.066 100.069C113.947 98.8966 114.084 97.7125 114.469 96.5986C114.853 95.4848 115.475 94.4681 116.292 93.6191C116.755 93.1465 117.274 92.734 117.839 92.3913L117.893 92.3585L117.911 92.419C118.122 93.1416 118.404 93.8706 118.532 94.1937L118.817 91.8794L118.878 91.8527C119.16 91.729 119.472 91.6925 119.774 91.7477C120.077 91.803 120.356 91.9475 120.575 92.1627C120.803 92.3769 120.961 92.6544 121.03 92.9594C121.098 93.2644 121.074 93.583 120.96 93.8741C120.858 94.1407 120.76 94.4178 120.666 94.6858C120.34 95.6068 120.004 96.559 119.435 97.3386C118.998 97.9245 118.437 98.4055 117.791 98.7464C117.144 99.0874 116.43 99.2797 115.7 99.3094L114.074 100.139Z" fill="#F2F2F2"/>
      <path d="M55.5999 99.3094C54.87 99.2797 54.1559 99.0874 53.5098 98.7464C52.8636 98.4055 52.3019 97.9245 51.8654 97.3386C51.2965 96.559 50.96 95.6067 50.6346 94.6858C50.5398 94.4178 50.4419 94.1407 50.3403 93.8741C50.2263 93.583 50.202 93.2644 50.2706 92.9594C50.3392 92.6544 50.4976 92.3769 50.7253 92.1627C50.9448 91.9475 51.2237 91.803 51.5261 91.7477C51.8285 91.6925 52.1405 91.729 52.4219 91.8527L52.4833 91.8794L52.7679 94.1937C52.8967 93.8706 53.1779 93.1416 53.3893 92.419L53.407 92.3584L53.4609 92.3913C54.026 92.734 54.5458 93.1465 55.0079 93.6191C55.825 94.4681 56.4473 95.4848 56.8318 96.5986C57.2162 97.7125 57.3535 98.8966 57.2341 100.069L57.2266 100.139L55.5999 99.3094Z" fill="#F2F2F2"/>
      <path d="M37.0762 51.8893C37.0762 51.8893 36.3205 45.78 34.6178 43.9185L30.6104 45.4706L33.7747 53.545L40.1777 61.6218C40.2099 61.9214 40.3209 62.2071 40.4992 62.4499C40.6776 62.6928 40.917 62.8841 41.1932 63.0045C41.4694 63.1249 41.7726 63.17 42.0719 63.1354C42.3712 63.1007 42.656 62.9875 42.8974 62.8072C43.1388 62.6269 43.3282 62.3859 43.4463 62.1087C43.5645 61.8315 43.6072 61.528 43.5701 61.229C43.5331 60.93 43.4176 60.6461 43.2353 60.4062C43.0531 60.1663 42.8106 59.9788 42.5325 59.8629L37.0762 51.8893Z" fill="#A0616A"/>
      <path d="M16.6503 55.2941C16.6503 55.2941 19.3914 49.7822 18.9988 47.2901L14.8003 46.373L12.9834 54.8529L13.866 65.122C13.7276 65.3896 13.6624 65.6891 13.6771 65.99C13.6919 66.291 13.786 66.5827 13.9499 66.8355C14.1138 67.0883 14.3417 67.2933 14.6104 67.4295C14.8791 67.5658 15.1791 67.6285 15.48 67.6114C15.7808 67.5942 16.0717 67.4978 16.3232 67.3318C16.5747 67.1659 16.7778 66.9364 16.9119 66.6665C17.046 66.3967 17.1063 66.0962 17.0867 65.7956C17.0671 65.4949 16.9684 65.2048 16.8004 64.9546L16.6503 55.2941Z" fill="#A0616A"/>
      <path d="M21.4994 97.5315H23.4856L24.4305 89.8701L21.499 89.8703L21.4994 97.5315Z" fill="#9E616A"/>
      <path d="M21.4288 99.6034L27.5367 99.6032V99.526C27.5366 98.8955 27.2861 98.2908 26.8403 97.845C26.3945 97.3992 25.7898 97.1487 25.1593 97.1487H25.1592L24.0435 96.3022L21.9619 97.1488L21.4287 97.1488L21.4288 99.6034Z" fill="#2F2E41"/>
      <path d="M26.9404 96.4074L28.8778 96.8451L31.4883 89.5806L28.6288 88.9346L26.9404 96.4074Z" fill="#9E616A"/>
      <path d="M26.416 98.4132L32.3737 99.7593L32.3907 99.684C32.5297 99.069 32.4186 98.424 32.082 97.8908C31.7454 97.3577 31.2109 96.9801 30.5959 96.8411L30.5957 96.841L29.6941 95.7695L27.4771 96.1364L26.957 96.0189L26.416 98.4132Z" fill="#2F2E41"/>
      <path d="M35.888 76.6539C34.7085 80.4116 31.6915 90.3857 31.6915 90.3857C31.6915 90.3857 29.1236 91.0267 28.3135 90.3857C27.5034 89.7446 28.5122 87.6038 28.5122 87.6038C27.9782 85.2054 29.1799 82.7814 29.2579 80.4562C29.0959 79.4804 29.9548 78.6427 30.0334 77.712C29.4656 77.2799 26.7035 70.4997 26.1402 68.6051C26.4297 74.3781 25.7304 83.0337 24.9356 88.5973C24.5594 91.2309 24.0348 90.4613 21.359 90.5844C20.1658 90.23 20.3654 87.6038 20.3654 87.6038V85.4181C19.7855 82.9705 19.7778 82.4418 19.968 81.2454C19.8809 79.9754 18.7877 66.8447 17.8177 62.4005C17.1712 58.8603 25.0938 58.081 27.3723 57.2368C31.1743 55.4051 30.5326 58.0092 32.5348 60.4082C34.4654 64.5435 35.6026 71.9278 35.888 76.6539Z" fill="#2F2E41"/>
      <path d="M28.2598 38.2643L26.3376 35.5166H22.9596L19.9791 38.2984L19.7285 63.0144L33.2164 64.5265L28.2598 38.2643Z" fill="#F7B80B"/>
      <path d="M26.9238 36.1621L32.2758 38.6114L33.2926 54.393C33.2926 54.393 34.1508 56.4201 34.2191 56.6198C34.2908 56.8292 34.4337 57.8628 34.3718 58.0347C34.1821 58.5894 34.086 59.1718 34.0874 59.758C34.1338 60.2079 34.6143 61.0108 34.7399 61.4452C34.8265 61.7447 34.2861 62.7385 34.2861 62.7385C34.5079 63.0723 34.6536 63.4506 34.713 63.8469C34.7724 64.2431 34.7441 64.6476 34.63 65.0317L34.4848 65.5204L31.703 65.123L30.5036 59.2261L30.377 58.6036L30.246 57.9596L30.1437 57.4563L30.0527 57.0088L29.9763 56.6333L26.9238 36.1621Z" fill="#3F3D56"/>
      <path d="M28.8975 39.4061L32.2754 38.6113C33.9052 40.5067 35.2294 44.2446 36.2737 47.6501C36.348 47.8925 36.2897 48.2273 36.4924 48.3714C36.8607 48.6333 37.0804 48.9493 36.814 49.4563C36.7123 49.6501 36.9362 49.8732 36.9955 50.0763C37.0454 50.2474 36.9776 50.5322 37.1428 50.5814C37.6155 50.7221 37.6381 51.1261 37.4532 51.6384L33.4792 53.6254L33.3659 53.4425L32.9196 52.7217L30.9989 49.6199L28.8975 39.4061Z" fill="#3F3D56"/>
      <path d="M22.1648 36.311L19.161 39.0087C19.161 39.0087 18.4581 50.4295 17.9921 54.7903C17.9723 54.9758 18.2309 55.4186 18.2111 55.6041L18.0947 56.693L18.0071 57.5124C17.9756 57.807 17.5947 60.7514 17.5947 60.7514L16.9987 61.1488C16.7452 62.286 16.8869 63.4755 17.4004 64.5213L17.7934 65.3216L22.1415 64.84L22.1648 36.311Z" fill="#3F3D56"/>
      <path d="M20.3738 40.7009L19.161 39.0088L15.3857 41.3932C14.5526 43.6979 13.6389 47.7191 13.1343 51.2161C13.1229 51.2953 13.1763 51.4287 13.1006 51.4527C12.8288 51.539 12.7466 51.6754 13.0385 51.9116C13.0724 51.954 13.0975 52.0027 13.1123 52.0549C13.127 52.1071 13.1312 52.1618 13.1245 52.2156C13.1177 52.2695 13.1003 52.3214 13.0731 52.3684C13.0459 52.4154 13.0096 52.4564 12.9663 52.4891C12.6463 52.6412 12.4002 52.7915 12.9153 52.9386C13.2775 53.042 12.8408 53.6757 12.8141 54.0228L17.1856 55.0163C17.585 54.9014 17.6608 54.6674 17.448 54.3274C17.7772 54.228 17.9019 54.0515 17.6715 53.7407L17.7796 53.4569C18.1469 53.4002 18.1417 53.248 17.9368 53.0445L18.0858 52.6533L18.7752 50.8436L20.3738 40.7009Z" fill="#3F3D56"/>
      <path d="M24.1721 34.7416C26.6256 34.7416 28.6146 32.7526 28.6146 30.299C28.6146 27.8455 26.6256 25.8564 24.1721 25.8564C21.7185 25.8564 19.7295 27.8455 19.7295 30.299C19.7295 32.7526 21.7185 34.7416 24.1721 34.7416Z" fill="#A0616A"/>
      <path d="M19.6737 33.162C19.6737 33.162 20.6522 33.4302 21.2216 33.5796C21.1921 33.1559 21.0086 32.7592 20.9154 32.3448C20.8222 31.9303 20.8425 31.4428 21.1505 31.1501C21.4586 30.8574 22.1001 30.9715 22.1498 31.3926C22.4104 30.8353 22.6715 30.2771 22.9332 29.7178C23.6793 31.2536 25.2048 29.9448 26.868 30.3328C27.7165 30.5327 28.78 30.4469 29.2283 29.6992C28.9763 29.4878 28.7641 29.2332 28.6015 28.9473C28.86 29.0231 29.129 29.0572 29.3983 29.0482C29.3921 28.7808 29.3218 28.5187 29.1933 28.2841C29.0648 28.0495 28.8818 27.8492 28.6597 27.7001C28.4205 27.5437 29.0126 26.9872 28.8038 26.7913C28.3756 26.3914 28.0883 26.6629 27.8353 26.1359C27.6722 25.81 27.4209 25.5364 27.1099 25.3463C26.799 25.1563 26.441 25.0573 26.0765 25.0606C25.3495 25.0777 24.0468 25.0706 23.7647 25.7404C22.8657 25.1519 21.6143 25.3182 20.7356 25.9356C20.0052 26.4467 19.174 27.9917 18.9905 28.8639C18.8071 29.7361 19.2113 32.4018 19.6737 33.162Z" fill="#2F2E41"/>
      <path d="M39.9149 52.7827C39.8389 52.7827 39.7637 52.7985 39.6942 52.829C39.6246 52.8595 39.5621 52.904 39.5106 52.9599C39.4591 53.0158 39.4198 53.0817 39.3951 53.1536C39.3704 53.2254 39.3609 53.3016 39.3671 53.3773C40.2062 55.5462 40.0926 58.0568 39.9852 60.8976C39.9972 61.0349 40.06 61.1627 40.1613 61.256C40.2627 61.3493 40.3952 61.4015 40.533 61.4022H44.0789C44.2167 61.4015 44.3492 61.3493 44.4506 61.256C44.5519 61.1627 44.6147 61.0349 44.6267 60.8976C44.3387 58.1379 44.4941 55.6052 45.2448 53.3773C45.251 53.3016 45.2415 53.2254 45.2168 53.1536C45.1921 53.0817 45.1527 53.0158 45.1013 52.9599C45.0498 52.904 44.9873 52.8595 44.9177 52.829C44.8481 52.7985 44.773 52.7827 44.697 52.7827H39.9149Z" fill="#F7B80B"/>
      <path d="M45.5288 53.785L45.2642 52.5284C45.2402 52.4141 45.1775 52.3116 45.0868 52.238C44.9962 52.1644 44.8829 52.1243 44.7662 52.1243H43.1147C43.1082 52.1024 43.0977 52.0818 43.0836 52.0638L42.9001 51.8344C42.8828 51.8128 42.8609 51.7954 42.8359 51.7834C42.811 51.7714 42.7837 51.7651 42.756 51.7651H41.7363C41.7086 51.7651 41.6813 51.7714 41.6563 51.7834C41.6314 51.7954 41.6094 51.8128 41.5921 51.8344L41.4086 52.0638C41.3946 52.0818 41.384 52.1024 41.3776 52.1243H39.8458C39.729 52.1243 39.6158 52.1644 39.5251 52.238C39.4344 52.3116 39.3718 52.4141 39.3477 52.5284L39.0832 53.785C39.0676 53.8592 39.0687 53.9358 39.0865 54.0094C39.1043 54.0831 39.1383 54.1518 39.186 54.2106C39.2337 54.2694 39.294 54.3168 39.3623 54.3494C39.4307 54.382 39.5055 54.3989 39.5812 54.3989C41.2709 55.2657 43.0843 55.2868 45.0307 54.3989C45.1065 54.3989 45.1812 54.382 45.2496 54.3494C45.318 54.3168 45.3782 54.2694 45.4259 54.2106C45.4737 54.1518 45.5077 54.0831 45.5254 54.0094C45.5432 53.9358 45.5444 53.8592 45.5288 53.785Z" fill="#3F3D56"/>
      <path d="M90.5068 54.4203C90.4201 54.0893 90.1123 53.0783 89.7211 53.5249C89.663 53.5912 89.6171 53.378 89.5631 53.2998C89.4699 53.1643 89.4134 52.5858 89.3146 52.4333C89.1137 52.1231 88.8643 52.2148 88.66 51.855C88.0758 50.8259 87.5307 49.6509 87.3276 48.658C86.7057 45.6182 87.5623 41.4613 87.5623 41.4613L87.2851 35.5403L85.2618 35.4723V35.3392C85.2618 35.072 85.1556 34.8157 84.9666 34.6267C84.7776 34.4377 84.5213 34.3315 84.2541 34.3315H80.4006C80.1446 34.3317 79.8982 34.4293 79.7116 34.6046C79.5251 34.7798 79.4123 35.0196 79.3962 35.2751L77.8094 35.2218L75.0615 43.9524C75.0615 43.9524 76.3439 47.2478 76.7963 50.4463C76.9191 51.3089 76.9488 52.1822 76.8849 53.0511C76.8236 53.9321 76.6511 54.8018 76.3715 55.6395C76.3715 55.6395 88.0286 58.0808 90.3081 55.8113C90.3081 55.8113 90.6625 55.0151 90.5068 54.4203Z" fill="#3F3D56"/>
      <path d="M105.433 55.0568H102.679V10.7264C102.489 10.5853 102.296 10.4462 102.101 10.3091C102.061 10.2833 102.024 10.2554 101.986 10.2296C100.813 9.40889 99.5882 8.66421 98.3198 8.00017C97.7522 7.23981 97.1306 6.52131 96.4599 5.85022C94.4824 3.87302 92.1023 2.3449 89.4814 1.36979C86.8606 0.394679 84.0604 -0.0045519 81.2715 0.199251C78.4825 0.403054 75.7702 1.20511 73.3189 2.55087C70.8676 3.89663 68.7349 5.75452 67.0659 7.99819C66.2075 8.44725 65.3716 8.93208 64.5583 9.45269C64.4808 9.50237 64.4033 9.55204 64.3278 9.60172C64.3258 9.60371 64.3238 9.60371 64.3218 9.60569C64.181 9.69783 64.0654 9.82371 63.9856 9.97189C63.9057 10.1201 63.8642 10.2858 63.8648 10.4541V55.0568H59.9504C58.2217 55.0568 56.7294 56.4696 56.7373 58.1983C56.7387 58.5926 56.8149 58.983 56.9619 59.3488C57.0184 59.4927 57.0869 59.6316 57.1665 59.7641C57.268 59.9378 57.3858 60.1015 57.5182 60.2529C57.8111 60.587 58.172 60.8547 58.5767 61.038C58.9814 61.2213 59.4206 61.3161 59.8649 61.316H105.519C106.346 61.3149 107.14 60.9864 107.726 60.4022C108.312 59.8181 108.643 59.0257 108.646 58.1983C108.654 56.4696 107.162 55.0568 105.433 55.0568ZM91.2281 55.0568H75.1988L72.9892 24.5262H93.4376L91.2281 55.0568Z" fill="#E6E6E6"/>
      <path d="M93.8125 43.7169L100.663 37.6138L102.684 38.615L94.4678 47.2322L93.8125 43.7169Z" fill="#FFB6B6"/>
      <path d="M86.1533 36.1467L87.2849 35.5404C87.2849 35.5404 90.0727 35.3027 91.2105 38.5776L94.2279 43.135L99.1186 38.8964L101.238 40.3636L95.0742 47.72L92.5113 48.0474L86.9684 40.8744L86.1533 36.1467Z" fill="#3F3D56"/>
      <path d="M87.9984 30.9081C87.3799 35.1 84.3017 35.5418 82.0481 33.3535C79.6904 31.0641 76.0977 33.7893 76.0977 30.9081C76.0977 28.027 77.399 22.9199 82.0481 22.9199C86.8573 22.9199 88.419 28.0578 87.9984 30.9081Z" fill="#2F2E41"/>
      <path d="M82.0275 33.3484C84.2389 33.3484 86.0315 31.5557 86.0315 29.3444C86.0315 27.133 84.2389 25.3403 82.0275 25.3403C79.8161 25.3403 78.0234 27.133 78.0234 29.3444C78.0234 31.5557 79.8161 33.3484 82.0275 33.3484Z" fill="#FFB6B6"/>
      <path d="M77.4014 28.9515H78.985L79.6837 26.9952L79.8234 28.9515H80.5803L80.9879 27.8103L81.0694 28.9515C82.2243 29.9954 84.3625 29.6134 86.6938 28.9515C86.6938 28.3949 86.5841 27.8437 86.3711 27.3295C86.1581 26.8152 85.8459 26.3479 85.4523 25.9544C85.0587 25.5608 84.5914 25.2485 84.0772 25.0355C83.5629 24.8225 83.0118 24.7129 82.4551 24.7129H81.64C81.0834 24.7129 80.5322 24.8225 80.0179 25.0355C79.5037 25.2485 79.0364 25.5608 78.6428 25.9544C78.2492 26.3479 77.937 26.8152 77.724 27.3295C77.511 27.8437 77.4014 28.3949 77.4014 28.9515Z" fill="#2F2E41"/>
      <path d="M70.0349 57.6595C70.3518 57.6587 70.6615 57.5654 70.9261 57.3911C71.1908 57.2168 71.3988 56.9691 71.5248 56.6783C71.6507 56.3875 71.6891 56.0663 71.6351 55.7541C71.5812 55.4418 71.4374 55.1521 71.2212 54.9204L73.4501 49.0347L73.9065 47.0675C73.6535 46.9085 73.3676 46.8092 73.0705 46.7771C72.7735 46.7449 72.473 46.7807 72.1918 46.8818C71.9107 46.983 71.6562 47.1467 71.4476 47.3607C71.2391 47.5746 71.0819 47.8332 70.9881 48.1169L68.666 55.1352C68.5043 55.3815 68.4123 55.6669 68.3999 55.9613C68.3875 56.2556 68.4551 56.5478 68.5955 56.8068C68.7359 57.0657 68.944 57.2818 69.1974 57.4319C69.4509 57.5821 69.7403 57.6607 70.0349 57.6595H70.0349Z" fill="#FFB6B6"/>
      <path d="M97.0223 31.7651C96.9463 31.7651 96.8712 31.7809 96.8016 31.8114C96.732 31.8419 96.6695 31.8865 96.618 31.9423C96.5666 31.9982 96.5272 32.0642 96.5025 32.136C96.4778 32.2078 96.4683 32.284 96.4745 32.3597C97.0912 35.0199 97.3527 37.5474 97.0926 39.88C97.1046 40.0173 97.1674 40.1451 97.2688 40.2384C97.3701 40.3318 97.5026 40.3839 97.6404 40.3846H101.186C101.324 40.3839 101.457 40.3318 101.558 40.2384C101.659 40.1451 101.722 40.0173 101.734 39.88C101.537 37.3563 101.745 34.8173 102.352 32.3597C102.358 32.284 102.349 32.2078 102.324 32.136C102.3 32.0642 102.26 31.9982 102.209 31.9423C102.157 31.8865 102.095 31.8419 102.025 31.8114C101.956 31.7809 101.88 31.7651 101.804 31.7651H97.0223Z" fill="#F7B80B"/>
      <path d="M102.636 32.7675L102.372 31.5108C102.348 31.3965 102.285 31.294 102.194 31.2204C102.104 31.1469 101.99 31.1067 101.874 31.1067H100.222C100.216 31.0848 100.205 31.0643 100.191 31.0462L100.008 30.8168C99.9902 30.7952 99.9683 30.7778 99.9434 30.7658C99.9184 30.7538 99.8911 30.7476 99.8634 30.7476H98.8437C98.816 30.7476 98.7887 30.7538 98.7637 30.7658C98.7388 30.7778 98.7168 30.7952 98.6995 30.8168L98.516 31.0462C98.502 31.0643 98.4915 31.0848 98.485 31.1067H96.9532C96.8364 31.1067 96.7232 31.1469 96.6325 31.2204C96.5418 31.294 96.4792 31.3965 96.4552 31.5108L96.1906 32.7675C96.175 32.8416 96.1761 32.9182 96.1939 32.9919C96.2117 33.0655 96.2457 33.1342 96.2934 33.193C96.3411 33.2518 96.4014 33.2992 96.4698 33.3318C96.5381 33.3644 96.6129 33.3813 96.6886 33.3813C98.5153 34.1428 100.334 34.2931 102.138 33.3813C102.214 33.3813 102.289 33.3644 102.357 33.3318C102.425 33.2992 102.486 33.2518 102.533 33.193C102.581 33.1342 102.615 33.0655 102.633 32.9919C102.651 32.9182 102.652 32.8416 102.636 32.7675Z" fill="#3F3D56"/>
      <path d="M80.921 7.78369C80.8701 7.78369 80.8198 7.79424 80.7731 7.81467C80.7265 7.83511 80.6847 7.865 80.6502 7.90244C80.6157 7.93988 80.5893 7.98406 80.5727 8.03221C80.5562 8.08035 80.5498 8.1314 80.554 8.18213L80.9682 13.2215C80.9762 13.3135 81.0183 13.3991 81.0862 13.4617C81.1541 13.5242 81.2429 13.5592 81.3352 13.5596H83.7114C83.8037 13.5592 83.8925 13.5242 83.9604 13.4617C84.0283 13.3991 84.0704 13.3135 84.0785 13.2215L84.4927 8.18213C84.4968 8.1314 84.4904 8.08035 84.4739 8.03221C84.4573 7.98406 84.431 7.93988 84.3965 7.90244C84.362 7.865 84.3201 7.83511 84.2735 7.81467C84.2269 7.79424 84.1765 7.78369 84.1256 7.78369H80.921Z" fill="white"/>
      <path d="M84.683 8.4556L84.5057 7.61352C84.4896 7.53695 84.4476 7.46824 84.3868 7.41894C84.3261 7.36963 84.2502 7.34272 84.1719 7.34272H83.0653C83.0609 7.32803 83.0539 7.31428 83.0445 7.30219L82.9215 7.14847C82.9099 7.13399 82.8952 7.12229 82.8785 7.11426C82.8618 7.10622 82.8435 7.10205 82.8249 7.10205H82.1416C82.1231 7.10205 82.1047 7.10622 82.088 7.11426C82.0713 7.12229 82.0566 7.13399 82.045 7.14847L81.922 7.30219C81.9126 7.31428 81.9056 7.32803 81.9012 7.34272H80.8748C80.7965 7.34272 80.7207 7.36963 80.6599 7.41894C80.5991 7.46824 80.5572 7.53695 80.541 7.61352L80.3638 8.4556C80.3533 8.50527 80.3541 8.55664 80.366 8.60597C80.3779 8.6553 80.4007 8.70135 80.4327 8.74076C80.4646 8.78017 80.505 8.81195 80.5508 8.83378C80.5966 8.8556 80.6468 8.86693 80.6975 8.86693H84.3492C84.4 8.86693 84.4501 8.8556 84.4959 8.83378C84.5417 8.81195 84.5821 8.78017 84.6141 8.74076C84.646 8.70135 84.6688 8.6553 84.6807 8.60597C84.6927 8.55664 84.6934 8.50527 84.683 8.4556Z" fill="#CCCCCC"/>
      <path d="M82.5708 16.441C81.274 16.441 80.0062 16.0564 78.9279 15.3359C77.8496 14.6154 77.0091 13.5913 76.5128 12.3932C76.0165 11.195 75.8867 9.87656 76.1397 8.6046C76.3927 7.33263 77.0172 6.16425 77.9342 5.24721C78.8513 4.33018 80.0196 3.70567 81.2916 3.45266C82.5636 3.19965 83.882 3.3295 85.0802 3.8258C86.2783 4.32209 87.3024 5.16254 88.0229 6.24086C88.7434 7.31919 89.128 8.58695 89.128 9.88384C89.1261 11.6223 88.4346 13.289 87.2053 14.5183C85.976 15.7476 84.3093 16.439 82.5708 16.441ZM82.5708 3.72407C81.3526 3.72407 80.1616 4.08533 79.1487 4.76217C78.1357 5.43902 77.3462 6.40104 76.88 7.52659C76.4137 8.65214 76.2918 9.89067 76.5294 11.0855C76.7671 12.2804 77.3538 13.378 78.2152 14.2395C79.0767 15.1009 80.1743 15.6876 81.3691 15.9252C82.564 16.1629 83.8025 16.0409 84.9281 15.5747C86.0536 15.1085 87.0157 14.319 87.6925 13.306C88.3694 12.2931 88.7306 11.1021 88.7306 9.88384C88.7288 8.25073 88.0792 6.68505 86.9244 5.53027C85.7696 4.3755 84.2039 3.72593 82.5708 3.72407Z" fill="#F2F2F2"/>
      <path d="M102.639 99.8961H63.4951L63.5388 95.3482L63.5428 94.9528L63.8925 59.3608H102.441L102.49 69.6429L102.492 70.0385L102.639 99.8961Z" fill="#E6E6E6"/>
      <path d="M125.269 100.086L0.731998 100.148C0.669369 100.147 0.609365 100.122 0.565143 100.078C0.520922 100.034 0.496094 99.9736 0.496094 99.9109C0.496094 99.8483 0.520922 99.7882 0.565143 99.7439C0.609365 99.6995 0.669369 99.6745 0.731998 99.6744L125.269 99.6133C125.331 99.6133 125.392 99.6382 125.436 99.6826C125.48 99.7269 125.505 99.7871 125.505 99.8499C125.505 99.9126 125.48 99.9728 125.436 100.017C125.392 100.062 125.331 100.086 125.269 100.086Z" fill="#CACACA"/>
      <path d="M61.5731 47.0205C61.4971 47.0205 61.422 47.0362 61.3524 47.0667C61.2828 47.0972 61.2203 47.1418 61.1688 47.1977C61.1173 47.2536 61.078 47.3195 61.0533 47.3914C61.0286 47.4632 61.0191 47.5394 61.0253 47.6151C61.5866 50.2606 61.8335 52.7833 61.6434 55.1354C61.6554 55.2727 61.7182 55.4005 61.8195 55.4938C61.9209 55.5871 62.0534 55.6393 62.1912 55.64H65.7371C65.8749 55.6393 66.0074 55.5871 66.1088 55.4938C66.2101 55.4005 66.2729 55.2727 66.2849 55.1354C65.9985 52.7629 66.3014 50.2297 66.903 47.6151C66.9092 47.5394 66.8997 47.4632 66.875 47.3914C66.8503 47.3195 66.811 47.2536 66.7595 47.1977C66.708 47.1418 66.6455 47.0972 66.5759 47.0667C66.5064 47.0362 66.4312 47.0205 66.3552 47.0205H61.5731Z" fill="#F7B80B"/>
      <path d="M67.187 48.0228L66.9224 46.7662C66.8984 46.6519 66.8357 46.5494 66.7451 46.4758C66.6544 46.4022 66.5411 46.3621 66.4244 46.3621H64.7729C64.7664 46.3402 64.7559 46.3196 64.7418 46.3016L64.5583 46.0722C64.541 46.0506 64.5191 46.0331 64.4941 46.0212C64.4692 46.0092 64.4419 46.0029 64.4142 46.0029H63.3945C63.3668 46.0029 63.3395 46.0092 63.3145 46.0212C63.2896 46.0331 63.2676 46.0506 63.2503 46.0722L63.0668 46.3016C63.0528 46.3196 63.0423 46.3402 63.0358 46.3621H61.504C61.3872 46.3621 61.274 46.4022 61.1833 46.4758C61.0926 46.5494 61.03 46.6519 61.0059 46.7662L60.7414 48.0228C60.7258 48.0969 60.7269 48.1736 60.7447 48.2472C60.7625 48.3208 60.7965 48.3896 60.8442 48.4484C60.8919 48.5072 60.9522 48.5546 61.0205 48.5872C61.0889 48.6198 61.1637 48.6367 61.2394 48.6367C63.0145 49.1851 64.9138 49.1851 66.6889 48.6367C66.7647 48.6367 66.8394 48.6198 66.9078 48.5872C66.9762 48.5546 67.0364 48.5072 67.0842 48.4484C67.1319 48.3896 67.1659 48.3209 67.1837 48.2472C67.2014 48.1736 67.2026 48.0969 67.187 48.0228Z" fill="#3F3D56"/>
      <path d="M82.3129 75.853H64.0918V76.2504H82.3129V75.853Z" fill="#F2F2F2"/>
      <path d="M102.381 61.1489H84.1602V61.5463H102.381V61.1489Z" fill="#F2F2F2"/>
      <path d="M92.2475 88.5698H74.0264V88.9672H92.2475V88.5698Z" fill="#F2F2F2"/>
      <path d="M102.492 70.0908H93.8965V69.6934H102.49L102.492 70.0908Z" fill="#F2F2F2"/>
      <path d="M65.9318 58.3671H57.3359V57.9697H65.9298L65.9318 58.3671Z" fill="#F2F2F2"/>
      <path d="M72.6877 19.2231H64.0918V18.8257H72.6857L72.6877 19.2231Z" fill="#F2F2F2"/>
      <path d="M101.499 11.0761H92.9033V10.6787H101.497L101.499 11.0761Z" fill="#F2F2F2"/>
      <path d="M92.756 19.2231H84.1602V18.8257H92.754L92.756 19.2231Z" fill="#F2F2F2"/>
      <path d="M68.9994 95.127V95.5244H63.5391L63.543 95.127H68.9994Z" fill="#F2F2F2"/>
      <path d="M101.961 40.1086C102.862 40.1086 103.592 39.3787 103.592 38.4784C103.592 37.578 102.862 36.8481 101.961 36.8481C101.061 36.8481 100.331 37.578 100.331 38.4784C100.331 39.3787 101.061 40.1086 101.961 40.1086Z" fill="#FFB6B6"/>
      <path d="M78.4918 36.3095L77.8098 35.2218C77.8098 35.2218 75.591 34.6732 74.6775 36.1435C73.7641 37.6137 68.8486 53.4268 68.8486 53.4268L71.6305 54.4203L76.9954 42.1493L78.4918 36.3095Z" fill="#3F3D56"/>
    </g>
    <defs>
      <clipPath id="clip0_1063_5643">
        <rect width="125.01" height="100" fill="white" transform="translate(0.495117 0.147461)"/>
      </clipPath>
    </defs>
  </svg>

);