import { createContext, useContext, useEffect, useState } from "react";
import { useSegment } from "./SegmentContext";

export type CustomerContextType = {
  customerInfo: CustomerType | null;
  setFirstName: (firstname: string) => void;
  setLastName: (lastname: string) => void;
  setCompanyName: (companyName: string) => void;
  setEmail: (email: string) => void;
  setPhone: (phone: string) => void;
  setSiren: (siren: string) => void;
  setAddress: (address: string) => void;
  setDataNeeded: (isNeeded: boolean) => void;
  setNeedDescription: (description: string) => void;
  setTime: (time: string) => void;
  setDate: (date: Date | undefined) => void;
  setPaymentType: (paymentType: string) => void;
  setPronoun: (pronoun: string) => void;
  checkContextInfo: () => void;
  resetCustomerInfo: () => void;
};

export const CustomerContext = createContext({
  customerInfo: null,
  setFirstName: () => {},
  setLastName: () => {},
  setCompanyName: () => {},
  setEmail: () => {},
  setPhone: () => {},
  setSiren: () => {},
  setAddress: () => {},
  setDataNeeded: () => {},
  setNeedDescription: () => {},
  setTime: () => {},
  setDate: () => {},
  setPaymentType: () => {},
  setPronoun: () => {},
  checkContextInfo: () => {},
  resetCustomerInfo: () => {},
} as CustomerContextType);

export function CustomerContextProvider({ children }: { children: any }) {
  const { identify } = useSegment();
  const [customerFirstname, setFirstName] = useState<string>(
    localStorage.getItem("customerFirstname") ?? ""
  );
  const [customerLastname, setLastName] = useState<string>(
    localStorage.getItem("customerLastname") ?? ""
  );
  const [companyName, setCompanyName] = useState<string>(
    localStorage.getItem("companyName") ?? ""
  );
  const [customerEmail, setEmail] = useState<string>(
    localStorage.getItem("customerEmail") ?? ""
  );
  const [customerPhone, setPhone] = useState<string>(
    localStorage.getItem("customerPhone") ?? ""
  );
  const [customerSiren, setSiren] = useState<string>(
    localStorage.getItem("customerSiren") ?? ""
  );
  const [customerAddress, setAddress] = useState<string>(
    localStorage.getItem("customerAddress") ?? ""
  );
  const [customerNeedDescription, setNeedDescription] = useState<string>(
    localStorage.getItem("customerNeedDescription") ?? ""
  );
  const [customerTime, setTime] = useState<string>(
    localStorage.getItem("customerTime") ?? ""
  );
  const [customerDate, setDate] = useState<Date | undefined>(() => {
    const date = localStorage.getItem("customerDate");
    if (date) return new Date(date);
    return undefined;
  });
  const [paymentType, setPaymentType] = useState<string>(
    localStorage.getItem("paymentType") ?? ""
  );
  const [pronoun, setPronoun] = useState<string>(
    localStorage.getItem("pronoun") ?? ""
  );
  const [isNeeded, setDataNeeded] = useState<boolean>(
    localStorage.getItem("isNeeded") === "true"
  );

  useEffect(() => {
    localStorage.setItem("customerFirstname", customerFirstname);

    localStorage.setItem("customerLastname", customerLastname);
    localStorage.setItem("companyName", companyName);
    localStorage.setItem("customerEmail", customerEmail);
    localStorage.setItem("customerPhone", customerPhone);
    localStorage.setItem("customerSiren", customerSiren);

    localStorage.setItem("customerAddress", customerAddress);

    localStorage.setItem("customerNeedDescription", customerNeedDescription);
    localStorage.setItem("customerTime", customerTime);
    if (customerDate)
      localStorage.setItem("customerDate", customerDate.toISOString());
    else localStorage.removeItem("customerDate");
    localStorage.setItem("paymentType", paymentType);
    localStorage.setItem("pronoun", pronoun);
    localStorage.setItem("isNeeded", isNeeded.toString());
  }, [
    companyName,
    customerAddress,
    customerEmail,
    customerFirstname,
    customerLastname,
    customerPhone,
    customerSiren,
    customerNeedDescription,
    customerTime,
    customerDate,
    paymentType,
    pronoun,
    isNeeded,
  ]);

  useEffect(() => {
    if (customerEmail) {
      identify(customerEmail, {
        email: customerEmail,
        name: pronoun + " " + customerFirstname + " " + customerLastname,
        companyName,
        customerAddress,
        customerFirstname,
        customerLastname,
        customerPhone,
        customerSiren,
        customerNeedDescription,
        customerTime,
        customerDate,
        paymentType,
        pronoun,
      });
    }
  }, [
    companyName,
    customerAddress,
    customerDate,
    customerEmail,
    customerFirstname,
    customerLastname,
    customerNeedDescription,
    customerPhone,
    customerSiren,
    customerTime,
    identify,
    paymentType,
    pronoun,
  ]);

  return (
    <CustomerContext.Provider
      value={{
        customerInfo: {
          firstname: customerFirstname,
          lastname: customerLastname,
          companyName: companyName,
          email: customerEmail,
          phone: customerPhone,
          siren: customerSiren,
          address: customerAddress,
          needDescription: customerNeedDescription,
          time: customerTime,
          date: customerDate,
          paymentType: paymentType,
          pronoun: pronoun,
          dataNeeded: isNeeded,
        },
        setFirstName(value) {
          setFirstName(value);
        },
        setLastName(value) {
          setLastName(value);
        },
        setCompanyName(value) {
          setCompanyName(value);
        },
        setEmail(value) {
          setEmail(value);
        },
        setPhone(value) {
          setPhone(value);
        },
        setSiren(value) {
          setSiren(value);
        },
        setAddress(value) {
          setAddress(value);
        },
        setDataNeeded(value) {
          setDataNeeded(value);
        },
        setNeedDescription(value) {
          setNeedDescription(value);
        },
        setTime(value) {
          setTime(value);
        },
        setDate(value) {
          setDate(value);
        },
        setPaymentType(value) {
          setPaymentType(value);
        },
        setPronoun(value) {
          setPronoun(value);
        },
        checkContextInfo() {
          if (!companyName || !customerEmail) {
            setDataNeeded(true);
          } else {
            setDataNeeded(false);
          }
        },
        resetCustomerInfo() {
          setFirstName("");
          setLastName("");
          setCompanyName("");
          setEmail("");
          setPhone("");
          setSiren("");
          setAddress("");
          setDataNeeded(false);
          setNeedDescription("");
          setTime("");
          setDate(undefined);
          setPaymentType("");
          setPronoun("");
        },
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
}

export function useCustomerContext() {
  const context = useContext(CustomerContext);
  return context;
}

export interface CustomerType {
  firstname: string;
  lastname: string;
  companyName: string;
  email: string;
  phone: string;
  siren: string;
  address: string;
  needDescription: string;
  time: string;
  date: Date | undefined;
  paymentType: string;
  pronoun: string;
  dataNeeded: boolean;
}
