import {
  Box,
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import {
  setAdviceOnHover,
  useAdviceContext,
} from "../../context/AdviceContext";
import { HelpType, useHelpContext } from "../../context/HelpContext";
import { useSessionContext } from "../../context/SessionContext";
import {
  PERSONNEL_TYPE_LABELS,
  PersonnelSelectionInfo,
  PersonnelType,
  SectionTitle,
  useUserContext,
} from "../../context/UserContext";
import ConsultationBlock from "../../modules/SelectDePersonnel/components/ConsultationBlock";
import SingleSelectCardWrapper from "../../modules/SelectDePersonnel/components/SingleSelectCardWrapper";
import TabHeadingButton from "../../modules/SelectDePersonnel/components/TabHeadingButton";
import {
  ACCUEIL_CONFIG,
  ANIMATION_CONFIG,
  ANIMATION_MAGASIN_ONLY_CONFIG,
  ANIMATION_STREET_ONLY_CONFIG,
  PersonnelConfig,
  TeamConfig,
} from "../../modules/SelectDePersonnel/personnelConfig";
import { useSegment } from "../../context/SegmentContext";

function PersonnelBlock({ config: item }: { config: TeamConfig }) {
  const [expanded, setExpanded] = useState(false);
  const { userInfos, setUserInfos } = useUserContext();
  const { setHelp } = useHelpContext();
  const { sessionInfo } = useSessionContext();
  const [showHelp, setShowHelp] = useState(true);
  let showMore = false;
  const rolesToShow = userInfos?.selectDePersonnelInfo?.rolesToShow;
  let tabItems = item.personnel;
  let hiddenItems: PersonnelConfig[] = [];

  // const disableMiddleManagement = sessionInfo?.needHelp !== true;
  const restrictMiddleManagement = sessionInfo?.needHelp !== true;

  if (rolesToShow) {
    showMore = true;
    tabItems = tabItems?.filter((tabItem) =>
      rolesToShow.includes(tabItem.type)
    );
    hiddenItems =
      item.personnel.filter((tabItem) => !rolesToShow.includes(tabItem.type)) ??
      [];

    if (tabItems?.length === 0) {
      showMore = false;
      tabItems = item.personnel;
    }
  }

  const setManagers = (
    persons: PersonnelSelectionInfo["persons"],
    ChefEquipeCount: number,
    cheffeHotesseCount: number,
    respVestCount: number
  ) => {
    const personel = {
      ...persons,
      [PersonnelType.chef]: {
        title: PERSONNEL_TYPE_LABELS[PersonnelType.chef],
        count: ChefEquipeCount,
        role: PersonnelType.chef,
      },
      [PersonnelType.chefHotesse]: {
        title: PERSONNEL_TYPE_LABELS[PersonnelType.chefHotesse],
        count: cheffeHotesseCount,
        role: PersonnelType.chefHotesse,
      },
      [PersonnelType.responsableVestiaire]: {
        title: PERSONNEL_TYPE_LABELS[PersonnelType.responsableVestiaire],
        count: respVestCount,
        role: PersonnelType.responsableVestiaire,
      },
    };
    return personel;
  };

  const [minCheffeHotesseNeeded, setMinCheffeHotesseNeeded] = useState(0);
  const [minRespVestiaireNeeded, setMinRespVestiaireNeeded] = useState(0);
  const [minChefEquipeNeeded, setMinChefEquipeNeeded] = useState(0);

  useEffect(() => {
    if (!restrictMiddleManagement) return;
    let totalHotessesAccueil = 0;

    for (const personne of ACCUEIL_CONFIG.personnel) {
      if (
        personne.type !== PersonnelType.chefHotesse &&
        personne.type !== PersonnelType.responsableVestiaire &&
        personne.type !== PersonnelType.vestiaire
      )
        totalHotessesAccueil +=
          userInfos?.selectDePersonnelInfo?.persons?.[personne.type]?.count ??
          0;
    }
    const cheffeHotesseNeeded = Math.floor(
      (totalHotessesAccueil -
        (userInfos?.selectDePersonnelInfo?.persons?.[PersonnelType.vestiaire]
          ?.count ?? 0) +
        ((userInfos?.selectDePersonnelInfo?.persons?.[PersonnelType.vestiaire]
          ?.count ?? 0) %
          4)) /
        5
    );
    setMinCheffeHotesseNeeded(cheffeHotesseNeeded >= 1 ? 1 : 0);

    const respVestiaireNeeded =
      (userInfos?.selectDePersonnelInfo?.persons?.[PersonnelType.vestiaire]
        ?.count ?? 0) / 8;
    setMinRespVestiaireNeeded(respVestiaireNeeded >= 1 ? 1 : 0);

    let totalHotessesStreet = 0;
    for (const personne of ANIMATION_STREET_ONLY_CONFIG.personnel) {
      if (personne.type !== PersonnelType.chef)
        totalHotessesStreet +=
          userInfos?.selectDePersonnelInfo?.persons?.[personne.type]?.count ??
          0;
    }
    const chefEquipeNeeded = Math.floor(totalHotessesStreet / 5);
    setMinChefEquipeNeeded(chefEquipeNeeded >= 1 ? 1 : 0);
    if (
      (userInfos?.selectDePersonnelInfo?.persons?.[PersonnelType.chef]?.count ??
        0) >= minChefEquipeNeeded &&
      (userInfos?.selectDePersonnelInfo?.persons?.[PersonnelType.chefHotesse]
        ?.count ?? 0) >= minCheffeHotesseNeeded &&
      (userInfos?.selectDePersonnelInfo?.persons?.[
        PersonnelType.responsableVestiaire
      ]?.count ?? 0) >= minRespVestiaireNeeded
    )
      return;

    const personel = setManagers(
      userInfos?.selectDePersonnelInfo?.persons,
      minChefEquipeNeeded,
      minCheffeHotesseNeeded,
      minRespVestiaireNeeded
    );
    setUserInfos({
      ...userInfos,
      selectDePersonnelInfo: {
        ...userInfos.selectDePersonnelInfo,
        persons: personel,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfos]);

  /*** CODE TO DISABLE COMPLETELY MIDDLE MANAGEMENT***/
  // useEffect(() => {
  // if (!disableMiddleManagement) return;
  // let totalHotessesAccueil = 0;
  // for (const personne of ACCUEIL_CONFIG.personnel) {
  //   if (
  //     personne.type !== PersonnelType.chefHotesse &&
  //     personne.type !== PersonnelType.responsableVestiaire
  //   )
  //     totalHotessesAccueil +=
  //       userInfos?.selectDePersonnelInfo?.persons?.[personne.type]?.count ??
  //       0;
  // }
  // const cheffeHotesseNeeded = Math.floor(
  //   (totalHotessesAccueil -
  //     (userInfos?.selectDePersonnelInfo?.persons?.[PersonnelType.vestiaire]
  //       ?.count ?? 0) +
  //     ((userInfos?.selectDePersonnelInfo?.persons?.[PersonnelType.vestiaire]
  //       ?.count ?? 0) %
  //       4)) /
  //     5
  // );
  // const respVestiaire = Math.floor(
  //   (userInfos?.selectDePersonnelInfo?.persons?.[PersonnelType.vestiaire]
  //     ?.count ?? 0) / 4
  // );

  // let totalHotessesStreet = 0;
  // for (const personne of STREET_MARKETING_CONFIG.personnel) {
  //   if (personne.type !== PersonnelType.chef)
  //     totalHotessesStreet +=
  //       userInfos?.selectDePersonnelInfo?.persons?.[personne.type]?.count ??
  //       0;
  // }
  // const chefEquipeNeeded = Math.floor(totalHotessesStreet / 5);

  // if (
  //   userInfos?.selectDePersonnelInfo?.persons?.[PersonnelType.chef]?.count ===
  //     chefEquipeNeeded &&
  //   userInfos?.selectDePersonnelInfo?.persons?.[PersonnelType.chefHotesse]
  //     ?.count === cheffeHotesseNeeded &&
  //   userInfos?.selectDePersonnelInfo?.persons?.[
  //     PersonnelType.responsableVestiaire
  //   ]?.count === respVestiaire
  // )
  //   return;
  // const personel = setManagers(
  //   userInfos?.selectDePersonnelInfo?.persons,
  //   chefEquipeNeeded,
  //   cheffeHotesseNeeded,
  //   respVestiaire
  // );
  // setUserInfos({
  //   ...userInfos,
  //   selectDePersonnelInfo: {
  //     ...userInfos.selectDePersonnelInfo,
  //     persons: personel,
  //   },
  // });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userInfos]);

  useEffect(() => {
    // if (disableMiddleManagement) return;
    if (restrictMiddleManagement) return;
    let leaderChosen = true;
    if (item?.leader && item?.countForLeader) {
      leaderChosen =
        userInfos.selectDePersonnelInfo.persons[
          item.leader as PersonnelType
        ] !== undefined;
      if (leaderChosen) return;
      let peopleCount = 0;
      for (const person of item.personnel ?? []) {
        peopleCount +=
          userInfos?.selectDePersonnelInfo?.persons?.[person.type ?? ""]
            ?.count ?? 0;
      }
      if (peopleCount >= item.countForLeader) {
        if (item?.leader === "chef" && showHelp) {
          setHelp(HelpType.CHEF_EQUIPE);
          setShowHelp(false);
        } else setHelp(HelpType.CHEF_HOTESSE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfos]);

  return (
    <Flex
      gap="16px"
      border="1px solid #D9D9D9"
      borderRadius="24px"
      padding="1rem"
      flexDir="column"
    >
      <Flex flexWrap="wrap" gap="0.5rem">
        {tabItems?.map((answer) => {
          return (
            <SingleSelectCardWrapper
              key={answer.type}
              answer={answer}
              isSelected={
                (userInfos?.selectDePersonnelInfo?.persons?.[answer.type]
                  ?.count ?? 0) > 0
              }
              // isDisabled={
              //   disableMiddleManagement &&
              //   (answer.type === PersonnelType.chef ||
              //     answer.type === PersonnelType.responsableVestiaire ||
              //     answer.type === PersonnelType.chefHotesse)
              // }
              isRestricted={
                restrictMiddleManagement &&
                ((answer.type === PersonnelType.chef &&
                  minChefEquipeNeeded >=
                    (userInfos?.selectDePersonnelInfo?.persons?.[
                      PersonnelType.chef
                    ]?.count ?? 0)) ||
                  (answer.type === PersonnelType.responsableVestiaire &&
                    minRespVestiaireNeeded >=
                      (userInfos?.selectDePersonnelInfo?.persons?.[
                        PersonnelType.responsableVestiaire
                      ]?.count ?? 0)) ||
                  (answer.type === PersonnelType.chefHotesse &&
                    minCheffeHotesseNeeded >=
                      (userInfos?.selectDePersonnelInfo?.persons?.[
                        PersonnelType.chefHotesse
                      ]?.count ?? 0)))
              }
              textWithField={answer.textWithField}
            />
          );
        })}
      </Flex>
      {showMore && (
        <Flex>
          {expanded && <Box flex={1} h="1px" bg="#D9D9D9" placeSelf="center" />}

          <Flex
            ml="auto"
            px="1rem"
            cursor="pointer"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? (
              <Text
                size="sm"
                display="flex"
                _hover={{ textDecoration: "underline" }}
              >
                <FaChevronUp
                  style={{ alignSelf: "center", marginRight: "0.25rem" }}
                />
                Afficher moins
              </Text>
            ) : (
              <Text
                size="sm"
                display="flex"
                _hover={{ textDecoration: "underline" }}
              >
                <FaChevronDown
                  style={{ alignSelf: "center", marginRight: "0.25rem" }}
                />{" "}
                Afficher plus
              </Text>
            )}
          </Flex>
        </Flex>
      )}
      {expanded && (
        <Flex flexWrap="wrap" gap="0.5rem">
          {hiddenItems?.map((answer) => {
            return (
              <SingleSelectCardWrapper
                key={answer.type}
                answer={answer}
                isSelected={
                  userInfos?.selectDePersonnelInfo?.persons?.[
                    answer.type ?? ""
                  ] !== undefined
                }
                textWithField={answer.textWithField}
              />
            );
          })}
        </Flex>
      )}
    </Flex>
  );
}

export default function SelectionDePersonnelBlock() {
  const { track } = useSegment();
  const { userInfos } = useUserContext();
  const { setAdvice } = useAdviceContext();
  return (
    <Tabs
      orientation="horizontal"
      variant="unstyled"
      gap="20px !important"
      alignItems="stretch"
      defaultIndex={0}
    >
      <TabList gap="0.5rem">
        {userInfos?.selectDePersonnelInfo?.tabsToHide === undefined && (
          <TabHeadingButton titleOfSection="" display="none" />
        )}
        {!userInfos?.selectDePersonnelInfo?.tabsToHide?.includes(
          SectionTitle.SERVICE
        ) && (
          <TabHeadingButton
            onClick={() =>
              track("Staff selection tab clicked", {
                category: "Service d'Accueil",
              })
            }
            titleOfSection="SERVICE D'ACCUEIL"
            {...setAdviceOnHover(
              "Réservez votre personnel d'accueil",
              setAdvice
            )}
          />
        )}
        {!userInfos?.selectDePersonnelInfo?.tabsToHide?.includes(
          SectionTitle.ANIMATION
        ) &&
          !userInfos?.selectDePersonnelInfo?.tabsToHide?.includes(
            SectionTitle.STREET_MARKETING
          ) && (
            <TabHeadingButton
              onClick={() =>
                track("Staff selection tab clicked", {
                  category: "Animation Magasin",
                })
              }
              titleOfSection="ANIMATION"
              {...setAdviceOnHover(
                "Commander du personnel formé à votre produit pour animer votre espace",
                setAdvice
              )}
            />
          )}
        {userInfos?.selectDePersonnelInfo?.tabsToHide?.includes(
          SectionTitle.ANIMATION
        ) &&
          !userInfos?.selectDePersonnelInfo?.tabsToHide?.includes(
            SectionTitle.STREET_MARKETING
          ) && (
            <TabHeadingButton
              onClick={() =>
                track("Staff selection tab clicked", {
                  category: "Animation Magasin",
                })
              }
              titleOfSection="ANIMATION"
              {...setAdviceOnHover(
                "Commander du personnel formé à votre produit pour animer votre espace",
                setAdvice
              )}
            />
          )}
        {!userInfos?.selectDePersonnelInfo?.tabsToHide?.includes(
          SectionTitle.ANIMATION
        ) &&
          userInfos?.selectDePersonnelInfo?.tabsToHide?.includes(
            SectionTitle.STREET_MARKETING
          ) && (
            <TabHeadingButton
              onClick={() =>
                track("Staff selection tab clicked", {
                  category: "Animation Magasin",
                })
              }
              titleOfSection="ANIMATION"
              {...setAdviceOnHover(
                "Commander du personnel formé à votre produit pour animer votre espace",
                setAdvice
              )}
            />
          )}
        {!userInfos?.selectDePersonnelInfo?.tabsToHide?.includes(
          SectionTitle.ARTIST
        ) && (
          <TabHeadingButton
            onClick={() =>
              track("Staff selection tab clicked", {
                category: "Artiste / Talent",
              })
            }
            titleOfSection="ARTISTE / TALENT"
            {...setAdviceOnHover(
              "Créer un temps fort ou marquer les esprits de vos invités",
              setAdvice
            )}
          />
        )}
      </TabList>
      <TabPanels>
        {userInfos?.selectDePersonnelInfo?.tabsToHide === undefined && (
          <TabPanel display="none" />
        )}
        {!userInfos?.selectDePersonnelInfo?.tabsToHide?.includes(
          SectionTitle.SERVICE
        ) && (
          <TabPanel p="1rem 0 0 0" tabIndex={1}>
            <PersonnelBlock config={ACCUEIL_CONFIG} />
          </TabPanel>
        )}

        {/* IF STREET_MARKETING don't need to be hide but STORE_ANIMATION need to be hide */}
        {!userInfos?.selectDePersonnelInfo?.tabsToHide?.includes(
          SectionTitle.STREET_MARKETING
        ) &&
          userInfos?.selectDePersonnelInfo?.tabsToHide?.includes(
            SectionTitle.ANIMATION
          ) && (
            <TabPanel p="1rem 0 0 0" tabIndex={2}>
              <PersonnelBlock config={ANIMATION_STREET_ONLY_CONFIG} />
            </TabPanel>
          )}

        {/* IF STORE_ANIMATION don't need to be hide but STREET_MARKETING need to be hide */}
        {!userInfos?.selectDePersonnelInfo?.tabsToHide?.includes(
          SectionTitle.ANIMATION
        ) &&
          userInfos?.selectDePersonnelInfo?.tabsToHide?.includes(
            SectionTitle.STREET_MARKETING
          ) && (
            <TabPanel p="1rem 0 0 0" tabIndex={3}>
              <PersonnelBlock config={ANIMATION_MAGASIN_ONLY_CONFIG} />
            </TabPanel>
          )}

        {/* IF STORE_ANIMATION don't need to be hide and STREET_MARKETING don't need to be hide too */}
        {!userInfos?.selectDePersonnelInfo?.tabsToHide?.includes(
          SectionTitle.ANIMATION
        ) &&
          !userInfos?.selectDePersonnelInfo?.tabsToHide?.includes(
            SectionTitle.STREET_MARKETING
          ) && (
            <TabPanel p="1rem 0 0 0" tabIndex={3}>
              <PersonnelBlock config={ANIMATION_CONFIG} />
            </TabPanel>
          )}

        {!userInfos.selectDePersonnelInfo.tabsToHide?.includes(
          SectionTitle.ARTIST
        ) && (
          <TabPanel p="1rem 0 0 0" tabIndex={4}>
            <ConsultationBlock />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}
