import { Flex } from "@chakra-ui/react";
import BottomBlock from "../../components/BottomActionsBlock/BottomBlock";
import Header from "../../components/Header";
import ContentLayout from "../../components/Layout/ContentLayout";
import MainContainer from "../../components/Layout/MainContainer";
import TotalCard from "../../components/TotalCard/TotalCard";
import { useOptionContext } from "../../context/OptionsContext";
import useServerSubmission from "../../hooks/useServerSubmission";
import { PageURL } from "../../types/pages";
import OptionsBlock from "./components/OptionsBlock";
import { useEffect, useState } from "react";

export default function OptionsContent() {
  const {
    optionInfo,
    incrementOptionCount,
    decrementOptionCount,
    editOptionCount,
  } = useOptionContext();
  const { multipleRequestData } = useServerSubmission();
  const [previousHt, setPreviousHt] = useState<any>(undefined);
  const [previousTtc, setPreviousTtc] = useState<any>(undefined);
  const [previousTva, setPreviousTva] = useState<any>(undefined);
  useEffect(() => {
    if (multipleRequestData?.computePriceMultiple?.priceHt)
      setPreviousHt(multipleRequestData?.computePriceMultiple?.priceHt);
    if (multipleRequestData?.computePriceMultiple?.priceTtc)
      setPreviousTtc(multipleRequestData?.computePriceMultiple?.priceTtc);
    if (multipleRequestData?.computePriceMultiple?.priceTva)
      setPreviousTva(multipleRequestData?.computePriceMultiple?.priceTva);
  }, [multipleRequestData]);

  return (
    <ContentLayout>
      <MainContainer>
        <Flex direction="column" width="100%">
          <Header
            title="Les options"
            subtitle="E-Norela vous accompagne sur votre événement avec une large gamme d’options"
          />
          {optionInfo?.selectedOptions && (
            <OptionsBlock
              selectedOptions={optionInfo.selectedOptions}
              optionsToHide={optionInfo.optionsToHide}
              onIncrement={incrementOptionCount}
              onDecrement={decrementOptionCount}
              onEdit={editOptionCount}
            />
          )}
        </Flex>
        <Flex direction="column" alignItems="flex-end">
          <TotalCard
            priceHt={previousHt}
            priceTtc={previousTtc}
            priceTva={previousTva}
            priceHtDiscounted={
              multipleRequestData?.computePriceMultiple?.priceHtDiscounted
            }
            discountPercent={
              multipleRequestData?.computePriceMultiple?.discountPercent
            }
            discountErrorMessage={
              multipleRequestData?.computePriceMultiple?.discountErrorMessage
            }
          />
        </Flex>
      </MainContainer>
      <BottomBlock nextPageLink={PageURL.RECAPITULATIF} />
    </ContentLayout>
  );
}
