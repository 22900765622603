export const DigitalEmargementIcon = () => (
  <svg
    width="182"
    height="157"
    viewBox="0 0 182 157"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3476_90972)">
      <path
        d="M51.129 39.5117H50.8174V42.4043H51.129V39.5117Z"
        fill="#F2F2F2"
      />
      <path
        d="M70.6066 46.5498H70.2949V49.4424H70.6066V46.5498Z"
        fill="#F2F2F2"
      />
      <path d="M68.4269 119.1H68.1152V121.992H68.4269V119.1Z" fill="#F2F2F2" />
      <path
        d="M72.9445 95.1758H72.6328V98.0684H72.9445V95.1758Z"
        fill="#F2F2F2"
      />
      <path
        d="M52.7129 31.3784C52.7129 31.3784 54.9907 37.8839 53.589 38.8847C52.1872 39.8855 58.3199 48.059 58.3199 48.059L65.679 46.0574L61.649 39.2183C61.649 39.2183 61.1234 32.3792 61.1234 31.3784C61.1234 30.3776 52.7129 31.3784 52.7129 31.3784Z"
        fill="#A0616A"
      />
      <path
        opacity="0.1"
        d="M52.7129 31.3784C52.7129 31.3784 54.9907 37.8839 53.589 38.8847C52.1872 39.8855 58.3199 48.059 58.3199 48.059L65.679 46.0574L61.649 39.2183C61.649 39.2183 61.1234 32.3792 61.1234 31.3784C61.1234 30.3776 52.7129 31.3784 52.7129 31.3784Z"
        fill="black"
      />
      <path
        d="M48.8883 77.7494C48.8883 77.7494 48.2234 86.0897 48.8883 90.4267C49.5532 94.7637 50.5506 106.774 50.5506 106.774C50.5506 106.774 50.5506 129.459 54.2077 129.793C57.8649 130.127 60.5246 130.46 60.8571 129.126C61.1895 127.791 59.1947 127.124 60.1921 126.457C61.1895 125.79 61.522 123.454 60.1921 120.785C58.8623 118.117 60.1921 91.0939 60.1921 91.0939L66.509 108.108C66.509 108.108 67.1739 122.12 67.8388 124.122C68.5038 126.123 67.1739 129.793 69.5012 130.127C71.8284 130.46 74.4881 128.459 75.818 127.791C77.1479 127.124 73.8232 127.124 74.4881 126.79C75.1531 126.457 77.1479 125.456 76.4829 125.122C75.818 124.789 75.1531 108.775 75.1531 108.775C75.1531 108.775 73.3245 74.5801 70.9973 73.2457C68.67 71.9112 67.1739 74.2097 67.1739 74.2097L48.8883 77.7494Z"
        fill="#2F2E41"
      />
      <path
        d="M54.8728 128.459V131.128C54.8728 131.128 51.8806 138.383 54.8728 138.383C57.865 138.383 60.1923 139.135 60.1923 138.134V129.126L54.8728 128.459Z"
        fill="#2F2E41"
      />
      <path
        d="M74.4894 128.456V131.125C74.4894 131.125 77.4816 138.38 74.4894 138.38C71.4972 138.38 69.1699 139.132 69.1699 138.131V129.123L74.4894 128.456Z"
        fill="#2F2E41"
      />
      <path
        d="M57.3692 36.2142C60.6742 36.2142 63.3535 33.5256 63.3535 30.2091C63.3535 26.8926 60.6742 24.2041 57.3692 24.2041C54.0641 24.2041 51.3848 26.8926 51.3848 30.2091C51.3848 33.5256 54.0641 36.2142 57.3692 36.2142Z"
        fill="#A0616A"
      />
      <path
        d="M55.539 41.3857C55.539 41.3857 57.2013 46.3899 59.861 45.3891L62.5207 44.3882L67.1752 76.4151C67.1752 76.4151 63.5181 81.7529 58.1987 78.4167C52.8792 75.0806 55.539 41.3857 55.539 41.3857Z"
        fill="#F7B80B"
      />
      <path
        d="M60.1934 40.3861L61.6895 37.2168C61.6895 37.2168 70.4998 41.387 71.8297 42.7214C73.1595 44.0559 73.1595 46.0575 73.1595 46.0575L70.8323 54.3978C70.8323 54.3978 71.4972 72.7465 71.4972 73.4138C71.4972 74.081 73.8244 77.7507 72.1621 76.4163C70.4998 75.0818 70.1673 73.7474 68.8375 75.7491C67.5076 77.7507 64.5154 80.086 64.5154 80.086L60.1934 40.3861Z"
        fill="#3F3D56"
      />
      <path
        d="M71.8301 64.4072L70.8327 73.7484C70.8327 73.7484 64.8483 79.0862 66.1782 79.4198C67.508 79.7534 68.173 78.4189 68.173 78.4189C68.173 78.4189 70.5002 80.7542 71.8301 79.4198C73.16 78.0853 76.4846 65.4081 76.4846 65.4081L71.8301 64.4072Z"
        fill="#A0616A"
      />
      <path
        d="M57.4946 21.7244C56.1679 21.6768 54.7482 21.6533 53.6297 22.3707C53.115 22.7307 52.6636 23.1741 52.2939 23.6827C51.2045 25.0647 50.2632 26.8035 50.6679 28.5182L51.1379 28.3342C51.1378 28.7919 51.0364 29.2439 50.841 29.6574C50.9072 29.4643 51.1288 29.7765 51.0695 29.9718L50.5518 31.6762C51.4029 31.3631 52.4616 31.997 52.5911 32.8973C52.6503 30.9176 52.855 28.6476 54.4554 27.4884C55.2625 26.9038 56.2839 26.7254 57.2666 26.5669C58.1732 26.4207 59.1236 26.2813 59.992 26.5808C60.8604 26.8803 61.5999 27.7715 61.403 28.6714C61.8034 28.533 62.2512 28.8131 62.4491 29.1888C62.647 29.5645 62.6574 30.0077 62.6633 30.4326C63.0901 30.7353 63.5759 30.1342 63.7498 29.6397C64.1581 28.4788 64.521 27.2431 64.3011 26.0321C64.0811 24.821 63.0976 23.6636 61.8708 23.6595C62.0198 23.4954 62.0992 23.2796 62.0924 23.0577L61.0812 22.972C61.3379 22.9355 61.574 22.8106 61.749 22.6187C61.3431 23.0669 58.1511 21.748 57.4946 21.7244Z"
        fill="#2F2E41"
      />
      <path
        d="M57.2008 39.3864C57.2008 39.3864 54.4943 36.725 53.5203 36.8881C52.5462 37.0511 51.2164 39.3864 51.2164 39.3864C51.2164 39.3864 43.2372 42.0553 43.5697 44.7242C43.9021 47.3931 47.5593 60.404 47.5593 60.404C47.5593 60.404 50.5515 76.0837 47.8917 77.7518C45.232 79.4199 60.5254 83.7568 60.8579 81.7552C61.1903 79.7535 61.8553 59.7367 60.8579 56.7342C59.8605 53.7317 57.2008 39.3864 57.2008 39.3864Z"
        fill="#3F3D56"
      />
      <path
        d="M68.8369 44.7227H72.9478C72.9478 44.7227 75.8187 57.3999 76.1512 58.7344C76.4836 60.0688 77.1486 66.4075 76.8161 66.4075C76.4836 66.4075 69.8343 65.073 69.8343 66.0739L68.8369 44.7227Z"
        fill="#3F3D56"
      />
      <path
        d="M68.698 76.1146H52.9602C51.4244 69.0254 51.2961 62.1759 52.9602 55.6318H68.698C66.1583 62.0582 65.9995 68.8608 68.698 76.1146Z"
        fill="#F2F2F2"
      />
      <path
        d="M46.5615 67.408L51.216 69.4097C51.216 69.4097 60.1926 70.7441 60.1926 67.0744C60.1926 63.4047 51.216 65.4063 51.216 65.4063L48.2237 64.4463L46.5615 67.408Z"
        fill="#A0616A"
      />
      <path
        d="M47.5578 42.7217L43.5682 43.7225L40.576 61.404C40.576 61.404 39.5786 66.0746 41.241 66.4082C42.9033 66.7418 47.5578 69.4107 47.5578 69.4107C47.5578 69.4107 48.2227 64.4065 49.5526 64.4065L46.228 61.7376L48.2228 50.0612L47.5578 42.7217Z"
        fill="#3F3D56"
      />
    </g>
    <g clipPath="url(#clip1_3476_90972)">
      <path
        d="M79.6552 88.8091C79.9491 88.5175 80.1752 88.165 80.3178 87.7767C80.4603 87.3884 80.5158 86.9736 80.4803 86.5616C80.4448 86.1495 80.3192 85.7503 80.1123 85.3919C79.9055 85.0336 79.6224 84.7248 79.283 84.4874L83.9108 62.1396L78.9179 63.7113L75.1945 85.331C74.8326 85.9263 74.7022 86.6334 74.8279 87.3183C74.9536 88.0032 75.3267 88.6183 75.8766 89.047C76.4264 89.4757 77.1148 89.6883 77.8112 89.6445C78.5076 89.6006 79.1637 89.3034 79.6552 88.8091Z"
        fill="#FFB6B6"
      />
      <path
        d="M90.3546 29.0717L87.2617 26.7422C87.2617 26.7422 84.5333 26.5495 84.0501 31.4589C83.5669 36.3683 80.5413 55.2104 80.5413 55.2104L75.8086 77.0818L82.36 78.5166L87.429 54.9135L92.5033 38.6298L90.3546 29.0717Z"
        fill="#E6E6E6"
      />
      <path
        d="M89.5575 6.48812C89.2033 8.38702 88.6224 10.2368 87.8273 11.9978C83.2746 21.6076 96.2399 18.4753 100.873 18.4753C105.939 18.4753 110.786 21.1333 110.046 9.32312C109.73 4.27834 105.939 0.170898 100.873 0.170898C96.5462 0.170898 90.4623 2.08994 89.5575 6.48812Z"
        fill="#2F2E41"
      />
      <path
        d="M105.597 147.427H101.281L99.2266 130.816H105.597V147.427Z"
        fill="#FFB6B6"
      />
      <path
        d="M107.875 154.891H104.779L104.227 151.975L102.812 154.891H94.6024C94.2088 154.891 93.8256 154.766 93.5087 154.533C93.1917 154.3 92.9576 153.973 92.8404 153.598C92.7233 153.223 92.7294 152.821 92.8577 152.449C92.986 152.078 93.2299 151.758 93.5537 151.535L100.11 147.017V144.069L107.005 144.48L107.875 154.891Z"
        fill="#2F2E41"
      />
      <path
        d="M131.238 141.994L127.775 144.564L116.192 132.457L121.304 128.664L131.238 141.994Z"
        fill="#FFB6B6"
      />
      <path
        d="M137.529 146.626L135.045 148.469L132.858 146.458L133.466 149.64L126.878 154.528C126.563 154.762 126.18 154.89 125.786 154.892C125.393 154.894 125.009 154.77 124.691 154.539C124.373 154.308 124.137 153.982 124.018 153.607C123.899 153.233 123.903 152.831 124.029 152.459L126.588 144.931L124.826 142.565L130.605 138.789L137.529 146.626Z"
        fill="#2F2E41"
      />
      <path
        d="M91.46 70.9797L111.949 67.7957L107.874 55.5654H91.46V70.9797Z"
        fill="#FFB6B6"
      />
      <path
        d="M110.62 61.7256C110.62 61.7256 118.81 68.785 115.031 88.9096L110.418 108.628L122.986 130.399L116.596 133.877L101.108 112.399L94.0212 95.8123L90.2725 66.742L110.62 61.7256Z"
        fill="#2F2E41"
      />
      <path
        d="M97.9282 100.539L97.4355 108.15L98.8775 130.689L106.036 133.148L107.392 110.055L97.9282 100.539Z"
        fill="#2F2E41"
      />
      <path
        d="M95.7272 21.0098L103.647 21.3181L106.803 26.5459L114.005 27.9486L109.706 49.649L110.496 62.0006L103.326 61.957C98.1579 61.9256 93.1604 63.7989 89.2917 67.2176L89.5062 51.1842L86.4219 39.2155L87.1151 26.6655L92.9083 25.943L95.7272 21.0098Z"
        fill="#E6E6E6"
      />
      <path
        d="M100.114 18.4721C104.231 18.4721 107.568 15.1423 107.568 11.0349C107.568 6.92741 104.231 3.59766 100.114 3.59766C95.9973 3.59766 92.6602 6.92741 92.6602 11.0349C92.6602 15.1423 95.9973 18.4721 100.114 18.4721Z"
        fill="#FFB6B6"
      />
      <path
        d="M95.0517 3.45412C96.3578 2.65962 97.8539 2.22984 99.3835 2.20972C100.913 2.1896 102.42 2.57988 103.747 3.33975C105.073 4.09961 106.171 5.20115 106.925 6.52919C107.679 7.85723 108.061 9.36299 108.033 10.889C104.234 11.8405 100.244 12.5686 96.194 11.1166L95.2374 8.78079L94.6735 11.1183C93.44 11.6214 92.2034 12.047 90.9667 11.1135C90.9162 9.59175 91.2669 8.08344 91.9838 6.73934C92.7006 5.39523 93.7585 4.26241 95.0517 3.45412Z"
        fill="#2F2E41"
      />
      <path
        d="M119.611 86.8821C119.319 86.589 119.094 86.2354 118.954 85.8464C118.813 85.4573 118.76 85.0423 118.797 84.6304C118.835 84.2186 118.963 83.8199 119.171 83.4626C119.38 83.1053 119.665 82.798 120.005 82.5624L115.491 60.1914L120.476 61.7883L124.089 83.4267C124.448 84.0238 124.575 84.7316 124.446 85.4158C124.317 86.1 123.94 86.7132 123.388 87.1391C122.836 87.565 122.147 87.7741 121.451 87.7267C120.755 87.6794 120.1 87.3789 119.611 86.8821Z"
        fill="#FFB6B6"
      />
      <path
        d="M110.912 30.2778L114.005 27.9483C114.005 27.9483 116.734 27.7555 117.217 32.6649C117.7 37.5743 120.726 56.4165 120.726 56.4165L125.458 78.2879L118.907 79.7227L113.838 56.1196L108.764 39.8358L110.912 30.2778Z"
        fill="#E6E6E6"
      />
      <path
        d="M78.9312 88.3413L64.0952 76.8311C63.8641 76.6514 63.7139 76.3876 63.6775 76.0975C63.6411 75.8075 63.7215 75.5149 63.9011 75.2839L73.9954 62.3305C74.1755 62.1 74.4399 61.9501 74.7306 61.9138C75.0214 61.8775 75.3146 61.9578 75.5461 62.1369L90.3822 73.6471C90.6132 73.8268 90.7634 74.0906 90.7998 74.3807C90.8362 74.6707 90.7558 74.9634 90.5762 75.1943L80.4819 88.1477C80.3018 88.3782 80.0374 88.5281 79.7467 88.5644C79.456 88.6007 79.1627 88.5204 78.9312 88.3413Z"
        fill="#3F3D56"
      />
      <path
        d="M78.8485 87.15L65.271 76.6162C65.0659 76.4567 64.9325 76.2224 64.9002 75.9649C64.8679 75.7073 64.9393 75.4475 65.0987 75.2424L74.2497 63.4997C74.4096 63.295 74.6443 63.1619 74.9025 63.1297C75.1606 63.0974 75.421 63.1687 75.6265 63.3278L89.204 73.8615C89.4092 74.0211 89.5426 74.2553 89.5749 74.5128C89.6072 74.7704 89.5358 75.0302 89.3763 75.2353L80.2254 86.978C80.0655 87.1828 79.8307 87.3158 79.5726 87.3481C79.3145 87.3803 79.0541 87.3091 78.8485 87.15Z"
        fill="white"
      />
      <path
        d="M67.0516 76.3179C67.0003 76.278 66.967 76.2194 66.9589 76.155C66.9509 76.0907 66.9687 76.0257 67.0086 75.9744L71.625 70.0506C71.6649 69.9993 71.7236 69.966 71.7881 69.9579C71.8201 69.954 71.8525 69.9563 71.8836 69.9648C71.9146 69.9733 71.9437 69.9879 71.9691 70.0076C71.9946 70.0273 72.0158 70.0518 72.0318 70.0798C72.0477 70.1077 72.0579 70.1385 72.0619 70.1704C72.0659 70.2023 72.0636 70.2346 72.0551 70.2656C72.0466 70.2966 72.032 70.3256 72.0122 70.351L67.3958 76.2749C67.3558 76.3261 67.2972 76.3593 67.2326 76.3674C67.1681 76.3754 67.103 76.3576 67.0516 76.3179Z"
        fill="#E4E4E4"
      />
      <path
        d="M67.8163 76.9107C67.765 76.8708 67.7317 76.8122 67.7236 76.7478C67.7155 76.6834 67.7334 76.6185 67.7732 76.5672L72.3896 70.6433C72.4295 70.5921 72.4882 70.5588 72.5528 70.5507C72.6173 70.5427 72.6824 70.5605 72.7338 70.6004C72.7852 70.6402 72.8185 70.6988 72.8266 70.7632C72.8347 70.8276 72.8168 70.8925 72.7769 70.9438L68.1605 76.8677C68.1205 76.9189 68.0618 76.9521 67.9973 76.9602C67.9328 76.9682 67.8677 76.9504 67.8163 76.9107Z"
        fill="#E4E4E4"
      />
      <path
        d="M71.1971 74.1477C71.1459 74.1079 71.1125 74.0493 71.1044 73.9849C71.0964 73.9205 71.1142 73.8556 71.1541 73.8043L73.1552 71.2364C73.1749 71.211 73.1995 71.1897 73.2275 71.1738C73.2555 71.1578 73.2864 71.1475 73.3184 71.1435C73.3504 71.1395 73.3829 71.1418 73.414 71.1503C73.4451 71.1588 73.4742 71.1734 73.4996 71.1931C73.5251 71.2129 73.5464 71.2374 73.5623 71.2654C73.5782 71.2934 73.5885 71.3242 73.5925 71.3562C73.5964 71.3881 73.5941 71.4205 73.5855 71.4515C73.5769 71.4825 73.5623 71.5115 73.5424 71.5369L71.5414 74.1048C71.5014 74.1559 71.4427 74.1892 71.3782 74.1973C71.3136 74.2053 71.2485 74.1875 71.1971 74.1477Z"
        fill="#E4E4E4"
      />
      <path
        d="M72.2294 79.1883C72.1781 79.1484 72.1447 79.0899 72.1367 79.0255C72.1286 78.9611 72.1464 78.8962 72.1863 78.8449L79.4741 69.4929C79.4939 69.4676 79.5185 69.4463 79.5465 69.4304C79.5745 69.4145 79.6054 69.4043 79.6373 69.4003C79.6693 69.3963 79.7017 69.3987 79.7328 69.4072C79.7638 69.4157 79.7929 69.4302 79.8183 69.45C79.8438 69.4697 79.8651 69.4942 79.881 69.5222C79.8969 69.5501 79.9072 69.5809 79.9112 69.6128C79.9152 69.6447 79.9128 69.677 79.9043 69.708C79.8957 69.739 79.8812 69.768 79.8614 69.7934L72.5736 79.1454C72.5336 79.1965 72.4749 79.2298 72.4104 79.2379C72.3458 79.2459 72.2808 79.2281 72.2294 79.1883Z"
        fill="#E4E4E4"
      />
      <path
        d="M72.9931 79.7811C72.9418 79.7413 72.9084 79.6827 72.9003 79.6183C72.8923 79.5539 72.9101 79.489 72.95 79.4377L80.2378 70.0857C80.2576 70.0603 80.2822 70.0391 80.3102 70.0232C80.3382 70.0073 80.369 69.9971 80.401 69.9931C80.433 69.9891 80.4654 69.9914 80.4965 70C80.5275 70.0085 80.5566 70.023 80.582 70.0427C80.6074 70.0625 80.6287 70.087 80.6447 70.1149C80.6606 70.1429 80.6708 70.1737 80.6748 70.2056C80.6788 70.2375 80.6765 70.2698 80.668 70.3008C80.6594 70.3318 80.6449 70.3608 80.6251 70.3862L73.3373 79.7382C73.2973 79.7893 73.2386 79.8226 73.1741 79.8307C73.1095 79.8387 73.0444 79.8209 72.9931 79.7811Z"
        fill="#E4E4E4"
      />
      <path
        d="M74.7323 81.1288C74.681 81.0889 74.6477 81.0303 74.6396 80.9659C74.6315 80.9016 74.6494 80.8366 74.6892 80.7853L81.9771 71.4334C81.9969 71.408 82.0214 71.3868 82.0494 71.3709C82.0774 71.355 82.1083 71.3448 82.1403 71.3408C82.1722 71.3368 82.2047 71.3391 82.2357 71.3476C82.2668 71.3561 82.2958 71.3707 82.3213 71.3904C82.3467 71.4101 82.368 71.4347 82.3839 71.4626C82.3998 71.4905 82.4101 71.5213 82.4141 71.5532C82.4181 71.5851 82.4157 71.6175 82.4072 71.6484C82.3987 71.6794 82.3841 71.7084 82.3643 71.7338L75.0765 81.0858C75.0365 81.137 74.9778 81.1702 74.9133 81.1783C74.8488 81.1863 74.7837 81.1685 74.7323 81.1288Z"
        fill="#E4E4E4"
      />
      <path
        d="M75.496 81.7225C75.4447 81.6827 75.4113 81.6241 75.4033 81.5597C75.3952 81.4953 75.413 81.4304 75.4529 81.3791L82.7408 72.0271C82.7605 72.0018 82.7851 71.9805 82.8131 71.9646C82.8411 71.9487 82.872 71.9385 82.9039 71.9345C82.9359 71.9305 82.9683 71.9328 82.9994 71.9414C83.0304 71.9499 83.0595 71.9644 83.0849 71.9841C83.1104 72.0039 83.1317 72.0284 83.1476 72.0564C83.1635 72.0843 83.1738 72.1151 83.1778 72.147C83.1818 72.1789 83.1794 72.2112 83.1709 72.2422C83.1624 72.2732 83.1478 72.3022 83.128 72.3276L75.8402 81.6796C75.8002 81.7307 75.7415 81.764 75.677 81.7721C75.6125 81.7801 75.5474 81.7623 75.496 81.7225Z"
        fill="#E4E4E4"
      />
      <path
        d="M77.2323 83.0702C77.181 83.0303 77.1477 82.9717 77.1396 82.9073C77.1315 82.843 77.1494 82.778 77.1892 82.7268L84.4771 73.3748C84.4969 73.3494 84.5214 73.3282 84.5494 73.3123C84.5774 73.2964 84.6083 73.2862 84.6403 73.2822C84.6722 73.2782 84.7046 73.2805 84.7357 73.289C84.7668 73.2975 84.7958 73.3121 84.8213 73.3318C84.8467 73.3515 84.868 73.3761 84.8839 73.404C84.8998 73.4319 84.9101 73.4627 84.9141 73.4946C84.9181 73.5265 84.9157 73.5589 84.9072 73.5899C84.8987 73.6208 84.8841 73.6499 84.8643 73.6752L77.5765 83.0272C77.5365 83.0784 77.4778 83.1116 77.4133 83.1197C77.3488 83.1278 77.2837 83.1099 77.2323 83.0702Z"
        fill="#E4E4E4"
      />
      <path
        d="M77.9989 83.6643C77.9476 83.6244 77.9143 83.5658 77.9062 83.5014C77.8981 83.4371 77.916 83.3721 77.9558 83.3208L85.2437 73.9689C85.2837 73.9179 85.3423 73.8848 85.4068 73.8768C85.4712 73.8689 85.5361 73.8868 85.5874 73.9265C85.6386 73.9663 85.672 74.0247 85.6802 74.089C85.6883 74.1532 85.6706 74.2181 85.631 74.2694L78.3431 83.6213C78.3031 83.6725 78.2444 83.7058 78.1799 83.7138C78.1154 83.7219 78.0503 83.7041 77.9989 83.6643Z"
        fill="#E4E4E4"
      />
      <path
        d="M75.1522 69.4381C76.1191 69.4381 76.9029 68.656 76.9029 67.6912C76.9029 66.7264 76.1191 65.9443 75.1522 65.9443C74.1852 65.9443 73.4014 66.7264 73.4014 67.6912C73.4014 68.656 74.1852 69.4381 75.1522 69.4381Z"
        fill="#F7B80B"
      />
    </g>
    <defs>
      <clipPath id="clip0_3476_90972">
        <rect
          width="39.2666"
          height="116.955"
          fill="white"
          transform="translate(40.3389 21.7041)"
        />
      </clipPath>
      <clipPath id="clip1_3476_90972">
        <rect
          width="76.8924"
          height="155.506"
          fill="white"
          transform="translate(60.4229 0.170898)"
        />
      </clipPath>
    </defs>
  </svg>
);
