import { createContext, useContext, useEffect, useState } from "react";

const ACTUAL_VERSION = "0.1.30";

export type VersionContextType = {
  version: string;
};

export const VersionContext = createContext({
  version: ACTUAL_VERSION,
} as VersionContextType);

export function VersionContextProvider({ children }: { children: any }) {
  function detectWrongVersion(): boolean {
    const version = localStorage.getItem("version");
    return version === ACTUAL_VERSION;
  }

  function correctVersion() {
    localStorage.clear();
    localStorage.setItem("version", ACTUAL_VERSION);
  }
  const [isOk, setIsOk] = useState<boolean>(false);
  useEffect(() => {
    if (!detectWrongVersion()) {
      correctVersion();
    }
    setIsOk(true);
  }, []);

  return (
    <VersionContext.Provider
      value={{
        version: ACTUAL_VERSION,
      }}
    >
      {isOk ? children : null}
    </VersionContext.Provider>
  );
}

export function useVersionContext() {
  const context = useContext(VersionContext);
  return context;
}
