export const BottomPopupsIcon = () => (
  <svg width="390" height="259" viewBox="0 0 390 259" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1647_76850)">
      <path d="M293.334 10.0064C290.388 11.6577 285.632 5.12619 284.555 8.32105C283.477 11.5159 286.087 24.407 287.434 27.4987C288.103 29.0354 289.049 30.4409 289.706 31.9831C290.363 33.5252 290.715 35.2984 290.134 36.8706C289.124 39.6068 285.79 40.7199 284.137 43.1248C282.211 45.9257 282.989 49.6983 283.889 52.9735C284.665 55.7965 285.492 58.645 286.985 61.1649C288.479 63.6847 290.73 65.8788 293.543 66.7125C297.126 67.774 300.954 66.5125 304.407 65.0883C307.727 63.719 311.012 62.1668 313.835 59.9515C316.659 57.7362 319.015 54.7958 320.002 51.3506C321.24 47.0291 320.206 42.2997 318.062 38.3463C315.918 34.3929 312.747 31.0994 309.493 27.9899C304.013 22.7529 298.181 17.8821 292.049 13.421L293.334 10.0064Z" fill="#2F2E41"/>
      <path d="M337.154 231.749L334.372 257.642L334.246 258.826L302.774 258.704L302.608 257.516L299.276 233.789L337.154 231.749Z" fill="#FFB6B6"/>
      <path d="M289.034 257.465C289.034 257.855 289.03 258.25 289.027 258.649L256.356 258.522L256.25 257.334L254.235 234.811L288.017 230.727C288.017 230.727 289.106 242.673 289.034 257.465Z" fill="#FFB6B6"/>
      <path d="M285.398 55.875C290.588 62.3947 299.737 64.284 307.121 60.4033C317.661 54.8639 332.052 48.5152 334.595 53.5881C338.69 61.756 338.69 69.6826 338.69 69.6826L342.785 164.875C342.785 164.875 344.833 203.31 319.24 199.917C293.647 196.525 253.722 210.819 253.722 210.819L257.8 168.648L268.689 152.748L273.101 107.132L265.494 70.4343L274.708 66.3504C274.708 66.3504 275.732 59.2035 278.803 59.2035C281.874 59.2035 282.616 52.338 285.316 55.7708C285.343 55.8051 285.37 55.8399 285.398 55.875V55.875Z" fill="#F7B80B"/>
      <path d="M340.92 258.854L334.245 258.826L302.773 258.704L298.678 258.688L298.44 257.5L292.109 225.624L291.12 257.473L291.084 258.657L289.026 258.649L256.356 258.523L254.014 258.511L254.006 257.327L253.867 234.816L253.741 213.97L253.721 210.817L253.725 210.813L263.507 191.037L266.36 185.267L266.945 184.083L272.66 172.531L324.364 165.392L325.155 165.281L339.203 163.344L339.306 169.086L339.365 172.346L339.575 184.083L339.599 185.267L339.622 186.62L340.897 257.666L340.92 258.854Z" fill="#2F2E41"/>
      <path d="M300.694 52.8469C312.501 52.8469 322.073 43.3006 322.073 31.5246C322.073 19.7487 312.501 10.2024 300.694 10.2024C288.886 10.2024 279.314 19.7487 279.314 31.5246C279.314 43.3006 288.886 52.8469 300.694 52.8469Z" fill="#FFB6B6"/>
      <path d="M324.795 64.9182C319.805 72.0173 317.253 80.7887 317.658 89.449C312.001 86.7157 308.182 80.9408 306.882 74.8065C305.583 68.6722 306.574 62.2313 308.663 56.3176C308.684 56.2585 308.705 56.1994 308.726 56.1403C311.897 47.264 308.95 37.3435 301.291 31.8257C296.125 28.1038 291.645 23.4345 288.206 18.0822C286.265 15.0605 284.614 11.2701 286.065 7.98576C287.401 4.95938 290.854 3.54489 294.031 2.59844C301.285 0.437292 308.993 -0.792432 316.439 0.575082C323.885 1.9426 331.054 6.21319 334.573 12.899C338.092 19.5848 337.17 28.7092 331.458 33.6656C333.259 34.2465 334.534 36.151 334.381 38.0331C334.228 39.9152 332.662 41.59 330.791 41.8738C332.012 41.8307 332.733 43.4348 332.297 44.5735C331.862 45.7123 330.739 46.4196 329.667 47.0057C328.141 47.8406 326.449 48.7137 326.443 50.9C326.44 51.7767 326.754 52.6275 327.266 53.34C328.507 55.0666 330.51 55.8442 332.439 56.5742C328.36 57.7725 325.107 61.4091 324.376 65.5875L324.795 64.9182Z" fill="#2F2E41"/>
      <path d="M233.629 56.6696C233.046 50.5649 235.731 45.3163 239.627 44.9466C243.523 44.577 247.152 49.2258 247.735 55.3327C248.001 57.769 247.679 60.2335 246.795 62.52L248.939 88.417L236.732 89.1616L235.914 63.5527C234.612 61.4732 233.829 59.113 233.629 56.6696Z" fill="#FFB6B6"/>
      <path d="M300.135 68.9548C300.135 68.9548 280.192 57.2553 276.086 59.0102C271.981 60.7651 252.038 90.5991 252.038 90.5991L250.204 85.1546L236.201 86.5042C236.201 86.5042 227.989 124.528 246.172 121.018C264.355 117.508 294.269 84.7493 294.269 84.7493L300.135 68.9548H300.135Z" fill="#F7B80B"/>
      <path d="M115.111 131.818C115.111 131.818 126.371 125.234 129.404 131.339C132.436 137.443 160.451 210.271 160.451 210.271L142.398 213.776L115.111 131.817V131.818Z" fill="#F7B80B"/>
      <path d="M90.5515 88.3119L91.0822 89.0656C89.1034 90.2049 88.8116 93.0793 89.7395 95.1649C90.6674 97.2507 92.4635 98.7966 94.0171 100.472C95.5706 102.148 96.9871 104.223 96.843 106.501C96.6813 109.064 94.5865 111.174 94.2947 113.726C93.8729 117.442 97.3421 120.702 97.1698 124.438C97.0398 127.292 94.8395 129.584 92.6568 131.435C90.9486 132.887 89.0893 134.345 87.9364 136.217C87.6201 136.732 87.3565 137.282 87.1632 137.871C86.3021 140.528 87.114 143.424 88.2071 145.993C89.2967 148.563 90.685 151.072 91.026 153.842C91.3634 156.611 90.316 159.808 87.7607 160.944C92.8501 160.509 97.8166 158.739 102.031 155.861C102.41 155.601 102.779 155.335 103.145 155.062C109.12 150.575 113.317 144.093 117.355 137.777C117.689 137.258 118.02 136.735 118.354 136.217C119.25 134.811 120.15 133.409 121.049 132.003C123.615 127.986 126.213 123.909 127.612 119.359C128.898 115.163 129.116 110.729 129.643 106.372C129.9 104.237 130.237 102.109 130.3 99.9603C130.402 96.6547 129.836 93.3351 128.684 90.2329C127.555 87.1832 125.861 84.3403 123.678 81.9216C121.443 79.4433 118.709 77.4277 115.7 75.9764C112.786 74.5708 109.616 73.6979 106.386 73.4561C105.303 73.3755 104.224 73.3614 103.145 73.4105C98.8886 73.5858 94.6814 74.6584 90.5901 75.9764C87.5814 77.4277 84.8469 79.4433 82.6115 81.9216C80.4288 84.3403 78.7347 87.1832 77.6064 90.2329C81.529 88.1436 86.1896 87.4251 90.5515 88.3119Z" fill="#2F2E41"/>
      <path d="M121.057 126.66L100.875 118.767L100.364 126.66L82.6723 129.423L74.3623 166.915L81.8809 190.2L138.072 191.778L136.094 183.885C136.094 183.885 145.195 173.624 139.655 157.049L139.04 152.83C137.431 141.801 130.79 132.137 121.057 126.66L121.057 126.66Z" fill="#F7B80B"/>
      <path d="M109.824 118.413C119.724 118.413 127.749 110.409 127.749 100.535C127.749 90.6617 119.724 82.6577 109.824 82.6577C99.924 82.6577 91.8984 90.6617 91.8984 100.535C91.8984 110.409 99.924 118.413 109.824 118.413Z" fill="#A0616A"/>
      <path d="M75.9902 99.9603C76.0535 102.109 76.3909 104.237 76.6475 106.372C77.1747 110.729 77.3926 115.163 78.6791 119.359C80.0779 123.909 82.6754 127.986 85.2412 132.003C86.141 133.409 87.0408 134.811 87.937 136.217C88.271 136.735 88.6013 137.258 88.9353 137.777C92.9738 144.093 97.1704 150.575 103.146 155.062C103.511 155.335 103.88 155.601 104.26 155.861C108.474 158.739 113.44 160.509 118.53 160.944C115.975 159.808 114.927 156.611 115.265 153.842C115.606 151.072 116.994 148.563 118.084 145.993C119.177 143.424 119.989 140.528 119.127 137.871C118.934 137.282 118.67 136.732 118.354 136.217C117.201 134.345 115.342 132.887 113.634 131.435C111.451 129.584 109.251 127.292 109.121 124.438C108.949 120.702 106.555 121.953 106.133 118.237C105.841 115.686 102.429 111.335 102.267 108.773C102.123 106.494 103.525 100.984 105.079 99.3083C106.632 97.6328 105.205 92.6306 106.133 90.5449C107.061 88.4592 117.187 90.2049 115.208 89.0656L115.739 88.3119C120.101 87.4251 124.762 88.1436 128.684 90.2329C129.538 90.6851 130.354 91.2004 131.127 91.7823C132.993 88.382 130.779 84.0669 127.767 81.6166C124.818 79.2119 121.184 77.8238 117.577 76.6004C116.955 76.3901 116.33 76.1798 115.7 75.9764C111.609 74.6584 107.402 73.5858 103.146 73.4105C102.067 73.3614 100.987 73.3755 99.905 73.4561C96.6749 73.6979 93.5045 74.5708 90.5907 75.9764C87.582 77.4277 84.8475 79.4433 82.6121 81.9216C80.4294 84.3403 78.7353 87.1832 77.607 90.2329C76.4542 93.3351 75.8883 96.6547 75.9902 99.9603H75.9902Z" fill="#2F2E41"/>
      <path d="M388.893 242.874L363.908 190.516C362.044 186.609 358.035 184.083 353.694 184.083H38.1043C36.9052 184.083 35.7379 184.268 34.6377 184.623C31.856 185.511 29.4896 187.453 28.0888 190.121L1.30675 241.039C-0.545168 244.556 -0.422473 248.692 1.63522 252.094C3.68895 255.499 7.29395 257.544 11.2788 257.56L254.014 258.511L256.356 258.523L289.027 258.649L291.084 258.657L298.678 258.688L302.774 258.704L334.245 258.826L340.92 258.854L378.636 259H378.684C382.577 259 386.143 257.043 388.232 253.763C390.329 250.468 390.575 246.395 388.893 242.874Z" fill="white"/>
      <path d="M388.893 242.874L363.908 190.516C362.044 186.609 358.035 184.083 353.694 184.083H38.1043C36.9052 184.083 35.7379 184.268 34.6377 184.623C31.856 185.511 29.4896 187.453 28.0888 190.121L1.30675 241.039C-0.545168 244.556 -0.422473 248.692 1.63522 252.094C3.68895 255.499 7.29395 257.544 11.2788 257.56L254.014 258.511L256.356 258.523L289.027 258.649L291.084 258.657L298.678 258.688L302.774 258.704L334.245 258.826L340.92 258.854L378.636 259H378.684C382.577 259 386.143 257.043 388.232 253.763C390.329 250.468 390.575 246.395 388.893 242.874ZM387.231 253.128C385.359 256.068 382.17 257.816 378.684 257.816H378.64L340.897 257.666L334.371 257.642L302.608 257.516L298.441 257.5L291.12 257.473L289.034 257.465L256.25 257.335L254.006 257.327L11.2827 256.376C7.71739 256.36 4.4883 254.533 2.65223 251.486C0.8121 248.439 0.701387 244.737 2.35941 241.588L29.1413 190.674C30.1068 188.838 31.5789 187.386 33.3399 186.451C34.7763 185.685 36.4067 185.267 38.1043 185.267H353.694C357.58 185.267 361.165 187.528 362.835 191.025L387.821 243.38C389.328 246.537 389.107 250.18 387.231 253.128Z" fill="#3F3D56"/>
      <path d="M112.153 221.969L171.906 200.263L142.03 188.279L82.4746 203.815L112.153 221.969Z" fill="#F2F2F2"/>
      <path d="M203.167 189.213L196.439 210.524L275.187 215.26L268.459 191.186L203.167 189.213Z" fill="#F2F2F2"/>
      <path d="M110.343 192.859C115.88 193.406 120.085 196.699 119.735 200.213C119.385 203.727 114.613 206.131 109.075 205.583C106.859 205.394 104.721 204.678 102.84 203.497L79.4102 200.881L80.8752 189.942L103.817 193.682C105.895 192.891 108.133 192.609 110.343 192.859H110.343Z" fill="#A0616A"/>
      <path d="M86.8272 131.594L82.6722 129.423C82.6722 129.423 76.1429 129.62 71.7901 133.962C67.4372 138.303 32.2187 188.029 32.2187 188.029C32.2187 188.029 23.513 201.448 45.2772 203.421C67.0415 205.394 91.1801 205.394 91.1801 205.394L93.1586 189.213L60.3144 185.661L83.3141 155.273L86.8272 131.594Z" fill="#F7B80B"/>
      <path d="M210.302 126.337C206.309 127.517 202.091 128.184 197.734 128.247C197.477 128.255 197.22 128.259 196.962 128.259C193.021 128.259 189.195 127.773 185.534 126.858L185.629 125.922L187.758 105.444L187.893 104.134L185.918 98.2256H210.144L209.756 103.999L209.613 106.103L209.823 106.77L215.165 123.748L215.403 124.506C213.749 125.212 212.047 125.824 210.302 126.337H210.302Z" fill="#F7B80B"/>
      <path d="M185.534 126.858C179.784 125.425 174.45 122.931 169.753 119.604C167.818 118.558 165.938 117.425 164.138 116.226L165.028 115.78L187.398 104.623L187.758 105.444L197.39 127.465L197.734 128.247C197.477 128.255 197.219 128.259 196.962 128.259C193.021 128.259 189.194 127.773 185.534 126.858Z" fill="#2F2E41"/>
      <path d="M234.27 109.919C234.171 110.053 234.068 110.184 233.966 110.314C232.296 112.429 230.444 114.395 228.441 116.19L228.426 115.137L228.37 111.257C228.37 111.257 229.969 110.093 231.943 109.789C232.414 109.714 232.909 109.69 233.408 109.746C233.696 109.773 233.981 109.829 234.27 109.919Z" fill="#2F2E41"/>
      <path d="M228.441 116.19C224.611 119.636 220.211 122.461 215.403 124.506C213.749 125.212 212.047 125.824 210.302 126.337L210.282 125.52L209.823 106.77L209.756 104L231.944 109.789L233.123 110.097L233.966 110.314C232.296 112.429 230.444 114.395 228.441 116.19Z" fill="#2F2E41"/>
      <path d="M198.259 91.8234C208.074 91.8234 216.03 83.8881 216.03 74.0994C216.03 64.3108 208.074 56.3755 198.259 56.3755C188.444 56.3755 180.487 64.3108 180.487 74.0994C180.487 83.8881 188.444 91.8234 198.259 91.8234Z" fill="#A0616A"/>
      <path d="M210.283 125.52C206.192 126.751 201.867 127.426 197.391 127.466L197.735 128.247C202.092 128.184 206.31 127.517 210.303 126.337L210.283 125.52ZM165.03 115.78C155.908 107.374 150.119 95.4236 149.886 82.1474C140.574 92.8978 149.826 106.695 164.139 116.226C165.94 117.426 167.819 118.558 169.754 119.604C168.096 118.432 166.517 117.153 165.03 115.78ZM233.813 52.0866C225.178 41.273 211.87 34.3311 196.964 34.3311C171.001 34.3311 149.874 55.3977 149.874 81.295C149.874 81.5791 149.878 81.8633 149.886 82.1474C150.131 81.8593 150.392 81.5752 150.665 81.295C163.724 67.8767 171.436 35.1204 196.964 35.1204C211.886 35.1204 225.182 42.1965 233.654 53.164C239.677 60.9545 243.262 70.7143 243.262 81.295C243.262 82.8302 243.187 84.3496 243.037 85.8453C242.942 86.8399 242.811 87.8265 242.649 88.8013C241.47 95.9643 238.632 102.583 234.545 108.238C234.177 108.751 233.797 109.252 233.409 109.746C233.314 109.864 233.223 109.982 233.124 110.097C231.684 111.893 230.113 113.578 228.427 115.137C224.561 118.72 220.081 121.648 215.167 123.748C213.584 124.431 211.953 125.023 210.283 125.52C206.192 126.751 201.867 127.426 197.391 127.466C197.249 127.47 197.106 127.47 196.964 127.47C193.378 127.47 189.536 126.921 185.63 125.923C180.296 124.561 174.839 122.367 169.754 119.604C174.451 122.931 179.786 125.425 185.535 126.858C189.196 127.773 193.022 128.259 196.964 128.259C197.221 128.259 197.478 128.255 197.735 128.247C202.092 128.184 206.31 127.517 210.303 126.337C212.048 125.824 213.75 125.212 215.404 124.506C220.212 122.461 224.612 119.636 228.443 116.19C230.445 114.395 232.297 112.429 233.967 110.314C234.07 110.184 234.173 110.053 234.272 109.919C234.406 109.746 234.541 109.568 234.671 109.39C239.147 103.443 242.233 96.4023 243.46 88.75C243.618 87.7673 243.745 86.7728 243.84 85.7664C243.982 84.2943 244.054 82.8025 244.054 81.295C244.054 70.2644 240.223 60.1099 233.813 52.0866V52.0866Z" fill="#E6E6E6"/>
      <path d="M182.342 83.5281C179.144 80.5195 176.914 76.5313 179.146 68.0297C179.312 67.3968 178.867 66.7173 178.437 66.2232C178.007 65.7291 177.196 65.4994 176.686 65.9106C177.049 64.8433 182.029 61.031 182.392 59.9637C181.968 60.5252 178.73 61.7291 178.47 61.0753C178.211 60.4215 178.438 59.6868 178.666 59.0214L180.025 55.0548C180.367 54.057 181.716 55.2491 182.392 54.4385C183.603 52.9865 182.221 51.4558 184.071 51.0487C190.124 49.7163 196.54 48.526 202.421 50.4816C204.989 51.3359 207.325 52.755 209.636 54.1624L213.864 56.7377C216.29 58.215 218.925 59.9676 219.589 62.7237L215.149 61.1412C216.138 63.2413 217.136 65.3675 217.588 67.6435C218.041 69.9194 216.817 78.4892 215.632 80.4858C214.591 82.2398 213.854 77.529 213.511 79.5386C213.903 75.591 212.122 71.4962 208.963 69.0854C208.315 68.5909 207.576 68.1154 207.288 67.3542C206.967 66.5053 207.289 65.4845 206.85 64.6896C206.305 63.7013 204.88 63.6071 203.818 63.995C202.756 64.3829 201.827 65.112 200.727 65.3733C197.809 66.066 195.155 63.315 192.164 63.1081C189.314 62.911 186.764 65.1361 185.579 67.7286C184.394 70.3211 181.116 69.7599 180.809 72.5927C180.503 75.4254 184.978 81.0315 183.184 83.2483L182.342 83.5281Z" fill="#2F2E41"/>
      <path d="M180.098 109.955L178.773 108.925L178.464 108.688C178.167 108.455 177.776 108.376 177.408 108.467L175.856 108.854C175.694 108.893 175.52 108.846 175.401 108.727L173.66 106.991C173.541 106.873 173.494 106.699 173.533 106.537L173.921 104.99C174.016 104.623 173.933 104.236 173.7 103.936L172.43 102.306C172.398 102.267 172.362 102.227 172.327 102.192C171.844 101.71 171.064 101.71 170.581 102.192L169.849 102.922C169.323 103.443 169.094 104.228 169.351 104.955C170.008 106.817 171.076 108.51 172.481 109.903C172.98 110.401 173.514 110.859 174.08 111.269C175.108 112.011 176.24 112.603 177.443 113.025C178.175 113.282 178.959 113.053 179.481 112.532L180.213 111.798C180.74 111.277 180.684 110.413 180.098 109.955ZM179.679 111.269L178.947 111.999C178.603 112.338 178.12 112.465 177.693 112.315C176.683 111.964 175.73 111.482 174.847 110.882C174.19 110.444 173.577 109.935 173.015 109.371C171.693 108.056 170.684 106.462 170.063 104.706C169.913 104.28 170.039 103.798 170.38 103.455L171.116 102.725C171.128 102.709 171.143 102.697 171.159 102.685C171.369 102.523 171.67 102.559 171.832 102.768L173.102 104.398C173.193 104.513 173.225 104.666 173.189 104.808L172.801 106.356C172.695 106.774 172.821 107.22 173.126 107.524L174.867 109.26C175.172 109.568 175.619 109.69 176.038 109.584L177.59 109.197C177.732 109.162 177.886 109.193 178.001 109.284L178.021 109.3L179.635 110.551C179.651 110.563 179.667 110.578 179.679 110.59C179.869 110.78 179.869 111.08 179.679 111.269Z" fill="#CCCCCC"/>
      <path d="M195.546 106.388C195.742 106.388 195.901 106.546 195.901 106.741V107.447C195.901 109.008 197.169 110.272 198.733 110.272C200.298 110.272 201.566 109.008 201.566 107.447V106.741C201.566 106.546 201.724 106.388 201.92 106.388C202.115 106.388 202.274 106.546 202.274 106.741V107.447C202.274 109.261 200.896 110.78 199.087 110.961V112.391H201.212C201.407 112.391 201.566 112.549 201.566 112.744C201.566 112.939 201.407 113.097 201.212 113.097H196.255C196.059 113.097 195.901 112.939 195.901 112.744C195.901 112.549 196.059 112.391 196.255 112.391H198.379V110.961C196.57 110.78 195.192 109.261 195.192 107.447V106.741C195.192 106.546 195.351 106.388 195.546 106.388Z" fill="#CCCCCC"/>
      <path d="M198.733 101.797C197.562 101.797 196.608 102.745 196.608 103.917V107.449C196.608 108.617 197.562 109.564 198.733 109.564C199.909 109.564 200.858 108.617 200.858 107.449V103.917C200.858 102.745 199.909 101.797 198.733 101.797ZM200.15 107.449C200.15 108.226 199.517 108.862 198.733 108.862C197.95 108.862 197.317 108.226 197.317 107.449V103.917C197.317 103.135 197.95 102.504 198.733 102.504C199.517 102.504 200.15 103.135 200.15 103.917V107.449Z" fill="#CCCCCC"/>
      <path d="M223.24 101.935C222.128 101.56 220.921 102.152 220.545 103.261L221.143 103.857C221.182 103.107 221.807 102.519 222.563 102.523C223.343 102.527 223.976 103.163 223.972 103.94V106.679L224.68 107.385V103.94C224.68 103.036 224.099 102.227 223.24 101.935ZM225.036 112.417H222.911V110.985C223.533 110.926 224.126 110.701 224.633 110.334L224.122 109.824C223.675 110.124 223.137 110.298 222.555 110.298C220.992 110.298 219.722 109.031 219.722 107.472V106.766C219.722 106.703 219.706 106.64 219.671 106.588C219.611 106.486 219.5 106.415 219.37 106.415C219.306 106.415 219.247 106.43 219.196 106.462C219.089 106.521 219.014 106.636 219.014 106.766V107.472C219.014 109.284 220.395 110.803 222.203 110.985V112.417H220.078C219.88 112.417 219.722 112.575 219.722 112.769C219.722 112.966 219.88 113.124 220.078 113.124H225.036C225.23 113.124 225.389 112.966 225.389 112.769C225.389 112.575 225.23 112.417 225.036 112.417ZM225.745 106.415C225.547 106.415 225.389 106.573 225.389 106.766V107.472C225.389 107.662 225.369 107.851 225.333 108.037L225.369 108.072L225.907 108.613C225.947 108.491 225.982 108.368 226.01 108.242C226.069 107.993 226.097 107.737 226.097 107.472V106.766C226.097 106.573 225.939 106.415 225.745 106.415V106.415Z" fill="#CCCCCC"/>
      <path d="M224.015 107.721L218.558 102.279L218.056 102.78L222.654 107.366L226.556 111.253L227.054 110.752L224.015 107.721ZM223.077 108.786C222.353 109.075 221.53 108.719 221.241 107.993C221.174 107.827 221.138 107.65 221.138 107.472V106.853L220.43 106.146V107.472C220.43 108.64 221.384 109.592 222.555 109.592C222.923 109.592 223.287 109.497 223.607 109.315L223.077 108.786Z" fill="#CCCCCC"/>
      <path d="M318.529 184.864C313.831 188.343 308.224 188.745 306.004 185.763C303.785 182.782 305.794 177.545 310.494 174.066C312.352 172.65 314.523 171.699 316.825 171.291L336.915 156.802L343.532 166.349L323.025 179.62C321.967 181.701 320.426 183.499 318.529 184.864Z" fill="#FFB6B6"/>
      <path d="M331.185 66.8608C331.185 66.8608 347.391 67.3713 350.463 78.6021C353.534 89.8328 359.676 113.771 358.652 147.008C358.652 147.008 363.771 168.448 350.463 167.427L332.036 175.595L319.751 159.259L329.988 147.008L331.185 66.8608Z" fill="#F7B80B"/>
    </g>
    <defs>
      <clipPath id="clip0_1647_76850">
        <rect width="390" height="259" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);