export const WalkGirlIcon = () => (
  <svg width="112" height="125" viewBox="0 0 112 125" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1219_49080)">
      <path d="M0.611599 90.4486C6.92136 90.6208 13.2126 91.2141 19.4432 92.2248C38.1836 95.2735 56.1513 101.941 72.3442 111.855C77.7295 115.15 82.8888 118.801 87.7877 122.784C88.3884 123.273 88.985 123.767 89.5775 124.265C89.9581 124.585 90.5109 124.038 90.1273 123.716C85.2638 119.625 80.1336 115.862 74.7707 112.453C69.4137 109.049 63.84 105.999 58.0854 103.321C52.3227 100.638 46.3932 98.3289 40.3337 96.4078C34.2833 94.4897 28.1143 92.968 21.8659 91.8524C15.6333 90.7381 9.33378 90.0377 3.00863 89.7561C2.20987 89.7209 1.41086 89.6926 0.611599 89.6711C0.11106 89.6574 0.111416 90.4349 0.611599 90.4486Z" fill="#E4E4E4"/>
      <path d="M61.2755 47.3253C61.0523 47.1912 60.8613 47.0098 60.7159 46.7938C60.5704 46.5779 60.4741 46.3327 60.4337 46.0755C60.3932 45.8183 60.4097 45.5554 60.4818 45.3052C60.554 45.0551 60.6802 44.8238 60.8514 44.6277L58.5371 21.4043L62.4015 21.6607L63.472 44.5287C63.7824 44.8382 63.9685 45.2508 63.9951 45.6883C64.0216 46.1259 63.8867 46.5579 63.6159 46.9026C63.3452 47.2474 62.9574 47.4808 62.526 47.5587C62.0947 47.6366 61.6497 47.5536 61.2755 47.3253V47.3253Z" fill="#9E616A"/>
      <path d="M63.9665 20.5446L62.9533 28.2447L63.094 32.964L58.8438 33.3532L57.992 29.4281L57.5227 19.0368C57.4873 18.253 57.7503 17.485 58.2587 16.8874C58.7671 16.2899 59.4831 15.9073 60.2624 15.8167C61.0709 15.7227 61.884 15.9503 62.5261 16.4505C63.1683 16.9507 63.588 17.6833 63.6947 18.4902L63.9665 20.5446Z" fill="#2F2E41"/>
      <path d="M47.6913 88.296H45.1132L43.8867 78.3516L47.6918 78.3518L47.6913 88.296Z" fill="#9E616A"/>
      <path d="M48.3482 90.7952L40.0352 90.7949V90.6897C40.0352 89.8316 40.3761 89.0086 40.9829 88.4018C41.5897 87.795 42.4127 87.4541 43.2708 87.4541H43.271L48.3484 87.4543L48.3482 90.7952Z" fill="#2F2E41"/>
      <path d="M68.9787 88.296H66.4006L65.1738 78.3516L68.9789 78.3518L68.9787 88.296Z" fill="#9E616A"/>
      <path d="M69.6373 90.7952L61.3242 90.7949V90.6897C61.3243 89.8316 61.6652 89.0086 62.272 88.4018C62.8788 87.795 63.7018 87.4541 64.5599 87.4541H64.5601L69.6375 87.4543L69.6373 90.7952Z" fill="#2F2E41"/>
      <path d="M58.953 12.156C62.0401 12.156 64.5427 9.65337 64.5427 6.56626C64.5427 3.47916 62.0401 0.976562 58.953 0.976562C55.8659 0.976562 53.3633 3.47916 53.3633 6.56626C53.3633 9.65337 55.8659 12.156 58.953 12.156Z" fill="#9E616A"/>
      <path d="M58.2321 14.4258L62.1112 14.8691C63.0545 16.2069 63.6661 17.7498 63.8956 19.3705C64.1251 20.9912 63.9659 22.6432 63.4311 24.1903L60.5041 32.6577C60.5041 32.6577 64.5673 40.8032 64.5673 48.366C64.5673 55.9287 72.3053 82.9238 72.3053 82.9238L61.4132 84.06L56.6607 55.3025L50.1114 83.9441L42.6348 82.7269L46.7527 45.1748L49.4739 32.8147L49.5898 26.787C49.5898 26.787 48.4328 22.2641 51.3285 19.3684L54.864 14.4419L58.2321 14.4258Z" fill="#2F2E41"/>
      <path d="M52.4375 4.57036H60.6514V0.989957C58.8485 0.273716 57.0843 -0.335409 56.0179 0.989957C55.0683 0.989964 54.1576 1.36719 53.4862 2.03864C52.8147 2.7101 52.4375 3.62078 52.4375 4.57036Z" fill="#2F2E41"/>
      <path d="M61.107 0.358398C66.0174 0.358398 67.3918 6.5134 67.3918 9.98575C67.3918 11.9222 66.5161 12.6149 65.1399 12.8492L64.6539 10.2572L63.5156 12.9608C63.129 12.9627 62.7229 12.9552 62.3019 12.9474L61.9159 12.1527L61.0553 12.9331C57.6083 12.9383 54.8223 13.4407 54.8223 9.98575C54.8223 6.5134 56.0276 0.358398 61.107 0.358398Z" fill="#2F2E41"/>
      <path opacity="0.2" d="M53.8445 34.3549L53.6369 25.3386L53.0861 21.3359L51.7246 25.301L53.8445 34.3549Z" fill="black"/>
      <path d="M64.1745 33.1518H58.002V15.0088H64.1745C65.3554 15.0101 66.4875 15.4798 67.3225 16.3148C68.1576 17.1499 68.6273 18.282 68.6286 19.4629V28.6977C68.6273 29.8786 68.1576 31.0108 67.3225 31.8458C66.4875 32.6808 65.3554 33.1505 64.1745 33.1518Z" fill="#F7B80B"/>
      <path d="M63.3166 23.4324C63.1105 23.4322 62.9128 23.3502 62.7671 23.2044C62.6213 23.0587 62.5393 22.861 62.5391 22.6549V15.916H64.0942V22.6549C64.094 22.861 64.012 23.0587 63.8662 23.2044C63.7204 23.3502 63.5228 23.4322 63.3166 23.4324Z" fill="#3F3D56"/>
      <path d="M65.1855 31.7269H60.6691C60.4089 31.7266 60.1594 31.623 59.9753 31.439C59.7913 31.255 59.6878 31.0054 59.6875 30.7452V28.0432C59.6878 27.783 59.7913 27.5334 59.9753 27.3494C60.1594 27.1654 60.4089 27.0618 60.6691 27.0615H65.1855C65.4458 27.0618 65.6953 27.1654 65.8793 27.3494C66.0634 27.5334 66.1669 27.783 66.1672 28.0432V30.7452C66.1669 31.0054 66.0634 31.255 65.8793 31.439C65.6953 31.623 65.4458 31.7266 65.1855 31.7269Z" fill="#3F3D56"/>
      <path d="M52.3258 26.2079C51.1568 24.8287 50.4863 22.8252 50.4863 20.7113C50.4863 16.7096 52.8699 13.4541 55.7997 13.4541C57.3726 13.4541 58.8544 14.3942 59.8653 16.0332L59.4241 16.3053C58.5097 14.8228 57.1887 13.9725 55.7996 13.9725C53.1557 13.9725 51.0047 16.9955 51.0047 20.7113C51.0047 22.7045 51.6304 24.5857 52.7213 25.8728L52.3258 26.2079Z" fill="#F7B80B"/>
      <path d="M54.8456 48.264C54.6094 48.104 54.4112 47.8942 54.2649 47.6493C54.1186 47.4044 54.0278 47.1304 53.9989 46.8466C53.9699 46.5628 54.0036 46.2762 54.0974 46.0068C54.1912 45.7374 54.343 45.4919 54.542 45.2875L53.3906 19.7422L57.6032 20.2525L57.415 45.335C57.7362 45.692 57.9152 46.1545 57.9182 46.6348C57.9212 47.115 57.748 47.5797 57.4313 47.9407C57.1146 48.3018 56.6764 48.5341 56.1999 48.5937C55.7233 48.6533 55.2414 48.536 54.8456 48.264V48.264Z" fill="#9E616A"/>
      <path d="M59.3809 19.1239L57.8148 27.4881L57.6881 32.6596L53.015 32.8327L52.3164 28.4878L52.4207 17.0912C52.4286 16.2316 52.762 15.4069 53.3537 14.7834C53.9455 14.1599 54.7516 13.7838 55.6095 13.731C56.4997 13.6763 57.3757 13.9737 58.0485 14.5591C58.7213 15.1445 59.137 15.971 59.2058 16.8601L59.3809 19.1239Z" fill="#2F2E41"/>
      <path d="M69.559 98.3227C69.9062 97.3828 68.0105 95.8167 65.3249 94.8248C62.6394 93.8329 60.1809 93.7907 59.8337 94.7307C59.4865 95.6706 61.3822 97.2367 64.0677 98.2286C66.7533 99.2206 69.2118 99.2627 69.559 98.3227Z" fill="#E6E6E6"/>
      <path d="M86.2919 99.1184C86.7929 98.2506 85.1891 96.3867 82.7098 94.9553C80.2305 93.5238 77.8144 93.0669 77.3134 93.9346C76.8124 94.8024 78.4162 96.6663 80.8955 98.0977C83.3748 99.5292 85.7909 99.9861 86.2919 99.1184Z" fill="#E6E6E6"/>
      <path d="M94.4393 110.317C94.9006 109.427 93.2141 107.638 90.6726 106.32C88.131 105.002 85.6968 104.655 85.2355 105.545C84.7743 106.434 86.4607 108.224 89.0023 109.541C91.5439 110.859 93.9781 111.206 94.4393 110.317Z" fill="#E6E6E6"/>
      <path d="M111.027 114.205C111.488 113.315 109.802 111.526 107.26 110.208C104.719 108.89 102.285 108.543 101.823 109.432C101.362 110.322 103.049 112.111 105.59 113.429C108.132 114.747 110.566 115.094 111.027 114.205Z" fill="#E6E6E6"/>
    </g>
    <defs>
      <clipPath id="clip0_1219_49080">
        <rect width="111.709" height="124.15" fill="white" transform="translate(0.167969 0.265625)"/>
      </clipPath>
    </defs>
  </svg>

);