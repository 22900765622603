import { useMemo, useState } from "react";
import {
  DISTRIBUTION_SCHEDULES,
  FlyerDistributionSchedule,
} from "./flyersDistributionConfig";
import { Box, Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import CalendarPopup from "../Popus/CalendarPopup";
import NorelaButton from "../Buttons/NorelaButton";

export default function EventHoursBlock({
  hours,
  onChange,
  chosenHours,
  days,
  setDays,
  nbDays,
}: {
  hours: number;
  nbDays: number;
  onChange: (hours: FlyerDistributionSchedule) => any;
  chosenHours: FlyerDistributionSchedule | undefined;
  days: string[];
  setDays: (day: string[]) => any;
}) {
  const schedules = useMemo(() => {
    return (
      DISTRIBUTION_SCHEDULES.find((schedule) => schedule.hours === hours) ??
      DISTRIBUTION_SCHEDULES[0]
    );
  }, [hours]);
  const [open, setOpen] = useState(false);

  return (
    <Box>
      <Flex w="100%" textAlign="left" mb="1rem">
        <NorelaButton mr="1rem" onClick={() => setOpen(true)}>
          Choisir la date de l'événement
        </NorelaButton>
        {nbDays === 1 && days.length === 1 && (
          <Text my="auto">
            {moment(days[0])
              .toDate()
              .toLocaleDateString("fr-FR", { dateStyle: "full" })}
          </Text>
        )}
        {nbDays > 1 && (
          <Text my="auto">
            {days.length}/{nbDays} jours séléctionnés
          </Text>
        )}
        {open && (
          <CalendarPopup
            isOpen
            selectedDatePeriod={null}
            selectedDates={days}
            setOpenPopup={setOpen}
            changeDatePeriod={() => {}}
            changeDates={(dates) => {
              if (!dates) return setDays([]);
              if (dates.length >= nbDays)
                return setDays(
                  dates.slice(0, nbDays - 1).concat(dates[dates.length - 1])
                );
              setDays(dates);
            }}
            daySelectionTitle={`Vous avez séléctionné ${
              days.length
            }/${nbDays} jour${nbDays > 1 ? "s" : ""}`}
            onlyDaySelection
          />
        )}
      </Flex>
      <Flex gap="1rem">
        {schedules.config.map((schedule) => (
          <Box
            key={schedule.startHour}
            textAlign="center"
            cursor="pointer"
            border="solid 1px"
            borderColor="gray.300"
            borderRadius="10px"
            w="100%"
            p="1rem"
            _hover={{
              backgroundColor: "gray.50",
            }}
            onClick={() => {
              onChange(schedule);
            }}
            background={
              chosenHours === schedule ? "gray.100 !important" : undefined
            }
            transition="0.2s"
          >
            <Text>
              {schedule.startHour} - {schedule.endHour}
            </Text>
          </Box>
        ))}
      </Flex>
    </Box>
  );
}
