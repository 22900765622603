import { FC, useState } from "react";
import Layout from "../../components/Layout";
import { cgvPageContent } from "../../modules/CGV/data";
import { PageContentProps } from "../../modules/Onboarding/type";
import CgvContent from "../../modules/CGV";

const CGV: FC = () => {
  const [page, setPage] = useState<PageContentProps>(cgvPageContent[0]);
  return (
    <Layout>
      <CgvContent page={page} pages={[]} setPage={setPage} />
    </Layout>
  );
};

export default CGV;
