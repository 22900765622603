export const SalonNewIcon = () => (
  <svg width="190" height="100" viewBox="0 0 190 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1279_388)">
      <path d="M145 50C145.006 55.4674 144.114 60.8987 142.359 66.0769C142.259 66.3728 142.156 66.6676 142.05 66.9615C140.192 72.1001 137.505 76.8998 134.096 81.1701C133.078 82.4468 132 83.6706 130.861 84.8417C129.722 86.0148 128.526 87.1307 127.274 88.1893C120.288 94.1138 111.801 97.9941 102.75 99.4024H102.749C101.595 99.5814 100.43 99.7209 99.253 99.821C97.8521 99.9393 96.4344 99.999 95 100C88.3295 100.009 81.7251 98.6783 75.5781 96.0878C69.4311 93.4972 63.8667 89.6992 59.2145 84.9186C58.8876 84.5843 58.5656 84.2451 58.2485 83.9009C57.4586 83.0444 56.6987 82.1612 55.969 81.2515C55.5592 80.7396 55.1593 80.2199 54.7692 79.6923C52.3667 76.4465 50.3678 72.9207 48.8166 69.1923C48.155 67.6044 47.5773 65.9828 47.0858 64.3343C47.0503 64.2175 47.0163 64.1006 46.9823 63.9837C46.9083 63.7323 46.8368 63.4793 46.7678 63.2249C45.591 58.9152 44.9965 54.4674 45 50C45 22.3861 67.3861 0 95 0C122.614 0 145 22.3861 145 50Z" fill="#F2F2F2"/>
      <path d="M108.659 94.8252L107.647 98.3858C106.033 98.8062 104.399 99.1453 102.751 99.4021H102.749C101.596 99.5811 100.43 99.7207 99.2539 99.8208L102.545 95.1181L103.013 95.0767L108.659 94.8252Z" fill="#2F2E41"/>
      <path d="M134.096 81.1699C133.078 82.4465 132 83.6704 130.861 84.8415L126.958 84.8445L105.972 84.8681L102.194 84.8726L99.6818 84.8755L95.9451 84.8785L93.0708 84.8815L87.7617 84.8874L84.63 84.8918L78.0353 84.8977L74.8874 84.9022L66.164 84.911L60.6847 84.917L59.2143 84.9184C58.8874 84.5841 58.5654 84.2449 58.2483 83.9007C57.4584 83.0442 56.6985 82.161 55.9688 81.2513L59.0131 81.2483L61.0871 81.2468L65.8889 81.2409L75.1551 81.232L78.2868 81.2291L79.5531 81.2276H79.5575L80.5516 81.2261L80.9969 81.2247L82.1374 81.2232H82.6196L86.0013 81.2202L93.2957 81.2113L96.5057 81.2084L99.5797 81.2054L102.461 81.2025L106.596 81.198L128.679 81.1758L134.096 81.1699Z" fill="#CCCCCC"/>
      <path d="M81.0973 83.6256C82.7421 83.6256 84.0754 83.2 84.0754 82.6751C84.0754 82.1502 82.7421 81.7246 81.0973 81.7246C79.4525 81.7246 78.1191 82.1502 78.1191 82.6751C78.1191 83.2 79.4525 83.6256 81.0973 83.6256Z" fill="#3F3D56"/>
      <path d="M81.0973 82.9918C82.7421 82.9918 84.0754 82.5662 84.0754 82.0413C84.0754 81.5164 82.7421 81.0908 81.0973 81.0908C79.4525 81.0908 78.1191 81.5164 78.1191 82.0413C78.1191 82.5662 79.4525 82.9918 81.0973 82.9918Z" fill="#3F3D56"/>
      <path d="M80.5743 82.6781L81.0181 82.6715L80.8957 74.3847C80.8779 73.0326 81.2151 71.6994 81.8736 70.5184C82.5321 69.3373 83.4889 68.3496 84.6485 67.6539L93.1188 62.5909L92.8911 62.21L84.4208 67.2731C83.1945 68.0088 82.1826 69.0534 81.4861 70.3024C80.7897 71.5515 80.4331 72.9614 80.4519 74.3914L80.5743 82.6781Z" fill="#3F3D56"/>
      <path d="M91.8221 65.2495C91.8221 65.8444 92.3044 66.3267 92.8994 66.3267C93.4943 66.3267 93.9766 65.8444 93.9766 65.2495V58.5328C93.9766 57.9378 93.4943 57.4556 92.8994 57.4556C92.3044 57.4556 91.8221 57.9378 91.8221 58.5328V65.2495Z" fill="#F7B80B"/>
      <path d="M92.8616 46.9058L104.478 60.6327L114.382 64.8723L117.021 59.741C114.6 56.8595 106.698 53.4011 106.698 53.4011L95.9677 43.7769C95.9485 43.7061 95.9278 43.6354 95.9021 43.5652C95.7516 43.1534 95.4974 42.7874 95.1641 42.5025C94.8308 42.2176 94.4297 42.0235 93.9995 41.9389C93.5692 41.8544 93.1245 41.8823 92.7082 42.0198C92.2918 42.1574 91.918 42.4001 91.6229 42.7243C91.3278 43.0486 91.1213 43.4435 91.0234 43.8709C90.9254 44.2983 90.9394 44.7437 91.064 45.1641C91.1885 45.5845 91.4194 45.9657 91.7343 46.2708C92.0492 46.5759 92.4374 46.7946 92.8616 46.9058Z" fill="#A0616A"/>
      <path d="M115.986 58.1816L113.424 64.7415L113.426 64.767C113.682 67.2872 117.619 69.6208 120.877 71.1346C121.952 71.6329 123.175 71.7045 124.301 71.335C125.426 70.9655 126.369 70.1825 126.94 69.1442C127.355 68.3755 127.547 67.5058 127.494 66.6336C127.442 65.7613 127.146 64.9213 126.641 64.2083L124.054 60.5859L115.986 58.1816Z" fill="#F7B80B"/>
      <path d="M130.837 70.6051L129.475 75.509L128.679 81.1761L128.434 82.9187L128.428 82.9557L126.914 84.3936L126.959 84.8448L127.077 86.0474L127.274 88.1894C120.287 94.1123 111.801 97.9924 102.75 99.4025C102.749 99.4039 102.749 99.4025 102.749 99.4025C101.54 99.0563 100.592 98.6806 100.21 98.4069C100.059 98.3004 99.9913 98.2057 99.9942 98.1096C100.011 97.469 101.829 95.1702 102.104 94.8255L102.826 91.8374L104.299 91.2516L103.935 89.1495L105.905 87.0696L105.962 84.9291L105.972 84.8684L106.596 81.1983L107.842 73.8891L107.969 73.1406L107.854 67.3684L107.82 65.6199L107.78 63.6258C107.769 63.1131 107.807 62.6005 107.892 62.0948C107.955 61.7139 108.046 61.3381 108.164 60.9705C108.493 59.9469 109.02 58.998 109.715 58.1784C110.411 57.3587 111.261 56.6844 112.218 56.1939C112.293 56.1554 112.368 56.1184 112.445 56.0815C112.445 56.0815 112.445 56.08 112.447 56.0815C113.039 55.8018 113.662 55.5947 114.303 55.4646L114.49 55.1332L116.315 51.8906L121.91 52.0341L125.247 57.2575L128.033 60.0223C129.397 61.3789 130.376 63.0741 130.869 64.9338C131.361 66.7935 131.351 68.7509 130.837 70.6051Z" fill="#F7B80B"/>
      <path d="M118.625 50.2889C122.718 50.2889 126.036 46.9705 126.036 42.8771C126.036 38.7837 122.718 35.4653 118.625 35.4653C114.531 35.4653 111.213 38.7837 111.213 42.8771C111.213 46.9705 114.531 50.2889 118.625 50.2889Z" fill="#A0616A"/>
      <path d="M117.72 50.3883C118.868 50.4062 120.015 50.326 121.149 50.1487C122.273 49.9201 124.788 48.0772 125.489 46.9682C126.01 46.1443 126.343 44.8576 126.531 43.9234C126.784 42.6871 126.774 41.4115 126.502 40.1792C126.231 38.9469 125.704 37.7854 124.955 36.7698C124.667 36.345 124.27 36.0059 123.805 35.7884C123.764 35.7723 123.721 35.758 123.678 35.7458C123.001 35.5676 122.393 35.1911 121.931 34.6646C121.842 34.5581 121.745 34.4582 121.641 34.3657C121.119 33.9254 120.5 33.6148 119.836 33.4593C118.762 33.1721 117.209 33.4572 115.22 34.3074C114.22 34.7346 113.127 34.58 112.286 34.3976C112.239 34.3885 112.19 34.3918 112.145 34.4074C112.099 34.423 112.059 34.4502 112.027 34.4863C111.669 34.8222 111.198 35.0103 110.707 35.0126C110.406 35.0258 110.091 35.442 109.71 35.9848C109.623 36.108 109.522 36.2517 109.449 36.3392L109.44 36.1712L109.27 36.3586C109.002 36.6532 108.814 37.0107 108.722 37.3977C108.63 37.7847 108.638 38.1887 108.744 38.572C108.85 38.9553 109.052 39.3055 109.33 39.59C109.608 39.8745 109.953 40.084 110.334 40.199C110.636 40.2795 110.946 40.3288 111.258 40.3464C111.448 40.3634 111.643 40.381 111.831 40.4135C112.433 40.5368 112.993 40.8141 113.456 41.2183C113.918 41.6225 114.269 42.1398 114.472 42.7198C114.52 42.8538 114.598 42.975 114.701 43.074C114.803 43.1731 114.927 43.2472 115.062 43.2908C115.198 43.3345 115.342 43.3463 115.482 43.3255C115.623 43.3048 115.757 43.2519 115.875 43.171C116.07 43.0188 116.299 42.9162 116.543 42.8718C116.787 42.8275 117.037 42.8427 117.274 42.9162C117.478 43.0298 117.637 43.2103 117.725 43.4273C117.83 43.6691 118.006 43.8738 118.229 44.0154C118.99 44.4082 119.043 46.1481 118.769 47.5986C118.506 48.9967 117.984 50.0081 117.502 50.0581C117.13 50.0965 117.087 50.1229 117.061 50.1886L117.037 50.2475L117.078 50.3025C117.284 50.3769 117.502 50.4062 117.72 50.3883Z" fill="#2F2E41"/>
      <path d="M112.919 70.8763L110.061 65.1787C105.913 65.4621 97.6799 70.2582 97.6799 70.2582L82.1031 73.5608C81.6417 73.3834 81.1417 73.3307 80.6534 73.408C80.1651 73.4853 79.7058 73.6899 79.3217 74.0011C78.9376 74.3124 78.6423 74.7193 78.4655 75.181C78.2886 75.6426 78.2366 76.1427 78.3144 76.6309C78.3923 77.1191 78.5974 77.5781 78.9091 77.9619C79.2208 78.3456 79.6281 78.6404 80.09 78.8167C80.5519 78.993 81.052 79.0444 81.5401 78.966C82.0282 78.8875 82.487 78.6818 82.8704 78.3697L102.685 76.9494L112.919 70.8763Z" fill="#A0616A"/>
      <path d="M111.767 71.8117L119.231 66.2652L120.545 61.5274C120.8 60.5964 120.789 59.6127 120.514 58.6874C120.239 57.7622 119.711 56.9323 118.989 56.2916C118.004 55.4297 116.729 54.9737 115.421 55.0159C114.114 55.0581 112.871 55.5953 111.944 56.5189C109.135 59.3223 105.932 63.2334 106.62 65.9454L106.627 65.9729L111.767 71.8117Z" fill="#F7B80B"/>
      <path d="M57.793 64.5131C57.7842 64.5264 57.7753 64.5397 57.7649 64.5545C57.4694 64.5356 57.173 64.5351 56.8774 64.5531C56.7087 64.5634 56.5401 64.5797 56.3714 64.6019C56.1596 64.6315 55.9616 64.7244 55.8035 64.8685C55.6454 65.0125 55.5344 65.201 55.4852 65.4091C55.4359 65.6173 55.4507 65.8355 55.5275 66.0351C55.6044 66.2347 55.7397 66.4065 55.9158 66.5279L55.9336 66.5397C55.9469 66.5486 55.9572 66.556 55.9706 66.5649C54.0025 68.3114 51.4473 69.2498 48.8167 69.1921C48.1552 67.6042 47.5774 65.9826 47.0859 64.3341C47.8901 63.5794 48.8747 63.044 49.9454 62.7794C52.0904 62.2616 54.4839 62.846 56.6303 62.6329C57.6629 62.5309 58.3803 63.6566 57.793 64.5131Z" fill="white"/>
      <path d="M59.6699 48.0309C59.664 48.0472 59.6581 48.0649 59.6507 48.0827C59.4821 48.093 59.3134 48.1108 59.1448 48.133C58.933 48.1625 58.7351 48.2552 58.577 48.3991C58.4189 48.5431 58.3079 48.7314 58.2587 48.9394C58.2094 49.1475 58.2242 49.3655 58.301 49.5651C58.3778 49.7646 58.5131 49.9362 58.6892 50.0575L58.7069 50.0709C58.4279 50.5271 58.1143 50.9613 57.7691 51.3697C56.7925 52.5053 55.5919 53.4269 54.2425 54.0768C52.8932 54.7267 51.424 55.0908 49.9273 55.1463C49.8297 55.1493 49.735 55.1522 49.6389 55.1522C49.3897 57.3833 48.8506 59.5724 48.0353 61.664C47.7261 62.4555 47.3745 63.2298 46.9821 63.9836C46.9081 63.7321 46.8366 63.4791 46.7676 63.2247C46.7853 63.1907 46.8016 63.1581 46.8179 63.1241C47.2087 62.3172 47.5539 61.489 47.8519 60.6433C48.096 59.9496 48.309 59.2469 48.4909 58.5354C49.4742 54.6709 49.5269 50.6283 48.6448 46.7395C47.8469 45.6237 47.4101 44.2901 47.3933 42.9185C47.3862 42.4247 47.4505 41.9324 47.5841 41.457C47.698 41.5324 47.8179 41.6034 47.9362 41.67C47.9732 41.6907 48.0117 41.7114 48.0501 41.7321L48.0634 41.7395C48.1949 41.8106 48.3441 41.8422 48.493 41.8304C48.642 41.8187 48.7844 41.7641 48.9031 41.6733C49.0218 41.5824 49.1116 41.4592 49.1619 41.3185C49.2121 41.1777 49.2205 41.0255 49.1862 40.88C49.1655 40.7898 49.1433 40.6996 49.1226 40.6093C49.0901 40.4703 49.056 40.3312 49.025 40.1922C49.0197 40.1783 49.0157 40.1639 49.0131 40.1493C48.9762 39.991 48.9377 39.8342 48.9007 39.6759C49.1956 39.4957 49.5283 39.3863 49.8726 39.3564C51.0575 39.2617 52.1522 40.2543 52.414 41.4126C52.6803 42.5723 52.238 43.8061 51.5013 44.738C50.8696 45.5354 50.0398 46.1463 49.1759 46.6907C49.1951 46.7794 49.2173 46.8667 49.2365 46.954C49.5817 48.5755 49.768 50.2268 49.7927 51.8845C50.3194 50.3135 51.4599 48.667 52.4924 47.7484C53.6685 46.7025 55.2188 46.0916 56.6625 45.3933C58.3948 44.5546 60.3149 46.2158 59.6699 48.0309Z" fill="white"/>
      <path d="M139.219 70.9276C139.466 71.2412 139.722 71.5533 139.978 71.8669C139.096 73.677 138.105 75.4318 137.01 77.1214C136.929 77.0637 136.848 77.006 136.766 76.9483C136.753 76.9413 136.741 76.9334 136.729 76.9246C136.426 76.7086 136.121 76.4956 135.818 76.2796V76.2781C135.568 75.4268 135.428 74.5468 135.402 73.6598C135.559 73.6239 135.7 73.5408 135.808 73.4218C135.915 73.3027 135.984 73.1534 136.004 72.9942C136.024 72.835 135.994 72.6735 135.919 72.5316C135.844 72.3898 135.727 72.2744 135.584 72.2012C135.544 72.1317 135.516 72.0814 135.476 72.0104C135.478 71.9926 135.481 71.9734 135.482 71.9557C135.714 70.108 138.07 69.4631 139.219 70.9276Z" fill="white"/>
      <path d="M141.874 64.5962C142.051 65.0844 142.213 65.578 142.359 66.077C142.259 66.3729 142.156 66.6677 142.05 66.9616C142.01 66.8063 141.969 66.651 141.926 66.4956C141.685 65.632 141.396 64.7825 141.06 63.9513C140.785 63.2693 140.48 62.6017 140.146 61.9483C138.328 58.3989 135.694 55.3317 132.46 52.9986C131.123 52.6895 129.914 51.978 128.994 50.9601C128.662 50.5944 128.385 50.1829 128.17 49.7383C128.306 49.719 128.442 49.6924 128.575 49.6643C128.617 49.6554 128.659 49.6451 128.701 49.6362L128.717 49.6332C128.863 49.5995 128.995 49.5244 129.099 49.417C129.203 49.3096 129.274 49.1745 129.303 49.0279C129.332 48.8814 129.318 48.7295 129.262 48.5908C129.207 48.452 129.113 48.3321 128.991 48.2456C128.915 48.1909 128.838 48.1377 128.763 48.0844C128.648 48.0001 128.531 47.9202 128.414 47.8374C128.402 47.8297 128.39 47.8213 128.378 47.8122C128.245 47.7175 128.112 47.6258 127.98 47.5297C128.083 47.2001 128.259 46.8984 128.497 46.648C129.324 45.7945 130.8 45.8137 131.764 46.509C132.73 47.2042 133.216 48.4217 133.278 49.6081C133.333 50.6243 133.114 51.6303 132.825 52.611C132.898 52.6643 132.973 52.7146 133.046 52.7693C134.377 53.7563 135.609 54.8716 136.723 56.0992C136.08 54.5726 135.846 52.5814 136.013 51.2101C136.204 49.648 136.963 48.1643 137.584 46.6865C138.328 44.9113 140.867 44.8877 141.583 46.6746C141.59 46.6924 141.597 46.7087 141.603 46.7264C141.483 46.8462 141.368 46.9705 141.257 47.0992C141.118 47.2614 141.031 47.4618 141.007 47.6743C140.984 47.8868 141.025 48.1014 141.126 48.2901C141.226 48.4787 141.381 48.6326 141.571 48.7316C141.76 48.8306 141.975 48.87 142.188 48.8448L142.21 48.8418C142.302 49.3692 142.354 49.9027 142.367 50.438C142.385 51.9354 142.094 53.4205 141.511 54.8002C140.929 56.1799 140.068 57.4244 138.982 58.4557C138.911 58.5223 138.841 58.5874 138.769 58.651C139.454 59.6255 140.07 60.6473 140.611 61.7087C141.091 62.6442 141.513 63.6086 141.874 64.5962Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_1279_388">
        <rect width="190" height="100" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);