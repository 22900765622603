export const RoleDistributionIcon = () => (
  <svg
    width="180"
    height="120"
    viewBox="0 0 180 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1503_62975)">
      <path
        d="M76.8624 15.1414C76.6987 14.5731 78.9878 19.7821 78.4432 19.8448C75.9629 20.127 70.467 21.3992 68.7395 22.0845C68.6617 22.0756 68.5839 22.0666 68.5084 22.0554C68.323 22.0353 68.1423 22.0061 67.9638 21.9726C63.9413 21.2178 60.8982 17.9411 60.8158 13.9857C60.8112 13.9297 60.8112 13.8715 60.8112 13.8155C60.8107 13.5424 60.8252 13.2695 60.8547 12.998C60.8926 12.6273 60.9576 12.2597 61.0492 11.8983L61.04 11.8961C59.4383 10.4044 60.1133 7.6384 62.1543 6.47151C63.0412 5.96581 64.0246 5.64922 65.0419 5.54203C65.3177 5.51234 65.595 5.4974 65.8725 5.49724H68.4786C69.0179 5.38199 69.5645 5.30341 70.1146 5.26206H70.1192C75.3658 4.86563 79.5027 8.03707 79.116 11.8961C79.1137 11.9006 79.1091 11.9051 79.1069 11.9095C78.9947 12.0753 78.8963 12.2321 78.8094 12.3821C78.7247 12.5232 78.6538 12.6576 78.592 12.7853C78.1138 13.7707 79.3431 15.0108 78.528 15.2422C77.4439 15.5501 81.3791 25.6681 77.0882 25.6681C79.1754 26.2566 77.3141 16.7092 76.8624 15.1414Z"
        fill="#1A202C"
      />
      <path
        d="M79.8921 109.087L83.0249 110.356L89.5618 98.7105L84.9389 96.8373L79.8921 109.087Z"
        fill="#FFB6B6"
      />
      <path
        d="M87.8139 118.159C87.6618 118.527 87.3687 118.82 86.9989 118.974C86.6291 119.128 86.2127 119.131 85.8412 118.981L79.6133 116.456L79.4484 113.822L78.1231 115.851L75.7725 114.9L79.6673 107.316L80.5048 107.601L83.9237 108.752L85.0258 109.126L84.11 111.349L87.6804 116.772C87.8141 116.975 87.8961 117.207 87.9194 117.449C87.9426 117.69 87.9064 117.934 87.8139 118.159Z"
        fill="#1A202C"
      />
      <path
        d="M82.8215 106.169C81.6426 105.505 78.6209 103.94 78.6209 103.94C78.6209 103.94 78.4336 100.264 79.0751 99.4109C79.7142 98.552 80.3836 98.6016 80.0487 97.8751C80.7752 92.4516 84.3265 84.6265 84.3265 84.6265C84.3265 84.6265 78.6221 78.2217 73.0565 71.2383C67.6167 64.4092 62.3095 57.0281 62.5987 54.5381C62.8414 52.7721 63.2388 51.0303 63.7864 49.3327C64.1314 48.2134 64.5093 47.132 64.8661 46.1705C65.68 43.9687 66.3935 42.388 66.3935 42.388L75.4833 45.9854L81.5201 48.3303L82.1451 48.5897L81.7092 49.6227L82.1624 52.9234C82.1624 52.9234 83.5563 57.1801 83.1146 57.3391C82.5801 57.5325 82.5643 57.8949 83.8249 58.7192C84.1856 58.9483 84.5754 59.1289 84.9841 59.256C87.2651 60.0059 87.6609 61.644 87.5992 61.9412C87.1514 64.0352 89.0918 69.7933 91.0574 74.836L91.0595 74.8369C92.8723 79.4932 94.7061 83.5367 94.7061 83.5367L93.3732 87.1717L87.1445 104.168C87.1445 104.168 85.9925 102.709 86.577 105.066C87.1606 107.425 84.0013 106.831 82.8215 106.169Z"
        fill="#1A202C"
      />
      <path
        d="M49.9665 107.396L52.304 109.823L63.0649 101.839L59.6155 98.2569L49.9665 107.396Z"
        fill="#FFB6B6"
      />
      <path
        d="M53.4644 118.885C53.174 119.159 52.7857 119.308 52.3847 119.299C51.9837 119.29 51.6027 119.124 51.3252 118.836L46.6788 114.011L47.6128 111.539L45.5676 112.855L43.813 111.036L50.4903 105.689L51.1377 106.287L53.7847 108.723L54.637 109.51L52.8859 111.169L53.9134 117.565C53.9518 117.804 53.9311 118.049 53.8528 118.279C53.7746 118.509 53.6413 118.717 53.4644 118.885Z"
        fill="#1A202C"
      />
      <path
        d="M59.1302 104.758C59.1169 104.745 59.1056 104.73 59.0952 104.717C58.2636 103.706 56.1793 101.754 56.051 101.633C56.0489 101.631 56.0463 101.629 56.0433 101.627C56.0433 101.627 57.4951 98.5631 58.4033 97.9977C59.3164 97.4325 57.8951 98.2717 59.8755 96.9177C60.903 96.2189 63.2407 93.3468 65.2568 90.7427C67.1325 88.3199 68.7251 86.1293 68.7251 86.1293C68.7251 86.1293 67.6385 83.1823 66.1962 78.966L66.1953 78.964C64.8612 75.0501 63.2207 70.0385 61.8647 65.2764C62.7563 56.6186 64.1305 47.6561 66.0866 46.0689C67.5008 44.969 69.009 43.9935 70.5941 43.1535L75.2794 47.5022L78.856 55.4318L78.8431 62.2481L78.8387 64.9149L78.8299 68.6481L78.7909 88.8634L64.176 105.347C64.176 105.347 63.6373 103.568 63.3155 105.977C62.9967 108.353 60.0282 105.827 59.1302 104.758Z"
        fill="#1A202C"
      />
      <path
        d="M69.8616 22.3086H76.6367L77.7034 24.6437L82.2407 26.6486C82.8308 26.9098 83.3507 27.3048 83.7586 27.8019C84.1664 28.2989 84.4508 28.8841 84.5888 29.5101C86.2225 36.9172 84.1559 38.4561 84.0894 40.8982C83.8635 49.1852 82.6999 50.8335 82.83 52.8793C82.869 53.4915 83.2458 55.9123 84.458 55.6843C85.6702 55.4564 85.0168 56.4133 85.3037 56.7854C85.5515 57.1069 86.8552 56.6803 86.0369 57.7481C85.2185 58.8159 88.7171 61.7276 86.5736 61.7276C82.5085 61.7276 74.3783 63.7433 71.2166 63.7433C68.0549 63.7433 65.6116 64.673 64.2156 64.6392C63.3648 64.6186 63.6308 60.6842 63.9709 57.2769C64.0479 56.5054 65.5417 56.5255 64.2012 55.095C62.8606 53.6646 64.4386 52.9212 64.4415 52.5447C64.4445 52.1448 65.2014 51.1373 64.2569 49.4893C63.3123 47.8413 64.183 48.5233 64.142 48.0058C63.8258 44.018 63.3727 38.9333 63.3727 38.9333L61.3755 33.3587C60.865 31.9338 60.9325 30.368 61.5638 28.9915C62.195 27.615 63.3407 26.5354 64.7591 25.9805L67.1019 25.064L69.8616 22.3086Z"
        fill="#F7B80B"
      />
      <path
        d="M117.445 20.2526C117.294 20.5311 117.084 20.7739 116.83 20.9642C116.575 21.1544 116.282 21.2876 115.971 21.3544C115.659 21.4213 115.337 21.4202 115.026 21.3513C114.715 21.2825 114.422 21.1474 114.169 20.9555L104.625 26.3142L102.481 24.0605L113.864 17.7781C114.221 17.3795 114.714 17.1246 115.248 17.062C115.782 16.9993 116.321 17.1332 116.763 17.4382C117.204 17.7432 117.517 18.1981 117.643 18.7168C117.768 19.2355 117.698 19.782 117.445 20.2526Z"
        fill="#FFB6B6"
      />
      <path
        d="M74.4715 32.901L74.3262 34.6266L93.3268 34.3844L112.24 23.8397L109.475 19.6191L92.8514 26.5508L81.0365 26.7884C79.3738 26.8218 77.7825 27.4651 76.57 28.594C75.3576 29.7229 74.6099 31.2574 74.4715 32.901Z"
        fill="#F7B80B"
      />
      <path
        d="M72.1792 21.0786C75.587 21.0786 78.3495 18.3389 78.3495 14.9592C78.3495 11.5796 75.587 8.83984 72.1792 8.83984C68.7714 8.83984 66.0088 11.5796 66.0088 14.9592C66.0088 18.3389 68.7714 21.0786 72.1792 21.0786Z"
        fill="#FFB6B6"
      />
      <path
        d="M79.1166 12.2311C79.0137 12.2848 78.913 12.3341 78.81 12.3834C78.7025 12.4349 78.5949 12.4864 78.4874 12.5335C76.4212 13.4629 74.7395 13.566 73.719 12.2311C73.6917 11.5287 73.5326 10.8375 73.2499 10.193C73.2998 10.9133 73.1084 11.6298 72.7054 12.2311H70.6507C68.5342 13.6242 67.4473 13.7698 68.9163 18.3433C69.3258 19.6177 70.9544 27.0334 70.3137 28.1331C70.1284 28.1129 68.1429 22.0074 67.9644 21.9738C63.9419 21.2191 60.8988 17.9424 60.8164 13.987C60.821 13.7854 60.8347 13.5839 60.8553 13.3868C61.1203 12.9917 61.4115 12.6146 61.7271 12.258C61.7255 11.2007 61.9706 10.1574 62.4432 9.20972C63.0412 8.00907 63.9734 7.00336 65.1295 6.31153C65.8186 5.98015 66.537 5.71248 67.2757 5.51195C67.294 5.50747 67.31 5.50299 67.3283 5.49851C67.5548 5.43804 67.7859 5.38205 68.017 5.33502C68.4945 5.23504 68.9773 5.1625 69.4631 5.11777C69.4975 5.11553 69.5318 5.11328 69.5638 5.11328C69.7578 5.11373 69.9482 5.16554 70.1152 5.26335C70.1175 5.26335 70.1175 5.26559 70.1198 5.26559C70.2392 5.33477 70.3434 5.42691 70.4264 5.53659C70.5726 5.72114 70.6516 5.94921 70.6507 6.18386H72.9387C73.0234 6.18386 73.1081 6.1861 73.1927 6.19058C74.7198 6.24234 76.1725 6.85739 77.2665 7.91537C78.3604 8.97335 79.0169 10.3981 79.1075 11.9108C79.1143 12.0183 79.1166 12.1236 79.1166 12.2311Z"
        fill="#1A202C"
      />
      <path
        d="M63.7643 13.3501C66.0094 13.3501 67.8294 11.5451 67.8294 9.31859C67.8294 7.09207 66.0094 5.28711 63.7643 5.28711C61.5192 5.28711 59.6992 7.09207 59.6992 9.31859C59.6992 11.5451 61.5192 13.3501 63.7643 13.3501Z"
        fill="#1A202C"
      />
      <path
        d="M68.7327 6.18366C68.8103 8.40886 66.9127 10.2151 64.6676 10.2151C63.5895 10.2151 62.5555 9.79041 61.7932 9.03436C61.0308 8.27831 60.6025 7.25288 60.6025 6.18366C60.6025 3.95714 63.3215 3.9341 64.6676 2.15218C66.5719 -0.368583 68.628 3.18281 68.7327 6.18366Z"
        fill="#1A202C"
      />
      <path
        d="M61.7072 61.7837C63.5594 55.2472 66.9795 49.6897 72.4769 45.4291C72.6953 45.0983 73.037 44.8669 73.4272 44.7857C73.8173 44.7045 74.224 44.7801 74.558 44.9959L87.0123 53.0622C87.3459 53.2787 87.5791 53.6177 87.661 54.0046C87.7429 54.3915 87.6667 54.7948 87.4491 55.1261L76.6794 71.4807C76.461 71.8115 76.1193 72.0428 75.7291 72.124C75.339 72.2053 74.9323 72.1297 74.5983 71.9139L62.144 63.8476C61.8105 63.631 61.5772 63.2921 61.4953 62.9052C61.4134 62.5183 61.4896 62.1149 61.7072 61.7837Z"
        fill="#EDF2F7"
      />
      <path
        opacity="0.2"
        d="M63.8917 64.0798C63.5582 63.8633 63.3249 63.5244 63.243 63.1374C63.1611 62.7505 63.2373 62.3472 63.4549 62.0159C65.3071 55.4794 68.7273 49.9219 74.2246 45.6613C74.3691 45.4448 74.5686 45.27 74.8031 45.1546L74.558 44.9959C74.224 44.7801 73.8173 44.7045 73.4272 44.7857C73.037 44.8669 72.6953 45.0983 72.4769 45.429C66.9795 49.6897 63.5594 55.2471 61.7072 61.7836C61.4896 62.1149 61.4134 62.5182 61.4953 62.9052C61.5772 63.2921 61.8105 63.631 62.144 63.8475L74.5982 71.9138C74.8199 72.0564 75.0757 72.1383 75.3396 72.1512C75.6035 72.1641 75.8661 72.1076 76.1009 71.9874L63.8917 64.0798Z"
        fill="black"
      />
      <path
        d="M78.9237 60.8929L70.5627 55.5105C70.4298 55.4248 70.3367 55.2903 70.3038 55.1365C70.2709 54.9827 70.3009 54.8222 70.3872 54.6904C70.4735 54.5585 70.6091 54.466 70.7641 54.4332C70.9191 54.4004 71.0809 54.43 71.214 54.5155L79.575 59.8979C79.7074 59.9838 79.8001 60.1183 79.8328 60.2719C79.8655 60.4254 79.8354 60.5856 79.7492 60.7172C79.6631 60.8489 79.5278 60.9413 79.3731 60.9742C79.2183 61.0072 79.0567 60.9779 78.9237 60.8929L78.9237 60.8929Z"
        fill="white"
      />
      <path
        d="M74.1887 53.967L72.3493 52.7829C72.2162 52.6976 72.1227 52.5633 72.0893 52.4097C72.0559 52.256 72.0854 52.0955 72.1713 51.9634C72.2572 51.8313 72.3924 51.7384 72.5473 51.7051C72.7022 51.6718 72.8641 51.7008 72.9974 51.7858L73.0005 51.7879L74.84 52.972C74.9724 53.0579 75.0651 53.1924 75.0978 53.346C75.1305 53.4995 75.1004 53.6597 75.0142 53.7914C74.9281 53.923 74.7928 54.0154 74.6381 54.0483C74.4833 54.0813 74.3217 54.052 74.1887 53.967L74.1887 53.967Z"
        fill="white"
      />
      <path
        d="M76.0613 65.2673L67.7004 59.8848C67.5678 59.7989 67.475 59.6645 67.4423 59.5109C67.4096 59.3572 67.4397 59.197 67.5258 59.0654C67.612 58.9337 67.7474 58.8412 67.9022 58.8083C68.057 58.7754 68.2186 58.8047 68.3516 58.8898L76.7126 64.2722C76.7787 64.3144 76.8359 64.369 76.8807 64.4331C76.9255 64.4972 76.9571 64.5694 76.9738 64.6456C76.9905 64.7218 76.9918 64.8005 76.9778 64.8772C76.9637 64.9539 76.9346 65.0271 76.892 65.0926C76.8494 65.1582 76.7942 65.2147 76.7295 65.2591C76.6649 65.3034 76.592 65.3347 76.5152 65.3511C76.4383 65.3675 76.359 65.3688 76.2816 65.3547C76.2043 65.3407 76.1305 65.3117 76.0645 65.2693L76.0613 65.2673Z"
        fill="white"
      />
      <path
        d="M77.4921 63.0811L69.1312 57.6987C68.998 57.6132 68.9046 57.4788 68.8714 57.3249C68.8382 57.1711 68.868 57.0105 68.9542 56.8784C69.0404 56.7464 69.1759 56.6537 69.3311 56.6208C69.4862 56.5879 69.6481 56.6174 69.7813 56.7029L69.7824 56.7037L78.1434 62.0861C78.2759 62.172 78.3687 62.3065 78.4014 62.4601C78.4341 62.6137 78.4041 62.7739 78.3179 62.9056C78.2317 63.0373 78.0964 63.1297 77.9416 63.1626C77.7868 63.1955 77.6252 63.1662 77.4921 63.0811L77.4921 63.0811Z"
        fill="white"
      />
      <path
        d="M79.5813 51.1723C80.8791 51.6294 82.3049 50.9566 82.7658 49.6694C83.2267 48.3823 82.5482 46.9683 81.2504 46.5112C79.9525 46.0541 78.5267 46.727 78.0658 48.0141C77.6049 49.3013 78.2834 50.7152 79.5813 51.1723Z"
        fill="#2D3748"
      />
      <path
        d="M111.788 18.918C112.748 12.1955 115.394 6.23673 120.272 1.28971C120.444 0.933109 120.752 0.658782 121.127 0.526907C121.503 0.395033 121.916 0.416384 122.276 0.586276L135.701 6.94061C136.06 7.11131 136.337 7.41649 136.47 7.7892C136.603 8.16191 136.581 8.57171 136.41 8.9287L127.926 26.557C127.754 26.9136 127.446 27.1879 127.07 27.3198C126.694 27.4517 126.281 27.4303 125.921 27.2604L112.497 20.9061C112.137 20.7354 111.861 20.4302 111.728 20.0575C111.595 19.6848 111.616 19.275 111.788 18.918Z"
        fill="#EDF2F7"
      />
      <path
        opacity="0.2"
        d="M114.26 20.9061C113.901 20.7354 113.624 20.4302 113.491 20.0575C113.358 19.6848 113.38 19.275 113.551 18.918C114.511 12.1955 117.157 6.2367 122.035 1.28969C122.149 1.05611 122.324 0.856594 122.54 0.711321L122.276 0.586268C121.916 0.416384 121.503 0.395035 121.127 0.526904C120.752 0.658774 120.444 0.933097 120.272 1.28969C115.394 6.23672 112.748 12.1955 111.788 18.918C111.616 19.275 111.595 19.6848 111.728 20.0575C111.861 20.4302 112.137 20.7354 112.497 20.9061L125.921 27.2604C126.16 27.3725 126.424 27.42 126.688 27.398C126.951 27.376 127.204 27.2854 127.42 27.1354L114.26 20.9061Z"
        fill="black"
      />
      <path
        d="M128.733 15.7669L119.726 11.5334C119.582 11.4661 119.472 11.345 119.419 11.1968C119.365 11.0487 119.373 10.8856 119.441 10.7434C119.509 10.6013 119.631 10.4917 119.781 10.4388C119.93 10.3859 120.095 10.3941 120.238 10.4614L129.246 14.6949C129.388 14.7626 129.498 14.8837 129.551 15.0316C129.604 15.1795 129.596 15.3421 129.528 15.484C129.46 15.6258 129.339 15.7352 129.19 15.7882C129.041 15.8412 128.877 15.8336 128.733 15.7669L128.733 15.7669Z"
        fill="white"
      />
      <path
        d="M123.112 9.52641L121.13 8.59505C121.059 8.56197 120.995 8.5153 120.942 8.45771C120.889 8.40012 120.848 8.33275 120.822 8.25944C120.795 8.18613 120.783 8.10832 120.787 8.03046C120.79 7.9526 120.81 7.87621 120.843 7.80567C120.876 7.73513 120.924 7.67182 120.982 7.61935C121.04 7.56688 121.108 7.52629 121.182 7.49989C121.256 7.4735 121.334 7.46181 121.413 7.46551C121.491 7.46921 121.568 7.48822 121.639 7.52145L121.643 7.52306L123.624 8.45442C123.767 8.52207 123.877 8.64314 123.93 8.79109C123.983 8.93904 123.975 9.10179 123.907 9.24368C123.839 9.38557 123.718 9.49501 123.568 9.54801C123.419 9.601 123.255 9.59324 123.112 9.52641Z"
        fill="white"
      />
      <path
        d="M126.481 20.479L117.473 16.2456C117.33 16.1779 117.22 16.0569 117.167 15.9089C117.114 15.761 117.123 15.5982 117.19 15.4563C117.258 15.3144 117.38 15.205 117.529 15.152C117.678 15.099 117.842 15.1068 117.985 15.1736L126.993 19.407C127.064 19.4402 127.128 19.4869 127.181 19.5444C127.234 19.602 127.275 19.6694 127.301 19.7427C127.328 19.8159 127.34 19.8937 127.336 19.9715C127.333 20.0493 127.313 20.1256 127.28 20.1961C127.247 20.2666 127.2 20.3299 127.141 20.3824C127.083 20.4348 127.015 20.4754 126.942 20.5018C126.868 20.5282 126.789 20.54 126.711 20.5363C126.632 20.5327 126.555 20.5138 126.484 20.4806L126.481 20.479Z"
        fill="white"
      />
      <path
        d="M127.607 18.1226L118.599 13.8892C118.456 13.822 118.345 13.7011 118.292 13.553C118.238 13.4049 118.246 13.2418 118.314 13.0995C118.382 12.9573 118.504 12.8476 118.653 12.7945C118.803 12.7415 118.967 12.7494 119.11 12.8166L119.112 12.8172L128.119 17.0506C128.262 17.1183 128.372 17.2394 128.425 17.3873C128.478 17.5352 128.47 17.698 128.402 17.8398C128.334 17.9817 128.212 18.0911 128.063 18.1441C127.915 18.1971 127.75 18.1894 127.607 18.1226L127.607 18.1226Z"
        fill="white"
      />
      <path
        d="M128.598 6.10058C129.976 6.10058 131.092 4.99332 131.092 3.62744C131.092 2.26156 129.976 1.1543 128.598 1.1543C127.221 1.1543 126.104 2.26156 126.104 3.62744C126.104 4.99332 127.221 6.10058 128.598 6.10058Z"
        fill="#2D3748"
      />
      <path
        d="M64.571 56.9522C69.2585 52.5432 74.8662 49.4166 81.6929 47.9889C82.415 47.6119 83.324 47.7765 83.7251 48.357L90.8977 58.7671C91.2971 59.3484 91.0367 60.1253 90.3156 60.5036L73.1936 69.4669C72.4716 69.844 71.5626 69.6794 71.1615 69.0989L63.9889 58.6888C63.5895 58.1075 63.8499 57.3306 64.571 56.9522Z"
        fill="#EDF2F7"
      />
      <path
        opacity="0.2"
        d="M65.4168 59.4558C65.0174 58.8744 65.2778 58.0976 65.9989 57.7192C70.6865 53.3101 76.2942 50.1836 83.1209 48.7559C83.3519 48.6372 83.6065 48.5706 83.8665 48.5608L83.7254 48.3559C83.3243 47.7755 82.4152 47.6108 81.6932 47.9879C74.8665 49.4155 69.2588 52.5421 64.5713 56.9512C63.8502 57.3295 63.5898 58.1064 63.9892 58.6877L71.1618 69.0978C71.3107 69.2919 71.5069 69.4453 71.7319 69.5438C71.9568 69.6423 72.2033 69.6826 72.4483 69.661L65.4168 59.4558Z"
        fill="black"
      />
      <path
        d="M80.1224 62.0704L75.2919 55.107C75.1321 54.876 75.2352 54.5667 75.5221 54.416C75.6523 54.3414 75.8048 54.315 75.9528 54.3414C76.1007 54.3678 76.2344 54.4454 76.3301 54.5603L81.1606 61.5237C81.3198 61.7549 81.216 62.0642 80.9288 62.2144C80.7988 62.2886 80.6466 62.3148 80.4991 62.2885C80.3515 62.2621 80.2181 62.1849 80.1224 62.0704L80.1224 62.0704Z"
        fill="white"
      />
      <path
        d="M79.2022 55.1389L78.1395 53.6069C77.9785 53.3767 78.08 53.067 78.3662 52.9154C78.4963 52.8402 78.6491 52.8132 78.7974 52.8393C78.9456 52.8653 79.0798 52.9427 79.1759 53.0576L79.1777 53.0603L80.2404 54.5922C80.3996 54.8235 80.2958 55.1327 80.0086 55.2829C79.8787 55.3571 79.7264 55.3833 79.5789 55.357C79.4313 55.3306 79.2979 55.2534 79.2022 55.1389Z"
        fill="white"
      />
      <path
        d="M75.5587 64.4721L70.7282 57.5086C70.569 57.2774 70.6729 56.9682 70.9601 56.818C71.09 56.7438 71.2422 56.7175 71.3898 56.7439C71.5373 56.7702 71.6708 56.8475 71.7664 56.962L76.597 63.9254C76.7579 64.1557 76.6564 64.4653 76.3702 64.617C76.2401 64.6922 76.0873 64.7191 75.9391 64.6931C75.7908 64.667 75.6567 64.5896 75.5606 64.4747L75.5587 64.4721Z"
        fill="white"
      />
      <path
        d="M77.8405 63.2707L73.01 56.3073C72.8496 56.0767 72.9518 55.7672 73.2383 55.616C73.3685 55.541 73.5213 55.5143 73.6695 55.5406C73.8177 55.5669 73.9517 55.6445 74.0476 55.7596L74.0483 55.7606L78.8788 62.7241C79.0379 62.9553 78.9341 63.2645 78.6469 63.4147C78.517 63.4889 78.3648 63.5152 78.2172 63.4888C78.0696 63.4625 77.9362 63.3852 77.8405 63.2707L77.8405 63.2707Z"
        fill="white"
      />
      <path
        d="M87.4845 55.3178C88.8347 55.0484 89.7455 53.9239 89.5188 52.8062C89.2921 51.6885 88.0137 51.0007 86.6635 51.2701C85.3133 51.5395 84.4025 52.6639 84.6292 53.7816C84.856 54.8994 86.1343 55.5871 87.4845 55.3178Z"
        fill="#2D3748"
      />
      <path
        d="M74.9928 71.1899C74.7138 71.037 74.4717 70.8259 74.2832 70.571C74.0946 70.3162 73.9641 70.0238 73.9007 69.7141C73.8373 69.4044 73.8425 69.0847 73.916 68.7771C73.9894 68.4695 74.1294 68.1815 74.3261 67.9328L69.0459 58.3995L71.3457 56.3027L77.5336 67.6711C77.931 68.0307 78.1816 68.5223 78.2379 69.0529C78.2942 69.5834 78.1523 70.116 77.8391 70.5497C77.5259 70.9835 77.0632 71.2882 76.5386 71.4062C76.014 71.5242 75.464 71.4472 74.9928 71.1899H74.9928Z"
        fill="#FFB6B6"
      />
      <path
        d="M68.8253 28.9916L63.1462 28.4482C62.8813 28.4229 62.6139 28.4527 62.3613 28.5359C62.1087 28.6191 61.8764 28.7537 61.6794 28.9312C61.4823 29.1087 61.3249 29.325 61.2171 29.5664C61.1094 29.8078 61.0537 30.0689 61.0537 30.3328V47.0931L71.4422 65.9839L75.7332 63.2963L68.958 46.7224L68.8253 28.9916Z"
        fill="#F7B80B"
      />
      <path
        d="M159.205 119.594H20.4033V120.042H159.205V119.594Z"
        fill="#CCCCCC"
      />
    </g>
    <defs>
      <clipPath id="clip0_1503_62975">
        <rect width="180" height="120" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
