export const DressingTenuePersoCasualIcon = () => (
  <svg
    className="casualIcon"
    width="155"
    height="151"
    viewBox="0 0 155 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1365_77540)">
      <path
        d="M82.4273 6.51221C82.7201 7.32547 82.6794 8.22047 82.3141 9.00422C81.9488 9.78797 81.2881 10.3977 80.4744 10.7019C80.2944 10.7687 80.1088 10.8195 79.9198 10.8538L78.039 22.2954L73.627 18.2481L76.0894 8.00344C75.9983 7.14017 76.2528 6.27593 76.7978 5.59758C77.3429 4.91922 78.1349 4.48126 79.0024 4.37835C79.7322 4.29523 80.4688 4.46118 81.0912 4.84895C81.7136 5.23672 82.1848 5.82324 82.4273 6.5122L82.4273 6.51221Z"
        fill="#9E616A"
      />
      <path
        d="M49.8662 32.4374C49.8662 32.4374 53.2905 27.9424 65.7164 28.0655L72.7693 25.5196L74.3812 10.8398L80.0228 12.1744L79.2168 30.3238L67.1278 37.2633L51.2776 40.4662L49.8662 32.4374Z"
        fill="#F7B80B"
      />
      <path
        d="M30.7734 143.103L34.0286 144.481L40.9283 132.663L36.1241 130.629L30.7734 143.103Z"
        fill="#9E616A"
      />
      <path
        d="M57.6727 146.163L61.2108 146.163L62.8941 132.604L57.6719 132.604L57.6727 146.163Z"
        fill="#9E616A"
      />
      <path
        d="M57.548 149.831L68.4286 149.831V149.694C68.4286 148.578 67.9823 147.508 67.1881 146.719C66.3939 145.93 65.3168 145.486 64.1936 145.486H64.1933L62.2059 143.988L58.4977 145.487L57.5479 145.487L57.548 149.831Z"
        fill="#1A202C"
      />
      <path
        d="M28.7939 145.45L38.8035 149.688L38.8575 149.562C39.2978 148.535 39.3096 147.377 38.8904 146.342C38.4711 145.307 37.6552 144.479 36.6219 144.042L36.6217 144.042L35.3845 141.889L31.382 141.823L30.5082 141.453L28.7939 145.45Z"
        fill="#1A202C"
      />
      <path
        d="M61.1426 65.916L66.5201 98.4473L65.0566 103.295L66.977 106.728L64.1569 136.881L65.3016 139.385L62.1032 141.344H55.595L55.2496 114.047L53.642 110.039V107.831L50.3693 91.1856L49.7667 110.225L48.8147 114.24L49.6144 117.098L48.5695 119.1L39.6152 138.202H32.3174L32.8818 133.882C31.5743 133.225 34.8449 131.302 35.1592 129.88L34.0846 127.478L35.1592 122.941L36.1445 114.075L35.1054 95.3597L35.5943 70.1578L44.1315 63.6719L61.1426 65.916Z"
        fill="#1A202C"
      />
      <path
        d="M48.0479 23.0724C52.121 23.0724 55.4229 19.7919 55.4229 15.7452C55.4229 11.6985 52.121 8.41797 48.0479 8.41797C43.9748 8.41797 40.6729 11.6985 40.6729 15.7452C40.6729 19.7919 43.9748 23.0724 48.0479 23.0724Z"
        fill="#9E616A"
      />
      <path
        d="M35.2335 79.4101C35.4402 79.042 35.5632 78.6333 35.5937 78.2129C35.6242 77.7924 35.5616 77.3704 35.4102 76.9767C35.2588 76.5829 35.0224 76.2268 34.7176 75.9337C34.4127 75.6405 34.0469 75.4173 33.6458 75.2798L33.6284 65.0332L29.1592 67.972L29.8609 77.2518C29.673 77.9365 29.7445 78.6659 30.0618 79.3018C30.379 79.9376 30.9201 80.4358 31.5823 80.7018C32.2446 80.9679 32.982 80.9833 33.655 80.7452C34.3279 80.5072 34.8896 80.0321 35.2335 79.4101Z"
        fill="#9E616A"
      />
      <path
        d="M34.82 34.0721L32.8234 32.1602L31.3567 33.2119C31.3567 33.2119 29.9136 34.9323 28.7592 40.0936C27.6047 45.2549 28.7592 73.6421 28.7592 73.6421L35.1086 72.7819L34.2428 57.8714L36.8403 45.2549L34.82 34.0721Z"
        fill="#F7B80B"
      />
      <path
        d="M31.8711 32.6269L38.5978 29.4345L43.1648 25.226L51.4928 25.1641L54.1793 28.6338L61.2716 31.0274L60.0895 55.3242L60.8955 57.1925C60.8955 57.1925 60.334 57.6965 60.0774 57.9783C59.8209 58.2601 60.4319 60.6197 60.4319 60.6197L60.7764 63.1868L59.8209 63.8651L61.0631 65.323L61.3715 67.6209C61.3715 67.6209 39.6877 62.2664 35.5906 71.1367C35.3741 71.6053 35.9071 67.0251 35.9071 67.0251L36.4402 63.6719L35.9114 60.6623L35.1054 59.8616L36.4571 58.5187L35.5906 52.1466L31.8711 32.6269Z"
        fill="#F7B80B"
      />
      <path
        d="M102.085 142.959H98.093L96.1934 127.662H102.085V142.959Z"
        fill="#FFB6B6"
      />
      <path
        d="M104.191 149.831H101.328L100.817 147.146L99.5083 149.831H91.9158C91.5519 149.831 91.1975 149.716 90.9043 149.501C90.6112 149.287 90.3946 148.985 90.2863 148.64C90.178 148.295 90.1836 147.924 90.3023 147.583C90.421 147.241 90.6465 146.946 90.946 146.74L97.0091 142.58V139.865L103.386 140.243L104.191 149.831Z"
        fill="#1A202C"
      />
      <path
        d="M140.906 135.091L137.472 137.114L127.984 124.919L133.052 121.934L140.906 135.091Z"
        fill="#FFB6B6"
      />
      <path
        d="M146.246 139.933L143.783 141.384L141.965 139.333L142.218 142.306L135.688 146.153C135.375 146.338 135.011 146.418 134.648 146.382C134.286 146.347 133.945 146.197 133.675 145.955C133.404 145.713 133.219 145.391 133.145 145.037C133.072 144.683 133.114 144.315 133.267 143.986L136.346 137.335L134.952 135L140.631 132.094L146.246 139.933Z"
        fill="#1A202C"
      />
      <path
        d="M100.303 45.1504L98.3633 57.4365L116.792 57.6773L114.367 45.8732L100.303 45.1504Z"
        fill="#FFB6B6"
      />
      <path
        d="M98.2915 57.2821L93.0279 69.9645L92.6445 103.88L97.1361 136.263H102.107L104.144 104.408L109.153 82.612L114.093 105.408L129.454 127.722H137.003L123.125 103.133L121.519 76.3484C121.519 76.3484 122.52 64.9856 117.397 58.2806L116.15 54.7012L98.2915 57.2821Z"
        fill="#1A202C"
      />
      <path
        d="M124.074 66.5406C123.561 66.4017 123.084 66.1527 122.679 65.8111C122.273 65.4696 121.947 65.0438 121.725 64.5637C121.503 64.0837 121.389 63.5611 121.393 63.0328C121.396 62.5044 121.515 61.9831 121.743 61.5057L113.903 36.1122L119.67 34.9766L126.84 59.8675C127.622 60.3008 128.217 61.0041 128.512 61.844C128.807 62.6839 128.781 63.6021 128.44 64.4247C128.099 65.2472 127.466 65.917 126.662 66.3069C125.857 66.6969 124.936 66.7801 124.074 66.5406V66.5406Z"
        fill="#FFB6B6"
      />
      <path
        d="M114.773 54.0711L100.996 47.6887C100.633 47.681 100.282 47.5553 99.9979 47.3308C99.7136 47.1063 99.5112 46.7955 99.4217 46.4458C98.7407 43.767 97.2424 40.7894 98.0371 33.8361C98.1939 32.4891 98.6211 31.1871 99.2933 30.007C99.9656 28.8269 100.869 27.7927 101.951 26.9657C103.032 26.1387 104.27 25.5356 105.59 25.1921C106.911 24.8486 108.287 24.7717 109.638 24.9659C111.062 25.1686 112.428 25.6683 113.645 26.4322C114.862 27.1961 115.902 28.2071 116.698 29.3986C117.494 30.5902 118.028 31.9355 118.263 33.3463C118.499 34.7571 118.432 36.2014 118.067 37.5846C116.468 43.6176 116.639 50.069 116.457 52.5485C116.43 52.907 116.285 53.2469 116.046 53.5162C115.806 53.7856 115.484 53.9696 115.129 54.0401C115.024 54.0609 114.916 54.0714 114.809 54.0715C114.797 54.0715 114.785 54.0714 114.773 54.0711Z"
        fill="#F7B80B"
      />
      <path
        d="M85.1087 11.423C85.0321 11.5929 84.9422 11.7566 84.8398 11.9125L91.4279 27.2597L95.4773 27.7533L95.5264 33.9223L88.2722 33.7407C87.6861 33.7261 87.1207 33.5229 86.6608 33.1618C86.2008 32.8007 85.8712 32.3011 85.7214 31.738L80.8384 13.3865C80.1466 13.1815 79.5341 12.7721 79.0823 12.2128C78.6305 11.6535 78.3607 10.9708 78.309 10.2555C78.2572 9.54022 78.4258 8.8262 78.7925 8.20845C79.1591 7.5907 79.7064 7.09849 80.3615 6.79728C81.0166 6.49608 81.7484 6.40016 82.4597 6.52229C83.171 6.64441 83.828 6.97879 84.3434 7.48095C84.8587 7.98311 85.208 8.62925 85.3447 9.33343C85.4814 10.0376 85.3991 10.7664 85.1087 11.423V11.423Z"
        fill="#FFB6B6"
      />
      <path
        d="M92.091 26.7125C92.2549 26.5682 92.4481 26.4607 92.6577 26.3974C92.8672 26.3341 93.088 26.3165 93.305 26.3457L100.884 27.3651C102.004 27.3506 103.085 27.7781 103.888 28.5539C104.692 29.3296 105.153 30.3903 105.17 31.5033C105.188 32.6163 104.761 33.6908 103.982 34.4912C103.203 35.2916 102.137 35.7526 101.017 35.7731L93.4416 36.9863C93.2254 37.0209 93.0042 37.0088 92.7932 36.9509C92.5821 36.8929 92.3861 36.7904 92.2185 36.6504C92.0509 36.5104 91.9156 36.3362 91.8219 36.1395C91.7282 35.9429 91.6782 35.7285 91.6755 35.511L91.5773 27.8654C91.5744 27.6478 91.6188 27.4321 91.7074 27.2332C91.7961 27.0342 91.9269 26.8566 92.091 26.7125Z"
        fill="#F7B80B"
      />
      <path
        d="M113.552 42.5444C113.358 42.4432 113.189 42.3015 113.055 42.1292C112.921 41.9569 112.826 41.758 112.777 41.546L111.056 34.1425C110.646 33.1066 110.667 31.9516 111.113 30.9306C111.559 29.9096 112.394 29.1061 113.436 28.6961C114.477 28.2861 115.64 28.3031 116.669 28.7433C117.698 29.1836 118.509 30.0113 118.925 31.0448L122.746 37.6549C122.855 37.8436 122.922 38.0534 122.942 38.27C122.962 38.4867 122.935 38.7051 122.863 38.9103C122.79 39.1156 122.674 39.303 122.522 39.4596C122.37 39.6162 122.186 39.7385 121.982 39.8181L114.819 42.6139C114.615 42.6936 114.396 42.7286 114.178 42.7166C113.959 42.7046 113.746 42.6459 113.552 42.5444Z"
        fill="#F7B80B"
      />
      <path
        d="M113.247 22.3545C117.023 20.3037 118.411 15.6001 116.347 11.8487C114.283 8.09723 109.548 6.71851 105.773 8.76924C101.997 10.82 100.609 15.5236 102.673 19.275C104.737 23.0265 109.471 24.4052 113.247 22.3545Z"
        fill="#FFB6B6"
      />
      <path
        d="M104.149 12.4497C102.477 12.4404 100.805 12.431 99.1335 12.4217C99.0146 11.2897 99.5217 10.1635 100.26 9.29382C100.999 8.4241 101.956 7.76922 102.901 7.12525C103.86 6.47146 104.853 5.80283 105.993 5.57433C106.948 5.38283 107.936 5.51571 108.901 5.64906C110.182 5.82593 111.462 6.00279 112.742 6.17964C114.147 6.37377 115.617 6.59334 116.759 7.43063C118.302 8.5629 117.908 11.1765 119.388 12.3883C123.861 16.0481 122.05 19.2443 122.134 22.5803C116.729 23.1436 111.324 23.7068 105.918 24.2701C105.332 24.3705 104.731 24.3531 104.151 24.2191C103.584 24.0467 103.063 23.5798 103.021 22.9916C102.975 22.3345 103.499 21.7968 103.925 21.2921C104.942 20.041 105.552 18.5129 105.675 16.9098C105.798 15.3067 105.428 13.7043 104.614 12.3145L104.149 12.4497Z"
        fill="#1A202C"
      />
      <path
        d="M46.1612 22.7156C46.1298 22.6466 46.0986 22.5773 46.0674 22.5078C46.0794 22.5082 46.0913 22.5099 46.1033 22.5102L46.1612 22.7156Z"
        fill="#1A202C"
      />
      <path
        d="M40.4681 8.75143C40.8183 8.4232 41.2735 8.22769 41.754 8.19908C42.2309 8.21435 42.7145 8.64228 42.6157 9.10607C43.4917 7.56515 44.886 6.38 46.5538 5.75865C48.2217 5.13731 50.0565 5.11947 51.7363 5.70827C52.9216 6.12374 54.084 6.95493 54.3563 8.17402C54.3828 8.49409 54.4788 8.80463 54.6378 9.08424C54.7756 9.23371 54.9544 9.33988 55.1522 9.38966C55.35 9.43944 55.5581 9.43066 55.7509 9.3644L55.7626 9.36098C55.8286 9.33859 55.8999 9.33639 55.9671 9.35466C56.0344 9.37293 56.0946 9.41084 56.1399 9.46348C56.1853 9.51611 56.2137 9.58105 56.2215 9.6499C56.2293 9.71876 56.2161 9.78835 56.1837 9.84967L55.8483 10.4711C56.2711 10.5647 56.7103 10.5554 57.1287 10.444C57.1982 10.4259 57.2717 10.4296 57.339 10.4546C57.4063 10.4796 57.4641 10.5247 57.5046 10.5837C57.5451 10.6426 57.5663 10.7126 57.5651 10.784C57.564 10.8553 57.5407 10.9246 57.4984 10.9823C56.9411 11.7399 56.2134 12.3578 55.3734 12.7868C54.5333 13.2158 53.6041 13.444 52.6596 13.4534C51.3196 13.4453 49.9658 12.9865 48.6617 13.2926C48.1572 13.411 47.6858 13.6402 47.282 13.9633C46.8783 14.2863 46.5524 14.6951 46.3284 15.1596C46.1044 15.6241 45.9879 16.1325 45.9875 16.6475C45.9871 17.1626 46.1027 17.6712 46.3259 18.136C45.9253 17.7007 45.1508 17.8037 44.741 18.2306C44.5523 18.4591 44.4128 18.7237 44.3312 19.0079C44.2495 19.2922 44.2275 19.59 44.2664 19.8831C44.3757 20.7807 44.6439 21.6519 45.0587 22.4567C43.4741 22.4083 41.9426 21.8778 40.6712 20.937C39.3997 19.9962 38.4495 18.6903 37.9489 17.1958C37.4483 15.7013 37.4215 14.0902 37.872 12.5801C38.3225 11.0699 39.2287 9.73353 40.4681 8.75142V8.75143Z"
        fill="#1A202C"
      />
      <path
        d="M154.116 150.123H17.6173C17.5672 150.123 17.5193 150.07 17.4839 149.976C17.4486 149.882 17.4287 149.755 17.4287 149.623C17.4287 149.491 17.4486 149.364 17.4839 149.27C17.5193 149.176 17.5672 149.123 17.6173 149.123H154.116C154.141 149.123 154.166 149.136 154.189 149.161C154.212 149.186 154.233 149.223 154.251 149.269C154.268 149.315 154.282 149.371 154.292 149.431C154.301 149.492 154.306 149.557 154.306 149.623C154.306 149.689 154.301 149.754 154.292 149.815C154.282 149.875 154.268 149.931 154.251 149.977C154.233 150.024 154.212 150.06 154.189 150.085C154.166 150.11 154.141 150.123 154.116 150.123Z"
        fill="#CCCCCC"
      />
    </g>
    <defs>
      <clipPath id="clip0_1365_77540">
        <rect
          width="154"
          height="150"
          fill="white"
          transform="translate(0.768555 0.0410156)"
        />
      </clipPath>
    </defs>
  </svg>
);
