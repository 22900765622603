export const ConventionIcon = () => (
  <svg width="102" height="121" viewBox="0 0 102 121" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_946_48124)">
      <path d="M27.9568 115.8C27.3357 116.124 26.7142 116.562 26.2436 117.338C26.1549 117.484 26.0759 117.643 26.0018 117.808C26.2106 116.325 26.6269 114.929 27.2271 113.767C27.4484 113.338 27.0765 112.71 26.8537 113.141V113.141C25.9023 113.635 25.5015 114.788 25.3485 116.154C25.1594 115.665 24.9268 115.224 24.6556 114.856C24.5448 114.706 24.3905 114.675 24.2822 114.856C24.1895 115.011 24.1707 115.331 24.2822 115.482C23.8729 117.629 24.9295 118.294 25.2943 119.362C25.3025 119.599 25.3112 119.833 25.3193 120.062C25.3274 120.289 25.4136 120.424 25.5139 120.47C25.5624 120.596 25.6501 120.683 25.7394 120.683C25.889 120.683 25.9954 120.48 26.0034 120.241C26.0327 119.362 26.1228 117.836 26.6454 117.917C27.7585 118.09 27.81 117.191 28.0972 116.653C28.3231 116.23 28.2826 115.63 27.9568 115.8H27.9568Z" fill="#E6E6E6"/>
      <path d="M83.935 115.8C84.556 116.124 85.1776 116.562 85.6482 117.338C85.7369 117.484 85.8159 117.643 85.8899 117.808C85.6812 116.325 85.2648 114.929 84.6647 113.767C84.4434 113.338 84.8153 112.71 85.0381 113.141V113.141C85.9895 113.635 86.3903 114.788 86.5433 116.154C86.7324 115.665 86.965 115.224 87.2362 114.856C87.347 114.706 87.5013 114.675 87.6096 114.856C87.7023 115.011 87.7211 115.331 87.6096 115.482C88.0188 117.629 86.9623 118.294 86.5975 119.362C86.5893 119.599 86.5806 119.833 86.5724 120.062C86.5644 120.289 86.4782 120.424 86.3779 120.47C86.3293 120.596 86.2417 120.683 86.1524 120.683C86.0028 120.683 85.8964 120.48 85.8884 120.241C85.8591 119.362 85.7689 117.836 85.2464 117.917C84.1332 118.09 84.0817 117.191 83.7946 116.653C83.5687 116.23 83.6092 115.63 83.935 115.8Z" fill="#E6E6E6"/>
      <path d="M-18.8105 120.537C-18.8105 120.732 -18.654 120.888 -18.459 120.888H100.677C100.872 120.888 101.029 120.732 101.029 120.537C101.029 120.343 100.872 120.186 100.677 120.186H-18.459C-18.654 120.186 -18.8105 120.343 -18.8105 120.537Z" fill="#CCCCCC"/>
      <path d="M90.1384 114.253L92.3885 113.687L90.8519 103.316L87.5312 104.15L90.1384 114.253Z" fill="#FFB6B6"/>
      <path d="M99.1013 116.787C99.2059 117.164 99.0227 117.544 98.6922 117.636L94.2549 118.863C94.2549 118.863 93.3319 117.231 91.3423 116.97L91.915 119.51L89.626 120.143L88.7848 116.036C88.7848 116.036 87.574 114.049 88.5398 112.595C89.5056 111.141 88.5182 111.58 88.5182 111.58L88.3261 108.947L91.8978 108.396L93.01 112.305L95.7673 115.727L98.5664 116.261C98.8155 116.308 99.0257 116.515 99.1013 116.787H99.1013Z" fill="#2F2E41"/>
      <path d="M68.5879 112.451L70.7572 113.273L75.444 103.891L72.2427 102.677L68.5879 112.451Z" fill="#FFB6B6"/>
      <path d="M74.4827 119.651C74.3523 120.019 73.9843 120.226 73.6608 120.112L69.3187 118.583C69.3187 118.583 69.4974 116.717 68.016 115.366L67.029 117.776L64.7891 116.987L66.4542 113.139C66.4542 113.139 66.6007 110.818 68.2263 110.178C69.8519 109.537 68.7909 109.333 68.7909 109.333L70.143 107.064L73.3874 108.654L72.0581 112.494L72.3567 116.876L74.3461 118.913C74.5231 119.094 74.5768 119.384 74.4827 119.65L74.4827 119.651Z" fill="#2F2E41"/>
      <path d="M78.0067 19.6951H66.4938C66.5107 19.1558 66.3954 18.6165 66.159 18.1334C66.1534 18.6699 66.0324 19.2064 65.8102 19.6951H65.2868C64.4878 19.6951 63.8379 19.0462 63.8379 18.2485V10.7798C63.8379 7.12273 66.7161 4.10885 70.3736 3.93471C70.4834 3.92908 70.5903 3.92627 70.7 3.92627C73.6795 3.92627 76.3411 5.85594 77.247 8.71532C78.5778 8.10301 79.374 11.5832 79.9029 17.111C80.3053 18.3918 79.3487 19.6951 78.0067 19.6951Z" fill="#2F2E41"/>
      <path d="M100.597 62.3954C101.168 63.7462 100.933 65.1351 100.072 65.4977C99.2108 65.8602 98.0502 65.0591 97.4795 63.7084C97.2297 63.1171 97.1344 62.5185 97.1783 62.0019L94.832 56.248L97.5601 55.2063L99.5835 60.9891C99.9848 61.3182 100.348 61.8041 100.597 62.3954Z" fill="#FFB6B6"/>
      <path d="M75.3724 17.1672L69.8694 18.3165L70.7135 20.8444L69.0254 40.179L71.8389 46.4049L89.0012 45.0005L84.781 36.5739C84.781 36.5739 86.4691 29.5518 84.2183 28.1474C81.9675 26.743 78.3099 19.7209 78.3099 19.7209L75.3724 17.1672V17.1672Z" fill="#F7B80B"/>
      <path d="M70.2428 19.4343L65.3679 21.406L67.056 46.1238L63.3984 67.471L75.2151 69.4372C75.2151 69.4372 73.8084 22.2487 72.9643 21.406C72.1203 20.5634 70.2428 19.4343 70.2428 19.4343V19.4343Z" fill="#2F2E41"/>
      <path d="M79.791 22.5179C79.791 22.5179 82.9631 20.5142 84.6769 21.9978C86.3907 23.4813 99.5854 59.3428 99.5854 59.3428L96.0264 60.8866L87.8644 43.8014L79.791 22.5179H79.791Z" fill="#2F2E41"/>
      <path d="M88.8783 82.9395L92.0969 107.638L87.5953 107.919C87.5953 107.919 85.2545 101.542 82.5845 94.0316C82.0162 92.439 81.4338 90.7958 80.857 89.1498C80.2324 87.3634 79.6106 85.577 79.0198 83.8524C77.5596 79.5998 76.2794 75.7292 75.5479 73.2012C75.4213 72.7575 75.3088 72.3558 75.2159 72.0019C73.2465 64.4517 72.1211 44.7196 72.1211 44.7196L88.4394 43.3152L88.6138 43.5315L92.0969 47.8767L91.9337 48.7952L87.5953 73.0889L88.8783 82.9395L88.8783 82.9395Z" fill="#2F2E41"/>
      <path d="M87.5939 73.0889L88.8768 82.9395L83.1063 93.0822L82.583 94.0316L74.6377 108.447L70.7129 106.225C70.7129 106.225 75.8194 94.5091 79.0183 83.8524C79.3728 82.6811 79.702 81.5211 79.9974 80.3919C80.8668 77.0859 79.4432 71.0525 80.0002 69.3897C81.8825 63.7776 88.0159 52.5507 90.8631 47.4639L91.9323 48.7953L87.5939 73.0889Z" fill="#2F2E41"/>
      <path d="M78.2383 19.5071L83.9366 21.6869L98.8481 65.2239L89.8449 66.6284L85.6247 41.3488L78.2383 19.5071Z" fill="#2F2E41"/>
      <path d="M63.9916 66.5019C63.8465 67.9607 62.9751 69.0686 62.0452 68.9765C61.1153 68.8844 60.4791 67.627 60.6241 66.1682C60.6876 65.5296 60.8903 64.9583 61.1761 64.5254L61.8684 58.3516L64.7635 58.7376L63.7738 64.7828C63.9688 65.2633 64.055 65.8633 63.9916 66.5019V66.5019Z" fill="#FFB6B6"/>
      <path d="M67.3363 21.4061C67.3363 21.4061 63.6787 20.5634 62.5533 22.5296C61.4279 24.4958 60.8652 62.696 60.8652 62.696L64.7355 62.9769L66.7736 44.1577L67.3363 21.4061V21.4061Z" fill="#2F2E41"/>
      <path d="M72.0048 16.486C75.2348 16.486 77.8533 13.8719 77.8533 10.6472C77.8533 7.42249 75.2348 4.80835 72.0048 4.80835C68.7747 4.80835 66.1562 7.42249 66.1562 10.6472C66.1562 13.8719 68.7747 16.486 72.0048 16.486Z" fill="#FFB6B6"/>
      <path d="M79.3468 10.1451C79.3468 10.1451 76.4977 9.30241 74.7919 10.1451C72.4213 11.3161 66.3691 10.1451 66.3691 10.1451V7.50555C66.3691 5.96462 67.5185 4.66484 69.05 4.47371L73.4175 3.92867C76.8458 3.50085 79.8739 6.16957 79.8739 9.61881V9.61882C79.8739 9.90945 79.6379 10.1451 79.3468 10.1451Z" fill="#2F2E41"/>
      <path d="M73.1218 19.6951H66.4932C66.5101 19.1558 66.3948 18.6165 66.1584 18.1333C66.1528 18.6698 66.0318 19.2063 65.8096 19.6951H65.5254V7.89795H70.5897L73.1218 19.6951Z" fill="#2F2E41"/>
      <path d="M39.6494 112.169L36.6844 114.2L27.4395 103.731L31.8154 100.733L39.6494 112.169Z" fill="#FFB6B6"/>
      <path d="M29.4453 119.061C29.4595 119.28 29.7546 119.815 29.8785 119.996C30.2592 120.551 31.0184 120.693 31.5741 120.313L40.7557 114.022C41.1348 113.763 41.2316 113.245 40.9718 112.866L40.71 112.483C40.71 112.483 40.3771 111.023 38.4718 110.248C38.4718 110.248 38.0692 112.153 35.1427 111.606L33.995 111.029L32.1453 116.28L30.2638 117.941C29.8521 118.304 29.4098 118.513 29.4454 119.061L29.4453 119.061L29.4453 119.061Z" fill="#2F2E41"/>
      <path d="M17.7897 117.561L14.1959 117.561L12.4863 103.698L16.8853 104.246L17.7897 117.561Z" fill="#FFB6B6"/>
      <path d="M5.47759 117.479C5.36569 117.668 5.30664 118.276 5.30664 118.495C5.30664 119.169 5.85273 119.715 6.52639 119.715H17.656C18.1156 119.715 18.4882 119.342 18.4882 118.883V118.419C18.4882 118.419 19.0387 117.027 17.9052 115.31C17.9052 115.31 16.4964 116.654 14.3912 114.549L13.7704 113.425L9.27676 116.711L6.786 117.018C6.24106 117.085 5.75794 117.007 5.47764 117.479H5.47759V117.479Z" fill="#2F2E41"/>
      <path d="M5.18963 64.8809L5.46007 68.1497L4.92188 70.7492L5.90185 73.4897L7.10064 87.9798L13.3486 112.962L18.1191 113.966L15.829 89.012L17.6054 68.4287L5.18963 64.8809Z" fill="#2F2E41"/>
      <path d="M25.4619 50.6897L7.2167 50.943L5.18945 64.8801L17.6052 70.7085L17.2092 90.6134L31.0367 110.63L38.4012 106.422L26.0608 89.4236L29.5163 61.079L25.4619 50.6897H25.4619Z" fill="#2F2E41"/>
      <path d="M11.9572 17.9239L8.73828 22.055L10.2588 53.7303L22.1687 53.477L24.196 25.8561L21.662 18.7608L20.7451 17.1707L19.6485 15.1118L12.6182 15.614L11.9572 17.9239H11.9572V17.9239Z" fill="#E6E6E6"/>
      <path d="M7.20214 37.5125L7.84582 43.9862L7.05501 51.4357L6.06649 53.4553L3.80675 61.49C3.80675 61.49 1.50055 65.0036 6.56866 65.2562C11.6368 65.5088 28.2486 63.8649 28.2486 63.8649C28.2486 63.8649 30.3198 63.8139 29.4172 59.4813C27.9591 56.9755 28.8992 53.4444 28.8992 53.4444L26.9817 46.38L27.4884 39.7916L31.0362 22.5601C27.8854 20.084 24.3563 18.3118 20.5198 17.1116L17.1597 25.9472L12.1568 17.8718L10.2563 19.2656C7.69294 19.9378 5.43701 20.9714 3.65282 22.5598L7.20214 37.5125Z" fill="#2F2E41"/>
      <path d="M15.5043 14.8189C18.9779 14.8189 21.7937 12.003 21.7937 8.52944C21.7937 5.05588 18.9779 2.23999 15.5043 2.23999C12.0307 2.23999 9.21484 5.05588 9.21484 8.52944C9.21484 12.003 12.0307 14.8189 15.5043 14.8189Z" fill="#FFB6B6"/>
      <path d="M20.1245 13.6971C20.4709 13.7562 20.8777 12.9165 21.2663 12.0986C22.0799 10.3866 22.5971 9.29825 22.6364 7.75986C22.67 6.44871 22.3057 6.79283 21.9514 3.87781C21.8345 2.91635 20.1919 2.9182 19.8576 2.46166C19.2929 1.69048 20.1593 1.37244 18.7544 0.909175C16.1228 0.0414657 14.9328 1.38942 13.2615 1.50555C13.2193 1.50849 12.3908 2.31873 11.928 2.36063C11.1173 2.43403 9.94781 2.30776 9.4235 1.07258C8.93326 -0.0823187 8.60853 3.29254 9.0467 3.7102C9.41872 4.06481 10.0605 4.95486 10.0768 5.01959C10.0954 5.09284 8.55091 3.38195 8.2931 4.08701C8.10754 4.59442 8.69162 7.20502 9.16343 7.30315C9.55798 7.38518 10.5229 6.08471 10.3052 6.61808C9.8003 7.85503 12.8363 8.6443 16.4708 6.16137C16.5997 6.07331 16.478 6.21374 16.9275 7.30315C17.4945 8.67713 17.6225 8.75156 17.6126 9.12999C17.5912 9.9492 16.9761 10.1904 17.1559 10.7285C17.3015 11.1643 17.8706 11.5017 18.2977 11.4136C18.7796 11.3141 18.8622 10.7179 19.2111 10.7285C19.5099 10.7376 19.8646 11.1877 19.8962 11.6419C19.9627 12.5989 18.5459 12.8621 18.2977 14.1538C18.1576 14.8828 19.8062 13.6428 20.1245 13.6971Z" fill="#2F2E41"/>
      <path d="M34.3578 63.9707C34.385 63.2664 34.2432 62.5659 33.9442 61.9276L34.592 54.5877L31.0893 54.3818L30.8095 61.8509C30.479 62.4734 30.3025 63.1661 30.2946 63.8709C30.2506 65.6341 31.1246 67.0858 32.2463 67.1139C33.3679 67.142 34.3132 65.7353 34.3572 63.9725L34.3578 63.9707Z" fill="#FFB6B6"/>
      <path d="M26.9069 34.3732L29.6689 42.91L30.4618 60.3454L35.0851 60.251L35.4438 41.1525C35.4438 41.1525 33.1396 27.0403 32.3681 23.8684C31.5965 20.6965 24.4824 21.8719 24.4824 21.8719L26.9069 34.3732V34.3732V34.3732Z" fill="#2F2E41"/>
      <path d="M3.46151 63.385C3.64314 65.1391 4.69544 66.4676 5.81147 66.3519C6.9275 66.2363 7.68535 64.7207 7.5038 62.9663C7.44084 62.2643 7.21069 61.5875 6.83257 60.9927L5.97001 53.5684L2.49414 54.0473L3.71346 61.3142C3.46523 61.9738 3.37861 62.6833 3.46077 63.3834L3.46151 63.385Z" fill="#FFB6B6"/>
      <path d="M8.57668 33.2941L10.0169 20.6416C10.0169 20.6416 2.83275 20.0256 2.31141 23.2481C1.79008 26.4706 0.595703 40.7197 0.595703 40.7197L2.44563 59.7318L7.06219 59.4647L6.49028 42.0206L8.57668 33.2941V33.2941Z" fill="#2F2E41"/>
    </g>
    <defs>
      <clipPath id="clip0_946_48124">
        <rect width="101.124" height="120" fill="white" transform="translate(0.4375 0.718994)"/>
      </clipPath>
    </defs>
  </svg>

);