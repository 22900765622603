import { useCustomerContext } from "../context/CustomerContext";
import { useOnboardingContext } from "../context/OnboardingContext";
import { useOptionContext } from "../context/OptionsContext";
import { useSessionContext } from "../context/SessionContext";
import { useUserContext } from "../context/UserContext";

export const useResetOrder = () => {
  const { resetInfos } = useUserContext();
  const { resetCustomerInfo } = useCustomerContext();
  const { resetOptionInfo } = useOptionContext();
  const { resetOnboarding } = useOnboardingContext();
  const { resetSessionInfo } = useSessionContext();

  const completeResetOrder = () => {
    // reset all
    resetInfos();
    resetOptionInfo();
    resetCustomerInfo();
    resetOnboarding();
    resetSessionInfo();
  };
  const partialResetOrder = () => {
    // reset all except user info (email, name, phone...)
    resetInfos();
    resetOptionInfo();
    resetOnboarding();
    resetSessionInfo();
  };
  return { completeResetOrder, partialResetOrder };
};
