export default function OptionsBesacesIcon() {
  return (
    <svg
      width="90"
      height="100"
      viewBox="0 0 90 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_841_52193)">
        <path
          d="M57.5171 7.73902L45.0171 -4.43204L46.3011 -10.5556C46.8262 -16.08 53.0948 -19.7888 52.3238 -21.464L61.1355 -17.9189L57.5171 7.73902Z"
          fill="#2F2E41"
        />
        <path
          d="M83.032 61.2745L74.8271 47.8919L79.1912 43.7012L86.669 59.1405C87.3332 59.2526 87.9467 59.5663 88.4264 60.0391C88.9061 60.5119 89.2287 61.1208 89.3504 61.7833C89.4721 62.4457 89.387 63.1295 89.1067 63.7419C88.8264 64.3543 88.3645 64.8657 87.7835 65.2065C87.2026 65.5473 86.5309 65.7012 85.8596 65.6471C85.1882 65.593 84.5498 65.3337 84.0309 64.9043C83.512 64.4749 83.1378 63.8963 82.9591 63.2469C82.7804 62.5975 82.8059 61.9089 83.032 61.2745Z"
          fill="#A0616A"
        />
        <path
          d="M57.5852 18.7554L61.2901 15.1397C61.2901 15.1397 64.5299 14.1395 67.5212 19.3098C70.5126 24.4801 77.0781 38.532 77.0781 38.532L79.5406 40.8798L79.8639 41.683L82.0097 43.6803L81.5948 44.6539L84.0993 45.4194L76.7405 52.0232L72.9776 48.0287L72.9623 45.6519L71.3281 44.9556L69.7161 43.6194L69.1363 41.4245L57.5852 18.7554Z"
          fill="#2F2E41"
        />
        <path
          d="M42.879 29.2852V50.4918L67.55 46.0615L61.3 26.9826L42.879 29.2852Z"
          fill="#A0616A"
        />
        <path
          d="M67.3855 44.2522L40.7408 47.5416L37.7803 70.568L35.1487 108.397L14.425 141.292L24.2934 143.594L50.9381 111.357L56.5303 81.4491L58.175 155.436L66.7276 155.765L75.2803 81.4232C75.2803 81.4232 74.9513 46.5548 67.3855 44.2522Z"
          fill="#2F2E41"
        />
        <path
          d="M55.7079 2.96928L45.1816 0.00875854L40.2474 6.91665L45.0171 47.8706C45.0171 47.8706 62.7803 34.7127 64.754 46.8838L65.5763 30.9298C65.5763 30.9298 66.4673 20.4035 64.5416 19.5811C62.6158 18.7588 55.379 8.56139 55.379 8.56139L55.7079 2.96928Z"
          fill="#F7B80B"
        />
        <path
          d="M37.9447 2.31139L34.1463 6.10982L29.0632 43.4298L41.8481 56.2147C41.8481 56.2147 55.7079 31.5877 45.8395 11.193L43.9991 1.66431L37.9447 2.31139Z"
          fill="#2F2E41"
        />
        <path
          d="M55.379 7.22632L69.5237 20.4036L74.129 57.2457L62.9447 54.6141L63.9316 27.3115L54.0632 9.54836L55.379 7.22632Z"
          fill="#2F2E41"
        />
        <path
          d="M53.9376 87.8377H14.0572C13.0646 87.8366 12.1129 87.4418 11.411 86.7399C10.7091 86.038 10.3143 85.0863 10.3132 84.0937V68.7135C10.3172 65.1498 11.7346 61.7332 14.2545 59.2133C16.7745 56.6934 20.191 55.2759 23.7547 55.2719H39.3511C44.211 55.2774 48.8702 57.2104 52.3067 60.6469C55.7431 64.0833 57.6761 68.7425 57.6816 73.6024V84.0937C57.6805 85.0863 57.2857 86.038 56.5838 86.7399C55.8819 87.4418 54.9302 87.8366 53.9376 87.8377Z"
          fill="#F7B80B"
        />
        <path
          d="M28.4555 55.9001L32.0251 37.3696L33.0495 32.0522C33.169 31.4317 32.2181 31.1659 32.0979 31.7898L28.5283 50.3203L27.504 55.6378C27.3844 56.2583 28.3353 56.5241 28.4555 55.9001Z"
          fill="#F7B80B"
        />
        <path
          d="M52.0882 0.0429344C57.6867 0.0429344 62.2252 -4.49556 62.2252 -10.0941C62.2252 -15.6926 57.6867 -20.2311 52.0882 -20.2311C46.4897 -20.2311 41.9512 -15.6926 41.9512 -10.0941C41.9512 -4.49556 46.4897 0.0429344 52.0882 0.0429344Z"
          fill="#A0616A"
        />
        <path
          d="M64.2375 -9.75769L63.4974 -7.72808L62.3428 -7.9715C62.4387 -8.69399 62.3917 -9.42829 62.2046 -10.1327C62.025 -9.46927 61.6626 -8.86971 61.1586 -8.40242L57.5599 -9.71492C52.0467 -9.7544 50.9151 -1.17216 49.9513 7.73903L39.6882 -0.30374C39.7072 -0.844818 39.6463 -1.38574 39.5072 -1.909C39.4048 -1.52511 39.238 -1.16139 39.0138 -0.833345L37.7803 -1.80045L38.7342 -13.4189C38.8572 -14.916 39.3126 -16.3669 40.0673 -17.6658C40.822 -18.9646 41.8568 -20.0788 43.0966 -20.9271C44.3363 -21.7755 45.7496 -22.3366 47.2336 -22.5696C48.7176 -22.8026 50.2349 -22.7017 51.675 -22.2741L58.0467 -19.3399L60.1487 -19.5636C63.8066 -18.2314 65.5697 -13.4189 64.2375 -9.75769Z"
          fill="#2F2E41"
        />
        <path
          d="M39.8445 55.1074H24.2481C22.3656 55.1068 20.504 55.5026 18.7845 56.269C17.065 57.0354 15.5261 58.1553 14.2681 59.5557C16.7322 57.3256 19.9378 56.0918 23.2613 56.0943H38.8577C43.7176 56.0998 48.3768 58.0328 51.8132 61.4692C55.2496 64.9057 57.1827 69.5649 57.1882 74.4247V84.916C57.1888 85.6677 56.9619 86.402 56.5372 87.0222C57.0416 86.6792 57.4545 86.2181 57.7399 85.679C58.0254 85.1399 58.1748 84.5392 58.175 83.9292V73.4379C58.1695 68.578 56.2365 63.9188 52.8001 60.4824C49.3636 57.0459 44.7044 55.1129 39.8445 55.1074Z"
          fill="#3F3D56"
        />
        <path
          d="M33.1338 6.6534C34.1448 5.77893 35.767 6.15851 36.683 6.9886C37.8302 8.02818 38.3056 9.57868 38.6859 11.0298C39.5703 14.5547 40.0652 18.166 40.1618 21.7989C40.2968 25.445 40.154 29.0945 39.9526 32.7354C39.7441 36.5033 39.5056 40.2697 39.2799 44.0367L38.5063 56.9437C38.4683 57.578 39.4553 57.5758 39.4932 56.9437C39.9705 48.9785 40.4742 41.0144 40.922 33.0475C41.3466 25.4937 41.5694 17.7275 39.5295 10.3655C39.07 8.70724 38.3862 6.95203 36.9226 5.92762C35.5941 4.99776 33.7034 4.85933 32.436 5.95558C31.9549 6.37177 32.6554 7.06722 33.1338 6.6534Z"
          fill="#F7B80B"
        />
        <path
          d="M52.2533 24.1202L38.2065 34.2417L40.8619 40.3638L55.0449 27.8401C55.7683 28.0089 56.5262 27.951 57.2155 27.6743C57.9049 27.3976 58.4924 26.9154 58.8984 26.2933C59.3043 25.6711 59.5089 24.9392 59.4846 24.1967C59.4602 23.4543 59.2079 22.7373 58.762 22.1432C58.3162 21.549 57.6982 21.1064 56.9922 20.8756C56.2861 20.6447 55.5261 20.6366 54.8154 20.8525C54.1046 21.0684 53.4774 21.4978 53.0191 22.0824C52.5607 22.667 52.2934 23.3784 52.2533 24.1202Z"
          fill="#A0616A"
        />
        <path
          d="M33.8046 6.36856C33.8046 6.36856 37.4935 6.98336 37.8878 13.5597C38.2822 20.1361 38.1965 32.5529 38.1965 32.5529L41.6925 29.8357L45.2036 28.8384L47.6941 35.0441L45.7335 38.3236L43.4773 39.4125L42.6607 38.9168L41.3872 40.6731C39.5339 41.636 37.3807 41.8476 35.3754 41.264C33.37 40.6804 31.6667 39.3464 30.6195 37.5393L29.3523 36.0452L28.4232 33.931L28.9362 31.4802L28.5042 13.8108C28.4639 12.164 28.9587 10.5484 29.9144 9.20656C30.8701 7.86471 32.2351 6.86892 33.8046 6.36856Z"
          fill="#2F2E41"
        />
      </g>
      <defs>
        <clipPath id="clip0_841_52193">
          <rect
            width="89.8026"
            height="100"
            fill="white"
            transform="translate(0.0986938)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
