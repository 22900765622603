import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import { FC, ReactElement } from "react";
import { colors } from "../../ui/theme";
import ColorBoxButton from "../Buttons/ColorBoxButton";
import { DressingMakeupIcon } from "../Icons/DressingMakeupIcon";
import { MakeupItemCardProps } from "./interfaces";

const RED = "#B80000";
const NUDE = "#fad0c3";
const WHITE = "#fff";

const DressingToggleItemCard: FC<MakeupItemCardProps> = ({
  onClickNails,
  onClickLipstick,
  selectedNails,
  selectedLipstick,
}): ReactElement => {
  return (
    <Flex
      gap="14px"
      width="140%"
      border={`1px solid ${colors.gray.lightMode}`}
      padding="14px"
      borderRadius="12px"
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap="14px"
      >
        <DressingMakeupIcon />
        <Text variant="xl" fontWeight="500">
          Maquillage
        </Text>
      </Flex>

      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap="14px"
        width="100%"
        minWidth="300px"
      >
        <Grid
          width="100%"
          height="50%"
          templateColumns="2fr 1fr 1fr 1fr 50px"
          gap="1rem"
        >
          <Text alignSelf="center">Ongles</Text>

          <ColorBoxButton
            buttonColor={RED}
            isActive={selectedNails === RED}
            onClick={() => onClickNails(RED)}
            m="auto"
          />
          <ColorBoxButton
            buttonColor={NUDE}
            isActive={selectedNails === NUDE}
            onClick={() => onClickNails(NUDE)}
            m="auto"
          />
          <ColorBoxButton
            buttonColor={WHITE}
            isActive={selectedNails === "None"}
            onClick={() => onClickNails("None")}
            m="auto"
          />
          <Text alignSelf="center">
            {selectedNails === RED
              ? "Rouge"
              : selectedNails === NUDE
              ? "Nude"
              : "Aucun"}
          </Text>
        </Grid>
        <Box w="90%" h="1px" bg="#aaa" mx="auto" />

        <Grid
          width="100%"
          height="50%"
          templateColumns="2fr 1fr 1fr 1fr 50px"
          gap="1rem"
        >
          <Text alignSelf="center">Lèvres</Text>
          <ColorBoxButton
            buttonColor={RED}
            isActive={selectedLipstick === RED}
            onClick={() => onClickLipstick(RED)}
            m="auto"
          />
          <ColorBoxButton
            buttonColor={NUDE}
            isActive={selectedLipstick === NUDE}
            onClick={() => onClickLipstick(NUDE)}
            m="auto"
          />
          <ColorBoxButton
            buttonColor={WHITE}
            isActive={selectedLipstick === "None"}
            onClick={() => onClickLipstick("None")}
            m="auto"
          />
          <Text alignSelf="center">
            {selectedLipstick === RED
              ? "Rouge"
              : selectedLipstick === NUDE
              ? "Nude"
              : "Aucun"}
          </Text>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default DressingToggleItemCard;
