export const RolePassageMicroIcon = () => (
  <svg
    width="127"
    height="121"
    viewBox="0 0 127 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_942_38828)">
      <path
        d="M75.0775 34.4908L75.0535 34.5054C74.6201 34.7703 74.1142 34.8916 73.6079 34.852C73.1015 34.8125 72.6205 34.6141 72.2335 34.2852C71.8465 33.9563 71.5732 33.5136 71.4525 33.0203C71.3318 32.5269 71.37 32.008 71.5615 31.5376C71.6364 31.3521 71.7345 31.1769 71.8535 31.016C72.0639 30.7298 72.335 30.4935 72.6473 30.3242C72.9595 30.1548 73.3054 30.0565 73.6601 30.0363C74.0148 30.016 74.3695 30.0744 74.6991 30.2071C75.0286 30.3398 75.3248 30.5437 75.5664 30.8041C75.808 31.0646 75.9892 31.3751 76.0969 31.7137C76.2046 32.0522 76.2362 32.4104 76.1895 32.7625C76.1428 33.1147 76.0189 33.4522 75.8267 33.751C75.6345 34.0497 75.3786 34.3024 75.0775 34.4908L75.0775 34.4908Z"
        fill="#F7B80B"
      />
      <path
        d="M75.0777 34.4915C73.8417 33.586 72.6671 32.5994 71.5617 31.5384C71.6367 31.3529 71.7348 31.1776 71.8537 31.0167C72.9813 32.1646 74.2165 33.2014 75.5425 34.1128C75.404 34.2579 75.2478 34.3852 75.0777 34.4915Z"
        fill="#E2E8F0"
      />
      <path
        d="M67.9302 40.9246L73.5453 34.8344L71.4431 33.0619L66.5038 39.7558C66.4148 39.8734 66.3754 40.0211 66.3939 40.1673C66.4124 40.3136 66.4874 40.4468 66.6029 40.5384L66.6793 40.599L66.4592 40.8382C66.4321 40.8675 66.4116 40.9022 66.3988 40.94C66.3861 40.9778 66.3814 41.0178 66.3851 41.0575C66.3888 41.0972 66.4007 41.1357 66.4202 41.1705C66.4397 41.2053 66.4663 41.2357 66.4983 41.2595L66.5094 41.2678C66.5685 41.312 66.6424 41.3315 66.7155 41.3223C66.7887 41.3131 66.8555 41.276 66.9018 41.2186L67.1198 40.9486L67.1645 40.9841C67.2761 41.0727 67.417 41.1158 67.559 41.1047C67.7011 41.0937 67.8336 41.0293 67.9302 40.9246Z"
        fill="#2D3748"
      />
      <path
        d="M70.0319 36.5425C70.0128 36.5113 70.0044 36.4747 70.0079 36.4383C70.0115 36.4019 70.0268 36.3676 70.0516 36.3407L70.4546 35.9029C70.4821 35.8732 70.5194 35.8544 70.5597 35.8498C70.6 35.8453 70.6406 35.8555 70.674 35.8784L70.9489 36.0681C70.968 36.0812 70.9841 36.0982 70.9963 36.1179C71.0086 36.1376 71.0166 36.1596 71.0199 36.1825C71.0232 36.2054 71.0218 36.2288 71.0156 36.2511C71.0095 36.2735 70.9988 36.2943 70.9843 36.3123L70.6043 36.783C70.577 36.817 70.5376 36.839 70.4943 36.8445C70.4511 36.8499 70.4074 36.8383 70.3726 36.8121L70.3726 36.8121L70.0747 36.5895C70.0576 36.5767 70.043 36.5607 70.0319 36.5425ZM70.9012 36.1773C70.8971 36.1706 70.8917 36.1649 70.8852 36.1604L70.6103 35.9707C70.5991 35.9631 70.5856 35.9597 70.5722 35.9612C70.5588 35.9627 70.5463 35.969 70.5371 35.9789L70.1342 36.4167C70.1289 36.4224 70.1248 36.4293 70.1223 36.4367C70.1198 36.4442 70.1189 36.452 70.1196 36.4599C70.1203 36.4677 70.1227 36.4753 70.1265 36.4821C70.1304 36.489 70.1356 36.4949 70.1419 36.4996L70.4398 36.7222C70.4514 36.7309 70.4659 36.7348 70.4803 36.733C70.4947 36.7312 70.5079 36.7238 70.5169 36.7125L70.897 36.2418C70.9019 36.2358 70.9054 36.2289 70.9075 36.2215C70.9095 36.214 70.91 36.2062 70.9088 36.1986C70.9078 36.191 70.9052 36.1838 70.9012 36.1773Z"
        fill="#E2E8F0"
      />
      <path
        d="M120.212 102.61C119.478 96.7075 115.82 90.8916 110.19 88.9707C112.41 95.0462 112.41 101.711 110.189 107.786C109.324 110.126 108.118 112.639 108.932 114.997C109.438 116.464 110.677 117.587 112.061 118.289C113.445 118.991 114.982 119.325 116.499 119.653L116.798 119.9C119.206 114.462 120.946 108.513 120.212 102.61Z"
        fill="#F0F0F0"
      />
      <path
        d="M110.135 89.0825C113.413 92.7557 115.45 97.3685 115.958 102.266C116.086 103.318 116.047 104.384 115.843 105.424C115.612 106.431 115.115 107.358 114.404 108.108C113.756 108.82 113.01 109.474 112.546 110.332C112.315 110.774 112.18 111.261 112.15 111.759C112.12 112.258 112.196 112.757 112.373 113.224C112.782 114.399 113.59 115.357 114.412 116.266C115.325 117.275 116.289 118.309 116.677 119.648C116.724 119.81 116.973 119.728 116.926 119.566C116.251 117.237 113.991 115.915 112.913 113.817C112.41 112.839 112.199 111.703 112.67 110.669C113.082 109.766 113.851 109.091 114.514 108.375C115.218 107.654 115.735 106.771 116.019 105.804C116.275 104.778 116.355 103.715 116.254 102.662C116.067 100.287 115.507 97.9564 114.595 95.7555C113.568 93.238 112.118 90.9146 110.308 88.8859C110.196 88.7604 110.023 88.9578 110.135 89.0825Z"
        fill="white"
      />
      <path
        d="M115.887 100.678C116.697 100.482 117.411 100.003 117.901 99.329C118.391 98.6546 118.625 97.828 118.562 96.9968C118.549 96.8286 118.287 96.8415 118.3 97.0099C118.361 97.7838 118.142 98.5538 117.685 99.1808C117.227 99.8078 116.56 100.251 115.805 100.429C115.641 100.468 115.724 100.717 115.887 100.678Z"
        fill="white"
      />
      <path
        d="M114.78 107.788C113.317 106.914 112.237 105.521 111.755 103.887C111.707 103.725 111.458 103.807 111.506 103.969C112.01 105.669 113.137 107.116 114.662 108.022C114.807 108.108 114.924 107.874 114.78 107.788Z"
        fill="white"
      />
      <path
        d="M113.324 93.4862C113.027 93.6276 112.699 93.6929 112.37 93.6762C112.04 93.6595 111.721 93.5613 111.439 93.3905C111.295 93.3025 111.178 93.5372 111.321 93.6247C111.633 93.8119 111.986 93.9199 112.35 93.9392C112.714 93.9584 113.076 93.8884 113.406 93.7352C113.439 93.7233 113.465 93.6996 113.481 93.6689C113.496 93.6381 113.5 93.6026 113.49 93.5696C113.479 93.5367 113.455 93.5095 113.424 93.4939C113.393 93.4782 113.357 93.4755 113.324 93.4862Z"
        fill="white"
      />
      <path
        d="M93.0303 99.5124C93.1188 99.5699 93.2072 99.6275 93.2958 99.6872C94.4814 100.467 95.6016 101.341 96.6456 102.302C96.7275 102.375 96.8093 102.451 96.889 102.526C99.3793 104.868 101.413 107.652 102.887 110.737C103.473 111.965 103.964 113.235 104.356 114.538C104.898 116.337 105.343 118.33 106.416 119.802C106.526 119.956 106.646 120.104 106.775 120.244H116.472C116.494 120.233 116.517 120.224 116.539 120.213L116.926 120.231C116.91 120.162 116.893 120.091 116.877 120.023C116.868 119.983 116.857 119.943 116.848 119.903C116.842 119.877 116.835 119.85 116.831 119.826C116.829 119.817 116.826 119.808 116.824 119.802C116.82 119.777 116.813 119.755 116.809 119.733C116.711 119.337 116.611 118.941 116.508 118.545C116.508 118.543 116.508 118.543 116.506 118.54C115.711 115.525 114.658 112.549 113.187 109.827C113.142 109.745 113.098 109.661 113.05 109.58C112.379 108.357 111.611 107.19 110.751 106.093C110.278 105.493 109.775 104.918 109.244 104.369C107.869 102.954 106.28 101.764 104.536 100.842C101.057 99.0057 97.0306 98.3021 93.3134 99.4239C93.2184 99.4527 93.1253 99.4814 93.0303 99.5124Z"
        fill="#F0F0F0"
      />
      <path
        d="M93.0522 99.6354C97.8813 100.594 102.285 103.051 105.639 106.655C106.375 107.419 106.986 108.293 107.449 109.246C107.87 110.19 108.032 111.229 107.916 112.255C107.827 113.215 107.625 114.186 107.771 115.15C107.853 115.643 108.038 116.112 108.314 116.528C108.591 116.944 108.952 117.297 109.374 117.563C110.408 118.255 111.63 118.534 112.834 118.764C114.17 119.021 115.563 119.266 116.679 120.101C116.814 120.202 116.963 119.986 116.828 119.885C114.887 118.433 112.286 118.738 110.162 117.712C109.172 117.234 108.319 116.454 108.074 115.345C107.859 114.375 108.066 113.374 108.165 112.403C108.292 111.403 108.173 110.387 107.818 109.444C107.405 108.47 106.828 107.574 106.114 106.793C104.534 105.01 102.684 103.486 100.631 102.278C98.2955 100.886 95.7391 99.9039 93.0724 99.3739C92.9073 99.3412 92.8883 99.6028 93.0522 99.6354Z"
        fill="white"
      />
      <path
        d="M104.628 105.433C105.157 104.788 105.439 103.976 105.424 103.143C105.409 102.309 105.098 101.508 104.548 100.883C104.436 100.756 104.234 100.924 104.346 101.051C104.86 101.632 105.15 102.379 105.162 103.155C105.174 103.931 104.908 104.686 104.413 105.283C104.305 105.413 104.521 105.562 104.628 105.433Z"
        fill="white"
      />
      <path
        d="M108.025 111.775C106.331 111.958 104.63 111.496 103.261 110.481C103.126 110.381 102.977 110.596 103.112 110.697C104.538 111.75 106.309 112.228 108.072 112.033C108.24 112.014 108.192 111.756 108.025 111.775Z"
        fill="white"
      />
      <path
        d="M98.2516 101.232C98.0991 101.524 97.8765 101.774 97.6037 101.958C97.3309 102.143 97.0165 102.257 96.6887 102.291C96.5206 102.307 96.5685 102.565 96.7356 102.549C97.0976 102.51 97.4446 102.384 97.7465 102.18C98.0483 101.977 98.2957 101.702 98.4671 101.381C98.4858 101.352 98.4928 101.317 98.4866 101.283C98.4805 101.25 98.4616 101.219 98.434 101.199C98.4053 101.179 98.3701 101.172 98.336 101.178C98.3018 101.184 98.2715 101.204 98.2516 101.232Z"
        fill="white"
      />
      <path
        d="M0.550781 0.0117188H69.5826V26.3197H66.9679V33.2001L61.0408 26.3197H0.550781V0.0117188Z"
        fill="#E4E4E4"
      />
      <path
        d="M67.5917 23.6859H2.76367V2.44531H53.4738C57.2168 2.45025 60.8053 4.19126 63.452 7.28639C66.0986 10.3815 67.5874 14.578 67.5917 18.9552V23.6859Z"
        fill="white"
      />
      <path
        d="M21.393 17.5216L17.3511 13.4796C16.8071 12.9357 16.4952 12.2021 16.481 11.433C16.4667 10.664 16.7513 9.91932 17.2747 9.35569C17.7982 8.79206 18.5198 8.45336 19.2878 8.41081C20.0559 8.36825 20.8105 8.62515 21.393 9.12749C21.9756 8.62523 22.7302 8.3684 23.4981 8.41098C24.2661 8.45357 24.9877 8.79226 25.5111 9.35585C26.0346 9.91944 26.3191 10.664 26.3049 11.4331C26.2907 12.2021 25.9789 12.9357 25.4351 13.4796L21.393 17.5216ZM19.4524 8.86206C18.9545 8.86174 18.4677 9.00914 18.0536 9.2856C17.6396 9.56206 17.3168 9.95515 17.1262 10.4151C16.9357 10.8751 16.8858 11.3813 16.9831 11.8696C17.0803 12.3579 17.3202 12.8063 17.6724 13.1582L21.393 16.8788L25.1137 13.1582C25.3477 12.9245 25.5333 12.647 25.66 12.3415C25.7867 12.0361 25.8519 11.7086 25.852 11.3779C25.8521 11.0472 25.787 10.7198 25.6605 10.4142C25.534 10.1087 25.3485 9.83106 25.1147 9.59722C24.8809 9.36338 24.6033 9.17791 24.2977 9.0514C23.9922 8.92489 23.6647 8.85982 23.334 8.85991C23.0033 8.86 22.6759 8.92525 22.3704 9.05192C22.0649 9.1786 21.7874 9.36422 21.5537 9.59819L21.393 9.75885L21.2324 9.59819C20.9988 9.36432 20.7213 9.1789 20.4158 9.05257C20.1103 8.92625 19.7829 8.86151 19.4524 8.86207L19.4524 8.86206Z"
        fill="#033349"
      />
      <path
        d="M35.4844 10.9082C35.3598 10.784 35.191 10.7143 35.0151 10.7145C34.8392 10.7146 34.6705 10.7845 34.5461 10.9089C34.4217 11.0333 34.3517 11.202 34.3516 11.3779C34.3514 11.5538 34.4211 11.7226 34.5452 11.8472L34.5453 11.8473L35.4844 12.7864L36.4235 11.8473C36.4854 11.7857 36.5344 11.7125 36.568 11.6319C36.6015 11.5513 36.6188 11.4648 36.6188 11.3775C36.6189 11.2902 36.6018 11.2038 36.5684 11.1231C36.535 11.0425 36.4861 10.9692 36.4244 10.9074C36.3627 10.8457 36.2894 10.7967 36.2087 10.7634C36.128 10.73 36.0416 10.7128 35.9543 10.7129C35.867 10.713 35.7806 10.7302 35.6999 10.7637C35.6193 10.7972 35.5461 10.8463 35.4845 10.9081L35.4844 10.9082Z"
        fill="#033349"
      />
      <path
        d="M31.6197 10.9082C31.4951 10.7838 31.3263 10.7139 31.1502 10.714C30.9742 10.714 30.8054 10.784 30.6809 10.9084C30.5564 11.0329 30.4864 11.2017 30.4863 11.3778C30.4863 11.5538 30.5561 11.7227 30.6805 11.8472L30.6806 11.8473L31.6197 12.7864L32.5588 11.8473C32.6206 11.7857 32.6697 11.7125 32.7032 11.6319C32.7367 11.5513 32.754 11.4648 32.7541 11.3775C32.7542 11.2902 32.737 11.2038 32.7037 11.1231C32.6703 11.0425 32.6214 10.9692 32.5596 10.9074C32.4979 10.8457 32.4246 10.7967 32.344 10.7634C32.2633 10.73 32.1768 10.7128 32.0895 10.7129C32.0022 10.713 31.9158 10.7302 31.8352 10.7637C31.7546 10.7972 31.6814 10.8463 31.6197 10.9081L31.6197 10.9082Z"
        fill="#033349"
      />
      <path
        d="M33.6382 17.9503C32.6494 17.9503 31.6828 17.6571 30.8606 17.1077C30.0385 16.5584 29.3976 15.7776 29.0192 14.864C28.6408 13.9505 28.5418 12.9452 28.7347 11.9754C28.9276 11.0056 29.4038 10.1147 30.103 9.41552C30.8022 8.71631 31.6931 8.24015 32.6629 8.04724C33.6327 7.85433 34.638 7.95334 35.5515 8.33175C36.4651 8.71015 37.2459 9.35096 37.7952 10.1731C38.3446 10.9953 38.6378 11.9619 38.6378 12.9508C38.6363 14.2763 38.1091 15.5471 37.1718 16.4843C36.2346 17.4216 34.9638 17.9488 33.6382 17.9503ZM33.6382 8.40568C32.7393 8.40568 31.8606 8.67225 31.1131 9.17167C30.3657 9.67108 29.7832 10.3809 29.4391 11.2114C29.0951 12.0419 29.0051 12.9558 29.1805 13.8375C29.3559 14.7191 29.7887 15.529 30.4244 16.1646C31.06 16.8002 31.8699 17.2331 32.7515 17.4085C33.6332 17.5839 34.5471 17.4939 35.3776 17.1498C36.2081 16.8058 36.9179 16.2233 37.4173 15.4759C37.9168 14.7284 38.1833 13.8497 38.1833 12.9508C38.1833 12.3539 38.0658 11.7629 37.8373 11.2114C37.6089 10.66 37.2741 10.159 36.8521 9.7369C36.4301 9.31485 35.929 8.98007 35.3776 8.75165C34.8261 8.52324 34.2351 8.40568 33.6382 8.40568Z"
        fill="#033349"
      />
      <path
        d="M45.9097 17.9503C44.9209 17.9503 43.9543 17.6571 43.1321 17.1077C42.3099 16.5584 41.6691 15.7776 41.2907 14.864C40.9123 13.9505 40.8133 12.9452 41.0062 11.9754C41.1991 11.0056 41.6753 10.1147 42.3745 9.41552C43.0737 8.71631 43.9645 8.24015 44.9344 8.04724C45.9042 7.85433 46.9094 7.95334 47.823 8.33175C48.7365 8.71015 49.5174 9.35096 50.0667 10.1731C50.6161 10.9953 50.9093 11.9619 50.9093 12.9508C50.9078 14.2763 50.3806 15.5471 49.4433 16.4843C48.506 17.4216 47.2352 17.9488 45.9097 17.9503ZM45.9097 8.40568C45.0108 8.40568 44.1321 8.67225 43.3846 9.17167C42.6372 9.67108 42.0546 10.3809 41.7106 11.2114C41.3666 12.0419 41.2766 12.9558 41.452 13.8375C41.6274 14.7191 42.0602 15.529 42.6959 16.1646C43.3315 16.8002 44.1414 17.2331 45.023 17.4085C45.9047 17.5839 46.8185 17.4939 47.649 17.1498C48.4796 16.8058 49.1894 16.2233 49.6888 15.4759C50.1882 14.7284 50.4548 13.8497 50.4548 12.9508C50.4548 12.3539 50.3372 11.7629 50.1088 11.2114C49.8804 10.66 49.5456 10.159 49.1236 9.7369C48.7015 9.31485 48.2005 8.98007 47.649 8.75165C47.0976 8.52324 46.5066 8.40568 45.9097 8.40568Z"
        fill="#033349"
      />
      <path
        d="M47.9546 12.4971C48.5822 12.4971 49.0909 11.9884 49.0909 11.3609C49.0909 10.7333 48.5822 10.2246 47.9546 10.2246C47.3271 10.2246 46.8184 10.7333 46.8184 11.3609C46.8184 11.9884 47.3271 12.4971 47.9546 12.4971Z"
        fill="#033349"
      />
      <path
        d="M44.0913 12.4971C44.7189 12.4971 45.2276 11.9884 45.2276 11.3609C45.2276 10.7333 44.7189 10.2246 44.0913 10.2246C43.4638 10.2246 42.9551 10.7333 42.9551 11.3609C42.9551 11.9884 43.4638 12.4971 44.0913 12.4971Z"
        fill="#033349"
      />
      <path
        d="M44.4936 13.8603C44.03 17.0679 47.7094 17.7185 47.3411 13.8602C47.363 13.8576 44.6419 13.8623 44.4936 13.8603Z"
        fill="#033349"
      />
      <path
        d="M31.8887 14.5254C32.9278 15.9224 34.7245 16.0479 35.6849 14.5254H31.8887Z"
        fill="#033349"
      />
      <path
        d="M91.6182 117.824H88.9056L87.6152 107.361H91.6186L91.6182 117.824Z"
        fill="#9E616A"
      />
      <path
        d="M92.3089 120.455L83.5625 120.454V120.344C83.5626 119.441 83.9213 118.575 84.5597 117.937C85.1981 117.298 86.064 116.94 86.9668 116.939H86.9671L92.3091 116.94L92.3089 120.455Z"
        fill="#1A202C"
      />
      <path
        d="M71.179 117.736L68.5664 117.006L70.1389 106.582L73.9947 107.659L71.179 117.736Z"
        fill="#9E616A"
      />
      <path
        d="M71.1366 120.454L62.7129 118.101L62.7427 117.994C62.9857 117.125 63.5641 116.387 64.3508 115.944C65.1375 115.501 66.0679 115.388 66.9375 115.631L66.9377 115.631L72.0826 117.069L71.1366 120.454Z"
        fill="#1A202C"
      />
      <path
        d="M85.2009 30.1791C88.4783 30.1791 91.1352 27.5222 91.1352 24.2448C91.1352 20.9674 88.4783 18.3105 85.2009 18.3105C81.9235 18.3105 79.2666 20.9674 79.2666 24.2448C79.2666 27.5222 81.9235 30.1791 85.2009 30.1791Z"
        fill="#9E616A"
      />
      <path
        d="M88.0088 53.9491L88.4792 57.1695C88.4792 57.1695 94.4135 68.9657 87.9726 74.7552C87.9726 74.7552 78.3475 103.781 75.5975 107.689L73.2982 112.5L68.873 111.837L71.6172 95.3141L76.2488 71.7157L76.9364 53.2977C76.9364 53.2977 87.0681 52.5741 88.0088 53.9491Z"
        fill="#1A202C"
      />
      <path
        d="M86.8872 74.0273L90.1438 92.4875C90.1438 92.4875 91.8807 100.955 92.0254 104.211C92.1702 107.468 92.7491 111.665 92.7491 111.665L86.8872 112.606L81.0977 87.7775L86.8872 74.0273Z"
        fill="#1A202C"
      />
      <path
        d="M91.6626 37.9377L91.241 48.6833L89.015 53.6073L88.19 55.4332L76.249 55.2884L77.4793 46.966C75.7424 42.4791 79.8675 40.6698 79.8675 40.6698L83.3412 36.9681L83.0336 32.185L88.2623 31.1895C90.9834 30.6714 89.6713 34.6188 89.6713 34.6188C93.2898 34.257 91.6626 37.9377 91.6626 37.9377Z"
        fill="#F7B80B"
      />
      <path
        d="M70.0002 38.307C70.0259 38.4267 70.0421 38.5483 70.0487 38.6705L79.4859 44.2688L81.8121 42.9671L84.2356 46.221L80.3414 48.9257C80.0268 49.1442 79.6498 49.2548 79.2671 49.2408C78.8843 49.2268 78.5164 49.089 78.2186 48.8482L68.5124 40.9976C68.0682 41.1559 67.5862 41.1753 67.1307 41.053C66.6752 40.9308 66.2677 40.6727 65.9624 40.3132C65.6571 39.9537 65.4685 39.5098 65.4216 39.0405C65.3748 38.5712 65.472 38.0987 65.7002 37.686C65.9284 37.2732 66.2769 36.9398 66.6993 36.7299C67.1216 36.52 67.5979 36.4437 68.0647 36.5112C68.5315 36.5786 68.9667 36.7865 69.3125 37.1073C69.6582 37.4281 69.8981 37.8466 70.0002 38.307L70.0002 38.307Z"
        fill="#9E616A"
      />
      <path
        d="M79.6245 43.6645C79.6564 43.525 79.7179 43.394 79.805 43.2805C79.8921 43.167 80.0027 43.0736 80.1291 43.0067L87.4879 37.6664C88.0775 37.2325 88.8152 37.0501 89.5391 37.1594C90.2631 37.2686 90.9141 37.6604 91.3496 38.2489C91.785 38.8375 91.9692 39.5747 91.8619 40.2989C91.7546 41.0231 91.3645 41.6752 90.7771 42.1121L84.275 48.6449C84.1734 48.7456 84.0513 48.8232 83.917 48.8725C83.7826 48.9218 83.6393 48.9415 83.4966 48.9304C83.354 48.9193 83.2154 48.8776 83.0904 48.808C82.9653 48.7385 82.8567 48.6429 82.7719 48.5276L79.793 44.4764C79.7081 44.3611 79.6492 44.2289 79.6201 44.0888C79.591 43.9487 79.5925 43.804 79.6245 43.6645Z"
        fill="#F7B80B"
      />
      <path
        d="M88.0123 16.918H88.0123C86.8783 16.9165 85.7651 17.2223 84.791 17.803H84.3616C82.7772 17.803 81.2577 18.4324 80.1374 19.5527C79.0171 20.673 78.3877 22.1925 78.3877 23.7769H81.7065V30.8571H94.3181V23.2238C94.3181 21.5514 93.6538 19.9475 92.4712 18.7649C91.2886 17.5823 89.6847 16.918 88.0123 16.918Z"
        fill="#1A202C"
      />
      <path
        d="M124.518 120.748H32.6314C32.5967 120.748 32.5624 120.741 32.5303 120.728C32.4983 120.715 32.4692 120.695 32.4446 120.671C32.4201 120.646 32.4006 120.617 32.3873 120.585C32.374 120.553 32.3672 120.519 32.3672 120.484C32.3672 120.449 32.374 120.415 32.3873 120.383C32.4006 120.351 32.4201 120.322 32.4446 120.298C32.4692 120.273 32.4983 120.254 32.5303 120.241C32.5624 120.227 32.5967 120.221 32.6314 120.221H124.518C124.552 120.221 124.587 120.227 124.619 120.241C124.651 120.254 124.68 120.273 124.704 120.298C124.729 120.322 124.748 120.351 124.762 120.383C124.775 120.415 124.782 120.449 124.782 120.484C124.782 120.519 124.775 120.553 124.762 120.585C124.748 120.617 124.729 120.646 124.704 120.671C124.68 120.695 124.651 120.715 124.619 120.728C124.587 120.741 124.552 120.748 124.518 120.748Z"
        fill="#CACACA"
      />
    </g>
    <defs>
      <clipPath id="clip0_942_38828">
        <rect
          width="125.458"
          height="120.372"
          fill="white"
          transform="translate(0.550781 0.445312)"
        />
      </clipPath>
    </defs>
  </svg>
);
