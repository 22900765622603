export const AlertPopupIcon = () => (
  <svg width="252" height="212" viewBox="0 0 252 212" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1169_70285)">
      <path d="M178.132 162.708C178.132 162.708 178.132 188.848 184.141 195.705C184.141 195.705 185.429 199.133 185.858 200.418C186.099 201.15 186.385 201.866 186.717 202.561V204.275C186.717 204.275 198.307 202.99 199.595 199.99L197.448 197.419C197.448 197.419 197.019 196.99 197.448 195.705C197.522 195.565 197.556 195.407 197.548 195.25C197.54 195.092 197.489 194.939 197.401 194.808C197.313 194.676 197.192 194.571 197.049 194.502C196.906 194.434 196.748 194.405 196.59 194.419L193.585 187.562L194.014 159.279L191.868 120.283L173.41 119.426L178.132 162.708Z" fill="#2F2E41"/>
      <path d="M187.576 201.702C187.576 201.702 184.571 210.702 187.576 211.13C190.58 211.559 216.765 211.987 216.336 208.559C215.906 205.131 207.321 205.559 207.321 205.559L198.307 199.988L187.576 201.702Z" fill="#2F2E41"/>
      <path d="M160.963 120.281C160.963 120.281 160.105 125.852 163.539 131.852C166.973 137.851 164.827 157.992 164.827 157.992L134.35 184.561C134.35 184.561 140.359 194.846 142.505 194.417C144.652 193.989 178.563 166.563 178.563 166.563L191.87 121.995L160.963 120.281Z" fill="#2F2E41"/>
      <path d="M134.768 183.426C134.768 183.426 125.546 185.71 126.792 188.473C128.038 191.236 141.693 213.544 144.364 211.347C147.034 209.15 142.076 202.14 142.076 202.14L141.963 191.557L134.768 183.426Z" fill="#2F2E41"/>
      <path d="M177.703 50.4313C183.156 50.4313 187.576 46.0185 187.576 40.575C187.576 35.1315 183.156 30.7188 177.703 30.7188C172.25 30.7188 167.83 35.1315 167.83 40.575C167.83 46.0185 172.25 50.4313 177.703 50.4313Z" fill="#FFB8B8"/>
      <path d="M169.936 44.0527L170.372 56.7359L178.679 60.0127C178.679 60.0127 179.494 47.026 180.644 46.306C181.794 45.586 169.936 44.0527 169.936 44.0527Z" fill="#FFB8B8"/>
      <path d="M180.708 61.5734C180.708 61.5734 169.118 50.003 166.113 50.4315C163.109 50.8601 163.109 52.1457 163.109 53.0027C163.109 53.8598 163.967 54.2883 163.109 54.7169C162.25 55.1454 161.392 54.7169 161.392 56.0025C161.392 57.2881 153.665 70.144 156.24 84.2856C158.816 98.4272 161.821 105.284 160.533 112.997C159.245 120.711 160.104 122.425 160.104 122.425C160.104 122.425 192.298 124.996 192.727 121.996C193.156 118.997 191.439 120.711 193.586 118.568C195.732 116.426 194.873 116.426 194.873 115.14C194.873 113.854 185.43 61.5734 180.708 61.5734Z" fill="#575A89"/>
      <path d="M174.699 119.853C174.699 119.853 179.85 132.28 183.714 129.281C187.577 126.281 181.997 115.996 181.997 115.996L174.699 119.853Z" fill="#FFB8B8"/>
      <path d="M162.25 62.4297C162.25 62.4297 152.165 63.5824 155.92 82.0758C159.675 100.569 174.269 121.996 174.269 121.996L184.142 117.282L171.264 91.9985L174.269 76.1428C174.269 76.1428 175.986 61.1441 162.25 62.4297Z" fill="#575A89"/>
      <path opacity="0.2" d="M171.051 92.2129L185.216 115.782L183.928 117.068L171.051 92.2129Z" fill="black"/>
      <path opacity="0.2" d="M163.553 103.197L174.914 121.783H171.051L163.553 103.197Z" fill="black"/>
      <path d="M173.234 46.4968C174.033 46.0749 174.962 45.988 175.827 45.7257C178.922 44.7876 180.996 41.2946 180.334 38.1337C180.211 37.7283 180.156 37.3056 180.171 36.8824C180.287 35.9463 181.246 35.3422 182.166 35.1278C183.086 34.9133 184.065 34.9444 184.945 34.5995C186.303 34.0669 187.205 32.6739 187.4 31.2306C187.541 29.7695 187.215 28.3014 186.469 27.0363L186.137 27.9911C185.63 27.5627 185.253 27.0009 185.051 26.3696C184.673 26.433 184.318 26.5921 184.019 26.8317C183.721 27.0713 183.489 27.3834 183.346 27.7381C182.813 28.0522 182.736 26.7625 182.147 26.5722C181.887 26.5399 181.625 26.6044 181.411 26.7533C180.224 27.3062 178.844 26.8335 177.57 26.527C175.365 25.9989 173.066 25.9962 170.859 26.5191C169.393 26.8683 167.942 27.4762 166.892 28.5566C166.072 29.4866 165.442 30.5679 165.037 31.7396C163.912 34.5666 163.168 37.6256 163.54 40.645C163.849 43.1158 164.885 45.4398 166.516 47.3238C167.365 48.301 170.137 51.7103 171.657 51.129C173.567 50.3987 170.984 47.6845 173.234 46.4968Z" fill="#2F2E41"/>
      <path opacity="0.2" d="M189.216 136.97L177.92 166.537L178.491 166.754L189.786 137.187L189.216 136.97Z" fill="black"/>
      <path d="M180.648 63.9263C180.985 63.9263 181.258 63.6533 181.258 63.3166C181.258 62.98 180.985 62.707 180.648 62.707C180.31 62.707 180.037 62.98 180.037 63.3166C180.037 63.6533 180.31 63.9263 180.648 63.9263Z" fill="#D0CDE1"/>
      <path d="M185.838 80.3858C185.772 80.3857 185.709 80.3649 185.656 80.3262C185.604 80.2875 185.565 80.2331 185.546 80.1708L180.355 63.4065C180.344 63.3683 180.339 63.3281 180.343 63.2882C180.347 63.2484 180.358 63.2097 180.377 63.1743C180.396 63.1389 180.421 63.1075 180.452 63.0819C180.483 63.0564 180.519 63.0371 180.557 63.0253C180.595 63.0135 180.635 63.0093 180.675 63.013C180.715 63.0167 180.754 63.0283 180.789 63.0469C180.825 63.0656 180.856 63.0911 180.882 63.1218C180.908 63.1526 180.927 63.1882 180.939 63.2264L186.129 79.991C186.143 80.0366 186.146 80.0849 186.138 80.1319C186.13 80.1789 186.111 80.2234 186.083 80.2618C186.055 80.3001 186.018 80.3314 185.975 80.3529C185.932 80.3744 185.885 80.3857 185.838 80.3858Z" fill="#D0CDE1"/>
      <path d="M31.1747 184.299L29.5742 203.79H35.6557L38.8562 184.299H31.1747Z" fill="#FFB8B8"/>
      <path d="M70.2235 184.299L68.623 203.79H74.7045L77.905 184.299H70.2235Z" fill="#FFB8B8"/>
      <path d="M73.023 77.1855L72.4071 86.4812L68.9398 114.569C68.9398 114.569 64.5753 127.801 70.852 126.393C77.1286 124.984 75.1146 113.867 75.1146 113.867L79.1875 87.5989L80.8155 78.3042L73.023 77.1855Z" fill="#FFB8B8"/>
      <path d="M78.3854 63.9946L81.266 81.8886L71.3438 80.93L74.2243 63.3555L78.3854 63.9946Z" fill="#575A89"/>
      <path d="M64.7823 47.8587C69.7319 47.8587 73.7443 43.853 73.7443 38.9118C73.7443 33.9705 69.7319 29.9648 64.7823 29.9648C59.8327 29.9648 55.8203 33.9705 55.8203 38.9118C55.8203 43.853 59.8327 47.8587 64.7823 47.8587Z" fill="#FFB8B8"/>
      <path d="M70.7039 41.9473C70.7039 41.9473 73.5846 48.3379 75.1849 51.8528C76.7853 55.3677 76.4652 56.0067 76.4652 56.0067H63.3423C63.3423 56.0067 64.6226 47.0598 63.3423 46.1012C62.062 45.1426 70.7039 41.9473 70.7039 41.9473Z" fill="#FFB8B8"/>
      <path d="M68.4636 88.2799C68.4636 88.2799 86.3876 66.2321 77.4256 54.7289C77.9212 54.4205 74.3252 50.2762 71.9844 51.214C71.5643 51.3823 62.7023 53.1312 61.422 54.4093C60.1417 55.6875 54.5405 62.2379 54.5405 62.2379C54.5405 62.2379 51.3398 66.0723 50.0595 74.3802C48.7792 82.688 46.0586 87.0017 46.0586 87.0017" fill="#575A89"/>
      <path d="M46.0585 87.002L45.4184 92.1145L32.1354 142.121L25.2539 186.377C28.5474 191.759 35.6732 192.618 46.3786 189.253L48.4591 145.636L58.0612 121.671L62.0621 189.253C70.7616 192.139 78.6104 191.522 85.7474 187.975L73.7447 116.559C73.7447 116.559 79.666 106.174 69.1037 92.7536L68.4635 88.2801" fill="#2F2E41"/>
      <path d="M55.6609 75.498L52.1402 84.1255L39.9774 109.688C39.9774 109.688 31.6556 120.872 38.057 121.511C44.4584 122.15 46.0588 110.966 46.0588 110.966L58.2215 87.3208L62.7025 79.0129L55.6609 75.498Z" fill="#FFB8B8"/>
      <path d="M36.9351 196.441V201.6C36.9351 201.6 40.6257 210.074 38.0423 210.81C35.4589 211.547 28.4468 210.81 27.3396 210.81C26.2324 210.81 14.4226 211.916 14.7917 208.6C15.1607 205.284 22.9109 203.073 22.9109 203.073L29.923 196.441H36.9351Z" fill="#2F2E41"/>
      <path d="M76.3042 196.441V201.6C76.3042 201.6 79.9948 210.074 77.4114 210.81C74.828 211.547 67.8159 210.81 66.7088 210.81C65.6016 210.81 53.7918 211.916 54.1608 208.6C54.5299 205.284 62.2801 203.073 62.2801 203.073L69.2922 196.441H76.3042Z" fill="#2F2E41"/>
      <path d="M66.5431 53.4512C66.5431 53.4512 60.1417 55.0488 57.261 65.2739C54.3804 75.4989 53.7402 77.0966 53.7402 77.0966L63.3424 81.5701L70.3839 67.5106C70.3839 67.5106 74.5449 55.3684 66.5431 53.4512Z" fill="#575A89"/>
      <path d="M55.2085 36.1717C55.2085 36.1717 53.4573 22.5657 67.7462 27.0274C67.7462 27.0274 77.9514 25.158 81.2981 37.9018L84.6869 51.2603L83.0354 50.363L82.2654 52.0431L79.5892 52.7197L78.444 50.4505L77.89 53.2062L55.9703 55.3877C55.9703 55.3877 65.7877 49.5916 65.9434 39.2034L64.0734 41.1762L55.2085 36.1717Z" fill="#2F2E41"/>
      <path opacity="0.2" d="M68.6233 66.7109L61.7285 80.8177L63.182 81.4094L68.6233 66.7109Z" fill="black"/>
      <path opacity="0.2" d="M60.621 64.793L53.8996 77.2549H52.6191L60.621 64.793Z" fill="black"/>
      <path opacity="0.2" d="M78.3291 70.9785L73.4004 81.1284H76.4904L78.3291 70.9785Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_1169_70285">
        <rect width="252" height="211" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>

);