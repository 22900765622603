import { useNavigate } from "react-router-dom";
import { Icons } from "../../components/PicturesVariant/PicturesVariant";
import { useOnboardingContext } from "../../context/OnboardingContext";
import { EventSubCategory } from "../../modules/Onboarding/EventTypes";
import OnboardingListButtonLayout from "./OnboardingListButtonLayout";
import { PageURL } from "../../types/pages";
import { useSessionContext } from "../../context/SessionContext";

export default function OnboardingAnimationEvent() {
  const { setEventSubcategory } = useOnboardingContext();
  const { updateHistory } = useSessionContext();
  const navigate = useNavigate();

  const onChoose = (e: EventSubCategory) => {
    setEventSubcategory(e);
    updateHistory(PageURL.CALENDAR);
    navigate(PageURL.CALENDAR);
  };

  return (
    <OnboardingListButtonLayout
      title="Quel type d’événement organisez-vous ?"
      subtitle="Sélectionner le type d’événement sur lequel votre personnel sera missionné"
      elements={[
        {
          icon: Icons.EVENEMENT_ICON,
          title: "Evénement sportif",
          value: EventSubCategory.EVENEMENT_SPORTIF_ANIMATION,
          onChoose,
        },
        {
          icon: Icons.VILLAGEEVENEMENTIEL_ICON,
          title: "Village événementiel",
          value: EventSubCategory.VILLAGE_EVENEMENTIEL_ANIMATION,
          onChoose,
        },
        {
          icon: Icons.SALON_SVG_ICON,
          title: "Salon",
          value: EventSubCategory.SALON_ANIMATION,
          onChoose,
        },
        {
          icon: Icons.FESIVAL_ICON,
          title: "Festival",
          elements: [
            {
              title: "Festival",
              value: EventSubCategory.FESTIVAL,
            },
            {
              title: "Concert",
              value: EventSubCategory.CONCERT,
            },
          ],
          onChoose,
        },
        {
          icon: Icons.ESCAPE_GAME_ICON,
          title: "Escape Game",
          value: EventSubCategory.ESCAPE_GAME,
          onChoose,
        },
        {
          icon: Icons.GOODIES_ICON,
          title: "Autre ?",
          value: EventSubCategory.AUTRE_ANIMATION,
          isInputText: true,
          onChoose,
        },
      ]}
    />
  );
}
