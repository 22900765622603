import { Tab, TabProps, Text } from "@chakra-ui/react";
import { ReactElement } from "react";
import { colors } from "../../../ui/theme";

const TabHeadingButton = ({ titleOfSection, ...props }: any & TabProps): ReactElement => (
  <Tab
    value={titleOfSection}
    width="fit-content !important"
    className="tabListButton"
    padding="10px !important"
    borderRadius="10px"
    border={`solid 1px ${colors.black}`}
    _hover={{
      background: colors.secondaryFontColor.lightMode,
      borderColor: colors.secondaryFontColor.lightMode,
      ".chakra-text": {
        color: colors.white,
      },
    }}
    _selected={{
      background: colors.secondaryFontColor.lightMode,
      borderColor: colors.secondaryFontColor.lightMode,
      ".chakra-text": {
        color: colors.white,
      },
    }}
    {...props}
  >
    <Text
      _hover={{
        color: colors.white,
      }}
      fontWeight={700}
      variant="sm"
      textTransform="uppercase"
    >
      {titleOfSection}
    </Text>
  </Tab>
);

export default TabHeadingButton;
