export const InstitutionelIcon = () => (
  <svg width="74" height="101" viewBox="0 0 74 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1063_20179)">
      <path d="M9.78067 24.7444C15.3054 24.7444 19.784 20.3057 19.784 14.8302C19.784 9.35475 15.3054 4.91602 9.78067 4.91602C4.25599 4.91602 -0.222656 9.35475 -0.222656 14.8302C-0.222656 20.3057 4.25599 24.7444 9.78067 24.7444Z" fill="#F7B80B"/>
      <path d="M68.1944 17.7206H56.1953V6.90967L68.1944 17.7206Z" fill="#3F3D56"/>
      <path d="M69.5963 17.3667H9.2334V100.018H69.5963V17.3667Z" fill="#E6E6E6"/>
      <path d="M22.3574 23.1621H15.2559V37.9927H22.3574V23.1621Z" fill="white"/>
      <path d="M33.3418 23.1621H26.2402V37.9927H33.3418V23.1621Z" fill="white"/>
      <path d="M21.4209 44.1821H14.3193V59.0127H21.4209V44.1821Z" fill="white"/>
      <path d="M33.3418 44.1821H26.2402V59.0127H33.3418V44.1821Z" fill="white"/>
      <path d="M21.4209 63.6318H14.3193V78.4624H21.4209V63.6318Z" fill="white"/>
      <path d="M33.0742 63.3667H25.9727V78.1973H33.0742V63.3667Z" fill="white"/>
      <path d="M43.3955 85.2212H36.2939V100.052H43.3955V85.2212Z" fill="#CCCCCC"/>
      <path d="M54.125 63.3667H47.0234V78.1973H54.125V63.3667Z" fill="white"/>
      <path d="M66.0459 63.3667H58.9443V78.1973H66.0459V63.3667Z" fill="white"/>
      <path d="M54.3925 44.0254H47.291V58.856H54.3925V44.0254Z" fill="white"/>
      <path d="M66.3125 44.0254H59.2109V58.856H66.3125V44.0254Z" fill="white"/>
      <path d="M54.3925 23.1621H47.291V37.9927H54.3925V23.1621Z" fill="white"/>
      <path d="M66.3125 23.1621H59.2109V37.9927H66.3125V23.1621Z" fill="white"/>
      <path d="M69.5963 36.4707H9.2334V38.733H69.5963V36.4707Z" fill="#3F3D56"/>
      <path d="M69.5963 54.0664H9.2334V55.3232H69.5963V54.0664Z" fill="#3F3D56"/>
      <path d="M69.5963 50.0444H9.2334V51.3013H69.5963V50.0444Z" fill="#3F3D56"/>
      <path d="M15.1934 79.454V69.3994H13.9252V79.454H15.1934Z" fill="#3F3D56"/>
      <path d="M22.2949 79.454V69.3994H21.0268V79.454H22.2949Z" fill="#3F3D56"/>
      <path d="M29.3965 79.454V69.3994H28.1284V79.454H29.3965Z" fill="#3F3D56"/>
      <path d="M36.498 79.454V69.3994H35.2299V79.454H36.498Z" fill="#3F3D56"/>
      <path d="M43.5996 79.454V69.3994H42.3315V79.454H43.5996Z" fill="#3F3D56"/>
      <path d="M50.7012 79.454V69.3994H49.433V79.454H50.7012Z" fill="#3F3D56"/>
      <path d="M57.8027 79.454V69.3994H56.5346V79.454H57.8027Z" fill="#3F3D56"/>
      <path d="M64.9043 79.454V69.3994H63.6362V79.454H64.9043Z" fill="#3F3D56"/>
      <path d="M15.3203 60.3505V50.2959H14.0522V60.3505H15.3203Z" fill="#3F3D56"/>
      <path d="M22.4219 60.3505V50.2959H21.1537V60.3505H22.4219Z" fill="#3F3D56"/>
      <path d="M29.5234 60.3505V50.2959H28.2553V60.3505H29.5234Z" fill="#3F3D56"/>
      <path d="M36.625 60.3505V50.2959H35.3569V60.3505H36.625Z" fill="#3F3D56"/>
      <path d="M43.7266 60.3505V50.2959H42.4584V60.3505H43.7266Z" fill="#3F3D56"/>
      <path d="M50.8281 60.3505V50.2959H49.56V60.3505H50.8281Z" fill="#3F3D56"/>
      <path d="M57.9297 60.3505V50.2959H56.6616V60.3505H57.9297Z" fill="#3F3D56"/>
      <path d="M65.0312 60.3505V50.2959H63.7631V60.3505H65.0312Z" fill="#3F3D56"/>
      <path d="M15.3203 37.9789V27.9243H14.0522V37.9789H15.3203Z" fill="#3F3D56"/>
      <path d="M22.4219 37.9789V27.9243H21.1537V37.9789H22.4219Z" fill="#3F3D56"/>
      <path d="M29.5234 37.9789V27.9243H28.2553V37.9789H29.5234Z" fill="#3F3D56"/>
      <path d="M36.625 37.9789V27.9243H35.3569V37.9789H36.625Z" fill="#3F3D56"/>
      <path d="M43.7266 37.9789V27.9243H42.4584V37.9789H43.7266Z" fill="#3F3D56"/>
      <path d="M50.8281 37.9789V27.9243H49.56V37.9789H50.8281Z" fill="#3F3D56"/>
      <path d="M57.9297 37.9789V27.9243H56.6616V37.9789H57.9297Z" fill="#3F3D56"/>
      <path d="M65.0312 37.9789V27.9243H63.7631V37.9789H65.0312Z" fill="#3F3D56"/>
      <path d="M69.5963 31.4434H9.2334V32.7002H69.5963V31.4434Z" fill="#3F3D56"/>
      <path d="M69.5963 27.4214H9.2334V28.6782H69.5963V27.4214Z" fill="#3F3D56"/>
      <path d="M69.5963 58.3394H9.2334V60.6016H69.5963V58.3394Z" fill="#3F3D56"/>
      <path d="M72.3734 16.437H6.45508V19.8082H72.3734V16.437Z" fill="#3F3D56"/>
      <path d="M22.3702 88.4281V85.2212H18.4873V88.4281H22.0466H22.3702Z" fill="white"/>
      <path d="M22.0466 90.9937H18.4873V94.2006H22.3702V90.9937H22.0466Z" fill="white"/>
      <path d="M28.8418 85.2212H24.959V88.4281H28.8418V85.2212Z" fill="white"/>
      <path d="M28.8418 90.9937H24.959V94.2005H28.8418V90.9937Z" fill="white"/>
      <path d="M54.0392 88.4281V85.2212H50.1562V88.4281H53.7155H54.0392Z" fill="white"/>
      <path d="M53.7155 90.9937H50.1562V94.2006H54.0392V90.9937H53.7155Z" fill="white"/>
      <path d="M60.5108 85.2212H56.6279V88.4281H60.5108V85.2212Z" fill="white"/>
      <path d="M60.5108 90.9937H56.6279V94.2005H60.5108V90.9937Z" fill="white"/>
      <path d="M69.5963 77.4434H9.2334V79.7056H69.5963V77.4434Z" fill="#3F3D56"/>
      <path d="M69.5963 73.4214H9.2334V74.6782H69.5963V73.4214Z" fill="#3F3D56"/>
      <path d="M69.5963 69.3994H9.2334V70.6562H69.5963V69.3994Z" fill="#3F3D56"/>
      <path d="M27.5076 98.9414C27.5078 98.8714 27.5068 98.8018 27.5048 98.7325C27.4505 96.8795 25.5959 96.377 24.6016 97.2542C24.2878 96.9841 23.8863 96.8354 23.4707 96.8354C23.0552 96.8354 22.6536 96.9841 22.3398 97.2542C21.3413 96.3908 19.5236 96.8576 19.4636 98.6895C19.4613 98.7588 19.4601 98.8284 19.46 98.8983C19.4629 99.2836 19.4867 99.6684 19.5313 100.051H27.4373C27.4802 99.6827 27.5037 99.3123 27.5076 98.9414Z" fill="#F6C443"/>
      <path d="M58.9295 98.9414C58.9297 98.8714 58.9287 98.8018 58.9266 98.7325C58.8724 96.8795 57.0178 96.377 56.0235 97.2542C55.7097 96.9841 55.3081 96.8354 54.8926 96.8354C54.477 96.8354 54.0755 96.9841 53.7617 97.2542C52.7632 96.3908 50.9455 96.8576 50.8854 98.6895C50.8832 98.7588 50.882 98.8284 50.8818 98.8983C50.8848 99.2836 50.9086 99.6684 50.9532 100.051H58.8591C58.9021 99.6827 58.9256 99.3123 58.9295 98.9414Z" fill="#F6C443"/>
      <path d="M34.3079 -0.531117L34.2903 -0.521598C34.0999 -0.418372 33.8864 -0.363912 33.6694 -0.363157C33.4523 -0.362403 33.2385 -0.415378 33.0473 -0.517277C33.0363 -0.523167 33.0253 -0.528983 33.0145 -0.534724C32.8233 -0.63585 32.6098 -0.688395 32.3931 -0.687653C32.1764 -0.686912 31.9633 -0.632907 31.7729 -0.530475L31.7564 -0.521598C31.566 -0.418372 31.3525 -0.363912 31.1355 -0.363157C30.9184 -0.362403 30.7046 -0.415378 30.5134 -0.517277C30.502 -0.523392 30.4906 -0.529426 30.4793 -0.535382C30.2904 -0.636044 30.0794 -0.688912 29.8649 -0.689288C29.6504 -0.689664 29.4391 -0.637537 29.2499 -0.537538C28.6717 -0.228957 27.8901 0.298642 27.8936 0.894481C27.8937 0.918043 27.895 0.941584 27.8976 0.96501C27.9648 1.58379 30.0012 1.74146 31.1199 1.44984C31.9538 1.63842 32.8199 1.63842 33.6538 1.44984C34.7677 1.74613 36.8455 1.57638 36.9063 0.950501C36.9085 0.92705 36.9096 0.903501 36.9095 0.879941C36.9061 0.28378 36.1175 -0.23829 35.5358 -0.542527C35.346 -0.640938 35.1346 -0.691387 34.9204 -0.689396C34.7062 -0.687406 34.4958 -0.633038 34.3079 -0.531117Z" fill="#E6E6E6"/>
      <g clipPath="url(#clip1_1063_20179)">
        <path d="M50.634 85.8696L43.7878 127.968L39.2158 127.74L39.3292 89.0853L50.634 85.8696Z" fill="#2F2E41"/>
        <path d="M32.1767 73.0073L48.7879 73.237L50.6336 85.8694L39.3288 91.152L34.4148 126.83L30.0715 126.602L28.4854 82.4242L32.1767 73.0073Z" fill="#2F2E41"/>
        <path d="M42.0981 40.393C44.9013 40.393 47.1737 38.1307 47.1737 35.3401C47.1737 32.5494 44.9013 30.2871 42.0981 30.2871C39.2949 30.2871 37.0225 32.5494 37.0225 35.3401C37.0225 38.1307 39.2949 40.393 42.0981 40.393Z" fill="#FFB9B9"/>
        <path d="M44.4738 43.3092L47.4044 47.0535L46.02 75.7634L35.1767 75.5337L33.3311 50.4988L35.6381 44.0677L36.4729 42.6265L44.4738 43.3092Z" fill="#E4E4E4"/>
        <path d="M49.4805 57.8488L52.0183 67.4953L49.4805 77.1419L53.6333 82.6542L57.3246 65.1985C57.3246 65.1985 54.5561 50.9584 54.3254 49.3506C54.0947 47.7429 53.6333 47.5132 53.6333 47.5132L51.7876 47.7429L49.4805 57.8488Z" fill="#2F2E41"/>
        <path d="M31.0236 61.5237L29.4085 64.5097L28.947 69.5626L29.8699 75.3045L26.64 80.5873L23.4102 66.1173L26.8707 47.5132H28.255L31.0236 61.5237Z" fill="#2F2E41"/>
        <path d="M37.7078 36.6486L37.5876 38.6913C37.5876 38.6913 36.0988 35.3398 35.8681 31.8946C35.6374 28.4495 39.5595 27.7604 41.8666 27.5307C44.1737 27.3011 48.0958 28.9088 48.0958 30.7462C48.0958 32.5837 43.943 32.1243 42.328 31.665C40.7131 31.2056 40.0209 35.1102 40.0209 35.1102L39.0981 35.3398L37.7078 36.6486Z" fill="#2F2E41"/>
        <path d="M50.1721 61.0639L49.2493 69.7918L51.0949 75.0744L52.2485 80.1274C52.2485 80.1274 52.2485 84.9507 47.6343 85.1803C43.0201 85.41 29.6389 84.9507 29.6389 84.9507C29.6389 84.9507 27.7932 84.721 26.1782 81.0461C24.5632 77.3712 29.4082 73.926 29.4082 73.926L30.7924 69.1027L30.331 63.1311L27.101 47.5128L36.6755 42.5747L39.098 66.1169L44.289 43.2637L46.0193 44.527L53.402 47.5128L50.1721 61.0639Z" fill="#2F2E41"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1063_20179">
        <rect width="73.7226" height="100" fill="white" transform="translate(0.138672 0.147461)"/>
      </clipPath>
      <clipPath id="clip1_1063_20179">
        <rect width="58.9781" height="111.285" fill="white" transform="translate(14.5186 24.3564)"/>
      </clipPath>
    </defs>
  </svg>

);