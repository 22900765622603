import { Omit } from "@stripe/stripe-js/types/utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../components/PicturesVariant/PicturesVariant";
import { HelpType, useHelpContext } from "../../context/HelpContext";
import { useOnboardingContext } from "../../context/OnboardingContext";
import { EventSubCategory } from "../../modules/Onboarding/EventTypes";
import { PageURL } from "../../types/pages";
import OnboardingListButtonLayout, {
  OnboardingButton,
} from "./OnboardingListButtonLayout";
import { useSessionContext } from "../../context/SessionContext";

const PROMO_ELEMENTS: Omit<OnboardingButton<EventSubCategory>, "onChoose">[] = [
  {
    value: EventSubCategory.HIGH_TECH,
    title: "High tech",
    icon: Icons.HIGHT_TECH_ICON,
    tooltipText:
      "Téléviseurs, Ordinateurs, Appareils photo numériques, Smartphones, Tablettes, Drones...",
  },
  {
    title: "Gaming",
    value: EventSubCategory.GAMING,
    icon: Icons.GAMING_ICON,
    tooltipText:
      "Jeux vidéo, Réalité virtuelle, jeux de société, jeux de plateau",
  },
  {
    title: "Food",
    value: EventSubCategory.FOOD,
    icon: Icons.FOOD_ICON,
    tooltipText: "Restauration rapide, Restaurant, Apéritifs",
  },
  {
    title: "Téléphonie",
    value: EventSubCategory.TELEPHONIE,
    icon: Icons.TELEPHONENIEL_ICON,
    tooltipText: "Téléphones, Box internet, Abonnement, Accessoires ",
  },
  {
    title: "Institutionnel",
    value: EventSubCategory.INSTITUIONNEL,
    icon: Icons.INSTITUIONELL_ICON,
    tooltipText: "Assurance, Santé, institution administrive et politique",
  },
  {
    title: "Textile",
    value: EventSubCategory.TEXTILE,
    icon: Icons.TEXTILE_ICON,
    tooltipText:
      "Manteau, hoodie, T-Shirt, Pantalon, Vêtement personnalisés...",
  },
  {
    title: "Drink",
    value: EventSubCategory.DRINK,
    icon: Icons.DRINK_ICON,
    tooltipText: "Soda, boisson sans alcool, Boisson alcoolisée",
  },
  {
    title: "Cosmétique",
    value: EventSubCategory.COSMETIQUE,
    icon: Icons.COSMETIC_ICON,
    tooltipText: "Produit de beauté, Soin, Parfum, Ongle, Douche...",
  },
];

export default function OnboardingAnimationPromo() {
  const { setEventSubcategory } = useOnboardingContext();
  const navigate = useNavigate();
  const { setHelp } = useHelpContext();
  const { updateHistory } = useSessionContext();

  useEffect(() => {
    setHelp(HelpType.ATTENTION_FIN_COMMERCIALE);
    return () => setHelp(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <OnboardingListButtonLayout
      title="QUEL TYPE DE PRODUIT SERA À PROMOUVOIR ?"
      subtitle="Sélectionner le type produit dont vous souhaitez faire la promotion !"
      elements={PROMO_ELEMENTS.map(
        (e) =>
          ({
            ...e,
            onChoose: (value) => {
              setEventSubcategory(value);
              updateHistory(PageURL.ONBOARDING_DETAILS);
              navigate(PageURL.ONBOARDING_DETAILS);
            },
          } as OnboardingButton<EventSubCategory>)
      )}
    />
  );
}
