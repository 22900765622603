export const RolePlateauIcon = () => (
  <svg
    width="132"
    height="121"
    viewBox="0 0 132 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_942_38739)">
      <path
        d="M49.0507 108.844L12.3457 39.4981L12.5005 39.4273L97.6936 0.445312L97.7633 0.5835L131.413 67.3078L131.269 67.3803L49.0507 108.844ZM12.7891 39.6487L49.1874 108.415L130.981 67.1657L97.546 0.866387L12.7891 39.6487Z"
        fill="#F0F0F0"
      />
      <path
        d="M28.8264 59.291L22.4626 55.1547C22.2696 54.5209 21.8991 53.9558 21.395 53.526C20.8908 53.0962 20.2741 52.8199 19.6178 52.7296C18.9615 52.6393 18.2931 52.739 17.6917 53.0167C17.0902 53.2944 16.5809 53.7386 16.224 54.2967C15.8671 54.8548 15.6775 55.5035 15.6777 56.166C15.6779 56.8284 15.8679 57.477 16.2251 58.0349C16.5824 58.5928 17.0919 59.0367 17.6936 59.314C18.2952 59.5914 18.9636 59.6906 19.6199 59.6C19.6661 59.5936 19.7104 59.5823 19.7559 59.5742L26.5777 64.536L28.8264 59.291Z"
        fill="#9E616A"
      />
      <path
        d="M19.4743 55.4075C26.9742 55.4075 33.0541 54.688 33.0541 53.8004C33.0541 52.9129 26.9742 52.1934 19.4743 52.1934C11.9744 52.1934 5.89453 52.9129 5.89453 53.8004C5.89453 54.688 11.9744 55.4075 19.4743 55.4075Z"
        fill="#2A2939"
      />
      <path
        d="M88.112 105.229L86.405 81.5455L86.7297 73.6406L80.0762 75.1211L82.527 106.155C82.2494 106.727 82.1344 107.364 82.1947 107.997C82.255 108.63 82.4882 109.235 82.8687 109.744C83.2493 110.253 83.7626 110.648 84.3526 110.886C84.9425 111.123 85.5864 111.194 86.2137 111.09C86.8411 110.986 87.4278 110.711 87.9097 110.296C88.3915 109.882 88.7501 109.342 88.946 108.737C89.142 108.132 89.1678 107.485 89.0207 106.866C88.8737 106.248 88.5593 105.681 88.112 105.229Z"
        fill="#9E616A"
      />
      <path
        d="M37.4255 73.5387L22.2988 62.362L27.7831 58.1108L34.4446 63.1548L59.0129 53.189C59.3745 53.1275 59.7448 53.1395 60.1017 53.2244C60.4585 53.3093 60.7946 53.4653 61.0898 53.6831C61.3814 53.8962 61.627 54.166 61.8119 54.4763C61.9968 54.7865 62.1173 55.1309 62.166 55.4888L62.1779 55.5733L57.6747 62.6106L37.4255 73.5387Z"
        fill="#F7B80B"
      />
      <path
        d="M81.8282 97.9219L77.5664 68.2979L77.5734 68.2705L81.9503 51.0648L82.3604 51.0171C83.5429 50.8867 84.7316 51.2021 85.6938 51.9017C86.6561 52.6013 87.3228 53.6347 87.5635 54.7998L87.8879 98.8308L81.8282 97.9219Z"
        fill="#F7B80B"
      />
      <path
        d="M83.1726 46.4195H61.0215V36.4115C61.0215 34.957 61.308 33.5168 61.8646 32.1731C62.4212 30.8293 63.237 29.6083 64.2654 28.5799C65.2939 27.5514 66.5149 26.7356 67.8586 26.179C69.2023 25.6224 70.6426 25.3359 72.097 25.3359C73.5515 25.3359 74.9917 25.6224 76.3354 26.179C77.6792 26.7356 78.9002 27.5514 79.9286 28.5799C80.9571 29.6083 81.7729 30.8293 82.3295 32.1731C82.8861 33.5168 83.1726 34.957 83.1726 36.4115V46.4195Z"
        fill="#1A202C"
      />
      <path
        d="M78.2429 44.0673C81.7783 40.532 81.7783 34.8001 78.243 31.2647C74.7076 27.7294 68.9757 27.7294 65.4404 31.2647C61.905 34.8001 61.905 40.532 65.4404 44.0673C68.9757 47.6027 74.7076 47.6026 78.2429 44.0673Z"
        fill="#9E616A"
      />
      <path
        d="M76.2118 29.986C79.5399 29.986 82.238 27.288 82.238 23.9598C82.238 20.6316 79.5399 17.9336 76.2118 17.9336C72.8836 17.9336 70.1855 20.6316 70.1855 23.9598C70.1855 27.288 72.8836 29.986 76.2118 29.986Z"
        fill="#1A202C"
      />
      <path
        d="M19.4749 54.6045C29.9038 54.6045 38.3581 53.777 38.3581 52.7563C38.3581 51.7356 29.9038 50.9082 19.4749 50.9082C9.04607 50.9082 0.591797 51.7356 0.591797 52.7563C0.591797 53.777 9.04607 54.6045 19.4749 54.6045Z"
        fill="#1A202C"
      />
      <path
        d="M81.9528 38.1465H58.3926V38.013C58.3926 31.9059 63.6771 26.9375 70.1727 26.9375C76.6683 26.9375 81.9528 31.9059 81.9528 38.013V38.1465Z"
        fill="#1A202C"
      />
      <path
        d="M85.7371 52.1555L85.7339 52.1266C85.7142 51.8644 85.6422 51.6088 85.5221 51.375C85.4019 51.1411 85.2361 50.9337 85.0345 50.765C84.8328 50.5963 84.5994 50.4697 84.348 50.3928C84.0966 50.3158 83.8323 50.2901 83.5708 50.317L59.9339 52.734C59.5567 52.7728 59.1987 52.9195 58.9028 53.1567C58.6069 53.3939 58.3857 53.7114 58.2658 54.0711L56.5253 59.2877C56.1188 59.7377 50.7222 65.9281 53.9348 74.391L55.1384 81.3078L52.94 85.4187C48.4273 90.2576 45.7997 101.579 45.1328 118.396C50.4265 119.987 55.925 120.793 61.4526 120.787C61.4977 120.787 61.5426 120.787 61.5876 120.786L63.0774 110.06L66.2883 120.582C71.5446 120.141 76.7124 118.961 81.6391 117.077L77.8673 91.0178L77.112 81.1986L85.7291 52.1812L85.7371 52.1555Z"
        fill="#1A202C"
      />
      <path
        d="M20.7211 34.8053C20.3664 34.841 20.0095 34.8494 19.6535 34.8302C19.5928 34.8236 19.532 34.841 19.4841 34.8788C19.4361 34.9166 19.4049 34.9717 19.3972 35.0323L18.7162 40.1405C18.6638 40.5113 18.512 40.861 18.2769 41.1525C18.083 41.3844 17.9389 41.6537 17.8535 41.9437C17.7681 42.2337 17.7432 42.5381 17.7804 42.8381V51.5153C17.7804 51.5153 17.7365 52.6723 18.6254 52.6723C19.6894 52.8059 20.7659 52.8059 21.8298 52.6723C21.8298 52.6723 22.5415 52.5375 22.5415 51.4699V42.926C22.5958 42.6279 22.5846 42.3216 22.5088 42.0282C22.4329 41.7349 22.2942 41.4615 22.1022 41.2272C21.8749 40.9346 21.728 40.5878 21.676 40.221L20.9686 35.0059C20.9606 34.9473 20.9306 34.8939 20.8846 34.8567C20.8386 34.8194 20.7802 34.801 20.7211 34.8053Z"
        fill="white"
      />
      <path
        d="M20.2241 52.9187C19.6864 52.9187 19.1492 52.8853 18.6156 52.8186C18.4964 52.8215 18.3779 52.7996 18.2675 52.7544C18.1572 52.7092 18.0573 52.6417 17.9743 52.5561C17.6125 52.1804 17.6327 51.5369 17.6337 51.5097L17.6336 42.847C17.5958 42.5285 17.6234 42.2056 17.7146 41.8981C17.8059 41.5906 17.9588 41.3049 18.1641 41.0585C18.3818 40.7881 18.5223 40.4638 18.5708 40.12L19.2517 35.0129C19.265 34.9147 19.3159 34.8254 19.3939 34.7642C19.4718 34.7029 19.5705 34.6743 19.6691 34.6846C20.0149 34.7028 20.3616 34.6945 20.7061 34.6596L20.7102 34.6592C20.8064 34.6522 20.9015 34.6821 20.9764 34.7428C21.0513 34.8035 21.1003 34.8905 21.1134 34.986L21.8208 40.2014C21.8694 40.5425 22.0061 40.865 22.2174 41.1372C22.421 41.3859 22.5684 41.6757 22.6495 41.9867C22.7307 42.2976 22.7436 42.6225 22.6876 42.9389V51.4698C22.6876 52.6452 21.865 52.8146 21.8566 52.8161C21.3151 52.8844 20.7699 52.9186 20.2241 52.9187ZM19.6332 34.9758C19.6115 34.9747 19.5901 34.9818 19.5732 34.9957C19.5564 35.0095 19.5453 35.0291 19.5421 35.0507L18.861 40.1598C18.805 40.5572 18.6424 40.932 18.3905 41.2444C18.2094 41.4613 18.0747 41.713 17.9947 41.984C17.9147 42.255 17.8911 42.5396 17.9254 42.82L17.9265 42.838V51.5152C17.9262 51.526 17.9107 52.0686 18.186 52.3537C18.2429 52.4117 18.3115 52.4569 18.3871 52.4866C18.4628 52.5163 18.5439 52.5296 18.6251 52.5258L18.6433 52.5269C19.6951 52.659 20.7593 52.659 21.8111 52.5269C21.825 52.5235 22.3947 52.3936 22.3947 51.4698L22.3971 42.8997C22.4478 42.6225 22.4375 42.3376 22.3669 42.0648C22.2964 41.7919 22.1672 41.5378 21.9885 41.3199C21.7445 41.0065 21.5867 40.6348 21.5307 40.2415L20.8232 35.0255C20.82 35.0041 20.8091 34.9847 20.7924 34.9709C20.7757 34.9572 20.7545 34.9502 20.7329 34.9512C20.3715 34.9874 20.0079 34.9958 19.6453 34.9764C19.6413 34.976 19.6373 34.9758 19.6332 34.9758Z"
        fill="#2D3748"
      />
      <path
        d="M19.6456 42.9941H20.725C21.1542 42.9941 21.5658 43.1646 21.8693 43.4681C22.1727 43.7716 22.3432 44.1832 22.3432 44.6124V48.6735C22.3432 49.1027 22.1727 49.5143 21.8693 49.8178C21.5658 50.1213 21.1542 50.2918 20.725 50.2918H19.6456C19.2164 50.2918 18.8048 50.1213 18.5013 49.8178C18.1978 49.5143 18.0273 49.1027 18.0273 48.6735V44.6124C18.0273 44.1832 18.1978 43.7716 18.5013 43.4681C18.8048 43.1646 19.2164 42.9941 19.6456 42.9941Z"
        fill="#F7B80B"
      />
      <path
        d="M28.9985 34.8053C28.6438 34.841 28.2869 34.8494 27.9309 34.8302C27.8702 34.8236 27.8093 34.841 27.7614 34.8788C27.7135 34.9166 27.6823 34.9717 27.6746 35.0323L26.9936 40.1405C26.9412 40.5113 26.7894 40.861 26.5542 41.1525C26.3604 41.3844 26.2163 41.6537 26.1309 41.9437C26.0455 42.2337 26.0206 42.5381 26.0578 42.8381V51.5153C26.0578 51.5153 26.0138 52.6723 26.9028 52.6723C27.9667 52.8059 29.0432 52.8059 30.1071 52.6723C30.1071 52.6723 30.8189 52.5375 30.8189 51.4699V42.926C30.8732 42.6279 30.862 42.3216 30.7861 42.0282C30.7103 41.7349 30.5715 41.4615 30.3795 41.2272C30.1523 40.9346 30.0054 40.5878 29.9534 40.221L29.246 35.0059C29.238 34.9473 29.2079 34.8939 29.1619 34.8567C29.116 34.8194 29.0575 34.801 28.9985 34.8053Z"
        fill="white"
      />
      <path
        d="M28.5015 52.9187C27.9637 52.9187 27.4265 52.8853 26.893 52.8186C26.7737 52.8215 26.6552 52.7996 26.5449 52.7544C26.4345 52.7092 26.3347 52.6417 26.2516 52.5561C25.8899 52.1804 25.91 51.5369 25.911 51.5097L25.9109 42.847C25.8732 42.5285 25.9008 42.2056 25.992 41.8981C26.0832 41.5906 26.2361 41.3049 26.4415 41.0585C26.6591 40.7881 26.7997 40.4638 26.8482 40.12L27.529 35.0129C27.5423 34.9147 27.5933 34.8254 27.6712 34.7642C27.7491 34.7029 27.8478 34.6743 27.9464 34.6846C28.2922 34.7028 28.6389 34.6945 28.9835 34.6596L28.9875 34.6592C29.0837 34.6522 29.1789 34.6821 29.2538 34.7428C29.3287 34.8035 29.3776 34.8905 29.3907 34.986L30.0981 40.2014C30.1467 40.5425 30.2834 40.865 30.4948 41.1372C30.6983 41.3859 30.8457 41.6757 30.9269 41.9867C31.008 42.2976 31.021 42.6225 30.9649 42.9389V51.4698C30.9649 52.6452 30.1423 52.8146 30.134 52.8161C29.5925 52.8844 29.0472 52.9186 28.5015 52.9187ZM27.9106 34.9758C27.8888 34.9747 27.8674 34.9818 27.8506 34.9957C27.8337 35.0095 27.8227 35.0291 27.8195 35.0507L27.1384 40.1598C27.0824 40.5572 26.9197 40.932 26.6678 41.2444C26.4867 41.4613 26.3521 41.713 26.2721 41.984C26.1921 42.255 26.1685 42.5396 26.2027 42.82L26.2038 42.838V51.5152C26.2036 51.526 26.188 52.0686 26.4633 52.3537C26.5203 52.4117 26.5888 52.4569 26.6645 52.4866C26.7402 52.5163 26.8212 52.5296 26.9024 52.5258L26.9207 52.5269C27.9725 52.659 29.0367 52.659 30.0885 52.5269C30.1024 52.5235 30.672 52.3936 30.672 51.4698L30.6744 42.8997C30.7251 42.6225 30.7148 42.3376 30.6443 42.0648C30.5737 41.7919 30.4446 41.5378 30.2658 41.3199C30.0219 41.0065 29.864 40.6348 29.808 40.2415L29.1005 35.0255C29.0974 35.0041 29.0864 34.9847 29.0697 34.9709C29.053 34.9572 29.0318 34.9502 29.0102 34.9512C28.6489 34.9874 28.2853 34.9958 27.9226 34.9764C27.9186 34.976 27.9146 34.9758 27.9106 34.9758Z"
        fill="#2D3748"
      />
      <path
        d="M27.923 42.9941H29.0023C29.4315 42.9941 29.8431 43.1646 30.1466 43.4681C30.4501 43.7716 30.6206 44.1832 30.6206 44.6124V48.6735C30.6206 49.1027 30.4501 49.5143 30.1466 49.8178C29.8431 50.1213 29.4315 50.2918 29.0023 50.2918H27.923C27.4938 50.2918 27.0822 50.1213 26.7787 49.8178C26.4752 49.5143 26.3047 49.1027 26.3047 48.6735V44.6124C26.3047 44.1832 26.4752 43.7716 26.7787 43.4681C27.0822 43.1646 27.4938 42.9941 27.923 42.9941Z"
        fill="#F7B80B"
      />
      <path
        d="M11.4106 35.2194C11.0559 35.2551 10.699 35.2634 10.343 35.2443C10.2823 35.2376 10.2214 35.2551 10.1735 35.2929C10.1256 35.3307 10.0944 35.3858 10.0867 35.4464L9.40569 40.5546C9.3533 40.9254 9.20148 41.2751 8.96634 41.5665C8.77249 41.7985 8.62839 42.0678 8.54299 42.3578C8.45758 42.6477 8.43269 42.9522 8.46987 43.2522V51.9294C8.46987 51.9294 8.42594 53.0863 9.31489 53.0863C10.3788 53.22 11.4553 53.22 12.5192 53.0863C12.5192 53.0863 13.231 52.9516 13.231 51.884V43.34C13.2853 43.042 13.2741 42.7356 13.1982 42.4423C13.1224 42.1489 12.9836 41.8756 12.7916 41.6412C12.5644 41.3487 12.4175 41.0019 12.3655 40.6351L11.6581 35.42C11.6501 35.3614 11.62 35.308 11.574 35.2707C11.5281 35.2335 11.4696 35.2151 11.4106 35.2194Z"
        fill="white"
      />
      <path
        d="M10.9136 53.3327C10.3758 53.3328 9.83865 53.2994 9.30508 53.2327C9.18585 53.2355 9.06734 53.2136 8.95697 53.1685C8.84661 53.1233 8.74678 53.0558 8.66375 52.9702C8.30199 52.5945 8.32212 51.951 8.32315 51.9237L8.32304 43.261C8.28529 42.9425 8.31287 42.6197 8.40409 42.3122C8.4953 42.0047 8.64825 41.719 8.85357 41.4726C9.07125 41.2021 9.21177 40.8778 9.26028 40.534L9.94116 35.4269C9.95442 35.3287 10.0054 35.2395 10.0833 35.1782C10.1612 35.1169 10.2599 35.0884 10.3585 35.0986C10.7043 35.1169 11.051 35.1085 11.3956 35.0736L11.3997 35.0733C11.4958 35.0663 11.591 35.0962 11.6659 35.1569C11.7408 35.2176 11.7897 35.3045 11.8028 35.4001L12.5102 40.6154C12.5588 40.9565 12.6956 41.2791 12.9069 41.5513C13.1104 41.8 13.2578 42.0898 13.339 42.4007C13.4201 42.7117 13.4331 43.0365 13.377 43.353V51.8839C13.377 53.0592 12.5544 53.2286 12.5461 53.2302C12.0046 53.2984 11.4593 53.3327 10.9136 53.3327ZM10.3227 35.3898C10.3009 35.3888 10.2795 35.3959 10.2627 35.4097C10.2458 35.4236 10.2348 35.4432 10.2316 35.4648L9.55047 40.5739C9.49446 40.9713 9.33185 41.3461 9.07993 41.6585C8.89885 41.8754 8.76417 42.1271 8.68418 42.3981C8.60418 42.6691 8.58058 42.9536 8.61484 43.2341L8.61595 43.2521V51.9293C8.61566 51.9401 8.60011 52.4827 8.87542 52.7677C8.93238 52.8257 9.00093 52.871 9.0766 52.9007C9.15226 52.9303 9.23334 52.9437 9.31452 52.9399L9.33279 52.941C10.3846 53.073 11.4488 53.073 12.5006 52.941C12.5145 52.9376 13.0842 52.8076 13.0842 51.8839L13.0865 43.3137C13.1372 43.0366 13.1269 42.7516 13.0564 42.4788C12.9858 42.206 12.8567 41.9518 12.6779 41.734C12.434 41.4206 12.2761 41.0488 12.2201 40.6556L11.5126 35.4396C11.5095 35.4182 11.4985 35.3987 11.4818 35.385C11.4651 35.3713 11.4439 35.3642 11.4223 35.3653C11.061 35.4015 10.6974 35.4099 10.3347 35.3905C10.3307 35.3901 10.3267 35.3898 10.3227 35.3898Z"
        fill="#2D3748"
      />
      <path
        d="M10.3351 43.4082H11.4144C11.8436 43.4082 12.2552 43.5787 12.5587 43.8822C12.8622 44.1857 13.0327 44.5973 13.0327 45.0265V49.0876C13.0327 49.5168 12.8622 49.9284 12.5587 50.2319C12.2552 50.5353 11.8436 50.7058 11.4144 50.7058H10.3351C9.90588 50.7058 9.49426 50.5353 9.19078 50.2319C8.88729 49.9284 8.7168 49.5168 8.7168 49.0876V45.0265C8.7168 44.5973 8.88729 44.1857 9.19078 43.8822C9.49426 43.5787 9.90588 43.4082 10.3351 43.4082Z"
        fill="#F7B80B"
      />
      <path
        d="M24.2387 36.0475C23.884 36.0832 23.5271 36.0915 23.1711 36.0724C23.1104 36.0657 23.0496 36.0832 23.0016 36.121C22.9537 36.1588 22.9225 36.2139 22.9148 36.2745L22.2338 41.3827C22.1814 41.7535 22.0296 42.1032 21.7945 42.3947C21.6006 42.6266 21.4565 42.8959 21.3711 43.1859C21.2857 43.4759 21.2608 43.7803 21.298 44.0803V52.7575C21.298 52.7575 21.2541 53.9145 22.143 53.9145C23.2069 54.0481 24.2834 54.0481 25.3474 53.9145C25.3474 53.9145 26.0591 53.7797 26.0591 52.7121V44.1682C26.1134 43.8701 26.1022 43.5637 26.0264 43.2704C25.9505 42.9771 25.8118 42.7037 25.6198 42.4693C25.3925 42.1768 25.2456 41.83 25.1936 41.4632L24.4862 36.2481C24.4782 36.1895 24.4481 36.1361 24.4022 36.0988C24.3562 36.0616 24.2977 36.0432 24.2387 36.0475Z"
        fill="white"
      />
      <path
        d="M23.7417 54.1609C23.204 54.1609 22.6668 54.1275 22.1332 54.0608C22.014 54.0636 21.8955 54.0418 21.7851 53.9966C21.6747 53.9514 21.5749 53.8839 21.4919 53.7983C21.1301 53.4226 21.1502 52.7791 21.1513 52.7519L21.1512 44.0892C21.1134 43.7707 21.141 43.4478 21.2322 43.1403C21.3234 42.8328 21.4764 42.5471 21.6817 42.3007C21.8994 42.0303 22.0399 41.706 22.0884 41.3622L22.7693 36.2551C22.7825 36.1568 22.8335 36.0676 22.9114 36.0063C22.9893 35.9451 23.088 35.9165 23.1866 35.9268C23.5325 35.945 23.8792 35.9366 24.2237 35.9017L24.2278 35.9014C24.3239 35.8944 24.4191 35.9243 24.494 35.985C24.5689 36.0457 24.6179 36.1327 24.6309 36.2282L25.3384 41.4436C25.387 41.7847 25.5237 42.1072 25.735 42.3794C25.9385 42.6281 26.0859 42.9179 26.1671 43.2289C26.2482 43.5398 26.2612 43.8647 26.2052 44.1811V52.712C26.2052 53.8873 25.3825 54.0567 25.3742 54.0583C24.8327 54.1266 24.2875 54.1608 23.7417 54.1609ZM23.1508 36.218C23.129 36.2169 23.1076 36.224 23.0908 36.2379C23.074 36.2517 23.0629 36.2713 23.0597 36.2929L22.3786 41.402C22.3226 41.7994 22.16 42.1742 21.9081 42.4866C21.727 42.7035 21.5923 42.9552 21.5123 43.2262C21.4323 43.4972 21.4087 43.7817 21.443 44.0622L21.4441 44.0802V52.7574C21.4438 52.7682 21.4282 53.3108 21.7035 53.5959C21.7605 53.6538 21.8291 53.6991 21.9047 53.7288C21.9804 53.7585 22.0615 53.7718 22.1426 53.768L22.1609 53.7691C23.2127 53.9011 24.2769 53.9011 25.3287 53.7691C25.3426 53.7657 25.9123 53.6358 25.9123 52.712L25.9146 44.1419C25.9653 43.8647 25.9551 43.5798 25.8845 43.3069C25.8139 43.0341 25.6848 42.78 25.5061 42.5621C25.2621 42.2487 25.1043 41.8769 25.0482 41.4837L24.3407 36.2677C24.3376 36.2463 24.3266 36.2269 24.31 36.2131C24.2933 36.1994 24.272 36.1924 24.2504 36.1934C23.8891 36.2296 23.5255 36.238 23.1629 36.2186C23.1589 36.2182 23.1548 36.218 23.1508 36.218Z"
        fill="#2D3748"
      />
      <path
        d="M23.1632 44.2363H24.2425C24.6717 44.2363 25.0833 44.4068 25.3868 44.7103C25.6903 45.0138 25.8608 45.4254 25.8608 45.8546V49.9157C25.8608 50.3449 25.6903 50.7565 25.3868 51.06C25.0833 51.3635 24.6717 51.534 24.2425 51.534H23.1632C22.734 51.534 22.3224 51.3635 22.0189 51.06C21.7154 50.7565 21.5449 50.3449 21.5449 49.9157V45.8546C21.5449 45.4254 21.7154 45.0138 22.0189 44.7103C22.3224 44.4068 22.734 44.2363 23.1632 44.2363Z"
        fill="#F7B80B"
      />
    </g>
    <defs>
      <clipPath id="clip0_942_38739">
        <rect
          width="130.816"
          height="120.37"
          fill="white"
          transform="translate(0.591797 0.445312)"
        />
      </clipPath>
    </defs>
  </svg>
);
