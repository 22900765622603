import {
  AccordionButton,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  Box,
  Heading,
} from "@chakra-ui/react";
import { colors } from "../../ui/theme";
import CustomTooltip from "../CustomTooltip";

export default function AccordionWrapper({
  title,
  children,
  detail,
  fakeButton,
  ...props
}: {
  title: string;
  children?: React.ReactNode;
  detail?: string[];
  fakeButton?: string;
  isDisabled?: boolean;
} & AccordionItemProps) {
  return (
    <AccordionItem
      borderRadius="25px"
      border="solid 1px"
      borderColor="gray.200 !important"
      p="1rem"
      w="100%"
      mb="1rem"
      {...props}
    >
      <AccordionButton
        borderRadius="25px"
        _hover={{}}
        w="100%"
        justifyContent="space-between"
      >
        <Heading size="md" display="inline">
          {title}
        </Heading>
        {fakeButton ? (
          <Box
            bg={colors.additional.lightMode}
            p="0.5rem"
            m="0.5rem"
            borderRadius="8px"
            color="white"
          >
            {fakeButton}
          </Box>
        ) : (
          <></>
        )}
        {detail ? (
          <CustomTooltip content={detail}>
            <Heading
              size="md"
              display="inline"
              color={colors.white}
              bg={colors.additional.lightMode}
              borderRadius="50%"
              w="1.5rem"
              h="1.5rem"
            >
              ?
            </Heading>
          </CustomTooltip>
        ) : (
          <></>
        )}
      </AccordionButton>
      <AccordionPanel py="0.5rem" px="1rem">
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
}
