export const DressingTopIcon = () => (
  <svg
    width="43"
    height="50"
    viewBox="0 0 43 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.8277 6.86743L32.4557 4.88368C32.4557 4.88368 34.774 4.71951 35.1846 8.90027C35.5952 13.081 38.166 29.1268 38.166 29.1268L42.1873 47.7521L36.6207 48.9739L32.3135 28.8739L28.002 15.0069L29.8277 6.86743Z"
      fill="#2F2E41"
    />
    <path
      d="M25.2608 0L18.5314 0.26253L15.8493 4.71448L9.73047 5.90899L13.3833 24.3887L9.77559 37.8003L22.7391 43.4734L25.6238 34.8284L29.4576 45.3091L35.6129 38.616L30.5467 25.696L33.1675 15.5037L32.5784 4.81621L27.656 4.2009L25.2608 0Z"
      fill="#2F2E41"
    />
    <path
      d="M12.3596 7.89282L9.73162 5.90907C9.73162 5.90907 7.41331 5.7449 7.00273 9.92566C6.59214 14.1064 4.02134 30.1521 4.02134 30.1521L0 48.7775L5.56663 49.9993L9.87377 29.8993L14.1854 16.0323L12.3596 7.89282Z"
      fill="#2F2E41"
    />
  </svg>
);
