export const FlyersPopupIcon = () => (
  <svg
    width="195"
    height="254"
    viewBox="0 0 195 254"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1055_69834)">
      <path
        d="M69.7443 31.2794C69.4003 30.0701 74.2095 41.1535 73.0654 41.2869C67.8547 41.8873 56.3084 44.5941 52.6792 46.0524C52.5157 46.0333 52.3523 46.0142 52.1937 45.9904C51.8043 45.9475 51.4245 45.8856 51.0496 45.8141C42.599 44.2081 36.2057 37.2362 36.0327 28.8204C36.023 28.7013 36.023 28.5774 36.023 28.4582C36.0219 27.8772 36.0524 27.2965 36.1144 26.7188C36.1939 25.93 36.3305 25.148 36.523 24.379L36.5037 24.3742C33.1389 21.2004 34.5569 15.3151 38.8447 12.8322C40.7078 11.7562 42.774 11.0826 44.9111 10.8546C45.4906 10.7914 46.0731 10.7596 46.6561 10.7593H52.1312C53.2642 10.514 54.4125 10.3469 55.5682 10.2589H55.5778C66.6002 9.41539 75.2912 16.1633 74.4788 24.3742C74.474 24.3838 74.4643 24.3933 74.4596 24.4028C74.224 24.7555 74.0173 25.0891 73.8346 25.4083C73.6568 25.7086 73.5078 25.9945 73.378 26.2661C72.3733 28.3629 74.9558 31.0014 73.2434 31.4939C70.9659 32.1489 79.2333 53.6772 70.2187 53.6772C74.6036 54.9293 70.6932 34.6152 69.7443 31.2794Z"
        fill="#1A202C"
      />
      <path
        d="M76.1076 231.168L82.689 233.869L96.4221 209.091L86.7101 205.105L76.1076 231.168Z"
        fill="#FFB6B6"
      />
      <path
        d="M92.7498 250.472C92.4304 251.255 91.8147 251.879 91.0377 252.207C90.2608 252.535 89.3861 252.54 88.6055 252.221L75.5217 246.85L75.1752 241.244L72.391 245.562L67.4527 243.539L75.6352 227.402L77.3945 228.007L84.5772 230.458L86.8926 231.253L84.9685 235.983L92.4695 247.521C92.7504 247.953 92.9227 248.447 92.9715 248.961C93.0204 249.475 92.9443 249.994 92.7498 250.472Z"
        fill="#1A202C"
      />
      <path
        d="M82.2612 224.959C79.7845 223.547 73.4363 220.217 73.4363 220.217C73.4363 220.217 73.0429 212.396 74.3906 210.581C75.7331 208.753 77.1395 208.859 76.436 207.313C77.9622 195.773 85.4231 179.124 85.4231 179.124C85.4231 179.124 73.4388 165.496 61.7462 150.638C50.3181 136.107 39.1685 120.402 39.7761 115.104C40.2858 111.347 41.1207 107.641 42.2712 104.029C42.996 101.647 43.79 99.3462 44.5394 97.3004C46.2494 92.6156 47.7483 89.2523 47.7483 89.2523L66.8448 96.9065L79.527 101.896L80.8401 102.448L79.9244 104.646L80.8765 111.669C80.8765 111.669 83.8049 120.726 82.877 121.064C81.7541 121.475 81.7208 122.247 84.3693 124C85.1269 124.488 85.9458 124.872 86.8044 125.143C91.5965 126.738 92.4281 130.224 92.2984 130.856C91.3577 135.311 95.4341 147.563 99.5636 158.292L99.568 158.294C103.376 168.202 107.229 176.805 107.229 176.805L104.429 184.539L91.3432 220.702C91.3432 220.702 88.9231 217.598 90.1509 222.614C91.3769 227.634 84.7398 226.368 82.2612 224.959Z"
        fill="#1A202C"
      />
      <path
        d="M13.2392 227.57L18.1501 232.734L40.757 215.744L33.5105 208.124L13.2392 227.57Z"
        fill="#FFB6B6"
      />
      <path
        d="M20.5872 252.013C19.9772 252.597 19.1615 252.914 18.319 252.895C17.4765 252.876 16.676 252.522 16.0931 251.91L6.33168 241.643L8.29387 236.383L3.99711 239.184L0.311116 235.312L14.3391 223.936L15.6992 225.21L21.2602 230.392L23.0508 232.067L19.3719 235.596L21.5306 249.204C21.6113 249.714 21.5676 250.236 21.4033 250.725C21.239 251.214 20.9589 251.656 20.5872 252.013Z"
        fill="#1A202C"
      />
      <path
        d="M32.491 221.958C32.463 221.929 32.4393 221.897 32.4175 221.87C30.6704 219.718 26.2915 215.566 26.0219 215.309C26.0175 215.303 26.012 215.299 26.0057 215.295C26.0057 215.295 29.0559 208.776 30.9638 207.573C32.8822 206.371 29.8961 208.156 34.0568 205.275C36.2153 203.788 41.1266 197.677 45.3621 192.136C49.3027 186.981 52.6486 182.32 52.6486 182.32C52.6486 182.32 50.3657 176.05 47.3355 167.079L47.3336 167.075C44.5309 158.747 41.0845 148.084 38.2358 137.951C40.1088 119.53 42.996 100.46 47.1052 97.0831C50.0764 94.743 53.245 92.6674 56.575 90.8799L66.418 100.133L73.9321 117.005L73.9049 131.508L73.8956 137.182L73.8771 145.125L73.7951 188.138L43.0914 223.209C43.0914 223.209 41.9598 219.425 41.2837 224.551C40.6139 229.607 34.3775 224.232 32.491 221.958Z"
        fill="#1A202C"
      />
      <path
        d="M55.0371 46.5293H69.2706L71.5117 51.4977L81.0439 55.7637C82.2835 56.3194 83.3758 57.1599 84.2327 58.2175C85.0895 59.2751 85.6869 60.5201 85.9767 61.8522C89.4091 77.6122 85.0674 80.8867 84.9276 86.0828C84.4531 103.715 82.0084 107.222 82.2819 111.575C82.3637 112.878 83.1554 118.029 85.7021 117.543C88.2488 117.058 86.876 119.095 87.4787 119.886C87.9994 120.57 90.7382 119.663 89.0191 121.935C87.2999 124.207 94.6498 130.402 90.1466 130.402C81.6064 130.402 64.5261 134.691 57.8838 134.691C51.2415 134.691 46.1084 136.669 43.1758 136.597C41.3884 136.553 41.9472 128.182 42.6616 120.932C42.8234 119.29 45.9617 119.333 43.1454 116.29C40.329 113.246 43.6442 111.664 43.6502 110.863C43.6566 110.012 45.2468 107.869 43.2624 104.362C41.2779 100.856 43.1072 102.307 43.021 101.206C42.3568 92.7208 41.405 81.902 41.405 81.902L37.2091 70.0408C36.1366 67.009 36.2784 63.6774 37.6046 60.7486C38.9308 57.8197 41.3377 55.5228 44.3175 54.3421L49.2393 52.3921L55.0371 46.5293Z"
        fill="#F7B80B"
      />
      <path
        d="M155.002 42.1548C154.685 42.7475 154.245 43.2641 153.71 43.6689C153.175 44.0737 152.56 44.357 151.905 44.4992C151.251 44.6415 150.573 44.6392 149.92 44.4927C149.267 44.3461 148.653 44.0588 148.121 43.6505L128.07 55.0522L123.566 50.2571L147.48 36.8899C148.231 36.0416 149.265 35.4994 150.387 35.3661C151.51 35.2328 152.642 35.5176 153.569 36.1665C154.496 36.8155 155.154 37.7835 155.418 38.8872C155.682 39.9909 155.534 41.1535 155.002 42.1548Z"
        fill="#FFB6B6"
      />
      <path
        d="M64.7214 69.0666L64.416 72.7383L104.334 72.2229L144.067 49.7868L138.258 40.8066L103.335 55.5552L78.5134 56.0608C75.0203 56.1319 71.6773 57.5007 69.1301 59.9027C66.5829 62.3047 65.0122 65.5695 64.7214 69.0666Z"
        fill="#F7B80B"
      />
      <path
        d="M59.9045 43.9117C67.0638 43.9117 72.8675 38.0823 72.8675 30.8914C72.8675 23.7005 67.0638 17.8711 59.9045 17.8711C52.7452 17.8711 46.9414 23.7005 46.9414 30.8914C46.9414 38.0823 52.7452 43.9117 59.9045 43.9117Z"
        fill="#FFB6B6"
      />
      <path
        d="M74.4793 25.0841C74.263 25.1985 74.0515 25.3034 73.8352 25.4082C73.6093 25.5178 73.3833 25.6274 73.1574 25.7275C68.8167 27.7052 65.2836 27.9244 63.1397 25.0841C63.0823 23.5897 62.7481 22.1191 62.1542 20.7476C62.2591 22.2802 61.8569 23.8047 61.0102 25.0841H56.6935C52.2471 28.0483 49.9638 28.358 53.0498 38.0891C53.9103 40.8007 57.3316 56.5792 55.9857 58.919C55.5963 58.8761 51.4251 45.8855 51.0502 45.814C42.5995 44.208 36.2062 37.2361 36.0332 28.8203C36.0428 28.3914 36.0716 27.9625 36.1149 27.5431C36.6716 26.7026 37.2835 25.9002 37.9464 25.1413C37.943 22.8917 38.458 20.6719 39.4509 18.6555C40.7072 16.1009 42.6655 13.961 45.0943 12.489C46.542 11.7839 48.0514 11.2144 49.6033 10.7877C49.6417 10.7782 49.6754 10.7686 49.7138 10.7591C50.1897 10.6304 50.6752 10.5113 51.1607 10.4112C52.1637 10.1985 53.178 10.0442 54.1987 9.94899C54.2708 9.94423 54.3429 9.93945 54.4102 9.93945C54.8178 9.94041 55.2177 10.0506 55.5687 10.2587C55.5735 10.2587 55.5735 10.2635 55.5783 10.2635C55.8291 10.4107 56.0481 10.6068 56.2225 10.8401C56.5295 11.2328 56.6955 11.7181 56.6935 12.2174H61.5005C61.6784 12.2174 61.8562 12.2221 62.0341 12.2316C65.2423 12.3418 68.2942 13.6504 70.5924 15.9015C72.8907 18.1526 74.2699 21.1841 74.4601 24.4027C74.4745 24.6314 74.4793 24.8554 74.4793 25.0841Z"
        fill="#1A202C"
      />
      <path
        d="M42.2257 27.4663C46.9423 27.4663 50.7658 23.6258 50.7658 18.8884C50.7658 14.151 46.9423 10.3105 42.2257 10.3105C37.5091 10.3105 33.6855 14.151 33.6855 18.8884C33.6855 23.6258 37.5091 27.4663 42.2257 27.4663Z"
        fill="#1A202C"
      />
      <path
        d="M52.6643 12.2183C52.8274 16.9529 48.8407 20.7961 44.1241 20.7961C41.8591 20.7961 39.6869 19.8924 38.0853 18.2837C36.4837 16.6751 35.584 14.4933 35.584 12.2183C35.584 7.48085 41.2961 7.43183 44.1241 3.64041C48.1247 -1.72306 52.4443 5.8333 52.6643 12.2183Z"
        fill="#1A202C"
      />
      <path
        d="M37.9037 130.519C41.7948 116.611 48.9801 104.786 60.5293 95.7207C60.9881 95.0169 61.706 94.5246 62.5257 94.3518C63.3453 94.179 64.1997 94.3399 64.9015 94.7991L91.0661 111.962C91.7668 112.423 92.2569 113.144 92.4289 113.967C92.6009 114.79 92.4408 115.648 91.9837 116.353L69.3581 151.151C68.8993 151.855 68.1814 152.347 67.3618 152.52C66.5421 152.693 65.6877 152.532 64.986 152.073L38.8213 134.91C38.1206 134.449 37.6305 133.728 37.4585 132.905C37.2865 132.082 37.4466 131.223 37.9037 130.519Z"
        fill="#EDF2F7"
      />
      <path
        opacity="0.2"
        d="M42.493 135.404C41.7923 134.943 41.3022 134.222 41.1302 133.399C40.9582 132.576 41.1183 131.718 41.5755 131.013C45.4665 117.105 52.6519 105.28 64.201 96.2148C64.5045 95.7542 64.9237 95.3823 65.4163 95.1368L64.9014 94.799C64.1997 94.3399 63.3453 94.179 62.5256 94.3518C61.706 94.5246 60.9881 95.0168 60.5293 95.7206C48.9801 104.786 41.7948 116.611 37.9037 130.519C37.4466 131.223 37.2865 132.082 37.4585 132.905C37.6305 133.728 38.1206 134.449 38.8213 134.91L64.9859 152.073C65.4517 152.376 65.989 152.55 66.5434 152.578C67.0978 152.605 67.6496 152.485 68.1428 152.229L42.493 135.404Z"
        fill="black"
      />
      <path
        d="M74.0733 128.624L56.5081 117.172C56.229 116.989 56.0333 116.703 55.9641 116.376C55.895 116.048 55.958 115.707 56.1393 115.426C56.3206 115.146 56.6054 114.949 56.9312 114.879C57.2569 114.81 57.5968 114.872 57.8763 115.054L75.4415 126.507C75.7198 126.689 75.9146 126.976 75.9832 127.302C76.0518 127.629 75.9887 127.97 75.8077 128.25C75.6267 128.53 75.3425 128.727 75.0174 128.797C74.6923 128.867 74.3528 128.805 74.0733 128.624L74.0733 128.624Z"
        fill="white"
      />
      <path
        d="M64.1254 113.886L60.2611 111.367C59.9814 111.185 59.785 110.9 59.7149 110.573C59.6448 110.246 59.7068 109.904 59.8872 109.623C60.0676 109.342 60.3517 109.144 60.6771 109.074C61.0025 109.003 61.3426 109.064 61.6226 109.245L61.6293 109.25L65.4936 111.769C65.7719 111.952 65.9666 112.238 66.0353 112.565C66.1039 112.892 66.0408 113.232 65.8598 113.512C65.6787 113.793 65.3945 113.989 65.0694 114.059C64.7444 114.129 64.4049 114.067 64.1254 113.886L64.1254 113.886Z"
        fill="white"
      />
      <path
        d="M68.0594 137.931L50.4942 126.479C50.2158 126.296 50.0209 126.01 49.9522 125.683C49.8834 125.356 49.9465 125.015 50.1276 124.735C50.3087 124.455 50.593 124.258 50.9182 124.188C51.2434 124.118 51.5829 124.18 51.8624 124.362L69.4276 135.814C69.5666 135.903 69.6866 136.02 69.7808 136.156C69.8749 136.292 69.9414 136.446 69.9764 136.608C70.0114 136.77 70.0142 136.938 69.9847 137.101C69.9553 137.264 69.894 137.42 69.8045 137.559C69.7151 137.699 69.5991 137.819 69.4632 137.914C69.3274 138.008 69.1743 138.074 69.0129 138.109C68.8514 138.144 68.6847 138.147 68.5222 138.117C68.3597 138.087 68.2047 138.025 68.066 137.935L68.0594 137.931Z"
        fill="white"
      />
      <path
        d="M71.0658 133.278L53.5006 121.825C53.2209 121.643 53.0246 121.357 52.9548 121.03C52.8851 120.703 52.9476 120.361 53.1287 120.08C53.3098 119.799 53.5947 119.602 53.9205 119.532C54.2464 119.462 54.5866 119.525 54.8663 119.707L54.8688 119.708L72.434 131.16C72.7124 131.343 72.9074 131.629 72.9761 131.956C73.0448 132.283 72.9817 132.624 72.8006 132.904C72.6195 133.184 72.3352 133.381 72.01 133.451C71.6848 133.521 71.3453 133.459 71.0658 133.278L71.0658 133.278Z"
        fill="white"
      />
      <path
        d="M75.4538 107.942C78.1804 108.915 81.1757 107.483 82.144 104.744C83.1123 102.006 81.687 98.9973 78.9604 98.0247C76.2338 97.0521 73.2385 98.4838 72.2702 101.222C71.3019 103.961 72.7272 106.97 75.4538 107.942Z"
        fill="#2D3748"
      />
      <path
        d="M143.117 39.313C145.135 25.0094 150.693 12.3308 160.941 1.80496C161.303 1.04621 161.949 0.462514 162.739 0.181922C163.528 -0.0986702 164.396 -0.0532399 165.153 0.308241L193.355 13.8285C194.11 14.1917 194.691 14.841 194.971 15.634C195.25 16.4271 195.205 17.299 194.845 18.0586L177.021 55.5667C176.66 56.3254 176.013 56.9091 175.224 57.1897C174.434 57.4703 173.566 57.4249 172.81 57.0634L144.608 43.5431C143.852 43.1799 143.271 42.5306 142.992 41.7376C142.712 40.9445 142.758 40.0726 143.117 39.313Z"
        fill="#EDF2F7"
      />
      <path
        opacity="0.2"
        d="M148.312 43.5431C147.557 43.1799 146.976 42.5305 146.696 41.7375C146.417 40.9445 146.462 40.0726 146.822 39.313C148.839 25.0093 154.398 12.3308 164.646 1.80491C164.885 1.30793 165.252 0.883402 165.708 0.574303L165.153 0.308225C164.396 -0.0532393 163.528 -0.0986651 162.739 0.181916C161.949 0.462497 161.303 1.04618 160.941 1.80491C150.693 12.3308 145.135 25.0093 143.117 39.313C142.758 40.0726 142.712 40.9445 142.992 41.7375C143.271 42.5306 143.852 43.1799 144.608 43.5431L172.81 57.0634C173.312 57.3018 173.867 57.4028 174.42 57.3561C174.973 57.3093 175.504 57.1166 175.959 56.7973L148.312 43.5431Z"
        fill="black"
      />
      <path
        d="M178.717 32.6093L159.793 23.6017C159.492 23.4584 159.259 23.2008 159.147 22.8855C159.035 22.5703 159.053 22.2233 159.195 21.9208C159.338 21.6183 159.594 21.3852 159.908 21.2727C160.222 21.1601 160.568 21.1775 160.869 21.3208L179.793 30.3283C180.093 30.4724 180.323 30.73 180.435 31.0447C180.546 31.3594 180.528 31.7056 180.386 32.0073C180.244 32.3091 179.988 32.5419 179.675 32.6547C179.362 32.7675 179.017 32.7512 178.717 32.6092L178.717 32.6093Z"
        fill="white"
      />
      <path
        d="M166.908 19.3278L162.745 17.3462C162.595 17.2758 162.461 17.1765 162.35 17.0539C162.238 16.9314 162.152 16.7881 162.096 16.6321C162.04 16.4761 162.015 16.3105 162.023 16.1449C162.031 15.9792 162.071 15.8167 162.141 15.6666C162.211 15.5165 162.31 15.3818 162.432 15.2702C162.554 15.1585 162.697 15.0721 162.852 15.016C163.008 14.9598 163.173 14.935 163.338 14.9428C163.503 14.9507 163.664 14.9911 163.814 15.0618L163.821 15.0653L167.984 17.0469C168.284 17.1909 168.515 17.4485 168.627 17.7633C168.738 18.0781 168.721 18.4244 168.578 18.7263C168.436 19.0282 168.18 19.261 167.867 19.3738C167.554 19.4865 167.209 19.47 166.908 19.3278Z"
        fill="white"
      />
      <path
        d="M173.987 42.637L155.063 33.6294C154.763 33.4855 154.532 33.2279 154.42 32.9131C154.309 32.5983 154.326 32.252 154.469 31.9501C154.611 31.6482 154.867 31.4153 155.18 31.3026C155.493 31.1898 155.838 31.2064 156.139 31.3485L175.063 40.3561C175.212 40.4266 175.346 40.5259 175.457 40.6485C175.568 40.771 175.654 40.9143 175.71 41.0702C175.766 41.2261 175.791 41.3916 175.784 41.5571C175.776 41.7227 175.736 41.8851 175.666 42.0351C175.595 42.1851 175.496 42.3197 175.374 42.4313C175.252 42.5429 175.11 42.6293 174.955 42.6855C174.799 42.7417 174.635 42.7666 174.47 42.7589C174.305 42.7512 174.143 42.7109 173.994 42.6404L173.987 42.637Z"
        fill="white"
      />
      <path
        d="M176.351 37.6216L157.427 28.6141C157.125 28.4711 156.893 28.2138 156.781 27.8987C156.668 27.5836 156.685 27.2365 156.827 26.9339C156.97 26.6313 157.226 26.3978 157.54 26.2849C157.853 26.172 158.199 26.1889 158.5 26.3319L158.503 26.3332L177.427 35.3407C177.727 35.4847 177.958 35.7423 178.069 36.0571C178.18 36.3718 178.163 36.718 178.02 37.0198C177.878 37.3217 177.622 37.5545 177.309 37.6673C176.996 37.7801 176.652 37.7636 176.351 37.6216L176.351 37.6216Z"
        fill="white"
      />
      <path
        d="M178.432 12.0399C181.326 12.0399 183.671 9.68398 183.671 6.77778C183.671 3.87157 181.326 1.51562 178.432 1.51562C175.539 1.51562 173.193 3.87157 173.193 6.77778C173.193 9.68398 175.539 12.0399 178.432 12.0399Z"
        fill="#2D3748"
      />
      <path
        d="M43.9205 120.236C53.7684 110.855 65.5494 104.202 79.8913 101.165C81.4082 100.363 83.318 100.713 84.1606 101.948L99.2292 124.098C100.068 125.335 99.5212 126.987 98.0063 127.793L62.0355 146.864C60.5187 147.666 58.6088 147.316 57.7663 146.081L42.6977 123.931C41.8586 122.694 42.4056 121.041 43.9205 120.236Z"
        fill="#EDF2F7"
      />
      <path
        opacity="0.2"
        d="M45.6975 125.566C44.8584 124.329 45.4054 122.676 46.9203 121.871C56.7682 112.49 68.5492 105.837 82.8911 102.799C83.3765 102.547 83.9113 102.405 84.4576 102.384L84.1611 101.948C83.3185 100.713 81.4087 100.363 79.8918 101.165C65.5499 104.203 53.7689 110.855 43.9211 120.237C42.4061 121.042 41.8591 122.695 42.6982 123.931L57.7667 146.081C58.0797 146.494 58.4918 146.821 58.9644 147.03C59.4371 147.24 59.9549 147.326 60.4695 147.28L45.6975 125.566Z"
        fill="black"
      />
      <path
        d="M76.5932 131.127L66.445 116.311C66.1095 115.819 66.326 115.161 66.9287 114.841C67.2021 114.682 67.5227 114.626 67.8335 114.682C68.1442 114.738 68.4251 114.903 68.6262 115.148L78.7744 129.964C79.1088 130.456 78.8907 131.114 78.2873 131.433C78.0143 131.591 77.6946 131.647 77.3846 131.591C77.0746 131.535 76.7943 131.37 76.5932 131.127L76.5932 131.127Z"
        fill="white"
      />
      <path
        d="M74.659 116.382L72.4264 113.122C72.0882 112.632 72.3015 111.973 72.9028 111.651C73.1761 111.491 73.497 111.433 73.8085 111.489C74.12 111.544 74.4018 111.709 74.6038 111.953L74.6076 111.959L76.8402 115.219C77.1746 115.711 76.9565 116.368 76.3531 116.688C76.0801 116.846 75.7603 116.902 75.4503 116.846C75.1403 116.79 74.86 116.625 74.659 116.382Z"
        fill="white"
      />
      <path
        d="M67.0049 136.241L56.8567 121.425C56.5223 120.933 56.7404 120.275 57.3437 119.955C57.6167 119.797 57.9365 119.741 58.2465 119.797C58.5565 119.853 58.8368 120.018 59.0378 120.261L69.186 135.078C69.5242 135.568 69.3109 136.226 68.7097 136.549C68.4364 136.709 68.1154 136.766 67.8039 136.711C67.4925 136.656 67.2107 136.491 67.0087 136.246L67.0049 136.241Z"
        fill="white"
      />
      <path
        d="M71.7991 133.684L61.6509 118.868C61.3139 118.377 61.5287 117.719 62.1306 117.397C62.4041 117.237 62.7251 117.18 63.0364 117.236C63.3478 117.292 63.6292 117.458 63.8306 117.703L63.8321 117.705L73.9803 132.521C74.3147 133.013 74.0966 133.671 73.4932 133.99C73.2202 134.148 72.9005 134.204 72.5905 134.148C72.2804 134.092 72.0001 133.928 71.7991 133.684L71.7991 133.684Z"
        fill="white"
      />
      <path
        d="M92.0601 116.76C94.8967 116.187 96.8102 113.794 96.3339 111.416C95.8576 109.038 93.172 107.575 90.3354 108.148C87.4987 108.721 85.5853 111.113 86.0616 113.492C86.5379 115.87 89.2235 117.333 92.0601 116.76Z"
        fill="#2D3748"
      />
      <path
        d="M65.8158 150.535C65.2298 150.21 64.7212 149.76 64.325 149.218C63.9289 148.676 63.6548 148.054 63.5216 147.395C63.3884 146.736 63.3993 146.056 63.5536 145.401C63.7079 144.747 64.0019 144.134 64.4152 143.605L53.3223 123.321L58.1538 118.859L71.1537 143.048C71.9886 143.813 72.515 144.859 72.6333 145.988C72.7516 147.117 72.4536 148.25 71.7956 149.173C71.1376 150.096 70.1655 150.744 69.0633 150.995C67.9612 151.246 66.8057 151.083 65.8158 150.535H65.8158Z"
        fill="#FFB6B6"
      />
      <path
        d="M52.8603 60.7472L40.9293 59.5909C40.3727 59.5369 39.811 59.6005 39.2803 59.7775C38.7496 59.9545 38.2616 60.2409 37.8476 60.6185C37.4336 60.9962 37.1028 61.4565 36.8765 61.9701C36.6501 62.4837 36.5332 63.0392 36.5332 63.6009V99.2621L58.358 139.456L67.3726 133.738L53.139 98.4733L52.8603 60.7472Z"
        fill="#F7B80B"
      />
      <path
        d="M242.732 253.523H-48.8691V254.477H242.732V253.523Z"
        fill="#CCCCCC"
      />
    </g>
    <defs>
      <clipPath id="clip0_1055_69834">
        <rect width="195" height="254" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
