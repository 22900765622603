import {
  BoxProps,
  Checkbox,
  Flex,
  Grid,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/react";
import { FC, ReactElement } from "react";
import { colors } from "../../ui/theme";
import ColorBoxButton from "../Buttons/ColorBoxButton";
import { DressingTopIcon } from "../Icons/DressingTopIcon";
import { DressCode } from "./DressingBlock";

const WHITE = "#fff";
const BLACK = "#000";

interface PersonalTopOutfitSelectionCardProps {
  dressCode: DressCode;
  onSetBlazer: (value: boolean) => void;
  onSetTop: (value: string | undefined) => void;
  onSetTopColor: (value: string) => void;
}
const PersonalTopOutfitSelectionCard: FC<
  PersonalTopOutfitSelectionCardProps & BoxProps
> = ({
  dressCode,
  onSetBlazer,
  onSetTop,
  onSetTopColor,
  ...props
}): ReactElement => {
  return (
    <Flex
      gap="14px"
      width="100%"
      border={`1px solid ${colors.gray.lightMode}`}
      padding="14px"
      borderRadius="12px"
      {...props}
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap="14px"
      >
        <DressingTopIcon />
        <Text variant="xl" fontWeight="500">
          Haut
        </Text>
      </Flex>
      <Flex direction="column" width="100%" ml="0.5rem">
        <Grid alignItems="center" gridTemplateColumns="80% 20%" mb="0.5rem">
          <Checkbox
            display="flex"
            isChecked={dressCode?.personalOutfit?.custom?.blazer}
            onChange={(v) => onSetBlazer(v.currentTarget.checked)}
            gridColumn={1}
          >
            Blaser
          </Checkbox>
          <ColorBoxButton
            buttonColor={BLACK}
            isActive={dressCode?.personalOutfit?.custom?.blazer}
          />
        </Grid>
        <RadioGroup
          onChange={onSetTop}
          value={dressCode?.personalOutfit?.custom?.top ?? ""}
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          gap="0.5rem"
        >
          <Grid width="100%" templateColumns="60% 20% 20%" alignItems="center">
            <Radio value="Chemise" key={1} gridColumn={1}>
              <Text variant="lg" whiteSpace="nowrap">
                Chemise
              </Text>
            </Radio>
            <ColorBoxButton
              buttonColor={WHITE}
              isActive={
                dressCode?.personalOutfit?.custom?.top === "Chemise" &&
                dressCode?.personalOutfit?.custom?.topColor === WHITE
              }
              onClick={() => onSetTopColor(WHITE)}
            />
            <ColorBoxButton
              buttonColor={BLACK}
              isActive={
                dressCode?.personalOutfit?.custom?.top === "Chemise" &&
                dressCode?.personalOutfit?.custom?.topColor === BLACK
              }
              onClick={() => onSetTopColor(BLACK)}
            />
          </Grid>

          <Grid width="100%" templateColumns="60% 20% 20%" alignItems="center">
            <Radio value="T-shirt" key={2} gridColumn={1}>
              <Text variant="lg" whiteSpace="nowrap">
                T-shirt
              </Text>
            </Radio>
            <ColorBoxButton
              buttonColor={WHITE}
              isActive={
                dressCode?.personalOutfit?.custom?.top === "T-shirt" &&
                dressCode?.personalOutfit?.custom?.topColor === WHITE
              }
              onClick={() => onSetTopColor(WHITE)}
            />
            <ColorBoxButton
              buttonColor={BLACK}
              isActive={
                dressCode?.personalOutfit?.custom?.top === "T-shirt" &&
                dressCode?.personalOutfit?.custom?.topColor === BLACK
              }
              onClick={() => onSetTopColor(BLACK)}
            />
          </Grid>
        </RadioGroup>
      </Flex>
    </Flex>
  );
};

export default PersonalTopOutfitSelectionCard;
