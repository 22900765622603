export const CinemaIcon = () => (
  <svg
    width="131"
    height="121"
    viewBox="0 0 131 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_946_48239)">
      <path
        d="M62.67 61.2006C63.754 59.8276 65.341 59.2739 66.2147 59.9638C67.0884 60.6538 66.9178 62.3259 65.8333 63.6993C65.4077 64.2538 64.8554 64.6984 64.2226 64.9957L59.5565 70.7554L56.9004 68.5081L61.7823 63.0687C61.9248 62.384 62.2292 61.7435 62.6701 61.2006H62.67V61.2006V61.2006Z"
        fill="#FFB6B6"
      />
      <path
        d="M45.4753 58.7364L52.2835 70.3066L60.5255 64.6131L63.8408 65.9356C63.8408 65.9356 57.9837 78.6836 52.9335 77.8545C47.8833 77.0253 37.8344 65.618 38.9879 61.6344C40.1414 57.6508 45.4753 58.7364 45.4753 58.7364H45.4753V58.7364V58.7364Z"
        fill="#F7B80B"
      />
      <path
        d="M65.9976 55.1712L62.1934 56.2947L64.8148 65.1714L68.6191 64.0479L65.9976 55.1712Z"
        fill="#F7B80B"
      />
      <path
        d="M44.6194 59.0247L44.7008 53.5991L41.355 51.5134L37.7168 55.9637L44.6194 59.0247Z"
        fill="#FFB6B6"
      />
      <path
        opacity="0.1"
        d="M44.6199 59.0247L44.8365 53.7343L41.3555 51.5134L42.5381 58.0606L44.6199 59.0247Z"
        fill="black"
      />
      <path
        d="M42.8293 54.1398C46.1156 54.1398 48.7797 51.4757 48.7797 48.1894C48.7797 44.9031 46.1156 42.239 42.8293 42.239C39.543 42.239 36.8789 44.9031 36.8789 48.1894C36.8789 51.4757 39.543 54.1398 42.8293 54.1398Z"
        fill="#FFB6B6"
      />
      <path
        d="M52.7467 94.9013L51.6983 102.028L51.1713 105.617L48.9764 120.551C46.2073 120.464 43.474 120.339 40.7823 120.182L41.1733 111.089L41.4094 105.617L41.5643 102.028L41.6607 99.769L40.2497 94.3799C40.2497 94.3799 39.785 93.5167 39.3127 92.3663C39.2296 92.1623 39.1446 91.9489 39.0634 91.7298C38.4778 90.1639 38.0132 88.2995 38.5969 87.3248C38.6252 87.2795 38.6535 87.2361 38.6856 87.1945C38.9501 86.8526 39.6358 86.5976 40.567 86.4087C41.1356 86.2916 41.7986 86.2009 42.5126 86.1311C44.6735 85.9176 47.3123 85.8855 49.3315 85.9063C51.1581 85.9214 52.4766 85.978 52.4766 85.978L52.4955 86.5806L52.4974 86.6392L52.5106 87.0963L52.5427 88.1881L52.6239 90.8854L52.707 93.6263L52.7467 94.9013Z"
        fill="#2F2E41"
      />
      <path
        d="M52.706 93.6263L49.7649 99.5254L47.9043 102.028L45.2391 105.617L41.1723 111.089L34.7235 119.769C31.6219 119.527 28.5808 119.238 25.6133 118.909L33.5071 105.617L35.6377 102.028L37.6948 98.5658L39.0019 93.1503C39.0019 93.1503 39.0019 92.8613 39.0227 92.3985C39.0321 92.204 39.0453 91.9773 39.0623 91.7299C39.1776 90.0771 39.527 87.442 40.5659 86.4088C40.7057 86.2671 40.8587 86.1576 41.0249 86.082C41.3366 85.9422 41.856 85.9762 42.5115 86.1311C44.1322 86.5184 46.5802 87.6593 48.7789 88.7926C50.2995 89.5784 51.6992 90.3585 52.6228 90.8855L52.706 93.6263Z"
        fill="#2F2E41"
      />
      <path
        d="M38.4825 54.8157L45.2423 58.0604C45.2423 58.0604 52.4077 79.2862 52.4077 80.3677C52.4077 81.4493 54.4357 82.1253 53.2189 83.342C52.0021 84.5588 55.4836 85.5893 53.6077 86.5612C51.7317 87.5331 40.0293 92.8494 38.728 92.37C35.1391 91.0478 38.8801 86.1892 38.8801 86.1892C38.8801 86.1892 36.1842 84.018 36.5898 82.9365C36.9954 81.8549 34.1562 61.1699 34.1562 61.1699L38.4825 54.8157L38.4825 54.8157V54.8157V54.8157Z"
        fill="#F7B80B"
      />
      <path
        d="M43.3297 49.5721L44.8231 50.4078C44.8231 50.4078 43.9072 47.6989 44.5757 46.5041C45.2442 45.3094 44.8545 44.4638 44.8545 44.4638C44.8545 44.4638 47.2391 45.2489 47.8671 44.2668C48.4951 43.2847 48.7027 42.7734 46.6574 41.9427C44.6121 41.112 45.3816 41.6994 45.3816 41.6994C45.3816 41.6994 43.6349 41.0359 44.0197 41.3296C44.4044 41.6233 42.5717 40.8332 42.8299 41.2129C43.088 41.5927 41.6806 40.8836 41.9388 41.2634C42.1969 41.6432 38.678 42.1843 39.402 42.4325C40.126 42.6807 36.435 42.9687 36.5458 45.8547C36.6567 48.7406 34.6468 49.5592 36.13 50.398C37.6132 51.2369 39.6834 54.8269 39.6834 54.8269L41.4292 53.6402C41.4292 53.6402 40.9046 48.9996 43.3297 49.5721H43.3297V49.5721V49.5721Z"
        fill="#2F2E41"
      />
      <path
        d="M30.6527 80.4937C32.3875 80.2689 33.6778 79.1916 33.5346 78.0876C33.3914 76.9836 31.8691 76.2711 30.1337 76.4961C29.4392 76.5765 28.7741 76.822 28.194 77.2121L20.8555 78.2572L21.4193 81.6905L28.5939 80.2957C29.2545 80.5252 29.9605 80.5931 30.6527 80.4937V80.4937Z"
        fill="#A0616A"
      />
      <path
        d="M8.21813 65.4278L10.231 80.4122C10.231 80.4122 10.7847 83.6349 15.8246 83.1604C20.8645 82.6859 27.4877 80.7313 27.4877 80.7313L27.0567 77.2322L21.4275 77.842C21.4275 77.842 18.7998 77.2046 18.2782 77.6345C17.7565 78.0644 17.9961 78.5677 17.4802 77.9417C16.9643 77.3157 17.2234 76.927 16.7717 77.4745C16.32 78.0219 16.2282 78.9735 15.9577 77.7949C15.6872 76.6163 15.1838 76.856 15.1838 76.856L14.8975 65.8796C14.8975 65.8796 13.3854 60.6437 10.6235 62.3943C7.86161 64.1449 8.21814 65.4278 8.21814 65.4278H8.21813V65.4278H8.21813Z"
        fill="#E6E6E6"
      />
      <path
        d="M17.0412 53.4724H7.07227V68.2027H17.0412V53.4724Z"
        fill="#2F2E41"
      />
      <path
        d="M9.30859 63.7112L9.65771 59.4922H14.2997L15.3288 63.9621L9.30859 63.7112Z"
        fill="#A0616A"
      />
      <path
        opacity="0.1"
        d="M9.30859 63.7112L9.65771 59.4922H14.2997L15.3288 63.9621L9.30859 63.7112Z"
        fill="black"
      />
      <path
        d="M17.7186 56.5735C18.1675 53.8073 16.289 51.201 13.5228 50.7521C10.7567 50.3033 8.15034 52.1818 7.70146 54.9479C7.25257 57.7141 9.1311 60.3204 11.8973 60.7693C14.6634 61.2182 17.2698 59.3397 17.7186 56.5735Z"
        fill="#A0616A"
      />
      <path
        d="M13.9902 50.4223C13.9902 50.4223 13.9902 48.3392 12.0559 48.7856C10.1216 49.232 9.52648 49.9759 9.52648 49.9759C9.52648 49.9759 8.78253 51.1663 7.59221 51.315C6.40188 51.4638 5.36035 54.886 5.50914 55.63C5.65793 56.3739 6.40188 58.3082 5.36035 59.201C4.31881 60.0937 2.8309 61.4328 3.27728 62.7719C3.72365 64.111 5.06277 67.5332 5.06277 67.5332C5.06277 67.5332 1.32777 68.0792 1.63297 70.41C1.63297 70.41 3.42607 69.0211 5.80672 69.0211C8.18737 69.0211 7.88979 64.7021 7.88979 64.7021C7.88979 64.7021 8.03858 62.028 8.48495 61.284C8.93133 60.5401 8.33616 56.3739 10.568 54.1421C12.7999 51.9102 13.9902 50.4223 13.9902 50.4223H13.9902Z"
        fill="#2F2E41"
      />
      <path
        d="M13.3963 49.9761C13.3963 49.9761 16.2233 50.5712 17.2648 53.3983C18.3064 56.2253 17.7803 56.7513 17.7803 56.7513C17.7803 56.7513 18.0106 58.4536 19.2744 59.0506C20.5382 59.6475 19.4967 62.1769 19.4967 62.1769C19.4967 62.1769 19.7943 64.7064 23.8116 65.5991C27.829 66.4918 18.7738 73.3378 18.7738 73.3378C18.7738 73.3378 19.4248 70.1067 15.2587 69.9579C11.0926 69.8092 16.0745 62.1769 16.0745 62.1769L16.5209 57.2668C16.5209 57.2668 15.6282 51.6128 12.9499 50.8688C10.2717 50.1249 13.3963 49.9761 13.3963 49.9761L13.3963 49.9761H13.3963Z"
        fill="#2F2E41"
      />
      <path
        d="M20.5988 95.0373L19.5712 102.028L19.0442 105.617L18.1508 111.688L17.246 117.84C16.175 117.685 15.1172 117.525 14.0726 117.359C12.2782 117.073 10.5215 116.773 8.80255 116.456L9.13689 108.649L9.26723 105.617L9.42212 102.028L9.51279 99.9031L8.09988 94.5141C8.09988 94.5141 5.18529 89.0872 6.53775 87.3305C6.60009 87.2512 6.6832 87.1756 6.78898 87.1058C7.13276 86.8734 7.69944 86.6883 8.41156 86.5447C10.0946 86.201 12.5879 86.0763 14.8603 86.0442C17.7768 86.0026 20.3268 86.1141 20.3268 86.1141L20.3419 86.558L20.3703 87.5213L20.5988 95.0373Z"
        fill="#2F2E41"
      />
      <path
        d="M20.5992 95.0374L19.5717 102.028L19.0447 105.617L18.1512 111.688L14.0731 117.359C12.2786 117.073 10.5219 116.773 8.80299 116.456C7.53932 116.223 6.2983 115.982 5.07617 115.732L9.13733 108.649L10.8751 105.617L12.9303 102.028L13.9182 100.306L14.7776 98.8058L15.4066 96.0102L15.8902 93.8663L16.0016 93.3714C16.0016 93.3714 15.9903 92.5931 16.064 91.5486C16.1773 89.9657 16.489 87.767 17.3465 86.7337C17.5128 86.5297 17.7017 86.3729 17.9151 86.2728C18.3609 86.0632 19.2411 86.2067 20.3424 86.5581L20.3707 87.5214L20.5993 95.0374L20.5992 95.0374Z"
        fill="#2F2E41"
      />
      <path
        d="M15.6642 63.7387L15.5404 63.7038L9.44518 61.9812L4.57812 68.6058L7.82283 82.3958L6.60607 87.9388C6.60607 87.9388 16.4754 85.3701 20.3961 87.5332C20.3961 87.5332 20.6665 86.3005 20.3961 85.7001C20.1257 85.0997 19.7201 82.6012 20.1257 81.4845C20.5313 80.3679 19.3145 75.0952 19.3145 75.0952C19.3145 75.0952 19.7201 71.5801 17.9625 69.8226C16.205 68.065 15.6642 63.7387 15.6642 63.7387L15.6642 63.7387V63.7387Z"
        fill="#E6E6E6"
      />
      <path
        d="M13.9714 100.525C14.4085 102.219 15.6367 103.367 16.7147 103.088C17.7926 102.81 18.3118 101.212 17.8744 99.5171C17.7089 98.8379 17.3832 98.2082 16.9245 97.6806L14.982 90.5271L11.6445 91.5102L13.9138 98.4579C13.7676 99.1418 13.7874 99.8507 13.9714 100.525V100.525H13.9714Z"
        fill="#A0616A"
      />
      <path
        d="M14.8566 69.8276C14.8566 69.8276 12.9662 64.7191 10.8944 65.0517C8.82251 65.3843 6.93359 68.5954 6.93359 68.5954L8.43134 86.768L12.5453 97.1384L16.9091 95.418L14.6424 85.054L14.8566 69.8276V69.8276V69.8276Z"
        fill="#E6E6E6"
      />
      <path
        opacity="0.7"
        d="M109.372 38.2773V116.597C109.31 116.611 109.247 116.622 109.183 116.631C108.225 116.805 107.254 116.971 106.272 117.134C105.105 117.326 103.921 117.513 102.721 117.693C101.724 117.84 100.717 117.984 99.6988 118.122C96.7351 118.528 93.6883 118.889 90.5678 119.202C89.5686 119.304 88.5599 119.4 87.5456 119.491C78.6771 120.288 69.2514 120.719 59.4782 120.719C58.0936 120.719 56.7185 120.709 55.349 120.692C55.2848 120.692 55.2225 120.692 55.1602 120.691V38.2773H109.372Z"
        fill="#F2F2F2"
      />
      <path
        d="M109.182 64.1159V64.3483L109.207 64.2992C109.205 64.2387 109.196 64.1783 109.182 64.1159ZM55.1596 88.7927L54.9707 88.6397V105.617H55.1596V120.691C55.2219 120.693 55.2843 120.693 55.3485 120.693V77.3421C55.2861 77.3761 55.2238 77.4082 55.1596 77.4403V88.7927ZM54.9707 38.0886V120.687C55.033 120.689 55.0954 120.691 55.1596 120.691C55.2219 120.693 55.2843 120.693 55.3485 120.693V38.4664H109.182V116.631C109.247 116.622 109.309 116.611 109.371 116.597C109.434 116.588 109.498 116.577 109.56 116.563V38.0886H54.9707Z"
        fill="#3F3D56"
      />
      <path
        d="M59.7882 97.4957C61.7703 97.4957 63.3771 95.8889 63.3771 93.9068C63.3771 91.9247 61.7703 90.3179 59.7882 90.3179C57.806 90.3179 56.1992 91.9247 56.1992 93.9068C56.1992 95.8889 57.806 97.4957 59.7882 97.4957Z"
        fill="#3F3D56"
      />
      <path
        d="M59.3242 92.9123C60.6125 94.0957 62.2674 94.3902 63.0204 93.5701C63.7734 92.7501 63.3393 91.1264 62.0503 89.9428C61.5419 89.4629 60.926 89.1117 60.2541 88.9187L54.7321 83.9736L52.4668 86.6144L58.151 91.209C58.4004 91.8623 58.8027 92.4464 59.3242 92.9123H59.3242V92.9123Z"
        fill="#FFB6B6"
      />
      <path
        d="M43.3639 66.6746C43.3639 66.6746 38.9983 63.4169 37.4409 64.8233C35.8835 66.2296 36.0458 69.9516 36.0458 69.9516L47.1901 84.3839L56.2843 90.8465L59.0096 87.0287L51.4688 79.5664L43.3639 66.6746L43.3639 66.6746V66.6746Z"
        fill="#F7B80B"
      />
      <path
        d="M90.5672 99.1948V119.202C89.5679 119.304 88.5593 119.401 87.5449 119.491V99.1948H90.5672Z"
        fill="#3F3D56"
      />
      <path
        d="M5.36796 99.1948V108.28C4.31205 107.814 3.30337 107.334 2.3457 106.845V99.1948H5.36796Z"
        fill="#3F3D56"
      />
      <path
        d="M89.638 101.586H3.54102V105.174H89.638V101.586Z"
        fill="#F7B80B"
      />
      <path
        d="M113.835 48.9827C113.835 48.9827 111.821 50.1643 112.481 51.3328C113.142 52.5013 114.063 54.093 113.438 54.5943C112.813 55.0956 111.782 57.3058 111.782 57.3058C111.782 57.3058 107.378 56.6907 107.791 60.1117C108.205 63.5327 110.273 63.9991 108.5 65.5041C106.727 67.0091 110.343 68.809 110.343 68.809C110.343 68.809 112.84 68.031 113.409 65.6386C113.979 63.2461 112.902 67.4679 113.263 67.7706C113.624 68.0733 115.027 70.0686 116.02 67.4158C117.013 64.7629 116.03 63.7344 117.341 62.1687C118.653 60.603 119.545 58.0706 119.506 57.6279C119.467 57.1852 121.006 53.1457 118.637 51.5703C116.267 49.9949 113.835 48.9827 113.835 48.9827H113.835Z"
        fill="#2F2E41"
      />
      <path
        d="M96.7337 86.2898C95.7236 87.4279 94.3176 87.8292 93.5935 87.1863C92.8693 86.5433 93.1012 85.0998 94.1118 83.9614C94.5092 83.5012 95.0111 83.1428 95.5755 82.9163L99.9161 78.1482L102.11 80.2328L97.5981 84.712C97.4401 85.2994 97.1437 85.8404 96.7337 86.2898H96.7337Z"
        fill="#FFB6B6"
      />
      <path
        d="M109.427 63.8559C109.427 63.8559 112.06 61.4376 113.17 62.2517C114.281 63.0658 114.424 65.5066 114.424 65.5066L108.112 75.673L99.0088 83.3458L96.9746 81.0354L104.997 72.8136L109.427 63.8559V63.8559V63.8559Z"
        fill="#3F3D56"
      />
      <path
        d="M114.762 58.3767L113.336 63.4455H121.163L118.586 57.2L114.762 58.3767Z"
        fill="#FFB6B6"
      />
      <path
        opacity="0.1"
        d="M114.762 58.3767L113.336 63.4455H121.163L118.586 57.2L114.762 58.3767Z"
        fill="black"
      />
      <path
        d="M118.731 75.104L123.321 85.9461L107.789 85.1791L108.845 75.104H118.731Z"
        fill="#FFB6B6"
      />
      <path
        d="M114.24 60.8502L120.716 60.5803L125.034 68.1361L121.256 80.2793L108.033 81.6285L109.465 69.9175L110.192 63.2788L114.24 60.8502Z"
        fill="#3F3D56"
      />
      <path
        d="M125.034 96.1178C124.723 97.627 124.384 99.6954 124.054 101.937C123.882 103.105 123.712 104.319 123.55 105.526C123.13 108.622 122.756 111.669 122.509 113.743C120.454 114.276 118.317 114.784 116.105 115.264L114.963 105.526L114.541 101.937L114.105 98.2239L113.909 101.937L113.718 105.526L113.17 115.876C111.986 116.114 110.782 116.342 109.56 116.563C109.498 116.577 109.434 116.588 109.371 116.597C109.309 116.611 109.247 116.622 109.182 116.631C108.225 116.805 107.254 116.971 106.272 117.134L106.109 105.526L106.058 101.937L105.875 88.9153L107.471 83.6415L107.953 83.598L109.182 83.4866L109.371 83.4696L109.56 83.4507L121.793 82.34L122.201 82.3022C122.201 82.3022 122.492 82.8708 122.892 83.8172C122.926 83.8965 122.962 83.9796 122.998 84.0646C123.344 84.8957 123.755 85.9743 124.131 87.1926C124.628 88.7944 125.064 90.638 125.21 92.4721C125.308 93.7169 125.274 94.9561 125.034 96.1178Z"
        fill="#2F2E41"
      />
      <path
        d="M125.513 91.1944C125.399 92.7118 124.524 93.8831 123.558 93.8105C122.593 93.738 121.902 92.4492 122.016 90.9312C122.053 90.3242 122.236 89.735 122.547 89.2128L123.11 82.7896L126.119 83.1181L125.244 89.4154C125.475 89.9785 125.567 90.5885 125.513 91.1944Z"
        fill="#FFB6B6"
      />
      <path
        d="M122.014 65.6575C122.014 65.6575 122.642 62.1385 124.019 62.1131C125.396 62.0876 126.988 63.9427 126.988 63.9427L128.129 75.8545L125.537 87.4745L122.519 86.8694L123.918 75.4678L122.014 65.6575L122.014 65.6575V65.6575Z"
        fill="#3F3D56"
      />
      <path
        d="M116.622 59.4782C119.225 59.4782 121.335 57.3679 121.335 54.7646C121.335 52.1614 119.225 50.051 116.622 50.051C114.019 50.051 111.908 52.1614 111.908 54.7646C111.908 57.3679 114.019 59.4782 116.622 59.4782Z"
        fill="#FFB6B6"
      />
      <path
        d="M114.815 49.5011C114.815 49.5011 114.029 51.7008 115.286 52.1721C116.543 52.6435 118.271 53.272 118.114 54.0576C117.957 54.8432 118.586 57.2 118.586 57.2C118.586 57.2 114.815 59.5568 117.329 61.9136C119.843 64.2704 121.728 63.2992 121.335 65.5917C120.942 67.8841 124.87 66.9414 124.87 66.9414C124.87 66.9414 126.284 64.7417 125.185 62.542C124.085 60.3424 125.97 64.2704 126.442 64.2704C126.913 64.2704 129.27 64.8988 128.327 62.2278C127.384 59.5568 125.97 59.3996 125.97 57.3571C125.97 55.3145 125.028 52.8006 124.713 52.4864C124.399 52.1721 122.985 48.087 120.157 48.4013C117.329 48.7155 114.815 49.5011 114.815 49.5011H114.815V49.5011V49.5011Z"
        fill="#2F2E41"
      />
      <path
        d="M93.7086 79.7449L89.9043 80.8684L92.5258 89.7451L96.33 88.6216L93.7086 79.7449Z"
        fill="#F7B80B"
      />
      <path
        d="M31.3746 72.1893L27.5703 73.3127L30.1918 82.1894L33.9961 81.0659L31.3746 72.1893Z"
        fill="#F7B80B"
      />
      <path
        d="M102.721 99.104V117.693C101.724 117.84 100.717 117.984 99.6992 118.122V99.104H102.721Z"
        fill="#3F3D56"
      />
      <path
        d="M140.371 101.937H101.777V105.526H140.371V101.937Z"
        fill="#F7B80B"
      />
    </g>
    <defs>
      <clipPath id="clip0_946_48239">
        <rect
          width="129.643"
          height="120"
          fill="white"
          transform="translate(0.900391 0.718994)"
        />
      </clipPath>
    </defs>
  </svg>
);
