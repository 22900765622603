import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../../config";
import CardFields from "./CardFields";

const Pay = () => {
  return (
    <Elements stripe={stripePromise}>
      <CardFields />
    </Elements>
  );
};

export default Pay;
