export const SalonIcon = () => (
  <svg width="156" height="121" viewBox="0 0 156 121" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_946_3896)">
      <path
        d="M69.3494 28.0053C62.9687 28.0102 56.6323 26.9466 50.603 24.8585C42.5201 22.0638 35.8984 18.2091 30.9219 13.4013C21.5084 4.3069 13.7907 2.93633 8.97495 3.38763C3.76198 3.87638 0.727637 6.5458 0.697611 6.57271L0.490234 6.34153C0.520866 6.31401 3.61777 3.58401 8.92581 3.08032C12.0386 2.78515 15.2937 3.29945 18.6011 4.60944C22.7255 6.24304 26.9435 9.1259 31.1377 13.1779C36.0814 17.9541 42.6646 21.7852 50.7045 24.5649C65.4639 29.6678 82.0983 28.4323 96.3425 21.1752C101.059 18.7722 105.72 17.132 110.197 16.3002C115.842 15.2509 121.196 15.4945 126.11 17.0235C136.91 20.3834 144.287 20.2053 148.573 19.4645C153.199 18.6651 155.297 17.0652 155.318 17.0491L155.508 17.2943C155.421 17.3618 153.327 18.958 148.626 19.7705C144.306 20.5173 136.876 20.6983 126.018 17.32C115.024 13.8999 103.889 17.679 96.4836 21.4518C89.4432 25.0578 81.7554 27.2266 73.8684 27.8317C72.3567 27.9474 70.8503 28.0052 69.3494 28.0053Z"
        fill="#3F3D56"/>
      <path
        d="M68.7215 31.859C70.4597 31.859 71.8688 30.4499 71.8688 28.7117C71.8688 26.9735 70.4597 25.5645 68.7215 25.5645C66.9833 25.5645 65.5742 26.9735 65.5742 28.7117C65.5742 30.4499 66.9833 31.859 68.7215 31.859Z"
        fill="#F7B80B"/>
      <path
        d="M117.481 19.2811C119.219 19.2811 120.629 17.872 120.629 16.1338C120.629 14.3957 119.219 12.9866 117.481 12.9866C115.743 12.9866 114.334 14.3957 114.334 16.1338C114.334 17.872 115.743 19.2811 117.481 19.2811Z"
        fill="#F7B80B"/>
      <path
        d="M16.0809 7.01354C17.8191 7.01354 19.2281 5.60445 19.2281 3.86626C19.2281 2.12808 17.8191 0.718994 16.0809 0.718994C14.3427 0.718994 12.9336 2.12808 12.9336 3.86626C12.9336 5.60445 14.3427 7.01354 16.0809 7.01354Z"
        fill="#F7B80B"/>
      <path
        d="M91.293 75.9517V106.361H96.0743V78.6292H115.199V106.361H119.981V45.5427H115.199C113.615 45.5427 111.183 53.7944 111.183 62.086C111.183 68.3358 111.913 73.692 112.951 75.9517H91.293ZM115.199 75.9517H115.153C115.169 75.9177 115.184 75.8818 115.199 75.8464V75.9517Z"
        fill="#3F3D56"/>
      <path
        d="M109.011 30.4915H109.011C105.086 30.4915 101.904 34.0223 101.904 38.3778V43.9115H103.606L104.592 41.8612L104.345 43.9115H115.284L116.18 42.0476L115.956 43.9115H117.188V39.5665C117.188 34.5545 113.527 30.4915 109.011 30.4915Z"
        fill="#2F2E41"/>
      <path
        d="M100.869 66.6458L96.5057 68.5001L81.3437 80.826C80.7862 81.5111 80.4445 82.3463 80.362 83.2257C80.2529 84.6438 75.6716 92.0611 77.3078 93.0428C78.944 94.0246 84.8342 96.9697 85.1615 95.0063C85.4887 93.0429 87.234 84.6438 87.234 84.6438L101.851 71.5543L100.869 66.6458Z"
        fill="#2F2E41"/>
      <path
        d="M83.7423 93.3702L81.8879 98.8241C81.8879 98.8241 83.8514 100.897 82.8696 102.642V108.641H81.3425L80.9062 105.478C80.9062 105.478 79.1668 108.601 78.2883 108.641C76.4467 108.725 72.7996 109.38 72.6905 108.398C72.5815 107.417 75.4523 106.46 75.4523 106.46L78.5065 98.0605L79.8154 92.9338L83.7423 93.3702Z"
        fill="#2F2E41"/>
      <path
        d="M112.213 65.2277C112.213 65.2277 113.194 74.8267 110.358 76.5719C107.522 78.3172 94.9781 83.8802 94.9781 83.8802C94.9781 83.8802 100.323 88.8979 99.2322 90.0977C98.1414 91.2976 93.2329 94.57 92.5784 93.9155C91.9239 93.261 84.6156 85.4073 85.4883 82.8985C86.3609 80.3897 98.4686 72.2088 98.4686 72.2088L99.8867 67.0821L100.323 62.9371L104.904 59.9919L112.213 65.2277Z"
        fill="#2F2E41"/>
      <path
        d="M107.215 42.8791C109.727 42.8791 111.763 40.8432 111.763 38.3317C111.763 35.8203 109.727 33.7844 107.215 33.7844C104.704 33.7844 102.668 35.8203 102.668 38.3317C102.668 40.8432 104.704 42.8791 107.215 42.8791Z"
        fill="#FFB9B9"/>
      <path
        d="M110.74 40.1943C110.74 40.1943 110.522 44.3393 113.903 45.6483C117.284 46.9572 105.504 55.4654 105.504 55.4654V45.9755C105.504 45.9755 107.031 44.3393 105.504 41.8305L110.74 40.1943Z"
        fill="#FFB9B9"/>
      <path
        d="M117.994 59.6648L117.121 63.9189L114.503 78.5355C114.503 78.5355 116.685 84.7529 113.739 84.6439C110.794 84.5348 112.212 77.881 112.212 77.881L113.739 65.7732V61.0828L117.994 59.6648Z"
        fill="#FFB9B9"/>
      <path
        d="M99.2308 57.5923L98.2491 61.41L93.7769 72.2089C93.7769 72.2089 90.7227 77.3356 93.2315 77.2265C95.7403 77.1174 95.7403 72.2089 95.7403 72.2089L100.867 63.4825V58.574L99.2308 57.5923Z"
        fill="#FFB9B9"/>
      <path
        d="M98.469 89.4434L102.069 92.8248C102.069 92.8248 104.577 92.2794 105.559 95.2245C106.541 98.1697 107.195 100.024 107.195 100.024L105.886 100.788L104.25 98.4969C104.25 98.4969 103.269 102.642 102.287 102.969C101.305 103.296 96.3965 105.478 96.8329 102.969L99.2326 99.4786C99.2326 99.4786 99.5598 95.5518 98.5781 95.2245C97.5964 94.8973 94.9785 91.6249 94.9785 91.6249L98.469 89.4434Z"
        fill="#2F2E41"/>
      <path
        d="M112.386 68.6093C112.386 68.6093 102.132 64.9006 101.478 65.2279C100.991 65.4431 100.472 65.5777 99.9414 65.6266L99.9507 64.1371C99.9507 64.1371 103.332 62.9372 105.186 63.7007C107.041 64.4643 112.713 67.8457 112.713 67.8457L112.386 68.6093Z"
        fill="#3F3D56"/>
      <path
        d="M106.158 53.2836C106.158 53.2836 109.103 49.9022 109.975 47.8297C110.527 46.5733 111.541 45.5768 112.806 45.0459C112.806 45.0459 119.356 46.5208 119.247 49.1386C119.138 51.7565 115.102 55.7925 115.102 55.7925C115.102 55.7925 115.647 70.3 112.702 70.0818C112.067 69.3237 111.619 68.427 111.393 67.4639C111.066 65.9368 100.922 64.3006 100.922 64.3006C100.922 64.3006 100.049 65.8277 99.9401 65.1732C99.831 64.5188 100.813 48.4842 100.813 48.4842L106.014 45.0459L106.158 53.2836Z"
        fill="#D0CDE1"/>
      <path
        d="M118.408 48.0849L118.481 48.1259C118.714 48.2567 118.908 48.4472 119.043 48.6779C119.178 48.9086 119.25 49.1711 119.25 49.4384L119.248 57.5335C119.249 57.7535 119.298 57.9708 119.391 58.1701C119.484 58.3694 119.62 58.5461 119.788 58.6879C120.208 59.0453 120.598 59.5603 119.794 59.8283C118.485 60.2647 113.358 62.4462 113.249 61.6827C113.14 60.9191 112.922 60.0465 113.249 60.0465C113.505 60.0465 115.032 52.2246 116.258 48.8803C116.333 48.6756 116.451 48.4895 116.605 48.3351C116.759 48.1807 116.945 48.0615 117.149 47.9859C117.354 47.9102 117.572 47.8799 117.79 47.897C118.007 47.9141 118.218 47.9782 118.408 48.0849Z"
        fill="#D0CDE1"/>
      <path
        d="M102.285 48.1025L100.758 48.5389L99.0126 56.0653C99.0126 56.0653 96.9401 56.5016 97.5946 57.2652C98.2491 58.0287 100.976 59.9921 100.976 59.9921L102.285 48.1025Z"
        fill="#D0CDE1"/>
      <path
        d="M111.031 32.6639C110.689 32.2047 110.246 31.8305 109.736 31.5703C109.226 31.31 108.663 31.1708 108.09 31.1633H107.951C105.261 31.1633 103.08 33.5974 103.08 36.6001V36.6001H103.981L104.127 35.4921L104.34 36.6001H109.686L110.134 35.6627L110.022 36.6001H111.074C111.564 39.0372 110.859 41.4744 108.958 43.9116H110.75L111.646 42.0368L111.422 43.9116H114.837L115.509 39.5997C115.509 36.3704 113.632 33.6321 111.031 32.6639Z"
        fill="#2F2E41"/>
      <path
        d="M19.673 82.9779C20.8205 80.4794 21.6276 74.5573 21.6276 67.647C21.6276 58.4793 18.9387 49.3557 17.1869 49.3557H11.9004V116.6H17.1869V85.9383H38.3329V116.6H43.6194V82.9779H19.673ZM17.1869 82.8615C17.204 82.9006 17.221 82.9403 17.2383 82.9779H17.1869V82.8615Z"
        fill="#3F3D56"/>
      <path
        d="M41.7082 70.7102C41.7082 70.7102 48.0749 73.35 46.0562 74.9029C44.0375 76.4557 39.8448 73.0395 40 72.5736C40.1553 72.1078 39.3789 71.0208 39.3789 71.0208L41.7082 70.7102Z"
        fill="#9F616A"/>
      <path
        d="M36.1182 49.9021L38.1369 51.7655L38.6028 57.2005L40.1556 62.946C40.1556 62.946 42.7955 68.381 42.3296 69.468C41.8638 70.555 42.7955 70.8656 42.7955 70.8656L39.2239 72.2631C39.2239 72.2631 35.3418 67.9151 35.3418 66.9834C35.3418 66.0517 36.1182 49.9021 36.1182 49.9021Z"
        fill="#575A88"/>
      <path opacity="0.1"
            d="M36.1182 49.9021L38.1369 51.7655L38.6028 57.2005L40.1556 62.946C40.1556 62.946 42.7955 68.381 42.3296 69.468C41.8638 70.555 42.7955 70.8656 42.7955 70.8656L39.2239 72.2631C39.2239 72.2631 35.3418 67.9151 35.3418 66.9834C35.3418 66.0517 36.1182 49.9021 36.1182 49.9021Z"
            fill="black"/>
      <path
        d="M55.2186 105.805C55.2186 105.805 54.4422 109.376 54.2869 109.687C54.1316 109.997 57.2373 111.084 57.2373 111.084L60.9641 108.445C60.9641 108.445 59.4113 105.028 59.4113 104.407L55.2186 105.805Z"
        fill="#575A88"/>
      <path
        d="M34.8749 71.0208L35.9619 72.263C35.9619 72.263 58.4782 75.0582 58.9441 79.4061C59.4099 83.7541 62.6709 104.252 60.8075 104.873C58.9441 105.494 54.1302 107.823 54.1302 106.581C54.1302 105.339 51.0245 88.2574 51.6457 85.1517C51.6457 85.1517 24.7814 81.7354 24.1602 77.8533C23.5391 73.9712 24.7814 72.263 24.7814 72.263L34.8749 71.0208Z"
        fill="#2F2E41"/>
      <path opacity="0.1"
            d="M34.8749 71.0208L35.9619 72.263C35.9619 72.263 58.4782 75.0582 58.9441 79.4061C59.4099 83.7541 62.6709 104.252 60.8075 104.873C58.9441 105.494 54.1302 107.823 54.1302 106.581C54.1302 105.339 51.0245 88.2574 51.6457 85.1517C51.6457 85.1517 24.7814 81.7354 24.1602 77.8533C23.5391 73.9712 24.7814 72.263 24.7814 72.263L34.8749 71.0208Z"
            fill="black"/>
      <path
        d="M54.908 109.376C54.908 109.376 54.2868 107.979 53.9763 108.445C53.6657 108.91 52.5787 114.345 53.6657 114.345C54.7527 114.345 64.6909 116.83 66.0885 116.519C67.4861 116.209 70.1259 116.675 69.8153 115.122C69.5048 113.569 67.9519 113.414 67.9519 113.414C67.9519 113.414 62.2064 109.997 61.8958 108.289C61.5852 106.581 59.8771 107.513 59.8771 107.513V108.6C59.8771 108.6 56.6161 111.084 54.908 109.376Z"
        fill="#2F2E41"/>
      <path
        d="M25.404 38.1005C25.404 38.1005 26.491 43.3802 24.1618 43.8461C21.8325 44.3119 27.1122 47.4176 27.1122 47.4176L30.5284 49.281L33.013 46.7965C33.013 46.7965 30.7614 42.2156 32.4695 38.7993C34.1776 35.383 25.404 38.1005 25.404 38.1005Z"
        fill="#9F616A"/>
      <path
        d="M33.013 46.7965L30.5284 49.281L27.1122 47.4176C27.1122 47.4176 21.8325 44.3119 24.1618 43.8461C25.6602 43.5464 25.7441 41.2544 25.6152 39.658C25.5732 39.1354 25.5026 38.6155 25.404 38.1005C25.404 38.1005 34.1776 35.383 32.4695 38.7993C32.21 39.3269 32.0299 39.8899 31.9353 40.4702C31.412 43.5402 33.013 46.7965 33.013 46.7965Z"
        fill="#9F616A"/>
      <path d="M41.707 110.308V113.88H47.6079L48.0737 112.482L47.4526 108.755L41.707 110.308Z" fill="#575A88"/>
      <path
        d="M17.3281 74.1266C17.3281 74.1266 17.3281 83.2884 21.055 83.599C24.7818 83.9095 38.1363 85.6177 38.1363 85.6177C38.1363 85.6177 38.9127 100.836 40.3103 105.805C41.7078 110.774 40.4656 111.084 41.7078 111.395C42.9501 111.706 48.2298 110.619 48.0745 109.532C47.9192 108.445 47.2981 80.9591 47.2981 80.9591C47.2981 80.9591 48.6957 75.0583 30.0615 72.2632L17.3281 74.1266Z"
        fill="#2F2E41"/>
      <path
        d="M42.1742 112.793C42.1742 112.793 41.2425 110.774 41.0872 111.395C40.9319 112.016 39.0685 117.451 40.9319 117.917C42.7953 118.383 48.6962 120.867 52.5783 120.712C56.4604 120.557 56.9263 120.402 56.9263 120.402C56.9263 120.402 58.0133 118.849 56.6157 117.917C55.2181 116.985 49.4726 111.55 49.162 110.929C48.8515 110.308 46.5222 109.842 46.5222 110.929C46.5222 112.016 43.2612 114.19 42.1742 112.793Z"
        fill="#2F2E41"/>
      <path opacity="0.1"
            d="M32.4678 38.7993C32.2083 39.3269 32.0283 39.8899 31.9336 40.4702C31.1603 40.9376 30.6432 40.818 29.6727 40.818C28.2332 40.818 27.6524 38.9779 26.7223 38.0229C26.6493 37.1362 25.4023 38.1005 25.4023 38.1005C25.4023 38.1005 34.1759 35.383 32.4678 38.7993Z"
            fill="black"/>
      <path
        d="M29.2846 40.7403C32.1147 40.7403 34.409 38.446 34.409 35.6159C34.409 32.7857 32.1147 30.4915 29.2846 30.4915C26.4544 30.4915 24.1602 32.7857 24.1602 35.6159C24.1602 38.446 26.4544 40.7403 29.2846 40.7403Z"
        fill="#9F616A"/>
      <path
        d="M38.1368 51.7655C38.1368 51.7655 37.3604 57.3558 36.8946 59.3745C36.4287 61.3932 35.8076 70.7103 35.497 71.9526C35.1864 73.1948 33.323 73.5054 29.5962 73.0395C29.3622 73.0101 29.1269 72.9925 28.8912 72.9867C26.5961 72.9138 23.9702 73.7725 21.54 74.4464C20.1424 74.8346 18.8085 75.1607 17.6392 75.2135C15.869 75.2943 15.3503 73.3315 15.4109 71.2072C15.4684 69.2304 16.0289 67.1108 16.5522 66.3623C17.4948 65.0144 16.5678 52.9131 16.9156 48.9222V48.9207C16.9684 48.312 17.0507 47.8927 17.1734 47.7281C18.1051 46.4858 24.1612 43.3801 24.8398 43.5059C25.5168 43.6317 31.3043 47.2622 31.3043 47.2622C32.7019 47.2622 32.6568 45.7467 32.6568 45.7467C32.6568 45.7467 33.6336 47.5728 35.497 48.0387C37.3604 48.5045 38.1368 51.7655 38.1368 51.7655Z"
        fill="#575A88"/>
      <path
        d="M27.7563 71.897C27.7563 71.897 37.7591 72.2155 36.0068 73.4039C34.2545 74.5923 26.4903 75.6793 26.335 74.1264C26.1797 72.5736 27.7563 71.897 27.7563 71.897Z"
        fill="#9F616A"/>
      <path opacity="0.1"
            d="M28.8904 72.9868C28.923 73.5831 27.319 74.9263 26.8003 75.8348C26.513 76.3379 24.2894 75.6748 21.5392 74.4465C19.4289 73.4993 17.3816 72.4173 15.4102 71.2073C15.4676 69.2305 16.0282 67.1108 16.5515 66.3624C17.4941 65.0145 16.567 52.9132 16.9149 48.9223V48.9208C16.9978 48.8787 17.084 48.8434 17.1726 48.8152H19.0361C25.4027 51.7656 18.1043 64.499 18.1043 64.499C18.1043 64.499 27.732 72.4185 28.6637 72.7291C28.7248 72.7406 28.7803 72.7723 28.8214 72.819C28.8625 72.8657 28.8868 72.9248 28.8904 72.9868Z"
            fill="black"/>
      <path
        d="M19.0369 47.8833H17.1735C17.1735 47.8833 15.4654 48.3492 14.0678 51.4549C12.6702 54.5606 7.54583 64.1882 12.0491 67.9151C16.5524 71.6419 26.18 75.9899 26.8012 74.9029C27.4223 73.8159 29.5963 72.1077 28.6646 71.7972C27.7329 71.4866 18.1052 63.5671 18.1052 63.5671C18.1052 63.5671 25.4036 50.8337 19.0369 47.8833Z"
        fill="#575A88"/>
      <path
        d="M28.4961 33.2313C28.4961 33.2313 28.721 30.1183 31.4952 31.3876C34.2695 32.6569 34.0885 29.1582 33.2501 28.54C32.4117 27.9217 32.4383 27.7687 29.372 28.1823C26.3058 28.596 20.3528 29.2969 22.2777 35.4622C24.2025 41.6275 25.9458 40.0415 25.9458 40.0415C25.9458 40.0415 24.4158 34.4935 25.9661 35.5505L27.5163 36.6075L28.3344 36.4342L28.4961 33.2313Z"
        fill="#2F2E41"/>
      <path
        d="M142.97 78.058C142.97 75.2551 122.142 72.9829 96.449 72.9829C70.756 72.9829 49.9277 75.2551 49.9277 78.058C49.9277 79.1443 53.0581 80.1508 58.3861 80.9763V115.698H60.9237V81.3342C69.4572 82.4343 82.2042 83.133 96.449 83.133C110.812 83.133 123.652 82.4228 132.186 81.307V115.698H134.723V80.943C139.922 80.1237 142.97 79.1298 142.97 78.058Z"
        fill="#3F3D56"/>
      <path
        d="M102.277 78.1639C107.509 78.1639 111.749 77.1211 111.749 75.8346C111.749 74.5482 107.509 73.5054 102.277 73.5054C97.0456 73.5054 92.8047 74.5482 92.8047 75.8346C92.8047 77.1211 97.0456 78.1639 102.277 78.1639Z"
        fill="#F2F2F2"/>
      <path
        d="M102.276 77.744C106.564 77.744 110.04 76.8892 110.04 75.8348C110.04 74.7803 106.564 73.9255 102.276 73.9255C97.9879 73.9255 94.5117 74.7803 94.5117 75.8348C94.5117 76.8892 97.9879 77.744 102.276 77.744Z"
        fill="#D0CDE1"/>
      <path
        d="M67.0271 80.0274C72.2585 80.0274 76.4994 78.9846 76.4994 77.6982C76.4994 76.4117 72.2585 75.3689 67.0271 75.3689C61.7956 75.3689 57.5547 76.4117 57.5547 77.6982C57.5547 78.9846 61.7956 80.0274 67.0271 80.0274Z"
        fill="#F2F2F2"/>
      <path
        d="M67.026 79.6073C71.314 79.6073 74.7902 78.7525 74.7902 77.6981C74.7902 76.6436 71.314 75.7888 67.026 75.7888C62.7379 75.7888 59.2617 76.6436 59.2617 77.6981C59.2617 78.7525 62.7379 79.6073 67.026 79.6073Z"
        fill="#D0CDE1"/>
      <path
        d="M102.057 75.6702C104.551 75.6702 106.573 75.3509 106.573 74.9571C106.573 74.5634 104.551 74.2441 102.057 74.2441C99.5628 74.2441 97.541 74.5634 97.541 74.9571C97.541 75.3509 99.5628 75.6702 102.057 75.6702Z"
        fill="#9F616A"/>
      <path
        d="M99.6506 74.6899C100.438 74.6899 101.077 74.3441 101.077 73.9174C101.077 73.4908 100.438 73.145 99.6506 73.145C98.8631 73.145 98.2246 73.4908 98.2246 73.9174C98.2246 74.3441 98.8631 74.6899 99.6506 74.6899Z"
        fill="#F7B80B"/>
      <path
        d="M99.1155 74.6899C99.9031 74.6899 100.542 74.3441 100.542 73.9174C100.542 73.4908 99.9031 73.145 99.1155 73.145C98.3279 73.145 97.6895 73.4908 97.6895 73.9174C97.6895 74.3441 98.3279 74.6899 99.1155 74.6899Z"
        fill="#F7B80B"/>
      <path
        d="M105.354 74.8681C106.141 74.8681 106.78 74.5223 106.78 74.0957C106.78 73.6691 106.141 73.3232 105.354 73.3232C104.566 73.3232 103.928 73.6691 103.928 74.0957C103.928 74.5223 104.566 74.8681 105.354 74.8681Z"
        fill="#F7B80B"/>
      <path
        d="M102.086 74.6899C102.874 74.6899 103.512 74.3441 103.512 73.9174C103.512 73.4908 102.874 73.145 102.086 73.145C101.299 73.145 100.66 73.4908 100.66 73.9174C100.66 74.3441 101.299 74.6899 102.086 74.6899Z"
        fill="#F7B80B"/>
      <path
        d="M104.522 74.6899C105.309 74.6899 105.948 74.3441 105.948 73.9174C105.948 73.4908 105.309 73.145 104.522 73.145C103.734 73.145 103.096 73.4908 103.096 73.9174C103.096 74.3441 103.734 74.6899 104.522 74.6899Z"
        fill="#F7B80B"/>
      <path
        d="M106.364 73.4421C103.787 74.3167 100.896 74.3167 97.6895 73.4421V73.3218C97.6889 72.4125 98.0379 71.5378 98.6642 70.8787C99.2906 70.2196 100.146 69.8264 101.054 69.7806C101.819 69.7421 102.584 69.7431 103.349 69.7835C104.166 69.8311 104.933 70.1897 105.494 70.7857C106.055 71.3816 106.366 72.1697 106.364 72.9881V73.4421Z"
        fill="#3F3D56"/>
      <path
        d="M105.099 76.7163C103.194 76.9087 101.275 76.9087 99.3703 76.7163C98.9185 76.672 98.4992 76.4613 98.1941 76.1253C97.8889 75.7892 97.7195 75.3517 97.7188 74.8977H106.75C106.75 75.3517 106.58 75.7892 106.275 76.1253C105.97 76.4613 105.551 76.672 105.099 76.7163Z"
        fill="#3F3D56"/>
      <path
        d="M100.838 70.8871C100.631 70.9734 100.409 71.0178 100.185 71.0178C99.9605 71.0178 99.7383 70.9734 99.5312 70.8871C99.7383 70.8008 99.9605 70.7563 100.185 70.7563C100.409 70.7563 100.631 70.8008 100.838 70.8871Z"
        fill="#D0CDE1"/>
      <path
        d="M102.145 71.422C101.938 71.5083 101.716 71.5527 101.491 71.5527C101.267 71.5527 101.045 71.5083 100.838 71.422C101.045 71.3357 101.267 71.2913 101.491 71.2913C101.716 71.2913 101.938 71.3357 102.145 71.422Z"
        fill="#D0CDE1"/>
      <path
        d="M102.264 70.4711C102.057 70.5573 101.835 70.6018 101.611 70.6018C101.386 70.6018 101.164 70.5573 100.957 70.4711C101.164 70.3848 101.386 70.3403 101.611 70.3403C101.835 70.3403 102.057 70.3848 102.264 70.4711Z"
        fill="#D0CDE1"/>
      <path
        d="M104.7 70.4711C104.493 70.5573 104.271 70.6018 104.046 70.6018C103.822 70.6018 103.6 70.5573 103.393 70.4711C103.6 70.3848 103.822 70.3403 104.046 70.3403C104.271 70.3403 104.493 70.3848 104.7 70.4711Z"
        fill="#D0CDE1"/>
      <path
        d="M104.047 71.006C103.84 71.0923 103.618 71.1367 103.394 71.1367C103.169 71.1367 102.947 71.0923 102.74 71.006C102.947 70.9197 103.169 70.8752 103.394 70.8752C103.618 70.8752 103.84 70.9197 104.047 71.006Z"
        fill="#D0CDE1"/>
      <path
        d="M66.9611 77.5337C69.455 77.5337 71.4768 77.2145 71.4768 76.8207C71.4768 76.4269 69.455 76.1077 66.9611 76.1077C64.4671 76.1077 62.4453 76.4269 62.4453 76.8207C62.4453 77.2145 64.4671 77.5337 66.9611 77.5337Z"
        fill="#9F616A"/>
      <path
        d="M64.5549 76.5532C65.3425 76.5532 65.981 76.2073 65.981 75.7807C65.981 75.3541 65.3425 75.0083 64.5549 75.0083C63.7674 75.0083 63.1289 75.3541 63.1289 75.7807C63.1289 76.2073 63.7674 76.5532 64.5549 76.5532Z"
        fill="#F7B80B"/>
      <path
        d="M64.0198 76.5532C64.8073 76.5532 65.4458 76.2073 65.4458 75.7807C65.4458 75.3541 64.8073 75.0083 64.0198 75.0083C63.2322 75.0083 62.5938 75.3541 62.5938 75.7807C62.5938 76.2073 63.2322 76.5532 64.0198 76.5532Z"
        fill="#F7B80B"/>
      <path
        d="M70.26 76.7314C71.0476 76.7314 71.686 76.3856 71.686 75.959C71.686 75.5324 71.0476 75.1865 70.26 75.1865C69.4724 75.1865 68.834 75.5324 68.834 75.959C68.834 76.3856 69.4724 76.7314 70.26 76.7314Z"
        fill="#F7B80B"/>
      <path
        d="M66.9905 76.5532C67.778 76.5532 68.4165 76.2073 68.4165 75.7807C68.4165 75.3541 67.778 75.0083 66.9905 75.0083C66.2029 75.0083 65.5645 75.3541 65.5645 75.7807C65.5645 76.2073 66.2029 76.5532 66.9905 76.5532Z"
        fill="#F7B80B"/>
      <path
        d="M69.428 76.5532C70.2155 76.5532 70.854 76.2073 70.854 75.7807C70.854 75.3541 70.2155 75.0083 69.428 75.0083C68.6404 75.0083 68.002 75.3541 68.002 75.7807C68.002 76.2073 68.6404 76.5532 69.428 76.5532Z"
        fill="#F7B80B"/>
      <path
        d="M71.2687 75.3054C68.6915 76.18 65.7998 76.18 62.5938 75.3054V75.185C62.5932 74.2758 62.9422 73.4011 63.5685 72.742C64.1949 72.0828 65.0506 71.6897 65.9587 71.6439C66.723 71.6054 67.4887 71.6064 68.2529 71.6468C69.0699 71.6944 69.8377 72.053 70.3985 72.6489C70.9594 73.2449 71.2708 74.033 71.2687 74.8514V75.3054Z"
        fill="#3F3D56"/>
      <path
        d="M70.005 78.5798C68.1003 78.7722 66.1812 78.7722 64.2765 78.5798C63.8247 78.5355 63.4055 78.3249 63.1003 77.9888C62.7951 77.6527 62.6257 77.2152 62.625 76.7612H71.6565C71.6558 77.2152 71.4864 77.6527 71.1812 77.9888C70.876 78.3249 70.4568 78.5355 70.005 78.5798Z"
        fill="#3F3D56"/>
      <path
        d="M65.7427 72.7506C65.5356 72.8369 65.3135 72.8813 65.0891 72.8813C64.8648 72.8813 64.6426 72.8369 64.4355 72.7506C64.6426 72.6643 64.8648 72.6199 65.0891 72.6199C65.3135 72.6199 65.5356 72.6643 65.7427 72.7506Z"
        fill="#D0CDE1"/>
      <path
        d="M67.0513 73.2853C66.8442 73.3716 66.6221 73.416 66.3977 73.416C66.1734 73.416 65.9512 73.3716 65.7441 73.2853C65.9512 73.199 66.1734 73.1545 66.3977 73.1545C66.6221 73.1545 66.8442 73.199 67.0513 73.2853Z"
        fill="#D0CDE1"/>
      <path
        d="M67.1705 72.3346C66.9634 72.4209 66.7412 72.4653 66.5169 72.4653C66.2925 72.4653 66.0704 72.4209 65.8633 72.3346C66.0704 72.2483 66.2925 72.2039 66.5169 72.2039C66.7412 72.2039 66.9634 72.2483 67.1705 72.3346Z"
        fill="#D0CDE1"/>
      <path
        d="M69.606 72.3346C69.3989 72.4209 69.1768 72.4653 68.9524 72.4653C68.7281 72.4653 68.5059 72.4209 68.2988 72.3346C68.5059 72.2483 68.7281 72.2039 68.9524 72.2039C69.1768 72.2039 69.3989 72.2483 69.606 72.3346Z"
        fill="#D0CDE1"/>
      <path
        d="M68.9517 72.8692C68.7446 72.9555 68.5225 73 68.2981 73C68.0738 73 67.8516 72.9555 67.6445 72.8692C67.8516 72.783 68.0738 72.7385 68.2981 72.7385C68.5225 72.7385 68.7446 72.783 68.9517 72.8692Z"
        fill="#D0CDE1"/>
      <path
        d="M110.426 75.2036L110.448 75.6846L110.476 76.3085C110.476 76.3085 107.61 76.4704 107.033 77.0803C107.02 77.0934 107.007 77.1076 106.996 77.1227L105.849 76.8714L105.846 76.306C105.846 76.306 105.862 76.2972 105.892 76.2806C105.899 76.277 105.908 76.2723 105.917 76.2677C107.321 75.58 108.862 75.2163 110.426 75.2036Z"
        fill="#F7B80B"/>
      <path opacity="0.1"
            d="M110.426 75.2036L110.448 75.6846C109.177 75.7239 106.773 76.4813 106.773 76.4813L105.893 76.2806C107.303 75.5839 108.853 75.2157 110.426 75.2036Z"
            fill="black"/>
      <path opacity="0.1"
            d="M105.917 76.2676L106.773 76.4812L107.033 77.0802C107.02 77.0934 107.007 77.1075 106.996 77.1226L105.849 76.8714L105.846 76.306C105.846 76.306 105.862 76.2972 105.892 76.2806C105.899 76.2769 105.908 76.2722 105.917 76.2676Z"
            fill="white"/>
      <path
        d="M75.797 77.2222L75.8188 77.7032L75.8466 78.327C75.8466 78.327 72.9814 78.4889 72.4042 79.0989C72.3908 79.112 72.3785 79.1261 72.3673 79.1412L71.2196 78.89L71.2168 78.3246C71.2168 78.3246 71.2326 78.3158 71.2634 78.2992C71.2704 78.2955 71.2792 78.2909 71.288 78.2862C72.6924 77.5985 74.2333 77.2349 75.797 77.2222Z"
        fill="#F7B80B"/>
      <path opacity="0.1"
            d="M75.7973 77.2222L75.8191 77.7032C74.5485 77.7424 72.1439 78.4998 72.1439 78.4998L71.2637 78.2992C72.6742 77.6025 74.2241 77.2342 75.7973 77.2222Z"
            fill="black"/>
      <path opacity="0.1"
            d="M71.288 78.2864L72.1436 78.5L72.4042 79.099C72.3908 79.1122 72.3785 79.1263 72.3673 79.1414L71.2196 78.8902L71.2168 78.3248C71.2168 78.3248 71.2326 78.316 71.2634 78.2994C71.2704 78.2957 71.2792 78.291 71.288 78.2864Z"
            fill="white"/>
      <path
        d="M127.682 60.7961C127.437 60.5519 127.151 60.3539 126.836 60.2117V54.1779C126.836 54.1071 126.808 54.0393 126.758 53.9893C126.708 53.9392 126.64 53.9111 126.569 53.9111H125.075C125.005 53.9111 124.937 53.9392 124.887 53.9893C124.837 54.0393 124.809 54.1071 124.809 54.1779V60.0959C124.269 60.275 123.799 60.6196 123.466 61.0808C123.133 61.542 122.953 62.0964 122.953 62.6653V75.6086C122.953 75.7643 122.984 75.9186 123.043 76.0625C123.103 76.2064 123.19 76.3371 123.301 76.4473C123.411 76.5574 123.541 76.6448 123.685 76.7044C123.829 76.764 123.983 76.7947 124.139 76.7947H127.339C127.652 76.7947 127.952 76.6703 128.174 76.4489C128.395 76.2275 128.519 75.9272 128.519 75.6141V62.8178C128.52 62.4423 128.446 62.0703 128.302 61.7234C128.159 61.3764 127.948 61.0613 127.682 60.7961Z"
        fill="#3F3D56"/>
      <path d="M128.519 64.5627H122.953V71.9157H128.519V64.5627Z" fill="#F7B80B"/>
      <path
        d="M126.957 54.3234H126.837V54.1779C126.837 54.1071 126.809 54.0393 126.759 53.9893C126.709 53.9392 126.641 53.9111 126.57 53.9111H125.076C125.006 53.9111 124.938 53.9392 124.888 53.9893C124.838 54.0393 124.81 54.1071 124.81 54.1779V54.3234H124.689V54.9419H124.81V58.103H126.837V54.9419H126.957V54.3234Z"
        fill="#F7B80B"/>
      <path
        d="M83.1138 66.0335C82.9665 65.7191 82.8502 65.4967 82.8095 65.4202C80.311 66.0367 78.644 65.5157 78.3811 65.4236C78.2525 65.6663 77.4499 67.2182 77.1255 68.706C76.8325 70.048 77.0026 70.9477 77.6311 71.3801L77.6497 71.3933L77.6538 71.3961C78.3476 71.8532 79.0094 72.2581 79.6206 72.5993C79.703 72.6451 79.7807 72.6877 79.8536 72.7271L79.8614 72.7313L79.8669 72.7383C79.9508 72.844 80.0289 72.9542 80.1009 73.0684C80.236 73.2573 80.3179 73.4789 80.3382 73.7102V80.3757L80.2961 80.3766C78.7024 80.4091 77.7159 80.6793 77.7159 80.9109C77.7159 81.1651 78.8988 81.4483 80.5962 81.4483C82.2936 81.4483 83.4764 81.1651 83.4764 80.9109C83.4764 80.6827 82.5067 80.414 80.94 80.3778L80.8977 80.3768L80.9371 74.2112C80.938 73.7938 81.0351 73.3821 81.2207 73.0082C81.2523 72.9454 81.2863 72.8843 81.3217 72.8267C81.3502 72.7803 81.3803 72.7351 81.4113 72.6924L81.417 72.6846L81.4255 72.6799C81.5039 72.637 81.5873 72.5906 81.6755 72.5406L81.6858 72.5349C82.2644 72.2082 82.8877 71.8251 83.5388 71.3959C84.12 71.0127 84.3224 70.2382 84.1403 69.094C83.9668 68.003 83.4863 66.8275 83.1138 66.0335Z"
        fill="#D0CDE1"/>
      <path
        d="M83.2628 71.2561C82.5802 71.706 82.0167 72.0434 81.5857 72.2865H81.5854C80.9566 72.2436 80.3253 72.2613 79.6999 72.3395C79.2582 72.0929 78.6622 71.7392 77.9289 71.2561C77.922 71.2515 77.915 71.2468 77.9081 71.2418V71.2414C77.2355 70.7787 77.2189 69.7989 77.4377 68.7971C77.9943 68.7353 78.8817 68.7623 80.0741 69.1883C81.486 69.6927 82.964 69.4074 83.8206 69.1481C83.9612 70.032 83.8754 70.8522 83.2628 71.2561Z"
        fill="#F7B80B"/>
      <path
        d="M79.205 68.4346C79.3117 68.4346 79.3982 68.3481 79.3982 68.2414C79.3982 68.1346 79.3117 68.0481 79.205 68.0481C79.0982 68.0481 79.0117 68.1346 79.0117 68.2414C79.0117 68.3481 79.0982 68.4346 79.205 68.4346Z"
        fill="#F7B80B"/>
      <path
        d="M78.664 70.2124C78.7707 70.2124 78.8572 70.1259 78.8572 70.0192C78.8572 69.9125 78.7707 69.8259 78.664 69.8259C78.5572 69.8259 78.4707 69.9125 78.4707 70.0192C78.4707 70.1259 78.5572 70.2124 78.664 70.2124Z"
        fill="white"/>
      <path
        d="M79.669 68.8217C79.7333 68.8217 79.7854 68.7697 79.7854 68.7054C79.7854 68.6412 79.7333 68.5891 79.669 68.5891C79.6048 68.5891 79.5527 68.6412 79.5527 68.7054C79.5527 68.7697 79.6048 68.8217 79.669 68.8217Z"
        fill="#F7B80B"/>
      <path
        d="M80.1319 71.0249C80.1962 71.0249 80.2483 70.9728 80.2483 70.9086C80.2483 70.8443 80.1962 70.7922 80.1319 70.7922C80.0677 70.7922 80.0156 70.8443 80.0156 70.9086C80.0156 70.9728 80.0677 71.0249 80.1319 71.0249Z"
        fill="white"/>
      <path
        d="M79.3214 71.1408C79.3856 71.1408 79.4377 71.0887 79.4377 71.0245C79.4377 70.9603 79.3856 70.9082 79.3214 70.9082C79.2572 70.9082 79.2051 70.9603 79.2051 71.0245C79.2051 71.0887 79.2572 71.1408 79.3214 71.1408Z"
        fill="white"/>
      <path
        d="M81.6788 67.2758C81.743 67.2758 81.7951 67.2238 81.7951 67.1595C81.7951 67.0953 81.743 67.0432 81.6788 67.0432C81.6146 67.0432 81.5625 67.0953 81.5625 67.1595C81.5625 67.2238 81.6146 67.2758 81.6788 67.2758Z"
        fill="#F7B80B"/>
      <path
        d="M92.1783 59.7495C92.0309 59.4351 91.9147 59.2128 91.874 59.1362C89.3754 59.7528 87.7084 59.2318 87.4455 59.1396C87.317 59.3824 86.5144 60.9343 86.19 62.4221C85.897 63.7641 86.067 64.6638 86.6955 65.0961L86.7142 65.1094L86.7182 65.1121C87.412 65.5693 88.0738 65.9741 88.6851 66.3154C88.7675 66.3612 88.8451 66.4038 88.918 66.4432L88.9258 66.4474L88.9313 66.4544C89.0153 66.5601 89.0934 66.6703 89.1653 66.7845C89.3004 66.9734 89.3824 67.195 89.4027 67.4263V74.0918L89.3606 74.0926C87.7668 74.1251 86.7804 74.3953 86.7804 74.627C86.7804 74.8811 87.9633 75.1643 89.6606 75.1643C91.358 75.1643 92.5409 74.8811 92.5409 74.627C92.5409 74.3988 91.5711 74.1301 90.0044 74.0939L89.9621 74.0929L90.0015 67.9273C90.0025 67.5098 90.0995 67.0982 90.2851 66.7243C90.3168 66.6615 90.3508 66.6003 90.3862 66.5427C90.4146 66.4964 90.4448 66.4512 90.4757 66.4085L90.4814 66.4006L90.4899 66.396C90.5684 66.3531 90.6517 66.3067 90.7399 66.2566L90.7503 66.251C91.3288 65.9243 91.9521 65.5411 92.6032 65.112C93.1845 64.7288 93.3869 63.9543 93.2047 62.81C93.0312 61.719 92.5508 60.5435 92.1783 59.7495Z"
        fill="#D0CDE1"/>
      <path
        d="M92.3273 64.9724C91.6447 65.4223 91.0811 65.7597 90.6502 66.0028H90.6498C90.0211 65.9599 89.3897 65.9777 88.7644 66.0558C88.3226 65.8092 87.7266 65.4555 86.9934 64.9724C86.9864 64.9678 86.9795 64.9631 86.9725 64.9581V64.9577C86.3 64.4951 86.2834 63.5153 86.5021 62.5134C87.0587 62.4516 87.9461 62.4786 89.1385 62.9046C90.5505 63.409 92.0285 63.1237 92.885 62.8644C93.0257 63.7483 92.9399 64.5685 92.3273 64.9724Z"
        fill="#F7B80B"/>
      <path
        d="M88.2694 62.1509C88.3762 62.1509 88.4627 62.0644 88.4627 61.9577C88.4627 61.8509 88.3762 61.7644 88.2694 61.7644C88.1627 61.7644 88.0762 61.8509 88.0762 61.9577C88.0762 62.0644 88.1627 62.1509 88.2694 62.1509Z"
        fill="#F7B80B"/>
      <path
        d="M87.7284 63.9287C87.8351 63.9287 87.9217 63.8422 87.9217 63.7355C87.9217 63.6288 87.8351 63.5422 87.7284 63.5422C87.6217 63.5422 87.5352 63.6288 87.5352 63.7355C87.5352 63.8422 87.6217 63.9287 87.7284 63.9287Z"
        fill="white"/>
      <path
        d="M88.7335 62.538C88.7977 62.538 88.8498 62.486 88.8498 62.4217C88.8498 62.3575 88.7977 62.3054 88.7335 62.3054C88.6693 62.3054 88.6172 62.3575 88.6172 62.4217C88.6172 62.486 88.6693 62.538 88.7335 62.538Z"
        fill="#F7B80B"/>
      <path
        d="M89.1984 64.7412C89.2626 64.7412 89.3147 64.6891 89.3147 64.6249C89.3147 64.5606 89.2626 64.5085 89.1984 64.5085C89.1341 64.5085 89.082 64.5606 89.082 64.6249C89.082 64.6891 89.1341 64.7412 89.1984 64.7412Z"
        fill="white"/>
      <path
        d="M88.3858 64.8571C88.4501 64.8571 88.5021 64.8051 88.5021 64.7408C88.5021 64.6766 88.4501 64.6245 88.3858 64.6245C88.3216 64.6245 88.2695 64.6766 88.2695 64.7408C88.2695 64.8051 88.3216 64.8571 88.3858 64.8571Z"
        fill="white"/>
      <path
        d="M90.7433 60.9919C90.8075 60.9919 90.8596 60.9398 90.8596 60.8756C90.8596 60.8114 90.8075 60.7593 90.7433 60.7593C90.679 60.7593 90.627 60.8114 90.627 60.8756C90.627 60.9398 90.679 60.9919 90.7433 60.9919Z"
        fill="#F7B80B"/>
    </g>
    <defs>
      <clipPath id="clip0_946_3896">
        <rect width="155.018" height="120" fill="white" transform="translate(0.490234 0.718994)"/>
      </clipPath>
    </defs>
  </svg>

);