import { Box, Flex, Text } from "@chakra-ui/react";
import { FC, ReactElement } from "react";

import { colors } from "../../ui/theme";
import { LeftArrowIcon } from "../Icons/LeftArrowIcon";
import PersonalGenericMenuCards from "../PersonalGenericMenuCards/PersonalGenericMenuCards";
import PicturesVariant, { Icons } from "../PicturesVariant/PicturesVariant";

import {
  setAdviceOnHover,
  useAdviceContext,
} from "../../context/AdviceContext";
import { DressCode } from "../DressingBlock/DressingBlock";
import PersonalCustomMenuFirstBlock from "./PersonalCustomMenuFirstBlock";
import PersonalCustomMenuSecondBlock from "./PersonalCustomMenuSecondBlock";

interface PersonalGenericMenuProps {
  dressCode?: DressCode;
  openToggleSection: boolean;
  filter?: string;
  setDressCode: (element: DressCode) => void;
  onActivePersonalCustomMenu: (click: boolean) => void;
}

interface PersonalCustomMenuProps {
  dressCode: any;
  onActivePersonalCustomMenu: (click: boolean) => void;
  setDressCode: (element: DressCode) => void;
}

const GLOBAL_GENERIC_PERSONAL_OUTFIT_OPTIONS = [
  {
    name: "Classique",
    dressingTabTitle: "Tenue personnelle",
    icon: Icons.DRESSING_TENUE_PERSONELLE_CLASSIQUE,
    description:
      "Adaptée pour les événements professionnels, formels ou élégants. Elle se compose d’un ensemble veste et pantalon ou jupe, d’une chemise ou d’un chemisier, et de chaussures à talons ou plates.",
  },
  {
    name: "Casual",
    icon: Icons.DRESSING_TENUE_PERSONELLE_CASUAL,
    description:
      "Adaptée pour les événements décontractés, ludiques ou créatifs. Elle se compose d’un pantalon ou d’un jean, d’un haut coloré et de chaussures confortables.",
  },
];

const STREET_GENERIC_PERSONAL_OUTFIT_OPTIONS = [
  {
    name: "Casual",
    icon: Icons.DRESSING_TENUE_PERSONELLE_CASUAL,
    description:
      "Adaptée pour les événements décontractés, ludiques ou créatifs. Elle se compose d’un pantalon ou d’un jean, d’un haut coloré et de chaussures confortables.",
  },
];

const PersonalOutfitMenu: FC<
  PersonalCustomMenuProps & PersonalGenericMenuProps
> = ({
  onActivePersonalCustomMenu,
  openToggleSection,
  dressCode,
  filter,
  setDressCode,
}): ReactElement => {
  return !openToggleSection ? (
    <PersonalCustomMenu
      setDressCode={setDressCode}
      onActivePersonalCustomMenu={onActivePersonalCustomMenu}
      dressCode={dressCode}
    />
  ) : (
    <PersonalGenericMenu
      dressCode={dressCode}
      setDressCode={setDressCode}
      filter={filter}
      onActivePersonalCustomMenu={onActivePersonalCustomMenu}
      openToggleSection={openToggleSection}
    />
  );
};

const PersonalCustomMenu: FC<PersonalCustomMenuProps> = ({
  onActivePersonalCustomMenu,
  dressCode,
  setDressCode,
}): ReactElement => {
  return (
    <Box>
      <Flex
        mb="1em"
        pl="0.5em"
        gap="8px"
        cursor="pointer"
        alignItems="center"
        onClick={() => onActivePersonalCustomMenu(true)}
      >
        <LeftArrowIcon />
        <Text variant="sm" fontWeight={600} color={colors.additional.lightMode}>
          Retour au choix de tenues simples
        </Text>
      </Flex>
      <PersonalCustomMenuFirstBlock
        dressCode={dressCode}
        setDressCode={setDressCode}
      />
      <Box w="100%" h="1px" bg="#aaa" my="1rem" />
      <PersonalCustomMenuSecondBlock
        dressCode={dressCode}
        setDressCode={setDressCode}
      />
    </Box>
  );
};

const PersonalGenericMenu: FC<PersonalGenericMenuProps> = ({
  openToggleSection,
  dressCode,
  filter,
  setDressCode,
  onActivePersonalCustomMenu,
}): ReactElement => {
  const { setAdvice } = useAdviceContext();

  const onSetPersonalGenericOutfit = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      personalOutfit: {
        generic: value,
      },
    });
  };

  let selectedOptions = GLOBAL_GENERIC_PERSONAL_OUTFIT_OPTIONS;
  if (filter === "street") {
    selectedOptions = STREET_GENERIC_PERSONAL_OUTFIT_OPTIONS;
  }

  return (
    <Flex
      gap="36px"
      justifyContent="center"
      alignItems="center"
      display={openToggleSection ? "flex" : "none"}
    >
      <Flex gap="10px" padding="24px">
        {selectedOptions.map((item) => {
          return (
            <PersonalGenericMenuCards
              dressCode={dressCode}
              onSetPersonalGenericOutfit={onSetPersonalGenericOutfit}
              details={item}
              key={item.name}
            />
          );
        })}
      </Flex>

      <Flex
        {...setAdviceOnHover(
          "Personnalisez la tenue de vos équipes selon vos besoins.",
          setAdvice
        )}
        className="PersonalGenericMenuCards"
        flexDirection="column"
        position="relative"
        borderRadius="12px"
        alignItems="center"
        border="1px solid transparent"
        padding={{ md: "8px", "2xl": "17px 12px 12px" }}
        gap="14px"
        width="100%"
        backgroundColor="transparent"
        _hover={{
          backgroundColor: colors.blue.transparent,
          color: colors.white,
          p: {
            color: colors.white,
          },
        }}
        onClick={() => {
          onActivePersonalCustomMenu(false);
        }}
        sx={{
          ".helpIconCardItem": {
            position: "absolute",
            top: "3px",
            right: "-2px",
            width: "75px",
            borderTopRightRadius: "30px",
            "@media(max-width: 1600px), @media(max-height: 850px)": {
              width: "30px",
              borderTopRightRadius: "10px",
              right: "12px",
              top: "-10px",
            },
          },
          "@media(max-width: 1600px), @media(max-height: 850px)": {
            width: "180px",
            svg: {
              height: "70px",
            },
          },
        }}
      >
        <Flex width="fit-content" position="absolute" top="0" right="0"></Flex>
        <PicturesVariant icon={Icons.DRESSING_TENUE_PERSONELLE_MORE_OPTIONS} />
        <Text
          color={colors.black}
          variant="xl"
          textAlign="center"
          className="cardActiveTitle"
        >
          Je veux + de choix dans les tenues
        </Text>
      </Flex>
    </Flex>
  );
};
export default PersonalOutfitMenu;
