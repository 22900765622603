import { Flex, Radio, RadioGroup, Text } from "@chakra-ui/react";
import { FC, ReactElement } from "react";
import { colors } from "../../ui/theme";
import PicturesVariant from "../PicturesVariant/PicturesVariant";
import { ToggleItemCardProps } from "./interfaces";

const GenericDressingSelectionCard: FC<ToggleItemCardProps> = ({
  title,
  icon,
  values,
  onClick,
  selected,
}): ReactElement => {
  const onChangeHandler = (value: string) => {
    onClick!(value);
  };
  return (
    <Flex
      key={title}
      gap="14px"
      width="100%"
      border={`1px solid ${colors.gray.lightMode}`}
      padding="14px"
      borderRadius="12px"
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap="14px"
      >
        <PicturesVariant icon={icon} />
        <Text variant="xl" fontWeight="500">
          {title}
        </Text>
      </Flex>
      <RadioGroup
        onChange={onChangeHandler}
        value={selected ?? ""}
        display="flex"
        flexWrap="wrap"
        flexDirection={{ md: "column", "2xl": "row" }}
        gap={{ md: "8px", "2xl": "14px" }}
        justifyContent="space-evenly"
      >
        {values.map((item) => (
          <Radio value={item} key={item}>
            <Text variant="lg" whiteSpace="nowrap">
              {item}
            </Text>
          </Radio>
        ))}
      </RadioGroup>
    </Flex>
  );
};

export default GenericDressingSelectionCard;
