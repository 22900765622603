export const EmargementIcon = () => (
  <svg
    width="133"
    height="121"
    viewBox="0 0 133 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_942_38771)">
      <path
        d="M64.077 24.565C65.5522 23.0637 67.5227 22.608 68.4779 23.547C69.4332 24.486 69.0115 26.4638 67.5356 27.9655C66.9542 28.5734 66.2357 29.0333 65.4402 29.3066L59.1093 35.5887L56.2246 32.5549L62.7721 26.684C63.0316 25.8836 63.479 25.157 64.077 24.565V24.565Z"
        fill="#FFB6B6"
      />
      <path
        d="M43.8294 19.3791L50.4672 34.1048L61.0669 28.3673L64.8603 30.3806C64.8603 30.3806 56.1953 44.8668 50.2624 43.2178C44.3295 41.5687 33.7942 26.616 35.6924 22.0012C37.5906 17.3864 43.8294 19.3791 43.8294 19.3791Z"
        fill="#E2E8F0"
      />
      <path
        d="M74.5986 27.6923C73.0576 26.2585 72.5483 24.3012 73.461 23.3207C74.3736 22.3402 76.3622 22.7078 77.9035 24.1423C78.527 24.7068 79.0063 25.4125 79.3012 26.2003L85.7535 32.3577L82.7995 35.324L76.7523 28.939C75.9451 28.7014 75.2066 28.2739 74.5986 27.6923Z"
        fill="#A0616A"
      />
      <path
        d="M107.101 28.641L94.6466 41.9005C94.6466 41.9005 91.8498 44.6677 87.2484 40.6771C82.647 36.6864 77.5312 30.1391 77.5312 30.1391L80.4013 27.0156L85.4849 31.551C85.4849 31.551 88.5031 32.7654 88.7129 33.5512C88.9227 34.337 88.3361 34.6621 89.279 34.4103C90.222 34.1585 90.2404 33.5967 90.2995 34.4487C90.3586 35.3006 89.7826 36.2962 90.8721 35.3318C91.9615 34.3674 92.2865 34.9541 92.2865 34.9541L100.246 24.4101C100.246 24.4101 105.39 20.3427 106.869 23.9887C108.347 27.6346 107.101 28.641 107.101 28.641V28.641Z"
        fill="#F7B80B"
      />
      <path
        d="M40.9189 117.521L45.759 117.521L48.0614 98.8516L40.918 98.8521L40.9189 117.521Z"
        fill="#FFB6B6"
      />
      <path
        d="M57.5012 117.412C57.6519 117.666 57.7314 118.485 57.7314 118.78C57.7314 119.687 56.9959 120.423 56.0887 120.423H41.0996C40.4806 120.423 39.9789 119.921 39.9789 119.302V118.678C39.9789 118.678 39.2374 116.803 40.764 114.491C40.764 114.491 42.6614 116.301 45.4965 113.466L46.3326 111.951L52.3845 116.377L55.739 116.79C56.4729 116.881 57.1236 116.776 57.5011 117.412H57.5012Z"
        fill="#1A202C"
      />
      <path
        d="M96.7025 31.3672H108.697V13.6436H96.7025V31.3672Z"
        fill="#1A202C"
      />
      <path
        d="M106.005 25.963L105.585 20.8867H99.9999L98.7617 26.265L106.005 25.963Z"
        fill="#A0616A"
      />
      <path
        opacity="0.1"
        d="M106.005 25.963L105.585 20.8867H99.9999L98.7617 26.265L106.005 25.963Z"
        fill="black"
      />
      <path
        d="M44.5807 20.3247L44.6786 13.7966L40.6529 11.2871L36.2754 16.6417L44.5807 20.3247Z"
        fill="#FFB6B6"
      />
      <path
        opacity="0.1"
        d="M44.5801 20.3247L44.8407 13.9592L40.6523 11.2871L42.0753 19.1646L44.5801 20.3247Z"
        fill="black"
      />
      <path
        d="M101.912 22.5014C105.284 22.5014 108.017 19.768 108.017 16.3962C108.017 13.0244 105.284 10.291 101.912 10.291C98.54 10.291 95.8066 13.0244 95.8066 16.3962C95.8066 19.768 98.54 22.5014 101.912 22.5014Z"
        fill="#A0616A"
      />
      <path
        d="M100.373 9.97442C100.373 9.97442 100.373 7.46806 102.7 8.00514C105.028 8.54221 105.744 9.43734 105.744 9.43734C105.744 9.43734 106.639 10.8695 108.071 11.0486C109.503 11.2276 110.757 15.3452 110.578 16.2403C110.398 17.1355 109.503 19.4628 110.757 20.5369C112.01 21.6111 113.8 23.2223 113.263 24.8336C112.726 26.4448 111.115 30.5624 111.115 30.5624C111.115 30.5624 115.609 31.2193 115.241 34.0238C115.241 34.0238 113.084 32.3526 110.219 32.3526C107.355 32.3526 107.713 27.156 107.713 27.156C107.713 27.156 107.534 23.9384 106.997 23.0433C106.46 22.1482 107.176 17.1355 104.491 14.4501C101.805 11.7647 100.373 9.97442 100.373 9.97442V9.97442Z"
        fill="#1A202C"
      />
      <path
        d="M101.089 9.4375C101.089 9.4375 97.6873 10.1536 96.4341 13.5551C95.181 16.9566 95.8139 17.5895 95.8139 17.5895C95.8139 17.5895 95.5368 19.6377 94.0162 20.356C92.4956 21.0742 93.7488 24.1176 93.7488 24.1176C93.7488 24.1176 93.3907 27.1611 88.557 28.2352C83.7233 29.3094 94.6186 37.5465 94.6186 37.5465C94.6186 37.5465 93.8352 33.6588 98.8479 33.4798C103.861 33.3008 97.8664 24.1176 97.8664 24.1176L97.3293 18.2098C97.3293 18.2098 98.4034 11.4068 101.626 10.5117C104.848 9.61652 101.089 9.4375 101.089 9.4375L101.089 9.4375Z"
        fill="#1A202C"
      />
      <path
        d="M44.5931 15.8269C48.5472 15.8269 51.7527 12.6214 51.7527 8.66734C51.7527 4.71324 48.5472 1.50781 44.5931 1.50781C40.639 1.50781 37.4336 4.71324 37.4336 8.66734C37.4336 12.6214 40.639 15.8269 44.5931 15.8269Z"
        fill="#FFB6B6"
      />
      <path
        d="M54.3576 63.4926L47.6882 108.877H39.3205L40.4332 82.9705L41.0188 69.3487L39.3205 62.8647C39.3205 62.8647 38.5462 61.4234 37.8939 59.6764C37.1587 57.7048 36.5779 55.3413 37.4401 54.2205C37.7573 53.8073 38.582 53.5015 39.7028 53.2737C40.3876 53.1338 41.1847 53.0248 42.0452 52.9403C46.9936 52.4506 54.0323 52.7564 54.0323 52.7564L54.2112 58.6613L54.3105 61.9586L54.3576 63.4926Z"
        fill="#1A202C"
      />
      <path
        d="M13.3613 110.29L17.6252 112.58L28.4892 97.2242L22.1962 93.8438L13.3613 110.29Z"
        fill="#FFB6B6"
      />
      <path
        d="M28.0205 118.042C28.0331 118.337 27.7155 119.096 27.5758 119.356C27.1464 120.155 26.1504 120.455 25.3512 120.025L12.1471 112.931C11.6019 112.638 11.3974 111.959 11.6903 111.414L11.9857 110.864C11.9857 110.864 12.2202 108.861 14.6591 107.547C14.6591 107.547 15.4738 110.039 19.3131 108.884L20.7665 107.945L24.0029 114.709L26.7625 116.66C27.3662 117.087 27.9888 117.303 28.0205 118.042L28.0205 118.042Z"
        fill="#1A202C"
      />
      <path
        d="M54.3111 61.9572L50.7715 69.0545L40.4339 82.9692L23.4155 105.878L16.0449 101.918L36.2484 67.9012L37.823 61.3846C37.823 61.3846 37.823 60.6803 37.8946 59.675C38.0345 57.6856 38.4542 54.5151 39.7035 53.2724C39.8726 53.1032 40.0565 52.9698 40.2565 52.8803C40.6323 52.7111 41.257 52.7518 42.0459 52.9389C45.3416 53.7262 51.4921 57.1081 54.2119 58.6599L54.3111 61.9572H54.3111Z"
        fill="#1A202C"
      />
      <path
        d="M105.549 118.089L101.388 118.089L99.4082 102.037L105.55 102.038L105.549 118.089Z"
        fill="#A0616A"
      />
      <path
        d="M91.2917 117.994C91.1621 118.212 91.0938 118.917 91.0938 119.17C91.0938 119.95 91.7261 120.583 92.5062 120.583H105.394C105.926 120.583 106.358 120.151 106.358 119.619V119.083C106.358 119.083 106.995 117.47 105.683 115.482C105.683 115.482 104.051 117.039 101.613 114.601L100.895 113.299L95.691 117.104L92.8068 117.459C92.1758 117.537 91.6163 117.447 91.2917 117.994H91.2917H91.2917Z"
        fill="#1A202C"
      />
      <path
        d="M121.943 113.186L118.095 114.773L110.143 100.69L115.82 98.3477L121.943 113.186Z"
        fill="#A0616A"
      />
      <path
        d="M108.727 118.538C108.69 118.79 108.896 119.467 108.993 119.701C109.29 120.422 110.116 120.766 110.837 120.468L122.75 115.552C123.242 115.349 123.477 114.786 123.274 114.294L123.069 113.798C123.069 113.798 123.043 112.064 121.072 110.727C121.072 110.727 120.157 112.788 116.974 111.465L115.813 110.535L112.454 116.038L109.924 117.466C109.37 117.779 108.819 117.909 108.727 118.538L108.727 118.538H108.727Z"
        fill="#1A202C"
      />
      <path
        d="M107.459 63.0269L105.761 69.5109L106.213 80.0323L106.963 97.5078L106.984 98.0007L107.069 99.9657L107.459 109.039H99.0913L95.3662 83.6907L92.4219 63.6548L92.7309 53.4522L92.7472 52.9186C92.7472 52.9186 107.713 52.268 109.339 54.3827C110.966 56.4974 107.459 63.0269 107.459 63.0269H107.459Z"
        fill="#1A202C"
      />
      <path
        d="M119.101 102.516L117.683 103.249L112.437 105.964L111.669 106.361L107.069 99.9652L95.3662 83.6902L92.4219 63.6543L92.731 53.4517C94.0551 53.0304 95.114 52.858 95.6508 53.1085C98.0681 54.239 97.9526 61.6502 97.9526 61.6502L99.4248 68.1895L106.213 80.0318L119.101 102.516Z"
        fill="#1A202C"
      />
      <path
        d="M88.8461 104.518C88.7385 104.188 87.9224 104.333 87.844 104.039C87.7659 103.745 88.549 103.499 89.1496 102.671C89.258 102.522 89.9405 101.581 89.6787 101.186C89.1825 100.436 85.7302 102.304 84.9016 101.256C84.7197 101.026 84.6449 100.631 84.1031 99.8961C83.8876 99.6038 83.7493 99.4627 83.5841 99.4669C83.3496 99.4728 83.2658 99.7661 82.8973 100.297C82.3447 101.094 82.1453 100.994 81.7813 101.647C81.5111 102.131 81.4791 102.441 81.2842 102.459C81.0085 102.485 80.8945 101.882 80.5576 101.858C80.2138 101.834 79.8697 102.431 79.6991 102.896C79.3791 103.77 79.5773 104.439 79.6212 105.104C79.669 105.828 79.5568 106.887 78.7707 108.246L71.3555 120.061C72.948 117.652 77.4684 111.085 79.2897 108.676C79.8155 107.98 80.3805 107.282 81.2501 107.151C82.0874 107.024 83.1061 107.438 84.528 107.414C84.6941 107.411 85.1563 107.398 85.2687 107.153C85.3616 106.95 85.1309 106.774 85.2148 106.584C85.3274 106.328 85.834 106.438 86.5244 106.268C87.0112 106.149 87.342 105.951 87.6264 105.781C87.7121 105.73 88.9895 104.956 88.8462 104.518H88.8461Z"
        fill="#EDF2F7"
      />
      <path
        d="M69.4234 107.275C69.2306 107.232 69.0664 107.675 68.8991 107.628C68.7324 107.582 68.8375 107.126 68.6047 106.593C68.5627 106.497 68.298 105.89 68.0284 105.905C67.5172 105.932 67.4376 108.165 66.6842 108.269C66.5189 108.292 66.3035 108.215 65.7866 108.269C65.5809 108.29 65.4718 108.318 65.4261 108.4C65.3614 108.517 65.4812 108.642 65.6356 108.977C65.8672 109.478 65.7605 109.547 65.9759 109.914C66.1358 110.186 66.2785 110.291 66.2312 110.392C66.1644 110.535 65.8354 110.417 65.7267 110.575C65.6157 110.737 65.8091 111.078 65.9884 111.296C66.3251 111.705 66.7102 111.801 67.0496 111.971C67.4185 112.157 67.906 112.517 68.3464 113.295L72.0054 120.344C71.2828 118.867 69.3637 114.754 68.7068 113.165C68.5172 112.706 68.3375 112.227 68.5239 111.762C68.7034 111.315 69.2007 110.934 69.5991 110.23C69.6457 110.147 69.7723 109.916 69.6845 109.791C69.6119 109.687 69.4589 109.749 69.3897 109.653C69.2969 109.524 69.4968 109.307 69.6128 108.919C69.6945 108.646 69.693 108.426 69.6917 108.238C69.6913 108.181 69.6801 107.331 69.4234 107.274L69.4234 107.275Z"
        fill="#EDF2F7"
      />
      <path
        d="M73.0742 108.995L73.1892 107.933L73.2598 107.866C73.5861 107.558 73.7903 107.231 73.8669 106.895C73.8791 106.841 73.8885 106.787 73.8982 106.732C73.9364 106.514 73.9838 106.242 74.1937 105.96C74.3112 105.803 74.6219 105.448 74.9424 105.572C75.0289 105.605 75.0873 105.661 75.1278 105.72C75.138 105.71 75.1485 105.7 75.1596 105.689C75.2981 105.554 75.3949 105.505 75.4882 105.457C75.5599 105.421 75.6339 105.383 75.7592 105.283C75.8141 105.239 75.8581 105.2 75.8952 105.166C76.0077 105.066 76.1515 104.948 76.3485 104.995C76.5574 105.052 76.629 105.251 76.6765 105.383C76.7614 105.618 76.7875 105.78 76.8048 105.887C76.811 105.927 76.8182 105.971 76.8237 105.985C76.8694 106.1 77.4433 106.13 77.6592 106.143C78.1446 106.169 78.5646 106.192 78.6656 106.524C78.7381 106.762 78.587 107.021 78.2037 107.315C78.0843 107.407 77.9649 107.47 77.8609 107.517C77.9249 107.578 77.9759 107.66 77.979 107.772C77.986 108.037 77.714 108.241 77.1707 108.378C77.0356 108.412 76.854 108.458 76.6163 108.446C76.5044 108.441 76.4064 108.424 76.3228 108.408C76.3103 108.455 76.2875 108.505 76.2463 108.552C76.1253 108.69 75.93 108.725 75.6649 108.647C75.3722 108.566 75.141 108.455 74.9369 108.357C74.7587 108.272 74.605 108.2 74.476 108.18C74.2362 108.146 74.0125 108.295 73.7449 108.492L73.0742 108.995H73.0742H73.0742Z"
        fill="#EDF2F7"
      />
      <path
        d="M74.9887 102.948L73.9324 103.106L73.85 103.054C73.4688 102.817 73.101 102.702 72.7562 102.714C72.7014 102.715 72.6467 102.72 72.591 102.725C72.3701 102.743 72.0955 102.766 71.7696 102.634C71.5879 102.561 71.1657 102.35 71.2043 102.009C71.2138 101.917 71.2532 101.846 71.3004 101.792C71.2878 101.785 71.2752 101.777 71.2616 101.769C71.0964 101.669 71.0241 101.588 70.9546 101.51C70.901 101.45 70.8459 101.388 70.7172 101.292C70.6608 101.25 70.6114 101.217 70.5699 101.19C70.4442 101.107 70.294 100.997 70.2891 100.795C70.2913 100.578 70.4659 100.459 70.5814 100.379C70.7875 100.238 70.9373 100.171 71.0367 100.127C71.0731 100.111 71.1139 100.093 71.1264 100.084C71.2263 100.011 71.1095 99.4481 71.0668 99.2361C70.9692 98.7599 70.8847 98.3478 71.1805 98.1658C71.3921 98.0354 71.6813 98.1158 72.0631 98.4119C72.1819 98.5042 72.2733 98.6036 72.3453 98.6923C72.3876 98.6151 72.4542 98.5449 72.5618 98.5135C72.8165 98.4394 73.0823 98.6509 73.3528 99.1417C73.4204 99.2635 73.5106 99.4277 73.5597 99.6605C73.5827 99.7702 73.591 99.8693 73.5967 99.9543C73.646 99.9542 73.6997 99.9638 73.7556 99.9917C73.9202 100.074 74.0038 100.254 73.9955 100.53C73.9909 100.834 73.9422 101.085 73.8997 101.308C73.8628 101.502 73.8315 101.669 73.845 101.798C73.8734 102.039 74.0734 102.218 74.3319 102.427L74.9887 102.948H74.9887V102.948Z"
        fill="#EDF2F7"
      />
      <path
        d="M75.1309 99.7741L75.2459 98.7122L75.3164 98.6455C75.6428 98.3371 75.8469 98.0103 75.9235 97.674C75.9357 97.6205 75.9451 97.5664 75.9548 97.5114C75.993 97.293 76.0404 97.0216 76.2503 96.7397C76.3678 96.5827 76.6785 96.2277 76.999 96.3517C77.0855 96.3841 77.1439 96.4402 77.1845 96.4996C77.1947 96.4893 77.2051 96.479 77.2163 96.4678C77.3547 96.3333 77.4515 96.284 77.5448 96.2366C77.6165 96.2 77.6905 96.1625 77.8158 96.0623C77.8708 96.0184 77.9148 95.9788 77.9518 95.9456C78.0643 95.8452 78.2081 95.7276 78.4052 95.7742C78.614 95.8313 78.6856 96.0305 78.7332 96.1624C78.818 96.3977 78.8441 96.5595 78.8614 96.6668C78.8677 96.706 78.8749 96.7501 78.8803 96.7644C78.9261 96.8797 79.4999 96.9095 79.7159 96.9219C80.2012 96.9483 80.6212 96.971 80.7222 97.3034C80.7947 97.5411 80.6437 97.8005 80.2603 98.0946C80.1409 98.1862 80.0216 98.2493 79.9176 98.2965C79.9815 98.357 80.0325 98.4392 80.0356 98.5513C80.0426 98.8164 79.7707 99.0199 79.2273 99.1571C79.0923 99.1915 78.9107 99.2371 78.673 99.2256C78.5611 99.2201 78.463 99.2029 78.3794 99.1869C78.367 99.2346 78.3441 99.2841 78.3029 99.3311C78.182 99.4695 77.9866 99.5047 77.7215 99.4267C77.4289 99.3451 77.1976 99.2342 76.9936 99.1367C76.8153 99.0518 76.6616 98.9792 76.5327 98.9593C76.2928 98.9258 76.0692 99.0739 75.8015 99.271L75.1309 99.7741H75.1309Z"
        fill="#EDF2F7"
      />
      <path
        d="M71.7044 120.12L71.5177 119.936L71.521 119.674L71.5177 119.936L71.2581 119.908C71.2593 119.884 71.2576 119.827 71.2557 119.738C71.2457 119.249 71.2147 117.763 71.4218 115.478C71.5663 113.884 71.8079 112.265 72.1397 110.667C72.4721 109.066 72.8171 107.875 73.0942 106.917C73.3033 106.195 73.5143 105.51 73.7204 104.843C74.2702 103.06 74.7894 101.377 75.1109 99.4561C75.1829 99.0274 75.3323 98.1349 75.0264 97.0816C74.8489 96.4707 74.545 95.8956 74.1228 95.373L74.5314 95.043C74.9973 95.6205 75.3333 96.2574 75.5305 96.9357C75.8697 98.1035 75.7069 99.076 75.6286 99.5432C75.3014 101.498 74.7771 103.198 74.2218 104.997C74.0168 105.662 73.8064 106.344 73.5983 107.063C73.3237 108.012 72.9818 109.193 72.6537 110.773C72.3256 112.352 72.0872 113.951 71.9442 115.526C71.7399 117.781 71.7706 119.246 71.7807 119.727C71.7859 119.984 71.787 120.037 71.7044 120.12V120.12H71.7044Z"
        fill="#EDF2F7"
      />
      <path
        d="M73.0021 95.9772C72.9811 95.9725 72.96 95.9673 72.9388 95.961C72.5078 95.8402 72.1621 95.489 71.911 94.9173C71.7935 94.6485 71.7658 94.3656 71.7104 93.8013C71.7018 93.7143 71.6634 93.2741 71.7106 92.6907C71.7412 92.3095 71.7822 92.1564 71.886 92.0362C72.0012 91.9022 72.1564 91.8255 72.3204 91.787C72.3248 91.7366 72.3413 91.6872 72.3741 91.6404C72.509 91.4463 72.7349 91.5261 72.8574 91.5676C72.9192 91.5898 72.9961 91.6179 73.0827 91.6325C73.2187 91.656 73.2999 91.6321 73.4228 91.5965C73.5405 91.5624 73.6867 91.5199 73.8889 91.5235C74.2878 91.529 74.5831 91.7083 74.6805 91.7673C75.1933 92.0754 75.3703 92.5671 75.5753 93.1363C75.616 93.2504 75.7511 93.6628 75.7826 94.1939C75.8053 94.577 75.7551 94.7339 75.6975 94.846C75.5799 95.0766 75.3999 95.1834 74.953 95.4083C74.4861 95.6439 74.2519 95.7618 74.0598 95.8224C73.6129 95.9627 73.3323 96.0508 73.0021 95.9772V95.9772Z"
        fill="#EDF2F7"
      />
      <path
        d="M132.895 120.626C132.895 120.732 132.81 120.817 132.704 120.817H0.384766V120.436H132.704C132.81 120.436 132.895 120.521 132.895 120.626Z"
        fill="#E2E8F0"
      />
      <path
        d="M37.1956 15.2598L45.3291 19.1638C45.3291 19.1638 53.9505 44.7028 53.9505 46.0042C53.9505 47.3055 56.3905 48.1189 54.9265 49.5829C53.4625 51.0469 57.6515 52.2868 55.3943 53.4562C53.1372 54.6256 39.0567 61.0222 37.491 60.4454C33.1728 58.8544 37.674 53.0085 37.674 53.0085C37.674 53.0085 34.4303 50.3962 34.9183 49.0949C35.4063 47.7935 31.9902 22.9052 31.9902 22.9052L37.1956 15.2598V15.2598Z"
        fill="#E2E8F0"
      />
      <path
        d="M44.1566 10.3831L45.0775 12.2248C45.0775 12.2248 45.9984 8.90968 47.4717 8.17299C48.9451 7.4363 49.1293 6.33127 49.1293 6.33127C49.1293 6.33127 50.971 8.72551 52.2602 8.17299C53.5494 7.62047 54.102 7.25213 52.6286 5.04206C51.1552 2.83199 51.5235 3.93702 51.5235 3.93702C51.5235 3.93702 50.2343 2.09529 50.4185 2.64781C50.6027 3.20033 49.3135 1.17443 49.3135 1.72695C49.3135 2.27946 48.3926 0.621911 48.3926 1.17443C48.3926 1.72695 44.525 -0.114779 45.0775 0.621913C45.63 1.35861 41.7624 -0.851468 39.9207 2.09529C38.0789 5.04206 35.5252 4.49711 36.4337 6.33505C37.3422 8.17299 36.9739 13.1456 36.9739 13.1456H39.5139C39.5139 13.1456 42.1307 8.17299 44.1566 10.3831Z"
        fill="#1A202C"
      />
      <path
        d="M98.3595 25.9956L98.5085 25.9534L105.842 23.8809L111.698 31.8516L107.794 48.4438L109.258 55.1133C109.258 55.1133 97.3835 52.0226 92.6661 54.6253C92.6661 54.6253 92.3407 53.142 92.6661 52.4196C92.9914 51.6972 93.4794 48.691 92.9914 47.3474C92.5034 46.0038 93.9674 39.6597 93.9674 39.6597C93.9674 39.6597 93.4794 35.4303 95.5941 33.3156C97.7088 31.201 98.3595 25.9956 98.3595 25.9956Z"
        fill="#F7B80B"
      />
      <path
        d="M91.6991 55.9563L65.9289 43.1493C65.8007 43.0856 65.6887 42.9978 65.596 42.8885L61.575 38.1496C61.3084 37.8353 61.2506 37.3918 61.428 37.0197L68.9677 21.2011C69.0998 20.9239 69.3457 20.7192 69.6423 20.6395C69.9392 20.5599 70.2545 20.6138 70.5078 20.7875L88.6818 33.254C89.0549 33.5098 89.2216 33.9739 89.0966 34.4086L85.4068 47.2493C85.3059 47.6005 85.4345 47.9838 85.7268 48.203L93.0262 53.6776C93.4193 53.9724 93.5551 54.5102 93.3493 54.9562L93.1184 55.4565C92.9986 55.7161 92.784 55.9113 92.5145 56.0063C92.4002 56.0466 92.2823 56.0666 92.1649 56.0666C92.0053 56.0665 91.8467 56.0296 91.6991 55.9563V55.9563Z"
        fill="#F7B80B"
      />
      <path
        d="M91.4926 52.8407L84.3189 46.8724C84.2571 46.8203 84.2083 46.7569 84.1741 46.6869C84.1091 46.5552 84.0961 46.3974 84.1449 46.2526L88.2327 34.2916C88.3385 33.9825 88.2246 33.6376 87.9546 33.4522L70.9232 21.7921C70.7491 21.6717 70.5311 21.6343 70.3262 21.688C70.1196 21.7417 69.9488 21.8816 69.8544 22.0719L62.6742 36.6519C62.5652 36.8715 62.5734 37.1236 62.6937 37.3335C62.7084 37.3628 62.7263 37.3904 62.7474 37.4164L66.3587 42.1826C66.4286 42.2737 66.5197 42.3485 66.6222 42.399L90.7069 54.0607C90.8078 54.1095 90.9168 54.1339 91.0257 54.1339C91.2144 54.1339 91.4031 54.0607 91.543 53.9192C91.5593 53.9013 91.5756 53.885 91.5918 53.8655C91.7057 53.7272 91.7643 53.5499 91.7561 53.3694C91.7464 53.1644 91.6504 52.9724 91.4926 52.8407Z"
        fill="#EDF2F7"
      />
      <path
        d="M65.5004 35.756C65.56 35.756 65.6173 35.7233 65.6459 35.6665L71.484 24.068C71.5244 23.9877 71.4921 23.8899 71.4119 23.8495C71.3314 23.8089 71.2339 23.8414 71.1934 23.9216L65.3553 35.5202C65.3149 35.6005 65.3472 35.6983 65.4274 35.7386C65.4509 35.7505 65.4759 35.756 65.5004 35.756Z"
        fill="#F7B80B"
      />
      <path
        d="M68.5903 37.7092C68.6498 37.7092 68.7071 37.6765 68.7357 37.6197L74.5738 26.0211C74.6143 25.9408 74.5819 25.843 74.5017 25.8027C74.4212 25.762 74.3236 25.7946 74.2833 25.8748L68.4452 37.4734C68.4047 37.5537 68.4371 37.6514 68.5173 37.6918C68.5408 37.7036 68.5657 37.7092 68.5903 37.7092Z"
        fill="#F7B80B"
      />
      <path
        d="M71.1938 39.3361C71.2534 39.3361 71.3106 39.3034 71.3392 39.2466L77.1773 27.648C77.2178 27.5677 77.1854 27.47 77.1052 27.4296C77.0247 27.389 76.9272 27.4215 76.8868 27.5017L71.0487 39.1003C71.0082 39.1806 71.0406 39.2784 71.1208 39.3187C71.1443 39.3306 71.1692 39.3361 71.1938 39.3361V39.3361Z"
        fill="#F7B80B"
      />
      <path
        d="M61.9801 37.7644C63.9987 37.168 65.9426 37.7262 66.3219 39.0108C66.7012 40.2955 65.3724 41.82 63.353 42.4163C62.5497 42.6655 61.6983 42.7188 60.8701 42.5716L52.2855 44.9903L51.2363 40.9376L59.8106 38.9834C60.426 38.4096 61.17 37.9916 61.9801 37.7644Z"
        fill="#FFB6B6"
      />
      <path
        d="M46.7962 23.4018L45.4507 39.4982L57.5028 39.6278L59.8419 43.2295C59.8419 43.2295 45.234 51.6878 40.8476 47.3659C36.4613 43.044 34.5096 24.857 38.4094 21.744C42.3093 18.6309 46.7962 23.4018 46.7962 23.4018Z"
        fill="#E2E8F0"
      />
      <path
        d="M86.2765 52.465C84.1777 52.3069 82.5579 51.0958 82.6588 49.7601C82.7596 48.4244 84.5426 47.4701 86.6422 47.6285C87.4817 47.6803 88.2967 47.9324 89.0189 48.3637L97.9033 49.1458L97.4474 53.3072L88.7373 52.0944C87.9584 52.4127 87.1146 52.5397 86.2765 52.465Z"
        fill="#A0616A"
      />
      <path
        d="M112.26 32.9156L110.809 51.0488C110.809 51.0488 110.351 54.9566 104.265 54.7116C98.1794 54.4666 90.0957 52.5455 90.0957 52.5455L90.3878 48.3137L97.1905 48.6832C97.1905 48.6832 100.306 47.7478 100.961 48.2306C101.615 48.7135 101.36 49.3337 101.939 48.5483C102.519 47.7629 102.183 47.3126 102.761 47.9412C103.339 48.5698 103.51 49.7072 103.759 48.2737C104.008 46.8402 104.628 47.0956 104.628 47.0956L104.264 33.8894C104.264 33.8894 105.743 27.5009 109.175 29.4261C112.606 31.3512 112.26 32.9156 112.26 32.9156Z"
        fill="#F7B80B"
      />
    </g>
    <defs>
      <clipPath id="clip0_942_38771">
        <rect
          width="132.51"
          height="120.372"
          fill="white"
          transform="translate(0.384766 0.445312)"
        />
      </clipPath>
    </defs>
  </svg>
);
