export const UserIcon = () => (
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3337 14.7988H0.666992V13.4655C0.666992 12.5814 1.01818 11.7336 1.6433 11.1085C2.26842 10.4834 3.11627 10.1322 4.00033 10.1322H8.00033C8.88438 10.1322 9.73223 10.4834 10.3573 11.1085C10.9825 11.7336 11.3337 12.5814 11.3337 13.4655V14.7988ZM6.00033 8.79883C5.47504 8.79883 4.95489 8.69536 4.46959 8.49435C3.98429 8.29333 3.54333 7.99869 3.1719 7.62726C2.80046 7.25582 2.50583 6.81486 2.30481 6.32956C2.10379 5.84426 2.00033 5.32412 2.00033 4.79883C2.00033 4.27354 2.10379 3.7534 2.30481 3.26809C2.50583 2.78279 2.80046 2.34184 3.1719 1.9704C3.54333 1.59897 3.98429 1.30433 4.46959 1.10331C4.95489 0.902291 5.47504 0.798828 6.00033 0.798828C7.06119 0.798828 8.07861 1.22026 8.82875 1.9704C9.5789 2.72055 10.0003 3.73796 10.0003 4.79883C10.0003 5.85969 9.5789 6.87711 8.82875 7.62726C8.07861 8.3774 7.06119 8.79883 6.00033 8.79883Z"
      fill="#2D3748"
    />
  </svg>
);
