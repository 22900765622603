export const AnimationEvenemIcon = () => (<svg width="148" height="121" viewBox="0 0 148 121" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_942_3961)">
      <path d="M49.7526 43.0977L48.506 43.4941C48.4903 43.4263 48.472 43.3585 48.4512 43.2906C48.2895 42.7703 48.2165 43.7053 47.7966 43.3585C47.8266 43.807 47.1811 39.196 47.1185 39.6394C47.0924 39.8259 47.318 42.9151 47.275 43.0977L37.594 43.1902C37.6487 42.2579 37.1519 42.6165 36.7125 41.7936C36.453 42.5669 36.4125 42.0062 36.0018 42.7065C35.8296 43.0025 35.6458 43.2867 35.4476 43.5645C35.3524 43.7014 35.252 43.8357 35.1489 43.9687L35.0172 44.0105L34.3652 31.2311C34.3652 27.0179 38.0165 24.9719 40.8853 27.7103C42.4096 29.1653 46.1379 27.2604 46.9972 28.6401C47.8553 30.021 48.1878 31.9275 48.1878 34.0348L49.7526 43.0977Z" fill="#1A202C"/>
      <path d="M34.4854 115.684L36.5856 115.734L37.7564 106.298L34.6569 106.225L34.4854 115.684Z" fill="#FFB7B7"/>
      <path d="M41.6916 120.067C41.6916 120.421 41.4398 120.709 41.1292 120.709H36.9603C36.9603 120.709 36.55 119.06 34.8774 118.35L34.7619 120.709H32.6113L32.8719 116.916C32.8719 116.916 32.2966 114.887 33.4914 113.85C34.6861 112.812 33.7184 112.957 33.7184 112.957L34.1884 110.609L37.4382 110.992L37.4621 114.677L39.0392 118.334L41.3524 119.477C41.5583 119.579 41.6915 119.81 41.6915 120.067H41.6916H41.6916Z" fill="#1A202C"/>
      <path d="M40.876 114.147L42.8949 114.728L46.4218 105.898L43.4423 105.041L40.876 114.147Z" fill="#FFB7B7"/>
      <path d="M46.7341 120.214C46.6441 120.557 46.3276 120.771 46.0271 120.693L41.9947 119.635C41.9947 119.635 42.0163 117.935 40.5785 116.824L39.8683 119.077L37.7881 118.531L39.0026 114.928C39.0026 114.928 38.9611 112.82 40.3799 112.12C41.7988 111.419 40.8261 111.314 40.8261 111.314L41.8764 109.162L44.9229 110.356L44.0109 113.927L44.6082 117.865L46.5557 119.557C46.729 119.708 46.7991 119.966 46.7341 120.214L46.7341 120.214L46.7341 120.214Z" fill="#1A202C"/>
      <path d="M45.9712 39.6465L39.5815 40.2985L35.5391 63.5099L47.7968 65.4659L45.9712 39.6465Z" fill="#F7B80B"/>
      <path d="M33.7136 43.9505L34.3656 59.0771L28.6279 69.118L32.8008 72.8996C32.8008 72.8996 40.4944 57.6427 40.7553 53.4698C41.0161 49.297 39.5161 40.6758 39.5161 40.6758L33.7136 43.9505Z" fill="#1A202C"/>
      <path d="M51.9696 43.9505L51.3176 59.0771L57.0552 69.118L48.188 73.1604C48.188 73.1604 45.1887 57.6427 44.9279 53.4698C44.6671 49.297 46.1671 40.6758 46.1671 40.6758L51.9696 43.9505Z" fill="#1A202C"/>
      <path d="M36.3214 62.0762L33.3222 62.4674L31.627 80.5932L33.9742 108.037H37.8143L40.6246 77.4635L45.5799 88.9388L41.9543 108.037L45.5799 108.407L52.8824 88.5476L47.7967 65.4666L36.3214 62.0762Z" fill="#1A202C"/>
      <path d="M48.6302 78.44C48.7721 77.9403 49.0151 77.5142 49.3044 77.2102L50.3553 72.3652L52.6461 72.9328L51.3334 77.7864C51.4197 78.197 51.4024 78.6873 51.2605 79.1869C50.9364 80.3283 50.0848 81.0863 49.3585 80.8801C48.6322 80.6738 48.3061 79.5813 48.6302 78.44V78.44Z" fill="#FFB6B6"/>
      <path d="M48.9473 42.9706C48.9473 42.9706 53.1768 42.7552 53.4848 45.5599C53.7928 48.3647 54.7781 64.3386 54.7781 64.3386L52.4942 75.1207L49.2374 74.6681L50.6767 60.9438L48.9473 42.9706H48.9473Z" fill="#1A202C"/>
      <path d="M12.6962 51.3493C13.1776 51.549 13.574 51.841 13.8432 52.1648L18.5494 53.7781L17.7162 55.995L13.032 54.1198C12.6126 54.1579 12.126 54.0834 11.6446 53.8837C10.5448 53.4273 9.88871 52.49 10.1791 51.7902C10.4695 51.0903 11.5965 50.8929 12.6962 51.3493H12.6962H12.6962Z" fill="#FFB6B6"/>
      <path d="M39.5695 44.6214L39.5695 44.6214L28.8332 59.1136L16.248 55.6481L16.7952 52.365L26.3784 54.1801L31.7247 45.9609C33.4705 42.5863 36.9113 41.9067 39.5695 44.6214V44.6214Z" fill="#1A202C"/>
      <path d="M42.3055 38.3278C44.9061 38.3278 47.0143 36.2196 47.0143 33.619C47.0143 31.0184 44.9061 28.9102 42.3055 28.9102C39.7049 28.9102 37.5967 31.0184 37.5967 33.619C37.5967 36.2196 39.7049 38.3278 42.3055 38.3278Z" fill="#FFB7B7"/>
      <path d="M49.2306 33.4477H47.6442L47.2134 32.867C47.004 32.867 46.8498 33.4253 46.6495 33.3827C46.0384 33.2527 45.4775 32.9348 45.018 32.4915C44.9792 32.454 44.939 32.4181 44.8974 32.3837L44.8981 32.3943C44.9348 32.9684 44.4613 33.4477 43.886 33.4477H40.8268C39.9916 33.4477 39.3825 34.2381 39.5951 35.0458L39.3201 44.9231C39.3201 42.9071 30.4007 32.6132 36.4513 28.4925C37.8521 27.5385 38.869 27.7101 40.885 27.7101C43.9836 26.0575 47.8818 27.5997 48.8886 30.9641C49.1141 31.7177 49.2306 32.5509 49.2306 33.4477Z" fill="#1A202C"/>
      <path d="M121.409 38.2255C121.183 37.987 121.024 35.3608 120.743 35.1854C120.855 35.5619 120.804 38.3705 120.787 38.7634C118.318 40.4471 116.643 36.1208 114.897 36.1208C111.732 36.1208 107.881 28.9952 107.881 25.8311C107.881 22.667 111.867 20.9866 114.78 19.7508C118.638 18.1138 122.166 21.7623 122.614 24.8957C123.783 33.0807 122.82 36.6587 121.409 38.2255Z" fill="#1A202C"/>
      <path d="M113.834 31.7028C116.434 31.7028 118.543 29.5946 118.543 26.994C118.543 24.3934 116.434 22.2852 113.834 22.2852C111.233 22.2852 109.125 24.3934 109.125 26.994C109.125 29.5946 111.233 31.7028 113.834 31.7028Z" fill="#A0616A"/>
      <path d="M106.981 25.9722L109.328 26.2635L109.905 25.9181L109.969 26.3431C110.299 26.384 110.634 26.256 110.834 25.9898C110.993 25.7773 111.189 25.5926 111.413 25.45C111.383 25.6048 111.36 25.7608 111.345 25.9173C111.313 26.2437 111.542 26.5382 111.867 26.5787L115.321 27.0074C116.15 27.1103 116.657 27.9696 116.347 28.745C116.347 28.745 117.186 26.9308 117.936 27.4668C119.573 28.6361 124.289 26.16 120.274 22.6289C119.001 21.5096 117.971 21.5546 115.97 21.3063C113.099 19.2846 109.04 20.3349 107.627 23.5496C107.31 24.2696 107.092 25.0822 106.981 25.9722V25.9722Z" fill="#1A202C"/>
      <path d="M117.363 55.5417H103.615C104.768 52.9704 105.476 50.5215 104.369 48.5736L117.363 47.8203C116.714 50.4499 116.634 53.0305 117.363 55.5417Z" fill="#A0616A"/>
      <path d="M115.669 32.7556L110.207 32.3789L107.947 35.2038L106.802 35.4121C105.084 35.7244 103.861 37.257 103.938 39.0008L104.384 49.1298L117.438 50.0816L120.565 36.8916L116.799 35.3921L115.669 32.7556V32.7556Z" fill="#E2E8F0"/>
      <path d="M118.042 115.657L115.804 115.71L114.557 105.656L117.859 105.578L118.042 115.657Z" fill="#A0616A"/>
      <path d="M110.364 120.327C110.364 120.705 110.633 121.011 110.963 121.011H115.405C115.405 121.011 115.842 119.254 117.625 118.498L117.748 121.011H120.039L119.761 116.97C119.761 116.97 120.374 114.808 119.101 113.703C117.828 112.597 118.86 112.751 118.86 112.751L118.359 110.25L114.896 110.657L114.871 114.584L113.19 118.481L110.726 119.698C110.506 119.807 110.364 120.054 110.364 120.327L110.364 120.327Z" fill="#1A202C"/>
      <path d="M109.857 115.423L107.619 115.476L106.372 105.422L109.675 105.344L109.857 115.423Z" fill="#A0616A"/>
      <path d="M102.18 120.092C102.18 120.47 102.448 120.777 102.779 120.777H107.221C107.221 120.777 107.658 119.02 109.44 118.263L109.563 120.777H111.855L111.577 116.735C111.577 116.735 112.19 114.573 110.917 113.468C109.644 112.363 110.675 112.517 110.675 112.517L110.174 110.016L106.712 110.423L106.686 114.349L105.006 118.246L102.541 119.464C102.322 119.572 102.18 119.819 102.18 120.092L102.18 120.092Z" fill="#1A202C"/>
      <path d="M104.139 53.6594L102.268 59.7397L101.566 84.5285L106.428 107.484L110.176 106.967L111.081 75.2999L113.408 106.967L118.681 106.768C118.681 106.768 123.081 60.909 117.544 55.2655L117.044 53.1328L104.139 53.6594Z" fill="#1A202C"/>
      <path d="M100.727 44.2746L93.0121 49.905L84.7746 53.1103L84.2021 51.1653L90.952 47.1594L99.3352 40.7363L100.727 44.2746Z" fill="#A0616A"/>
      <path d="M83.671 53.928C84.7842 53.372 85.4191 52.3855 85.089 51.7245C84.7589 51.0635 83.5888 50.9784 82.4756 51.5344C81.3624 52.0903 80.7276 53.0768 81.0577 53.7378C81.3878 54.3988 82.5578 54.4839 83.671 53.928Z" fill="#A0616A"/>
      <path d="M105.665 36.2422C105.665 36.2422 108.062 39.533 106.33 40.8273C104.598 42.1216 99.3484 48.1953 99.3484 48.1953L95.0098 43.4238L105.665 36.2422V36.2422Z" fill="#E2E8F0"/>
      <path d="M12.0988 0.00390625C5.57647 0.00390625 0.289062 5.29131 0.289062 11.8137C0.289062 17.2988 5.098 24.4416 9.60741 27.9581C10.0126 28.2741 10.4652 28.4942 10.937 28.6256L11.3799 30.22C11.4719 30.5515 11.7737 30.7809 12.1178 30.7809H12.1739C12.5328 30.7809 12.8436 30.5315 12.9215 30.1811L13.2855 28.543C13.6793 28.4031 14.0561 28.1998 14.3988 27.9314C19.4451 23.9799 23.9086 17.4843 23.9086 11.8137C23.9086 5.29131 18.6212 0.00390625 12.0988 0.00390625Z" fill="#F7B80B"/>
      <path d="M14.067 29.3486C12.6478 32.904 11.5065 32.0939 10.4883 29.3486V28.6328H14.067V29.3486H14.067Z" fill="#2D3748"/>
      <path d="M12.4566 28.9938L12.3761 35.7415L12.3725 35.9705L12.3564 59.4111V60.499L12.3528 60.8426H11.9932L11.9967 60.5169V59.4111L12.0146 35.9705L12.0343 34.3547L12.0987 28.9902L12.4566 28.9938Z" fill="#2D3748"/>
      <path d="M12.3922 60.8416H12.0307L11.9967 60.5159L11.9896 59.4101L11.8446 35.9695L11.1074 28.9516L11.4635 28.9141L12.0343 34.3537L12.2043 35.9695L12.2759 59.4101L12.2777 59.7554L12.3564 60.498L12.3922 60.8416Z" fill="#2D3748"/>
      <path d="M13.4478 28.9516L12.7106 35.9695L12.5031 59.4101L12.4905 60.8416H12.1631L12.2776 59.7554L12.2794 59.4101L12.351 35.9695L12.376 35.7405L13.0918 28.9141L13.4478 28.9516Z" fill="#2D3748"/>
      <path d="M143.613 36.2057C139.688 33.0193 133.923 33.6178 130.737 37.5425C128.057 40.8431 127.461 47.4905 128.457 51.8095C128.546 52.1977 128.711 52.5512 128.931 52.8607L128.418 54.0365C128.312 54.2809 128.381 54.5664 128.588 54.7345L128.622 54.7619C128.838 54.9372 129.147 54.939 129.365 54.7662L130.384 53.9583C130.69 54.0666 131.016 54.1283 131.353 54.1342C136.32 54.2218 142.179 52.4937 144.95 49.0815C148.136 45.1568 147.537 39.3921 143.613 36.2057H143.613Z" fill="#559EC2"/>
      <path d="M130.46 54.8255C126.595 57.3757 126.821 55.8535 128.307 53.0772L128.657 52.6465L130.81 54.3948L130.46 54.8255Z" fill="#2D3748"/>
      <path d="M129.665 53.8235L126.32 57.8444L126.206 57.9805L114.745 72.0776L114.214 72.7323L114.044 72.9372L113.827 72.7615L113.988 72.5673L114.529 71.9019L125.991 57.8057L126.792 56.843L129.452 53.6465L129.665 53.8235Z" fill="#2D3748"/>
      <path d="M114.067 72.9575L113.85 72.7809L113.988 72.5684L114.524 71.8995L125.889 57.7237L128.873 53.1406L129.106 53.292L126.792 56.844L126.105 57.8994L114.696 72.0393L114.529 72.248L114.213 72.7333L114.067 72.9575Z" fill="#2D3748"/>
      <path d="M130.282 54.2845L126.41 58.1472L114.834 72.1508L114.127 73.006L113.93 72.846L114.529 72.2484L114.699 72.0415L126.194 57.9715L126.321 57.8459L130.086 54.0879L130.282 54.2845Z" fill="#2D3748"/>
      <path d="M70.8059 15.5372C65.7907 17.9964 63.7186 24.0557 66.1778 29.0709C68.246 33.2886 74.6369 36.9677 79.4302 37.9714C79.8609 38.0616 80.2919 38.0602 80.7042 37.9834L81.6459 39.0423C81.8417 39.2625 82.1602 39.3251 82.4248 39.1954L82.4679 39.1742C82.7439 39.0389 82.8889 38.73 82.8167 38.4312L82.4789 37.0343C82.7289 36.7783 82.942 36.48 83.1044 36.1443C85.4947 31.2031 86.4777 24.5256 84.3396 20.1653C81.8804 15.15 75.8211 13.078 70.8059 15.5372H70.8059Z" fill="#F7B80B"/>
      <path d="M83.3839 37.359C83.3079 39.2367 82.737 40.2745 80.6322 38.7083L80.3623 38.1579L83.1141 36.8086L83.3839 37.359Z" fill="#2D3748"/>
      <path d="M82.0109 37.6934L84.4932 42.9122L84.5768 43.0897L82.6757 62.1926L83.0859 63.0291L83.2127 63.2947L82.9362 63.4303L82.8161 63.1785L82.3992 62.3282L84.3016 43.2246L83.7075 41.9747L81.7344 37.8255L82.0109 37.6934Z" fill="#2D3748"/>
      <path d="M83.243 63.2804L82.9651 63.4167L82.8162 63.1791L82.3937 62.3315L84.1709 43.2892L80.958 38.171L81.2176 38.0078L83.7075 41.9753L84.4475 43.1536L82.6139 62.2235L82.7455 62.4884L83.086 63.0297L83.243 63.2804Z" fill="#2D3748"/>
      <path d="M82.7577 37.2871L84.8369 42.9613L82.7886 62.1365L83.3187 63.2419L83.067 63.3654L82.7455 62.487L82.6167 62.2208L84.5603 43.0969L84.4932 42.9114L82.4697 37.3925L82.7577 37.2871Z" fill="#2D3748"/>
      <path d="M-12.9658 120.673C-12.9658 120.803 -12.8225 120.907 -12.6441 120.907H96.3912C96.5696 120.907 96.7129 120.803 96.7129 120.673C96.7129 120.544 96.5696 120.439 96.3912 120.439H-12.6441C-12.8225 120.439 -12.9658 120.544 -12.9658 120.673Z" fill="#CCCCCC"/>
      <path d="M88.7617 120.71C88.7617 120.864 88.8857 120.988 89.04 120.988H183.352C183.506 120.988 183.63 120.864 183.63 120.71C183.63 120.556 183.506 120.432 183.352 120.432H89.04C88.8857 120.432 88.7617 120.556 88.7617 120.71Z" fill="#CCCCCC"/>
      <path d="M122.223 47.8698L122.1 57.4202L119.724 65.9339L117.824 65.2253L118.668 57.4217L118.557 46.8613L122.223 47.8698Z" fill="#A0616A"/>
      <path d="M119.715 67.3056C119.938 66.0814 119.53 64.9817 118.803 64.8494C118.076 64.717 117.306 65.6022 117.083 66.8264C116.86 68.0506 117.269 69.1503 117.996 69.2826C118.723 69.4149 119.493 68.5298 119.715 67.3056Z" fill="#A0616A"/>
      <path d="M119.339 36.498C119.339 36.498 123.41 36.5541 123.406 38.7165C123.403 40.879 122.956 48.9271 122.956 48.9271L116.536 49.5365L119.339 36.498Z" fill="#E2E8F0"/>
    </g>
    <defs>
      <clipPath id="clip0_942_3961">
        <rect width="146.244" height="120.37" fill="white" transform="translate(0.87793 0.445312)"/>
      </clipPath>
    </defs>
  </svg>

);