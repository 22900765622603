export const HighTechTechnologyIcon = () => (
  <svg
    width="130"
    height="101"
    viewBox="0 0 130 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_905_17461)">
      <path
        d="M89.8215 92.0093C93.4789 92.0093 96.4439 89.0444 96.4439 85.387C96.4439 81.7296 93.4789 78.7646 89.8215 78.7646C86.1641 78.7646 83.1992 81.7296 83.1992 85.387C83.1992 89.0444 86.1641 92.0093 89.8215 92.0093Z"
        fill="#F7B80B"
      />
      <path
        d="M33.4094 100.349C37.0668 100.349 40.0317 97.3842 40.0317 93.7268C40.0317 90.0694 37.0668 87.1045 33.4094 87.1045C29.752 87.1045 26.7871 90.0694 26.7871 93.7268C26.7871 97.3842 29.752 100.349 33.4094 100.349Z"
        fill="#F7B80B"
      />
      <path
        d="M123.524 77.7588C123.317 77.4096 122.421 77.7835 122.256 77.4666C122.092 77.1508 122.924 76.6697 123.4 75.5707C123.486 75.3725 124.026 74.1235 123.626 73.7381C122.868 73.0076 119.398 76.0222 118.183 75.0357C117.917 74.8192 117.731 74.387 116.924 73.6853C116.603 73.4061 116.409 73.2801 116.221 73.327C115.955 73.3935 115.934 73.7501 115.648 74.4513C115.219 75.5028 114.966 75.4393 114.716 76.2783C114.531 76.9011 114.573 77.2632 114.355 77.3337C114.047 77.4336 113.763 76.773 113.372 76.8321C112.972 76.8925 112.731 77.6616 112.655 78.2375C112.512 79.3182 112.909 80.0313 113.129 80.7811C113.368 81.596 113.51 82.8353 112.958 84.5896L107.494 99.9843C108.7 96.8254 112.193 88.1658 113.66 84.9479C114.084 84.0189 114.552 83.0767 115.512 82.7048C116.437 82.3468 117.707 82.5606 119.326 82.1705C119.515 82.1249 120.04 81.9914 120.106 81.6828C120.161 81.4276 119.852 81.2852 119.9 81.0462C119.963 80.7256 120.57 80.7217 121.316 80.3518C121.842 80.091 122.17 79.7809 122.452 79.5141C122.537 79.4337 123.799 78.2236 123.524 77.7588H123.524H123.524Z"
        fill="#F2F2F2"
      />
      <path
        d="M102.027 85.8637C101.796 85.8646 101.721 86.4122 101.518 86.4016C101.315 86.391 101.32 85.8437 100.917 85.2932C100.845 85.1939 100.388 84.5683 100.083 84.6535C99.5058 84.8149 99.9841 87.3878 99.1496 87.6992C98.9664 87.7675 98.7006 87.7342 98.1234 87.9276C97.8938 88.0046 97.776 88.0634 97.7448 88.1691C97.7006 88.3191 97.8695 88.4324 98.1312 88.7749C98.5237 89.2886 98.4194 89.3947 98.7591 89.7593C99.0113 90.0299 99.2013 90.1136 99.173 90.241C99.1329 90.4213 98.7267 90.3701 98.6429 90.5791C98.5574 90.7924 98.8653 91.1328 99.1259 91.3363C99.6151 91.7183 100.08 91.7293 100.511 91.8377C100.98 91.9555 101.629 92.2434 102.331 93.0206L108.31 100.144C107.108 98.6407 103.865 94.4279 102.71 92.779C102.376 92.3031 102.048 91.8016 102.143 91.2228C102.234 90.6657 102.705 90.104 102.981 89.1967C103.013 89.0908 103.099 88.7947 102.967 88.6732C102.857 88.5728 102.698 88.6832 102.595 88.5909C102.456 88.4672 102.629 88.1682 102.663 87.6954C102.686 87.362 102.629 87.1116 102.579 86.8964C102.564 86.8315 102.335 85.8625 102.027 85.8637V85.8637Z"
        fill="#F2F2F2"
      />
      <path
        d="M106.638 86.8981L106.499 85.655L106.562 85.5608C106.857 85.125 107.007 84.6995 107.008 84.2955C107.009 84.2313 107.006 84.1671 107.003 84.1016C106.991 83.8424 106.976 83.52 107.144 83.1443C107.238 82.9348 107.503 82.4498 107.901 82.5098C108.008 82.5249 108.089 82.5741 108.15 82.6317C108.159 82.6173 108.169 82.6029 108.179 82.5873C108.303 82.3982 108.401 82.3172 108.495 82.2391C108.568 82.1791 108.643 82.1173 108.761 81.9708C108.812 81.9066 108.852 81.8502 108.886 81.8028C108.989 81.6593 109.124 81.4882 109.361 81.4912C109.614 81.5033 109.747 81.7127 109.835 81.8514C109.992 82.0987 110.063 82.277 110.11 82.3952C110.127 82.4384 110.146 82.487 110.156 82.502C110.238 82.6221 110.902 82.5098 111.152 82.469C111.713 82.3754 112.199 82.2944 112.399 82.6485C112.543 82.9018 112.436 83.2367 112.073 83.6707C111.96 83.8057 111.839 83.9084 111.733 83.9888C111.821 84.0416 111.9 84.1227 111.932 84.2499C112.008 84.5512 111.749 84.8531 111.163 85.1485C111.017 85.2223 110.821 85.3207 110.547 85.3681C110.417 85.3903 110.301 85.3958 110.201 85.3988C110.199 85.4564 110.186 85.5188 110.151 85.583C110.048 85.7721 109.833 85.8621 109.51 85.8405C109.155 85.8219 108.863 85.7541 108.604 85.6947C108.379 85.643 108.185 85.5992 108.032 85.6094C107.75 85.6322 107.532 85.8585 107.276 86.152L106.638 86.8981H106.638Z"
        fill="#F2F2F2"
      />
      <path
        d="M107.284 79.4969L106.117 79.9469L106.01 79.9092C105.514 79.735 105.064 79.6978 104.673 79.7985C104.61 79.8145 104.549 79.8337 104.487 79.8532C104.239 79.9306 103.931 80.0269 103.525 79.9596C103.298 79.9216 102.762 79.7885 102.719 79.3884C102.706 79.2809 102.733 79.1901 102.774 79.1159C102.757 79.1109 102.741 79.1056 102.723 79.0999C102.509 79.028 102.406 78.9536 102.306 78.882C102.23 78.8269 102.151 78.77 101.98 78.6934C101.904 78.6598 101.84 78.6352 101.785 78.6144C101.62 78.5512 101.421 78.4645 101.363 78.2344C101.311 77.9864 101.48 77.8049 101.592 77.6847C101.791 77.4701 101.945 77.3561 102.048 77.2806C102.085 77.2531 102.127 77.2218 102.139 77.2085C102.235 77.0991 101.958 76.4857 101.855 76.2542C101.622 75.7348 101.42 75.2854 101.712 75.0019C101.921 74.7989 102.272 74.817 102.784 75.0582C102.943 75.1334 103.073 75.2238 103.178 75.3067C103.206 75.2077 103.265 75.1105 103.38 75.0471C103.652 74.8976 104.01 75.0715 104.444 75.5635C104.552 75.6856 104.697 75.8502 104.813 76.1038C104.867 76.2233 104.902 76.3346 104.93 76.4302C104.986 76.4176 105.05 76.4148 105.121 76.4325C105.33 76.4841 105.471 76.6685 105.532 76.9864C105.605 77.3348 105.613 77.6351 105.621 77.8998C105.628 78.1309 105.635 78.3298 105.684 78.4747C105.778 78.7424 106.052 78.8958 106.401 79.0686L107.284 79.4969L107.284 79.4969Z"
        fill="#F2F2F2"
      />
      <path
        d="M106.638 75.8346L106.499 74.5916L106.562 74.4973C106.857 74.0616 107.007 73.636 107.008 73.232C107.009 73.1678 107.006 73.1036 107.003 73.0382C106.991 72.7789 106.976 72.4566 107.144 72.0808C107.238 71.8713 107.503 71.3863 107.901 71.4464C108.008 71.4614 108.089 71.5106 108.15 71.5682C108.159 71.5538 108.169 71.5394 108.179 71.5238C108.303 71.3347 108.401 71.2537 108.495 71.1757C108.568 71.1156 108.643 71.0538 108.761 70.9074C108.812 70.8431 108.852 70.7867 108.886 70.7393C108.989 70.5958 109.124 70.4248 109.361 70.4278C109.614 70.4398 109.747 70.6493 109.835 70.7879C109.992 71.0352 110.063 71.2135 110.11 71.3317C110.127 71.3749 110.146 71.4236 110.156 71.4386C110.238 71.5586 110.902 71.4464 111.152 71.4055C111.713 71.3119 112.199 71.2309 112.399 71.585C112.543 71.8383 112.436 72.1732 112.073 72.6072C111.96 72.7423 111.839 72.8449 111.733 72.9253C111.821 72.9781 111.9 73.0592 111.932 73.1864C112.008 73.4877 111.749 73.7897 111.163 74.085C111.017 74.1588 110.821 74.2572 110.547 74.3047C110.417 74.3269 110.301 74.3323 110.201 74.3353C110.199 74.3929 110.186 74.4553 110.151 74.5195C110.048 74.7086 109.833 74.7986 109.51 74.777C109.155 74.7584 108.863 74.6906 108.604 74.6312C108.379 74.5796 108.185 74.5357 108.032 74.546C107.75 74.5688 107.532 74.795 107.276 75.0886L106.638 75.8346H106.638Z"
        fill="#F2F2F2"
      />
      <path
        d="M107.91 99.9645L107.649 99.8013L107.586 99.5005L107.649 99.8013L107.345 99.8361C107.341 99.8079 107.324 99.743 107.299 99.6416C107.163 99.0858 106.749 97.3943 106.403 94.7305C106.162 92.871 106.025 90.9593 105.997 89.0475C105.969 87.1328 106.059 85.6833 106.132 84.5182C106.187 83.6395 106.254 82.8028 106.319 81.9877C106.493 79.81 106.657 77.753 106.535 75.4758C106.508 74.9674 106.451 73.9092 105.833 72.7831C105.475 72.1301 104.981 71.5503 104.365 71.0605L104.748 70.5791C105.427 71.1205 105.974 71.7628 106.372 72.4878C107.058 73.7363 107.12 74.8894 107.149 75.4434C107.273 77.7608 107.107 79.8376 106.932 82.0363C106.867 82.8484 106.8 83.6815 106.746 84.5566C106.674 85.7109 106.584 87.1478 106.612 89.038C106.639 90.9269 106.774 92.8152 107.012 94.6513C107.354 97.2815 107.762 98.9478 107.896 99.4952C107.968 99.7875 107.983 99.8487 107.91 99.9645L107.91 99.9645Z"
        fill="#F2F2F2"
      />
      <path
        d="M103.238 72.0367C103.212 72.0367 103.187 72.0361 103.161 72.0343C102.638 72.0061 102.153 71.6927 101.72 71.1033C101.517 70.826 101.413 70.5097 101.206 69.8788C101.174 69.7816 101.018 69.2882 100.923 68.6094C100.861 68.1658 100.869 67.9803 100.957 67.8164C101.055 67.634 101.212 67.5067 101.39 67.4209C101.382 67.3621 101.389 67.3015 101.414 67.2396C101.519 66.9833 101.797 67.0169 101.948 67.0332C102.024 67.0428 102.119 67.0554 102.222 67.05C102.383 67.0422 102.47 66.9941 102.602 66.9221C102.727 66.8531 102.884 66.7672 103.116 66.7198C103.573 66.6244 103.956 66.754 104.083 66.7967C104.747 67.0181 105.075 67.5349 105.454 68.1334C105.53 68.2534 105.79 68.6904 105.961 69.2894C106.085 69.7216 106.067 69.9136 106.03 70.0565C105.954 70.35 105.776 70.5181 105.322 70.889C104.849 71.2774 104.611 71.4718 104.407 71.5901C103.932 71.8644 103.634 72.0367 103.238 72.0367V72.0367Z"
        fill="#F2F2F2"
      />
      <path
        d="M0.706055 100.033C0.706055 100.195 0.836056 100.325 0.997942 100.325H129.101C129.262 100.325 129.392 100.195 129.392 100.033C129.392 99.8712 129.262 99.7412 129.101 99.7412H0.997942C0.836056 99.7412 0.706055 99.8712 0.706055 100.033Z"
        fill="#3F3D56"
      />
      <path
        d="M53.7158 92.0459L51.3876 94.3742L48.1724 96.0372L48.505 98.9198L54.6028 98.2546L55.3789 96.7024L55.1571 95.372L55.6006 93.2655L53.7158 92.0459Z"
        fill="#FFB6B6"
      />
      <path
        d="M48.9483 97.8113L48.7579 95.3652L42.1853 98.4765C42.1853 98.4765 41.6763 99.9178 43.2612 100.14C44.8461 100.361 55.3787 100.361 55.3787 99.8069C55.3787 99.2525 55.6668 96.4145 55.6668 96.4145L49.2809 98.2547L48.9483 97.8113Z"
        fill="#2F2E41"
      />
      <path
        d="M68.7358 87.013L68.2408 90.2682L66.6372 93.5135L68.6138 95.6379L73.1305 91.4875L72.8354 89.7774L71.8682 88.8373L70.9766 86.8779L68.7358 87.013Z"
        fill="#FFB6B6"
      />
      <path
        d="M68.3136 94.4825L66.7103 92.6254L63.2612 99.0272C63.2612 99.0272 63.7057 100.49 65.1136 99.7288C66.5215 98.9679 75.004 92.7244 74.6754 92.2779C74.3468 91.8315 72.8965 89.375 72.8965 89.375L68.8444 94.6425L68.3136 94.4825H68.3136Z"
        fill="#2F2E41"
      />
      <path
        d="M68.0229 17.8369H57.5091V11.7654C57.5091 7.40289 61.0508 3.86113 65.4133 3.86113H68.0229V17.8369H68.0229Z"
        fill="#2F2E41"
      />
      <path
        d="M61.6426 17.7536L62.5722 14.0351L68.4155 13.3711C68.4155 13.3711 68.2827 17.3552 68.6811 18.0192L61.6426 17.7536Z"
        fill="#FFB6B6"
      />
      <path
        d="M64.2989 15.2296C67.306 15.2296 69.7438 12.7919 69.7438 9.78473C69.7438 6.7776 67.306 4.33984 64.2989 4.33984C61.2918 4.33984 58.854 6.7776 58.854 9.78473C58.854 12.7919 61.2918 15.2296 64.2989 15.2296Z"
        fill="#FFB6B6"
      />
      <path
        d="M56.4635 40.1963L52.4795 46.8364L52.2803 93.782H55.7828L62.3732 55.4021L60.6468 77.5801L67.779 90.2713L72.5599 88.6777L68.6813 76.5841L74.1926 49.1604L72.0014 40.5947L56.4635 40.1963Z"
        fill="#2F2E41"
      />
      <path
        d="M68.6814 17.3545H61.6429L57.6589 19.2137L56.3308 26.7834C56.3308 26.7834 54.7372 30.4067 56.3308 32.5128C56.3308 32.5128 56.7292 33.4236 56.3308 33.822C55.9324 34.2204 55.9324 35.0172 56.198 35.2828C56.4636 35.5484 56.4636 37.5321 56.4636 37.5321V40.3931L72.0015 40.5949L71.4703 38.0716C71.4703 38.0716 72.2671 37.7767 72.0015 36.729C71.7359 35.6812 72.3755 35.5913 72.0557 34.9722C71.7359 34.3532 71.7359 34.2204 71.7359 34.2204L72.7983 33.158C72.7983 33.158 73.5951 29.5723 72.2671 27.4475C70.9391 25.3226 72.7983 20.4089 72.7983 20.4089L68.6814 17.3545L68.6814 17.3545Z"
        fill="#E6E6E6"
      />
      <path
        d="M73.4507 52.9188L72.5527 51.7666C76.4822 48.1562 80.911 43.5053 83.0801 43.5625C83.3983 43.3146 83.8573 43.3715 84.1052 43.6897C84.3532 44.0079 84.2963 44.4668 83.9781 44.7148C84.0976 48.1025 78.1942 50.4063 73.4507 52.9188Z"
        fill="#3F3D56"
      />
      <path
        d="M71.4715 56.1432C72.865 56.1432 73.9947 55.0135 73.9947 53.6199C73.9947 52.2264 72.865 51.0967 71.4715 51.0967C70.0779 51.0967 68.9482 52.2264 68.9482 53.6199C68.9482 55.0135 70.0779 56.1432 71.4715 56.1432Z"
        fill="#F7B80B"
      />
      <path
        d="M53.8557 12.6023C54.1231 11.7893 54.651 11.0774 54.8872 10.2548C55.2008 9.16246 55.8684 11.5857 55.9335 10.4511C56.0573 8.29468 59.3486 2.74756 61.2275 1.68204C63.1064 0.616506 64.1855 0.937769 66.0998 1.93847C66.0998 1.93847 67.029 1.99757 68.1858 2.56869C69.3426 3.13981 70.3839 4.05448 70.8338 5.26358C71.1904 6.22196 71.151 7.27444 71.2701 8.29005C71.6021 11.1195 73.1882 13.8104 72.8477 16.6389C72.7415 17.5213 72.4061 18.4339 71.6846 18.9529C70.9631 19.472 69.7974 19.4155 69.3225 18.6642C69.1604 18.4078 69.0919 18.1037 68.953 17.834C68.8141 17.5644 68.5631 17.313 68.2599 17.3214C67.8042 17.334 67.5722 17.9455 67.7519 18.3645C67.9316 18.7835 68.354 19.0365 68.7505 19.2615C67.8061 19.6863 66.6347 19.554 65.8089 18.9293C64.9831 18.3045 64.5372 17.2133 64.6891 16.189C64.9033 14.7456 67.006 15.0443 67.2537 13.6063C67.434 12.5595 68.0673 10.8079 67.8948 9.75979C67.7223 8.71164 66.8939 9.98075 67.7666 9.37513C66.8065 9.83797 64.7528 6.67164 63.7477 6.31699C62.7426 5.96234 61.8904 5.19441 61.4334 4.23153C60.3673 5.77691 59.4296 5.35724 59.0478 7.19542C58.666 9.0336 59.019 13.2098 60.5911 14.236C59.9619 14.6653 59.4604 15.2791 59.1652 15.9812C58.9979 16.379 58.8895 16.8142 58.612 17.1446C58.0818 17.7755 57.103 17.8139 56.3227 17.5486C55.3553 17.2196 54.5281 16.4972 54.0721 15.5828C53.616 14.6684 53.5365 13.573 53.8557 12.6023H53.8557Z"
        fill="#2F2E41"
      />
      <path
        d="M58.6007 6.0449C58.6007 6.0449 56.1858 10.5667 57.9241 12.3219C57.9241 12.3219 65.7718 11.8247 68.5218 13.1607C68.5218 13.1607 70.3748 12.0792 69.6035 8.7031C68.8323 5.32701 63.159 5.09764 58.6007 6.0449Z"
        fill="#E6E6E6"
      />
      <path
        d="M58.9221 6.40025L57.709 10.8774C57.709 10.8774 61.8928 10.1202 68.0831 12.018C68.0831 12.018 69.8003 10.3319 68.8663 7.96381C67.9324 5.59569 58.9221 6.40025 58.9221 6.40025Z"
        fill="#F7B80B"
      />
      <path
        d="M68.522 13.1603C68.522 13.1603 70.4392 13.4119 70.565 12.4533C70.6908 11.4947 69.9055 8.92629 69.6037 8.7027C69.3019 8.47911 69.7713 11.6316 68.522 13.1603Z"
        fill="#3F3D56"
      />
      <path
        d="M43.3752 47.2294C44.1701 47.6672 45.3721 47.0095 46.0602 45.7599C46.3419 45.2646 46.5009 44.7089 46.5238 44.1395L47.2859 42.7139L47.3124 42.7223L51.9689 36.6748C53.0516 35.2686 53.4536 33.4352 52.986 31.7233C52.7078 30.7049 52.1948 29.9354 51.2519 30.3388C49.6873 31.0083 47.3324 36.5621 46.0798 39.7785L44.3036 42.9169C43.8344 43.2407 43.4497 43.6722 43.1815 44.1753C42.4935 45.4243 42.5803 46.7917 43.3752 47.2294V47.2294Z"
        fill="#FFB6B6"
      />
      <path
        d="M59.5418 19.0394C59.5418 19.0394 55.2151 17.295 53.5876 21.2408C51.96 25.1866 50.2632 30.7164 50.2632 30.7164L52.7415 34.7337L56.9518 28.3854L59.5418 19.0394Z"
        fill="#E6E6E6"
      />
      <path
        d="M39.3633 44.6754V43.2146C44.682 42.7822 51.0341 41.8362 52.7099 43.2146C53.1133 43.2146 53.4403 43.5416 53.4403 43.945C53.4403 44.3484 53.1133 44.6754 52.7099 44.6754C50.7218 47.421 44.6492 45.6094 39.3633 44.6754Z"
        fill="#3F3D56"
      />
      <path
        d="M37.3714 46.5348C38.7649 46.5348 39.8946 45.4051 39.8946 44.0115C39.8946 42.618 38.7649 41.4883 37.3714 41.4883C35.9778 41.4883 34.8481 42.618 34.8481 44.0115C34.8481 45.4051 35.9778 46.5348 37.3714 46.5348Z"
        fill="#F7B80B"
      />
      <path
        d="M78.3237 52.0358C77.4353 52.2211 76.4792 51.2397 76.1879 49.8433C76.0639 49.2871 76.075 48.7092 76.22 48.1581L75.9098 46.5717L75.8819 46.572L73.2043 39.4245C72.5818 37.7626 72.7353 35.8919 73.6846 34.3925C74.2493 33.5005 74.9655 32.9153 75.7485 33.5776C77.0479 34.6766 77.67 40.6769 77.9239 44.1192L78.7013 47.6406C79.0548 48.0878 79.296 48.6132 79.4048 49.1728C79.6962 50.5687 79.2121 51.8505 78.3237 52.0358H78.3237Z"
        fill="#FFB6B6"
      />
      <path
        d="M71.1382 20.3429C71.1382 20.3429 75.7862 19.9445 76.1846 24.1942C76.5831 28.4439 76.583 34.2281 76.583 34.2281L73.0353 37.3416L70.8726 30.0375L71.1382 20.3429Z"
        fill="#E6E6E6"
      />
      <path
        d="M89.8319 100.218C86.0451 100.218 82.9644 97.1369 82.9644 93.35C82.9644 89.5632 86.0451 86.4824 89.8319 86.4824C93.6188 86.4824 96.6995 89.5632 96.6995 93.35C96.6995 97.1369 93.6188 100.218 89.8319 100.218ZM89.8319 86.973C86.3155 86.973 83.4549 89.8336 83.4549 93.35C83.4549 96.8664 86.3155 99.7271 89.8319 99.7271C93.3484 99.7271 96.209 96.8664 96.209 93.35C96.209 89.8336 93.3484 86.973 89.8319 86.973Z"
        fill="#3F3D56"
      />
      <path
        d="M30.1163 100.349H20.5141C19.5099 100.349 18.6929 99.5321 18.6929 98.528V88.9256C18.6929 87.9215 19.5099 87.1045 20.5141 87.1045H30.1163C31.1205 87.1045 31.9375 87.9215 31.9375 88.9256V98.528C31.9375 99.5321 31.1205 100.349 30.1163 100.349ZM20.5141 87.595C19.7803 87.595 19.1834 88.1919 19.1834 88.9256V98.528C19.1834 99.2617 19.7803 99.8586 20.5141 99.8586H30.1163C30.8501 99.8586 31.447 99.2617 31.447 98.528V88.9256C31.447 88.1919 30.8501 87.595 30.1163 87.595H20.5141Z"
        fill="#3F3D56"
      />
    </g>
    <defs>
      <clipPath id="clip0_905_17461">
        <rect
          width="128.947"
          height="100"
          fill="white"
          transform="translate(0.526367 0.71875)"
        />
      </clipPath>
    </defs>
  </svg>
);
