import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, ReactElement, useRef } from "react";
import { AlertPopupProps } from "../../../modules/Onboarding/type";
import { colors } from "../../../ui/theme";
import ContactFormContent from "./ContactFormContent";

const BottomPopup: FC<AlertPopupProps> = ({
  title = "Vous avez besoin de nous ? On vous rappelle quand vous voulez !",
  close,
  open,
  defaultIsOpen = false,
}): ReactElement => {
  const onCloseHandler = () => {
    onClose();
    close && close();
  };
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen, isOpen: open });
  const cancelRef = useRef<any>();

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onCloseHandler}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        maxWidth="880px"
        width="100%"
        border={`1px solid ${colors.additional.lightMode}`}
        borderRadius="32px"
      >
        <AlertDialogBody padding={{ md: "20px", "2xl": "32px 32px 0px" }}>
          <Flex direction="column">
            {title && (
              <Text variant="2xl" fontWeight="500">
                {title}
              </Text>
            )}
            <Flex justifyContent="space-between" gap="32px">
              <Flex
                direction="column"
                maxWidth="100%"
                width="100%"
                gap={{ md: "15px", "2xl": "20px" }}
                justifyContent="center"
              >
                <ContactFormContent
                  onClose={onCloseHandler}
                  buttonText="Fermer"
                />
              </Flex>
            </Flex>
          </Flex>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default BottomPopup;
