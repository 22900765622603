export const AvatarIcon = () => (
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1203_49700)">
      <rect y="0.132812" width="40" height="40" rx="20" fill="#E2E8F0"/>
      <g clipPath="url(#clip1_1203_49700)">
        <path d="M24 27.6328C29.6812 27.6328 34.2857 23.0443 34.2857 17.3828C34.2857 11.7213 29.6812 7.13281 24 7.13281C18.3188 7.13281 13.7143 11.7213 13.7143 17.3828C13.7143 23.0443 18.3188 27.6328 24 27.6328ZM31.2 30.1953H29.858C28.0741 31.0121 26.0893 31.4766 24 31.4766C21.9107 31.4766 19.9339 31.0121 18.142 30.1953H16.8C10.8375 30.1953 6 35.016 6 40.9578V44.2891C6 46.4111 7.72768 48.1328 9.85714 48.1328H38.1429C40.2723 48.1328 42 46.4111 42 44.2891V40.9578C42 35.016 37.1625 30.1953 31.2 30.1953Z" fill="white"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1203_49700">
        <rect y="0.132812" width="40" height="40" rx="20" fill="white"/>
      </clipPath>
      <clipPath id="clip1_1203_49700">
        <rect width="36" height="41" fill="white" transform="translate(6 7.13281)"/>
      </clipPath>
    </defs>
  </svg>

);