import { useNavigate } from "react-router-dom";
import { Icons } from "../../components/PicturesVariant/PicturesVariant";
import { useOnboardingContext } from "../../context/OnboardingContext";
import { EventSubCategory } from "../../modules/Onboarding/EventTypes";
import OnboardingListButtonLayout from "./OnboardingListButtonLayout";
import { PageURL } from "../../types/pages";
import { useSessionContext } from "../../context/SessionContext";

export default function OnboardingStreetMarketing() {
  const { setEventSubcategory } = useOnboardingContext();
  const navigate = useNavigate();
  const { updateHistory } = useSessionContext();

  const onChoose = (e: EventSubCategory) => {
    setEventSubcategory(e);
    updateHistory(PageURL.ONBOARDING_FLYERS);
    navigate(PageURL.ONBOARDING_FLYERS);
  };

  return (
    <OnboardingListButtonLayout
      title="QUE SOUHAITEZ-VOUS FAIRE ?"
      subtitle="Sélectionner le type d’activité de votre équipe"
      elements={[
        {
          icon: Icons.GOODIES_ICON,
          title: "Dispositif de distribution de flyers ou goodies",
          value: EventSubCategory.FLYERS,
          onChoose,
        },
        {
          icon: Icons.TEST_PRODUCT_ICON,
          title: "Echantillonnage et test produit",
          value: EventSubCategory.TESTING,
          onChoose,
        },
      ]}
    />
  );
}
