export const CoupleIcon = () => (
  <svg
    width="500"
    height="284"
    viewBox="0 0 256 284"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_502_52454)">
      <path
        d="M225.41 279.362L218.77 279.361L215.611 253.746L225.412 253.747L225.41 279.362Z"
        fill="#FFB6B6"
      />
      <path
        d="M202.66 279.211C202.453 279.56 202.344 280.684 202.344 281.089C202.344 282.333 203.353 283.342 204.597 283.342H225.161C226.01 283.342 226.699 282.654 226.699 281.805V280.949C226.699 280.949 227.716 278.375 225.622 275.203C225.622 275.203 223.018 277.687 219.129 273.797L217.982 271.719L209.679 277.792L205.077 278.358C204.07 278.482 203.177 278.339 202.66 279.211Z"
        fill="#1A202C"
      />
      <path
        d="M195.221 279.362L188.581 279.361L185.422 253.746L195.222 253.747L195.221 279.362Z"
        fill="#FFB6B6"
      />
      <path
        d="M172.47 279.211C172.263 279.56 172.154 280.684 172.154 281.089C172.154 282.333 173.163 283.342 174.408 283.342H194.972C195.821 283.342 196.509 282.654 196.509 281.805V280.949C196.509 280.949 197.526 278.375 195.432 275.203C195.432 275.203 192.829 277.687 188.939 273.797L187.792 271.719L179.49 277.792L174.888 278.358C173.881 278.482 172.988 278.339 172.47 279.211Z"
        fill="#1A202C"
      />
      <path
        d="M236.066 119.44L189.857 120.672L185.146 131.764L179.383 203.859L184.312 267.327H198.701V207.425L209.573 170.584L213.886 265.478L228.741 267.327L234.834 202.01L240.379 139.774L236.066 119.44Z"
        fill="#1A202C"
      />
      <path
        d="M219.651 36.8696L203.632 37.4858L201.783 44.8802C201.783 44.8802 182.067 50.4259 183.3 57.8203C184.532 65.2146 190.693 97.8729 190.693 97.8729C190.693 97.8729 183.916 118.824 188.229 121.288C192.541 123.753 236.985 121.288 236.985 121.288C236.985 121.288 240.599 112.045 238.751 110.813C236.902 109.581 244.296 50.4259 243.063 49.1935C241.831 47.9611 221.499 41.7992 221.499 41.7992L219.651 36.8696Z"
        fill="#F6C443"
      />
      <path
        d="M251.07 98.4892L252.303 107.116L238.748 137.926L231.971 134.228L239.364 105.267L240.596 94.1758L251.07 98.4892Z"
        fill="#FFB6B6"
      />
      <path
        d="M238.132 49.81C238.132 49.81 244.909 46.729 249.838 57.8205C254.767 68.912 253.535 92.9436 253.535 92.9436C253.535 92.9436 255.383 94.7922 254.151 96.0246C252.919 97.257 250.577 94.3571 252.919 98.2759C254.151 100.338 257.848 99.7218 254.151 100.338C250.454 100.954 251.75 99.232 252.919 101.57C253.535 102.803 255.999 102.803 253.535 103.419C251.07 104.035 238.67 102.977 238.67 102.977C238.67 102.977 234.435 100.954 236.9 99.7218C239.364 98.4894 240.596 98.2468 239.364 97.7519C238.132 97.257 235.051 97.257 236.9 95.4084C238.748 93.5598 239.98 93.5598 238.748 93.5598C237.516 93.5598 236.129 90.5904 236.129 90.5904L238.132 49.81Z"
        fill="#F6C443"
      />
      <path
        d="M237.976 143.096C239.478 138.602 238.532 134.236 235.864 133.344C233.195 132.453 229.815 135.374 228.314 139.868C226.813 144.362 227.759 148.729 230.427 149.62C233.095 150.512 236.475 147.591 237.976 143.096Z"
        fill="#FFB6B6"
      />
      <path
        d="M165.242 95.5705L160.34 102.775L129.568 116.408L127.051 109.109L152.025 92.6908L160.408 85.3252L165.242 95.5705Z"
        fill="#FFB6B6"
      />
      <path
        d="M188.476 50.8809C188.476 50.8809 195.56 53.1712 191.729 64.6885C187.899 76.2058 170.799 93.1333 170.799 93.1333C170.799 93.1333 170.919 95.7448 169.178 95.8251C167.438 95.9054 167.661 92.1845 166.751 96.6583C166.273 99.0124 169.419 101.048 166.273 99.0124C163.126 96.9766 165.243 96.5771 164.532 99.0927C164.157 100.419 165.978 102.079 163.742 100.874C161.505 99.6685 153.055 90.5307 153.055 90.5307C153.055 90.5307 151.289 86.182 153.94 86.9321C156.591 87.6821 157.665 88.3332 157.088 87.1372C156.511 85.9411 154.235 83.8652 156.846 83.7448C159.457 83.6243 160.368 84.4547 159.457 83.6243C158.547 82.794 159.522 79.6653 159.522 79.6653L188.476 50.8809Z"
        fill="#F6C443"
      />
      <path
        d="M125.515 119.708C129.652 117.399 131.894 113.535 130.523 111.078C129.152 108.622 124.687 108.502 120.55 110.812C116.412 113.121 114.17 116.985 115.541 119.441C116.912 121.898 121.377 122.018 125.515 119.708Z"
        fill="#FFB6B6"
      />
      <path
        d="M212.337 33.8577C220.509 33.8577 227.134 27.2323 227.134 19.0594C227.134 10.8866 220.509 4.26123 212.337 4.26123C204.166 4.26123 197.541 10.8866 197.541 19.0594C197.541 27.2323 204.166 33.8577 212.337 33.8577Z"
        fill="#FFB6B6"
      />
      <path
        d="M200.52 7.15682C201.273 5.02123 203.362 3.72561 205.626 3.77607C205.722 3.77819 205.818 3.7773 205.912 3.7734C207.052 3.72679 208.147 3.33738 209.264 3.10893C211.348 2.68313 213.544 2.82569 215.555 3.51715C216.18 3.73209 216.806 4.00237 217.467 4.00244C218.128 4.00254 218.848 3.64007 219.009 2.99904C219.047 3.57942 219.086 4.15976 219.124 4.74011C219.339 4.27039 219.554 3.80063 219.769 3.33089L220.2 5.20418L221.58 3.63859C221.456 4.32865 221.859 5.02414 222.403 5.46532C222.948 5.90653 223.62 6.15058 224.265 6.42545C227.048 7.61186 229.661 9.71239 230.463 12.6298C230.97 14.4731 230.705 16.4293 230.436 18.3219C230.199 19.9951 229.941 21.7261 229.032 23.1504C228.449 24.0634 227.609 24.8317 227.254 25.8552C226.923 26.8092 227.048 27.9015 226.577 28.7949C226.105 29.6922 225.129 30.1999 224.174 30.5408C223.219 30.8818 222.203 31.123 221.381 31.7163C220.559 32.3097 219.604 34.019 217.812 33.927L217.234 32.8403C216.639 32.3555 218.841 32.7399 218.863 31.9727C218.886 31.2055 216.214 30.7315 216.593 30.0639C217.174 29.0402 217.96 28.1493 218.614 27.171C219.379 26.0269 219.981 24.6901 219.717 23.2938C219.615 22.7506 219.36 22.2375 218.973 21.8429C217.857 20.7054 216.612 21.2054 215.403 21.3681C214.569 21.4803 213.574 21.3507 213.141 20.6294C212.634 19.7854 213.199 18.7118 213.13 17.73C213.056 16.6896 212.268 15.8365 211.411 15.2434C210.553 14.6503 209.579 14.2182 208.808 13.5167C208.036 12.8152 208.158 13.179 208.494 12.1919C202.758 16.4949 203.605 9.06512 199.754 9.99383C199.937 9.03058 200.194 8.08153 200.52 7.15682L200.52 7.15682Z"
        fill="#1A202C"
      />
      <path
        d="M71.9166 268.635L77.3785 267.26L73.6485 242.04L65.5879 244.069L71.9166 268.635Z"
        fill="#FFB6B6"
      />
      <path
        d="M93.6714 274.799C93.9254 275.716 93.4808 276.64 92.6783 276.862L81.9072 279.847C81.9072 279.847 79.6667 275.879 74.837 275.243L76.2273 281.421L70.6709 282.96L68.629 272.973C68.629 272.973 65.6899 268.142 68.0343 264.606C70.3786 261.07 67.9817 262.136 67.9817 262.136L67.5156 255.734L76.1855 254.395L78.8852 263.899L85.5784 272.222L92.373 273.518C92.9777 273.634 93.488 274.137 93.6715 274.799H93.6714Z"
        fill="#1A202C"
      />
      <path
        d="M19.6035 264.253L24.8694 266.252L36.2462 243.437L28.4752 240.486L19.6035 264.253Z"
        fill="#FFB6B6"
      />
      <path
        d="M33.9132 281.761C33.5967 282.658 32.7036 283.161 31.9183 282.884L21.3782 279.164C21.3782 279.164 21.812 274.629 18.2161 271.342L15.8201 277.203L10.3828 275.285L14.4249 265.927C14.4249 265.927 14.7804 260.282 18.7264 258.725C22.6723 257.169 20.0969 256.67 20.0969 256.67L23.3792 251.154L31.2546 255.019L28.0279 264.358L28.7525 275.014L33.5817 279.967C34.0115 280.408 34.1418 281.113 33.9132 281.761L33.9132 281.761Z"
        fill="#1A202C"
      />
      <path
        d="M42.4677 38.6966H14.5213C14.5623 37.3852 14.2823 36.0737 13.7086 34.8989C13.6949 36.2035 13.4012 37.5082 12.8617 38.6966H11.5914C9.65184 38.6966 8.07422 37.1188 8.07422 35.179V17.0171C8.07422 8.124 15.0608 0.795063 23.9392 0.371587C24.2056 0.357911 24.4651 0.351074 24.7315 0.351074C31.9639 0.351074 38.4247 5.04351 40.6238 11.9968C43.8542 10.5078 45.7869 18.9706 47.0709 32.4127C48.0475 35.5273 45.7254 38.6966 42.4677 38.6966Z"
        fill="#1A202C"
      />
      <path
        d="M97.3057 142.532C98.691 145.817 98.1197 149.194 96.0297 150.076C93.9397 150.958 91.1224 149.01 89.7371 145.725C89.1307 144.287 88.8992 142.832 89.0059 141.575L83.3105 127.583L89.9327 125.05L94.8444 139.112C95.8184 139.913 96.6993 141.094 97.3057 142.532Z"
        fill="#FFB6B6"
      />
      <path
        d="M36.0749 32.5493L22.7168 35.344L24.7657 41.4913L20.668 88.5077L27.4975 103.647L69.1577 100.232L58.9134 79.7412C58.9134 79.7412 63.0111 62.6654 57.5475 59.2502C52.0838 55.835 43.2054 38.7592 43.2054 38.7592L36.0749 32.5493V32.5493Z"
        fill="#F6C443"
      />
      <path
        d="M23.6238 38.0625L11.7904 42.8572L15.8882 102.964L7.00977 154.875L35.6938 159.656C35.6938 159.656 32.279 44.9063 30.2302 42.8572C28.1813 40.8081 23.6238 38.0625 23.6238 38.0625V38.0625Z"
        fill="#1A202C"
      />
      <path
        d="M46.7988 45.561C46.7988 45.561 54.4988 40.6886 58.6589 44.2962C62.819 47.9038 94.8481 135.109 94.8481 135.109L86.2089 138.863L66.3963 97.3168L46.7988 45.561H46.7988Z"
        fill="#1A202C"
      />
      <path
        d="M68.8564 192.49L76.6694 252.549L65.7421 253.232C65.7421 253.232 60.06 237.727 53.5787 219.463C52.1991 215.59 50.7854 211.594 49.3854 207.592C47.8692 203.248 46.3599 198.904 44.9257 194.71C41.3812 184.369 38.2738 174.956 36.4981 168.809C36.1907 167.73 35.9176 166.753 35.6922 165.893C30.9115 147.533 28.1797 99.5494 28.1797 99.5494L67.791 96.1343L68.2144 96.6602L76.6694 107.227L76.2733 109.46L65.7421 168.536L68.8564 192.49L68.8564 192.49Z"
        fill="#1A202C"
      />
      <path
        d="M65.7428 168.536L68.8571 192.49L54.8497 217.154L53.5794 219.463L34.2928 254.516L24.7656 249.113C24.7656 249.113 37.1612 220.624 44.9264 194.71C45.787 191.861 46.586 189.041 47.3031 186.295C49.4134 178.255 45.9577 163.584 47.3099 159.54C51.8789 145.893 66.7673 118.592 73.6787 106.223L76.274 109.46L65.7428 168.536Z"
        fill="#1A202C"
      />
      <path
        d="M43.0332 38.2397L56.8654 43.5405L93.062 149.411L71.2075 152.826L60.9632 91.3529L43.0332 38.2397Z"
        fill="#1A202C"
      />
      <path
        d="M8.44879 152.518C8.09672 156.066 5.98147 158.76 3.72422 158.536C1.46697 158.312 -0.0774496 155.254 0.274619 151.707C0.428728 150.154 0.92074 148.765 1.61457 147.712L3.29496 132.699L10.3226 133.637L7.92017 148.338C8.39356 149.506 8.60291 150.965 8.44879 152.518V152.518Z"
        fill="#FFB6B6"
      />
      <path
        d="M16.5693 42.8574C16.5693 42.8574 7.69086 40.8083 4.95905 45.5895C2.22724 50.3708 0.861328 143.263 0.861328 143.263L10.256 143.946L15.2034 98.1832L16.5693 42.8574V42.8574Z"
        fill="#1A202C"
      />
      <path
        d="M27.8999 30.8926C35.7406 30.8926 42.0968 24.5357 42.0968 16.6941C42.0968 8.85249 35.7406 2.49561 27.8999 2.49561C20.0593 2.49561 13.7031 8.85249 13.7031 16.6941C13.7031 24.5357 20.0593 30.8926 27.8999 30.8926Z"
        fill="#FFB6B6"
      />
      <path
        d="M45.723 15.4734C45.723 15.4734 38.807 13.4243 34.6663 15.4734C28.9119 18.321 14.2207 15.4734 14.2207 15.4734V9.05481C14.2207 5.30769 17.0106 2.14696 20.7284 1.68218L31.3301 0.356807C39.652 -0.683551 47.0025 5.80606 47.0025 14.1937V14.1937C47.0025 14.9005 46.4296 15.4734 45.723 15.4734Z"
        fill="#1A202C"
      />
      <path
        d="M30.6116 38.6967H14.5212C14.5622 37.3853 14.2822 36.0738 13.7085 34.899C13.6948 36.2036 13.4012 37.5082 12.8617 38.6967H12.1719V10.0093H24.465L30.6116 38.6967Z"
        fill="#1A202C"
      />
    </g>
    <defs>
      <clipPath id="clip0_502_52454">
        <rect width="256" height="284" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
