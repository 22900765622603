export const VillageEvenementielIcon = () => (
  <svg
    width="100"
    height="101"
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1017_48448)">
      <path
        d="M16.2967 41.1949C21.3971 41.1949 25.5318 37.0602 25.5318 31.9598C25.5318 26.8593 21.3971 22.7246 16.2967 22.7246C11.1962 22.7246 7.06152 26.8593 7.06152 31.9598C7.06152 37.0602 11.1962 41.1949 16.2967 41.1949Z"
        fill="#F7B80B"
      />
      <path
        d="M73.5102 42.4163C73.5102 60.5983 62.7013 66.9467 49.3679 66.9467C36.0345 66.9467 25.2256 60.5983 25.2256 42.4163C25.2256 24.2343 49.3679 1.104 49.3679 1.104C49.3679 1.104 73.5102 24.2343 73.5102 42.4163Z"
        fill="#F7B80B"
      />
      <path
        d="M48.4883 64.1654L48.7355 48.9486L59.0256 30.1235L48.7743 46.5617L48.8855 39.7196L55.9773 26.0998L48.9149 37.909L49.1149 25.6033L56.709 14.7602L49.1462 23.6683L49.2711 1.104L48.4862 30.975L48.5508 29.7428L40.8296 17.9245L48.4269 32.1082L47.7075 45.8517L47.686 45.4869L38.7852 33.0498L47.6592 46.7755L47.569 48.4943L47.5529 48.5201L47.5603 48.6612L45.7352 83.5293H48.1737L48.4664 65.5193L57.3185 51.8272L48.4883 64.1654Z"
        fill="#3F3D56"
      />
      <path
        d="M96.8042 59.5891C96.8042 77.7711 85.9953 84.1196 72.6619 84.1196C59.3284 84.1196 48.5195 77.7711 48.5195 59.5891C48.5195 41.4072 72.6619 18.2769 72.6619 18.2769C72.6619 18.2769 96.8042 41.4072 96.8042 59.5891Z"
        fill="#3F3D56"
      />
      <path
        d="M71.7823 81.3383L72.0296 66.1217L82.3195 47.2965L72.0684 63.7346L72.1794 56.8926L79.2714 43.2728L72.2088 55.0819V55.0821L72.4088 42.7762L80.0029 31.9332L72.4402 40.8413L72.5652 18.2769L71.7801 48.148L71.8447 46.9158L64.1236 35.0975L71.7209 49.2812L71.0014 63.0245L70.9799 62.6597L62.0791 50.2228L70.9531 63.9485L70.8632 65.6673L70.8469 65.6931L70.8543 65.8342L69.0291 100.702H71.4677L71.7603 82.6921L80.6125 69.0002L71.7823 81.3383Z"
        fill="#F2F2F2"
      />
      <path
        d="M51.4067 59.5891C51.4067 77.7711 40.5978 84.1196 27.2644 84.1196C13.931 84.1196 3.12207 77.7711 3.12207 59.5891C3.12207 41.4072 27.2644 18.2769 27.2644 18.2769C27.2644 18.2769 51.4067 41.4072 51.4067 59.5891Z"
        fill="#3F3D56"
      />
      <path
        d="M26.3848 81.3383L26.6321 66.1217L36.9222 47.2965L26.6709 63.7346L26.782 56.8926L33.8739 43.2728L26.8115 55.0819V55.0821L27.0113 42.7762L34.6055 31.9332L27.0427 40.8413L27.1677 18.2769L26.3828 48.148L26.4472 46.9158L18.7263 35.0975L26.3234 49.2812L25.604 63.0245L25.5826 62.6597L16.6816 50.2228L25.5556 63.9485L25.4657 65.6673L25.4496 65.6931L25.457 65.8342L23.6316 100.702H26.0702L26.3628 82.6921L35.2152 69.0002L26.3848 81.3383Z"
        fill="#F2F2F2"
      />
      <path
        d="M49.9821 100.549C38.4352 100.549 27.2456 96.545 18.3197 89.2198C9.39387 81.8946 3.28407 71.701 1.03137 60.3761C-1.22134 49.0511 0.522428 37.2954 5.96555 27.112C11.4087 16.9285 20.2144 8.94748 30.8823 4.52868C41.5502 0.109872 53.4202 -0.473284 64.4698 2.87857C75.5195 6.23043 85.0651 13.3099 91.4802 22.9108C97.8953 32.5116 100.783 44.0398 99.6511 55.531C98.5194 67.0223 93.4382 77.7656 85.2733 85.9305C80.6496 90.5795 75.1497 94.2653 69.0919 96.7745C63.0342 99.2837 56.5389 100.567 49.9821 100.549ZM49.9821 1.11074C22.6721 1.11074 0.453666 23.329 0.453666 50.6391C0.453666 77.9492 22.6721 100.168 49.9821 100.168C77.2921 100.168 99.5105 77.9492 99.5105 50.6391C99.5105 23.329 77.2921 1.11074 49.9821 1.11074Z"
        fill="#2F2E41"
      />
      <path
        d="M90.7926 79.1136V100.367H9.17871V79.1136L49.9856 57.8599L50.0707 57.904L90.7926 79.1136Z"
        fill="#F2F2F2"
      />
      <path
        opacity="0.1"
        d="M90.7933 79.1139V100.367H50.0713V57.9043L90.7933 79.1139Z"
        fill="black"
      />
      <path
        d="M46.5008 70.8674L43.4404 72.023V67.4669L46.5008 66.1255V70.8674Z"
        fill="#3F3D56"
      />
      <path
        d="M12.6647 83.6195L11.6445 84.0052V81.2391L12.6647 80.7881V83.6195Z"
        fill="#3F3D56"
      />
      <path
        d="M16.2352 82.4293L15.0449 82.8754V79.8789L16.2352 79.3564V82.4293Z"
        fill="#3F3D56"
      />
      <path
        d="M20.1464 80.8987L18.7861 81.4103V78.1782L20.1464 77.5796V80.8987Z"
        fill="#3F3D56"
      />
      <path
        d="M24.9064 79.0285L23.2061 79.6702V76.308L24.9064 75.562V79.0285Z"
        fill="#3F3D56"
      />
      <path
        d="M30.6877 76.8181L28.6475 77.5893V73.9277L30.6877 73.0327V76.8181Z"
        fill="#3F3D56"
      />
      <path
        d="M37.6587 74.2676L35.2783 75.1645V71.0371L37.6587 69.9937V74.2676Z"
        fill="#3F3D56"
      />
      <path
        d="M12.6647 89.2304L11.6445 89.4869V86.6801L12.6647 86.3574V89.2304Z"
        fill="#3F3D56"
      />
      <path
        d="M16.2352 88.3803L15.0449 88.6783V85.6598L16.2352 85.2847V88.3803Z"
        fill="#3F3D56"
      />
      <path
        d="M20.1464 87.36L18.7861 87.7018V84.4696L20.1464 84.0405V87.36Z"
        fill="#3F3D56"
      />
      <path
        d="M24.9064 86.1699L23.2061 86.5969V83.1093L24.9064 82.5742V86.1699Z"
        fill="#3F3D56"
      />
      <path
        d="M30.6877 84.6394L28.6475 85.1543V81.4089L30.6877 80.7671V84.6394Z"
        fill="#3F3D56"
      />
      <path
        d="M37.6589 82.9396L35.1084 83.581V79.3689L37.6589 78.5664V82.9396Z"
        fill="#3F3D56"
      />
      <path
        d="M46.5008 80.7292L43.4404 81.4985V76.8185L46.5008 75.8584V80.7292Z"
        fill="#3F3D56"
      />
      <path
        d="M46.5008 90.4203L43.4404 90.81V86.1697L46.5008 85.5908V90.4203Z"
        fill="#3F3D56"
      />
      <path
        d="M12.6647 94.6711L11.6445 94.8023V92.1207L12.6647 91.9263V94.6711Z"
        fill="#3F3D56"
      />
      <path
        d="M16.2352 94.3315L15.0449 94.4815V91.611L16.2352 91.3877V94.3315Z"
        fill="#3F3D56"
      />
      <path
        d="M20.1464 93.8211L18.7861 93.9932V90.7607L20.1464 90.5015V93.8211Z"
        fill="#3F3D56"
      />
      <path
        d="M24.9064 93.1413L23.2061 93.3586V89.9108L24.9064 89.5864V93.1413Z"
        fill="#3F3D56"
      />
      <path
        d="M30.6877 92.461L28.6475 92.7199V88.8905L30.6877 88.502V92.461Z"
        fill="#3F3D56"
      />
      <path
        d="M37.6589 91.6106L35.1084 91.9329V87.6999L37.6589 87.2158V91.6106Z"
        fill="#3F3D56"
      />
      <path
        d="M87.4775 83.6195L88.4978 84.0052V81.2391L87.4775 80.7881V83.6195Z"
        fill="#3F3D56"
      />
      <path
        d="M83.9072 82.4293L85.0975 82.8754V79.8789L83.9072 79.3564V82.4293Z"
        fill="#3F3D56"
      />
      <path
        d="M79.9961 80.8987L81.3563 81.4103V78.1782L79.9961 77.5796V80.8987Z"
        fill="#3F3D56"
      />
      <path
        d="M75.2354 79.0285L76.9357 79.6702V76.308L75.2354 75.562V79.0285Z"
        fill="#3F3D56"
      />
      <path
        d="M69.4541 76.8181L71.4944 77.5893V73.9277L69.4541 73.0327V76.8181Z"
        fill="#3F3D56"
      />
      <path
        d="M62.4834 74.2676L64.8639 75.1645V71.0371L62.4834 69.9937V74.2676Z"
        fill="#3F3D56"
      />
      <path
        d="M87.4775 89.2304L88.4978 89.4869V86.6801L87.4775 86.3574V89.2304Z"
        fill="#3F3D56"
      />
      <path
        d="M83.9072 88.3803L85.0975 88.6783V85.6598L83.9072 85.2847V88.3803Z"
        fill="#3F3D56"
      />
      <path
        d="M79.9961 87.36L81.3563 87.7018V84.4696L79.9961 84.0405V87.36Z"
        fill="#3F3D56"
      />
      <path
        d="M75.2354 86.1699L76.9357 86.5969V83.1093L75.2354 82.5742V86.1699Z"
        fill="#3F3D56"
      />
      <path
        d="M69.4541 84.6394L71.4944 85.1543V81.4089L69.4541 80.7671V84.6394Z"
        fill="#3F3D56"
      />
      <path
        d="M62.4834 82.9396L65.0339 83.581V79.3689L62.4834 78.5664V82.9396Z"
        fill="#3F3D56"
      />
      <path
        d="M53.6416 80.7292L56.7022 81.4985V76.8185L53.6416 75.8584V80.7292Z"
        fill="#3F3D56"
      />
      <path
        d="M53.6416 90.4203L56.7022 90.81V86.1697L53.6416 85.5908V90.4203Z"
        fill="#3F3D56"
      />
      <path
        d="M87.4775 94.6711L88.4978 94.8023V92.1207L87.4775 91.9263V94.6711Z"
        fill="#3F3D56"
      />
      <path
        d="M83.9072 94.3315L85.0975 94.4815V91.611L83.9072 91.3877V94.3315Z"
        fill="#3F3D56"
      />
      <path
        d="M79.9961 93.8211L81.3563 93.9932V90.7607L79.9961 90.5015V93.8211Z"
        fill="#3F3D56"
      />
      <path
        d="M75.2354 93.1413L76.9357 93.3586V89.9108L75.2354 89.5864V93.1413Z"
        fill="#3F3D56"
      />
      <path
        d="M69.4541 92.461L71.4944 92.7199V88.8905L69.4541 88.502V92.461Z"
        fill="#3F3D56"
      />
      <path
        d="M62.4834 91.6106L65.0339 91.9329V87.6999L62.4834 87.2158V91.6106Z"
        fill="#3F3D56"
      />
      <path
        d="M56.5321 72.0575L53.4717 70.9033V66.1064L56.5321 67.4484V72.0575Z"
        fill="#3F3D56"
      />
      <path d="M85.27 96.5825H85.0205V100.574H85.27V96.5825Z" fill="#2F2E41" />
      <path
        d="M88.209 97.6694H88.0322V100.498H88.209V97.6694Z"
        fill="#2F2E41"
      />
      <path d="M90.8204 98.0146H90.665V100.5H90.8204V98.0146Z" fill="#2F2E41" />
      <path
        d="M77.9562 95.3175V100.452L74.6408 100.447L71.3252 100.442V94.6714L74.6408 94.9945L77.9562 95.3175Z"
        fill="#3F3D56"
      />
      <path
        d="M22.1865 100.452L28.8176 100.442V94.6714L22.1865 95.3169V100.452Z"
        fill="#3F3D56"
      />
      <path
        d="M129.698 100.282H-18.876V100.622H129.698V100.282Z"
        fill="#2F2E41"
      />
      <path
        opacity="0.1"
        d="M77.9561 95.3176V100.453L74.6406 100.447V94.9946L77.9561 95.3176Z"
        fill="black"
      />
      <path
        d="M49.6457 95.7764H49.3057V100.537H49.6457V95.7764Z"
        fill="#2F2E41"
      />
      <path
        d="M51.4385 94.0817C51.4385 95.75 50.5474 97.1421 49.4761 97.1899C48.4346 97.2364 47.6113 95.9881 47.6113 94.4028C47.6113 92.8175 49.6461 88.4653 49.6461 88.4653C49.6461 88.4653 51.4385 92.4134 51.4385 94.0817Z"
        fill="#F7B80B"
      />
      <path
        d="M9.64034 97.3022H9.4375V100.142H9.64034V97.3022Z"
        fill="#2F2E41"
      />
      <path
        d="M10.7096 96.2914C10.7096 97.2865 10.178 98.1169 9.53905 98.1454C8.91783 98.1731 8.42676 97.4285 8.42676 96.4829C8.42676 95.5373 9.64047 92.9414 9.64047 92.9414C9.64047 92.9414 10.7096 95.2963 10.7096 96.2914Z"
        fill="#F7B80B"
      />
      <path
        d="M86.517 96.74C86.517 97.6864 85.9206 98.4256 85.1449 98.3892C84.3213 98.3506 83.6152 97.4621 83.6152 96.407C83.6152 95.3519 85.1449 92.7158 85.1449 92.7158C85.1449 92.7158 86.517 95.7936 86.517 96.74Z"
        fill="#F7B80B"
      />
      <path
        d="M89.0923 97.7807C89.0923 98.4513 88.6697 98.9751 88.1201 98.9493C87.5364 98.9219 87.0361 98.2924 87.0361 97.5448C87.0361 96.7971 88.1201 94.9292 88.1201 94.9292C88.1201 94.9292 89.0923 97.1101 89.0923 97.7807Z"
        fill="#F7B80B"
      />
      <path
        d="M91.5972 98.1126C91.5972 98.702 91.2258 99.1624 90.7427 99.1397C90.2297 99.1156 89.79 98.5623 89.79 97.9052C89.79 97.2482 90.7427 95.6064 90.7427 95.6064C90.7427 95.6064 91.5972 97.5233 91.5972 98.1126Z"
        fill="#F7B80B"
      />
    </g>
    <defs>
      <clipPath id="clip0_1017_48448">
        <rect
          width="99.8322"
          height="100"
          fill="white"
          transform="translate(0.0839844 0.71875)"
        />
      </clipPath>
    </defs>
  </svg>
);
