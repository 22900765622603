import { Icon, IconProps } from "@chakra-ui/react";

export const TeamSplittingIcon = (props: IconProps) => (
  <Icon
    width="188"
    height="106"
    viewBox="0 0 188 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M185.001 20.0823H95.4583C95.0745 20.0828 94.6943 20.0076 94.3396 19.8611C93.9848 19.7145 93.6624 19.4995 93.3909 19.2283C93.1193 18.9571 92.9038 18.635 92.7568 18.2804C92.6098 17.9259 92.5342 17.5458 92.5342 17.162C92.5342 16.7782 92.6098 16.3981 92.7568 16.0436C92.9038 15.689 93.1193 15.3669 93.3909 15.0957C93.6624 14.8245 93.9848 14.6094 94.3396 14.4629C94.6943 14.3164 95.0745 14.2412 95.4583 14.2417H185.001C185.385 14.2412 185.765 14.3164 186.12 14.4629C186.475 14.6094 186.797 14.8245 187.069 15.0957C187.34 15.3669 187.556 15.689 187.703 16.0436C187.85 16.3981 187.925 16.7782 187.925 17.162C187.925 17.5458 187.85 17.9259 187.703 18.2804C187.556 18.635 187.34 18.9571 187.069 19.2283C186.797 19.4995 186.475 19.7145 186.12 19.8611C185.765 20.0076 185.385 20.0828 185.001 20.0823Z"
      fill="#F7B80B"
    />
    <path
      d="M185 31.6212H95.4567C94.6828 31.6201 93.9409 31.312 93.3941 30.7644C92.8472 30.2169 92.54 29.4746 92.54 28.7007C92.54 27.9268 92.8472 27.1846 93.3941 26.637C93.9409 26.0894 94.6828 25.7813 95.4567 25.7803H185C185.774 25.7813 186.515 26.0894 187.062 26.637C187.609 27.1846 187.916 27.9268 187.916 28.7007C187.916 29.4746 187.609 30.2169 187.062 30.7644C186.515 31.312 185.774 31.6201 185 31.6212Z"
      fill="#F7B80B"
    />
    <path
      d="M134.271 43.1617H95.4567C94.6828 43.1607 93.9409 42.8525 93.3941 42.305C92.8472 41.7574 92.54 41.0151 92.54 40.2412C92.54 39.4673 92.8472 38.7251 93.3941 38.1775C93.9409 37.6299 94.6828 37.3218 95.4567 37.3208H134.271C134.655 37.3203 135.035 37.3955 135.39 37.542C135.745 37.6885 136.067 37.9036 136.339 38.1748C136.611 38.4461 136.826 38.7682 136.973 39.1227C137.12 39.4773 137.196 39.8574 137.196 40.2412C137.196 40.6251 137.12 41.0051 136.973 41.3597C136.826 41.7143 136.611 42.0364 136.339 42.3076C136.067 42.5789 135.745 42.7939 135.39 42.9405C135.035 43.087 134.655 43.1622 134.271 43.1617Z"
      fill="#F7B80B"
    />
    <path
      d="M59.7349 51.8327H5.82803C4.38333 51.8311 2.99828 51.2565 1.97672 50.2349C0.955166 49.2133 0.380537 47.8283 0.378906 46.3836V11.0195C0.380538 9.57476 0.955166 8.1897 1.97672 7.16814C2.99828 6.14658 4.38334 5.57195 5.82803 5.57031H59.7349C61.1796 5.57195 62.5647 6.14657 63.5862 7.16814C64.6078 8.1897 65.1824 9.57476 65.184 11.0195V46.3836C65.1824 47.8283 64.6078 49.2133 63.5862 50.2349C62.5647 51.2565 61.1796 51.8311 59.7349 51.8327Z"
      fill="#CCCCCC"
    />
    <path
      d="M55.9447 9.98926H9.61644C8.30105 9.99084 7.03995 10.5139 6.1096 11.4438C5.17925 12.3737 4.65556 13.6345 4.65332 14.9499V42.4518C4.65516 43.7676 5.17866 45.0289 6.10903 45.9593C7.03939 46.8896 8.3007 47.4131 9.61644 47.4149H39.1583C44.9246 47.4102 50.4533 45.1175 54.5306 41.0402C58.6079 36.9629 60.9006 31.4342 60.9053 25.668V14.9499C60.9039 13.6347 60.3808 12.3737 59.4508 11.4437C58.5208 10.5137 57.2599 9.99064 55.9447 9.98926Z"
      fill="white"
    />
    <path
      d="M21.647 15.3243C23.8289 15.3243 25.5977 13.5555 25.5977 11.3736C25.5977 9.19165 23.8289 7.42285 21.647 7.42285C19.4651 7.42285 17.6963 9.19165 17.6963 11.3736C17.6963 13.5555 19.4651 15.3243 21.647 15.3243Z"
      fill="#2F2E41"
    />
    <path
      d="M27.9694 30.3373C33.497 30.3373 37.9779 25.8563 37.9779 20.3288C37.9779 14.8013 33.497 10.3203 27.9694 10.3203C22.4419 10.3203 17.9609 14.8013 17.9609 20.3288C17.9609 25.8563 22.4419 30.3373 27.9694 30.3373Z"
      fill="#2F2E41"
    />
    <path
      d="M28.7961 30.3349C33.2879 30.3349 36.9291 26.6936 36.9291 22.2019C36.9291 17.7101 33.2879 14.0688 28.7961 14.0688C24.3044 14.0688 20.6631 17.7101 20.6631 22.2019C20.6631 26.6936 24.3044 30.3349 28.7961 30.3349Z"
      fill="#9E616A"
    />
    <path
      d="M34.3198 13.9109C32.8946 13.0421 31.2622 12.5721 29.5932 12.5501C27.9242 12.5281 26.28 12.9549 24.8324 13.7859C23.3849 14.6168 22.1872 15.8214 21.3645 17.2737C20.5418 18.726 20.1244 20.3726 20.156 22.0414C24.3002 23.0819 28.6539 23.8782 33.0734 22.2903L34.1171 19.736L34.7324 22.2922C36.0782 22.8423 37.4276 23.3078 38.777 22.2869C38.8321 20.6228 38.4494 18.9734 37.6672 17.5035C36.8851 16.0337 35.7308 14.7949 34.3198 13.9109Z"
      fill="#2F2E41"
    />
    <path
      d="M17.9681 0.648697C16.581 0.0566675 15.0559 -0.135402 13.5654 0.0942189C12.0748 0.32384 10.6783 0.965988 9.53367 1.94802C8.41428 2.95806 7.56212 4.22932 7.05317 5.64853C6.54421 7.06775 6.39426 8.59083 6.61665 10.082C7.06462 13.1151 8.90852 15.727 10.6082 18.2787C12.3078 20.8304 13.9683 23.6265 13.9664 26.6924C13.9651 28.9007 13.088 31.0183 11.5276 32.5808C9.96722 34.1433 7.85083 35.0233 5.64258 35.0277C8.23432 36.1434 10.9394 37.2811 13.758 37.1488C16.5767 37.0165 19.531 35.2045 19.9908 32.4205C20.3221 30.4145 19.3379 28.4585 18.5171 26.5984C17.1801 23.5674 16.2137 20.3862 15.6388 17.1237C15.3647 15.5666 15.1862 13.9296 15.6938 12.4323C16.0981 11.3227 16.8482 10.3724 17.8335 9.72152C18.8189 9.07062 19.9873 8.75359 21.1665 8.81717L22.0978 7.97929C22.2573 6.47966 21.9453 4.96761 21.2051 3.65368C20.4648 2.33975 19.3333 1.28933 17.9681 0.648697Z"
      fill="#2F2E41"
    />
    <path
      d="M50.3088 44.3317C46.9419 46.3546 43.0869 47.4207 39.1591 47.4152H11.2797L11.2354 47.3782L13.6355 40.6784C14.178 39.4892 15.0341 38.4701 16.1118 37.7305C17.1895 36.9908 18.4483 36.5586 19.7531 36.48L21.28 36.3863L24.9209 33.2461C24.9209 33.2461 34.7312 32.8933 34.5857 32.7256L40.5281 36.2999L40.5232 36.2777L42.8592 36.4208C44.1642 36.4985 45.4233 36.9305 46.5012 37.6702C47.5791 38.4099 48.435 39.4295 48.9768 40.6192L50.3088 44.3317Z"
      fill="#F7B80B"
    />
    <path
      d="M162.94 43.9553C164.786 43.9553 166.283 42.4582 166.283 40.6114C166.283 38.7647 164.786 37.2676 162.94 37.2676C161.093 37.2676 159.596 38.7647 159.596 40.6114C159.596 42.4582 161.093 43.9553 162.94 43.9553Z"
      fill="#F7B80B"
    />
    <path
      d="M173.798 43.9553C175.645 43.9553 177.142 42.4582 177.142 40.6114C177.142 38.7647 175.645 37.2676 173.798 37.2676C171.951 37.2676 170.454 38.7647 170.454 40.6114C170.454 42.4582 171.951 43.9553 173.798 43.9553Z"
      fill="#F7B80B"
    />
    <path
      d="M184.656 43.9553C186.503 43.9553 188 42.4582 188 40.6114C188 38.7647 186.503 37.2676 184.656 37.2676C182.81 37.2676 181.312 38.7647 181.312 40.6114C181.312 42.4582 182.81 43.9553 184.656 43.9553Z"
      fill="#F7B80B"
    />
    <path
      d="M185 74.2498H95.4574C94.6829 74.2498 93.9401 73.9421 93.3924 73.3944C92.8448 72.8468 92.5371 72.104 92.5371 71.3295C92.5371 70.555 92.8448 69.8122 93.3924 69.2645C93.9401 68.7169 94.6829 68.4092 95.4574 68.4092H185C185.775 68.4092 186.518 68.7169 187.065 69.2645C187.613 69.8122 187.921 70.555 187.921 71.3295C187.921 72.104 187.613 72.8468 187.065 73.3944C186.518 73.9421 185.775 74.2498 185 74.2498Z"
      fill="#E6E6E6"
    />
    <path
      d="M185.001 85.7896H95.4575C94.683 85.7896 93.9402 85.4819 93.3925 84.9342C92.8448 84.3865 92.5371 83.6437 92.5371 82.8692C92.5371 82.0946 92.8448 81.3518 93.3925 80.8041C93.9402 80.2564 94.683 79.9487 95.4575 79.9487H185.001C185.775 79.9487 186.518 80.2564 187.066 80.8041C187.613 81.3518 187.921 82.0946 187.921 82.8692C187.921 83.6437 187.613 84.3865 187.066 84.9342C186.518 85.4819 185.775 85.7896 185.001 85.7896Z"
      fill="#E6E6E6"
    />
    <path
      d="M134.272 97.3282H95.4575C94.683 97.3282 93.9402 97.0205 93.3925 96.4728C92.8448 95.9251 92.5371 95.1823 92.5371 94.4077C92.5371 93.6332 92.8448 92.8904 93.3925 92.3427C93.9402 91.795 94.683 91.4873 95.4575 91.4873H134.272C134.656 91.4868 135.036 91.562 135.391 91.7085C135.746 91.8551 136.068 92.0701 136.34 92.3413C136.611 92.6126 136.827 92.9347 136.974 93.2893C137.121 93.6438 137.197 94.0239 137.197 94.4077C137.197 94.7916 137.121 95.1717 136.974 95.5262C136.827 95.8808 136.611 96.2029 136.34 96.4741C136.068 96.7454 135.746 96.9604 135.391 97.107C135.036 97.2535 134.656 97.3287 134.272 97.3282Z"
      fill="#E6E6E6"
    />
    <path
      d="M59.7349 106H5.82803C4.38334 105.999 2.99828 105.424 1.97672 104.402C0.955166 103.381 0.380538 101.996 0.378906 100.551V65.187C0.380537 63.7423 0.955166 62.3572 1.97672 61.3356C2.99828 60.3141 4.38333 59.7394 5.82803 59.7378H59.7349C61.1796 59.7394 62.5647 60.3141 63.5862 61.3356C64.6078 62.3572 65.1824 63.7422 65.184 65.187V100.551C65.1824 101.996 64.6078 103.381 63.5862 104.402C62.5647 105.424 61.1796 105.999 59.7349 106Z"
      fill="#E6E6E6"
    />
    <path
      d="M55.9447 64.1567H9.61644C8.30096 64.158 7.03969 64.681 6.10927 65.6109C5.17885 66.5409 4.65526 67.8019 4.65332 69.1174V96.6193C4.65516 97.935 5.17866 99.1963 6.10903 100.127C7.03939 101.057 8.3007 101.581 9.61644 101.582H39.1583C44.9242 101.577 50.4523 99.2836 54.5294 95.2065C58.6065 91.1294 60.8996 85.6013 60.9053 79.8355V69.1174C60.9039 67.8022 60.3808 66.5412 59.4508 65.6112C58.5208 64.6812 57.2599 64.1581 55.9447 64.1567Z"
      fill="white"
    />
    <path
      d="M42.8538 73.4544L42.7153 73.704C43.9477 70.6668 42.8271 66.9242 40.6286 64.634C38.4302 62.3438 35.3739 61.2968 32.3644 60.8814C30.1304 60.5731 27.8115 60.582 25.6872 61.4003C22.611 62.5854 20.2608 65.3286 18.2985 68.1824C17.4009 69.4877 16.5184 70.9758 16.6078 72.6072C16.6908 74.1212 17.5895 75.406 18.3255 76.6905C19.0615 77.975 19.6761 79.5686 19.1479 80.9706C18.8331 81.806 18.1679 82.4077 17.6281 83.0911C17.0883 83.7746 16.6443 84.6983 16.905 85.5558C17.0208 85.8839 17.2197 86.1763 17.4823 86.4045C17.7449 86.6327 18.0622 86.7889 18.4032 86.8578C19.0636 86.968 19.739 86.952 20.3935 86.8106L37.5472 84.2189C38.7129 84.0428 39.8186 83.5874 40.7702 82.8916C41.7218 82.1958 42.491 81.2801 43.0123 80.2228C43.5335 79.1654 43.7913 77.9977 43.7637 76.8192C43.7361 75.6406 43.4239 74.4862 42.8538 73.4544Z"
      fill="#2F2E41"
    />
    <path
      d="M30.0958 84.0988C34.7277 84.0988 38.4826 80.3439 38.4826 75.712C38.4826 71.0801 34.7277 67.3252 30.0958 67.3252C25.4639 67.3252 21.709 71.0801 21.709 75.712C21.709 80.3439 25.4639 84.0988 30.0958 84.0988Z"
      fill="#FFB9B9"
    />
    <path
      d="M51.9809 97.391C48.2637 100.119 43.7719 101.588 39.1611 101.582H13.4254C13.3736 101.54 13.3243 101.498 13.2725 101.454L15.6726 94.7565C16.215 93.567 17.071 92.5476 18.1487 91.8075C19.2264 91.0675 20.4852 90.6347 21.7902 90.5557L23.3171 90.4619L26.9581 87.3217C26.9581 87.3217 36.7683 86.9714 36.6228 86.8037L42.5653 90.378L42.5603 90.3534L44.8964 90.4964C46.2014 90.5752 47.4604 91.0078 48.5381 91.7479C49.6159 92.488 50.4718 93.5076 51.0139 94.6973L51.9809 97.391Z"
      fill="#F7B80B"
    />
    <path
      d="M38.0498 67.9282C37.8333 66.7829 36.6652 66.2591 35.6644 66.0053C32.7615 65.2646 29.7417 65.0988 26.7751 65.5171C24.6125 65.8255 22.3986 66.5056 20.8295 68.1936C19.5642 69.6149 18.8473 71.4409 18.8075 73.3434C18.7435 74.4809 18.9977 75.6138 19.5415 76.615C19.7848 77.0811 20.145 77.476 20.587 77.7608C21.0289 78.0457 21.5373 78.2107 22.0623 78.2397L22.1629 78.5896C26.3736 78.003 30.285 75.383 32.7485 71.4992C32.2861 73.1501 31.366 74.6365 30.0946 75.7865C32.9374 74.649 35.4085 72.7445 37.2327 70.2852C37.7308 69.624 38.2144 68.7995 38.0498 67.9282Z"
      fill="#2F2E41"
    />
    <path
      d="M162.94 97.6511C164.786 97.6511 166.283 96.154 166.283 94.3072C166.283 92.4605 164.786 90.9634 162.94 90.9634C161.093 90.9634 159.596 92.4605 159.596 94.3072C159.596 96.154 161.093 97.6511 162.94 97.6511Z"
      fill="#E6E6E6"
    />
    <path
      d="M173.798 97.6511C175.645 97.6511 177.142 96.154 177.142 94.3072C177.142 92.4605 175.645 90.9634 173.798 90.9634C171.951 90.9634 170.454 92.4605 170.454 94.3072C170.454 96.154 171.951 97.6511 173.798 97.6511Z"
      fill="#E6E6E6"
    />
    <path
      d="M184.656 97.6511C186.503 97.6511 188 96.154 188 94.3072C188 92.4605 186.503 90.9634 184.656 90.9634C182.81 90.9634 181.312 92.4605 181.312 94.3072C181.312 96.154 182.81 97.6511 184.656 97.6511Z"
      fill="#E6E6E6"
    />
  </Icon>
);
