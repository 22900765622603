import { gql } from "@apollo/client";

export const options = gql`
  query ($name: String!) {
    options(name: $name) {
      name
      count
      perDay
      perPerson
      priceUnit
      pricePackage
      priceDelivery
      priceTotal
    }
  }
`;

export const computePriceOne = gql`
  mutation ($input: [StaffInput!]!) {
    computePriceOne(input: $input) {
      price
      priceTva
      priceTtc
      tvaPercent
      staffDetails {
        count
        type
        workingHours
        breakHours
        dayWorkingHours
        nightWorkingHours
        dayHourlyRate
        nightHourlyRate
        increaseOvertime11th12th
        increaseHoliday
        priceBase
        priceTotalOne
        priceTotalAll
      }
    }
  }
`;

export const computePriceMultiple = gql`
  mutation (
    $input: [StaffInput!]!
    $options: OptionsInput
    $outfit: OutfitInput
    $operationInput: OperationInfoInput
    $discountCode: String
  ) {
    computePriceMultiple(
      input: $input
      options: $options
      outfit: $outfit
      operationInput: $operationInput
      discountCode: $discountCode
    ) {
      priceHt
      priceTva
      priceTtc
      tvaPercent
      discountPercent
      priceHtDiscounted
      discountErrorMessage
      staffDetails {
        count
        type
        workingHours
        breakHours
        dayWorkingHours
        nightWorkingHours
        dayHourlyRate
        nightHourlyRate
        increaseOvertime11th12th
        increaseHoliday
        priceBase
        priceTotalOne
        priceTotalAll
      }
    }
  }
`;

export type OptionOutput = {
  name: string;
  count: number;
  perDay: boolean;
  perPerson: boolean;
  priceUnit?: number;
  pricePackage?: number;
  priceDelivery?: number;
  priceTotal?: number;
};

export type StaffOutput = {
  count: number;
  type: string;
  workingHours: number;
  breakHours: number;
  dayWorkingHours: number;
  nightWorkingHours: number;
  dayHourlyRate: number;
  nightHourlyRate: number;
  increaseOvertime11th12th: number;
  increaseHoliday: number;
  priceBase: number;
  priceTotalOne: number;
  priceTotalAll: number;
};
