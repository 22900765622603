export const MarqueIcon = () => (
  <svg width="140" height="101" viewBox="0 0 140 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_905_40549)">
      <path d="M102.532 0.776367H0.435547V66.1636H102.532V0.776367Z" fill="#E6E6E6"/>
      <path d="M99.6128 8.97754H3.35498V62.2952H99.6128V8.97754Z" fill="white"/>
      <path d="M102.489 0.71875H0.39209V5.05615H102.489V0.71875Z" fill="#F7B80B"/>
      <path d="M3.6155 3.74077C4.05953 3.74077 4.41948 3.38082 4.41948 2.93679C4.41948 2.49277 4.05953 2.13281 3.6155 2.13281C3.17148 2.13281 2.81152 2.49277 2.81152 2.93679C2.81152 3.38082 3.17148 3.74077 3.6155 3.74077Z" fill="white"/>
      <path d="M6.66677 3.74077C7.1108 3.74077 7.47075 3.38082 7.47075 2.93679C7.47075 2.49277 7.1108 2.13281 6.66677 2.13281C6.22275 2.13281 5.86279 2.49277 5.86279 2.93679C5.86279 3.38082 6.22275 3.74077 6.66677 3.74077Z" fill="white"/>
      <path d="M9.71804 3.74077C10.1621 3.74077 10.522 3.38082 10.522 2.93679C10.522 2.49277 10.1621 2.13281 9.71804 2.13281C9.27402 2.13281 8.91406 2.49277 8.91406 2.93679C8.91406 3.38082 9.27402 3.74077 9.71804 3.74077Z" fill="white"/>
      <path d="M34.0248 13.3633H15.1982V33.7885H34.0248V13.3633Z" fill="#E6E6E6"/>
      <path d="M60.8969 13.3633H42.0703V33.7885H60.8969V13.3633Z" fill="#E6E6E6"/>
      <path d="M87.769 13.3633H68.9424V33.7885H87.769V13.3633Z" fill="#E6E6E6"/>
      <path d="M34.0248 37.4854H15.1982V57.9106H34.0248V37.4854Z" fill="#E6E6E6"/>
      <path d="M60.8969 37.4854H42.0703V57.9106H60.8969V37.4854Z" fill="#E6E6E6"/>
      <path d="M87.769 37.4854H68.9424V57.9106H87.769V37.4854Z" fill="#E6E6E6"/>
      <path d="M46.6208 42.735L50.0606 41.375L50.4607 48.1747C50.4607 48.1747 51.1806 50.2546 50.9406 51.0546C50.9406 51.0546 51.1006 52.4145 50.7006 52.4945C50.3006 52.5745 49.1803 52.7346 49.1012 52.6545C49.022 52.5743 49.0212 52.3344 49.0212 52.3344C49.0212 52.3344 48.2212 52.8944 48.1412 53.5343C48.1412 53.5343 44.7813 54.5742 44.7014 53.6143C44.6214 52.6543 46.3812 51.8543 46.3812 51.8543L47.7412 49.7744L46.6208 42.735Z" fill="white"/>
      <path d="M53.9011 42.735L57.3404 41.375L57.7404 48.1747C57.7404 48.1747 58.4603 50.2546 58.2204 51.0546C58.2204 51.0546 58.3804 52.4145 57.9804 52.4945C57.5803 52.5745 56.4601 52.7346 56.381 52.6545C56.3018 52.5743 56.301 52.3344 56.301 52.3344C56.301 52.3344 55.501 52.8944 55.421 53.5343C55.421 53.5343 52.0611 54.5742 51.9812 53.6143C51.9012 52.6543 53.661 51.8543 53.661 51.8543L55.021 49.7744L53.9011 42.735Z" fill="white"/>
      <path d="M54.2367 16.4766C54.2367 16.4766 53.5043 18.5232 54.353 19.4461L53.6454 21.8774C53.6454 21.8774 56.1592 29.4187 55.441 30.4721C55.441 30.4721 52.7597 31.8127 47.3965 29.9932L49.575 21.5422L49.2638 17.975L49.6947 16.2991L50.6523 16.2041C50.6523 16.2041 50.0778 18.3587 51.1312 18.6939C52.1846 19.0292 53.2121 16.2579 53.2121 16.2579L54.2367 16.4766Z" fill="#3F3D56"/>
      <path d="M20.4116 29.6877L21.3359 30.0659L25.7475 24.268L27.1339 31.0741L28.0582 30.9061C28.6511 25.0382 29.135 19.0531 28.5413 16.2223L25.1172 16.0752L23.5628 21.0335L22.8065 24.3946L20.4116 29.6877Z" fill="white"/>
      <path d="M76.5107 16.1768C76.5107 16.1768 75.2492 17.9304 75.3108 18.6381C75.3724 19.3458 76.1722 30.8834 76.1722 30.8834L78.0491 30.9757L77.4948 22.6686L77.9563 20.4226L79.0947 30.8834L81.4023 30.945L79.341 17.3768L78.941 16.1769L76.5107 16.1768Z" fill="white"/>
      <path d="M83.6404 44.4277C83.3055 44.0258 82.7297 43.8085 82.1768 43.691C82.1927 43.6408 80.4954 43.2901 80.4666 43.3623L79.8267 42.8525L77.8604 43.8735L76.7555 43.1213L76.2423 43.3034C76.2632 43.2128 74.649 43.4925 74.649 43.4925C74.3407 43.5035 73.9469 43.5229 73.506 43.5568C71.9622 43.6755 71.8433 47.674 71.8433 47.674C72.5536 47.3835 73.3109 47.2249 74.0782 47.2061L74.4954 51.9885C76.6416 51.8045 78.8925 52.0186 81.2256 52.5427L82.3197 48.7833L84.8675 47.7925C84.8675 47.7925 84.4322 45.3777 83.6404 44.4277Z" fill="#3F3D56"/>
      <path d="M28.9662 42.1513C28.6902 41.8201 28.2159 41.641 27.7603 41.5443C27.7739 41.5028 26.3749 41.2139 26.3509 41.2735L25.8236 40.8535L24.2038 41.6947L23.2934 41.0751L22.8706 41.2252C22.8878 41.1506 21.5578 41.3808 21.5578 41.3808C21.3044 41.39 20.9792 41.4059 20.616 41.4339C19.344 41.5318 19.2461 44.8261 19.2461 44.8261C19.8314 44.5866 20.4554 44.4559 21.0876 44.4405L21.4314 54.0866C23.1998 53.9349 25.0545 54.1115 26.9763 54.5433L27.8777 45.7403L29.977 44.9239C29.977 44.9239 29.6185 42.9343 28.9662 42.1513Z" fill="white"/>
      <path d="M119.073 44.7181H128.099V39.8882C128.099 38.6912 127.624 37.5433 126.777 36.6969C125.931 35.8505 124.783 35.375 123.586 35.375C122.389 35.375 121.241 35.8505 120.395 36.6969C119.548 37.5433 119.073 38.6912 119.073 39.8882V44.7181Z" fill="#2F2E41"/>
      <path d="M115.043 98.4558H113.102L112.178 90.9668H115.044L115.043 98.4558Z" fill="#FFB6B6"/>
      <path d="M115.538 97.8223H111.715C111.069 97.8223 110.449 98.079 109.992 98.5359C109.535 98.9928 109.278 99.6124 109.278 100.259V100.338H115.538V97.8223Z" fill="#2F2E41"/>
      <path d="M138.251 95.8647L136.561 96.8191L132.075 90.7523L134.57 89.3438L138.251 95.8647Z" fill="#FFB6B6"/>
      <path d="M138.371 95.0693L135.041 96.9484C134.479 97.2661 134.065 97.7944 133.892 98.4169C133.719 99.0394 133.8 99.7053 134.118 100.268L134.156 100.337L139.608 97.2596L138.371 95.0693Z" fill="#2F2E41"/>
      <path d="M125.843 64.7775L125.993 65.5266C125.993 65.5266 126.443 65.976 126.218 66.2007C125.993 66.4254 126.068 67.4742 126.068 67.4742C126.194 69.3865 128.692 80.1326 129.139 82.2313C129.139 82.2313 132.585 84.4786 135.282 88.8981C137.979 93.3177 138.053 94.4414 138.053 94.4414L135.057 95.7151L127.567 86.6511C127.567 86.6511 125.47 85.3775 124.72 84.254C123.971 83.1305 119.326 71.5193 119.326 71.5193L116.704 83.6546C116.704 83.6546 116.854 88.0742 116.179 90.9208C115.765 92.7172 115.514 94.5475 115.43 96.3892L111.91 96.0147C111.91 96.0147 112.134 85.3776 112.509 84.2539C112.509 84.2539 110.936 71.2946 114.082 66.1258L116.618 59.366L119.101 58.4102L125.843 64.7775Z" fill="#2F2E41"/>
      <path d="M123.632 36.5557C118.906 36.6364 118.907 43.667 123.632 43.747C128.359 43.6662 128.358 36.6356 123.632 36.5557Z" fill="#FFB8B8"/>
      <path d="M119.995 39.9653H120.798L121.017 39.4183L120.907 39.9653H125.301L125.158 38.8208L126.226 39.9653H127.292V39.3451C127.292 38.8661 127.198 38.3916 127.015 37.9488C126.832 37.5061 126.563 37.1037 126.225 36.7647C125.886 36.4257 125.484 36.1567 125.042 35.973C124.599 35.7894 124.125 35.6947 123.646 35.6943C123.167 35.694 122.692 35.788 122.25 35.9711C121.807 36.1541 121.404 36.4225 121.065 36.7611C120.726 37.0996 120.457 37.5016 120.274 37.9441C120.09 38.3866 119.995 38.8609 119.995 39.34V39.3451L119.995 39.9653Z" fill="#2F2E41"/>
      <path d="M118.759 45.8467L127.69 45.919L125.803 65.0671C125.803 65.0671 115.358 64.5159 115.352 61.3277L116.33 58.4945L118.759 45.8467Z" fill="#3F3D56"/>
      <path d="M128.074 66.918C127.937 66.7499 127.838 66.5537 127.785 66.3433C127.731 66.1328 127.725 65.9133 127.766 65.7001C127.806 65.4869 127.893 65.2852 128.021 65.1092C128.148 64.9333 128.312 64.7874 128.502 64.6817L128.113 61.3108L130.104 60.5752L130.586 65.3435C130.741 65.6737 130.77 66.0494 130.667 66.3996C130.564 66.7498 130.337 67.0502 130.028 67.244C129.718 67.4379 129.349 67.5117 128.989 67.4517C128.629 67.3916 128.303 67.2018 128.074 66.918Z" fill="#FFB6B6"/>
      <path d="M126.516 47.5692L127.691 45.9266C127.691 45.9266 128.495 45.7481 129.445 46.8566C130.395 47.9651 130.95 61.8214 130.95 61.8214L127.466 62.6132L125.407 52.795L126.516 47.5692Z" fill="#3F3D56"/>
      <path d="M106.83 62.5951L107.089 74.9053L115.058 79.1168L129.571 76.5251L129.928 63.7289L120.727 60.4893L106.83 62.5951Z" fill="white"/>
      <path d="M115.037 79.2494L106.963 74.9825L106.7 62.4856L120.739 60.3584L120.769 60.3689L130.057 63.6393L129.695 76.6318L115.037 79.2494ZM107.214 74.8274L115.079 78.9834L129.447 76.4177L129.797 63.8178L120.715 60.6195L106.959 62.7037L107.214 74.8274Z" fill="#CACACA"/>
      <path d="M114.88 65.9325L114.849 65.9202L106.782 62.713L106.876 62.4766L114.911 65.6713L129.91 63.6026L129.945 63.8547L114.88 65.9325Z" fill="#CACACA"/>
      <path d="M115.023 65.8006L114.769 65.8037L114.931 79.1183L115.185 79.1152L115.023 65.8006Z" fill="#CACACA"/>
      <path d="M113.86 65.295L110.953 64.1162L124.951 61.8857L127.858 63.0644L113.86 65.295Z" fill="#CACACA"/>
      <path d="M106.879 66.1656L106.779 66.3994L110.151 67.8514L110.252 67.6176L106.879 66.1656Z" fill="#CACACA"/>
      <path d="M108.407 70.3658L108.306 70.5996L111.679 72.0516L111.779 71.8179L108.407 70.3658Z" fill="#F7B80B"/>
      <path d="M62.4893 79.6976L62.7483 92.0078L70.7176 96.2193L85.2307 93.6277L85.5872 80.8315L76.3868 77.5918L62.4893 79.6976Z" fill="white"/>
      <path d="M70.6972 96.3519L62.623 92.085L62.3599 79.5882L76.3995 77.4609L76.4294 77.4714L85.7173 80.7418L85.3554 93.7343L70.6972 96.3519ZM62.8743 91.9299L70.7387 96.0859L85.1068 93.5202L85.4576 80.9203L76.3748 77.722L62.619 79.8063L62.8743 91.9299Z" fill="#CACACA"/>
      <path d="M70.54 83.0351L70.5088 83.0227L62.4424 79.8155L62.5363 79.5791L70.5716 82.7738L85.5699 80.7052L85.6048 80.9573L70.54 83.0351Z" fill="#CACACA"/>
      <path d="M70.6832 82.9032L70.4287 82.9062L70.5907 96.2208L70.8452 96.2177L70.6832 82.9032Z" fill="#CACACA"/>
      <path d="M68.8162 82.1078L66.6128 81.2187L80.6108 78.9883L82.814 79.8773L68.8162 82.1078Z" fill="#CACACA"/>
      <path d="M62.5396 83.2682L62.439 83.502L65.8114 84.954L65.912 84.7202L62.5396 83.2682Z" fill="#CACACA"/>
      <path d="M64.067 87.4684L63.9663 87.7021L67.3387 89.1542L67.4394 88.9204L64.067 87.4684Z" fill="#F7B80B"/>
      <path d="M76.3345 81.3858L76.6354 95.6761L85.8864 100.565L102.734 97.5564L103.147 82.7021L92.4674 78.9414L76.3345 81.3858Z" fill="#F7B80B"/>
      <path d="M85.8618 100.718L76.489 95.7653L76.1836 81.2584L92.4813 78.7891L92.516 78.8013L103.298 82.5977L102.878 97.6799L85.8618 100.718ZM76.7807 95.5852L85.91 100.41L102.589 97.4313L102.996 82.8048L92.4527 79.0922L76.4845 81.5116L76.7807 95.5852Z" fill="#3F3D56"/>
      <path d="M85.6799 85.26L85.6437 85.2456L76.2798 81.5226L76.3889 81.248L85.7166 84.9566L103.127 82.5553L103.168 82.8479L85.6799 85.26Z" fill="#3F3D56"/>
      <path d="M85.8458 85.1068L85.5503 85.1104L85.7383 100.566L86.0338 100.563L85.8458 85.1068Z" fill="#3F3D56"/>
      <path d="M83.8287 84.1905L81.1211 83.1518L97.3704 80.5625L100.078 81.6012L83.8287 84.1905Z" fill="#3F3D56"/>
      <path d="M76.3927 85.5304L76.2759 85.8018L80.1907 87.4873L80.3076 87.2159L76.3927 85.5304Z" fill="#3F3D56"/>
      <path d="M78.1652 90.4064L78.0483 90.6777L81.9632 92.3633L82.08 92.0919L78.1652 90.4064Z" fill="white"/>
      <path d="M111.348 64.63C111.544 64.5378 111.718 64.4036 111.857 64.2369C111.996 64.0702 112.097 63.8751 112.152 63.6652C112.208 63.4554 112.216 63.2359 112.178 63.0223C112.139 62.8087 112.055 62.6061 111.929 62.4289L113.738 59.5579L112.261 58.0332L109.764 62.1234C109.48 62.3537 109.291 62.6801 109.232 63.0406C109.172 63.4012 109.248 63.771 109.443 64.08C109.638 64.3889 109.94 64.6156 110.291 64.717C110.642 64.8184 111.018 64.7875 111.348 64.6302V64.63Z" fill="#FFB6B6"/>
      <path d="M120.131 46.6019L118.835 45.748C118.835 45.748 117.835 45.9723 116.771 48.0179C115.708 50.0636 111.419 59.2093 111.419 59.2093L113.182 60.9988L117.885 53.8238L120.131 46.6019Z" fill="#3F3D56"/>
    </g>
    <defs>
      <clipPath id="clip0_905_40549">
        <rect width="139.216" height="100" fill="white" transform="translate(0.39209 0.71875)"/>
      </clipPath>
    </defs>
  </svg>

);