import { Icon, IconProps } from "@chakra-ui/react";

export const MessageSentIcon = (props: IconProps) => (
  <Icon
    width="676"
    height="591"
    viewBox="0 0 676 591"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3915_41933)">
      <path
        d="M33.1522 201.258L0.614164 182.931C0.175261 163.323 4.52935 143.905 13.2983 126.362C20.8001 160.411 60.2795 176.61 78.4948 206.339C84.105 215.655 87.5469 226.114 88.5655 236.94C89.5841 247.767 88.1533 258.684 84.3791 268.883L86.5191 295.282C69.7023 290.245 54.1774 281.622 41.014 270.008C27.8506 258.393 17.3616 244.063 10.2697 228.004C6.38049 218.956 3.64617 209.454 2.13131 199.722C17.302 201.336 33.1522 201.258 33.1522 201.258Z"
        fill="#F2F2F2"
      />
      <path
        d="M618.077 534.67L619.075 512.236C629.476 506.676 641.077 503.74 652.871 503.681C636.639 516.951 638.668 542.532 627.663 560.378C624.188 565.917 619.511 570.604 613.979 574.09C608.447 577.577 602.201 579.773 595.704 580.518L582.121 588.834C580.257 578.444 580.668 567.773 583.325 557.556C585.983 547.34 590.823 537.821 597.514 529.655C601.312 525.112 605.668 521.064 610.478 517.609C613.731 526.187 618.077 534.67 618.077 534.67Z"
        fill="#F2F2F2"
      />
      <path
        d="M583.08 577.289L570.78 577.288L564.929 529.848L583.082 529.849L583.08 577.289Z"
        fill="#FFB6B6"
      />
      <path
        d="M586.217 589.211L546.559 589.21V588.708C546.559 584.614 548.185 580.688 551.08 577.794C553.975 574.899 557.901 573.273 561.995 573.272L569.24 567.777L582.755 573.273L586.217 573.273L586.217 589.211Z"
        fill="#2F2E41"
      />
      <path
        d="M525.939 577.289L513.64 577.288L507.789 529.848L525.942 529.849L525.939 577.289Z"
        fill="#FFB6B6"
      />
      <path
        d="M529.076 589.211L489.418 589.21V588.708C489.418 584.614 491.045 580.688 493.939 577.794C496.834 574.899 500.76 573.273 504.854 573.272L512.099 567.777L525.615 573.273L529.077 573.273L529.076 589.211Z"
        fill="#2F2E41"
      />
      <path
        d="M520.615 317.93L600.615 319.93C615.809 328.183 616.039 346.044 611.061 367.25C611.061 367.25 612.742 379.855 609.381 383.216C606.019 386.577 604.339 386.577 606.019 392.459C607.7 398.341 598.821 407.949 599.899 409.027C600.978 410.106 603.498 415.988 603.498 415.988L595.095 463.885C595.095 463.885 589.213 547.074 587.533 548.755C585.852 550.435 584.172 548.755 585.852 552.956C587.533 557.158 589.213 555.477 587.533 557.158C586.044 558.763 584.642 560.446 583.331 562.2H563.015C563.015 562.2 561.484 553.797 561.484 552.956C561.484 552.116 559.803 547.074 559.803 546.234C559.803 545.394 561.288 543.908 561.288 543.908C561.802 542.2 562.148 540.446 562.324 538.671C562.324 536.991 567.366 472.288 567.366 472.288L557.282 388.258L532.913 470.607C532.913 470.607 532.913 542.873 531.233 544.553C529.552 546.234 529.552 545.394 530.393 548.755C531.233 552.116 534.594 551.276 532.073 553.797C529.552 556.317 528.712 552.116 529.552 556.318L530.393 560.519L505.184 560.88C505.184 560.88 501.822 553.797 503.503 551.276C505.184 548.755 505.083 549.443 503.032 545.318C500.982 541.192 500.142 540.352 500.982 539.512C501.822 538.671 501.822 534.202 501.822 534.202L506.024 453.801C506.024 453.801 506.864 368.931 506.864 366.41C506.927 365.113 506.689 363.819 506.166 362.63V359.208L509.385 347.083L520.615 317.93Z"
        fill="#2F2E41"
      />
      <path
        d="M568.673 151.583C582.401 151.583 593.529 140.454 593.529 126.726C593.529 112.998 582.401 101.87 568.673 101.87C554.945 101.87 543.816 112.998 543.816 126.726C543.816 140.454 554.945 151.583 568.673 151.583Z"
        fill="#FFB6B6"
      />
      <path
        d="M556.245 168.011C550.89 171.195 547.686 177.044 545.847 182.996C542.443 194.016 540.394 205.41 539.745 216.925L537.803 251.401L513.748 324.808C534.595 342.448 612.957 328.817 612.957 328.817C612.957 328.817 615.363 328.016 612.957 325.61C610.552 323.205 608.21 325.336 610.616 322.93C613.021 320.525 611.363 323.205 610.561 320.799C609.759 318.394 610.561 319.997 611.363 319.195C612.165 318.394 605.156 311.177 605.156 311.177L611.57 269.041L619.588 184.048C609.966 172.02 590.616 164.93 590.616 164.93L585.616 155.93L560.616 157.93L556.245 168.011Z"
        fill="#E6E6E6"
      />
      <path
        d="M552.835 112.139C553.796 112.146 554.754 112.272 555.685 112.513C557.52 112.954 559.385 113.266 561.264 113.448C567.018 113.99 572.425 116.444 576.621 120.418C580.817 124.393 583.56 129.659 584.412 135.376C585.856 134.117 589.143 131.8 591.837 133.953C591.878 133.987 591.902 134.005 591.996 133.96C593.785 133.111 596.961 122.665 597.057 113.467C597.108 108.585 596.34 102.351 592.391 100.377L592.183 100.273L592.129 100.047C591.865 98.9491 588.918 97.0746 583.796 95.6156C574.487 92.9642 560.611 87.7993 552.798 95.4004C552.207 97.9629 550.283 103.447 548.34 104.068C546.188 104.756 544.329 105.35 544.036 107.99C543.792 111.746 543.809 115.515 544.09 119.269C544.819 116.972 546.25 114.961 548.182 113.52C549.555 112.596 551.179 112.113 552.835 112.139Z"
        fill="#2F2E41"
      />
      <path
        d="M506.57 249.481L547.173 223.478L526.723 196.293L506.529 221.133L443.61 224.992C441.956 223.356 439.86 222.237 437.581 221.771C435.301 221.305 432.934 221.513 430.771 222.369C428.607 223.225 426.739 224.691 425.394 226.591C424.049 228.49 423.286 230.739 423.198 233.064C423.11 235.39 423.7 237.69 424.897 239.686C426.093 241.682 427.845 243.286 429.937 244.304C432.03 245.321 434.374 245.708 436.682 245.416C438.991 245.125 441.165 244.168 442.939 242.662L506.57 249.481Z"
        fill="#FFB6B6"
      />
      <path
        d="M584.858 187.183C587.215 201.444 542.713 228.678 542.713 228.678C542.712 225.331 516.151 250.601 514.952 247.825C511.545 239.935 509.149 221.246 504.388 218.255C501.665 216.544 520.787 200.474 520.787 200.474C520.787 200.474 530.796 190.391 543.932 177.671C547.579 174.006 552.093 171.324 557.056 169.873C562.018 168.423 567.267 168.251 572.314 169.375C572.314 169.375 582.502 172.921 584.858 187.183Z"
        fill="#E6E6E6"
      />
      <path
        d="M668.548 256.307L641.69 216.263L614.944 237.285L640.206 256.948L645.397 319.771C643.796 321.46 642.722 323.579 642.305 325.868C641.887 328.157 642.145 330.518 643.046 332.663C643.948 334.809 645.454 336.645 647.381 337.95C649.308 339.254 651.573 339.969 653.9 340.008C656.226 340.047 658.514 339.408 660.484 338.17C662.454 336.931 664.021 335.146 664.994 333.032C665.967 330.918 666.304 328.567 665.963 326.265C665.623 323.963 664.62 321.81 663.078 320.068L668.548 256.307Z"
        fill="#FFB6B6"
      />
      <path
        d="M604.606 179.355C618.814 176.697 646.984 220.613 646.984 220.613C643.638 220.684 669.465 246.704 666.715 247.962C658.899 251.536 640.265 254.326 637.375 259.15C635.723 261.909 619.251 243.131 619.251 243.131C619.251 243.131 608.958 233.337 595.963 220.474C592.222 216.906 589.445 212.449 587.889 207.518C586.334 202.587 586.051 197.343 587.068 192.274C587.068 192.274 590.397 182.013 604.606 179.355Z"
        fill="#E6E6E6"
      />
      <path
        d="M675.409 589.409C675.41 589.565 675.379 589.72 675.32 589.865C675.26 590.009 675.172 590.141 675.062 590.251C674.951 590.362 674.82 590.45 674.675 590.509C674.531 590.569 674.376 590.599 674.219 590.599H393.929C393.614 590.599 393.311 590.473 393.088 590.25C392.865 590.027 392.739 589.724 392.739 589.409C392.739 589.093 392.865 588.79 393.088 588.567C393.311 588.344 393.614 588.219 393.929 588.219H674.219C674.376 588.218 674.531 588.249 674.675 588.308C674.82 588.368 674.951 588.456 675.062 588.566C675.172 588.677 675.26 588.808 675.32 588.953C675.379 589.097 675.41 589.252 675.409 589.409Z"
        fill="#CCCCCC"
      />
      <path
        d="M415.753 228.792H75.5829C70.2682 228.798 65.173 230.912 61.415 234.67C57.657 238.428 55.5431 243.523 55.5371 248.838V460.626C55.5432 465.941 57.657 471.036 61.415 474.794C65.173 478.552 70.2683 480.666 75.5829 480.672H415.753C421.067 480.666 426.163 478.552 429.921 474.794C433.679 471.036 435.793 465.941 435.799 460.626V248.838C435.793 243.523 433.679 238.428 429.921 234.67C426.163 230.912 421.068 228.798 415.753 228.792Z"
        fill="#F7B80B"
      />
      <path
        d="M415.753 228.792H75.5829C70.2682 228.798 65.173 230.912 61.415 234.67C57.657 238.428 55.5431 243.523 55.5371 248.838V460.626C55.5432 465.941 57.657 471.036 61.415 474.794C65.173 478.552 70.2683 480.666 75.5829 480.672H415.753C421.067 480.666 426.163 478.552 429.921 474.794C433.679 471.036 435.793 465.941 435.799 460.626V248.838C435.793 243.523 433.679 238.428 429.921 234.67C426.163 230.912 421.068 228.798 415.753 228.792ZM422.331 380.922C422.25 402.483 413.721 423.154 398.575 438.499C383.429 453.845 362.871 462.643 341.313 463.006C306.796 463.455 360.411 463.63 318.781 463.63C223.589 463.63 124.329 462.721 87.2941 462.131C82.4214 462.03 77.7811 460.029 74.3639 456.554C70.9466 453.079 69.0233 448.406 69.0045 443.532V248.853C69.0064 247.108 69.6994 245.435 70.932 244.199C72.1645 242.964 73.8363 242.266 75.5815 242.26H415.739C417.484 242.262 419.157 242.955 420.393 244.187C421.628 245.42 422.325 247.091 422.331 248.836V380.922Z"
        fill="#3F3D56"
      />
      <path
        d="M373.878 106.838H114.704C112.411 106.841 110.175 107.552 108.301 108.874C106.427 110.195 105.006 112.063 104.233 114.222L64.2057 226.522C63.6083 228.198 63.4221 229.993 63.6625 231.756C63.903 233.519 64.5633 235.199 65.5877 236.654C66.6122 238.109 67.971 239.296 69.5499 240.117C71.1288 240.938 72.8817 241.368 74.6611 241.37L422.256 241.987H422.271C424.111 241.987 425.921 241.53 427.541 240.658C429.161 239.786 430.539 238.526 431.551 236.99C432.564 235.454 433.18 233.691 433.343 231.859C433.507 230.027 433.213 228.183 432.489 226.492L384.096 113.575C383.24 111.575 381.815 109.869 379.999 108.672C378.182 107.474 376.054 106.836 373.878 106.838Z"
        fill="#3F3D56"
      />
      <path
        d="M433.389 228.931C433.388 230.436 433.081 231.926 432.489 233.311L384.099 320.23C383.237 322.227 381.81 323.927 379.995 325.123C378.179 326.319 376.053 326.958 373.879 326.961H114.709C112.415 326.958 110.177 326.248 108.302 324.928C106.426 323.607 105.004 321.74 104.229 319.581L64.2085 233.281C63.718 231.906 63.5032 230.448 63.5765 228.99C63.6498 227.532 64.0097 226.102 64.6357 224.784C65.2616 223.465 66.1413 222.282 67.2245 221.304C68.3077 220.325 69.5732 219.57 70.9485 219.081C72.1383 218.651 73.3936 218.431 74.6585 218.431L117.119 218.351L134.449 218.321L362.489 217.921L379.829 217.891L422.259 217.811H422.269C425.218 217.811 428.046 218.983 430.131 221.068C432.216 223.153 433.388 225.981 433.389 228.931Z"
        fill="#3F3D56"
      />
      <path
        d="M101.085 387.658C106.224 387.658 176.799 391.971 176.799 397.11C176.799 402.25 106.224 406.269 101.085 406.269C98.6169 406.269 96.25 405.289 94.5048 403.543C92.7597 401.798 91.7793 399.431 91.7793 396.963C91.7793 394.495 92.7597 392.128 94.5048 390.383C96.25 388.638 98.6169 387.658 101.085 387.658Z"
        fill="#3F3D56"
      />
      <path
        d="M357.738 38.5205H139.208C133.352 38.5273 127.737 40.8568 123.596 44.998C119.454 49.1392 117.125 54.7539 117.118 60.6105V326.961H373.878C375.987 326.967 378.052 326.367 379.828 325.23V60.6105C379.821 54.754 377.492 49.1392 373.351 44.998C369.209 40.8568 363.595 38.5273 357.738 38.5205Z"
        fill="#E6E6E6"
      />
      <path
        d="M274.328 56.4304H154.568C149.235 56.4356 144.122 58.5557 140.351 62.3257C136.579 66.0956 134.456 71.2076 134.448 76.5405V326.96H362.488V144.59C362.461 121.217 353.164 98.8091 336.637 82.2817C320.11 65.7543 297.701 56.4574 274.328 56.4304Z"
        fill="white"
      />
      <path
        d="M323.638 304.72H173.298C171.999 304.72 170.753 305.237 169.834 306.156C168.915 307.075 168.398 308.321 168.398 309.62C168.398 310.92 168.915 312.166 169.834 313.085C170.753 314.004 171.999 314.52 173.298 314.52H323.638C324.938 314.52 326.184 314.004 327.103 313.085C328.022 312.166 328.538 310.92 328.538 309.62C328.538 308.321 328.022 307.075 327.103 306.156C326.184 305.237 324.938 304.72 323.638 304.72Z"
        fill="#E4E4E4"
      />
      <path
        d="M323.639 285.341H173.299C171.998 285.341 170.75 285.857 169.83 286.777C168.91 287.697 168.394 288.945 168.394 290.246C168.394 291.546 168.91 292.794 169.83 293.714C170.75 294.634 171.998 295.151 173.299 295.151H323.639C324.939 295.151 326.187 294.634 327.107 293.714C328.027 292.794 328.544 291.546 328.544 290.246C328.544 288.945 328.027 287.697 327.107 286.777C326.187 285.857 324.939 285.341 323.639 285.341Z"
        fill="#E4E4E4"
      />
      <path
        d="M238.468 265.97H173.298C171.999 265.97 170.753 266.487 169.834 267.406C168.915 268.325 168.398 269.571 168.398 270.87C168.398 272.17 168.915 273.416 169.834 274.335C170.753 275.254 171.999 275.77 173.298 275.77H238.468C239.768 275.77 241.014 275.254 241.933 274.335C242.852 273.416 243.368 272.17 243.368 270.87C243.368 269.571 242.852 268.325 241.933 267.406C241.014 266.487 239.768 265.97 238.468 265.97Z"
        fill="#E4E4E4"
      />
      <path
        d="M323.639 225.391H173.299C171.998 225.391 170.75 225.907 169.83 226.827C168.91 227.747 168.394 228.995 168.394 230.296C168.394 231.597 168.91 232.844 169.83 233.764C170.75 234.684 171.998 235.201 173.299 235.201H323.639C324.939 235.201 326.187 234.684 327.107 233.764C328.027 232.844 328.544 231.597 328.544 230.296C328.544 228.995 328.027 227.747 327.107 226.827C326.187 225.907 324.939 225.391 323.639 225.391Z"
        fill="#E4E4E4"
      />
      <path
        d="M323.638 206.021H173.298C171.999 206.021 170.753 206.537 169.834 207.456C168.915 208.375 168.398 209.621 168.398 210.921C168.398 212.22 168.915 213.466 169.834 214.385C170.753 215.304 171.999 215.82 173.298 215.82H323.638C324.938 215.82 326.184 215.304 327.103 214.385C328.022 213.466 328.538 212.22 328.538 210.921C328.538 209.621 328.022 208.375 327.103 207.456C326.184 206.537 324.938 206.021 323.638 206.021Z"
        fill="#E4E4E4"
      />
      <path
        d="M238.469 186.641H173.299C171.998 186.641 170.75 187.157 169.83 188.077C168.91 188.997 168.394 190.245 168.394 191.546C168.394 192.847 168.91 194.094 169.83 195.014C170.75 195.934 171.998 196.451 173.299 196.451H238.469C239.769 196.451 241.017 195.934 241.937 195.014C242.857 194.094 243.374 192.847 243.374 191.546C243.374 190.245 242.857 188.997 241.937 188.077C241.017 187.157 239.769 186.641 238.469 186.641Z"
        fill="#E4E4E4"
      />
      <path
        d="M323.639 146.061H173.299C171.998 146.061 170.75 146.577 169.83 147.497C168.91 148.417 168.394 149.665 168.394 150.966C168.394 152.266 168.91 153.514 169.83 154.434C170.75 155.354 171.998 155.871 173.299 155.871H323.639C324.939 155.871 326.187 155.354 327.107 154.434C328.027 153.514 328.544 152.266 328.544 150.966C328.544 149.665 328.027 148.417 327.107 147.497C326.187 146.577 324.939 146.061 323.639 146.061Z"
        fill="#F7B80B"
      />
      <path
        d="M323.639 126.69H173.299C171.998 126.69 170.75 127.207 169.83 128.127C168.91 129.047 168.394 130.295 168.394 131.595C168.394 132.896 168.91 134.144 169.83 135.064C170.75 135.984 171.998 136.5 173.299 136.5H323.639C324.939 136.5 326.187 135.984 327.107 135.064C328.027 134.144 328.544 132.896 328.544 131.595C328.544 130.295 328.027 129.047 327.107 128.127C326.187 127.207 324.939 126.69 323.639 126.69Z"
        fill="#F7B80B"
      />
      <path
        d="M238.469 107.311H173.299C171.998 107.311 170.75 107.827 169.83 108.747C168.91 109.667 168.394 110.915 168.394 112.216C168.394 113.516 168.91 114.764 169.83 115.684C170.75 116.604 171.998 117.121 173.299 117.121H238.469C239.769 117.121 241.017 116.604 241.937 115.684C242.857 114.764 243.374 113.516 243.374 112.216C243.374 110.915 242.857 109.667 241.937 108.747C241.017 107.827 239.769 107.311 238.469 107.311Z"
        fill="#F7B80B"
      />
      <path
        d="M371.635 76.3256C392.712 76.3256 409.798 59.2396 409.798 38.1628C409.798 17.0861 392.712 0 371.635 0C350.559 0 333.473 17.0861 333.473 38.1628C333.473 59.2396 350.559 76.3256 371.635 76.3256Z"
        fill="#F7B80B"
      />
      <path
        d="M366.43 58.0248C365.771 58.0248 365.121 57.8713 364.531 57.5765C363.942 57.2818 363.429 56.8537 363.034 56.3264L352.623 42.4443C352.286 41.9985 352.041 41.4907 351.902 40.95C351.762 40.4093 351.73 39.8464 351.808 39.2934C351.887 38.7405 352.073 38.2084 352.357 37.7277C352.642 37.247 353.018 36.8271 353.465 36.492C353.911 36.157 354.42 35.9134 354.961 35.7752C355.502 35.637 356.065 35.6069 356.618 35.6866C357.171 35.7664 357.702 35.9544 358.182 36.2399C358.662 36.5255 359.081 36.9029 359.415 37.3506L366.226 46.4319L383.721 20.1905C384.345 19.2538 385.316 18.6034 386.42 18.3826C387.524 18.1618 388.671 18.3886 389.608 19.0132C390.544 19.6377 391.195 20.6088 391.416 21.7128C391.636 22.8168 391.41 23.9633 390.785 24.9001L369.962 56.1344C369.585 56.6993 369.079 57.1656 368.485 57.4943C367.891 57.823 367.226 58.0044 366.548 58.0235C366.508 58.0241 366.469 58.0248 366.43 58.0248Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3915_41933">
        <rect
          width="674.818"
          height="590.599"
          fill="white"
          transform="translate(0.59082)"
        />
      </clipPath>
    </defs>
  </Icon>
);
