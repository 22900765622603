export const DressingPopupStartIcon = () => (
  <svg width="96" height="214" viewBox="0 0 96 214" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1055_69589)">
      <path
        d="M78.8076 201.835C78.3671 203.065 77.6457 204.176 76.7001 205.081C75.2765 206.474 73.488 207.435 71.6829 208.286C61.4111 213.104 49.9655 214.893 38.7023 213.441C35.608 213.037 32.4605 212.353 29.8639 210.631C29.0328 210.079 28.2831 209.415 27.6367 208.658C30.5 206.556 34.1607 205.42 37.7463 205.021C42.8866 204.449 48.0758 205.142 53.2481 205.168C59.339 205.198 65.399 204.305 71.2194 202.52C73.2384 201.9 75.3152 201.165 77.4106 201.441C77.8926 201.506 78.3629 201.639 78.8076 201.835Z"
        fill="#F2F2F2"/>
      <path
        d="M33.8518 173.881C31.7473 173.224 29.8692 171.513 29.543 169.344C31.6116 170.551 34.043 170.992 36.4069 170.588C37.3181 170.429 38.3148 170.158 39.1209 170.609C39.6139 170.917 39.9693 171.401 40.1136 171.962C40.2538 172.517 40.3219 173.089 40.316 173.662L40.3863 173.786C38.242 174.3 35.9562 174.538 33.8518 173.881Z"
        fill="#CCCCCC"/>
      <path
        d="M37.715 179.092C35.6106 178.435 33.7324 176.723 33.4062 174.555C35.4749 175.762 37.9062 176.203 40.2702 175.799C41.1813 175.64 42.1781 175.369 42.9842 175.82C43.4772 176.128 43.8325 176.612 43.9769 177.173C44.1171 177.728 44.1851 178.3 44.1793 178.873L44.2495 178.997C42.1053 179.511 39.8195 179.748 37.715 179.092Z"
        fill="#CCCCCC"/>
      <path
        d="M25.5783 176.35C23.4739 175.693 21.5957 173.981 21.2695 171.812C23.3381 173.02 25.7695 173.46 28.1335 173.057C29.0446 172.898 30.0414 172.627 30.8475 173.078C31.3405 173.386 31.6958 173.87 31.8402 174.43C31.9804 174.986 32.0484 175.557 32.0426 176.13L32.1128 176.255C29.9685 176.769 27.6827 177.006 25.5783 176.35Z"
        fill="#CCCCCC"/>
      <path
        d="M34.9553 170.314C32.8508 169.658 30.9727 167.946 30.6465 165.777C32.7151 166.984 35.1465 167.425 37.5105 167.022C38.4216 166.863 39.4183 166.592 40.2244 167.043C40.7174 167.35 41.0728 167.835 41.2171 168.395C41.3574 168.951 41.4254 169.522 41.4196 170.095L41.4898 170.22C39.3455 170.734 37.0597 170.971 34.9553 170.314Z"
        fill="#CCCCCC"/>
      <path
        d="M52.92 81.6484C49.0209 81.6484 42.9176 92.0812 42.8549 92.1893C37.2313 99.3252 31.4477 117.395 28.4999 127.532C28.1646 128.683 28.0734 129.89 28.2319 131.078C28.3903 132.265 28.7951 133.407 29.4205 134.431C30.046 135.455 30.8784 136.338 31.8652 137.026C32.852 137.713 33.9715 138.189 35.153 138.424C41.3149 139.647 46.5538 141.386 50.7237 143.592C60.5954 148.814 72.3016 143.877 73.4872 143.35L69.602 123.39C65.2873 109.144 56.3106 82.0991 53.2035 81.6677C53.1096 81.6548 53.0148 81.6484 52.92 81.6484Z"
        fill="#2F2E41"/>
      <path opacity="0.1"
            d="M69.7349 123.359C69.7349 123.359 57.2378 82.0914 53.2215 81.5337C49.2053 80.976 44.3778 92.2357 44.3778 92.2357C41.845 102.645 35.5227 116.96 30.9324 126.628C30.3669 127.819 30.0777 129.12 30.0861 130.437C30.0946 131.753 30.4005 133.051 30.9812 134.234C31.562 135.417 32.4027 136.455 33.4413 137.272C34.4799 138.088 35.6898 138.662 36.9816 138.951C41.3503 139.925 46.3308 141.426 50.6578 143.715C61.2004 149.291 73.6422 143.432 73.6422 143.432L69.7349 123.359Z"
            fill="black"/>
      <path d="M61.7786 126.127L41.6406 123.659L45.7786 105.557L61.7786 106.105V126.127Z" fill="#9E616A"/>
      <path d="M50.5671 203.361L46.5312 202.71L47.1365 186.924L53.0929 187.884L50.5671 203.361Z" fill="#9E616A"/>
      <path d="M70.6838 194.993L67.0337 196.824L57.3652 182.874L62.7525 180.172L70.6838 194.993Z" fill="#9E616A"/>
      <path
        d="M59.1617 189.919L42.3537 147.307C36.3383 130.97 41.7129 119.711 41.7681 119.599L41.7957 119.544L41.853 119.523C42.5433 122.194 58.1929 122.013 61.7791 117.898C63.8724 119.647 66.6583 123.763 67.2552 128.682C67.8789 133.959 67.4059 139.307 65.8655 144.394L59.2448 166.709L67.4359 185.055L59.1617 189.919Z"
        fill="#2F2E41"/>
      <path d="M51.0572 163.488L44.9883 194.085L53.071 195.6L58.1566 169.116L51.0572 163.488Z" fill="#2F2E41"/>
      <path
        d="M51.9019 198.237L51.4081 198.207L46.7157 197.927L46.0536 197.889V200.382L40.4923 204.2C40.2175 204.388 40.0106 204.659 39.9018 204.973C39.793 205.287 39.788 205.627 39.8876 205.944C39.9871 206.261 40.186 206.538 40.4551 206.734C40.7242 206.93 41.0495 207.036 41.3833 207.036H48.3461L49.5461 204.573L50.015 207.036H52.6385L51.9019 198.237Z"
        fill="#2F2E41"/>
      <path
        d="M75.9035 200.929L70.4829 193.936L70.2042 194.092L66.5573 196.138L65.3822 196.796L66.7394 198.889L64.1518 205.096C64.0245 205.402 63.9989 205.741 64.0788 206.063C64.1587 206.385 64.34 206.673 64.5961 206.886C64.8522 207.098 65.1699 207.224 65.5029 207.244C65.8358 207.264 66.1666 207.179 66.447 206.999L72.298 203.244L71.9615 200.526L73.6994 202.345L75.9035 200.929Z"
        fill="#2F2E41"/>
      <path
        d="M60.471 77.8478C66.0123 77.8478 70.5045 73.3815 70.5045 67.8721C70.5045 62.3627 66.0123 57.8965 60.471 57.8965C54.9296 57.8965 50.4375 62.3627 50.4375 67.8721C50.4375 73.3815 54.9296 77.8478 60.471 77.8478Z"
        fill="#2F2E41"/>
      <path
        d="M9.68549 109.785C8.75986 109.409 8.00492 108.709 7.56372 107.817C7.12253 106.926 7.02574 105.904 7.29168 104.946C7.55763 103.987 8.16785 103.16 9.0067 102.619C9.84554 102.078 10.8547 101.862 11.8431 102.012L24.7159 99.5508L29.5459 105.256L15.2103 106.927C15.0823 107.503 14.8287 108.045 14.4674 108.513C14.1061 108.982 13.6458 109.366 13.1191 109.638C12.5924 109.911 12.0119 110.065 11.4187 110.09C10.8254 110.116 10.2338 110.011 9.68551 109.785H9.68549Z"
        fill="#9E616A"/>
      <path d="M49.6877 97.0339L33.7109 106.778L19.2971 107.202L14.9727 100.949L30.6844 98.0769L52.3219 81.6562L49.6877 97.0339Z"
            fill="#2F2E41"/>
      <path
        d="M46.8845 90.9546C42.9826 94.834 46.6058 98.9905 46.6058 98.9905L42.1465 108.966L59.7051 113.677L65.8412 86.4161L61.6606 81.9825C60.7711 81.953 61.0796 79.182 60.3692 79.155C57.7782 79.0564 56.3115 78.9747 55.4812 78.9138C54.3994 78.8345 53.2948 81.5331 53.2948 81.5331C51.8299 82.9585 46.8845 90.9546 46.8845 90.9546Z"
        fill="#F7B80B"/>
      <path
        d="M77.8479 132.942C76.914 132.171 76.055 131.314 75.2824 130.383C73.8594 128.691 72.5857 126.879 71.4755 124.969C69.9728 122.403 68.7158 119.703 67.7211 116.903C67.7183 116.898 67.7183 116.892 67.7155 116.887C67.4038 116.012 67.1151 115.111 66.8493 114.185C66.8469 114.175 66.8432 114.164 66.8383 114.155C66.39 112.59 66.0271 111.003 65.7514 109.399C64.6369 102.907 66.7997 96.9936 68.8107 93.1757C68.9824 92.8537 69.129 92.519 69.2493 92.1746C69.7977 90.6667 69.8404 89.0228 69.371 87.4887C68.9016 85.9546 67.9453 84.6128 66.6452 83.664L66.5486 83.5927C65.7535 83.0084 64.8499 82.5864 63.89 82.3512C62.93 82.1159 61.9327 82.0719 60.9555 82.2219C59.9784 82.3718 59.0408 82.7126 58.1968 83.2247C57.3528 83.7368 56.6192 84.41 56.0383 85.2054C56.031 85.2129 56.0245 85.2212 56.019 85.2301C52.5817 89.9914 49.3431 97.0293 50.7417 105.726C51.0753 107.774 51.6264 109.781 52.3859 111.714C52.6176 112.309 52.8714 112.91 53.1473 113.516C54.2755 115.984 55.3827 118.301 56.4686 120.466C57.4204 122.366 58.3509 124.15 59.2604 125.817C62.2038 131.22 64.8962 135.392 67.1803 138.313C69.8231 141.686 71.9197 143.389 73.23 143.389C73.3639 143.391 73.4972 143.37 73.6245 143.329C73.63 143.326 73.6328 143.326 73.6383 143.324C74.659 142.981 75.9142 141.925 76.9597 140.584C77.9279 139.336 78.7142 137.841 78.9624 136.439C79.1473 135.394 79.1031 133.941 77.8479 132.942Z"
        fill="#2F2E41"/>
      <path
        d="M60.986 76.8683C64.5652 74.923 65.8806 70.4613 63.924 66.9027C61.9674 63.3442 57.4798 62.0364 53.9006 63.9817C50.3214 65.9269 49.006 70.3887 50.9626 73.9472C52.9192 77.5058 57.4068 78.8136 60.986 76.8683Z"
        fill="#9E616A"/>
      <path
        d="M47.8848 69.3205H58.5497L58.6591 67.7981L59.2059 69.3205H60.8481L61.0648 66.3032L62.1486 69.3205H65.3261V69.171C65.3237 67.0704 64.4834 65.0566 62.9894 63.5712C61.4955 62.0859 59.47 61.2504 57.3573 61.248H55.8536C53.7409 61.2504 51.7154 62.0859 50.2214 63.5712C48.7275 65.0566 47.8872 67.0704 47.8848 69.171V69.3205Z"
        fill="#2F2E41"/>
      <path
        d="M82.2423 138.111C81.9143 139.05 81.2512 139.836 80.3788 140.322C79.5063 140.807 78.4852 140.957 77.5087 140.744C76.5323 140.531 75.6684 139.969 75.0809 139.165C74.4933 138.361 74.2228 137.37 74.3207 136.381L71.166 123.731L76.6401 118.633L79.0784 132.777C79.6642 132.874 80.2216 133.097 80.7113 133.431C81.201 133.764 81.6111 134.201 81.9128 134.71C82.2144 135.218 82.4003 135.786 82.4572 136.374C82.5141 136.961 82.4408 137.554 82.2423 138.111V138.111Z"
        fill="#9E616A"/>
      <path d="M68.0222 87.8945L79.5287 127.883L73.2953 131.556L55.2344 88.4302L68.0222 87.8945Z" fill="#2F2E41"/>
      <path
        d="M88.2307 73.7319C86.0311 72.215 84.6631 69.7659 83.7222 67.2742C82.7814 64.7825 82.1891 62.1641 81.1229 59.7228C79.8321 56.7751 77.8837 54.1581 75.4251 52.0699C73.8508 50.7354 72.0192 49.6085 69.9773 49.278C67.9353 48.9475 65.6598 49.5374 64.3539 51.1327C63.048 52.728 63.0507 55.3677 64.6323 56.6936C64.1601 56.1996 63.2446 56.322 62.8347 56.8682C62.6457 57.1538 62.5531 57.4919 62.5704 57.8333C62.5877 58.1748 62.7138 58.5019 62.9307 58.7672C63.0885 58.9637 63.2824 59.1287 63.5021 59.2533C63.9953 60.3494 64.8392 61.2525 65.9024 61.822C67.1947 62.4556 68.9175 62.3233 69.8959 61.2717C70.8744 60.2201 70.7345 58.2562 69.4739 57.5625C71.435 57.1871 73.4641 58.3193 74.6013 59.9517C75.7384 61.5841 76.1209 63.6235 76.2145 65.6069C76.3081 67.5905 76.1416 69.5828 76.3099 71.5614C76.4782 73.54 77.0224 75.565 78.3366 77.0602C80.4902 79.5104 84.2768 79.9675 87.4099 79.027C90.5429 78.0865 93.1812 76.0072 95.734 73.971C93.6104 75.5916 90.4304 75.2488 88.2307 73.7319Z"
        fill="#2F2E41"/>
      <path
        d="M1.58529 104.321C0.162692 105.364 -0.198892 107.273 0.290032 108.893C0.544202 109.778 1.04586 110.573 1.73669 111.186C2.42752 111.799 3.27915 112.205 4.19254 112.355C6.24115 112.739 8.43373 111.94 10.0272 110.675C11.8233 109.25 12.8647 107.17 13.899 105.183C15.3 102.492 16.8377 99.865 18.3178 97.2158C19.9685 94.2609 21.6186 91.3057 23.2681 88.3502C26.9759 81.702 30.6663 75.0443 34.3391 68.3769C38.0076 61.702 41.6342 55.0045 45.219 48.2844C48.124 42.8085 51.0008 37.3129 53.6919 31.7293C53.9563 31.1806 54.2184 30.6307 54.478 30.0797C54.5544 29.8757 54.5472 29.6501 54.458 29.4513C54.3689 29.2525 54.2048 29.0965 54.0011 29.0167C53.7958 28.943 53.5698 28.9512 53.3705 29.0396C53.1711 29.128 53.014 29.2897 52.932 29.4908C50.4829 34.6911 47.8331 39.7986 45.1572 44.8862C41.8028 51.2636 38.3877 57.6089 34.9119 63.9223C31.3191 70.4868 27.6992 77.0365 24.0521 83.5714C20.8958 89.2407 17.7334 94.9066 14.5649 100.569C13.7956 101.948 13.0152 103.321 12.2869 104.722C11.3459 106.531 10.3675 108.426 8.66079 109.641C7.21137 110.673 5.15668 111.22 3.47785 110.422C2.22737 109.828 1.58948 108.344 1.76116 107.061C1.79591 106.769 1.89179 106.487 2.04264 106.234C2.19349 105.981 2.39597 105.762 2.63703 105.592C3.49604 104.962 2.4408 103.693 1.58529 104.321V104.321Z"
        fill="#2F2E41"/>
      <path
        d="M91.1075 69.3021L91.0098 69.2902C87.2502 68.8314 83.5251 68.2778 79.9382 67.6446C77.3857 67.1939 74.8934 66.7036 72.5308 66.1872C70.5383 65.7541 68.5687 65.2901 66.6768 64.8083C64.5673 64.2742 62.474 63.6991 60.4555 63.0987C59.6129 62.8521 58.7554 62.591 57.907 62.323C56.335 61.8268 54.8199 61.3221 53.4038 60.8233C52.0294 60.3455 50.6434 59.8357 49.2848 59.3083C49.2558 59.2997 49.2273 59.2893 49.1996 59.2773C46.4464 58.214 43.7431 57.0666 41.1674 55.8679C40.6684 55.6377 40.1722 55.404 39.6791 55.1668C24.8218 48.0283 12.3825 38.3428 2.70728 26.3793L2.65234 26.3114L2.69083 26.2332C7.86259 15.6866 16.8155 7.44512 27.7893 3.12899C38.7632 -1.18713 50.964 -1.26561 61.9931 2.90899C63.352 3.42506 64.6899 4.00248 65.9697 4.62528L69.4147 6.30004C71.2765 7.20472 73.0771 8.22887 74.8051 9.36601C84.3661 15.6746 91.2952 25.2228 94.3022 36.2329C97.3092 47.2429 96.1891 58.9643 91.1505 69.2144L91.1075 69.3021Z"
        fill="#F7B80B"/>
      <path
        d="M9.60097 32.9603C22.4408 23.4974 36.4412 15.6998 51.2639 9.75598C55.4221 8.08764 59.6367 6.56886 63.9077 5.19962C64.4132 5.03745 64.0632 4.29123 63.561 4.45231C48.2833 9.35431 33.6974 16.1749 20.1567 24.7488C16.3733 27.1416 12.6794 29.667 9.07509 32.3249C8.64634 32.641 9.17387 33.2751 9.60097 32.9603Z"
        fill="#3F3D56"/>
      <path
        d="M72.1636 7.90234C72.1149 7.97213 72.0898 8.05555 72.0919 8.14049C72.0941 8.22544 72.1234 8.30748 72.1757 8.37472C74.513 11.5969 76.4949 15.0597 78.0872 18.7034C79.6801 22.347 80.8945 26.1428 81.7114 30.0319C82.5369 33.9211 82.9706 37.8825 83.0061 41.8573C83.0431 45.8314 82.6777 49.7991 81.9154 53.7004C81.2632 57.0365 80.3045 60.3061 79.0513 63.468C78.8415 63.9989 78.6228 64.5261 78.3952 65.0497C78.1985 65.5019 77.9958 65.9508 77.7869 66.3963C77.5623 66.8789 78.3377 67.1688 78.5618 66.6916C78.8357 66.1008 79.1016 65.5065 79.3516 64.9051C80.664 61.7924 81.6932 58.569 82.4269 55.2736C83.3018 51.3431 83.7706 47.334 83.826 43.3088C83.8848 39.2778 83.543 35.2508 82.8056 31.2866C82.0697 27.3268 80.9329 23.4511 79.4126 19.7187C77.8883 15.9717 75.9627 12.3988 73.669 9.06181C73.5648 8.90845 73.4578 8.75483 73.3506 8.60394L72.1636 7.90234Z"
        fill="#3F3D56"/>
      <path
        d="M31.0928 50.1983L44.372 34.2893L57.6512 18.3804L65.1263 9.42499C65.4673 9.01643 64.794 8.5373 64.4554 8.94295L51.1762 24.8519L37.897 40.7609L30.4219 49.7162C30.0809 50.1248 30.7542 50.6039 31.0928 50.1983H31.0928Z"
        fill="#3F3D56"/>
      <path
        d="M60.1506 62.4753L63.9251 42.9939L67.6867 23.5796L69.8083 12.6297C69.909 12.11 69.0846 12.0338 68.9845 12.5502L65.21 32.0316L61.4484 51.4459L59.3269 62.3958C59.2262 62.9155 60.0505 62.9917 60.1506 62.4753Z"
        fill="#3F3D56"/>
      <path
        d="M74.728 9.48012C73.9235 10.9084 72.6492 12.0181 71.1191 12.6228C69.589 13.2274 67.8963 13.2903 66.3251 12.8008C64.7539 12.3114 63.3998 11.2994 62.4904 9.9347C61.5809 8.57005 61.1713 6.93584 61.3305 5.30646C61.4055 4.52126 61.6125 3.75416 61.943 3.03711C63.2766 3.54343 64.5983 4.11384 65.9082 4.74832L69.3533 6.42305C71.2098 7.32472 73.0052 8.34591 74.728 9.48012Z"
        fill="#3F3D56"/>
      <path
        d="M8.05355 43.033L13.0807 51.7277C13.138 51.818 13.2284 51.8826 13.3327 51.9079C13.437 51.9332 13.5471 51.9172 13.6398 51.8633C13.7325 51.8095 13.8006 51.7219 13.8296 51.6191C13.8586 51.5163 13.8463 51.4063 13.7953 51.3124L8.76816 42.6177C8.71084 42.5274 8.62049 42.4628 8.51617 42.4375C8.41186 42.4122 8.30174 42.4282 8.20905 42.482C8.11635 42.5359 8.04831 42.6235 8.01931 42.7263C7.9903 42.8291 8.00258 42.939 8.05355 43.033Z"
        fill="#F2F2F2"/>
      <path
        d="M19.2698 30.604L23.364 39.2986C23.5896 39.7777 24.3033 39.3605 24.0786 38.8833L19.9844 30.1887C19.7587 29.7095 19.0451 30.1268 19.2698 30.604Z"
        fill="#F2F2F2"/>
      <path
        d="M6.85572 83.813L10.9499 92.5076C11.1755 92.9867 11.8892 92.5694 11.6645 92.0923L7.57031 83.3977C7.34466 82.9185 6.63101 83.3358 6.85572 83.813Z"
        fill="#F2F2F2"/>
      <path
        d="M17.9832 146.158L23.0104 154.853C23.0677 154.943 23.1581 155.008 23.2624 155.033C23.3667 155.058 23.4768 155.042 23.5695 154.988C23.6622 154.934 23.7302 154.847 23.7593 154.744C23.7883 154.641 23.776 154.531 23.725 154.437L18.6978 145.743C18.6405 145.652 18.5502 145.588 18.4459 145.563C18.3416 145.537 18.2314 145.553 18.1387 145.607C18.046 145.661 17.978 145.748 17.949 145.851C17.92 145.954 17.9323 146.064 17.9832 146.158Z"
        fill="#F2F2F2"/>
      <path
        d="M29.1995 133.731L33.2936 142.426C33.5193 142.905 34.2329 142.487 34.0082 142.01L29.9141 133.316C29.6884 132.836 28.9748 133.254 29.1995 133.731Z"
        fill="#F2F2F2"/>
      <path
        d="M38.9918 98.9859C40.279 103.458 42.2062 107.724 44.7142 111.651C45.4281 112.767 46.1878 113.852 46.9934 114.906C47.3111 115.322 48.0301 114.913 47.708 114.49C44.9089 110.827 42.6567 106.78 41.0213 102.476C40.56 101.257 40.1495 100.021 39.7898 98.7672C39.7577 98.6653 39.6871 98.5798 39.5928 98.5287C39.4986 98.4777 39.388 98.465 39.2845 98.4933C39.181 98.5217 39.0927 98.5889 39.0381 98.6808C38.9834 98.7727 38.9669 98.8821 38.9918 98.9859H38.9918Z"
        fill="#F2F2F2"/>
      <path
        d="M63.4989 116.808L68.526 125.503C68.5834 125.593 68.6737 125.658 68.778 125.683C68.8823 125.709 68.9924 125.693 69.0851 125.639C69.1778 125.585 69.2459 125.497 69.2749 125.394C69.3039 125.292 69.2916 125.182 69.2406 125.088L64.2135 116.393C64.1561 116.303 64.0658 116.238 63.9615 116.213C63.8572 116.188 63.7471 116.204 63.6544 116.257C63.5617 116.311 63.4936 116.399 63.4646 116.502C63.4356 116.604 63.4479 116.714 63.4989 116.808Z"
        fill="#F2F2F2"/>
      <path
        d="M74.717 104.383L78.8112 113.078C79.0369 113.557 79.7505 113.14 79.5258 112.663L75.4317 103.968C75.206 103.489 74.4923 103.906 74.717 104.383Z"
        fill="#F2F2F2"/>
      <path
        d="M49.4742 36.9996C50.7614 41.472 52.6886 45.7372 55.1966 49.6643C55.9105 50.7804 56.6703 51.8653 57.4759 52.9192C57.7935 53.3358 58.5125 52.9263 58.1905 52.5039C55.3913 48.8403 53.1391 44.7939 51.5037 40.49C51.0424 39.2708 50.6319 38.0344 50.2722 36.7808C50.2401 36.679 50.1695 36.5935 50.0753 36.5424C49.981 36.4913 49.8705 36.4786 49.767 36.507C49.6635 36.5354 49.5751 36.6026 49.5205 36.6945C49.4659 36.7864 49.4493 36.8958 49.4742 36.9996H49.4742Z"
        fill="#F2F2F2"/>
      <path
        d="M73.9813 54.824L79.0084 63.5187C79.0658 63.609 79.1561 63.6736 79.2604 63.6989C79.3647 63.7242 79.4749 63.7082 79.5676 63.6543C79.6603 63.6005 79.7283 63.5129 79.7573 63.4101C79.7863 63.3073 79.774 63.1974 79.723 63.1034L74.6959 54.4087C74.6385 54.3184 74.5482 54.2538 74.4439 54.2285C74.3396 54.2032 74.2295 54.2192 74.1368 54.2731C74.0441 54.3269 73.976 54.4145 73.947 54.5173C73.918 54.6201 73.9303 54.73 73.9813 54.824Z"
        fill="#F2F2F2"/>
      <path
        d="M85.1995 42.3989L89.2937 51.0935C89.5193 51.5727 90.2329 51.1554 90.0082 50.6782L85.9141 41.9836C85.6884 41.5044 84.9748 41.9217 85.1995 42.3989Z"
        fill="#F2F2F2"/>
    </g>
    <defs>
      <clipPath id="clip0_1055_69589">
        <rect width="96" height="214" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);