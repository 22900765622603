import {
  Box,
  Button,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { colors } from "../../ui/theme";
import CustomTooltip from "../CustomTooltip";
import { ArrowsItemIcon } from "../Icons/ArrowsItemIcon";
import PicturesVariant, { Icons } from "../PicturesVariant/PicturesVariant";
import BottomPopup from "../Popus/BottomPopup/BottomPopup";
import { FLYER_CONVERSION_RATE } from "./flyersDistributionConfig";

function FlyersBlockItem({
  icon,
  description,
  quantity,
  tooltip,
}: {
  icon: Icons | undefined;
  description: string | undefined;
  quantity: number;
  tooltip?: string;
}) {
  return (
    <Flex
      border={`1px solid ${colors.gray.lightMode}`}
      direction="column"
      borderRadius="32px"
      padding="20px"
      alignItems="center"
      gap="1rem"
    >
      <Box
        __css={{
          svg: {
            height: "100px",
          },
        }}
      >
        <PicturesVariant icon={icon} />
      </Box>
      <Flex direction="row" gap="0.5rem">
        <Text variant="xl" fontWeight="700">
          {description}
        </Text>
        {tooltip ? (
          <CustomTooltip content={[tooltip]}>
            <Text
              mt="6px"
              display="inline-block"
              padding="0px 4px 4px"
              borderRadius="50%"
              border="1px solid"
              borderColor="#666"
              cursor="pointer"
              width="20px"
              height="20px"
              fontSize="14px"
              color="#666"
            >
              ?
            </Text>
          </CustomTooltip>
        ) : (
          <></>
        )}
      </Flex>
      <Flex
        borderRadius="20px"
        width="240px"
        height="auto"
        backgroundColor={colors.white}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Text fontSize="32px">{quantity}</Text>
      </Flex>
    </Flex>
  );
}

const values = [
  500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 6500,
  7000, 7500, 8000, 8500, 9000, 9500, 10000, 10500, 11000, 11500, 12000, 13500,
  15000, 16500, 18000, 19500, 20000,
];

export default function FlyerSelectionBlock({
  setFlyers,
  flyers,
}: {
  flyers: number;
  setFlyers: (value: number) => any;
}) {
  const [contactOpen, setContactOpen] = useState(false);

  return (
    <Box>
      <BottomPopup open={contactOpen} close={() => setContactOpen(false)} />
      <Flex alignItems="center" gap="2rem">
        <FlyersBlockItem
          icon={Icons.FLYERS_GIRL_ICON}
          description="Potentiel de personnes touchées"
          quantity={Math.round(flyers * FLYER_CONVERSION_RATE)}
        />
        <ArrowsItemIcon />
        <FlyersBlockItem
          icon={Icons.FLYERS_MEN_ICON}
          description="Flyers"
          quantity={flyers}
          tooltip={
            "Format : A5, Orientation Portrait, Impression Recto/verso, Papier couché brillant, 170g, Sans pelliculage"
          }
        />
      </Flex>
      <Slider
        value={values.indexOf(flyers)}
        onChange={(value) => setFlyers(values[value])}
        min={0}
        max={values.length - 1}
        step={1}
        mt="2rem"
      >
        <SliderTrack>
          <Box position="relative" right={10} />
          <SliderFilledTrack bg={colors.secondaryFontColor.lightMode} />
        </SliderTrack>
        <SliderThumb border={`solid 1px ${colors.gray.lightMode} !important`} />
      </Slider>
      <Flex justifyContent="right">
        <Text mr="0.25rem">
          Une plus grande quantité de flyers à distribuer ?
        </Text>
        <Button
          variant="link"
          color={colors.secondaryFontColor.lightMode}
          onClick={() => setContactOpen(true)}
        >
          On vous accompagne !
        </Button>
      </Flex>
    </Box>
  );
}
