export const OptionsCabasIcon = () => (
  <svg
    width="110"
    height="100"
    viewBox="0 0 110 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M83.9656 8.13526L66.206 3.5492C65.6372 5.78635 65.9563 8.25281 67.2772 10.5011C68.598 12.7495 70.5904 14.2176 72.8104 14.7908L69.3973 28.2138L47.1979 22.4812L31.2701 85.1214L49.9176 89.9368C49.5985 87.4703 49.9398 86.1281 50.1674 85.2332C51.0775 81.6538 53.2059 78.8657 56.5524 76.869C59.899 74.8723 63.3482 74.3326 66.9001 75.2498L83.9656 8.13526Z"
      fill="#F7B80B"
    />
    <path
      d="M61.2116 97.6214C58.5476 96.9335 56.9992 95.58 55.6783 93.3317C54.3575 91.0833 53.9246 89.0643 54.6072 86.3797C55.2898 83.6951 56.6328 82.1347 58.8639 80.8036C61.0949 79.4725 63.5424 79.1509 65.7623 79.7242C67.9823 80.2974 69.9747 81.7655 71.2956 84.0139C72.6164 86.2622 73.0493 88.2812 72.3667 90.9658C71.6841 93.6504 70.341 95.2108 68.11 96.5419C65.879 97.873 63.8755 98.3093 61.2116 97.6214Z"
      fill="#0D2734"
    />
    <rect
      width="1.5392"
      height="44.9395"
      transform="matrix(0.968238 0.250029 -0.246432 0.96916 68.4862 25.8112)"
      fill="white"
    />
    <rect
      width="21.5488"
      height="1.54966"
      transform="matrix(0.968238 0.250029 -0.246432 0.96916 44.3759 33.1892)"
      fill="white"
    />
    <rect
      width="21.5488"
      height="1.54966"
      transform="matrix(0.968238 0.250029 -0.246432 0.96916 41.8936 42.9513)"
      fill="white"
    />
    <rect
      width="21.5488"
      height="1.54966"
      transform="matrix(0.968238 0.250029 -0.246432 0.96916 39.4114 52.7134)"
      fill="white"
    />
    <rect
      width="21.5488"
      height="1.54966"
      transform="matrix(0.968238 0.250029 -0.246432 0.96916 36.9291 62.4756)"
      fill="white"
    />
  </svg>
);
