export const FoodIcon = () => (
  <svg width="118" height="101" viewBox="0 0 118 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1063_48362)">
      <path d="M57.5524 74.3181C60.3187 75.1811 62.1158 77.308 61.5663 79.0685C61.0168 80.8289 58.3291 81.556 55.5618 80.6923C54.4517 80.3624 53.4367 79.7715 52.6018 78.969L40.9268 75.1643L42.829 69.7236L54.1365 74.052C55.28 73.8666 56.4514 73.9578 57.5524 74.3181Z" fill="#FFB6B6"/>
      <path d="M32.5414 34.3978H32.5414C29.7575 34.0681 27.1943 35.9496 26.6757 38.7045C24.826 48.5307 20.8973 69.7794 21.4147 70.2967C22.0882 70.9703 47.234 78.3793 47.234 78.3793L50.1527 72.0929L33.5386 64.0103L37.1823 40.5084C37.6448 37.5248 35.5396 34.7529 32.5414 34.3978Z" fill="#E6E6E6"/>
      <path d="M62.8357 84.5394C60.1365 85.5937 57.4045 85.0556 56.7338 83.3376C56.0631 81.6197 57.7076 79.3729 60.4079 78.3186C61.4808 77.8826 62.6425 77.7099 63.7958 77.815L75.2891 73.4922L77.2027 78.9289L65.6692 82.6132C64.8921 83.4723 63.9205 84.1328 62.8357 84.5394Z" fill="#A0616A"/>
      <path d="M79.4105 37.8761H79.4105C82.1659 37.3601 84.8497 39.0651 85.5523 41.7789C88.0582 51.4586 93.4059 72.3952 92.9244 72.9462C92.2977 73.6635 67.7066 82.7455 67.7066 82.7455L64.3721 76.6695L80.4055 67.4887L75.1907 44.2847C74.5287 41.339 76.4429 38.4319 79.4105 37.8761Z" fill="#E6E6E6"/>
      <path d="M30.5694 35.8092L31.9388 27.2506L22.6955 23.4849L21.3262 36.4939L30.5694 35.8092Z" fill="#FFB6B6"/>
      <path d="M84.5539 37.1564L83.1846 28.5978L92.4278 24.832L93.7972 37.8411L84.5539 37.1564Z" fill="#A0616A"/>
      <path d="M28.2335 30.0022C33.4393 30.0022 37.6595 25.7821 37.6595 20.5763C37.6595 15.3705 33.4393 11.1504 28.2335 11.1504C23.0278 11.1504 18.8076 15.3705 18.8076 20.5763C18.8076 25.7821 23.0278 30.0022 28.2335 30.0022Z" fill="#FFB6B6"/>
      <path d="M27.4815 7.90369C27.7542 8.0629 28.1195 7.82201 28.207 7.51855C28.2945 7.21508 28.1926 6.89288 28.0916 6.59365L27.583 5.08731C27.2223 4.01899 26.8395 2.91333 26.0583 2.10019C24.8792 0.872892 23.0054 0.560672 21.3186 0.786865C19.1525 1.07734 17.0152 2.2503 16.008 4.18995C15.0008 6.12959 15.4296 8.8361 17.2495 10.0462C14.6557 13.0192 13.7515 16.3325 13.8943 20.2754C14.0371 24.2182 18.3338 27.847 21.1359 30.6246C21.7616 30.2452 22.3305 28.4676 21.9864 27.8218C21.6423 27.176 22.1353 26.4278 21.7092 25.833C21.283 25.2381 20.9265 26.1853 21.3573 25.5939C21.6292 25.2206 20.5681 24.362 20.9722 24.1386C22.9268 23.0582 23.5769 20.6218 24.8046 18.7561C26.2853 16.5057 28.8196 14.9817 31.5016 14.7289C32.979 14.5896 34.5395 14.8418 35.7505 15.6996C36.9614 16.5573 37.7454 18.09 37.4646 19.5471C38.1919 18.8087 38.5539 17.7265 38.4173 16.6991C38.2808 15.6717 37.6487 14.7217 36.7538 14.1988C37.2979 12.3994 36.8318 10.3296 35.5688 8.93713C34.3059 7.54469 29.1827 7.78185 27.3389 8.14832" fill="#2F2E41"/>
      <path d="M27.1956 14.7837C24.7535 15.0473 22.9903 17.1627 21.5014 19.1162C20.6432 20.2422 19.7443 21.4857 19.7658 22.9013C19.7876 24.3324 20.7445 25.56 21.2018 26.9163C21.9493 29.1333 21.2208 31.7709 19.4414 33.29C21.1996 33.6236 23.1003 32.3053 23.4038 30.5416C23.7572 28.4885 22.2008 26.5069 22.3851 24.4318C22.5475 22.6036 23.9882 21.1966 25.213 19.8296C26.4379 18.4627 27.5882 16.6488 27.0247 14.902" fill="#2F2E41"/>
      <path d="M31.6367 34.3743L20.9651 31.4556L9.96386 35.9459L5.24902 73.2155L32.1909 74.1136V68.102C32.1909 68.102 36.9058 62.2142 36.0077 53.6826L35.1096 45.151L31.6367 34.3743Z" fill="#E6E6E6"/>
      <path d="M36.5694 49.5293L15.4649 48.4067L9.73977 64.6841C9.73977 64.6841 2.66751 74.4506 1.54493 81.6351C0.422355 88.8196 1.0959 98.9228 1.0959 98.9228H34.8829L32.1914 68.1023C32.1914 68.1023 38.4752 54.4687 37.7468 52.1113C37.0185 49.7538 36.5694 49.5293 36.5694 49.5293Z" fill="#3F3D56"/>
      <path d="M20.4041 31.5679H15.2402V50.6517H20.4041V31.5679Z" fill="#3F3D56"/>
      <path d="M36.3445 35.8335H31.1807V51.5496H36.3445V35.8335Z" fill="#3F3D56"/>
      <path d="M83.0374 36.1707L93.709 33.252L104.71 37.7423L109.425 75.0119L82.4832 75.91V69.8984C82.4832 69.8984 79.7271 63.7525 82.3709 55.5913C84.9528 47.621 79.5645 46.9474 79.5645 46.9474L83.0374 36.1707Z" fill="#E6E6E6"/>
      <path d="M78.1045 51.3252L99.209 50.2026L104.934 66.48C104.934 66.48 108.414 77.5936 109.537 84.7781C110.659 91.9626 113.578 100.719 113.578 100.719H79.791L78.778 71.0826L78.1045 51.3252Z" fill="#3F3D56"/>
      <path d="M94.2697 52.4478H99.4336V33.3639H94.2697V52.4478Z" fill="#3F3D56"/>
      <path d="M78.3293 53.3457H83.4932V37.6296H78.3293V53.3457Z" fill="#3F3D56"/>
      <path d="M86.6416 31.312C93.1617 31.312 98.4472 26.0264 98.4472 19.5063C98.4472 12.9863 93.1617 7.70068 86.6416 7.70068C80.1215 7.70068 74.8359 12.9863 74.8359 19.5063C74.8359 26.0264 80.1215 31.312 86.6416 31.312Z" fill="#A0616A"/>
      <path d="M98.2536 25.9106C98.2536 25.9106 92.5419 19.0566 90.2572 19.6277C87.9726 20.1989 89.4005 21.0557 89.4005 21.0557L83.6888 14.7728C83.6888 14.7728 79.1195 13.0593 74.8357 18.4854C74.8357 18.4854 72.2654 13.9161 74.8357 11.3458C77.406 8.77555 78.2627 9.06113 78.8339 7.63321C79.405 6.20529 83.4032 5.06295 83.4032 5.06295C83.4032 5.06295 83.1739 3.00765 84.4309 4.0353C85.6879 5.06295 88.8271 5.06295 88.8271 5.06295C88.8271 5.06295 88.8451 3.92062 90.1223 5.06295C91.3996 6.20529 92.8275 5.63412 92.8275 5.63412C92.8275 5.63412 92.8275 7.61072 93.827 7.62197C94.8266 7.63321 96.5401 6.20529 96.8257 7.34763C97.1113 8.48996 99.1103 9.06113 99.3959 12.4881C99.6815 15.9152 98.2536 25.9106 98.2536 25.9106V25.9106Z" fill="#2F2E41"/>
      <path d="M54.4689 43.5311L63.6092 60.8104L62.3563 61.4732L53.216 44.1939C53.0833 43.9431 53.1792 43.6317 53.43 43.499L53.7741 43.317C54.0249 43.1844 54.3362 43.2803 54.4689 43.5311Z" fill="#3F3D56"/>
      <path d="M69.6969 67.3405C67.3715 66.7409 64.9387 67.8025 63.7718 69.9015C62.3106 72.5297 60.6413 74.6858 61.2003 69.5471C61.8157 63.89 58.4969 59.3208 61.1051 57.9412C63.7133 56.5615 66.4064 60.7545 70.6455 64.5509C74.5742 68.0694 72.3087 68.0139 69.6969 67.3405Z" fill="#3F3D56"/>
      <path d="M56.2368 47.0268C58.8729 45.8232 61.6307 46.2077 62.3964 47.8854C63.1621 49.5632 61.6459 51.8984 59.0087 53.1021C57.9619 53.5974 56.8116 53.8348 55.6542 53.7944L44.4207 58.7531L42.2061 53.4319L53.5155 49.1084C54.2433 48.2072 55.1765 47.4934 56.2368 47.0268Z" fill="#FFB6B6"/>
      <path d="M10.9677 33.9912L10.9677 33.9912C8.67451 35.6036 8.02144 38.7155 9.47389 41.1131C14.6545 49.6651 25.9105 68.1109 26.641 68.1508C27.5922 68.2029 51.2628 56.9378 51.2628 56.9378L49.2407 50.3083L31.4739 55.3778L18.5019 35.4443C16.8551 32.9137 13.4374 32.2546 10.9677 33.9912Z" fill="#E6E6E6"/>
      <path d="M57.3604 73.5635L87.7248 57.5015L91.4522 64.548C94.6676 70.6265 92.3431 78.1718 86.2647 81.3871L77.9269 85.7976C71.8485 89.0129 64.3031 86.6885 61.0878 80.61L57.3604 73.5635Z" fill="#F6C443"/>
      <path d="M83.0859 84.3603C80.45 85.5644 77.6922 85.1802 76.9262 83.5026C76.1603 81.825 77.6762 79.4895 80.3132 78.2854C81.36 77.79 82.5102 77.5525 83.6676 77.5927L94.9004 72.6323L97.1158 77.9532L85.807 82.2783C85.0793 83.1797 84.1462 83.8936 83.0859 84.3603Z" fill="#A0616A"/>
      <path d="M105.932 36.8675H105.932C108.716 36.5378 111.279 38.4193 111.797 41.1742C113.647 51.0004 117.576 72.2491 117.058 72.7665C116.385 73.44 91.239 80.849 91.239 80.849L88.3203 74.5626L104.934 66.48L101.291 42.9781C100.828 39.9945 102.933 37.2226 105.932 36.8675Z" fill="#E6E6E6"/>
    </g>
    <defs>
      <clipPath id="clip0_1063_48362">
        <rect width="116.209" height="100" fill="white" transform="translate(0.895508 0.71875)"/>
      </clipPath>
    </defs>
  </svg>

);