export const OptionsPortantIcon = () => (
  <svg
    width="84"
    height="101"
    viewBox="0 0 84 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.41171 12.7911L5.09876 26.0341C5.49595 27.3265 5.68476 28.6739 5.65809 30.0257C5.59079 32.2766 5.03442 34.8593 5.00918 35.9936C4.99903 36.1439 5.01185 36.2948 5.04719 36.4412C7.08882 42.8458 18.8232 37.3468 19.8903 36.8312C19.941 36.8073 19.968 36.7938 19.968 36.7938L19.9074 29.6727L19.9949 27.6276L20.5977 13.5983L20.3594 13.3983L18.272 11.6514L18.0676 11.4804L17.4063 12.3974L11.7177 11.8711L9.55737 11.2181L8.8725 11.4778L5.78499 12.6495L5.41171 12.7911Z"
      fill="#2F2E41"
    />
    <path
      d="M5.78369 12.7734C7.02162 13.1254 7.69451 14.7567 8.10347 15.977C8.62999 17.5449 7.49006 27.1072 6.34577 28.4788C5.45826 29.5427 5.66786 29.3391 5.93112 30.2438C6.19439 31.1485 6.87229 30.2882 6.2491 31.61C5.62591 32.9317 5.12797 32.6788 5.50857 33.2576C5.88917 33.8364 6.35063 33.7817 5.82655 34.6238C5.30246 35.4659 5.33895 35.7735 5.2216 36.0994C5.10426 36.4253 4.91639 38.7873 4.91639 38.7873C4.91639 38.7873 2.36405 38.31 1.01614 38.7818C1.01614 38.7818 1.99137 36.4824 1.62901 36.0574C1.26665 35.6324 0.786937 35.5333 1.19368 35.0171C1.60043 34.501 2.38778 34.5636 1.56395 34.1933C0.740122 33.8231 1.55909 28.8904 1.55909 28.8904C0.702001 25.6107 1.46803 20.7536 2.53583 15.6031C2.69378 14.8414 3.09682 14.1524 3.68333 13.6414C4.26984 13.1304 5.00755 12.8255 5.78369 12.7734Z"
      fill="#2F2E41"
    />
    <path
      d="M19.4219 13.1621L19.4893 13.1717C20.117 13.2608 20.7135 13.5016 21.2271 13.8731C21.7407 14.2447 22.1561 14.7359 22.4372 15.3041C23.1714 16.7861 25.3693 26.758 24.7416 28.1045C24.1562 29.3602 24.1866 29.0487 24.5701 29.9094C24.9536 30.77 25.5086 29.8257 25.0703 31.2198C24.6321 32.6139 24.1044 32.4308 24.56 32.9526C25.0156 33.4745 25.4653 33.3577 25.0603 34.2631C24.6552 35.1684 24.733 35.4683 24.6609 35.8071C24.5889 36.1458 24.723 38.5115 24.723 38.5115C24.723 38.5115 22.1295 38.3847 20.858 39.0349C20.858 39.0349 21.5125 36.6245 21.0958 36.2525C20.6792 35.8806 20.1905 35.8475 20.5235 35.2809C20.8565 34.7143 21.6451 34.6696 20.7787 34.4145C19.9122 34.1593 20.0549 29.1612 20.0549 29.1612L19.4219 13.1621Z"
      fill="#2F2E41"
    />
    <path
      d="M41.3591 17.9434H29.4492V24.9953H41.3591V17.9434Z"
      fill="#2F2E41"
    />
    <path
      d="M37.843 94.7679H35.9217L35.0078 87.3574H37.8433L37.843 94.7679Z"
      fill="#FFB6B6"
    />
    <path
      d="M38.3315 96.6304L32.1367 96.6301V96.5518C32.1368 95.9123 32.3908 95.299 32.843 94.8469C33.2952 94.3947 33.9084 94.1407 34.5479 94.1406H34.5481L38.3317 94.1408L38.3315 96.6304Z"
      fill="#2F2E41"
    />
    <path
      d="M30.7903 94.7679H28.869L27.9551 87.3574H30.7906L30.7903 94.7679Z"
      fill="#FFB6B6"
    />
    <path
      d="M31.2808 96.6304L25.0859 96.6301V96.5518C25.086 95.9123 25.34 95.299 25.7922 94.8469C26.2444 94.3947 26.8577 94.1407 27.4971 94.1406H27.4973L31.2809 94.1408L31.2808 96.6304Z"
      fill="#2F2E41"
    />
    <path
      d="M25.1484 48.3672C25.1484 48.3672 22.3413 54.4122 24.8773 66.58C27.4132 78.7478 27.4342 92.1061 27.4342 92.1061L31.3117 92.1693L33.49 63.41L34.6304 91.7633H38.4507L41.8175 63.8582C41.8175 63.8582 44.033 51.3977 39.0924 49.1668L25.1484 48.3672Z"
      fill="#2F2E41"
    />
    <path
      d="M38.5842 27.0326L30.0941 26.25C27.4316 28.1906 25.9373 31.6761 26.2441 35.2723C26.66 40.1482 26.5193 46.8584 23.1816 49.7736C23.1816 49.7736 32.2785 56.9533 40.3593 50.1418C40.3593 50.1418 37.5961 42.7393 39.4933 39.7831C41.3904 36.8269 42.0749 28.5214 38.5842 27.0326Z"
      fill="#E4E4E4"
    />
    <path
      d="M13.3219 14.9854C13.2959 15.2301 13.3239 15.4776 13.4038 15.7103C13.4838 15.9431 13.6138 16.1555 13.7847 16.3326C13.9556 16.5097 14.1633 16.6471 14.3931 16.7353C14.6228 16.8234 14.8692 16.8601 15.1147 16.8428L26.3246 27.3043L28.3337 24.2438L16.683 14.9228C16.6265 14.5123 16.4214 14.1368 16.1065 13.8674C15.7917 13.598 15.389 13.4535 14.9747 13.4612C14.5604 13.4689 14.1633 13.6284 13.8587 13.9093C13.5541 14.1902 13.3631 14.5731 13.3219 14.9854Z"
      fill="#FFB6B6"
    />
    <path
      d="M24.2315 25.6885L26.8909 22.9568C26.9665 22.8791 27.0581 22.8186 27.1592 22.7794C27.2604 22.7403 27.3688 22.7234 27.4771 22.7299C27.5853 22.7364 27.6909 22.7662 27.7867 22.8172C27.8824 22.8682 27.966 22.9392 28.0318 23.0254L30.3373 26.0472C30.7327 26.4366 30.9576 26.9669 30.9627 27.5218C30.9677 28.0768 30.7525 28.6111 30.3642 29.0076C29.9759 29.4041 29.4463 29.6305 28.8914 29.6371C28.3364 29.6437 27.8015 29.43 27.4039 29.0428L24.3308 26.8272C24.2428 26.7638 24.1696 26.6821 24.116 26.5878C24.0624 26.4934 24.0298 26.3887 24.0204 26.2806C24.011 26.1725 24.025 26.0637 24.0614 25.9615C24.0979 25.8594 24.1559 25.7663 24.2315 25.6885V25.6885Z"
      fill="#E4E4E4"
    />
    <path
      d="M54.9678 40.4603C54.9802 40.2145 54.9386 39.969 54.8458 39.741C54.753 39.5131 54.6114 39.3082 54.4309 39.1409C54.2504 38.9736 54.0355 38.8479 53.8011 38.7726C53.5668 38.6974 53.3188 38.6744 53.0747 38.7054L41.3008 28.8828L39.4648 32.0502L51.6154 40.7095C51.6946 41.1162 51.9203 41.4798 52.2496 41.7312C52.579 41.9827 52.9891 42.1046 53.4023 42.0739C53.8155 42.0432 54.2031 41.862 54.4917 41.5646C54.7802 41.2672 54.9496 40.8743 54.9678 40.4603Z"
      fill="#FFB6B6"
    />
    <path
      d="M43.4814 30.3819L40.978 33.2571C40.9067 33.3389 40.8187 33.4044 40.7199 33.4491C40.6211 33.4938 40.5138 33.5167 40.4053 33.5162C40.2968 33.5158 40.1897 33.4919 40.0913 33.4463C39.9929 33.4007 39.9055 33.3344 39.835 33.252L37.3651 30.363C36.9486 29.9961 36.6946 29.4791 36.6587 28.9253C36.6228 28.3715 36.808 27.826 37.1737 27.4085C37.5393 26.991 38.0556 26.7355 38.6094 26.6981C39.1631 26.6607 39.7091 26.8444 40.1276 27.209L43.3191 29.2504C43.4104 29.3089 43.4881 29.3864 43.5468 29.4776C43.6055 29.5688 43.6439 29.6716 43.6593 29.779C43.6747 29.8863 43.6668 29.9958 43.6361 30.0998C43.6054 30.2038 43.5527 30.3 43.4814 30.3819Z"
      fill="#E4E4E4"
    />
    <path
      d="M56.1649 44.6156H51.5137V42.9936C51.5137 42.3768 51.7587 41.7853 52.1948 41.3491C52.631 40.913 53.2225 40.668 53.8393 40.668C54.4561 40.668 55.0476 40.913 55.4838 41.3491C55.9199 41.7853 56.165 42.3768 56.165 42.9936L56.1649 44.6156ZM51.6166 44.5126H56.062V42.9936C56.062 42.4041 55.8278 41.8388 55.411 41.422C54.9941 41.0051 54.4288 40.771 53.8393 40.771C53.2498 40.771 52.6845 41.0051 52.2676 41.422C51.8508 41.8388 51.6166 42.4041 51.6166 42.9936V44.5126Z"
      fill="#3F3D56"
    />
    <path
      d="M58.4648 44.3917V43.7578L46.6361 44.0748L46.2617 60.1305L48.0123 62.2931L58.4648 44.3917Z"
      fill="#3F3D56"
    />
    <path
      d="M60.5593 44.168L47.7383 44.4598L48.0128 62.2925L60.3858 60.9589L60.5593 44.168Z"
      fill="#F7B80B"
    />
    <path
      d="M34.9346 24.8399C37.7042 24.8399 39.9493 22.5948 39.9493 19.8252C39.9493 17.0557 37.7042 14.8105 34.9346 14.8105C32.1651 14.8105 29.9199 17.0557 29.9199 19.8252C29.9199 22.5948 32.1651 24.8399 34.9346 24.8399Z"
      fill="#FFB6B6"
    />
    <path
      d="M32.6255 17.5898C34.3602 19.2898 37.3791 19.4528 39.2868 17.9493C39.8162 19.7755 39.8742 21.7061 39.4552 23.5607C39.0363 25.4154 38.1541 27.1335 36.891 28.5548C37.9522 28.9382 39.1738 28.6859 40.1223 28.0748C41.0708 27.4637 41.7732 26.5306 42.3033 25.5345C43.6155 23.0687 43.9441 20.0579 43.0157 17.4235C42.0874 14.7891 39.8633 12.6047 37.1611 11.8973C34.459 11.19 31.3608 12.0637 29.5555 14.1951C27.6677 16.4238 27.3618 19.6895 28.1891 22.4907C29.0163 25.2919 30.8308 27.6975 32.8244 29.8321C31.8668 27.3699 31.2318 24.794 30.9352 22.1688C30.8338 21.2697 30.7745 20.343 31.0285 19.4746C31.2825 18.6062 31.9077 17.7973 32.7789 17.5533"
      fill="#2F2E41"
    />
    <path
      d="M79.6397 96.9052H3.25322C3.20005 96.9052 3.14906 96.8887 3.11146 96.8593C3.07386 96.8299 3.05273 96.7901 3.05273 96.7485C3.05273 96.7069 3.07386 96.6671 3.11146 96.6377C3.14906 96.6083 3.20005 96.5918 3.25322 96.5918H79.6397C79.6929 96.5918 79.7439 96.6083 79.7815 96.6377C79.8191 96.6671 79.8402 96.7069 79.8402 96.7485C79.8402 96.7901 79.8191 96.8299 79.7815 96.8593C79.7439 96.8887 79.6929 96.9052 79.6397 96.9052Z"
      fill="#CACACA"
    />
    <path
      d="M53.6571 83.3226H49.0059V81.7006C49.0059 81.0838 49.2509 80.4923 49.687 80.0562C50.1232 79.62 50.7147 79.375 51.3315 79.375C51.9483 79.375 52.5398 79.62 52.976 80.0562C53.4121 80.4923 53.6571 81.0838 53.6571 81.7006L53.6571 83.3226ZM49.1088 83.2196H53.5541V81.7006C53.5541 81.1111 53.32 80.5458 52.9031 80.129C52.4863 79.7122 51.921 79.478 51.3315 79.478C50.742 79.478 50.1767 79.7122 49.7598 80.129C49.343 80.5458 49.1088 81.1111 49.1088 81.7006V83.2196Z"
      fill="#CACACA"
    />
    <path
      d="M55.957 83.0987V82.4648L44.1283 82.7819L43.7539 98.8375L45.5045 101L55.957 83.0987Z"
      fill="#CACACA"
    />
    <path
      d="M58.0515 82.875L45.2305 83.1668L45.505 101L57.878 99.6659L58.0515 82.875Z"
      fill="#E4E4E4"
    />
    <rect x="64" y="1" width="1" height="85" fill="#3D3D3D" />
    <path
      d="M59.45 7.79075C59.4153 8.16343 59.9982 11.9427 59.9982 11.9427L58.5527 27.3575L68.3297 26.8518L73.8109 8.16822C74.0065 8.02099 72.9104 5.84016 72.1665 4.95993C71.1403 3.74569 59.6611 5.52512 59.45 7.79075Z"
      fill="#3F3D56"
    />
    <path
      d="M59.9979 7.97901L59.4497 7.60156C59.4497 7.60156 57.4765 12.8858 58.1342 13.452C58.792 14.0181 60.4363 15.3392 60.4363 15.3392L59.9979 7.97901Z"
      fill="#3F3D56"
    />
    <path
      d="M72.4953 6.6582L73.8108 8.16799C73.8108 8.16799 74.907 15.5282 73.8108 15.3395C72.7145 15.1507 70.1932 16.2831 70.0836 15.9056C69.9739 15.5282 72.4953 6.6582 72.4953 6.6582Z"
      fill="#3F3D56"
    />
    <path
      d="M65.4149 3.65393C65.5873 3.4012 65.8735 3.25 66.1794 3.25H68.111C68.3942 3.25 68.6617 3.37963 68.8372 3.60183C69.3164 4.20861 68.8842 5.10064 68.111 5.10064H66.1794C65.4359 5.10064 64.996 4.26815 65.4149 3.65393Z"
      stroke="#898989"
      strokeWidth="0.5"
    />
    <path
      d="M78.9861 9.27256L79.2783 21.6401C78.9074 22.8471 78.7311 24.1055 78.756 25.3679C78.8188 27.4701 79.3384 29.882 79.362 30.9414C79.3715 31.0817 79.3595 31.2227 79.3265 31.3594C77.4198 37.3407 66.4611 32.2051 65.4645 31.7236C65.4171 31.7013 65.3919 31.6887 65.3919 31.6887L65.4485 25.0383L65.3668 23.1283L64.8039 10.0264L65.0264 9.8396L66.9758 8.2081L67.1668 8.04842L67.7843 8.90486L73.0969 8.41333L75.1144 7.80351L75.754 8.04604L78.6375 9.14025L78.9861 9.27256Z"
      fill="#F7B80B"
    />
    <path
      d="M78.6389 9.25495C77.4828 9.58371 76.8544 11.1072 76.4725 12.2469C75.9807 13.7111 77.0453 22.6413 78.114 23.9223C78.9428 24.9158 78.7471 24.7257 78.5012 25.5706C78.2554 26.4155 77.6223 25.6121 78.2043 26.8465C78.7863 28.0809 79.2513 27.8447 78.8958 28.3852C78.5404 28.9258 78.1094 28.8747 78.5989 29.6611C79.0883 30.4475 79.0543 30.7348 79.1638 31.0391C79.2734 31.3435 79.4489 33.5494 79.4489 33.5494C79.4489 33.5494 81.8325 33.1036 83.0913 33.5443C83.0913 33.5443 82.1806 31.3968 82.519 30.9999C82.8574 30.6031 83.3054 30.5105 82.9255 30.0284C82.5457 29.5464 81.8103 29.6049 82.5797 29.2591C83.3491 28.9133 82.5843 24.3067 82.5843 24.3067C83.3847 21.2438 82.6693 16.7076 81.6721 11.8976C81.5246 11.1863 81.1482 10.5428 80.6004 10.0656C80.0527 9.58837 79.3637 9.30365 78.6389 9.25495Z"
      fill="#F7B80B"
    />
    <path
      d="M65.9004 9.61914L65.8374 9.62807C65.2512 9.71131 64.6942 9.93617 64.2145 10.2832C63.7348 10.6302 63.3469 11.0889 63.0844 11.6195C62.3987 13.0036 60.3461 22.3163 60.9323 23.5738C61.479 24.7466 61.4506 24.4557 61.0925 25.2594C60.7343 26.0632 60.216 25.1813 60.6253 26.4833C61.0346 27.7852 61.5273 27.6142 61.1019 28.1015C60.6764 28.5889 60.2564 28.4799 60.6347 29.3254C61.013 30.1709 60.9403 30.4509 61.0076 30.7673C61.0749 31.0837 60.9497 33.293 60.9497 33.293C60.9497 33.293 63.3718 33.1746 64.5592 33.7818C64.5592 33.7818 63.948 31.5307 64.3371 31.1833C64.7262 30.836 65.1826 30.805 64.8716 30.2759C64.5606 29.7468 63.8241 29.7051 64.6333 29.4668C65.4424 29.2285 65.3092 24.5607 65.3092 24.5607L65.9004 9.61914Z"
      fill="#F7B80B"
    />
    <path
      d="M69.7645 7.65393C69.9369 7.4012 70.2231 7.25 70.529 7.25H72.4606C72.7438 7.25 73.0113 7.37963 73.1868 7.60183C73.666 8.20861 73.2338 9.10064 72.4606 9.10064H70.529C69.7855 9.10064 69.3456 8.26815 69.7645 7.65393Z"
      stroke="#898989"
      strokeWidth="0.5"
    />
    <path
      d="M64.8355 0.482964L76.7789 11.3076C76.6073 11.6371 76.2573 11.8858 75.8214 11.9881L75.7353 12.0083L63.7919 1.18368C63.9524 0.838567 64.3188 0.581749 64.7753 0.494468L64.8355 0.482964Z"
      fill="#3D3D3D"
    />
    <rect x="75" y="11" width="1" height="85" fill="#3D3D3D" />
  </svg>
);
