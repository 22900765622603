export const RoleDemonstrationProduitIcon = () => (
  <svg
    width="180"
    height="120"
    viewBox="0 0 180 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M131.387 4.88176C130.152 5.57553 128.16 2.8314 127.708 4.17367C127.257 5.51594 128.351 10.9319 128.915 12.2309C129.195 12.8765 129.592 13.467 129.867 14.1149C130.142 14.7628 130.29 15.5078 130.046 16.1683C129.623 17.3179 128.226 17.7855 127.533 18.7959C126.727 19.9727 127.052 21.5577 127.43 22.9337C127.755 24.1198 128.101 25.3165 128.727 26.3752C129.353 27.4339 130.296 28.3557 131.475 28.706C132.976 29.1519 134.58 28.6219 136.027 28.0236C137.418 27.4483 138.794 26.7961 139.977 25.8654C141.161 24.9347 142.148 23.6993 142.561 22.2519C143.08 20.4363 142.647 18.4493 141.748 16.7883C140.85 15.1274 139.521 13.7436 138.158 12.4372C135.862 10.237 133.418 8.19059 130.849 6.31633L131.387 4.88176Z"
      fill="#1A202C"
    />
    <path
      d="M149.748 98.0437L148.582 108.922L148.529 109.42L135.342 109.368L135.273 108.869L133.876 98.9009L149.748 98.0437Z"
      fill="#FFB6B6"
    />
    <path
      d="M129.585 108.848C129.585 109.012 129.583 109.178 129.582 109.345L115.893 109.292L115.848 108.793L115.004 99.3302L129.159 97.6141C129.159 97.6141 129.615 102.633 129.585 108.848Z"
      fill="#FFB6B6"
    />
    <path
      d="M128.062 24.1529C130.236 26.8921 134.07 27.6858 137.164 26.0554C141.58 23.7281 147.61 21.0608 148.676 23.1921C150.392 26.6237 150.392 29.954 150.392 29.954L152.108 69.9475C152.108 69.9475 152.965 86.0955 142.242 84.6702C131.518 83.2449 114.789 89.2501 114.789 89.2501L116.498 71.5327L121.061 64.8526L122.909 45.6878L119.722 30.2698L123.582 28.554C123.582 28.554 124.011 25.5513 125.298 25.5513C126.585 25.5513 126.896 22.6669 128.027 24.1091C128.039 24.1236 128.05 24.1382 128.062 24.1529V24.1529Z"
      fill="#F7B80B"
    />
    <path
      d="M151.326 109.431L148.529 109.42L135.342 109.368L133.626 109.362L133.527 108.863L130.874 95.4703L130.459 108.851L130.444 109.348L129.582 109.345L115.893 109.292L114.911 109.287L114.908 108.79L114.85 99.3319L114.797 90.5739L114.789 89.2491L114.79 89.2475L118.889 80.9388L120.084 78.5147L120.33 78.0173L122.724 73.1641L144.389 70.1646L144.72 70.1182L150.606 69.3041L150.65 71.7166L150.674 73.0862L150.762 78.0173L150.772 78.5147L150.782 79.0834L151.316 108.932L151.326 109.431Z"
      fill="#1A202C"
    />
    <path
      d="M134.471 22.8807C139.418 22.8807 143.429 18.8699 143.429 13.9224C143.429 8.97496 139.418 4.96423 134.471 4.96423C129.523 4.96423 125.513 8.97496 125.513 13.9224C125.513 18.8699 129.523 22.8807 134.471 22.8807Z"
      fill="#FFB6B6"
    />
    <path
      d="M144.569 27.9521C142.478 30.9347 141.409 34.6198 141.579 38.2583C139.208 37.11 137.608 34.6837 137.063 32.1065C136.519 29.5293 136.934 26.8232 137.809 24.3387C137.818 24.3138 137.827 24.289 137.836 24.2642C139.164 20.5349 137.93 16.367 134.721 14.0487C132.556 12.4851 130.679 10.5233 129.238 8.27462C128.424 7.00513 127.733 5.41263 128.341 4.03277C128.901 2.76128 130.347 2.16701 131.678 1.76937C134.718 0.861395 137.948 0.344745 141.068 0.919285C144.188 1.49383 147.191 3.28805 148.666 6.09699C150.141 8.90593 149.754 12.7394 147.361 14.8218C148.116 15.0658 148.65 15.866 148.586 16.6567C148.522 17.4474 147.866 18.1511 147.081 18.2703C147.593 18.2522 147.895 18.9262 147.713 19.4046C147.53 19.883 147.059 20.1802 146.611 20.4264C145.971 20.7772 145.262 21.144 145.259 22.0625C145.258 22.4309 145.39 22.7883 145.604 23.0877C146.124 23.8131 146.964 24.1398 147.772 24.4465C146.063 24.9499 144.7 26.4778 144.394 28.2333L144.569 27.9521Z"
      fill="#1A202C"
    />
    <path
      d="M106.37 24.4866C106.126 21.9218 107.251 19.7167 108.883 19.5614C110.516 19.4061 112.036 21.3592 112.281 23.925C112.392 24.9485 112.257 25.9839 111.887 26.9446L112.785 37.8248L107.67 38.1377L107.328 27.3785C106.782 26.5048 106.454 25.5132 106.37 24.4866Z"
      fill="#FFB6B6"
    />
    <path
      d="M134.236 29.648C134.236 29.648 125.88 24.7326 124.16 25.4699C122.439 26.2072 114.083 38.7415 114.083 38.7415L113.315 36.4541L107.447 37.0211C107.447 37.0211 104.007 52.9961 111.625 51.5215C119.244 50.0469 131.779 36.2838 131.779 36.2838L134.236 29.648H134.236Z"
      fill="#F7B80B"
    />
    <path
      d="M56.71 56.0589C56.71 56.0589 61.4281 53.2928 62.6987 55.8576C63.9692 58.4225 75.7075 89.0199 75.7075 89.0199L68.1434 90.4927L56.71 56.0589V56.0589Z"
      fill="#F7B80B"
    />
    <path
      d="M46.4192 37.7807L46.6416 38.0973C45.8124 38.576 45.6902 39.7836 46.079 40.6599C46.4678 41.5361 47.2204 42.1856 47.8713 42.8896C48.5223 43.5936 49.1158 44.4654 49.0554 45.4227C48.9877 46.4993 48.1099 47.3859 47.9877 48.458C47.8109 50.0191 49.2645 51.3888 49.1924 52.9587C49.1379 54.1575 48.2159 55.1207 47.3014 55.8983C46.5856 56.508 45.8066 57.1206 45.3235 57.9071C45.1909 58.1236 45.0805 58.3548 44.9995 58.6022C44.6387 59.7186 44.9789 60.935 45.4369 62.0146C45.8934 63.0941 46.4752 64.1485 46.618 65.312C46.7594 66.4755 46.3205 67.8186 45.2498 68.2958C47.3824 68.1132 49.4634 67.3694 51.2292 66.1603C51.3882 66.0513 51.5429 65.9394 51.696 65.8245C54.1997 63.9394 55.9581 61.2163 57.6503 58.5625C57.7902 58.3445 57.9287 58.1251 58.0686 57.9071C58.4441 57.3165 58.8211 56.7274 59.1982 56.1369C60.2733 54.4491 61.3616 52.7363 61.9478 50.8247C62.4868 49.0618 62.5781 47.1988 62.799 45.3682C62.9065 44.4713 63.0479 43.5774 63.0744 42.6746C63.1171 41.2858 62.88 39.8911 62.3969 38.5877C61.9242 37.3064 61.2143 36.1121 60.2998 35.0959C59.3631 34.0546 58.2173 33.2078 56.9566 32.5981C55.7357 32.0075 54.4073 31.6408 53.0539 31.5392C52.6003 31.5053 52.1482 31.4994 51.696 31.5201C49.9125 31.5937 48.1497 32.0443 46.4354 32.5981C45.1747 33.2078 44.029 34.0546 43.0923 35.0959C42.1777 36.1121 41.4679 37.3064 40.9951 38.5877C42.6387 37.71 44.5915 37.4081 46.4192 37.7807Z"
      fill="#1A202C"
    />
    <path
      d="M59.2006 53.8923L50.7444 50.5762L50.5303 53.8923L43.1172 55.053L39.6353 70.8048L42.7856 80.5875L66.3304 81.2507L65.5013 77.9345C65.5013 77.9345 69.3149 73.6235 66.9936 66.6596L66.7358 64.8872C66.0618 60.2533 63.2791 56.193 59.2006 53.8923L59.2006 53.8923Z"
      fill="#F7B80B"
    />
    <path
      d="M54.4944 50.4271C58.6426 50.4271 62.0053 47.0643 62.0053 42.9161C62.0053 38.7679 58.6426 35.4052 54.4944 35.4052C50.3462 35.4052 46.9834 38.7679 46.9834 42.9161C46.9834 47.0643 50.3462 50.4271 54.4944 50.4271Z"
      fill="#A0616A"
    />
    <path
      d="M40.3174 42.6746C40.3439 43.5774 40.4853 44.4713 40.5928 45.3682C40.8137 47.1988 40.905 49.0618 41.4441 50.8247C42.0302 52.7363 43.1186 54.4491 44.1937 56.1369C44.5707 56.7274 44.9477 57.3165 45.3232 57.9071C45.4632 58.1251 45.6016 58.3445 45.7415 58.5625C47.4337 61.2163 49.1921 63.9394 51.6958 65.8245C51.8489 65.9394 52.0036 66.0513 52.1626 66.1603C53.9284 67.3694 56.0094 68.1132 58.142 68.2958C57.0713 67.8186 56.6324 66.4755 56.7738 65.312C56.9166 64.1486 57.4984 63.0941 57.9549 62.0146C58.4129 60.935 58.7531 59.7186 58.3923 58.6022C58.3113 58.3548 58.2009 58.1236 58.0683 57.9071C57.5853 57.1206 56.8062 56.508 56.0904 55.8983C55.1759 55.1207 54.2539 54.1575 54.1994 52.9587C54.1273 51.3888 53.1243 51.9145 52.9476 50.3534C52.8254 49.2813 51.3953 47.4536 51.3276 46.377C51.2672 45.4198 51.8548 43.1046 52.5058 42.4006C53.1567 41.6967 52.5588 39.5951 52.9476 38.7188C53.3364 37.8425 57.5794 38.5759 56.7502 38.0973L56.9726 37.7807C58.8003 37.4081 60.7531 37.71 62.3967 38.5877C62.7546 38.7777 63.0962 38.9942 63.4202 39.2387C64.2023 37.8101 63.2744 35.9972 62.0123 34.9677C60.7767 33.9574 59.2539 33.3742 57.7428 32.8602C57.4822 32.7719 57.22 32.6835 56.9564 32.5981C55.2421 32.0443 53.4793 31.5937 51.6958 31.5201C51.2436 31.4994 50.7915 31.5053 50.3379 31.5392C48.9845 31.6408 47.6561 32.0075 46.4352 32.5981C45.1745 33.2078 44.0287 34.0546 43.092 35.0959C42.1775 36.1121 41.4676 37.3064 40.9949 38.5877C40.5118 39.8911 40.2747 41.2858 40.3174 42.6746H40.3174Z"
      fill="#1A202C"
    />
    <path
      d="M171.427 102.718L160.958 80.72C160.177 79.0785 158.497 78.0173 156.678 78.0173H24.4431C23.9407 78.0173 23.4516 78.0953 22.9906 78.2445C21.825 78.6176 20.8335 79.4333 20.2465 80.5542L9.02459 101.947C8.24862 103.424 8.30003 105.162 9.16222 106.591C10.0228 108.022 11.5333 108.881 13.203 108.887L114.911 109.287L115.893 109.292L129.582 109.345L130.444 109.348L133.626 109.362L135.342 109.368L148.529 109.42L151.326 109.431L167.129 109.493H167.149C168.781 109.493 170.275 108.67 171.15 107.292C172.029 105.908 172.132 104.197 171.427 102.718Z"
      fill="white"
    />
    <path
      d="M171.427 102.718L160.958 80.72C160.177 79.0785 158.497 78.0173 156.678 78.0173H24.4431C23.9407 78.0173 23.4516 78.0953 22.9906 78.2445C21.825 78.6176 20.8335 79.4333 20.2465 80.5542L9.02459 101.947C8.24862 103.424 8.30003 105.162 9.16222 106.591C10.0228 108.022 11.5333 108.881 13.203 108.887L114.911 109.287L115.893 109.292L129.582 109.345L130.444 109.348L133.626 109.362L135.342 109.368L148.529 109.42L151.326 109.431L167.129 109.493H167.149C168.781 109.493 170.275 108.67 171.15 107.292C172.029 105.908 172.132 104.197 171.427 102.718ZM170.731 107.025C169.946 108.261 168.61 108.995 167.149 108.995H167.131L151.316 108.932L148.582 108.922L135.273 108.869L133.527 108.863L130.459 108.851L129.585 108.848L115.848 108.793L114.908 108.79L13.2046 108.39C11.7107 108.383 10.3577 107.616 9.58836 106.336C8.81733 105.056 8.77094 103.5 9.46567 102.177L20.6876 80.7863C21.0921 80.0153 21.7089 79.4051 22.4468 79.0122C23.0487 78.6905 23.7318 78.5148 24.4431 78.5148H156.678C158.307 78.5148 159.809 79.4648 160.509 80.9339L170.978 102.93C171.609 104.256 171.517 105.787 170.731 107.025Z"
      fill="#2D3748"
    />
    <path
      d="M55.4703 93.9348L80.5073 84.8153L67.9888 79.7804L43.0347 86.3076L55.4703 93.9348Z"
      fill="#EDF2F7"
    />
    <path
      d="M93.6058 80.1729L90.7871 89.1265L123.783 91.1162L120.964 81.0019L93.6058 80.1729Z"
      fill="#EDF2F7"
    />
    <path
      d="M54.7118 81.7043C57.0317 81.9344 58.7935 83.3179 58.6469 84.7943C58.5002 86.2706 56.5009 87.2806 54.1802 87.0501C53.2519 86.9708 52.3561 86.6703 51.5677 86.1738L41.7505 85.0749L42.3644 80.4789L51.9773 82.0502C52.8481 81.7182 53.7858 81.5996 54.7118 81.7043H54.7118Z"
      fill="#A0616A"
    />
    <path
      d="M44.8584 55.9648L43.1174 55.0529C43.1174 55.0529 40.3816 55.1358 38.5577 56.9597C36.7338 58.7835 21.9769 79.6754 21.9769 79.6754C21.9769 79.6754 18.3291 85.3129 27.4486 86.1419C36.568 86.9709 46.6823 86.9709 46.6823 86.9709L47.5114 80.1728L33.7493 78.6805L43.3864 65.9133L44.8584 55.9648Z"
      fill="#F7B80B"
    />
    <path
      d="M96.5957 53.7563C94.9227 54.252 93.1552 54.5322 91.3297 54.5588C91.2219 54.5621 91.1141 54.5638 91.0063 54.5638C89.3549 54.5638 87.7515 54.3598 86.2178 53.9751L86.2576 53.5821L87.1496 44.9784L87.206 44.4279L86.3786 41.9457H96.5294L96.3669 44.3715L96.3072 45.2553L96.3951 45.5355L98.6335 52.6686L98.733 52.9869C98.0399 53.2837 97.3269 53.5407 96.5957 53.7563H96.5957Z"
      fill="#F7B80B"
    />
    <path
      d="M86.2177 53.9749C83.8085 53.3731 81.5734 52.3252 79.6053 50.9274C78.7945 50.488 78.0069 50.0121 77.2524 49.5081L77.6255 49.3207L86.9987 44.6333L87.1495 44.9782L91.1853 54.2303L91.3296 54.5586C91.2218 54.5619 91.114 54.5636 91.0062 54.5636C89.3548 54.5636 87.7514 54.3596 86.2177 53.9749Z"
      fill="#1A202C"
    />
    <path
      d="M106.639 46.8586C106.597 46.9149 106.554 46.9697 106.511 47.0244C105.811 47.9131 105.035 48.7388 104.196 49.4933L104.19 49.0506L104.167 47.4207C104.167 47.4207 104.836 46.9315 105.664 46.8039C105.861 46.7724 106.068 46.7624 106.277 46.7856C106.398 46.7972 106.518 46.8205 106.639 46.8586Z"
      fill="#1A202C"
    />
    <path
      d="M104.196 49.4932C102.591 50.9407 100.747 52.1279 98.7328 52.9868C98.0397 53.2836 97.3267 53.5406 96.5955 53.7561L96.5872 53.4129L96.3949 45.5354L96.3667 44.3714L105.664 46.8038L106.158 46.9331L106.511 47.0243C105.811 47.913 105.035 48.7388 104.196 49.4932Z"
      fill="#1A202C"
    />
    <path
      d="M91.5495 39.2559C95.662 39.2559 98.9959 35.922 98.9959 31.8094C98.9959 27.6969 95.662 24.363 91.5495 24.363C87.4369 24.363 84.103 27.6969 84.103 31.8094C84.103 35.922 87.4369 39.2559 91.5495 39.2559Z"
      fill="#A0616A"
    />
    <path
      d="M96.5878 53.4129C94.8734 53.9303 93.0611 54.2138 91.1858 54.2304L91.3301 54.5587C93.1556 54.5322 94.9231 54.2519 96.5961 53.7562L96.5878 53.4129ZM77.626 49.3208C73.8041 45.7891 71.3783 40.7684 71.2805 35.1906C67.3791 39.7073 71.2556 45.5039 77.253 49.5082C78.0073 50.0122 78.7949 50.4881 79.6058 50.9275C78.911 50.435 78.2494 49.8978 77.626 49.3208ZM106.447 22.561C102.829 18.0179 97.2527 15.1013 91.0067 15.1013C80.128 15.1013 71.2756 23.9522 71.2756 34.8325C71.2756 34.9519 71.2772 35.0713 71.2805 35.1906C71.3833 35.0696 71.4927 34.9502 71.6072 34.8325C77.0788 29.195 80.3104 15.4329 91.0067 15.4329C97.2594 15.4329 102.83 18.4059 106.38 23.0137C108.904 26.2867 110.406 30.3872 110.406 34.8325C110.406 35.4775 110.375 36.1158 110.312 36.7443C110.272 37.1621 110.217 37.5766 110.149 37.9862C109.655 40.9956 108.466 43.7762 106.754 46.1522C106.599 46.3678 106.44 46.5783 106.278 46.7856C106.238 46.8353 106.2 46.8851 106.158 46.9332C105.555 47.6876 104.896 48.3956 104.19 49.0505C102.57 50.5561 100.693 51.7864 98.6339 52.6685C97.9707 52.9553 97.2875 53.204 96.5878 53.4129C94.8734 53.9303 93.0611 54.2138 91.1858 54.2304C91.1261 54.232 91.0664 54.232 91.0067 54.232C89.5045 54.232 87.8945 54.0016 86.258 53.5821C84.0229 53.01 81.7364 52.0881 79.6058 50.9275C81.5739 52.3252 83.809 53.3732 86.2182 53.975C87.7519 54.3597 89.3552 54.5637 91.0067 54.5637C91.1145 54.5637 91.2223 54.562 91.3301 54.5587C93.1556 54.5322 94.9231 54.2519 96.5961 53.7562C97.3273 53.5406 98.0403 53.2836 98.7334 52.9868C100.748 52.1279 102.592 50.9408 104.197 49.4932C105.036 48.7388 105.812 47.9131 106.511 47.0244C106.555 46.9696 106.598 46.9149 106.639 46.8585C106.695 46.7856 106.752 46.711 106.807 46.6364C108.682 44.1376 109.975 41.1796 110.489 37.9646C110.556 37.5517 110.609 37.1339 110.648 36.7111C110.708 36.0926 110.738 35.4659 110.738 34.8325C110.738 30.1981 109.133 25.9319 106.447 22.561V22.561Z"
      fill="#E2E8F0"
    />
    <path
      d="M84.88 35.7708C83.54 34.5067 82.6057 32.8312 83.5406 29.2593C83.6102 28.9934 83.4237 28.708 83.2435 28.5004C83.0634 28.2928 82.7236 28.1963 82.5098 28.369C82.662 27.9206 84.7486 26.3189 84.9009 25.8705C84.723 26.1064 83.3663 26.6122 83.2576 26.3375C83.1488 26.0628 83.2442 25.7542 83.3397 25.4746L83.9092 23.8081C84.0525 23.3889 84.6176 23.8897 84.9009 23.5492C85.4085 22.9392 84.8292 22.2961 85.6042 22.125C88.1404 21.5652 90.8292 21.0651 93.2931 21.8868C94.3694 22.2457 95.3481 22.8419 96.3164 23.4332L98.0881 24.5152C99.1045 25.1359 100.208 25.8722 100.487 27.0301L98.6265 26.3652C99.0409 27.2476 99.4589 28.1408 99.6485 29.0971C99.838 30.0533 99.3254 33.6537 98.8288 34.4926C98.3925 35.2295 98.0836 33.2503 97.9402 34.0946C98.1044 32.4361 97.3579 30.7157 96.0343 29.7028C95.7629 29.4951 95.4534 29.2953 95.3326 28.9755C95.198 28.6189 95.333 28.19 95.1492 27.856C94.9206 27.4408 94.3236 27.4012 93.8785 27.5642C93.4334 27.7272 93.0444 28.0335 92.5833 28.1433C91.3608 28.4343 90.2488 27.2785 88.9952 27.1916C87.8011 27.1088 86.7328 28.0436 86.2363 29.1328C85.7397 30.222 84.3661 29.9862 84.2377 31.1764C84.1092 32.3665 85.9845 34.7218 85.2325 35.6532L84.88 35.7708Z"
      fill="#1A202C"
    />
    <path
      d="M83.9397 46.8735L83.3843 46.4407L83.255 46.3412C83.1306 46.2434 82.9665 46.2103 82.8122 46.2484L82.1623 46.4109C82.0943 46.4275 82.0213 46.4076 81.9716 46.3578L81.242 45.6283C81.1923 45.5785 81.1724 45.5056 81.189 45.4376L81.3514 44.7876C81.3913 44.6334 81.3565 44.4709 81.2586 44.3449L80.7264 43.6601C80.7131 43.6435 80.6982 43.627 80.6833 43.612C80.481 43.4098 80.1544 43.4098 79.952 43.612L79.6453 43.9188C79.4248 44.1377 79.3286 44.4676 79.4364 44.7727C79.7116 45.5553 80.1593 46.2666 80.7479 46.8519C80.9568 47.0609 81.1807 47.2532 81.4178 47.4256C81.8489 47.7374 82.3231 47.9861 82.8272 48.1635C83.1339 48.2712 83.4622 48.1751 83.6811 47.9562L83.9878 47.6478C84.2084 47.4289 84.1851 47.0658 83.9397 46.8735ZM83.764 47.4256L83.4572 47.7324C83.313 47.875 83.1107 47.928 82.9316 47.865C82.5088 47.7175 82.1092 47.5152 81.7395 47.2631C81.4642 47.0791 81.2072 46.8652 80.9718 46.6281C80.418 46.076 79.9951 45.4061 79.7348 44.6682C79.6718 44.4892 79.7249 44.2869 79.8675 44.1426L80.1759 43.8359C80.1809 43.8293 80.1875 43.8243 80.1941 43.8193C80.282 43.7513 80.408 43.7662 80.476 43.8541L81.0083 44.5389C81.0464 44.587 81.0597 44.6517 81.0447 44.7114L80.8822 45.3613C80.8375 45.5371 80.8905 45.7244 81.0182 45.8521L81.7478 46.5817C81.8754 46.711 82.0628 46.7624 82.2385 46.7176L82.8885 46.5551C82.9482 46.5402 83.0128 46.5535 83.0609 46.5916L83.0692 46.5983L83.7457 47.1239C83.7524 47.1288 83.759 47.1355 83.764 47.1404C83.8436 47.22 83.8436 47.346 83.764 47.4256Z"
      fill="#CCCCCC"
    />
    <path
      d="M90.4125 45.375C90.4945 45.375 90.5609 45.4414 90.5609 45.5234V45.8201C90.5609 46.4756 91.0923 47.007 91.7478 47.007C92.4033 47.007 92.9346 46.4756 92.9346 45.8201V45.5234C92.9346 45.4414 93.0011 45.375 93.083 45.375C93.1649 45.375 93.2314 45.4414 93.2314 45.5234V45.8201C93.2314 46.582 92.6542 47.2201 91.8961 47.2963V47.8971H92.7863C92.8682 47.8971 92.9346 47.9636 92.9346 48.0455C92.9346 48.1274 92.8682 48.1939 92.7863 48.1939H90.7092C90.6273 48.1939 90.5609 48.1274 90.5609 48.0455C90.5609 47.9636 90.6273 47.8971 90.7092 47.8971H91.5994V47.2963C90.8413 47.2201 90.2642 46.582 90.2642 45.8201V45.5234C90.2642 45.4414 90.3306 45.375 90.4125 45.375Z"
      fill="#CCCCCC"
    />
    <path
      d="M91.7478 43.4464C91.257 43.4464 90.8574 43.8443 90.8574 44.3367V45.8207C90.8574 46.3115 91.257 46.7095 91.7478 46.7095C92.2403 46.7095 92.6382 46.3115 92.6382 45.8207V44.3367C92.6382 43.8443 92.2403 43.4464 91.7478 43.4464ZM92.3414 45.8207C92.3414 46.1474 92.0761 46.4143 91.7478 46.4143C91.4195 46.4143 91.1542 46.1474 91.1542 45.8207V44.3367C91.1542 44.0084 91.4195 43.7431 91.7478 43.7431C92.0761 43.7431 92.3414 44.0084 92.3414 44.3367V45.8207Z"
      fill="#CCCCCC"
    />
    <path
      d="M102.016 43.5043C101.55 43.3468 101.045 43.5955 100.887 44.0614L101.138 44.3118C101.154 43.9968 101.416 43.7497 101.733 43.7514C102.06 43.753 102.325 44.02 102.323 44.3466V45.4973L102.62 45.7941V44.3466C102.62 43.9669 102.376 43.627 102.016 43.5043ZM102.769 47.9082H101.879V47.3063C102.139 47.2814 102.388 47.1869 102.6 47.0327L102.386 46.8188C102.199 46.9448 101.973 47.0178 101.73 47.0178C101.075 47.0178 100.542 46.4855 100.542 45.8306V45.5338C100.542 45.5073 100.536 45.4808 100.521 45.4592C100.496 45.4161 100.45 45.3863 100.395 45.3863C100.368 45.3863 100.343 45.3929 100.322 45.4062C100.277 45.431 100.246 45.4791 100.246 45.5338V45.8306C100.246 46.5917 100.824 47.23 101.582 47.3063V47.9082H100.692C100.609 47.9082 100.542 47.9745 100.542 48.0558C100.542 48.1387 100.609 48.205 100.692 48.205H102.769C102.85 48.205 102.917 48.1387 102.917 48.0558C102.917 47.9745 102.85 47.9082 102.769 47.9082ZM103.066 45.3862C102.983 45.3862 102.917 45.4526 102.917 45.5338V45.8306C102.917 45.9102 102.908 45.9898 102.894 46.0677L102.908 46.0826L103.134 46.3098C103.151 46.2584 103.165 46.207 103.177 46.1539C103.202 46.0495 103.214 45.9417 103.214 45.8306V45.5338C103.214 45.4526 103.147 45.3863 103.066 45.3863V45.3862Z"
      fill="#CCCCCC"
    />
    <path
      d="M102.341 45.9351L100.055 43.6486L99.8442 43.8592L101.771 45.7859L103.406 47.4191L103.615 47.2085L102.341 45.9351ZM101.948 46.3828C101.645 46.5038 101.3 46.3546 101.179 46.0495C101.151 45.9799 101.136 45.9053 101.136 45.8307V45.5703L100.839 45.2735V45.8307C100.839 46.3214 101.239 46.7211 101.729 46.7211C101.884 46.7211 102.036 46.6813 102.17 46.605L101.948 46.3828Z"
      fill="#CCCCCC"
    />
    <path
      d="M141.944 78.3454C139.976 79.807 137.626 79.9762 136.696 78.7234C135.766 77.4706 136.608 75.2706 138.577 73.8087C139.356 73.214 140.265 72.8143 141.23 72.6432L149.648 66.5556L152.421 70.5668L143.828 76.1423C143.385 77.0166 142.739 77.772 141.944 78.3454Z"
      fill="#FFB6B6"
    />
    <path
      d="M147.247 28.7683C147.247 28.7683 154.038 28.9828 155.324 33.7012C156.611 38.4196 159.185 48.4768 158.756 62.4407C158.756 62.4407 160.901 71.4486 155.324 71.0197L147.603 74.4513L142.456 67.5881L146.746 62.4407L147.247 28.7683Z"
      fill="#F7B80B"
    />
  </svg>
);
