import { Flex, Image, Text } from "@chakra-ui/react";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import support from "../../assets/images/page/support.png";
import { useAdviceContext } from "../../context/AdviceContext";
import { useSegment } from "../../context/SegmentContext";
import { useSessionContext } from "../../context/SessionContext";
import { useResetOrder } from "../../hooks/useResetOrder";
import { PageURL } from "../../types/pages";
import { colors } from "../../ui/theme";
import NorelaButton from "../Buttons/NorelaButton";
import AdviceIcon from "../Icons/AdviceIcon";
import { LeftArrowIcon } from "../Icons/LeftArrowIcon";
import BottomPopup from "../Popus/BottomPopup/BottomPopup";

export default function BottomBlock({
  onNextPage,
  nextPageLink,
  showQuitPage = false,
  hideNeedHelp = false,
  nextPageText = "ETAPE SUIVANTE",
  disableNextPage = false,
}: {
  showQuitPage?: boolean;
  hideNeedHelp?: boolean;
  nextPageText?: string;
  disableNextPage?: boolean;
} & (
  | {
      onNextPage?: () => any;
      nextPageLink?: never;
    }
  | {
      onNextPage?: never;
      nextPageLink?: string;
    }
)) {
  const { advice } = useAdviceContext();
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const clickHandler = () => setOpenPopup(!openPopup);
  const { completeResetOrder } = useResetOrder();
  const { sessionInfo, updateHistory } = useSessionContext();
  function resetAndGoHomePage() {
    completeResetOrder();
    navigate(PageURL.HOME);
    updateHistory(PageURL.HOME);
  }
  const { track } = useSegment();
  const handlePreviousPageClick = () => {
    track("Previous page", {
      page: sessionInfo.history[sessionInfo.history.length - 1],
    });
    if (sessionInfo.history.length >= 2) {
      const previousPage = sessionInfo.history[sessionInfo.history.length - 2];
      navigate(previousPage);
      updateHistory(previousPage);
    }
  };

  return (
    <div className="pageFooter-wrapper">
      <Flex fontSize="18px" alignItems="center" gap="0.25rem" h="50px">
        {advice && (
          <>
            <AdviceIcon ml="auto" />
            <Text fontSize="18px" mr="auto">
              {advice}
            </Text>
          </>
        )}
      </Flex>
      <Flex
        className="pageFooter"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        pb="1rem"
      >
        <BottomPopup close={clickHandler} open={openPopup} />
        {showQuitPage === true ? (
          <Flex
            gap="8px"
            cursor="pointer"
            alignItems="center"
            width="280px"
            onClick={resetAndGoHomePage}
          >
            <FaTimes />
            <Text
              variant="sm"
              fontWeight={600}
              color={colors.additional.lightMode}
            >
              Quitter cette page
            </Text>
          </Flex>
        ) : (
          <Flex
            gap="8px"
            cursor="pointer"
            alignItems="center"
            width="280px"
            onClick={handlePreviousPageClick}
          >
            <LeftArrowIcon />
            <Text
              variant="sm"
              fontWeight={600}
              color={colors.additional.lightMode}
            >
              Retour à l’étape précédente
            </Text>
          </Flex>
        )}

        {showQuitPage !== true && (nextPageLink || onNextPage) && (
          <NorelaButton
            onClick={() => {
              if (onNextPage) {
                onNextPage();
              } else {
                updateHistory(nextPageLink! as PageURL);
                navigate(nextPageLink! as PageURL);
              }
            }}
            borderRadius="6px"
            p="10px 24px"
            isDisabled={disableNextPage}
          >
            {nextPageText}
          </NorelaButton>
        )}
        {hideNeedHelp !== true ? (
          <Flex
            onClick={() => clickHandler()}
            cursor="pointer"
            width="100%"
            maxWidth="280px"
            gap="30px"
            alignItems="center"
            h="80px"
          >
            <Image src={support} h="100%" />
            <Text
              variant="md"
              color={colors.additional.lightMode}
              textAlign="left"
            >
              Besoin d’aide ? Contactez un conseiller !
            </Text>
          </Flex>
        ) : (
          <Flex
            width="100%"
            maxWidth="280px"
            gap="30px"
            alignItems="center"
            h="80px"
          ></Flex>
        )}
      </Flex>
      <footer className="Yellow-footer" />
    </div>
  );
}
