import { Flex, Text } from "@chakra-ui/react";
import { FC } from "react";
import {
  setAdviceOnHover,
  useAdviceContext,
} from "../../context/AdviceContext";
import { DressingSubMenuProps } from "../../types/dressingTypes";
import { colors } from "../../ui/theme";
import PicturesVariant from "../PicturesVariant/PicturesVariant";

const DressingSubMenu: FC<DressingSubMenuProps> = ({
  menuTitle,
  menuIcon,
  tooltipText,
}) => {
  const { setAdvice } = useAdviceContext();
  return (
    <Flex
      {...(tooltipText && setAdviceOnHover(tooltipText, setAdvice))}
      className="PersonalGenericMenuCards"
      flexDirection="column"
      position="relative"
      borderRadius="12px"
      alignItems="center"
      border="1px solid transparent"
      padding={{ md: "8px", "2xl": "17px 12px 12px" }}
      gap="14px"
      width="100%"
      _hover={{
        backgroundColor: colors.blue.transparent,
        color: colors.white,
        p: {
          color: colors.white,
        },
      }}
      sx={{
        ".helpIconCardItem": {
          position: "absolute",
          top: "3px",
          right: "-2px",
          width: "75px",
          borderTopRightRadius: "30px",
          "@media(max-width: 1600px), @media(max-height: 850px)": {
            width: "30px",
            borderTopRightRadius: "10px",
            right: "12px",
            top: "-10px",
          },
        },
        "@media(max-width: 1600px), @media(max-height: 850px)": {
          width: "180px",
          svg: {
            height: "70px",
          },
        },
      }}
    >
      <Flex width="fit-content" position="absolute" top="0" right="0"></Flex>
      <PicturesVariant icon={menuIcon} />
      <Text variant="xl" textAlign="center" className="cardActiveTitle">
        {menuTitle}
      </Text>
    </Flex>
  );
};

export default DressingSubMenu;
