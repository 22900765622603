export const EvenementielIcon = () => (
  <svg width="100" height="101" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_905_39956)">
      <path d="M16.2972 41.1949C21.3976 41.1949 25.5323 37.0602 25.5323 31.9598C25.5323 26.8593 21.3976 22.7246 16.2972 22.7246C11.1967 22.7246 7.06201 26.8593 7.06201 31.9598C7.06201 37.0602 11.1967 41.1949 16.2972 41.1949Z" fill="#F7B80B"/>
      <path d="M73.5102 42.4163C73.5102 60.5983 62.7013 66.9467 49.3679 66.9467C36.0345 66.9467 25.2256 60.5983 25.2256 42.4163C25.2256 24.2343 49.3679 1.104 49.3679 1.104C49.3679 1.104 73.5102 24.2343 73.5102 42.4163Z" fill="#F7B80B"/>
      <path d="M48.4883 64.1654L48.7355 48.9486L59.0256 30.1235L48.7743 46.5617L48.8855 39.7196L55.9773 26.0998L48.9149 37.909L49.1149 25.6033L56.709 14.7602L49.1462 23.6683L49.2711 1.104L48.4862 30.975L48.5508 29.7428L40.8296 17.9245L48.4269 32.1082L47.7075 45.8517L47.686 45.4869L38.7852 33.0498L47.6592 46.7755L47.569 48.4943L47.5529 48.5201L47.5603 48.6612L45.7352 83.5293H48.1737L48.4664 65.5193L57.3185 51.8272L48.4883 64.1654Z" fill="#3F3D56"/>
      <path d="M96.8042 59.5891C96.8042 77.7711 85.9953 84.1196 72.6619 84.1196C59.3284 84.1196 48.5195 77.7711 48.5195 59.5891C48.5195 41.4072 72.6619 18.2769 72.6619 18.2769C72.6619 18.2769 96.8042 41.4072 96.8042 59.5891Z" fill="#3F3D56"/>
      <path d="M71.7823 81.3383L72.0296 66.1217L82.3195 47.2965L72.0684 63.7346L72.1794 56.8926L79.2714 43.2728L72.2088 55.0819V55.0821L72.4088 42.7762L80.0029 31.9332L72.4402 40.8413L72.5652 18.2769L71.7801 48.148L71.8447 46.9158L64.1236 35.0975L71.7209 49.2812L71.0014 63.0245L70.9799 62.6597L62.0791 50.2228L70.9531 63.9485L70.8632 65.6673L70.8469 65.6931L70.8543 65.8342L69.0291 100.702H71.4677L71.7603 82.6921L80.6125 69.0002L71.7823 81.3383Z" fill="#F2F2F2"/>
      <path d="M51.4062 59.5891C51.4062 77.7711 40.5973 84.1196 27.2639 84.1196C13.9305 84.1196 3.12158 77.7711 3.12158 59.5891C3.12158 41.4072 27.2639 18.2769 27.2639 18.2769C27.2639 18.2769 51.4062 41.4072 51.4062 59.5891Z" fill="#3F3D56"/>
      <path d="M26.3843 81.3383L26.6317 66.1217L36.9218 47.2965L26.6704 63.7346L26.7815 56.8926L33.8734 43.2728L26.811 55.0819V55.0821L27.0108 42.7762L34.605 31.9332L27.0422 40.8413L27.1672 18.2769L26.3823 48.148L26.4468 46.9158L18.7258 35.0975L26.3229 49.2812L25.6035 63.0245L25.5822 62.6597L16.6812 50.2228L25.5551 63.9485L25.4652 65.6673L25.4491 65.6931L25.4565 65.8342L23.6312 100.702H26.0697L26.3624 82.6921L35.2147 69.0002L26.3843 81.3383Z" fill="#F2F2F2"/>
      <path d="M49.9826 100.549C38.4357 100.549 27.2461 96.545 18.3202 89.2198C9.39436 81.8946 3.28456 71.701 1.03186 60.3761C-1.22085 49.0511 0.522916 37.2954 5.96604 27.112C11.4092 16.9285 20.2149 8.94748 30.8828 4.52868C41.5506 0.109872 53.4207 -0.473284 64.4703 2.87857C75.52 6.23043 85.0656 13.3099 91.4807 22.9108C97.8958 32.5116 100.783 44.0398 99.6516 55.531C98.5199 67.0223 93.4386 77.7656 85.2738 85.9305C80.6501 90.5795 75.1502 94.2653 69.0924 96.7745C63.0347 99.2837 56.5394 100.567 49.9826 100.549ZM49.9826 1.11074C22.6726 1.11074 0.454154 23.329 0.454154 50.6391C0.454154 77.9492 22.6726 100.168 49.9826 100.168C77.2926 100.168 99.511 77.9492 99.511 50.6391C99.511 23.329 77.2926 1.11074 49.9826 1.11074Z" fill="#2F2E41"/>
      <path d="M90.7931 79.1136V100.367H9.1792V79.1136L49.9861 57.8599L50.0711 57.904L90.7931 79.1136Z" fill="#F2F2F2"/>
      <path opacity="0.1" d="M90.7933 79.1139V100.367H50.0713V57.9043L90.7933 79.1139Z" fill="black"/>
      <path d="M46.5003 70.8674L43.4399 72.023V67.4669L46.5003 66.1255V70.8674Z" fill="#3F3D56"/>
      <path d="M12.6647 83.6195L11.6445 84.0052V81.2391L12.6647 80.7881V83.6195Z" fill="#3F3D56"/>
      <path d="M16.2352 82.4293L15.0449 82.8754V79.8789L16.2352 79.3564V82.4293Z" fill="#3F3D56"/>
      <path d="M20.1459 80.8987L18.7856 81.4103V78.1782L20.1459 77.5796V80.8987Z" fill="#3F3D56"/>
      <path d="M24.9069 79.0285L23.2065 79.6702V76.308L24.9069 75.562V79.0285Z" fill="#3F3D56"/>
      <path d="M30.6877 76.8181L28.6475 77.5893V73.9277L30.6877 73.0327V76.8181Z" fill="#3F3D56"/>
      <path d="M37.6592 74.2676L35.2788 75.1645V71.0371L37.6592 69.9937V74.2676Z" fill="#3F3D56"/>
      <path d="M12.6647 89.2304L11.6445 89.4869V86.6801L12.6647 86.3574V89.2304Z" fill="#3F3D56"/>
      <path d="M16.2352 88.3803L15.0449 88.6783V85.6598L16.2352 85.2847V88.3803Z" fill="#3F3D56"/>
      <path d="M20.1459 87.36L18.7856 87.7018V84.4696L20.1459 84.0405V87.36Z" fill="#3F3D56"/>
      <path d="M24.9069 86.1699L23.2065 86.5969V83.1093L24.9069 82.5742V86.1699Z" fill="#3F3D56"/>
      <path d="M30.6877 84.6394L28.6475 85.1543V81.4089L30.6877 80.7671V84.6394Z" fill="#3F3D56"/>
      <path d="M37.6589 82.9396L35.1084 83.581V79.3689L37.6589 78.5664V82.9396Z" fill="#3F3D56"/>
      <path d="M46.5003 80.7292L43.4399 81.4985V76.8185L46.5003 75.8584V80.7292Z" fill="#3F3D56"/>
      <path d="M46.5003 90.4203L43.4399 90.81V86.1697L46.5003 85.5908V90.4203Z" fill="#3F3D56"/>
      <path d="M12.6647 94.6711L11.6445 94.8023V92.1207L12.6647 91.9263V94.6711Z" fill="#3F3D56"/>
      <path d="M16.2352 94.3315L15.0449 94.4815V91.611L16.2352 91.3877V94.3315Z" fill="#3F3D56"/>
      <path d="M20.1459 93.8211L18.7856 93.9932V90.7607L20.1459 90.5015V93.8211Z" fill="#3F3D56"/>
      <path d="M24.9069 93.1413L23.2065 93.3586V89.9108L24.9069 89.5864V93.1413Z" fill="#3F3D56"/>
      <path d="M30.6877 92.461L28.6475 92.7199V88.8905L30.6877 88.502V92.461Z" fill="#3F3D56"/>
      <path d="M37.6589 91.6106L35.1084 91.9329V87.6999L37.6589 87.2158V91.6106Z" fill="#3F3D56"/>
      <path d="M87.4775 83.6195L88.4978 84.0052V81.2391L87.4775 80.7881V83.6195Z" fill="#3F3D56"/>
      <path d="M83.9067 82.4293L85.097 82.8754V79.8789L83.9067 79.3564V82.4293Z" fill="#3F3D56"/>
      <path d="M79.9961 80.8987L81.3563 81.4103V78.1782L79.9961 77.5796V80.8987Z" fill="#3F3D56"/>
      <path d="M75.2354 79.0285L76.9357 79.6702V76.308L75.2354 75.562V79.0285Z" fill="#3F3D56"/>
      <path d="M69.4546 76.8181L71.4949 77.5893V73.9277L69.4546 73.0327V76.8181Z" fill="#3F3D56"/>
      <path d="M62.4829 74.2676L64.8635 75.1645V71.0371L62.4829 69.9937V74.2676Z" fill="#3F3D56"/>
      <path d="M87.4775 89.2304L88.4978 89.4869V86.6801L87.4775 86.3574V89.2304Z" fill="#3F3D56"/>
      <path d="M83.9067 88.3803L85.097 88.6783V85.6598L83.9067 85.2847V88.3803Z" fill="#3F3D56"/>
      <path d="M79.9961 87.36L81.3563 87.7018V84.4696L79.9961 84.0405V87.36Z" fill="#3F3D56"/>
      <path d="M75.2354 86.1699L76.9357 86.5969V83.1093L75.2354 82.5742V86.1699Z" fill="#3F3D56"/>
      <path d="M69.4546 84.6394L71.4949 85.1543V81.4089L69.4546 80.7671V84.6394Z" fill="#3F3D56"/>
      <path d="M62.4829 82.9396L65.0334 83.581V79.3689L62.4829 78.5664V82.9396Z" fill="#3F3D56"/>
      <path d="M53.6416 80.7292L56.7022 81.4985V76.8185L53.6416 75.8584V80.7292Z" fill="#3F3D56"/>
      <path d="M53.6416 90.4203L56.7022 90.81V86.1697L53.6416 85.5908V90.4203Z" fill="#3F3D56"/>
      <path d="M87.4775 94.6711L88.4978 94.8023V92.1207L87.4775 91.9263V94.6711Z" fill="#3F3D56"/>
      <path d="M83.9067 94.3315L85.097 94.4815V91.611L83.9067 91.3877V94.3315Z" fill="#3F3D56"/>
      <path d="M79.9961 93.8211L81.3563 93.9932V90.7607L79.9961 90.5015V93.8211Z" fill="#3F3D56"/>
      <path d="M75.2354 93.1413L76.9357 93.3586V89.9108L75.2354 89.5864V93.1413Z" fill="#3F3D56"/>
      <path d="M69.4546 92.461L71.4949 92.7199V88.8905L69.4546 88.502V92.461Z" fill="#3F3D56"/>
      <path d="M62.4829 91.6106L65.0334 91.9329V87.6999L62.4829 87.2158V91.6106Z" fill="#3F3D56"/>
      <path d="M56.5321 72.0575L53.4717 70.9033V66.1064L56.5321 67.4484V72.0575Z" fill="#3F3D56"/>
      <path d="M85.27 96.5825H85.0205V100.574H85.27V96.5825Z" fill="#2F2E41"/>
      <path d="M88.209 97.6694H88.0322V100.498H88.209V97.6694Z" fill="#2F2E41"/>
      <path d="M90.8209 98.0146H90.6655V100.5H90.8209V98.0146Z" fill="#2F2E41"/>
      <path d="M77.9558 95.3175V100.452L74.6403 100.447L71.3247 100.442V94.6714L74.6403 94.9945L77.9558 95.3175Z" fill="#3F3D56"/>
      <path d="M22.1865 100.452L28.8176 100.442V94.6714L22.1865 95.3169V100.452Z" fill="#3F3D56"/>
      <path d="M129.698 100.282H-18.876V100.622H129.698V100.282Z" fill="#2F2E41"/>
      <path opacity="0.1" d="M77.9556 95.3176V100.453L74.6401 100.447V94.9946L77.9556 95.3176Z" fill="black"/>
      <path d="M49.6462 95.7764H49.3062V100.537H49.6462V95.7764Z" fill="#2F2E41"/>
      <path d="M51.4385 94.0817C51.4385 95.75 50.5474 97.1421 49.4761 97.1899C48.4346 97.2364 47.6113 95.9881 47.6113 94.4028C47.6113 92.8175 49.6461 88.4653 49.6461 88.4653C49.6461 88.4653 51.4385 92.4134 51.4385 94.0817Z" fill="#F7B80B"/>
      <path d="M9.64034 97.3022H9.4375V100.142H9.64034V97.3022Z" fill="#2F2E41"/>
      <path d="M10.7096 96.2914C10.7096 97.2865 10.178 98.1169 9.53905 98.1454C8.91783 98.1731 8.42676 97.4285 8.42676 96.4829C8.42676 95.5373 9.64047 92.9414 9.64047 92.9414C9.64047 92.9414 10.7096 95.2963 10.7096 96.2914Z" fill="#F7B80B"/>
      <path d="M86.517 96.74C86.517 97.6864 85.9206 98.4256 85.1449 98.3892C84.3213 98.3506 83.6152 97.4621 83.6152 96.407C83.6152 95.3519 85.1449 92.7158 85.1449 92.7158C85.1449 92.7158 86.517 95.7936 86.517 96.74Z" fill="#F7B80B"/>
      <path d="M89.0928 97.7807C89.0928 98.4513 88.6702 98.9751 88.1206 98.9493C87.5369 98.9219 87.0366 98.2924 87.0366 97.5448C87.0366 96.7971 88.1206 94.9292 88.1206 94.9292C88.1206 94.9292 89.0928 97.1101 89.0928 97.7807Z" fill="#F7B80B"/>
      <path d="M91.5977 98.1126C91.5977 98.702 91.2263 99.1624 90.7432 99.1397C90.2302 99.1156 89.7905 98.5623 89.7905 97.9052C89.7905 97.2482 90.7432 95.6064 90.7432 95.6064C90.7432 95.6064 91.5977 97.5233 91.5977 98.1126Z" fill="#F7B80B"/>
    </g>
    <defs>
      <clipPath id="clip0_905_39956">
        <rect width="99.8322" height="100" fill="white" transform="translate(0.0839844 0.71875)"/>
      </clipPath>
    </defs>
  </svg>

);