import { Icons } from "../../components/PicturesVariant/PicturesVariant";
import { OrderFormProps } from "../../types/order";
// import { PicturesPngTypes } from "../../components/PicturePngVariants/PicturePngVariants";
import { UserInformation } from "../../context/UserContext";
import { OptionInformation } from "../../context/OptionsContext";
export interface PageProps {
  page: PageContentProps;
}

export interface DefaultProps extends PageProps {
  pages: PageContentProps[];
  setPage: (page: PageContentProps) => void;
}

export interface PageContentProps {
  id: string;
  title: string;
  description?: string;
  nameForTotalCard: string;
  introductionData?: {
    img?: string;
    subImg?: string;
    flexDirection?: boolean;
    textBlockWidth?: string;
    icon?: Icons;
    step?: string;
    alignItemsCenter?: boolean;
    nextSectionButtonVariants?: boolean;
  };
  subtitle: string;
  icon?: Icons;
  answers?: Answer[];
  additionalInfoText?: AdditionalTextProps;
  previousPageId?: string | undefined;
  nextPageId?: string | undefined;
  nextPageButtonText?: string | undefined;
  nextSection?: SectionPageTypes;
  totalCard?: boolean;
  addStaffCard?: boolean;
  ourAdvice?: boolean;
  pictureButtonBlock?: PictureTextBlock;
  animatedSalePoints?: AnimatedSaleProps;
  questionToggle?: VariantsTypes[];
  tabItems?: TabItems[];
  flyersSection?: FlyersSection;
  howManyCustomersSection?: boolean;
  hideInTotalCard?: boolean;
  squareSection?: ArrivalOfGuestsProps;
  arrivalOfGuests?: boolean;
  selectionPersonnel?: SelectionPersonnel[];
  dressing?: DressingProps[];
  order?: OrderFormProps[];
  options?: Answer[];
  calendarSelectionPage?: Answer[];
  validation?: ValidationProps;
  sliderPhotos?: string[];
  calendarDefault?: CalendarDefaultProps;
  calendarRole?: CalendarRoleProps;
  calendarPopupLevel1?: AlertPopupProps;
  calendarPopupLevel2?: AlertPopupProps;
  cvgContent?: CvgContentProps[];
  calendarBreakTimes?: string[];
  type: PageTypes;
  onNextPage?: (
    userContext: UserInformation | null,
    setUserContext: (userContent: UserInformation) => any,
    optionContext: OptionInformation | null,
    setOptionContext: (optionContent: OptionInformation) => any,
    page: PageContentProps
  ) => PageContentProps;
  stepToDisplay?: number;
  eventInWinter?: boolean;
  parking?: boolean;
}

export interface AnimatedSaleProps extends PictureTextBlock {
  toggleButton: {
    title: string;
  }[];
}

export interface CvgContentProps {
  title: string;
  values?: string[];
}

export interface ValidationProps {
  title: string;
  subtitle: string;
  description: string;
  icon?: Icons;
}

export interface DressingProps {
  tabHeaderItem: Answer;
  tabItems: Answer[];
  alertPopup?: AlertPopupProps;
  toggleChoiceTunies?: ToggleChoiceProps[];
  toggleChoiceModeleDePresentation?: ToggleChoiceProps[];
  dressingProfessionelItems?: boolean;
  dressingPerssonnelItems?: boolean;
  showDressingOption?: (eventType: string) => boolean;
}

export interface ToggleChoiceProps {
  title: string;
  icon: Icons;
  values: string[];
}

export interface AdditionalTextProps {
  title: string;
  tooltipText?: string;
}

export interface AlertPopupProps {
  title?: string;
  popupWidth?: string;
  description?: string;
  textButton?: string;
  defaultIsOpen?: boolean;
  open?: boolean;
  close?: (() => void) | undefined;
  icon?: Icons;
}

export interface CalendarRoleProps {
  role: SelectItem[];
}

export interface SelectItem {
  title: string;
  icon: Icons;
}

export interface CalendarDefaultProps {
  time: Select[];
}

export interface SelectionPersonnel {
  titleOfSection: string;
  leader?: string;
  countForLeader?: number;
  tabItems?: Answer[];
  consultationBlock?: Answer;
  tooltipText?: string;
}
export interface ArrivalOfGuestsProps {
  title: string;
  id?: number;
  icon: Icons;
}

export interface FlyersSection extends FlyersProps {
  ourAdvice?: {
    icon: Icons;
    title: string;
    subtitle: string;
    description: string;
    descriptionOption: string;
  };
}

export interface FlyersProps {
  pageId?: string;
  flyers: {
    icon: Icons;
    description: string;
  };
  arrows?: Icons;
  clients?: {
    icon: Icons;
    description: string;
  };
}

export interface TabItems {
  icon: Icons;
  title: string;
  description: string;
  isBtn?: boolean;
  pageId?: string;
  toggleButtonTitle?: string;
}

export interface Select {
  title: string;
}

export interface Answer {
  // title?: string;
  name?: string;
  // printed?: boolean;
  // calendarSelectId?: boolean;
  // nameForTotalCard?: string;
  // selectDePersonnelCard?: boolean;
  // selectDePersonnelTabTitle?: string;
  // withTextfield?: boolean;
  // dressingTabTitle?: string;
  // description?: string;
  // optionsPrice?: string;
  // variantCardTitle?: string;
  // image?: PicturesPngTypes;
  icon?: Icons;
  // answerDescription?: string;
  // select?: Select[];
  // selectDescription?: string;
  // toggleChoice?: Select[];
  // hover?: boolean;
  // pageId?: string;
  // cardVariantPageId?: string;
  tooltipText?: string;
  // variants?: VariantsTypes[];
  // toggleButtonsBlock?: ToggleButtons;
  // sliderPhotos?: string[];
  // colors?: string[];
  // accessoire?: string[];
  // modeleDePresentation?: string[];
}

// interface ToggleButtons {
//   direction?: string;
//   variants?: VariantsTypes[];
// }

interface VariantsTypes {
  title: string;
  pageId?: string | undefined;
  tooltipText?: string;
}

export enum PageTypes {
  SIMPLE = "Simple",
  ORDER = "Order",
  CGV = "Cvg",
  MULTIPLE = "Multiple",
  START = "Start",
  LOADING_CONTENT = "LoadingContent",
  DRESSING = "Dressing",
  OPTIONS = "Options",
  VALIDATION = "Validation",
  CALENDAR = "Calendar",
  SELECT_DE_PERSONNEL = "SelectDePersonnel",
  INRODUCTION = "Introduction",
  ONBOARDING_OPTIONS = "OnboardingOptions",
  CONTACT_FORM = "ContactForm",
}

export enum SectionPageTypes {
  DEFAULT = "DEFAULT",
  INRODUCTION = "INRODUCTION",
  CGV = "CGV",
  ONBOARDING = "ONBOARDING",
  SELECTION_DE_PERSONNEL = "SELECTION_DE_PERSONNEL",
  CALENDAR = "CALENDAR",
  OPTIONS = "OPTIONS",
  VALIDATION = "VALIDATION",
  ORDER = "ORDER",
  DRESSING = "DRESSING",
  CONTACT_FORM = "CONTACT_FORM",
  CONTACT_FORM_COMPLEX = "CONTACT_FORM_COMPLEX",
}

export interface PictureTextBlock {
  icon: Icons;
  title?: string;
  description: string;
  placeHolder?: string;
  inputType?: string;
}

export enum Abc {
  A = "A",
  B = "B",
  C = "C",
}
