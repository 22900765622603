import { Link, LinkProps } from "@chakra-ui/react";

export default function MailTo({
  email,
  subject,
  body,
  ...props
}: {
  email: string;
  subject?: string;
  body?: string;
} & LinkProps) {
  return (
    <Link
      href={`mailto:${email}?subject=${encodeURIComponent(
        subject ?? ""
      )}&body=${encodeURIComponent(body || "")}`}
      {...props}
    >
      {props.children}
    </Link>
  );
}
