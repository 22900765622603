import {
  BoxProps,
  Flex,
  Grid,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/react";
import { FC, ReactElement } from "react";
import { colors } from "../../ui/theme";
import ColorBoxButton from "../Buttons/ColorBoxButton";
import { DressingPantsIcon } from "../Icons/DressingPantsIcon";

const WHITE = "#fff";
const BLUE = "#004dcf";
const BLACK = "#000";

interface BottomOutfitSelectionCardProps {
  selectedOutfit: any;
  onSelectBottom: (value: string | undefined) => void;
  onSelectBottomColor: (value: string | undefined) => void;
}
const BottomOutfitSelectionCard: FC<
  BottomOutfitSelectionCardProps & BoxProps
> = ({
  selectedOutfit,
  onSelectBottom,
  onSelectBottomColor,
  ...props
}): ReactElement => {
  const handleSelectJeanColor = (value: string) => {
    if (selectedOutfit?.bottom === "Jean") {
      onSelectBottomColor(value);
    }
  };

  return (
    <Flex
      gap="14px"
      width="100%"
      minWidth="250px"
      border={`1px solid ${colors.gray.lightMode}`}
      padding="14px"
      borderRadius="12px"
      {...props}
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap="14px"
      >
        <DressingPantsIcon />
        <Text variant="xl" fontWeight="500">
          Bas
        </Text>
      </Flex>
      <RadioGroup
        marginLeft="1rem"
        onChange={onSelectBottom}
        value={selectedOutfit?.bottom ?? ""}
        display="flex"
        flexWrap="wrap"
        flexDirection="row"
        gap="1rem"
      >
        <Grid width="100%" templateColumns="80% 20%" alignItems="center">
          <Radio value="Pantalon tailleur" key={1} gridColumn={1}>
            <Text marginRight="1rem" variant="lg" whiteSpace="nowrap">
              Pantalon tailleur
            </Text>
          </Radio>
          <ColorBoxButton
            buttonColor={BLACK}
            isActive={selectedOutfit?.bottom === "Pantalon tailleur"}
          />
        </Grid>

        <Grid
          width="100%"
          templateColumns="55% 15% 15% 15%"
          alignItems="center"
        >
          <Radio value="Jean" key={2} gridColumn={1}>
            <Text variant="lg" whiteSpace="nowrap">
              Jean
            </Text>
          </Radio>
          <ColorBoxButton
            buttonColor={BLACK}
            isActive={
              selectedOutfit?.bottom === "Jean" &&
              selectedOutfit?.bottomColor === BLACK
            }
            onClick={() => handleSelectJeanColor(BLACK)}
          />
          <ColorBoxButton
            buttonColor={BLUE}
            isActive={
              selectedOutfit?.bottom === "Jean" &&
              selectedOutfit?.bottomColor === BLUE
            }
            onClick={() => handleSelectJeanColor(BLUE)}
          />
          <ColorBoxButton
            buttonColor={WHITE}
            isActive={
              selectedOutfit?.bottom === "Jean" &&
              selectedOutfit?.bottomColor === WHITE
            }
            onClick={() => handleSelectJeanColor(WHITE)}
          />
        </Grid>
      </RadioGroup>
    </Flex>
  );
};

export default BottomOutfitSelectionCard;
