export const OrderMastercardLogoIcon = () => (
  <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1137_69466)">
      <path d="M9.96387 2.30469H17.348V15.5727H9.96387V2.30469Z" fill="#FF5F00"/>
      <path d="M10.4355 8.93903C10.4355 6.24327 11.7013 3.85216 13.6469 2.30497C12.2171 1.17979 10.4121 0.5 8.44304 0.5C3.77797 0.5 0.00390625 4.27406 0.00390625 8.93903C0.00390625 13.6039 3.77797 17.3781 8.44294 17.3781C10.412 17.3781 12.217 16.6983 13.6469 15.573C11.7013 14.0493 10.4355 11.6348 10.4355 8.93903Z" fill="#EB001B"/>
      <path d="M27.311 8.93903C27.311 13.6039 23.5369 17.3781 18.872 17.3781C16.9029 17.3781 15.0979 16.6983 13.668 15.573C15.6371 14.0259 16.8795 11.6348 16.8795 8.93903C16.8795 6.24327 15.6136 3.85216 13.668 2.30497C15.0978 1.17979 16.9029 0.5 18.872 0.5C23.5369 0.5 27.3111 4.29756 27.3111 8.93903H27.311Z" fill="#F79E1B"/>
    </g>
    <defs>
      <clipPath id="clip0_1137_69466">
        <rect width="27.2" height="17" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>

);