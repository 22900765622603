export const RoleAnimateurMascotteIcon = () => (
  <svg
    width="180"
    height="120"
    viewBox="0 0 180 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M66.2431 36.5891C71.6397 36.5891 76.0145 32.2143 76.0145 26.8177C76.0145 21.4211 71.6397 17.0463 66.2431 17.0463C60.8465 17.0463 56.4717 21.4211 56.4717 26.8177C56.4717 32.2143 60.8465 36.5891 66.2431 36.5891Z"
      fill="#3F3D56"
    />
    <path
      d="M122.3 39.8463C127.697 39.8463 132.072 35.4715 132.072 30.0749C132.072 24.6783 127.697 20.3035 122.3 20.3035C116.904 20.3035 112.529 24.6783 112.529 30.0749C112.529 35.4715 116.904 39.8463 122.3 39.8463Z"
      fill="#3F3D56"
    />
    <path
      d="M122.472 19.9606C120.595 19.9606 118.758 20.5012 117.18 21.5178C115.602 22.5343 114.35 23.9838 113.575 25.6928C115.247 24.2028 117.381 23.3329 119.619 23.2288C121.857 23.1247 124.062 23.7928 125.866 25.1211C127.67 26.4493 128.963 28.3572 129.527 30.5249C130.092 32.6927 129.895 34.9887 128.969 37.0283C130.448 35.7114 131.493 33.9758 131.963 32.0517C132.434 30.1276 132.309 28.1059 131.604 26.2547C130.899 24.4035 129.649 22.8102 128.018 21.6861C126.387 20.562 124.452 19.9602 122.472 19.9606Z"
      fill="#F7B80B"
    />
    <path
      d="M66.0717 16.7035C67.9486 16.7035 69.7858 17.2441 71.3636 18.2607C72.9413 19.2772 74.1929 20.7267 74.9687 22.4357C73.296 20.9457 71.162 20.0758 68.9243 19.9717C66.6866 19.8676 64.481 20.5357 62.6772 21.864C60.8734 23.1923 59.5808 25.1001 59.0159 27.2678C58.4511 29.4356 58.6484 31.7316 59.5747 33.7712C58.0951 32.4543 57.0507 30.7187 56.5801 28.7947C56.1094 26.8706 56.2348 24.8489 56.9394 22.9976C57.6441 21.1464 58.8949 19.5531 60.5258 18.429C62.1568 17.3049 64.0909 16.7032 66.0717 16.7035Z"
      fill="#F7B80B"
    />
    <path
      d="M108.072 83.2177C108.076 88.2076 106.599 93.0866 103.83 97.2373C101.06 101.388 97.1213 104.624 92.512 106.535C87.9027 108.447 82.83 108.948 77.9357 107.975C73.0415 107.003 68.5457 104.6 65.0173 101.072C61.4889 97.5436 59.0865 93.0478 58.114 88.1536C57.1415 83.2593 57.6427 78.1866 59.5541 73.5773C61.4656 68.968 64.7014 65.0293 68.852 62.2596C73.0027 59.4899 77.8817 58.0136 82.8716 58.0177C86.184 58.0065 89.466 58.6507 92.5284 59.9131C95.5909 61.1756 98.3734 63.0314 100.716 65.3736C103.058 67.7159 104.914 70.4984 106.176 73.5609C107.439 76.6233 108.083 79.9053 108.072 83.2177Z"
      fill="#3F3D56"
    />
    <path
      d="M101.49 64.0082C104.058 66.5417 106.05 69.5987 107.331 72.9711C104.255 75.9981 100.56 78.3221 96.4994 79.7832C92.4389 81.2442 88.1099 81.8075 83.8108 81.4341C79.5117 81.0608 75.3446 79.7597 71.5968 77.6206C67.849 75.4814 64.6096 72.5551 62.1018 69.0433C64.0601 65.7565 66.7406 62.9583 69.9403 60.8605C73.14 58.7628 76.775 57.4206 80.5702 56.9355C84.3653 56.4503 88.2211 56.835 91.8456 58.0603C95.4701 59.2857 98.7683 61.3196 101.49 64.0082Z"
      fill="#F7B80B"
    />
    <path
      d="M92.643 82.1891C109.117 82.1891 122.472 68.8344 122.472 52.3606C122.472 35.8867 109.117 22.532 92.643 22.532C76.1692 22.532 62.8145 35.8867 62.8145 52.3606C62.8145 68.8344 76.1692 82.1891 92.643 82.1891Z"
      fill="#3F3D56"
    />
    <path
      d="M99.8918 53.3809C101.437 51.3066 103.741 49.9295 106.299 49.5513C108.858 49.1731 111.462 49.8247 113.541 51.3632C114.696 49.8122 115.365 47.9533 115.464 46.0218C115.562 44.0903 115.085 42.1731 114.092 40.513C113.1 38.8528 111.638 37.5245 109.89 36.696C108.143 35.8675 106.189 35.5763 104.275 35.8591C102.362 36.1419 100.576 36.9861 99.1429 38.2847C97.7097 39.5833 96.6942 41.278 96.2248 43.1542C95.7554 45.0304 95.8533 47.0036 96.5061 48.8241C97.1589 50.6446 98.3372 52.2305 99.8918 53.3809Z"
      fill="white"
    />
    <path
      d="M77.092 55.2666C78.6369 53.1924 80.9411 51.8153 83.4997 51.4371C86.0582 51.0589 88.6623 51.7104 90.7412 53.249C91.8965 51.698 92.5656 49.839 92.6638 47.9075C92.7619 45.976 92.2848 44.0588 91.2926 42.3987C90.3005 40.7386 88.8381 39.4102 87.0905 38.5817C85.3429 37.7533 83.3889 37.462 81.4757 37.7448C79.5624 38.0276 77.7762 38.8718 76.3431 40.1704C74.9099 41.4691 73.8944 43.1638 73.425 45.0399C72.9556 46.9161 73.0535 48.8894 73.7063 50.7098C74.3591 52.5303 75.5374 54.1162 77.092 55.2666Z"
      fill="white"
    />
    <path
      d="M101.153 60.3028C99.7285 61.9291 97.5078 63.1066 94.9779 63.5773C92.448 64.048 89.8152 63.7735 87.6565 62.8139C86.5911 64.0299 86.0276 65.4327 86.0375 66.8445C86.0474 68.2563 86.6301 69.6138 87.7119 70.7449C88.7937 71.876 90.326 72.73 92.1146 73.1986C93.9033 73.6673 95.8679 73.7296 97.7597 73.3776C99.6516 73.0256 101.385 72.2752 102.742 71.2214C104.098 70.1676 105.017 68.8578 105.38 67.4578C105.743 66.0578 105.536 64.6306 104.783 63.3569C104.031 62.0832 102.767 61.0203 101.153 60.3028Z"
      fill="white"
    />
    <path
      d="M83.5575 49.1034C85.5457 49.1034 87.1575 47.4917 87.1575 45.5034C87.1575 43.5152 85.5457 41.9034 83.5575 41.9034C81.5693 41.9034 79.9575 43.5152 79.9575 45.5034C79.9575 47.4917 81.5693 49.1034 83.5575 49.1034Z"
      fill="#3F3D56"
    />
    <path
      d="M106.871 46.3606C108.86 46.3606 110.471 44.7489 110.471 42.7606C110.471 40.7724 108.86 39.1606 106.871 39.1606C104.883 39.1606 103.271 40.7724 103.271 42.7606C103.271 44.7489 104.883 46.3606 106.871 46.3606Z"
      fill="#3F3D56"
    />
    <path
      d="M94.8716 55.2748C95.913 55.2748 96.7573 54.4306 96.7573 53.3891C96.7573 52.3477 95.913 51.5034 94.8716 51.5034C93.8301 51.5034 92.9858 52.3477 92.9858 53.3891C92.9858 54.4306 93.8301 55.2748 94.8716 55.2748Z"
      fill="#FF6584"
    />
    <path
      d="M80.1288 114.481V106.189H77.3859V114.481C76.5838 114.765 75.8894 115.291 75.3982 115.985C74.907 116.68 74.6432 117.51 74.6431 118.361L82.8716 118.361C82.8715 117.51 82.6077 116.68 82.1165 115.985C81.6253 115.291 80.9309 114.765 80.1288 114.481Z"
      fill="#3F3D56"
    />
    <path
      d="M90.243 114.481V106.189H87.5002V114.481C86.6981 114.765 86.0036 115.291 85.5124 115.985C85.0212 116.68 84.7574 117.51 84.7573 118.361L92.9859 118.361C92.9858 117.51 92.722 116.68 92.2308 115.985C91.7396 115.291 91.0452 114.765 90.243 114.481Z"
      fill="#3F3D56"
    />
    <path
      d="M80.4481 25.3926C79.2053 23.3916 78.3705 21.0412 78.6291 18.6999C78.8878 16.3587 80.4215 14.0741 82.6794 13.4032C84.9373 12.7322 87.7343 14.1222 88.1598 16.439C90.4213 12.5289 93.9986 9.54956 98.2533 8.03269C100.227 7.33732 102.849 7.17963 103.99 8.93343C104.555 9.80142 104.615 10.9816 105.372 11.6887C106.558 12.7972 108.46 12.0165 110.08 12.1112C112.359 12.2445 114.194 14.3714 114.481 16.6362C114.768 18.9009 113.765 21.1769 112.284 22.9145C110.804 24.6522 108.878 25.9413 106.978 27.2073"
      fill="#3F3D56"
    />
    <path
      d="M78.0199 95.5344C77.1279 95.8303 76.4996 96.5892 75.9769 97.3331C75.4381 98.1 74.9443 98.9327 74.2079 99.5317C73.8986 99.8005 73.5358 100.001 73.1433 100.119C72.7509 100.237 72.3379 100.27 71.9317 100.216C71.1243 100.094 70.4398 99.5299 70.317 98.6974C70.1553 97.601 70.9226 96.5863 71.5957 95.8064L71.7775 96.2454C70.7051 96.361 69.7727 96.9724 68.7907 97.3659C67.8497 97.743 66.7368 97.9487 65.7968 97.4572C65.0756 97.08 64.6259 96.3255 64.4736 95.5434C64.3176 94.69 64.448 93.809 64.8445 93.0373C65.3448 92.0143 66.1653 91.1875 66.9612 90.3912L67.2114 90.821C66.0281 90.9987 64.7088 91.1643 63.6821 90.404C63.2714 90.1146 62.9786 89.6871 62.8571 89.1997C62.7525 88.661 62.8415 88.1026 63.1086 87.6233C63.6485 86.5718 64.6675 85.8307 65.581 85.1255C67.7121 83.4777 70.0213 82.0741 72.4652 80.9408C72.7634 80.8022 73.0249 81.2454 72.7248 81.3849C70.6312 82.3571 68.6352 83.527 66.7641 84.8788C65.8639 85.5297 64.9126 86.2009 64.1624 87.0265C63.5256 87.7274 62.9456 88.7996 63.6411 89.6661C64.4592 90.6854 65.9396 90.4956 67.0747 90.3251C67.129 90.3119 67.1861 90.3167 67.2375 90.3386C67.2889 90.3605 67.3319 90.3984 67.36 90.4467C67.3881 90.495 67.3999 90.5511 67.3936 90.6066C67.3872 90.6622 67.3631 90.7142 67.3249 90.7549C66.1386 91.9417 64.7298 93.3665 64.9362 95.1929C65.0144 95.8854 65.3429 96.5926 65.9591 96.9588C66.7967 97.4566 67.8443 97.2004 68.6879 96.8562C69.7122 96.4384 70.6559 95.8521 71.7775 95.7311C72.0192 95.705 72.0892 96.0196 71.9593 96.1701C71.4151 96.8007 70.7623 97.6212 70.8107 98.5054C70.8511 99.2452 71.4962 99.6878 72.1879 99.7296C73.9476 99.836 74.836 98.004 75.7282 96.8026C76.2928 96.0423 76.9628 95.3437 77.8832 95.0385C78.1981 94.934 78.3331 95.4305 78.0199 95.5344Z"
      fill="#F7B80B"
    />
  </svg>
);
