export const SalonIcon2 = () => (
  <svg
    width="100"
    height="101"
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1017_48606)">
      <path
        d="M100 50.7188C100.006 56.1862 99.1142 61.6175 97.3595 66.7957C97.2589 67.0915 97.1558 67.3864 97.0503 67.6803C95.1922 72.8189 92.5053 77.6186 89.0962 81.8889C88.0784 83.1655 87 84.3894 85.861 85.5605C84.7219 86.7336 83.5262 87.8494 82.2737 88.9081C75.2875 94.8325 66.8011 98.7128 57.75 100.121H57.7485C56.5947 100.3 55.4295 100.44 54.253 100.54C52.8521 100.658 51.4344 100.718 50 100.719C43.3295 100.727 36.7251 99.3971 30.5781 96.8065C24.4311 94.216 18.8667 90.4179 14.2145 85.6374C13.8876 85.3031 13.5656 84.9638 13.2485 84.6196C12.4586 83.7631 11.6987 82.88 10.969 81.9702C10.5592 81.4584 10.1593 80.9387 9.76925 80.4111C7.36673 77.1653 5.36784 73.6394 3.81658 69.9111C3.15504 68.3231 2.57733 66.7016 2.08582 65.0531C2.05031 64.9362 2.01629 64.8194 1.98226 64.7025C1.9083 64.451 1.8368 64.198 1.76777 63.9436C0.591013 59.634 -0.00351995 55.1862 1.56767e-05 50.7188C1.56767e-05 23.1048 22.3861 0.71875 50 0.71875C77.6139 0.71875 100 23.1048 100 50.7188Z"
        fill="#F2F2F2"
      />
      <path
        d="M63.6583 95.5444L62.6464 99.1051C61.0323 99.5254 59.3981 99.8646 57.75 100.121H57.7485C56.5946 100.3 55.4295 100.44 54.2529 100.54L57.5443 95.8373L58.0118 95.7959L63.6583 95.5444Z"
        fill="#2F2E41"
      />
      <path
        d="M89.096 81.8887C88.0782 83.1653 86.9998 84.3892 85.8608 85.5603L81.9584 85.5632L60.9717 85.5869L57.1936 85.5913L54.6818 85.5943L50.9451 85.5973L48.0708 85.6002L42.7617 85.6061L39.63 85.6106L33.0353 85.6165L29.8874 85.6209L21.164 85.6298L15.6847 85.6357L14.2143 85.6372C13.8874 85.3029 13.5654 84.9636 13.2483 84.6194C12.4584 83.7629 11.6985 82.8798 10.9688 81.97L14.0131 81.9671L16.0871 81.9656L20.8889 81.9597L30.1551 81.9508L33.2868 81.9478L34.5531 81.9464H34.5575L35.5516 81.9449L35.9969 81.9434L37.1374 81.9419H37.6196L41.0013 81.939L48.2957 81.9301L51.5057 81.9271L54.5797 81.9242L57.4614 81.9212L61.596 81.9168L83.6788 81.8946L89.096 81.8887Z"
        fill="#CCCCCC"
      />
      <path
        d="M36.0973 84.3443C37.7421 84.3443 39.0754 83.9188 39.0754 83.3938C39.0754 82.8689 37.7421 82.4434 36.0973 82.4434C34.4525 82.4434 33.1191 82.8689 33.1191 83.3938C33.1191 83.9188 34.4525 84.3443 36.0973 84.3443Z"
        fill="#3F3D56"
      />
      <path
        d="M36.0973 83.7105C37.7421 83.7105 39.0754 83.285 39.0754 82.76C39.0754 82.2351 37.7421 81.8096 36.0973 81.8096C34.4525 81.8096 33.1191 82.2351 33.1191 82.76C33.1191 83.285 34.4525 83.7105 36.0973 83.7105Z"
        fill="#3F3D56"
      />
      <path
        d="M35.5743 83.3969L36.0181 83.3902L35.8957 75.1035C35.8779 73.7513 36.2151 72.4182 36.8736 71.2371C37.5321 70.056 38.4889 69.0684 39.6485 68.3727L48.1188 63.3097L47.8911 62.9287L39.4208 67.9919C38.1945 68.7276 37.1826 69.7721 36.4861 71.0212C35.7897 72.2702 35.4331 73.6801 35.4519 75.1101L35.5743 83.3969Z"
        fill="#3F3D56"
      />
      <path
        d="M46.8212 65.9682C46.8212 66.5631 47.3035 67.0454 47.8984 67.0454C48.4933 67.0454 48.9756 66.5631 48.9756 65.9682V59.2515C48.9756 58.6566 48.4933 58.1743 47.8984 58.1743C47.3035 58.1743 46.8212 58.6566 46.8212 59.2515V65.9682Z"
        fill="#F7B80B"
      />
      <path
        d="M47.8606 47.6245L59.4767 61.3515L69.3806 65.591L72.0204 60.4597C69.5986 57.5783 61.6966 54.1198 61.6966 54.1198L50.9667 44.4957C50.9475 44.4249 50.9268 44.3542 50.9011 44.284C50.7506 43.8721 50.4965 43.5061 50.1632 43.2212C49.8299 42.9363 49.4287 42.7422 48.9985 42.6577C48.5683 42.5732 48.1235 42.601 47.7072 42.7386C47.2909 42.8762 46.9171 43.1188 46.6219 43.4431C46.3268 43.7673 46.1203 44.1622 46.0224 44.5896C45.9245 45.017 45.9384 45.4624 46.063 45.8828C46.1875 46.3032 46.4184 46.6844 46.7333 46.9895C47.0482 47.2946 47.4365 47.5133 47.8606 47.6245Z"
        fill="#A0616A"
      />
      <path
        d="M70.986 58.9004L68.4238 65.4602L68.4264 65.4857C68.6818 68.006 72.619 70.3396 75.8769 71.8534C76.9516 72.3516 78.1754 72.4232 79.3008 72.0538C80.4263 71.6843 81.3695 70.9012 81.9397 69.863C82.3552 69.0942 82.5474 68.2246 82.4945 67.3523C82.4416 66.4801 82.1458 65.64 81.6405 64.9271L79.0538 61.3047L70.986 58.9004Z"
        fill="#F7B80B"
      />
      <path
        d="M85.8374 71.3239L84.475 76.2277L83.6791 81.8949L83.4336 83.6375L83.4277 83.6745L81.9143 85.1123L81.9587 85.5635L82.0771 86.7662L82.2738 88.9082C75.2867 94.8311 66.8007 98.7112 57.7502 100.121C57.7487 100.123 57.7487 100.121 57.7487 100.121C56.5401 99.7751 55.5919 99.3993 55.2102 99.1256C55.0593 99.0191 54.9913 98.9245 54.9942 98.8283C55.0105 98.1878 56.8286 95.889 57.1037 95.5443L57.8256 92.5561L59.299 91.9703L58.9351 89.8683L60.9055 87.7884L60.9617 85.6478L60.972 85.5872L61.5963 81.9171L62.8419 74.6079L62.9691 73.8594L62.8537 68.0872L62.8197 66.3387L62.7797 64.3446C62.7692 63.8318 62.8069 63.3192 62.8922 62.8135C62.9553 62.4326 63.0462 62.0568 63.1643 61.6893C63.4926 60.6656 64.0196 59.7168 64.7151 58.8971C65.4106 58.0775 66.261 57.4031 67.2176 56.9126C67.2931 56.8742 67.3685 56.8372 67.4454 56.8002C67.4454 56.8002 67.4454 56.7987 67.4469 56.8002C68.0386 56.5205 68.662 56.3134 69.3034 56.1833L69.4898 55.852L71.3152 52.6094L76.9099 52.7529L80.2472 57.9762L83.0327 60.741C84.3969 62.0977 85.3756 63.7928 85.8685 65.6525C86.3614 67.5123 86.3507 69.4697 85.8374 71.3239Z"
        fill="#F7B80B"
      />
      <path
        d="M73.6247 51.0076C77.7181 51.0076 81.0364 47.6893 81.0364 43.5959C81.0364 39.5024 77.7181 36.1841 73.6247 36.1841C69.5313 36.1841 66.2129 39.5024 66.2129 43.5959C66.2129 47.6893 69.5313 51.0076 73.6247 51.0076Z"
        fill="#A0616A"
      />
      <path
        d="M72.72 51.107C73.8677 51.125 75.0149 51.0448 76.149 50.8674C77.2732 50.6388 79.788 48.7959 80.4891 47.687C81.0099 46.863 81.343 45.5764 81.5307 44.6421C81.7835 43.4059 81.7739 42.1303 81.5024 40.898C81.2309 39.6657 80.7036 38.5041 79.9547 37.4886C79.6669 37.0638 79.2696 36.7247 78.8049 36.5072C78.7637 36.491 78.7213 36.4768 78.6784 36.4646C78.0013 36.2864 77.3928 35.9099 76.9311 35.3834C76.8416 35.2769 76.7445 35.177 76.6407 35.0844C76.1189 34.6441 75.5003 34.3335 74.8355 34.178C73.7625 33.8908 72.2095 34.1759 70.2199 35.0262C69.2203 35.4533 68.1271 35.2987 67.2861 35.1163C67.2389 35.1072 67.1902 35.1106 67.1447 35.1262C67.0993 35.1417 67.0587 35.1689 67.027 35.2051C66.6692 35.541 66.1975 35.729 65.7068 35.7314C65.4062 35.7446 65.0908 36.1608 64.7096 36.7035C64.6231 36.8267 64.5222 36.9705 64.4494 37.058L64.4396 36.8899L64.2696 37.0774C64.0023 37.372 63.814 37.7294 63.722 38.1164C63.6301 38.5034 63.6376 38.9074 63.7438 39.2907C63.85 39.6741 64.0515 40.0243 64.3296 40.3088C64.6076 40.5932 64.953 40.8028 65.3338 40.9178C65.6362 40.9982 65.946 41.0476 66.2584 41.0651C66.4476 41.0821 66.6434 41.0997 66.8309 41.1323C67.433 41.2556 67.9927 41.5328 68.4556 41.937C68.9185 42.3413 69.2686 42.8586 69.4719 43.4385C69.52 43.5726 69.5982 43.6938 69.7005 43.7928C69.8029 43.8918 69.9266 43.966 70.0622 44.0096C70.1977 44.0532 70.3415 44.0651 70.4824 44.0443C70.6233 44.0235 70.7575 43.9706 70.8747 43.8898C71.0701 43.7376 71.2994 43.635 71.543 43.5906C71.7867 43.5462 72.0375 43.5614 72.274 43.635C72.4783 43.7486 72.6374 43.929 72.7245 44.146C72.8304 44.3878 73.0059 44.5926 73.2286 44.7342C73.9904 45.1269 74.0429 46.8669 73.7693 48.3173C73.5056 49.7154 72.9843 50.7268 72.5016 50.7768C72.13 50.8153 72.0875 50.8417 72.0606 50.9074L72.0366 50.9662L72.0783 51.0213C72.2835 51.0957 72.5024 51.1249 72.72 51.107Z"
        fill="#2F2E41"
      />
      <path
        d="M67.9195 71.595L65.0617 65.8975C60.9136 66.1808 52.6808 70.9769 52.6808 70.9769L37.1041 74.2795C36.6427 74.1022 36.1426 74.0495 35.6544 74.1268C35.1661 74.2041 34.7068 74.4086 34.3227 74.7199C33.9386 75.0311 33.6433 75.4381 33.4665 75.8997C33.2896 76.3614 33.2375 76.8615 33.3154 77.3497C33.3933 77.8378 33.5984 78.2969 33.9101 78.6806C34.2218 79.0643 34.6291 79.3592 35.091 79.5354C35.5528 79.7117 36.053 79.7632 36.5411 79.6847C37.0292 79.6063 37.488 79.4006 37.8713 79.0884L57.6859 77.6681L67.9195 71.595Z"
        fill="#A0616A"
      />
      <path
        d="M66.7669 72.5305L74.2305 66.9839L75.5452 62.2462C75.7999 61.3152 75.7891 60.3314 75.5141 59.4062C75.2391 58.481 74.7107 57.6511 73.9888 57.0104C73.0043 56.1484 71.7293 55.6924 70.4215 55.7346C69.1137 55.7768 67.8707 56.314 66.9438 57.2377C64.1352 60.0411 60.9317 63.9522 61.6199 66.6642L61.6268 66.6917L66.7669 72.5305Z"
        fill="#F7B80B"
      />
      <path
        d="M12.793 65.2319C12.7842 65.2452 12.7753 65.2585 12.7649 65.2733C12.4694 65.2543 12.173 65.2538 11.8774 65.2718C11.7087 65.2822 11.5401 65.2984 11.3714 65.3206C11.1596 65.3503 10.9616 65.4432 10.8035 65.5872C10.6454 65.7313 10.5344 65.9197 10.4852 66.1279C10.4359 66.336 10.4507 66.5542 10.5275 66.7538C10.6044 66.9535 10.7397 67.1252 10.9158 67.2467L10.9336 67.2585C10.9469 67.2674 10.9572 67.2748 10.9706 67.2836C9.00252 69.0301 6.44729 69.9685 3.8167 69.9109C3.15517 68.3229 2.57745 66.7014 2.08594 65.0529C2.89008 64.2981 3.87474 63.7627 4.9454 63.4981C7.09037 62.9804 9.48386 63.5647 11.6303 63.3517C12.6629 63.2496 13.3803 64.3754 12.793 65.2319Z"
        fill="white"
      />
      <path
        d="M14.6699 48.7497C14.664 48.7659 14.6581 48.7837 14.6507 48.8014C14.4821 48.8118 14.3134 48.8295 14.1448 48.8517C13.933 48.8812 13.7351 48.974 13.577 49.1179C13.4189 49.2618 13.3079 49.4501 13.2587 49.6582C13.2094 49.8662 13.2242 50.0843 13.301 50.2838C13.3778 50.4833 13.5131 50.655 13.6892 50.7763L13.7069 50.7896C13.4279 51.2458 13.1143 51.68 12.7691 52.0884C11.7925 53.224 10.5919 54.1457 9.24255 54.7956C7.89315 55.4454 6.42405 55.8095 4.92734 55.8651C4.82971 55.868 4.73503 55.871 4.63888 55.871C4.38966 58.102 3.8506 60.2911 3.03533 62.3828C2.72607 63.1743 2.37448 63.9486 1.98207 64.7023C1.90811 64.4509 1.83661 64.1979 1.76758 63.9435C1.78533 63.9094 1.8016 63.8769 1.81787 63.8429C2.2087 63.0359 2.55392 62.2077 2.8519 61.3621C3.09597 60.6683 3.30899 59.9656 3.49095 59.2541C4.47418 55.3896 4.52691 51.3471 3.6448 47.4582C2.84689 46.3424 2.41012 45.0089 2.39331 43.6372C2.38618 43.1434 2.45045 42.6511 2.58414 42.1757C2.69805 42.2511 2.81787 42.3222 2.93622 42.3887C2.9732 42.4094 3.01166 42.4301 3.05012 42.4508L3.06343 42.4582C3.19487 42.5294 3.34407 42.5609 3.49304 42.5492C3.64202 42.5375 3.78443 42.4829 3.90309 42.392C4.02176 42.3012 4.11162 42.178 4.16185 42.0372C4.21209 41.8965 4.22055 41.7442 4.18621 41.5988C4.1655 41.5085 4.14332 41.4183 4.12261 41.3281C4.09006 41.189 4.05604 41.05 4.02497 40.9109C4.01966 40.897 4.0157 40.8827 4.01314 40.868C3.97616 40.7097 3.93769 40.5529 3.90071 40.3946C4.19563 40.2145 4.5283 40.1051 4.87261 40.0751C6.05752 39.9804 7.15219 40.973 7.41402 42.1313C7.6803 43.2911 7.23799 44.5248 6.50131 45.4568C5.86965 46.2541 5.03977 46.8651 4.17586 47.4094C4.19509 47.4982 4.21728 47.5855 4.23651 47.6727C4.58169 49.2943 4.76797 50.9456 4.79272 52.6032C5.31935 51.0322 6.45988 49.3858 7.49243 48.4671C8.66846 47.4213 10.2188 46.8103 11.6625 46.1121C13.3948 45.2733 15.3149 46.9346 14.6699 48.7497Z"
        fill="white"
      />
      <path
        d="M94.2189 71.6463C94.466 71.9599 94.7219 72.2721 94.9778 72.5857C94.0963 74.3958 93.1053 76.1505 92.0103 77.8401C91.929 77.7824 91.8476 77.7247 91.7663 77.667C91.7534 77.66 91.741 77.6521 91.7293 77.6434C91.426 77.4274 91.1213 77.2144 90.818 76.9984V76.9969C90.5679 76.1455 90.4281 75.2655 90.4023 74.3786C90.5588 74.3427 90.7003 74.2596 90.8079 74.1405C90.9155 74.0214 90.9838 73.8722 91.0037 73.7129C91.0236 73.5537 90.994 73.3922 90.919 73.2504C90.8439 73.1085 90.7271 72.9932 90.5843 72.92C90.5444 72.8504 90.5162 72.8002 90.4763 72.7292C90.4778 72.7114 90.4807 72.6922 90.4822 72.6744C90.7145 70.8268 93.0695 70.1818 94.2189 71.6463Z"
        fill="white"
      />
      <path
        d="M96.874 65.315C97.0515 65.8032 97.2132 66.2967 97.3592 66.7958C97.2586 67.0916 97.1555 67.3865 97.05 67.6804C97.0101 67.525 96.9686 67.3697 96.9257 67.2144C96.6851 66.3507 96.3962 65.5013 96.0603 64.67C95.7852 63.9881 95.4805 63.3204 95.1461 62.6671C93.3282 59.1177 90.6939 56.0504 87.4598 53.7174C86.1232 53.4082 84.9135 52.6968 83.9938 51.6789C83.662 51.3131 83.3845 50.9017 83.1698 50.457C83.3059 50.4378 83.442 50.4111 83.5751 50.383C83.6166 50.3742 83.6595 50.3638 83.7009 50.3549L83.7172 50.352C83.8627 50.3182 83.9954 50.2431 84.0993 50.1358C84.2032 50.0284 84.2738 49.8933 84.3027 49.7467C84.3316 49.6001 84.3176 49.4483 84.2622 49.3095C84.2069 49.1707 84.1127 49.0509 83.9908 48.9644C83.9154 48.9097 83.8385 48.8564 83.763 48.8032C83.6476 48.7188 83.5308 48.639 83.4139 48.5561C83.4016 48.5484 83.3897 48.54 83.3784 48.531C83.2453 48.4363 83.1121 48.3446 82.9805 48.2484C83.0826 47.9188 83.2593 47.6171 83.4967 47.3668C84.3237 46.5132 85.8 46.5324 86.7645 47.2277C87.7305 47.923 88.2157 49.1404 88.2778 50.3268C88.3325 51.3431 88.1136 52.349 87.8251 53.3298C87.8976 53.383 87.9731 53.4333 88.0456 53.4881C89.3773 54.4751 90.6091 55.5904 91.7231 56.818C91.0796 55.2913 90.8459 53.3002 91.013 51.9289C91.2038 50.3668 91.9627 48.883 92.584 47.4052C93.3281 45.6301 95.8666 45.6064 96.5825 47.3934C96.5899 47.4111 96.5973 47.4274 96.6032 47.4452C96.4834 47.565 96.368 47.6893 96.2571 47.818C96.1178 47.9801 96.0307 48.1806 96.0072 48.3931C95.9838 48.6055 96.025 48.8202 96.1256 49.0088C96.2262 49.1975 96.3813 49.3514 96.5708 49.4503C96.7603 49.5493 96.9753 49.5888 97.1876 49.5635L97.2098 49.5606C97.302 50.0879 97.3544 50.6215 97.3666 51.1567C97.3849 52.6542 97.0938 54.1393 96.5113 55.519C95.9289 56.8986 95.0678 58.1431 93.9819 59.1745C93.9109 59.241 93.8414 59.3061 93.7689 59.3697C94.4542 60.3442 95.0697 61.366 95.6106 62.4274C96.0909 63.363 96.5128 64.3273 96.874 65.315Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1017_48606">
        <rect
          width="100"
          height="100"
          fill="white"
          transform="translate(0 0.71875)"
        />
      </clipPath>
    </defs>
  </svg>
);
