import { createContext, useContext, useEffect, useState } from "react";
import { PageURL } from "../types/pages";

export type SessionContextType = {
  sessionInfo: SessionType;
  setReset: (reset: boolean) => void;
  setNeedHelp: (needHelp: boolean | undefined) => void;
  setHistory: (history: PageURL[]) => void;
  updateHistory: (page: PageURL) => void;
  resetSessionInfo: () => void;
  setBrief: (brief: string) => void;
};

export const SessionContext = createContext({
  sessionInfo: {
    reset: false,
    needHelp: undefined,
    history: [PageURL.HOME],
    brief: "",
  },
  setReset: () => {},
  setNeedHelp: () => {},
  setHistory: () => {},
  updateHistory: () => {},
  resetSessionInfo: () => {},
  setBrief: () => {},
} as SessionContextType);

export function SessionContextProvider({ children }: { children: any }) {
  const [reset, setReset] = useState<boolean>(
    localStorage.getItem("reset") === "true"
  );
  const [needHelp, setNeedHelp] = useState<boolean | undefined>(
    localStorage.getItem("needHelp") === "true"
      ? true
      : localStorage.getItem("needHelp") === "false"
      ? false
      : undefined
  );
  const [history, setHistory] = useState<PageURL[]>(() => {
    try {
      return JSON.parse(localStorage.getItem("history") ?? '["/"]');
    } catch (e) {
      return [PageURL.HOME];
    }
  });
  const [brief, setBrief] = useState<string>(
    localStorage.getItem("brief") ?? ""
  );
  useEffect(() => {
    localStorage.setItem("reset", JSON.stringify(reset));
  }, [reset]);
  useEffect(() => {
    localStorage.setItem("needHelp", JSON.stringify(needHelp));
  }, [needHelp]);
  useEffect(() => {
    localStorage.setItem("history", JSON.stringify(history));
  }, [history]);
  useEffect(() => {
    localStorage.setItem("brief", brief);
  }, [brief]);

  function resetSessionInfo() {
    setNeedHelp(undefined);
    setReset(false);
    setHistory([PageURL.HOME]);
    setBrief("");
  }

  return (
    <SessionContext.Provider
      value={{
        sessionInfo: {
          reset: reset,
          needHelp,
          history: history,
          brief: brief,
        },
        setReset(value: boolean) {
          setReset(value);
        },
        setNeedHelp(value: boolean | undefined) {
          setNeedHelp(value);
        },
        setHistory(history: PageURL[]) {
          setHistory(history);
        },
        updateHistory(page: PageURL) {
          if (history.includes(page)) {
            let copy = [...history];
            while (copy[copy.length - 1] !== page) {
              copy = copy.slice(0, copy.length - 1);
            }
            setHistory(copy);
          } else setHistory([...history, page]);
        },
        resetSessionInfo,
        setBrief(brief: string) {
          setBrief(brief);
        },
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export function useSessionContext() {
  const context = useContext(SessionContext);
  return context;
}

export interface SessionType {
  reset: boolean;
  needHelp: boolean | undefined;
  history: PageURL[];
  brief: string;
}
