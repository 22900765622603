export default function OptionsBesacesPrintIcon() {
  return (
    <svg
      width="90"
      height="100"
      viewBox="0 0 90 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_841_52234)">
        <path
          d="M57.5171 7.73902L45.0171 -4.43204L46.3011 -10.5556C46.8262 -16.08 53.0948 -19.7888 52.3238 -21.464L61.1355 -17.9189L57.5171 7.73902Z"
          fill="#2F2E41"
        />
        <path
          d="M83.0319 61.2745L74.827 47.8919L79.1911 43.7012L86.6689 59.1405C87.333 59.2526 87.9466 59.5663 88.4263 60.0391C88.906 60.5119 89.2286 61.1208 89.3503 61.7833C89.472 62.4457 89.3869 63.1295 89.1066 63.7419C88.8263 64.3543 88.3643 64.8657 87.7834 65.2065C87.2025 65.5473 86.5308 65.7012 85.8595 65.6471C85.1881 65.593 84.5497 65.3337 84.0308 64.9043C83.5119 64.4749 83.1377 63.8963 82.959 63.2469C82.7803 62.5975 82.8057 61.9089 83.0319 61.2745Z"
          fill="#A0616A"
        />
        <path
          d="M57.5852 18.7554L61.2901 15.1397C61.2901 15.1397 64.5299 14.1395 67.5212 19.3098C70.5126 24.4801 77.0781 38.532 77.0781 38.532L79.5406 40.8798L79.8639 41.683L82.0097 43.6803L81.5948 44.6539L84.0993 45.4194L76.7405 52.0232L72.9776 48.0287L72.9623 45.6519L71.3281 44.9556L69.7161 43.6194L69.1363 41.4245L57.5852 18.7554Z"
          fill="#2F2E41"
        />
        <path
          d="M42.8789 29.2852V50.4918L67.55 46.0615L61.3 26.9826L42.8789 29.2852Z"
          fill="#A0616A"
        />
        <path
          d="M67.3855 44.2522L40.7407 47.5416L37.7802 70.568L35.1486 108.397L14.4249 141.292L24.2934 143.594L50.9381 111.357L56.5302 81.4491L58.1749 155.436L66.7276 155.765L75.2802 81.4232C75.2802 81.4232 74.9512 46.5548 67.3855 44.2522Z"
          fill="#2F2E41"
        />
        <path
          d="M55.7078 2.96928L45.1815 0.00875854L40.2473 6.91665L45.0171 47.8706C45.0171 47.8706 62.7802 34.7127 64.7539 46.8838L65.5763 30.9298C65.5763 30.9298 66.4673 20.4035 64.5415 19.5811C62.6157 18.7588 55.3789 8.56139 55.3789 8.56139L55.7078 2.96928Z"
          fill="#F7B80B"
        />
        <path
          d="M37.9447 2.31139L34.1463 6.10982L29.0631 43.4298L41.848 56.2147C41.848 56.2147 55.7078 31.5877 45.8394 11.193L43.999 1.66431L37.9447 2.31139Z"
          fill="#2F2E41"
        />
        <path
          d="M55.3789 7.22632L69.5236 20.4036L74.1289 57.2457L62.9447 54.6141L63.9315 27.3115L54.0631 9.54836L55.3789 7.22632Z"
          fill="#2F2E41"
        />
        <path
          d="M53.9375 87.8377H14.0572C13.0645 87.8366 12.1128 87.4418 11.4109 86.7399C10.709 86.038 10.3142 85.0863 10.3131 84.0937V68.7135C10.3171 65.1498 11.7346 61.7332 14.2545 59.2133C16.7744 56.6934 20.191 55.2759 23.7547 55.2719H39.3511C44.2109 55.2774 48.8702 57.2104 52.3066 60.6469C55.743 64.0833 57.676 68.7425 57.6815 73.6024V84.0937C57.6804 85.0863 57.2856 86.038 56.5837 86.7399C55.8818 87.4418 54.9302 87.8366 53.9375 87.8377Z"
          fill="#F7B80B"
        />
        <path
          d="M28.4555 55.9001L32.0251 37.3696L33.0494 32.0522C33.1689 31.4317 32.218 31.1659 32.0978 31.7898L28.5282 50.3203L27.5039 55.6378C27.3844 56.2583 28.3353 56.5241 28.4555 55.9001Z"
          fill="#F7B80B"
        />
        <path
          d="M52.0882 0.0429344C57.6867 0.0429344 62.2252 -4.49556 62.2252 -10.0941C62.2252 -15.6926 57.6867 -20.2311 52.0882 -20.2311C46.4897 -20.2311 41.9512 -15.6926 41.9512 -10.0941C41.9512 -4.49556 46.4897 0.0429344 52.0882 0.0429344Z"
          fill="#A0616A"
        />
        <path
          d="M64.2375 -9.75769L63.4974 -7.72808L62.3428 -7.9715C62.4387 -8.69399 62.3917 -9.42829 62.2046 -10.1327C62.025 -9.46927 61.6626 -8.86971 61.1586 -8.40242L57.5599 -9.71492C52.0467 -9.7544 50.9151 -1.17216 49.9513 7.73903L39.6882 -0.30374C39.7072 -0.844818 39.6463 -1.38574 39.5072 -1.909C39.4048 -1.52511 39.238 -1.16139 39.0138 -0.833345L37.7803 -1.80045L38.7342 -13.4189C38.8572 -14.916 39.3126 -16.3669 40.0673 -17.6658C40.822 -18.9646 41.8568 -20.0788 43.0966 -20.9271C44.3363 -21.7755 45.7496 -22.3366 47.2336 -22.5696C48.7176 -22.8026 50.2349 -22.7017 51.675 -22.2741L58.0467 -19.3399L60.1487 -19.5636C63.8066 -18.2314 65.5697 -13.4189 64.2375 -9.75769Z"
          fill="#2F2E41"
        />
        <path
          d="M39.8445 55.1074H24.2481C22.3656 55.1068 20.504 55.5026 18.7845 56.269C17.065 57.0354 15.5261 58.1553 14.2681 59.5557C16.7322 57.3256 19.9378 56.0918 23.2613 56.0943H38.8577C43.7176 56.0998 48.3768 58.0328 51.8132 61.4692C55.2496 64.9057 57.1827 69.5649 57.1882 74.4247V84.916C57.1888 85.6677 56.9619 86.402 56.5372 87.0222C57.0416 86.6792 57.4545 86.2181 57.7399 85.679C58.0254 85.1399 58.1748 84.5392 58.175 83.9292V73.4379C58.1695 68.578 56.2365 63.9188 52.8001 60.4824C49.3636 57.0459 44.7044 55.1129 39.8445 55.1074Z"
          fill="#3F3D56"
        />
        <path
          d="M33.1338 6.6534C34.1447 5.77893 35.767 6.15851 36.683 6.9886C37.8301 8.02818 38.3055 9.57868 38.6858 11.0298C39.5702 14.5547 40.0651 18.166 40.1617 21.7989C40.2968 25.445 40.154 29.0945 39.9525 32.7354C39.744 36.5033 39.5056 40.2697 39.2798 44.0367L38.5063 56.9437C38.4682 57.578 39.4552 57.5758 39.4931 56.9437C39.9705 48.9785 40.4741 41.0144 40.9219 33.0475C41.3465 25.4937 41.5694 17.7275 39.5294 10.3655C39.0699 8.70724 38.3862 6.95203 36.9226 5.92762C35.594 4.99776 33.7033 4.85933 32.436 5.95558C31.9548 6.37177 32.6554 7.06722 33.1338 6.6534Z"
          fill="#F7B80B"
        />
        <path
          d="M52.2533 24.1202L38.2065 34.2417L40.8619 40.3638L55.0449 27.8401C55.7683 28.0089 56.5262 27.951 57.2155 27.6743C57.9049 27.3976 58.4924 26.9154 58.8984 26.2933C59.3043 25.6711 59.5089 24.9392 59.4846 24.1967C59.4602 23.4543 59.2079 22.7373 58.762 22.1432C58.3162 21.549 57.6982 21.1064 56.9922 20.8756C56.2861 20.6447 55.5261 20.6366 54.8154 20.8525C54.1046 21.0684 53.4774 21.4978 53.0191 22.0824C52.5607 22.667 52.2934 23.3784 52.2533 24.1202Z"
          fill="#A0616A"
        />
        <path
          d="M33.8045 6.36856C33.8045 6.36856 37.4934 6.98336 37.8878 13.5597C38.2821 20.1361 38.1965 32.5529 38.1965 32.5529L41.6924 29.8357L45.2036 28.8384L47.694 35.0441L45.7334 38.3236L43.4772 39.4125L42.6607 38.9168L41.3872 40.6731C39.5338 41.636 37.3807 41.8476 35.3753 41.264C33.37 40.6804 31.6667 39.3464 30.6195 37.5393L29.3523 36.0452L28.4231 33.931L28.9361 31.4802L28.5041 13.8108C28.4638 12.164 28.9587 10.5484 29.9143 9.20656C30.87 7.86471 32.235 6.86892 33.8045 6.36856Z"
          fill="#2F2E41"
        />
        <g clipPath="url(#clip1_841_52234)">
          <path
            d="M38.1823 81.7509L29.7492 81.5158C29.7492 81.5158 30.3839 77.7901 28.3473 76.7561C26.9592 76.0509 25.7272 72.7567 26.0776 70.5152C26.0824 70.4854 26.0872 70.4556 26.0925 70.4263C26.2702 69.4228 26.778 68.6456 27.7489 68.4345C30.8876 67.7526 33.1274 68.2813 33.1274 68.2813C33.1274 68.2813 33.646 68.2877 34.4143 68.4292C34.4417 68.4339 34.4691 68.4392 34.4965 68.445C36.6714 68.8632 40.6916 70.3521 40.8657 75.6386C41.1053 82.9018 38.1823 81.7497 38.1823 81.7497V81.7509Z"
            fill="#3F3D56"
          />
          <path
            d="M34.4142 68.4298C34.3999 68.8088 34.3123 69.1854 34.1525 69.5433C33.8211 70.2854 33.2168 70.852 32.4515 71.1392C30.1592 72 27.4842 71.1462 26.1312 70.2205C26.1258 70.2497 26.0186 70.8047 26.0138 70.8345C26.6128 71.2368 29.5447 71.6246 30.1007 71.6246C30.9453 71.6246 31.7583 71.4871 32.4813 71.2152C33.2674 70.9199 33.8879 70.3374 34.2282 69.5754C34.3909 69.2117 34.4803 68.8298 34.4964 68.445C34.469 68.4392 34.4416 68.4339 34.4142 68.4292V68.4298Z"
            fill="#F7B80B"
          />
          <path
            d="M35.0377 68.9122L33.6936 65.5958C33.6936 65.5958 35.3071 62.8824 34.0393 62.5812C32.7716 62.2794 31.4657 63.8999 31.4657 63.8999C31.4657 63.8999 29.0458 63.0707 28.1244 63.749C28.1244 63.749 26.5878 61.6385 25.6663 62.0531C24.7443 62.4677 25.9942 65.4555 25.9942 65.4555C25.9942 65.4555 24.0916 68.1958 25.5513 69.7783C27.011 71.3613 32.5034 72.6426 35.0383 68.9116L35.0377 68.9122Z"
            fill="#3F3D56"
          />
          <path
            d="M37.9206 79.7661C38.3784 79.9661 38.8564 80.1696 39.357 80.1503C39.8577 80.131 40.384 79.8146 40.4621 79.3293C40.5026 79.0784 40.4227 78.81 40.5205 78.5749C40.6522 78.2591 41.0766 78.1369 41.4062 78.245C41.7364 78.3532 41.9784 78.6328 42.1524 78.9281C42.4778 79.4813 42.6078 80.2076 42.2591 80.7468C41.9569 81.214 41.3835 81.4257 40.8507 81.607C40.1402 81.8485 39.3475 82.0877 38.6591 81.7912C37.9671 81.493 37.5898 80.6316 37.8461 79.9339"
            fill="#3F3D56"
          />
          <path
            d="M30.3487 67.4216C30.3487 67.4216 29.5214 67.0971 28.9844 67.5029L29.6048 68.4363L30.3493 67.4222L30.3487 67.4216Z"
            fill="#F7B80B"
          />
          <path
            d="M26.0067 71.6813L27.5367 79.6743C27.5367 79.6743 25.2212 81.9871 28.1983 81.9462C31.1755 81.9058 30.307 80.4047 30.307 80.4047L29.3558 73.4667"
            fill="#3F3D56"
          />
          <path
            d="M28.1232 82.0053C27.3168 82.0053 26.8292 81.824 26.6731 81.4655C26.3888 80.8129 27.3144 79.8181 27.473 79.6544L26.4442 73.6789L26.5616 73.6596L27.6005 79.6936L27.579 79.7146C27.5683 79.7251 26.5098 80.7936 26.7828 81.4193C26.9228 81.7409 27.3979 81.8982 28.1977 81.8871C29.3093 81.8719 30.0257 81.6433 30.2701 81.2263C30.49 80.8509 30.2576 80.4368 30.2552 80.4327L30.2498 80.4228L30.2481 80.4117L29.5865 75.5836L29.7045 75.5678L30.3649 80.3854C30.4042 80.4585 30.6098 80.8801 30.3744 81.2836C30.1044 81.7456 29.3725 81.9883 28.1995 82.0041C28.1739 82.0041 28.1488 82.0041 28.1238 82.0041L28.1232 82.0053Z"
            fill="#2F2E41"
          />
          <path
            d="M29.8934 71.6813L31.4234 79.6743C31.4234 79.6743 29.1079 81.9871 32.085 81.9462C35.0622 81.9058 34.1938 80.4047 34.1938 80.4047L33.2425 73.4667"
            fill="#3F3D56"
          />
          <path
            d="M32.0098 82.0053C31.2034 82.0053 30.7159 81.824 30.5597 81.4655C30.2754 80.814 31.1992 79.8205 31.359 79.655L30.7445 76.4386L30.8619 76.417L31.4877 79.693L31.4657 79.7146C31.4549 79.7251 30.3964 80.7936 30.6694 81.4193C30.8094 81.7409 31.2851 81.8988 32.0843 81.8871C33.1965 81.8719 33.9135 81.6433 34.1573 81.2251C34.3773 80.8485 34.1442 80.4368 34.1418 80.4327L34.1359 80.4228L33.1834 73.4737L33.3014 73.4579L34.2509 80.3854C34.2902 80.4585 34.4959 80.8801 34.2604 81.2836C33.9904 81.7456 33.2585 81.9883 32.0855 82.0041C32.0599 82.0041 32.0349 82.0041 32.0098 82.0041V82.0053Z"
            fill="#2F2E41"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_841_52234">
          <rect
            width="89.8026"
            height="100"
            fill="white"
            transform="translate(0.0986328)"
          />
        </clipPath>
        <clipPath id="clip1_841_52234">
          <rect
            width="18"
            height="20"
            fill="white"
            transform="translate(25 62)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
