import { SVGProps } from "react";

export default function AvatarFemmeIcon1(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="225"
      height="245"
      viewBox="0 0 359 370"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M179.36 369.441C278.418 369.441 358.72 289.139 358.72 190.081C358.72 91.0235 278.418 10.7213 179.36 10.7213C80.3022 10.7213 0 91.0235 0 190.081C0 289.139 80.3022 369.441 179.36 369.441Z"
        fill="#FEC103"
      />
      <path
        d="M179.36 351.671C268.604 351.671 340.95 279.325 340.95 190.081C340.95 100.838 268.604 28.4913 179.36 28.4913C90.1163 28.4913 17.77 100.838 17.77 190.081C17.77 279.325 90.1163 351.671 179.36 351.671Z"
        fill="#F7B801"
      />
      <mask
        id="mask0_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="17"
        y="28"
        width="324"
        height="324"
      >
        <path
          d="M179.36 351.671C268.604 351.671 340.95 279.325 340.95 190.081C340.95 100.838 268.604 28.4913 179.36 28.4913C90.1163 28.4913 17.77 100.838 17.77 190.081C17.77 279.325 90.1163 351.671 179.36 351.671Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_946_55801)">
        <path
          d="M107.62 262.041C107.62 262.041 83.42 228.221 115.86 212.001C115.86 212.001 114.83 180.951 131.05 180.051L148.04 119.021H247.24L258.99 180.051C258.99 180.051 266.2 195.371 264.4 206.181C262.6 216.991 288.73 235.011 264.4 262.041C240.06 289.071 107.62 262.041 107.62 262.041Z"
          fill="url(#paint0_linear_946_55801)"
        />
      </g>
      <mask
        id="mask1_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="81"
        y="44"
        width="201"
        height="264"
      >
        <path
          d="M277.361 305.831C293.391 293.995 263.413 226.198 210.402 154.401C157.391 82.6045 101.423 33.9966 85.3925 45.8323C69.3625 57.668 99.3413 125.465 152.352 197.262C205.363 269.058 261.331 317.666 277.361 305.831Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_946_55801)">
        <path
          d="M156.586 159.243C161.833 155.369 162.292 147.088 157.61 140.748C152.929 134.407 144.88 132.408 139.633 136.283C134.386 140.157 133.927 148.438 138.608 154.778C143.29 161.118 151.338 163.117 156.586 159.243Z"
          fill="#F0B4AA"
        />
      </g>
      <mask
        id="mask2_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="17"
        y="28"
        width="324"
        height="324"
      >
        <path
          d="M179.36 351.671C268.604 351.671 340.95 279.325 340.95 190.081C340.95 100.838 268.604 28.4913 179.36 28.4913C90.1163 28.4913 17.77 100.838 17.77 190.081C17.77 279.325 90.1163 351.671 179.36 351.671Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_946_55801)">
        <path
          d="M279.52 430.601C282.43 430.171 285.1 429.091 287.37 427.531C293.06 423.621 296.35 416.691 295.26 409.401L281.14 311.001L236.79 320.991L258.52 416.011C260.56 425.591 269.71 432.051 279.52 430.601Z"
          fill="#F3CFC3"
        />
      </g>
      <mask
        id="mask3_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="17"
        y="28"
        width="324"
        height="324"
      >
        <path
          d="M179.36 351.671C268.604 351.671 340.95 279.325 340.95 190.081C340.95 100.838 268.604 28.4913 179.36 28.4913C90.1163 28.4913 17.77 100.838 17.77 190.081C17.77 279.325 90.1163 351.671 179.36 351.671Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_946_55801)">
        <path
          d="M299.66 394.381L337.18 368.701L268.99 249.301C268.73 248.821 268.46 248.341 268.18 247.871C260.85 236.111 245.38 232.521 233.62 239.851C221.87 247.171 218.28 262.641 225.6 274.401C225.99 275.021 226.41 275.621 226.84 276.201L299.66 394.381Z"
          fill="#1A2F43"
        />
      </g>
      <mask
        id="mask4_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="17"
        y="28"
        width="324"
        height="324"
      >
        <path
          d="M179.36 351.671C268.604 351.671 340.95 279.325 340.95 190.081C340.95 100.838 268.604 28.4913 179.36 28.4913C90.1163 28.4913 17.77 100.838 17.77 190.081C17.77 279.325 90.1163 351.671 179.36 351.671Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_946_55801)">
        <path
          d="M249.31 465.951H105.97L86.42 259.601C84.9 251.081 89.08 242.581 96.72 238.501C150.92 209.521 205.11 209.471 259.31 238.351C267.09 242.491 271.26 251.221 269.53 259.861L249.31 465.951Z"
          fill="url(#paint1_linear_946_55801)"
        />
      </g>
      <mask
        id="mask5_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="17"
        y="28"
        width="324"
        height="324"
      >
        <path
          d="M179.36 351.671C268.604 351.671 340.95 279.325 340.95 190.081C340.95 100.838 268.604 28.4913 179.36 28.4913C90.1163 28.4913 17.77 100.838 17.77 190.081C17.77 279.325 90.1163 351.671 179.36 351.671Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_946_55801)">
        <path
          d="M206.42 220.191L184.19 267.451L158.84 219.861L162.18 174.731V174.711L162.75 166.941C162.75 166.891 162.75 166.871 162.75 166.871L162.77 166.821L164.03 157.511L166.05 142.661L172.46 145.751L178.48 148.631L198.66 158.341L204.14 202.061L206.42 220.191Z"
          fill="#F3CFC3"
        />
      </g>
      <mask
        id="mask6_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="17"
        y="28"
        width="324"
        height="324"
      >
        <path
          d="M179.36 351.671C268.604 351.671 340.95 279.325 340.95 190.081C340.95 100.838 268.604 28.4913 179.36 28.4913C90.1163 28.4913 17.77 100.838 17.77 190.081C17.77 279.325 90.1163 351.671 179.36 351.671Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask6_946_55801)">
        <path
          d="M162.18 174.701V174.721C166.43 221.001 206.42 220.191 206.42 220.191L204.14 202.051L198.66 158.331L178.48 148.621L172.46 145.741C168.85 148.671 165.87 152.521 164.03 157.511C162.3 162.161 161.55 167.821 162.18 174.701Z"
          fill="#F0B4AA"
        />
      </g>
      <mask
        id="mask7_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="60"
        y="40"
        width="235"
        height="279"
      >
        <path
          d="M244.221 229.035C297.124 157.168 309.933 76.7659 272.83 49.4535C235.727 22.1411 162.762 58.2604 109.858 130.128C56.9548 201.996 44.1459 282.398 81.2489 309.71C118.352 337.023 191.317 300.903 244.221 229.035Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask7_946_55801)">
        <path
          d="M254.601 155.024C259.273 148.678 258.802 140.398 253.55 136.532C248.297 132.665 240.252 134.676 235.58 141.022C230.908 147.369 231.379 155.648 236.631 159.515C241.884 163.381 249.929 161.371 254.601 155.024Z"
          fill="#F0B4AA"
        />
      </g>
      <mask
        id="mask8_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="17"
        y="28"
        width="324"
        height="324"
      >
        <path
          d="M179.36 351.671C268.604 351.671 340.95 279.325 340.95 190.081C340.95 100.838 268.604 28.4913 179.36 28.4913C90.1163 28.4913 17.77 100.838 17.77 190.081C17.77 279.325 90.1163 351.671 179.36 351.671Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask8_946_55801)">
        <path
          d="M249.07 141.981C248.99 155.821 245.72 169.141 240.72 180.301C232.79 197.991 215.84 210.901 205.28 211.041C192.53 211.191 169.14 206.541 152.76 154.401C168.84 153.841 170.35 130.501 170.35 130.501C196.48 126.901 200.98 109.781 200.98 109.781C225.33 122.891 241.03 126.051 248.37 126.751C248.88 131.991 249.11 137.081 249.07 141.981Z"
          fill="#F3CFC3"
        />
      </g>
      <mask
        id="mask9_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="21"
        y="29"
        width="324"
        height="324"
      >
        <path
          d="M197.073 352.224C285.978 344.495 351.784 266.158 344.055 177.254C336.327 88.3494 257.99 22.5432 169.086 30.2717C80.1812 38.0002 14.375 116.337 22.1035 205.241C29.832 294.146 108.169 359.952 197.073 352.224Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask9_946_55801)">
        <path
          d="M195.299 152.034C196.9 151.894 198.02 149.734 197.8 147.209C197.581 144.684 196.105 142.749 194.504 142.889C192.903 143.028 191.783 145.188 192.002 147.713C192.222 150.239 193.698 152.173 195.299 152.034Z"
          fill="#01222D"
        />
      </g>
      <mask
        id="mask10_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="17"
        y="28"
        width="324"
        height="324"
      >
        <path
          d="M179.36 351.671C268.604 351.671 340.95 279.325 340.95 190.081C340.95 100.838 268.604 28.4913 179.36 28.4913C90.1163 28.4913 17.77 100.838 17.77 190.081C17.77 279.325 90.1163 351.671 179.36 351.671Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask10_946_55801)">
        <path
          d="M207.17 143.431C209.8 150.921 211.81 158.731 215.68 165.681C216.19 166.591 216.73 167.721 216.25 168.841C214.3 172.281 205.75 168.481 203.06 167.131L207.92 168.041C209.99 168.291 212.68 169.021 214.52 168.221C214.78 167.961 214.51 167.501 214.35 167.101C210.24 159.861 208.41 151.571 207.17 143.431Z"
          fill="#E5A69E"
        />
      </g>
      <mask
        id="mask11_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="21"
        y="32"
        width="324"
        height="324"
      >
        <path
          d="M197.275 354.964C286.18 347.236 351.986 268.899 344.258 179.995C336.529 91.0901 258.193 25.2839 169.288 33.0124C80.3834 40.7409 14.5772 119.077 22.3057 207.982C30.0342 296.887 108.371 362.693 197.275 354.964Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask11_946_55801)">
        <path
          d="M226.977 151.075C228.578 150.935 229.698 148.775 229.479 146.25C229.259 143.725 227.783 141.79 226.182 141.929C224.581 142.069 223.461 144.229 223.681 146.754C223.9 149.279 225.376 151.214 226.977 151.075Z"
          fill="#01222D"
        />
      </g>
      <mask
        id="mask12_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="17"
        y="28"
        width="324"
        height="324"
      >
        <path
          d="M179.36 351.671C268.604 351.671 340.95 279.325 340.95 190.081C340.95 100.838 268.604 28.4913 179.36 28.4913C90.1163 28.4913 17.77 100.838 17.77 190.081C17.77 279.325 90.1163 351.671 179.36 351.671Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask12_946_55801)">
        <path
          d="M244.73 133.581C244.56 134.141 240.52 131.351 235 129.731C229.48 128.111 224.57 128.281 224.73 127.721C224.89 127.161 230.63 124.191 236.15 125.811C241.67 127.431 244.89 133.021 244.73 133.581Z"
          fill="#01222D"
        />
      </g>
      <mask
        id="mask13_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="17"
        y="28"
        width="324"
        height="324"
      >
        <path
          d="M179.36 351.671C268.604 351.671 340.95 279.325 340.95 190.081C340.95 100.838 268.604 28.4913 179.36 28.4913C90.1163 28.4913 17.77 100.838 17.77 190.081C17.77 279.325 90.1163 351.671 179.36 351.671Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask13_946_55801)">
        <path
          d="M192.27 174.561C192.27 174.561 195.67 189.101 213.34 183.481L192.27 174.561Z"
          fill="white"
        />
      </g>
      <mask
        id="mask14_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="17"
        y="28"
        width="324"
        height="324"
      >
        <path
          d="M179.36 351.671C268.604 351.671 340.95 279.325 340.95 190.081C340.95 100.838 268.604 28.4913 179.36 28.4913C90.1163 28.4913 17.77 100.838 17.77 190.081C17.77 279.325 90.1163 351.671 179.36 351.671Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask14_946_55801)">
        <path
          d="M56.12 370.561L96.72 375.401L133.19 267.211C133.38 266.701 133.56 266.181 133.72 265.651C137.66 252.371 130.1 238.401 116.82 234.461C103.55 230.521 89.58 238.081 85.64 251.361C85.43 252.061 85.26 252.771 85.11 253.481L56.12 370.561Z"
          fill="#1A2F43"
        />
      </g>
      <mask
        id="mask15_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="17"
        y="28"
        width="324"
        height="324"
      >
        <path
          d="M179.36 351.671C268.604 351.671 340.95 279.325 340.95 190.081C340.95 100.838 268.604 28.4913 179.36 28.4913C90.1163 28.4913 17.77 100.838 17.77 190.081C17.77 279.325 90.1163 351.671 179.36 351.671Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask15_946_55801)">
        <path
          d="M248.36 126.751C241.02 126.051 225.32 122.881 200.97 109.781C200.97 109.781 196.47 126.901 170.34 130.501C170.34 130.501 168.84 153.841 152.75 154.401C151.88 151.621 151.03 148.701 150.23 145.631C150.12 145.151 149.99 144.641 149.85 144.131C149.2 141.581 148.45 138.951 147.78 136.201C147.78 136.201 137.61 87.9513 192.31 80.9413C192.31 80.9413 242.94 76.1613 248.21 125.291C248.26 125.781 248.32 126.261 248.36 126.751Z"
          fill="#5B332E"
        />
      </g>
      <mask
        id="mask16_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="17"
        y="28"
        width="324"
        height="324"
      >
        <path
          d="M179.36 351.671C268.604 351.671 340.95 279.325 340.95 190.081C340.95 100.838 268.604 28.4913 179.36 28.4913C90.1163 28.4913 17.77 100.838 17.77 190.081C17.77 279.325 90.1163 351.671 179.36 351.671Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask16_946_55801)">
        <path
          d="M205.79 127.791C205.85 128.381 201.05 127.291 195.33 127.851C189.6 128.411 185.11 130.401 185.05 129.811C184.99 129.221 189.2 124.331 194.93 123.771C200.65 123.221 205.73 127.201 205.79 127.791Z"
          fill="#01222D"
        />
      </g>
      <mask
        id="mask17_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="17"
        y="28"
        width="324"
        height="324"
      >
        <path
          d="M179.36 351.671C268.604 351.671 340.95 279.325 340.95 190.081C340.95 100.838 268.604 28.4913 179.36 28.4913C90.1163 28.4913 17.77 100.838 17.77 190.081C17.77 279.325 90.1163 351.671 179.36 351.671Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask17_946_55801)">
        <path
          d="M192.31 80.9413C192.31 80.9413 231.95 62.9213 254.48 82.7413C277 102.561 267.99 129.591 248.17 126.891C228.35 124.191 192.31 80.9413 192.31 80.9413Z"
          fill="#5B332E"
        />
      </g>
      <mask
        id="mask18_946_55801"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="17"
        y="28"
        width="324"
        height="324"
      >
        <path
          d="M179.36 351.671C268.604 351.671 340.95 279.325 340.95 190.081C340.95 100.838 268.604 28.4913 179.36 28.4913C90.1163 28.4913 17.77 100.838 17.77 190.081C17.77 279.325 90.1163 351.671 179.36 351.671Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask18_946_55801)">
        <path
          d="M86.76 327.641C86.76 327.641 95.95 310.731 89.61 216.381C89.58 215.871 89.54 215.351 89.51 214.831C89.35 212.521 89.18 210.161 89.01 207.761C89.49 200.651 84.1 194.491 76.98 194.011C69.87 193.531 63.71 198.921 63.23 206.031L63.05 207.551L62.84 209.361L49.69 323.401C49.6 323.971 49.52 324.551 49.49 325.141C48.8 335.431 56.59 344.331 66.88 345.021C77.16 345.721 86.07 337.931 86.76 327.641Z"
          fill="#F3CFC3"
        />
        <path
          d="M63.49 175.371L97.26 180.121C97.26 180.121 96.96 198.091 88.18 210.351L84.27 216.511L66.41 215.151L63.49 203.471V175.371Z"
          fill="#F3CFC3"
        />
        <path
          d="M90.11 205.061L112.77 174.971C112.77 174.971 107.67 169.901 102.3 176.201C100.58 178.221 98.02 179.901 95.35 181.261C88.83 184.561 85.72 192.131 88.07 199.041L90.11 205.061Z"
          fill="#F3CFC3"
        />
        <path
          d="M63.49 178.951L64.45 147.541C64.47 146.961 65 146.541 65.57 146.641C66.78 146.841 68.58 147.571 68.82 150.201C69.18 154.181 73.62 182.311 73.62 182.311L63.49 178.951Z"
          fill="#F3CFC3"
        />
        <path
          d="M82.2 182.481L71.76 179.951L70.55 139.271C70.54 138.821 71.01 138.521 71.41 138.721C74.85 140.461 77.21 143.801 77.7 147.621L82.2 182.481Z"
          fill="#F3CFC3"
        />
        <path
          d="M89.86 179.661L79.54 182.311L80.14 135.981C80.15 135.531 80.63 135.251 81.03 135.471C84.39 137.361 86.6 140.801 86.92 144.651L89.86 179.661Z"
          fill="#F3CFC3"
        />
        <path
          d="M97.48 184.541L87.28 181.151L89.44 140.511C89.46 140.061 89.96 139.801 90.34 140.041C93.63 142.061 95.7 145.581 95.87 149.431L97.48 184.541Z"
          fill="#F3CFC3"
        />
        <path
          d="M88.47 213.591L63.09 211.061C62.23 210.971 61.5999 210.211 61.6899 209.351L62.11 205.161C62.2 204.301 62.96 203.671 63.82 203.761L89.2 206.291C90.06 206.381 90.69 207.141 90.6 208.001L90.18 212.191C90.1 213.051 89.33 213.681 88.47 213.591Z"
          fill="#142A3C"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_946_55801"
          x1="186.881"
          y1="102.762"
          x2="186.881"
          y2="266.969"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.4903" stopColor="#5B332E" />
          <stop offset="0.8007" stopColor="#B6332E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_946_55801"
          x1="178.014"
          y1="465.95"
          x2="178.014"
          y2="216.725"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.451" stopColor="#1A6177" />
          <stop offset="0.5902" stopColor="#1A475C" />
          <stop offset="0.7241" stopColor="#1A354A" />
          <stop offset="0.8182" stopColor="#1A2F43" />
        </linearGradient>
      </defs>
    </svg>
  );
}
