export const AnimationRollerIcon = () => (<svg width="186" height="121" viewBox="0 0 186 121" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_942_6341)">
      <path d="M167.926 114.067C167.926 114.178 167.853 114.268 167.763 114.268H17.9258V113.867H167.763C167.853 113.867 167.926 113.957 167.926 114.067Z" fill="#E2E8F0"/>
      <path d="M114.416 86.2782C115.601 86.2782 116.561 85.3179 116.561 84.1333C116.561 82.9486 115.601 81.9883 114.416 81.9883C113.232 81.9883 112.271 82.9486 112.271 84.1333C112.271 85.3179 113.232 86.2782 114.416 86.2782Z" fill="#2D3748"/>
      <path d="M130.623 104.868C131.808 104.868 132.768 103.908 132.768 102.723C132.768 101.538 131.808 100.578 130.623 100.578C129.439 100.578 128.479 101.538 128.479 102.723C128.479 103.908 129.439 104.868 130.623 104.868Z" fill="#2D3748"/>
      <path d="M108.577 75.4327L127.167 99.5042C127.167 99.5042 130.981 106.654 136.939 104.509C142.897 102.364 133.841 95.9292 133.841 95.9292L115.727 71.8577C115.727 71.8577 113.344 65.389 108.577 66.7167C103.811 68.0444 108.577 75.4327 108.577 75.4327Z" fill="#F7B80B"/>
      <path d="M140.001 92.3447L136.86 94.007L128.953 82.6839L133.589 80.2305L140.001 92.3447Z" fill="#A0616A"/>
      <path d="M129.203 97.9688C129.192 98.1853 129.422 98.7442 129.523 98.9357C129.835 99.5244 130.565 99.749 131.153 99.4374L140.879 94.2887C141.281 94.0761 141.434 93.5782 141.222 93.1765L141.007 92.7716C141.007 92.7716 140.844 91.2999 139.06 90.3242C139.06 90.3242 138.45 92.1505 135.637 91.2847L134.574 90.5891L132.167 95.5399L130.132 96.9601C129.687 97.2708 129.229 97.4266 129.203 97.9688L129.203 97.9688Z" fill="#1A202C"/>
      <path d="M124.509 72.0869L121.369 73.7492L113.462 62.4261L118.097 59.9727L124.509 72.0869Z" fill="#A0616A"/>
      <path d="M113.711 77.711C113.701 77.9275 113.93 78.4864 114.032 78.6779C114.343 79.2666 115.073 79.4912 115.662 79.1796L125.388 74.0309C125.79 73.8183 125.943 73.3203 125.73 72.9187L125.516 72.5138C125.516 72.5138 125.353 71.0421 123.568 70.0664C123.568 70.0664 122.959 71.8927 120.145 71.0269L119.083 70.3313L116.676 75.2821L114.641 76.7023C114.196 77.013 113.738 77.1688 113.711 77.711L113.711 77.711Z" fill="#1A202C"/>
      <path d="M168.971 92.2845C168.77 91.9452 167.899 92.3085 167.739 92.0005C167.58 91.6937 168.389 91.2262 168.851 90.1583C168.934 89.9657 169.459 88.7521 169.07 88.3776C168.334 87.6677 164.962 90.5971 163.782 89.6384C163.523 89.4281 163.342 89.0081 162.558 88.3262C162.246 88.055 162.058 87.9325 161.875 87.9781C161.616 88.0427 161.596 88.3892 161.318 89.0706C160.902 90.0924 160.655 90.0307 160.413 90.8459C160.233 91.4511 160.274 91.8029 160.062 91.8715C159.762 91.9685 159.486 91.3266 159.106 91.384C158.718 91.4427 158.484 92.1901 158.41 92.7497C158.271 93.7998 158.656 94.4927 158.87 95.2213C159.102 96.0131 159.24 97.2173 158.704 98.922L153.395 113.881C154.567 110.812 157.961 102.397 159.387 99.2702C159.798 98.3675 160.253 97.4519 161.186 97.0906C162.085 96.7427 163.319 96.9504 164.892 96.5714C165.076 96.5271 165.586 96.3974 165.65 96.0974C165.703 95.8495 165.403 95.7111 165.45 95.4789C165.511 95.1674 166.101 95.1635 166.826 94.8041C167.337 94.5507 167.655 94.2493 167.929 93.9902C168.012 93.912 169.239 92.7362 168.971 92.2845H168.971Z" fill="#EDF2F7"/>
      <path d="M148.083 100.16C147.858 100.161 147.785 100.693 147.588 100.683C147.392 100.673 147.395 100.141 147.005 99.6059C146.934 99.5094 146.49 98.9015 146.194 98.9843C145.633 99.1411 146.098 101.641 145.287 101.944C145.109 102.01 144.851 101.978 144.29 102.166C144.067 102.241 143.952 102.298 143.922 102.4C143.879 102.546 144.043 102.656 144.297 102.989C144.679 103.488 144.577 103.591 144.907 103.946C145.153 104.209 145.337 104.29 145.31 104.414C145.271 104.589 144.876 104.539 144.795 104.742C144.711 104.95 145.011 105.28 145.264 105.478C145.739 105.849 146.191 105.86 146.61 105.965C147.066 106.08 147.696 106.36 148.378 107.115L154.188 114.037C153.02 112.576 149.869 108.482 148.746 106.88C148.422 106.417 148.104 105.93 148.196 105.368C148.284 104.826 148.742 104.281 149.01 103.399C149.041 103.296 149.125 103.008 148.996 102.89C148.89 102.793 148.735 102.9 148.635 102.81C148.5 102.69 148.668 102.4 148.701 101.94C148.724 101.616 148.668 101.373 148.619 101.164C148.605 101.101 148.382 100.159 148.083 100.16V100.16Z" fill="#EDF2F7"/>
      <path d="M152.563 101.166L152.428 99.9581L152.49 99.8666C152.776 99.4431 152.921 99.0296 152.923 98.6371C152.923 98.5747 152.921 98.5123 152.918 98.4487C152.906 98.1967 152.891 97.8835 153.055 97.5184C153.146 97.3149 153.404 96.8436 153.79 96.9019C153.894 96.9165 153.973 96.9643 154.033 97.0203C154.042 97.0063 154.051 96.9923 154.06 96.9772C154.181 96.7934 154.276 96.7147 154.368 96.6389C154.438 96.5805 154.511 96.5205 154.626 96.3782C154.676 96.3158 154.715 96.2609 154.748 96.2149C154.848 96.0755 154.979 95.9092 155.209 95.9121C155.455 95.9238 155.584 96.1274 155.669 96.2621C155.822 96.5024 155.891 96.6756 155.937 96.7905C155.953 96.8325 155.972 96.8797 155.982 96.8943C156.061 97.011 156.706 96.9019 156.949 96.8623C157.495 96.7713 157.967 96.6925 158.161 97.0366C158.301 97.2828 158.197 97.6082 157.844 98.0299C157.734 98.1611 157.617 98.2609 157.514 98.339C157.6 98.3904 157.677 98.4691 157.708 98.5927C157.781 98.8855 157.53 99.1789 156.96 99.4659C156.819 99.5376 156.628 99.6333 156.361 99.6793C156.236 99.7009 156.123 99.7062 156.026 99.7091C156.024 99.7651 156.011 99.8257 155.976 99.8881C155.876 100.072 155.668 100.159 155.354 100.138C155.009 100.12 154.725 100.054 154.474 99.9966C154.255 99.9465 154.066 99.9039 153.918 99.9138C153.644 99.936 153.432 100.156 153.183 100.441L152.563 101.166Z" fill="#EDF2F7"/>
      <path d="M153.192 93.9741L152.057 94.4114L151.953 94.3748C151.471 94.2055 151.034 94.1694 150.654 94.2672C150.593 94.2828 150.534 94.3014 150.473 94.3204C150.232 94.3956 149.933 94.4891 149.538 94.4238C149.318 94.3868 148.797 94.2576 148.756 93.8687C148.743 93.7643 148.77 93.676 148.809 93.604C148.793 93.5991 148.777 93.5939 148.76 93.5884C148.552 93.5185 148.451 93.4463 148.355 93.3766C148.28 93.3232 148.204 93.2679 148.037 93.1934C147.964 93.1607 147.901 93.1368 147.848 93.1166C147.688 93.0552 147.494 92.971 147.438 92.7474C147.387 92.5064 147.551 92.3301 147.66 92.2132C147.854 92.0048 148.004 91.894 148.103 91.8205C148.14 91.7938 148.181 91.7635 148.192 91.7505C148.285 91.6442 148.016 91.0481 147.916 90.8232C147.69 90.3185 147.494 89.8818 147.777 89.6064C147.98 89.4091 148.321 89.4267 148.818 89.661C148.973 89.7341 149.099 89.8219 149.201 89.9025C149.229 89.8063 149.286 89.7118 149.397 89.6503C149.662 89.505 150.01 89.674 150.432 90.1521C150.537 90.2707 150.678 90.4307 150.79 90.6771C150.843 90.7932 150.877 90.9013 150.904 90.9942C150.959 90.982 151.021 90.9793 151.09 90.9965C151.293 91.0466 151.43 91.2258 151.489 91.5347C151.56 91.8733 151.568 92.165 151.576 92.4223C151.583 92.6468 151.589 92.8401 151.637 92.9809C151.728 93.241 151.994 93.39 152.333 93.558L153.192 93.9741L153.192 93.9741Z" fill="#EDF2F7"/>
      <path d="M152.563 90.416L152.428 89.2081L152.49 89.1166C152.776 88.6931 152.921 88.2796 152.923 87.8871C152.923 87.8247 152.921 87.7623 152.918 87.6987C152.906 87.4467 152.891 87.1335 153.055 86.7684C153.146 86.5649 153.404 86.0936 153.79 86.1519C153.894 86.1665 153.973 86.2143 154.033 86.2703C154.042 86.2563 154.051 86.2423 154.06 86.2272C154.181 86.0434 154.276 85.9647 154.368 85.8889C154.438 85.8305 154.511 85.7705 154.626 85.6282C154.676 85.5658 154.715 85.5109 154.748 85.4649C154.848 85.3255 154.979 85.1592 155.209 85.1621C155.455 85.1738 155.584 85.3774 155.669 85.5121C155.822 85.7524 155.891 85.9256 155.937 86.0405C155.953 86.0825 155.972 86.1297 155.982 86.1443C156.061 86.261 156.706 86.1519 156.949 86.1123C157.495 86.0213 157.967 85.9425 158.161 86.2866C158.301 86.5328 158.197 86.8582 157.844 87.2799C157.734 87.4111 157.617 87.5109 157.514 87.589C157.6 87.6404 157.677 87.7191 157.708 87.8427C157.781 88.1355 157.53 88.4289 156.96 88.7159C156.819 88.7876 156.628 88.8833 156.361 88.9293C156.236 88.9509 156.123 88.9562 156.026 88.9591C156.024 89.0151 156.011 89.0757 155.976 89.1381C155.876 89.3219 155.668 89.4093 155.354 89.3884C155.009 89.3703 154.725 89.3044 154.474 89.2466C154.255 89.1965 154.066 89.1539 153.918 89.1638C153.644 89.186 153.432 89.4059 153.183 89.6911L152.563 90.416Z" fill="#EDF2F7"/>
      <path d="M153.799 113.863L153.546 113.704L153.485 113.412L153.546 113.704L153.251 113.738C153.246 113.71 153.23 113.647 153.206 113.549C153.074 113.009 152.671 111.365 152.335 108.777C152.101 106.97 151.968 105.112 151.941 103.254C151.913 101.394 152.001 99.9854 152.072 98.8533C152.125 97.9994 152.19 97.1864 152.254 96.3943C152.423 94.2783 152.582 92.2795 152.464 90.0667C152.437 89.5727 152.382 88.5444 151.781 87.4503C151.433 86.8157 150.953 86.2523 150.354 85.7764L150.727 85.3086C151.387 85.8347 151.918 86.4588 152.305 87.1633C152.971 88.3765 153.031 89.4969 153.06 90.0352C153.181 92.2871 153.02 94.3052 152.849 96.4416C152.786 97.2307 152.721 98.0403 152.668 98.8906C152.598 100.012 152.511 101.409 152.538 103.245C152.565 105.081 152.696 106.916 152.927 108.7C153.259 111.255 153.656 112.875 153.786 113.406C153.856 113.691 153.87 113.75 153.799 113.863V113.863Z" fill="#EDF2F7"/>
      <path d="M149.26 86.7251C149.235 86.7251 149.21 86.7245 149.185 86.7228C148.677 86.6954 148.206 86.3909 147.785 85.8182C147.588 85.5487 147.487 85.2413 147.286 84.6283C147.255 84.5338 147.103 84.0544 147.011 83.3948C146.951 82.9637 146.958 82.7835 147.044 82.6243C147.139 82.447 147.292 82.3233 147.465 82.2399C147.457 82.1828 147.463 82.1239 147.488 82.0638C147.59 81.8147 147.86 81.8474 148.007 81.8632C148.081 81.8725 148.173 81.8847 148.273 81.8795C148.43 81.8719 148.514 81.8252 148.642 81.7553C148.764 81.6882 148.916 81.6048 149.141 81.5587C149.586 81.466 149.958 81.5919 150.081 81.6334C150.727 81.8486 151.045 82.3507 151.414 82.9322C151.487 83.0489 151.74 83.4735 151.906 84.0556C152.026 84.4755 152.009 84.6621 151.973 84.801C151.9 85.0862 151.726 85.2495 151.286 85.6099C150.826 85.9873 150.595 86.1763 150.396 86.2912C149.935 86.5577 149.645 86.7251 149.26 86.7251V86.7251Z" fill="#EDF2F7"/>
      <path d="M50.595 75.6693C50.24 75.0708 48.7045 75.7116 48.4222 75.1684C48.1409 74.6272 49.5676 73.8026 50.3827 71.9188C50.5297 71.5791 51.4559 69.4384 50.7702 68.7778C49.4705 67.5257 43.5227 72.6928 41.4413 71.0019C40.9845 70.6308 40.6653 69.89 39.2828 68.6872C38.7328 68.2087 38.4002 67.9927 38.0784 68.0731C37.6217 68.1871 37.5855 68.7984 37.0958 70.0002C36.3613 71.8026 35.9269 71.6937 35.499 73.1317C35.1814 74.1992 35.2539 74.8199 34.8801 74.9408C34.3512 75.1119 33.8643 73.9796 33.1939 74.081C32.5098 74.1844 32.0963 75.5028 31.9656 76.4898C31.7205 78.3422 32.4007 79.5644 32.7778 80.8497C33.1875 82.2464 33.4307 84.3705 32.4845 87.3774L23.1191 113.764C25.1867 108.35 31.1733 93.5072 33.6889 87.9916C34.4151 86.3993 35.2169 84.7843 36.8633 84.1469C38.4484 83.5332 40.6253 83.8996 43.4006 83.231C43.7248 83.153 44.6242 82.9241 44.7374 82.3951C44.831 81.9577 44.302 81.7136 44.3833 81.3039C44.4923 80.7545 45.5327 80.7477 46.8112 80.1138C47.7127 79.6668 48.2744 79.1352 48.7574 78.678C48.903 78.5402 51.0675 76.466 50.595 75.6693H50.595L50.595 75.6693Z" fill="#EDF2F7"/>
      <path d="M13.7492 89.5607C13.353 89.5622 13.2245 90.5008 12.8765 90.4826C12.5297 90.4645 12.5366 89.5264 11.8472 88.5828C11.7229 88.4126 10.9394 87.3403 10.4174 87.4863C9.42782 87.7629 10.2477 92.173 8.81726 92.7067C8.50333 92.8238 8.04765 92.7667 7.05844 93.0982C6.66491 93.2301 6.46292 93.331 6.4095 93.5122C6.3337 93.7692 6.62325 93.9634 7.07181 94.5505C7.74451 95.431 7.56576 95.6128 8.14807 96.2377C8.58035 96.7016 8.9059 96.8451 8.85737 97.0635C8.78874 97.3724 8.09253 97.2847 7.94886 97.643C7.80227 98.0086 8.32999 98.5919 8.77675 98.9409C9.61515 99.5956 10.4113 99.6144 11.1509 99.8002C11.9546 100.002 13.0673 100.496 14.2702 101.828L24.5188 114.038C22.4579 111.461 16.9004 104.24 14.9191 101.414C14.3471 100.598 13.7858 99.7383 13.948 98.7463C14.1042 97.7913 14.9121 96.8286 15.3847 95.2735C15.44 95.0919 15.5871 94.5844 15.3602 94.3762C15.1725 94.2041 14.9 94.3933 14.7224 94.2352C14.4842 94.0232 14.7811 93.5106 14.8388 92.7002C14.8796 92.1287 14.7807 91.6997 14.6957 91.3307C14.67 91.2195 14.2766 89.5586 13.7492 89.5607V89.5607L13.7492 89.5607Z" fill="#EDF2F7"/>
      <path d="M21.6527 91.3339L21.4141 89.2033L21.5231 89.0418C22.0277 88.2948 22.2849 87.5654 22.288 86.873C22.2885 86.763 22.2834 86.6529 22.2782 86.5407C22.2577 86.0963 22.232 85.5438 22.52 84.8998C22.6815 84.5408 23.1353 83.7095 23.8173 83.8124C24.001 83.8381 24.1399 83.9224 24.2453 84.0212C24.2608 83.9965 24.2767 83.9718 24.2937 83.9451C24.5061 83.621 24.6743 83.4821 24.8364 83.3484C24.9609 83.2455 25.0895 83.1395 25.2911 82.8885C25.3796 82.7784 25.4485 82.6817 25.5066 82.6004C25.6831 82.3545 25.9135 82.0613 26.3199 82.0665C26.7541 82.0871 26.9814 82.4461 27.1321 82.6838C27.4012 83.1076 27.5231 83.4132 27.6039 83.6158C27.6332 83.6899 27.6666 83.7733 27.6836 83.799C27.8235 84.0047 28.9609 83.8123 29.3893 83.7424C30.3518 83.5819 31.1846 83.443 31.5277 84.05C31.7736 84.4841 31.591 85.0582 30.9686 85.8021C30.7746 86.0335 30.5684 86.2095 30.3852 86.3473C30.537 86.4379 30.6728 86.5767 30.7278 86.7949C30.8575 87.3113 30.4135 87.8288 29.4089 88.335C29.1594 88.4615 28.8235 88.6303 28.3528 88.7115C28.1311 88.7496 27.9315 88.7589 27.7608 88.764C27.7572 88.8628 27.734 88.9698 27.6738 89.0798C27.4974 89.4039 27.1301 89.5582 26.5766 89.5212C25.9676 89.4893 25.466 89.373 25.0236 89.2712C24.6373 89.1827 24.3045 89.1076 24.0432 89.1251C23.5586 89.1642 23.1852 89.5521 22.7469 90.0551L21.6528 91.3339H21.6528H21.6527Z" fill="#EDF2F7"/>
      <path d="M22.7599 78.6488L20.7595 79.4201L20.5756 79.3556C19.7251 79.0569 18.9543 78.9932 18.2838 79.1658C18.1771 79.1933 18.072 79.2261 17.9648 79.2596C17.5401 79.3922 17.0122 79.5572 16.3162 79.4419C15.9279 79.3768 15.0088 79.1487 14.9353 78.4629C14.9136 78.2787 14.9599 78.123 15.0287 77.9959C15.0009 77.9872 14.973 77.9781 14.9428 77.9684C14.5754 77.8451 14.3984 77.7177 14.228 77.5948C14.0969 77.5005 13.9617 77.403 13.6678 77.2716C13.5389 77.214 13.4278 77.1718 13.3345 77.1362C13.0519 77.0279 12.7098 76.8794 12.6117 76.485C12.5215 76.0598 12.8111 75.7488 13.0028 75.5427C13.3445 75.175 13.6092 74.9795 13.7847 74.85C13.8489 74.8029 13.9211 74.7494 13.9417 74.7264C14.1052 74.5389 13.6306 73.4876 13.4543 73.0908C13.0549 72.2006 12.7093 71.4302 13.2094 70.9444C13.567 70.5964 14.1686 70.6274 15.046 71.0408C15.3191 71.1697 15.5416 71.3246 15.7214 71.4668C15.7705 71.297 15.8704 71.1304 16.0674 71.0219C16.5341 70.7655 17.1473 71.0636 17.8917 71.907C18.0774 72.1162 18.3258 72.3984 18.5238 72.833C18.6169 73.0378 18.6764 73.2285 18.7247 73.3924C18.8212 73.3709 18.9306 73.3661 19.0523 73.3964C19.4105 73.4849 19.653 73.801 19.7575 74.3458C19.8812 74.943 19.8959 75.4577 19.9096 75.9114C19.922 76.3075 19.9338 76.6485 20.017 76.8968C20.1777 77.3556 20.6476 77.6185 21.2454 77.9148L22.76 78.6488L22.7599 78.6488H22.7599Z" fill="#EDF2F7"/>
      <path d="M21.6527 72.3711L21.4141 70.2404L21.5231 70.0789C22.0277 69.332 22.2849 68.6025 22.288 67.9101C22.2885 67.8001 22.2834 67.69 22.2782 67.5779C22.2577 67.1334 22.232 66.5809 22.52 65.9369C22.6815 65.5779 23.1353 64.7466 23.8173 64.8495C24.001 64.8752 24.1399 64.9595 24.2453 65.0583C24.2608 65.0336 24.2767 65.0089 24.2937 64.9822C24.5061 64.6581 24.6743 64.5192 24.8364 64.3855C24.9609 64.2826 25.0895 64.1766 25.2911 63.9256C25.3796 63.8155 25.4485 63.7188 25.5066 63.6375C25.6831 63.3917 25.9135 63.0984 26.3199 63.1036C26.7541 63.1242 26.9814 63.4832 27.1321 63.7209C27.4012 64.1447 27.5231 64.4503 27.6039 64.653C27.6332 64.727 27.6666 64.8104 27.6836 64.8361C27.8235 65.0418 28.9609 64.8495 29.3893 64.7795C30.3518 64.619 31.1846 64.4801 31.5277 65.0871C31.7736 65.5213 31.591 66.0953 30.9686 66.8392C30.7746 67.0707 30.5684 67.2466 30.3852 67.3844C30.537 67.475 30.6728 67.6139 30.7278 67.832C30.8575 68.3484 30.4135 68.8659 29.4089 69.3721C29.1594 69.4986 28.8235 69.6674 28.3528 69.7486C28.1311 69.7867 27.9315 69.796 27.7608 69.8011C27.7572 69.8999 27.734 70.0069 27.6738 70.117C27.4974 70.441 27.1301 70.5953 26.5766 70.5583C25.9676 70.5264 25.466 70.4102 25.0236 70.3083C24.6373 70.2198 24.3045 70.1447 24.0432 70.1622C23.5586 70.2013 23.1852 70.5892 22.7469 71.0923L21.6528 72.3711H21.6528H21.6527Z" fill="#EDF2F7"/>
      <path d="M23.833 113.73L23.3864 113.451L23.2784 112.935L23.3864 113.451L22.8659 113.51C22.8576 113.462 22.8294 113.351 22.7867 113.177C22.5536 112.224 21.8433 109.325 21.2507 104.759C20.8371 101.572 20.603 98.2953 20.5547 95.0185C20.5063 91.7367 20.6617 89.2521 20.7862 87.2552C20.8803 85.749 20.9945 84.3149 21.1066 82.9177C21.405 79.1852 21.6864 75.6595 21.477 71.7563C21.4307 70.8849 21.3335 69.0711 20.2738 67.141C19.6591 66.0217 18.8124 65.0279 17.7568 64.1884L18.4132 63.3633C19.5783 64.2913 20.5151 65.3921 21.1977 66.6349C22.3726 68.7748 22.4785 70.7511 22.5295 71.7007C22.7424 75.6729 22.458 79.2326 22.1565 83.0011C22.0454 84.3931 21.9312 85.8211 21.8376 87.321C21.7141 89.2994 21.5603 91.7624 21.6082 95.0021C21.6555 98.2397 21.887 101.476 22.2949 104.623C22.8803 109.132 23.5804 111.988 23.8103 112.926C23.9328 113.427 23.9585 113.532 23.833 113.73V113.73H23.833Z" fill="#EDF2F7"/>
      <path d="M15.8241 65.8614C15.7809 65.8614 15.7372 65.8603 15.693 65.8573C14.7958 65.8089 13.9651 65.2719 13.2233 64.2616C12.8756 63.7863 12.6976 63.2441 12.3427 62.1628C12.2876 61.9961 12.0201 61.1505 11.8576 59.9869C11.7511 59.2266 11.7645 58.9087 11.9152 58.6278C12.0824 58.3151 12.3529 58.097 12.6575 57.9499C12.6441 57.849 12.6549 57.7451 12.6986 57.6392C12.8782 57.1999 13.3555 57.2575 13.6137 57.2853C13.7444 57.3017 13.9075 57.3233 14.0834 57.3141C14.3601 57.3007 14.5088 57.2184 14.7341 57.0949C14.9497 56.9766 15.2176 56.8295 15.6153 56.7482C16.3992 56.5846 17.0561 56.8069 17.2727 56.8799C18.4121 57.2595 18.9738 58.1453 19.624 59.171C19.7536 59.3768 20.1986 60.1258 20.4923 61.1525C20.7043 61.8932 20.6744 62.2225 20.6106 62.4673C20.481 62.9704 20.1749 63.2585 19.3977 63.8943C18.586 64.5599 18.1785 64.8933 17.8287 65.0959C17.0145 65.5661 16.5031 65.8613 15.8241 65.8613V65.8614Z" fill="#EDF2F7"/>
      <path d="M135.748 48.0258L124.069 66.854L136.224 84.0139L131.458 86.3972C131.458 86.3972 115.966 69.9523 115.49 67.8073C115.013 65.6623 120.256 50.6475 120.256 50.6475H110.302L119.779 62.564L115.251 65.424L99.0447 49.6942C99.0447 49.6942 97.138 46.5264 99.0447 45.3695C100.951 44.2125 118.048 36.5859 118.048 36.5859L135.748 48.0258Z" fill="#1A202C"/>
      <path d="M108.935 81.5107C110.12 81.5107 111.08 80.5503 111.08 79.3657C111.08 78.181 110.12 77.2207 108.935 77.2207C107.75 77.2207 106.79 78.181 106.79 79.3657C106.79 80.5503 107.75 81.5107 108.935 81.5107Z" fill="#2D3748"/>
      <path d="M125.023 102.126C126.208 102.126 127.168 101.166 127.168 99.9809C127.168 98.7963 126.208 97.8359 125.023 97.8359C123.838 97.8359 122.878 98.7963 122.878 99.9809C122.878 101.166 123.838 102.126 125.023 102.126Z" fill="#2D3748"/>
      <path d="M107.267 19.7827L110.842 13.1094C110.842 13.1094 121.792 15.2544 125.254 19.7827C128.716 24.3109 134.913 36.2275 134.913 36.2275C134.913 36.2275 139.68 45.7608 135.39 51.0041C131.1 56.2473 125.618 45.7608 125.618 45.7608L117.753 36.9425L116.8 36.7042L107.267 19.7827Z" fill="#F7B80B"/>
      <path d="M101.828 47.8073C102.133 47.0677 102.61 46.4119 103.221 45.8947L108.383 32.0781L112.378 33.6169L106.49 47.2947C106.537 48.093 106.392 48.8909 106.067 49.6216C105.279 51.4617 103.692 52.5465 102.521 52.0453C101.351 51.5441 101.04 49.6467 101.828 47.8073V47.8073V47.8073Z" fill="#A0616A"/>
      <path d="M110.008 20.1406L119.422 23.1198L111.676 36.5855L105.718 36.1088L110.008 20.1406Z" fill="#F7B80B"/>
      <path d="M88.0102 31.239C88.7609 30.9626 89.5668 30.8701 90.3606 30.9692L104.025 25.416L105.596 29.3978L91.5436 34.3235C90.9877 34.8983 90.3022 35.3316 89.5444 35.5872C87.6567 36.253 85.7837 35.819 85.3602 34.6181C84.9366 33.4172 86.1231 31.9042 88.0102 31.239V31.239L88.0102 31.239Z" fill="#A0616A"/>
      <path d="M110.603 17.1621L117.523 24.9219L102.936 30.89L99.2539 26.1818L110.603 17.1621Z" fill="#F7B80B"/>
      <path d="M54.5974 68.0155C54.6052 68.0168 54.6168 68.0188 54.6308 68.0211C54.9059 68.0666 56.569 68.3232 58.2458 67.6845C58.9561 67.4139 58.7138 67.3465 60.2958 66.4058C62.1505 65.3029 62.6265 65.3106 62.7646 64.7963C63.0404 63.7694 61.5677 62.1551 60.593 62.2614C60.3364 62.2894 60.0616 62.4424 59.6341 62.3386C59.5402 62.3158 59.1291 62.2161 58.9286 61.8758C58.7498 61.5722 58.9013 61.3532 58.8294 61.1042C58.6406 60.4507 57.0673 60.1245 55.688 60.355C54.8946 60.4875 54.2776 60.8494 53.0758 61.5676C50.9842 62.8178 49.9383 63.4429 49.7033 64.4006C49.4805 65.3084 50.0152 65.6995 49.6263 66.6712C49.2642 67.576 48.6891 67.5156 48.4912 68.2475C48.1727 69.4253 49.3548 70.7179 49.8472 71.2564C50.387 71.8468 50.9033 72.1708 51.5888 72.601C52.4491 73.1409 52.8793 73.4109 53.3965 73.3834C54.3342 73.3336 57.1205 73.5031 57.1569 72.7272C57.1705 72.4363 60.5499 72.6745 58.8859 69.5436C58.6818 69.1596 54.6834 69.5698 54.6306 69.3492" fill="#1A202C"/>
      <path d="M55.3579 71.462C58.2755 71.462 60.6407 69.0968 60.6407 66.1792C60.6407 63.2616 58.2755 60.8965 55.3579 60.8965C52.4404 60.8965 50.0752 63.2616 50.0752 66.1792C50.0752 69.0968 52.4404 71.462 55.3579 71.462Z" fill="#FFB6B6"/>
      <path d="M36.8129 108.685C36.8129 108.685 29.4001 106.321 29.0778 107.503C28.7555 108.685 34.3419 110.618 38.7467 110.618C43.1514 110.618 70.8688 110.403 70.8688 110.403C70.8688 110.403 78.6039 107.932 76.3479 107.503C74.0918 107.073 36.8129 108.685 36.8129 108.685H36.8129Z" fill="#F7B80B"/>
      <path d="M40.2512 114.055C41.3785 114.055 42.2924 113.141 42.2924 112.014C42.2924 110.887 41.3785 109.973 40.2512 109.973C39.1238 109.973 38.21 110.887 38.21 112.014C38.21 113.141 39.1238 114.055 40.2512 114.055Z" fill="#2D3748"/>
      <path d="M66.7863 114.055C67.9137 114.055 68.8275 113.141 68.8275 112.014C68.8275 110.887 67.9137 109.973 66.7863 109.973C65.659 109.973 64.7451 110.887 64.7451 112.014C64.7451 113.141 65.659 114.055 66.7863 114.055Z" fill="#2D3748"/>
      <path d="M58.4064 72.8013L52.2119 72.2148L50.0267 74.0905H50.0267C48.0378 74.8282 46.7025 76.7075 46.6604 78.8284L46.3203 95.9529L62.2202 96.9198C62.2202 96.9198 60.5013 92.9448 61.6831 92.1927C62.8648 91.4407 63.5872 81.1273 62.5963 79.4621C61.6053 77.7969 61.0385 75.8631 61.0385 75.8631L58.4064 72.8013Z" fill="#E2E8F0"/>
      <path d="M46.7504 95.416L44.8166 100.25C44.8166 100.25 41.8085 107.019 44.7092 108.952C47.6098 110.886 64.3692 109.382 64.3692 109.382C64.3692 109.382 65.9807 107.986 66.7327 105.085C67.4848 102.184 62.7577 97.5647 62.7577 97.5647L62.2206 96.9201L46.7504 95.416Z" fill="#1A202C"/>
      <path d="M67.549 107.988C67.8654 109.658 68.9832 110.848 70.0456 110.646C71.108 110.445 71.7125 108.928 71.3958 107.258C71.2784 106.589 71.0025 105.958 70.5913 105.418L69.1614 98.3613L65.8643 99.1029L67.624 105.981C67.439 106.634 67.4133 107.323 67.549 107.988Z" fill="#FFB6B6"/>
      <path d="M58.1758 83.7308C58.1758 83.7308 59.2151 84.9435 61.1418 85.2147C63.0685 85.4859 66.0794 103.315 66.0794 103.315L70.2134 101.609C70.2134 101.609 68.0145 85.4155 67.4016 84.3228C66.7886 83.2301 60.3157 75.0742 60.3157 75.0742L58.1758 83.7308H58.1758Z" fill="#E2E8F0"/>
      <path d="M41.6419 108.465C41.3255 110.134 40.2077 111.324 39.1453 111.123C38.0829 110.921 37.4784 109.405 37.7951 107.735C37.9125 107.066 38.1884 106.435 38.5996 105.894L40.0295 98.8379L43.3266 99.5795L41.5669 106.457C41.7519 107.111 41.7776 107.799 41.6419 108.465Z" fill="#FFB6B6"/>
      <path d="M51.0151 84.2074C51.0151 84.2074 49.9758 85.42 48.0491 85.6913C46.1224 85.9625 43.1115 103.792 43.1115 103.792L38.9775 102.085C38.9775 102.085 41.1764 85.8921 41.7893 84.7994C42.4023 83.7067 48.8752 75.5508 48.8752 75.5508L51.0151 84.2074H51.0151Z" fill="#E2E8F0"/>
      <path d="M59.4826 104.917L56.3226 102.162L48.8818 112.646L51.023 114.513L59.4826 104.917Z" fill="#FFB6B6"/>
      <path d="M64.6915 107.878C64.6915 107.878 70.3854 98.4244 70.6003 94.342C70.8152 90.2596 67.2699 89.7224 65.9807 90.5819C64.6915 91.4413 50.7822 107.25 50.7822 107.25L55.1306 110.17L64.5388 102.678L64.6915 107.878Z" fill="#1A202C"/>
      <path d="M48.6484 105.452L51.6946 102.572L59.5526 112.748L57.4886 114.699L48.6484 105.452Z" fill="#FFB6B6"/>
      <path d="M43.7423 107.878C43.7423 107.878 38.0484 98.4244 37.8336 94.342C37.6187 90.2596 41.164 89.7224 42.4532 90.5819C43.7423 91.4413 56.6244 107.878 56.6244 107.878L53.3033 110.17L43.895 102.678L43.7423 107.878Z" fill="#1A202C"/>
      <path d="M49.7047 110.832C49.7047 110.832 45.7297 112.873 45.9446 113.41C46.1595 113.948 46.3743 115.989 46.3743 115.989C46.3743 115.989 43.3662 118.03 46.052 119.427C48.7378 120.823 50.779 120.716 51.1013 119.856C51.4236 118.997 51.5311 116.526 51.5311 116.526L52.9277 112.981L49.7047 110.832L49.7047 110.832Z" fill="#1A202C"/>
      <path d="M58.6214 111.154C58.6214 111.154 62.5964 113.196 62.3815 113.733C62.1667 114.27 61.9518 116.311 61.9518 116.311C61.9518 116.311 64.9599 118.352 62.2741 119.749C59.5883 121.145 57.5471 121.038 57.2248 120.179C56.9025 119.319 56.7951 116.848 56.7951 116.848L55.3984 113.303L58.6214 111.154L58.6214 111.154Z" fill="#1A202C"/>
      <path d="M53.656 66.5214C53.6638 66.5227 53.6754 66.5246 53.6894 66.527C53.9645 66.5725 55.6276 66.8291 57.3044 66.1904C58.0147 65.9198 57.7724 65.8523 59.3544 64.9116C61.2091 63.8087 61.6851 63.8164 61.8232 63.3022C62.099 62.2753 60.6263 60.661 59.6516 60.7673C59.395 60.7952 59.1202 60.9482 58.6927 60.8445C58.5988 60.8217 58.1877 60.7219 57.9872 60.3816C57.8084 60.078 57.9599 59.8591 57.888 59.6101C57.6992 58.9565 56.1259 58.6304 54.7466 58.8608C53.9532 58.9934 53.3362 59.3552 52.1344 60.0735C50.0428 61.3237 48.9969 61.9488 48.7619 62.9065C48.5391 63.8142 49.0738 64.2054 48.6849 65.1771C48.3228 66.0819 47.7477 66.0214 47.5498 66.7533C47.2313 67.9312 48.4134 69.2238 48.9058 69.7623C49.4456 70.3527 49.9619 70.6767 50.6474 71.1068C51.5077 71.6468 51.9379 71.9167 52.4551 71.8893C53.3928 71.8395 54.3254 70.9897 54.3618 70.2137C54.3754 69.9229 54.2541 69.8357 53.9538 68.836C53.8288 68.4195 53.742 68.0756 53.6892 67.855" fill="#1A202C"/>
      <path d="M107.743 6.23095C109.341 6.23095 110.636 4.93579 110.636 3.33813C110.636 1.74047 109.341 0.445312 107.743 0.445312C106.146 0.445312 104.851 1.74047 104.851 3.33813C104.851 4.93579 106.146 6.23095 107.743 6.23095Z" fill="#1A202C"/>
      <path d="M103.3 16.3068C106.779 16.3068 109.6 13.4862 109.6 10.0069C109.6 6.52758 106.779 3.70703 103.3 3.70703C99.8206 3.70703 97 6.52758 97 10.0069C97 13.4862 99.8206 16.3068 103.3 16.3068Z" fill="#A0616A"/>
      <path d="M107.819 15.5988C108.573 15.6662 109.805 13.7355 109.75 11.8657C109.715 10.6629 110.759 10.4264 110.837 8.87511C110.882 7.97122 109.494 7.68938 109.398 6.9644C109.226 5.65081 108.378 4.76689 108.272 4.65943C107.359 3.73186 106.714 4.0334 105.64 2.95837C104.994 2.31115 105.159 2.13384 104.724 1.84676C103.467 1.01824 101.209 1.9162 99.8582 3.12578C99.3908 3.54435 97.6488 3.08688 96.8879 4.26887C95.9914 5.66146 96.1995 8.50149 96.584 8.87629C96.8175 9.104 97.3509 8.54768 98.7966 8.19063C100.135 7.86006 98.8725 9.49482 100.42 9.22049C102.25 8.89609 102.608 8.3394 103.293 8.67329C104.018 9.02624 104.584 10.1191 104.419 10.9783C104.338 11.4046 104.101 11.6423 104.235 11.8591C104.478 12.2549 104.757 11.8667 105.069 12.2639C105.432 12.7259 104.53 13.9112 104.7 14.0256C104.734 14.0487 104.838 14.0457 105.812 13.1092C106.201 12.7352 106.314 12.5056 106.391 12.5409C106.514 12.5979 107.321 13.3499 107.013 13.9437C106.533 14.8678 107.147 14.8965 107.013 15.5186C106.874 16.1604 107.339 15.5559 107.819 15.5988V15.5988Z" fill="#1A202C"/>
    </g>
    <defs>
      <clipPath id="clip0_942_6341">
        <rect width="184.478" height="120.37" fill="white" transform="translate(0.760742 0.445312)"/>
      </clipPath>
    </defs>
  </svg>
);