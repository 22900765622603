export const ConversationIcon = () => (

  <svg width="116" height="101" viewBox="0 0 116 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_946_5453)">
      <path
        d="M19.6861 35.5307C19.4178 35.5578 19.1479 35.5641 18.8786 35.5496C18.8327 35.5445 18.7867 35.5578 18.7504 35.5863C18.7142 35.6149 18.6906 35.6566 18.6848 35.7024L18.1697 39.566C18.1301 39.8465 18.0152 40.111 17.8374 40.3314C17.6908 40.5069 17.5818 40.7106 17.5172 40.9299C17.4526 41.1492 17.4338 41.3795 17.4619 41.6064V48.1694C17.4619 48.1694 17.4287 49.0444 18.101 49.0444C18.9057 49.1455 19.7199 49.1455 20.5246 49.0444C20.5246 49.0444 21.063 48.9425 21.063 48.135V41.6728C21.104 41.4474 21.0956 41.2157 21.0382 40.9938C20.9808 40.7719 20.8759 40.5652 20.7306 40.3879C20.5588 40.1667 20.4476 39.9043 20.4083 39.6269L19.8733 35.6825C19.8672 35.6381 19.8445 35.5978 19.8097 35.5696C19.775 35.5414 19.7308 35.5275 19.6861 35.5307Z"
        fill="white"/>
      <path
        d="M19.3096 49.231C18.9029 49.231 18.4966 49.2057 18.0931 49.1553C18.0029 49.1575 17.9132 49.1409 17.8298 49.1067C17.7463 49.0726 17.6708 49.0215 17.608 48.9568C17.3344 48.6726 17.3496 48.1859 17.3504 48.1653L17.3503 41.6133C17.3217 41.3724 17.3426 41.1282 17.4116 40.8956C17.4806 40.663 17.5963 40.4469 17.7516 40.2606C17.9162 40.056 18.0225 39.8107 18.0592 39.5507L18.5741 35.6879C18.5842 35.6136 18.6227 35.5462 18.6817 35.4998C18.7406 35.4535 18.8152 35.4319 18.8898 35.4396C19.1514 35.4534 19.4136 35.4471 19.6742 35.4207L19.6773 35.4204C19.75 35.4152 19.822 35.4378 19.8787 35.4837C19.9353 35.5296 19.9723 35.5954 19.9822 35.6676L20.5173 39.6122C20.554 39.8703 20.6574 40.1142 20.8173 40.3201C20.9712 40.5082 21.0827 40.7274 21.1441 40.9626C21.2055 41.1978 21.2153 41.4435 21.1729 41.6828V48.1352C21.1729 49.0241 20.5507 49.1522 20.5444 49.1534C20.1348 49.205 19.7224 49.231 19.3096 49.231ZM18.8627 35.6599C18.8463 35.6591 18.8301 35.6644 18.8173 35.6749C18.8046 35.6854 18.7962 35.7002 18.7938 35.7165L18.2787 39.5808C18.2363 39.8814 18.1133 40.1649 17.9228 40.4011C17.7858 40.5652 17.6839 40.7556 17.6234 40.9606C17.5629 41.1655 17.5451 41.3807 17.571 41.5929L17.5718 41.6065V48.1695C17.5716 48.1777 17.5598 48.5881 17.7681 48.8037C17.8112 48.8475 17.863 48.8818 17.9202 48.9042C17.9775 48.9266 18.0388 48.9367 18.1002 48.9338L18.114 48.9347C18.9096 49.0345 19.7144 49.0345 20.51 48.9347C20.5205 48.9321 20.9514 48.8338 20.9514 48.1352L20.9532 41.6531C20.9915 41.4435 20.9837 41.228 20.9304 41.0216C20.877 40.8153 20.7793 40.623 20.6441 40.4583C20.4596 40.2212 20.3402 39.94 20.2979 39.6426L19.7627 35.6975C19.7604 35.6813 19.7521 35.6666 19.7394 35.6562C19.7268 35.6458 19.7108 35.6405 19.6944 35.6413C19.4211 35.6687 19.1461 35.675 18.8718 35.6604C18.8688 35.66 18.8658 35.6599 18.8627 35.6599H18.8627Z"
        fill="#3F3D56"/>
      <path
        d="M18.8724 41.7239H19.6888C20.0134 41.7239 20.3247 41.8528 20.5543 42.0824C20.7838 42.3119 20.9128 42.6232 20.9128 42.9479V46.0195C20.9128 46.3441 20.7838 46.6554 20.5543 46.8849C20.3247 47.1145 20.0134 47.2434 19.6888 47.2434H18.8724C18.5478 47.2434 18.2365 47.1145 18.0069 46.8849C17.7774 46.6554 17.6484 46.3441 17.6484 46.0195V42.9479C17.6484 42.7871 17.6801 42.628 17.7416 42.4795C17.8031 42.331 17.8933 42.196 18.0069 42.0824C18.1206 41.9687 18.2555 41.8786 18.404 41.817C18.5525 41.7555 18.7117 41.7239 18.8724 41.7239Z"
        fill="#F7B80B"/>
      <path d="M3.86375 98.1389H6.52527L7.79147 87.873L3.86328 87.8732L3.86375 98.1389Z" fill="#9F616A"/>
      <path
        d="M3.18555 97.27L8.42704 97.2698H8.42725C9.31313 97.2698 10.1627 97.6218 10.7891 98.2482C11.4155 98.8746 11.7675 99.7241 11.7675 100.61V100.719L3.1857 100.719L3.18555 97.27Z"
        fill="#2F2E41"/>
      <path
        d="M2.19092 61.4719C2.19092 61.4719 1.03744 62.7758 2.94743 67.8585L2.611 95.7278L8.80068 95.6071L10.1612 82.0126L12.6384 62.1744L2.19092 61.4719Z"
        fill="#2F2E41"/>
      <path
        d="M14.7611 65.3248L14.6933 65.3145C6.77498 64.1068 0.799677 62.9491 0.740244 62.9376L0.666016 62.9232L2.74174 57.8745C2.72534 57.3469 2.27602 42.5325 3.13617 38.2762C3.96885 34.1558 7.89381 33.4805 8.34107 33.417L8.79018 31.929L14.464 34.8814L15.4536 38.2871L15.6546 47.6214L14.7611 65.3248Z"
        fill="#3F3D56"/>
      <path
        d="M18.1775 46.1128C17.922 46.1127 17.6692 46.1656 17.4351 46.2679C17.201 46.3703 16.9906 46.52 16.8172 46.7077H7.2804L3.78711 47.4513L4.36065 51.875L16.7873 49.2025C16.993 49.4348 17.2537 49.6119 17.5455 49.7176C17.8372 49.8233 18.1509 49.8542 18.4577 49.8076C18.7645 49.7609 19.0547 49.6381 19.3019 49.4504C19.5491 49.2627 19.7453 49.0161 19.8726 48.7331C20 48.4501 20.0544 48.1397 20.0309 47.8302C20.0074 47.5208 19.9068 47.2221 19.7382 46.9616C19.5697 46.701 19.3385 46.4868 19.0659 46.3385C18.7932 46.1903 18.4878 46.1127 18.1775 46.1128H18.1775Z"
        fill="#9F616A"/>
      <path
        d="M18.2608 30.2569C19.4784 28.0295 18.6598 25.2369 16.4325 24.0193C14.2051 22.8017 11.4124 23.6203 10.1949 25.8476C8.97728 28.075 9.79586 30.8677 12.0232 32.0852C14.2506 33.3028 17.0432 32.4842 18.2608 30.2569Z"
        fill="#9F616A"/>
      <path
        d="M9.90445 24.3776L9.68163 23.9292L10.8028 23.3721C10.8028 23.3721 12.0397 21.3596 14.2778 21.9252C16.5159 22.4907 17.5229 22.8292 17.5229 22.8292L18.6412 23.3919L18.0799 23.9504L19.0869 24.2889L18.4142 24.6231L19.1969 25.073L18.7755 27.3793C18.7755 27.3793 18.0757 25.63 16.7303 26.2985C15.3849 26.967 12.9239 25.953 12.9239 25.953L10.7859 30.0907C10.7859 30.0907 10.3448 28.6402 9.55836 29.027C9.55835 29.027 7.54926 25.8274 9.90445 24.3776Z"
        fill="#2F2E41"/>
      <path
        d="M4.42279 52.7043L3.33217 49.4602L3.06836 38.6461L3.53305 37.1117C3.70031 36.5595 3.97734 36.0467 4.34753 35.6041C4.71772 35.1615 5.17345 34.7982 5.68742 34.536C6.20139 34.2737 6.76301 34.1179 7.33863 34.0779C7.91425 34.0379 8.49202 34.1146 9.03731 34.3032C9.58261 34.4919 10.0842 34.7887 10.512 35.1759C10.9399 35.563 11.2851 36.0326 11.5272 36.5564C11.7692 37.0802 11.903 37.6474 11.9205 38.2242C11.938 38.8009 11.8389 39.3753 11.6292 39.9128L9.13846 46.2953L9.26507 46.541L14.6612 46.4973L16.5779 49.9475L4.42279 52.7043Z"
        fill="#3F3D56"/>
      <path
        d="M58.4124 51.3713C58.6137 51.2356 58.7829 51.0575 58.908 50.8494C59.0331 50.6413 59.111 50.4083 59.1363 50.1668C59.1616 49.9253 59.1337 49.6812 59.0544 49.4517C58.9752 49.2222 58.8466 49.0129 58.6777 48.8385L61.3375 43.5698L58.291 43.9232L56.2322 48.8722C55.958 49.1754 55.8045 49.5686 55.8008 49.9774C55.7972 50.3861 55.9436 50.782 56.2123 51.0901C56.481 51.3981 56.8534 51.5969 57.2589 51.6487C57.6644 51.7005 58.0748 51.6018 58.4124 51.3713Z"
        fill="#FFB7B7"/>
      <path d="M79.7426 88.6915L77.3454 88.6914L76.2051 79.4453L79.7429 79.4456L79.7426 88.6915Z" fill="#FFB7B7"/>
      <path
        d="M80.3542 91.0151L72.625 91.0148V90.9171C72.6251 90.1192 72.942 89.354 73.5062 88.7899C74.0704 88.2257 74.8356 87.9087 75.6334 87.9087H75.6336L80.3544 87.9089L80.3542 91.0151Z"
        fill="#2F2E41"/>
      <path d="M66.643 88.6915L64.2458 88.6914L63.1055 79.4453L66.6435 79.4456L66.643 88.6915Z" fill="#FFB7B7"/>
      <path
        d="M66.6667 91.0151L58.9375 91.0148V90.9171C58.9376 90.1192 59.2545 89.354 59.8187 88.7899C60.3829 88.2257 61.1481 87.9087 61.9459 87.9087H61.9461L66.6669 87.9089L66.6667 91.0151Z"
        fill="#2F2E41"/>
      <path
        d="M66.4458 49.6409L64.0995 55.0462L62.1641 86.1152L67.0521 85.9197L68.3872 71.1089L72.5056 57.9176L74.8731 72.6238L75.8509 85.5286L79.8955 85.7037L81.5073 49.6409H66.4458Z"
        fill="#2F2E41"/>
      <path
        d="M78.1758 22.9683L71.3755 23.1384L67.383 25.188L67.1639 40.3094L64.6738 50.7495C64.6738 50.7495 80.2319 51.8202 82.3827 50.256L79.8409 38.329L83.25 25.4393L78.1758 22.9683Z"
        fill="#F7B80B"/>
      <path
        d="M68.4313 25.4385L67.7983 25.011C67.7983 25.011 65.1756 25.7971 64.4004 29.2942C64.4004 29.2942 63.8072 31.0488 63.547 32.255C63.2484 33.6387 56.6562 46.3478 56.6562 46.3478L60.6031 47.48L67.839 35.7781L68.4313 25.4385Z"
        fill="#F7B80B"/>
      <path
        d="M83.1429 53.3102C82.9867 53.1243 82.8732 52.9064 82.8103 52.6719C82.7475 52.4374 82.7368 52.1919 82.7791 51.9528C82.8214 51.7137 82.9156 51.4868 83.055 51.2881C83.1945 51.0893 83.3758 50.9236 83.5863 50.8025L82.4824 45.0046L85.3134 46.1844L85.9276 51.5093C86.1076 51.8763 86.1467 52.2966 86.0375 52.6905C85.9283 53.0844 85.6784 53.4246 85.3352 53.6466C84.9919 53.8686 84.5791 53.957 84.1751 53.895C83.771 53.833 83.4038 53.6249 83.1429 53.3102Z"
        fill="#FFB7B7"/>
      <path
        d="M82.225 25.6197L82.9514 25.3833C82.9514 25.3833 85.2556 26.8621 85.0364 30.4374C85.0364 30.4374 85.1227 32.2875 85.0403 33.5188C84.9457 34.9311 86.2134 48.9653 86.2134 48.9653H82.1074L80.3477 36.2562L82.225 25.6197Z"
        fill="#F7B80B"/>
      <path
        d="M79.8442 16.624C79.8442 17.5564 79.5678 18.4678 79.0498 19.243C78.5318 20.0183 77.7955 20.6225 76.9341 20.9793C76.0727 21.3361 75.1249 21.4295 74.2104 21.2476C73.296 21.0657 72.456 20.6167 71.7968 19.9574C71.1375 19.2981 70.6885 18.4582 70.5066 17.5437C70.3247 16.6293 70.4181 15.6814 70.7749 14.82C71.1317 13.9586 71.7359 13.2224 72.5111 12.7044C73.2863 12.1864 74.1978 11.9099 75.1301 11.9099C76.3773 11.9068 77.5746 12.3993 78.4586 13.2789C79.3427 14.1586 79.8411 15.3534 79.8442 16.6005C79.8442 16.6084 79.8442 16.6162 79.8442 16.624Z"
        fill="#FFB7B7"/>
      <path
        d="M70.4308 10.2921C70.983 9.10461 72.0517 9.33939 72.9864 9.74192C74.1699 9.47938 75.2956 8.69339 76.5708 9.15822C77.827 10.9852 82.0444 10.4477 81.1472 13.4221C81.146 14.1348 82.4883 13.7199 82.254 14.8872C82.965 17.1336 79.6873 21.388 77.8057 20.4921C78.271 19.6392 79.3341 17.7021 77.7217 17.5099C74.2533 20.7372 77.3639 11.3645 73.0059 14.0902C71.5629 15.4108 69.5875 11.613 70.4308 10.2921Z"
        fill="#2F2E41"/>
      <path
        d="M83.968 53.7803C84.1925 53.8255 84.4242 53.8209 84.6468 53.7668C84.8693 53.7127 85.0773 53.6104 85.256 53.4673C85.4348 53.3241 85.58 53.1435 85.6814 52.9381C85.7828 52.7328 85.838 52.5077 85.843 52.2787L97.6797 41.1377L95.0164 39.0132L84.1987 50.6606C83.8134 50.6777 83.4479 50.8355 83.1712 51.1041C82.8946 51.3727 82.7261 51.7335 82.6977 52.118C82.6692 52.5026 82.7828 52.8842 83.017 53.1906C83.2511 53.4969 83.5895 53.7068 83.968 53.7803Z"
        fill="#9F616A"/>
      <path
        d="M96.0021 42.9387L93.6993 40.2399C93.6338 40.1632 93.5856 40.0732 93.558 39.9761C93.5304 39.879 93.5241 39.7771 93.5394 39.6773C93.5547 39.5775 93.5914 39.4822 93.6469 39.3979C93.7024 39.3136 93.7754 39.2422 93.8609 39.1886L96.8589 37.3119C97.2537 36.9788 97.7644 36.8159 98.2791 36.8588C98.7938 36.9018 99.2705 37.1471 99.6046 37.5409C99.9387 37.9348 100.103 38.4451 100.061 38.96C100.02 39.4748 99.7757 39.9521 99.3827 40.2872L97.0658 42.9445C96.9994 43.0206 96.9175 43.0815 96.8255 43.123C96.7335 43.1645 96.6336 43.1857 96.5326 43.1852C96.4317 43.1846 96.3321 43.1623 96.2405 43.1198C96.149 43.0772 96.0677 43.0155 96.0021 42.9387V42.9387Z"
        fill="#CBCBCB"/>
      <path d="M89.81 97.9136L86.9159 97.9135L85.5391 86.7507L89.8105 86.7509L89.81 97.9136Z" fill="#9F616A"/>
      <path
        d="M90.5485 100.719L81.2168 100.719V100.601C81.2169 99.6373 81.5996 98.7135 82.2807 98.0324C82.9619 97.3512 83.8857 96.9686 84.8489 96.9685H84.8492L90.5486 96.9687L90.5485 100.719Z"
        fill="#2F2E41"/>
      <path d="M112.943 97.9136L110.049 97.9135L108.672 86.7507L112.943 86.7509L112.943 97.9136Z" fill="#9F616A"/>
      <path
        d="M113.681 100.719L104.35 100.719V100.601C104.35 99.6373 104.732 98.7135 105.414 98.0324C106.095 97.3512 107.018 96.9686 107.982 96.9685H107.982L113.681 96.9687L113.681 100.719Z"
        fill="#2F2E41"/>
      <path
        d="M105.158 48.8559L108.076 38.0779L104.291 35.9507H99.0085L96.9539 38.1488C96.9539 38.1488 93.5213 40.5371 94.3521 43.1833L95.6924 49.2105L93.6425 58.7831L83.3141 73.1065L80.8691 87.1759L90.4935 89.699L100.901 69.2065L105.947 91.8971L115.334 91.425L112.099 63.9127C112.099 63.9127 111.177 55.5314 106.344 50.2983L105.158 48.8559Z"
        fill="#2F2E41"/>
      <path
        d="M111.184 58.3922C111.346 58.231 111.472 58.0361 111.551 57.8212C111.63 57.6063 111.661 57.3767 111.642 57.1485C111.623 56.9202 111.554 56.699 111.44 56.5003C111.326 56.3016 111.17 56.1303 110.983 55.9984L108.719 40.7283L105.471 41.7541L108.723 56.4609C108.522 56.7902 108.449 57.1816 108.518 57.561C108.587 57.9405 108.792 58.2815 109.095 58.5196C109.399 58.7576 109.779 58.8762 110.164 58.8527C110.549 58.8292 110.911 58.6654 111.184 58.3922Z"
        fill="#9F616A"/>
      <path
        d="M109.278 43.1249L105.754 43.5312C105.653 43.5428 105.552 43.5326 105.456 43.5014C105.36 43.4701 105.272 43.4186 105.197 43.3502C105.123 43.2818 105.064 43.1983 105.025 43.1052C104.986 43.0121 104.968 42.9117 104.971 42.8108L105.085 39.2757C105.029 38.7623 105.179 38.2476 105.501 37.8444C105.824 37.4412 106.293 37.1824 106.807 37.1247C107.32 37.0671 107.835 37.2153 108.239 37.5369C108.643 37.8584 108.903 38.3271 108.962 38.8401L109.876 42.2453C109.902 42.3428 109.907 42.4447 109.89 42.5443C109.873 42.6438 109.835 42.7385 109.778 42.822C109.722 42.9055 109.648 42.9757 109.561 43.028C109.475 43.0803 109.378 43.1133 109.278 43.1249V43.1249Z"
        fill="#CBCBCB"/>
      <path
        d="M95.7422 32.8855V28.3443C95.7422 26.7304 96.3833 25.1826 97.5245 24.0414C98.6657 22.9002 100.214 22.259 101.827 22.259C103.441 22.259 104.989 22.9002 106.13 24.0414C107.272 25.1826 107.913 26.7304 107.913 28.3443V32.8855C107.912 33.1022 107.826 33.31 107.673 33.4632C107.52 33.6165 107.312 33.7027 107.095 33.7029H96.5596C96.3429 33.7027 96.1351 33.6165 95.9819 33.4632C95.8286 33.31 95.7424 33.1022 95.7422 32.8855Z"
        fill="#2F2E41"/>
      <path
        d="M102.718 33.0506C104.88 31.8687 105.675 29.1579 104.493 26.9958C103.311 24.8338 100.6 24.0392 98.4382 25.2211C96.2761 26.403 95.4815 29.1138 96.6634 31.2758C97.8453 33.4379 100.556 34.2325 102.718 33.0506Z"
        fill="#9F616A"/>
      <path
        d="M94.3027 28.7077C94.3042 27.4315 94.8118 26.208 95.7142 25.3055C96.6167 24.4031 97.8402 23.8955 99.1164 23.894H100.025C101.301 23.8955 102.524 24.4031 103.427 25.3055C104.329 26.208 104.837 27.4315 104.838 28.7077V28.7986H102.919L102.264 26.9654L102.133 28.7986H101.141L100.811 27.8736L100.745 28.7986H94.3027V28.7077Z"
        fill="#2F2E41"/>
      <path
        d="M100.138 34.2136C100.048 34.0916 99.994 33.9466 99.9827 33.7953C99.9714 33.644 100.003 33.4926 100.074 33.3586C101.036 31.5279 102.384 28.1452 100.595 26.0591L100.467 25.9092H105.66V33.7055L100.942 34.5379C100.895 34.5463 100.846 34.5506 100.798 34.5506C100.669 34.5506 100.542 34.5201 100.427 34.4615C100.313 34.4029 100.213 34.3179 100.138 34.2136Z"
        fill="#2F2E41"/>
      <path
        d="M52.4088 38.365C52.343 38.4327 52.2826 38.5055 52.2282 38.5827L43.7671 38.3757L42.7801 36.572L39.8633 37.7059L41.3004 41.0688C41.4165 41.3405 41.6176 41.5672 41.8735 41.7148C42.1294 41.8625 42.4263 41.9231 42.7196 41.8877L52.279 40.7329C52.5044 41.0184 52.8115 41.2284 53.1593 41.3351C53.5071 41.4418 53.8791 41.4401 54.2259 41.3302C54.5727 41.2203 54.8778 41.0075 55.1007 40.7199C55.3235 40.4324 55.4536 40.0838 55.4735 39.7206C55.4934 39.3573 55.4023 38.9966 55.2122 38.6865C55.0221 38.3763 54.7421 38.1313 54.4094 37.9842C54.0767 37.837 53.7071 37.7946 53.3497 37.8627C52.9923 37.9307 52.6642 38.1059 52.4088 38.365H52.4088Z"
        fill="#FFB7B7"/>
      <path d="M24.8286 89.7498H27.6106L28.9341 79.019L24.8281 79.0192L24.8286 89.7498Z" fill="#FFB7B7"/>
      <path
        d="M24.1172 88.8415L29.596 88.8413H29.5963C30.5223 88.8414 31.4103 89.2092 32.0651 89.864C32.7199 90.5188 33.0877 91.4068 33.0878 92.3328V92.4463L24.1174 92.4466L24.1172 88.8415Z"
        fill="#2F2E41"/>
      <path d="M50.0888 89.6879L52.6471 88.5949L49.6488 78.2068L45.873 79.82L50.0888 89.6879Z" fill="#FFB7B7"/>
      <path
        d="M49.0801 89.1314L54.1184 86.9789L54.1186 86.9789C54.9702 86.6152 55.9314 86.6046 56.7907 86.9495C57.6501 87.2944 58.3372 87.9666 58.701 88.8181L58.7456 88.9225L50.4964 92.4466L49.0801 89.1314Z"
        fill="#2F2E41"/>
      <path d="M38.35 40.6898L38.5511 43.9498L32.6708 44.5791L26.5176 45.2376L28.3286 40.2874L38.35 40.6898Z"
            fill="#FFB7B7"/>
      <path
        d="M38.557 41.8593C38.4904 41.8332 31.8484 39.2495 27.7686 41.3621L27.6683 41.4141L26.8544 37.408L26.3906 28.7951L29.4084 27.3692L30.2393 25.508L35.8987 25.1731L37.0617 26.768L41.0213 28.0657L38.6548 41.898L38.557 41.8593Z"
        fill="#E4E4E4"/>
      <path
        d="M38.7656 34.085L39.4656 28.5848L41.0596 28.0996L41.0864 28.1095C41.1232 28.123 41.9895 28.4546 42.2266 29.6539C42.4542 30.805 44.1684 37.9684 44.1857 38.0406L44.2029 38.1123L40.1753 39.7237L38.7656 34.085Z"
        fill="#E4E4E4"/>
      <path
        d="M48.0181 87.8021L53.3794 86.7023L44.8104 63.0574L41.8777 54.9925C41.8777 54.9925 40.0177 48.5261 39.1773 46.0237L38.6701 42.666L26.6644 43.9949C26.6644 43.9949 23.3112 48.9193 24.3299 56.9459L23.8691 87.1147L29.2305 87.3897L33.7212 56.9629L41.6028 73.6426C41.6028 73.6426 44.087 80.7605 45.8644 82.3033L48.0181 87.8021Z"
        fill="#2F2E41"/>
      <path
        d="M31.4778 45.1145C31.3834 45.1123 31.289 45.1175 31.1954 45.1301L25.5945 38.7849L26.246 36.8347L23.4314 35.4668L21.9425 38.8071C21.8222 39.0769 21.7927 39.3785 21.8584 39.6666C21.9241 39.9546 22.0815 40.2136 22.3069 40.4045L29.6532 46.6293C29.5972 46.9887 29.6519 47.3567 29.8102 47.6843C29.9684 48.0119 30.2226 48.2835 30.5389 48.4631C30.8553 48.6427 31.2189 48.7217 31.5812 48.6896C31.9436 48.6575 32.2876 48.5159 32.5675 48.2835C32.8474 48.0511 33.05 47.7391 33.1482 47.3888C33.2464 47.0385 33.2356 46.6666 33.1173 46.3226C32.9991 45.9786 32.7788 45.6787 32.486 45.4629C32.1931 45.2471 31.8415 45.1256 31.4778 45.1145Z"
        fill="#FFB7B7"/>
      <path
        d="M22.9258 36.4068L25.1154 30.1699C25.3615 29.4347 25.6698 28.988 26.0319 28.8423C26.1033 28.8102 26.1805 28.7932 26.2587 28.7925C26.3369 28.7918 26.4144 28.8073 26.4863 28.838L27.4682 30.0785L28.3794 33.9066L26.043 39.4023L22.9258 36.4068Z"
        fill="#E4E4E4"/>
      <path
        d="M37.4188 23.8825H27.8063C27.6086 23.8823 27.419 23.8037 27.2792 23.6638C27.1394 23.524 27.0608 23.3345 27.0605 23.1367V18.9934C27.0605 17.5209 27.6455 16.1088 28.6867 15.0676C29.7279 14.0263 31.1401 13.4414 32.6126 13.4414C34.0851 13.4414 35.4972 14.0263 36.5384 15.0676C37.5796 16.1088 38.1646 17.5209 38.1646 18.9934V23.1367C38.1644 23.3345 38.0857 23.524 37.9459 23.6638C37.8061 23.8037 37.6165 23.8823 37.4188 23.8825Z"
        fill="#2F2E41"/>
      <path
        d="M33.7522 23.7861C36.0003 23.7861 37.8227 21.9637 37.8227 19.7156C37.8227 17.4675 36.0003 15.645 33.7522 15.645C31.5041 15.645 29.6816 17.4675 29.6816 19.7156C29.6816 21.9637 31.5041 23.7861 33.7522 23.7861Z"
        fill="#FFB7B7"/>
      <path
        d="M39.4777 19.4079H33.5999L33.5396 18.564L33.2383 19.4079H32.3332L32.2138 17.7353L31.6165 19.4079H29.8652V19.325C29.8665 18.1606 30.3297 17.0443 31.153 16.2209C31.9764 15.3976 33.0927 14.9344 34.2571 14.9331H35.0858C36.2502 14.9344 37.3665 15.3976 38.1899 16.2209C39.0132 17.0443 39.4764 18.1606 39.4777 19.325L39.4777 19.4079Z"
        fill="#2F2E41"/>
      <path
        d="M33.5512 24.656C33.5069 24.6559 33.4628 24.652 33.4192 24.6443L29.1152 23.8849V16.7717H33.8531L33.7358 16.9085C32.1038 18.8118 33.3333 21.8981 34.2115 23.5684C34.2762 23.6907 34.305 23.8288 34.2947 23.9668C34.2844 24.1048 34.2353 24.2371 34.1531 24.3485C34.0842 24.4437 33.9938 24.5212 33.8891 24.5746C33.7845 24.6281 33.6687 24.656 33.5512 24.656Z"
        fill="#2F2E41"/>
      <path
        d="M85.108 41.7539C84.8397 41.7809 84.5697 41.7872 84.3005 41.7727C84.2546 41.7677 84.2086 41.7809 84.1723 41.8095C84.136 41.8381 84.1125 41.8798 84.1066 41.9256L83.5916 45.7892C83.5519 46.0696 83.4371 46.3341 83.2593 46.5546C83.1126 46.73 83.0037 46.9337 82.9391 47.153C82.8745 47.3723 82.8556 47.6026 82.8838 47.8295V54.3925C82.8838 54.3925 82.8505 55.2676 83.5229 55.2676C84.3276 55.3687 85.1418 55.3687 85.9465 55.2676C85.9465 55.2676 86.4848 55.1657 86.4848 54.3582V47.896C86.5259 47.6705 86.5174 47.4388 86.4601 47.2169C86.4027 46.9951 86.2977 46.7883 86.1525 46.6111C85.9806 46.3898 85.8695 46.1275 85.8302 45.8501L85.2952 41.9056C85.2891 41.8613 85.2664 41.8209 85.2316 41.7927C85.1968 41.7645 85.1526 41.7506 85.108 41.7539Z"
        fill="white"/>
      <path
        d="M84.7335 55.4539C84.3268 55.4539 83.9205 55.4286 83.5169 55.3782C83.4267 55.3804 83.3371 55.3638 83.2536 55.3296C83.1701 55.2955 83.0946 55.2444 83.0318 55.1797C82.7582 54.8955 82.7734 54.4088 82.7742 54.3882L82.7741 47.8362C82.7456 47.5953 82.7664 47.3511 82.8354 47.1185C82.9044 46.8859 83.0201 46.6698 83.1754 46.4835C83.34 46.2789 83.4463 46.0336 83.483 45.7736L83.998 41.9108C84.008 41.8365 84.0466 41.7691 84.1055 41.7227C84.1644 41.6764 84.2391 41.6548 84.3137 41.6625C84.5752 41.6763 84.8374 41.67 85.098 41.6436L85.1011 41.6433C85.1738 41.6381 85.2458 41.6607 85.3025 41.7066C85.3591 41.7525 85.3962 41.8183 85.4061 41.8905L85.9411 45.8351C85.9779 46.0932 86.0813 46.3371 86.2411 46.543C86.3951 46.7311 86.5065 46.9503 86.5679 47.1855C86.6293 47.4207 86.6391 47.6664 86.5967 47.9057V54.3581C86.5967 55.247 85.9745 55.3751 85.9682 55.3763C85.5587 55.428 85.1463 55.4539 84.7335 55.4539ZM84.2866 41.8828C84.2701 41.882 84.2539 41.8873 84.2412 41.8978C84.2284 41.9083 84.2201 41.9231 84.2177 41.9395L83.7025 45.8037C83.6601 46.1043 83.5371 46.3878 83.3466 46.624C83.2096 46.7881 83.1078 46.9785 83.0473 47.1835C82.9868 47.3884 82.9689 47.6036 82.9948 47.8158L82.9957 47.8294V54.3924C82.9954 54.4006 82.9837 54.811 83.1919 55.0266C83.235 55.0704 83.2868 55.1047 83.3441 55.1271C83.4013 55.1495 83.4626 55.1596 83.524 55.1567L83.5378 55.1576C84.3334 55.2575 85.1383 55.2575 85.9338 55.1576C85.9443 55.155 86.3752 55.0567 86.3752 54.3581L86.377 47.876C86.4153 47.6664 86.4076 47.4509 86.3542 47.2445C86.3008 47.0382 86.2031 46.8459 86.068 46.6812C85.8834 46.4441 85.7641 46.1629 85.7217 45.8655L85.1866 41.9204C85.1842 41.9042 85.1759 41.8895 85.1633 41.8791C85.1506 41.8687 85.1346 41.8634 85.1183 41.8642C84.845 41.8916 84.57 41.8979 84.2957 41.8833C84.2926 41.8829 84.2896 41.8828 84.2866 41.8828Z"
        fill="#3F3D56"/>
      <path
        d="M84.2943 47.947H85.1107C85.4353 47.947 85.7466 48.076 85.9761 48.3055C86.2057 48.5351 86.3346 48.8464 86.3346 49.171V52.2426C86.3346 52.5672 86.2057 52.8785 85.9761 53.1081C85.7466 53.3376 85.4353 53.4666 85.1106 53.4666H84.2943C83.9697 53.4666 83.6584 53.3376 83.4288 53.1081C83.1993 52.8785 83.0703 52.5672 83.0703 52.2426V49.171C83.0703 48.8464 83.1993 48.5351 83.4288 48.3055C83.6584 48.076 83.9697 47.947 84.2943 47.947Z"
        fill="#F7B80B"/>
      <path
        d="M57.4049 36.4323C57.1366 36.4594 56.8666 36.4657 56.5974 36.4512C56.5515 36.4462 56.5054 36.4594 56.4692 36.488C56.4329 36.5165 56.4093 36.5582 56.4035 36.604L55.8884 40.4676C55.8488 40.7481 55.734 41.0126 55.5561 41.233C55.4095 41.4085 55.3005 41.6122 55.2359 41.8315C55.1713 42.0508 55.1525 42.2811 55.1806 42.508V49.071C55.1806 49.071 55.1474 49.946 55.8198 49.946C56.6245 50.0471 57.4387 50.0471 58.2434 49.946C58.2434 49.946 58.7817 49.8441 58.7817 49.0366V42.5744C58.8228 42.349 58.8143 42.1173 58.7569 41.8954C58.6996 41.6735 58.5946 41.4668 58.4494 41.2895C58.2775 41.0683 58.1664 40.806 58.1271 40.5285L57.592 36.5841C57.586 36.5398 57.5632 36.4994 57.5285 36.4712C57.4937 36.443 57.4495 36.4291 57.4049 36.4323Z"
        fill="white"/>
      <path
        d="M57.0303 50.1326C56.6236 50.1326 56.2173 50.1074 55.8138 50.0569C55.7236 50.0591 55.6339 50.0425 55.5505 50.0084C55.467 49.9742 55.3915 49.9231 55.3287 49.8584C55.0551 49.5742 55.0703 49.0875 55.0711 49.0669L55.071 42.5149C55.0424 42.274 55.0633 42.0298 55.1323 41.7972C55.2013 41.5646 55.317 41.3486 55.4723 41.1622C55.6369 40.9576 55.7432 40.7123 55.7799 40.4523L56.2949 36.5896C56.3049 36.5153 56.3435 36.4478 56.4024 36.4014C56.4613 36.3551 56.536 36.3335 56.6105 36.3412C56.8721 36.355 57.1343 36.3487 57.3949 36.3223L57.398 36.322C57.4707 36.3168 57.5427 36.3394 57.5994 36.3853C57.656 36.4312 57.693 36.497 57.7029 36.5692L58.238 40.5139C58.2748 40.7719 58.3782 41.0158 58.538 41.2217C58.6919 41.4098 58.8034 41.629 58.8648 41.8642C58.9262 42.0994 58.936 42.3451 58.8936 42.5844V49.0368C58.8936 49.9257 58.2714 50.0539 58.2651 50.055C57.8555 50.1067 57.4431 50.1326 57.0303 50.1326ZM56.5834 36.5615C56.567 36.5607 56.5508 36.5661 56.538 36.5765C56.5253 36.587 56.5169 36.6018 56.5145 36.6182L55.9994 40.4824C55.957 40.783 55.834 41.0665 55.6435 41.3028C55.5065 41.4668 55.4046 41.6572 55.3441 41.8622C55.2836 42.0671 55.2658 42.2823 55.2917 42.4945L55.2925 42.5081V49.0711C55.2923 49.0793 55.2806 49.4897 55.4888 49.7053C55.5319 49.7491 55.5837 49.7834 55.6409 49.8058C55.6982 49.8282 55.7595 49.8383 55.8209 49.8355L55.8347 49.8363C56.6302 49.9362 57.4351 49.9362 58.2307 49.8363C58.2412 49.8337 58.6721 49.7354 58.6721 49.0368L58.6739 42.5547C58.7122 42.3451 58.7044 42.1296 58.6511 41.9232C58.5977 41.7169 58.5 41.5246 58.3648 41.3599C58.1803 41.1228 58.0609 40.8417 58.0185 40.5443L57.4834 36.5991C57.4811 36.5829 57.4728 36.5682 57.4601 36.5578C57.4475 36.5474 57.4315 36.5421 57.4151 36.5429C57.1418 36.5703 56.8668 36.5766 56.5925 36.562C56.5895 36.5616 56.5865 36.5615 56.5834 36.5615Z"
        fill="#3F3D56"/>
      <path
        d="M56.5912 42.6255H57.4075C57.7321 42.6255 58.0435 42.7544 58.273 42.984C58.5026 43.2135 58.6315 43.5249 58.6315 43.8495V46.9211C58.6315 47.2457 58.5026 47.557 58.273 47.7866C58.0435 48.0161 57.7321 48.1451 57.4075 48.1451H56.5912C56.2665 48.1451 55.9552 48.0161 55.7257 47.7866C55.4961 47.557 55.3672 47.2457 55.3672 46.9211V43.8495C55.3672 43.5249 55.4961 43.2135 55.7257 42.984C55.9552 42.7544 56.2665 42.6255 56.5912 42.6255Z"
        fill="#F7B80B"/>
      <path
        d="M52.7095 30.7978C52.4413 30.8249 52.1713 30.8311 51.902 30.8167C51.8561 30.8116 51.8101 30.8248 51.7739 30.8534C51.7376 30.882 51.714 30.9237 51.7082 30.9695L51.1931 34.8331C51.1535 35.1136 51.0387 35.3781 50.8608 35.5985C50.7142 35.7739 50.6052 35.9776 50.5406 36.197C50.476 36.4163 50.4572 36.6465 50.4853 36.8734V43.4364C50.4853 43.4364 50.4521 44.3115 51.1245 44.3115C51.9292 44.4126 52.7434 44.4126 53.5481 44.3115C53.5481 44.3115 54.0864 44.2096 54.0864 43.4021V36.9399C54.1275 36.7144 54.119 36.4828 54.0616 36.2609C54.0042 36.039 53.8993 35.8323 53.7541 35.655C53.5822 35.4337 53.4711 35.1714 53.4318 34.894L52.8967 30.9496C52.8907 30.9052 52.8679 30.8649 52.8332 30.8367C52.7984 30.8085 52.7542 30.7946 52.7095 30.7978Z"
        fill="white"/>
      <path
        d="M52.335 44.4981C51.9283 44.4981 51.522 44.4728 51.1184 44.4224C51.0283 44.4245 50.9386 44.408 50.8552 44.3738C50.7717 44.3397 50.6962 44.2886 50.6334 44.2239C50.3598 43.9397 50.375 43.453 50.3758 43.4324L50.3757 36.8804C50.3471 36.6395 50.368 36.3953 50.437 36.1627C50.506 35.9301 50.6217 35.714 50.777 35.5277C50.9416 35.3231 51.0479 35.0778 51.0846 34.8178L51.5995 30.955C51.6096 30.8807 51.6481 30.8133 51.7071 30.7669C51.766 30.7206 51.8406 30.699 51.9152 30.7067C52.1768 30.7205 52.439 30.7142 52.6996 30.6878L52.7027 30.6875C52.7754 30.6822 52.8474 30.7049 52.904 30.7508C52.9607 30.7967 52.9977 30.8624 53.0076 30.9347L53.5427 34.8793C53.5794 35.1373 53.6828 35.3813 53.8427 35.5872C53.9966 35.7753 54.1081 35.9944 54.1695 36.2297C54.2309 36.4649 54.2407 36.7106 54.1983 36.9499V43.4022C54.1983 44.2912 53.5761 44.4193 53.5698 44.4205C53.1602 44.4721 52.7478 44.498 52.335 44.4981ZM51.8881 30.927C51.8716 30.9262 51.8555 30.9315 51.8427 30.942C51.83 30.9525 51.8216 30.9673 51.8192 30.9836L51.3041 34.8479C51.2617 35.1485 51.1387 35.432 50.9482 35.6682C50.8112 35.8323 50.7093 36.0227 50.6488 36.2277C50.5883 36.4326 50.5705 36.6478 50.5964 36.86L50.5972 36.8736V43.4366C50.597 43.4448 50.5852 43.8552 50.7935 44.0708C50.8366 44.1146 50.8884 44.1489 50.9456 44.1713C51.0029 44.1937 51.0642 44.2038 51.1256 44.2009L51.1394 44.2018C51.9349 44.3016 52.7398 44.3016 53.5354 44.2018C53.5459 44.1992 53.9768 44.1009 53.9768 43.4022L53.9785 36.9202C54.0169 36.7106 54.0091 36.4951 53.9557 36.2887C53.9024 36.0824 53.8047 35.8901 53.6695 35.7253C53.485 35.4883 53.3656 35.2071 53.3232 34.9097L52.7881 30.9646C52.7858 30.9484 52.7775 30.9337 52.7648 30.9233C52.7522 30.9129 52.7362 30.9076 52.7198 30.9084C52.4465 30.9358 52.1715 30.9421 51.8972 30.9274C51.8942 30.9271 51.8912 30.927 51.8881 30.927Z"
        fill="#3F3D56"/>
      <path
        d="M51.8959 36.991H52.7122C53.0368 36.991 53.3482 37.1199 53.5777 37.3495C53.8072 37.579 53.9362 37.8903 53.9362 38.215V41.2865C53.9362 41.6112 53.8072 41.9225 53.5777 42.152C53.3482 42.3816 53.0368 42.5105 52.7122 42.5105H51.8959C51.5712 42.5105 51.2599 42.3816 51.0304 42.152C50.8008 41.9225 50.6719 41.6112 50.6719 41.2865V38.215C50.6719 37.8903 50.8008 37.579 51.0304 37.3495C51.2599 37.1199 51.5712 36.991 51.8959 36.991Z"
        fill="#F7B80B"/>
      <path
        d="M9.45972 11.8316C11.6207 11.8316 13.3726 10.0797 13.3726 7.91871C13.3726 5.7577 11.6207 4.00586 9.45972 4.00586C7.29872 4.00586 5.54688 5.7577 5.54688 7.91871C5.54688 10.0797 7.29872 11.8316 9.45972 11.8316Z"
        fill="#F0F0F0"/>
      <path
        d="M99.6121 8.54469C101.773 8.54469 103.525 6.79285 103.525 4.63184C103.525 2.47084 101.773 0.718994 99.6121 0.718994C97.4511 0.718994 95.6992 2.47084 95.6992 4.63184C95.6992 6.79285 97.4511 8.54469 99.6121 8.54469Z"
        fill="#F0F0F0"/>
      <path
        d="M54.8485 18.405C56.4044 18.405 57.6657 17.1437 57.6657 15.5878C57.6657 14.0318 56.4044 12.7705 54.8485 12.7705C53.2926 12.7705 52.0312 14.0318 52.0312 15.5878C52.0312 17.1437 53.2926 18.405 54.8485 18.405Z"
        fill="#F0F0F0"/>
    </g>
    <defs>
      <clipPath id="clip0_946_5453">
        <rect width="114.669" height="100" fill="white" transform="translate(0.666016 0.718994)"/>
      </clipPath>
    </defs>
  </svg>


);