export const DressingMakeupIcon = () => (
  <svg
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3250_90459)">
      <path
        d="M46.8296 46.3421H30.5796V24.3496L44.6303 32.047L46.8296 46.3421Z"
        fill="#2F2E41"
      />
      <path
        d="M33.5814 42.4597C39.4081 42.4597 44.1316 37.7362 44.1316 31.9095C44.1316 26.0828 39.4081 21.3594 33.5814 21.3594C27.7547 21.3594 23.0312 26.0828 23.0312 31.9095C23.0312 37.7362 27.7547 42.4597 33.5814 42.4597Z"
        fill="#FFB6B6"
      />
      <path
        d="M27.5253 44.0196L27.0487 39.2016L36.5667 37.4219L42.187 51.1061L32.4125 62.3467L25.4482 48.6625L27.5253 44.0196Z"
        fill="#FFB6B6"
      />
      <path
        d="M56.3412 55.1445C56.2226 55.2679 56.1017 55.3901 55.9807 55.511C55.5971 55.8947 55.2061 56.2673 54.8066 56.629C48.8197 62.0587 41.1382 65.0301 32.9998 65.0301C25.4967 65.0301 18.3821 62.5046 12.6262 57.8557C12.6225 57.8532 12.6201 57.8508 12.6164 57.8471C12.4417 57.7066 12.2694 57.5636 12.0972 57.4182C12.1277 57.3303 12.1607 57.2435 12.1937 57.158C14.0447 52.3355 17.7004 49.9762 19.9192 48.9438C21.0188 48.4319 21.7653 48.2449 21.7653 48.2449L24.3702 43.5532L26.2041 46.9743L27.5554 49.4961L33.1 59.8411L37.8834 47.9309L39.3654 44.2386L39.7454 43.293L41.5524 44.6993L43.263 46.0298L46.7072 46.7898L49.5015 47.4068L53.2964 48.2449C54.831 49.4948 55.7645 51.8725 56.2862 54.8268C56.3057 54.9319 56.3241 55.0382 56.3412 55.1445Z"
        fill="#FFB61C"
      />
      <path
        d="M28.0321 19.9197C29.3172 17.7611 31.6775 16.2799 34.1802 16.0617C36.683 15.8434 39.264 16.8935 40.9034 18.7971C41.8804 19.9314 42.5209 21.3207 43.4732 22.4758C45.5224 24.9616 49.0168 26.4151 49.912 29.5098C50.3026 30.8603 50.108 32.302 49.9103 33.6939C49.7409 34.8869 49.5715 36.0798 49.4021 37.2728C49.2702 38.2012 49.1438 39.18 49.4919 40.0507C49.9004 41.0722 50.8778 41.7413 51.5097 42.6418C52.585 44.1742 52.5064 46.4176 51.3265 47.8709C50.8148 48.5012 50.1364 48.9765 49.5999 49.5858C49.0635 50.1952 48.6655 51.0283 48.8902 51.8084C49.3206 53.3028 51.6643 53.6612 51.9213 55.195C52.0527 55.9791 51.5111 56.7622 50.8014 57.1208C50.0918 57.4794 49.2564 57.4898 48.4671 57.3939C45.4671 57.0293 42.6895 55.1548 41.2289 52.5092C39.7683 49.8635 39.6619 46.5143 40.9518 43.7813C42.0111 41.5367 43.98 39.5532 43.9586 37.0713C43.9469 35.7191 43.3267 34.4562 42.7202 33.2476C41.479 30.774 40.2377 28.3004 38.9965 25.8267C38.3316 24.5017 37.5599 23.0738 36.1911 22.5046C35.1331 22.0646 33.9328 22.2353 32.8012 22.4152C31.6697 22.5951 30.4711 22.7703 29.41 22.338C28.3489 21.9057 27.5472 20.6214 28.0527 19.5932"
        fill="#2F2E41"
      />
      <path
        d="M29.8808 20.0332C27.8425 19.3622 25.556 20.4393 24.1761 22.0827C22.7961 23.7261 22.1268 25.8344 21.4891 27.8833C21.1238 29.057 20.7586 30.2306 20.3933 31.4043C20.0497 32.5083 19.7061 33.6124 19.3625 34.7164C18.9583 36.015 18.5509 37.3947 18.8826 38.7137C19.1224 39.6672 19.7394 40.5331 19.7526 41.5162C19.7665 42.5432 19.1234 43.4449 18.5597 44.3035C17.9961 45.1621 17.467 46.1823 17.7355 47.1736C18.144 48.6816 20.2405 49.5104 20.1256 51.0684C20.0872 51.5884 19.7865 52.0694 19.792 52.5907C19.7989 53.254 20.3183 53.8272 20.9267 54.0915C21.5352 54.3558 22.2196 54.3701 22.8829 54.3585C24.9174 54.3227 26.9481 54.0811 28.9342 53.6385C29.6983 53.4683 30.4754 53.2594 31.1114 52.803C31.7475 52.3466 32.222 51.5904 32.1392 50.812C32.0102 49.5989 30.7059 48.9107 30.0477 47.8835C29.4012 46.8747 29.4162 45.5964 29.1374 44.431C28.7543 42.8294 27.8081 41.4312 27.0977 39.9455C24.4273 34.3599 25.3621 27.2403 29.384 22.5334C29.7396 22.1172 30.1281 21.6986 30.2765 21.1716C30.4248 20.6447 30.2336 19.9734 29.7182 19.7891"
        fill="#2F2E41"
      />
      <path
        d="M55.9809 9.54837C49.8426 3.41003 41.6809 0.0292969 33 0.0292969C24.3191 0.0292969 16.1574 3.41003 10.0191 9.54837C3.88073 15.6867 0.5 23.8484 0.5 32.5293C0.5 40.4527 3.31626 47.9436 8.47716 53.8583C8.96955 54.424 9.48393 54.9751 10.0191 55.5102C10.1449 55.6361 10.272 55.7619 10.4003 55.8841C10.7424 56.2152 11.0906 56.539 11.4449 56.853C11.66 57.0448 11.8774 57.233 12.0974 57.4175C12.2696 57.5628 12.4419 57.7058 12.6166 57.8463C12.6203 57.85 12.6228 57.8524 12.6264 57.8549C18.3823 62.5038 25.4969 65.0293 33 65.0293C41.1384 65.0293 48.8199 62.0579 54.8068 56.6282C55.2063 56.2665 55.5973 55.8939 55.9809 55.5102C56.1019 55.3893 56.2228 55.2671 56.3414 55.1437C56.6712 54.804 56.9914 54.4582 57.3041 54.1076C62.6031 48.155 65.5 40.5651 65.5 32.5293C65.5 23.8484 62.1193 15.6867 55.9809 9.54837ZM57.2247 53.8034C56.9193 54.1503 56.6065 54.4924 56.2864 54.826C55.7879 55.3453 55.2735 55.8487 54.742 56.3337C54.5404 56.5194 54.3364 56.7003 54.1299 56.8799C52.0443 58.6942 49.7228 60.2435 47.2194 61.4775C46.3409 61.9113 45.4404 62.3059 44.5192 62.659C40.9405 64.0323 37.0564 64.7849 33 64.7849C29.0853 64.7849 25.332 64.0836 21.8584 62.8007C20.4325 62.2753 19.0543 61.651 17.7311 60.9363C17.5368 60.8312 17.3438 60.7249 17.152 60.6161C16.1428 60.0443 15.1665 59.42 14.2294 58.7455C13.5293 58.2434 12.85 57.7143 12.1939 57.1572C11.9642 56.9629 11.7369 56.7662 11.5133 56.5646C11.1492 56.2408 10.7925 55.9073 10.4443 55.5652C9.78449 54.9189 9.15404 54.2456 8.5517 53.5455C3.68769 47.8959 0.744361 40.5504 0.744361 32.5293C0.744361 14.7435 15.2142 0.273658 33 0.273658C50.7858 0.273658 65.2556 14.7435 65.2556 32.5293C65.2556 40.6738 62.2219 48.122 57.2247 53.8034Z"
        fill="#3F3D56"
      />
    </g>
    <defs>
      <clipPath id="clip0_3250_90459">
        <rect
          width="65"
          height="65"
          fill="white"
          transform="translate(0.5 0.0292969)"
        />
      </clipPath>
    </defs>
  </svg>
);
