import { Box } from "@chakra-ui/react";

const GrayLayout: typeof Box = (props) => (
  <Box
    className="grayLayout"
    border="1px solid #D9D9D9"
    borderRadius="24px"
    height="fit-content"
    padding={["24px", "24px", "24px", "24px", "40px"]}
    {...props}
  />
);

export default GrayLayout;
