import { FC } from "react";
import Layout from "../../components/Layout";
import ValidationContent from "../../modules/Validation/ValidationContent";

const Validation: FC = () => {
  return (
    <Layout>
      <ValidationContent />
    </Layout>
  );
};

export default Validation;
