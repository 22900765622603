import { Flex, Text } from "@chakra-ui/react";
import { FC, ReactElement } from "react";

import BottomOutfitSelectionCard from "./BottomOutfitSelectionCard";
import { DressCode } from "./DressingBlock";
import ShoesOutfitSelectionCard from "./ShoesOutfitSelectionCard";
import TopOutfitSelectionCard from "./TopOutfitSelectionCard";

interface PrintedOutfitSubMenuProps {
  dressCode?: DressCode;
  setDressCode: (element: DressCode) => void;
}

const PrintedOutfitSubMenu: FC<PrintedOutfitSubMenuProps> = ({
  dressCode,
  setDressCode,
}): ReactElement => {
  const selectBottom = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      printedOutfit: {
        ...dressCode?.printedOutfit,
        bottom: value,
        bottomColor: "#000",
      },
    });
  };
  const selectBottomColor = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      printedOutfit: {
        ...dressCode?.printedOutfit,
        bottomColor: value,
      },
    });
  };
  const selectShoes = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      printedOutfit: {
        ...dressCode?.printedOutfit,
        shoes: value,
        shoesColor: "#000",
      },
    });
  };
  const selectShoesColor = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      printedOutfit: {
        ...dressCode?.printedOutfit,
        shoesColor: value,
      },
    });
  };

  const selectTop = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      printedOutfit: {
        ...dressCode?.printedOutfit,
        printedTshirt: false,
        top: value,
        topColor: "#fff",
      },
    });
  };

  const selectBlazer = (value: boolean) => {
    setDressCode({
      ...dressCode,
      printedOutfit: {
        ...dressCode?.printedOutfit,
        blazer: value,
      },
    });
  };

  return (
    <Flex marginTop="1rem" width="100%" gap="24px" flexDir="column">
      <Text variant="2xl" fontWeight="700" textAlign="left">
        Choisissez le reste de leur tenue
      </Text>
      {!dressCode?.printedOutfit?.printedTshirt ? (
        <TopOutfitSelectionCard
          selectedOutfit={dressCode?.printedOutfit}
          onSelectTop={selectTop}
          onSelectBlazer={selectBlazer}
          width="300px"
        />
      ) : (
        <></>
      )}
      <BottomOutfitSelectionCard
        selectedOutfit={dressCode?.printedOutfit}
        onSelectBottom={selectBottom}
        onSelectBottomColor={selectBottomColor}
        width="300px"
      />
      <ShoesOutfitSelectionCard
        selectedOutfit={dressCode?.printedOutfit}
        onSelectShoes={selectShoes}
        onSelectShoesColor={selectShoesColor}
        width="300px"
      />
    </Flex>
  );
};

export default PrintedOutfitSubMenu;
