export const DressingRoomIcon = () => (
  <svg
    width="134"
    height="164"
    viewBox="0 0 134 164"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.37066 20.2212L6.86389 41.8072C7.50709 43.9138 7.81283 46.1101 7.76964 48.3135C7.66065 51.9825 6.75971 56.1923 6.71884 58.0412C6.7024 58.2862 6.72316 58.5322 6.7804 58.7709C10.0865 69.2103 29.0883 60.2469 30.8164 59.4065C30.8985 59.3676 30.9422 59.3456 30.9422 59.3456L30.8441 47.7381L30.9858 44.4046L31.9618 21.5369L31.576 21.2109L28.1958 18.3634L27.8647 18.0846L26.794 19.5794L17.5821 18.7215L14.0839 17.6572L12.9748 18.0805L7.97513 19.9903L7.37066 20.2212Z"
      fill="#2F2E41"
    />
    <path
      d="M7.97425 20.1889C9.97888 20.7627 11.0685 23.4218 11.7308 25.4109C12.5834 27.9665 10.7374 43.553 8.88445 45.7888C7.44728 47.5229 7.78669 47.1911 8.213 48.6657C8.63931 50.1404 9.73707 48.7381 8.72791 50.8925C7.71876 53.047 6.91243 52.6348 7.52874 53.5782C8.14506 54.5217 8.89232 54.4325 8.04366 55.805C7.19499 57.1776 7.25407 57.6791 7.06405 58.2103C6.87403 58.7415 6.56981 62.5916 6.56981 62.5916C6.56981 62.5916 2.43672 61.8136 0.254007 62.5826C0.254007 62.5826 1.83322 58.8346 1.24644 58.1419C0.659654 57.4491 -0.117156 57.2876 0.541503 56.4462C1.20016 55.6049 2.47514 55.7069 1.14109 55.1034C-0.192964 54.4999 1.13322 46.4597 1.13322 46.4597C-0.254696 41.1138 0.985768 33.1966 2.71488 24.8013C2.97066 23.5598 3.62331 22.4367 4.57307 21.6038C5.52282 20.7708 6.71742 20.2739 7.97425 20.1889V20.1889Z"
      fill="#2F2E41"
    />
    <path
      d="M30.0605 20.8242L30.1698 20.8398C31.1862 20.9851 32.1521 21.3775 32.9838 21.9832C33.8156 22.5888 34.4882 23.3894 34.9433 24.3156C36.1322 26.7313 39.6914 42.9854 38.675 45.1803C37.7271 47.2271 37.7762 46.7194 38.3972 48.1223C39.0182 49.5251 39.9169 47.9859 39.2073 50.2583C38.4976 52.5306 37.6432 52.2322 38.3809 53.0828C39.1186 53.9334 39.8469 53.7431 39.191 55.2188C38.535 56.6945 38.6611 57.1833 38.5443 57.7355C38.4276 58.2877 38.6448 62.1438 38.6448 62.1438C38.6448 62.1438 34.4451 61.937 32.3861 62.9968C32.3861 62.9968 33.4459 59.0679 32.7712 58.4616C32.0966 57.8554 31.3052 57.8013 31.8444 56.8778C32.3837 55.9544 33.6606 55.8815 32.2576 55.4656C30.8546 55.0497 31.0857 46.9028 31.0857 46.9028L30.0605 20.8242Z"
      fill="#2F2E41"
    />
    <path
      d="M65.5839 28.6191H46.2979V40.1138H65.5839V28.6191Z"
      fill="#2F2E41"
    />
    <path
      d="M59.888 153.842H56.7768L55.2969 141.763H59.8885L59.888 153.842Z"
      fill="#FFB6B6"
    />
    <path
      d="M60.6809 156.877L50.6494 156.876V156.749C50.6495 155.706 51.0609 154.707 51.7931 153.97C52.5253 153.232 53.5184 152.818 54.554 152.818H54.5542L60.6811 152.819L60.6809 156.877Z"
      fill="#2F2E41"
    />
    <path
      d="M48.4681 153.842H45.3569L43.877 141.763H48.4686L48.4681 153.842Z"
      fill="#FFB6B6"
    />
    <path
      d="M49.262 156.877L39.2305 156.876V156.749C39.2305 155.706 39.6419 154.707 40.3742 153.97C41.1064 153.232 42.0995 152.818 43.135 152.818H43.1353L49.2622 152.819L49.262 156.877Z"
      fill="#2F2E41"
    />
    <path
      d="M39.3317 78.208C39.3317 78.208 34.7861 88.0614 38.8927 107.895C42.9992 127.728 43.0332 149.502 43.0332 149.502L49.3122 149.605L52.8396 102.728L54.6863 148.944H60.8726L66.3246 103.458C66.3246 103.458 69.9122 83.1478 61.9117 79.5113L39.3317 78.208Z"
      fill="#2F2E41"
    />
    <path
      d="M61.0894 43.4339L47.341 42.1582C43.0296 45.3214 40.6098 51.0027 41.1066 56.8645C41.7801 64.8123 41.5522 75.75 36.1475 80.5017C36.1475 80.5017 50.8783 92.2046 63.9639 81.1019C63.9639 81.1019 59.4893 69.0357 62.5614 64.2172C65.6336 59.3986 66.7419 45.8605 61.0894 43.4339Z"
      fill="#E4E4E4"
    />
    <path
      d="M20.1813 23.7954C20.1392 24.1943 20.1844 24.5977 20.3139 24.9771C20.4433 25.3565 20.6539 25.7027 20.9306 25.9914C21.2074 26.28 21.5437 26.504 21.9158 26.6477C22.2879 26.7914 22.6868 26.8512 23.0843 26.8229L41.2369 43.8752L44.4904 38.8866L25.6239 23.6934C25.5325 23.0243 25.2003 22.4122 24.6905 21.9731C24.1806 21.534 23.5285 21.2985 22.8576 21.311C22.1867 21.3236 21.5438 21.5834 21.0505 22.0413C20.5572 22.4992 20.248 23.1233 20.1813 23.7954V23.7954Z"
      fill="#FFB6B6"
    />
    <path
      d="M37.8465 41.2408L42.1528 36.7881C42.2754 36.6614 42.4236 36.5628 42.5874 36.499C42.7512 36.4352 42.9267 36.4076 43.1021 36.4182C43.2774 36.4289 43.4484 36.4774 43.6034 36.5605C43.7585 36.6437 43.8938 36.7595 44.0004 36.9L47.7338 41.8255C48.3741 42.4602 48.7382 43.3246 48.7464 44.2291C48.7546 45.1337 48.4061 46.0046 47.7773 46.6509C47.1486 47.2972 46.2909 47.6662 45.3923 47.677C44.4937 47.6878 43.6275 47.3394 42.9836 46.7084L38.0072 43.0969C37.8647 42.9935 37.7461 42.8603 37.6594 42.7066C37.5727 42.5528 37.5199 42.3821 37.5046 42.2059C37.4894 42.0298 37.512 41.8524 37.571 41.6859C37.63 41.5193 37.724 41.3675 37.8465 41.2408V41.2408Z"
      fill="#E4E4E4"
    />
    <path
      d="M87.6211 65.3215C87.6412 64.9209 87.5737 64.5206 87.4235 64.1491C87.2733 63.7775 87.044 63.4436 86.7517 63.1709C86.4594 62.8981 86.1113 62.6932 85.7318 62.5706C85.3524 62.4479 84.9508 62.4105 84.5554 62.461L65.4897 46.4502L62.5166 51.6131L82.1925 65.7277C82.3207 66.3907 82.6861 66.9832 83.2194 67.3931C83.7527 67.803 84.4169 68.0018 85.086 67.9517C85.7552 67.9016 86.3828 67.6062 86.85 67.1215C87.3173 66.6367 87.5916 65.9963 87.6211 65.3215V65.3215Z"
      fill="#FFB6B6"
    />
    <path
      d="M69.0193 48.8911L64.9653 53.5778C64.85 53.7111 64.7075 53.8179 64.5474 53.8908C64.3874 53.9637 64.2136 54.001 64.038 54.0002C63.8623 53.9994 63.6889 53.9605 63.5295 53.8862C63.3702 53.8118 63.2286 53.7038 63.1144 53.5694L59.115 48.8603C58.4405 48.2624 58.0291 47.4197 57.971 46.517C57.9129 45.6142 58.2127 44.7251 58.8049 44.0446C59.397 43.364 60.2331 42.9476 61.1298 42.8867C62.0265 42.8257 62.9106 43.1252 63.5883 43.7194L68.7564 47.0469C68.9043 47.1422 69.0301 47.2685 69.1252 47.4172C69.2203 47.5659 69.2824 47.7334 69.3073 47.9084C69.3323 48.0834 69.3195 48.2618 69.2698 48.4314C69.2201 48.601 69.1346 48.7578 69.0193 48.8911V48.8911Z"
      fill="#E4E4E4"
    />
    <path
      d="M89.5583 72.0918H82.0264V69.448C82.0264 68.4426 82.4231 67.4784 83.1294 66.7675C83.8357 66.0566 84.7936 65.6572 85.7924 65.6572C86.7912 65.6572 87.749 66.0566 88.4553 66.7675C89.1616 67.4784 89.5584 68.4426 89.5584 69.448L89.5583 72.0918ZM82.1931 71.9239H89.3916V69.448C89.3916 68.4872 89.0124 67.5657 88.3374 66.8862C87.6624 66.2068 86.7469 65.8251 85.7923 65.8251C84.8378 65.8251 83.9223 66.2068 83.2473 66.8862C82.5723 67.5657 82.1931 68.4872 82.1931 69.448V71.9239Z"
      fill="#3F3D56"
    />
    <path
      d="M93.2824 71.7286V70.6953L74.1277 71.2121L73.5215 97.3827L76.3563 100.908L93.2824 71.7286Z"
      fill="#3F3D56"
    />
    <path
      d="M96.6726 71.3643L75.9111 71.8399L76.3557 100.907L96.3917 98.7334L96.6726 71.3643Z"
      fill="#F7B80B"
    />
    <path
      d="M55.18 39.8586C59.6648 39.8586 63.3005 36.199 63.3005 31.6847C63.3005 27.1703 59.6648 23.5107 55.18 23.5107C50.6952 23.5107 47.0596 27.1703 47.0596 31.6847C47.0596 36.199 50.6952 39.8586 55.18 39.8586Z"
      fill="#FFB6B6"
    />
    <path
      d="M51.4412 28.0418C54.2503 30.8129 59.1389 31.0785 62.228 28.6278C63.0855 31.6045 63.1793 34.7513 62.5009 37.7744C61.8224 40.7975 60.3938 43.5981 58.3485 45.9147C60.0669 46.5397 62.0451 46.1285 63.581 45.1324C65.117 44.1362 66.2545 42.6152 67.1129 40.9916C69.2378 36.9725 69.7698 32.0649 68.2665 27.7708C66.7632 23.4767 63.1616 19.9161 58.7859 18.7631C54.4103 17.6101 49.3932 19.0343 46.4698 22.5084C43.4128 26.1413 42.9176 31.4644 44.2572 36.0303C45.5968 40.5963 48.535 44.5174 51.7633 47.9968C50.2127 43.9833 49.1844 39.7847 48.7041 35.5056C48.5399 34.0401 48.4439 32.5294 48.8552 31.114C49.2665 29.6985 50.2788 28.38 51.6897 27.9823"
      fill="#2F2E41"
    />
    <path
      d="M127.573 157.326H3.87739C3.79129 157.326 3.70872 157.299 3.64783 157.251C3.58694 157.204 3.55273 157.139 3.55273 157.071C3.55273 157.003 3.58694 156.938 3.64783 156.89C3.70872 156.842 3.79129 156.815 3.87739 156.815H127.573C127.659 156.815 127.741 156.842 127.802 156.89C127.863 156.938 127.897 157.003 127.897 157.071C127.897 157.139 127.863 157.204 127.802 157.251C127.741 157.299 127.659 157.326 127.573 157.326Z"
      fill="#CACACA"
    />
    <path
      d="M85.4978 135.185H77.9658V132.541C77.9658 131.535 78.3626 130.571 79.0689 129.86C79.7751 129.149 80.733 128.75 81.7318 128.75C82.7306 128.75 83.6885 129.149 84.3948 129.86C85.101 130.571 85.4978 131.535 85.4978 132.541L85.4978 135.185ZM78.1326 135.017H85.331V132.541C85.331 131.58 84.9518 130.658 84.2768 129.979C83.6018 129.3 82.6864 128.918 81.7318 128.918C80.7772 128.918 79.8617 129.3 79.1868 129.979C78.5118 130.658 78.1326 131.58 78.1326 132.541V135.017Z"
      fill="#CACACA"
    />
    <path
      d="M89.2218 134.82V133.787L70.0672 134.304L69.4609 160.475L72.2957 164L89.2218 134.82Z"
      fill="#CACACA"
    />
    <path
      d="M92.613 134.457L71.8516 134.933L72.2961 164L92.3321 161.826L92.613 134.457Z"
      fill="#E4E4E4"
    />
    <rect x="102.245" y="1" width="1.61933" height="138.55" fill="#3D3D3D" />
    <path
      d="M94.8759 12.0701C94.8196 12.6776 95.7635 18.8378 95.7635 18.8378L93.4229 43.9639L109.255 43.1396L118.131 12.6854C118.448 12.4454 116.673 8.89068 115.468 7.45589C113.806 5.47668 95.2177 8.37716 94.8759 12.0701Z"
      fill="#3F3D56"
    />
    <path
      d="M95.7635 12.3779L94.8759 11.7627C94.8759 11.7627 91.6806 20.376 92.7457 21.2989C93.8108 22.2217 96.4736 24.375 96.4736 24.375L95.7635 12.3779Z"
      fill="#3F3D56"
    />
    <path
      d="M116.001 10.2246L118.131 12.6856C118.131 12.6856 119.906 24.6827 118.131 24.3751C116.356 24.0674 112.273 25.9132 112.095 25.2979C111.918 24.6827 116.001 10.2246 116.001 10.2246Z"
      fill="#3F3D56"
    />
    <path
      d="M105.784 4.50977H108.892C109.403 4.50977 109.887 4.74483 110.202 5.14731C111.06 6.24044 110.281 7.8413 108.892 7.8413H105.784C104.448 7.8413 103.656 6.34725 104.405 5.24113C104.715 4.78373 105.232 4.50977 105.784 4.50977Z"
      stroke="#898989"
      strokeWidth="0.5"
    />
    <path
      d="M126.514 14.4856L126.987 34.6448C126.386 36.6121 126.101 38.6633 126.141 40.721C126.243 44.1476 127.084 48.0791 127.123 49.8058C127.138 50.0345 127.118 50.2643 127.065 50.4872C123.977 60.2366 106.232 51.8656 104.618 51.0808C104.541 51.0444 104.5 51.0239 104.5 51.0239L104.592 40.1837L104.46 37.0705L103.548 15.7143L103.908 15.4099L107.065 12.7506L107.374 12.4903L108.374 13.8863L116.977 13.0851L120.244 12.0911L121.28 12.4864L125.949 14.27L126.514 14.4856Z"
      fill="#F7B80B"
    />
    <path
      d="M125.949 14.4553C124.077 14.9912 123.06 17.4745 122.441 19.3321C121.645 21.7188 123.369 36.275 125.099 38.3631C126.442 39.9826 126.125 39.6727 125.726 41.0499C125.328 42.427 124.303 41.1174 125.246 43.1295C126.188 45.1416 126.941 44.7566 126.365 45.6376C125.79 46.5187 125.092 46.4354 125.885 47.7173C126.677 48.9991 126.622 49.4674 126.799 49.9635C126.977 50.4596 127.261 54.0552 127.261 54.0552C127.261 54.0552 131.121 53.3287 133.159 54.0469C133.159 54.0469 131.684 50.5466 132.232 49.8996C132.78 49.2527 133.506 49.1018 132.891 48.3161C132.276 47.5303 131.085 47.6256 132.331 47.062C133.577 46.4984 132.338 38.9897 132.338 38.9897C133.634 33.9971 132.476 26.6032 130.861 18.7628C130.622 17.6034 130.013 16.5545 129.126 15.7766C128.239 14.9988 127.123 14.5347 125.949 14.4553V14.4553Z"
      fill="#F7B80B"
    />
    <path
      d="M105.324 15.0488L105.222 15.0634C104.273 15.1991 103.371 15.5656 102.594 16.1312C101.817 16.6968 101.189 17.4445 100.764 18.3095C99.6538 20.5654 96.33 35.7452 97.2792 37.795C98.1644 39.7065 98.1186 39.2324 97.5386 40.5425C96.9587 41.8526 96.1193 40.4152 96.7821 42.5373C97.4448 44.6594 98.2428 44.3807 97.5538 45.1752C96.8649 45.9696 96.1847 45.7918 96.7973 47.17C97.4099 48.5481 97.2922 49.0046 97.4012 49.5203C97.5102 50.036 97.3074 53.6372 97.3074 53.6372C97.3074 53.6372 101.23 53.4442 103.152 54.4339C103.152 54.4339 102.163 50.7646 102.793 50.1985C103.423 49.6323 104.162 49.5818 103.658 48.7194C103.155 47.8569 101.962 47.7889 103.272 47.4004C104.583 47.012 104.367 39.4036 104.367 39.4036L105.324 15.0488Z"
      fill="#F7B80B"
    />
    <path
      d="M112.829 11.0303H115.937C116.448 11.0303 116.932 11.2653 117.247 11.6678C118.105 12.761 117.326 14.3618 115.937 14.3618H112.829C111.493 14.3618 110.701 12.8678 111.45 11.7616C111.76 11.3042 112.277 11.0303 112.829 11.0303Z"
      stroke="#898989"
      strokeWidth="0.5"
    />
    <path
      d="M103.599 0.156997L122.94 17.8011V17.8011C122.662 18.3382 122.095 18.7437 121.389 18.9104L121.25 18.9432L101.909 1.29916V1.29916C102.169 0.736689 102.763 0.318 103.502 0.175742L103.599 0.156997Z"
      fill="#3D3D3D"
    />
    <rect
      x="120.058"
      y="17.2998"
      width="1.61933"
      height="138.55"
      fill="#3D3D3D"
    />
  </svg>
);
