export const OptionsTalkyWalkyIcon = () => (
  <svg
    width="190"
    height="100"
    viewBox="0 0 190 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1249_56334)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.408 36.8535H62.9565V26.5185C62.9565 26.0083 62.5327 25.6099 62.0249 25.6099V4.79862C62.0249 4.03119 61.4312 3.43665 60.6696 3.43665C59.9353 3.43665 59.3416 4.0333 59.3416 4.79862V25.6099C58.8339 25.6099 58.4101 26.0083 58.4101 26.5185V36.8535H58.408Z"
        fill="#18282C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.5408 37.3638V61.4113C84.5408 62.3474 83.8631 65.727 82.9589 65.8683V81.6533C82.9589 82.6189 82.1679 83.4137 81.2071 83.4137H58.5485C57.5876 83.4137 56.7966 82.6189 56.7966 81.6533V65.8683C55.8924 65.727 55.2147 62.3474 55.2147 61.4113V37.3638C55.2147 33.7859 84.5408 33.7859 84.5408 37.3638Z"
        fill="#2A383D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.3301 38.557V80.3482C80.3301 80.9448 79.8496 81.455 79.2286 81.455H60.5269C59.9059 81.455 59.4254 80.9448 59.4254 80.3482V38.557C59.4254 36.5984 80.3322 36.5984 80.3322 38.557H80.3301Z"
        fill="#39474B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.7776 80.7171C78.721 80.7171 78.6937 80.7446 78.637 80.7446H61.1186C61.0619 80.7446 61.0347 80.7171 60.978 80.7171C62.5327 79.5534 65.9231 78.729 69.8778 78.729C73.8326 78.729 77.223 79.5534 78.7776 80.7171Z"
        fill="#2A383D"
      />
      <path
        d="M77.9007 50.7643H61.855C61.4585 50.7643 61.1207 51.1058 61.1207 51.5022C61.1207 51.8985 61.4606 52.2401 61.855 52.2401H77.9028C78.2993 52.2401 78.6371 51.8985 78.6371 51.5022C78.6371 51.1058 78.2972 50.7643 77.9028 50.7643H77.9007Z"
        fill="#2A383D"
      />
      <path
        d="M61.855 41.9346H77.9028C78.2993 41.9346 78.6371 41.593 78.6371 41.1967C78.6371 40.8003 78.2972 40.4588 77.9028 40.4588H61.855C61.4585 40.4588 61.1207 40.8003 61.1207 41.1967C61.1207 41.593 61.4606 41.9346 61.855 41.9346Z"
        fill="#2A383D"
      />
      <path
        d="M77.9007 47.3298H61.855C61.4585 47.3298 61.1207 47.6714 61.1207 48.0677C61.1207 48.4641 61.4606 48.8056 61.855 48.8056H77.9028C78.2993 48.8056 78.6371 48.4641 78.6371 48.0677C78.6371 47.6714 78.2972 47.3298 77.9028 47.3298H77.9007Z"
        fill="#2A383D"
      />
      <path
        d="M77.9007 43.8932H61.855C61.4585 43.8932 61.1207 44.2348 61.1207 44.6311C61.1207 45.0275 61.4606 45.369 61.855 45.369H77.9028C78.2993 45.369 78.6371 45.0275 78.6371 44.6311C78.6371 44.2348 78.2972 43.8932 77.9028 43.8932H77.9007Z"
        fill="#2A383D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.9117 55.08H77.8461C78.2699 55.08 78.6371 55.4215 78.6371 55.8748V62.4338C78.6371 62.8871 78.2699 63.2286 77.8461 63.2286H61.9117C61.4879 63.2286 61.1207 62.8871 61.1207 62.4338V55.8748C61.1207 55.4215 61.4879 55.08 61.9117 55.08Z"
        fill="#2A383D"
      />
      <path d="M77.674 56.0456H62.0795V62.263H77.674V56.0456Z" fill="#386F8D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.2148 41.7069H54.6504C54.3105 41.7069 54 41.9915 54 42.3605V54.1713C54 54.5128 54.3105 54.8249 54.6504 54.8249H55.2148V41.709V41.7069Z"
        fill="#39474B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.5409 41.7069H85.1053C85.4451 41.7069 85.7556 41.9915 85.7556 42.3605V54.1713C85.7556 54.5128 85.4451 54.8249 85.1053 54.8249H84.5409V41.709V41.7069Z"
        fill="#39474B"
      />
      <path
        d="M63.6614 62.263H66.5441L68.9736 56.0456H66.093L63.6614 62.263Z"
        fill="#559EC2"
      />
      <path
        d="M75.4145 56.0456L72.985 62.263H74.0592L76.4887 56.0456H75.4145Z"
        fill="#559EC2"
      />
      <path
        d="M71.7136 56.0456L69.3135 62.263H70.9793L73.4088 56.0456H71.7136Z"
        fill="#559EC2"
      />
      <path
        d="M63.8041 66.6357H62.9292C61.9411 66.6357 61.1207 67.5444 61.1207 68.6807C61.1207 69.8171 61.9411 70.7532 62.9292 70.7532H63.8041C64.7923 70.7532 65.6126 69.8445 65.6126 68.6807C65.6126 67.517 64.7923 66.6357 63.8041 66.6357Z"
        fill="#2A383D"
      />
      <path
        d="M76.8286 66.6357H75.9538C74.9656 66.6357 74.1453 67.5444 74.1453 68.6807C74.1453 69.8171 74.9656 70.7532 75.9538 70.7532H76.8286C77.8168 70.7532 78.6371 69.8445 78.6371 68.6807C78.6371 67.517 77.8168 66.6357 76.8286 66.6357Z"
        fill="#2A383D"
      />
      <path
        d="M70.3016 66.6357H69.454C68.4658 66.6357 67.6455 67.5444 67.6455 68.6807C67.6455 69.8171 68.4658 70.7532 69.454 70.7532H70.3016C71.2898 70.7532 72.1101 69.8445 72.1101 68.6807C72.1101 67.517 71.2898 66.6357 70.3016 66.6357Z"
        fill="#2A383D"
      />
      <path
        d="M76.8286 66.6357H75.9538C74.9656 66.6357 74.1453 67.4305 74.1453 68.4531C74.1453 69.4756 74.9656 70.2704 75.9538 70.2704H76.8286C77.8168 70.2704 78.6371 69.4461 78.6371 68.4531C78.6371 67.46 77.8168 66.6357 76.8286 66.6357Z"
        fill="#F7B80B"
      />
      <path
        d="M63.8041 66.6357H62.9292C61.9411 66.6357 61.1207 67.4305 61.1207 68.4531C61.1207 69.4756 61.9411 70.2704 62.9292 70.2704H63.8041C64.7923 70.2704 65.6126 69.4461 65.6126 68.4531C65.6126 67.46 64.7923 66.6357 63.8041 66.6357Z"
        fill="#677980"
      />
      <path
        d="M70.3016 66.6357H69.454C68.4658 66.6357 67.6455 67.4305 67.6455 68.4531C67.6455 69.4756 68.4658 70.2704 69.454 70.2704H70.3016C71.2898 70.2704 72.1101 69.4461 72.1101 68.4531C72.1101 67.46 71.2898 66.6357 70.3016 66.6357Z"
        fill="#677980"
      />
    </g>
    <g clipPath="url(#clip1_1249_56334)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.377 35.9486L116.659 37.4809L120.141 27.75C120.313 27.2696 120.048 26.7516 119.57 26.5806L126.581 6.98576C126.839 6.26318 126.48 5.50337 125.763 5.24682C125.072 4.99945 124.312 5.36122 124.054 6.0818L117.043 25.6766C116.565 25.5056 116.032 25.738 115.86 26.2184L112.379 35.9493L112.377 35.9486Z"
        fill="#18282C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.81 45.2324L128.709 67.8744C128.394 68.7558 126.617 71.7096 125.718 71.538L120.401 86.4003C120.076 87.3095 119.063 87.7914 118.158 87.4677L96.8241 79.8347C95.9194 79.511 95.4424 78.4961 95.7677 77.587L101.085 62.7246C100.281 62.287 100.782 58.8766 101.097 57.9952L109.198 35.3533C110.403 31.9846 138.015 41.8637 136.81 45.2324Z"
        fill="#2A383D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.444 44.9375L118.365 84.286C118.164 84.8478 117.54 85.1663 116.955 84.9571L99.3468 78.657C98.7621 78.4478 98.4816 77.8055 98.6826 77.2438L112.761 37.8953C113.421 36.0512 133.105 43.0941 132.446 44.9382L132.444 44.9375Z"
        fill="#39474B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.779 84.1104C116.726 84.0913 116.691 84.1079 116.638 84.0888L100.143 78.1873C100.09 78.1682 100.073 78.1332 100.02 78.1142C101.876 77.5421 105.346 77.9081 109.069 79.2403C112.793 80.5726 115.708 82.4909 116.779 84.1104Z"
        fill="#2A383D"
      />
      <path
        d="M126.044 55.6128L110.936 50.2074C110.563 50.0738 110.13 50.2816 109.996 50.6548C109.863 51.028 110.067 51.4641 110.439 51.597L125.549 57.003C125.922 57.1366 126.355 56.9288 126.489 56.5556C126.622 56.1824 126.417 55.7463 126.046 55.6135L126.044 55.6128Z"
        fill="#2A383D"
      />
      <path
        d="M113.911 41.8939L129.02 47.2999C129.394 47.4335 129.827 47.2257 129.96 46.8525C130.094 46.4793 129.889 46.0432 129.517 45.9103L114.408 40.5043C114.034 40.3707 113.601 40.5785 113.468 40.9517C113.334 41.3249 113.539 41.761 113.911 41.8939Z"
        fill="#2A383D"
      />
      <path
        d="M127.201 52.3791L112.093 46.9737C111.72 46.8401 111.287 47.0479 111.153 47.4211C111.02 47.7943 111.224 48.2304 111.596 48.3633L126.706 53.7693C127.079 53.9029 127.512 53.6951 127.646 53.3219C127.779 52.9487 127.574 52.5126 127.203 52.3798L127.201 52.3791Z"
        fill="#2A383D"
      />
      <path
        d="M128.358 49.1433L113.251 43.738C112.877 43.6044 112.444 43.8122 112.311 44.1854C112.177 44.5586 112.382 44.9947 112.754 45.1276L127.863 50.5336C128.237 50.6672 128.67 50.4594 128.803 50.0862C128.937 49.713 128.732 49.2769 128.36 49.1441L128.358 49.1433Z"
        fill="#2A383D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.536 54.29L124.539 59.6579C124.938 59.8006 125.168 60.2459 125.016 60.6727L122.806 66.8483C122.653 67.2751 122.193 67.473 121.794 67.3302L106.791 61.9624C106.392 61.8196 106.161 61.3743 106.314 60.9475L108.523 54.7719C108.676 54.3451 109.137 54.1472 109.536 54.29Z"
        fill="#2A383D"
      />
      <path
        d="M124.051 60.5091L109.368 55.2557L107.274 61.1097L121.957 66.3631L124.051 60.5091Z"
        fill="#386F8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.735 39.4426L107.204 39.2525C106.884 39.138 106.496 39.3013 106.371 39.6487L102.393 50.7692C102.277 51.0908 102.465 51.4892 102.785 51.6037L103.316 51.7938L107.734 39.4446L107.735 39.4426Z"
        fill="#39474B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.347 49.3217L135.879 49.5119C136.199 49.6264 136.395 49.9989 136.271 50.3463L132.292 61.4668C132.177 61.7884 131.779 61.9776 131.459 61.8631L130.928 61.673L135.346 49.3237L135.347 49.3217Z"
        fill="#39474B"
      />
      <path
        d="M108.763 61.6426L111.477 62.6137L115.859 57.5781L113.147 56.6077L108.763 61.6426Z"
        fill="#559EC2"
      />
      <path
        d="M121.924 59.7479L117.542 64.7835L118.553 65.1454L122.935 60.1098L121.924 59.7479Z"
        fill="#559EC2"
      />
      <path
        d="M118.439 58.5012L114.085 63.5467L115.653 64.1078L120.035 59.0722L118.439 58.5012Z"
        fill="#559EC2"
      />
      <path
        d="M107.425 65.8078L106.601 65.513C105.67 65.1802 104.592 65.7594 104.209 66.8293C103.826 67.8993 104.283 69.057 105.214 69.3899L106.038 69.6846C106.968 70.0175 108.046 69.4383 108.438 68.3425C108.831 67.2468 108.355 66.1406 107.425 65.8078Z"
        fill="#2A383D"
      />
      <path
        d="M119.688 70.1954L118.864 69.9006C117.934 69.5678 116.855 70.147 116.472 71.2169C116.09 72.2869 116.547 73.4446 117.477 73.7775L118.301 74.0722C119.231 74.4051 120.31 73.8259 120.702 72.7301C121.094 71.6344 120.618 70.5283 119.688 70.1954Z"
        fill="#2A383D"
      />
      <path
        d="M113.542 67.9966L112.744 67.7111C111.814 67.3782 110.735 67.9574 110.353 69.0274C109.97 70.0974 110.427 71.2551 111.357 71.588L112.155 71.8735C113.086 72.2064 114.164 71.6272 114.556 70.5314C114.948 69.4356 114.473 68.3295 113.542 67.9966Z"
        fill="#2A383D"
      />
      <path
        d="M119.688 70.1954L118.864 69.9006C117.934 69.5678 116.893 70.0398 116.549 71.0026C116.205 71.9653 116.709 72.9901 117.64 73.3229L118.463 73.6177C119.394 73.9505 120.444 73.4507 120.778 72.5157C121.113 71.5808 120.618 70.5283 119.688 70.1954Z"
        fill="#F7B80B"
      />
      <path
        d="M107.425 65.8078L106.601 65.513C105.67 65.1802 104.63 65.6522 104.286 66.615C103.941 67.5777 104.446 68.6024 105.376 68.9353L106.2 69.2301C107.131 69.5629 108.181 69.0631 108.515 68.1281C108.85 67.1932 108.355 66.1406 107.425 65.8078Z"
        fill="#677980"
      />
      <path
        d="M113.542 67.9966L112.744 67.7111C111.814 67.3782 110.774 67.8502 110.429 68.813C110.085 69.7758 110.589 70.8005 111.52 71.1334L112.318 71.4189C113.248 71.7518 114.298 71.252 114.633 70.317C114.967 69.382 114.473 68.3295 113.542 67.9966Z"
        fill="#677980"
      />
    </g>
    <defs>
      <clipPath id="clip0_1249_56334">
        <rect
          width="31.7556"
          height="79.9771"
          fill="white"
          transform="translate(54 3.43665)"
        />
      </clipPath>
      <clipPath id="clip1_1249_56334">
        <rect
          width="31.7556"
          height="79.9771"
          fill="white"
          transform="translate(119.484 3) rotate(19.6865)"
        />
      </clipPath>
    </defs>
  </svg>
);
