export const AnimationMicroIcon = () => (
  <svg
    width="86"
    height="121"
    viewBox="0 0 86 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_942_6508)">
      <path
        d="M85.4238 109.592H0.576172V109.94H85.4238V109.592Z"
        fill="#2D3748"
      />
      <path
        d="M55.1041 33.9084C55.1041 33.9084 51.4981 34.5088 49.6453 34.172C47.7924 33.8353 44.116 35.7466 44.116 35.7466L34.4008 39.3435C34.4008 39.3435 25.6133 46.5434 24.3776 41.5268C23.6855 38.7172 32.7419 36.4591 32.7419 36.4591L48.6604 28.834L54.4524 26.832L55.1041 33.9084Z"
        fill="#FFB9B9"
      />
      <path
        d="M55.798 54.8887L53.2585 57.3228L53.7116 67.9288C53.7116 67.9288 54.2332 81.8382 54.0594 82.3598C53.8855 82.8814 50.7559 104.093 50.7559 104.093C50.7559 104.093 54.4071 105.832 57.7106 104.789C57.7106 104.789 61.0141 89.1407 61.0141 84.6201L64.8392 68.7981L70.7507 86.8804C70.7507 86.8804 71.0984 99.0512 71.7939 103.05C72.4894 107.049 72.4894 107.571 72.4894 107.571C72.4894 107.571 78.0531 109.136 79.4441 107.571C79.4441 107.571 78.7486 87.0543 78.227 84.9678L77.0099 60.1047L55.798 54.8887Z"
        fill="#1A202C"
      />
      <path
        d="M52.1467 104.094C52.1467 104.094 51.799 103.572 50.7557 104.963C49.7125 106.354 46.409 108.962 46.409 108.962C46.409 108.962 37.3679 115.395 44.1488 115.743C50.9296 116.091 52.1467 112.613 52.1467 112.613C52.1467 112.613 57.8843 111.57 58.0582 110.353C58.2321 109.136 57.3627 104.268 57.3627 104.268L52.1467 104.094Z"
        fill="#1A202C"
      />
      <path
        d="M72.8371 107.223C72.8371 107.223 71.62 111.395 71.9678 113.134C72.3155 114.873 71.2723 121.306 76.1406 120.784C81.0089 120.263 79.4441 114.699 79.4441 114.699L77.8793 107.57L72.8371 107.223Z"
        fill="#1A202C"
      />
      <path
        d="M65.9691 16.2038C69.1379 16.2038 71.7067 13.635 71.7067 10.4662C71.7067 7.29735 69.1379 4.72852 65.9691 4.72852C62.8003 4.72852 60.2314 7.29735 60.2314 10.4662C60.2314 13.635 62.8003 16.2038 65.9691 16.2038Z"
        fill="#FFB9B9"
      />
      <path
        d="M62.5791 13.508C62.5791 13.508 64.3178 19.5934 62.9268 21.8537C61.5359 24.114 72.3157 22.2014 73.0112 20.115C73.0112 20.115 69.5338 16.2899 70.0554 12.6387L62.5791 13.508Z"
        fill="#FFB9B9"
      />
      <path
        d="M65.3609 21.6815C65.3609 21.6815 70.1204 22.0032 72.4351 19.4082L78.6276 22.0547C79.3041 22.3438 79.8625 22.8541 80.2113 23.5019C80.5601 24.1497 80.6787 24.8968 80.5476 25.6207L77.3577 43.2411C77.3577 43.2411 78.8171 54.3687 78.0532 61.845C77.363 68.5996 52.3207 61.845 53.1901 56.1073C53.2832 55.4926 53.3692 54.8639 53.4484 54.2312C54.1796 48.5799 55.2366 42.9755 56.6141 37.446C57.0764 35.5571 55.7981 24.8111 55.7981 24.8111L63.4514 19.7402C63.4514 19.7402 63.796 21.6815 65.3609 21.6815Z"
        fill="#F7B80B"
      />
      <path
        d="M60.2014 24.3607L58.7465 22.8652L48.0898 28.8663C48.0898 28.8663 51.2116 34.3193 52.441 34.3362C52.9121 34.3427 58.5031 34.6162 59.8657 32.8082C62.0583 29.8988 60.2014 24.3607 60.2014 24.3607Z"
        fill="#F7B80B"
      />
      <path
        d="M64.5597 6.71166C65.4112 6.57848 66.2695 6.49338 67.1306 6.45678C67.7545 6.43027 68.4045 6.43448 68.9683 6.70298C69.8184 7.10782 70.2947 8.02297 70.6065 8.9114C70.9183 9.79982 71.1395 10.7493 71.7179 11.4922C72.6804 10.4875 72.8728 8.99391 73.0161 7.61002C73.1168 6.63664 73.2062 5.59874 72.7561 4.72979C72.4789 4.19459 72.0215 3.77751 71.5583 3.39193C69.3467 1.55125 66.5379 0.102899 63.6904 0.516322L63.6238 0.955939C63.1379 0.941331 62.6516 0.971888 62.1714 1.04722L62.2731 2.13789L60.7606 2.23384L60.7026 3.37053L59.173 3.42356C59.2024 3.86037 59.3925 4.27083 59.7065 4.57583C59.4712 4.74584 59.204 4.86654 58.9209 4.9307C59.176 5.91809 59.4236 6.90775 59.6871 7.89293C59.8812 8.61865 59.8465 9.25986 60.6249 8.90569C61.2176 8.63602 61.6186 7.77849 62.2178 7.42418C62.9238 7.00679 63.7592 6.83687 64.5597 6.71166Z"
        fill="#1A202C"
      />
      <path
        d="M83.3966 32.0449L83.4808 38.1726L81.7158 55.7347C81.7158 55.7347 82.6679 65.0196 79.7903 65.323C74.6521 65.8646 78.4394 55.1541 78.4394 55.1541L78.5227 44.7947C78.5227 44.7947 79.0717 40.6876 78.1257 39.0594C77.1796 37.4311 76.5195 33.8355 76.5195 33.8355L83.3966 32.0449Z"
        fill="#FFB9B9"
      />
      <path
        d="M78.5749 22.5508C79.2792 22.5506 79.9645 22.7793 80.5274 23.2026C81.0903 23.6258 81.5004 24.2205 81.6958 24.8971C82.8062 28.7447 84.8228 35.9849 84.3126 36.1125C83.6171 36.2864 75.4453 36.808 75.4453 36.808L78.5749 22.5508Z"
        fill="#F7B80B"
      />
      <path
        d="M15.1162 52.0477L15.1581 52.0578C15.9173 52.2411 16.5993 52.6593 17.1069 53.2529C17.6145 53.8465 17.9218 54.5852 17.9849 55.3636C18.0481 56.1421 17.864 56.9206 17.4587 57.5883C17.0535 58.2559 16.4479 58.7787 15.7281 59.0819C15.4449 59.2021 15.1477 59.286 14.8434 59.3316C14.3034 59.414 13.7518 59.3762 13.2281 59.2207C12.7044 59.0653 12.2214 58.7962 11.8137 58.4325C11.4061 58.0689 11.0837 57.6197 10.8697 57.117C10.6558 56.6144 10.5554 56.0707 10.5758 55.5248C10.5963 54.9789 10.737 54.4442 10.988 53.959C11.239 53.4737 11.594 53.0499 12.0277 52.7177C12.4614 52.3856 12.9632 52.1533 13.497 52.0375C14.0309 51.9217 14.5838 51.9251 15.1162 52.0477V52.0477Z"
        fill="#F7B80B"
      />
      <path
        d="M15.1164 52.0477C15.4758 54.3764 15.6802 56.7263 15.7284 59.082C15.4452 59.2021 15.1479 59.286 14.8437 59.3316C14.8658 56.8575 14.65 54.387 14.1992 51.9541C14.5077 51.947 14.8158 51.9785 15.1164 52.0477Z"
        fill="#E2E8F0"
      />
      <path
        d="M29.884 52.823L17.1562 53.3395L17.5148 57.5526L30.1641 55.6448C30.3887 55.6138 30.5922 55.4961 30.7311 55.3169C30.87 55.1377 30.9332 54.9113 30.9073 54.6861L30.8902 54.5372L31.3895 54.5165C31.4508 54.514 31.5109 54.4986 31.5658 54.4714C31.6208 54.4442 31.6694 54.4057 31.7086 54.3585C31.7478 54.3113 31.7766 54.2565 31.7932 54.1974C31.8099 54.1384 31.8139 54.0765 31.8052 54.0158L31.8021 53.9947C31.7859 53.8824 31.7268 53.7809 31.6372 53.7113C31.5476 53.6417 31.4346 53.6096 31.3219 53.6216L30.7913 53.6781L30.7812 53.5908C30.7562 53.3732 30.6498 53.1731 30.4834 53.0307C30.317 52.8883 30.1029 52.8141 29.884 52.823Z"
        fill="#2D3748"
      />
      <path
        d="M22.8336 55.3034C22.8205 55.3581 22.7898 55.407 22.7464 55.4428C22.7029 55.4785 22.649 55.499 22.5928 55.5013L21.6786 55.5392C21.6163 55.5416 21.5553 55.5215 21.5066 55.4826C21.4579 55.4437 21.4247 55.3886 21.4133 55.3273L21.3206 54.8221C21.3142 54.7871 21.3151 54.7511 21.3232 54.7164C21.3314 54.6817 21.3466 54.6491 21.3679 54.6205C21.3892 54.592 21.4162 54.5682 21.4472 54.5505C21.4781 54.5329 21.5124 54.5219 21.5478 54.5181L22.4728 54.4195C22.5394 54.4122 22.6063 54.4311 22.6592 54.4722C22.7121 54.5133 22.747 54.5734 22.7564 54.6398L22.7563 54.6398L22.8382 55.2057C22.8429 55.2383 22.8413 55.2714 22.8336 55.3034ZM21.4913 54.7552C21.4885 54.7669 21.4881 54.7791 21.4904 54.791L21.583 55.2962C21.5868 55.3166 21.5979 55.335 21.6141 55.348C21.6303 55.3609 21.6507 55.3676 21.6714 55.3669L22.5856 55.329C22.5977 55.3285 22.6095 55.3254 22.6203 55.3201C22.6311 55.3147 22.6407 55.3072 22.6484 55.2979C22.6561 55.2886 22.6618 55.2778 22.6651 55.2661C22.6684 55.2545 22.6692 55.2423 22.6674 55.2304L22.5856 54.6645C22.5824 54.6424 22.5708 54.6224 22.5532 54.6087C22.5356 54.595 22.5133 54.5887 22.4911 54.5911L21.5661 54.6897C21.5543 54.6909 21.5428 54.6945 21.5325 54.7004C21.5222 54.7063 21.5132 54.7142 21.5061 54.7238C21.4991 54.7331 21.4941 54.7438 21.4913 54.7552Z"
        fill="#E2E8F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_942_6508">
        <rect
          width="84.8476"
          height="120.37"
          fill="white"
          transform="translate(0.576172 0.445312)"
        />
      </clipPath>
    </defs>
  </svg>
);
