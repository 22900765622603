import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Switch,
  Text,
} from "@chakra-ui/react";
import { FC, ReactElement, useEffect, useState } from "react";

import {
  CalendarDay,
  DateRangePicker,
  DayPickerSingleDateController,
  FocusedInputShape,
} from "react-dates";
import "react-dates/initialize";
import styled from "styled-components";
import { colors } from "../../ui/theme";
import CustomTooltip from "../CustomTooltip";
import { NextArrowCalendarIcon } from "../Icons/NextArrowCalendarIcon";
import { PrevArrowCalendarIcon } from "../Icons/PrevArrowCalendarIcon";
import moment from "moment";

interface Props {
  changeDates?: (dates: string[] | null) => any;
  changeDatePeriod?: (
    period: { startDate: string; endDate: string } | null
  ) => any;
  selectedDates: string[] | null;
  selectedDatePeriod: {
    startDate: string;
    endDate: string;
  } | null;
  isOpen: boolean;
  setOpenPopup: (open: boolean) => any;
  onlyDaySelection?: boolean;
  daySelectionTitle?: string;
}

export const needToCallNorela = (selectedDays: string[]): boolean => {
  for (let day of selectedDays)
    if (countOpenDaysBefore(day) === 1 || countOpenDaysBefore(day) === 2)
      return true;
  return false;
};

const RenderDayContents = ({ day }: { day: string }) => {
  return (
    <CustomTooltip
      fontSize="14px"
      padding="16px"
      content={
        checkIfDateIsNotInTheFutur(day) || countOpenDaysBefore(day) === 0
          ? []
          : countOpenDaysBefore(day) === 1 || countOpenDaysBefore(day) === 2
          ? [
              "Le délai de votre commande est très court, merci de contacter notre agence afin d’établir la faisabilité de votre événement.",
            ]
          : countOpenDaysBefore(day) === 3 || countOpenDaysBefore(day) === 4
          ? [
              "Commande sous réserve de faisabilité si votre besoin nécessite un effectif important.",
            ]
          : []
      }
    >
      <div
        className={
          countOpenDaysBefore(day) === 1 || countOpenDaysBefore(day) === 2
            ? "need-call"
            : countOpenDaysBefore(day) === 3 || countOpenDaysBefore(day) === 4
            ? "hot-day"
            : ""
        }
      >
        <div
          className={
            checkIfDateIsNotInTheFutur(day) || countOpenDaysBefore(day) === 0
              ? "disabled-day"
              : ""
          }
        >
          {moment(day).format("D")}
        </div>
      </div>
    </CustomTooltip>
  );
};

const CalendarPopup: FC<Props> = ({
  changeDates,
  changeDatePeriod,
  selectedDates: selectedDatesParam = null,
  selectedDatePeriod: selectedDatePeriodParam = null,
  isOpen: openPopup,
  setOpenPopup,
  onlyDaySelection: singleDate,
  daySelectionTitle = "Veuillez choisir une date",
}): ReactElement => {
  const [focus, setFocus] = useState<FocusedInputShape>("startDate");
  const selectedDates = selectedDatesParam?.map((date) => moment(date)) || null;
  const selectedDatePeriod = selectedDatePeriodParam
    ? {
        startDate: selectedDatePeriodParam.startDate,
        endDate: selectedDatePeriodParam.endDate,
      }
    : null;
  const [isDailySelection, setIsDailySelection] = useState<boolean>(
    selectedDatePeriod === null
  );
  const [startDateMoment, setStartDate] = useState<string | null>(
    selectedDatePeriod?.startDate ?? null
  );
  const [endDateMoment, setEndDate] = useState<string | null>(
    selectedDatePeriod?.endDate ?? null
  );

  useEffect(() => {
    if (changeDatePeriod && startDateMoment && endDateMoment) {
      changeDatePeriod({
        startDate: startDateMoment,
        endDate: endDateMoment,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateMoment, endDateMoment]);

  const resetCurrentCalendar = () => {
    if (changeDates) changeDates(null);
    if (changeDatePeriod) {
      changeDatePeriod(null);
      setStartDate(null);
      setEndDate(null);
    }
  };

  const toggleIsRange = () => {
    setIsDailySelection(!isDailySelection);
    if (changeDatePeriod) changeDatePeriod(null);
    if (changeDates) changeDates(null);
    setStartDate(null);
    setEndDate(null);
  };

  const difference = selectedDatePeriod
    ? moment(selectedDatePeriod.endDate)?.diff(
        selectedDatePeriod.startDate,
        "days"
      )
    : 0;
  const startDate =
    selectedDatePeriod?.startDate &&
    moment(selectedDatePeriod.startDate)?.toDate().toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  const endDate =
    selectedDatePeriod?.endDate &&
    moment(selectedDatePeriod.endDate)?.toDate().toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

  return (
    <Wrapper onClick={() => setOpenPopup(!openPopup)}>
      <Flex
        display={openPopup ? "flex" : "none"}
        position="absolute"
        top="50%"
        left="50%"
        height="570px"
        width="845px"
        maxWidth="100%"
        transform="translateX(-50%) translateY(-50%)"
        overflow="hidden"
        backgroundColor="white"
        transition="opacity 1000ms"
        visibility={openPopup ? "visible" : "hidden"}
        opacity={openPopup ? 1 : 0}
        border={`1px solid ${colors.black}`}
        borderRadius="12px"
        zIndex="4"
        sx={{
          "& .DateInput_input": {
            padding: 0,
            width: "90px",
          },
        }}
      >
        <Flex
          direction="column"
          justifyContent="space-between"
          width="100%"
          onClick={(e: any) => e.stopPropagation()}
        >
          <Flex
            p="22px 40px"
            borderBottom={`1px solid ${colors.black}`}
            width="100%"
          >
            <Flex
              alignItems="center"
              pr="30px"
              flex="1"
              sx={{
                ".DayPicker__hidden": {
                  visibility: "visible !important",
                },
                ".DayPickerKeyboardShortcuts_show__bottomRight": {
                  display: "none !important",
                },
                height: "50px",
                ".need-call": {
                  color: "#FF4500 !important",
                },
                ".hot-day": {
                  color: "#F7B80B !important",
                },
                ".disabled-day": {
                  opacity: "1 !important",
                  cursor: "not-allowed !important",
                  color: "#cacccd",
                },
                ".DateRangePickerInput__withBorder": {
                  border: `1px solid ${colors.black}`,
                  borderRadius: "12px",
                },
                ".DateInput_input__focused": {},
                ".DateRangePickerInput": {
                  padding: "10px 12px !important",
                },
                ".DateRangePicker_picker": {
                  top: "85px !important",
                  left: "-8px !important",
                },
                ".DayPicker_transitionContainer": {
                  height: "380px !important",
                  width: "785px !important",
                },
                ".DayPicker_weekHeader": {
                  width: "400px !important",
                  padding: "0 4px !important",
                  transform: "translateX(-5px) !important",
                },
                ".DayPicker__withBorder": {
                  boxShadow: "none",
                },
                "#end_date_id, #start_date_id": {
                  fontWeight: "600",
                  width: "100%",
                  fontSize: "13px",
                  display: "block",
                  textAlign: "center",
                  borderBottom: `none`,
                },
                ".DateInput_fang": {
                  display: "none",
                },
                ".DayPickerNavigation": {
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0 2rem",
                },
                ".CalendarDay": {
                  width: "50px !important",
                  height: "49px !important",
                  border: "0px solid transparent !important",
                  boxSizing: "border-box",
                  borderRadius: "50%",
                  background: "transparent",
                },
                ".DayPicker_weekHeader_li": {
                  width: "50px !important",
                },
                ".DayPicker_weekHeader_ul": {
                  padding: "0 13px",
                  small: {
                    color: "transparent",
                    position: "relative",
                  },
                  "small::after": {
                    color: "#757575",
                    position: "absolute",
                    top: "0",
                    left: "50%",
                    width: "20px",
                    height: "100%",
                    transform: "translateX(-50%)",
                    textAlign: "center",
                  },
                },
                ".DayPicker_weekHeader_ul li:nth-of-type(1) ": {
                  "small::after": {
                    content: "'Lu'",
                  },
                },
                ".DayPicker_weekHeader_ul li:nth-of-type(2) ": {
                  "small::after": {
                    content: "'Ma'",
                  },
                },
                ".DayPicker_weekHeader_ul li:nth-of-type(3) ": {
                  "small::after": {
                    content: "'Me'",
                  },
                },
                ".DayPicker_weekHeader_ul li:nth-of-type(4) ": {
                  "small::after": {
                    content: "'Je'",
                  },
                },
                ".DayPicker_weekHeader_ul li:nth-of-type(5) ": {
                  "small::after": {
                    content: "'Ve'",
                  },
                },
                ".DayPicker_weekHeader_ul li:nth-of-type(6) ": {
                  "small::after": {
                    content: "'Sa'",
                  },
                },
                ".DayPicker_weekHeader_ul li:nth-of-type(7) ": {
                  "small::after": {
                    content: "'Di'",
                  },
                },
                ".CalendarDay__selected_start": {
                  borderRadius: "40% 0 0 40%",
                  position: "relative",

                  content: "''",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#033349",

                  div: {
                    position: "relative",
                  },
                },
                ".CalendarDay__selected_end": {
                  borderRadius: "0% 40% 40% 0%",
                  position: "relative",

                  width: "100%",
                  height: "100%",
                  backgroundColor: "#033349",
                  color: "white",

                  div: {
                    position: "relative",
                  },
                },
                ".CalendarDay__selected_span, .CalendarDay__hovered_span": {
                  color: "#000",
                  backgroundColor: "#d1d1d1 !important",
                  borderRadius: "0",
                },
                ".CalendarDay__hovered_span": {
                  color: "#000",
                  backgroundColor: "transparent",
                },
                ".CalendarDay__selected, .CalendarDay__selected_span": {},
                "& > div.DayPicker": {
                  top: "107px !important",
                  position: "absolute",
                  width: "785px !important",
                  left: "32px !important",

                  "& > div, & > div > div": {
                    width: "785px !important",
                  },

                  ".DayPicker_transitionContainer": {
                    height: "380px !important",
                  },

                  ".CalendarDay__selected, .CalendarDay__highlighted_calendar":
                    {
                      color: "#fff",
                      backgroundColor: "#033349 !important",
                    },

                  ".DayPicker_weekHeaders": {
                    ".DayPicker_weekHeader:nth-of-type(2)": {
                      right: "0 !important",
                      left: "auto !important",
                    },
                  },
                },
              }}
            >
              {isDailySelection ? (
                <DayPickerSingleDateController
                  firstDayOfWeek={1}
                  renderMonthText={(month) => {
                    const date = month.toDate().toLocaleDateString("fr-FR", {
                      month: "long",
                      year: "numeric",
                    });
                    return <>{date.charAt(0).toUpperCase() + date.slice(1)}</>;
                  }}
                  onDateChange={(date) => {
                    if (
                      date === null ||
                      checkIfDateIsNotInTheFutur(date.toISOString()) ||
                      countOpenDaysBefore(date.toISOString()) === 0
                    ) {
                      return;
                    }
                    const newDates = selectedDates?.find((d) => d.isSame(date))
                      ? selectedDates.filter((d) => !date.isSame(d))
                      : [...(selectedDates || []), date];

                    if (newDates.length === 0 && changeDates)
                      return changeDates(null);
                    if (changeDates) {
                      changeDates(newDates.map((d) => d.toISOString()));
                    }
                  }}
                  focused={true}
                  onFocusChange={() => {}}
                  date={null}
                  numberOfMonths={2}
                  initialVisibleMonth={() => moment()}
                  keepOpenOnDateSelect
                  isDayHighlighted={(day) =>
                    selectedDates?.some((d: moment.Moment) =>
                      d.isSame(day, "day")
                    ) ?? false
                  }
                  navPrev={<PrevArrowCalendarIcon />}
                  navNext={<NextArrowCalendarIcon />}
                  calendarInfoPosition="bottom"
                  renderCalendarInfo={() => (
                    <BottomInfo
                      resetCurrentCalendar={resetCurrentCalendar}
                      setOpenPopup={setOpenPopup}
                      openPopup={openPopup}
                      needToCallNorela={needToCallNorela(
                        selectedDates?.map((d) => d.toISOString()) || []
                      )}
                    />
                  )}
                  renderDayContents={(day) => {
                    return <RenderDayContents day={day.toISOString()} />;
                  }}
                  renderCalendarDay={(props) => {
                    const { day, modifiers } = props;
                    if (day && selectedDates?.includes(day)) {
                      modifiers && modifiers.add("selected");
                    } else {
                      modifiers && modifiers.delete("selected");
                    }

                    return <CalendarDay {...props} modifiers={modifiers} />;
                  }}
                />
              ) : (
                <DateRangePicker
                  firstDayOfWeek={1}
                  startDatePlaceholderText="Début"
                  endDatePlaceholderText="Fin"
                  isOutsideRange={(day) =>
                    checkIfDateIsNotInTheFutur(day.toISOString()) ||
                    countOpenDaysBefore(day.toISOString()) === 0
                  }
                  renderMonthText={(month) => {
                    const date = month.toDate().toLocaleDateString("fr-FR", {
                      month: "long",
                      year: "numeric",
                    });
                    return <>{date.charAt(0).toUpperCase() + date.slice(1)}</>;
                  }}
                  navPrev={<PrevArrowCalendarIcon />}
                  navNext={<NextArrowCalendarIcon />}
                  daySize={50}
                  calendarInfoPosition="bottom"
                  renderCalendarInfo={() => (
                    <BottomInfo
                      resetCurrentCalendar={resetCurrentCalendar}
                      setOpenPopup={setOpenPopup}
                      openPopup={openPopup}
                      needToCallNorela={
                        startDateMoment
                          ? needToCallNorela([startDateMoment] || [])
                          : false
                      }
                    />
                  )}
                  customArrowIcon="|"
                  customInputIcon={null}
                  startDate={startDateMoment ? moment(startDateMoment) : null}
                  startDateId="start_date_id"
                  endDate={endDateMoment ? moment(endDateMoment) : null}
                  endDateId="end_date_id"
                  renderDayContents={(day) => {
                    return <RenderDayContents day={day.toISOString()} />;
                  }}
                  onDatesChange={(date) => {
                    setStartDate(date.startDate?.toISOString() ?? null);
                    setEndDate(date.endDate?.toISOString() ?? null);
                  }}
                  focusedInput={focus}
                  onFocusChange={(focused) => {
                    if (focused) {
                      setFocus(focused);
                    } else {
                      setFocus("startDate");
                    }
                  }}
                />
              )}
            </Flex>
            <Flex
              flex="2"
              alignItems="center"
              display={singleDate ? "none" : undefined}
            >
              <Flex
                direction="column"
                gap="5px"
                pr="48px"
                textTransform="uppercase"
                flex="1"
              >
                <Text variant="sm" fontWeight="700" textAlign="left">
                  {isDailySelection
                    ? selectedDates?.length ?? 0
                    : difference === 0
                    ? 0
                    : difference + 1}{" "}
                  jours
                </Text>
                {(startDate || endDate) && (
                  <Text
                    variant="sm"
                    opacity=".8"
                    whiteSpace="nowrap"
                    textAlign="left"
                  >
                    {startDate} - {endDate}
                  </Text>
                )}
              </Flex>
              <FormControl display="flex" alignItems="center" flex="2">
                <FormLabel htmlFor="email-alerts" mb="0">
                  <Text variant="sm" textTransform="uppercase">
                    Période
                  </Text>
                </FormLabel>
                <Switch
                  id="email-alerts"
                  isChecked={isDailySelection}
                  onChange={toggleIsRange}
                  variant="calendarSwitch"
                />
                <FormLabel htmlFor="email-alerts" ml="17px" mb="0">
                  <Text variant="sm" textTransform="uppercase">
                    {" "}
                    à la journée
                  </Text>
                </FormLabel>
              </FormControl>
            </Flex>
            <Flex
              w="100%"
              placeContent="center"
              display={singleDate ? undefined : "none"}
            >
              <Heading size="lg">{daySelectionTitle}</Heading>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

const checkIfDateIsNotInTheFutur = (date: string) => {
  return !moment(date).isAfter(moment(), "day");
};

const countOpenDaysBefore = (date: string) => {
  let count = 0;
  const _date = moment(date);
  const today = moment();
  if (today.day() !== 0 && today.day() !== 6) count += 0.5;
  for (
    let checkDay = today.add(1, "days");
    checkDay.isBefore(_date, "days");
    checkDay.add(1, "days")
  ) {
    if (checkDay.day() !== 0 && checkDay.day() !== 6) count++;
  }
  if (_date.day() !== 0 && _date.day() !== 6) count += 0.5;
  return Math.floor(count);
};

const Wrapper = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const BottomInfo = ({
  setOpenPopup,
  openPopup,
  resetCurrentCalendar,
  needToCallNorela,
}: {
  setOpenPopup: (open: boolean) => void;
  openPopup: boolean;
  resetCurrentCalendar?: () => void;
  needToCallNorela?: boolean;
}) => (
  <Flex m="28px 21px" gap="40px" alignItems="center" justifyContent="flex-end">
    {needToCallNorela ? (
      <Text fontSize="14px" fontWeight="600" color="#F04500">
        Votre evenement est prévu à une date trop proche, veuillez contacter
        e-Norela directement !
      </Text>
    ) : (
      <Text fontSize="14px" fontWeight="600">
        Un événement sur plusieurs dates ? Séléctionnez une date, son horaire,
        et ajoutez ensuite les autres dates pour que les horaires soient
        pré-remplis.
      </Text>
    )}

    <Button
      textDecoration="underline"
      onClick={resetCurrentCalendar}
      borderRadius="80px"
      variant="ghost"
    >
      Réinitialiser
    </Button>
    <Button
      onClick={() => setOpenPopup(!openPopup)}
      w="fit-content"
      borderRadius="80px"
      backgroundColor={colors.additional.lightMode}
      color={colors.white}
      p="10px 24px"
      _hover={{
        opacity: 0.5,
      }}
    >
      Valider
    </Button>
  </Flex>
);

export default CalendarPopup;
