import { Icon, IconProps } from "@chakra-ui/react";

export const MultiAddressPopupIcon = (props: IconProps) => (
  <Icon
    width="167"
    height="108"
    viewBox="0 0 167 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3982_41116)" filter="url(#filter0_d_3982_41116)">
      <path
        d="M120.129 24.3533C120.129 33.9474 105.128 62.2412 96.5272 77.6753C95.9438 78.7225 95.0912 79.5949 94.0575 80.2022C93.0238 80.8095 91.8466 81.1298 90.6476 81.1298C89.4486 81.1298 88.2713 80.8095 87.2376 80.2022C86.2039 79.5949 85.3513 78.7225 84.7679 77.6753C76.1669 62.2412 61.166 33.9474 61.166 24.3533C61.166 10.9033 74.3654 0 90.6476 0C106.93 0 120.129 10.9033 120.129 24.3533Z"
        fill="#F7B80B"
      />
      <path
        d="M90.7862 40.1275C99.6535 40.1275 106.842 32.9412 106.842 24.0764C106.842 15.2117 99.6535 8.02539 90.7862 8.02539C81.9188 8.02539 74.7305 15.2117 74.7305 24.0764C74.7305 32.9412 81.9188 40.1275 90.7862 40.1275Z"
        fill="white"
      />
      <path
        d="M23.7866 38.5999C29.0978 38.5999 33.4033 34.2956 33.4033 28.986C33.4033 23.6764 29.0978 19.3721 23.7866 19.3721C18.4755 19.3721 14.1699 23.6764 14.1699 28.986C14.1699 34.2956 18.4755 38.5999 23.7866 38.5999Z"
        fill="#FF6584"
      />
      <path
        d="M90.6477 100C103.504 100 113.926 99.1959 113.926 98.2037C113.926 97.2115 103.504 96.4072 90.6477 96.4072C77.7913 96.4072 67.3691 97.2115 67.3691 98.2037C67.3691 99.1959 77.7913 100 90.6477 100Z"
        fill="#F7B80B"
      />
      <path
        opacity="0.4"
        d="M21.828 37.5355C22.3174 34.5203 20.2692 31.6793 17.2531 31.19C14.237 30.7007 11.3952 32.7484 10.9057 35.7636C10.4163 38.7788 12.4645 41.6198 15.4806 42.1091C18.4967 42.5984 21.3385 40.5507 21.828 37.5355Z"
        fill="#F7B80B"
      />
      <path
        d="M145.806 65.1809C102.237 48.8531 49.0983 64.7713 18.4181 97.3327C18.2437 97.5154 18.0693 97.7008 17.9004 97.8862H17.4326C17.5932 97.7008 17.7593 97.5154 17.9226 97.3327C49.2395 62.2944 101.672 48.33 145.806 65.1809Z"
        fill="#3F3D56"
      />
      <path
        d="M157.713 70.597C153.862 68.5438 149.885 66.7349 145.807 65.1812C149.896 66.7093 153.874 68.5189 157.713 70.597Z"
        fill="#3F3D56"
      />
      <path
        opacity="0.4"
        d="M133.867 54.3063C137.218 54.3063 139.935 51.5903 139.935 48.2401C139.935 44.8898 137.218 42.1738 133.867 42.1738C130.516 42.1738 127.799 44.8898 127.799 48.2401C127.799 51.5903 130.516 54.3063 133.867 54.3063Z"
        fill="#F7B80B"
      />
      <path
        d="M134.543 94.9004C135.012 96.6334 136.618 97.7066 136.618 97.7066C136.618 97.7066 137.464 95.9702 136.995 94.2373C136.526 92.5044 134.92 91.4312 134.92 91.4312C134.92 91.4312 134.074 93.1675 134.543 94.9004Z"
        fill="#3F3D56"
      />
      <path
        d="M135.232 94.5284C136.519 95.7806 136.676 97.7056 136.676 97.7056C136.676 97.7056 134.747 97.6003 133.461 96.3481C132.174 95.0958 132.017 93.1709 132.017 93.1709C132.017 93.1709 133.946 93.2762 135.232 94.5284Z"
        fill="#F7B80B"
      />
      <path
        d="M5.34382 70.806V73.4793L5.21094 73.6232V70.7783C5.25523 70.7866 5.29953 70.7977 5.34382 70.806Z"
        fill="#3F3D56"
      />
      <path
        d="M13.8644 97.3317C13.8256 97.3953 13.7841 97.459 13.7426 97.5226H5.21094V70.9388L5.34382 70.8059L6.47602 72.0928V70.2109H10.0747V76.183L13.7094 80.3093L13.7426 80.348C13.7869 80.4864 13.8284 80.6247 13.8699 80.7631C13.8702 80.7652 13.8712 80.7672 13.8727 80.7687C14.4178 82.5126 14.8651 84.2856 15.2125 86.0793C16.0042 90.3107 15.8852 94.1906 13.8644 97.3317Z"
        fill="#CCCCCC"
      />
      <path
        d="M8.20657 85.5005H5.91602V87.4087H8.20657V85.5005Z"
        fill="#3F3D56"
      />
      <path
        d="M8.20657 88.9351H5.91602V90.8433H8.20657V88.9351Z"
        fill="#3F3D56"
      />
      <path
        d="M78.7653 81.8745L77.6201 79.9664L88.3093 70.8067C96.2118 67.0041 105.738 68.1013 115.796 70.8067V73.4781L105.87 84.1645L94.4174 86.4543L78.7653 81.8745Z"
        fill="#3F3D56"
      />
      <path
        d="M120.527 76.1822C122.425 74.0714 122.689 72.0643 120.527 70.2109H116.928V72.0939L115.795 70.807L106.633 79.9667L105.106 81.4932L103.197 89.1262L106.633 97.5223H124.194V80.3483L120.527 76.1822Z"
        fill="#CCCCCC"
      />
      <path
        d="M77.6197 79.9663H106.633V97.5221H77.6197C74.1097 93.7256 75.9331 86.8059 77.6197 79.9663Z"
        fill="#F2F2F2"
      />
      <path
        d="M102.625 85.5005H96.5166V90.8436H102.625V85.5005Z"
        fill="#3F3D56"
      />
      <path
        d="M94.0351 85.3096H88.6904V97.5223H94.0351V85.3096Z"
        fill="#CCCCCC"
      />
      <path
        d="M82.5824 87.2177V85.3096H80.292V87.2177H82.3914H82.5824Z"
        fill="#3F3D56"
      />
      <path
        d="M82.3914 88.7441H80.292V90.6526H82.5824V88.7441H82.3914Z"
        fill="#3F3D56"
      />
      <path
        d="M86.3999 85.3096H84.1094V87.2178H86.3999V85.3096Z"
        fill="#3F3D56"
      />
      <path
        d="M86.3999 88.7441H84.1094V90.6524H86.3999V88.7441Z"
        fill="#3F3D56"
      />
      <path
        d="M114.841 87.4089V85.5005H112.551V87.4089H114.65H114.841Z"
        fill="#3F3D56"
      />
      <path
        d="M114.65 88.9351H112.551V90.8432H114.841V88.9351H114.65Z"
        fill="#3F3D56"
      />
      <path
        d="M118.659 85.5005H116.368V87.4087H118.659V85.5005Z"
        fill="#3F3D56"
      />
      <path
        d="M118.659 88.9351H116.368V90.8433H118.659V88.9351Z"
        fill="#3F3D56"
      />
      <path
        d="M27.104 49.8166L25.1953 48.2898L35.8845 38.7485H62.9893V47.1449L45.4285 58.9762L27.104 49.8166Z"
        fill="#3F3D56"
      />
      <path
        d="M67.9307 44.1002V38.9387H64.3321V40.2029L62.9889 38.748L54.2084 48.2893L50.7728 50.5793L45.4453 89.4901L53.4795 97.5219H72.1512C75.0121 83.1347 77.2807 68.3549 72.1512 48.6709L67.9307 44.1002Z"
        fill="#CCCCCC"
      />
      <path
        d="M25.1948 48.2896H54.2084V97.5222H25.1948C22.0198 78.3957 20.4693 60.6586 25.1948 48.2896Z"
        fill="#F2F2F2"
      />
      <path
        d="M41.8017 85.5005H36.457V97.5224H41.8017V85.5005Z"
        fill="#CCCCCC"
      />
      <path
        d="M30.7309 88.935V87.0269H28.4404V88.935H30.5401H30.7309Z"
        fill="#3F3D56"
      />
      <path
        d="M30.5401 90.4619H28.4404V92.3703H30.7309V90.4619H30.5401Z"
        fill="#3F3D56"
      />
      <path
        d="M34.5484 87.0269H32.2578V88.9351H34.5484V87.0269Z"
        fill="#3F3D56"
      />
      <path
        d="M46.1915 90.4619H44.0918V92.3703H46.3822V90.4619H46.1915Z"
        fill="#3F3D56"
      />
      <path
        d="M50.2007 87.0269H47.9102V88.9351H50.2007V87.0269Z"
        fill="#3F3D56"
      />
      <path
        d="M50.2007 90.4619H47.9102V92.3702H50.2007V90.4619Z"
        fill="#3F3D56"
      />
      <path
        d="M62.4164 50.7704V48.8623H60.126V50.7704H62.2257H62.4164Z"
        fill="#3F3D56"
      />
      <path
        d="M62.2257 52.2969H60.126V54.205H62.4164V52.2969H62.2257Z"
        fill="#3F3D56"
      />
      <path
        d="M66.2339 48.8623H63.9434V50.7706H66.2339V48.8623Z"
        fill="#3F3D56"
      />
      <path
        d="M66.2339 52.2969H63.9434V54.2051H66.2339V52.2969Z"
        fill="#3F3D56"
      />
      <path
        d="M62.4164 61.4568V59.5483H60.126V61.4568H62.2257H62.4164Z"
        fill="#3F3D56"
      />
      <path
        d="M62.2257 62.9834H60.126V64.8915H62.4164V62.9834H62.2257Z"
        fill="#3F3D56"
      />
      <path
        d="M66.2339 59.5483H63.9434V61.4566H66.2339V59.5483Z"
        fill="#3F3D56"
      />
      <path
        d="M66.2339 62.9829H63.9434V64.8912H66.2339V62.9829Z"
        fill="#3F3D56"
      />
      <path
        d="M62.4164 72.1428V70.2344H60.126V72.1428H62.2257H62.4164Z"
        fill="#3F3D56"
      />
      <path
        d="M62.2257 73.6694H60.126V75.5776H62.4164V73.6694H62.2257Z"
        fill="#3F3D56"
      />
      <path
        d="M66.2339 70.2344H63.9434V72.1426H66.2339V70.2344Z"
        fill="#3F3D56"
      />
      <path
        d="M66.2339 73.6689H63.9434V75.5772H66.2339V73.6689Z"
        fill="#3F3D56"
      />
      <path
        d="M62.4164 82.8288V80.9204H60.126V82.8288H62.2257H62.4164Z"
        fill="#3F3D56"
      />
      <path
        d="M62.2257 84.3555H60.126V86.2636H62.4164V84.3555H62.2257Z"
        fill="#3F3D56"
      />
      <path
        d="M66.2339 80.9204H63.9434V82.8287H66.2339V80.9204Z"
        fill="#3F3D56"
      />
      <path
        d="M66.2339 84.3555H63.9434V86.2637H66.2339V84.3555Z"
        fill="#3F3D56"
      />
      <path
        d="M28.4404 50.7705H50.5824V55.3503C43.4852 53.3922 36.0842 53.5326 28.4404 55.3503V50.7705Z"
        fill="#3F3D56"
      />
      <path
        d="M28.4404 59.9297C35.3512 61.9652 42.7319 61.9652 50.5824 59.9297V64.5095H28.4404V59.9297Z"
        fill="#3F3D56"
      />
      <path
        d="M28.4404 69.0894H50.5824V73.6692C43.0365 71.2603 35.648 71.1468 28.4404 73.6692V69.0894Z"
        fill="#3F3D56"
      />
      <path
        d="M28.4404 78.249C35.6616 81.0313 43.0489 80.9155 50.5824 78.249V82.8288H28.4404V78.249Z"
        fill="#3F3D56"
      />
      <path
        d="M147.442 70.2925H162.999V77.7866L161.61 78.2212L156.987 79.6637L149.734 77.984L142.49 76.3057L138.664 75.4198L136.754 74.7133C138.343 72.5337 141.233 70.8194 147.442 70.2925Z"
        fill="#3F3D56"
      />
      <path
        d="M162.999 74.7134V97.5227H136.754C136.746 97.5086 136.74 97.4958 136.732 97.4817V97.4804C136.726 97.4645 136.718 97.4491 136.71 97.4342C136.3 96.6098 135.933 95.7875 135.608 94.9674C132.853 88.0272 133.152 81.2703 136.754 74.7134H162.999Z"
        fill="#F2F2F2"
      />
      <path
        d="M153.361 85.5005H148.017V97.5224H153.361V85.5005Z"
        fill="#CCCCCC"
      />
      <path
        d="M142.29 88.935V87.0269H140V88.935H142.1H142.29Z"
        fill="#3F3D56"
      />
      <path
        d="M142.1 90.4619H140V92.3703H142.29V90.4619H142.1Z"
        fill="#3F3D56"
      />
      <path
        d="M146.108 87.0269H143.817V88.9351H146.108V87.0269Z"
        fill="#3F3D56"
      />
      <path
        d="M146.108 90.4619H143.817V92.3702H146.108V90.4619Z"
        fill="#3F3D56"
      />
      <path
        d="M157.942 88.935V87.0269H155.651V88.935H157.751H157.942Z"
        fill="#3F3D56"
      />
      <path
        d="M157.751 90.4619H155.651V92.3703H157.942V90.4619H157.751Z"
        fill="#3F3D56"
      />
      <path
        d="M161.759 87.0269H159.469V88.9351H161.759V87.0269Z"
        fill="#3F3D56"
      />
      <path
        d="M161.759 90.4619H159.469V92.3702H161.759V90.4619Z"
        fill="#3F3D56"
      />
      <path
        d="M142.49 61.437H145.78C149.026 61.437 152.138 62.7258 154.433 65.0198C156.727 67.3138 158.016 70.4252 158.016 73.6694H142.49V61.437Z"
        fill="#3F3D56"
      />
      <path
        d="M42.835 28.2275H46.1252C49.3704 28.2275 52.4827 29.5163 54.7774 31.8103C57.072 34.1044 58.3612 37.2157 58.3612 40.46H42.835V28.2275Z"
        fill="#3F3D56"
      />
      <path
        d="M95.4307 58.9463H98.7209C101.966 58.9463 105.078 60.2351 107.373 62.5291C109.668 64.8231 110.957 67.9345 110.957 71.1787H95.4307V58.9463Z"
        fill="#3F3D56"
      />
      <path d="M158.016 78.249H142.49V82.8288H158.016V78.249Z" fill="#3F3D56" />
      <path
        d="M162.999 97.333H5.21094V97.8865H162.999V97.333Z"
        fill="#3F3D56"
      />
      <path
        d="M19.7636 75.4688H19.21V97.3314H19.7636V75.4688Z"
        fill="#3F3D56"
      />
      <path
        d="M24.8737 78.2552C24.909 85.9 19.5289 92.1223 19.5289 92.1223C19.5289 92.1223 12.639 85.8175 14.0564 78.3051C15.4819 70.7498 19.9247 64.4701 19.4012 64.438C19.4012 64.438 24.8385 70.6104 24.8737 78.2552Z"
        fill="#F7B80B"
      />
      <path
        d="M75.1288 75.4692H74.5752V97.3319H75.1288V75.4692Z"
        fill="#3F3D56"
      />
      <path
        d="M80.2384 78.2552C80.7374 85.8838 74.8935 92.1223 74.8935 92.1223C74.8935 92.1223 69.4563 85.9499 69.4211 78.3051C69.3858 70.6603 74.7659 64.438 74.7659 64.438C74.2414 66.5336 79.8346 72.0828 80.2384 78.2552Z"
        fill="#F7B80B"
      />
      <path
        d="M39.1592 39.931L63.2078 38.7549L73.296 47.1731L71.5125 48.7829L55.5374 48.4234L39.1592 39.931Z"
        fill="#3F3D56"
      />
      <path
        d="M15.1631 78.9976L13.8675 80.1682L13.7098 80.3093L13.3803 80.6082L5.21131 80.4256V70.7783C4.94556 70.7202 4.67981 70.6649 4.41406 70.6123L5.07567 70.5791L5.21131 70.6926L6.47639 71.7469L10.0751 74.7524L14.0945 78.1065L15.1631 78.9976Z"
        fill="#3F3D56"
      />
      <path
        d="M91.7549 71.7562L115.804 70.5801L125.892 78.9983L124.108 80.6081L108.133 80.2486L91.7549 71.7562Z"
        fill="#3F3D56"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_3982_41116"
        x="0.414062"
        y="0"
        width="166.586"
        height="108"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3982_41116"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3982_41116"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_3982_41116">
        <rect
          width="158.586"
          height="100"
          fill="white"
          transform="translate(4.41406)"
        />
      </clipPath>
    </defs>
  </Icon>
);
