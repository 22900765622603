import {
  FlyerDistributionSchedule,
  // FlyerDistributionSchedule,
  FlyersDistributionConfig,
} from "../../components/FlyersBlock/flyersDistributionConfig";
import {
  HIDE_ACCUEIL_OPTIONS,
  OnboardingInfo,
} from "../../context/OnboardingContext";
import { OptionInformation, OptionType } from "../../context/OptionsContext";
import {
  PERSONNEL_TYPE_LABELS,
  PersonnelSelectionInfo,
  PersonnelType,
  SectionTitle,
  UserInformation,
} from "../../context/UserContext";
import { PageURL } from "../../types/pages";
import { makeDateInfoObject } from "../../utils/utils";
import { createEventScheduleObject } from "../Calendar/EventDateInfo";
import {
  IDK,
  OVER_1200,
  OVER_1500,
  OVER_3000,
  OVER_600,
  OVER_700,
  OVER_750,
  OVER_800,
  SHIFT_0_50,
  SHIFT_1000_2000,
  SHIFT_100_150,
  SHIFT_100_200,
  SHIFT_100_250,
  SHIFT_1200_1500,
  SHIFT_1500_2000,
  SHIFT_150_250,
  SHIFT_2000_3000,
  SHIFT_200_300,
  SHIFT_200_400,
  SHIFT_250_300,
  SHIFT_250_350,
  SHIFT_250_500,
  SHIFT_300_400,
  SHIFT_300_600,
  SHIFT_350_500,
  SHIFT_400_500,
  SHIFT_400_550,
  SHIFT_400_600,
  SHIFT_400_700,
  SHIFT_500_600,
  SHIFT_500_650,
  SHIFT_500_700,
  SHIFT_500_800,
  SHIFT_50_100,
  SHIFT_550_750,
  SHIFT_600_1000,
  SHIFT_600_700,
  SHIFT_650_800,
  SHIFT_700_800,
  SHIFT_700_900,
  SHIFT_800_1200,
  SHIFT_800_1500,
  SHIFT_800_900,
  SHIFT_900_1200,
  SHIFT_900_1500,
  STAND_0_50,
  STAND_100_200,
  STAND_200_300,
  STAND_300_400,
  STAND_300_450,
  STAND_450_600,
  STAND_50_100,
  STAND_600_800,
  STAND_OVER_400,
} from "./onboardingSizeConfigs";

export const manageTablettesHiding = (
  optionsToHide: OptionType[],
  digital: boolean | undefined
) => {
  const newOptionsToHide = [...optionsToHide];
  if (digital !== true) {
    newOptionsToHide.push(OptionType.TabletteNumérique);
  }
  return newOptionsToHide;
};
const addPersons = (
  persons: PersonnelSelectionInfo["persons"],
  id: PersonnelType,
  count: number
): PersonnelSelectionInfo["persons"] => {
  if (count === 0) return persons;
  return {
    ...persons,
    [id]: {
      title: PERSONNEL_TYPE_LABELS[id],
      count,
      role: id,
    },
  };
};

const handleEmargement = (
  persons: PersonnelSelectionInfo["persons"],
  emargement: boolean | undefined,
  count: number
): PersonnelSelectionInfo["persons"] => {
  console.log(persons[PersonnelType.emargement]?.count);
  if (count === 0) return persons;
  if (emargement === true)
    if (persons[PersonnelType.emargement]?.count !== undefined) {
      const actualCount = persons[PersonnelType.emargement]?.count ?? 0;
      return addPersons(persons, PersonnelType.emargement, actualCount + count);
    } else return addPersons(persons, PersonnelType.emargement, count);
  return persons;
};

const handleVestiaire = (
  persons: PersonnelSelectionInfo["persons"],
  winter: boolean | undefined,
  countWinter: number,
  countSummer: number
): PersonnelSelectionInfo["persons"] => {
  if (winter === true && countWinter > 0) {
    persons = addPersons(persons, PersonnelType.vestiaire, countWinter);
    if (countWinter >= 5)
      return addPersons(persons, PersonnelType.responsableVestiaire, 1);
  } else if (winter === false && countSummer > 0) {
    persons = addPersons(persons, PersonnelType.vestiaire, countSummer);
    if (countSummer >= 5)
      return addPersons(persons, PersonnelType.responsableVestiaire, 1);
  }
  return persons;
};

const addAccueilOrPolyvalente = (
  persons: PersonnelSelectionInfo["persons"],
  count: number
): PersonnelSelectionInfo["persons"] => {
  if (count === 0) return persons;
  if (persons && Object.keys(persons).length !== 0)
    return addPersons(persons, PersonnelType.accueil, count);
  else if (persons && Object.keys(persons).length === 0)
    return addPersons(persons, PersonnelType.hotessePolyvalente, count);
  return persons;
};

const handleCheffeHotesse = (
  persons: PersonnelSelectionInfo["persons"]
): PersonnelSelectionInfo["persons"] => {
  let total = 0;
  for (const person in persons)
    total += persons[person as PersonnelType]!.count;
  if (total >= 4) return addPersons(persons, PersonnelType.chefHotesse, 1);
  return persons;
};

const setPersonnelInfo = (
  persons: any,
  rolesToShow: PersonnelType[],
  tabsToHide: SectionTitle[],
  userInfos: UserInformation | null,
  setUserInfos: (userInfos: UserInformation) => any
) => {
  const calendarInfo = makeDateInfoObject(userInfos?.calendarInfo);
  setUserInfos({
    ...userInfos,
    selectDePersonnelInfo: {
      ...userInfos?.selectDePersonnelInfo,
      persons,
      rolesToShow,
      tabsToHide,
    },
    calendarInfo: calendarInfo,
  });
};

export const setOptionConfig = (
  optionsToHide: OptionType[],
  optionInfo: OptionInformation | null,
  selectedOptions: { [option in OptionType]: number },
  setOptionInfo: (optionInfo: OptionInformation) => any
) => {
  setOptionInfo({
    ...optionInfo,
    selectedOptions: selectedOptions,
    optionsToHide: optionsToHide,
  });
};

export function setFlyerPreselection(
  userInfos: UserInformation,
  setUserInfos: (userInfos: UserInformation) => any,
  days: string[],
  hours: FlyerDistributionSchedule,
  chosenOption: FlyersDistributionConfig
): string {
  let persons = {};

  let personCount = chosenOption.personsPerTeam * chosenOption.teams;
  persons = addPersons(persons, PersonnelType.distribution, personCount);
  if (personCount > 2 && personCount <= 10) {
    persons = addPersons(persons, PersonnelType.chef, 1);
  } else if (personCount > 10) {
    persons = addPersons(persons, PersonnelType.chef, 2);
  }
  const schedule = createEventScheduleObject(
    days,
    hours.startHour,
    hours.endHour
  );
  setUserInfos({
    ...userInfos,
    selectDePersonnelInfo: {
      ...userInfos.selectDePersonnelInfo,
      persons: persons,
      tabsToHide: HIDE_ACCUEIL_ANIMATION,
    },
    calendarInfo: {
      days: days.map((day) => day),
      period: null,
      eventSchedule: schedule,
    },
  });
  return PageURL.CALENDAR;
}

const SALON_ROLES = [
  PersonnelType.hotessePolyvalente,
  PersonnelType.chefHotesse,
  PersonnelType.accueil,
  PersonnelType.emargement,
];

const HIDE_MARKETING_ANIMATION = [
  SectionTitle.STREET_MARKETING,
  SectionTitle.ANIMATION,
];

const HIDE_ACCUEIL_ANIMATION = [SectionTitle.SERVICE, SectionTitle.ANIMATION];

const DEFAULT_ROLES = [
  PersonnelType.hotessePolyvalente,
  PersonnelType.chefHotesse,
  PersonnelType.accueil,
  PersonnelType.emargement,
  PersonnelType.orientation,
  PersonnelType.vestiaire,
  PersonnelType.responsableVestiaire,
  PersonnelType.regisseur,
  PersonnelType.barman,
  PersonnelType.maitreHotel,
];

export function preselectSalonB2B( //update 13/06 03/07 18/08
  onboardingInfo: OnboardingInfo,
  userInfos: UserInformation | null,
  setUserInfos: (userContent: UserInformation) => any,
  optionInfo: OptionInformation | null,
  setOptionInfo: (optionContent: OptionInformation) => any
): string {
  let persons = {};
  let page: string = PageURL.CALENDAR;
  const emargement = onboardingInfo.options.emargementConfig.emargement;
  let emargementNb = 0;
  const digital = onboardingInfo.options.emargementConfig.digital;

  switch (onboardingInfo.options.salonSize) {
    case STAND_0_50:
      emargementNb = digital ? 1 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 1
      );
      persons = addPersons(persons, PersonnelType.hotessePolyvalente, 2);
      break;
    case STAND_50_100:
      emargementNb = digital ? 1 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 1
      );
      persons = addAccueilOrPolyvalente(persons, 3);
      break;
    case STAND_100_200:
      emargementNb = digital ? 2 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 2
      );
      persons = addAccueilOrPolyvalente(persons, 5);
      persons = handleCheffeHotesse(persons);
      break;
    case STAND_200_300:
      emargementNb = digital ? 3 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 4
      );
      persons = addAccueilOrPolyvalente(persons, 8);
      persons = handleCheffeHotesse(persons);
      break;
    case STAND_300_400:
      emargementNb = digital ? 3 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 5
      );
      persons = addAccueilOrPolyvalente(persons, 10);
      persons = handleCheffeHotesse(persons);
      break;
    case STAND_OVER_400:
      page = `${PageURL.CONTACT}?complex=true`;
      break;
  }

  setPersonnelInfo(
    persons,
    SALON_ROLES,
    HIDE_MARKETING_ANIMATION,
    userInfos,
    setUserInfos
  );

  const optionsToHide = manageTablettesHiding(HIDE_ACCUEIL_OPTIONS, digital);

  let tmpSelectedOptions = optionInfo!.selectedOptions;

  tmpSelectedOptions = {
    ...optionInfo!.selectedOptions,
    ...(emargement && digital && emargementNb !== 0
      ? { "Tablette numérique": emargementNb }
      : {}),
  };

  setOptionConfig(optionsToHide, optionInfo, tmpSelectedOptions, setOptionInfo);

  return page;
}

export function preselectPress( //update 13/06 03/07 18/08
  onboardingInfo: OnboardingInfo,
  userInfos: UserInformation | null,
  setUserInfos: (userContent: UserInformation) => any,
  optionInfo: OptionInformation | null,
  setOptionInfo: (optionContent: OptionInformation) => any
): string {
  let persons = {};
  let page: string = PageURL.CALENDAR;
  const emargement = onboardingInfo.options.emargementConfig.emargement;
  let emargementNb = 0;
  const winter = onboardingInfo.options.eventInWinter;
  const digital = onboardingInfo.options.emargementConfig.digital;

  switch (onboardingInfo.options.nbParticipants) {
    case IDK:
      break;
    case SHIFT_50_100:
      emargementNb = digital ? 1 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 2
      );
      persons = handleVestiaire(persons, winter, 1, 1);
      persons = addAccueilOrPolyvalente(persons, 2);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_100_150:
      emargementNb = digital ? 2 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 2
      );
      persons = handleVestiaire(persons, winter, 2, 1);
      persons = addAccueilOrPolyvalente(persons, 3);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_150_250:
      emargementNb = digital ? 2 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 3
      );
      persons = handleVestiaire(persons, winter, 3, 2);
      persons = addAccueilOrPolyvalente(persons, 3);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_250_300:
      emargementNb = digital ? 2 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 3
      );
      persons = handleVestiaire(persons, winter, 3, 2);
      persons = addAccueilOrPolyvalente(persons, 3);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_300_400:
      emargementNb = digital ? 3 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 4
      );
      persons = handleVestiaire(persons, winter, 4, 2);
      persons = addAccueilOrPolyvalente(persons, 4);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_400_550:
      emargementNb = digital ? 4 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 6
      );
      persons = handleVestiaire(persons, winter, 5, 3);
      persons = addAccueilOrPolyvalente(persons, 6);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_550_750:
      emargementNb = digital ? 5 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 8
      );
      persons = handleVestiaire(persons, winter, 6, 4);
      persons = addAccueilOrPolyvalente(persons, 7);
      persons = handleCheffeHotesse(persons);
      break;
    case OVER_750:
      page = `${PageURL.CONTACT}?complex=true`;
      break;
  }

  const roles = [...DEFAULT_ROLES];
  if (onboardingInfo.options.parking) {
    roles.push(PersonnelType.voiturier);
    persons = addPersons(persons, PersonnelType.voiturier, 1);
  }

  setPersonnelInfo(
    persons,
    roles,
    HIDE_MARKETING_ANIMATION,
    userInfos,
    setUserInfos
  );

  const result =
    Number(onboardingInfo.options.nbParticipants?.split("-")[1]) / 50;

  const optionsToHide = manageTablettesHiding(HIDE_ACCUEIL_OPTIONS, digital);

  let tmpSelectedOptions = optionInfo!.selectedOptions;

  tmpSelectedOptions = {
    ...optionInfo!.selectedOptions,
    ...(emargement && digital && emargementNb !== 0
      ? { "Tablette numérique": emargementNb }
      : {}),
    ...(winter !== undefined && result !== 0 ? { Portant: result } : {}),
    ...(winter !== undefined && result !== 0
      ? { "Tickets vestiaires": result }
      : {}),
  };

  setOptionConfig(optionsToHide, optionInfo, tmpSelectedOptions, setOptionInfo);

  return page;
}

export function preselectCongress( //update 13/06 03/07 18/08
  onboardingInfo: OnboardingInfo,
  userInfos: UserInformation | null,
  setUserInfos: (userContent: UserInformation) => any,
  optionInfo: OptionInformation | null,
  setOptionInfo: (optionContent: OptionInformation) => any
): string {
  let persons = {};
  let page: string = PageURL.CALENDAR;
  const emargement = onboardingInfo.options.emargementConfig.emargement;
  let emargementNb = 0;
  const winter = onboardingInfo.options.eventInWinter;
  const digital = onboardingInfo.options.emargementConfig.digital;

  switch (onboardingInfo.options.nbParticipants) {
    case IDK:
      break;
    case SHIFT_50_100:
      emargementNb = digital ? 1 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 2
      );
      persons = handleVestiaire(persons, winter, 1, 1);
      persons = addAccueilOrPolyvalente(persons, 2);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_100_200:
      emargementNb = digital ? 2 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 2
      );
      persons = handleEmargement(persons, emargement, 2);
      persons = handleVestiaire(persons, winter, 2, 1);
      persons = addAccueilOrPolyvalente(persons, 3);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_200_300:
      emargementNb = digital ? 3 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 4
      );
      persons = handleVestiaire(persons, winter, 3, 2);
      persons = addAccueilOrPolyvalente(persons, 3);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_300_400:
      emargementNb = digital ? 3 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 6
      );
      persons = handleVestiaire(persons, winter, 4, 2);
      persons = addAccueilOrPolyvalente(persons, 3);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_400_500:
      emargementNb = digital ? 8 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 4
      );
      persons = handleVestiaire(persons, winter, 5, 3);
      persons = addAccueilOrPolyvalente(persons, 4);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_500_700:
      emargementNb = digital ? 6 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 9
      );
      persons = handleVestiaire(persons, winter, 7, 4);
      persons = addAccueilOrPolyvalente(persons, 5);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_700_900:
      emargementNb = digital ? 7 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 10
      );
      persons = handleVestiaire(persons, winter, 9, 5);
      persons = addAccueilOrPolyvalente(persons, 7);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_900_1200:
      emargementNb = digital ? 8 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 12
      );
      persons = handleVestiaire(persons, winter, 12, 7);
      persons = addAccueilOrPolyvalente(persons, 8);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_1200_1500:
      emargementNb = digital ? 12 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 16
      );
      persons = handleVestiaire(persons, winter, 14, 9);
      persons = addAccueilOrPolyvalente(persons, 10);
      persons = handleCheffeHotesse(persons);
      break;
    case OVER_1500:
      page = `${PageURL.CONTACT}?complex=true`;
      break;
  }

  const roles = [...DEFAULT_ROLES];
  if (onboardingInfo.options.parking) {
    roles.push(PersonnelType.voiturier);
    persons = addPersons(persons, PersonnelType.voiturier, 1);
  }

  setPersonnelInfo(
    persons,
    roles,
    HIDE_MARKETING_ANIMATION,
    userInfos,
    setUserInfos
  );

  const result =
    Number(onboardingInfo.options.nbParticipants?.split("-")[1]) / 50;

  const optionsToHide = manageTablettesHiding(HIDE_ACCUEIL_OPTIONS, digital);

  let tmpSelectedOptions = optionInfo!.selectedOptions;

  tmpSelectedOptions = {
    ...optionInfo!.selectedOptions,
    ...(emargement && digital && emargementNb !== 0
      ? { "Tablette numérique": emargementNb }
      : {}),
    ...(winter !== undefined && result !== 0 ? { Portant: result } : {}),
    ...(winter !== undefined && result !== 0
      ? { "Tickets vestiaires": result }
      : {}),
  };

  setOptionConfig(optionsToHide, optionInfo, tmpSelectedOptions, setOptionInfo);

  return page;
}

export function preselectAwards( //update 13/06 03/07 18/08
  onboardingInfo: OnboardingInfo,
  userInfos: UserInformation | null,
  setUserInfos: (userContent: UserInformation) => any,
  optionInfo: OptionInformation | null,
  setOptionInfo: (optionContent: OptionInformation) => any
): string {
  let persons = {};
  let page: string = PageURL.CALENDAR;
  const emargement = onboardingInfo.options.emargementConfig.emargement;
  let emargementNb = 0;
  const winter = onboardingInfo.options.eventInWinter;
  const digital = onboardingInfo.options.emargementConfig.digital;

  switch (onboardingInfo.options.nbParticipants) {
    case SHIFT_50_100:
      emargementNb = digital ? 1 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 2
      );
      persons = handleVestiaire(persons, winter, 1, 1);
      persons = addAccueilOrPolyvalente(persons, 2);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_100_150:
      emargementNb = digital ? 2 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 3
      );
      persons = handleVestiaire(persons, winter, 2, 1);
      persons = addAccueilOrPolyvalente(persons, 2);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_150_250:
      emargementNb = digital ? 2 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 4
      );
      persons = handleVestiaire(persons, winter, 2, 2);
      persons = addAccueilOrPolyvalente(persons, 3);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_250_350:
      emargementNb = digital ? 3 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 5
      );
      persons = handleVestiaire(persons, winter, 3, 2);
      persons = addAccueilOrPolyvalente(persons, 4);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_350_500:
      emargementNb = digital ? 4 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 6
      );
      persons = handleVestiaire(persons, winter, 5, 3);
      persons = addAccueilOrPolyvalente(persons, 4);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_500_650:
      emargementNb = digital ? 5 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 8
      );
      persons = handleVestiaire(persons, winter, 6, 3);
      persons = addAccueilOrPolyvalente(persons, 5);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_650_800:
      emargementNb = digital ? 6 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 10
      );
      persons = handleVestiaire(persons, winter, 7, 4);
      persons = addAccueilOrPolyvalente(persons, 6);
      persons = handleCheffeHotesse(persons);
      break;
    case OVER_800:
      page = `${PageURL.CONTACT}?complex=true`;
      break;
  }

  const roles = [...DEFAULT_ROLES];
  if (onboardingInfo.options.parking) {
    roles.push(PersonnelType.voiturier);
    persons = addPersons(persons, PersonnelType.voiturier, 1);
  }

  setPersonnelInfo(
    persons,
    roles,
    HIDE_MARKETING_ANIMATION,
    userInfos,
    setUserInfos
  );

  const result =
    Number(onboardingInfo.options.nbParticipants?.split("-")[1]) / 50;

  const optionsToHide = manageTablettesHiding(HIDE_ACCUEIL_OPTIONS, digital);

  let tmpSelectedOptions = optionInfo!.selectedOptions;

  tmpSelectedOptions = {
    ...optionInfo!.selectedOptions,
    ...(emargement && digital && emargementNb !== 0
      ? { "Tablette numérique": emargementNb }
      : {}),
    ...(winter !== undefined && result !== 0 ? { Portant: result } : {}),
    ...(winter !== undefined && result !== 0
      ? { "Tickets vestiaires": result }
      : {}),
  };

  setOptionConfig(optionsToHide, optionInfo, tmpSelectedOptions, setOptionInfo);

  return page;
}

export function preselectPremiere( //update 13/06 03/07 18/08
  onboardingInfo: OnboardingInfo,
  userInfos: UserInformation | null,
  setUserInfos: (userContent: UserInformation) => any,
  optionInfo: OptionInformation | null,
  setOptionInfo: (optionContent: OptionInformation) => any
): string {
  let persons = {};
  let page: string = PageURL.CALENDAR;
  const emargement = onboardingInfo.options.emargementConfig.emargement;
  let emargementNb = 0;
  const winter = onboardingInfo.options.eventInWinter;
  const digital = onboardingInfo.options.emargementConfig.digital;

  switch (onboardingInfo.options.nbParticipants) {
    case IDK:
      break;
    case SHIFT_50_100:
      emargementNb = digital ? 1 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 2
      );
      persons = handleVestiaire(persons, winter, 1, 1);
      persons = addAccueilOrPolyvalente(persons, 2);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_100_200:
      emargementNb = digital ? 2 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 3
      );
      persons = handleVestiaire(persons, winter, 2, 1);
      persons = addAccueilOrPolyvalente(persons, 2);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_200_400:
      emargementNb = digital ? 3 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 4
      );
      persons = handleVestiaire(persons, winter, 4, 3);
      persons = addAccueilOrPolyvalente(persons, 3);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_400_600:
      emargementNb = digital ? 4 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 6
      );
      persons = handleVestiaire(persons, winter, 5, 4);
      persons = addAccueilOrPolyvalente(persons, 4);
      persons = handleCheffeHotesse(persons);
      break;
    case OVER_600:
      page = `${PageURL.CONTACT}?complex=true`;
      break;
  }

  const roles = [...DEFAULT_ROLES];
  if (onboardingInfo.options.parking) {
    roles.push(PersonnelType.voiturier);
    persons = addPersons(persons, PersonnelType.voiturier, 1);
  }

  setPersonnelInfo(
    persons,
    roles,
    HIDE_MARKETING_ANIMATION,
    userInfos,
    setUserInfos
  );

  const result =
    Number(onboardingInfo.options.nbParticipants?.split("-")[1]) / 50;

  const optionsToHide = manageTablettesHiding(HIDE_ACCUEIL_OPTIONS, digital);

  let tmpSelectedOptions = optionInfo!.selectedOptions;

  tmpSelectedOptions = {
    ...optionInfo!.selectedOptions,
    ...(emargement && digital && emargementNb !== 0
      ? { "Tablette numérique": emargementNb }
      : {}),
    ...(winter !== undefined && result !== 0 ? { Portant: result } : {}),
    ...(winter !== undefined && result !== 0
      ? { "Tickets vestiaires": result }
      : {}),
  };

  setOptionConfig(optionsToHide, optionInfo, tmpSelectedOptions, setOptionInfo);

  return page;
}

export function preselectOther( //update 13/06 03/07 18/08
  onboardingInfo: OnboardingInfo,
  userInfos: UserInformation | null,
  setUserInfos: (userContent: UserInformation) => any,
  optionInfo: OptionInformation | null,
  setOptionInfo: (optionContent: OptionInformation) => any
): string {
  let persons = {};
  let page: string = PageURL.CALENDAR;
  const emargement = onboardingInfo.options.emargementConfig.emargement;
  let emargementNb = 0;
  const winter = onboardingInfo.options.eventInWinter;
  const digital = onboardingInfo.options.emargementConfig.digital;

  switch (onboardingInfo.options.nbParticipants) {
    case IDK:
    case SHIFT_50_100:
      emargementNb = digital ? 1 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 2
      );
      persons = handleVestiaire(persons, winter, 2, 1);
      persons = addAccueilOrPolyvalente(persons, 2);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_100_200:
      emargementNb = digital ? 2 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 3
      );
      persons = handleVestiaire(persons, winter, 3, 2);
      persons = addAccueilOrPolyvalente(persons, 2);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_200_300:
      emargementNb = digital ? 3 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 4
      );
      persons = handleVestiaire(persons, winter, 3, 2);
      persons = addAccueilOrPolyvalente(persons, 3);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_300_400:
      emargementNb = digital ? 3 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 5
      );
      persons = handleVestiaire(persons, winter, 4, 3);
      persons = addAccueilOrPolyvalente(persons, 4);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_400_700:
      emargementNb = digital ? 5 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 7
      );
      persons = handleVestiaire(persons, winter, 6, 3);
      persons = addAccueilOrPolyvalente(persons, 5);
      persons = handleCheffeHotesse(persons);
      break;
    case OVER_700:
      page = `${PageURL.CONTACT}?complex=true`;
      break;
  }

  const roles = [...DEFAULT_ROLES];
  if (onboardingInfo.options.parking) {
    roles.push(PersonnelType.voiturier);
    persons = addPersons(persons, PersonnelType.voiturier, 1);
  }

  setPersonnelInfo(
    persons,
    roles,
    HIDE_MARKETING_ANIMATION,
    userInfos,
    setUserInfos
  );

  const result =
    Number(onboardingInfo.options.nbParticipants?.split("-")[1]) / 50;

  const optionsToHide = manageTablettesHiding(HIDE_ACCUEIL_OPTIONS, digital);

  let tmpSelectedOptions = optionInfo!.selectedOptions;

  tmpSelectedOptions = {
    ...optionInfo!.selectedOptions,
    ...(emargement && digital && emargementNb !== 0
      ? { "Tablette numérique": emargementNb }
      : {}),
    ...(winter !== undefined && result !== 0 ? { Portant: result } : {}),
    ...(winter !== undefined && result !== 0
      ? { "Tickets vestiaires": result }
      : {}),
  };

  setOptionConfig(optionsToHide, optionInfo, tmpSelectedOptions, setOptionInfo);

  return page;
}

export function preselectInauguration( //update 13/06 03/07 18/08
  onboardingInfo: OnboardingInfo,
  userInfos: UserInformation | null,
  setUserInfos: (userContent: UserInformation) => any,
  optionInfo: OptionInformation | null,
  setOptionInfo: (optionContent: OptionInformation) => any
): string {
  let persons = {};
  let page: string = PageURL.CALENDAR;
  const emargement = onboardingInfo.options.emargementConfig.emargement;
  let emargementNb = 0;
  const winter = onboardingInfo.options.eventInWinter;
  const digital = onboardingInfo.options.emargementConfig.digital;

  switch (onboardingInfo.options.nbParticipants) {
    case IDK:
      break;
    case SHIFT_50_100:
      emargementNb = digital ? 1 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 2
      );
      persons = handleVestiaire(persons, winter, 1, 1);
      persons = addAccueilOrPolyvalente(persons, 2);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_100_200:
      emargementNb = digital ? 2 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 3
      );
      persons = handleVestiaire(persons, winter, 2, 1);
      persons = addAccueilOrPolyvalente(persons, 2);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_200_300: // TODO
      break;
    case SHIFT_300_400:
      emargementNb = digital ? 3 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 3
      );
      persons = handleVestiaire(persons, winter, 4, 2);
      persons = addAccueilOrPolyvalente(persons, 3);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_400_500:
      emargementNb = digital ? 4 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 5
      );
      persons = handleVestiaire(persons, winter, 5, 3);
      persons = addAccueilOrPolyvalente(persons, 4);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_500_600:
      emargementNb = digital ? 5 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 6
      );
      persons = handleVestiaire(persons, winter, 6, 4);
      persons = addAccueilOrPolyvalente(persons, 5);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_600_700:
      emargementNb = digital ? 6 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 7
      );
      persons = handleVestiaire(persons, winter, 7, 4);
      persons = addAccueilOrPolyvalente(persons, 6);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_700_800:
      emargementNb = digital ? 7 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 8
      );
      persons = handleVestiaire(persons, winter, 9, 5);
      persons = addAccueilOrPolyvalente(persons, 8);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_800_900:
      emargementNb = digital ? 8 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 10
      );
      persons = handleVestiaire(persons, winter, 10, 5);
      persons = addAccueilOrPolyvalente(persons, 8);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_900_1500:
      emargementNb = digital ? 8 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 12
      );
      persons = handleVestiaire(persons, winter, 12, 6);
      persons = addAccueilOrPolyvalente(persons, 12);
      persons = handleCheffeHotesse(persons);
      break;
    case OVER_1500:
      page = `${PageURL.CONTACT}?complex=true`;
      break;
  }

  const roles = [...DEFAULT_ROLES];
  if (onboardingInfo.options.parking) {
    roles.push(PersonnelType.voiturier);
    persons = addPersons(persons, PersonnelType.voiturier, 1);
  }

  setPersonnelInfo(
    persons,
    roles,
    HIDE_MARKETING_ANIMATION,
    userInfos,
    setUserInfos
  );

  const result =
    Number(onboardingInfo.options.nbParticipants?.split("-")[1]) / 50;

  const optionsToHide = manageTablettesHiding(HIDE_ACCUEIL_OPTIONS, digital);

  let tmpSelectedOptions = optionInfo!.selectedOptions;

  tmpSelectedOptions = {
    ...optionInfo!.selectedOptions,
    ...(emargement && digital && emargementNb !== 0
      ? { "Tablette numérique": emargementNb }
      : {}),
    ...(winter !== undefined && result !== 0 ? { Portant: result } : {}),
    ...(winter !== undefined && result !== 0
      ? { "Tickets vestiaires": result }
      : {}),
  };

  setOptionConfig(optionsToHide, optionInfo, tmpSelectedOptions, setOptionInfo);

  return page;
}

export function preselectEventVillage( //update 13/06 03/07 18/08
  onboardingInfo: OnboardingInfo,
  userInfos: UserInformation | null,
  setUserInfos: (userContent: UserInformation) => any,
  optionInfo: OptionInformation | null,
  setOptionInfo: (optionContent: OptionInformation) => any
): string {
  let persons = {};
  let page: string = PageURL.CALENDAR;

  switch (onboardingInfo.options.nbParticipants) {
    case IDK:
      break;
    case SHIFT_300_600:
      persons = addAccueilOrPolyvalente(persons, 6);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_600_1000:
      persons = addAccueilOrPolyvalente(persons, 8);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_1000_2000:
      persons = addAccueilOrPolyvalente(persons, 12);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_2000_3000:
      persons = addAccueilOrPolyvalente(persons, 16);
      persons = handleCheffeHotesse(persons);
      break;
    case OVER_3000:
      page = `${PageURL.CONTACT}?complex=true`;
      break;
  }

  const roles = [...DEFAULT_ROLES];
  if (onboardingInfo.options.parking) {
    roles.push(PersonnelType.voiturier);
    persons = addPersons(persons, PersonnelType.voiturier, 1);
  }

  setPersonnelInfo(
    persons,
    roles,
    HIDE_MARKETING_ANIMATION,
    userInfos,
    setUserInfos
  );

  const digital = onboardingInfo.options.emargementConfig.digital;

  const optionsToHide = manageTablettesHiding(HIDE_ACCUEIL_OPTIONS, digital);

  let tmpSelectedOptions = optionInfo!.selectedOptions;

  setOptionConfig(optionsToHide, optionInfo, tmpSelectedOptions, setOptionInfo);

  return page;
}

export function preselectSalonPublic( //update 13/06 03/07 18/08
  onboardingInfo: OnboardingInfo,
  userInfos: UserInformation | null,
  setUserInfos: (userContent: UserInformation) => any,
  optionInfo: OptionInformation | null,
  setOptionInfo: (optionContent: OptionInformation) => any
): string {
  let persons = {};
  let page: string = PageURL.CALENDAR;
  const emargement = onboardingInfo.options.emargementConfig.emargement;
  let emargementNb = 0;
  const digital = onboardingInfo.options.emargementConfig.digital;

  switch (onboardingInfo.options.salonSize) {
    case IDK:
      break;
    case STAND_0_50:
      emargementNb = digital ? 1 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 2
      );
      persons = addAccueilOrPolyvalente(persons, 1);
      persons = handleCheffeHotesse(persons);
      break;
    case STAND_50_100:
      emargementNb = digital ? 2 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 2
      );
      persons = addAccueilOrPolyvalente(persons, 2);
      persons = handleCheffeHotesse(persons);
      break;
    case STAND_100_200:
      emargementNb = digital ? 2 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 3
      );
      persons = addAccueilOrPolyvalente(persons, 3);
      persons = handleCheffeHotesse(persons);
      break;
    case STAND_200_300:
      emargementNb = digital ? 3 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 4
      );
      persons = addAccueilOrPolyvalente(persons, 4);
      persons = handleCheffeHotesse(persons);
      break;
    case STAND_300_450:
      emargementNb = digital ? 4 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 5
      );
      persons = addAccueilOrPolyvalente(persons, 6);
      persons = handleCheffeHotesse(persons);
      break;
    case STAND_450_600:
      emargementNb = digital ? 5 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 6
      );
      persons = addAccueilOrPolyvalente(persons, 14);
      persons = handleCheffeHotesse(persons);
      break;
    case STAND_600_800:
      emargementNb = digital ? 6 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 8
      );
      persons = addAccueilOrPolyvalente(persons, 16);
      persons = handleCheffeHotesse(persons);
      break;
  }
  setPersonnelInfo(
    persons,
    SALON_ROLES,
    HIDE_MARKETING_ANIMATION,
    userInfos,
    setUserInfos
  );

  const optionsToHide = manageTablettesHiding(HIDE_ACCUEIL_OPTIONS, digital);

  let tmpSelectedOptions = optionInfo!.selectedOptions;

  tmpSelectedOptions = {
    ...optionInfo!.selectedOptions,
    ...(emargement && digital && emargementNb !== 0
      ? { "Tablette numérique": emargementNb }
      : {}),
  };

  setOptionConfig(optionsToHide, optionInfo, tmpSelectedOptions, setOptionInfo);

  return page;
}

export function preselectSportEvent( //update 13/06 03/07 18/08
  onboardingInfo: OnboardingInfo,
  userInfos: UserInformation | null,
  setUserInfos: (userContent: UserInformation) => any,
  optionInfo: OptionInformation | null,
  setOptionInfo: (optionContent: OptionInformation) => any
): string {
  let persons = {};
  let page: string = PageURL.CALENDAR;
  const emargement = onboardingInfo.options.emargementConfig.emargement;
  let emargementNb = 0;
  const winter = onboardingInfo.options.eventInWinter;
  const digital = onboardingInfo.options.emargementConfig.digital;

  switch (onboardingInfo.options.nbParticipants) {
    case IDK:
      break;
    case SHIFT_0_50:
      emargementNb = digital ? 1 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 0
      );
      persons = handleVestiaire(persons, winter, 1, 1);
      persons = addAccueilOrPolyvalente(persons, 2);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_50_100:
      emargementNb = digital ? 1 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 2
      );
      persons = handleVestiaire(persons, winter, 2, 2);
      persons = addAccueilOrPolyvalente(persons, 2);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_100_250:
      emargementNb = digital ? 2 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 3
      );
      persons = handleVestiaire(persons, winter, 3, 2);
      persons = addAccueilOrPolyvalente(persons, 3);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_250_500:
      emargementNb = digital ? 4 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 5
      );
      persons = handleVestiaire(persons, winter, 5, 2);
      persons = addAccueilOrPolyvalente(persons, 4);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_500_800:
      emargementNb = digital ? 4 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 6
      );
      persons = handleVestiaire(persons, winter, 8, 3);
      persons = addAccueilOrPolyvalente(persons, 6);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_800_1500:
      emargementNb = digital ? 7 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 8
      );
      persons = handleVestiaire(persons, winter, 12, 6);
      persons = addAccueilOrPolyvalente(persons, 8);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_1500_2000:
      emargementNb = digital ? 8 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 10
      );
      persons = handleVestiaire(persons, winter, 0, 0);
      persons = addAccueilOrPolyvalente(persons, 12);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_2000_3000:
      emargementNb = digital ? 10 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 12
      );
      persons = handleVestiaire(persons, winter, 0, 0);
      persons = addAccueilOrPolyvalente(persons, 18);
      persons = handleCheffeHotesse(persons);
      break;
    case OVER_3000:
      page = `${PageURL.CONTACT}?complex=true`;
      break;
  }

  const roles = [...DEFAULT_ROLES];
  if (onboardingInfo.options.parking) {
    roles.push(PersonnelType.voiturier);
    persons = addPersons(persons, PersonnelType.voiturier, 1);
  }

  setPersonnelInfo(
    persons,
    roles,
    HIDE_MARKETING_ANIMATION,
    userInfos,
    setUserInfos
  );

  const result =
    Number(onboardingInfo.options.nbParticipants?.split("-")[1]) / 50;

  const optionsToHide = manageTablettesHiding(HIDE_ACCUEIL_OPTIONS, digital);

  let tmpSelectedOptions = optionInfo!.selectedOptions;

  tmpSelectedOptions = {
    ...optionInfo!.selectedOptions,
    ...(emargement && digital && emargementNb !== 0
      ? { "Tablette numérique": emargementNb }
      : {}),
    ...(winter !== undefined && result !== 0 ? { Portant: result } : {}),
    ...(winter !== undefined && result !== 0
      ? { "Tickets vestiaires": result }
      : {}),
  };

  setOptionConfig(optionsToHide, optionInfo, tmpSelectedOptions, setOptionInfo);

  return page;
}

export function preselectPublicOther( // update 13/06 03/07 18/08
  onboardingInfo: OnboardingInfo,
  userInfos: UserInformation | null,
  setUserInfos: (userContent: UserInformation) => any,
  optionInfo: OptionInformation | null,
  setOptionInfo: (optionContent: OptionInformation) => any
): string {
  let persons = {};
  let page: string = PageURL.CALENDAR;
  const emargement = onboardingInfo.options.emargementConfig.emargement;
  let emargementNb = 0;
  const winter = onboardingInfo.options.eventInWinter;
  const digital = onboardingInfo.options.emargementConfig.digital;

  switch (onboardingInfo.options.nbParticipants) {
    case IDK:
      break;
    case SHIFT_50_100:
      emargementNb = digital ? 1 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 2
      );
      persons = handleVestiaire(persons, winter, 2, 1);
      persons = addAccueilOrPolyvalente(persons, 2);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_100_200:
      emargementNb = digital ? 2 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 2
      );
      persons = handleVestiaire(persons, winter, 2, 2);
      persons = addAccueilOrPolyvalente(persons, 2);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_200_300:
      emargementNb = digital ? 3 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 4
      );
      persons = handleVestiaire(persons, winter, 4, 2);
      persons = addAccueilOrPolyvalente(persons, 3);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_300_400:
      emargementNb = digital ? 4 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 5
      );
      persons = handleVestiaire(persons, winter, 4, 3);
      persons = addAccueilOrPolyvalente(persons, 3);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_400_500:
      emargementNb = digital ? 4 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 6
      );
      persons = handleVestiaire(persons, winter, 5, 4);
      persons = addAccueilOrPolyvalente(persons, 4);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_500_600:
      emargementNb = digital ? 5 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 8
      );
      persons = handleVestiaire(persons, winter, 6, 5);
      persons = addAccueilOrPolyvalente(persons, 5);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_600_700:
      emargementNb = digital ? 5 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 9
      );
      persons = handleVestiaire(persons, winter, 8, 6);
      persons = addAccueilOrPolyvalente(persons, 6);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_700_800:
      emargementNb = digital ? 6 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 10
      );
      persons = handleVestiaire(persons, winter, 10, 7);
      persons = addAccueilOrPolyvalente(persons, 8);
      persons = handleCheffeHotesse(persons);
      break;
    case SHIFT_800_1200:
      emargementNb = digital ? 6 : emargementNb;
      persons = handleEmargement(
        persons,
        emargement,
        digital ? emargementNb : 12
      );
      persons = handleVestiaire(persons, winter, 12, 8);
      persons = addAccueilOrPolyvalente(persons, 10);
      persons = handleCheffeHotesse(persons);
      break;
    case OVER_1200:
      page = `${PageURL.CONTACT}?complex=true`;
      break;
  }

  const roles = [...DEFAULT_ROLES];
  if (onboardingInfo.options.parking) {
    roles.push(PersonnelType.voiturier);
    persons = addPersons(persons, PersonnelType.voiturier, 1);
  }

  setPersonnelInfo(
    persons,
    roles,
    HIDE_MARKETING_ANIMATION,
    userInfos,
    setUserInfos
  );

  const result =
    Number(onboardingInfo.options.nbParticipants?.split("-")[1]) / 50;

  const optionsToHide = manageTablettesHiding(HIDE_ACCUEIL_OPTIONS, digital);

  let tmpSelectedOptions = optionInfo!.selectedOptions;

  tmpSelectedOptions = {
    ...optionInfo!.selectedOptions,
    ...(emargement && digital && emargementNb !== 0
      ? { "Tablette numérique": emargementNb }
      : {}),
    ...(winter !== undefined && result !== 0 ? { Portant: result } : {}),
    ...(winter !== undefined && result !== 0
      ? { "Tickets vestiaires": result }
      : {}),
  };

  setOptionConfig(optionsToHide, optionInfo, tmpSelectedOptions, setOptionInfo);

  return page;
}
