import { createContext, useContext, useState } from "react";

export type StepContextType = {
  step: number;
  setStep: (step: number) => void;
};

export const StepContext = createContext({
  step: 0,
  setStep: (step: number) => {},
} as StepContextType);

export function StepContextProvider({ children }: { children: React.ReactNode }) {
  const [step, setStep] = useState(0);

  return <StepContext.Provider value={{ step, setStep }}>{children}</StepContext.Provider>;
}

export function useStepContext() {
  return useContext(StepContext);
}
