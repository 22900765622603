import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { client } from "./config";
import { AdviceContextProvider } from "./context/AdviceContext";
import { CustomerContextProvider } from "./context/CustomerContext";
import { HelpContextProvider } from "./context/HelpContext";
import { OnboardingContextProvider } from "./context/OnboardingContext";
import { OptionProvider } from "./context/OptionsContext";
import { SegmentProvider } from "./context/SegmentContext";
import { SessionContextProvider } from "./context/SessionContext";
import { StepContextProvider } from "./context/StepContext";
import { UserProvider } from "./context/UserContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { VersionContextProvider } from "./context/VersionContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <VersionContextProvider>
          <SegmentProvider>
            <SessionContextProvider>
              <HelpContextProvider>
                <AdviceContextProvider>
                  <UserProvider>
                    <OptionProvider>
                      <CustomerContextProvider>
                        <OnboardingContextProvider>
                          <StepContextProvider>
                            <App />
                          </StepContextProvider>
                        </OnboardingContextProvider>
                      </CustomerContextProvider>
                    </OptionProvider>
                  </UserProvider>
                </AdviceContextProvider>
              </HelpContextProvider>
            </SessionContextProvider>
          </SegmentProvider>
        </VersionContextProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);
reportWebVitals();
