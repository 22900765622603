import { Box, Flex, Text } from "@chakra-ui/react";
import { FC, ReactElement } from "react";
import { PersonnelConfig } from "../personnelConfig";

import CustomTooltip from "../../../components/CustomTooltip";
import { MinusCircleIcon } from "../../../components/Icons/MinusCircleIcon";
import { PlusCircleIcon } from "../../../components/Icons/PlusCircleIcon";
import PicturesVariant from "../../../components/PicturesVariant/PicturesVariant";
import {
  setAdviceOnHover,
  useAdviceContext,
} from "../../../context/AdviceContext";
import {
  PERSONNEL_TYPE_LABELS,
  useUserContext,
} from "../../../context/UserContext";
import { colors } from "../../../ui/theme";
import { useSegment } from "../../../context/SegmentContext";
interface CardProps {
  isSelected: boolean;
  answer: PersonnelConfig;
  textWithField?: boolean;
  // isDisabled?: boolean;
  isRestricted?: boolean;
}

const SingleSelectCardWrapper: FC<CardProps> = (props): ReactElement => {
  return (
    <CustomTooltip
      content={
        // props.isDisabled
        //   ? [
        //       "Le nombre adéquat de poste sera calculé en fonction de votre besoin.",
        //     ]
        //   :
        props.isRestricted
          ? [
              "Un manager pourra être ajouté automatiquement à votre équipe en fonction de votre séléction",
            ]
          : []
      }
    >
      <Flex
        color="gray.800"
        bgColor="white"
        borderWidth="1px"
        borderColor="transparent"
        justifyContent="center"
        cursor="pointer"
        borderRadius="3xl"
        sx={{
          width: "180px",
        }}
      >
        <Flex
          backgroundColor="transparent"
          width="100%"
          height="100%"
          justifyContent="center"
          _hover={{
            "& .newHover": {
              top: 0,
            },
          }}
          overflow="hidden"
          position="relative"
          sx={{
            width: "180px",
            svg: {
              height: "80px",
            },
          }}
        >
          <SingleCard {...props} />
        </Flex>
      </Flex>
    </CustomTooltip>
  );
};

const SingleCard: FC<CardProps> = ({
  answer,
  isSelected,
  textWithField = false,
  // isDisabled = false,
  isRestricted = false,
}) => {
  const { userInfos, setUserInfos, decrementWorker, incrementWorker } =
    useUserContext();
  const { setAdvice } = useAdviceContext();
  const selectDePersonnel = userInfos?.selectDePersonnelInfo?.persons;
  const count = selectDePersonnel?.[answer.type]
    ? selectDePersonnel?.[answer.type]?.count ?? 0
    : 0;

  const { track } = useSegment();
  const handleDecrement = () => {
    track("Decrement personal", {
      type: PERSONNEL_TYPE_LABELS[answer.type],
      countBeforeAction: count,
    });

    decrementWorker(answer.type);
  };

  const handleIncrement = () => {
    track("Increment personal", {
      type: PERSONNEL_TYPE_LABELS[answer.type],
      countBeforeAction: count,
    });

    incrementWorker(answer.type);
  };

  const handleChange = (e: any) => {
    if (answer.type) {
      if (e.target.value.length > 0)
        setUserInfos({
          ...userInfos,
          selectDePersonnelInfo: {
            ...userInfos?.selectDePersonnelInfo,
            persons: {
              ...selectDePersonnel,
              [answer.type]: {
                title: e.target.value,
                count: 1,
              },
            },
          },
        });
      else
        setUserInfos({
          ...userInfos,
          selectDePersonnelInfo: {
            ...userInfos?.selectDePersonnelInfo,
            persons: {
              ...selectDePersonnel,
              [answer.type]: {
                count: 0,
              },
            },
          },
        });
    }
  };

  return (
    <Flex
      {...(answer.tooltipText &&
        setAdviceOnHover(answer.tooltipText, setAdvice))}
      flexDirection="column"
      position="relative"
      borderRadius="12px"
      alignItems="center"
      border={
        isSelected
          ? // && !isDisabled
            `1px solid ${colors.secondaryFontColor.lightMode}`
          : "1px solid transparent"
      }
      padding={{ md: "8px", "2xl": "17px 12px 12px" }}
      gap={{ md: "4px", "2xl": "14px" }}
      width="100%"
      _hover={{
        // backgroundColor: isDisabled ? "#DDD" : colors.blue.transparent,
        backgroundColor: colors.blue.transparent,
        // color: isDisabled ? "" : colors.white,
        color: colors.white,
        svg: {
          // fill: isDisabled ? "" : colors.white,
          fill: colors.white,
        },
        p: {
          // color: isDisabled ? "" : colors.white,
          color: colors.white,
        },
      }}
      sx={
        count <= 0
          ? {
              svg: {
                fill: colors.black,
              },
              p: {
                color: colors.black,
              },
              ".helpIconCardItem": {
                position: "absolute",
                top: "3px",
                right: "-2px",
                width: "75px",
                borderTopRightRadius: "30px",
              },
            }
          : {
              svg: {
                fill: colors.white,
              },
              backgroundColor: colors.blue.lightMode,
              color: colors.white,
              p: {
                color: colors.white,
              },
              ".helpIconCardItem": {
                position: "absolute",
                top: "3px",
                right: "-2px",
                width: "75px",
                borderTopRightRadius: "30px",
              },
            }
      }
      // opacity={isDisabled ? "0.5" : ""}
      opacity={""}
      // backgroundColor={isDisabled ? "#DDD" : ""}
      backgroundColor={""}
      // cursor={isDisabled ? "not-allowed" : ""}
      cursor={""}
    >
      <Flex width="fit-content" position="absolute" top="0" right="0"></Flex>
      <Flex direction="row">
        <PicturesVariant icon={answer.icon} />
        {answer.detail && (
          <CustomTooltip content={[answer.detail]}>
            <Text textColor={count > 0 ? "white" : "black"}>?</Text>
          </CustomTooltip>
        )}
      </Flex>
      <Flex alignItems="center" gap="7px" maxHeight="30px">
        {textWithField ? (
          <input
            onChange={handleChange}
            style={{
              backgroundColor: "#fff",
              width: "60%",
              margin: "0 auto",
              display: "block",
              border: "1px solid rgba(0, 0, 0, 0.3)",
              borderRadius: "6px",
              color: "#000",
              paddingLeft: "10px",
              outline: "none",
            }}
          />
        ) : (
          <>
            <Flex
              // cursor={isDisabled ? "not-allowed" : "pointer"}
              cursor={isRestricted ? "not-allowed" : "pointer"}
              alignItems="center"
              justifyContent="center"
              width={{ md: "22px", "2xl": "35px" }}
              // onClick={isDisabled ? () => {} : handleDecrement}
              onClick={isRestricted ? () => {} : handleDecrement}
            >
              {isRestricted ? (
                <CustomTooltip
                  content={[
                    "Impossible de diminuer le nombre de " +
                      PERSONNEL_TYPE_LABELS[answer.type] +
                      " compte tenu de votre commande.",
                  ]}
                >
                  <Box>
                    {/* Need to be put in a box to avoir a console error */}
                    <MinusCircleIcon />
                  </Box>
                </CustomTooltip>
              ) : (
                <MinusCircleIcon />
              )}
            </Flex>
            <Text
              variant="lg"
              color={colors.white}
              display="block"
              width="22px"
            >
              {count}
            </Text>
            <Flex
              // cursor={isDisabled ? "not-allowed" : "pointer"}
              cursor={"pointer"}
              alignItems="center"
              justifyContent="center"
              width={{ md: "22px", "2xl": "35px" }}
              // onClick={isDisabled ? () => {} : handleIncrement}
              onClick={handleIncrement}
            >
              <PlusCircleIcon />
            </Flex>
          </>
        )}
      </Flex>
      <Text variant="xl" textAlign="center" color={colors.black}>
        {PERSONNEL_TYPE_LABELS[answer.type]}
      </Text>
    </Flex>
  );
};

export default SingleSelectCardWrapper;
