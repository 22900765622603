export const RoleHotessTrilingueIcon = () => (
  <svg
    width="180"
    height="120"
    viewBox="0 0 180 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1494_1279)">
      <path
        d="M134.649 50.4473L136.482 51.03C136.505 50.9303 136.532 50.8306 136.562 50.731C136.8 49.9662 136.907 51.3405 137.524 50.8306C137.48 51.49 138.429 44.7126 138.521 45.3643C138.559 45.6384 138.228 50.179 138.291 50.4473L152.52 50.5834C152.44 49.213 153.17 49.7401 153.816 48.5306C154.198 49.6672 154.257 48.8431 154.861 49.8723C155.114 50.3074 155.384 50.7252 155.675 51.1335C155.815 51.3347 155.963 51.5321 156.114 51.7276L156.308 51.789L157.266 33.0056C157.266 26.8129 151.899 23.8056 147.683 27.8306C145.442 29.9692 139.962 27.1694 138.699 29.1972C137.438 31.2269 136.949 34.0291 136.949 37.1265L134.649 50.4473Z"
        fill="#1A202C"
      />
      <path
        d="M151.708 45.3765L142.317 46.3348L136.375 80.4515L154.392 83.3265L151.708 45.3765Z"
        fill="#F7B80B"
      />
      <path
        d="M133.692 51.7014L134.65 73.9347L126.217 88.6931L132.35 94.2514C132.35 94.2514 143.658 71.8264 144.042 65.6931C144.425 59.5597 142.22 46.8881 142.22 46.8881L133.692 51.7014Z"
        fill="#1A202C"
      />
      <path
        d="M160.525 51.7014L159.567 73.9347L168 88.6931L154.967 94.6348C154.967 94.6348 150.558 71.8264 150.175 65.6931C149.792 59.5597 151.997 46.8881 151.997 46.8881L160.525 51.7014Z"
        fill="#1A202C"
      />
      <path
        d="M137.525 78.3431L133.117 78.9181L130.625 105.56L134.075 145.897H139.719L143.85 100.96L151.133 117.827L145.804 145.897L151.133 146.441L161.867 117.252L154.392 83.3265L137.525 78.3431Z"
        fill="#1A202C"
      />
      <path
        d="M155.617 102.395C155.825 101.66 156.183 101.034 156.608 100.587L158.152 93.4658L161.519 94.3L159.59 101.434C159.717 102.038 159.691 102.758 159.483 103.492C159.007 105.17 157.755 106.284 156.687 105.981C155.62 105.678 155.14 104.072 155.617 102.395V102.395Z"
        fill="#FFB6B6"
      />
      <path
        d="M156.083 50.2614C156.083 50.2614 162.299 49.9447 162.752 54.0672C163.205 58.1897 164.653 81.6685 164.653 81.6685L161.296 97.5163L156.509 96.8511L158.624 76.6787L156.083 50.2614H156.083Z"
        fill="#1A202C"
      />
      <path
        d="M102.801 62.5752C103.508 62.8688 104.091 63.2979 104.487 63.7739L111.404 66.1451L110.179 69.4036L103.294 66.6474C102.678 66.7034 101.963 66.5939 101.255 66.3003C99.6386 65.6296 98.6742 64.2519 99.1011 63.2233C99.5279 62.1946 101.184 61.9045 102.801 62.5752H102.801H102.801Z"
        fill="#FFB6B6"
      />
      <path
        d="M142.3 52.6869L142.3 52.6869L126.519 73.9879L108.021 68.8943L108.826 64.0687L122.911 66.7365L130.769 54.6558C133.335 49.6958 138.393 48.6969 142.3 52.6869V52.6869Z"
        fill="#1A202C"
      />
      <path
        d="M146.321 43.4358C150.143 43.4358 153.242 40.3371 153.242 36.5146C153.242 32.6922 150.143 29.5935 146.321 29.5935C142.498 29.5935 139.399 32.6922 139.399 36.5146C139.399 40.3371 142.498 43.4358 146.321 43.4358Z"
        fill="#FFB7B7"
      />
      <path
        d="M134.623 36.264H136.954L137.588 35.4105C137.895 35.4105 138.122 36.2311 138.417 36.1684C139.315 35.9774 140.139 35.51 140.815 34.8585C140.872 34.8034 140.931 34.7505 140.992 34.7L140.991 34.7157C140.937 35.5595 141.633 36.264 142.478 36.264H146.975C148.202 36.264 149.098 37.4257 148.785 38.6128L149.189 53.1307C149.189 50.1675 162.299 35.0373 153.406 28.9806C151.347 27.5784 149.853 27.8306 146.889 27.8306C142.335 25.4016 136.605 27.6684 135.125 32.6134C134.794 33.7211 134.623 34.9458 134.623 36.264Z"
        fill="#1A202C"
      />
      <path
        d="M121.687 24.0604L107.399 56.4378C107.399 56.4378 93.5427 59.8263 81.2726 32.6818C81.2726 32.6818 73.0427 17.2968 61.0001 19.1155L81.6706 0.359899C81.6706 0.359899 92.3396 -3.53846 103.682 14.325C103.683 14.325 109.217 25.9076 121.687 24.0604Z"
        fill="#5D76B7"
      />
      <path
        d="M103.549 19.771L102.844 21.7359L104.201 23.0827L102.619 22.8884L102.028 24.6862L101.56 22.6454L100.087 22.527L101.392 21.4686L101.09 19.5432L102.244 20.8309L103.549 19.771Z"
        fill="#FFD037"
      />
      <path
        d="M100.526 14.764L99.8639 16.6537L101.116 18.1565L99.6267 17.8269L98.8285 19.5433L98.6298 17.4121L97.0013 17.1567L98.4134 16.2683L98.1121 14.4003L99.1138 15.6524L100.526 14.764Z"
        fill="#FFD037"
      />
      <path
        d="M95.4869 11.0697L94.809 12.6649L95.9722 14.311L94.583 13.8336L93.9051 15.4289L93.4547 13.4548L91.8578 13.1623L93.344 12.2984L92.8375 10.2859L94.078 11.8196L95.4869 11.0697Z"
        fill="#FFD037"
      />
      <path
        d="M90.9993 10.875L90.2012 12.1992L91.8579 13.3661L89.9625 13.044L89.2303 14.4002L88.6353 12.7296L86.7149 12.5341L88.2808 12.0036L87.7321 10.2858L89.3425 11.4987L90.9993 10.875Z"
        fill="#FFD037"
      />
      <path
        d="M86.8852 13.9609L86.0886 15.2851L87.7441 16.4521L85.848 16.13L85.1156 17.4861L84.5222 15.8155L82.6011 15.62L84.1676 15.0896L83.6169 13.3717L85.2297 14.5847L86.8852 13.9609Z"
        fill="#FFD037"
      />
      <path
        d="M84.8271 18.2229L84.0305 19.8781L85.686 21.3368L83.7917 20.9342L83.0575 22.6293L82.4641 20.5411L80.543 20.2967L82.1095 19.6336L81.5588 17.4863L83.1716 19.0025L84.8271 18.2229Z"
        fill="#FFD037"
      />
      <path
        d="M85.134 25.6001L84.4243 27.1761L85.6855 29.1173L84.1578 28.4451L83.5036 29.8293L83.1873 27.8525L81.5711 27.196L82.9991 26.5749L82.6999 24.6863L83.6732 26.0463L85.134 25.6001Z"
        fill="#FFD037"
      />
      <path
        d="M88.1715 31.9755L87.5685 33.8903L88.7715 35.9767L87.1654 35.2537L86.4895 37.0295L86.167 34.6197L84.6571 33.879L86.0198 33.2078L85.6586 30.8579L86.7918 32.5464L88.1715 31.9755Z"
        fill="#FFD037"
      />
      <path
        d="M92.2525 35.816L91.7776 37.908L92.8857 39.7585L91.7882 39.4109L91.3015 41.1439L90.9376 38.9507L89.7999 38.3029L90.6954 37.4021L90.4497 34.9723L91.2838 36.7347L92.2525 35.816Z"
        fill="#FFD037"
      />
      <path
        d="M97.1108 36.3069L96.5642 38.3176L98.0293 39.6099L96.5448 39.5453L96.1147 41.1437L95.5412 39.2438L93.9149 38.913L95.1052 37.8576L94.8244 36.0007L95.8862 37.173L97.1108 36.3069Z"
        fill="#FFD037"
      />
      <path
        d="M101.839 33.3345L101.227 35.0646L103.172 36.2184L101.124 36.2739L100.73 38.0582L99.9552 36.1032L98.0294 36.006L99.4777 35.0729L98.866 32.9152L100.588 34.237L101.839 33.3345Z"
        fill="#FFD037"
      />
      <path
        d="M103.134 27.1629L102.645 28.8929L104.201 30.0468L102.562 30.1009L102.248 31.8865L101.627 29.9301L100.087 29.8343L101.247 28.9013L100.756 26.7435L102.134 28.064L103.134 27.1629Z"
        fill="#FFD037"
      />
      <path
        d="M121.687 22.4509L92.9998 88.4595L90.829 87.7566L119.536 21.6005L121.687 22.4509Z"
        fill="#F0F5FA"
      />
      <path
        d="M32.6522 73.0627C33.2127 72.3979 34.0077 71.9746 34.8721 71.8806C34.995 71.8673 35.1186 71.8606 35.2422 71.8605L99.0369 71.8009C99.4987 71.7993 99.9559 71.8924 100.38 72.0745C100.805 72.2566 101.187 72.5238 101.504 72.8597C102.115 73.5235 102.449 74.3958 102.437 75.2978L102.447 85.6609C102.452 87.5386 102.045 89.3945 101.255 91.0978C101.696 92.7693 102.432 94.3481 103.43 95.7596C103.741 96.2068 103.919 96.7326 103.944 97.2764C103.969 97.8202 103.84 98.3601 103.572 98.8339C103.304 99.3078 102.908 99.6964 102.429 99.9551C101.95 100.214 101.407 100.332 100.864 100.296C100.192 100.255 99.5218 100.179 98.8572 100.068C96.9231 99.7653 95.0776 99.0465 93.4481 97.9613C92.1946 98.3558 90.8883 98.5568 89.5742 98.5575L44.7159 98.5994C43.0241 98.6056 41.348 98.2753 39.785 97.6278C38.2219 96.9803 36.8033 96.0285 35.6115 94.8277C33.1831 92.3489 31.8339 89.0102 31.8583 85.5403L31.8488 75.3681C31.8347 74.5286 32.1194 73.7115 32.6522 73.0627Z"
        fill="#F7B80B"
      />
      <path
        d="M49.3202 81.2051L84.9759 81.1718C85.0778 81.1717 85.1786 81.1516 85.2727 81.1125C85.3668 81.0734 85.4523 81.0162 85.5243 80.9441C85.5962 80.872 85.6533 80.7864 85.6922 80.6922C85.7311 80.5981 85.7511 80.4972 85.751 80.3953C85.7509 80.2934 85.7307 80.1926 85.6916 80.0985C85.6526 80.0044 85.5953 79.9189 85.5232 79.847C85.4511 79.775 85.3656 79.7179 85.2714 79.679C85.1772 79.6401 85.0763 79.6201 84.9745 79.6202L49.3188 79.6535C49.113 79.6537 48.9158 79.7356 48.7704 79.8813C48.6251 80.0269 48.5435 80.2243 48.5437 80.43C48.5439 80.6358 48.6258 80.833 48.7714 80.9784C48.9171 81.1237 49.1145 81.2053 49.3202 81.2051Z"
        fill="white"
      />
      <path
        d="M49.3246 85.9668L84.9803 85.9335C85.1861 85.9333 85.3833 85.8514 85.5287 85.7058C85.674 85.5602 85.7556 85.3628 85.7554 85.157C85.7552 84.9513 85.6733 84.754 85.5276 84.6087C85.382 84.4633 85.1846 84.3818 84.9789 84.382L49.3232 84.4152C49.1174 84.4154 48.9202 84.4974 48.7748 84.643C48.6295 84.7886 48.5479 84.986 48.5481 85.1918C48.5483 85.3975 48.6302 85.5948 48.7758 85.7401C48.9215 85.8855 49.1189 85.967 49.3246 85.9668Z"
        fill="white"
      />
      <path
        d="M80.83 90.6993L84.9852 90.6954C85.0871 90.6953 85.1879 90.6751 85.282 90.6361C85.3761 90.597 85.4616 90.5397 85.5335 90.4676C85.6055 90.3955 85.6626 90.31 85.7015 90.2158C85.7404 90.1216 85.7603 90.0207 85.7603 89.9189C85.7602 89.817 85.74 89.7161 85.7009 89.622C85.6618 89.528 85.6046 89.4425 85.5325 89.3705C85.4604 89.2985 85.3748 89.2415 85.2807 89.2026C85.1865 89.1637 85.0856 89.1437 84.9837 89.1438L80.8286 89.1477C80.6231 89.1482 80.4261 89.2303 80.2811 89.3759C80.136 89.5214 80.0546 89.7187 80.0548 89.9242C80.055 90.1297 80.1367 90.3268 80.2821 90.4721C80.4274 90.6174 80.6245 90.6991 80.83 90.6993Z"
        fill="white"
      />
      <path
        d="M32.5136 55.7219C32.9776 55.1716 33.6358 54.8212 34.3514 54.7434C34.4531 54.7323 34.5554 54.7268 34.6577 54.7267L87.4673 54.6774C87.8496 54.676 88.2281 54.7531 88.5794 54.9039C88.9307 55.0546 89.2473 55.2758 89.5098 55.5538C90.0154 56.1033 90.2916 56.8255 90.2819 57.5722L90.2899 66.1508C90.294 67.7051 89.9572 69.2414 89.3032 70.6515C89.6682 72.0351 90.2781 73.3421 91.1041 74.5105C91.3611 74.8807 91.5085 75.3159 91.5292 75.7661C91.5499 76.2163 91.4432 76.6632 91.2214 77.0555C90.9995 77.4477 90.6714 77.7694 90.2749 77.9836C89.8784 78.1977 89.4295 78.2957 88.9798 78.2661C88.4232 78.2318 87.8687 78.1687 87.3186 78.0772C85.7175 77.8264 84.1898 77.2314 82.8409 76.3331C81.8032 76.6597 80.7219 76.8261 79.634 76.8267L42.5001 76.8613C41.0996 76.8664 39.7121 76.593 38.4182 76.057C37.1243 75.521 35.95 74.7331 34.9634 73.7391C32.9532 71.6871 31.8363 68.9233 31.8565 66.0509L31.8486 57.6303C31.8369 56.9354 32.0726 56.259 32.5136 55.7219Z"
        fill="#2F2E41"
      />
      <path
        d="M46.3116 62.4622L75.8276 62.4347C75.912 62.4346 75.9955 62.4179 76.0733 62.3856C76.1512 62.3532 76.222 62.3058 76.2816 62.2462C76.3411 62.1865 76.3884 62.1156 76.4206 62.0377C76.4528 61.9597 76.4693 61.8762 76.4692 61.7919C76.4692 61.7075 76.4525 61.624 76.4201 61.5462C76.3878 61.4683 76.3404 61.3975 76.2807 61.3379C76.221 61.2784 76.1502 61.2311 76.0722 61.1989C75.9943 61.1667 75.9108 61.1502 75.8264 61.1503L46.3104 61.1778C46.1401 61.178 45.9768 61.2458 45.8565 61.3663C45.7362 61.4869 45.6687 61.6503 45.6688 61.8206C45.669 61.991 45.7368 62.1542 45.8574 62.2746C45.9779 62.3949 46.1413 62.4624 46.3116 62.4622Z"
        fill="white"
      />
      <path
        d="M46.3151 66.404L75.8311 66.3764C76.0014 66.3763 76.1647 66.3085 76.285 66.1879C76.4053 66.0674 76.4728 65.904 76.4727 65.7336C76.4725 65.5633 76.4047 65.4 76.2841 65.2797C76.1636 65.1594 76.0002 65.0919 75.8299 65.092L46.3139 65.1196C46.1436 65.1198 45.9803 65.1876 45.8599 65.3081C45.7396 65.4287 45.6721 65.5921 45.6723 65.7624C45.6724 65.9327 45.7402 66.096 45.8608 66.2163C45.9813 66.3367 46.1447 66.4042 46.3151 66.404Z"
        fill="white"
      />
      <path
        d="M72.3953 70.3214L75.835 70.3182C75.9193 70.3181 76.0028 70.3015 76.0807 70.2691C76.1586 70.2368 76.2293 70.1894 76.2889 70.1297C76.3485 70.07 76.3957 69.9992 76.4279 69.9212C76.4601 69.8433 76.4766 69.7598 76.4766 69.6754C76.4765 69.5911 76.4598 69.5076 76.4274 69.4297C76.3951 69.3518 76.3477 69.2811 76.2881 69.2215C76.2284 69.1619 76.1575 69.1147 76.0796 69.0825C76.0016 69.0503 75.9181 69.0337 75.8338 69.0338L72.3941 69.037C72.224 69.0375 72.061 69.1054 71.9409 69.2259C71.8208 69.3464 71.7534 69.5097 71.7536 69.6798C71.7537 69.85 71.8214 70.0131 71.9417 70.1334C72.062 70.2537 72.2252 70.3213 72.3953 70.3214Z"
        fill="white"
      />
      <path
        d="M32.3791 40.8337C32.7495 40.3945 33.2747 40.1148 33.8459 40.0527C33.9271 40.0439 34.0087 40.0394 34.0904 40.0394L76.2404 40C76.5455 39.9989 76.8476 40.0605 77.128 40.1808C77.4084 40.3011 77.6611 40.4777 77.8705 40.6995C78.2741 41.1381 78.4946 41.7145 78.4868 42.3105L78.4932 49.1575C78.4965 50.3981 78.2277 51.6243 77.7057 52.7497C77.997 53.854 78.4838 54.8972 79.1431 55.8298C79.3482 56.1253 79.4658 56.4727 79.4823 56.832C79.4989 57.1913 79.4137 57.548 79.2367 57.861C79.0596 58.1741 78.7977 58.4309 78.4813 58.6018C78.1648 58.7728 77.8065 58.8509 77.4476 58.8274C77.0033 58.7999 76.5607 58.7496 76.1216 58.6765C74.8437 58.4764 73.6244 58.0015 72.5478 57.2845C71.7196 57.5451 70.8565 57.678 69.9882 57.6784L40.3498 57.7061C39.232 57.7102 38.1246 57.492 37.0919 57.0642C36.0592 56.6364 35.1218 56.0075 34.3344 55.2141C32.73 53.5763 31.8385 51.3704 31.8546 49.0778L31.8483 42.3569C31.839 41.8023 32.0271 41.2624 32.3791 40.8337Z"
        fill="#F7B80B"
      />
      <path
        d="M43.3921 46.2134L66.9503 46.1914C67.0176 46.1913 67.0843 46.178 67.1464 46.1522C67.2086 46.1264 67.2651 46.0886 67.3126 46.0409C67.3602 45.9933 67.3979 45.9368 67.4236 45.8745C67.4493 45.8123 67.4625 45.7457 67.4624 45.6784C67.4623 45.611 67.449 45.5444 67.4232 45.4822C67.3974 45.4201 67.3596 45.3636 67.3119 45.3161C67.2643 45.2685 67.2078 45.2308 67.1455 45.2051C67.0833 45.1794 67.0167 45.1662 66.9494 45.1663L43.3912 45.1883C43.2552 45.1884 43.1249 45.2425 43.0289 45.3387C42.9328 45.4349 42.879 45.5654 42.8791 45.7013C42.8792 45.8372 42.9333 45.9676 43.0296 46.0636C43.1258 46.1596 43.2562 46.2135 43.3921 46.2134Z"
        fill="white"
      />
      <path
        d="M43.3951 49.3596L66.9532 49.3376C67.0892 49.3375 67.2195 49.2834 67.3155 49.1872C67.4116 49.091 67.4655 48.9605 67.4653 48.8246C67.4652 48.6887 67.4111 48.5583 67.3149 48.4623C67.2187 48.3663 67.0882 48.3124 66.9523 48.3125L43.3941 48.3345C43.2582 48.3346 43.1279 48.3887 43.0318 48.485C42.9358 48.5812 42.8819 48.7116 42.882 48.8475C42.8822 48.9835 42.9363 49.1138 43.0325 49.2099C43.1287 49.3059 43.2591 49.3598 43.3951 49.3596Z"
        fill="white"
      />
      <path
        d="M64.2103 52.4863L66.9557 52.4838C67.023 52.4837 67.0896 52.4704 67.1518 52.4446C67.214 52.4188 67.2704 52.3809 67.318 52.3333C67.3655 52.2857 67.4032 52.2291 67.4289 52.1669C67.4546 52.1047 67.4678 52.038 67.4678 51.9707C67.4677 51.9034 67.4544 51.8368 67.4286 51.7746C67.4028 51.7124 67.365 51.656 67.3173 51.6084C67.2697 51.5609 67.2131 51.5232 67.1509 51.4975C67.0887 51.4718 67.022 51.4586 66.9547 51.4586L64.2094 51.4612C64.0736 51.4615 63.9435 51.5158 63.8476 51.6119C63.7518 51.7081 63.698 51.8384 63.6981 51.9742C63.6982 52.11 63.7522 52.2402 63.8483 52.3362C63.9443 52.4323 64.0745 52.4862 64.2103 52.4863Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1494_1279">
        <rect
          width="180"
          height="120"
          fill="white"
          transform="matrix(-1 0 0 1 180 0)"
        />
      </clipPath>
    </defs>
  </svg>
);
