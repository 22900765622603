import { createContext, useContext, useEffect, useState } from "react";

export type AdviceContextType = {
  advice: string | null;
  setAdvice: (advice: string | null) => void;
};

export const AdviceContext = createContext({
  advice: null,
  setAdvice: (advice: string | null) => {},
} as AdviceContextType);

export function AdviceContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [advice, setAdvice] = useState<string | null>(null);

  return (
    <AdviceContext.Provider value={{ advice, setAdvice }}>
      {children}
    </AdviceContext.Provider>
  );
}

export function useAdviceContext() {
  const context = useContext(AdviceContext);
  useEffect(() => {
    // remove advice on unmount
    return () => context.setAdvice(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return context;
}

export function setAdviceOnHover(
  advice: string,
  setAdvice: (advice: string | null) => void
): {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
} {
  return {
    onMouseEnter: () => {
      setAdvice(advice);
    },
    onMouseLeave: () => {
      setAdvice(null);
    },
  };
}
