export const DressingCheveuxIcon = () => (
  <svg
    width="66"
    height="78"
    viewBox="0 0 66 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.7387 31.8003C35.1243 31.8003 38.6796 28.1505 38.6796 23.6482C38.6796 19.1459 35.1243 15.4961 30.7387 15.4961C26.3531 15.4961 22.7979 19.1459 22.7979 23.6482C22.7979 28.1505 26.3531 31.8003 30.7387 31.8003Z"
      fill="#2F2E41"
    />
    <path
      d="M43.444 62.7767C54.5542 62.7767 63.5609 53.5305 63.5609 42.1247C63.5609 30.7189 54.5542 21.4727 43.444 21.4727C32.3338 21.4727 23.3271 30.7189 23.3271 42.1247C23.3271 53.5305 32.3338 62.7767 43.444 62.7767Z"
      fill="#2F2E41"
    />
    <path
      d="M45.1089 62.7712C54.1373 62.7712 61.4562 55.2576 61.4562 45.9891C61.4562 36.7206 54.1373 29.207 45.1089 29.207C36.0806 29.207 28.7617 36.7206 28.7617 45.9891C28.7617 55.2576 36.0806 62.7712 45.1089 62.7712Z"
      fill="#FFB7B7"
    />
    <path
      d="M56.2106 28.8819C53.3461 27.0891 50.0649 26.1193 46.7104 26.0739C43.3558 26.0285 40.0509 26.9092 37.1413 28.6238C34.2317 30.3385 31.8243 32.8241 30.1707 35.8208C28.5172 38.8176 27.6782 42.2153 27.7416 45.6588C36.0714 47.8058 44.8223 49.4489 53.7054 46.1724L55.8032 40.9016L57.04 46.1763C59.7452 47.3114 62.4574 48.2719 65.1696 46.1653C65.2803 42.7315 64.5111 39.328 62.939 36.295C61.3668 33.2621 59.0467 30.7058 56.2106 28.8819Z"
      fill="#2F2E41"
    />
    <path
      d="M13.9725 77.0297H13.9509C12.8741 77.0404 11.8194 76.716 10.9257 76.0992C10.0319 75.4824 9.34118 74.6022 8.94435 73.5744C8.51759 72.5336 8.40793 71.3845 8.62981 70.2784C8.85168 69.1723 9.39469 68.1611 10.1873 67.378L10.2791 67.2845C13.3731 64.0857 15.1163 59.7651 15.1317 55.2572C15.1356 48.8912 11.6643 43.0077 8.41037 37.9926L7.81057 37.0703C4.56763 32.09 1.21433 26.9402 0.360757 21.0079C-0.545667 14.7075 1.77822 8.05747 6.28107 4.06592C10.9548 -0.0769572 18.0009 -1.19041 23.4162 1.35826C29.0289 4.00022 32.6395 10.5819 31.8145 16.6676L31.806 16.7307L29.8336 18.5522L29.761 18.5466C25.0906 18.177 20.4385 21.3361 18.9353 25.8881C17.9231 28.9524 18.2871 32.3101 18.8309 35.4822C19.9839 42.2003 21.9224 48.751 24.6043 54.9924C24.7404 55.3094 24.8783 55.6276 25.018 55.9471C26.5812 59.5406 28.1978 63.2563 27.5785 67.105C26.6002 73.1875 20.3115 76.7469 14.8911 77.0076C14.5842 77.0226 14.2779 77.03 13.9725 77.0297Z"
      fill="#2F2E41"
    />
  </svg>
);
