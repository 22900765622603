import { Checkbox, Flex, Input, Select, Text } from "@chakra-ui/react";
import { FC, ReactElement, useEffect, useState } from "react";
import { OptionOutput } from "../../../apollo/calendar/mutations";
import CustomTooltip from "../../../components/CustomTooltip";
import DownloadIcon from "../../../components/Icons/DownloadIcon";
import { MinusCircleIcon } from "../../../components/Icons/MinusCircleIcon";
import { PlusCircleIcon } from "../../../components/Icons/PlusCircleIcon";
import PicturesVariant from "../../../components/PicturesVariant/PicturesVariant";
import { ROUND_NUMBERS } from "../../../context/OptionsContext";
import {
  setAdviceOnHover,
  useAdviceContext,
} from "../../../context/AdviceContext";
import { OptionDetails } from "../../../context/OptionsContext";
import { colors } from "../../../ui/theme";
import { BackendPrice } from "./OptionsBlock";

interface SingleOptionsCardProps {
  onIncrement: () => void;
  onDecrement: () => void;
  onEditValue: (value: number) => void;
  optionData: OptionDetails;
  price: BackendPrice;
  amount: number;
}

const OptionsSingleCard: FC<SingleOptionsCardProps> = ({
  onIncrement,
  onDecrement,
  onEditValue,
  optionData,
  price,
  amount,
}): ReactElement => {
  const backendData = price?.data?.data?.options;
  const backendLoading = price?.data?.loading;
  const { setAdvice } = useAdviceContext();
  const [value, setValue] = useState<number>(amount);
  const [backendPrice, setBackendPrice] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      let tmp = value;
      // Code to run after the timeout
      if (optionData.name === "Talky walky") tmp += tmp % 2;
      setValue(tmp);
      onEditValue(tmp);
    }, 1000);

    // Cleanup function to clear the timeout if the component unmounts or the effect re-runs
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionData.name, value]);

  const moovingValue = (action: "increment" | "decrement") => {
    if (optionData.name === "Talky walky") {
      return 2;
    }
    if (optionData.name.includes("Totbag")) {
      const roundValues = ROUND_NUMBERS;
      let index = roundValues.findIndex((val) => val === value);
      console.log(index, roundValues[index + 1]);
      if (action === "increment") {
        return roundValues[index + 1] - value;
      } else if (action === "decrement") {
        return value - roundValues[index - 1];
      }
    }
    return 1;
  };

  useEffect(() => {
    if (!backendData || backendLoading) {
      setBackendPrice(undefined);
      return;
    }
    let option: OptionOutput | undefined = undefined;
    if (amount !== 0)
      option = backendData.find(
        (option: OptionOutput) => option.count === amount
      );
    else option = backendData[0];
    if (backendData.length === 1) setBackendPrice(backendData[0].priceUnit);
    else if (option) setBackendPrice(option.priceUnit);
    else setBackendPrice(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, price]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") setValue(0);
    let value = parseInt(e.target.value);
    if (value > 10000) return;
    if (isNaN(value)) return;
    if (value < 0) value = 0;
    setValue(value);
    onEditValue(value);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = optionData.file!; // Assuming your PDF is in public/pdfs directory
    link.download = optionData.fileName ?? "Norela-Option-Presentation.pdf"; // Optional: set a filename for the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Flex
      flexDirection="column"
      position="relative"
      borderRadius="12px"
      alignItems="center"
      border={
        amount > 0
          ? `1px solid ${colors.secondaryFontColor.lightMode}`
          : "1px solid transparent"
      }
      padding={{ md: "8px", "2xl": "17px 12px 12px" }}
      width="100%"
      {...(optionData?.tooltipText &&
        setAdviceOnHover(optionData.tooltipText, setAdvice))}
      _hover={{
        backgroundColor: colors.blue.transparent,
        color: colors.white,
        svg: {
          fill: colors.white,
        },
        "> *": {
          fill: colors.white,
        },
        p: {
          color: colors.white,
          borderColor: colors.white,
        },
      }}
      sx={
        amount <= 0
          ? {}
          : {
              color: colors.white,
              backgroundColor: colors.blue.lightMode,
              p: {
                color: colors.white,
              },
            }
      }
    >
      <Flex direction="row">
        <Flex display="flex" position="relative" margin="1px" maxWidth="100%">
          <PicturesVariant icon={optionData?.icon} />
        </Flex>
        {optionData.description && (
          <CustomTooltip content={[optionData.description]}>
            <Text
              position="absolute"
              right={optionData.file ? "3.5rem" : "1rem"}
              top="14px"
              display="inline-block"
              justifySelf="flex-end"
              cursor="pointer"
              fontSize="20px"
              color="#666"
            >
              ?
            </Text>
          </CustomTooltip>
        )}
        {optionData.file && (
          <CustomTooltip content={["Télécharger le PDF"]}>
            <Text
              position="absolute"
              right="1rem"
              display="inline-block"
              ml={optionData.description ? "5px" : "0px"}
              justifySelf="flex-end"
              padding="4px"
              cursor="pointer"
              width="30px"
              height="30px"
              fontSize="12px"
              color="#666"
              onClick={handleDownload}
            >
              <DownloadIcon
                padding="1px"
                mt="-5px"
                width="20px"
                height="20px"
              />
            </Text>
          </CustomTooltip>
        )}
      </Flex>
      <Text mt="5px" variant="xl" fontWeight="700" textAlign="center">
        {optionData?.name}
      </Text>
      <Text variant="md" fontWeight="500" textAlign="center" marginBottom="1">
        {backendPrice
          ? price.before + backendPrice + price.after
          : "prix indisponible"}
      </Text>
      {!optionData?.name.includes("Formation") ? (
        <Flex
          alignItems="center"
          gap="10px"
          p="9px"
          maxHeight={{ md: "30px", "2xl": "100%" }}
        >
          <Flex
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            width={{ md: "22px", "2xl": "35px" }}
            onClick={() => {
              onDecrement();
              value - moovingValue("decrement") >= 0 &&
                value - moovingValue("decrement") <= 10000 &&
                setValue(value - moovingValue("decrement"));
            }}
            sx={{
              "& svg": {
                fill: colors.black,
              },
            }}
          >
            <MinusCircleIcon />
          </Flex>
          {optionData.name.includes("Totbag") ? (
            <Select
              marginTop="3px"
              variant="lg"
              rounded="md"
              color={colors.black}
              display="block"
              size="s"
              textAlign="center"
              minWidth="20px"
              width="80px"
              value={value}
              onChange={(e) => {
                setValue(parseInt(e.target.value));
              }}
            >
              {ROUND_NUMBERS.map((val) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </Select>
          ) : (
            <Input
              type="number"
              max="10000"
              variant="lg"
              color={colors.black}
              display="block"
              maxHeight="25px"
              textAlign="center"
              minWidth="20px"
              maxWidth="80px"
              value={value}
              onChange={handleChange}
            />
          )}
          <Flex
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            width={{ md: "22px", "2xl": "35px" }}
            onClick={() => {
              onIncrement();
              value + moovingValue("increment") >= 0 &&
                value + moovingValue("increment") <= 10000 &&
                setValue(value + moovingValue("increment"));
            }}
            sx={{
              "& svg": {
                fill: colors.black,
              },
            }}
          >
            <PlusCircleIcon />
          </Flex>
        </Flex>
      ) : (
        <Flex>
          <Checkbox
            marginTop={3}
            size="lg"
            colorScheme="blue"
            onChange={() => {
              onEditValue(value === 0 ? 1 : 0);
              value === 0 ? setValue(1) : setValue(0);
            }}
            isChecked={value === 1}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default OptionsSingleCard;
