export const OptionsFormationIcon = () => (
  <svg
    width="190"
    height="100"
    viewBox="0 0 190 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1454_52309)">
      <path
        d="M65.5163 22.9847L50.5645 20.9914L53.5902 5.66797L69.6521 8.83957L65.5163 22.9847Z"
        fill="#2F2E41"
      />
      <path
        d="M51.1426 89.2768V26.0619C51.1426 24.9164 52.0745 23.9844 53.2201 23.9844H155.766C156.911 23.9844 157.843 24.9164 157.843 26.0619V89.2768C157.843 90.4224 156.911 91.3544 155.766 91.3544H53.2201C52.0745 91.3544 51.1426 90.4224 51.1426 89.2768ZM157.467 26.0619C157.467 25.1224 156.705 24.3607 155.766 24.3607H53.2201C52.2806 24.3607 51.5189 25.1224 51.5189 26.0619V89.2768C51.5189 90.2164 52.2806 90.978 53.2201 90.978H126.109C143.427 90.978 157.467 76.9386 157.467 59.6201V26.0619Z"
        fill="#3F3D56"
      />
      <path
        d="M147.461 33.449L145.912 32.7153C146.547 33.6018 147.095 34.9864 147.388 36.0881C147.884 35.0618 148.684 33.8061 149.475 33.0561L147.839 33.4771C148.847 28.5339 152.641 24.9852 156.985 24.9852L157.047 24.8066C152.509 24.8066 148.491 28.3062 147.461 33.449V33.449Z"
        fill="#3F3D56"
      />
      <path
        d="M154.589 40.9801H144.804C144.388 40.9801 144.051 40.6424 144.051 40.2273C144.051 39.8123 144.388 39.4746 144.804 39.4746H154.589C155.004 39.4746 155.342 39.8123 155.342 40.2273C155.342 40.6424 155.004 40.9801 154.589 40.9801Z"
        fill="#F7B80B"
      />
      <path
        d="M125.535 46.6347V66.5822C125.535 68.3463 124.1 69.7814 122.336 69.7814H102.389C100.625 69.7814 99.1895 68.3463 99.1895 66.5822V46.6347C99.1895 44.8706 100.625 43.4355 102.389 43.4355H122.336C124.1 43.4355 125.535 44.8706 125.535 46.6347ZM121.099 69.405C123.341 69.405 125.159 67.5872 125.159 65.3448V56.5255C125.159 49.504 119.467 43.8119 112.445 43.8119H102.389C100.83 43.8119 99.5658 45.0757 99.5658 46.6347V63.7991C99.5658 66.8952 102.076 69.405 105.172 69.405H121.099Z"
        fill="#3F3D56"
      />
      <path
        d="M115.017 51.583H108.578C108.415 51.583 108.283 51.4509 108.283 51.2885C108.283 51.1262 108.415 50.9941 108.578 50.9941H115.017C115.18 50.9941 115.312 51.1262 115.312 51.2885C115.312 51.4509 115.18 51.583 115.017 51.583V51.583Z"
        fill="#3F3D56"
      />
      <path
        d="M115.017 57.8623H108.578C108.415 57.8623 108.283 57.7302 108.283 57.5678C108.283 57.4055 108.415 57.2734 108.578 57.2734H115.017C115.18 57.2734 115.312 57.4055 115.312 57.5678C115.312 57.7302 115.18 57.8623 115.017 57.8623V57.8623Z"
        fill="#3F3D56"
      />
      <path
        d="M111.818 66.5185H105.378C105.216 66.5185 105.084 66.3864 105.084 66.2241C105.084 66.0617 105.216 65.9297 105.378 65.9297H111.818C111.981 65.9297 112.113 66.0617 112.113 66.2241C112.113 66.3864 111.981 66.5185 111.818 66.5185V66.5185Z"
        fill="#3F3D56"
      />
      <path
        d="M118.474 54.7236H105.123C104.96 54.7236 104.828 54.5915 104.828 54.4292C104.828 54.2668 104.96 54.1348 105.123 54.1348H118.474C118.636 54.1348 118.768 54.2668 118.768 54.4292C118.768 54.5915 118.636 54.7236 118.474 54.7236V54.7236Z"
        fill="#3F3D56"
      />
      <path
        d="M66.7099 56.9478H44.6406C46.4904 52.8201 47.6277 48.8889 45.8499 45.762L66.7099 44.5527C65.6675 48.774 65.5401 52.9166 66.7099 56.9478Z"
        fill="#FFB6B6"
      />
      <path
        d="M74.3115 91.3544H58.087C57.9697 91.3544 57.8626 91.2873 57.8114 91.1818L57.7126 90.978L55.5565 86.5523C55.4177 86.2674 54.9905 86.3551 54.9753 86.6717L54.7675 90.978L54.7633 91.0634C54.7551 91.2264 54.6206 91.3544 54.4573 91.3544H38.4831C38.3216 91.3544 38.1878 91.2289 38.1772 91.0678C38.1753 91.0378 38.1733 91.0078 38.1715 90.978C37.1516 75.2364 40.8193 64.5663 42.9702 59.7921L42.9721 59.7902C43.8171 57.9121 44.4306 56.9468 44.4306 56.9468L44.6432 56.9449H44.6451L48.9413 56.8696H48.9432L52.7483 56.8056L55.6238 56.7586L56.7773 56.7379H56.7792L57.5018 56.7266L61.0943 56.6645L64.3028 56.6099L66.6062 56.5723H66.6702L67.0127 58.0495V58.0514L67.0259 58.1173V58.1191L67.0842 58.3619L67.0861 58.3694L67.2028 58.8794V58.8832L67.2103 58.9114V58.9133L67.2385 59.0356L67.2404 59.0412L67.5114 60.2136L67.9498 62.1199L68.5276 64.6134L69.2558 67.7786L69.6247 69.3726L70.1309 71.5687L74.6097 90.978C74.6549 91.1703 74.509 91.3544 74.3115 91.3544H74.3115Z"
        fill="#2F2E41"
      />
      <path
        d="M63.5242 20.1893L54.7415 19.8828L51.2698 24.5382L49.4431 24.9349C46.7048 25.5295 44.8264 28.055 45.0446 30.8487L45.8744 50.203L46.2507 52.4612L66.951 55.8485L71.6056 26.558L65.4809 24.3577L63.5242 20.1893Z"
        fill="#F7B80B"
      />
      <path
        d="M60.3191 19.4546C64.6731 19.4546 68.2027 15.925 68.2027 11.5711C68.2027 7.21709 64.6731 3.6875 60.3191 3.6875C55.9651 3.6875 52.4355 7.21709 52.4355 11.5711C52.4355 15.925 55.9651 19.4546 60.3191 19.4546Z"
        fill="#FFB6B6"
      />
      <path
        d="M148.112 90.6239L141.277 88.084C140.252 87.703 139.728 86.5591 140.109 85.5339L146.823 67.4664C147.204 66.4413 148.348 65.9172 149.373 66.2982L156.208 68.8381C157.233 69.2191 157.757 70.363 157.376 71.3881C154.807 78.6908 152.004 86.8963 150.662 89.4557C150.281 90.4808 149.137 91.0049 148.112 90.6239Z"
        fill="#3F3D56"
      />
      <path
        d="M155.693 76.2103C155.65 76.1942 155.601 76.2164 155.585 76.2598L154.65 78.7765C154.634 78.8199 154.656 78.8683 154.699 78.8844C154.743 78.9005 154.791 78.8784 154.807 78.835L155.742 76.3182C155.759 76.2748 155.736 76.2265 155.693 76.2103Z"
        fill="#3F3D56"
      />
      <path
        d="M157.2 71.3216L150.485 89.3889C150.154 90.2811 149.188 90.7529 148.289 90.4832L148.288 90.4831C148.275 90.479 148.262 90.475 148.248 90.4704C148.225 90.463 148.201 90.4547 148.178 90.446C148.178 90.446 148.093 90.4075 147.94 90.337C147.784 90.2659 147.559 90.1618 147.277 90.0309C147.193 89.9919 147.104 89.9504 147.01 89.9066C146.824 89.8192 146.618 89.7233 146.399 89.6193C146.311 89.5782 146.221 89.5352 146.128 89.4914C145.655 89.2678 145.127 89.0154 144.57 88.7451C144.476 88.6999 144.382 88.6539 144.286 88.6072C144.261 88.5948 140.208 85.8094 140.286 85.5986L147.001 67.5314C147.346 66.6021 148.379 66.1289 149.308 66.4743L150.313 66.8475C150.473 66.9071 150.555 67.0801 150.51 67.2453C150.507 67.2564 150.504 67.2674 150.502 67.2788C150.472 67.4504 150.577 67.6173 150.741 67.6781L154.271 68.99C154.435 69.0508 154.624 68.9936 154.713 68.8435C154.719 68.8336 154.724 68.8235 154.729 68.8133C154.802 68.6584 154.978 68.5812 155.138 68.6409L156.142 69.0141C157.072 69.3594 157.545 70.3924 157.2 71.3216V71.3216Z"
        fill="#F7B80B"
      />
      <path
        d="M145.707 70.351C145.664 70.3348 145.615 70.357 145.599 70.4004L145.365 71.0296C145.348 71.073 145.371 71.1213 145.414 71.1375C145.458 71.1536 145.506 71.1314 145.522 71.088L145.757 70.4588C145.773 70.4155 145.751 70.3671 145.707 70.351Z"
        fill="#3F3D56"
      />
      <path
        d="M145.006 72.2377C144.962 72.2216 144.914 72.2437 144.898 72.2871L144.445 73.5062C144.429 73.5495 144.451 73.5979 144.494 73.614C144.538 73.6302 144.586 73.608 144.602 73.5646L145.055 72.3456C145.071 72.3022 145.049 72.2538 145.006 72.2377Z"
        fill="#3F3D56"
      />
      <path
        d="M144.361 73.9682C144.318 73.952 144.269 73.9742 144.253 74.0176L143.8 75.2366C143.784 75.28 143.806 75.3284 143.85 75.3445C143.893 75.3606 143.941 75.3385 143.957 75.2951L144.411 74.076C144.427 74.0327 144.404 73.9843 144.361 73.9682Z"
        fill="#3F3D56"
      />
      <path
        d="M150.183 67.1131L148.679 66.5545C148.671 66.5514 148.662 66.5556 148.658 66.564L148.597 66.7303C148.594 66.7387 148.598 66.748 148.606 66.7511L150.109 67.3098C150.118 67.3129 150.127 67.3086 150.13 67.3002L150.192 67.134C150.195 67.1256 150.191 67.1163 150.183 67.1131Z"
        fill="#E6E6E6"
      />
      <path
        d="M155.27 69.2165C155.363 69.2165 155.438 69.1413 155.438 69.0487C155.438 68.956 155.363 68.8809 155.27 68.8809C155.177 68.8809 155.102 68.956 155.102 69.0487C155.102 69.1413 155.177 69.2165 155.27 69.2165Z"
        fill="#E6E6E6"
      />
      <path
        d="M155.701 69.3766C155.794 69.3766 155.87 69.3015 155.87 69.2088C155.87 69.1162 155.794 69.041 155.701 69.041C155.609 69.041 155.533 69.1162 155.533 69.2088C155.533 69.3015 155.609 69.3766 155.701 69.3766Z"
        fill="#E6E6E6"
      />
      <path
        d="M156.135 69.5387C156.228 69.5387 156.303 69.4636 156.303 69.3709C156.303 69.2783 156.228 69.2031 156.135 69.2031C156.042 69.2031 155.967 69.2783 155.967 69.3709C155.967 69.4636 156.042 69.5387 156.135 69.5387Z"
        fill="#E6E6E6"
      />
      <path
        d="M131.355 33.5713H125.453C124.694 33.5713 124.076 32.9535 124.076 32.194V25.5394C124.076 24.78 124.694 24.1621 125.453 24.1621H131.355C132.115 24.1621 132.733 24.78 132.733 25.5394V32.194C132.733 32.9535 132.115 33.5713 131.355 33.5713Z"
        fill="white"
      />
      <path
        d="M131.356 33.5713H125.454C124.694 33.5713 124.076 32.9535 124.076 32.194C123.78 29.7436 123.698 27.4614 124.076 25.5394C124.076 24.78 124.694 24.1621 125.454 24.1621H131.356C132.115 24.1621 132.733 24.78 132.733 25.5394V32.194C132.733 32.9535 132.115 33.5713 131.356 33.5713V33.5713ZM125.454 24.3503C124.798 24.3503 124.265 24.8837 124.265 25.5394V32.194C124.265 32.8497 124.798 33.3831 125.454 33.3831H131.356C132.011 33.3831 132.545 32.8497 132.545 32.194V25.5394C132.545 24.8837 132.011 24.3503 131.356 24.3503H125.454Z"
        fill="#3F3D56"
      />
      <path
        d="M130.758 28.2071H125.896C125.689 28.2071 125.521 28.0393 125.521 27.8331C125.521 27.6268 125.689 27.459 125.896 27.459H130.758C130.965 27.459 131.133 27.6268 131.133 27.8331C131.133 28.0393 130.965 28.2071 130.758 28.2071Z"
        fill="#F7B80B"
      />
      <path
        d="M130.758 30.2774H125.896C125.689 30.2774 125.521 30.1096 125.521 29.9034C125.521 29.6971 125.689 29.5293 125.896 29.5293H130.758C130.965 29.5293 131.133 29.6971 131.133 29.9034C131.133 30.1096 130.965 30.2774 130.758 30.2774Z"
        fill="#F7B80B"
      />
      <path
        d="M130.758 26.1388H125.896C125.689 26.1388 125.521 25.971 125.521 25.7647C125.521 25.5584 125.689 25.3906 125.896 25.3906H130.758C130.965 25.3906 131.133 25.5584 131.133 25.7647C131.133 25.971 130.965 26.1388 130.758 26.1388Z"
        fill="#F7B80B"
      />
      <path
        d="M57.6393 21.9102C58.0713 22.7245 58.6514 23.3738 59.2665 23.7939L62.8917 31.8117L66.6564 29.9751L62.5737 22.0395C62.5708 21.2948 62.3586 20.4503 61.9266 19.636C60.9397 17.7756 59.18 16.7765 57.9961 17.4045C56.8122 18.0325 56.6525 20.0498 57.6393 21.9102L57.6393 21.9102Z"
        fill="#FFB6B6"
      />
      <path
        d="M65.8013 26.9637C65.8013 26.9637 71.3229 24.0703 73.036 27.1084C74.4977 29.7005 75.5539 42.6715 75.8325 46.4236C75.8814 47.0822 75.7095 47.7363 75.3439 48.2862V48.2863C74.2611 49.915 71.9268 50.0702 70.6378 48.5993L59.4492 26.5691L64.7564 24.0469L66.0544 27.1905L65.8013 26.9637V26.9637Z"
        fill="#F7B80B"
      />
      <path
        d="M77.6057 57.9982C77.7269 57.8264 77.7984 57.617 77.7984 57.3908V38.0842C77.7984 37.5016 77.3261 37.0293 76.7435 37.0293H47.5598C46.9772 37.0293 46.5049 37.5016 46.5049 38.0842V57.3908C46.5049 57.617 46.5764 57.8264 46.6976 57.9982H43.0527V59.0102C55.7251 59.719 68.4577 59.719 81.2506 59.0102V57.9982H77.6057Z"
        fill="#3F3D56"
      />
      <path
        d="M108.961 99.9991H103.059C102.299 99.9991 101.682 99.3812 101.682 98.6218V91.9671C101.682 91.2077 102.299 90.5898 103.059 90.5898H108.961C109.72 90.5898 110.338 91.2077 110.338 91.9671V98.6218C110.338 99.3812 109.72 99.9991 108.961 99.9991Z"
        fill="white"
      />
      <path
        d="M108.961 99.9991H103.059C102.3 99.9991 101.682 99.3812 101.682 98.6218C101.385 96.1713 101.303 93.8892 101.682 91.9671C101.682 91.2077 102.3 90.5898 103.059 90.5898H108.961C109.721 90.5898 110.338 91.2077 110.338 91.9671V98.6218C110.338 99.3812 109.721 99.9991 108.961 99.9991ZM103.059 90.778C102.403 90.778 101.87 91.3114 101.87 91.9671V98.6218C101.87 99.2775 102.403 99.8109 103.059 99.8109H108.961C109.617 99.8109 110.15 99.2775 110.15 98.6218V91.9671C110.15 91.3114 109.617 90.778 108.961 90.778H103.059Z"
        fill="#3F3D56"
      />
      <path
        d="M108.364 94.6349H103.501C103.295 94.6349 103.127 94.467 103.127 94.2608C103.127 94.0545 103.295 93.8867 103.501 93.8867H108.364C108.57 93.8867 108.738 94.0545 108.738 94.2608C108.738 94.467 108.57 94.6349 108.364 94.6349Z"
        fill="#F7B80B"
      />
      <path
        d="M108.364 96.7052H103.501C103.295 96.7052 103.127 96.5374 103.127 96.3311C103.127 96.1248 103.295 95.957 103.501 95.957H108.364C108.57 95.957 108.738 96.1248 108.738 96.3311C108.738 96.5374 108.57 96.7052 108.364 96.7052Z"
        fill="#F7B80B"
      />
      <path
        d="M108.364 92.5665H103.501C103.295 92.5665 103.127 92.3987 103.127 92.1924C103.127 91.9862 103.295 91.8184 103.501 91.8184H108.364C108.57 91.8184 108.738 91.9862 108.738 92.1924C108.738 92.3987 108.57 92.5665 108.364 92.5665Z"
        fill="#F7B80B"
      />
      <path
        d="M57.4743 55.4434C56.5524 55.4483 55.7081 55.661 55.0507 56.011L46.266 55.5027L46.1465 59.6899L55.0705 59.7546C55.7316 60.0976 56.5781 60.3014 57.4999 60.2965C59.6059 60.2853 61.3073 59.1899 61.3002 57.8498C61.2931 56.5097 59.5802 55.4323 57.4743 55.4434H57.4743Z"
        fill="#FFB6B6"
      />
      <path
        d="M49.0344 24.9487C49.0344 24.9487 41.5667 24.1167 39.6645 28.7494C38.2113 32.2883 34.4362 46.0812 32.3177 53.978C31.5432 56.865 33.6488 59.7237 36.6356 59.842L49.8264 60.3644L50.5894 54.5289L41.17 53.0252L46.4391 37.2177L49.0344 24.9487Z"
        fill="#F7B80B"
      />
      <path
        d="M68.2042 5.48527C68.5833 3.56542 66.2566 2.81976 64.5142 1.92895L63.0349 0.677757C61.484 -0.115133 59.6706 -0.217091 58.0406 0.396952L52.9537 2.09026C50.6447 2.96009 49.839 5.0307 48.5084 7.10861L46.2781 10.0861C44.9683 12.1317 45.3599 15.3252 45.667 17.7346L45.7957 18.7443L44.5703 21.6746L53.9879 23.3559L55.8861 18.8337L54.7015 16.6843C54.2812 15.9217 54.4494 14.9692 55.1055 14.3967L55.1055 14.3967C57.4655 12.3371 57.9176 8.80227 57.9176 8.80227L59.9127 10.5617C61.3626 11.1056 62.701 11.3861 63.9299 11.4074C65.2814 11.4308 66.5003 11.1407 67.5891 10.5429L67.7808 13.458C67.218 16.9042 66.378 17.662 62.5937 20.2983L67.1484 24.8399L67.3618 22.9905C67.8696 18.5911 70.0634 15.5291 70.6573 11.9895C71.068 9.54198 70.12 7.06289 68.2042 5.48528H68.2042V5.48527Z"
        fill="#2F2E41"
      />
    </g>
    <defs>
      <clipPath id="clip0_1454_52309">
        <rect width="190" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
