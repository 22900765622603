import { Flex, Text } from "@chakra-ui/react";
import { FC } from "react";
import {
  setAdviceOnHover,
  useAdviceContext,
} from "../../context/AdviceContext";
import { colors } from "../../ui/theme";
import PicturesVariant from "../PicturesVariant/PicturesVariant";
import { DressCode } from "../DressingBlock/DressingBlock";

export interface PersonalGenericMenuCardsProps {
  dressCode?: DressCode;
  onSetPersonalGenericOutfit: (element: string | undefined) => void;
  details: any;
}

const PersonalGenericMenuCards: FC<PersonalGenericMenuCardsProps> = ({
  onSetPersonalGenericOutfit,
  details,
  dressCode,
}) => {
  const activeItem = dressCode?.personalOutfit?.generic === details?.name;

  const { setAdvice } = useAdviceContext();
  return (
    <Flex
      {...(details?.description &&
        setAdviceOnHover(details?.description, setAdvice))}
      className="PersonalGenericMenuCards"
      flexDirection="column"
      position="relative"
      borderRadius="12px"
      alignItems="center"
      border="1px solid transparent"
      padding={{ md: "8px", "2xl": "17px 12px 12px" }}
      gap="14px"
      width="100%"
      backgroundColor={
        dressCode?.personalOutfit?.generic === details?.name
          ? colors.blue.lightMode
          : "transparent"
      }
      _hover={{
        backgroundColor: colors.blue.transparent,
        color: colors.white,
        p: {
          color: colors.white,
        },
      }}
      onClick={() => {
        onSetPersonalGenericOutfit!(details?.name);
      }}
      sx={{
        ".helpIconCardItem": {
          position: "absolute",
          top: "3px",
          right: "-2px",
          width: "75px",
          borderTopRightRadius: "30px",
          "@media(max-width: 1600px), @media(max-height: 850px)": {
            width: "30px",
            borderTopRightRadius: "10px",
            right: "12px",
            top: "-10px",
          },
        },
        "@media(max-width: 1600px), @media(max-height: 850px)": {
          width: "180px",
          svg: {
            height: "70px",
          },
        },
      }}
    >
      <Flex width="fit-content" position="absolute" top="0" right="0"></Flex>
      <PicturesVariant icon={details?.icon} />
      <Text
        color={activeItem ? colors.white : colors.black}
        variant="xl"
        textAlign="center"
        className="cardActiveTitle"
      >
        {details?.name}
      </Text>
    </Flex>
  );
};

export default PersonalGenericMenuCards;
