import { useMutation } from "@apollo/client";
import { Box, Button, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CreateQuoteOutput,
  createQuote,
} from "../../../apollo/payment/mutation";
import DownloadIcon from "../../../components/Icons/DownloadIcon";
import { useCustomerContext } from "../../../context/CustomerContext";
import { HelpType, useHelpContext } from "../../../context/HelpContext";
import { useOnboardingContext } from "../../../context/OnboardingContext";
import { useOptionContext } from "../../../context/OptionsContext";
import { useSegment } from "../../../context/SegmentContext";
import { useSessionContext } from "../../../context/SessionContext";
import { useUserContext } from "../../../context/UserContext";
import { PageURL } from "../../../types/pages";
import { colors } from "../../../ui/theme";
import {
  getContactInfo,
  parseOperationInfo,
  parseOptions,
  parsePersonnel,
} from "../../../utils/parseUserData";

export default function GetQuote() {
  const [loading, setLoading] = useState(false);
  const [downloadURL, setDownloadURL] = useState(null as null | string);
  const [createNewQuote] = useMutation<{ createQuote: CreateQuoteOutput }>(
    createQuote
  );
  const navigate = useNavigate();
  const { sessionInfo, updateHistory } = useSessionContext();
  const { userInfos, setUserInfos } = useUserContext();
  const { customerInfo } = useCustomerContext();
  const { optionInfo } = useOptionContext();
  const { getAllInfo } = useOnboardingContext();

  // const downloadQuote = (url: string, quoteNumber: string | undefined) => {
  //   fetch(`${KEYS.API_URL}${url}`).then((response) => {
  //     response.blob().then((blob) => {
  //       const url = window.URL.createObjectURL(new Blob([blob]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `Devis-${quoteNumber || ""}.pdf`);

  //       // Append to html link element page
  //       document.body.appendChild(link);

  //       // Start download
  //       link.click();

  //       // Clean up and remove the link
  //       link.parentNode?.removeChild(link);
  //     });
  //   });
  // };

  const genAndDownloadQuote = async (): Promise<boolean> => {
    if (!userInfos || !customerInfo) return false;
    if (!downloadURL) {
      setLoading(true);
      createNewQuote({
        variables: {
          input: parsePersonnel(
            userInfos.selectDePersonnelInfo ?? { persons: {} },
            userInfos.calendarInfo ?? {
              days: null,
              period: null,
              eventSchedule: undefined,
            }
          ),
          options: parseOptions(optionInfo!, getAllInfo()),
          contact: getContactInfo(customerInfo!),
          operationInfo: parseOperationInfo(
            userInfos,
            getAllInfo(),
            sessionInfo
          ),
          outfit: userInfos.outfitInfos,
          discountCode: userInfos.discountCode,
        },
      })
        .then((res) => {
          if (res.data?.createQuote.quoteDownloadUrl === undefined) return;
          setDownloadURL(res.data?.createQuote.quoteDownloadUrl);
          // downloadQuote(
          //   res.data?.createQuote.quoteDownloadUrl,
          //   res.data?.createQuote.quoteNumber
          // );
          setUserInfos({
            ...userInfos,
            quoteId: res.data?.createQuote.quoteId,
            quoteNumber: res.data?.createQuote.quoteNumber,
          });
        })
        .finally(() => setLoading(false));
    }
    return true;
    // else {
    //   downloadQuote(downloadURL, userInfos.quoteNumber);
    // }
  };
  const { track } = useSegment();
  const { setHelp } = useHelpContext();
  const [showHelp, setShowHelp] = useState(true);
  useEffect(() => {
    if (showHelp) {
      setHelp(HelpType.QUOTE_WARNING);
      setShowHelp(false);
    } else setHelp(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box textAlign="left" pb="1em">
      <Button
        isLoading={loading}
        p="1.2em"
        backgroundColor={colors.additional.lightMode}
        color="white"
        onClick={async () => {
          if ((await genAndDownloadQuote()) === true) {
            track("Download quote");
            updateHistory(PageURL.VALIDATION);
            navigate(PageURL.VALIDATION);
          }
        }}
      >
        <DownloadIcon width="30px" height="30px" />
        <Text color="white" ml="0.5em">
          Envoyer le devis par mail
        </Text>
      </Button>
      <Text mt="1em">
        {
          "Signez le devis et procédez au paiement de l’acompte via virement bancaire."
        }
        <br />
        {
          "Le contrat sera validé une fois que vous nous aurez envoyé le contrat signé, et payé l’acompte à l’adresse suivante :"
        }
        <br />
        <br />
        <a href="mailto:contact@e-norela.com">{"contact@e-norela.com"}</a>
        <br />
        <br />
        <b>{"Modifiez votre devis"}</b>
        <br />
        <br />
        {
          'Une fois que vous aurez cliqué sur "Envoyer le devis par mail", vous pourrez modifier votre devis à tout moment sur la page suivante, ou effectuer une nouvelle commande.'
        }
      </Text>
      {/* <Button
        w="100%"
        padding="16px 0"
        mt="1rem"
        backgroundColor={colors.additional.lightMode}
        type="button"
        color="white"
        disabled={downloadURL === null}
        onClick={() => {
          updateHistory(PageURL.VALIDATION);
          navigate(PageURL.VALIDATION);
        }}
      >
        Finaliser ma commande
      </Button> */}
    </Box>
  );
}
