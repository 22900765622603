export const OrderCardWithGirlIcon = () => (
  <svg width="113" height="60" viewBox="0 0 113 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1377_2594)">
      <path d="M112.18 59.845H49.36C49.1665 59.8447 48.9809 59.7677 48.844 59.6307C48.7071 59.4937 48.6301 59.308 48.6299 59.1143V0.730705C48.6301 0.536987 48.7071 0.351277 48.844 0.214298C48.9809 0.0773192 49.1665 0.000253533 49.36 0H112.18C112.374 0.000253533 112.56 0.0773192 112.696 0.214298C112.833 0.351277 112.91 0.536987 112.911 0.730705V59.1141C112.91 59.3079 112.833 59.4936 112.696 59.6306C112.56 59.7676 112.374 59.8446 112.18 59.8448V59.845ZM49.36 0.291707C49.244 0.29196 49.1328 0.338191 49.0508 0.420285C48.9688 0.502379 48.9226 0.613648 48.9223 0.729746V59.1141C48.9226 59.2302 48.9688 59.3415 49.0508 59.4236C49.1328 59.5057 49.244 59.5519 49.36 59.5522H112.18C112.296 59.552 112.408 59.5057 112.49 59.4236C112.572 59.3415 112.618 59.2302 112.618 59.1141V0.730705C112.618 0.614607 112.572 0.503337 112.49 0.421244C112.408 0.33915 112.296 0.292918 112.18 0.292665L49.36 0.291707Z" fill="#E6E6E6"/>
      <path d="M79.0436 15.1719C78.9121 15.1719 78.786 15.2241 78.693 15.3172C78.6001 15.4102 78.5479 15.5364 78.5479 15.668C78.5479 15.7995 78.6001 15.9257 78.693 16.0187C78.786 16.1118 78.9121 16.164 79.0436 16.164H106.244C106.375 16.164 106.501 16.1118 106.594 16.0187C106.687 15.9257 106.74 15.7995 106.74 15.668C106.74 15.5364 106.687 15.4102 106.594 15.3172C106.501 15.2241 106.375 15.1719 106.244 15.1719H79.0436Z" fill="#E6E6E6"/>
      <path d="M79.0436 18.1484C78.9121 18.1484 78.786 18.2007 78.693 18.2937C78.6001 18.3868 78.5479 18.513 78.5479 18.6445C78.5479 18.7761 78.6001 18.9023 78.693 18.9953C78.786 19.0883 78.9121 19.1406 79.0436 19.1406H92.9299C93.0613 19.1406 93.1874 19.0883 93.2804 18.9953C93.3733 18.9023 93.4256 18.7761 93.4256 18.6445C93.4256 18.513 93.3733 18.3868 93.2804 18.2937C93.1874 18.2007 93.0613 18.1484 92.9299 18.1484H79.0436Z" fill="#E6E6E6"/>
      <path d="M55.2691 32.5547C55.1377 32.5547 55.0116 32.607 54.9186 32.7C54.8257 32.793 54.7734 32.9192 54.7734 33.0508C54.7734 33.1823 54.8257 33.3085 54.9186 33.4015C55.0116 33.4946 55.1377 33.5468 55.2691 33.5468H106.27C106.401 33.5468 106.527 33.4946 106.62 33.4015C106.713 33.3085 106.766 33.1823 106.766 33.0508C106.766 32.9192 106.713 32.793 106.62 32.7C106.527 32.607 106.401 32.5547 106.27 32.5547H55.2691Z" fill="#E6E6E6"/>
      <path d="M55.2691 35.5313C55.1377 35.5312 55.0116 35.5835 54.9186 35.6766C54.8257 35.7696 54.7734 35.8958 54.7734 36.0273C54.7734 36.1589 54.8257 36.2851 54.9186 36.3781C55.0116 36.4711 55.1377 36.5234 55.2691 36.5234H92.9559C93.0874 36.5234 93.2134 36.4711 93.3064 36.3781C93.3994 36.2851 93.4516 36.1589 93.4516 36.0273C93.4516 35.8958 93.3994 35.7696 93.3064 35.6766C93.2134 35.5835 93.0874 35.5313 92.9559 35.5313H55.2691Z" fill="#E6E6E6"/>
      <path d="M55.2691 38.4414C55.1377 38.4414 55.0116 38.4937 54.9186 38.5867C54.8257 38.6797 54.7734 38.8059 54.7734 38.9375C54.7734 39.0691 54.8257 39.1952 54.9186 39.2883C55.0116 39.3813 55.1377 39.4336 55.2691 39.4336H106.27C106.401 39.4336 106.527 39.3813 106.62 39.2883C106.713 39.1952 106.766 39.0691 106.766 38.9375C106.766 38.8059 106.713 38.6797 106.62 38.5867C106.527 38.4937 106.401 38.4414 106.27 38.4414H55.2691Z" fill="#E6E6E6"/>
      <path d="M55.2691 41.418C55.1377 41.418 55.0116 41.4702 54.9186 41.5633C54.8257 41.6563 54.7734 41.7825 54.7734 41.9141C54.7734 42.0456 54.8257 42.1718 54.9186 42.2648C55.0116 42.3579 55.1377 42.4101 55.2691 42.4101H92.9559C93.0874 42.4101 93.2134 42.3579 93.3064 42.2648C93.3994 42.1718 93.4516 42.0456 93.4516 41.9141C93.4516 41.7825 93.3994 41.6563 93.3064 41.5633C93.2134 41.4702 93.0874 41.418 92.9559 41.418H55.2691Z" fill="#E6E6E6"/>
      <path d="M72.1694 25.6224H32.7389C32.5454 25.6222 32.3598 25.5451 32.2229 25.4081C32.086 25.2711 32.009 25.0854 32.0088 24.8917V7.57445C32.009 7.38072 32.086 7.19499 32.2229 7.05801C32.3598 6.92102 32.5454 6.84397 32.7389 6.84375H72.1694C72.363 6.84397 72.5486 6.92102 72.6855 7.05801C72.8223 7.19499 72.8993 7.38072 72.8996 7.57445V24.8917C72.8993 25.0854 72.8223 25.2711 72.6855 25.4081C72.5486 25.5451 72.363 25.6222 72.1694 25.6224Z" fill="#F7B80B"/>
      <path d="M105.955 53.0024H91.0085C90.8149 53.0022 90.6293 52.9252 90.4924 52.7882C90.3555 52.6512 90.2785 52.4655 90.2783 52.2717V49.8752C90.2785 49.6815 90.3555 49.4958 90.4924 49.3588C90.6293 49.2218 90.8149 49.1447 91.0085 49.1445H105.955C106.148 49.1447 106.334 49.2218 106.471 49.3588C106.608 49.4958 106.685 49.6815 106.685 49.8752V52.2717C106.685 52.4655 106.608 52.6512 106.471 52.7882C106.334 52.9252 106.148 53.0022 105.955 53.0024Z" fill="#F7B80B"/>
      <path d="M17.3846 29.3105C16.7405 28.8293 16.5515 27.93 16.9624 27.302C17.0032 27.2405 17.0494 27.1827 17.1004 27.1294L17.1416 14.3359L19.0418 14.691L19.1253 27.2737C19.5582 27.7762 19.6456 28.5087 19.295 29.0445C18.8841 29.6726 18.0288 29.7916 17.3846 29.3105Z" fill="#FFB8B8"/>
      <path d="M27.2669 57.9449L29.2259 57.9447L30.1579 50.3828L27.2666 50.3829L27.2669 57.9449Z" fill="#FFB8B8"/>
      <path d="M26.7666 57.3048L30.6247 57.3047H30.6248C31.2769 57.3047 31.9022 57.564 32.3633 58.0254C32.8244 58.4868 33.0834 59.1126 33.0835 59.7652V59.8451L26.7667 59.8454L26.7666 57.3048Z" fill="#2F2E41"/>
      <path d="M9.05008 57.9449L11.0092 57.9447L11.9411 50.3828L9.0498 50.3829L9.05008 57.9449Z" fill="#FFB8B8"/>
      <path d="M8.55078 57.3048L12.4088 57.3047H12.409C13.0611 57.3047 13.6864 57.564 14.1475 58.0254C14.6086 58.4868 14.8676 59.1126 14.8677 59.7652V59.8451L8.5509 59.8454L8.55078 57.3048Z" fill="#2F2E41"/>
      <path d="M27.1523 55.8306L26.9881 54.8447C26.8195 54.7856 26.6761 54.6705 26.5821 54.5185C26.4057 54.2397 26.3776 53.8474 26.4985 53.3522L25.1767 42.1811L18.2759 35.0159L13.8488 45.7019L12.0963 54.1581V55.3799L8.59728 55.8295L8.75651 54.6779C8.62265 54.6205 8.51112 54.5211 8.43879 54.3946C8.23776 54.0597 8.24051 53.5407 8.44694 52.8521L8.45589 52.8222L8.44447 52.7194C8.43645 52.6473 8.48906 45.7402 10.5408 42.318L12.5957 31.9279C12.5423 31.7816 12.2789 30.9297 12.762 29.4901C13.2389 28.0694 14.5725 25.7807 18.2179 22.9453L18.2304 22.9356L18.2459 22.9323C18.2863 22.924 22.3069 22.124 24.3863 24.8453L24.4004 24.8637V24.8869C24.4004 25.0551 24.3968 28.9702 24.0874 29.8389L29.0216 38.9184C29.1177 39.0203 29.9803 40.0172 29.6739 42.6382L30.471 51.5726C30.5622 51.7469 31.3873 53.3878 30.4779 54.0375L30.641 55.1656L27.1523 55.8306Z" fill="#2F2E41"/>
      <path d="M18.8978 18.2871C17.9713 18.2871 16.7402 17.9593 15.9865 16.4488L15.9717 16.4192L17.8284 12.4804C18.0689 11.9701 18.4995 11.5743 19.0279 11.3776C19.5564 11.1809 20.1408 11.1991 20.656 11.4281C21.1713 11.6572 21.5765 12.0789 21.785 12.6031C21.9935 13.1274 21.9887 13.7124 21.7716 14.2332L20.1664 18.0843L20.1384 18.0946C19.7375 18.2235 19.3189 18.2884 18.8978 18.2871Z" fill="#F7B80B"/>
      <path d="M24.0408 26.0985L18.1875 23.3324L18.9921 13.5092L20.4589 11.715L20.7431 11.6542C22.0489 11.3744 23.4059 11.4496 24.6729 11.872L24.7007 11.8813L27.1101 17.025C27.1252 17.0507 28.8628 20.1207 26.4608 21.7393L24.0408 26.0985Z" fill="#F7B80B"/>
      <path d="M32.0881 22.9657C32.0183 22.9949 31.9508 23.0295 31.8864 23.0692L25.7185 20.2457L25.5603 18.6043L23.0538 18.5195L23.0518 21.4525C23.0516 21.6894 23.1282 21.92 23.2702 22.1097C23.4121 22.2993 23.6117 22.4378 23.8389 22.5042L31.2456 24.6705C31.3216 24.9521 31.4816 25.2039 31.7041 25.3924C31.9267 25.5808 32.2013 25.697 32.4915 25.7254C32.7816 25.7539 33.0735 25.6933 33.3284 25.5516C33.5833 25.41 33.789 25.1941 33.9183 24.9325C34.0475 24.671 34.0942 24.3763 34.052 24.0876C34.0098 23.799 33.8808 23.53 33.6821 23.3165C33.4834 23.103 33.2245 22.955 32.9397 22.8924C32.655 22.8297 32.358 22.8553 32.0881 22.9657L32.0881 22.9657Z" fill="#FFB8B8"/>
      <path d="M24.5435 19.3043C23.8407 19.3043 23.0475 19.0611 22.2862 18.2992L22.2627 18.2757L22.7771 13.9512C22.8438 13.3909 23.1269 12.879 23.5659 12.5249C24.0049 12.1709 24.5649 12.0028 25.1261 12.0568C25.6874 12.1107 26.2052 12.3823 26.5689 12.8134C26.9326 13.2446 27.1132 13.8011 27.0721 14.3638L26.7685 18.5254L26.7452 18.544C26.7303 18.556 25.776 19.3043 24.5435 19.3043Z" fill="#F7B80B"/>
      <path d="M26.4243 8.29029C27.464 6.38693 26.765 4.00048 24.8631 2.96001C22.9611 1.91954 20.5765 2.61905 19.5368 4.52241C18.4971 6.42577 19.1961 8.81222 21.098 9.85269C23 10.8932 25.3846 10.1936 26.4243 8.29029Z" fill="#FFB8B8"/>
      <path d="M26.9745 4.32819C26.7797 3.19463 25.8704 2.25494 24.7873 1.87045C23.704 1.48597 22.4825 1.61101 21.4218 2.05377C21.2451 2.12784 21.0724 2.21095 20.9043 2.30277C20.5166 1.56319 19.566 1.13425 18.6966 1.18054C17.6463 1.23647 16.6978 1.79263 15.7856 2.3168C14.8734 2.84096 13.8945 3.36248 12.8429 3.33807C10.9425 3.29398 9.52876 1.53082 7.70141 1.00683C6.88794 0.773575 6.02237 0.79717 5.2228 1.07439C4.42322 1.35162 3.72859 1.86897 3.23366 2.55586C2.73874 3.24275 2.46764 4.06572 2.45734 4.9125C2.44704 5.75928 2.69804 6.58861 3.17611 7.28735C3.95581 5.88903 5.78683 5.41674 7.37561 5.6071C8.96432 5.79747 10.445 6.49439 12.0015 6.86562C13.558 7.23686 15.3606 7.22093 16.5663 6.16815C17.2502 5.571 17.6517 4.71748 18.2471 4.03193C18.6498 3.56823 19.2231 3.17668 19.8089 3.08452C19.2319 3.60454 18.7914 4.25845 18.5261 4.98877C18.0564 6.32436 18.3075 7.928 19.282 8.95456C20.3266 10.0551 21.9662 10.3485 23.4829 10.3307L23.5147 10.2984C23.0503 9.60276 22.6838 8.63819 23.1951 7.97633C23.6998 7.323 24.6952 7.41925 25.4682 7.13016C26.5447 6.7275 27.1691 5.46175 26.9745 4.32819Z" fill="#2F2E41"/>
      <path d="M37.5687 60.0003H0.226628C0.19035 60.0003 0.155559 59.9859 0.129907 59.9602C0.104255 59.9346 0.0898438 59.8998 0.0898438 59.8634C0.0898438 59.8271 0.104255 59.7923 0.129907 59.7667C0.155559 59.741 0.19035 59.7266 0.226628 59.7266H37.5687C37.6049 59.7266 37.6397 59.741 37.6654 59.7667C37.691 59.7923 37.7054 59.8271 37.7054 59.8634C37.7054 59.8998 37.691 59.9346 37.6654 59.9602C37.6397 59.9859 37.6049 60.0003 37.5687 60.0003Z" fill="#CCCCCC"/>
      <path d="M50.6844 12.918C50.5132 12.9185 50.3747 13.1411 50.375 13.415C50.3753 13.6882 50.5137 13.9096 50.6844 13.9101H65.3025C65.4737 13.9096 65.6123 13.687 65.6119 13.4131C65.6116 13.1399 65.4732 12.9185 65.3025 12.918H50.6844Z" fill="white"/>
      <path d="M50.6844 15.793C50.5132 15.7935 50.3747 16.0161 50.375 16.29C50.3753 16.5632 50.5137 16.7846 50.6844 16.7851H65.3025C65.4737 16.7846 65.6123 16.562 65.6119 16.2881C65.6116 16.0149 65.4732 15.7935 65.3025 15.793H50.6844Z" fill="white"/>
      <path d="M50.6844 18.6328C50.5132 18.6334 50.3747 18.8559 50.375 19.1299C50.3753 19.4031 50.5137 19.6244 50.6844 19.625H56.9746C57.1458 19.6244 57.2844 19.4019 57.2841 19.1279C57.2837 18.8547 57.1454 18.6334 56.9746 18.6328H50.6844Z" fill="white"/>
      <path d="M43.2626 20.2051C45.4534 20.2051 47.2294 18.4278 47.2294 16.2354C47.2294 14.0429 45.4534 12.2656 43.2626 12.2656C41.0719 12.2656 39.2959 14.0429 39.2959 16.2354C39.2959 18.4278 41.0719 20.2051 43.2626 20.2051Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_1377_2594">
        <rect width="112.821" height="60" fill="white" transform="translate(0.0898438)"/>
      </clipPath>
    </defs>
  </svg>

);