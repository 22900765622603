export const NotificationAlertGirlIcon = () => (
  <svg width="193" height="193" viewBox="0 0 193 193" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1055_69700)">
      <path d="M193 192.06C193 192.304 192.805 192.5 192.562 192.5H0.437742C0.194972 192.5 0 192.304 0 192.06C0 191.817 0.194972 191.621 0.437742 191.621H192.562C192.805 191.621 193 191.817 193 192.06Z" fill="#3F3D56"/>
      <path d="M133.36 170.676L135.567 179.903L128.946 181.749L127.475 171.783L133.36 170.676Z" fill="#FFB6B6"/>
      <path d="M144.028 140.779L137.039 146.685L132.625 153.698L138.878 157.758L149.546 145.578L144.028 140.779Z" fill="#FFB6B6"/>
      <path d="M141.823 79.877L147.708 99.8082C147.708 99.8082 173.825 122.692 171.251 127.491C168.676 132.289 151.387 145.576 151.387 145.576C151.387 145.576 152.67 149.825 148.901 148.07C145.133 146.315 141.087 141.516 141.087 141.516C141.087 141.516 141.087 137.825 142.926 138.563C144.765 139.302 153.226 129.336 153.226 129.336L134.833 112.358V169.13C134.833 169.13 139.248 173.003 134.833 173.315C130.419 173.628 126.373 174.366 126.373 174.366C126.373 174.366 121.223 172.52 123.062 170.675L117.544 140.409L113.498 103.499L116.441 84.3061L141.823 79.877Z" fill="#1A202C"/>
      <path d="M130.05 178.797C130.05 178.797 130.418 177.32 131.889 177.32H136.383C136.383 177.32 137.775 183.595 137.407 183.595C137.039 183.595 143.292 191.715 136.671 192.084C130.05 192.453 128.211 191.715 128.211 191.715L127.475 188.802C127.475 188.802 126.371 186.917 126.739 185.44C127.107 183.964 130.05 178.797 130.05 178.797Z" fill="#1A202C"/>
      <path d="M139.246 154.805C139.246 154.805 143.471 154.738 142.462 155.879C141.453 157.02 139.614 158.865 139.614 158.865C139.614 158.865 135.239 167.724 133.196 166.063C131.153 164.402 127.843 145.947 128.946 143.732C130.05 141.518 131.521 141.518 131.521 141.518L138.308 145.613C138.308 145.613 134.096 155.912 139.246 154.805Z" fill="#1A202C"/>
      <path d="M128.948 48.5043L130.419 39.2769L120.487 35.2168L119.016 49.2425L128.948 48.5043Z" fill="#FFB6B6"/>
      <path opacity="0.1" d="M128.948 48.5043L130.419 39.2769L120.487 35.2168L119.016 49.2425L128.948 48.5043Z" fill="black"/>
      <path d="M118.463 47.2125L130.235 44.2598L142.742 48.3198C142.742 48.3198 148.259 63.8219 146.42 69.3584C144.581 74.8949 144.581 85.7091 144.581 85.7091C144.581 85.7091 144.397 80.985 133.729 84.3069C123.062 87.6287 115.337 87.2596 115.337 87.2596L114.969 73.9721L110.348 62.1696C108.373 57.1248 110.502 51.3972 115.287 48.8822L118.463 47.2125Z" fill="#E6E6E6"/>
      <path d="M172.499 90.6932C174.16 92.6898 174.48 95.1682 173.213 96.2287C171.947 97.2891 169.574 96.53 167.912 94.5324C167.238 93.7436 166.761 92.8041 166.523 91.7927L159.592 83.2435L162.968 80.502L170.06 88.8308C171.01 89.2472 171.846 89.8859 172.499 90.6932V90.6932Z" fill="#FFB6B6"/>
      <path d="M142.207 48.4375L139.795 58.0253L151.602 76.9891L163.655 89.1438L167.562 85.8918L155.406 70.7158L146.192 51.1619L142.207 48.4375Z" fill="#E6E6E6"/>
      <path d="M170.313 39.1831C178.721 38.5596 184.9 29.4021 184.114 18.7293C183.328 8.05639 175.875 -0.0902491 167.466 0.53322C159.058 1.15669 152.879 10.3142 153.665 20.987C154.452 31.6599 161.905 39.8065 170.313 39.1831Z" fill="#F7B80B"/>
      <path d="M169.994 39.1836H169.258V112.796H169.994V39.1836Z" fill="#1A202C"/>
      <path d="M170.177 37.6289L170.266 40.2373L171.44 42.652L166.539 42.4012L168.599 40.3219L168.366 37.9517L170.177 37.6289Z" fill="#F7B80B"/>
      <path d="M126.437 42.2431C132.03 42.2431 136.565 37.6932 136.565 32.0805C136.565 26.4679 132.03 21.918 126.437 21.918C120.843 21.918 116.309 26.4679 116.309 32.0805C116.309 37.6932 120.843 42.2431 126.437 42.2431Z" fill="#FFB6B6"/>
      <path d="M125.629 18.4183C125.922 18.59 126.314 18.3303 126.408 18.0031C126.502 17.6759 126.393 17.3285 126.284 17.0059L125.738 15.3818C125.35 14.23 124.939 13.038 124.099 12.1613C122.833 10.8381 120.819 10.5014 119.007 10.7453C116.679 11.0585 114.383 12.3231 113.3 14.4143C112.218 16.5056 112.679 19.4236 114.634 20.7283C111.847 23.9336 110.876 27.5058 111.029 31.7568C111.183 36.0078 115.799 39.9202 118.81 42.9148C119.483 42.5059 120.094 40.5893 119.724 39.893C119.354 39.1968 119.884 38.3901 119.426 37.7488C118.968 37.1075 118.585 38.1287 119.048 37.491C119.34 37.0886 118.2 36.1629 118.634 35.922C120.735 34.7571 121.433 32.1303 122.752 30.1188C124.343 27.6926 127.067 26.0495 129.948 25.7769C131.536 25.6267 133.213 25.8987 134.514 26.8234C135.815 27.7482 136.657 29.4007 136.356 30.9717C137.137 30.1756 137.526 29.0088 137.379 27.9011C137.233 26.7934 136.553 25.7691 135.592 25.2054C136.177 23.2654 135.676 21.0338 134.319 19.5325C132.962 18.0313 127.457 18.287 125.475 18.6821" fill="#1A202C"/>
      <path d="M125.322 25.8359C122.698 26.1202 120.804 28.4009 119.204 30.5071C118.282 31.7211 117.316 33.0617 117.339 34.5879C117.362 36.1309 118.39 37.4544 118.882 38.9167C119.685 41.3069 118.902 44.1507 116.99 45.7885C118.879 46.1482 120.922 44.7268 121.248 42.8254C121.628 40.6118 119.955 38.4754 120.153 36.2381C120.328 34.267 121.876 32.75 123.192 31.2762C124.508 29.8024 125.744 27.8468 125.139 25.9635" fill="#1A202C"/>
      <path d="M115.662 87.0742H40.007C31.4276 87.0742 24.4727 94.0527 24.4727 102.661V176.271C24.4727 184.88 31.4276 191.858 40.0069 191.858H115.662C124.242 191.858 131.197 184.88 131.197 176.271V102.661C131.197 94.0527 124.242 87.0742 115.662 87.0742V87.0742Z" fill="white"/>
      <path d="M115.661 192.227H40.0057C31.2373 192.227 24.1035 185.069 24.1035 176.271V102.661C24.1035 93.863 31.2373 86.7051 40.0057 86.7051H115.661C124.429 86.7051 131.563 93.863 131.563 102.661V176.271C131.563 185.069 124.429 192.227 115.661 192.227ZM40.0057 87.4433C31.6428 87.4433 24.8392 94.27 24.8392 102.661V176.271C24.8392 184.662 31.6428 191.489 40.0057 191.489H115.661C124.024 191.489 130.828 184.662 130.828 176.271V102.661C130.828 94.27 124.024 87.4433 115.661 87.4433H40.0057Z" fill="#3F3D56"/>
      <path d="M107.678 94.3154C108.647 94.3154 109.432 93.5273 109.432 92.5552C109.432 91.583 108.647 90.7949 107.678 90.7949C106.709 90.7949 105.924 91.583 105.924 92.5552C105.924 93.5273 106.709 94.3154 107.678 94.3154Z" fill="#3F3D56"/>
      <path d="M112.504 94.3154C113.473 94.3154 114.259 93.5273 114.259 92.5552C114.259 91.583 113.473 90.7949 112.504 90.7949C111.535 90.7949 110.75 91.583 110.75 92.5552C110.75 93.5273 111.535 94.3154 112.504 94.3154Z" fill="#3F3D56"/>
      <path d="M117.329 94.3154C118.297 94.3154 119.083 93.5273 119.083 92.5552C119.083 91.583 118.297 90.7949 117.329 90.7949C116.36 90.7949 115.574 91.583 115.574 92.5552C115.574 93.5273 116.36 94.3154 117.329 94.3154Z" fill="#3F3D56"/>
      <path d="M37.5274 166.088C36.9824 166.088 36.5391 166.533 36.5391 167.08C36.5391 167.346 36.6417 167.593 36.828 167.775C37.0159 167.968 37.262 168.071 37.5274 168.071H116.651C117.196 168.071 117.64 167.627 117.64 167.08C117.64 166.813 117.537 166.566 117.351 166.384C117.163 166.191 116.917 166.088 116.651 166.088H37.5274Z" fill="#E6E6E6"/>
      <path d="M79.8349 165.758V168.402H37.5268C37.1644 168.402 36.835 168.257 36.5978 168.012C36.3539 167.774 36.209 167.444 36.209 167.08C36.209 166.353 36.802 165.758 37.5268 165.758H79.8349V165.758Z" fill="#F7B80B"/>
      <path d="M115.004 160.799H101.825C100.372 160.799 99.1895 159.613 99.1895 158.154C99.1895 156.696 100.372 155.51 101.825 155.51H115.004C116.457 155.51 117.639 156.696 117.639 158.154C117.639 159.613 116.457 160.799 115.004 160.799Z" fill="#E6E6E6"/>
      <path d="M65.4938 162.69L56.2813 131.631L55.5762 131.841L64.7563 162.791" fill="#1A202C"/>
      <path d="M65.813 92.0053C74.2212 91.3819 80.4001 82.2244 79.614 71.5515C78.828 60.8787 71.3746 52.732 62.9665 53.3555C54.5583 53.979 48.3794 63.1364 49.1655 73.8093C49.9515 84.4822 57.4049 92.6288 65.813 92.0053Z" fill="#F7B80B"/>
      <path d="M65.4935 92.0059H64.7578V165.618H65.4935V92.0059Z" fill="#1A202C"/>
      <path d="M56.6954 130.73L57.5213 133.205L59.3336 135.184L54.5645 136.346L55.9482 133.763L55.0514 131.558L56.6954 130.73Z" fill="#F7B80B"/>
      <path d="M65.6754 90.4512L65.7637 93.0596L66.9385 95.4743L62.0371 95.2235L64.0974 93.1441L63.8645 90.774L65.6754 90.4512Z" fill="#F7B80B"/>
      <path d="M56.943 131.381C64.8175 128.358 68.1119 117.805 64.3011 107.809C60.4903 97.8136 51.0175 92.1609 43.1429 95.1834C35.2684 98.206 31.974 108.759 35.7848 118.755C39.5956 128.75 49.0684 134.403 56.943 131.381Z" fill="#F7B80B"/>
      <path d="M123.785 83.2969C126.267 84.0486 127.892 85.9426 127.415 87.5271C126.937 89.1115 124.54 89.7862 122.057 89.0339C121.062 88.747 120.149 88.2251 119.396 87.5117L108.922 84.1935L110.24 80.041L120.728 83.0863C121.749 82.9105 122.798 82.9827 123.785 83.2969Z" fill="#FFB6B6"/>
      <path d="M105.589 56.0713L116.624 47.9512L116.256 63.4533L105.957 76.7408L116.992 81.17L116.624 87.494C116.624 87.494 102.646 84.1227 100.439 83.7536C98.2318 83.3845 96.3926 78.2172 96.3926 78.2172L105.589 56.0713Z" fill="#E6E6E6"/>
    </g>
    <defs>
      <clipPath id="clip0_1055_69700">
        <rect width="193" height="192" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>

);