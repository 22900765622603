import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
import { colors } from "../../ui/theme";

interface ButtonTypes extends ButtonProps {
  light?: boolean;
}

const NorelaButton: React.FunctionComponent<ButtonTypes> = ({ onClick, light, ...props }) => {
  return (
    <Button
      padding="10px 24px"
      background={light ? colors.white : colors.additional.lightMode}
      border={light ? `solid 1px ${colors.additional.lightMode}` : undefined}
      borderRadius="6px"
      onClick={onClick}
      _hover={{
        opacity: 0.7,
      }}
      _active={{
        opacity: 0.5,
      }}
      color={light ? colors.additional.lightMode : colors.white}
      fontWeight="500"
      {...props}
    />
  );
};
export default NorelaButton;
