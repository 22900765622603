import moment from "moment";
import { useEffect, useState } from "react";
import {
  EventDateInfo,
  EventSchedule,
  ORGANISATION_TYPE,
  PersonInfo,
  PersonnelType,
} from "../context/UserContext";
import {
  getHourValue,
  modulo,
} from "../modules/Calendar/CalendarEventSchedule";
import { EventType } from "../modules/Onboarding/EventTypes";
import { PageContentProps } from "../modules/Onboarding/type";
import {
  ACCUEIL_CONFIG,
  STREET_MARKETING_CONFIG,
} from "../modules/SelectDePersonnel/personnelConfig";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const enum COLORS {
  WHITE = "#FFF",
  BLACK = "#000",
  YELLOW = "#F7B80B",
  ORANGE = "#DB3E00",
  RED = "#B80000",
  PURPLE = "#5300EB",
  GREEN = "#008B02",
  BLUE = "#004DCF",
  NUDE = "#FAD0C3",
}

export const getColor = (color: string): [string, string, string, string] => {
  if (color?.toUpperCase() === COLORS.WHITE)
    return ["Blanc", "Blanche", "Blancs", "Blanches"];
  if (color?.toUpperCase() === COLORS.BLACK)
    return ["Noir", "Noire", "Noirs", "Noires"];
  if (color?.toUpperCase() === COLORS.YELLOW)
    return ["Jaune", "Jaune", "Jaunes", "Jaunes"];
  if (color?.toUpperCase() === COLORS.ORANGE)
    return ["Orange", "Orange", "Orange", "Orange"];
  if (color?.toUpperCase() === COLORS.RED)
    return ["Rouge", "Rouge", "Rouges", "Rouges"];
  if (color?.toUpperCase() === COLORS.PURPLE)
    return ["Violet", "Violette", "Violets", "Violettes"];
  if (color?.toUpperCase() === COLORS.GREEN)
    return ["Vert", "Verte", "Verts", "Vertes"];
  if (color?.toUpperCase() === COLORS.BLUE)
    return ["Bleu", "Bleue", "Bleus", "Bleues"];
  if (color?.toUpperCase() === COLORS.NUDE)
    return ["Nude", "Nude", "Nudes", "Nudes"];
  if (color?.toUpperCase() === "NONE")
    return ["Aucun", "Aucune", "Aucuns", "Aucunes"];
  return ["", "", "", ""];
};

export const determineOrganisationType = (
  startHour?: string,
  endHour?: string,
  current?: ORGANISATION_TYPE
): ORGANISATION_TYPE => {
  if (!startHour || !endHour) return ORGANISATION_TYPE.NONE;
  const workingHours = calcDiffHours(endHour, startHour);
  if (workingHours < 6) {
    return ORGANISATION_TYPE.NONE;
  } else if (workingHours >= 14) {
    return ORGANISATION_TYPE.ROTATION;
  } else {
    if (current === ORGANISATION_TYPE.ROTATION)
      return ORGANISATION_TYPE.ROTATION;
    return ORGANISATION_TYPE.BREAKTIME;
  }
};

export function showEventDayHoursToUser(
  schedule: EventSchedule | undefined
): string {
  const start = schedule?.schedule?.defaultStartHour
    ? schedule?.schedule?.defaultStartHour.toString()
    : undefined;
  const end = schedule?.schedule?.defaultEndHour
    ? schedule?.schedule?.defaultEndHour.toString()
    : undefined;
  const toShow =
    start && end
      ? ": " + start + " - " + end
      : start
      ? ": " + start
      : end
      ? ": " + end
      : "";
  return toShow;
}

export function isMissingHours(
  eventSchedule: EventSchedule[] | undefined
): boolean {
  if (!eventSchedule) return true;
  let answer = false;
  eventSchedule?.forEach((schedule) => {
    if (
      schedule.schedule.defaultEndHour === undefined ||
      schedule.schedule.defaultStartHour === undefined
    ) {
      answer = true;
    }
  });
  return answer;
}

export function getDayListFromPerdiod(period: {
  startDate: string;
  endDate: string;
}): string[] {
  const dayList = [];
  const numberOfDays = moment(period?.endDate).diff(
    moment(period?.startDate),
    "days"
  );
  const firstDay = moment(period?.startDate);
  for (let i = 0; i <= numberOfDays; i++) {
    dayList.push(firstDay.clone());
    firstDay.add(1, "day");
  }
  return dayList.map((day) => day.toISOString());
}

export const formatBreaktimeToDisplay = (breaktime: string): string => {
  //01:00 -> 1h
  if (breaktime[0] === "0") breaktime = breaktime.substring(1);
  return breaktime.replace(":00", ":").replace(":", "h");
};

export const formatBreaktimeToStore = (breaktime: string): string => {
  //1h -> 01:00
  if (breaktime[breaktime.length - 1] === "h")
    breaktime = breaktime.substring(0, breaktime.length - 1) + ":00";
  if (parseFloat(breaktime) < 10) breaktime = "0" + breaktime;
  return breaktime.replace("h", ":");
};

export function getDayList(
  calendarInfo: EventDateInfo | null
): string[] | null {
  if (!calendarInfo) return null;
  if (calendarInfo.days) return calendarInfo.days;
  if (!calendarInfo.period) return null;
  return getDayListFromPerdiod(calendarInfo.period);
}

export const makeDateInfoObject = (current?: EventDateInfo): EventDateInfo => {
  if (current) return current;
  return { days: null, period: null, eventSchedule: undefined };
};

export const getDayScheduleFromEventDateInfo = (
  day: string,
  eventDateInfo: EventDateInfo
) => {
  return eventDateInfo?.eventSchedule?.find((schedule) => schedule.day === day)
    ?.schedule;
};

export const getSpecialDayScheduleFromEventDateInfo = (
  day: string,
  eventDateInfo: EventDateInfo,
  personnelType: PersonnelType
) => {
  return getDayScheduleFromEventDateInfo(
    day,
    eventDateInfo
  )?.specialSchedules?.find(
    (schedule) => schedule.PersonnelType === personnelType
  )?.schedule;
};

export const calcDiffHours = (endTime?: string, startTime?: string) => {
  if (!endTime || !startTime) return 0;
  return getHourValue(endTime) - getHourValue(startTime);
};

export const getDaySchedule = (
  person: PersonInfo,
  _date: string,
  index: number,
  startHour: string,
  endHour: string
): { begin: string; end: string } | null => {
  const obj = { begin: startHour, end: endHour };
  const override = person.dateOverrides?.at(index);
  if (!override) return obj;

  for (const date in person.dateOverrides?.at(index)) {
    if (date === _date) {
      if (!person.dateOverrides?.at(index)?.[date].isWorking) return null;
      const result = {
        begin: person.dateOverrides?.at(index)?.[date].startHour ?? startHour,
        end: person.dateOverrides?.at(index)?.[date].endHour ?? endHour,
      };
      return result;
    }
  }
  return obj;
};

export const floatToHour = (floatValue: number) => {
  const hours = Math.floor(modulo(floatValue, 24));
  const minutes = Math.round(modulo(floatValue - hours, 24) * 60);
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};

export const create3HoursShift = (startTime: string) => {
  return floatToHour(getHourValue(startTime) + 3);
};

export const create3HoursShiftReversed = (startTime: string) => {
  return floatToHour(getHourValue(startTime) - 3);
};

export const computeSwapHour = (endTime?: string, startTime?: string) => {
  if (!endTime || !startTime) return undefined;
  return floatToHour(
    getHourValue(startTime) +
      Math.floor((getHourValue(endTime) - getHourValue(startTime)) / 2)
  );
};

export const setEmargementDefaultSchedule = (
  person: PersonInfo,
  _date: string,
  index: number,
  startHour: string
) => {
  const endHour = create3HoursShift(startHour);
  const obj = { begin: startHour, end: endHour, isWorking: true };
  if (person.dateOverrides === undefined) {
    person.dateOverrides = [];
  }
  while (person.dateOverrides.length <= index) person.dateOverrides.push({});
  person.dateOverrides!.at(index)![_date] = obj;
  return person;
};

export const checkRequiresBreak = (endTime?: string, startTime?: string) => {
  return (
    calcDiffHours(endTime, startTime) >= 6 &&
    calcDiffHours(endTime, startTime) < 14
  );
};

export const checkRequiresRotation = (endTime?: string, startTime?: string) => {
  return calcDiffHours(endTime, startTime) >= 14;
};

export const is3HoursShift = (endTime?: string, startTime?: string) => {
  if (!endTime || !startTime) return false;
  return calcDiffHours(endTime, startTime) === 3;
};

export const checkIntervalTooSmall = (endTime?: string, startTime?: string) => {
  if (!endTime || !startTime) return false;
  return calcDiffHours(endTime, startTime) < 3;
};

export const formatingNumber = (price: number, currency?: string) =>
  `${price
    .toFixed(2)
    .toString()
    .replace(/\B(?=(?:\d{3})*$)/g, " ")}${currency ? " " + currency : ""}`;

export const maskInputField = (
  e: any,
  formatingSpaceNumber: number | undefined
) => {
  let target = e.target,
    position = target.selectionEnd,
    length = target.value.length;
  formatingSpaceNumber === 2
    ? (target.value = target.value
        .replace(/[^\dA-Z]/g, "")
        .replace(/(.{2})/g, "$1 ")
        .trim())
    : (target.value = target.value
        .replace(/[^\dA-Z]/g, "")
        .replace(/(.{3})/g, "$1 ")
        .trim());
  target.selectionEnd = position +=
    target.value.charAt(position - 1) === " " &&
    target.value.charAt(length - 1) === " " &&
    length !== target.value.length
      ? 1
      : 0;
  return e;
};

export function gotoNextPage(
  page: PageContentProps,
  pages: PageContentProps[],
  setSearchParams: (params: any) => void
) {
  let nextPage = pages.filter((e) => e.id === page.nextPageId)[0];
  setSearchParams({ pageId: nextPage.id });
}

export function guessEventTypeFromPersonel(persons: {
  [key in PersonnelType]?: PersonInfo;
}) {
  if (!persons) return undefined;
  let answer: EventType | undefined = undefined;
  for (const person in persons) {
    for (const elem of ACCUEIL_CONFIG.personnel) {
      if (elem.type === person) {
        if (!answer || answer === EventType.ACCUEIL) answer = EventType.ACCUEIL;
        else return undefined;
      }
    }
    for (const person in persons) {
      for (const elem of STREET_MARKETING_CONFIG.personnel) {
        if (elem.type === person) {
          if (!answer || answer === EventType.STREET_MARKETING)
            answer = EventType.STREET_MARKETING;
          else return undefined;
        }
      }
    }
  }
  return answer;
}

export function isInParisAgglomeration(address: string): boolean {
  // Expression régulière pour les codes postaux de Paris et de son agglomération
  const parisAgglomerationRegex = /\b(75|92|93|94)\d{3}\b/;

  // Recherche du motif dans l'adresse
  return parisAgglomerationRegex.test(address);
}
