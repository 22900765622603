export const CalendarSelectionGroupeIcon = () => (
  <svg width="295" height="100" viewBox="0 0 295 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1506_78877)">
      <path
        d="M90.1185 52.1762H4.66892C4.44911 52.176 4.23837 52.0885 4.08294 51.9331C3.92751 51.7777 3.84008 51.567 3.83984 51.3471V0.829071C3.84008 0.609261 3.92751 0.398523 4.08294 0.243094C4.23837 0.0876651 4.44911 0.000239954 4.66892 0H90.1185C90.3383 0.000239955 90.5491 0.0876655 90.7045 0.243095C90.8599 0.398524 90.9473 0.609262 90.9476 0.829071V51.3471C90.9473 51.567 90.8599 51.7777 90.7045 51.9331C90.5491 52.0885 90.3383 52.176 90.1185 52.1762ZM4.66892 0.331628C4.53703 0.331772 4.41059 0.384227 4.31733 0.477485C4.22407 0.570742 4.17162 0.697185 4.17147 0.829071V51.3471C4.17162 51.479 4.22407 51.6055 4.31733 51.6987C4.41059 51.792 4.53703 51.8444 4.66892 51.8446H90.1185C90.2504 51.8444 90.3768 51.792 90.4701 51.6987C90.5634 51.6055 90.6158 51.479 90.616 51.3471V0.829071C90.6158 0.697185 90.5634 0.570743 90.4701 0.477485C90.3768 0.384228 90.2504 0.331772 90.1185 0.331628H4.66892Z"
        fill="#E6E6E6"/>
      <path
        d="M39.1581 4.03525C38.9857 4.03814 38.8213 4.10866 38.7004 4.23161C38.5795 4.35456 38.5117 4.5201 38.5117 4.69254C38.5117 4.86498 38.5795 5.03052 38.7004 5.15347C38.8213 5.27642 38.9857 5.34694 39.1581 5.34983H55.6385C55.8124 5.3524 55.9803 5.28589 56.1053 5.16488C56.2304 5.04387 56.3023 4.87824 56.3054 4.70427C56.3085 4.53031 56.2426 4.3622 56.1219 4.2368C56.0013 4.1114 55.8359 4.03893 55.662 4.03528L55.66 4.03525C55.6528 4.03513 55.6456 4.03513 55.6385 4.03525H39.1581Z"
        fill="#3F3D56"/>
      <path
        d="M59.3745 3.85233C59.3503 3.87659 59.3366 3.90948 59.3366 3.94378C59.3366 3.97809 59.3503 4.01098 59.3745 4.03524L59.3745 4.03525L59.9298 4.59049H58.5606C58.5264 4.5906 58.4936 4.60427 58.4694 4.62851C58.4452 4.65276 58.4316 4.6856 58.4316 4.71984C58.4316 4.75407 58.4452 4.78691 58.4694 4.81116C58.4936 4.8354 58.5264 4.84908 58.5606 4.84918H59.9298L59.3745 5.40442C59.3625 5.41643 59.353 5.43069 59.3465 5.44638C59.34 5.46207 59.3366 5.47889 59.3366 5.49588C59.3366 5.51286 59.34 5.52968 59.3465 5.54537C59.353 5.56107 59.3625 5.57533 59.3745 5.58734C59.3865 5.59935 59.4008 5.60887 59.4165 5.61537C59.4322 5.62188 59.449 5.62522 59.466 5.62522C59.483 5.62522 59.4998 5.62188 59.5155 5.61538C59.5312 5.60888 59.5454 5.59935 59.5574 5.58734L60.3335 4.8113C60.3577 4.78704 60.3714 4.75414 60.3714 4.71984C60.3714 4.68553 60.3577 4.65263 60.3335 4.62838L59.5574 3.85233C59.5454 3.84032 59.5312 3.8308 59.5155 3.8243C59.4998 3.8178 59.483 3.81445 59.466 3.81445C59.449 3.81445 59.4322 3.8178 59.4165 3.8243C59.4008 3.8308 59.3865 3.84032 59.3745 3.85233L59.3745 3.85233Z"
        fill="#E6E6E6"/>
      <path
        d="M35.4129 3.85234C35.4371 3.87659 35.4507 3.90949 35.4507 3.94379C35.4507 3.97809 35.4371 4.01099 35.4129 4.03524L35.4129 4.03526L34.8576 4.5905H36.2268C36.2438 4.59043 36.2607 4.59372 36.2764 4.60018C36.2921 4.60664 36.3064 4.61615 36.3185 4.62815C36.3306 4.64015 36.3401 4.65441 36.3467 4.67012C36.3532 4.68583 36.3566 4.70267 36.3566 4.71969C36.3566 4.7367 36.3533 4.75355 36.3468 4.76928C36.3403 4.785 36.3308 4.79928 36.3187 4.81131C36.3067 4.82334 36.2924 4.83288 36.2767 4.83938C36.261 4.84588 36.2441 4.84921 36.2271 4.84918H34.8576L35.4129 5.40442C35.4249 5.41643 35.4344 5.43069 35.4409 5.44638C35.4474 5.46208 35.4507 5.4789 35.4507 5.49588C35.4507 5.51287 35.4474 5.52969 35.4409 5.54538C35.4344 5.56107 35.4249 5.57533 35.4129 5.58734C35.4008 5.59935 35.3866 5.60888 35.3709 5.61538C35.3552 5.62188 35.3384 5.62523 35.3214 5.62523C35.3044 5.62523 35.2876 5.62188 35.2719 5.61538C35.2562 5.60888 35.2419 5.59935 35.2299 5.58734L34.4539 4.8113C34.4296 4.78704 34.416 4.75414 34.416 4.71984C34.416 4.68554 34.4296 4.65264 34.4539 4.62838L35.2299 3.85234C35.2542 3.82808 35.2871 3.81445 35.3214 3.81445C35.3557 3.81445 35.3886 3.82808 35.4128 3.85233L35.4129 3.85234Z"
        fill="#E6E6E6"/>
      <path
        d="M3.79053 54.958C3.04122 54.958 2.30874 54.7358 1.68571 54.3195C1.06268 53.9032 0.577092 53.3115 0.290343 52.6193C0.00359523 51.927 -0.071432 51.1652 0.0747512 50.4303C0.220934 49.6954 0.581763 49.0203 1.11161 48.4905C1.64145 47.9607 2.31651 47.5998 3.05142 47.4537C3.78633 47.3075 4.54809 47.3825 5.24036 47.6692C5.93263 47.956 6.52433 48.4416 6.94062 49.0646C7.35691 49.6876 7.57911 50.4201 7.57911 51.1694C7.57911 52.1742 7.17996 53.1379 6.46946 53.8484C5.75896 54.5589 4.79533 54.958 3.79053 54.958Z"
        fill="#F7B80B"/>
      <path
        d="M5.28535 50.7957H4.1642V49.6745C4.1642 49.5754 4.12483 49.4803 4.05474 49.4102C3.98465 49.3402 3.8896 49.3008 3.79048 49.3008C3.69136 49.3008 3.59631 49.3402 3.52622 49.4102C3.45613 49.4803 3.41676 49.5754 3.41676 49.6745V50.7957H2.29559C2.19648 50.7957 2.10142 50.835 2.03133 50.9051C1.96125 50.9752 1.92188 51.0703 1.92188 51.1694C1.92188 51.2685 1.96125 51.3636 2.03133 51.4336C2.10142 51.5037 2.19648 51.5431 2.29559 51.5431H3.41675V52.6643C3.41675 52.7634 3.45613 52.8584 3.52622 52.9285C3.5963 52.9986 3.69136 53.038 3.79048 53.038C3.88959 53.038 3.98465 52.9986 4.05474 52.9285C4.12483 52.8584 4.1642 52.7634 4.1642 52.6643V51.5431H5.28535C5.38447 51.5431 5.47952 51.5037 5.54961 51.4336C5.61969 51.3636 5.65907 51.2685 5.65907 51.1694C5.65907 51.0703 5.61969 50.9752 5.54961 50.9051C5.47952 50.835 5.38447 50.7957 5.28535 50.7957Z"
        fill="white"/>
      <path d="M22.1359 10.7637H10.75V18.5017H22.1359V10.7637Z" fill="#F1F1F1"/>
      <path d="M37.6105 10.7637H26.2246V18.5017H37.6105V10.7637Z" fill="#F1F1F1"/>
      <path d="M53.0871 10.7637H41.7012V18.5017H53.0871V10.7637Z" fill="#F1F1F1"/>
      <path d="M68.5636 10.7637H57.1777V18.5017H68.5636V10.7637Z" fill="#F1F1F1"/>
      <path d="M84.0383 10.7637H72.6523V18.5017H84.0383V10.7637Z" fill="#F1F1F1"/>
      <path d="M22.1769 24.3613H10.791V32.0993H22.1769V24.3613Z" fill="#F1F1F1"/>
      <path d="M37.6535 24.3613H26.2676V32.0993H37.6535V24.3613Z" fill="#F1F1F1"/>
      <path d="M53.1281 24.3613H41.7422V32.0993H53.1281V24.3613Z" fill="#F1F1F1"/>
      <path d="M68.6047 24.3613H57.2188V32.0993H68.6047V24.3613Z" fill="#F1F1F1"/>
      <path d="M84.0812 24.3613H72.6953V32.0993H84.0812V24.3613Z" fill="#F1F1F1"/>
      <path d="M22.2179 37.957H10.832V45.695H22.2179V37.957Z" fill="#F1F1F1"/>
      <path d="M37.6945 37.957H26.3086V45.695H37.6945V37.957Z" fill="#F1F1F1"/>
      <path d="M53.1711 37.957H41.7852V45.695H53.1711V37.957Z" fill="#F1F1F1"/>
      <path d="M68.6476 37.957H57.2617V45.695H68.6476V37.957Z" fill="#F1F1F1"/>
      <path d="M84.1222 37.957H72.7363V45.695H84.1222V37.957Z" fill="#F1F1F1"/>
      <path
        d="M44.064 14.6339C44.8577 14.6339 45.5011 13.9905 45.5011 13.1968C45.5011 12.4032 44.8577 11.7598 44.064 11.7598C43.2704 11.7598 42.627 12.4032 42.627 13.1968C42.627 13.9905 43.2704 14.6339 44.064 14.6339Z"
        fill="#F7B80B"/>
      <path
        d="M28.3667 28.2296C29.1604 28.2296 29.8038 27.5862 29.8038 26.7925C29.8038 25.9989 29.1604 25.3555 28.3667 25.3555C27.5731 25.3555 26.9297 25.9989 26.9297 26.7925C26.9297 27.5862 27.5731 28.2296 28.3667 28.2296Z"
        fill="#F7B80B"/>
      <path
        d="M44.064 41.8272C44.8577 41.8272 45.5011 41.1838 45.5011 40.3902C45.5011 39.5965 44.8577 38.9531 44.064 38.9531C43.2704 38.9531 42.627 39.5965 42.627 40.3902C42.627 41.1838 43.2704 41.8272 44.064 41.8272Z"
        fill="#F7B80B"/>
      <path
        d="M59.8726 28.2296C60.6663 28.2296 61.3097 27.5862 61.3097 26.7925C61.3097 25.9989 60.6663 25.3555 59.8726 25.3555C59.0789 25.3555 58.4355 25.9989 58.4355 26.7925C58.4355 27.5862 59.0789 28.2296 59.8726 28.2296Z"
        fill="#3F3D56"/>
      <path
        d="M75.2378 28.2296C76.0315 28.2296 76.6749 27.5862 76.6749 26.7925C76.6749 25.9989 76.0315 25.3555 75.2378 25.3555C74.4442 25.3555 73.8008 25.9989 73.8008 26.7925C73.8008 27.5862 74.4442 28.2296 75.2378 28.2296Z"
        fill="#3F3D56"/>
      <path
        d="M28.3667 41.8272C29.1604 41.8272 29.8038 41.1838 29.8038 40.3902C29.8038 39.5965 29.1604 38.9531 28.3667 38.9531C27.5731 38.9531 26.9297 39.5965 26.9297 40.3902C26.9297 41.1838 27.5731 41.8272 28.3667 41.8272Z"
        fill="#3F3D56"/>
    </g>
    <g clipPath="url(#clip1_1506_78877)">
      <path
        d="M184.624 52.1762H99.1748C98.955 52.176 98.7442 52.0885 98.5888 51.9331C98.4334 51.7777 98.3459 51.567 98.3457 51.3471V0.829071C98.3459 0.609261 98.4334 0.398523 98.5888 0.243094C98.7442 0.0876651 98.955 0.000239954 99.1748 0H184.624C184.844 0.000239955 185.055 0.0876655 185.21 0.243095C185.366 0.398524 185.453 0.609262 185.453 0.829071V51.3471C185.453 51.567 185.366 51.7777 185.21 51.9331C185.055 52.0885 184.844 52.176 184.624 52.1762ZM99.1748 0.331628C99.0429 0.331772 98.9164 0.384227 98.8232 0.477485C98.7299 0.570742 98.6775 0.697185 98.6773 0.829071V51.3471C98.6775 51.479 98.7299 51.6055 98.8232 51.6987C98.9164 51.792 99.0429 51.8444 99.1748 51.8446H184.624C184.756 51.8444 184.883 51.792 184.976 51.6987C185.069 51.6055 185.122 51.479 185.122 51.3471V0.829071C185.122 0.697185 185.069 0.570743 184.976 0.477485C184.883 0.384228 184.756 0.331772 184.624 0.331628H99.1748Z"
        fill="#E6E6E6"/>
      <path
        d="M133.664 4.03525C133.492 4.03814 133.327 4.10866 133.206 4.23161C133.085 4.35456 133.018 4.5201 133.018 4.69254C133.018 4.86498 133.085 5.03052 133.206 5.15347C133.327 5.27642 133.492 5.34694 133.664 5.34983H150.144C150.318 5.3524 150.486 5.28589 150.611 5.16488C150.736 5.04387 150.808 4.87824 150.811 4.70427C150.814 4.53031 150.748 4.3622 150.628 4.2368C150.507 4.1114 150.342 4.03893 150.168 4.03528L150.166 4.03525C150.159 4.03513 150.152 4.03513 150.144 4.03525H133.664Z"
        fill="#3F3D56"/>
      <path
        d="M153.88 3.85233C153.856 3.87659 153.842 3.90948 153.842 3.94378C153.842 3.97809 153.856 4.01098 153.88 4.03524L153.88 4.03525L154.436 4.59049H153.066C153.032 4.5906 152.999 4.60427 152.975 4.62851C152.951 4.65276 152.938 4.6856 152.938 4.71984C152.938 4.75407 152.951 4.78691 152.975 4.81116C152.999 4.8354 153.032 4.84908 153.066 4.84918H154.436L153.88 5.40442C153.868 5.41643 153.859 5.43069 153.852 5.44638C153.846 5.46207 153.842 5.47889 153.842 5.49588C153.842 5.51286 153.846 5.52968 153.852 5.54537C153.859 5.56107 153.868 5.57533 153.88 5.58734C153.892 5.59935 153.907 5.60887 153.922 5.61537C153.938 5.62188 153.955 5.62522 153.972 5.62522C153.989 5.62522 154.006 5.62188 154.021 5.61538C154.037 5.60888 154.051 5.59935 154.063 5.58734L154.839 4.8113C154.864 4.78704 154.877 4.75414 154.877 4.71984C154.877 4.68553 154.864 4.65263 154.839 4.62838L154.063 3.85233C154.051 3.84032 154.037 3.8308 154.021 3.8243C154.006 3.8178 153.989 3.81445 153.972 3.81445C153.955 3.81445 153.938 3.8178 153.922 3.8243C153.907 3.8308 153.892 3.84032 153.88 3.85233L153.88 3.85233Z"
        fill="#E6E6E6"/>
      <path
        d="M129.919 3.85234C129.943 3.87659 129.957 3.90949 129.957 3.94379C129.957 3.97809 129.943 4.01099 129.919 4.03524L129.919 4.03526L129.363 4.5905H130.733C130.75 4.59043 130.767 4.59372 130.782 4.60018C130.798 4.60664 130.812 4.61615 130.824 4.62815C130.836 4.64015 130.846 4.65441 130.853 4.67012C130.859 4.68583 130.862 4.70267 130.862 4.71969C130.863 4.7367 130.859 4.75355 130.853 4.76928C130.846 4.785 130.837 4.79928 130.825 4.81131C130.813 4.82334 130.798 4.83288 130.783 4.83938C130.767 4.84588 130.75 4.84921 130.733 4.84918H129.363L129.919 5.40442C129.931 5.41643 129.94 5.43069 129.947 5.44638C129.953 5.46208 129.957 5.4789 129.957 5.49588C129.957 5.51287 129.953 5.52969 129.947 5.54538C129.94 5.56107 129.931 5.57533 129.919 5.58734C129.907 5.59935 129.892 5.60888 129.877 5.61538C129.861 5.62188 129.844 5.62523 129.827 5.62523C129.81 5.62523 129.793 5.62188 129.778 5.61538C129.762 5.60888 129.748 5.59935 129.736 5.58734L128.96 4.8113C128.936 4.78704 128.922 4.75414 128.922 4.71984C128.922 4.68554 128.936 4.65264 128.96 4.62838L129.736 3.85234C129.76 3.82808 129.793 3.81445 129.827 3.81445C129.862 3.81445 129.894 3.82808 129.919 3.85233L129.919 3.85234Z"
        fill="#E6E6E6"/>
      <path
        d="M98.2964 54.958C97.5471 54.958 96.8146 54.7358 96.1916 54.3195C95.5685 53.9032 95.083 53.3115 94.7962 52.6193C94.5095 51.927 94.4344 51.1652 94.5806 50.4303C94.7268 49.6954 95.0876 49.0203 95.6175 48.4905C96.1473 47.9607 96.8224 47.5998 97.5573 47.4537C98.2922 47.3075 99.0539 47.3825 99.7462 47.6692C100.438 47.956 101.03 48.4416 101.446 49.0646C101.863 49.6876 102.085 50.4201 102.085 51.1694C102.085 52.1742 101.686 53.1379 100.975 53.8484C100.265 54.5589 99.3012 54.958 98.2964 54.958Z"
        fill="#F7B80B"/>
      <path
        d="M99.7912 50.7957H98.6701V49.6745C98.6701 49.5754 98.6307 49.4803 98.5606 49.4102C98.4905 49.3402 98.3955 49.3008 98.2963 49.3008C98.1972 49.3008 98.1022 49.3402 98.0321 49.4102C97.962 49.4803 97.9226 49.5754 97.9226 49.6745V50.7957H96.8015C96.7023 50.7957 96.6073 50.835 96.5372 50.9051C96.4671 50.9752 96.4277 51.0703 96.4277 51.1694C96.4277 51.2685 96.4671 51.3636 96.5372 51.4336C96.6073 51.5037 96.7023 51.5431 96.8015 51.5431H97.9226V52.6643C97.9226 52.7634 97.962 52.8584 98.0321 52.9285C98.1022 52.9986 98.1972 53.038 98.2963 53.038C98.3955 53.038 98.4905 52.9986 98.5606 52.9285C98.6307 52.8584 98.6701 52.7634 98.6701 52.6643V51.5431H99.7912C99.8903 51.5431 99.9854 51.5037 100.055 51.4336C100.126 51.3636 100.165 51.2685 100.165 51.1694C100.165 51.0703 100.126 50.9752 100.055 50.9051C99.9854 50.835 99.8903 50.7957 99.7912 50.7957Z"
        fill="white"/>
      <path d="M116.642 10.7637H105.256V18.5017H116.642V10.7637Z" fill="#F1F1F1"/>
      <path d="M132.116 10.7637H120.73V18.5017H132.116V10.7637Z" fill="#F1F1F1"/>
      <path d="M147.593 10.7637H136.207V18.5017H147.593V10.7637Z" fill="#F1F1F1"/>
      <path d="M163.07 10.7637H151.684V18.5017H163.07V10.7637Z" fill="#F1F1F1"/>
      <path d="M178.544 10.7637H167.158V18.5017H178.544V10.7637Z" fill="#F1F1F1"/>
      <path d="M116.683 24.3613H105.297V32.0993H116.683V24.3613Z" fill="#F1F1F1"/>
      <path d="M132.159 24.3613H120.773V32.0993H132.159V24.3613Z" fill="#F1F1F1"/>
      <path d="M147.634 24.3613H136.248V32.0993H147.634V24.3613Z" fill="#F1F1F1"/>
      <path d="M163.111 24.3613H151.725V32.0993H163.111V24.3613Z" fill="#F1F1F1"/>
      <path d="M178.587 24.3613H167.201V32.0993H178.587V24.3613Z" fill="#F1F1F1"/>
      <path d="M116.724 37.957H105.338V45.695H116.724V37.957Z" fill="#F1F1F1"/>
      <path d="M132.2 37.957H120.814V45.695H132.2V37.957Z" fill="#F1F1F1"/>
      <path d="M147.677 37.957H136.291V45.695H147.677V37.957Z" fill="#F1F1F1"/>
      <path d="M163.153 37.957H151.768V45.695H163.153V37.957Z" fill="#F1F1F1"/>
      <path d="M178.628 37.957H167.242V45.695H178.628V37.957Z" fill="#F1F1F1"/>
      <path
        d="M138.57 14.6339C139.364 14.6339 140.007 13.9905 140.007 13.1968C140.007 12.4032 139.364 11.7598 138.57 11.7598C137.776 11.7598 137.133 12.4032 137.133 13.1968C137.133 13.9905 137.776 14.6339 138.57 14.6339Z"
        fill="#F7B80B"/>
      <path
        d="M122.873 28.2296C123.666 28.2296 124.31 27.5862 124.31 26.7925C124.31 25.9989 123.666 25.3555 122.873 25.3555C122.079 25.3555 121.436 25.9989 121.436 26.7925C121.436 27.5862 122.079 28.2296 122.873 28.2296Z"
        fill="#F7B80B"/>
      <path
        d="M138.57 41.8272C139.364 41.8272 140.007 41.1838 140.007 40.3902C140.007 39.5965 139.364 38.9531 138.57 38.9531C137.776 38.9531 137.133 39.5965 137.133 40.3902C137.133 41.1838 137.776 41.8272 138.57 41.8272Z"
        fill="#F7B80B"/>
      <path
        d="M154.378 28.2296C155.172 28.2296 155.816 27.5862 155.816 26.7925C155.816 25.9989 155.172 25.3555 154.378 25.3555C153.585 25.3555 152.941 25.9989 152.941 26.7925C152.941 27.5862 153.585 28.2296 154.378 28.2296Z"
        fill="#3F3D56"/>
      <path
        d="M169.744 28.2296C170.537 28.2296 171.181 27.5862 171.181 26.7925C171.181 25.9989 170.537 25.3555 169.744 25.3555C168.95 25.3555 168.307 25.9989 168.307 26.7925C168.307 27.5862 168.95 28.2296 169.744 28.2296Z"
        fill="#3F3D56"/>
      <path
        d="M122.873 41.8272C123.666 41.8272 124.31 41.1838 124.31 40.3902C124.31 39.5965 123.666 38.9531 122.873 38.9531C122.079 38.9531 121.436 39.5965 121.436 40.3902C121.436 41.1838 122.079 41.8272 122.873 41.8272Z"
        fill="#3F3D56"/>
    </g>
    <g clipPath="url(#clip2_1506_78877)">
      <path
        d="M278.919 52.1762H193.47C193.25 52.176 193.039 52.0885 192.884 51.9331C192.728 51.7777 192.641 51.567 192.641 51.3471V0.829071C192.641 0.609261 192.728 0.398523 192.884 0.243094C193.039 0.0876651 193.25 0.000239954 193.47 0H278.919C279.139 0.000239955 279.35 0.0876655 279.505 0.243095C279.661 0.398524 279.748 0.609262 279.748 0.829071V51.3471C279.748 51.567 279.661 51.7777 279.505 51.9331C279.35 52.0885 279.139 52.176 278.919 52.1762ZM193.47 0.331628C193.338 0.331772 193.211 0.384227 193.118 0.477485C193.025 0.570742 192.972 0.697185 192.972 0.829071V51.3471C192.972 51.479 193.025 51.6055 193.118 51.6987C193.211 51.792 193.338 51.8444 193.47 51.8446H278.919C279.051 51.8444 279.178 51.792 279.271 51.6987C279.364 51.6055 279.417 51.479 279.417 51.3471V0.829071C279.417 0.697185 279.364 0.570743 279.271 0.477485C279.178 0.384228 279.051 0.331772 278.919 0.331628H193.47Z"
        fill="#E6E6E6"/>
      <path
        d="M227.959 4.03525C227.786 4.03814 227.622 4.10866 227.501 4.23161C227.38 4.35456 227.312 4.5201 227.312 4.69254C227.312 4.86498 227.38 5.03052 227.501 5.15347C227.622 5.27642 227.786 5.34694 227.959 5.34983H244.439C244.613 5.3524 244.781 5.28589 244.906 5.16488C245.031 5.04387 245.103 4.87824 245.106 4.70427C245.109 4.53031 245.043 4.3622 244.923 4.2368C244.802 4.1114 244.637 4.03893 244.463 4.03528L244.461 4.03525C244.454 4.03513 244.446 4.03513 244.439 4.03525H227.959Z"
        fill="#3F3D56"/>
      <path
        d="M248.175 3.85233C248.151 3.87659 248.137 3.90948 248.137 3.94378C248.137 3.97809 248.151 4.01098 248.175 4.03524L248.175 4.03525L248.731 4.59049H247.361C247.327 4.5906 247.294 4.60427 247.27 4.62851C247.246 4.65276 247.232 4.6856 247.232 4.71984C247.232 4.75407 247.246 4.78691 247.27 4.81116C247.294 4.8354 247.327 4.84908 247.361 4.84918H248.731L248.175 5.40442C248.163 5.41643 248.154 5.43069 248.147 5.44638C248.141 5.46207 248.137 5.47889 248.137 5.49588C248.137 5.51286 248.141 5.52968 248.147 5.54537C248.154 5.56107 248.163 5.57533 248.175 5.58734C248.187 5.59935 248.202 5.60887 248.217 5.61537C248.233 5.62188 248.25 5.62522 248.267 5.62522C248.284 5.62522 248.301 5.62188 248.316 5.61538C248.332 5.60888 248.346 5.59935 248.358 5.58734L249.134 4.8113C249.159 4.78704 249.172 4.75414 249.172 4.71984C249.172 4.68553 249.159 4.65263 249.134 4.62838L248.358 3.85233C248.346 3.84032 248.332 3.8308 248.316 3.8243C248.301 3.8178 248.284 3.81445 248.267 3.81445C248.25 3.81445 248.233 3.8178 248.217 3.8243C248.202 3.8308 248.187 3.84032 248.175 3.85233L248.175 3.85233Z"
        fill="#E6E6E6"/>
      <path
        d="M224.214 3.85234C224.238 3.87659 224.252 3.90949 224.252 3.94379C224.252 3.97809 224.238 4.01099 224.214 4.03524L224.214 4.03526L223.658 4.5905H225.028C225.045 4.59043 225.061 4.59372 225.077 4.60018C225.093 4.60664 225.107 4.61615 225.119 4.62815C225.131 4.64015 225.141 4.65441 225.147 4.67012C225.154 4.68583 225.157 4.70267 225.157 4.71969C225.157 4.7367 225.154 4.75355 225.148 4.76928C225.141 4.785 225.132 4.79928 225.12 4.81131C225.107 4.82334 225.093 4.83288 225.077 4.83938C225.062 4.84588 225.045 4.84921 225.028 4.84918H223.658L224.214 5.40442C224.226 5.41643 224.235 5.43069 224.242 5.44638C224.248 5.46208 224.252 5.4789 224.252 5.49588C224.252 5.51287 224.248 5.52969 224.242 5.54538C224.235 5.56107 224.226 5.57533 224.214 5.58734C224.202 5.59935 224.187 5.60888 224.172 5.61538C224.156 5.62188 224.139 5.62523 224.122 5.62523C224.105 5.62523 224.088 5.62188 224.073 5.61538C224.057 5.60888 224.043 5.59935 224.031 5.58734L223.255 4.8113C223.23 4.78704 223.217 4.75414 223.217 4.71984C223.217 4.68554 223.23 4.65264 223.255 4.62838L224.031 3.85234C224.055 3.82808 224.088 3.81445 224.122 3.81445C224.156 3.81445 224.189 3.82808 224.214 3.85233L224.214 3.85234Z"
        fill="#E6E6E6"/>
      <path
        d="M192.591 54.958C191.842 54.958 191.11 54.7358 190.486 54.3195C189.863 53.9032 189.378 53.3115 189.091 52.6193C188.804 51.927 188.729 51.1652 188.876 50.4303C189.022 49.6954 189.383 49.0203 189.912 48.4905C190.442 47.9607 191.117 47.5998 191.852 47.4537C192.587 47.3075 193.349 47.3825 194.041 47.6692C194.733 47.956 195.325 48.4416 195.741 49.0646C196.158 49.6876 196.38 50.4201 196.38 51.1694C196.38 52.1742 195.981 53.1379 195.27 53.8484C194.56 54.5589 193.596 54.958 192.591 54.958Z"
        fill="#F7B80B"/>
      <path
        d="M194.086 50.7957H192.965V49.6745C192.965 49.5754 192.926 49.4803 192.856 49.4102C192.785 49.3402 192.69 49.3008 192.591 49.3008C192.492 49.3008 192.397 49.3402 192.327 49.4102C192.257 49.4803 192.218 49.5754 192.218 49.6745V50.7957H191.096C190.997 50.7957 190.902 50.835 190.832 50.9051C190.762 50.9752 190.723 51.0703 190.723 51.1694C190.723 51.2685 190.762 51.3636 190.832 51.4336C190.902 51.5037 190.997 51.5431 191.096 51.5431H192.218V52.6643C192.218 52.7634 192.257 52.8584 192.327 52.9285C192.397 52.9986 192.492 53.038 192.591 53.038C192.69 53.038 192.785 52.9986 192.856 52.9285C192.926 52.8584 192.965 52.7634 192.965 52.6643V51.5431H194.086C194.185 51.5431 194.28 51.5037 194.35 51.4336C194.42 51.3636 194.46 51.2685 194.46 51.1694C194.46 51.0703 194.42 50.9752 194.35 50.9051C194.28 50.835 194.185 50.7957 194.086 50.7957Z"
        fill="white"/>
      <path d="M210.937 10.7637H199.551V18.5017H210.937V10.7637Z" fill="#F1F1F1"/>
      <path d="M226.411 10.7637H215.025V18.5017H226.411V10.7637Z" fill="#F1F1F1"/>
      <path d="M241.888 10.7637H230.502V18.5017H241.888V10.7637Z" fill="#F1F1F1"/>
      <path d="M257.364 10.7637H245.979V18.5017H257.364V10.7637Z" fill="#F1F1F1"/>
      <path d="M272.839 10.7637H261.453V18.5017H272.839V10.7637Z" fill="#F1F1F1"/>
      <path d="M210.978 24.3613H199.592V32.0993H210.978V24.3613Z" fill="#F1F1F1"/>
      <path d="M226.454 24.3613H215.068V32.0993H226.454V24.3613Z" fill="#F1F1F1"/>
      <path d="M241.929 24.3613H230.543V32.0993H241.929V24.3613Z" fill="#F1F1F1"/>
      <path d="M257.405 24.3613H246.02V32.0993H257.405V24.3613Z" fill="#F1F1F1"/>
      <path d="M272.882 24.3613H261.496V32.0993H272.882V24.3613Z" fill="#F1F1F1"/>
      <path d="M211.019 37.957H199.633V45.695H211.019V37.957Z" fill="#F1F1F1"/>
      <path d="M226.495 37.957H215.109V45.695H226.495V37.957Z" fill="#F1F1F1"/>
      <path d="M241.972 37.957H230.586V45.695H241.972V37.957Z" fill="#F1F1F1"/>
      <path d="M257.448 37.957H246.062V45.695H257.448V37.957Z" fill="#F1F1F1"/>
      <path d="M272.923 37.957H261.537V45.695H272.923V37.957Z" fill="#F1F1F1"/>
      <path
        d="M232.865 14.6339C233.658 14.6339 234.302 13.9905 234.302 13.1968C234.302 12.4032 233.658 11.7598 232.865 11.7598C232.071 11.7598 231.428 12.4032 231.428 13.1968C231.428 13.9905 232.071 14.6339 232.865 14.6339Z"
        fill="#F7B80B"/>
      <path
        d="M217.168 28.2296C217.961 28.2296 218.605 27.5862 218.605 26.7925C218.605 25.9989 217.961 25.3555 217.168 25.3555C216.374 25.3555 215.73 25.9989 215.73 26.7925C215.73 27.5862 216.374 28.2296 217.168 28.2296Z"
        fill="#F7B80B"/>
      <path
        d="M232.865 41.8272C233.658 41.8272 234.302 41.1838 234.302 40.3902C234.302 39.5965 233.658 38.9531 232.865 38.9531C232.071 38.9531 231.428 39.5965 231.428 40.3902C231.428 41.1838 232.071 41.8272 232.865 41.8272Z"
        fill="#F7B80B"/>
      <path
        d="M248.673 28.2296C249.467 28.2296 250.11 27.5862 250.11 26.7925C250.11 25.9989 249.467 25.3555 248.673 25.3555C247.88 25.3555 247.236 25.9989 247.236 26.7925C247.236 27.5862 247.88 28.2296 248.673 28.2296Z"
        fill="#3F3D56"/>
      <path
        d="M264.039 28.2296C264.832 28.2296 265.476 27.5862 265.476 26.7925C265.476 25.9989 264.832 25.3555 264.039 25.3555C263.245 25.3555 262.602 25.9989 262.602 26.7925C262.602 27.5862 263.245 28.2296 264.039 28.2296Z"
        fill="#3F3D56"/>
      <path
        d="M217.168 41.8272C217.961 41.8272 218.605 41.1838 218.605 40.3902C218.605 39.5965 217.961 38.9531 217.168 38.9531C216.374 38.9531 215.73 39.5965 215.73 40.3902C215.73 41.1838 216.374 41.8272 217.168 41.8272Z"
        fill="#3F3D56"/>
    </g>
    <g clipPath="url(#clip3_1506_78877)">
      <path
        d="M164.474 42.3995C164.583 42.047 164.613 41.675 164.562 41.3097C164.512 40.9444 164.382 40.5945 164.182 40.2849C163.982 39.9753 163.717 39.7133 163.405 39.5175C163.093 39.3217 162.743 39.1968 162.378 39.1517L150.352 25.0625L147.547 28.0152L159.526 41.5238C159.49 42.1439 159.683 42.7554 160.068 43.2424C160.453 43.7293 161.002 44.0579 161.612 44.1659C162.222 44.2738 162.85 44.1536 163.378 43.8281C163.906 43.5025 164.296 42.9942 164.474 42.3995H164.474Z"
        fill="#A0616A"/>
      <path
        d="M154.265 27.2636C154.277 27.1123 154.257 26.96 154.205 26.8173C154.153 26.6745 154.071 26.5447 153.965 26.4366L150.25 22.6595C150.024 22.3476 149.739 22.0834 149.411 21.882C149.083 21.6805 148.719 21.5458 148.339 21.4854C147.959 21.4251 147.572 21.4403 147.198 21.5302C146.824 21.6202 146.471 21.7831 146.16 22.0096C145.849 22.2362 145.586 22.5219 145.385 22.8506C145.184 23.1792 145.049 23.5443 144.989 23.9251C144.929 24.3058 144.944 24.6947 145.034 25.0695C145.124 25.4443 145.286 25.7978 145.512 26.1097L147.983 30.8202C148.053 30.9547 148.152 31.0723 148.272 31.1651C148.392 31.2579 148.53 31.3236 148.678 31.358C148.825 31.3923 148.979 31.3943 149.127 31.3639C149.275 31.3334 149.416 31.2713 149.538 31.1817L153.837 28.0317C153.959 27.9421 154.061 27.827 154.135 27.6945C154.208 27.5619 154.253 27.415 154.265 27.2636Z"
        fill="#F7B80B"/>
      <path
        d="M145.541 18.6178C148.719 18.6178 151.295 16.035 151.295 12.849C151.295 9.66289 148.719 7.08008 145.541 7.08008C142.363 7.08008 139.787 9.66289 139.787 12.849C139.787 16.035 142.363 18.6178 145.541 18.6178Z"
        fill="#A0616A"/>
      <path
        d="M148.668 38.6643C148.782 38.579 148.878 38.4712 148.949 38.3474C149.02 38.2236 149.065 38.0865 149.081 37.9445C149.479 34.6106 150.058 31.3009 150.816 28.0302C151.151 26.5605 150.943 25.0187 150.23 23.6914C149.517 22.3641 148.347 21.3415 146.938 20.8137C145.53 20.2859 143.978 20.2888 142.571 20.8218C141.165 21.3549 139.999 22.3818 139.291 23.7118C137.028 27.9698 136.855 33.8156 136.886 36.1313C136.889 36.3785 136.979 36.6166 137.14 36.804C137.301 36.9915 137.522 37.1165 137.765 37.1571L147.861 38.8609C147.92 38.8709 147.979 38.8759 148.039 38.8759C148.266 38.8758 148.487 38.8016 148.668 38.6644L148.668 38.6643Z"
        fill="#2F2E41"/>
      <path
        d="M126.873 19.8809C126.901 20.0076 126.92 20.1365 126.929 20.2662L136.992 26.087L139.438 24.6751L142.046 28.0979L137.958 31.0192C137.628 31.2552 137.23 31.3774 136.824 31.3675C136.419 31.3576 136.028 31.2161 135.709 30.9643L125.332 22.7562C124.864 22.93 124.354 22.9568 123.87 22.8329C123.386 22.709 122.952 22.4404 122.624 22.0627C122.296 21.685 122.09 21.2163 122.035 20.7187C121.979 20.2212 122.076 19.7184 122.312 19.2774C122.549 18.8363 122.913 18.4778 123.358 18.2496C123.802 18.0213 124.305 17.9342 124.8 17.9997C125.295 18.0652 125.759 18.2803 126.129 18.6164C126.499 18.9524 126.759 19.3935 126.873 19.8809Z"
        fill="#A0616A"/>
      <path
        d="M137.329 26.3619L140.581 30.588C140.674 30.7082 140.791 30.807 140.925 30.8777C141.059 30.9484 141.207 30.9893 141.358 30.9976C141.51 31.0059 141.661 30.9815 141.802 30.9259C141.943 30.8703 142.07 30.7849 142.175 30.6755L145.857 26.842C146.161 26.6056 146.415 26.3116 146.606 25.9767C146.796 25.6418 146.919 25.2726 146.967 24.8901C147.015 24.5077 146.987 24.1195 146.886 23.7477C146.784 23.3759 146.611 23.0278 146.375 22.7233C146.139 22.4188 145.846 22.1639 145.512 21.973C145.178 21.7821 144.81 21.6591 144.428 21.6109C144.047 21.5627 143.659 21.5904 143.289 21.6922C142.918 21.794 142.571 21.9681 142.267 22.2045L137.649 24.7938C137.517 24.868 137.402 24.9698 137.313 25.0924C137.223 25.2151 137.161 25.3556 137.131 25.5044C137.1 25.6532 137.102 25.8068 137.137 25.9548C137.171 26.1028 137.236 26.2416 137.329 26.3619Z"
        fill="#F7B80B"/>
      <path d="M132.257 90.6147H129.385L128.709 79.5436L132.258 79.5078L132.257 90.6147Z" fill="#A0616A"/>
      <path
        d="M132.991 89.6758H127.335C126.862 89.6758 126.393 89.7693 125.956 89.9509C125.518 90.1325 125.121 90.3987 124.786 90.7343C124.451 91.0699 124.186 91.4683 124.005 91.9068C123.824 92.3453 123.73 92.8153 123.73 93.2899V93.4072H132.991L132.991 89.6758Z"
        fill="#2F2E41"/>
      <path d="M159.668 90.6148H156.796L155.43 79.5078H159.669L159.668 90.6148Z" fill="#A0616A"/>
      <path
        d="M160.399 89.6758H154.743C154.27 89.6758 153.801 89.7693 153.364 89.9509C152.926 90.1325 152.529 90.3987 152.194 90.7343C151.86 91.0699 151.594 91.4683 151.413 91.9068C151.232 92.3453 151.139 92.8153 151.139 93.2899V93.4072H160.399L160.399 89.6758Z"
        fill="#2F2E41"/>
      <path
        d="M156.536 84.8468L160.101 84.3809C160.25 84.3614 160.393 84.3103 160.52 84.2308C160.648 84.1514 160.757 84.0455 160.841 83.9203C160.924 83.7951 160.98 83.6535 161.004 83.5049C161.029 83.3563 161.021 83.2042 160.982 83.0587L155.783 57.4423C155.266 54.9554 154.909 52.4379 154.712 49.9052C154.561 47.8446 153.959 44.9305 151.955 42.751C151.14 41.857 150.379 40.9146 149.676 39.929L148.103 37.7438L137.619 36.0723L137.612 36.2023C137.159 44.5549 134.995 52.7245 131.255 60.2019C129.573 63.5649 128.607 67.2421 128.42 70.9997L127.826 82.615C127.818 82.7621 127.841 82.9092 127.894 83.0468C127.947 83.1844 128.027 83.3095 128.131 83.414C128.234 83.5192 128.358 83.6012 128.495 83.6547C128.633 83.7083 128.779 83.7321 128.926 83.7246L132.334 83.5693C132.565 83.5584 132.786 83.4719 132.963 83.3229C133.14 83.174 133.264 82.9709 133.315 82.7447L136.16 70.019C136.185 69.9053 136.234 69.7982 136.304 69.7047L145.232 57.7696C145.295 57.6845 145.381 57.6183 145.479 57.5782C145.578 57.5381 145.685 57.5254 145.79 57.5417C145.895 57.558 145.993 57.6025 146.075 57.6705C146.156 57.7386 146.218 57.8275 146.253 57.928L155.406 84.1468C155.479 84.3534 155.614 84.5323 155.792 84.659C155.971 84.7857 156.184 84.854 156.402 84.8546C156.447 84.8547 156.492 84.8517 156.537 84.8459L156.536 84.8468Z"
        fill="#2F2E41"/>
      <path
        d="M151.903 8.84536C151.446 7.45621 151.109 6.03032 150.895 4.58337C150.827 3.74102 151.043 2.83447 151.654 2.2533C152.8 1.1655 154.745 1.68366 155.943 2.71309C157.673 4.20081 158.537 6.48732 158.863 8.74958C159.189 11.0118 159.045 13.3123 159.124 15.5965C159.203 17.8808 159.528 20.2297 160.69 22.197C161.851 24.1642 164.007 25.6735 166.281 25.506C167.198 25.4563 168.07 25.0861 168.744 24.4595C169.075 24.144 169.325 23.7535 169.474 23.3209C169.624 22.8883 169.667 22.4261 169.601 21.9731C170.764 24.0593 170.174 26.887 168.441 28.5271C166.708 30.1672 164.042 30.6309 161.763 29.9305C159.484 29.2302 157.609 27.4745 156.488 25.3656C155.368 23.2568 154.958 20.82 155.017 18.4311C155.063 16.5966 155.374 14.7471 155.064 12.9382C154.755 11.1293 153.626 9.29317 151.846 8.86721L151.903 8.84536Z"
        fill="#2F2E41"/>
      <path
        d="M143.909 11.2226C143.605 12.2391 142.444 12.8661 141.4 12.6941C140.355 12.5222 139.496 11.6599 139.179 10.6475C138.892 9.6136 139.01 8.50887 139.508 7.5588C139.782 6.98938 140.165 6.47975 140.636 6.05908C141.107 5.63841 141.656 5.31495 142.252 5.10721C142.851 4.90813 143.489 4.85634 144.112 4.9562C144.736 5.05607 145.326 5.30466 145.833 5.68104C145.887 5.45266 146.001 5.24284 146.163 5.07319C146.325 4.90354 146.529 4.78019 146.754 4.71582C147.207 4.59333 147.686 4.60044 148.135 4.73633C149.431 5.10645 150.553 5.92517 151.303 7.04693C152.043 8.16522 152.533 9.43116 152.739 10.7575C153.092 12.7467 152.962 14.9202 151.848 16.6036C150.733 18.2871 148.454 19.2812 146.605 18.4787C146.49 17.8877 146.763 17.2924 147.079 16.7804C147.395 16.2684 147.769 15.7694 147.891 15.1789C148.012 14.5885 147.78 13.8677 147.211 13.6748C146.725 13.5101 146.208 13.7768 145.696 13.8095C145.406 13.8278 145.115 13.7691 144.855 13.6395C144.594 13.5099 144.372 13.3138 144.211 13.0709C144.049 12.828 143.955 12.5469 143.936 12.2557C143.917 11.9645 143.975 11.6735 144.104 11.4119L143.909 11.2226Z"
        fill="#2F2E41"/>
    </g>
    <g clipPath="url(#clip4_1506_78877)">
      <path d="M218.037 97.0929L215.36 97.0927L214.086 86.8203H218.038L218.037 97.0929Z" fill="#FFB8B8"/>
      <path
        d="M218.719 99.674L210.086 99.6737V99.565C210.086 98.6786 210.44 97.8285 211.07 97.2017C211.7 96.5749 212.555 96.2227 213.446 96.2227H213.447L218.719 96.2229L218.719 99.674Z"
        fill="#2F2E41"/>
      <path d="M205.846 97.0929L203.168 97.0927L201.895 86.8203H205.846L205.846 97.0929Z" fill="#FFB8B8"/>
      <path
        d="M206.53 99.674L197.896 99.6737V99.565C197.897 98.6786 198.251 97.8285 198.881 97.2017C199.511 96.5749 200.366 96.2227 201.257 96.2227H201.257L206.53 96.2229L206.53 99.674Z"
        fill="#2F2E41"/>
      <path
        d="M217.272 51.8665C217.241 51.5466 217.28 51.2237 217.387 50.9204C217.494 50.6172 217.667 50.341 217.893 50.1113C218.119 49.8817 218.393 49.7041 218.696 49.5912C218.998 49.4783 219.322 49.4327 219.644 49.4578L221.937 44.9805L225.013 45.7029L221.685 51.9804C221.607 52.516 221.334 53.0045 220.918 53.3533C220.502 53.7022 219.972 53.8871 219.428 53.873C218.884 53.859 218.364 53.647 217.967 53.2771C217.57 52.9073 217.323 52.4054 217.272 51.8665L217.272 51.8665Z"
        fill="#FFB8B8"/>
      <path
        d="M204.779 59.5324C204.985 59.2849 205.134 58.9954 205.216 58.6844C205.297 58.3733 205.31 58.0483 205.251 57.7321C205.193 57.4159 205.066 57.1163 204.878 56.8544C204.691 56.5925 204.448 56.3746 204.167 56.2161L204.783 51.2289L201.828 50.1172L201.057 57.1722C200.82 57.6597 200.772 58.2165 200.921 58.7371C201.069 59.2576 201.405 59.7059 201.864 59.9968C202.323 60.2877 202.873 60.4011 203.41 60.3155C203.947 60.2299 204.434 59.9513 204.779 59.5324Z"
        fill="#FFB8B8"/>
      <path
        d="M218.807 44.3047L222.036 54.2814L219.986 69.4722L218.571 95.032L212.913 94.5629V71.3481L212.254 58.2163L209.062 75.5689L206.656 94.4217L201.092 94.4668L203.248 54.6991L204.191 44.6158L218.807 44.3047Z"
        fill="#2F2E41"/>
      <path
        d="M214.209 23.3945L211.144 24.567L208.079 23.8635L206.547 46.7266C206.547 46.7266 215.741 50.9475 217.156 45.7886C218.57 40.6298 214.209 23.3945 214.209 23.3945Z"
        fill="#CCCCCC"/>
      <path
        d="M221.165 25.1514L215.978 24.6825L214.135 23.3438L212.678 36.6416C212.678 36.6416 214.799 50.2422 218.807 51.6492L221.872 46.7248C221.872 46.7248 221.4 42.504 219.279 40.8625L221.165 25.1514Z"
        fill="#2F2E41"/>
      <path
        d="M202.305 26.56L207.022 25.0039L208.081 23.8633C208.081 23.8633 210.792 51.6507 209.849 52.1197C208.906 52.5887 202.776 53.0577 202.776 53.0577L202.305 26.56Z"
        fill="#2F2E41"/>
      <path
        d="M219.278 27.0283L221.164 25.1523C221.164 25.1523 228.472 40.3944 228.001 41.3324C227.529 42.2704 223.993 49.0707 223.993 49.0707L220.928 45.5533L223.286 39.2219L218.807 33.5941L219.278 27.0283Z"
        fill="#2F2E41"/>
      <path
        d="M203.718 27.0276L202.303 26.5586C201.927 26.8218 201.622 27.1739 201.416 27.5832C201.211 27.9925 201.11 28.4462 201.124 28.9035C201.124 30.545 200.889 55.1668 200.889 55.1668H204.838L203.718 27.0276Z"
        fill="#2F2E41"/>
      <path
        d="M203.471 15.7277C203.475 14.4422 203.815 13.1799 204.457 12.0642C205.098 10.9484 206.021 10.0176 207.133 9.3626C209.495 7.97531 212.346 8.00045 214.953 9.43123C216.81 10.4508 218.322 13.0161 218.322 15.1498V21.3757H203.471V15.7277Z"
        fill="#2F2E41"/>
      <path
        d="M213.175 20.5677C215.774 19.1543 216.73 15.9125 215.309 13.3269C213.888 10.7413 210.628 9.79107 208.029 11.2045C205.43 12.6179 204.474 15.8597 205.895 18.4453C207.316 21.0309 210.575 21.9811 213.175 20.5677Z"
        fill="#FFB8B8"/>
      <path
        d="M205.008 15.9364C204.967 15.8897 204.872 15.7804 205.534 10.7163L205.555 10.6336H205.615C208.968 8.99248 212.371 8.95122 215.729 10.5104C215.832 10.5591 215.921 10.6338 215.987 10.727C216.052 10.8202 216.092 10.9288 216.103 11.0421C216.495 15.7999 216.4 15.9036 216.36 15.948C216.298 16.0151 216.224 16.0687 216.14 16.1054C216.057 16.1422 215.966 16.1612 215.875 16.1613H214.387L214.356 16.1054C214.101 15.6332 213.82 15.1748 213.517 14.732C213.377 15.1727 213.262 15.6211 213.174 16.0748L213.156 16.1613H205.503C205.409 16.1613 205.316 16.1412 205.231 16.1024C205.145 16.0636 205.069 16.007 205.008 15.9364Z"
        fill="#2F2E41"/>
    </g>
    <g clipPath="url(#clip5_1506_78877)">
      <path d="M251.553 97.0929L248.875 97.0927L247.602 86.8203H251.553L251.553 97.0929Z" fill="#FFB8B8"/>
      <path
        d="M252.235 99.674L243.602 99.6737V99.565C243.602 98.6786 243.956 97.8285 244.586 97.2017C245.216 96.5749 246.071 96.2227 246.962 96.2227H246.962L252.235 96.2229L252.235 99.674Z"
        fill="#2F2E41"/>
      <path d="M239.361 97.0929L236.684 97.0927L235.41 86.8203H239.362L239.361 97.0929Z" fill="#FFB8B8"/>
      <path
        d="M240.045 99.674L231.412 99.6737V99.565C231.412 98.6786 231.766 97.8285 232.396 97.2017C233.027 96.5749 233.881 96.2227 234.772 96.2227H234.773L240.046 96.2229L240.045 99.674Z"
        fill="#2F2E41"/>
      <path
        d="M250.788 51.8665C250.756 51.5466 250.795 51.2237 250.903 50.9204C251.01 50.6172 251.183 50.341 251.409 50.1113C251.635 49.8817 251.909 49.7041 252.211 49.5912C252.514 49.4783 252.838 49.4327 253.16 49.4578L255.453 44.9805L258.528 45.7029L255.201 51.9804C255.123 52.516 254.85 53.0045 254.434 53.3533C254.018 53.7022 253.488 53.8871 252.944 53.873C252.4 53.859 251.88 53.647 251.483 53.2771C251.085 52.9073 250.838 52.4054 250.788 51.8665L250.788 51.8665Z"
        fill="#FFB8B8"/>
      <path
        d="M238.294 59.5324C238.501 59.2849 238.65 58.9954 238.732 58.6844C238.813 58.3733 238.825 58.0483 238.767 57.7321C238.708 57.4159 238.581 57.1163 238.394 56.8544C238.206 56.5925 237.964 56.3746 237.682 56.2161L238.299 51.2289L235.343 50.1172L234.572 57.1722C234.336 57.6597 234.287 58.2165 234.436 58.7371C234.585 59.2576 234.921 59.7059 235.379 59.9968C235.838 60.2877 236.388 60.4011 236.926 60.3155C237.463 60.2299 237.95 59.9513 238.294 59.5324Z"
        fill="#FFB8B8"/>
      <path
        d="M252.323 44.3047L255.552 54.2814L253.502 69.4722L252.087 95.032L246.429 94.5629V71.3481L245.77 58.2163L242.577 75.5689L240.172 94.4217L234.607 94.4668L236.763 54.6991L237.706 44.6158L252.323 44.3047Z"
        fill="#2F2E41"/>
      <path
        d="M247.724 23.3945L244.66 24.567L241.595 23.8635L240.062 46.7266C240.062 46.7266 249.257 50.9475 250.671 45.7886C252.086 40.6298 247.724 23.3945 247.724 23.3945Z"
        fill="#CCCCCC"/>
      <path
        d="M254.68 25.1514L249.494 24.6825L247.651 23.3438L246.193 36.6416C246.193 36.6416 248.315 50.2422 252.323 51.6492L255.388 46.7248C255.388 46.7248 254.916 42.504 252.794 40.8625L254.68 25.1514Z"
        fill="#2F2E41"/>
      <path
        d="M235.82 26.56L240.538 25.0039L241.596 23.8633C241.596 23.8633 244.307 51.6507 243.364 52.1197C242.421 52.5887 236.292 53.0577 236.292 53.0577L235.82 26.56Z"
        fill="#2F2E41"/>
      <path
        d="M252.794 27.0283L254.68 25.1523C254.68 25.1523 261.988 40.3944 261.516 41.3324C261.045 42.2704 257.509 49.0707 257.509 49.0707L254.444 45.5533L256.802 39.2219L252.322 33.5941L252.794 27.0283Z"
        fill="#2F2E41"/>
      <path
        d="M237.233 27.0276L235.819 26.5586C235.443 26.8218 235.138 27.1739 234.932 27.5832C234.726 27.9925 234.626 28.4462 234.64 28.9035C234.64 30.545 234.404 55.1668 234.404 55.1668H238.354L237.233 27.0276Z"
        fill="#2F2E41"/>
      <path
        d="M236.986 15.7277C236.991 14.4422 237.33 13.1799 237.972 12.0642C238.614 10.9484 239.536 10.0176 240.648 9.3626C243.011 7.97531 245.861 8.00045 248.468 9.43123C250.326 10.4508 251.837 13.0161 251.837 15.1498V21.3757H236.986V15.7277Z"
        fill="#2F2E41"/>
      <path
        d="M246.69 20.5677C249.29 19.1543 250.245 15.9125 248.824 13.3269C247.403 10.7413 244.144 9.79107 241.545 11.2045C238.945 12.6179 237.99 15.8597 239.411 18.4453C240.832 21.0309 244.091 21.9811 246.69 20.5677Z"
        fill="#FFB8B8"/>
      <path
        d="M238.524 15.9364C238.483 15.8897 238.388 15.7804 239.049 10.7163L239.07 10.6336H239.13C242.484 8.99248 245.887 8.95122 249.244 10.5104C249.348 10.5591 249.437 10.6338 249.502 10.727C249.568 10.8202 249.608 10.9288 249.619 11.0421C250.011 15.7999 249.916 15.9036 249.876 15.948C249.814 16.0151 249.739 16.0687 249.656 16.1054C249.572 16.1422 249.482 16.1612 249.391 16.1613H247.903L247.872 16.1054C247.616 15.6332 247.336 15.1748 247.032 14.732C246.892 15.1727 246.778 15.6211 246.689 16.0748L246.671 16.1613H239.019C238.925 16.1613 238.832 16.1412 238.747 16.1024C238.661 16.0636 238.585 16.007 238.524 15.9364Z"
        fill="#2F2E41"/>
    </g>
    <g clipPath="url(#clip6_1506_78877)">
      <path d="M284.52 96.5421L281.842 96.5419L280.568 86.2695H284.52L284.52 96.5421Z" fill="#FFB8B8"/>
      <path
        d="M285.202 99.1232L276.568 99.1229V99.0143C276.568 98.1278 276.922 97.2777 277.553 96.6509C278.183 96.0241 279.038 95.6719 279.929 95.6719H279.929L285.202 95.6721L285.202 99.1232Z"
        fill="#2F2E41"/>
      <path d="M272.328 96.5421L269.651 96.5419L268.377 86.2695H272.329L272.328 96.5421Z" fill="#FFB8B8"/>
      <path
        d="M273.012 99.1232L264.379 99.1229V99.0143C264.379 98.1278 264.733 97.2777 265.363 96.6509C265.993 96.0241 266.848 95.6719 267.739 95.6719H267.739L273.012 95.6721L273.012 99.1232Z"
        fill="#2F2E41"/>
      <path
        d="M283.755 51.3157C283.723 50.9958 283.762 50.6729 283.869 50.3696C283.977 50.0664 284.149 49.7902 284.375 49.5605C284.602 49.3309 284.876 49.1534 285.178 49.0404C285.481 48.9275 285.805 48.882 286.127 48.907L288.42 44.4297L291.495 45.1521L288.168 51.4296C288.089 51.9652 287.817 52.4537 287.401 52.8026C286.985 53.1514 286.454 53.3363 285.91 53.3222C285.366 53.3082 284.847 53.0962 284.449 52.7264C284.052 52.3565 283.805 51.8546 283.755 51.3157L283.755 51.3157Z"
        fill="#FFB8B8"/>
      <path
        d="M271.261 58.9816C271.468 58.7341 271.617 58.4446 271.698 58.1336C271.78 57.8226 271.792 57.4975 271.734 57.1813C271.675 56.8652 271.548 56.5656 271.361 56.3036C271.173 56.0417 270.93 55.8238 270.649 55.6653L271.266 50.6782L268.31 49.5664L267.539 56.6214C267.303 57.1089 267.254 57.6657 267.403 58.1863C267.552 58.7069 267.887 59.1551 268.346 59.446C268.805 59.7369 269.355 59.8503 269.892 59.7647C270.43 59.6791 270.917 59.4005 271.261 58.9816Z"
        fill="#FFB8B8"/>
      <path
        d="M285.29 43.7539L288.519 53.7306L286.468 68.9214L285.054 94.4812L279.396 94.0121V70.7973L278.737 57.6656L275.544 75.0182L273.139 93.8709L267.574 93.916L269.73 54.1483L270.673 44.065L285.29 43.7539Z"
        fill="#2F2E41"/>
      <path
        d="M280.691 22.8438L277.626 24.0162L274.562 23.3127L273.029 46.1758C273.029 46.1758 282.224 50.3967 283.638 45.2379C285.053 40.079 280.691 22.8438 280.691 22.8438Z"
        fill="#CCCCCC"/>
      <path
        d="M287.647 24.6007L282.461 24.1317L280.617 22.793L279.16 36.0908C279.16 36.0908 281.282 49.6915 285.29 51.0984L288.354 46.1741C288.354 46.1741 287.883 41.9532 285.761 40.3117L287.647 24.6007Z"
        fill="#2F2E41"/>
      <path
        d="M268.787 26.0092L273.505 24.4531L274.563 23.3125C274.563 23.3125 277.274 51.1 276.331 51.5689C275.388 52.0379 269.259 52.5069 269.259 52.5069L268.787 26.0092Z"
        fill="#2F2E41"/>
      <path
        d="M285.761 26.4775L287.647 24.6016C287.647 24.6016 294.955 39.8436 294.483 40.7816C294.012 41.7196 290.476 48.5199 290.476 48.5199L287.411 45.0025L289.768 38.6712L285.289 33.0433L285.761 26.4775Z"
        fill="#2F2E41"/>
      <path
        d="M270.2 26.4768L268.786 26.0078C268.409 26.2711 268.105 26.6231 267.899 27.0324C267.693 27.4417 267.593 27.8954 267.607 28.3527C267.607 29.9942 267.371 54.616 267.371 54.616H271.32L270.2 26.4768Z"
        fill="#2F2E41"/>
      <path
        d="M269.953 15.1769C269.957 13.8914 270.297 12.6291 270.939 11.5134C271.581 10.3977 272.503 9.46678 273.615 8.81182C275.978 7.42452 278.828 7.44967 281.435 8.88045C283.293 9.9 284.804 12.4653 284.804 14.599V20.8249H269.953V15.1769Z"
        fill="#2F2E41"/>
      <path
        d="M279.657 20.0169C282.257 18.6035 283.212 15.3617 281.791 12.7761C280.37 10.1905 277.111 9.24028 274.511 10.6537C271.912 12.0671 270.957 15.3089 272.378 17.8945C273.799 20.4801 277.058 21.4303 279.657 20.0169Z"
        fill="#FFB8B8"/>
      <path
        d="M271.49 15.3856C271.45 15.3389 271.355 15.2296 272.016 10.1656L272.037 10.0828H272.097C275.451 8.4417 278.853 8.40044 282.211 9.95957C282.315 10.0084 282.403 10.083 282.469 10.1762C282.535 10.2694 282.575 10.378 282.586 10.4913C282.978 15.2491 282.883 15.3528 282.842 15.3972C282.781 15.4643 282.706 15.5179 282.623 15.5547C282.539 15.5914 282.449 15.6104 282.357 15.6105H280.87L280.839 15.5546C280.583 15.0824 280.303 14.624 279.999 14.1812C279.859 14.622 279.745 15.0703 279.656 15.524L279.638 15.6105H271.986C271.892 15.6105 271.799 15.5904 271.713 15.5516C271.628 15.5128 271.552 15.4562 271.49 15.3856Z"
        fill="#2F2E41"/>
    </g>
    <g clipPath="url(#clip7_1506_78877)">
      <path
        d="M31.0775 49.7413C31.2114 49.9744 31.3902 50.1789 31.6036 50.3433C31.817 50.5076 32.0608 50.6285 32.3212 50.6991C32.5816 50.7697 32.8534 50.7886 33.1211 50.7546C33.3888 50.7207 33.6471 50.6346 33.8814 50.5014C34.1157 50.3681 34.3213 50.1902 34.4864 49.9779C34.6516 49.7655 34.7731 49.5229 34.8441 49.2638C34.915 49.0048 34.934 48.7343 34.8999 48.468C34.8658 48.2016 34.7793 47.9445 34.6453 47.7114C34.6407 47.7035 34.6361 47.6955 34.6314 47.6876C34.5739 47.5918 34.5092 47.5004 34.4379 47.4144L36.1664 35.1552L36.2182 34.7879L36.5693 32.2863L36.6211 31.9258L36.4298 31.9347L33.0806 32.087L32.1826 32.1273L32.07 34.4094L31.4331 47.2397C31.1003 47.5565 30.8843 47.9755 30.8198 48.4292C30.7553 48.8829 30.846 49.3451 31.0775 49.7413Z"
        fill="#FFB8B8"/>
      <path
        d="M36.208 34.8996L31.9552 34.5115C31.7346 34.4907 31.5312 34.384 31.3894 34.2146C31.2476 34.0453 31.1788 33.827 31.198 33.6074L31.9858 24.8078C32.0234 24.3882 32.1438 23.98 32.34 23.6066C32.5361 23.2332 32.8043 22.902 33.1292 22.6317C33.454 22.3615 33.8292 22.1575 34.2333 22.0316C34.6373 21.9056 35.0624 21.86 35.4842 21.8975C35.9059 21.9349 36.3161 22.0547 36.6914 22.2499C37.0666 22.4451 37.3996 22.712 37.6712 23.0352C37.9428 23.3584 38.1477 23.7317 38.2743 24.1338C38.401 24.5358 38.4468 24.9588 38.4091 25.3784C38.4037 25.439 38.3965 25.4994 38.3876 25.5596L37.1123 34.1908C37.083 34.3885 36.9833 34.5692 36.8314 34.7C36.6795 34.8308 36.4854 34.9029 36.2845 34.9032C36.2589 34.9036 36.2334 34.9024 36.208 34.8996Z"
        fill="#F7B80B"/>
      <path
        d="M32.9846 27.7891C32.5544 26.5768 32.5345 25.2578 32.9278 24.0332C33.3211 22.8086 34.1061 21.7455 35.1631 21.006C36.7668 19.9143 38.9612 19.5224 41.3245 22.3218C46.0511 27.9206 47.2891 34.0793 47.2891 34.0793L37.6108 35.647C37.6108 35.647 34.3195 31.6438 32.9846 27.7891Z"
        fill="#F7B80B"/>
      <path d="M48.6389 90.8559H51.3983L52.7109 80.2656H48.6384L48.6389 90.8559Z" fill="#FFB8B8"/>
      <path
        d="M56.7188 93.4063H48.0463V90.0723H53.368C54.2567 90.0723 55.109 90.4235 55.7374 91.0488C56.3657 91.674 56.7188 92.522 56.7188 93.4063Z"
        fill="#2F2E41"/>
      <path d="M25.9513 88.4615L28.5712 89.3233L33.1582 79.6781L29.2914 78.4062L25.9513 88.4615Z" fill="#FFB8B8"/>
      <path
        d="M32.821 93.4049L24.5867 90.6968L25.6382 87.5312L30.6911 89.193C31.1089 89.3304 31.4954 89.5484 31.8285 89.8344C32.1616 90.1204 32.4349 90.4689 32.6327 90.86C32.8305 91.2511 32.9489 91.6772 32.9812 92.1138C33.0135 92.5505 32.959 92.9892 32.821 93.4049Z"
        fill="#2F2E41"/>
      <path
        d="M39.0598 18.2296C36.0067 18.2296 33.5317 15.7669 33.5317 12.729C33.5317 9.69118 36.0067 7.22852 39.0598 7.22852C42.1129 7.22852 44.5879 9.69118 44.5879 12.729C44.5879 15.7669 42.1129 18.2296 39.0598 18.2296Z"
        fill="#FFB8B8"/>
      <path
        d="M43.8995 87.9075L41.337 71.0785C41.3097 70.8989 41.2205 70.7343 41.0847 70.6129C40.9489 70.4915 40.775 70.4208 40.5926 70.4129C40.4102 70.4049 40.2307 70.4603 40.0848 70.5694C39.9389 70.6786 39.8356 70.8348 39.7926 71.0113L35.8902 87.0491C35.8287 87.2948 35.6772 87.5087 35.4653 87.649C35.2534 87.7892 34.9965 87.8456 34.745 87.8071L23.9807 86.0858C23.838 86.063 23.7018 86.0101 23.5813 85.9306C23.4609 85.8511 23.3589 85.7468 23.2823 85.6248C23.2057 85.5028 23.1562 85.3659 23.1372 85.2233C23.1182 85.0807 23.1301 84.9357 23.172 84.7981L37.208 38.7533C37.2479 38.6224 37.2526 38.4835 37.2217 38.3502L36.7545 36.3346C36.7032 36.1161 36.7269 35.8868 36.8218 35.6833C36.9167 35.4798 37.0774 35.3138 37.2782 35.2117C39.1896 34.2413 44.0305 32.2239 47.6837 34.4337C47.8023 34.5057 47.905 34.6009 47.9854 34.7136C48.0658 34.8263 48.1224 34.9541 48.1517 35.0892L59.1165 86.3678C59.1463 86.5072 59.1461 86.6514 59.1158 86.7907C59.0855 86.9301 59.0258 87.0614 58.9407 87.1761C58.8556 87.2908 58.747 87.3863 58.6221 87.4561C58.4972 87.526 58.3589 87.5687 58.2162 87.5814L44.9914 88.7598C44.9614 88.7625 44.9313 88.7639 44.9013 88.7639C44.6592 88.7634 44.4252 88.6769 44.2416 88.5198C44.058 88.3627 43.9369 88.1454 43.9002 87.9072L43.8995 87.9075Z"
        fill="#2F2E41"/>
      <path
        d="M35.3956 8.8435C35.8123 8.59409 36.1821 8.27427 36.4884 7.89842C36.6396 7.70852 36.7391 7.48308 36.7773 7.24381C36.8156 7.00454 36.7912 6.75951 36.7067 6.53231C36.4274 5.91912 35.5975 5.72607 34.9542 5.93412C34.311 6.14218 33.8142 6.64451 33.3443 7.12847C32.9304 7.55398 32.5045 8.00009 32.3089 8.55975C32.1133 9.11941 32.2135 9.82912 32.7019 10.1689C33.1813 10.5021 33.8705 10.3657 34.3315 10.0078C34.7924 9.64996 35.0778 9.11874 35.3499 8.60365L35.3956 8.8435Z"
        fill="#2F2E41"/>
      <path
        d="M33.5215 7.27593C33.4909 5.72617 31.7155 4.61469 30.182 4.88948C28.6486 5.16427 27.4701 6.465 26.8802 7.89964C25.6337 10.9308 26.5693 14.3896 27.7703 17.4401C28.9713 20.4905 30.4578 23.5851 30.2824 26.8557C30.1638 28.6235 29.5676 30.3265 28.5568 31.785C27.546 33.2436 26.158 34.4034 24.5396 35.142C26.27 35.9225 28.3384 35.1162 29.7926 33.8995C33.022 31.1975 34.2685 26.3868 32.7549 22.468C32.0083 20.5351 30.6925 18.8814 29.6653 17.0806C28.638 15.2798 27.8842 13.1574 28.4147 11.155C28.9452 9.15266 31.2023 7.53169 33.1319 8.30634L33.5215 7.27593Z"
        fill="#2F2E41"/>
      <path
        d="M39.3928 15.1697C38.9109 14.3594 38.425 13.2957 39.3055 12.5873C39.497 12.4403 39.7161 12.3329 39.95 12.2713C40.1838 12.2098 40.4276 12.1954 40.6672 12.229C41.6602 12.3481 42.7417 12.3972 43.703 12.0382C44.0635 11.9147 44.3954 11.7204 44.679 11.4669C44.9626 11.2133 45.1921 10.9056 45.3539 10.5621C45.8491 9.39215 45.2288 7.9705 44.1914 7.231C43.5567 6.79503 42.8068 6.55521 42.0357 6.54163C41.2646 6.52804 40.5067 6.74129 39.8569 7.15463C38.5132 6.56407 36.883 6.82677 35.6621 7.63972C34.4413 8.45267 33.6061 9.75451 33.1523 11.1446C32.7343 12.3411 32.6623 13.6304 32.9446 14.8656C33.1717 15.7249 33.6036 16.5171 34.2036 17.1751C34.8037 17.8331 35.5543 18.3375 36.3918 18.6457C36.7181 18.7737 37.0724 18.8142 37.4193 18.7631C37.7662 18.712 38.0935 18.5711 38.3685 18.3545C38.3685 18.3545 39.1303 17.6928 39.3687 16.8957C39.5521 16.2827 39.6501 15.6026 39.3928 15.1697Z"
        fill="#2F2E41"/>
      <path
        d="M55.7914 20.0789C55.7638 20.1997 55.7458 20.3225 55.7376 20.4461L46.069 25.9961L43.719 24.65L41.2136 27.9134L45.1412 30.6989C45.4586 30.9241 45.8407 31.0407 46.2304 31.0312C46.62 31.0218 46.996 30.8868 47.3019 30.6465L57.2728 22.8203C57.7229 22.9862 58.2131 23.0118 58.6781 22.8937C59.1432 22.7756 59.5611 22.5194 59.8762 22.1592C60.1914 21.7989 60.3888 21.3518 60.4423 20.8772C60.4958 20.4026 60.4028 19.9231 60.1756 19.5023C59.9485 19.0816 59.598 18.7397 59.1708 18.5221C58.7435 18.3044 58.2598 18.2214 57.784 18.284C57.3082 18.3465 56.8628 18.5518 56.5071 18.8724C56.1514 19.1931 55.9022 19.6139 55.7927 20.0789H55.7914Z"
        fill="#FFB8B8"/>
      <path
        d="M45.7454 26.2588L42.6204 30.2887C42.5315 30.4032 42.4189 30.4973 42.2901 30.5646C42.1614 30.632 42.0196 30.6709 41.8744 30.6788C41.7292 30.6868 41.5839 30.6635 41.4486 30.6106C41.3132 30.5577 41.1909 30.4763 41.0899 30.3722L37.5524 26.7171C36.9538 26.2738 36.5567 25.6121 36.4484 24.8775C36.3401 24.1429 36.5296 23.3956 36.9751 22.8C37.4206 22.2044 38.0856 21.8092 38.8239 21.7015C39.5621 21.5938 40.3132 21.7823 40.9118 22.2255C40.9424 22.2482 40.9725 22.2715 41.0021 22.2953L45.4388 24.7637C45.5658 24.8345 45.676 24.9316 45.7619 25.0485C45.8478 25.1654 45.9073 25.2994 45.9365 25.4413C45.9657 25.5832 45.9637 25.7297 45.9309 25.8707C45.898 26.0118 45.835 26.1442 45.7461 26.2589L45.7454 26.2588Z"
        fill="#F7B80B"/>
    </g>
    <defs>
      <clipPath id="clip0_1506_78877">
        <rect width="90.9992" height="54.9451" fill="white"/>
      </clipPath>
      <clipPath id="clip1_1506_78877">
        <rect width="90.9992" height="54.9451" fill="white" transform="translate(94.5059)"/>
      </clipPath>
      <clipPath id="clip2_1506_78877">
        <rect width="90.9992" height="54.9451" fill="white" transform="translate(188.801)"/>
      </clipPath>
      <clipPath id="clip3_1506_78877">
        <rect width="48.3517" height="91.7582" fill="white" transform="translate(121.979 1.64844)"/>
      </clipPath>
      <clipPath id="clip4_1506_78877">
        <rect width="30.2198" height="91.7582" fill="white" transform="translate(197.803 8.24219)"/>
      </clipPath>
      <clipPath id="clip5_1506_78877">
        <rect width="30.2198" height="91.7582" fill="white" transform="translate(231.318 8.24219)"/>
      </clipPath>
      <clipPath id="clip6_1506_78877">
        <rect width="30.2198" height="91.7582" fill="white" transform="translate(264.285 7.69141)"/>
      </clipPath>
      <clipPath id="clip7_1506_78877">
        <rect width="37.3626" height="88.4615" fill="white" transform="translate(23.0762 4.94531)"/>
      </clipPath>
    </defs>
  </svg>

);