import { Flex, Grid, Radio, RadioGroup, Text } from "@chakra-ui/react";
import { FC, ReactElement } from "react";
import { colors } from "../../ui/theme";
import ColorBoxButton from "../Buttons/ColorBoxButton";
import { DressingOutfitEnsembleIcon } from "../Icons/DressingOutfitEnsembleIcon";
import { DressCode } from "./DressingBlock";

const BLACK = "#000";

interface PersonalEnsembleOutfitSelectionCardProps {
  dressCode: DressCode;
  onSetEnsemble: (value: string | undefined) => void;
}
const PersonalEnsembleOutfitSelectionCard: FC<
  PersonalEnsembleOutfitSelectionCardProps
> = ({ dressCode, onSetEnsemble }): ReactElement => {
  return (
    <Flex
      gap="14px"
      width="350px"
      border={`1px solid ${colors.gray.lightMode}`}
      padding="14px"
      borderRadius="12px"
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap="14px"
      >
        <DressingOutfitEnsembleIcon />
        <Text variant="xl" fontWeight="600">
          Ensemble
        </Text>
      </Flex>
      <RadioGroup
        ml="1rem"
        onChange={onSetEnsemble}
        value={dressCode?.personalOutfit?.custom?.ensemble ?? ""}
        display="flex"
        flexWrap="wrap"
        flexDirection="row"
        gap="14px"
      >
        <Grid width="100%" templateColumns="80% 20%" alignItems="center">
          <Radio value="Tailleur/pantalon" key={1} gridColumn={1}>
            <Text marginRight="1rem" variant="lg" whiteSpace="nowrap">
              Tailleur/pantalon
            </Text>
          </Radio>
          <ColorBoxButton
            buttonColor={BLACK}
            isActive={
              dressCode?.personalOutfit?.custom?.ensemble ===
              "Tailleur/pantalon"
            }
          />
        </Grid>

        <Grid width="100%" templateColumns="80% 20%" alignItems="center">
          <Radio value="Tailleur/Jupe" key={2} gridColumn={1}>
            <Text variant="lg" whiteSpace="nowrap">
              Tailleur/Jupe
            </Text>
          </Radio>
          <ColorBoxButton
            buttonColor={BLACK}
            isActive={
              dressCode?.personalOutfit?.custom?.ensemble === "Tailleur/Jupe"
            }
          />
        </Grid>
      </RadioGroup>
    </Flex>
  );
};

export default PersonalEnsembleOutfitSelectionCard;
