export const AnimationGamingIcon = () => (
  <svg
    width="158"
    height="121"
    viewBox="0 0 158 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_942_6167)">
      <path
        d="M135.053 58.3168C137.202 60.5709 139.6 60.9306 142.247 59.3959C141.136 57.616 140.872 55.68 141.168 53.6406H136.012C136.24 55.4327 136.179 57.1014 135.053 58.3168Z"
        fill="#FFB8B8"
      />
      <path
        d="M143.566 117.308L146.324 115.869L147.523 100.642V92.6082L141.168 92.4883L142.966 100.522L143.566 117.308Z"
        fill="#FFB8B8"
      />
      <path
        d="M135.572 117.308L132.814 115.869L131.615 100.642V92.6082L137.97 92.4883L136.171 100.522L135.572 117.308Z"
        fill="#FFB8B8"
      />
      <path
        d="M131.336 94.2856L138.051 93.6861L139.13 87.5712L140.449 93.5662L147.763 93.0866C147.923 90.1733 147.517 87.1748 146.564 84.0941L130.497 83.7344L131.336 94.2856Z"
        fill="#1A202C"
      />
      <path
        d="M124.357 71.3201L116.28 65.1007C116.197 65.0362 116.104 64.9833 116.006 64.9434L114.832 64.4658C113.89 64.0821 112.094 62.8062 112.969 61.4923C113.676 60.4331 116.379 62.3307 116.711 63.0337C116.816 63.2549 116.989 63.4372 117.204 63.5546L118.845 64.454C118.885 64.4759 118.926 64.4954 118.968 64.5125L125.217 67.0454C125.391 67.1156 125.578 67.1435 125.764 67.1267C125.95 67.1099 126.13 67.0489 126.288 66.9488L127.421 66.2304C127.582 66.1282 127.766 66.0668 127.956 66.0516C128.146 66.0363 128.337 66.0677 128.513 66.1429C128.688 66.2181 128.843 66.3349 128.963 66.4833C129.083 66.6316 129.165 66.807 129.202 66.9942L129.576 68.884C129.63 69.1561 129.586 69.4385 129.452 69.6812C129.318 69.9239 129.102 70.1112 128.843 70.21L125.492 71.4871C125.304 71.5586 125.102 71.5808 124.903 71.5516C124.704 71.5224 124.516 71.4427 124.357 71.3201Z"
        fill="#FFB8B8"
      />
      <path
        d="M125.857 66.7892C128.094 66.1895 127.859 69.3136 129.565 70.8497L132.919 68.2015L130.976 58.7949C130.455 58.9439 129.976 59.212 129.576 59.5781C129.176 59.9442 128.867 60.3983 128.673 60.9045L125.857 66.7892Z"
        fill="#F7B80B"
      />
      <path
        d="M130.018 84.3335H146.924C144.187 76.9604 144.454 69.3771 146.784 61.6495C146.823 61.5212 146.816 61.3833 146.764 61.2595C146.712 61.1357 146.619 61.0339 146.5 60.9715L141.648 58.435C139.743 59.6744 137.696 59.2935 135.533 57.5957L130.257 59.0345C131.694 67.66 131.734 76.1083 130.018 84.3335Z"
        fill="#F7B80B"
      />
      <path
        d="M134.244 120.122L127.199 120.421C126.892 120.434 126.591 120.333 126.354 120.138C126.118 119.942 125.961 119.666 125.916 119.362C125.87 119.056 125.94 118.743 126.113 118.485C126.286 118.228 126.549 118.044 126.85 117.97L128.045 117.677L132.742 114.955C133.438 115.676 134.396 116.285 135.567 116.804L135.581 118.718C135.583 119.079 135.446 119.428 135.196 119.69C134.947 119.952 134.606 120.107 134.244 120.122Z"
        fill="#1A202C"
      />
      <path
        d="M157.047 120.446H1.23535V120.061H157.117L157.047 120.446Z"
        fill="#CCCCCC"
      />
      <path
        d="M144.835 120.017L151.88 120.316C152.187 120.329 152.488 120.228 152.725 120.032C152.961 119.837 153.117 119.561 153.163 119.257C153.209 118.95 153.138 118.637 152.966 118.38C152.793 118.122 152.53 117.939 152.229 117.865L151.034 117.572L146.337 114.85C145.641 115.57 144.683 116.179 143.512 116.698L143.498 118.612C143.495 118.974 143.633 119.323 143.883 119.585C144.132 119.847 144.473 120.002 144.835 120.017Z"
        fill="#1A202C"
      />
      <path
        d="M138.051 55.7986C140.17 55.7986 141.887 54.0808 141.887 51.9618C141.887 49.8428 140.17 48.125 138.051 48.125C135.932 48.125 134.214 49.8428 134.214 51.9618C134.214 54.0808 135.932 55.7986 138.051 55.7986Z"
        fill="#FFB8B8"
      />
      <path
        d="M144.045 83.1343L142.778 85.599C142.666 85.8165 142.601 86.0552 142.588 86.2994C142.575 86.5436 142.614 86.7879 142.702 87.016C142.79 87.2442 142.926 87.4511 143.1 87.6232C143.273 87.7953 143.482 87.9287 143.711 88.0146C143.953 88.1055 144.213 88.1409 144.471 88.1183C144.728 88.0957 144.978 88.0156 145.201 87.884C145.424 87.7523 145.614 87.5724 145.758 87.3574C145.903 87.1425 145.997 86.8979 146.034 86.6418L146.563 83.0144L148.482 73.9019L148.362 69.3457H144.525C145.834 72.7345 145.184 77.733 144.045 83.1343Z"
        fill="#FFB8B8"
      />
      <path
        d="M143.926 69.8261H149.082L148.333 64.1718C148.232 63.405 147.94 62.6758 147.484 62.0508C147.028 61.4258 146.423 60.9249 145.724 60.5938L143.926 69.8261Z"
        fill="#F7B80B"
      />
      <path
        d="M142.129 49.8484C142.206 49.3358 142.174 48.8129 142.035 48.3136C141.896 47.8144 141.653 47.35 141.323 46.9507C140.668 46.1761 139.571 45.5421 137.751 45.8412C133.779 46.4938 132.634 49.8003 133.023 50.6318C133.412 51.4633 134.605 53.3909 134.605 53.3909C134.605 53.3909 133.729 51.4828 134.679 51.0944C135.629 50.706 139.306 49.1759 140.589 49.8652C140.953 50.0476 141.261 50.323 141.483 50.6634C141.705 51.0038 141.833 51.397 141.854 51.8029L142.129 49.8484Z"
        fill="#1A202C"
      />
      <path
        d="M76.4597 33.8802L74.917 34.663C73.1402 31.1643 70.7862 27.9901 67.954 25.274C54.0455 11.9028 31.8518 12.34 18.4806 26.2485C5.10938 40.1571 5.54654 62.3507 19.4551 75.722C22.1023 78.272 25.135 80.3887 28.4414 81.994L27.6868 83.5506C22.4459 81.0058 17.8857 77.2504 14.383 72.5948C10.8803 67.9392 8.53575 62.5169 7.54294 56.7761C6.55013 51.0352 6.9376 45.1404 8.67335 39.5789C10.4091 34.0174 13.4433 28.9487 17.5252 24.7916C21.6072 20.6346 26.6197 17.5085 32.1487 15.6716C37.6776 13.8347 43.5643 13.3399 49.3224 14.2278C55.0804 15.1158 60.5445 17.3612 65.2632 20.7784C69.9819 24.1956 73.8197 28.6866 76.4597 33.8802Z"
        fill="#E2E8F0"
      />
      <path
        d="M24.2697 66.0622C22.0007 68.4224 20.7621 71.5874 20.8266 74.8607C20.8911 78.1341 22.2533 81.2478 24.6135 83.5169C26.9737 85.7859 30.1387 87.0244 33.412 86.96C36.6854 86.8955 39.7991 85.5333 42.0682 83.1731L44.1934 80.9624L26.3949 63.8516L24.2697 66.0622Z"
        fill="#E2E8F0"
      />
      <path
        d="M25.2023 62.5954C24.6973 63.122 24.4217 63.8274 24.4361 64.5568C24.4505 65.2863 24.7536 65.9803 25.279 66.4866L41.6037 82.1806C42.1299 82.6864 42.8354 82.9625 43.5652 82.9482C44.2949 82.9338 44.989 82.6301 45.4949 82.104C46.0007 81.5778 46.2768 80.8722 46.2624 80.1425C46.2481 79.4128 45.9444 78.7186 45.4182 78.2128L29.0935 62.5188C28.567 62.0137 27.8616 61.7381 27.1321 61.7525C26.4026 61.7669 25.7086 62.07 25.2023 62.5954Z"
        fill="#4A5568"
      />
      <path
        d="M74.1647 49.7866L76.2899 47.5759C78.559 45.2157 79.7975 42.0508 79.733 38.7774C79.6685 35.504 78.3063 32.3903 75.9461 30.1212C74.7775 28.9977 73.3989 28.1154 71.8893 27.5246C70.3796 26.9339 68.7684 26.6462 67.1476 26.6782C63.8742 26.7427 60.7605 28.1048 58.4914 30.4651L56.3662 32.6757L74.1647 49.7866Z"
        fill="#E2E8F0"
      />
      <path
        d="M55.0647 31.5329C54.5596 32.0595 54.284 32.7649 54.2984 33.4943C54.3128 34.2238 54.6159 34.9178 55.1413 35.4241L71.466 51.1181C71.9923 51.6233 72.6977 51.8989 73.427 51.8843C74.1564 51.8697 74.8502 51.566 75.3558 51.0401C75.8614 50.5142 76.1375 49.809 76.1234 49.0796C76.1093 48.3502 75.8061 47.6563 75.2805 47.1503L58.9558 31.4563C58.4293 30.9512 57.7239 30.6756 56.9944 30.69C56.2649 30.7044 55.5709 31.0075 55.0647 31.5329Z"
        fill="#4A5568"
      />
      <path
        d="M61.9512 67.8009V66.5258C61.9512 66.5258 67.5154 63.396 72.0363 66.5258V67.685L61.9512 67.8009Z"
        fill="#4A5568"
      />
      <path
        d="M112.608 67.8009V66.5258C112.608 66.5258 118.173 63.396 122.693 66.5258V67.685L112.608 67.8009Z"
        fill="#4A5568"
      />
      <path
        d="M78.6437 67.6867H73.1954C73.1954 67.6867 71.8044 66.0638 67.1676 66.1798C62.5307 66.2957 61.6034 67.6867 61.6034 67.6867C61.6034 67.6867 57.0245 70.7586 55.9812 74.584C55.9812 74.584 50.3591 91.4504 50.2432 104.665C50.1272 117.88 55.2277 119.735 57.8939 120.083C60.5601 120.431 65.3128 119.967 69.9496 108.259L71.9203 101.42C71.9203 101.42 72.2681 97.3624 76.4412 99.9126C80.6143 102.463 84.3238 98.5216 84.3238 98.5216H99.9731C99.9731 98.5216 105.189 101.767 109.942 99.2171C109.942 99.2171 111.797 96.7828 113.536 105.013C113.536 105.013 118.868 121.938 127.098 120.199C135.329 118.46 134.401 104.318 134.401 104.318C134.401 104.318 133.3 82.6984 128.779 75.0477C128.779 75.0477 124.432 67.1071 121.534 66.7594C121.534 66.7594 115.275 65.6002 112.84 66.6434L111.449 67.8026L78.6437 67.6867Z"
        fill="#E2E8F0"
      />
      <path
        d="M66.0079 89.1311C71.5778 89.1311 76.093 84.6159 76.093 79.046C76.093 73.4762 71.5778 68.9609 66.0079 68.9609C60.4381 68.9609 55.9229 73.4762 55.9229 79.046C55.9229 84.6159 60.4381 89.1311 66.0079 89.1311Z"
        fill="#4A5568"
      />
      <path
        d="M118.636 89.1311C124.206 89.1311 128.721 84.6159 128.721 79.046C128.721 73.4762 124.206 68.9609 118.636 68.9609C113.066 68.9609 108.551 73.4762 108.551 79.046C108.551 84.6159 113.066 89.1311 118.636 89.1311Z"
        fill="#4A5568"
      />
      <path
        d="M67.7659 76.7981L66.4212 77.9874C66.3074 78.0877 66.1609 78.143 66.0091 78.143C65.8574 78.143 65.7109 78.0877 65.597 77.9874L64.25 76.7981C64.1842 76.74 64.1313 76.6687 64.095 76.5888C64.0586 76.5089 64.0395 76.4222 64.0391 76.3344V73.062C64.0394 72.8968 64.1052 72.7384 64.2221 72.6217C64.3391 72.505 64.4975 72.4395 64.6627 72.4395H67.3579C67.523 72.4391 67.6814 72.5044 67.7984 72.621C67.9153 72.7375 67.9812 72.8957 67.9815 73.0608V76.3321C67.9808 76.4206 67.9612 76.508 67.924 76.5883C67.8868 76.6687 67.8329 76.7402 67.7659 76.7981Z"
        fill="#E2E8F0"
      />
      <path
        d="M67.7659 81.293L66.4212 80.1048C66.3074 80.0045 66.1609 79.9492 66.0091 79.9492C65.8574 79.9492 65.7109 80.0045 65.597 80.1048L64.25 81.2941C64.1842 81.3522 64.1313 81.4235 64.095 81.5034C64.0586 81.5833 64.0395 81.67 64.0391 81.7578V85.0268C64.0388 85.1919 64.1041 85.3503 64.2206 85.4673C64.3371 85.5842 64.4953 85.6501 64.6604 85.6504H67.3579C67.523 85.6507 67.6814 85.5854 67.7984 85.4689C67.9153 85.3524 67.9812 85.1942 67.9815 85.0291V81.759C67.9808 81.6704 67.9612 81.5831 67.924 81.5027C67.8868 81.4223 67.8329 81.3508 67.7659 81.293Z"
        fill="#E2E8F0"
      />
      <path
        d="M63.7604 80.8053L64.9498 79.4583C65.05 79.3445 65.1053 79.1979 65.1053 79.0462C65.1053 78.8945 65.05 78.748 64.9498 78.6341L63.7604 77.2871C63.7023 77.2213 63.631 77.1685 63.5511 77.1322C63.4712 77.0958 63.3845 77.0767 63.2967 77.0762H60.0278C59.8626 77.0765 59.7042 77.1423 59.5875 77.2592C59.4708 77.3762 59.4053 77.5346 59.4053 77.6998V80.395C59.405 80.5601 59.4703 80.7185 59.5868 80.8355C59.7033 80.9524 59.8615 81.0183 60.0266 81.0186H63.2979C63.3857 81.0177 63.4723 80.9982 63.552 80.9615C63.6317 80.9247 63.7027 80.8715 63.7604 80.8053Z"
        fill="#E2E8F0"
      />
      <path
        d="M68.257 80.8065L67.07 79.4583C66.9695 79.3446 66.9141 79.198 66.9141 79.0462C66.9141 78.8945 66.9695 78.7479 67.07 78.6341L68.2593 77.2871C68.3174 77.2213 68.3887 77.1684 68.4686 77.1321C68.5485 77.0957 68.6352 77.0767 68.723 77.0762H71.992C72.1571 77.0759 72.3155 77.1412 72.4325 77.2577C72.5494 77.3742 72.6153 77.5324 72.6156 77.6975V80.395C72.6159 80.5601 72.5507 80.7185 72.4341 80.8355C72.3176 80.9524 72.1594 81.0183 71.9943 81.0186H68.7242C68.6357 81.0185 68.5483 80.9995 68.4677 80.9629C68.3872 80.9263 68.3153 80.873 68.257 80.8065Z"
        fill="#E2E8F0"
      />
      <path
        d="M79.6868 97.3618C83.7201 97.3618 86.9898 94.0922 86.9898 90.0589C86.9898 86.0255 83.7201 82.7559 79.6868 82.7559C75.6535 82.7559 72.3838 86.0255 72.3838 90.0589C72.3838 94.0922 75.6535 97.3618 79.6868 97.3618Z"
        fill="#4A5568"
      />
      <path
        d="M104.957 97.3618C108.991 97.3618 112.26 94.0922 112.26 90.0589C112.26 86.0255 108.991 82.7559 104.957 82.7559C100.924 82.7559 97.6543 86.0255 97.6543 90.0589C97.6543 94.0922 100.924 97.3618 104.957 97.3618Z"
        fill="#4A5568"
      />
      <path
        d="M79.687 94.9268C82.3759 94.9268 84.5557 92.747 84.5557 90.0581C84.5557 87.3692 82.3759 85.1895 79.687 85.1895C76.9981 85.1895 74.8184 87.3692 74.8184 90.0581C74.8184 92.747 76.9981 94.9268 79.687 94.9268Z"
        fill="#E2E8F0"
      />
      <path
        d="M79.6869 93.4207C79.022 93.4207 78.3721 93.2235 77.8192 92.8541C77.2664 92.4847 76.8355 91.9597 76.5811 91.3454C76.3266 90.7312 76.2601 90.0552 76.3898 89.4031C76.5195 88.751 76.8397 88.152 77.3098 87.6819C77.78 87.2117 78.379 86.8916 79.0311 86.7619C79.6832 86.6322 80.3591 86.6987 80.9734 86.9532C81.5876 87.2076 82.1127 87.6385 82.482 88.1913C82.8514 88.7441 83.0486 89.3941 83.0486 90.059C83.0476 90.9502 82.6931 91.8047 82.0629 92.4349C81.4326 93.0651 80.5782 93.4196 79.6869 93.4207ZM79.6869 86.9291C79.0679 86.9291 78.4627 87.1127 77.948 87.4566C77.4333 87.8005 77.0322 88.2893 76.7953 88.8612C76.5584 89.4331 76.4964 90.0624 76.6172 90.6696C76.738 91.2767 77.036 91.8344 77.4738 92.2721C77.9115 92.7098 78.4692 93.0079 79.0763 93.1287C79.6834 93.2495 80.3127 93.1875 80.8846 92.9506C81.4565 92.7137 81.9454 92.3125 82.2893 91.7978C82.6332 91.2831 82.8168 90.678 82.8168 90.059C82.8158 89.2292 82.4858 88.4336 81.899 87.8469C81.3122 87.2601 80.5167 86.9301 79.6869 86.9291Z"
        fill="#4A5568"
      />
      <path
        d="M104.958 94.9268C107.646 94.9268 109.826 92.747 109.826 90.0581C109.826 87.3692 107.646 85.1895 104.958 85.1895C102.269 85.1895 100.089 87.3692 100.089 90.0581C100.089 92.747 102.269 94.9268 104.958 94.9268Z"
        fill="#E2E8F0"
      />
      <path
        d="M104.957 93.4207C104.293 93.4207 103.643 93.2235 103.09 92.8541C102.537 92.4847 102.106 91.9597 101.852 91.3454C101.597 90.7312 101.531 90.0552 101.66 89.4031C101.79 88.751 102.11 88.152 102.58 87.6819C103.05 87.2117 103.649 86.8916 104.302 86.7619C104.954 86.6322 105.63 86.6987 106.244 86.9532C106.858 87.2076 107.383 87.6385 107.753 88.1913C108.122 88.7441 108.319 89.3941 108.319 90.059C108.318 90.9502 107.964 91.8047 107.333 92.4349C106.703 93.0651 105.849 93.4196 104.957 93.4207ZM104.957 86.9291C104.338 86.9291 103.733 87.1127 103.219 87.4566C102.704 87.8005 102.303 88.2893 102.066 88.8612C101.829 89.4331 101.767 90.0624 101.888 90.6696C102.008 91.2767 102.307 91.8344 102.744 92.2721C103.182 92.7098 103.74 93.0079 104.347 93.1287C104.954 93.2495 105.583 93.1875 106.155 92.9506C106.727 92.7137 107.216 92.3125 107.56 91.7978C107.904 91.2831 108.087 90.678 108.087 90.059C108.086 89.2292 107.756 88.4336 107.169 87.8469C106.583 87.2601 105.787 86.9301 104.957 86.9291Z"
        fill="#4A5568"
      />
      <path
        d="M92.5543 92.9563C93.7067 92.9563 94.6409 92.0222 94.6409 90.8698C94.6409 89.7174 93.7067 88.7832 92.5543 88.7832C91.402 88.7832 90.4678 89.7174 90.4678 90.8698C90.4678 92.0222 91.402 92.9563 92.5543 92.9563Z"
        fill="#4A5568"
      />
      <path
        d="M89.3647 84.3604C89.6163 84.3604 89.8203 84.1564 89.8203 83.9048C89.8203 83.6532 89.6163 83.4492 89.3647 83.4492C89.1131 83.4492 88.9092 83.6532 88.9092 83.9048C88.9092 84.1564 89.1131 84.3604 89.3647 84.3604Z"
        fill="#4A5568"
      />
      <path
        d="M91.0718 84.3604C91.3234 84.3604 91.5273 84.1564 91.5273 83.9048C91.5273 83.6532 91.3234 83.4492 91.0718 83.4492C90.8202 83.4492 90.6162 83.6532 90.6162 83.9048C90.6162 84.1564 90.8202 84.3604 91.0718 84.3604Z"
        fill="#4A5568"
      />
      <path
        d="M90.8442 86.5225C91.0958 86.5225 91.2998 86.3185 91.2998 86.0669C91.2998 85.8153 91.0958 85.6113 90.8442 85.6113C90.5926 85.6113 90.3887 85.8153 90.3887 86.0669C90.3887 86.3185 90.5926 86.5225 90.8442 86.5225Z"
        fill="#4A5568"
      />
      <path
        d="M92.4383 86.5817C92.6944 86.5817 92.902 86.3741 92.902 86.118C92.902 85.8619 92.6944 85.6543 92.4383 85.6543C92.1822 85.6543 91.9746 85.8619 91.9746 86.118C91.9746 86.3741 92.1822 86.5817 92.4383 86.5817Z"
        fill="#4A5568"
      />
      <path
        d="M94.0298 86.5225C94.2814 86.5225 94.4854 86.3185 94.4854 86.0669C94.4854 85.8153 94.2814 85.6113 94.0298 85.6113C93.7782 85.6113 93.5742 85.8153 93.5742 86.0669C93.5742 86.3185 93.7782 86.5225 94.0298 86.5225Z"
        fill="#4A5568"
      />
      <path
        d="M90.1616 85.499C90.4132 85.499 90.6172 85.2951 90.6172 85.0435C90.6172 84.7919 90.4132 84.5879 90.1616 84.5879C89.91 84.5879 89.7061 84.7919 89.7061 85.0435C89.7061 85.2951 89.91 85.499 90.1616 85.499Z"
        fill="#4A5568"
      />
      <path
        d="M91.6268 85.5367C91.8828 85.5367 92.0905 85.3291 92.0905 85.0731C92.0905 84.817 91.8828 84.6094 91.6268 84.6094C91.3707 84.6094 91.1631 84.817 91.1631 85.0731C91.1631 85.3291 91.3707 85.5367 91.6268 85.5367Z"
        fill="#4A5568"
      />
      <path
        d="M93.1336 85.5367C93.3897 85.5367 93.5973 85.3291 93.5973 85.0731C93.5973 84.817 93.3897 84.6094 93.1336 84.6094C92.8775 84.6094 92.6699 84.817 92.6699 85.0731C92.6699 85.3291 92.8775 85.5367 93.1336 85.5367Z"
        fill="#4A5568"
      />
      <path
        d="M94.7124 85.499C94.964 85.499 95.168 85.2951 95.168 85.0435C95.168 84.7919 94.964 84.5879 94.7124 84.5879C94.4608 84.5879 94.2568 84.7919 94.2568 85.0435C94.2568 85.2951 94.4608 85.499 94.7124 85.499Z"
        fill="#4A5568"
      />
      <path
        d="M92.4383 84.3785C92.6944 84.3785 92.902 84.1709 92.902 83.9149C92.902 83.6588 92.6944 83.4512 92.4383 83.4512C92.1822 83.4512 91.9746 83.6588 91.9746 83.9149C91.9746 84.1709 92.1822 84.3785 92.4383 84.3785Z"
        fill="#4A5568"
      />
      <path
        d="M94.0613 84.3785C94.3174 84.3785 94.525 84.1709 94.525 83.9149C94.525 83.6588 94.3174 83.4512 94.0613 83.4512C93.8053 83.4512 93.5977 83.6588 93.5977 83.9149C93.5977 84.1709 93.8053 84.3785 94.0613 84.3785Z"
        fill="#4A5568"
      />
      <path
        d="M95.395 84.3604C95.6466 84.3604 95.8506 84.1564 95.8506 83.9048C95.8506 83.6532 95.6466 83.4492 95.395 83.4492C95.1434 83.4492 94.9395 83.6532 94.9395 83.9048C94.9395 84.1564 95.1434 84.3604 95.395 84.3604Z"
        fill="#4A5568"
      />
      <path
        d="M75.2238 68.6738C75.0094 68.6741 74.8038 68.7593 74.6522 68.911C74.5006 69.0626 74.4153 69.2682 74.415 69.4826V71.917C74.4153 72.1314 74.5006 72.337 74.6522 72.4886C74.8038 72.6402 75.0094 72.7255 75.2238 72.7258H75.8034C76.0179 72.7255 76.2234 72.6402 76.3751 72.4886C76.5267 72.337 76.612 72.1314 76.6122 71.917V69.4826C76.612 69.2682 76.5267 69.0626 76.3751 68.911C76.2234 68.7593 76.0179 68.6741 75.8034 68.6738H75.2238Z"
        fill="#4A5568"
      />
      <path
        d="M109.072 68.6738C108.858 68.674 108.652 68.7593 108.501 68.911C108.349 69.0626 108.264 69.2682 108.264 69.4826V71.917C108.264 72.1314 108.349 72.337 108.501 72.4886C108.652 72.6403 108.858 72.7255 109.072 72.7258H109.652C109.867 72.7255 110.072 72.6403 110.224 72.4886C110.375 72.337 110.461 72.1314 110.461 71.917V69.4826C110.461 69.2682 110.375 69.0626 110.224 68.911C110.072 68.7593 109.867 68.674 109.652 68.6738H109.072Z"
        fill="#4A5568"
      />
      <path
        d="M118.752 75.6833C120.288 75.6833 121.534 74.4377 121.534 72.9012C121.534 71.3647 120.288 70.1191 118.752 70.1191C117.215 70.1191 115.97 71.3647 115.97 72.9012C115.97 74.4377 117.215 75.6833 118.752 75.6833Z"
        fill="#E2E8F0"
      />
      <path
        d="M112.841 81.5954C114.377 81.5954 115.623 80.3498 115.623 78.8133C115.623 77.2768 114.377 76.0312 112.841 76.0312C111.304 76.0312 110.059 77.2768 110.059 78.8133C110.059 80.3498 111.304 81.5954 112.841 81.5954Z"
        fill="#E2E8F0"
      />
      <path
        d="M124.896 81.2478C126.433 81.2478 127.678 80.0022 127.678 78.4657C127.678 76.9292 126.433 75.6836 124.896 75.6836C123.36 75.6836 122.114 76.9292 122.114 78.4657C122.114 80.0022 123.36 81.2478 124.896 81.2478Z"
        fill="#E2E8F0"
      />
      <path
        d="M119.216 87.3923C120.752 87.3923 121.998 86.1467 121.998 84.6102C121.998 83.0737 120.752 81.8281 119.216 81.8281C117.679 81.8281 116.434 83.0737 116.434 84.6102C116.434 86.1467 117.679 87.3923 119.216 87.3923Z"
        fill="#E2E8F0"
      />
      <path
        d="M80.4697 67.0508C79.8481 67.0515 79.2521 67.2988 78.8125 67.7383C78.373 68.1779 78.1257 68.7739 78.125 69.3955V79.7704C78.1257 80.392 78.373 80.988 78.8125 81.4276C79.2521 81.8671 79.8481 82.1144 80.4697 82.1151H104.291C104.913 82.1144 105.509 81.8671 105.949 81.4276C106.388 80.988 106.635 80.392 106.636 79.7704V69.3955C106.635 68.7739 106.388 68.1779 105.949 67.7383C105.509 67.2988 104.913 67.0515 104.291 67.0508H80.4697Z"
        fill="#4A5568"
      />
      <path
        d="M120.642 74.1769H116.869L118.69 71.0547L120.642 74.1769ZM117.273 73.9451H120.223L118.697 71.5031L117.273 73.9451Z"
        fill="#4A5568"
      />
      <path
        d="M114.405 80.2059H111.275V77.4238H114.405V80.2059ZM111.507 79.9741H114.173V77.6557H111.507V79.9741Z"
        fill="#4A5568"
      />
      <path
        d="M124.896 80.2061C124.552 80.2061 124.216 80.1041 123.93 79.9131C123.644 79.722 123.421 79.4505 123.29 79.1327C123.158 78.815 123.124 78.4654 123.191 78.1281C123.258 77.7908 123.423 77.481 123.667 77.2378C123.91 76.9946 124.22 76.829 124.557 76.7619C124.894 76.6948 125.244 76.7293 125.561 76.8609C125.879 76.9925 126.151 77.2153 126.342 77.5013C126.533 77.7872 126.635 78.1234 126.635 78.4673C126.634 78.9283 126.451 79.3703 126.125 79.6963C125.799 80.0222 125.357 80.2056 124.896 80.2061ZM124.896 76.9603C124.598 76.9603 124.307 77.0487 124.059 77.2143C123.811 77.3799 123.618 77.6153 123.504 77.8906C123.39 78.166 123.36 78.469 123.418 78.7613C123.476 79.0536 123.62 79.3221 123.83 79.5329C124.041 79.7437 124.31 79.8872 124.602 79.9453C124.894 80.0035 125.197 79.9736 125.473 79.8596C125.748 79.7455 125.983 79.5524 126.149 79.3045C126.315 79.0567 126.403 78.7654 126.403 78.4673C126.403 78.0678 126.244 77.6847 125.961 77.4022C125.679 77.1197 125.296 76.9608 124.896 76.9603Z"
        fill="#4A5568"
      />
      <path
        d="M117.965 83.2248L117.801 83.3887L120.409 85.9969L120.573 85.833L117.965 83.2248Z"
        fill="#4A5568"
      />
      <path
        d="M120.469 83.2229L117.803 85.7734L117.963 85.941L120.629 83.3904L120.469 83.2229Z"
        fill="#4A5568"
      />
      <path
        d="M25.8197 40.5625C19.4904 42.3074 12.5596 43.6209 10.0762 41.5831C11.4359 39.3195 12.2201 37.0884 12.2866 34.8979C12.3016 33.8956 12.7076 32.9389 13.4179 32.2316C13.8882 31.7773 14.4558 31.4361 15.0777 31.2341C15.6995 31.032 16.3593 30.9743 17.0068 31.0654L18.6494 31.2959C18.7047 31.3037 18.7597 31.3125 18.8144 31.3223C19.6028 31.4717 20.3247 31.8642 20.8786 32.4448C21.4326 33.0253 21.7908 33.7648 21.903 34.5594C22.2491 36.9347 23.4128 38.991 25.8197 40.5625Z"
        fill="#1A202C"
      />
      <path
        d="M31.9253 63.0974L34.3859 63.6047C34.5738 63.6435 34.7507 63.7236 34.9039 63.8393C35.057 63.9549 35.1824 64.1032 35.2711 64.2734C35.3597 64.4436 35.4093 64.6314 35.4164 64.8232C35.4234 65.0149 35.3876 65.2059 35.3117 65.3821C35.2383 65.5524 35.1291 65.7049 34.9915 65.8292C34.8539 65.9535 34.6912 66.0468 34.5144 66.1027C34.3376 66.1586 34.1509 66.1758 33.9668 66.1531C33.7828 66.1305 33.6058 66.0684 33.4478 65.9713L29.684 63.6564L23.291 48.6277L26.6022 47.6699L31.9253 63.0974Z"
        fill="#A0616A"
      />
      <path
        d="M14.3964 82.8172L12.5333 83.359L11.123 80.4465L12.5571 79.6992L14.3964 82.8172Z"
        fill="#A0616A"
      />
      <path
        d="M13.45 95.2961L11.1641 94.6909L11.5219 90.7637L13.4892 90.8848L13.45 95.2961Z"
        fill="#A0616A"
      />
      <path
        d="M13.689 80.0955L11.2679 81.6888L1.95606 71.156C1.75408 70.9275 1.60492 70.6574 1.51921 70.3647C1.4335 70.072 1.41333 69.7641 1.46015 69.4628C1.50696 69.1614 1.61961 68.8741 1.79007 68.6213C1.96053 68.3684 2.18462 68.1562 2.4464 67.9998L14.0466 61.0684L15.8233 66.1723L9.78306 71.8406L13.689 80.0955Z"
        fill="#1A202C"
      />
      <path
        d="M15.7272 85.6076C15.7211 85.6155 15.715 85.6234 15.7089 85.6312C15.5542 86.5372 15.2824 87.3636 14.6755 87.9619C14.4956 88.3677 14.1725 88.6931 13.7679 88.8758C13.3634 89.0585 12.9057 89.0858 12.4823 88.9525C12.083 88.8267 11.7397 88.5662 11.5113 88.2154C11.2829 87.8645 11.1834 87.4452 11.2299 87.0292L11.6824 82.9853C12.7676 82.7359 13.5953 82.1187 14.0143 80.918L15.069 81.6364C15.3811 81.8473 15.647 82.1196 15.8504 82.4366C16.0538 82.7537 16.1905 83.1088 16.2521 83.4804C16.3137 83.8521 16.2989 84.2323 16.2086 84.5981C16.1184 84.9638 15.9546 85.3073 15.7272 85.6076Z"
        fill="#1A202C"
      />
      <path
        d="M16.8749 39.6951C18.9329 39.6951 20.6013 38.0267 20.6013 35.9686C20.6013 33.9106 18.9329 32.2422 16.8749 32.2422C14.8168 32.2422 13.1484 33.9106 13.1484 35.9686C13.1484 38.0267 14.8168 39.6951 16.8749 39.6951Z"
        fill="#A0616A"
      />
      <path
        d="M20.1018 41.9259L14.2271 46.7913L13.7695 44.7886L14.7949 43.2255C15.0795 41.6919 14.8958 40.0008 14.4068 38.207L18.2396 38.2105C18.4051 39.6601 18.9694 40.9247 20.1018 41.9259Z"
        fill="#A0616A"
      />
      <path
        d="M21.7558 54.6887L13.8249 57.1045L13.2079 54.2838C11.7216 52.2881 11.0755 50.4667 12.0479 48.9806L11.5586 46.7436C12.9623 45.6384 14.0661 44.5077 14.6722 43.3344L19.5518 41.4277L23.139 41.6484L24.1982 45.1982L21.7558 54.6887Z"
        fill="#F7B80B"
      />
      <path
        d="M27.3907 48.0661C25.7001 48.0482 24.0456 48.5552 22.6553 49.5171L23.1394 41.6484C23.6308 41.7141 24.0967 41.9065 24.4912 42.2068C24.8857 42.5071 25.1952 42.905 25.3893 43.3612L27.3907 48.0661Z"
        fill="#F7B80B"
      />
      <path
        d="M13.6637 91.8244L11.1465 91.2049L11.517 69.8158L14.8972 66.1142L14.1405 61.4216L13.6509 56.1645L21.9076 54.1172C24.6984 59.1845 26.3852 63.7918 23.923 66.6696L13.6637 91.8244Z"
        fill="#1A202C"
      />
      <path
        d="M13.0851 99.0472C13.0738 99.0517 13.0625 99.0561 13.0512 99.0604C12.3202 99.9092 11.5171 100.601 10.5074 100.845C10.0645 101.155 9.52273 101.292 8.9855 101.228C8.44827 101.165 7.95324 100.906 7.59492 100.5C7.257 100.118 7.06238 99.6304 7.04435 99.1205C7.02631 98.6107 7.18598 98.1104 7.49605 97.7052L10.5098 93.7673C11.7997 94.1889 13.0518 94.0646 14.2438 93.0742L14.8936 94.4872C15.0869 94.9035 15.1934 95.3549 15.2066 95.8138C15.2197 96.2727 15.1392 96.7294 14.9699 97.1561C14.8007 97.5828 14.5463 97.9706 14.2222 98.2957C13.8982 98.6209 13.5112 98.8766 13.0851 99.0472Z"
        fill="#1A202C"
      />
      <path
        d="M3.88949 65.6701L1.68 67.5619C1.49665 67.7189 1.3559 67.9196 1.27079 68.1455C1.18569 68.3713 1.15898 68.615 1.19315 68.854C1.22732 69.0929 1.32126 69.3194 1.46626 69.5123C1.61126 69.7053 1.80263 69.8585 2.02263 69.9578C2.35382 70.1073 2.72942 70.1255 3.07349 70.0087C3.41756 69.8918 3.70444 69.6487 3.87613 69.3285L5.4739 66.3484L12.8012 52.977L10.0454 52.459L3.88949 65.6701Z"
        fill="#A0616A"
      />
      <path
        d="M13.2431 53.3523C11.8119 52.9203 10.4714 52.6659 9.43164 53.0017C9.64959 50.3047 10.0988 47.8955 11.559 46.7441L13.6347 46.988L13.2431 53.3523Z"
        fill="#F7B80B"
      />
      <path
        d="M21.0356 35.1052L13.0654 34.6148L13.1635 33.0208C15.9457 31.1448 18.6024 31.3083 21.1337 33.5112L21.0356 35.1052Z"
        fill="#1A202C"
      />
    </g>
    <defs>
      <clipPath id="clip0_942_6167">
        <rect
          width="156.812"
          height="120.37"
          fill="white"
          transform="translate(0.59375 0.445312)"
        />
      </clipPath>
    </defs>
  </svg>
);
