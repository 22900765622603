export const AircraftIcon = () => (
  <svg width="198" height="125" viewBox="0 0 198 125" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1219_48966)">
      <path d="M121.534 124.416H77.5563C76.3126 124.416 75.3008 123.404 75.3008 122.16V42.7858C75.3008 41.5422 76.3126 40.5303 77.5563 40.5303H121.534C122.778 40.5303 123.79 41.5422 123.79 42.7858V122.16C123.79 123.404 122.778 124.416 121.534 124.416V124.416ZM94.7452 40.9069C84.2144 40.9069 75.6774 49.4439 75.6774 59.9747V122.16C75.6774 123.198 76.5186 124.039 77.5563 124.039H121.534C122.572 124.039 123.413 123.198 123.413 122.16V42.7858C123.413 41.7481 122.572 40.9069 121.534 40.9069H94.7452V40.9069Z" fill="#E6E6E6"/>
      <path d="M123.561 61.4484C123.561 61.5829 123.711 61.6908 123.899 61.6908H182.003C182.19 61.6908 182.341 61.5829 182.341 61.4484C182.341 61.314 182.19 61.2061 182.003 61.2061H123.899C123.711 61.2061 123.561 61.314 123.561 61.4484H123.561V61.4484Z" fill="#E6E6E6"/>
      <path d="M76.3051 96.0635L76.8802 111.302H75.4805V113.027H79.3926V111.302H78.2515L78.333 96.0635H76.3051Z" fill="#3F3D56"/>
      <path d="M74.797 108.858H74.797C75.3924 108.858 75.8752 109.341 75.8752 109.937V114.393C75.8752 114.989 75.3924 115.471 74.797 115.471H74.797C74.2015 115.471 73.7188 114.989 73.7188 114.393V109.937C73.7188 109.341 74.2015 108.858 74.797 108.858Z" fill="#3F3D56"/>
      <path d="M80.2598 108.858H80.2598C80.8553 108.858 81.338 109.341 81.338 109.937V114.393C81.338 114.989 80.8553 115.471 80.2598 115.471H80.2598C79.6644 115.471 79.1816 114.989 79.1816 114.393V109.937C79.1816 109.341 79.6644 108.858 80.2598 108.858Z" fill="#3F3D56"/>
      <path d="M89.0298 86.5691L66.8906 85.5628L70.3409 83.9814L89.7486 84.269L89.0298 86.5691Z" fill="#F7B80B"/>
      <path d="M0.759766 88.8032L3.43349 87.7969L86.4411 89.9533L89.8914 88.3719L88.4538 98.1476L22.5172 91.1216L0.759766 88.8032Z" fill="#F7B80B"/>
      <path d="M22.1074 94.4822L22.5163 91.1221L88.453 98.1481L88.0936 103.108L22.1074 94.4822Z" fill="#3F3D56"/>
      <path d="M66.4575 104.402C70.2289 104.402 73.2862 101.151 73.2862 97.1418C73.2862 93.1322 70.2289 89.8818 66.4575 89.8818C62.6862 89.8818 59.6289 93.1322 59.6289 97.1418C59.6289 101.151 62.6862 104.402 66.4575 104.402Z" fill="#3F3D56"/>
      <path d="M66.4594 100.88C69.2382 100.88 71.491 98.6269 71.491 95.848C71.491 93.0691 69.2382 90.8164 66.4594 90.8164C63.6805 90.8164 61.4277 93.0691 61.4277 95.848C61.4277 98.6269 63.6805 100.88 66.4594 100.88Z" fill="#F7B80B"/>
      <path d="M122.597 96.0635L122.022 111.302H123.422V113.027H119.51V111.302H120.651L120.569 96.0635H122.597Z" fill="#3F3D56"/>
      <path d="M124.106 108.858H124.106C123.51 108.858 123.027 109.341 123.027 109.937V114.393C123.027 114.989 123.51 115.471 124.106 115.471H124.106C124.701 115.471 125.184 114.989 125.184 114.393V109.937C125.184 109.341 124.701 108.858 124.106 108.858Z" fill="#3F3D56"/>
      <path d="M118.643 108.858H118.643C118.047 108.858 117.564 109.341 117.564 109.937V114.393C117.564 114.989 118.047 115.471 118.643 115.471H118.643C119.238 115.471 119.721 114.989 119.721 114.393V109.937C119.721 109.341 119.238 108.858 118.643 108.858Z" fill="#3F3D56"/>
      <path d="M109.875 86.5691L132.014 85.5628L128.564 83.9814L109.156 84.269L109.875 86.5691Z" fill="#F7B80B"/>
      <path d="M101.951 83.4722L100.945 61.333L99.3633 64.7833L99.6508 84.191L101.951 83.4722Z" fill="#F7B80B"/>
      <path d="M198.143 88.8032L195.47 87.7969L112.462 89.9533L109.012 88.3719L110.449 98.1476L176.386 91.1216L198.143 88.8032Z" fill="#F7B80B"/>
      <path d="M176.795 94.4822L176.386 91.1221L110.449 98.1481L110.809 103.108L176.795 94.4822Z" fill="#3F3D56"/>
      <path d="M132.444 104.402C136.215 104.402 139.272 101.151 139.272 97.1418C139.272 93.1322 136.215 89.8818 132.444 89.8818C128.673 89.8818 125.615 93.1322 125.615 97.1418C125.615 101.151 128.673 104.402 132.444 104.402Z" fill="#3F3D56"/>
      <path d="M132.444 100.88C135.223 100.88 137.475 98.6269 137.475 95.848C137.475 93.0691 135.223 90.8164 132.444 90.8164C129.665 90.8164 127.412 93.0691 127.412 95.848C127.412 98.6269 129.665 100.88 132.444 100.88Z" fill="#F7B80B"/>
      <path d="M111.994 83.8115L111.668 86.2162L111.498 99.1604C111.485 100.124 111.369 101.075 111.15 101.998C110.521 104.687 109.05 107.132 106.878 108.905C104.907 110.515 102.218 111.734 98.6603 111.518C89.1721 110.943 88.334 102.868 88.334 102.868L88.692 88.2245L88.7408 86.2162L89.1721 82.5924C89.1721 82.5924 90.3222 72.3854 100.96 72.673C111.599 72.9605 111.994 83.8115 111.994 83.8115V83.8115Z" fill="#F7B80B"/>
      <path d="M91.4727 80.4359L93.6291 76.9856L96.3605 76.123H105.705L108.005 76.9856L110.093 80.4359L106.28 78.7107H95.5851L91.4727 80.4359Z" fill="#E6E6E6"/>
      <path d="M99.3806 105.241L99.7495 115.017H98.8516V116.124H101.361V115.017H100.629L100.682 105.241H99.3806Z" fill="#3F3D56"/>
      <path d="M98.4124 113.449C98.7944 113.449 99.1041 113.759 99.1041 114.141V117C99.1041 117.382 98.7944 117.692 98.4124 117.692C98.0304 117.692 97.7207 117.382 97.7207 117V114.141C97.7207 113.759 98.0304 113.449 98.4124 113.449Z" fill="#3F3D56"/>
      <path d="M101.916 113.449C102.298 113.449 102.608 113.759 102.608 114.141V117C102.608 117.382 102.298 117.692 101.916 117.692C101.534 117.692 101.225 117.382 101.225 117V114.141C101.225 113.759 101.534 113.449 101.916 113.449V113.449Z" fill="#3F3D56"/>
      <path d="M96.7049 74.0036L94.7188 81.1543L95.1015 81.2606L97.0876 74.1099L96.7049 74.0036Z" fill="#F7B80B"/>
      <path d="M105.71 75.1451L105.32 75.2217L106.751 82.5038L107.141 82.4272L105.71 75.1451Z" fill="#F7B80B"/>
      <path d="M146.637 63.721C148.063 63.721 149.219 62.5649 149.219 61.1388C149.219 59.7127 148.063 58.5566 146.637 58.5566C145.211 58.5566 144.055 59.7127 144.055 61.1388C144.055 62.5649 145.211 63.721 146.637 63.721Z" fill="#F7B80B"/>
      <path d="M75.1662 72.1731C76.5923 72.1731 77.7483 71.0171 77.7483 69.591C77.7483 68.1649 76.5923 67.0088 75.1662 67.0088C73.7401 67.0088 72.584 68.1649 72.584 69.591C72.584 71.0171 73.7401 72.1731 75.1662 72.1731Z" fill="#F7B80B"/>
      <path d="M16.6992 118.057C16.6992 118.191 16.85 118.299 17.0377 118.299H75.1412C75.3289 118.299 75.4797 118.191 75.4797 118.057C75.4797 117.922 75.3289 117.814 75.1412 117.814H17.0377C16.85 117.814 16.6992 117.922 16.6992 118.057Z" fill="#E6E6E6"/>
      <path d="M39.7736 120.33C41.1997 120.33 42.3558 119.174 42.3558 117.748C42.3558 116.322 41.1997 115.166 39.7736 115.166C38.3475 115.166 37.1914 116.322 37.1914 117.748C37.1914 119.174 38.3475 120.33 39.7736 120.33Z" fill="#F7B80B"/>
    </g>
    <defs>
      <clipPath id="clip0_1219_48966">
        <rect width="197.131" height="124.15" fill="white" transform="translate(0.759766 0.265625)"/>
      </clipPath>
    </defs>
  </svg>

);