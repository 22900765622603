import { Flex, TabList, TabPanels, Tabs } from "@chakra-ui/react";
import { useState } from "react";
import BottomBlock from "../../components/BottomActionsBlock/BottomBlock";
import OutlineButton from "../../components/Buttons/OutlineButton";
import ContactUsBlock from "../../components/ContactUsBlock";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import ContentLayout from "../../components/Layout/ContentLayout";
import GrayLayout from "../../components/Layout/GrayLayout";
import MainContainer from "../../components/Layout/MainContainer";
import { Icons } from "../../components/PicturesVariant/PicturesVariant";
import TotalCard from "../../components/TotalCard/TotalCard";

function RoadshowContent({
  activeItem,
  items,
  onChoose,
}: {
  activeItem: string | undefined;
  items: string[];
  onChoose: (title: string) => void;
}) {
  return (
    <Tabs
      direction="rtl"
      orientation="vertical"
      variant="unstyled"
      gap="60px"
      alignItems="stretch"
      sx={{
        ".grayLayout": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "24px",
          height: "-webkit-fill-available",
          "@media(max-width: 1600px)": {
            height: "auto",
          },
        },
        ".chakra-tabs__tab": {
          padding: 0,
          width: "100%",
        },
        ".chakra-tabs__tab-panels": {
          "& > div": {
            padding: 0,
          },
        },
      }}
    >
      <TabList>
        <GrayLayout>
          {items.map((item) => (
            <OutlineButton
              w="100%"
              py="50px"
              textTransform="uppercase"
              active={activeItem === item}
              onClick={() => onChoose(item)}
            >
              {item}
            </OutlineButton>
          ))}
        </GrayLayout>
      </TabList>
      <TabPanels>
        {activeItem && (
          <ContactUsBlock
            title="Votre demande nécessite l’intervention d’un conseiller"
            description="Contactez le par mail, par téléphone ou demandez à vous faire rappeler afin d’assurer une gestion optimale de votre événement !"
            icon={Icons.CONTACT_US_ICON}
          />
        )}
      </TabPanels>
    </Tabs>
  );
}

export default function OnboardingRoadshow() {
  const [activeItem, setActiveItem] = useState<string | undefined>();

  return (
    <Layout>
      <ContentLayout>
        <MainContainer>
          <Flex flexDir="column">
            <Header
              title="COMMENT S’ORGANISE LE SOURCING DE L’ÉQUIPE"
              subtitle="Précisez comment s’organisera le sourcing de l’équipe !"
            />
            <RoadshowContent
              activeItem={activeItem}
              items={[
                "La même équipe fait la même tournée",
                "Equipe locale avec un titulaire Norela",
                "Les deux",
              ]}
              onChoose={setActiveItem}
            />
          </Flex>
          <BottomBlock />
          <TotalCard />
        </MainContainer>
      </ContentLayout>
    </Layout>
  );
}
