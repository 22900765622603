import { Box, BoxProps } from "@chakra-ui/react";

export default function ButtonCheckbox({
  handleChange,
  active,
  name,
  value,
  text,
  ...props
}: {
  handleChange: (e: any) => void;
  active: boolean;
  name: string;
  value: string;
  text: string;
} & BoxProps) {
  return (
    <label style={{ display: "flex" }}>
      <input
        type="checkbox"
        onChange={handleChange}
        name={name}
        value={value}
        style={{ maxWidth: 0, maxHeight: 0 }}
      />
      <Box
        p="4px 10px"
        bg={active ? "#033349" : "white"}
        color={active ? "white" : "#033349"}
        border="solid 1px"
        borderColor={active ? undefined : "#033349"}
        borderRadius="8px"
        userSelect="none"
        cursor="pointer"
        transition=".3s"
        _hover={{
          filter: active ? "brightness(1.5)" : "brightness(0.9)",
        }}
        {...props}
      >
        {text}
      </Box>
    </label>
  );
}
