export const CalendarSelectionDetailleIcon = () => (
  <svg width="107" height="100" viewBox="0 0 107 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1506_78543)">
      <path d="M98.8826 76.5021L103.679 77.2517C105.303 75.2859 106.422 72.9535 106.939 70.457C103.432 73.3279 98.0987 71.8023 93.8459 73.3697C92.5232 73.8687 91.3298 74.659 90.3541 75.6819C89.3783 76.7049 88.6453 77.9342 88.2093 79.279L85.8652 81.8009C87.9871 82.641 90.2659 83.011 92.5445 82.8856C94.8231 82.7601 97.0475 82.1422 99.0643 81.0743C100.19 80.464 101.234 79.7144 102.172 78.8432C100.494 77.7864 98.8826 76.5021 98.8826 76.5021Z" fill="#F2F2F2"/>
      <path d="M97.1805 68.832L101.808 67.3637C102.384 64.8802 102.346 62.2934 101.696 59.8281C99.837 63.9619 94.3828 64.9751 91.2754 68.2746C90.3141 69.3111 89.5984 70.5507 89.1813 71.9014C88.7642 73.2521 88.6564 74.6794 88.8659 76.0775L87.8926 79.3801C90.1664 79.1857 92.371 78.5006 94.3545 77.3721C96.3381 76.2436 98.0534 74.6984 99.3823 72.8432C100.117 71.795 100.718 70.6584 101.169 69.4602C99.1955 69.2628 97.1805 68.832 97.1805 68.832Z" fill="#F2F2F2"/>
      <path d="M106.966 83.3289C106.966 83.3537 106.961 83.3782 106.951 83.4011C106.942 83.424 106.928 83.4448 106.911 83.4623C106.893 83.4798 106.872 83.4936 106.85 83.5031C106.827 83.5125 106.802 83.5173 106.777 83.5172H0.235182C0.18524 83.5172 0.137343 83.4974 0.102029 83.4621C0.0667142 83.4268 0.046875 83.3789 0.046875 83.3289C0.046875 83.279 0.0667142 83.2311 0.102029 83.1958C0.137343 83.1605 0.18524 83.1406 0.235182 83.1406H106.777C106.802 83.1406 106.827 83.1454 106.85 83.1548C106.872 83.1643 106.893 83.1781 106.911 83.1956C106.928 83.2131 106.942 83.2339 106.951 83.2568C106.961 83.2797 106.966 83.3042 106.966 83.3289Z" fill="#CCCCCC"/>
      <path d="M44.1957 75.3015C62.9854 75.3015 78.2175 60.0694 78.2175 41.2797C78.2175 22.4899 62.9854 7.25781 44.1957 7.25781C25.4059 7.25781 10.1738 22.4899 10.1738 41.2797C10.1738 60.0694 25.4059 75.3015 44.1957 75.3015Z" fill="#E6E6E6"/>
      <path d="M73.4711 41.2805C73.4745 45.8484 72.4075 50.3535 70.3555 54.4347C68.3036 58.5158 65.3238 62.0592 61.6552 64.7809C61.41 64.9644 61.1599 65.1432 60.9083 65.3173C56.0026 68.7274 50.171 70.5551 44.1965 70.5551C38.2219 70.5551 32.3904 68.7274 27.4846 65.3173C27.233 65.1432 26.983 64.9644 26.7377 64.7809C23.0717 62.0568 20.0938 58.5129 18.0421 54.4323C15.9904 50.3518 14.9219 45.8478 14.9219 41.2805C14.9219 36.7132 15.9904 32.2092 18.0421 28.1286C20.0938 24.0481 23.0717 20.5041 26.7377 17.7801C26.983 17.5965 27.233 17.4177 27.4846 17.2436C32.3904 13.8336 38.2219 12.0059 44.1965 12.0059C50.171 12.0059 56.0026 13.8336 60.9083 17.2436C61.1599 17.4177 61.41 17.5965 61.6552 17.7801C65.3238 20.5017 68.3036 24.0452 70.3555 28.1263C72.4075 32.2074 73.4745 36.7125 73.4711 41.2805Z" fill="white"/>
      <path d="M44.1959 68.0226C58.9655 68.0226 70.9386 56.0495 70.9386 41.2799C70.9386 26.5102 58.9655 14.5371 44.1959 14.5371C29.4263 14.5371 17.4531 26.5102 17.4531 41.2799C17.4531 56.0495 29.4263 68.0226 44.1959 68.0226Z" fill="#F7B80B"/>
      <path opacity="0.2" d="M44.2656 14.6113C43.8602 14.6113 43.4573 14.6219 43.0563 14.6398C49.8154 15.2337 56.0959 18.3748 60.6256 23.4265C65.1553 28.4782 67.5953 35.0629 67.4513 41.8465C67.3073 48.6301 64.5901 55.1053 59.8502 59.9603C55.1102 64.8153 48.702 67.687 41.9238 67.9936C42.6957 68.0606 43.4764 68.0968 44.2656 68.0968C51.3582 68.0968 58.1603 65.2793 63.1756 60.2641C68.1908 55.2488 71.0083 48.4467 71.0083 41.3541C71.0083 34.2615 68.1908 27.4593 63.1756 22.4441C58.1603 17.4289 51.3582 14.6113 44.2656 14.6113V14.6113Z" fill="black"/>
      <path d="M44.2746 12.6387C44.0439 12.6391 43.8228 12.7309 43.6597 12.894C43.4965 13.0572 43.4047 13.2783 43.4043 13.509V17.3068C43.4043 17.5376 43.496 17.759 43.6592 17.9222C43.8224 18.0854 44.0438 18.1771 44.2746 18.1771C44.5054 18.1771 44.7268 18.0854 44.89 17.9222C45.0533 17.759 45.1449 17.5376 45.1449 17.3068V13.509C45.1445 13.2783 45.0527 13.0572 44.8896 12.894C44.7265 12.7309 44.5053 12.6391 44.2746 12.6387Z" fill="white"/>
      <path d="M61.6548 17.7785C61.4095 17.595 61.1595 17.4161 60.9079 17.2421C60.7569 17.2322 60.606 17.2619 60.47 17.3283C60.334 17.3946 60.2177 17.4952 60.1325 17.6203L57.9947 20.7582C57.8648 20.9489 57.816 21.1835 57.859 21.4102C57.902 21.6369 58.0334 21.8373 58.2241 21.9672C58.4149 22.097 58.6494 22.1458 58.8761 22.1028C59.1029 22.0598 59.3032 21.9285 59.4331 21.7377L61.5709 18.5998C61.6523 18.4807 61.7025 18.3432 61.7172 18.1998C61.7318 18.0564 61.7104 17.9115 61.6548 17.7785Z" fill="white"/>
      <path d="M71.3681 32.087C71.3251 31.9812 71.2617 31.8849 71.1815 31.8036C71.1012 31.7223 71.0058 31.6576 70.9006 31.6132C70.7954 31.5689 70.6824 31.5457 70.5682 31.5449C70.454 31.5442 70.3408 31.566 70.2351 31.6091L66.7158 33.038C66.5021 33.125 66.3318 33.2933 66.2422 33.5058C66.1526 33.7184 66.151 33.9578 66.2378 34.1715C66.3247 34.3852 66.4928 34.5557 66.7052 34.6455C66.9177 34.7353 67.1571 34.7371 67.3709 34.6505L70.8902 33.2215C71.1037 33.1341 71.2739 32.9656 71.3634 32.7529C71.453 32.5403 71.4547 32.3008 71.3681 32.087Z" fill="white"/>
      <path d="M30.3969 20.7582L28.2591 17.6203C28.1739 17.4952 28.0576 17.3946 27.9216 17.3283C27.7856 17.2619 27.6347 17.2322 27.4837 17.2421C27.2321 17.4161 26.9821 17.595 26.7368 17.7785C26.6812 17.9115 26.6597 18.0564 26.6744 18.1998C26.689 18.3432 26.7393 18.4807 26.8207 18.5998L28.9585 21.7377C29.0884 21.9285 29.2887 22.0598 29.5155 22.1028C29.7422 22.1458 29.9767 22.097 30.1675 21.9672C30.3582 21.8373 30.4895 21.6369 30.5326 21.4102C30.5756 21.1835 30.5268 20.9489 30.3969 20.7582Z" fill="white"/>
      <path d="M21.6759 33.0378L18.1566 31.6089C17.9428 31.522 17.7033 31.5237 17.4907 31.6135C17.2781 31.7033 17.1098 31.8738 17.023 32.0876C16.9361 32.3014 16.9377 32.5409 17.0275 32.7536C17.1172 32.9662 17.2877 33.1344 17.5015 33.2213L21.0208 34.6502C21.2346 34.7369 21.474 34.7351 21.6865 34.6453C21.8989 34.5555 22.067 34.385 22.1539 34.1713C22.2407 33.9576 22.2391 33.7181 22.1495 33.5056C22.0599 33.2931 21.8895 33.1248 21.6759 33.0378V33.0378Z" fill="white"/>
      <path d="M44.2746 64.3828C44.0439 64.3832 43.8228 64.475 43.6597 64.6382C43.4965 64.8013 43.4047 65.0224 43.4043 65.2531V69.0509C43.4043 69.2818 43.496 69.5031 43.6592 69.6663C43.8224 69.8296 44.0438 69.9213 44.2746 69.9213C44.5054 69.9213 44.7268 69.8296 44.89 69.6663C45.0533 69.5031 45.1449 69.2818 45.1449 69.0509V65.2531C45.1445 65.0224 45.0527 64.8013 44.8896 64.6382C44.7265 64.475 44.5053 64.3832 44.2746 64.3828Z" fill="white"/>
      <path d="M61.5709 63.9597L59.4331 60.8218C59.3688 60.7273 59.2865 60.6465 59.1909 60.5838C59.0954 60.5212 58.9884 60.478 58.8761 60.4567C58.7639 60.4354 58.6485 60.4364 58.5366 60.4597C58.4248 60.4829 58.3186 60.528 58.2241 60.5923C58.1297 60.6566 58.0488 60.7389 57.9862 60.8345C57.9235 60.9301 57.8803 61.037 57.859 61.1493C57.8377 61.2616 57.8387 61.3769 57.862 61.4888C57.8853 61.6006 57.9304 61.7068 57.9947 61.8013L60.1325 64.9392C60.2177 65.0642 60.334 65.1649 60.47 65.2312C60.606 65.2975 60.7569 65.3273 60.9079 65.3174C61.1595 65.1433 61.4095 64.9645 61.6548 64.781C61.7104 64.6479 61.7318 64.5031 61.7172 64.3597C61.7026 64.2162 61.6523 64.0787 61.5709 63.9597Z" fill="white"/>
      <path d="M70.8888 49.3366L67.3695 47.9077C67.1557 47.8209 66.9162 47.8225 66.7036 47.9123C66.491 48.0021 66.3227 48.1726 66.2359 48.3864C66.149 48.6002 66.1506 48.8398 66.2404 49.0524C66.3301 49.265 66.5006 49.4333 66.7144 49.5202L70.2337 50.9491C70.4475 51.0357 70.6869 51.0339 70.8994 50.9441C71.1118 50.8543 71.2799 50.6838 71.3667 50.4701C71.4536 50.2564 71.452 50.017 71.3624 49.8044C71.2728 49.5919 71.1024 49.4236 70.8888 49.3366V49.3366Z" fill="white"/>
      <path d="M30.1675 60.5922C29.9767 60.4624 29.7422 60.4136 29.5155 60.4567C29.2888 60.4997 29.0884 60.631 28.9585 60.8217L26.8207 63.9596C26.7393 64.0787 26.689 64.2162 26.6744 64.3596C26.6597 64.503 26.6812 64.6479 26.7368 64.7809C26.9821 64.9644 27.2321 65.1433 27.4837 65.3173C27.6347 65.3272 27.7856 65.2975 27.9216 65.2311C28.0576 65.1648 28.1739 65.0642 28.2591 64.9391L30.3969 61.8012C30.4612 61.7068 30.5063 61.6006 30.5296 61.4887C30.5529 61.3768 30.5539 61.2615 30.5326 61.1492C30.5113 61.0369 30.4681 60.93 30.4054 60.8344C30.3428 60.7388 30.2619 60.6566 30.1675 60.5922Z" fill="white"/>
      <path d="M22.1552 48.3858C22.1122 48.28 22.0488 48.1837 21.9686 48.1024C21.8884 48.0211 21.7929 47.9565 21.6877 47.9121C21.5825 47.8677 21.4695 47.8445 21.3553 47.8438C21.2411 47.8431 21.1279 47.8648 21.0222 47.9079L17.5029 49.3368C17.2893 49.4238 17.1189 49.5921 17.0293 49.8046C16.9397 50.0172 16.9381 50.2566 17.0249 50.4703C17.1118 50.684 17.2799 50.8545 17.4923 50.9443C17.7048 51.0341 17.9442 51.0359 18.158 50.9493L21.6773 49.5204C21.8908 49.4329 22.061 49.2644 22.1505 49.0518C22.2401 48.8391 22.2418 48.5997 22.1552 48.3858Z" fill="white"/>
      <path d="M45.4035 40.8483C45.4839 41.324 45.3723 41.8122 45.0931 42.2057C44.814 42.5993 44.3902 42.866 43.9147 42.9475L28.4706 45.5745C28.235 45.6145 27.9939 45.6078 27.7609 45.5547C27.5279 45.5015 27.3076 45.403 27.1127 45.2648C26.9178 45.1265 26.752 44.9512 26.6248 44.7489C26.4976 44.5466 26.4115 44.3212 26.3715 44.0856C26.3314 43.85 26.3381 43.6088 26.3913 43.3758C26.4444 43.1429 26.5429 42.9226 26.6811 42.7277C26.8194 42.5328 26.9947 42.367 27.197 42.2398C27.3993 42.1126 27.6247 42.0265 27.8603 41.9864L43.3044 39.3594C43.7801 39.2791 44.2683 39.3907 44.6618 39.6698C45.0553 39.9489 45.3221 40.3728 45.4035 40.8483Z" fill="#3F3D56"/>
      <path d="M61.6577 57.9582C61.4359 58.1814 61.1346 58.3074 60.8199 58.3086C60.5052 58.3098 60.203 58.1862 59.9793 57.9648L43.3541 41.4699C43.1309 41.2482 43.005 40.9469 43.0039 40.6323C43.0028 40.3178 43.1267 40.0156 43.3482 39.7923C43.5698 39.569 43.8709 39.4428 44.1855 39.4414C44.5001 39.44 44.8023 39.5636 45.0258 39.7849L61.6511 56.2798C61.8743 56.5017 62.0003 56.803 62.0015 57.1177C62.0027 57.4323 61.8791 57.7346 61.6577 57.9582Z" fill="#3F3D56"/>
      <path d="M44.1958 43.3369C45.3319 43.3369 46.2529 42.4159 46.2529 41.2798C46.2529 40.1437 45.3319 39.2227 44.1958 39.2227C43.0597 39.2227 42.1387 40.1437 42.1387 41.2798C42.1387 42.4159 43.0597 43.3369 44.1958 43.3369Z" fill="#CCCCCC"/>
      <path d="M30.0265 5.60805C26.8689 10.5725 22.0509 13.8671 16.1093 16.0315C15.9919 16.1194 15.8583 16.1833 15.7162 16.2197C15.574 16.256 15.4261 16.2639 15.2809 16.2431C15.1357 16.2222 14.9961 16.173 14.8699 16.0982C14.7437 16.0234 14.6335 15.9244 14.5456 15.807C11.3181 11.4977 12.0469 5.33887 16.3065 2.04607C17.3363 1.25136 18.5137 0.669139 19.7704 0.333196C21.0271 -0.00274612 22.3379 -0.0857162 23.6269 0.0891051C24.9159 0.263926 26.1574 0.693055 27.2792 1.35158C28.401 2.0101 29.3808 2.8849 30.1617 3.92519L30.251 4.04438C30.3389 4.16179 30.4029 4.29537 30.4392 4.4375C30.4755 4.57962 30.4835 4.7275 30.4626 4.8727C30.4418 5.0179 30.3925 5.15758 30.3177 5.28375C30.2429 5.40992 30.1439 5.52012 30.0265 5.60805Z" fill="#E6E6E6"/>
      <path d="M58.2302 3.92519C59.0111 2.8849 59.9909 2.0101 61.1127 1.35158C62.2345 0.693053 63.476 0.263927 64.765 0.0891058C66.054 -0.0857156 67.3649 -0.00274785 68.6215 0.333194C69.8782 0.669137 71.0556 1.25136 72.0854 2.04606C76.345 5.33887 77.0738 11.4977 73.8463 15.807C73.7584 15.9244 73.6482 16.0234 73.522 16.0982C73.3958 16.173 73.2562 16.2222 73.111 16.2431C72.9658 16.2639 72.8179 16.256 72.6758 16.2197C72.5336 16.1833 72.4001 16.1194 72.2826 16.0315C66.7668 13.9197 62.2435 10.2652 58.3654 5.60805C58.1283 5.43045 57.9714 5.16594 57.9293 4.8727C57.8872 4.57945 57.9633 4.2815 58.1409 4.04438L58.2302 3.92519Z" fill="#E6E6E6"/>
      <path d="M56.8451 70.7548C56.644 70.8678 56.4959 71.056 56.4332 71.278C56.3705 71.5 56.3984 71.7378 56.5107 71.9393L61.5335 80.9152C61.6463 81.1165 61.8344 81.2649 62.0565 81.3276C62.2786 81.3903 62.5165 81.3622 62.7179 81.2495C62.9193 81.1368 63.0677 80.9487 63.1305 80.7266C63.1932 80.5045 63.1652 80.2666 63.0525 80.0651L58.0297 71.0893C57.9166 70.8882 57.7285 70.74 57.5065 70.6773C57.2845 70.6146 57.0467 70.6425 56.8451 70.7548Z" fill="#E6E6E6"/>
      <path d="M30.3602 71.09L25.3373 80.0659C25.2246 80.2673 25.1966 80.5052 25.2593 80.7273C25.3221 80.9494 25.4705 81.1375 25.6719 81.2502C25.8733 81.3629 26.1112 81.391 26.3333 81.3283C26.5554 81.2656 26.7435 81.1173 26.8563 80.9159L31.8792 71.94C31.935 71.8403 31.9707 71.7305 31.9841 71.617C31.9975 71.5035 31.9884 71.3884 31.9574 71.2784C31.9263 71.1684 31.8739 71.0656 31.8031 70.9759C31.7323 70.8861 31.6445 70.8112 31.5448 70.7554C31.445 70.6995 31.3352 70.6639 31.2217 70.6505C31.1082 70.6371 30.9932 70.6462 30.8832 70.6773C30.7731 70.7084 30.6703 70.7608 30.5806 70.8316C30.4909 70.9025 30.416 70.9903 30.3602 71.09H30.3602Z" fill="#E6E6E6"/>
      <path d="M62.5514 83.2142C64.0371 83.2142 65.2415 82.0098 65.2415 80.5241C65.2415 79.0384 64.0371 77.834 62.5514 77.834C61.0657 77.834 59.8613 79.0384 59.8613 80.5241C59.8613 82.0098 61.0657 83.2142 62.5514 83.2142Z" fill="#E6E6E6"/>
      <path d="M64.4499 14.3782C65.9356 14.3782 67.14 13.1738 67.14 11.6881C67.14 10.2024 65.9356 8.99805 64.4499 8.99805C62.9642 8.99805 61.7598 10.2024 61.7598 11.6881C61.7598 13.1738 62.9642 14.3782 64.4499 14.3782Z" fill="#E6E6E6"/>
      <path d="M44.0378 8.04817C45.5235 8.04817 46.7279 6.84377 46.7279 5.35807C46.7279 3.87237 45.5235 2.66797 44.0378 2.66797C42.5521 2.66797 41.3477 3.87237 41.3477 5.35807C41.3477 6.84377 42.5521 8.04817 44.0378 8.04817Z" fill="#E6E6E6"/>
      <path d="M24.5749 14.0618C26.0606 14.0618 27.265 12.8574 27.265 11.3717C27.265 9.88604 26.0606 8.68164 24.5749 8.68164C23.0892 8.68164 21.8848 9.88604 21.8848 11.3717C21.8848 12.8574 23.0892 14.0618 24.5749 14.0618Z" fill="#E6E6E6"/>
      <path d="M26.3151 83.2142C27.8008 83.2142 29.0052 82.0098 29.0052 80.5241C29.0052 79.0384 27.8008 77.834 26.3151 77.834C24.8294 77.834 23.625 79.0384 23.625 80.5241C23.625 82.0098 24.8294 83.2142 26.3151 83.2142Z" fill="#E6E6E6"/>
      <path d="M60.4424 56.4451C60.5484 56.6111 60.6896 56.7519 60.8559 56.8575C61.0222 56.9631 61.2097 57.0309 61.4051 57.0562C61.6004 57.0815 61.799 57.0636 61.9867 57.0039C62.1745 56.9442 62.3468 56.844 62.4916 56.7104L66.7128 58.9722L66.4864 56.494L62.5127 54.7261C62.2722 54.4976 61.9562 54.3653 61.6247 54.3543C61.2932 54.3432 60.9692 54.4541 60.714 54.666C60.4588 54.8779 60.2902 55.176 60.2401 55.5039C60.1901 55.8318 60.262 56.1667 60.4424 56.4451H60.4424Z" fill="#FFB7B7"/>
      <path d="M63.5176 57.3107L65.1718 55.0273L73.1408 58.7945L83.0573 56.9339C83.5474 56.8419 84.0518 56.859 84.5347 56.9839C85.0175 57.1088 85.4669 57.3385 85.851 57.6566C86.2351 57.9747 86.5444 58.3735 86.7571 58.8246C86.9697 59.2757 87.0804 59.7681 87.0814 60.2668C87.082 61.1676 86.7254 62.0319 86.0896 62.67C85.4538 63.3082 84.5909 63.668 83.6901 63.6707L72.3258 63.6918L63.5176 57.3107Z" fill="#3F3D56"/>
      <path d="M60.9686 96.749L59.2949 95.9487L61.594 88.9531L64.064 90.1346L60.9686 96.749Z" fill="#FFB7B7"/>
      <path d="M72.1898 95.6203C72.0681 95.6966 71.9464 95.7715 71.8234 95.8465C71.5788 95.9965 71.3317 96.144 71.0846 96.2891C70.9604 96.3616 70.835 96.4342 70.7109 96.5055V96.5091L70.7035 96.5104L70.701 96.5116L70.5117 96.5583L70.24 95.8404L70.1146 95.5097L69.9376 95.0413L69.6131 94.1845L67.9043 89.6728L70.5609 89.0176L70.6605 89.4245H70.6617L70.7109 89.6261L71.639 93.3903L71.8283 94.1599L71.8345 94.1832L71.9488 94.643L72.0472 95.0413L72.1566 95.4864L72.1898 95.6203Z" fill="#FFB7B7"/>
      <path d="M88.0373 79.7764C87.8689 80.0468 87.6968 80.316 87.5234 80.5828C87.2013 81.0795 86.8707 81.5687 86.5314 82.0506C86.5314 82.0519 86.5301 82.0519 86.5289 82.0531C86.3309 82.2365 86.1123 82.3961 85.8774 82.5288C85.8675 82.5362 85.8565 82.5411 85.8466 82.5473C85.6736 82.6429 85.4895 82.7168 85.2983 82.7673C85.2295 82.7845 85.1594 82.8018 85.0906 82.819C84.8423 82.878 84.5964 82.9284 84.3517 82.9726C83.9166 83.0501 83.4863 83.1054 83.0585 83.1398C83.0241 83.1435 82.9897 83.146 82.9552 83.1484C81.1961 83.2775 79.4836 83.0697 77.6961 82.7292C76.8135 82.562 75.9124 82.3616 74.9793 82.1539C74.6744 82.0851 74.3684 82.0174 74.0586 81.9498C74.0352 81.9449 74.0106 81.9388 73.986 81.9338C73.5435 81.8367 73.0935 81.7408 72.6325 81.6462C72.0523 81.5269 71.4536 81.4114 70.8315 81.302L70.8758 82.1785C70.9126 82.8528 71.0528 83.5174 71.2913 84.1491C71.5569 84.926 71.7671 85.5407 71.3233 86.8204L71.1094 87.44C71.2203 87.7477 71.2583 88.0769 71.2206 88.4017C71.1828 88.7266 71.0703 89.0383 70.8918 89.3123L70.8488 89.3775L72.6325 93.8903L72.7481 94.1829H71.8347L70.7111 94.1842H68.8068L66.0593 88.6718L65.9277 88.4087V88.3952L66.2117 77.8021C66.2154 77.7086 66.2228 77.614 66.2351 77.5205C66.2852 77.0958 66.4193 76.6853 66.6298 76.313C66.8402 75.9407 67.1227 75.614 67.4607 75.352C67.7846 75.0988 68.1557 74.9126 68.5522 74.8041C68.9488 74.6957 69.3629 74.6671 69.7706 74.7201L70.7111 74.8418L72.6325 75.0902L73.986 75.266L74.0131 75.2696L78.0047 75.786L78.2911 74.6181L78.344 74.5984L80.0245 73.9825L85.8368 71.8521L85.8515 71.8472L86.2339 71.707L86.2793 71.7808C86.2929 71.8029 86.3064 71.825 86.3187 71.8472C86.6966 72.5246 87.0043 73.2389 87.237 73.9788C87.6284 75.1876 87.9505 76.4177 88.202 77.6632C88.2131 77.7172 88.2229 77.7664 88.2315 77.8107L88.2352 77.8242C88.2389 77.8451 88.2414 77.8586 88.2426 77.8623C88.3766 78.4204 88.2832 79.1015 88.0373 79.7764Z" fill="#2F2E41"/>
      <path d="M88.2822 75.8734C88.2637 75.7947 88.2404 75.7148 88.2158 75.6374C88.0206 75.0508 87.7933 74.4754 87.5347 73.9138C87.2446 73.2746 86.8967 72.5898 86.4824 71.8486C86.2821 71.4896 86.0665 71.1163 85.8358 70.7286C85.7203 70.5344 85.6002 70.3365 85.4756 70.1349C85.4707 70.1256 85.4654 70.1166 85.4596 70.1078L85.424 70.0488L79.6732 70.8184L77.5317 71.1048L77.4038 72.1719L77.3731 72.4362C77.3718 72.4362 77.3718 72.4362 77.3731 72.4374L77.287 73.1578V73.159L77.1727 74.1105L77.1629 74.1093L73.985 73.6975L70.7101 73.2734L68.9509 73.046C68.5432 72.9928 68.129 73.0213 67.7325 73.1298C67.3359 73.2383 66.9649 73.4246 66.641 73.6778C66.303 73.9399 66.0206 74.2666 65.8102 74.6389C65.5997 75.0112 65.4655 75.4217 65.4154 75.8464C65.4043 75.9324 65.3969 76.0209 65.3932 76.1082L63.7336 80.0409C62.7405 82.3928 62.1081 84.8811 61.8577 87.4218L60.6406 91.3126L60.814 91.3286L63.3931 91.5683L64.0741 91.6322L65.5739 89.4428C65.7448 89.1932 65.9071 88.9363 66.0583 88.6732C66.3072 88.2436 66.5286 87.7985 66.7209 87.3406L69.9651 79.6204C70.2159 79.6635 70.4642 79.7044 70.7101 79.7434C71.8472 79.9278 72.9389 80.0782 73.985 80.1945C77.3866 80.5744 80.3321 80.6039 82.9542 80.2818C83.6906 80.1921 84.4024 80.074 85.0896 79.9278C85.3416 79.8749 85.5904 79.8179 85.8358 79.7569C85.8936 79.7434 85.9501 79.7298 86.0067 79.7151C86.5147 79.5841 86.9818 79.3277 87.3652 78.9695C87.7485 78.6112 88.0359 78.1626 88.201 77.6645C88.3982 77.0874 88.4263 76.466 88.2822 75.8734Z" fill="#2F2E41"/>
      <path d="M61.5049 95.6059L59.6941 94.738L59.4101 94.6016L58.9565 95.7153L55.7897 96.4124C55.6331 96.4467 55.4916 96.5302 55.3859 96.6507C55.2802 96.7711 55.2157 96.9223 55.202 97.082C55.1882 97.2417 55.2259 97.4016 55.3096 97.5384C55.3932 97.6751 55.5184 97.7815 55.6668 97.8421L58.761 99.1046L59.7433 98.2207L59.5023 99.407L60.6677 99.8828L61.945 95.8174L61.5049 95.6059Z" fill="#2F2E41"/>
      <path d="M72.6328 93.8906L72.7485 94.1832H72.6328V93.8906Z" fill="#2F2E41"/>
      <path d="M72.0029 94.2453L70.1055 94.9027L69.8077 95.0056L70.2691 96.1162L68.5093 98.8397C68.4222 98.9743 68.3804 99.1331 68.39 99.2932C68.3997 99.4532 68.4602 99.6059 68.5628 99.7291C68.6654 99.8522 68.8047 99.9393 68.9604 99.9776C69.116 100.016 69.2799 100.004 69.4279 99.9421L72.515 98.6621L72.591 97.3429L73.2545 98.3554L74.4174 97.8734L72.4644 94.0859L72.0029 94.2453Z" fill="#2F2E41"/>
      <path d="M87.8102 77.6536C87.6976 77.6534 87.5866 77.6259 87.487 77.5734L77.4743 72.3416L77.3587 71.8679L77.1406 70.3691L77.1436 70.3551L78.0959 65.9649C78.0784 65.9272 78.0419 65.8654 78.0007 65.7956C77.5756 65.0753 76.2819 62.8831 78.751 59.8646L83.0861 54.8105L86.8068 54.6504L86.8491 54.7039C87.844 55.9526 88.5149 57.4277 88.8023 58.9982C89.0897 60.5687 88.9846 62.1859 88.4964 63.706L88.109 70.7029L88.5246 72.1159C88.7675 72.9463 88.7273 73.8339 88.4104 74.639L88.5056 76.927C88.5093 77.0208 88.4941 77.1144 88.4609 77.2021C88.4276 77.2899 88.3771 77.3701 88.3122 77.4379C88.2473 77.5057 88.1694 77.5598 88.0831 77.5968C87.9969 77.6339 87.9041 77.6532 87.8102 77.6536L87.8102 77.6536Z" fill="#3F3D56"/>
      <path d="M79.1238 83.4248C79.2935 83.3246 79.4391 83.1885 79.5504 83.0259C79.6617 82.8634 79.736 82.6784 79.7681 82.484C79.8001 82.2896 79.7892 82.0906 79.736 81.9009C79.6829 81.7112 79.5887 81.5354 79.4603 81.386L81.8676 77.2461L79.3829 77.3861L77.4779 81.296C77.2412 81.5284 77.098 81.8396 77.0754 82.1705C77.0528 82.5014 77.1524 82.8291 77.3553 83.0915C77.5582 83.3539 77.8503 83.5328 78.1762 83.5942C78.5022 83.6557 78.8393 83.5954 79.1238 83.4249L79.1238 83.4248Z" fill="#FFB7B7"/>
      <path d="M80.0955 80.3802L77.8711 78.6476L81.9132 70.8145L80.3986 60.8392C80.3237 60.3461 80.3583 59.8426 80.5 59.3644C80.6416 58.8863 80.8868 58.4451 81.218 58.0723C81.5493 57.6995 81.9586 57.4042 82.4169 57.2074C82.8751 57.0106 83.3711 56.9171 83.8695 56.9335C84.7698 56.9641 85.6211 57.3506 86.2368 58.0082C86.8524 58.6658 87.182 59.5408 87.1533 60.4411L86.7791 71.7993L80.0955 80.3802Z" fill="#3F3D56"/>
      <path d="M80.6992 52.7102V49.0285C80.6984 47.72 81.2173 46.4648 82.142 45.539C83.0666 44.6132 84.3211 44.0926 85.6295 44.0918C86.938 44.091 88.1932 44.6099 89.119 45.5345C90.0448 46.4592 90.5654 47.7137 90.5662 49.0221V49.0285C91.4357 50.8404 90.8917 51.7019 90.5662 52.7102C90.5659 52.8858 90.496 53.0541 90.3719 53.1783C90.2477 53.3025 90.0794 53.3723 89.9038 53.3726H81.362C81.1863 53.3724 81.0179 53.3026 80.8937 53.1784C80.7694 53.0542 80.6995 52.8859 80.6992 52.7102Z" fill="#2F2E41"/>
      <path d="M84.0152 53.1338C86.0547 53.1338 87.7081 51.4805 87.7081 49.4409C87.7081 47.4014 86.0547 45.748 84.0152 45.748C81.9756 45.748 80.3223 47.4014 80.3223 49.4409C80.3223 51.4805 81.9756 53.1338 84.0152 53.1338Z" fill="#FFB7B7"/>
      <path d="M87.8794 50.4085C87.846 50.4032 87.8125 50.3962 87.779 50.3909C87.203 50.2887 86.627 50.1883 86.0527 50.0844L85.9998 48.7826L85.304 49.9487C83.7168 50.4755 82.2318 50.1778 80.8349 49.5207C80.3741 49.3019 79.927 49.0555 79.4961 48.7826C79.6134 48.0626 79.8903 47.378 80.3063 46.7788C80.7223 46.1795 81.2671 45.6809 81.9007 45.3193C81.9535 45.2894 82.0046 45.263 82.0574 45.2365C82.0577 45.2365 82.0579 45.2365 82.0581 45.2364C82.0583 45.2363 82.0585 45.2362 82.0587 45.236C82.0588 45.2359 82.059 45.2357 82.0591 45.2354C82.0591 45.2352 82.0592 45.235 82.0592 45.2348C82.149 45.1925 82.2406 45.152 82.3322 45.115C83.0494 44.8347 83.8313 44.7638 84.5872 44.9103C85.3431 45.0569 86.0419 45.4149 86.6023 45.9429C87.7297 47.0193 88.237 48.7967 87.8794 50.4085Z" fill="#2F2E41"/>
    </g>
    <defs>
      <clipPath id="clip0_1506_78543">
        <rect width="106.919" height="100" fill="white" transform="translate(0.046875)"/>
      </clipPath>
    </defs>
  </svg>

);