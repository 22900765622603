import { Button, Flex } from "@chakra-ui/react";
import AvatarFemmeIcon1 from "../Icons/AvatarFemmeIcon1";
import SpeechBubbleArrow from "../Icons/SpeechBubbleArrowIcon";
import { useEffect, useState } from "react";
import { useSegment } from "../../context/SegmentContext";

export default function HelpBubble({
  onClick,
  children,
}: {
  onClick?: () => void;
  children?: React.ReactNode;
}) {
  const [save, setSave] = useState<React.ReactNode>();
  const { track } = useSegment();
  const handleClick = () => {
    let trackContent = children?.toString();
    if (!trackContent) trackContent = "error";
    track("Close help bubble", { content: trackContent });
    onClick && onClick();
  };
  useEffect(() => {
    if (children) setSave(children);
  }, [children]);
  return (
    <Flex
      display="flex"
      position="absolute"
      top="50"
      right="0"
      direction="row"
      transition="linear 1000ms"
      transform={
        children ? "translate(-2rem, -100%)" : "translate(520%, -100%)"
      }
    >
      <Flex
        position="absolute"
        width="250px"
        left="1rem"
        bg="white"
        bottom="12rem"
        flexDir="column"
        transform="translateX(-75%)"
        borderRadius="12px"
        border="solid 0.5px #D9D9D9"
        padding="16px 24px"
      >
        <Button width="10px" height="30px" onClick={handleClick}>
          x
        </Button>
        {save}
        <SpeechBubbleArrow
          position="absolute"
          bottom="0px"
          left="11rem"
          transform="translateY(80%)"
          width="60px"
        />
      </Flex>
      <AvatarFemmeIcon1 />
    </Flex>
  );
}
