import "react-dates/lib/css/_datepicker.css";
import {
  DaySchedule,
  EventSchedule,
  ORGANISATION_TYPE,
  PersonnelType,
  SpecialSchedulesType,
} from "../../context/UserContext";
import * as Sentry from "@sentry/react";
import {
  create3HoursShift,
  determineOrganisationType,
} from "../../utils/utils";

export const EVENT_BREAK_MANAGEMENT_VALUES = [
  [
    "Laisser les heures de pause",
    "une pause est obligatoire dans toute mission de plus de 6 heures",
  ],
  // "Hotesse volante",
  [
    "Rotation de personnel",
    "un roulement sera mis en place afin de couvrir l'ensemble de la mission sans pause",
  ],
];

export function createSpecialSchedulesObject(
  startHour?: string
): SpecialSchedulesType {
  return [
    {
      PersonnelType: PersonnelType.emargement,
      schedule: {
        isWorking: true,
        startHour: startHour,
        endHour: startHour ? create3HoursShift(startHour) : undefined,
        organisationType: ORGANISATION_TYPE.NONE,
      },
    },
  ];
}

export function updateSpecialSchedulesObject(
  current: SpecialSchedulesType,
  startHour?: string
): SpecialSchedulesType {
  for (const elem of current) {
    if (elem.PersonnelType === PersonnelType.emargement) {
      elem.schedule.startHour = startHour;
      elem.schedule.endHour = startHour
        ? create3HoursShift(startHour)
        : undefined;
    }
  }
  return current;
}

export function updateEventScheduleObject(
  previous: EventSchedule[] | undefined,
  recent: EventSchedule[]
): EventSchedule[] {
  if (!previous) return recent;
  const result = recent.map((elem) => elem);
  for (const elem of result) {
    const data = previous.find((e) => e.day === elem.day);
    if (data) {
      elem.schedule = data.schedule;
    }
  }
  return result;
}

export function createEventScheduleObject(
  days: string[],
  startHour?: string,
  endHour?: string,
  breakTime?: string,
  swapTime?: string
): EventSchedule[] {
  Sentry.setContext("SelectedDates", {
    dates: days,
  });
  const eventSchedule: EventSchedule[] = days.map((elem) => {
    const specialSchedules: SpecialSchedulesType =
      createSpecialSchedulesObject(startHour);
    const organisationType = determineOrganisationType(startHour, endHour);
    const schedule: DaySchedule = {
      defaultEndHour: endHour,
      defaultStartHour: startHour,
      defaultBreakTime: breakTime,
      defaultSwapHour: swapTime,
      specialSchedules: specialSchedules,
      organisationType: organisationType,
    };
    // Sentry.setContext("SelectedDate", {
    //   startDate: elem,
    //   endDate: endDateMoment,
    // });
    // console.log(elem, schedule);
    return { day: elem, schedule: schedule };
  });
  return eventSchedule;
}

// export function eventDateInfoFromUserInfos(
//   userInfos: UserInformation
// ): EventDateInfo {
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   let days: { format: string; date: string }[] = [];
//   if (userInfos.calendarInfo?.period) {
//     const dayDiff = moment(userInfos.calendarInfo.period.endDate).diff(
//       moment(userInfos.calendarInfo.period.startDate),
//       "days"
//     );
//     const itMoment = moment(userInfos.calendarInfo.period.startDate).clone();
//     for (let i = 0; i <= dayDiff; i++, itMoment.add(1, "days")) {
//       days.push({
//         format: getMonthDayDateFormat(itMoment.toISOString()),
//         date: itMoment.toISOString(),
//       });
//     }
//   } else if (userInfos.calendarInfo?.days) {
//     days = userInfos.calendarInfo?.days.map((day) => {
//       return {
//         format: getMonthDayDateFormat(day),
//         date: day,
//       };
//     });
//   }
//   let eventSchedules: EventSchedule[] = [];
//   for (const day in userInfos?.calendarInfo?.eventSchedule) {
//     const eventSchedule
//     // const eventSchedule: EventSchedule[] = days.map((elem) => {
//     //   const specialSchedules: SpecialSchedulesType =
//     //     createSpecialSchedulesObject(startHour);
//     //   const organisationType = ORGANISATION_TYPE.NONE;
//     //   const shedule: DaySchedule = {
//     //     defaultEndHour: endHour,
//     //     defaultStartHour: startHour,
//     //     specialSchedules: specialSchedules,
//     //     organisationType: organisationType,
//     //   };
//     //   return { day: elem, schedule: shedule };

//   }

//   return {
//     days: userInfos.calendarInfo?.days || null,
//     period: userInfos.calendarInfo?.period
//       ? {
//           startDate: userInfos.calendarInfo?.period?.startDate,
//           endDate: userInfos.calendarInfo?.period?.endDate,
//         }
//       : null,
//     eventSchedule: eventSchedules,
//   };
// }

// export function userInfosFromEventDateInfo(
//   userInfos: UserInformation,
//   eventDateInfo: EventDateInfo
// ): UserInformation {
//   let workingHours = eventDateInfo.workingHours;
//   if (
//     !workingHours &&
//     eventDateInfo.defaultStartHour &&
//     eventDateInfo.defaultEndHour
//   )
//     workingHours = createSpecialSchedulesObject(
//       eventDateInfo?.defaultStartHour,
//       eventDateInfo?.defaultEndHour
//     );
//   return {
//     ...userInfos,
//     calendarInfo: {
//       days: eventDateInfo.days?.map((day) => day) ?? null,
//       period: eventDateInfo.period
//         ? {
//             startDate: eventDateInfo.period?.startDate,
//             endDate: eventDateInfo.period?.endDate,
//           }
//         : null,

//     },
//   };
// }
