export const OptionsPaniereIcon = () => (
  <svg
    width="190"
    height="100"
    viewBox="0 0 190 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M87.3387 97.9449L84.3311 97.9446L82.9004 86.3438L87.3393 86.3441L87.3387 97.9449Z"
      fill="#FFB6B6"
    />
    <path
      d="M77.0337 97.8757C76.94 98.0334 76.8906 98.5425 76.8906 98.7259C76.8906 99.2896 77.3476 99.7467 77.9114 99.7467H87.2256C87.6102 99.7467 87.9219 99.4349 87.9219 99.0503V98.6625C87.9219 98.6625 88.3827 97.497 87.4341 96.0605C87.4341 96.0605 86.2551 97.1853 84.4933 95.4236L83.9738 94.4824L80.2131 97.2328L78.1287 97.4893C77.6726 97.5455 77.2683 97.4806 77.0337 97.8757H77.0337Z"
      fill="#2F2E41"
    />
    <path
      d="M96.6356 97.9449L93.628 97.9446L92.1973 86.3438L96.6362 86.3441L96.6356 97.9449Z"
      fill="#FFB6B6"
    />
    <path
      d="M86.3306 97.8757C86.2369 98.0334 86.1875 98.5425 86.1875 98.7259C86.1875 99.2896 86.6445 99.7467 87.2083 99.7467H96.5224C96.907 99.7467 97.2188 99.4349 97.2188 99.0503V98.6625C97.2188 98.6625 97.6796 97.497 96.731 96.0605C96.731 96.0605 95.5519 97.1853 93.7902 95.4236L93.2707 94.4824L89.51 97.2328L87.4255 97.4893C86.9695 97.5455 86.5652 97.4806 86.3306 97.8757H86.3306Z"
      fill="#2F2E41"
    />
    <path
      d="M81.081 31.0234C81.081 31.0234 80.6454 33.8273 81.1499 35.6365L73.7422 48.7724L76.6238 50.8726L86.2469 38.7649L87.5805 32.7827L81.081 31.0234Z"
      fill="#FFB6B6"
    />
    <path
      d="M76.1825 52.7829C77.2033 50.9727 77.1245 48.9941 76.0064 48.3636C74.8883 47.733 73.1544 48.6894 72.1335 50.4996C71.1127 52.3099 71.1915 54.2885 72.3096 54.919C73.4277 55.5495 75.1617 54.5932 76.1825 52.7829Z"
      fill="#FFB6B6"
    />
    <path
      d="M83.9707 44.3873C83.9707 44.3873 80.429 46.8867 81.6465 50.6174L80.6504 63.2022L82.8639 89.1003H87.6317L88.3977 70.5068L91.4966 58.7752L91.8983 89.1003L97.8051 88.9896C97.8051 88.9896 98.1371 73.827 97.6944 72.9416C97.2517 72.0562 97.6944 72.7665 97.6944 72.7665L101.347 55.0096L100.793 42.8379L83.9707 44.3873Z"
      fill="#2F2E41"
    />
    <path
      d="M92.714 13.5078L86.2949 15.9427L86.9589 17.7135L81.6465 21.6978L85.1881 34.0935L83.1959 45.161L88.9511 44.9397L101.347 43.8329L100.019 30.3305L100.24 17.2708L95.3703 15.5L92.714 13.5078Z"
      fill="#F7B80B"
    />
    <path
      d="M83.3084 21.3672L81.6483 21.6992C81.6483 21.6992 80.8735 22.2526 80.8735 24.2448C80.8735 26.2369 80.8735 27.565 81.0949 28.6718C81.0949 28.6718 79.7668 32.2134 80.2095 33.0988C80.6522 33.9842 86.186 33.9842 86.186 33.9842L83.3084 21.3672V21.3672Z"
      fill="#F7B80B"
    />
    <path
      d="M92.4548 8.37193C92.4548 11.1586 90.1957 13.4177 87.409 13.4177C84.6223 13.4177 82.3633 11.1586 82.3633 8.37193C82.3633 5.58524 84.6223 3.32617 87.409 3.32617C90.1957 3.32617 92.4548 5.58524 92.4548 8.37193Z"
      fill="#FFB6B6"
    />
    <path
      d="M90.5635 11.8858C90.5635 11.8858 91.4573 8.45167 90.5049 9.05722L89.0267 8.91676L89.96 6.42008C89.96 6.42008 88.2891 5.16749 87.6229 5.89968C86.9567 6.63188 87.1164 7.0448 86.5108 6.09232C85.9053 5.13984 84.64 5.15784 83.481 5.84321C82.3221 6.52858 81.4164 6.64139 81.6367 5.36964C81.857 4.09789 81.7335 1.9369 82.4215 2.18197C83.1096 2.42705 82.9209 2.56538 83.292 1.67729C83.663 0.789201 83.7098 0.296464 84.5686 0.676388C85.4275 1.05631 87.5857 -0.727343 88.4775 0.351777C89.3693 1.4309 90.1484 1.60436 90.9274 1.77782C91.7064 1.95129 94.8363 1.45321 94.5745 6.30076C94.5745 6.30076 96.6636 10.4768 92.9005 12.4062C92.9005 12.4062 91.8793 11.3984 91.1662 12.6233L90.5635 11.8858H90.5635Z"
      fill="#2F2E41"
    />
    <path
      d="M86.4043 18.2756L87.1023 17.7891L72.2738 41.8876L70.6211 40.7572L86.4043 18.2756Z"
      fill="#2F2E42"
    />
    <path
      d="M84.1704 38.1635L83.9445 49.6004C83.9226 50.7076 83.0888 51.5927 82.0678 51.5927H62.6703C61.6735 51.5927 60.8505 50.7476 60.7961 49.668L60.2197 38.231C60.1609 37.0647 61.017 36.084 62.0939 36.084H82.2938C83.3462 36.084 84.193 37.0223 84.1704 38.1635Z"
      fill="#3F3D56"
    />
    <path
      d="M84.9996 51.2992L84.7716 51.896C84.7495 51.9538 83.9083 52 82.8781 52H63.3068C62.301 52 61.4707 51.9559 61.4158 51.8996L61 50.5C61.5 51 61.6386 51.1907 62.7252 51.1907H83.1061C84.168 51.1907 85.0223 51.2397 84.9996 51.2992Z"
      fill="#2F2E42"
    />
    <path
      d="M85.0916 38.2964L85.0739 49.6202C85.0722 50.7163 85.0068 51.5927 84.9267 51.5927L83.5884 50.8325C83.5102 50.8325 83.2623 50.7559 83.258 49.687L83.2128 38.3633C83.1723 36.4679 82.5504 36.084 82.6348 36.084L84.631 36.3143C84.9381 36.4679 85.0934 37.1665 85.0916 38.2964Z"
      fill="#2D2B3E"
    />
    <path
      d="M97.6165 16.1602L98.578 16.5573L75.8522 43.9407L75.7677 43.9501C74.9946 44.0364 74.3184 43.4313 74.3184 42.6535L97.6165 16.1602Z"
      fill="#2F2E42"
    />
    <path
      d="M85.0201 47.4686C86.2721 45.8098 86.4564 43.8382 85.4318 43.0649C84.4073 42.2916 82.5618 43.0095 81.3098 44.6683C80.0579 46.3271 79.8735 48.2987 80.8981 49.072C81.9227 49.8453 83.7681 49.1274 85.0201 47.4686Z"
      fill="#FFB6B6"
    />
    <path
      d="M92.7633 26.5508C92.7633 26.5508 91.9595 29.272 92.2196 31.1322L83.1348 43.1693L85.7123 45.6332L96.8565 34.9092L98.9719 29.1567L92.7633 26.5508V26.5508Z"
      fill="#FFB6B6"
    />
    <path
      d="M99.3474 16.5449C99.3474 16.5449 102.178 17.5717 101.818 19.5185C101.458 21.4653 101.321 22.7487 101.321 22.7487C101.321 22.7487 101.628 23.8122 101.228 24.3422C100.827 24.8722 98.7736 31.0871 98.7736 31.0871L91.3867 28.5624C91.3867 28.5624 95.7172 16.5782 99.3474 16.5449Z"
      fill="#F7B80B"
    />
  </svg>
);
