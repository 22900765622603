export default function ParkingWideIcon() {
  return (
    <svg
      width="172"
      height="149"
      viewBox="0 0 172 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.419 88.6758H19.184C16.7659 88.6758 14.8057 90.8517 14.8057 93.5358V119.094C14.8057 121.778 16.7659 123.954 19.184 123.954H25.419C27.8371 123.954 29.7974 121.778 29.7974 119.094V93.5358C29.7974 90.8517 27.8371 88.6758 25.419 88.6758Z"
        fill="#3F3D56"
      />
      <path
        d="M123.765 88.6758H117.53C115.112 88.6758 113.151 90.8517 113.151 93.5358V119.094C113.151 121.778 115.112 123.954 117.53 123.954H123.765C126.183 123.954 128.143 121.778 128.143 119.094V93.5358C128.143 90.8517 126.183 88.6758 123.765 88.6758Z"
        fill="#3F3D56"
      />
      <path
        d="M119.813 115.301H24.5915C21.281 115.301 18.4483 112.677 17.8561 109.062L14.2146 86.8302C12.6309 77.162 15.9123 67.3117 22.7797 61.1133L29.9602 43.9651C31.6246 39.9902 35.2517 37.4219 39.2005 37.4219H92.8391C95.6906 37.4219 98.4302 38.7609 100.356 41.0959L113.55 57.0974C123.203 64.3176 128.679 76.8051 127.844 89.693L126.641 108.248C126.385 112.203 123.385 115.301 119.813 115.301Z"
        fill="#F7B80B"
      />
      <path
        d="M103.562 59.5536H31.6213C30.3719 59.5536 29.219 58.8597 28.5373 57.6978C27.8555 56.5355 27.7502 55.0843 28.2556 53.8161L32.0475 44.2999C32.6246 42.8517 33.9191 41.8971 35.3451 41.8682L96.6727 41.951C97.8404 41.9426 98.9555 42.526 99.6635 43.5527L106.485 52.986C107.342 54.2295 107.487 55.8738 106.862 57.2778C106.237 58.6816 104.973 59.5536 103.562 59.5536Z"
        fill="white"
      />
      <path
        d="M114.351 84.6814C117.829 84.6814 120.648 81.5523 120.648 77.6923C120.648 73.8323 117.829 70.7031 114.351 70.7031C110.874 70.7031 108.055 73.8323 108.055 77.6923C108.055 81.5523 110.874 84.6814 114.351 84.6814Z"
        fill="white"
      />
      <path
        d="M31.5963 84.6814C35.0738 84.6814 37.8929 81.5523 37.8929 77.6923C37.8929 73.8323 35.0738 70.7031 31.5963 70.7031C28.1189 70.7031 25.2998 73.8323 25.2998 77.6923C25.2998 81.5523 28.1189 84.6814 31.5963 84.6814Z"
        fill="white"
      />
      <path
        d="M108.204 103.818H39.542V103.652C39.542 99.706 42.4338 96.4961 45.9884 96.4961H101.758C105.312 96.4961 108.204 99.706 108.204 103.652V103.818Z"
        fill="#3F3D56"
      />
      <path
        d="M23.7891 62.5798C23.4175 62.5798 23.0452 62.4484 22.7326 62.1832L20.2186 60.0498C19.969 59.8379 19.6584 59.7212 19.344 59.7212H13.8539C13.2258 59.7212 12.6629 59.3611 12.3482 58.7576C12.0335 58.1543 12.0323 57.4331 12.345 56.8286L13.6853 54.2369C13.953 53.7191 14.4311 53.3629 14.9643 53.2839L21.8331 52.2673C22.6892 52.1412 23.4862 52.7155 23.7312 53.6333L25.4576 60.1005C25.6477 60.8126 25.4483 61.5927 24.9497 62.0883C24.6211 62.4146 24.2056 62.5798 23.7891 62.5798Z"
        fill="#DFDFDF"
      />
      <path
        d="M112.863 61.2481C112.447 61.2481 112.032 61.083 111.703 60.7567C111.204 60.261 111.005 59.481 111.195 58.7689L112.921 52.3017C113.166 51.3832 113.965 50.8069 114.819 50.936L121.688 51.9523C122.221 52.0313 122.7 52.3875 122.967 52.9052L124.307 55.4969C124.62 56.1014 124.619 56.8227 124.304 57.4259C123.99 58.0294 123.427 58.3896 122.799 58.3896H117.308C116.994 58.3896 116.683 58.5062 116.434 58.7182L113.92 60.8516C113.607 61.1168 113.235 61.2481 112.863 61.2481Z"
        fill="#DFDFDF"
      />
      <path
        d="M123.006 51.5784C121.911 50.9707 121.305 49.8509 121.654 49.0773C122.003 48.3037 123.173 48.1694 124.268 48.7774C124.709 49.0137 125.092 49.3636 125.386 49.798L129.997 52.4379L128.835 54.8134L124.412 51.9587C123.923 51.9584 123.441 51.8281 123.006 51.5784V51.5784Z"
        fill="#FFB6B6"
      />
      <path
        d="M149.132 112.99L146.937 112.989L145.893 103.592L149.133 103.592L149.132 112.99Z"
        fill="#FFB6B6"
      />
      <path
        d="M141.612 112.934C141.543 113.062 141.507 113.475 141.507 113.623C141.507 114.08 141.841 114.45 142.252 114.45H149.05C149.33 114.45 149.558 114.197 149.558 113.886V113.572C149.558 113.572 149.894 112.628 149.202 111.464C149.202 111.464 148.342 112.375 147.056 110.948L146.677 110.186L143.932 112.414L142.411 112.621C142.078 112.667 141.783 112.614 141.612 112.934H141.612H141.612Z"
        fill="#1A202C"
      />
      <path
        d="M145.686 102.656L143.736 103.776L138.917 95.9636L141.793 94.3105L145.686 102.656Z"
        fill="#FFB6B6"
      />
      <path
        d="M138.984 106.445C138.977 106.593 139.115 106.978 139.177 107.11C139.366 107.515 139.816 107.674 140.181 107.464L146.218 103.995C146.467 103.852 146.564 103.511 146.435 103.235L146.305 102.956C146.305 102.956 146.213 101.946 145.116 101.266C145.116 101.266 144.729 102.514 142.996 101.903L142.344 101.419L140.829 104.798L139.564 105.759C139.288 105.969 139.004 106.073 138.984 106.445L138.984 106.445V106.445Z"
        fill="#1A202C"
      />
      <path
        d="M151.361 38.3975H145.605L145.73 40.4808L141.977 53.762L141.273 62.6161L148.311 63.9182L151.596 47.512L150.892 39.96L151.361 38.3975Z"
        fill="#F7B80B"
      />
      <path
        d="M151.009 40.0908L157.108 43.4762L153.355 57.5387L154.058 71.8616C154.058 71.8616 144.44 75.247 144.909 65.6116C145.378 55.9762 145.378 54.4137 145.378 54.4137L151.009 40.0908H151.009Z"
        fill="#1A202C"
      />
      <path
        d="M141.272 41.8444C141.272 41.8444 139.748 40.611 139.044 42.4339C138.34 44.2568 133.765 53.5016 133.765 53.5016L126.609 49.986L125.436 52.8505C125.436 52.8505 131.302 59.1005 134.821 58.3193C138.34 57.538 141.155 50.7672 141.155 50.7672L141.272 41.8444H141.272Z"
        fill="#1A202C"
      />
      <path
        d="M153.355 69.2575C153.355 69.2575 153.59 73.4241 153.121 76.8095C152.652 80.1949 149.484 108.971 149.484 108.971L145.496 108.71L145.144 74.9866L139.513 84.8824L144.44 99.7261L141.038 101.419L133.883 85.1428L138.81 66.6533L140.217 61.9658L150.775 63.2679L153.355 69.2575V69.2575Z"
        fill="#1A202C"
      />
      <path
        d="M137.637 70.2984C137.637 70.2984 143.971 72.3817 145.379 54.413L145.697 39.9248L139.748 41.6526L140.452 54.6734L137.637 70.2984V70.2984Z"
        fill="#1A202C"
      />
      <path
        d="M148.428 36.7051C146.096 36.7051 144.205 34.6064 144.205 32.0176C144.205 29.4287 146.096 27.3301 148.428 27.3301C150.761 27.3301 152.651 29.4287 152.651 32.0176C152.651 34.6064 150.761 36.7051 148.428 36.7051Z"
        fill="#FFB6B6"
      />
      <path
        d="M151.127 36.5749C151.127 36.5749 153.003 34.2311 153.238 33.1895C153.473 32.1478 153.473 29.2832 153.238 29.0228C153.003 28.7624 150.423 26.1583 149.484 26.4187C148.546 26.6791 146.686 27.9812 145.027 27.9812C144.558 27.9812 144.323 30.5853 144.323 30.5853L146.2 30.8457C146.2 30.8457 148.546 29.5437 148.311 30.8457C148.077 32.1478 149.954 32.1478 149.954 32.1478L149.719 33.1895L150.423 33.4499L150.892 32.4082C150.892 32.4082 150.657 34.752 149.954 35.7936C149.25 36.8353 151.127 36.5749 151.127 36.5749H151.127Z"
        fill="#1A202C"
      />
      <path
        d="M152.997 74.0702C152.353 75.2263 151.293 75.7946 150.631 75.3396C149.968 74.8847 149.953 73.579 150.597 72.4226C150.849 71.9568 151.197 71.5646 151.613 71.2789L154.397 66.4189L156.424 67.9176L153.464 72.5495C153.42 73.0906 153.26 73.6121 152.997 74.0702V74.0702Z"
        fill="#FFB6B6"
      />
      <path
        d="M156.17 43.4756H157.343C157.343 43.4756 162.036 55.1943 161.801 57.538C161.801 57.538 161.801 59.6214 160.628 61.1839C159.455 62.7464 154.528 71.6005 154.528 71.6005L152.417 69.7776L156.64 58.8401L154.059 47.3818L156.17 43.4756V43.4756Z"
        fill="#1A202C"
      />
    </svg>
  );
}
