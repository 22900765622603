import { Flex } from "@chakra-ui/react";
import BottomBlock from "../../components/BottomActionsBlock/BottomBlock";
import FlyersBlock from "../../components/FlyersBlock/FlyersBlock";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import ContentLayout from "../../components/Layout/ContentLayout";
import MainContainer from "../../components/Layout/MainContainer";
import TotalCard from "../../components/TotalCard/TotalCard";

export default function OnboardingFlyers() {
  return (
    <Layout>
      <ContentLayout>
        <MainContainer>
          <Flex flexDir="column">
            <Header
              title="COMBIEN DE CLIENTS VOULEZ-VOUS GAGNER ?"
              subtitle="Aidez-nous à établir votre besoin de personnel pour la distribution en sélectionnant le nombre de client ou le nombre de flyers à distribuer"
            />
            <FlyersBlock />
          </Flex>
          <TotalCard />
          <BottomBlock />
        </MainContainer>
      </ContentLayout>
    </Layout>
  );
}
