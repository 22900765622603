export const OptionsTourDeCouIcon = () => (
  <svg
    width="190"
    height="96"
    viewBox="0 0 190 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M87.1705 22.1182C90.3021 22.1182 92.8408 19.5795 92.8408 16.4479C92.8408 13.3163 90.3021 10.7776 87.1705 10.7776C84.0389 10.7776 81.5002 13.3163 81.5002 16.4479C81.5002 19.5795 84.0389 22.1182 87.1705 22.1182Z"
      fill="#2F2E41"
    />
    <path
      d="M96.2429 43.6653C104.176 43.6653 110.608 37.234 110.608 29.3006C110.608 21.3672 104.176 14.9359 96.2429 14.9359C88.3095 14.9359 81.8782 21.3672 81.8782 29.3006C81.8782 37.234 88.3095 43.6653 96.2429 43.6653Z"
      fill="#2F2E41"
    />
    <path
      d="M97.4318 43.6617C103.879 43.6617 109.105 38.4356 109.105 31.9888C109.105 25.542 103.879 20.3158 97.4318 20.3158C90.985 20.3158 85.7588 25.542 85.7588 31.9888C85.7588 38.4356 90.985 43.6617 97.4318 43.6617Z"
      fill="#FFB6B6"
    />
    <path
      d="M105.359 20.0898C103.313 18.8428 100.97 18.1683 98.5749 18.1367C96.1795 18.1051 93.8196 18.7177 91.742 19.9103C89.6643 21.1029 87.9453 22.8318 86.7645 24.9162C85.5838 27.0006 84.9847 29.364 85.03 31.7592C90.978 33.2525 97.2267 34.3954 103.57 32.1164L105.068 28.4502L105.951 32.1191C107.883 32.9086 109.819 33.5767 111.756 32.1115C111.835 29.723 111.286 27.3557 110.163 25.2461C109.041 23.1365 107.384 21.3585 105.359 20.0898Z"
      fill="#2F2E41"
    />
    <path
      d="M75.1984 53.5788H75.183C74.414 53.5863 73.6609 53.3606 73.0227 52.9316C72.3845 52.5026 71.8913 51.8903 71.6079 51.1754C71.3032 50.4515 71.2249 49.6522 71.3833 48.8828C71.5418 48.1135 71.9295 47.4101 72.4955 46.8655L72.561 46.8004C74.7703 44.5754 76.0151 41.5702 76.0261 38.4347C76.0289 34.0068 73.5501 29.9144 71.2266 26.4261L70.7983 25.7846C68.4827 22.3205 66.0882 18.7385 65.4787 14.6122C64.8314 10.23 66.4909 5.60445 69.7062 2.82809C73.0435 -0.0535283 78.0749 -0.828005 81.9418 0.944749C85.9496 2.7824 88.5278 7.36035 87.9387 11.5933L87.9326 11.6372L86.5242 12.9042L86.4723 12.9002C83.1374 12.6432 79.8155 14.8406 78.7421 18.0068C78.0194 20.1381 78.2793 22.4736 78.6676 24.68C79.4909 29.3528 80.8751 33.9093 82.7902 38.2505C82.8873 38.471 82.9858 38.6924 83.0855 38.9146C84.2018 41.4141 85.3561 43.9986 84.9139 46.6756C84.2153 50.9063 79.7248 53.3821 75.8543 53.5634C75.6351 53.5738 75.4165 53.579 75.1984 53.5788Z"
      fill="#2F2E41"
    />
    <path
      d="M124.66 83.8721C121.51 86.7356 117.984 89.1559 114.18 91.0662L113.375 88.4754L112.726 91.0006L112.979 91.6471C112.699 91.7783 112.418 91.9047 112.136 92.0287C111.895 92.1356 111.654 92.2401 111.414 92.3422C102.603 96.0436 92.8705 96.9629 83.5222 94.9768C83.2767 94.9257 83.0336 94.8723 82.7906 94.8164C81.7285 94.5733 80.6785 94.2914 79.6432 93.9779L81.0917 88.592L79.8036 82.0809L76.4496 92.8745C72.6424 91.4007 69.0454 89.433 65.7509 87.022C66.7814 80.3602 67.9893 72.3641 68.7354 66.83C69.0052 64.8079 69.2142 63.1163 69.3309 61.9351C69.4254 60.8282 69.6423 59.7351 69.9774 58.6759C70.1419 58.1637 70.3526 57.6675 70.6068 57.1934C70.6603 57.0888 70.7186 56.9892 70.777 56.892C70.8329 56.7972 70.8912 56.7073 70.952 56.6198C72.4443 54.3959 74.3594 54.532 74.3594 54.532L80.0758 53.4165L81.8622 53.0665L82.5889 52.9255L83.4784 52.7505C84.1579 52.618 84.802 52.3445 85.3693 51.9476C85.9365 51.5507 86.4142 51.0394 86.7716 50.4465L89.1996 46.4144C89.7142 45.562 90.4728 44.8838 91.3773 44.4676C91.4867 44.419 91.5985 44.3728 91.7103 44.3291C92.204 44.1441 92.7243 44.0399 93.2512 44.0204L96.6878 43.8868L98.078 43.8308L99.9762 43.7579C100.424 43.7401 100.872 43.7843 101.308 43.8892H101.31C101.464 43.9256 101.609 43.967 101.758 44.018C102.906 44.4056 103.872 45.2013 104.472 46.254L106.169 49.2288C106.551 49.8979 107.083 50.4688 107.724 50.8962C108.365 51.3236 109.096 51.5957 109.861 51.6909L110.512 51.7711L111.244 51.8634L113.093 52.0943L116.471 52.5172L116.479 52.646C117.125 52.6971 118.968 53.0762 120.202 55.7083C120.285 55.8809 120.363 56.0656 120.438 56.2576C120.87 57.4248 121.146 58.6442 121.257 59.8838C121.264 59.9373 121.269 59.9908 121.274 60.0442C121.687 64.2562 123.289 74.9962 124.66 83.8721Z"
      fill="#2F2E41"
    />
    <path
      d="M97.1348 94.8869L96.9041 95.9635L96.192 95.8104L96.4038 94.8229C97.0721 91.7069 97.3739 88.5235 97.3031 85.3375L96.4083 45.0558L96.3913 44.3315L96.3816 43.8916L96.751 43.8843L97.1107 43.877L97.1472 45.5491L98.0324 85.4099C98.1031 88.5931 97.8019 91.7736 97.1348 94.8869Z"
      fill="white"
    />
    <path
      d="M68.5701 68.0354L78.8217 69.4353L79.5386 69.5325L79.9761 69.5933L79.9251 69.9578L79.874 70.3151L78.2165 70.0891L68.4705 68.7572L68.5701 68.0354Z"
      fill="white"
    />
    <path
      d="M99.1643 44.5575L98.078 43.8308L96.7509 43.8843L96.6877 43.8868L96.3912 44.3315L96.4083 45.0558L97.1471 45.5492L98.0828 46.1738C98.1889 46.2452 98.3081 46.2949 98.4335 46.3202C98.5588 46.3455 98.688 46.3458 98.8135 46.3211C98.939 46.2964 99.0583 46.2471 99.1648 46.1762C99.2712 46.1053 99.3626 46.0141 99.4337 45.9078C99.5049 45.8015 99.5543 45.6822 99.5793 45.5568C99.6042 45.4313 99.6042 45.3022 99.5792 45.1768C99.5542 45.0513 99.5046 44.9321 99.4334 44.8258C99.3623 44.7196 99.2708 44.6284 99.1643 44.5575ZM99.0282 45.6366C98.9746 45.7164 98.8986 45.7786 98.8097 45.8152C98.7208 45.8518 98.6231 45.8612 98.5288 45.8423C98.4346 45.8234 98.348 45.777 98.2801 45.7089C98.2123 45.6408 98.1661 45.5542 98.1474 45.4599C98.1288 45.3656 98.1385 45.2678 98.1753 45.1791C98.2122 45.0903 98.2745 45.0144 98.3545 44.961C98.4344 44.9076 98.5284 44.8791 98.6245 44.8791C98.7207 44.8791 98.8146 44.9076 98.8946 44.961C99.0016 45.0331 99.0758 45.1446 99.1008 45.2712C99.1259 45.3978 99.0998 45.5292 99.0282 45.6366Z"
      fill="white"
    />
    <g clipPath="url(#clip0_1249_57145)">
      <path
        d="M103.093 44.4789L105.746 45.9328C106.501 46.347 106.907 47.1947 106.756 48.0424L106.534 52.6586"
        stroke="#091C25"
        strokeWidth="1.55061"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M90.6046 42.4922L89.6699 44.8694C89.6699 44.8694 96.8541 48.9829 103.995 44.0712L102.34 42C102.34 42 99.3403 45 90.6046 42.4922Z"
        fill="#F7B80B"
      />
      <path
        d="M90.6046 42.3024C90.6046 42.3024 97.5988 45.6196 102.84 42"
        stroke="#091C25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.6699 44.4789C89.6699 44.4789 96.0381 49.0584 103.995 44.3517"
        stroke="#091C25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.309 47.5916C103.309 47.5916 102.741 56.7873 108.218 57.95C108.218 57.95 110.64 56.4141 109.662 50.7021C109.662 50.7021 115.244 52.0244 117.701 48.5688C117.701 48.5688 114.37 43.4823 106.43 45.2382L103.995 45.208L103.309 47.5916Z"
        fill="#F7B80B"
      />
      <path
        d="M102.493 47.4989C102.493 47.4989 100.086 44.5544 102.935 43.3378C102.935 43.3378 106.406 41.8171 106.555 44.9902L105.783 47.8677L102.495 47.4967L102.493 47.4989Z"
        fill="#F7B80B"
      />
      <path
        d="M103.553 45.5618L104.261 46.9013C104.427 47.4104 104.971 47.6477 105.411 47.3996C106.117 47.0027 106.491 46.196 106.674 45.6351C106.797 45.2598 106.793 44.8499 106.668 44.4768C105.487 40.993 102.506 43.1545 102.506 43.1545C101.107 44.5091 101.84 46.5 103.34 48"
        stroke="#091C25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.827 45.208C106.827 45.208 114.34 43.6139 117.701 48.5688C117.701 48.5688 111.414 53.9234 105.84 48"
        stroke="#091C25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.318 47.6542C105.318 47.6542 105.318 49.0584 106.43 50.4606"
        stroke="#091C25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.093 47.9605C103.093 47.9605 101.491 54.6324 108.045 57.9478C108.045 57.9478 110.34 55 109.84 51"
        stroke="#091C25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1249_57145">
        <rect
          width="29.4082"
          height="17.7421"
          fill="white"
          transform="translate(88.9813 40.8938)"
        />
      </clipPath>
    </defs>
  </svg>
);
