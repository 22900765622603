export const TextileIcon = () => (
  <svg width="140" height="101" viewBox="0 0 140 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1063_48822)">
      <path d="M102.533 0.776367H0.436523V66.1636H102.533V0.776367Z" fill="#E6E6E6"/>
      <path d="M99.6133 8.97754H3.35547V62.2952H99.6133V8.97754Z" fill="white"/>
      <path d="M102.489 0.71875H0.392578V5.05615H102.489V0.71875Z" fill="#F7B80B"/>
      <path d="M3.6155 3.74028C4.05953 3.74028 4.41948 3.38033 4.41948 2.9363C4.41948 2.49228 4.05953 2.13232 3.6155 2.13232C3.17148 2.13232 2.81152 2.49228 2.81152 2.9363C2.81152 3.38033 3.17148 3.74028 3.6155 3.74028Z" fill="white"/>
      <path d="M6.66726 3.74028C7.11128 3.74028 7.47124 3.38033 7.47124 2.9363C7.47124 2.49228 7.11128 2.13232 6.66726 2.13232C6.22323 2.13232 5.86328 2.49228 5.86328 2.9363C5.86328 3.38033 6.22323 3.74028 6.66726 3.74028Z" fill="white"/>
      <path d="M9.71902 3.74028C10.163 3.74028 10.523 3.38033 10.523 2.9363C10.523 2.49228 10.163 2.13232 9.71902 2.13232C9.27499 2.13232 8.91504 2.49228 8.91504 2.9363C8.91504 3.38033 9.27499 3.74028 9.71902 3.74028Z" fill="white"/>
      <path d="M34.0258 13.3633H15.1992V33.7885H34.0258V13.3633Z" fill="#E6E6E6"/>
      <path d="M60.8979 13.3633H42.0713V33.7885H60.8979V13.3633Z" fill="#E6E6E6"/>
      <path d="M87.77 13.3633H68.9434V33.7885H87.77V13.3633Z" fill="#E6E6E6"/>
      <path d="M34.0258 37.4854H15.1992V57.9106H34.0258V37.4854Z" fill="#E6E6E6"/>
      <path d="M60.8979 37.4854H42.0713V57.9106H60.8979V37.4854Z" fill="#E6E6E6"/>
      <path d="M87.77 37.4854H68.9434V57.9106H87.77V37.4854Z" fill="#E6E6E6"/>
      <path d="M46.6213 42.735L50.0611 41.375L50.4611 48.1747C50.4611 48.1747 51.181 50.2546 50.9411 51.0546C50.9411 51.0546 51.1011 52.4145 50.7011 52.4945C50.3011 52.5745 49.1808 52.7346 49.1017 52.6545C49.0225 52.5743 49.0217 52.3344 49.0217 52.3344C49.0217 52.3344 48.2217 52.8944 48.1417 53.5343C48.1417 53.5343 44.7818 54.5742 44.7019 53.6143C44.6219 52.6543 46.3817 51.8543 46.3817 51.8543L47.7417 49.7744L46.6213 42.735Z" fill="white"/>
      <path d="M53.9011 42.735L57.3404 41.375L57.7404 48.1747C57.7404 48.1747 58.4603 50.2546 58.2204 51.0546C58.2204 51.0546 58.3804 52.4145 57.9804 52.4945C57.5803 52.5745 56.4601 52.7346 56.381 52.6545C56.3018 52.5743 56.301 52.3344 56.301 52.3344C56.301 52.3344 55.501 52.8944 55.421 53.5343C55.421 53.5343 52.0611 54.5742 51.9812 53.6143C51.9012 52.6543 53.661 51.8543 53.661 51.8543L55.021 49.7744L53.9011 42.735Z" fill="white"/>
      <path d="M54.2377 16.4766C54.2377 16.4766 53.5053 18.5232 54.3539 19.4461L53.6464 21.8774C53.6464 21.8774 56.1601 29.4187 55.442 30.4721C55.442 30.4721 52.7607 31.8127 47.3975 29.9932L49.576 21.5422L49.2648 17.975L49.6957 16.2991L50.6533 16.2041C50.6533 16.2041 50.0788 18.3587 51.1322 18.6939C52.1855 19.0292 53.2131 16.2579 53.2131 16.2579L54.2377 16.4766Z" fill="#3F3D56"/>
      <path d="M20.4121 29.6882L21.3364 30.0664L25.7479 24.2684L27.1344 31.0746L28.0587 30.9066C28.6516 25.0387 29.1355 19.0536 28.5418 16.2228L25.1177 16.0757L23.5633 21.034L22.8069 24.3951L20.4121 29.6882Z" fill="white"/>
      <path d="M76.5116 16.1763C76.5116 16.1763 75.2502 17.9299 75.3118 18.6376C75.3734 19.3453 76.1732 30.8829 76.1732 30.8829L78.0501 30.9752L77.4958 22.6681L77.9573 20.4221L79.0957 30.8829L81.4033 30.9445L79.3419 17.3763L78.9419 16.1764L76.5116 16.1763Z" fill="white"/>
      <path d="M83.6409 44.4282C83.3059 44.0263 82.7302 43.809 82.1773 43.6915C82.1932 43.6413 80.4959 43.2906 80.4671 43.3628L79.8272 42.853L77.8608 43.874L76.756 43.1218L76.2427 43.3039C76.2637 43.2133 74.6495 43.493 74.6495 43.493C74.3412 43.504 73.9474 43.5234 73.5065 43.5572C71.9627 43.676 71.8438 47.6745 71.8438 47.6745C72.5541 47.3839 73.3114 47.2254 74.0786 47.2066L74.4959 51.989C76.6421 51.805 78.893 52.0191 81.2261 52.5432L82.3202 48.7838L84.868 47.793C84.868 47.793 84.4327 45.3782 83.6409 44.4282Z" fill="#3F3D56"/>
      <path d="M28.9662 42.1508C28.6902 41.8196 28.2159 41.6405 27.7603 41.5438C27.7739 41.5023 26.3749 41.2134 26.3509 41.273L25.8236 40.853L24.2038 41.6942L23.2934 41.0746L22.8706 41.2247C22.8878 41.1501 21.5578 41.3804 21.5578 41.3804C21.3044 41.3895 20.9792 41.4054 20.616 41.4334C19.344 41.5313 19.2461 44.8256 19.2461 44.8256C19.8314 44.5861 20.4554 44.4555 21.0876 44.44L21.4314 54.0861C23.1998 53.9344 25.0545 54.111 26.9763 54.5428L27.8777 45.7398L29.977 44.9234C29.977 44.9234 29.6185 42.9338 28.9662 42.1508Z" fill="white"/>
      <path d="M119.073 44.7186H128.1V39.8887C128.1 38.6917 127.624 37.5437 126.778 36.6974C125.931 35.851 124.783 35.3755 123.586 35.3755C122.389 35.3755 121.242 35.851 120.395 36.6974C119.549 37.5437 119.073 38.6917 119.073 39.8887V44.7186Z" fill="#2F2E41"/>
      <path d="M115.044 98.4558H113.102L112.179 90.9668H115.044L115.044 98.4558Z" fill="#FFB6B6"/>
      <path d="M115.539 97.8218H111.716C111.07 97.8218 110.45 98.0785 109.993 98.5354C109.536 98.9923 109.279 99.6119 109.279 100.258V100.337H115.539V97.8218Z" fill="#2F2E41"/>
      <path d="M138.252 95.8652L136.561 96.8196L132.075 90.7528L134.57 89.3442L138.252 95.8652Z" fill="#FFB6B6"/>
      <path d="M138.371 95.0698L135.041 96.9489C134.479 97.2666 134.065 97.7948 133.892 98.4174C133.719 99.0399 133.8 99.7058 134.118 100.269L134.156 100.337L139.608 97.2601L138.371 95.0698Z" fill="#2F2E41"/>
      <path d="M125.844 64.778L125.994 65.527C125.994 65.527 126.443 65.9765 126.218 66.2012C125.994 66.4259 126.069 67.4747 126.069 67.4747C126.194 69.387 128.693 80.1331 129.14 82.2318C129.14 82.2318 132.586 84.479 135.282 88.8986C137.979 93.3182 138.054 94.4419 138.054 94.4419L135.058 95.7156L127.568 86.6515C127.568 86.6515 125.47 85.378 124.721 84.2545C123.972 83.131 119.327 71.5197 119.327 71.5197L116.704 83.6551C116.704 83.6551 116.854 88.0747 116.18 90.9213C115.765 92.7177 115.515 94.548 115.431 96.3897L111.91 96.0152C111.91 96.0152 112.135 85.378 112.509 84.2543C112.509 84.2543 110.936 71.295 114.082 66.1263L116.618 59.3665L119.101 58.4106L125.844 64.778Z" fill="#2F2E41"/>
      <path d="M123.633 36.5562C118.907 36.6369 118.908 43.6675 123.633 43.7475C128.36 43.6667 128.359 36.6361 123.633 36.5562Z" fill="#FFB8B8"/>
      <path d="M119.995 39.9653H120.798L121.017 39.4183L120.908 39.9653H125.301L125.159 38.8208L126.227 39.9653H127.292V39.3451C127.292 38.8661 127.198 38.3916 127.015 37.9488C126.832 37.5061 126.564 37.1037 126.225 36.7647C125.887 36.4257 125.485 36.1567 125.042 35.973C124.6 35.7894 124.125 35.6947 123.646 35.6943C123.167 35.694 122.693 35.788 122.25 35.9711C121.807 36.1541 121.405 36.4225 121.066 36.7611C120.727 37.0996 120.458 37.5016 120.274 37.9441C120.091 38.3866 119.996 38.8609 119.996 39.34V39.3451L119.995 39.9653Z" fill="#2F2E41"/>
      <path d="M118.759 45.8467L127.69 45.919L125.803 65.0671C125.803 65.0671 115.358 64.5159 115.352 61.3277L116.33 58.4945L118.759 45.8467Z" fill="#3F3D56"/>
      <path d="M128.074 66.9185C127.937 66.7504 127.838 66.5542 127.785 66.3437C127.731 66.1333 127.725 65.9138 127.766 65.7005C127.806 65.4873 127.893 65.2857 128.021 65.1097C128.148 64.9338 128.312 64.7878 128.502 64.6822L128.113 61.3113L130.104 60.5757L130.586 65.344C130.741 65.6742 130.77 66.0499 130.667 66.4001C130.564 66.7503 130.337 67.0507 130.028 67.2445C129.718 67.4384 129.349 67.5122 128.989 67.4522C128.629 67.3921 128.303 67.2023 128.074 66.9185Z" fill="#FFB6B6"/>
      <path d="M126.516 47.5688L127.691 45.9261C127.691 45.9261 128.495 45.7476 129.445 46.8561C130.395 47.9646 130.95 61.8209 130.95 61.8209L127.466 62.6127L125.407 52.7945L126.516 47.5688Z" fill="#3F3D56"/>
      <path d="M106.83 62.5951L107.089 74.9053L115.058 79.1168L129.572 76.5251L129.928 63.7289L120.728 60.4893L106.83 62.5951Z" fill="white"/>
      <path d="M115.037 79.2498L106.963 74.983L106.7 62.4861L120.74 60.3589L120.77 60.3694L130.058 63.6398L129.696 76.6323L115.037 79.2498ZM107.215 74.8279L115.079 78.9839L129.447 76.4181L129.798 63.8182L120.715 60.62L106.959 62.7042L107.215 74.8279Z" fill="#CACACA"/>
      <path d="M114.881 65.9325L114.85 65.9202L106.783 62.713L106.877 62.4766L114.912 65.6713L129.911 63.6026L129.946 63.8547L114.881 65.9325Z" fill="#CACACA"/>
      <path d="M115.023 65.8006L114.769 65.8037L114.931 79.1183L115.185 79.1152L115.023 65.8006Z" fill="#CACACA"/>
      <path d="M113.861 65.2955L110.953 64.1167L124.951 61.8862L127.859 63.0649L113.861 65.2955Z" fill="#CACACA"/>
      <path d="M106.88 66.1661L106.779 66.3999L110.152 67.8519L110.252 67.6181L106.88 66.1661Z" fill="#CACACA"/>
      <path d="M108.407 70.3658L108.307 70.5996L111.679 72.0516L111.78 71.8179L108.407 70.3658Z" fill="#F7B80B"/>
      <path d="M62.4902 79.6976L62.7493 92.0078L70.7186 96.2193L85.2317 93.6277L85.5882 80.8315L76.3878 77.5918L62.4902 79.6976Z" fill="white"/>
      <path d="M70.6976 96.3524L62.6235 92.0855L62.3604 79.5886L76.4 77.4614L76.4299 77.4719L85.7177 80.7423L85.3559 93.7348L70.6976 96.3524ZM62.8748 91.9304L70.7392 96.0864L85.1073 93.5207L85.4581 80.9208L76.3753 77.7225L62.6195 79.8068L62.8748 91.9304Z" fill="#CACACA"/>
      <path d="M70.54 83.0351L70.5088 83.0227L62.4424 79.8155L62.5363 79.5791L70.5716 82.7738L85.5699 80.7052L85.6048 80.9573L70.54 83.0351Z" fill="#CACACA"/>
      <path d="M70.6832 82.9032L70.4287 82.9062L70.5907 96.2208L70.8452 96.2177L70.6832 82.9032Z" fill="#CACACA"/>
      <path d="M68.8167 82.1082L66.6133 81.2192L80.6112 78.9888L82.8145 79.8778L68.8167 82.1082Z" fill="#CACACA"/>
      <path d="M62.5401 83.2687L62.4395 83.5024L65.8119 84.9545L65.9125 84.7207L62.5401 83.2687Z" fill="#CACACA"/>
      <path d="M64.0674 87.4684L63.9668 87.7021L67.3392 89.1542L67.4399 88.9204L64.0674 87.4684Z" fill="#F7B80B"/>
      <path d="M76.335 81.3853L76.6358 95.6756L85.8869 100.564L102.734 97.5559L103.148 82.7016L92.4679 78.9409L76.335 81.3853Z" fill="#F7B80B"/>
      <path d="M85.8628 100.719L76.49 95.7658L76.1846 81.2589L92.4823 78.7896L92.517 78.8017L103.299 82.5982L102.879 97.6803L85.8628 100.719ZM76.7817 95.5857L85.911 100.41L102.59 97.4318L102.997 82.8053L92.4537 79.0927L76.4854 81.5121L76.7817 95.5857Z" fill="#3F3D56"/>
      <path d="M85.6803 85.26L85.6442 85.2456L76.2803 81.5226L76.3894 81.248L85.7171 84.9566L103.128 82.5553L103.168 82.8479L85.6803 85.26Z" fill="#3F3D56"/>
      <path d="M85.8462 85.1068L85.5508 85.1104L85.7388 100.566L86.0343 100.563L85.8462 85.1068Z" fill="#3F3D56"/>
      <path d="M83.8287 84.1905L81.1211 83.1518L97.3704 80.5625L100.078 81.6012L83.8287 84.1905Z" fill="#3F3D56"/>
      <path d="M76.3932 85.5309L76.2764 85.8022L80.1912 87.4878L80.3081 87.2164L76.3932 85.5309Z" fill="#3F3D56"/>
      <path d="M78.1657 90.4059L78.0488 90.6772L81.9637 92.3628L82.0805 92.0914L78.1657 90.4059Z" fill="white"/>
      <path d="M111.349 64.6305C111.545 64.5383 111.719 64.4041 111.858 64.2374C111.997 64.0707 112.098 63.8756 112.153 63.6657C112.209 63.4559 112.217 63.2364 112.179 63.0228C112.14 62.8092 112.056 62.6066 111.93 62.4294L113.739 59.5584L112.262 58.0337L109.765 62.1239C109.481 62.3542 109.292 62.6805 109.233 63.0411C109.173 63.4017 109.248 63.7715 109.444 64.0805C109.639 64.3894 109.941 64.6161 110.292 64.7175C110.643 64.8189 111.019 64.788 111.349 64.6307V64.6305Z" fill="#FFB6B6"/>
      <path d="M120.132 46.6014L118.836 45.7476C118.836 45.7476 117.836 45.9718 116.772 48.0174C115.709 50.0631 111.42 59.2089 111.42 59.2089L113.183 60.9983L117.886 53.8233L120.132 46.6014Z" fill="#3F3D56"/>
    </g>
    <defs>
      <clipPath id="clip0_1063_48822">
        <rect width="139.216" height="100" fill="white" transform="translate(0.392578 0.71875)"/>
      </clipPath>
    </defs>
  </svg>

);