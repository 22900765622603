export const DressingPantsIcon = () => (
  <svg
    width="31"
    height="55"
    viewBox="0 0 31 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.648285 51.0237L8.9853 33.8887L11.6025 13.0332C9.79797 9.68203 13.1492 2.86304 13.1492 2.86304V0L26.1308 2.44904V2.44905C28.7859 9.69096 30.0735 17.3629 29.928 25.0748L29.4049 52.7976H29.569C29.8413 52.7976 30.1024 52.9058 30.295 53.0983C30.4875 53.2909 30.5957 53.552 30.5957 53.8243C30.5957 54.0966 30.4875 54.3577 30.295 54.5503C30.1024 54.7428 29.8413 54.851 29.569 54.851H24.4355C24.1632 54.851 23.9021 54.7428 23.7096 54.5503C23.517 54.3577 23.4088 54.0966 23.4088 53.8243C23.4088 53.552 23.517 53.2909 23.7096 53.0983C23.9021 52.9058 24.1632 52.7976 24.4355 52.7976H24.5679L20.8827 17.5293L16.5977 34.9333L6.24251 50.9578C6.50665 50.9784 6.75254 51.1006 6.92851 51.2986C7.10449 51.4967 7.19686 51.7552 7.18623 52.0199C7.17559 52.2847 7.06279 52.535 6.8715 52.7183C6.68021 52.9016 6.42532 53.0036 6.16039 53.0029H1.02691C0.788155 53.0032 0.556786 52.9201 0.372698 52.7681C0.188612 52.616 0.0633411 52.4045 0.0184822 52.17C-0.0263786 51.9355 0.011982 51.6927 0.126953 51.4834C0.241924 51.2742 0.426302 51.1116 0.648296 51.0237H0.648285Z"
      fill="#2F2E41"
    />
  </svg>
);
