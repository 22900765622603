export const HighTechIcon = () => (
  <svg width="130" height="101" viewBox="0 0 130 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1063_18902)">
      <path d="M89.8215 92.0098C93.4789 92.0098 96.4439 89.0449 96.4439 85.3875C96.4439 81.7301 93.4789 78.7651 89.8215 78.7651C86.1641 78.7651 83.1992 81.7301 83.1992 85.3875C83.1992 89.0449 86.1641 92.0098 89.8215 92.0098Z" fill="#F7B80B"/>
      <path d="M33.4094 100.349C37.0668 100.349 40.0317 97.3842 40.0317 93.7268C40.0317 90.0694 37.0668 87.1045 33.4094 87.1045C29.752 87.1045 26.7871 90.0694 26.7871 93.7268C26.7871 97.3842 29.752 100.349 33.4094 100.349Z" fill="#F7B80B"/>
      <path d="M123.523 77.7588C123.316 77.4096 122.42 77.7835 122.256 77.4666C122.091 77.1508 122.924 76.6697 123.399 75.5707C123.485 75.3725 124.026 74.1235 123.625 73.7381C122.867 73.0076 119.397 76.0222 118.183 75.0357C117.916 74.8192 117.73 74.387 116.923 73.6853C116.603 73.4061 116.409 73.2801 116.221 73.327C115.954 73.3935 115.933 73.7501 115.647 74.4513C115.219 75.5028 114.966 75.4393 114.716 76.2783C114.531 76.9011 114.573 77.2632 114.355 77.3337C114.046 77.4336 113.762 76.773 113.371 76.8321C112.972 76.8925 112.731 77.6616 112.654 78.2375C112.511 79.3182 112.908 80.0313 113.128 80.7811C113.367 81.596 113.509 82.8353 112.957 84.5896L107.493 99.9843C108.699 96.8254 112.192 88.1658 113.66 84.9479C114.084 84.0189 114.551 83.0767 115.512 82.7048C116.437 82.3468 117.707 82.5606 119.326 82.1705C119.515 82.1249 120.04 81.9914 120.106 81.6828C120.16 81.4276 119.852 81.2852 119.899 81.0462C119.963 80.7256 120.57 80.7217 121.316 80.3518C121.842 80.091 122.169 79.7809 122.451 79.5141C122.536 79.4337 123.799 78.2236 123.523 77.7588H123.523H123.523Z" fill="#F2F2F2"/>
      <path d="M102.026 85.8632C101.795 85.8641 101.72 86.4117 101.517 86.4011C101.315 86.3905 101.319 85.8432 100.917 85.2927C100.844 85.1934 100.387 84.5678 100.083 84.653C99.5053 84.8144 99.9837 87.3873 99.1491 87.6987C98.9659 87.767 98.7001 87.7337 98.1229 87.9271C97.8933 88.0041 97.7755 88.0629 97.7443 88.1686C97.7001 88.3186 97.869 88.4319 98.1307 88.7744C98.5232 89.2881 98.4189 89.3942 98.7587 89.7588C99.0109 90.0294 99.2008 90.1131 99.1725 90.2405C99.1324 90.4208 98.7262 90.3696 98.6424 90.5786C98.5569 90.7919 98.8648 91.1323 99.1254 91.3358C99.6146 91.7179 100.079 91.7288 100.511 91.8372C100.979 91.955 101.629 92.2429 102.33 93.0201L108.31 100.144C107.107 98.6402 103.865 94.4274 102.709 92.7786C102.375 92.3026 102.048 91.8011 102.142 91.2224C102.234 90.6652 102.705 90.1035 102.981 89.1962C103.013 89.0903 103.099 88.7942 102.966 88.6727C102.857 88.5723 102.698 88.6827 102.594 88.5905C102.455 88.4667 102.629 88.1677 102.662 87.6949C102.686 87.3615 102.628 87.1112 102.579 86.8959C102.564 86.831 102.334 85.862 102.026 85.8632V85.8632Z" fill="#F2F2F2"/>
      <path d="M106.637 86.8981L106.498 85.655L106.562 85.5608C106.856 85.125 107.006 84.6995 107.008 84.2955C107.008 84.2313 107.005 84.1671 107.002 84.1016C106.99 83.8424 106.975 83.52 107.143 83.1443C107.238 82.9348 107.502 82.4498 107.9 82.5098C108.007 82.5249 108.088 82.5741 108.15 82.6317C108.159 82.6173 108.168 82.6029 108.178 82.5873C108.302 82.3982 108.4 82.3172 108.495 82.2391C108.567 82.1791 108.642 82.1173 108.76 81.9708C108.812 81.9066 108.852 81.8502 108.886 81.8028C108.989 81.6593 109.123 81.4882 109.36 81.4912C109.614 81.5033 109.746 81.7127 109.834 81.8514C109.991 82.0987 110.062 82.277 110.109 82.3952C110.126 82.4384 110.146 82.487 110.156 82.502C110.237 82.6221 110.901 82.5098 111.151 82.469C111.713 82.3754 112.198 82.2944 112.399 82.6485C112.542 82.9018 112.436 83.2367 112.072 83.6707C111.959 83.8057 111.839 83.9084 111.732 83.9888C111.821 84.0416 111.9 84.1227 111.932 84.2499C112.008 84.5512 111.749 84.8531 111.162 85.1485C111.017 85.2223 110.821 85.3207 110.546 85.3681C110.417 85.3903 110.301 85.3958 110.201 85.3988C110.199 85.4564 110.185 85.5188 110.15 85.583C110.047 85.7721 109.833 85.8621 109.51 85.8405C109.155 85.8219 108.862 85.7541 108.604 85.6947C108.379 85.643 108.184 85.5992 108.032 85.6094C107.749 85.6322 107.531 85.8585 107.276 86.152L106.637 86.8981H106.637Z" fill="#F2F2F2"/>
      <path d="M107.283 79.4969L106.116 79.9469L106.009 79.9092C105.513 79.735 105.063 79.6978 104.672 79.7985C104.609 79.8145 104.548 79.8337 104.486 79.8532C104.238 79.9306 103.93 80.0269 103.524 79.9596C103.297 79.9216 102.761 79.7885 102.718 79.3884C102.705 79.2809 102.732 79.1901 102.773 79.1159C102.756 79.1109 102.74 79.1056 102.722 79.0999C102.508 79.028 102.405 78.9536 102.305 78.882C102.229 78.8269 102.15 78.77 101.979 78.6934C101.903 78.6598 101.839 78.6352 101.784 78.6144C101.619 78.5512 101.42 78.4645 101.362 78.2344C101.31 77.9864 101.479 77.8049 101.591 77.6847C101.79 77.4701 101.944 77.3561 102.047 77.2806C102.084 77.2531 102.126 77.2218 102.138 77.2085C102.234 77.0991 101.957 76.4857 101.854 76.2542C101.621 75.7348 101.419 75.2854 101.711 75.0019C101.92 74.7989 102.271 74.817 102.783 75.0582C102.942 75.1334 103.072 75.2238 103.177 75.3067C103.205 75.2077 103.264 75.1105 103.379 75.0471C103.651 74.8976 104.009 75.0715 104.443 75.5635C104.551 75.6856 104.696 75.8502 104.812 76.1038C104.866 76.2233 104.901 76.3346 104.929 76.4302C104.985 76.4176 105.049 76.4148 105.12 76.4325C105.329 76.4841 105.47 76.6685 105.531 76.9864C105.604 77.3348 105.612 77.6351 105.62 77.8998C105.627 78.1309 105.634 78.3298 105.683 78.4747C105.777 78.7424 106.051 78.8958 106.4 79.0686L107.283 79.4969L107.283 79.4969Z" fill="#F2F2F2"/>
      <path d="M106.637 75.8351L106.498 74.5921L106.562 74.4978C106.856 74.0621 107.006 73.6365 107.008 73.2325C107.008 73.1683 107.005 73.1041 107.002 73.0387C106.99 72.7794 106.975 72.457 107.143 72.0813C107.238 71.8718 107.502 71.3868 107.9 71.4469C108.007 71.4619 108.088 71.5111 108.15 71.5687C108.159 71.5543 108.168 71.5399 108.178 71.5243C108.302 71.3352 108.4 71.2542 108.495 71.1761C108.567 71.1161 108.642 71.0543 108.76 70.9078C108.812 70.8436 108.852 70.7872 108.886 70.7398C108.989 70.5963 109.123 70.4253 109.36 70.4283C109.614 70.4403 109.746 70.6497 109.834 70.7884C109.991 71.0357 110.062 71.214 110.109 71.3322C110.126 71.3754 110.146 71.424 110.156 71.439C110.237 71.5591 110.901 71.4468 111.151 71.406C111.713 71.3124 112.198 71.2314 112.399 71.5855C112.542 71.8388 112.436 72.1737 112.072 72.6077C111.959 72.7427 111.839 72.8454 111.732 72.9258C111.821 72.9786 111.9 73.0597 111.932 73.1869C112.008 73.4882 111.749 73.7901 111.162 74.0855C111.017 74.1593 110.821 74.2577 110.546 74.3052C110.417 74.3274 110.301 74.3328 110.201 74.3358C110.199 74.3934 110.185 74.4558 110.15 74.52C110.047 74.7091 109.833 74.7991 109.51 74.7775C109.155 74.7589 108.862 74.6911 108.604 74.6317C108.379 74.5801 108.184 74.5362 108.032 74.5464C107.749 74.5692 107.531 74.7955 107.276 75.089L106.637 75.8351H106.637Z" fill="#F2F2F2"/>
      <path d="M107.909 99.964L107.649 99.8008L107.586 99.5001L107.649 99.8008L107.345 99.8356C107.34 99.8074 107.324 99.7425 107.299 99.6411C107.163 99.0853 106.748 97.3938 106.403 94.73C106.161 92.8705 106.025 90.9588 105.997 89.0471C105.968 87.1323 106.059 85.6828 106.132 84.5177C106.187 83.639 106.253 82.8023 106.319 81.9872C106.493 79.8095 106.657 77.7525 106.535 75.4753C106.508 74.9669 106.451 73.9087 105.833 72.7826C105.474 72.1296 104.98 71.5498 104.364 71.06L104.747 70.5786C105.427 71.12 105.973 71.7623 106.372 72.4873C107.057 73.7358 107.119 74.8889 107.149 75.4429C107.273 77.7604 107.107 79.8372 106.931 82.0358C106.866 82.8479 106.8 83.681 106.745 84.5562C106.673 85.7104 106.583 87.1473 106.611 89.0375C106.639 90.9264 106.774 92.8147 107.012 94.6508C107.353 97.281 107.762 98.9473 107.896 99.4947C107.967 99.787 107.982 99.8482 107.909 99.964L107.909 99.964Z" fill="#F2F2F2"/>
      <path d="M103.238 72.0367C103.212 72.0367 103.187 72.0361 103.161 72.0343C102.638 72.0061 102.153 71.6927 101.72 71.1033C101.517 70.826 101.413 70.5097 101.206 69.8788C101.174 69.7816 101.018 69.2882 100.923 68.6094C100.861 68.1658 100.869 67.9803 100.957 67.8164C101.055 67.634 101.212 67.5067 101.39 67.4209C101.382 67.3621 101.389 67.3015 101.414 67.2396C101.519 66.9833 101.797 67.0169 101.948 67.0332C102.024 67.0428 102.119 67.0554 102.222 67.05C102.383 67.0422 102.47 66.9941 102.602 66.9221C102.727 66.8531 102.884 66.7672 103.116 66.7198C103.573 66.6244 103.956 66.754 104.083 66.7967C104.747 67.0181 105.075 67.5349 105.454 68.1334C105.53 68.2534 105.79 68.6904 105.961 69.2894C106.085 69.7216 106.067 69.9136 106.03 70.0565C105.954 70.35 105.776 70.5181 105.322 70.889C104.849 71.2774 104.611 71.4718 104.407 71.5901C103.932 71.8644 103.634 72.0367 103.238 72.0367V72.0367Z" fill="#F2F2F2"/>
      <path d="M0.706055 100.033C0.706055 100.195 0.836056 100.325 0.997942 100.325H129.101C129.262 100.325 129.392 100.195 129.392 100.033C129.392 99.8712 129.262 99.7412 129.101 99.7412H0.997942C0.836056 99.7412 0.706055 99.8712 0.706055 100.033Z" fill="#3F3D56"/>
      <path d="M53.7153 92.0464L51.3871 94.3746L48.1719 96.0377L48.5045 98.9203L54.6023 98.2551L55.3784 96.7029L55.1566 95.3725L55.6001 93.266L53.7153 92.0464Z" fill="#FFB6B6"/>
      <path d="M48.9483 97.8117L48.7579 95.3657L42.1853 98.477C42.1853 98.477 41.6763 99.9183 43.2612 100.14C44.8461 100.362 55.3787 100.362 55.3787 99.8074C55.3787 99.253 55.6668 96.415 55.6668 96.415L49.2809 98.2552L48.9483 97.8117Z" fill="#2F2E41"/>
      <path d="M68.7363 87.0125L68.2413 90.2677L66.6377 93.513L68.6143 95.6374L73.131 91.487L72.8359 89.7769L71.8687 88.8368L70.9771 86.8774L68.7363 87.0125Z" fill="#FFB6B6"/>
      <path d="M68.3141 94.4815L66.7108 92.6245L63.2617 99.0263C63.2617 99.0263 63.7062 100.489 65.1141 99.7278C66.5219 98.9669 75.0045 92.7234 74.6759 92.2769C74.3473 91.8305 72.897 89.374 72.897 89.374L68.8449 94.6415L68.3141 94.4815H68.3141Z" fill="#2F2E41"/>
      <path d="M68.0225 17.8369H57.5086V11.7654C57.5086 7.40289 61.0503 3.86113 65.4128 3.86113H68.0225V17.8369H68.0225Z" fill="#2F2E41"/>
      <path d="M61.6426 17.7531L62.5722 14.0346L68.4155 13.3706C68.4155 13.3706 68.2827 17.3547 68.6811 18.0187L61.6426 17.7531Z" fill="#FFB6B6"/>
      <path d="M64.2984 15.2301C67.3055 15.2301 69.7433 12.7923 69.7433 9.78522C69.7433 6.77809 67.3055 4.34033 64.2984 4.34033C61.2913 4.34033 58.8535 6.77809 58.8535 9.78522C58.8535 12.7923 61.2913 15.2301 64.2984 15.2301Z" fill="#FFB6B6"/>
      <path d="M56.4635 40.1968L52.4795 46.8369L52.2803 93.7824H55.7828L62.3732 55.4026L60.6468 77.5806L67.779 90.2718L72.5599 88.6782L68.6813 76.5846L74.1926 49.1609L72.0014 40.5952L56.4635 40.1968Z" fill="#2F2E41"/>
      <path d="M68.6809 17.355H61.6424L57.6584 19.2142L56.3303 26.7839C56.3303 26.7839 54.7367 30.4072 56.3303 32.5132C56.3303 32.5132 56.7288 33.424 56.3303 33.8224C55.9319 34.2209 55.9319 35.0177 56.1975 35.2833C56.4632 35.5489 56.4632 37.5325 56.4632 37.5325V40.3935L72.001 40.5954L71.4698 38.0721C71.4698 38.0721 72.2666 37.7772 72.001 36.7295C71.7354 35.6817 72.375 35.5917 72.0552 34.9727C71.7354 34.3537 71.7354 34.2208 71.7354 34.2208L72.7978 33.1584C72.7978 33.1584 73.5946 29.5728 72.2666 27.4479C70.9386 25.3231 72.7978 20.4094 72.7978 20.4094L68.681 17.355L68.6809 17.355Z" fill="#E6E6E6"/>
      <path d="M73.4507 52.9183L72.5527 51.7661C76.4822 48.1557 80.911 43.5048 83.0801 43.562C83.3983 43.3141 83.8573 43.371 84.1052 43.6892C84.3532 44.0074 84.2963 44.4663 83.9781 44.7143C84.0976 48.102 78.1942 50.4058 73.4507 52.9183Z" fill="#3F3D56"/>
      <path d="M71.4715 56.1422C72.865 56.1422 73.9947 55.0125 73.9947 53.6189C73.9947 52.2254 72.865 51.0957 71.4715 51.0957C70.0779 51.0957 68.9482 52.2254 68.9482 53.6189C68.9482 55.0125 70.0779 56.1422 71.4715 56.1422Z" fill="#F7B80B"/>
      <path d="M53.8552 12.6018C54.1226 11.7888 54.6505 11.077 54.8867 10.2543C55.2003 9.16197 55.8679 11.5852 55.933 10.4507C56.0568 8.29419 59.3481 2.74707 61.227 1.68155C63.1059 0.616018 64.1851 0.937281 66.0993 1.93798C66.0993 1.93798 67.0285 1.99708 68.1853 2.5682C69.3421 3.13932 70.3834 4.05399 70.8333 5.26309C71.1899 6.22147 71.1505 7.27395 71.2696 8.28956C71.6016 11.119 73.1877 13.81 72.8473 16.6384C72.741 17.5209 72.4056 18.4334 71.6841 18.9524C70.9626 19.4715 69.7969 19.415 69.322 18.6637C69.1599 18.4073 69.0914 18.1032 68.9525 17.8335C68.8136 17.5639 68.5626 17.3125 68.2594 17.3209C67.8037 17.3335 67.5718 17.945 67.7515 18.364C67.9312 18.783 68.3535 19.036 68.75 19.261C67.8056 19.6858 66.6342 19.5535 65.8084 18.9288C64.9826 18.3041 64.5367 17.2128 64.6886 16.1885C64.9028 14.7451 67.0055 15.0439 67.2532 13.6058C67.4336 12.559 68.0668 10.8074 67.8943 9.7593C67.7218 8.71115 66.8934 9.98026 67.7661 9.37464C66.806 9.83748 64.7524 6.67115 63.7473 6.3165C62.7422 5.96185 61.8899 5.19392 61.4329 4.23104C60.3668 5.77642 59.4291 5.35675 59.0473 7.19493C58.6655 9.03312 59.0185 13.2093 60.5906 14.2355C59.9614 14.6648 59.4599 15.2787 59.1647 15.9808C58.9975 16.3785 58.889 16.8137 58.6115 17.1441C58.0813 17.775 57.1025 17.8134 56.3223 17.5481C55.3548 17.2191 54.5277 16.4967 54.0716 15.5823C53.6155 14.6679 53.536 13.5725 53.8552 12.6018H53.8552Z" fill="#2F2E41"/>
      <path d="M58.6007 6.0449C58.6007 6.0449 56.1858 10.5667 57.9241 12.3219C57.9241 12.3219 65.7718 11.8247 68.5218 13.1607C68.5218 13.1607 70.3748 12.0792 69.6035 8.7031C68.8323 5.32701 63.159 5.09764 58.6007 6.0449Z" fill="#E6E6E6"/>
      <path d="M58.9221 6.39976L57.709 10.8769C57.709 10.8769 61.8928 10.1197 68.0831 12.0175C68.0831 12.0175 69.8003 10.3314 68.8663 7.96332C67.9324 5.5952 58.9221 6.39976 58.9221 6.39976Z" fill="#F7B80B"/>
      <path d="M68.5215 13.1608C68.5215 13.1608 70.4387 13.4124 70.5645 12.4538C70.6903 11.4952 69.905 8.92678 69.6032 8.70319C69.3014 8.4796 69.7708 11.6321 68.5215 13.1608Z" fill="#3F3D56"/>
      <path d="M43.3757 47.2294C44.1706 47.6672 45.3725 47.0095 46.0607 45.7599C46.3424 45.2646 46.5014 44.7089 46.5243 44.1395L47.2863 42.7139L47.3129 42.7223L51.9694 36.6748C53.0521 35.2686 53.4541 33.4352 52.9864 31.7233C52.7082 30.7049 52.1953 29.9354 51.2524 30.3388C49.6878 31.0083 47.3329 36.5621 46.0803 39.7785L44.304 42.9169C43.8349 43.2407 43.4502 43.6722 43.182 44.1753C42.494 45.4243 42.5807 46.7917 43.3757 47.2294V47.2294Z" fill="#FFB6B6"/>
      <path d="M59.5413 19.0389C59.5413 19.0389 55.2146 17.2945 53.5871 21.2403C51.9595 25.1861 50.2627 30.7159 50.2627 30.7159L52.741 34.7332L56.9513 28.3849L59.5413 19.0389Z" fill="#E6E6E6"/>
      <path d="M39.3643 44.6749V43.2141C44.683 42.7817 51.0351 41.8357 52.7109 43.2141C53.1143 43.2141 53.4413 43.5411 53.4413 43.9445C53.4413 44.3479 53.1143 44.6749 52.7109 44.6749C50.7228 47.4205 44.6502 45.6089 39.3643 44.6749Z" fill="#3F3D56"/>
      <path d="M37.3719 46.5343C38.7654 46.5343 39.8951 45.4046 39.8951 44.011C39.8951 42.6175 38.7654 41.4878 37.3719 41.4878C35.9783 41.4878 34.8486 42.6175 34.8486 44.011C34.8486 45.4046 35.9783 46.5343 37.3719 46.5343Z" fill="#F7B80B"/>
      <path d="M78.3237 52.0353C77.4353 52.2206 76.4792 51.2392 76.1879 49.8428C76.0639 49.2866 76.075 48.7087 76.22 48.1577L75.9098 46.5712L75.8819 46.5715L73.2043 39.424C72.5818 37.7621 72.7353 35.8914 73.6846 34.392C74.2493 33.5 74.9655 32.9148 75.7485 33.5771C77.0479 34.6762 77.67 40.6764 77.9239 44.1188L78.7013 47.6401C79.0548 48.0873 79.296 48.6127 79.4048 49.1723C79.6962 50.5682 79.2121 51.85 78.3237 52.0353H78.3237Z" fill="#FFB6B6"/>
      <path d="M71.1377 20.3429C71.1377 20.3429 75.7857 19.9445 76.1842 24.1942C76.5826 28.4439 76.5826 34.2281 76.5826 34.2281L73.0348 37.3416L70.8721 30.0375L71.1377 20.3429Z" fill="#E6E6E6"/>
      <path d="M89.8315 100.217C86.0446 100.217 82.9639 97.1364 82.9639 93.3495C82.9639 89.5627 86.0446 86.4819 89.8315 86.4819C93.6183 86.4819 96.699 89.5627 96.699 93.3495C96.699 97.1364 93.6183 100.217 89.8315 100.217ZM89.8315 86.9725C86.315 86.9725 83.4544 89.8331 83.4544 93.3495C83.4544 96.866 86.315 99.7266 89.8315 99.7266C93.3479 99.7266 96.2085 96.866 96.2085 93.3495C96.2085 89.8331 93.3479 86.9725 89.8315 86.9725Z" fill="#3F3D56"/>
      <path d="M30.1168 100.349H20.5146C19.5104 100.349 18.6934 99.5321 18.6934 98.528V88.9256C18.6934 87.9215 19.5104 87.1045 20.5146 87.1045H30.1168C31.121 87.1045 31.938 87.9215 31.938 88.9256V98.528C31.938 99.5321 31.121 100.349 30.1168 100.349ZM20.5146 87.595C19.7808 87.595 19.1839 88.1919 19.1839 88.9256V98.528C19.1839 99.2617 19.7808 99.8586 20.5146 99.8586H30.1168C30.8506 99.8586 31.4475 99.2617 31.4475 98.528V88.9256C31.4475 88.1919 30.8506 87.595 30.1168 87.595H20.5146Z" fill="#3F3D56"/>
    </g>
    <defs>
      <clipPath id="clip0_1063_18902">
        <rect width="128.947" height="100" fill="white" transform="translate(0.526367 0.71875)"/>
      </clipPath>
    </defs>
  </svg>

);