export const OkIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3147 0.620548C6.96267 0.620548 0.981339 6.60188 0.981339 13.9539C0.981339 21.3059 6.96267 27.2872 14.3147 27.2872C21.6667 27.2872 27.648 21.3059 27.648 13.9539C27.648 6.60188 21.6667 0.620548 14.3147 0.620548ZM14.3147 19.8392L6.70534 12.2299L8.59067 10.3445L14.3147 16.0685L20.0387 10.3445L21.924 12.2299L14.3147 19.8392Z"
      fill="#033349"
    />
  </svg>
);
