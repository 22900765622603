export const OurAdviceIcon = () => (
  <svg width="145" height="150" viewBox="0 0 145 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_461_51716)">
      <path d="M128.038 114.154L128.035 114.222L131.419 150H106.729L103.529 128.026L102.12 149.437L76.7656 149.155L79.4588 121.045L81.7492 108.016V108.013L82.0929 106.052H127.365L127.424 106.762L128.038 114.154Z" fill="#1A202C"/>
      <path d="M62.7893 134.895C65.4042 135.372 68.1307 132.427 68.8794 128.318C69.2071 126.519 69.1013 124.795 68.6596 123.386L69.0605 121.017L75.7889 89.1524C75.7889 89.1524 88.1789 64.3682 88.1786 60.0655C88.1783 55.7629 84.5945 53.6528 84.5945 53.6528L79.7449 53.6982L64.5371 90.8417L61.7981 118.289L61.355 122.055C60.4446 123.218 59.7378 124.794 59.4103 126.592C58.6616 130.702 60.1745 134.419 62.7893 134.895Z" fill="#FFB6B6"/>
      <path d="M112.121 38.299L107.614 27.8755L92.9645 30.4109L91.9785 42.6745L112.121 38.299Z" fill="#FFB6B6"/>
      <path opacity="0.1" d="M112.121 38.299L107.614 27.8755L92.9645 30.4109L91.9785 42.6745L112.121 38.299Z" fill="black"/>
      <path d="M143.956 59.9914C143.956 59.9914 133.532 40.5529 131.56 38.5809C130.683 37.703 129.303 37.6908 128.041 37.9466L113.531 32.9465L108.612 30.1831L108.288 35.4405L92.1415 38.3387C92.2312 35.1325 92.6835 33.913 92.6835 33.913L86.7674 40.8346L75.217 46.469L75.2233 46.5197C74.3757 46.8156 73.5721 47.3219 72.9632 48.1593C70.7095 51.2582 64.5117 89.0083 64.5117 89.0083L77.7524 91.8255L79.5544 80.5175L80.8513 90.6986L81.8373 109.715C81.8373 109.715 99.8673 125.209 108.6 119.575C117.334 113.94 128.039 114.222 128.039 114.222L128.306 105.408C128.496 105.104 128.602 104.925 128.602 104.925L128.321 73.6591V65.3394C130.467 68.3294 133.057 70.9008 136.068 72.1053C145.928 76.0493 143.956 59.9914 143.956 59.9914V59.9914Z" fill="#E6E6E6"/>
      <path d="M98.7582 32.2449C106.201 32.2449 112.235 26.2111 112.235 18.7679C112.235 11.3248 106.201 5.29102 98.7582 5.29102C91.3151 5.29102 85.2812 11.3248 85.2812 18.7679C85.2812 26.2111 91.3151 32.2449 98.7582 32.2449Z" fill="#FFB6B6"/>
      <path d="M108.426 22.5767C108.426 22.5767 109.598 18.475 112.234 18.768C114.871 19.061 115.457 16.7172 114.871 15.5452C114.285 14.3733 113.113 8.5138 113.113 8.5138C113.113 8.5138 113.699 4.41213 110.184 3.82617C106.668 3.24022 105.496 2.65427 104.91 1.48236C104.324 0.310451 95.5347 -0.861457 92.605 0.896406C89.6752 2.65427 87.3314 6.02696 85.2805 6.97741C83.2297 7.92785 81.4718 9.68571 82.6437 12.6155C83.8157 15.5452 85.5153 21.2752 85.5153 21.2752C85.5153 21.2752 88.5033 20.2329 89.0892 21.9907C89.6752 23.7486 87.3314 21.4048 90.2611 16.7172C93.1909 12.0295 93.1909 7.3419 97.8785 9.09976C102.566 10.8576 107.84 10.2717 107.254 13.7874C106.668 17.3031 108.426 22.5767 108.426 22.5767V22.5767Z" fill="#1A202C"/>
      <path d="M107.602 129.416H5.2493C2.35478 129.416 0 127.061 0 124.167V69.7976C0 66.9033 2.35478 64.5483 5.2493 64.5483H107.602C110.496 64.5483 112.851 66.9033 112.851 69.7976V124.167C112.851 127.061 110.496 129.416 107.602 129.416Z" fill="white"/>
      <path d="M107.602 129.416H5.2493C2.35478 129.416 0 127.061 0 124.167V69.7976C0 66.9033 2.35478 64.5483 5.2493 64.5483H107.602C110.496 64.5483 112.851 66.9033 112.851 69.7976V124.167C112.851 127.061 110.496 129.416 107.602 129.416ZM5.2493 65.4249C2.83824 65.4249 0.876595 67.3866 0.876595 69.7976V124.167C0.876595 126.578 2.83824 128.54 5.2493 128.54H107.602C110.013 128.54 111.975 126.578 111.975 124.167V69.7976C111.975 67.3866 110.013 65.4249 107.602 65.4249H5.2493Z" fill="#3F3D56"/>
      <path d="M99.7016 71.123C100.428 71.123 101.017 70.5343 101.017 69.8081C101.017 69.0819 100.428 68.4932 99.7016 68.4932C98.9754 68.4932 98.3867 69.0819 98.3867 69.8081C98.3867 70.5343 98.9754 71.123 99.7016 71.123Z" fill="#3F3D56"/>
      <path d="M103.209 71.123C103.936 71.123 104.524 70.5343 104.524 69.8081C104.524 69.0819 103.936 68.4932 103.209 68.4932C102.483 68.4932 101.895 69.0819 101.895 69.8081C101.895 70.5343 102.483 71.123 103.209 71.123Z" fill="#3F3D56"/>
      <path d="M106.715 71.123C107.441 71.123 108.03 70.5343 108.03 69.8081C108.03 69.0819 107.441 68.4932 106.715 68.4932C105.989 68.4932 105.4 69.0819 105.4 69.8081C105.4 70.5343 105.989 71.123 106.715 71.123Z" fill="#3F3D56"/>
      <path d="M7.45042 91.0654C7.08788 91.0654 6.79297 91.3603 6.79297 91.7229C6.79297 91.8997 6.86124 92.0632 6.98515 92.1839C7.11013 92.3118 7.27386 92.3803 7.45042 92.3803H105.838C106.2 92.3803 106.495 92.0854 106.495 91.7229C106.495 91.5461 106.427 91.3826 106.303 91.2619C106.178 91.1339 106.014 91.0654 105.838 91.0654H7.45042Z" fill="#E6E6E6"/>
      <path d="M93.5658 90.8462V92.5994H7.45082C7.20976 92.5994 6.99061 92.5029 6.83283 92.3408C6.67063 92.183 6.57422 91.9638 6.57422 91.7228C6.57422 91.2406 6.9687 90.8462 7.45081 90.8462H93.5658Z" fill="#F7B80B"/>
      <path d="M104.742 87.5591H95.9759C95.0092 87.5591 94.2227 86.7728 94.2227 85.8059C94.2227 84.839 95.0092 84.0527 95.9759 84.0527H104.742C105.709 84.0527 106.495 84.839 106.495 85.8059C106.495 86.7728 105.709 87.5591 104.742 87.5591Z" fill="#E6E6E6"/>
      <path d="M47.3257 78.3545H8.10866C7.14196 78.3545 6.35547 77.5682 6.35547 76.6013C6.35547 75.6344 7.14196 74.8481 8.10866 74.8481H47.3257C48.2924 74.8481 49.0789 75.6344 49.0789 76.6013C49.0789 77.5682 48.2924 78.3545 47.3257 78.3545Z" fill="#E6E6E6"/>
      <path d="M7.45042 110.789C7.08788 110.789 6.79297 111.083 6.79297 111.446C6.79297 111.623 6.86124 111.786 6.98515 111.907C7.11013 112.035 7.27386 112.103 7.45042 112.103H105.838C106.2 112.103 106.495 111.809 106.495 111.446C106.495 111.269 106.427 111.106 106.303 110.985C106.178 110.857 106.014 110.789 105.838 110.789H7.45042Z" fill="#E6E6E6"/>
      <path d="M59.8168 110.57V112.323H7.45082C7.20976 112.323 6.99061 112.227 6.83283 112.064C6.67063 111.907 6.57422 111.687 6.57422 111.446C6.57422 110.964 6.9687 110.57 7.45081 110.57H59.8168Z" fill="#F7B80B"/>
      <path d="M104.742 107.282H95.9759C95.0092 107.282 94.2227 106.496 94.2227 105.529C94.2227 104.562 95.0092 103.776 95.9759 103.776H104.742C105.709 103.776 106.495 104.562 106.495 105.529C106.495 106.496 105.709 107.282 104.742 107.282Z" fill="#E6E6E6"/>
      <path d="M120.088 33.2824C127.67 33.2824 133.815 27.2661 133.815 19.8446C133.815 12.4231 127.67 6.40674 120.088 6.40674C112.507 6.40674 106.361 12.4231 106.361 19.8446C106.361 27.2661 112.507 33.2824 120.088 33.2824Z" fill="#F7B80B"/>
      <path d="M124.747 13.0663C122.919 16.394 121.092 19.7217 119.264 23.0494C118.1 20.9915 116.941 18.9299 115.774 16.8736C115.37 16.1634 114.274 16.8022 114.678 17.5141C116.031 19.8979 117.371 22.2895 118.724 24.6733C118.956 25.0809 119.591 25.0903 119.82 24.6733C121.828 21.0178 123.835 17.3623 125.843 13.7069C126.236 12.9906 125.141 12.3495 124.747 13.0663Z" fill="white"/>
      <path d="M139.039 55.2649L125.362 48.7225C125.362 48.7225 124.813 44.3436 122.248 42.7817C121.812 41.7414 121.31 40.5986 120.855 39.6931C119.728 37.4538 121.418 33.228 120.01 33.228C118.601 33.228 116.519 37.6722 116.629 38.8624C116.714 39.776 117.469 41.371 117.625 42.7672C115.907 43.415 114.521 44.5254 113.61 45.4095C112.822 46.1745 112.529 47.316 112.837 48.3705C113.73 51.4263 115.962 57.7374 119.165 57.7374C123.39 57.7374 124.517 56.8923 124.517 56.8923C124.517 56.8923 133.25 67.8793 139.73 68.7244C146.209 69.5696 139.039 55.2649 139.039 55.2649V55.2649Z" fill="#FFB6B6"/>
    </g>
    <defs>
      <clipPath id="clip0_461_51716">
        <rect width="144.116" height="150" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);