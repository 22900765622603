import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Flex,
  InputGroup,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BottomBlock from "../../components/BottomActionsBlock/BottomBlock";
import { OutfitType } from "../../components/DressingBlock/DressingBlock";
import Header from "../../components/Header";
import { PencilIcon } from "../../components/Icons/PencilIcon";
import ContentLayout from "../../components/Layout/ContentLayout";
import MainContainer from "../../components/Layout/MainContainer";
import {
  OptionRecapFunction,
  PersonnelRecapfunction,
  dressingTypeTranslation,
} from "../../components/TotalCard/TotalCard";
import { useOnboardingContext } from "../../context/OnboardingContext";
import { OptionType, useOptionContext } from "../../context/OptionsContext";
import { useSegment } from "../../context/SegmentContext";
import { useSessionContext } from "../../context/SessionContext";
import { PersonnelType, useUserContext } from "../../context/UserContext";
import { PageURL } from "../../types/pages";
import { colors } from "../../ui/theme";
import {
  getColor,
  getDayList,
  showEventDayHoursToUser,
} from "../../utils/utils";
import {
  EVENT_CATEGORY_TEXTS,
  EVENT_SUBCATEGORY_TEXTS,
  EventCategory,
  EventSubCategory,
} from "../Onboarding/EventTypes";
import { DownCrossIcon } from "../../components/Icons/DownCrossIcon";
import NorelaButton from "../../components/Buttons/NorelaButton";
import { TeamSplittingIcon } from "../../components/Icons/TeamSplittingIcon";

const BRIEF_PLACEHOLDER =
  "Ex: J’ai besoin de personnel pour un série d’événements qui auront lieux dans plusieurs villes: 2 barmaids le 28 septembre à Brest 2 barmaids le 29 septembre à Bordeaux 2 barmaids le 30 septembre à Toulouse Ces événements auront lieux dans des barsLes missions : accueillir les invités inscrits sur listes, explication du concept et distribution de boissons.";

export default function RecapContent() {
  const briefRef = useRef<any>(null);
  const fakeBoxRef = useRef<any>(null);
  const handleBriefClick = () => {
    if (briefRef.current) {
      briefRef.current.focus();
    }
  };
  const { userInfos, setUserInfos } = useUserContext();

  const {
    eventCategory,
    eventSubcategory,
    nbParticipants,
    salonSize,
    eventName,
    eventLocations,
    flyerConfig,
  } = useOnboardingContext();

  const { sessionInfo, setBrief, updateHistory } = useSessionContext();
  const selectDePersonnel = userInfos?.selectDePersonnelInfo?.persons;
  const amountOfPersonels =
    (selectDePersonnel &&
      Object.keys(selectDePersonnel).reduce((acc, personKey) => {
        return acc + selectDePersonnel[personKey as PersonnelType]!.count;
      }, 0)) ||
    0;

  const dressing = userInfos?.outfitInfos;
  const printDressingOption = (): string => {
    if (userInfos?.outfitInfos?.professionalOutfit) {
      return ": " + userInfos?.outfitInfos?.professionalOutfit;
    } else if (userInfos?.outfitInfos?.personalOutfit) {
      if (userInfos.outfitInfos.personalOutfit.generic) {
        return ": " + userInfos.outfitInfos.personalOutfit.generic;
      } else if (userInfos.outfitInfos.personalOutfit.custom) {
        return ": Personnalisée";
      }
    }
    return "";
  };
  const { optionInfo } = useOptionContext();
  const [brief, _setBrief] = useState<string>(sessionInfo?.brief || "");
  const [fakeW, setFakeW] = useState<number>(500);
  const [fakeH, setFakeH] = useState<number>(150);
  const { track } = useSegment();
  const navigate = useNavigate();
  const onChangeBrief = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBrief(event.target.value);
    _setBrief(event.target.value);
    setTimeout(() => {}, 500);
    setFakeW(fakeBoxRef.current?.getBoundingClientRect().width);
    setFakeH(fakeBoxRef.current?.getBoundingClientRect().height);
  };
  const amountOfOptions =
    (optionInfo &&
      Object?.keys(optionInfo.selectedOptions)?.filter(
        (optionKey) => optionInfo?.selectedOptions[optionKey as OptionType] > 0
      )?.length) ||
    0;

  const doWeNeedLogo = (): boolean => {
    if (userInfos?.outfitInfos?.outfitType === OutfitType.PRINTED_OUTFIT)
      return true;
    if (optionInfo?.selectedOptions?.[OptionType.Ballonbag] > 0) return true;
    if (optionInfo?.selectedOptions?.[OptionType.Oriflamme] > 0) return true;
    if (optionInfo?.selectedOptions?.[OptionType.BesacePrint] > 0) return true;
    if (optionInfo?.selectedOptions?.[OptionType.TotbagR] > 0) return true;
    if (optionInfo?.selectedOptions?.[OptionType.TotbagRV] > 0) return true;
    return false;
  };
  const needLogo = doWeNeedLogo();
  const [logoTooHeavy, setLogoTooHeavy] = useState<boolean>(false);
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.size > 748567) {
      setLogoTooHeavy(true);
      setUserInfos({
        ...userInfos,
        logo: undefined,
      });
      return;
    }

    const selectPrintedLogo = (value: string | undefined) => {
      setUserInfos({
        ...userInfos,
        logo: value,
      });
    };
    const reader = new FileReader();
    reader.onloadend = () => {
      selectPrintedLogo(reader.result as string);
    };
    if (file) {
      reader.readAsDataURL(file);
      setLogoTooHeavy(false);
    }
  };

  const totalEventDays = getDayList(userInfos.calendarInfo!)?.length ?? 1;

  const [multiAddressPopup, setMultiAddressPopup] = useState(
    eventLocations && eventLocations?.length > 1 ? true : false
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ref = useRef(null);
  useEffect(() => {
    if (multiAddressPopup) {
      onOpen();
      setMultiAddressPopup(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={ref}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent minW="fit-content" p="2rem 1rem 1rem 1rem">
          <Flex gap="2rem">
            <Box w="400px">
              <Flex>
                <AlertDialogHeader minW="200px" my="auto">
                  Répartissez vos équipes
                </AlertDialogHeader>
                <TeamSplittingIcon />
              </Flex>
              <Text p="3rem 2rem">
                Vous avez indiqué <b>plusieurs adresses</b> pour votre événement
                au début de la commande
                <br />
                <br />
                Merci d’indiquer la répartition de vos équipes en fonction des
                adresses afin de finaliser votre commande
                <br />
                <br />
                Notre équipe prendra ensuite contact après la commande pour
                confirmer vos besoins.
              </Text>
            </Box>
            <Flex flexDir="column" mt="2rem" gap="2rem">
              <Box
                border="1px solid black"
                borderRadius="8px"
                p="1rem"
                w="100%"
              >
                <Text fontWeight="bold">Votre commande :</Text>
                <Text>7 hôtesses d'accueil et 2 animateurs</Text>
              </Box>
              <DownCrossIcon mx="auto" />
              <Box
                border="1px solid black"
                borderRadius="8px"
                p="1rem"
                w="100%"
              >
                <Text>
                  Votre brief :
                  <br />
                  J’ai besoin de 3 équipes sur 3 adresses différentes :
                  <br />
                  - Adresse A : 3 hôtesses d’accueil et 1 Animateur micro de 9h
                  à 18H
                  <br />
                  - Adresse B : 2 hôtesses d’accueil de 8h30 à 17h
                  <br />- Adresse C : 2 hôtesses d’accueil et 1 Animateur micro
                  de 9h à 18h
                </Text>
              </Box>
            </Flex>
          </Flex>
          <AlertDialogFooter justifyContent="center">
            <NorelaButton onClick={onClose}>J'ai compris</NorelaButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <ContentLayout>
        <MainContainer>
          <Box // Fake element, made to calculate the size needed for the textarea (brief)
            position="fixed"
            top="0"
            left="0"
            opacity="0"
            h="0px"
            overflow="hidden"
            bg="red"
          >
            <Box ref={fakeBoxRef} maxW="800px">
              {brief}wwwwwwww
            </Box>
          </Box>
          <Flex
            direction="column"
            gap="1rem"
            ml="2rem"
            // maxW="100%"
            w="auto"
          >
            <Header
              title="Récapitulatif de votre commande"
              subtitle="Retrouvez toutes les informations concernant votre commande. Remplissez votre brief pour ajouter des précisions sur votre commande."
            />
            <Box
              w="auto"
              h="100%"
              border={`1px solid ${colors.gray.lightMode}`}
              borderRadius="8px"
              p="1rem"
            >
              <Flex>
                <Box h="100%">
                  {/* 1 */}
                  {eventName && (
                    <>
                      <Text fontWeight="bold" textAlign="left">
                        Nom:
                      </Text>
                      <Text textAlign="left">{eventName}</Text>
                    </>
                  )}
                  {eventLocations && (
                    <>
                      <Text mt="0.5rem" fontWeight="bold" textAlign="left">
                        Adresse:
                      </Text>
                      {eventLocations.map((location, index) => (
                        <Text key={index} textAlign="left">
                          {location.address}
                        </Text>
                      ))}
                    </>
                  )}
                  {userInfos?.calendarInfo?.days && (
                    <>
                      <Text fontWeight="bold" textAlign="left">
                        Date
                        {userInfos.calendarInfo.days.length !== 1 ? "s" : ""}:
                      </Text>
                      {userInfos.calendarInfo.days
                        .sort((a, b) => moment(a).unix() - moment(b).unix())
                        .map((day) => {
                          const toShow = showEventDayHoursToUser(
                            userInfos?.calendarInfo?.eventSchedule?.find(
                              (schedule) => schedule.day === day
                            )
                          );

                          return (
                            <Text key={day.toString()} textAlign="left">
                              -{" "}
                              {moment(day)
                                .toDate()
                                .toLocaleDateString("fr-FR", {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                })}
                              {toShow}
                            </Text>
                          );
                        })}
                    </>
                  )}
                  {userInfos?.calendarInfo?.period && (
                    <>
                      <Text fontWeight="bold" textAlign="left">
                        Date(s):
                      </Text>
                      <Text textAlign="left">
                        {"Du "}
                        {moment(userInfos.calendarInfo?.period.startDate)
                          .toDate()
                          .toLocaleDateString("fr-FR", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          })}
                        {" au "}
                        {moment(userInfos.calendarInfo?.period.endDate)
                          .toDate()
                          .toLocaleDateString("fr-FR", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          })}
                      </Text>
                    </>
                  )}
                </Box>
                {(eventCategory || nbParticipants || salonSize) && ( // vertical line only if needed
                  <Flex my="1rem" mx="1.5rem" flexDirection="column">
                    <Box h="100%" w="1px" bg="#aaa" m="auto" />
                  </Flex>
                )}
                <Box h="100%">
                  {/* 2 */}
                  {eventCategory && (
                    <>
                      <Text mt="0.5rem" fontWeight="bold" textAlign="left">
                        Type d'événement:
                      </Text>
                      <Text textAlign="left">
                        {
                          EVENT_CATEGORY_TEXTS[eventCategory as EventCategory]
                            .totalCard
                        }
                        {eventSubcategory
                          ? ` - ${
                              EVENT_SUBCATEGORY_TEXTS[
                                eventSubcategory as EventSubCategory
                              ].totalCard
                            }`
                          : ""}
                      </Text>
                    </>
                  )}
                  {(eventCategory ?? undefined) ===
                    EventCategory.STREET_MARKETING && (
                    <>
                      <Text fontWeight="bold" textAlign="left">
                        Quantité de flyers distribués:
                      </Text>
                      <Text textAlign="left">{flyerConfig?.nbFlyers}</Text>
                    </>
                  )}
                  {(nbParticipants || salonSize) && (
                    <>
                      <Text fontWeight="bold" textAlign="left">
                        Taille de l'événement:
                      </Text>
                      <Text textAlign="left">
                        {nbParticipants ? `${nbParticipants}` : `${salonSize}`}
                      </Text>
                    </>
                  )}
                </Box>
                {amountOfPersonels > 0 && ( // vertical line only if needed
                  <Flex my="1rem" mx="1.5rem" flexDirection="column">
                    <Box h="100%" w="1px" bg="#aaa" m="auto" />
                  </Flex>
                )}
                <Box h="100%">
                  {/* 3 */}
                  {amountOfPersonels > 0 && (
                    <Text fontWeight="bold" textAlign="left">
                      Votre équipe:
                    </Text>
                  )}
                  <PersonnelRecapfunction
                    selectPersonnelInfo={userInfos.selectDePersonnelInfo}
                    calendarInfo={userInfos.calendarInfo!}
                    fontsize="12px"
                  />
                </Box>
                {dressing?.outfitType && ( // vertical line only if needed
                  <Flex my="1rem" mx="1.5rem" flexDirection="column">
                    <Box h="100%" w="1px" bg="#aaa" m="auto" />
                  </Flex>
                )}
                <Box h="100%">
                  {/* 4 */}
                  {dressing?.outfitType && (
                    <>
                      <Text fontWeight="bold" textAlign="left">
                        Dressing:
                      </Text>
                      <Text textAlign="left">
                        {`${
                          dressingTypeTranslation[
                            dressing?.outfitType as string
                          ]
                        }`}
                        {printDressingOption()}
                      </Text>
                      {dressing.presentation && (
                        <>
                          <Text fontWeight="bold" textAlign="left">
                            Présentation:
                          </Text>
                          {dressing.presentation["hair"] && (
                            <Text textAlign="left">
                              {"Coiffure: " + dressing?.presentation["hair"]}
                            </Text>
                          )}
                          {dressing.presentation["nails"] && (
                            <Text textAlign="left">
                              {"Ongles: " +
                                getColor(dressing?.presentation["nails"])[2]}
                            </Text>
                          )}
                          {dressing.presentation["lipstick"] && (
                            <Text textAlign="left">
                              {"Lèvres: " +
                                getColor(dressing?.presentation["lipstick"])[3]}
                            </Text>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Box>
                {amountOfOptions > 0 && ( // vertical line only if needed
                  <Flex my="1rem" mx="1.5rem" flexDirection="column">
                    <Box h="100%" w="1px" bg="#aaa" m="auto" />
                  </Flex>
                )}
                <Box h="100%">
                  {amountOfOptions > 0 && (
                    <Text fontWeight="bold" textAlign="left">
                      OPTIONS:
                    </Text>
                  )}
                  <OptionRecapFunction
                    optionInfo={optionInfo}
                    fontsize="16px"
                    nbDays={totalEventDays}
                    nbPersons={amountOfPersonels}
                  />
                </Box>
              </Flex>
            </Box>
            <Flex gap="1rem" w="auto">
              <Box
                h="auto"
                border={`1px solid ${colors.gray.lightMode}`}
                borderRadius="8px"
                p="0.5rem"
              >
                <Text textAlign="left" fontSize="16px">
                  Votre brief
                </Text>
                <Flex
                  m="0.5rem 0 1rem 1rem"
                  flexDirection={"row"}
                  gap="1rem"
                  w="auto"
                >
                  <InputGroup w="auto">
                    <Textarea
                      ref={briefRef}
                      width={fakeW}
                      height={fakeH}
                      minW="500px"
                      minH="150px"
                      maxH="400px"
                      resize="none"
                      id="message"
                      name="message"
                      value={brief}
                      onChange={onChangeBrief}
                      placeholder={BRIEF_PLACEHOLDER}
                      _focusVisible={{
                        borderColor: colors.secondaryFontColor.lightMode,
                        boxShadow: `0 0 0 1px ${colors.secondaryFontColor.lightMode}`,
                      }}
                      errorBorderColor={colors.red.default}
                      focusBorderColor={colors.blue.default}
                    />
                  </InputGroup>
                  <PencilIcon
                    m="2rem 0.5rem 0 0"
                    onClick={() => handleBriefClick()}
                  />
                </Flex>
              </Box>
              {needLogo ? (
                <Box
                  w="300px"
                  h="auto"
                  border={`1px solid ${colors.gray.lightMode}`}
                  borderRadius="8px"
                  p="0.5rem"
                >
                  <Text textAlign="left" fontSize="16px">
                    Déposez votre logo ici
                  </Text>
                  <Box ml="1rem" mt="1rem">
                    {logoTooHeavy && (
                      <Text color="red" align="left">
                        Attention, l'image est trop volumineuse: veuillez en
                        selectionner une autre.
                      </Text>
                    )}
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleFileUpload}
                    ></input>
                    {userInfos?.logo && (
                      <Box p="1rem 0.5rem 0.5rem 0">
                        <img
                          src={userInfos?.logo}
                          alt="logo"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : (
                <></>
              )}
            </Flex>
          </Flex>
        </MainContainer>
        <BottomBlock
          disableNextPage={needLogo && !userInfos?.logo}
          onNextPage={() => {
            if (brief) track("Give a brief", { brief });
            updateHistory(PageURL.ORDER as PageURL);
            navigate(PageURL.ORDER as PageURL);
          }}
        />
      </ContentLayout>
    </>
  );
}
