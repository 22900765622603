export const InagurationIcon = () => (
  <svg
    width="190"
    height="100"
    viewBox="0 0 190 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1318_28766)">
      <g clipPath="url(#clip1_1318_28766)">
        <path
          d="M160.93 8.24124C160.93 8.24124 160.956 8.28689 161.002 8.36736C161.049 8.44706 161.11 8.56467 161.195 8.69853C161.279 8.83316 161.377 8.99024 161.481 9.15891C161.535 9.24248 161.586 9.33146 161.645 9.41735C161.703 9.50323 161.762 9.59144 161.821 9.67888C161.941 9.85297 162.055 10.0317 162.171 10.1919C162.288 10.352 162.397 10.5014 162.491 10.6298C162.585 10.7583 162.662 10.865 162.721 10.937C162.778 11.0097 162.811 11.0515 162.811 11.0515"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M158.952 4.79651C158.952 4.79651 158.819 4.96132 158.62 5.20737C158.521 5.3304 158.405 5.47432 158.28 5.62907C158.16 5.78692 158.032 5.95482 157.903 6.12272C157.649 6.46085 157.387 6.79279 157.205 7.05355C157.02 7.31121 156.897 7.48221 156.897 7.48221"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M155.927 8.86954C155.927 8.86954 155.897 8.91287 155.845 8.98947C155.793 9.06607 155.716 9.17362 155.63 9.30671C155.456 9.57211 155.223 9.92494 154.991 10.2785C154.759 10.6321 154.536 10.9919 154.365 11.2589C154.195 11.5266 154.082 11.7053 154.082 11.7053"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M153.197 13.1469C153.197 13.1469 153.087 13.3279 152.923 13.5995C152.76 13.8719 152.543 14.234 152.325 14.5969C151.894 15.325 151.463 16.0523 151.463 16.0523"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M150.604 17.5108C150.604 17.5108 150.174 18.2389 149.744 18.967C149.31 19.6928 148.876 20.4194 148.876 20.4194"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M147.994 21.8632C147.994 21.8632 147.882 22.0419 147.712 22.3104C147.542 22.5781 147.321 22.9387 147.09 23.2931C146.86 23.6475 146.629 24.0018 146.456 24.268C146.37 24.4011 146.293 24.5094 146.242 24.586C146.19 24.6626 146.161 24.7067 146.161 24.7067"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M145.201 26.1003C145.201 26.1003 145.078 26.2728 144.895 26.5313C144.716 26.7928 144.456 27.1271 144.205 27.4675C144.078 27.637 143.951 27.8064 143.833 27.965C143.71 28.1206 143.594 28.2652 143.496 28.3898C143.299 28.6382 143.168 28.8046 143.168 28.8046"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M142.084 30.1037C142.084 30.1037 142.05 30.1439 141.991 30.2143C141.929 30.2832 141.84 30.3807 141.733 30.4983C141.519 30.7327 141.239 31.0492 140.95 31.3579C140.655 31.6605 140.359 31.963 140.137 32.1897C140.081 32.2462 140.031 32.2988 139.985 32.3445C139.938 32.3886 139.898 32.4273 139.864 32.459C139.796 32.5224 139.758 32.5588 139.758 32.5588"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M138.512 33.704C138.512 33.704 138.352 33.8425 138.112 34.0498C137.991 34.1528 137.855 34.2773 137.7 34.4011C137.546 34.5265 137.382 34.6596 137.218 34.7927C136.889 35.0588 136.561 35.3258 136.315 35.5254C136.066 35.7219 135.899 35.8527 135.899 35.8527"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M134.566 36.8934C134.566 36.8934 134.398 37.0218 134.145 37.2137C133.892 37.4048 133.559 37.6648 133.218 37.9163C132.537 38.4177 131.856 38.9198 131.856 38.9198"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M130.478 39.901C130.478 39.901 130.306 40.0248 130.043 40.2035C129.782 40.383 129.434 40.6229 129.085 40.8627C128.738 41.1049 128.383 41.3355 128.119 41.5112C127.855 41.6868 127.679 41.8036 127.679 41.8036"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M126.26 42.7236C126.26 42.7236 126.082 42.8373 125.814 43.0083C125.549 43.1817 125.187 43.4006 124.826 43.6227C124.466 43.844 124.105 44.0645 123.834 44.2309C123.561 44.3918 123.379 44.4994 123.379 44.4994"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M121.919 45.3528C121.919 45.3528 121.182 45.7683 120.445 46.1831C119.701 46.5854 118.956 46.9878 118.956 46.9878"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M117.454 47.7646C117.454 47.7646 117.407 47.7886 117.325 47.8311C117.243 47.8737 117.125 47.934 116.982 48.0029C116.697 48.1422 116.317 48.3287 115.937 48.5144C115.747 48.6072 115.558 48.7016 115.379 48.7867C115.199 48.8703 115.031 48.9484 114.887 49.015C114.599 49.1488 114.408 49.2378 114.408 49.2378"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M112.865 49.9319C112.865 49.9319 112.671 50.017 112.38 50.1439C112.235 50.2066 112.066 50.2839 111.883 50.3598C111.699 50.4356 111.504 50.5161 111.309 50.5965C110.918 50.7575 110.526 50.9184 110.233 51.0391C109.938 51.1536 109.74 51.2295 109.74 51.2295"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M108.158 51.8299C108.158 51.8299 107.96 51.9011 107.661 52.0079C107.361 52.1131 106.965 52.2594 106.565 52.3963C105.761 52.6609 104.957 52.9248 104.957 52.9248"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M103.337 53.413C103.337 53.413 103.136 53.4773 102.829 53.5577C102.522 53.6405 102.115 53.7512 101.706 53.8618C101.299 53.9779 100.886 54.0692 100.578 54.145C100.269 54.2193 100.064 54.2688 100.064 54.2688"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M98.4116 54.6317C98.4116 54.6317 98.205 54.675 97.894 54.74C97.7392 54.7748 97.5566 54.805 97.3616 54.8398C97.1667 54.8746 96.9585 54.9118 96.7504 54.9489C96.3341 55.0216 95.9186 55.1021 95.6037 55.1416C95.2895 55.1872 95.0806 55.2174 95.0806 55.2174"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M93.4023 55.4364C93.4023 55.4364 93.1919 55.4596 92.877 55.4952C92.7191 55.5122 92.535 55.5331 92.3384 55.5548C92.1411 55.5702 91.9299 55.5857 91.7194 55.602C91.2977 55.6345 90.876 55.6662 90.5596 55.6902C90.2423 55.7025 90.0311 55.7118 90.0311 55.7118"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M88.3396 55.7629C88.3396 55.7629 88.1284 55.7629 87.8111 55.7644C87.6525 55.7644 87.4676 55.7652 87.2695 55.766C87.1705 55.766 87.0675 55.7683 86.9639 55.766C86.8602 55.7621 86.7542 55.7583 86.6482 55.7552C86.2257 55.7405 85.8025 55.725 85.486 55.7142C85.3274 55.7087 85.1951 55.7049 85.103 55.6956C85.0109 55.6886 84.9583 55.6848 84.9583 55.6848"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M83.2717 55.5532L79.9036 55.2244"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M78.2207 55.0549C78.2207 55.0549 77.3789 54.9714 76.5378 54.8785C75.6967 54.788 74.8556 54.6982 74.8556 54.6982"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M73.1748 54.5032C73.1748 54.5032 72.9651 54.4785 72.6494 54.4421C72.3345 54.4042 71.9144 54.3585 71.495 54.302C70.6562 54.1937 69.8167 54.0854 69.8167 54.0854"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M68.1409 53.8541C68.1409 53.8541 67.3037 53.7334 66.4665 53.6119C65.6316 53.4765 64.7967 53.3411 64.7967 53.3411"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M63.1292 53.0563C63.1292 53.0563 62.2974 52.9046 61.4648 52.7522C61.0485 52.6756 60.6361 52.5835 60.3251 52.5216C60.014 52.4574 59.8074 52.4149 59.8074 52.4149"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M58.1526 52.0605C58.1526 52.0605 57.3285 51.8694 56.5045 51.6783C56.0913 51.5878 55.6835 51.4756 55.3763 51.3966C55.0692 51.3169 54.8649 51.2635 54.8649 51.2635"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M53.2283 50.8318C53.2283 50.8318 52.4158 50.5973 51.6026 50.3629C51.3991 50.3041 51.1964 50.2453 51.0061 50.1903C50.8149 50.1369 50.6401 50.0758 50.4884 50.0286C50.1859 49.9319 49.9847 49.8677 49.9847 49.8677"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M48.3735 49.3539C48.3735 49.3539 48.3233 49.3377 48.235 49.3098C48.1476 49.2789 48.023 49.2355 47.8737 49.1829C47.5742 49.0777 47.175 48.9384 46.7757 48.7984C46.3765 48.6583 45.9772 48.5183 45.6778 48.4138C45.5277 48.3627 45.4054 48.3125 45.3188 48.2807C45.2321 48.2475 45.1826 48.2289 45.1826 48.2289"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M43.6021 47.6238C43.6021 47.6238 43.4048 47.548 43.1084 47.435C42.8105 47.3267 42.4221 47.158 42.0306 46.9978C41.2491 46.6744 40.4676 46.351 40.4676 46.351"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M38.9128 45.6832C38.9128 45.6832 38.7194 45.5966 38.43 45.4674C38.1406 45.3381 37.7561 45.1625 37.3715 44.9853C36.6032 44.6317 35.841 44.2634 35.841 44.2634"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M34.3223 43.5198C34.3223 43.5198 33.5655 43.1422 32.8134 42.7538C32.0614 42.3661 31.3162 41.9661 31.3162 41.9661"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M29.8298 41.1568C29.8298 41.1568 29.0893 40.7482 28.3558 40.3265C27.6215 39.9072 26.8942 39.4738 26.8942 39.4738"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M25.4465 38.5987C25.4465 38.5987 24.7254 38.1569 24.0128 37.7012C23.6553 37.4752 23.3017 37.2423 23.0355 37.0706C22.7709 36.8957 22.5945 36.7796 22.5945 36.7796"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M21.1919 35.8333C21.1919 35.8333 20.494 35.3559 19.8069 34.8615C19.4618 34.617 19.1221 34.3647 18.8652 34.179C18.6106 33.9895 18.4412 33.8633 18.4412 33.8633"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M39.2441 6.83844C39.2441 6.83844 39.173 7.03729 39.0662 7.33674C39.0391 7.41102 39.0105 7.49226 38.9795 7.57738C38.9463 7.66171 38.9114 7.75147 38.8751 7.84355C38.8008 8.0277 38.7288 8.22656 38.6437 8.41999C38.5601 8.61421 38.4774 8.80842 38.3992 8.99102C38.3164 9.17131 38.2383 9.33921 38.1725 9.48313C38.0417 9.77174 37.9442 9.95977 37.9442 9.95977"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M37.155 11.4562C37.155 11.4562 37.0506 11.6396 36.8935 11.9158C36.7295 12.1874 36.5167 12.5526 36.2869 12.9078C36.0648 13.2676 35.8234 13.6158 35.6493 13.8804C35.4675 14.1404 35.3468 14.3137 35.3468 14.3137"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M34.3401 15.6747C34.3401 15.6747 34.3084 15.7165 34.2519 15.79C34.1938 15.862 34.1118 15.9656 34.0128 16.0894C33.913 16.2125 33.8 16.3595 33.6723 16.5111C33.5454 16.6636 33.41 16.8261 33.2746 16.9878C32.9976 17.3073 32.7222 17.6284 32.5071 17.8613C32.4003 17.979 32.3121 18.0772 32.2494 18.1453C32.186 18.2126 32.1504 18.2513 32.1504 18.2513"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M30.9705 19.4646C30.9705 19.4646 30.8188 19.6124 30.5921 19.8336C30.4768 19.9427 30.3422 20.0689 30.1975 20.2051C30.0543 20.342 29.8918 20.4782 29.7348 20.619C29.5793 20.7622 29.4106 20.8906 29.2581 21.0167C29.1808 21.0786 29.108 21.1405 29.0361 21.1962C28.9633 21.2512 28.8953 21.303 28.8318 21.3502C28.7049 21.4462 28.5997 21.5251 28.5262 21.5808C28.4898 21.6094 28.4596 21.6295 28.4395 21.6435C28.4194 21.6574 28.4086 21.6651 28.4086 21.6651"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M26.9988 22.6037C26.9988 22.6037 26.9539 22.6315 26.875 22.6795C26.7953 22.7275 26.6846 22.7987 26.5469 22.8784C26.2715 23.0362 25.9078 23.2513 25.5333 23.4486C25.1634 23.6544 24.7827 23.8394 24.5019 23.9856C24.2164 24.1241 24.026 24.2162 24.026 24.2162"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M22.4856 24.9164C22.4856 24.9164 22.4369 24.9373 22.3525 24.9745C22.2674 25.0108 22.1467 25.065 21.9997 25.1238C21.7064 25.2437 21.3165 25.4078 20.9211 25.5587C20.1342 25.8689 19.3364 26.1506 19.3364 26.1506"
          stroke="white"
          strokeWidth="0.619004"
          strokeMiterlimit="10"
        />
        <path
          d="M7.94162 87.965V76.3254H14.8775V53.4695H27.0673V63.8394H32.5478V26.5166H45.6064V41.2248H59.6834V15.2298H79.8559V4.4545H104V11.8083H118.771V22.5836H123.469V38.6552H130.01V21.3014H147.256V39.9249H161.503V60.4534H171.471V73.1515H178.955V87.965H7.94162Z"
          fill="#CADFF4"
        />
        <path
          d="M97.9161 26.8941H121.291V82.1193H97.9161V26.8941Z"
          fill="#446B8C"
        />
        <path
          d="M100.109 29.0877V79.9265H119.098V29.0877H100.109ZM95.7226 24.7013H123.484V84.3137H95.7226V24.7005V24.7013Z"
          fill="#355370"
        />
        <path
          d="M70.1539 26.8941H93.5291V82.1193H70.1539V26.8941Z"
          fill="#446B8C"
        />
        <path
          d="M72.3475 29.0877V79.9265H91.3362V29.0877H72.3475ZM67.9611 24.7013H95.7227V84.3137H67.9611V24.7005V24.7013Z"
          fill="#355370"
        />
        <path
          d="M-23.1261 86.8284H214.571V100.016H-23.1261V86.8284Z"
          fill="#CADFF4"
        />
        <path
          d="M17.3767 91.6055H174.068C175.435 91.6055 176.544 90.4967 176.544 89.1295C176.544 87.7623 175.435 86.6535 174.068 86.6535H17.3767C16.0094 86.6535 14.9007 87.7623 14.9007 89.1295C14.9007 90.4967 16.0094 91.6055 17.3767 91.6055Z"
          fill="#B8D1E6"
        />
        <path
          d="M67.9609 9.6866V84.3129H123.484V9.6866H67.9609ZM65.4454 7.17112H126V86.8291H65.4454V7.17035V7.17112Z"
          fill="white"
        />
        <path
          d="M117.494 7.17035H126.202L139.265 20.9347H126.823L117.494 7.17035Z"
          fill="#F7B80B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M139.265 20.9347V23.8285C139.265 27.264 136.48 30.0487 133.044 30.0487C129.609 30.0487 126.824 27.264 126.824 23.8285V20.9347H139.265Z"
          fill="#C18E0C"
        />
        <path
          d="M114.384 20.9347L108.785 7.17035H117.494L126.824 20.9347H114.384Z"
          fill="white"
        />
        <path
          d="M126.824 20.9347V23.8285C126.824 27.264 124.039 30.0487 120.604 30.0487C117.168 30.0487 114.383 27.264 114.383 23.8285V20.9347H126.824Z"
          fill="#C5E8FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.384 20.9347V23.8285C114.384 27.264 111.599 30.0487 108.164 30.0487C104.728 30.0487 101.943 27.264 101.943 23.8285V20.9347H114.384Z"
          fill="#C18E0C"
        />
        <path
          d="M101.943 20.9347L100.076 7.17035H108.785L114.384 20.9347H101.943Z"
          fill="#F7B80B"
        />
        <path
          d="M89.5022 20.9347L91.3685 7.17035H100.076L101.943 20.9347H89.5022Z"
          fill="white"
        />
        <path
          d="M101.943 20.9347V23.8285C101.943 27.264 99.1579 30.0487 95.7224 30.0487C92.287 30.0487 89.5022 27.264 89.5022 23.8285V20.9347H101.943Z"
          fill="#C5E8FF"
        />
        <path
          d="M77.0619 20.9347L82.66 7.17035H91.3687L89.5023 20.9347H77.0619Z"
          fill="#F7B80B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M89.5024 20.9347V23.8285C89.5024 27.264 86.7177 30.0487 83.2822 30.0487C79.8468 30.0487 77.062 27.264 77.062 23.8285V20.9347H89.5024Z"
          fill="#C18E0C"
        />
        <path
          d="M64.6206 20.9347L73.9513 7.17035H82.6599L77.0618 20.9347H64.6206Z"
          fill="white"
        />
        <path
          d="M77.0618 20.9347V23.8285C77.0618 27.264 74.277 30.0487 70.8416 30.0487C67.4061 30.0487 64.6213 27.264 64.6213 23.8285V20.9347H77.0618Z"
          fill="#C5E8FF"
        />
        <path
          d="M52.1803 20.9347L65.2436 7.17035H73.9514L64.6207 20.9347H52.1803Z"
          fill="#F7B80B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.6206 20.9347V23.8285C64.6206 27.264 61.8359 30.0487 58.4004 30.0487C54.9649 30.0487 52.1802 27.264 52.1802 23.8285V20.9347H64.6206Z"
          fill="#C18E0C"
        />
        <path
          d="M214.571 39.8081C214.571 39.8081 99.6502 55.2128 -23.1261 39.771V46.7123C-23.1261 46.7123 92.6593 62.442 214.571 46.7123V39.8081Z"
          fill="#FF6D3A"
        />
        <path
          d="M131.579 60.0403C131.247 60.2654 130.727 60.5641 130.344 60.6724C130.065 60.7514 130.074 61.0183 129.975 61.2651C129.819 61.6528 129.224 61.8555 129.257 62.2927C129.272 62.4815 129.399 62.6502 129.559 62.75C129.721 62.8498 129.913 62.8892 130.102 62.9024C130.716 62.9465 131.196 62.7763 131.587 62.3066C131.842 62.001 132.027 61.6714 132.313 61.3479C132.352 61.3038 132.422 61.235 132.45 61.1839L131.579 60.0411V60.0403Z"
          fill="#FFB8B8"
        />
        <path
          d="M126.612 47.7368L116.451 49.8438L114.437 53.3264L84.7558 56.9228C84.1306 56.9987 83.551 56.5824 83.4234 55.9657L117.694 47.9442L119.948 45.1471L126.613 47.7368H126.612Z"
          fill="#B8D1E6"
        />
        <path
          d="M126.077 56.2984L116.24 52.9922L114.656 49.2936L85.617 42.1759C85.005 42.0257 84.3806 42.3701 84.1794 42.9666L117.246 55.0256L119.149 58.0719L126.077 56.2976V56.2984Z"
          fill="#E1EFFA"
        />
        <path
          d="M112.239 50.9672C112.313 51.3247 112.083 51.6752 111.725 51.7495C111.367 51.8238 111.017 51.594 110.943 51.2357C110.868 50.8783 111.098 50.5277 111.456 50.4535C111.815 50.3792 112.164 50.609 112.239 50.9672Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M119.947 45.1471L119.973 45.1571C119.449 44.4321 119.066 43.5879 118.873 42.6556C118.139 39.1149 120.414 35.65 123.954 34.9157C124.152 34.8747 124.35 34.843 124.546 34.8205C127.873 34.4399 131.001 36.6536 131.694 39.997C132.429 43.5377 130.154 47.0025 126.613 47.7368L118.299 49.4607L117.694 47.9442L119.948 45.1471H119.947ZM130.324 40.2809C130.062 39.0151 129.339 37.9016 128.291 37.148C127.248 36.3982 125.974 36.0647 124.705 36.2102C124.549 36.228 124.392 36.2535 124.238 36.2853C121.458 36.8617 119.666 39.5923 120.242 42.3716C120.504 43.6375 121.227 44.7509 122.275 45.5045C123.318 46.2543 124.592 46.5878 125.861 46.4423C126.016 46.4245 126.174 46.399 126.328 46.3673C129.108 45.7908 130.9 43.0602 130.324 40.2809Z"
          fill="#D35431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M119.177 58.0665L119.149 58.0734L117.246 55.0272L117.819 53.523L126.076 56.2984C129.503 57.4506 131.348 61.1623 130.196 64.59C129.36 67.0768 127.176 68.7303 124.731 69.0104C123.806 69.1164 122.844 69.0259 121.904 68.7095C118.477 67.5573 116.632 63.8456 117.785 60.4179C118.088 59.5149 118.57 58.7226 119.177 58.0657L119.177 58.0665ZM119.381 64.795C119.993 66.0268 121.047 66.9461 122.351 67.384C123.075 67.627 123.822 67.7067 124.573 67.6208C126.55 67.3941 128.238 66.0299 128.872 64.1443C129.31 62.8405 129.214 61.4447 128.602 60.2129C127.99 58.981 126.936 58.0618 125.632 57.6239C124.909 57.3809 124.161 57.3012 123.41 57.3871C121.432 57.6138 119.745 58.9779 119.112 60.8636C118.674 62.1674 118.769 63.5632 119.381 64.795Z"
          fill="#E25D34"
        />
        <path
          d="M130.073 60.4744C129.972 60.4736 129.85 60.4844 129.805 60.5749C129.79 60.6067 129.788 60.6438 129.785 60.6794C129.781 60.7444 129.781 60.8164 129.824 60.8643C129.857 60.9015 129.91 60.9146 129.959 60.9247C130.183 60.968 130.306 60.8992 130.506 61.0415C130.704 61.1831 130.684 61.5305 130.752 61.5127C130.821 61.4949 130.982 60.9123 131.012 60.8481C131.032 60.804 131.033 60.7537 131.035 60.7057C131.042 60.4527 131.067 60.4666 130.856 60.4643C130.64 60.462 130.29 60.4767 130.074 60.4744H130.073Z"
          fill="#FFB8B8"
        />
        <path
          d="M128.742 61.6822C128.753 61.522 128.8 61.3502 128.927 61.2527C129.048 61.1607 129.23 61.1599 129.353 61.2512C129.727 61.5336 129.746 62.1967 129.353 62.4637C128.853 62.8018 128.718 62.0427 128.743 61.6822H128.742Z"
          fill="#FFB8B8"
        />
        <path
          d="M156.423 86.5622V24.6031"
          stroke="white"
          strokeWidth="0.571031"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M149.857 12.1704C149.857 16.3548 152.848 22.363 156.539 22.363C160.23 22.363 163.221 16.3548 163.221 12.1704C163.221 7.9859 160.23 5.24835 156.539 5.24835C152.848 5.24835 149.857 7.9859 149.857 12.1704Z"
          fill="#F7B80B"
        />
        <path
          d="M155.411 24.603H157.488C157.838 24.603 158.058 24.2231 157.882 23.9198L156.538 21.5908L155.029 23.8982C154.831 24.2015 155.049 24.603 155.411 24.603Z"
          fill="#F7B80B"
        />
        <path
          d="M156.313 6.25891C156.313 6.25891 151.522 6.78584 151.522 12.3205"
          stroke="white"
          strokeWidth="0.571031"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M156.423 86.5621L149.594 31.5854"
          stroke="white"
          strokeWidth="0.571031"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M141.449 20.1253C142 24.2734 145.756 29.8351 149.415 29.3492C153.073 28.8633 155.248 22.5139 154.697 18.3658C154.146 14.2177 150.821 11.898 147.162 12.3831C143.504 12.869 140.899 15.9764 141.45 20.1245L141.449 20.1253Z"
          fill="#F7B80B"
        />
        <path
          d="M148.592 31.7184L150.65 31.4453C150.997 31.3989 151.165 30.9942 150.951 30.7157L149.312 28.5832L148.12 31.0693C147.963 31.3958 148.232 31.7656 148.591 31.7177L148.592 31.7184Z"
          fill="#F7B80B"
        />
        <path
          d="M147.071 13.4153C147.071 13.4153 142.391 14.5682 143.119 20.0549"
          stroke="white"
          strokeWidth="0.571031"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M156.423 86.5621L162.37 41.1459"
          stroke="white"
          strokeWidth="0.571031"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M157.106 28.1081C156.679 32.2702 159.042 38.5523 162.714 38.9291C166.385 39.3059 169.974 33.6343 170.401 29.4715C170.828 25.3095 168.131 22.2802 164.46 21.9042C160.788 21.5274 157.533 23.9461 157.106 28.1081Z"
          fill="#F7B80B"
        />
        <path
          d="M161.364 41.043L163.43 41.255C163.778 41.2906 164.035 40.9354 163.892 40.6151L162.793 38.1607L161.056 40.3025C160.828 40.5841 161.004 41.0058 161.364 41.043Z"
          fill="#F7B80B"
        />
        <path
          d="M164.133 22.886C164.133 22.886 159.312 22.9216 158.748 28.4269"
          stroke="white"
          strokeWidth="0.571031"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M34.0361 86.5622V24.6031"
          stroke="white"
          strokeWidth="0.571031"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M27.469 12.1704C27.469 16.3548 30.4603 22.363 34.1511 22.363C37.8419 22.363 40.8333 16.3548 40.8333 12.1704C40.8333 7.9859 37.8419 5.24835 34.1511 5.24835C30.4603 5.24835 27.469 7.9859 27.469 12.1704Z"
          fill="#F7B80B"
        />
        <path
          d="M33.024 24.603H35.1007C35.4512 24.603 35.671 24.2231 35.4953 23.9198L34.1513 21.5908L32.6425 23.8982C32.4444 24.2015 32.6618 24.603 33.024 24.603Z"
          fill="#F7B80B"
        />
        <path
          d="M33.9263 6.25891C33.9263 6.25891 29.1352 6.78584 29.1352 12.3205"
          stroke="white"
          strokeWidth="0.571031"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M34.0361 86.5622L27.2077 34.316"
          stroke="white"
          strokeWidth="0.571031"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M19.0624 22.8559C19.6134 27.004 23.3699 32.5658 27.0282 32.0799C30.6866 31.5939 32.8616 25.2445 32.3107 21.0964C31.7598 16.9483 28.4342 14.6286 24.7758 15.1138C21.1175 15.5997 18.5123 18.7071 19.0632 22.8552L19.0624 22.8559Z"
          fill="#F7B80B"
        />
        <path
          d="M26.2048 34.4491L28.263 34.1759C28.6104 34.1295 28.7783 33.7248 28.564 33.4463L26.9252 31.3138L25.7328 33.7999C25.5765 34.1264 25.845 34.4963 26.204 34.4483L26.2048 34.4491Z"
          fill="#F7B80B"
        />
        <path
          d="M24.6843 16.1459C24.6843 16.1459 20.0039 17.2988 20.7328 22.7855"
          stroke="white"
          strokeWidth="0.571031"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M34.0358 86.5622L39.9829 39.3346"
          stroke="white"
          strokeWidth="0.571031"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M34.7189 26.2976C34.2918 30.4596 36.6549 36.7417 40.3263 37.1185C43.9978 37.4953 47.5865 31.8237 48.0136 27.6609C48.4407 23.4989 45.7434 20.4697 42.0727 20.0936C38.402 19.7176 35.1461 22.1356 34.7189 26.2976Z"
          fill="#F7B80B"
        />
        <path
          d="M38.9764 39.2317L41.0423 39.4437C41.3913 39.4793 41.6482 39.1241 41.505 38.8038L40.4055 36.3494L38.6692 38.4912C38.441 38.7728 38.6166 39.1945 38.9764 39.2317Z"
          fill="#F7B80B"
        />
        <path
          d="M41.7446 21.0747C41.7446 21.0747 36.9241 21.1103 36.3601 26.6156"
          stroke="white"
          strokeWidth="0.571031"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M60.416 84.3129H131.028V86.8284H60.416V84.3129Z"
          fill="#446B8C"
        />
        <path
          d="M97.8527 62.7445C98.1242 62.7445 98.344 62.5248 98.344 62.2532V56.5181C98.344 56.2465 98.1242 56.0268 97.8527 56.0268C97.5811 56.0268 97.3613 56.2465 97.3613 56.5181V62.2532C97.3613 62.5248 97.5811 62.7445 97.8527 62.7445Z"
          fill="#446B8C"
        />
        <path
          d="M93.5922 62.7445C93.8638 62.7445 94.0835 62.5247 94.0835 62.2531V56.5181C94.0835 56.2465 93.8638 56.0267 93.5922 56.0267C93.3206 56.0267 93.1008 56.2465 93.1008 56.5181V62.2531C93.1008 62.5247 93.3206 62.7445 93.5922 62.7445Z"
          fill="#446B8C"
        />
        <path
          d="M144.962 64.6897C143.494 69.0962 137.484 64.4227 137.484 64.4227L139.086 47.7305C141.49 45.8611 144.027 47.4636 144.027 47.4636L144.962 64.6904V64.6897Z"
          fill="#F7B80B"
        />
        <path
          d="M144.647 79.4792L146.694 79.5009L146.183 85.4031H144.647V79.4792Z"
          fill="#FFB8B8"
        />
        <path
          d="M148.599 85.5107C148.249 85.1609 147.775 84.9644 147.281 84.9644H144.319V86.8291H149.145C149.145 86.3339 148.949 85.8604 148.599 85.5099V85.5107Z"
          fill="#2F2E41"
        />
        <path
          d="M139.096 79.4792H136.829L137.559 85.4031H139.095V79.4792H139.096Z"
          fill="#FFB8B8"
        />
        <path
          d="M135.144 85.5107C134.794 85.8604 134.598 86.3347 134.598 86.8291H139.425V84.9644H136.463C135.968 84.9644 135.494 85.1609 135.144 85.5107Z"
          fill="#2F2E41"
        />
        <path
          d="M136.216 63.6877L136.883 84.2525L139.288 84.1187L141.825 68.3612L144.562 84.3191L146.632 84.3856L147.968 64.4878C146.365 61.4159 136.216 63.6861 136.216 63.6861V63.6877Z"
          fill="#2F2E41"
        />
        <path
          d="M138.589 42.6965C138.589 40.9973 139.966 39.6193 141.666 39.6193C143.365 39.6193 144.743 40.9966 144.743 42.6965C144.743 44.3957 143.366 45.7737 141.666 45.7737C139.966 45.7737 138.589 44.3964 138.589 42.6965Z"
          fill="#FFB8B8"
        />
        <path
          d="M142.959 48.5314C140.422 50.8016 143.093 63.0873 143.493 64.6897C143.894 66.2921 144.562 66.2921 145.629 65.2236C146.698 64.155 147.232 64.0219 147.232 64.0219C147.232 64.0219 147.366 65.0905 148.167 65.0905C148.968 65.0905 148.233 51.4028 147.966 49.3995C147.699 47.3963 144.361 47.3963 144.361 47.3963L143.559 46.8624L142.958 48.5314H142.959Z"
          fill="#2F2E41"
        />
        <path
          d="M140.288 48.5314C142.825 50.8016 140.154 63.0873 139.754 64.6897C139.353 66.2921 138.685 66.2921 137.618 65.2236C136.549 64.155 136.015 64.0219 136.015 64.0219C136.015 64.0219 135.881 65.0905 135.08 65.0905C134.279 65.0905 135.014 51.4028 135.281 49.3995C135.548 47.3963 138.887 47.3963 138.887 47.3963L139.688 46.8624L140.289 48.5314H140.288Z"
          fill="#2F2E41"
        />
        <path
          d="M144.206 44.4545C144.726 44.08 145.12 43.5562 145.336 42.9518C145.551 42.3483 145.578 41.6929 145.412 41.0732C145.246 40.4542 144.895 39.8994 144.407 39.4847C143.919 39.0692 143.315 38.813 142.677 38.7488C142.394 38.7202 142.089 38.7248 141.854 38.5639C141.645 38.4207 141.532 38.1731 141.341 38.0083C141.219 37.9139 141.079 37.8458 140.93 37.8087C140.78 37.7708 140.625 37.7646 140.473 37.7901C140.17 37.8427 139.876 37.9387 139.601 38.0764C138.919 38.3805 138.194 38.755 137.883 39.4344C137.614 40.0232 137.733 40.7482 138.114 41.272C138.514 41.7897 139.098 42.1348 139.744 42.2346C140.387 42.3305 141.043 42.2717 141.659 42.062C142.066 41.9344 142.466 41.7603 142.892 41.7456C143.224 41.7347 143.552 41.8222 143.834 41.9978C144.115 42.1735 144.339 42.4296 144.475 42.7321C144.611 43.0354 144.655 43.372 144.599 43.6993C144.543 44.0266 144.391 44.3307 144.162 44.5713L144.207 44.4529L144.206 44.4545Z"
          fill="#2F2E41"
        />
        <path
          d="M147.472 66.2937C147.804 66.3037 148.127 66.1923 148.381 65.9795C148.635 65.7675 148.802 65.4688 148.851 65.1415C148.9 64.8142 148.827 64.48 148.646 64.2022L148.577 60.6839L146.73 60.3334L146.693 63.8649C146.536 63.9832 146.406 64.1341 146.314 64.3074C146.221 64.4808 146.168 64.6726 146.158 64.8692C146.147 65.0657 146.18 65.2623 146.254 65.4441C146.327 65.6267 146.44 65.7907 146.584 65.9246C146.825 66.1521 147.141 66.2836 147.472 66.2937H147.472Z"
          fill="#FFB8B8"
        />
        <path
          d="M133.275 60.9246C132.875 61.8593 130.271 60.5911 130.271 60.5911C130.283 55.4487 135.599 48.6134 135.599 48.6134L135.748 49.1991L136.274 57.5851C133.97 60.0131 133.432 60.5571 133.275 60.9246Z"
          fill="#2F2E41"
        />
        <path
          d="M146.097 62.4861C146.498 63.4207 149.236 62.1526 149.236 62.1526C149.701 57.0775 147.98 49.504 147.98 49.504L147.499 49.1999C147.499 49.1999 145.697 61.5521 146.097 62.4868V62.4861Z"
          fill="#2F2E41"
        />
        <path
          d="M80.2721 51.3905C80.9553 51.0485 81.3337 50.4194 81.1155 49.9838C80.8981 49.5489 80.1676 49.4731 79.4836 49.8151C79.2089 49.9482 78.9691 50.1447 78.7857 50.3892L75.9081 51.8733L76.6331 53.2088L79.3931 51.604C79.6987 51.604 79.9997 51.5305 80.2713 51.3905H80.2721Z"
          fill="#FFB6B6"
        />
        <path
          d="M63.9667 85.9192H65.3363L65.9878 80.6353H63.966V85.9192H63.9667Z"
          fill="#FFB6B6"
        />
        <path
          d="M68.6594 85.8882C68.7019 85.9602 68.7244 86.1916 68.7244 86.2751C68.7244 86.532 68.5162 86.7401 68.2593 86.7401H64.0169C63.842 86.7401 63.6996 86.5978 63.6996 86.4229V86.2465C63.6996 86.2465 63.4899 85.7157 63.9217 85.0611C63.9217 85.0611 64.4587 85.5733 65.261 84.7709L65.4978 84.3423L67.2109 85.595L68.1603 85.7118C68.3677 85.7374 68.5518 85.708 68.6594 85.8875V85.8882Z"
          fill="#1A202C"
        />
        <path
          d="M66.1178 80.1091L67.3342 80.7389L70.3425 76.3463L68.5466 75.4171L66.1178 80.1091Z"
          fill="#FFB6B6"
        />
        <path
          d="M70.2996 82.2392C70.3043 82.3228 70.2176 82.5387 70.1797 82.613C70.0613 82.8412 69.7812 82.9302 69.5529 82.8118L65.7855 80.8612C65.63 80.7807 65.5689 80.5896 65.6494 80.4333L65.7306 80.2762C65.7306 80.2762 65.7886 79.7083 66.4726 79.326C66.4726 79.326 66.714 80.0278 67.7957 79.6843L68.2027 79.4127L69.1483 81.313L69.9375 81.8531C70.1101 81.9715 70.2872 82.0295 70.2996 82.2385V82.2392Z"
          fill="#1A202C"
        />
        <path
          d="M62.5755 43.9786H66.168L66.0899 45.1501L68.4328 52.6176L68.8723 57.5959L64.4797 58.3279L62.43 49.1032L62.8695 44.8569L62.577 43.9786H62.5755Z"
          fill="#F7B80B"
        />
        <path
          d="M62.7953 44.9304L58.9884 46.8338L61.3313 54.7408L60.8918 62.794C60.8918 62.794 66.8954 64.6975 66.6021 59.2796C66.3097 53.8618 66.3097 52.9836 66.3097 52.9836L62.7953 44.9304Z"
          fill="#1A202C"
        />
        <path
          d="M68.8717 45.9169C68.8717 45.9169 69.8234 45.2236 70.2629 46.2481C70.7024 47.2733 73.5575 52.4706 73.5575 52.4706L78.0236 50.4937L78.7556 52.1046C78.7556 52.1046 75.095 55.619 72.8991 55.1795C70.7024 54.74 68.946 50.9332 68.946 50.9332L68.8724 45.9161L68.8717 45.9169Z"
          fill="#1A202C"
        />
        <path
          d="M61.3305 61.3301C61.3305 61.3301 61.1843 63.673 61.4768 65.5764C61.7692 67.4799 63.7462 83.6598 63.7462 83.6598L66.2354 83.5136L66.4551 64.552L69.9695 70.116L66.8946 78.4618L69.0178 79.4135L73.4839 70.2623L70.409 59.8661L69.5308 57.2307L62.9415 57.9627L61.3305 61.3301Z"
          fill="#1A202C"
        />
        <path
          d="M71.1409 61.9158C71.1409 61.9158 67.1878 63.0872 66.3088 52.9843L66.1099 44.8383L69.8232 45.8101L69.3837 53.1314L71.1409 61.9166V61.9158Z"
          fill="#1A202C"
        />
        <path
          d="M64.4056 43.0269C65.8611 43.0269 67.041 41.8469 67.041 40.3915C67.041 38.9361 65.8611 37.7561 64.4056 37.7561C62.9502 37.7561 61.7702 38.9361 61.7702 40.3915C61.7702 41.8469 62.9502 43.0269 64.4056 43.0269Z"
          fill="#FFB6B6"
        />
        <path
          d="M62.7219 42.9542C62.7219 42.9542 61.5504 41.6365 61.4042 41.0507C61.258 40.465 61.258 38.8541 61.4042 38.7078C61.5504 38.5616 63.1614 37.0969 63.7471 37.2439C64.3329 37.3901 65.4935 38.1221 66.5288 38.1221C66.8213 38.1221 66.9683 39.586 66.9683 39.586L65.7968 39.7323C65.7968 39.7323 64.3329 39.0003 64.4791 39.7323C64.6253 40.4642 63.4539 40.4642 63.4539 40.4642L63.6001 41.05L63.1606 41.1962L62.8681 40.6105C62.8681 40.6105 63.0144 41.9282 63.4539 42.5139C63.8934 43.0996 62.7219 42.9534 62.7219 42.9534V42.9542Z"
          fill="#1A202C"
        />
        <path
          d="M61.5549 64.0359C61.9565 64.6859 62.618 65.0054 63.0312 64.7493C63.4452 64.4932 63.4545 63.7597 63.0521 63.1089C62.8951 62.8466 62.6776 62.6261 62.4176 62.466L60.6806 59.7331L59.4155 60.5757L61.2624 63.1801C61.2903 63.4842 61.3901 63.7775 61.5541 64.0351L61.5549 64.0359Z"
          fill="#FFB6B6"
        />
        <path
          d="M59.5734 46.8345H58.8414C58.8414 46.8345 55.9127 53.4238 56.059 54.7415C56.059 54.7415 56.059 55.913 56.791 56.7912C57.5229 57.6694 60.5978 62.6478 60.5978 62.6478L61.9155 61.6225L59.2801 55.4727L60.8911 49.0305L59.5734 46.8338V46.8345Z"
          fill="#1A202C"
        />
        <path
          d="M43.3651 46.5274C43.7218 46.7278 44.2967 45.9355 44.4266 46.3231C44.5566 46.7108 44.2409 48.2737 44.0785 48.6482C43.9972 48.8347 43.8835 49.0049 43.8038 49.1922C43.7241 49.3794 43.6815 49.5945 43.7519 49.7849C43.8742 50.1168 44.2773 50.2515 44.4769 50.5432C44.7098 50.8828 44.6154 51.3401 44.5071 51.7371C44.4135 52.0791 44.3137 52.4249 44.1326 52.7306C43.9523 53.0362 43.68 53.3024 43.3395 53.403C42.9062 53.5314 42.4435 53.379 42.0257 53.2056C41.6241 53.0393 41.2272 52.8513 40.8859 52.5828C40.5447 52.3143 40.2592 51.9576 40.14 51.5398C39.9907 51.0159 40.1153 50.4426 40.3745 49.9628C40.6337 49.4831 41.0175 49.0839 41.4105 48.707C42.0729 48.0718 42.7785 47.4814 43.5198 46.9406"
          fill="#2F2E41"
        />
        <path
          d="M38.0656 73.4146L38.8084 80.3474C38.8084 80.3474 37.0752 83.9376 38.9322 88.2706L39.056 90.7466L41.1606 90.499L40.6654 88.3944L41.7796 79.8522L42.6462 73.6621L38.0656 73.4146Z"
          fill="#FFB6B6"
        />
        <path
          d="M44.0081 73.2908C44.0081 73.2908 43.5129 78.738 44.5033 81.5854C44.5033 81.5854 44.0081 85.9184 45.3699 90.499V93.909L47.5983 93.4695L47.4745 80.718L48.0935 73.7852L44.0081 73.29V73.2908Z"
          fill="#FFB6B6"
        />
        <path
          d="M44.3247 52.0891C43.6971 52.8799 42.5907 53.1089 41.6978 52.6385C40.4234 51.9669 38.6832 51.197 38.3752 51.8121C37.88 52.8025 37.8801 53.7635 37.8801 53.7635L37.3848 65.3064C37.3848 65.3064 37.1372 69.9667 40.2323 69.5558C43.3273 69.1442 48.1555 70.8774 48.1555 70.8774L47.6626 65.7637L46.3457 63.8355L45.8126 58.3039L46.7326 53.854L45.6184 53.3588C45.6184 53.3588 45.4946 52.4922 45.1232 52.4922C44.7518 52.4922 44.662 51.6597 44.3355 52.076L44.3254 52.0883L44.3247 52.0891Z"
          fill="#F7B80B"
        />
        <path
          d="M37.8181 65.1199L37.5705 78.9276C37.5705 78.9276 39.799 82.394 42.1512 79.5466L43.513 72.671L43.7606 80.6608C43.7606 80.6608 47.1032 82.394 48.0936 81.0322L48.1555 70.8759L45.8652 66.2333L37.8181 65.1191V65.1199Z"
          fill="#2F2E41"
        />
        <path
          d="M38.9835 89.2874C38.9835 89.2874 38.1896 89.8793 38.4372 90.6221C38.6848 91.3649 40.5418 94.2123 42.1512 93.8409C43.7606 93.4695 43.0178 92.6029 43.0178 92.6029L41.1608 89.8793C41.1608 89.8793 40.418 90.0031 40.0466 90.2507C39.6752 90.4983 38.9835 89.2874 38.9835 89.2874Z"
          fill="#2F2E41"
        />
        <path
          d="M45.37 92.8505C45.37 92.8505 44.0081 94.2123 44.7509 94.8313C45.4938 95.4503 50.5696 95.9455 50.4458 95.2027C50.322 94.4599 47.7222 92.3553 47.7222 92.3553C47.7222 92.3553 46.9793 92.1077 46.7317 92.7267C46.4841 93.3457 45.37 92.8505 45.37 92.8505Z"
          fill="#2F2E41"
        />
        <path
          d="M42.4752 51.7216C41.0476 51.7216 39.8901 50.5641 39.8901 49.1365C39.8901 47.7089 41.0476 46.5514 42.4752 46.5514C43.9028 46.5514 45.0603 47.7089 45.0603 49.1365C45.0603 50.5641 43.9028 51.7216 42.4752 51.7216Z"
          fill="#FFB6B6"
        />
        <path
          d="M39.5606 53.1855C40.1641 54.0467 40.4728 55.1099 40.4233 56.1598C41.1073 55.8287 41.5692 55.1284 41.7263 54.3841C41.8834 53.6405 41.7634 52.859 41.5112 52.1425C41.5089 52.1356 41.5066 52.1278 41.5035 52.1209C41.1197 51.0446 41.4764 49.8414 42.4026 49.1728C43.027 48.7217 43.5694 48.1554 43.9849 47.5062C44.2193 47.1394 44.419 46.6798 44.2441 46.2821C44.0824 45.9153 43.6653 45.7436 43.2808 45.6291C42.4033 45.3668 41.4717 45.2182 40.5711 45.3838C39.6704 45.5494 38.8038 46.0678 38.3783 46.8779C37.9527 47.6888 38.0641 48.7953 38.7551 49.3957C38.5377 49.4661 38.3829 49.6967 38.4015 49.9249C38.4201 50.1532 38.6096 50.3559 38.8355 50.3907C38.6878 50.3853 38.6003 50.5803 38.653 50.718C38.7056 50.8558 38.8417 50.9417 38.971 51.0128C39.1551 51.1142 39.3602 51.2202 39.3609 51.4848C39.3609 51.5908 39.3238 51.6945 39.2619 51.7804C39.1118 51.9901 38.8696 52.0837 38.6359 52.1727C39.1296 52.3182 39.5226 52.7592 39.6109 53.2652"
          fill="#2F2E41"
        />
        <path
          d="M40.318 67.729C40.886 68.1507 41.5646 68.1995 41.833 67.8381C42.1015 67.4768 41.8586 66.8415 41.2899 66.4198C41.0655 66.2481 40.8024 66.1328 40.5238 66.0832L38.0943 64.326L37.2942 65.4836L39.7741 67.093C39.9017 67.3452 40.0882 67.5634 40.318 67.729Z"
          fill="#FFB6B6"
        />
        <path
          d="M38.7875 53.4207C38.7875 53.4207 36.8275 53.4826 36.4561 54.8444C36.0847 56.2062 35.342 59.1086 35.4658 63.1391C35.4658 63.1391 34.8467 65.7389 36.4561 65.6151L38.6846 66.6055L40.1702 64.6247L38.9322 63.1391L38.7875 53.4207Z"
          fill="#F7B80B"
        />
        <path
          d="M50.5856 52.1851C50.656 51.4446 50.3311 50.8085 49.8606 50.7637C49.3894 50.7188 48.9507 51.2829 48.8803 52.0233C48.8478 52.3189 48.8872 52.6176 48.994 52.8946L48.7348 56.0345L50.2111 56.125L50.3102 53.0199C50.4672 52.7677 50.5624 52.4814 50.5864 52.1851H50.5856Z"
          fill="#FFB6B6"
        />
        <path
          d="M42.5425 53.6746C42.5425 53.6746 44.9543 52.2563 45.4511 52.469C45.9478 52.6818 48.3596 56.2991 48.3596 56.2991L48.5817 55.6391L50.2754 55.8032C50.2754 55.8032 51.2682 60.414 49.0699 59.9884C46.8709 59.5628 43.2536 55.5904 43.2536 55.5904L42.5441 53.6753L42.5425 53.6746Z"
          fill="#F7B80B"
        />
        <path
          d="M54.0639 54.193L50.7298 53.4177L50.4907 54.1102L48.8774 53.6745L47.8135 53.3867L46.7805 51.3796L47.5133 49.2549L49.696 49.2347L50.3638 49.3663L52.2262 49.7323C52.384 49.993 52.2974 50.3327 52.0351 50.4867L51.9492 50.537L54.5691 50.9904C54.5691 50.9904 55.4063 52.4799 54.0646 54.193H54.0639Z"
          fill="#3F3D56"
        />
        <path
          d="M50.3645 49.3655L48.8781 53.6745L47.8142 53.3867L46.7813 51.3796L47.514 49.2549L49.6968 49.2347L50.3645 49.3663V49.3655Z"
          fill="#2F2E41"
        />
        <path
          d="M50.9285 49.545C50.6987 49.4939 50.5339 49.3601 50.5586 49.2456C50.5842 49.131 50.7908 49.0792 51.0198 49.1295C51.2496 49.1806 51.4144 49.3144 51.3896 49.4289C51.3641 49.5435 51.1575 49.5953 50.9285 49.545Z"
          fill="#3F3D56"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1318_28766">
        <rect
          width="190"
          height="100"
          fill="white"
          transform="matrix(-1 0 0 1 190 0)"
        />
      </clipPath>
      <clipPath id="clip1_1318_28766">
        <rect
          width="352.975"
          height="111.127"
          fill="white"
          transform="matrix(-1 0 0 1 255.167 -11.1111)"
        />
      </clipPath>
    </defs>
  </svg>
);
