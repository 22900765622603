export const InstitutionnelIcon = () => (
  <svg width="74" height="101" viewBox="0 0 74 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_905_18657)">
      <path d="M9.78067 25.3157C15.3054 25.3157 19.784 20.8769 19.784 15.4015C19.784 9.92604 15.3054 5.4873 9.78067 5.4873C4.25599 5.4873 -0.222656 9.92604 -0.222656 15.4015C-0.222656 20.8769 4.25599 25.3157 9.78067 25.3157Z" fill="#F7B80B"/>
      <path d="M68.1939 18.2919H56.1948V7.48096L68.1939 18.2919Z" fill="#3F3D56"/>
      <path d="M69.5958 17.938H9.23291V100.59H69.5958V17.938Z" fill="#E6E6E6"/>
      <path d="M22.3569 23.7334H15.2554V38.564H22.3569V23.7334Z" fill="white"/>
      <path d="M33.3413 23.7334H26.2397V38.564H33.3413V23.7334Z" fill="white"/>
      <path d="M21.4209 44.7534H14.3193V59.584H21.4209V44.7534Z" fill="white"/>
      <path d="M33.3413 44.7534H26.2397V59.584H33.3413V44.7534Z" fill="white"/>
      <path d="M21.4209 64.2031H14.3193V79.0337H21.4209V64.2031Z" fill="white"/>
      <path d="M33.0742 63.938H25.9727V78.7686H33.0742V63.938Z" fill="white"/>
      <path d="M43.3955 85.7925H36.2939V100.623H43.3955V85.7925Z" fill="#CCCCCC"/>
      <path d="M54.125 63.938H47.0234V78.7686H54.125V63.938Z" fill="white"/>
      <path d="M66.0454 63.938H58.9438V78.7686H66.0454V63.938Z" fill="white"/>
      <path d="M54.3925 44.5967H47.291V59.4273H54.3925V44.5967Z" fill="white"/>
      <path d="M66.3125 44.5967H59.2109V59.4273H66.3125V44.5967Z" fill="white"/>
      <path d="M54.3925 23.7334H47.291V38.564H54.3925V23.7334Z" fill="white"/>
      <path d="M66.3125 23.7334H59.2109V38.564H66.3125V23.7334Z" fill="white"/>
      <path d="M69.5958 37.042H9.23291V39.3043H69.5958V37.042Z" fill="#3F3D56"/>
      <path d="M69.5958 54.6377H9.23291V55.8945H69.5958V54.6377Z" fill="#3F3D56"/>
      <path d="M69.5958 50.6157H9.23291V51.8726H69.5958V50.6157Z" fill="#3F3D56"/>
      <path d="M15.1934 80.0253V69.9707H13.9252V80.0253H15.1934Z" fill="#3F3D56"/>
      <path d="M22.2949 80.0253V69.9707H21.0268V80.0253H22.2949Z" fill="#3F3D56"/>
      <path d="M29.3965 80.0253V69.9707H28.1284V80.0253H29.3965Z" fill="#3F3D56"/>
      <path d="M36.498 80.0253V69.9707H35.2299V80.0253H36.498Z" fill="#3F3D56"/>
      <path d="M43.5996 80.0253V69.9707H42.3315V80.0253H43.5996Z" fill="#3F3D56"/>
      <path d="M50.7012 80.0253V69.9707H49.433V80.0253H50.7012Z" fill="#3F3D56"/>
      <path d="M57.8022 80.0253V69.9707H56.5341V80.0253H57.8022Z" fill="#3F3D56"/>
      <path d="M64.9038 80.0253V69.9707H63.6357V80.0253H64.9038Z" fill="#3F3D56"/>
      <path d="M15.3203 60.9218V50.8672H14.0522V60.9218H15.3203Z" fill="#3F3D56"/>
      <path d="M22.4219 60.9218V50.8672H21.1537V60.9218H22.4219Z" fill="#3F3D56"/>
      <path d="M29.5234 60.9218V50.8672H28.2553V60.9218H29.5234Z" fill="#3F3D56"/>
      <path d="M36.625 60.9218V50.8672H35.3569V60.9218H36.625Z" fill="#3F3D56"/>
      <path d="M43.7261 60.9218V50.8672H42.4579V60.9218H43.7261Z" fill="#3F3D56"/>
      <path d="M50.8276 60.9218V50.8672H49.5595V60.9218H50.8276Z" fill="#3F3D56"/>
      <path d="M57.9292 60.9218V50.8672H56.6611V60.9218H57.9292Z" fill="#3F3D56"/>
      <path d="M65.0308 60.9218V50.8672H63.7626V60.9218H65.0308Z" fill="#3F3D56"/>
      <path d="M15.3203 38.5502V28.4956H14.0522V38.5502H15.3203Z" fill="#3F3D56"/>
      <path d="M22.4219 38.5502V28.4956H21.1537V38.5502H22.4219Z" fill="#3F3D56"/>
      <path d="M29.5234 38.5502V28.4956H28.2553V38.5502H29.5234Z" fill="#3F3D56"/>
      <path d="M36.625 38.5502V28.4956H35.3569V38.5502H36.625Z" fill="#3F3D56"/>
      <path d="M43.7261 38.5502V28.4956H42.4579V38.5502H43.7261Z" fill="#3F3D56"/>
      <path d="M50.8276 38.5502V28.4956H49.5595V38.5502H50.8276Z" fill="#3F3D56"/>
      <path d="M57.9292 38.5502V28.4956H56.6611V38.5502H57.9292Z" fill="#3F3D56"/>
      <path d="M65.0308 38.5502V28.4956H63.7626V38.5502H65.0308Z" fill="#3F3D56"/>
      <path d="M69.5958 32.0146H9.23291V33.2715H69.5958V32.0146Z" fill="#3F3D56"/>
      <path d="M69.5958 27.9927H9.23291V29.2495H69.5958V27.9927Z" fill="#3F3D56"/>
      <path d="M69.5958 58.9106H9.23291V61.1729H69.5958V58.9106Z" fill="#3F3D56"/>
      <path d="M72.3739 17.0083H6.45557V20.3795H72.3739V17.0083Z" fill="#3F3D56"/>
      <path d="M22.3702 88.9994V85.7925H18.4873V88.9994H22.0466H22.3702Z" fill="white"/>
      <path d="M22.0466 91.5649H18.4873V94.7719H22.3702V91.5649H22.0466Z" fill="white"/>
      <path d="M28.8418 85.7925H24.959V88.9994H28.8418V85.7925Z" fill="white"/>
      <path d="M28.8418 91.5649H24.959V94.7718H28.8418V91.5649Z" fill="white"/>
      <path d="M54.0392 88.9994V85.7925H50.1562V88.9994H53.7155H54.0392Z" fill="white"/>
      <path d="M53.7155 91.5649H50.1562V94.7719H54.0392V91.5649H53.7155Z" fill="white"/>
      <path d="M60.5108 85.7925H56.6279V88.9994H60.5108V85.7925Z" fill="white"/>
      <path d="M60.5108 91.5649H56.6279V94.7718H60.5108V91.5649Z" fill="white"/>
      <path d="M69.5958 78.0146H9.23291V80.2769H69.5958V78.0146Z" fill="#3F3D56"/>
      <path d="M69.5958 73.9927H9.23291V75.2495H69.5958V73.9927Z" fill="#3F3D56"/>
      <path d="M69.5958 69.9707H9.23291V71.2275H69.5958V69.9707Z" fill="#3F3D56"/>
      <path d="M27.5076 99.5127C27.5078 99.4427 27.5068 99.3731 27.5048 99.3038C27.4505 97.4508 25.5959 96.9483 24.6016 97.8255C24.2878 97.5554 23.8863 97.4067 23.4707 97.4067C23.0552 97.4067 22.6536 97.5554 22.3398 97.8255C21.3413 96.9621 19.5236 97.4289 19.4636 99.2608C19.4613 99.3301 19.4601 99.3997 19.46 99.4696C19.4629 99.8549 19.4867 100.24 19.5313 100.622H27.4373C27.4802 100.254 27.5037 99.8836 27.5076 99.5127Z" fill="#F6C443"/>
      <path d="M58.929 99.5127C58.9292 99.4427 58.9282 99.3731 58.9262 99.3038C58.8719 97.4508 57.0173 96.9483 56.023 97.8255C55.7092 97.5554 55.3077 97.4067 54.8921 97.4067C54.4766 97.4067 54.075 97.5554 53.7612 97.8255C52.7627 96.9621 50.945 97.4289 50.885 99.2608C50.8827 99.3301 50.8815 99.3997 50.8813 99.4696C50.8843 99.8549 50.9081 100.24 50.9527 100.622H58.8587C58.9016 100.254 58.9251 99.8836 58.929 99.5127Z" fill="#F6C443"/>
      <path d="M34.3084 0.0401719L34.2908 0.0496912C34.1004 0.152917 33.8869 0.207377 33.6699 0.208132C33.4528 0.208887 33.239 0.155911 33.0478 0.0540117C33.0368 0.0481221 33.0258 0.0423065 33.0149 0.036565C32.8238 -0.0645607 32.6103 -0.117106 32.3936 -0.116364C32.1769 -0.115623 31.9638 -0.0616176 31.7734 0.040814L31.7569 0.0496912C31.5665 0.152917 31.353 0.207377 31.136 0.208132C30.9189 0.208887 30.7051 0.155911 30.5139 0.0540117C30.5025 0.0478975 30.4911 0.0418628 30.4798 0.0359068C30.2909 -0.0647554 30.0798 -0.117623 29.8654 -0.117999C29.6509 -0.118375 29.4396 -0.0662479 29.2504 0.0337511C28.6722 0.342332 27.8906 0.869931 27.8941 1.46577C27.8942 1.48933 27.8955 1.51287 27.8981 1.5363C27.9653 2.15508 30.0017 2.31275 31.1204 2.02113C31.9543 2.20971 32.8204 2.20971 33.6543 2.02113C34.7682 2.31741 36.846 2.14767 36.9067 1.52179C36.909 1.49834 36.9101 1.47479 36.91 1.45123C36.9065 0.855069 36.118 0.332999 35.5362 0.0287621C35.3465 -0.0696484 35.1351 -0.120098 34.9209 -0.118107C34.7067 -0.116117 34.4963 -0.0617486 34.3084 0.0401719Z" fill="#E6E6E6"/>
      <g clipPath="url(#clip1_905_18657)">
        <path d="M50.6335 86.4409L43.7873 128.539L39.2153 128.311L39.3287 89.6566L50.6335 86.4409Z" fill="#2F2E41"/>
        <path d="M32.1767 73.5786L48.7879 73.8083L50.6336 86.4407L39.3288 91.7233L34.4148 127.401L30.0715 127.173L28.4854 82.9955L32.1767 73.5786Z" fill="#2F2E41"/>
        <path d="M42.0971 40.9643C44.9003 40.9643 47.1727 38.702 47.1727 35.9114C47.1727 33.1207 44.9003 30.8584 42.0971 30.8584C39.2939 30.8584 37.0215 33.1207 37.0215 35.9114C37.0215 38.702 39.2939 40.9643 42.0971 40.9643Z" fill="#FFB9B9"/>
        <path d="M44.4728 43.8805L47.4034 47.6248L46.0191 76.3346L35.1758 76.105L33.3301 51.0701L35.6371 44.639L36.4719 43.1978L44.4728 43.8805Z" fill="#E4E4E4"/>
        <path d="M49.48 58.4201L52.0178 68.0666L49.48 77.7132L53.6328 83.2255L57.3241 65.7698C57.3241 65.7698 54.5556 51.5297 54.3249 49.9219C54.0942 48.3141 53.6328 48.0845 53.6328 48.0845L51.7871 48.3142L49.48 58.4201Z" fill="#2F2E41"/>
        <path d="M31.0231 62.095L29.4081 65.081L28.9465 70.1339L29.8694 75.8758L26.6395 81.1586L23.4097 66.6886L26.8702 48.0845H28.2546L31.0231 62.095Z" fill="#2F2E41"/>
        <path d="M37.7078 37.2199L37.5876 39.2626C37.5876 39.2626 36.0988 35.9111 35.8681 32.4659C35.6374 29.0207 39.5595 28.3317 41.8666 28.102C44.1737 27.8723 48.0958 29.4801 48.0958 31.3175C48.0958 33.155 43.943 32.6956 42.328 32.2363C40.7131 31.7769 40.0209 35.6815 40.0209 35.6815L39.0981 35.9111L37.7078 37.2199Z" fill="#2F2E41"/>
        <path d="M50.1721 61.6352L49.2493 70.3631L51.0949 75.6457L52.2485 80.6987C52.2485 80.6987 52.2485 85.5219 47.6343 85.7516C43.0201 85.9813 29.6389 85.5219 29.6389 85.5219C29.6389 85.5219 27.7932 85.2923 26.1782 81.6174C24.5632 77.9425 29.4082 74.4973 29.4082 74.4973L30.7924 69.674L30.331 63.7023L27.101 48.0841L36.6755 43.146L39.098 66.6882L44.289 43.835L46.0193 45.0983L53.402 48.0841L50.1721 61.6352Z" fill="#2F2E41"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_905_18657">
        <rect width="73.7226" height="100" fill="white" transform="translate(0.138672 0.71875)"/>
      </clipPath>
      <clipPath id="clip1_905_18657">
        <rect width="58.9781" height="111.285" fill="white" transform="translate(14.5181 24.9277)"/>
      </clipPath>
    </defs>
  </svg>

);