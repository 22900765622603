import React, { createContext, useContext, useEffect, useState } from "react";
import { Icons } from "../components/PicturesVariant/PicturesVariant";
import { OrderOptionType } from "../utils/parseUserData";
import { useSegment } from "./SegmentContext";

export const ROUND_NUMBERS = [
  0, 10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 400, 500, 600, 700, 800, 900,
  1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800,
];

export enum BackendOptionType {
  Portant = "portant",
  TicketsVestiaires = "tickets_vestiaires",
  TabletteNumérique = "tablette_numerique",
  TalkyWalky = "talky_walky",
  Panières = "paniere",
  Cabas = "cabas",
  PanneauDirectionnel = "panneau_directionnel",
  Formation = "formation",
  TotbagR = "totbag_print_r",
  TotbagRV = "totbag_print_rv",
  Besace = "besace_no_print",
  BesacePrint = "besace_print",
  Ballonbag = "ballonbag_print_rv",
  Oriflamme = "oriflammebag_print_rv",
  // TourDeCou = "tour_de_cou",
  // Badge = "badge",
}

export enum OptionType {
  Portant = "Portant",
  TicketsVestiaires = "Tickets vestiaires",
  TabletteNumérique = "Tablette numérique",
  TalkyWalky = "Talky walky",
  Panières = "Panières",
  Cabas = "Cabas",
  PanneauDirectionnel = "Panneau directionnel",
  Formation = "Formation",
  TotbagR = "Totbag imprimé recto",
  TotbagRV = "Totbag imprimé recto-verso",
  Besace = "Besace",
  BesacePrint = "Besace imprimée",
  Ballonbag = "Ballonbag",
  Oriflamme = "Oriflamme",
  // TourDeCou = "Tour de cou",
  // Badge = "Badge",
}

export const OPTIONS_LIST: {
  [key in OptionType]: OptionDetails;
} = {
  [OptionType.Portant]: {
    name: "Portant",
    icon: Icons.OPTIONS_PORTANT,
    id: OrderOptionType.portant,
    tooltipText: "Permets la création d'un vestiaire effémère",
  },
  [OptionType.TicketsVestiaires]: {
    name: "50 Tickets vestiaires",
    icon: Icons.OPTIONS_TICKET,
    id: OrderOptionType.ticketsVestiaires,
    tooltipText: "Indispensable pour la gestion de votre vestiaire",
  },
  [OptionType.TabletteNumérique]: {
    name: "Tablette numérique",
    icon: Icons.OPTIONS_TABLETTE_NUMERIQUE,
    id: OrderOptionType.tabletteNumerique,
    tooltipText:
      "Dédiée a l'emmargement digital de votre événement. Augmente la vitesse d'emargement, donne de la visibilité en temps réel et augmente la qualité service",
    // description: "papapa les tablettes",
    file: "Tablette_Emargement.pdf",
    fileName: "Norela-Tablette-Emargement-Présentation.pdf",
  },
  [OptionType.TalkyWalky]: {
    name: "Talky walky",
    icon: Icons.TALKY_WALKY,
    id: OrderOptionType.talkyWalky,
    tooltipText: "Location par set de 2 talky walky uniquement",
    incrementCheck: (count: number) => {
      return count + 2;
    },
    decrementCheck: (count: number) => {
      return count - 2;
    },
  },
  [OptionType.Panières]: {
    name: "Panière",
    icon: Icons.OPTIONS_PANIERE,
    id: OrderOptionType.paniere,
    tooltipText:
      "Bac en osier rectangulaire porté en bandoulière. Permet la distribution de produits",
  },
  [OptionType.Cabas]: {
    name: "Cabas à roulette / Caddie",
    icon: Icons.CABAS,
    id: OrderOptionType.cabas,
    tooltipText:
      "Mini base logistique pour stocker les flyers/goodies pendant la distribution",
  },
  [OptionType.PanneauDirectionnel]: {
    name: "Panneau directionnel & informationnel",
    icon: Icons.OPTIONS_PANNEAU_DIRECTIONNEL,
    id: OrderOptionType.panneauDirectionnel,
    tooltipText:
      "Panneau informationnel (insertion d'une feuille de papier A4 avec votre message)",
  },
  [OptionType.Formation]: {
    name: "Formation",
    icon: Icons.OPTIONS_FORMATING,
    id: OrderOptionType.formation,
    tooltipText:
      "Formation du personnel sélectionné selon votre brief et vos objectifs",
  },
  [OptionType.TotbagR]: {
    name: "Totbag print Recto",
    icon: Icons.OPTIONS_TOTBAG_R,
    id: OrderOptionType.totbagR,
    tooltipText:
      "Renfort de visibilité qui facilite le taux de diffusion afin de mieux voir votre dispositif, développement de votre notoriété accru.",
    incrementCheck: (count: number) => {
      const values = ROUND_NUMBERS;
      if (values.includes(count) && count !== 1800) {
        return values[values.indexOf(count) + 1];
      }
      return count;
    },
    decrementCheck: (count: number) => {
      const values = ROUND_NUMBERS;
      if (values.includes(count) && count !== 0) {
        return values[values.indexOf(count) - 1];
      }
      return count;
    },
  },
  [OptionType.TotbagRV]: {
    name: "Totbag print Recto - Verso",
    icon: Icons.OPTIONS_TOTBAG_RV,
    id: OrderOptionType.totbagRV,
    tooltipText:
      "Renfort de visibilité qui facilite le taux de diffusion afin de mieux voir votre dispositif, développement de votre notoriété accru.",
    incrementCheck: (count: number) => {
      const values = ROUND_NUMBERS;
      if (values.includes(count) && count !== 1800) {
        return values[values.indexOf(count) + 1];
      }
      return count;
    },
    decrementCheck: (count: number) => {
      const values = ROUND_NUMBERS;
      if (values.includes(count) && count !== 0) {
        return values[values.indexOf(count) - 1];
      }
      return count;
    },
  },
  [OptionType.Besace]: {
    name: "Besace",
    icon: Icons.BESACES,
    id: OrderOptionType.besace,
    tooltipText: "Transport de flyers, goodies, distribution",
    incrementCheck: (count: number) => {
      return count < 30 ? count + 1 : count;
    },
  },
  [OptionType.BesacePrint]: {
    name: "Besace imprimée",
    icon: Icons.BESACES_PRINT,
    id: OrderOptionType.besacePrint,
    tooltipText: "Transport de flyers, goodies, distribution",
    incrementCheck: (count: number) => {
      return count < 30 ? count + 1 : count;
    },
  },
  [OptionType.Ballonbag]: {
    name: "Ballonbag",
    icon: Icons.BALLONBAG,
    id: OrderOptionType.ballonbag,
    tooltipText:
      "Renfort de visibilité qui facilite le taux de diffusion afin de mieux voir votre dispositif, développement de votre notoriété accru.",
    incrementCheck: (count: number) => {
      return count < 10 ? count + 1 : count;
    },
  },
  [OptionType.Oriflamme]: {
    name: "Oriflamme bag",
    icon: Icons.ORIFLAMME,
    id: OrderOptionType.oriflamme,
    tooltipText:
      "Augmentez votre visibilité et l’impact de votre équipe avec des Oriflamme pour votre événement.",
    // description:
    // "Ce sac à dos porte-drapeau aussi appelé meneur d’allure est particulièrement adapté aux opérations de street marketing et aux courses à pied. Le sac à dos est monté sur une coque thermoformée épousant la forme du dos. Serrage poitrine et taille. Voile maille 100% polyester 115g/m2. L’impression est réalisée en sublimation numérique Haute-Définition, en recto ou recto/verso. En impression recto seule, le visuel reste visible de l’autre côté par transparence. 2 formes de voiles sont au choix : la forme classique WF (réf : WF 120) ou goutte d’eau (réf : FF 110). Sur la voile, le fourreau noir est en polyester Oxford 300 D. Mât en fibre de carbone. L’installation se fait en 1 minute.",
    incrementCheck: (count: number) => {
      return count < 15 ? count + 1 : count;
    },
  },
  // [OptionType.TourDeCou]: {
  //   name: "Tour de cou",
  //   icon: Icons.TOUR_DE_COU,
  //   id: OrderOptionType.tourDeCou,
  //   tooltipText: "Tour de coup.",
  //   // incrementCheck: (count: number) => {return 0},
  //   // decrementCheck: (count: number) => {return 0},
  // },
  // [OptionType.Badge]: {
  //   name: "Badge",
  //   icon: Icons.BADGE,
  //   id: OrderOptionType.badge,
  //   tooltipText: "Badge.",
  //   // incrementCheck: (count: number) => {return 0},
  //   // decrementCheck: (count: number) => {return 0},
  // },
};

const defaultOptionInfo: OptionInformation = {
  optionsToHide: [],
  selectedOptions: {
    [OptionType.Portant]: 0,
    [OptionType.TicketsVestiaires]: 0,
    [OptionType.TabletteNumérique]: 0,
    [OptionType.TalkyWalky]: 0,
    [OptionType.Panières]: 0,
    [OptionType.Cabas]: 0,
    [OptionType.PanneauDirectionnel]: 0,
    [OptionType.Formation]: 0,
    [OptionType.TotbagR]: 0,
    [OptionType.TotbagRV]: 0,
    [OptionType.Besace]: 0,
    [OptionType.BesacePrint]: 0,
    [OptionType.Ballonbag]: 0,
    [OptionType.Oriflamme]: 0,
    // [OptionType.TourDeCou]: 0,
    // [OptionType.Badge]: 0,
  },
};

export interface OptionInformation {
  optionsToHide: OptionType[];
  selectedOptions: { [option in OptionType]: number };
}

export interface OptionDetails {
  name: string;
  icon: Icons;
  id: OrderOptionType;
  tooltipText: string;
  description?: string;
  file?: string; //PATH TO FILE (public folder)
  fileName?: string; //NAME OF FILE on the user's computer
  incrementCheck?: (current: number) => number;
  decrementCheck?: (current: number) => number;
}

export const OptionContext = createContext<OptionContextProps>({
  optionInfo: defaultOptionInfo,
  setOptionInfo: () => {},
  incrementOptionCount: () => {},
  decrementOptionCount: () => {},
  editOptionCount: () => {},
  resetOptionInfo: () => {},
});

export const OptionProvider: React.FunctionComponent<ProviderProps> = ({
  children,
}) => {
  const { track } = useSegment();
  const [optionInfo, setOptionInfo] = useState<OptionInformation>(() => {
    const userInfoFromLocalStorage = localStorage.getItem("optionInfo");
    if (!userInfoFromLocalStorage) return { ...defaultOptionInfo };
    try {
      return JSON.parse(userInfoFromLocalStorage);
    } catch (e) {
      return { ...defaultOptionInfo };
    }
  });

  useEffect(() => {
    localStorage.setItem("optionInfo", JSON.stringify(optionInfo));
  }, [optionInfo]);

  return (
    <OptionContext.Provider
      value={{
        optionInfo,
        setOptionInfo,
        incrementOptionCount(option) {
          track("Increment option", {
            optionType: option,
            countBeforeAction: optionInfo.selectedOptions[option],
          });
          if (OPTIONS_LIST[option].incrementCheck) {
            setOptionInfo({
              ...optionInfo,
              selectedOptions: {
                ...optionInfo.selectedOptions,
                [option]: OPTIONS_LIST[option].incrementCheck!(
                  optionInfo.selectedOptions[option]
                ),
              },
            });
          } else
            setOptionInfo({
              ...optionInfo,
              selectedOptions: {
                ...optionInfo.selectedOptions,
                [option]: optionInfo.selectedOptions[option] + 1,
              },
            });
        },
        decrementOptionCount(option) {
          track("Decrement option", {
            optionType: option,
            countBeforeAction: optionInfo.selectedOptions[option],
          });
          if (OPTIONS_LIST[option].decrementCheck) {
            setOptionInfo({
              ...optionInfo,
              selectedOptions: {
                ...optionInfo.selectedOptions,
                [option]: OPTIONS_LIST[option].decrementCheck!(
                  optionInfo.selectedOptions[option]
                ),
              },
            });
          } else
            setOptionInfo({
              ...optionInfo,
              selectedOptions: {
                ...optionInfo.selectedOptions,
                [option]:
                  optionInfo.selectedOptions[option] > 0
                    ? optionInfo.selectedOptions[option] - 1
                    : 0,
              },
            });
        },
        editOptionCount(option, value) {
          setOptionInfo({
            ...optionInfo,
            selectedOptions: {
              ...optionInfo.selectedOptions,
              [option]: value,
            },
          });
        },
        resetOptionInfo() {
          setOptionInfo(defaultOptionInfo);
        },
      }}
    >
      {children}
    </OptionContext.Provider>
  );
};

export const useOptionContext = (): OptionContextProps =>
  useContext(OptionContext);

export interface OptionContextProps {
  optionInfo: OptionInformation;
  setOptionInfo: (optionInfo: OptionInformation) => void;
  incrementOptionCount: (selectedOption: OptionType) => void;
  decrementOptionCount: (selectedOption: OptionType) => void;
  editOptionCount: (selectedOption: OptionType, value: number) => void;
  resetOptionInfo: () => void;
}

export interface ProviderProps {
  children: JSX.Element;
}
