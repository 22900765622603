export const DressingTenuePersoClassiqueIcon = () => (
  <svg
    width="155"
    height="152"
    viewBox="0 0 155 152"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M89.1782 26.7601L87.194 27.391C87.1691 27.2831 87.14 27.1752 87.1068 27.0672C86.8495 26.2391 86.7332 27.7272 86.0649 27.1752C86.1127 27.8891 85.0853 20.5502 84.9857 21.2559C84.9442 21.5527 85.3032 26.4695 85.2348 26.7601L69.8264 26.9074C69.9136 25.4234 69.1228 25.9942 68.4234 24.6846C68.0103 25.9153 67.946 25.0229 67.2922 26.1374C67.0183 26.6085 66.7256 27.061 66.4101 27.5031C66.2586 27.721 66.0988 27.9348 65.9349 28.1465L65.7252 28.2129L64.6875 7.87319C64.6875 1.16732 70.4988 -2.08911 75.0649 2.2694C77.491 4.58523 83.4249 1.55335 84.7927 3.74921C86.1583 5.94714 86.6876 8.98149 86.6876 12.3355L89.1782 26.7601Z"
      fill="#2F2E41"
    />
    <path
      d="M64.8799 142.29L68.2227 142.369L70.0861 127.351L65.1529 127.234L64.8799 142.29Z"
      fill="#FFB7B7"
    />
    <path
      d="M76.3487 149.265C76.3487 149.83 75.9479 150.288 75.4536 150.288H68.8183C68.8183 150.288 68.1654 147.663 65.5031 146.533L65.3194 150.288H61.8965L62.3112 144.251C62.3112 144.251 61.3956 141.021 63.2972 139.37C65.1988 137.719 63.6585 137.949 63.6585 137.949L64.4066 134.213L69.579 134.821L69.617 140.686L72.1271 146.508L75.8088 148.327C76.1365 148.488 76.3486 148.857 76.3486 149.265H76.3487H76.3487Z"
      fill="#2F2E41"
    />
    <path
      d="M75.0508 139.843L78.2642 140.768L83.8775 126.714L79.1354 125.35L75.0508 139.843Z"
      fill="#FFB7B7"
    />
    <path
      d="M84.3744 149.5C84.2311 150.046 83.7273 150.387 83.2491 150.262L76.831 148.578C76.831 148.578 76.8655 145.874 74.577 144.106L73.4466 147.69L70.1357 146.822L72.0688 141.088C72.0688 141.088 72.0027 137.732 74.2609 136.617C76.5192 135.503 74.9711 135.334 74.9711 135.334L76.6428 131.91L81.4915 133.811L80.04 139.494L80.9906 145.762L84.0903 148.455C84.3662 148.695 84.4778 149.106 84.3743 149.5L84.3744 149.5L84.3744 149.5Z"
      fill="#2F2E41"
    />
    <path
      d="M83.1595 21.2695L72.9897 22.3073L66.5557 59.2508L86.0652 62.364L83.1595 21.2695Z"
      fill="#F7B80B"
    />
    <path
      d="M63.65 28.1184L64.6878 52.194L55.5557 68.1751L62.1972 74.194C62.1972 74.194 74.4425 49.9109 74.8576 43.2694C75.2727 36.6279 72.8853 22.9062 72.8853 22.9062L63.65 28.1184Z"
      fill="#2F2E41"
    />
    <path
      d="M92.7072 28.1184L91.6695 52.194L100.802 68.1751L86.6883 74.6091C86.6883 74.6091 81.9147 49.9109 81.4996 43.2694C81.0845 36.6279 83.4719 22.9062 83.4719 22.9062L92.7072 28.1184Z"
      fill="#2F2E41"
    />
    <path
      d="M67.8008 56.9668L63.0272 57.5894L60.3291 86.4386L64.065 130.118H70.177L74.6499 81.4575L82.5367 99.7217L76.7662 130.118L82.5367 130.707L94.1594 99.099L86.0651 62.363L67.8008 56.9668Z"
      fill="#2F2E41"
    />
    <path
      d="M87.3919 83.0104C87.6177 82.2152 88.0044 81.5369 88.4648 81.0531L90.1375 73.3418L93.7836 74.2451L91.6942 81.9701C91.8316 82.6237 91.8041 83.404 91.5783 84.1992C91.0624 86.0159 89.7071 87.2224 88.551 86.8941C87.395 86.5658 86.876 84.827 87.3919 83.0104V83.0104Z"
      fill="#FFB6B6"
    />
    <path
      d="M87.8965 26.5588C87.8965 26.5588 94.6283 26.216 95.1185 30.68C95.6087 35.1441 97.1769 60.5683 97.1769 60.5683L93.5418 77.7292L88.3583 77.0089L90.649 55.165L87.8965 26.5588H87.8965Z"
      fill="#2F2E41"
    />
    <path
      d="M30.1997 39.8931C30.9659 40.2111 31.5968 40.6757 32.0252 41.1911L39.5157 43.7588L38.1896 47.2873L30.7341 44.3027C30.0666 44.3633 29.2921 44.2448 28.5259 43.9268C26.7755 43.2005 25.7312 41.7087 26.1934 40.5949C26.6556 39.481 28.4493 39.1668 30.1997 39.8931H30.1997H30.1997Z"
      fill="#FFB6B6"
    />
    <path
      d="M72.9712 29.1852L72.9712 29.1852L55.8832 52.2512L35.8525 46.7355L36.7234 41.5101L51.9761 44.3989L60.4854 31.3172C63.2639 25.9462 68.7403 24.8646 72.9712 29.1852V29.1852Z"
      fill="#2F2E41"
    />
    <path
      d="M77.3257 19.1669C81.4648 19.1669 84.8203 15.8115 84.8203 11.6723C84.8203 7.53318 81.4648 4.17773 77.3257 4.17773C73.1865 4.17773 69.8311 7.53318 69.8311 11.6723C69.8311 15.8115 73.1865 19.1669 77.3257 19.1669Z"
      fill="#FFB7B7"
    />
    <path
      d="M88.3486 11.4009H85.8237L85.1379 10.4767C84.8047 10.4767 84.5593 11.3653 84.2405 11.2975C83.2679 11.0906 82.3752 10.5845 81.6438 9.87895C81.582 9.81935 81.518 9.76208 81.4518 9.70734L81.4529 9.72433C81.5113 10.6381 80.7577 11.4009 79.8421 11.4009H74.9731C73.6437 11.4009 72.6742 12.6589 73.0127 13.9444L72.575 29.6652C72.575 26.4565 58.3787 10.0726 68.0089 3.51411C70.2385 1.99573 71.8569 2.26883 75.0656 2.26883C79.9974 -0.361478 86.2018 2.09315 87.8043 7.44787C88.1632 8.64733 88.3486 9.97355 88.3486 11.4009Z"
      fill="#2F2E41"
    />
    <path
      d="M142.854 151.041H7.22938C7.17963 151.041 7.13197 150.988 7.09684 150.894C7.06172 150.8 7.04199 150.673 7.04199 150.541C7.04199 150.409 7.06172 150.282 7.09684 150.188C7.13197 150.094 7.17963 150.041 7.22938 150.041H142.854C142.878 150.041 142.903 150.054 142.926 150.079C142.948 150.104 142.969 150.141 142.987 150.187C143.004 150.233 143.018 150.289 143.028 150.349C143.037 150.41 143.042 150.475 143.042 150.541C143.042 150.607 143.037 150.672 143.028 150.733C143.018 150.793 143.004 150.849 142.987 150.895C142.969 150.942 142.948 150.978 142.926 151.003C142.903 151.028 142.878 151.041 142.854 151.041Z"
      fill="#CCCCCC"
    />
  </svg>
);
