export const ConsultationBlockIcon = () => (
  <svg
    width="87"
    height="58"
    viewBox="0 0 87 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_374_50472)">
      <path
        d="M65.4359 2.24148C64.7786 2.61127 63.7178 1.14861 63.4774 1.86406C63.237 2.57951 63.8192 5.46631 64.1196 6.15866C64.2689 6.5028 64.48 6.81754 64.6265 7.16289C64.773 7.50823 64.8516 7.90531 64.7221 8.2574C64.4967 8.87013 63.7529 9.11939 63.3842 9.65794C62.9547 10.2852 63.1281 11.13 63.3289 11.8634C63.502 12.4956 63.6864 13.1335 64.0196 13.6978C64.3527 14.2621 64.855 14.7534 65.4826 14.9401C66.2817 15.1778 67.1357 14.8953 67.906 14.5764C68.6466 14.2698 69.3793 13.9222 70.0092 13.4261C70.6392 12.93 71.1647 12.2715 71.3849 11.5C71.6611 10.5323 71.4304 9.47318 70.9522 8.58786C70.4739 7.70255 69.7665 6.96499 69.0406 6.26866C67.8181 5.09589 66.5171 4.00513 65.1492 3.00613L65.4359 2.24148Z"
        fill="#1A202C"
      />
      <path
        d="M75.2113 51.8984L74.5908 57.6969L74.5625 57.9621L67.5421 57.9347L67.505 57.6687L66.7617 52.3554L75.2113 51.8984Z"
        fill="#FFB6B6"
      />
      <path
        d="M64.4767 57.6556C64.4767 57.7431 64.4759 57.8315 64.475 57.9207L57.187 57.8924L57.1632 57.6264L56.7139 52.5827L64.2499 51.668C64.2499 51.668 64.4926 54.3432 64.4767 57.6556Z"
        fill="#FFB6B6"
      />
      <path
        d="M63.6659 12.5117C64.8236 13.9717 66.8646 14.3948 68.5118 13.5257C70.8631 12.2852 74.0733 10.8635 74.6407 11.9996C75.5541 13.8286 75.5541 15.6037 75.5541 15.6037L76.4676 36.9209C76.4676 36.9209 76.9243 45.528 71.2151 44.7683C65.506 44.0086 56.5996 47.2095 56.5996 47.2095L57.5094 37.7658L59.9386 34.2052L60.9226 23.9901L59.2258 15.772L61.2811 14.8575C61.2811 14.8575 61.5095 13.257 62.1946 13.257C62.8797 13.257 63.0452 11.7196 63.6475 12.4883C63.6536 12.496 63.6597 12.5038 63.6659 12.5117V12.5117Z"
        fill="#F7B80B"
      />
      <path
        d="M76.0518 57.9665L74.5626 57.9604L67.5421 57.933L66.6285 57.9294L66.5755 57.6634L65.1631 50.5251L64.9425 57.6572L64.9345 57.9224L64.4755 57.9206L57.1875 57.8923L56.6649 57.8897L56.6632 57.6245L56.6323 52.5834L56.604 47.9153L56.5996 47.2091L56.6005 47.2083L58.7826 42.7796L59.4191 41.4875L59.5497 41.2224L60.8244 38.6356L72.3584 37.0368L72.535 37.0121L75.6687 36.5781L75.6917 37.864L75.7049 38.594L75.7517 41.2224L75.757 41.4875L75.7623 41.7907L76.0465 57.7005L76.0518 57.9665Z"
        fill="#1A202C"
      />
      <path
        d="M67.0779 11.8349C69.7119 11.8349 71.8471 9.6971 71.8471 7.06002C71.8471 4.42294 69.7119 2.28516 67.0779 2.28516C64.4439 2.28516 62.3086 4.42294 62.3086 7.06002C62.3086 9.6971 64.4439 11.8349 67.0779 11.8349Z"
        fill="#FFB6B6"
      />
      <path
        d="M72.4544 14.5377C71.3412 16.1274 70.772 18.0917 70.8623 20.0311C69.6002 19.419 68.7484 18.1257 68.4585 16.752C68.1686 15.3783 68.3897 13.936 68.8556 12.6117C68.8603 12.5984 68.865 12.5852 68.8697 12.572C69.577 10.5842 68.9198 8.36263 67.2113 7.12699C66.0588 6.29351 65.0593 5.24787 64.2922 4.04928C63.8591 3.37263 63.491 2.52381 63.8146 1.78832C64.1127 1.1106 64.8828 0.793837 65.5916 0.581891C67.2099 0.0979265 68.9294 -0.177456 70.5904 0.128783C72.2515 0.435021 73.8505 1.39137 74.6356 2.88858C75.4206 4.38579 75.2149 6.4291 73.9407 7.53902C74.3426 7.66911 74.627 8.09558 74.5928 8.51706C74.5587 8.93854 74.2094 9.31359 73.7919 9.37715C74.0643 9.36748 74.2251 9.72672 74.128 9.98172C74.0308 10.2367 73.7803 10.3951 73.5413 10.5264C73.2008 10.7133 72.8234 10.9089 72.822 11.3985C72.8214 11.5948 72.8913 11.7853 73.0055 11.9449C73.2824 12.3315 73.7294 12.5057 74.1596 12.6691C73.2496 12.9375 72.5239 13.7518 72.361 14.6876L72.4544 14.5377Z"
        fill="#1A202C"
      />
      <path
        d="M52.1174 12.6904C51.9872 11.3233 52.5863 10.1479 53.4553 10.0652C54.3243 9.98238 55.134 11.0234 55.264 12.391C55.3234 12.9366 55.2515 13.4885 55.0542 14.0005L55.5326 19.7998L52.8095 19.9666L52.627 14.2318C52.3366 13.7661 52.1619 13.2376 52.1174 12.6904Z"
        fill="#FFB6B6"
      />
      <path
        d="M66.9531 15.441C66.9531 15.441 62.5043 12.821 61.5884 13.214C60.6725 13.607 56.2237 20.288 56.2237 20.288L55.8147 19.0687L52.6909 19.371C52.6909 19.371 50.8591 27.8859 54.9153 27.0999C58.9715 26.3139 65.6446 18.978 65.6446 18.978L66.9531 15.441H66.9531Z"
        fill="#F7B80B"
      />
      <path
        d="M25.6787 29.5183C25.6787 29.5183 28.1906 28.044 28.867 29.4111C29.5435 30.7782 35.7928 47.087 35.7928 47.087L31.7658 47.8721L25.6787 29.5183V29.5183Z"
        fill="#F7B80B"
      />
      <path
        d="M20.2002 19.777L20.3186 19.9457C19.8772 20.2009 19.8121 20.8445 20.0191 21.3116C20.2261 21.7787 20.6268 22.1249 20.9733 22.5001C21.3199 22.8753 21.6359 23.34 21.6037 23.8503C21.5677 24.4241 21.1003 24.8967 21.0353 25.4681C20.9412 26.3002 21.7151 27.0303 21.6766 27.8671C21.6476 28.5061 21.1568 29.0194 20.6699 29.4339C20.2888 29.7589 19.8741 30.0855 19.6169 30.5047C19.5463 30.62 19.4875 30.7433 19.4444 30.8752C19.2523 31.4702 19.4334 32.1186 19.6773 32.694C19.9203 33.2694 20.23 33.8314 20.3061 34.4516C20.3814 35.0717 20.1477 35.7876 19.5777 36.042C20.713 35.9446 21.8209 35.5482 22.761 34.9037C22.8457 34.8456 22.928 34.786 23.0096 34.7248C24.3425 33.72 25.2787 32.2685 26.1796 30.854C26.2541 30.7378 26.3278 30.6208 26.4022 30.5047C26.6022 30.1899 26.8029 29.8759 27.0036 29.5611C27.576 28.6615 28.1554 27.7485 28.4675 26.7296C28.7545 25.79 28.8031 24.797 28.9207 23.8212C28.9779 23.3432 29.0532 22.8667 29.0673 22.3855C29.09 21.6452 28.9638 20.9018 28.7066 20.2071C28.4549 19.5242 28.077 18.8876 27.5901 18.3459C27.0914 17.7909 26.4814 17.3396 25.8103 17.0146C25.1603 16.6998 24.453 16.5043 23.7325 16.4502C23.491 16.4321 23.2503 16.429 23.0096 16.44C22.0601 16.4792 21.1215 16.7194 20.2089 17.0146C19.5377 17.3396 18.9277 17.7909 18.429 18.3459C17.9421 18.8876 17.5642 19.5242 17.3125 20.2071C18.1875 19.7393 19.2272 19.5784 20.2002 19.777Z"
        fill="#1A202C"
      />
      <path
        d="M27.0053 28.3633L22.5033 26.5957L22.3893 28.3633L18.4426 28.9819L16.5889 37.3779L18.2661 42.5922L30.8011 42.9457L30.3597 41.1781C30.3597 41.1781 32.39 38.8803 31.1542 35.1684L31.0169 34.2237C30.6581 31.7538 29.1766 29.5896 27.0053 28.3633L27.0053 28.3633Z"
        fill="#F7B80B"
      />
      <path
        d="M24.4988 26.5167C26.7072 26.5167 28.4975 24.7243 28.4975 22.5132C28.4975 20.3022 26.7072 18.5098 24.4988 18.5098C22.2903 18.5098 20.5 20.3022 20.5 22.5132C20.5 24.7243 22.2903 26.5167 24.4988 26.5167Z"
        fill="#A0616A"
      />
      <path
        d="M16.9518 22.3855C16.9659 22.8667 17.0412 23.3432 17.0985 23.8212C17.2161 24.797 17.2647 25.79 17.5516 26.7296C17.8637 27.7485 18.4431 28.6615 19.0155 29.5611C19.2162 29.8759 19.417 30.1899 19.6169 30.5047C19.6914 30.6208 19.7651 30.7378 19.8396 30.854C20.7405 32.2685 21.6766 33.72 23.0096 34.7248C23.0911 34.786 23.1734 34.8457 23.2581 34.9037C24.1982 35.5482 25.3061 35.9446 26.4415 36.042C25.8714 35.7876 25.6378 35.0717 25.713 34.4516C25.7891 33.8314 26.0988 33.2694 26.3419 32.694C26.5857 32.1186 26.7668 31.4702 26.5747 30.8752C26.5316 30.7433 26.4728 30.62 26.4022 30.5047C26.1451 30.0855 25.7303 29.7589 25.3492 29.4339C24.8623 29.0194 24.3715 28.5061 24.3425 27.8671C24.3041 27.0303 23.7701 27.3105 23.676 26.4784C23.6109 25.907 22.8496 24.9328 22.8135 24.359C22.7814 23.8487 23.0942 22.6147 23.4408 22.2395C23.7874 21.8642 23.469 20.7441 23.676 20.277C23.883 19.8099 26.1419 20.2009 25.7005 19.9457L25.8189 19.777C26.7919 19.5784 27.8316 19.7393 28.7066 20.2071C28.8972 20.3084 29.0791 20.4238 29.2516 20.5541C29.6679 19.7927 29.1739 18.8263 28.502 18.2776C27.8441 17.7391 27.0334 17.4283 26.229 17.1543C26.0902 17.1072 25.9506 17.0601 25.8103 17.0146C24.8976 16.7194 23.9591 16.4792 23.0096 16.44C22.7689 16.429 22.5281 16.4321 22.2867 16.4502C21.5661 16.5043 20.8589 16.6998 20.2089 17.0146C19.5377 17.3396 18.9277 17.7909 18.429 18.3459C17.9421 18.8876 17.5642 19.5242 17.3125 20.2071C17.0553 20.9018 16.9291 21.6452 16.9518 22.3855H16.9518Z"
        fill="#1A202C"
      />
      <path
        d="M86.753 54.3884L81.1794 42.6632C80.7636 41.7883 79.8694 41.2227 78.901 41.2227H8.50019C8.23269 41.2227 7.97231 41.2642 7.72688 41.3437C7.10633 41.5426 6.57845 41.9774 6.26595 42.5748L0.291505 53.9774C-0.121615 54.7649 -0.0942441 55.6911 0.36478 56.4529C0.82292 57.2156 1.62711 57.6734 2.51605 57.6769L56.6646 57.8899L57.1872 57.8926L64.4752 57.9208L64.9342 57.9226L66.6282 57.9297L67.5418 57.9332L74.5623 57.9606L76.0515 57.9668L84.465 57.9995H84.4756C85.3442 57.9995 86.1395 57.5611 86.6056 56.8267C87.0735 56.0888 87.1282 55.1767 86.753 54.3884Z"
        fill="white"
      />
      <path
        d="M86.753 54.3884L81.1794 42.6632C80.7636 41.7883 79.8694 41.2227 78.901 41.2227H8.50019C8.23269 41.2227 7.97231 41.2642 7.72688 41.3437C7.10633 41.5426 6.57845 41.9774 6.26595 42.5748L0.291505 53.9774C-0.121615 54.7649 -0.0942441 55.6911 0.36478 56.4529C0.82292 57.2156 1.62711 57.6734 2.51605 57.6769L56.6646 57.8899L57.1872 57.8926L64.4752 57.9208L64.9342 57.9226L66.6282 57.9297L67.5418 57.9332L74.5623 57.9606L76.0515 57.9668L84.465 57.9995H84.4756C85.3442 57.9995 86.1395 57.5611 86.6056 56.8267C87.0735 56.0888 87.1282 55.1767 86.753 54.3884ZM86.3823 56.6844C85.9647 57.3428 85.2533 57.7344 84.4756 57.7344H84.4658L76.0462 57.7008L74.5906 57.6955L67.5048 57.6672L66.5752 57.6637L64.9421 57.6575L64.4769 57.6557L57.1634 57.6265L56.6628 57.6248L2.51691 57.4118C1.72157 57.4082 1.00124 56.9991 0.591651 56.3168C0.181161 55.6345 0.156463 54.8055 0.526329 54.1003L6.50075 42.6986C6.71614 42.2876 7.04452 41.9624 7.43736 41.7529C7.75779 41.5815 8.12149 41.4878 8.50019 41.4878H78.901C79.7679 41.4878 80.5676 41.9942 80.9402 42.7772L86.5138 54.5015C86.8501 55.2085 86.8007 56.0242 86.3823 56.6844Z"
        fill="#3F3D56"
      />
      <path
        d="M25.019 49.7066L38.3485 44.8458L31.6838 42.1621L18.3984 45.6412L25.019 49.7066Z"
        fill="#F2F2F2"
      />
      <path
        d="M45.322 42.3711L43.8213 47.1435L61.388 48.2041L59.8873 42.813L45.322 42.3711Z"
        fill="#F2F2F2"
      />
      <path
        d="M24.6153 43.1883C25.8504 43.3109 26.7884 44.0483 26.7103 44.8353C26.6322 45.6222 25.5678 46.1605 24.3323 46.0377C23.8381 45.9954 23.3612 45.8352 22.9415 45.5706L17.7148 44.9849L18.0417 42.5352L23.1595 43.3727C23.6231 43.1957 24.1223 43.1325 24.6153 43.1883H24.6153Z"
        fill="#A0616A"
      />
      <path
        d="M19.3692 29.4685L18.4423 28.9824C18.4423 28.9824 16.9858 29.0266 16.0148 29.9988C15.0438 30.9709 7.18732 42.1066 7.18732 42.1066C7.18732 42.1066 5.24528 45.1115 10.1004 45.5533C14.9555 45.9952 20.3403 45.9952 20.3403 45.9952L20.7816 42.3717L13.4548 41.5763L18.5855 34.7712L19.3692 29.4685Z"
        fill="#F7B80B"
      />
      <path
        d="M46.9138 28.2913C46.0231 28.5555 45.0821 28.7049 44.1102 28.719C44.0528 28.7208 43.9954 28.7217 43.9381 28.7217C43.0588 28.7217 42.2052 28.613 41.3887 28.4079L41.4099 28.1985L41.8848 23.6125L41.9148 23.3191L41.4743 21.9961H46.8785L46.792 23.2891L46.7602 23.7601L46.807 23.9095L47.9987 27.7115L48.0517 27.8812C47.6827 28.0394 47.3031 28.1764 46.9138 28.2913H46.9138Z"
        fill="#F7B80B"
      />
      <path
        d="M41.3882 28.4089C40.1056 28.0881 38.9157 27.5296 37.8679 26.7845C37.4362 26.5503 37.0169 26.2967 36.6152 26.028L36.8139 25.9281L41.804 23.4297L41.8844 23.6135L44.033 28.545L44.1098 28.72C44.0524 28.7218 43.995 28.7227 43.9376 28.7227C43.0584 28.7227 42.2048 28.614 41.3882 28.4089Z"
        fill="#1A202C"
      />
      <path
        d="M52.2605 24.616C52.2385 24.646 52.2155 24.6752 52.1925 24.7044C51.82 25.1781 51.4069 25.6182 50.9602 26.0203L50.9567 25.7843L50.9443 24.9156C50.9443 24.9156 51.301 24.6549 51.7415 24.5868C51.8465 24.57 51.9569 24.5647 52.0681 24.5771C52.1325 24.5833 52.1961 24.5957 52.2605 24.616Z"
        fill="#1A202C"
      />
      <path
        d="M50.9603 26.0191C50.1058 26.7906 49.1242 27.4234 48.0517 27.8812C47.6827 28.0394 47.3031 28.1764 46.9138 28.2913L46.9094 28.1083L46.807 23.9095L46.792 23.2891L51.7416 24.5856L52.0046 24.6545L52.1926 24.7031C51.8201 25.1768 51.407 25.6169 50.9603 26.0191Z"
        fill="#1A202C"
      />
      <path
        d="M44.2271 20.5631C46.4166 20.5631 48.1915 18.7861 48.1915 16.5941C48.1915 14.402 46.4166 12.625 44.2271 12.625C42.0376 12.625 40.2627 14.402 40.2627 16.5941C40.2627 18.7861 42.0376 20.5631 44.2271 20.5631Z"
        fill="#A0616A"
      />
      <path
        d="M46.9089 28.1082C45.9962 28.3839 45.0313 28.5351 44.0329 28.5439L44.1097 28.7189C45.0816 28.7047 46.0226 28.5554 46.9133 28.2911L46.9089 28.1082ZM36.8138 25.927C34.7791 24.0445 33.4876 21.3685 33.4356 18.3954C31.3585 20.8028 33.4223 23.8925 36.6152 26.0269C37.0169 26.2955 37.4362 26.5492 37.8678 26.7834C37.498 26.5209 37.1457 26.2346 36.8138 25.927ZM52.1577 11.6636C50.2316 9.24208 47.2629 7.6875 43.9376 7.6875C38.1459 7.6875 33.4329 12.4051 33.4329 18.2045C33.4329 18.2681 33.4338 18.3318 33.4356 18.3954C33.4903 18.3309 33.5485 18.2673 33.6095 18.2045C36.5225 15.1997 38.243 7.86426 43.9376 7.86426C47.2664 7.86426 50.2325 9.44888 52.1224 11.9049C53.466 13.6495 54.2657 15.8351 54.2657 18.2045C54.2657 18.5483 54.249 18.8886 54.2154 19.2235C54.1942 19.4462 54.1651 19.6672 54.1289 19.8855C53.8659 21.4895 53.2329 22.9716 52.3211 24.2381C52.2389 24.353 52.1542 24.4652 52.0677 24.5757C52.0465 24.6022 52.0262 24.6287 52.0041 24.6544C51.6828 25.0565 51.3324 25.4339 50.9563 25.7829C50.0939 26.5854 49.0946 27.2412 47.9982 27.7114C47.6451 27.8643 47.2814 27.9968 46.9089 28.1082C45.9962 28.3839 45.0313 28.5351 44.0329 28.5439C44.0012 28.5448 43.9694 28.5448 43.9376 28.5448C43.1378 28.5448 42.2807 28.4219 41.4094 28.1983C40.2195 27.8934 39.0022 27.402 37.8678 26.7834C38.9157 27.5284 40.1056 28.087 41.3882 28.4078C42.2048 28.6128 43.0584 28.7215 43.9376 28.7215C43.995 28.7215 44.0524 28.7206 44.1097 28.7189C45.0816 28.7047 46.0226 28.5554 46.9133 28.2911C47.3026 28.1762 47.6822 28.0392 48.0512 27.881C49.1237 27.4233 50.1054 26.7905 50.9599 26.0189C51.4065 25.6168 51.8196 25.1767 52.1922 24.703C52.2151 24.6738 52.2381 24.6446 52.2602 24.6146C52.2901 24.5757 52.3202 24.5359 52.3493 24.4962C53.3477 23.1643 54.0362 21.5876 54.3099 19.874C54.3452 19.6539 54.3734 19.4312 54.3946 19.2058C54.4264 18.8762 54.4423 18.5421 54.4423 18.2045C54.4423 15.7343 53.5878 13.4604 52.1577 11.6636V11.6636Z"
        fill="#E6E6E6"
      />
      <path
        d="M40.6759 18.7056C39.9625 18.0318 39.4651 17.1387 39.9629 15.2349C39.9999 15.0931 39.9006 14.941 39.8047 14.8303C39.7088 14.7197 39.5279 14.6683 39.4141 14.7603C39.4951 14.5213 40.606 13.6676 40.6871 13.4286C40.5924 13.5543 39.8701 13.8239 39.8122 13.6775C39.7543 13.5311 39.805 13.3666 39.8559 13.2176L40.1591 12.3293C40.2354 12.1059 40.5362 12.3728 40.6871 12.1913C40.9573 11.8661 40.6489 11.5234 41.0615 11.4322C42.4118 11.1338 43.8432 10.8672 45.155 11.3052C45.728 11.4965 46.2491 11.8143 46.7646 12.1295L47.7078 12.7062C48.2489 13.037 48.8367 13.4295 48.9849 14.0467L47.9944 13.6923C48.2151 14.1626 48.4376 14.6387 48.5385 15.1484C48.6395 15.6581 48.3665 17.5772 48.1021 18.0243C47.8699 18.4171 47.7054 17.3621 47.6291 17.8122C47.7165 16.9282 47.3191 16.0112 46.6144 15.4713C46.4699 15.3606 46.3051 15.2541 46.2408 15.0836C46.1692 14.8935 46.241 14.6649 46.1432 14.4869C46.0215 14.2656 45.7037 14.2445 45.4667 14.3314C45.2297 14.4182 45.0226 14.5815 44.7771 14.64C44.1263 14.7951 43.5343 14.1791 42.8669 14.1328C42.2311 14.0886 41.6624 14.5869 41.398 15.1674C41.1336 15.748 40.4023 15.6223 40.334 16.2567C40.2656 16.8911 41.264 18.1465 40.8636 18.6429L40.6759 18.7056Z"
        fill="#1A202C"
      />
      <path
        d="M40.175 24.622L39.8793 24.3913L39.8105 24.3383C39.7443 24.2862 39.6569 24.2685 39.5748 24.2888L39.2287 24.3754C39.1925 24.3843 39.1537 24.3737 39.1272 24.3471L38.7388 23.9583C38.7123 23.9318 38.7017 23.8929 38.7105 23.8566L38.797 23.5102C38.8183 23.428 38.7997 23.3414 38.7476 23.2742L38.4643 22.9092C38.4572 22.9004 38.4493 22.8916 38.4413 22.8836C38.3336 22.7758 38.1597 22.7758 38.052 22.8836L37.8887 23.0471C37.7713 23.1638 37.7201 23.3396 37.7775 23.5023C37.924 23.9194 38.1624 24.2985 38.4757 24.6105C38.587 24.7219 38.7061 24.8244 38.8324 24.9163C39.0619 25.0825 39.3143 25.215 39.5827 25.3096C39.746 25.367 39.9208 25.3158 40.0373 25.1991L40.2006 25.0347C40.318 24.9181 40.3057 24.7245 40.175 24.622ZM40.0815 24.9163L39.9182 25.0798C39.8413 25.1558 39.7337 25.1841 39.6383 25.1505C39.4132 25.0718 39.2005 24.964 39.0036 24.8297C38.8571 24.7316 38.7203 24.6176 38.5949 24.4912C38.3001 24.1969 38.075 23.8399 37.9364 23.4466C37.9028 23.3511 37.9311 23.2433 38.007 23.1664L38.1712 23.0029C38.1738 22.9994 38.1774 22.9967 38.1809 22.9941C38.2277 22.9578 38.2948 22.9658 38.331 23.0126L38.6143 23.3776C38.6346 23.4033 38.6417 23.4377 38.6338 23.4696L38.5472 23.816C38.5234 23.9097 38.5517 24.0095 38.6196 24.0776L39.008 24.4665C39.076 24.5354 39.1758 24.5628 39.2693 24.5389L39.6154 24.4523C39.6471 24.4444 39.6816 24.4514 39.7072 24.4718L39.7116 24.4753L40.0718 24.7555C40.0753 24.7581 40.0788 24.7616 40.0815 24.7643C40.1238 24.8067 40.1238 24.8739 40.0815 24.9163Z"
        fill="#CCCCCC"
      />
      <path
        d="M43.621 23.8223C43.6646 23.8223 43.7 23.8577 43.7 23.9013V24.0595C43.7 24.4089 43.9829 24.6921 44.3318 24.6921C44.6808 24.6921 44.9637 24.4089 44.9637 24.0595V23.9013C44.9637 23.8577 44.9991 23.8223 45.0427 23.8223C45.0863 23.8223 45.1217 23.8577 45.1217 23.9013V24.0595C45.1217 24.4656 44.8144 24.8057 44.4108 24.8463V25.1666H44.8847C44.9284 25.1666 44.9637 25.202 44.9637 25.2457C44.9637 25.2894 44.9284 25.3248 44.8847 25.3248H43.7789C43.7353 25.3248 43.7 25.2894 43.7 25.2457C43.7 25.202 43.7353 25.1666 43.7789 25.1666H44.2529V24.8463C43.8493 24.8057 43.542 24.4656 43.542 24.0595V23.9013C43.542 23.8577 43.5774 23.8223 43.621 23.8223Z"
        fill="#CCCCCC"
      />
      <path
        d="M44.3315 22.7949C44.0702 22.7949 43.8574 23.007 43.8574 23.2695V24.0605C43.8574 24.3221 44.0702 24.5342 44.3315 24.5342C44.5936 24.5342 44.8055 24.3221 44.8055 24.0605V23.2695C44.8055 23.007 44.5936 22.7949 44.3315 22.7949ZM44.6475 24.0605C44.6475 24.2346 44.5062 24.3769 44.3315 24.3769C44.1567 24.3769 44.0154 24.2346 44.0154 24.0605V23.2695C44.0154 23.0945 44.1567 22.9531 44.3315 22.9531C44.5062 22.9531 44.6475 23.0945 44.6475 23.2695V24.0605Z"
        fill="#CCCCCC"
      />
      <path
        d="M49.7992 22.826C49.5512 22.742 49.2819 22.8746 49.1981 23.1229L49.3314 23.2564C49.3402 23.0885 49.4797 22.9568 49.6483 22.9577C49.8222 22.9586 49.9634 23.1008 49.9625 23.275V23.8883L50.1205 24.0465V23.275C50.1205 23.0726 49.9908 22.8914 49.7992 22.826ZM50.2 25.1733H49.726V24.8525C49.8645 24.8392 49.9969 24.7889 50.1099 24.7067L49.9961 24.5927C49.8963 24.6598 49.7763 24.6987 49.6465 24.6987C49.2978 24.6987 49.0145 24.415 49.0145 24.0659V23.9077C49.0145 23.8936 49.0109 23.8795 49.003 23.868C48.9897 23.845 48.965 23.8291 48.9359 23.8291C48.9218 23.8291 48.9085 23.8326 48.897 23.8397C48.8732 23.8529 48.8564 23.8786 48.8564 23.9077V24.0659C48.8564 24.4716 49.1645 24.8119 49.5679 24.8525V25.1733H49.0939C49.0498 25.1733 49.0145 25.2087 49.0145 25.252C49.0145 25.2962 49.0498 25.3315 49.0939 25.3315H50.2C50.2432 25.3315 50.2785 25.2962 50.2785 25.252C50.2785 25.2087 50.2432 25.1733 50.2 25.1733ZM50.358 23.8291C50.3139 23.8291 50.2785 23.8644 50.2785 23.9077V24.0659C50.2785 24.1084 50.2741 24.1508 50.2662 24.1923L50.2741 24.2003L50.3942 24.3213C50.403 24.2939 50.411 24.2666 50.4171 24.2383C50.4304 24.1826 50.4366 24.1251 50.4366 24.0659V23.9077C50.4366 23.8644 50.4013 23.8291 50.358 23.8291V23.8291Z"
        fill="#CCCCCC"
      />
      <path
        d="M49.972 24.1211L48.7547 22.9023L48.6426 23.0146L49.6683 24.0415L50.5387 24.9121L50.6499 24.7998L49.972 24.1211ZM49.7628 24.3597C49.6012 24.4242 49.4176 24.3447 49.3532 24.1821C49.3382 24.1449 49.3302 24.1052 49.3302 24.0654V23.9267L49.1722 23.7684V24.0654C49.1722 24.327 49.385 24.54 49.6463 24.54C49.7283 24.54 49.8095 24.5188 49.8811 24.4781L49.7628 24.3597Z"
        fill="#CCCCCC"
      />
      <path
        d="M71.0561 41.3974C70.0082 42.1765 68.7573 42.2667 68.2621 41.5989C67.767 40.9311 68.2152 39.7585 69.2636 38.9793C69.6781 38.6623 70.1625 38.4493 70.6761 38.3581L75.1577 35.1133L76.6338 37.2513L72.059 40.2231C71.8231 40.6891 71.4792 41.0918 71.0561 41.3974Z"
        fill="#FFB6B6"
      />
      <path
        d="M73.8798 14.9727C73.8798 14.9727 77.495 15.087 78.1801 17.602C78.8652 20.117 80.2354 25.4776 80.0071 32.9206C80.0071 32.9206 81.1489 37.7219 78.1801 37.4933L74.0695 39.3224L71.3291 35.6642L73.6128 32.9206L73.8798 14.9727Z"
        fill="#F7B80B"
      />
    </g>
    <defs>
      <clipPath id="clip0_374_50472">
        <rect width="87" height="58" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
