import { gql } from "@apollo/client";
import {Location} from "../../modules/Onboarding/EventTypes";

export type ContactInput = {
  pronoun: string;
  company: string;
  firstname: string;
  lastname: string;
  email: string;
  siren: string;
  phone: string;
  address: string;
  availablePeriodOfDay: string | null;
  availableDays: string | null;
  requestText: string | null;
};

// export type ContactEmailAdditionalInput = {
// }

export type PaymentIntentOutput = {
  paymentIntentId: string;
  paymentIntentClientSecret: string;
};

export type CreateQuoteOutput = {
  quoteDownloadUrl: string;
  quoteId: string;
  quoteNumber: string;
};

export type DailySchedule = {
  day: string;
  startHour: string;
  endHour: string;
};

export type OperationInfoInput = {
  type: string;
  name?: string;
  locations?: Location[];
  startDate?: any;
  endDate?: any;
  totalDays?: number;
  eventSchedule?: DailySchedule[];
  totalPeople?: number;
  brief?: string;
  logo?: string;
  summaryDressCode?: string;
  summaryOnboarding?: string;
};

export type ResponseOutput = {
  code: number;
  text: string;
};

export const getPaymentIntentForOrder = gql`
  mutation (
    $contact: ContactInput!
    $input: [StaffInput!]!
    $options: OptionsInput
    $outfit: OutfitInput
    $operationInfo: OperationInfoInput
    $discountCode: String
  ) {
    getPaymentIntentForOrder(
      contact: $contact
      input: $input
      options: $options
      outfit: $outfit
      operationInfo: $operationInfo
      discountCode: $discountCode
    ) {
      paymentIntentId
      paymentIntentClientSecret
    }
  }
`;

export const createQuote = gql`
  mutation (
    $contact: ContactInput!
    $input: [StaffInput!]!
    $options: OptionsInput
    $outfit: OutfitInput
    $operationInfo: OperationInfoInput
    $discountCode: String
  ) {
    createQuote(
      contact: $contact
      input: $input
      options: $options
      outfit: $outfit
      operationInfo: $operationInfo
      discountCode: $discountCode
    ) {
      quoteDownloadUrl
      quoteId
      quoteNumber
    }
  }
`;

export const sendDepositEmails = gql`
  mutation (
    $paymentIntentId: String!
    $contact: ContactInput!
    $input: [StaffInput!]!
    $options: OptionsInput
    $outfit: OutfitInput
    $operationInfo: OperationInfoInput
    $discountCode: String
  ) {
    sendDepositEmails(
      paymentIntentId: $paymentIntentId
      contact: $contact
      input: $input
      options: $options
      outfit: $outfit
      operationInfo: $operationInfo
      discountCode: $discountCode
    ) {
      code
      text
    }
  }
`;

export const sendContactEmail = gql`
  mutation ($contact: ContactInput!) {
    sendContactEmail(contact: $contact) {
      code
      text
    }
  }
`;
