export const EvenementSportifIcon = () => (
  <svg
    width="116"
    height="101"
    viewBox="0 0 116 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1017_48302)">
      <path
        d="M0.235352 100.503C0.235352 100.607 0.319042 100.691 0.42326 100.691H115.577C115.681 100.691 115.765 100.607 115.765 100.503C115.765 100.399 115.681 100.315 115.577 100.315H0.42326C0.319042 100.315 0.235352 100.399 0.235352 100.503Z"
        fill="#3F3D56"
      />
      <path
        d="M70.2674 22.9281L70.899 26.5598L64.1094 27.6651L65.6884 22.6123L70.2674 22.9281Z"
        fill="#A0616A"
      />
      <path
        d="M87.5851 25.3287C88.9529 25.9419 89.2805 28.1814 88.3166 30.3304C87.9432 31.1948 87.3681 31.9571 86.6395 32.5537L85.2611 36.2333C84.3571 40.6195 82.2987 45.2377 79.4638 44.8797C75.4515 44.373 74.1875 39.4963 74.1875 39.4963L79.5869 37.3841L81.9974 32.7369L82.8192 30.8408C82.7801 29.9004 82.9667 28.9642 83.3636 28.1107C84.3275 25.9606 86.2173 24.7155 87.5851 25.3287Z"
        fill="#A0616A"
      />
      <path
        d="M41.6212 95.9052L37.877 93.1378L37.9067 93.0783L58.7673 51.5154L58.8067 51.5086L75.6042 48.6562L79.1029 56.4489L88.2523 69.3126C88.8825 70.1988 89.2118 71.2419 89.2049 72.3293L89.057 95.2432L83.3813 95.4053L83.3736 95.3327L81.3937 76.8066C81.2494 75.4563 80.549 74.2071 79.4719 73.3797L68.3051 64.8003L41.6212 95.9052H41.6212Z"
        fill="#2F2E41"
      />
      <path
        d="M38.4512 93.4297C38.4512 93.4297 36.5564 93.7455 35.9248 94.8508C35.2932 95.9561 33.3984 98.3245 33.3984 98.3245C33.3984 98.3245 31.9773 101.009 35.2932 100.693C38.6091 100.377 39.8723 98.9561 39.8723 98.9561L41.2933 95.0087L38.4512 93.4297Z"
        fill="#2F2E41"
      />
      <path
        d="M86.2943 93.4297C86.2943 93.4297 88.1891 93.7455 88.8207 94.8508C89.4523 95.9561 91.3471 98.3245 91.3471 98.3245C91.3471 98.3245 92.7682 101.009 89.4523 100.693C86.1364 100.377 84.8732 98.9561 84.8732 98.9561L83.4521 95.0087L86.2943 93.4297Z"
        fill="#2F2E41"
      />
      <path
        d="M60.9519 27.507L72.1627 25.2964L78.4787 27.3491L76.7418 50.8759L58.8203 51.5865L60.9519 27.507Z"
        fill="#F7B80B"
      />
      <path
        d="M77.3543 26.8223L79.7965 30.511L80.3631 39.6051L73.1104 40.1557L73.3057 28.374L77.3543 26.8223Z"
        fill="#F7B80B"
      />
      <path
        d="M84.0654 35.6733L81.1305 34.5073L77.3994 41.4182L80.8072 43.1173L84.0654 35.6733Z"
        fill="#A0616A"
      />
      <path
        d="M86.1112 29.7917C85.9074 29.7917 85.703 29.7572 85.508 29.6875C85.038 29.5194 84.6564 29.1598 84.461 28.7006L82.2351 23.4712C81.3966 21.5011 80.4335 19.5599 79.3725 17.7015L78.9075 16.8872C78.5877 16.3953 71.0134 4.73861 70.6532 3.82822C70.3815 3.14173 71.1932 1.73406 71.2279 1.67439L71.2373 1.66144C71.2553 1.64139 73.0722 -0.338354 75.2588 1.50015C77.4057 3.30503 81.0191 15.2666 81.1718 15.775L81.2696 16.0213C82.1608 18.2729 83.2877 20.4407 84.619 22.4643L87.6111 27.0115C87.8893 27.4345 87.9761 27.9582 87.849 28.4482C87.722 28.9381 87.392 29.3535 86.9434 29.588C86.6848 29.7233 86.3985 29.7917 86.1112 29.7917Z"
        fill="#3F3D56"
      />
      <path
        d="M66.1476 31.4547L69.6728 31.6526L76.4457 24.5075L79.0921 21.3615C79.3239 20.4492 79.7707 19.6056 80.3954 18.9014C81.9346 17.1174 84.1018 16.4654 85.2368 17.4447C86.3717 18.4238 86.0444 20.6632 84.5056 22.4464C83.9003 23.1677 83.1309 23.7335 82.2621 24.0964L81.6095 24.9571L74.1347 35.4635C72.786 37.3591 70.4013 38.2169 68.1541 37.6147L63.5791 36.3885L66.1476 31.4547Z"
        fill="#A0616A"
      />
      <path
        d="M64.1691 38.4956L58.7295 35.0014C57.0006 33.8909 56.3362 31.7155 57.1496 29.8287C57.6437 28.6828 58.6135 27.8239 59.8108 27.4724C61.0066 27.1205 62.2883 27.3183 63.3236 28.0154L68.6651 31.6105L64.1691 38.4956Z"
        fill="#F7B80B"
      />
      <path
        d="M66.7975 24.2624C69.8758 24.2624 72.3713 21.7669 72.3713 18.6886C72.3713 15.6102 69.8758 13.1147 66.7975 13.1147C63.7191 13.1147 61.2236 15.6102 61.2236 18.6886C61.2236 21.7669 63.7191 24.2624 66.7975 24.2624Z"
        fill="#A0616A"
      />
      <path
        d="M70.4641 23.5L69.9829 22.6981C69.9829 22.6981 72.0907 21.2773 70.3492 20.4526C68.6077 19.628 68.3325 17.6117 68.3325 17.6117C68.3325 17.6117 63.2457 16.146 63.5205 15.0461C63.7953 13.9463 63.0162 13.1673 64.2992 12.7089C65.5823 12.2505 71.3105 12.708 71.9523 14.0369C72.594 15.3657 73.236 19.0317 72.9153 19.5358C72.5946 20.0399 70.4641 23.5 70.4641 23.5V23.5Z"
        fill="#2F2E41"
      />
      <path
        d="M69.0199 17.1992L63.8411 13.763C63.8411 13.763 57.1504 11.7017 59.6713 15.6423L61.8022 16.0774C61.8022 16.0774 67.5993 17.4285 69.0199 17.1992H69.0199Z"
        fill="#F7B80B"
      />
      <path
        d="M67.4615 16.4203L72.8694 20.4981C72.8694 20.4981 76.0307 14.8612 71.0812 12.0665C66.1316 9.27173 62.9932 13.6485 62.9932 13.6485L64.2994 15.046L67.4615 16.4203Z"
        fill="#F7B80B"
      />
      <path
        d="M28.6035 7.2171C28.6035 8.91921 29.9836 10.3008 31.6873 10.3008C32.5211 10.3008 33.2774 9.9693 33.8316 9.43084C34.4111 8.87026 34.7694 8.08554 34.7694 7.2171C34.7694 5.51491 33.3895 4.1333 31.6873 4.1333C31.1536 4.1333 30.6531 4.26915 30.2157 4.506C30.1415 4.54701 30.0689 4.59127 29.9978 4.63861C29.3315 5.07445 28.8467 5.76124 28.6746 6.565C28.6526 6.65659 28.6367 6.74973 28.6273 6.84448C28.6114 6.96607 28.6035 7.09081 28.6035 7.2171H28.6035Z"
        fill="#F7B80B"
      />
      <path
        d="M28.627 6.84434C28.6758 6.898 28.7264 6.95645 28.7816 7.02275C28.968 7.24695 29.0422 7.36854 29.1464 7.53746C29.2363 7.68434 29.3501 7.86752 29.5726 8.177C30.0322 8.81491 30.2627 9.13541 30.6212 9.426C30.9749 9.71173 31.4155 10.0686 32.077 10.1144C32.1228 10.1176 32.1686 10.1176 32.2128 10.1176C33.0133 10.1176 33.5975 9.63594 33.8202 9.41968C34.4029 8.85123 34.5244 8.16119 34.5497 7.89119C34.5702 7.67331 34.5986 6.90903 34.098 6.22223C33.656 5.61585 33.0449 5.39959 32.4528 5.18795C32.0659 5.05056 31.7723 5.0016 31.5117 4.95743C31.1549 4.89744 30.8485 4.84533 30.3796 4.59899C30.3148 4.5643 30.2611 4.53423 30.2153 4.50586C30.1412 4.54688 30.0685 4.59113 29.9975 4.63847C30.0086 4.64479 30.0212 4.65265 30.0339 4.6606C30.0954 4.69691 30.1711 4.74109 30.2753 4.79637C30.7759 5.06005 31.1154 5.11849 31.4738 5.17847C31.7376 5.22272 32.0107 5.26852 32.3786 5.39959C32.9628 5.60799 33.5155 5.80374 33.9166 6.35484C34.3713 6.97857 34.3445 7.67169 34.327 7.86906C34.3034 8.11539 34.1929 8.74228 33.6638 9.25862C33.4507 9.46547 32.8728 9.94387 32.0929 9.89021C31.5007 9.84912 31.0916 9.51751 30.7617 9.25229C30.4269 8.98068 30.2027 8.66804 29.7558 8.04747C29.5364 7.74277 29.4259 7.56275 29.3375 7.41904C29.2333 7.25011 29.1511 7.11588 28.9554 6.88066C28.8496 6.75275 28.7563 6.65013 28.6743 6.56486C28.6522 6.65645 28.6364 6.74959 28.627 6.84434L28.627 6.84434Z"
        fill="#E6E6E6"
      />
    </g>
    <defs>
      <clipPath id="clip0_1017_48302">
        <rect
          width="115.53"
          height="100"
          fill="white"
          transform="translate(0.235352 0.71875)"
        />
      </clipPath>
    </defs>
  </svg>
);
