import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import {
  setAdviceOnHover,
  useAdviceContext,
} from "../../context/AdviceContext";
import {
  FLYER_DISTRIBUTION_CONFIG,
  FlyersDistributionConfig,
} from "./flyersDistributionConfig";
import CustomTooltip from "../CustomTooltip";

const options = [
  {
    title: "Option A",
    details: "Récurrence & mémorisation",
    tooltipText:
      "Un nombre de jours et de points de distribution plus importants pour ne passer à côté d'aucun potentiel client",
  },
  {
    title: "Option B",
    details: "Intermédiaire",
    tooltipText:
      "Un dispositif équilibré entre impact,  mémorisation et notoriété.",
  },
  {
    title: "Option C",
    details: "Impacte & notoriété",
    tooltipText:
      "Une équipe massive pour marquer les esprits ou faire une action flash !",
  },
];

export default function EventConfigBlock({
  flyers,
  onChange,
}: {
  flyers: number;
  onChange: (value: FlyersDistributionConfig, index: number) => any;
}) {
  const config = useMemo(() => {
    const cfg = FLYER_DISTRIBUTION_CONFIG.find(
      (config) => config.flyers === flyers
    );
    // just to be safe
    if (!cfg)
      return FLYER_DISTRIBUTION_CONFIG.sort(
        (a, b) => Math.abs(a.flyers - flyers) - Math.abs(b.flyers - flyers)
      )[0];
    return cfg;
  }, [flyers]);

  const [chosenOption, setChosenOption] = useState(
    undefined as number | undefined
  );

  useEffect(() => {
    setChosenOption(undefined);
  }, [flyers]);
  const { setAdvice } = useAdviceContext();

  return (
    <Flex gap="1rem">
      {config.configs.map((config, index) => (
        <CustomTooltip
          key={index}
          placement="right"
          padding="1rem"
          content={[
            `${(
              config.teams *
              config.personsPerTeam *
              config.hoursPerDay *
              config.days
            ).toString()} heures de distribution prévues au total.`,
            `${Math.ceil(
              flyers /
                (config.teams *
                  config.personsPerTeam *
                  config.hoursPerDay *
                  config.days)
            )} flyers distribués / heure / personne.`,
          ]}
        >
          <Box
            {...setAdviceOnHover(options[index].tooltipText, setAdvice)}
            textAlign="left"
            cursor="pointer"
            border="solid 1px"
            borderColor="gray.300"
            borderRadius="10px"
            w="100%"
            p="1rem"
            _hover={{
              backgroundColor: "gray.50",
            }}
            onClick={() => {
              setChosenOption(index);
              onChange(config, index);
            }}
            background={
              chosenOption === index ? "gray.100 !important" : undefined
            }
            transition="0.2s"
          >
            <Heading fontSize="18px" mb="0.5rem">
              {options[index].title}
            </Heading>
            <Heading fontSize="14px" mb="0.5rem">
              {config.teams * config.personsPerTeam} personne
              {config.teams * config.personsPerTeam > 1 ? "s" : undefined}
            </Heading>
            <Heading fontSize="14px" mb="0.5rem">
              {options[index].details}
            </Heading>
            <Text>
              {config.teams} équipe{config.teams > 1 ? "s" : undefined}
            </Text>
            <Text>
              {config.personsPerTeam} personne
              {config.personsPerTeam > 1 ? "s" : undefined} par équipe
            </Text>
            <Text>{config.hoursPerDay} heures par jour</Text>
            <Text>
              {config.days} jour{config.days > 1 ? "s" : undefined}
            </Text>
          </Box>
        </CustomTooltip>
      ))}
    </Flex>
  );
}
