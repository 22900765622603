import { Box, Flex, Text } from "@chakra-ui/react";
import { FC, ReactElement } from "react";

import { colors } from "../../ui/theme";
import BottomOutfitSelectionCard from "../DressingBlock/BottomOutfitSelectionCard";
import PersonalEnsembleOutfitSelectionCard from "../DressingBlock/PersonalEnsembleOutfitSelectionCard";
import PersonalTopOutfitSelectionCard from "../DressingBlock/PersonalTopOutfitSelectionCard";
import { DressCode } from "../DressingBlock/DressingBlock";

interface PersonalCustomMenuFirstBlockProps {
  dressCode: DressCode;
  setDressCode: (element: DressCode) => void;
}
const PersonalCustomMenuFirstBlock: FC<PersonalCustomMenuFirstBlockProps> = ({
  dressCode,
  setDressCode,
}): ReactElement => {
  const onSetEnsemble = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      personalOutfit: {
        ...dressCode?.personalOutfit,
        custom: {
          ...dressCode?.personalOutfit?.custom,
          ensemble: value,
          blazer: false,
          top: "",
          bottom: "",
          bottomColor: "",
        },
      },
    });
  };
  const onSetBlazer = (value: boolean) => {
    setDressCode({
      ...dressCode,
      personalOutfit: {
        ...dressCode?.personalOutfit,
        custom: {
          ...dressCode?.personalOutfit?.custom,
          ensemble: "",
          blazer: value,
        },
      },
    });
  };
  const onSetTop = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      personalOutfit: {
        ...dressCode?.personalOutfit,
        custom: {
          ...dressCode?.personalOutfit?.custom,
          ensemble: "",
          top: value,
        },
      },
    });
  };
  const onSetTopColor = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      personalOutfit: {
        ...dressCode?.personalOutfit,
        custom: {
          ...dressCode?.personalOutfit?.custom,
          ensemble: "",
          topColor: value,
        },
      },
    });
  };
  const onSetBottom = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      personalOutfit: {
        ...dressCode?.personalOutfit,
        custom: {
          ...dressCode?.personalOutfit?.custom,
          ensemble: "",
          bottom: value,
          bottomColor: "#000",
        },
      },
    });
  };
  const onSetBottomColor = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      personalOutfit: {
        ...dressCode?.personalOutfit,
        custom: {
          ...dressCode?.personalOutfit?.custom,
          bottomColor: value,
        },
      },
    });
  };

  return (
    <Flex
      border={`1px solid ${colors.gray.lightMode}`}
      padding="24px"
      borderRadius="32px"
      width="100%"
      gap="24px"
    >
      <Flex direction="column" gap="16px">
        <Text variant="2xl" fontWeight="700" textAlign="left">
          Tenues
        </Text>
        <Flex direction="row">
          <PersonalEnsembleOutfitSelectionCard
            dressCode={dressCode}
            onSetEnsemble={onSetEnsemble}
          />
          <Flex my="1rem" mx="1.5rem" flexDirection="column">
            <Box h="50%" w="1px" bg="#aaa" m="auto" />
            <Text my="1rem" color="#aaa" variant="raleway">
              ou
            </Text>
            <Box h="50%" w="1px" bg="#aaa" m="auto" />
          </Flex>
          <Flex direction="column" gap="1rem">
            <PersonalTopOutfitSelectionCard
              dressCode={dressCode}
              onSetTop={onSetTop}
              onSetTopColor={onSetTopColor}
              onSetBlazer={onSetBlazer}
            />
            <BottomOutfitSelectionCard
              selectedOutfit={dressCode?.personalOutfit?.custom}
              onSelectBottom={onSetBottom}
              onSelectBottomColor={onSetBottomColor}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PersonalCustomMenuFirstBlock;
