export const UrbanIcon = () => (
  <svg width="366" height="231" viewBox="0 0 366 231" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_446_51792)">
      <path d="M266.542 56.4872C266.542 78.5537 232.05 143.629 212.273 179.128C210.932 181.536 208.971 183.543 206.594 184.94C204.218 186.337 201.511 187.073 198.754 187.073C195.997 187.073 193.29 186.337 190.913 184.94C188.536 183.543 186.576 181.536 185.234 179.128C165.458 143.629 130.966 78.5537 130.966 56.4872C130.966 25.5523 161.316 0.474609 198.754 0.474609C236.192 0.474609 266.542 25.5523 266.542 56.4872Z" fill="#F7B80B"/>
      <path d="M199.073 92.7679C219.462 92.7679 235.99 76.2394 235.99 55.8505C235.99 35.4616 219.462 18.9331 199.073 18.9331C178.684 18.9331 162.155 35.4616 162.155 55.8505C162.155 76.2394 178.684 92.7679 199.073 92.7679Z" fill="white"/>
      <path d="M45.0183 89.2543C57.2304 89.2543 67.1303 79.3544 67.1303 67.1423C67.1303 54.9302 57.2304 45.0303 45.0183 45.0303C32.8061 45.0303 22.9062 54.9302 22.9062 67.1423C22.9062 79.3544 32.8061 89.2543 45.0183 89.2543Z" fill="#FF6584"/>
      <path d="M198.754 230.475C228.315 230.475 252.279 228.625 252.279 226.343C252.279 224.061 228.315 222.211 198.754 222.211C169.193 222.211 145.229 224.061 145.229 226.343C145.229 228.625 169.193 230.475 198.754 230.475Z" fill="#F7B80B"/>
      <path opacity="0.4" d="M40.5158 86.8061C41.6412 79.8711 36.9316 73.3368 29.9966 72.2114C23.0616 71.086 16.5274 75.7957 15.402 82.7307C14.2766 89.6657 18.9862 96.1999 25.9212 97.3253C32.8562 98.4507 39.3904 93.7411 40.5158 86.8061Z" fill="#F7B80B"/>
      <path d="M325.583 150.391C225.403 112.837 103.219 149.449 32.6751 224.34C32.2741 224.76 31.8731 225.187 31.4849 225.613H30.4092C30.7784 225.187 31.1603 224.76 31.5358 224.34C103.544 143.752 224.105 111.634 325.583 150.391Z" fill="#3F3D56"/>
      <path d="M352.959 162.848C344.104 158.125 334.961 153.965 325.583 150.391C334.985 153.906 344.132 158.068 352.959 162.848Z" fill="#3F3D56"/>
      <path opacity="0.4" d="M298.131 125.38C305.837 125.38 312.083 119.133 312.083 111.427C312.083 103.722 305.837 97.4751 298.131 97.4751C290.425 97.4751 284.179 103.722 284.179 111.427C284.179 119.133 290.425 125.38 298.131 125.38Z" fill="#F7B80B"/>
      <path d="M299.685 218.746C300.763 222.732 304.456 225.2 304.456 225.2C304.456 225.2 306.401 221.206 305.323 217.221C304.245 213.235 300.552 210.767 300.552 210.767C300.552 210.767 298.607 214.76 299.685 218.746Z" fill="#3F3D56"/>
      <path d="M301.269 217.889C304.228 220.769 304.589 225.197 304.589 225.197C304.589 225.197 300.154 224.955 297.195 222.075C294.236 219.194 293.875 214.767 293.875 214.767C293.875 214.767 298.311 215.009 301.269 217.889Z" fill="#F7B80B"/>
      <path d="M2.61315 163.328V169.477L2.30762 169.808V163.265C2.40946 163.284 2.51133 163.309 2.61315 163.328Z" fill="#3F3D56"/>
      <path d="M22.2048 224.338C22.1157 224.484 22.0202 224.63 21.9248 224.777H2.30762V163.634L2.61315 163.328L5.21646 166.288V161.96H13.491V175.696L21.8484 185.186L21.9248 185.275C22.0266 185.593 22.1221 185.912 22.2175 186.23C22.2182 186.235 22.2205 186.239 22.2239 186.243C23.4773 190.254 24.5058 194.332 25.3046 198.457C27.125 208.189 26.8513 217.113 22.2048 224.338Z" fill="#CCCCCC"/>
      <path d="M9.19546 197.125H3.92871V201.514H9.19546V197.125Z" fill="#3F3D56"/>
      <path d="M9.19546 205.025H3.92871V209.414H9.19546V205.025Z" fill="#3F3D56"/>
      <path d="M171.433 188.786L168.8 184.397L193.378 163.33C211.548 154.584 233.451 157.108 256.579 163.33V169.474L233.757 194.053L207.422 199.32L171.433 188.786Z" fill="#3F3D56"/>
      <path d="M267.458 175.693C271.823 170.839 272.429 166.222 267.458 161.959H259.183V166.29L256.579 163.331L235.512 184.398L232 187.909L227.611 205.465L235.512 224.776H275.89V185.275L267.458 175.693Z" fill="#CCCCCC"/>
      <path d="M168.8 184.397H235.512V224.776H168.8C160.729 216.044 164.922 200.128 168.8 184.397Z" fill="#F2F2F2"/>
      <path d="M226.295 197.125H212.25V209.415H226.295V197.125Z" fill="#3F3D56"/>
      <path d="M206.544 196.687H194.255V224.776H206.544V196.687Z" fill="#CCCCCC"/>
      <path d="M180.211 201.075V196.687H174.944V201.075H179.772H180.211Z" fill="#3F3D56"/>
      <path d="M179.772 204.586H174.944V208.976H180.211V204.586H179.772Z" fill="#3F3D56"/>
      <path d="M188.988 196.687H183.722V201.076H188.988V196.687Z" fill="#3F3D56"/>
      <path d="M188.988 204.586H183.722V208.975H188.988V204.586Z" fill="#3F3D56"/>
      <path d="M254.384 201.515V197.125H249.117V201.515H253.945H254.384Z" fill="#3F3D56"/>
      <path d="M253.945 205.025H249.117V209.414H254.384V205.025H253.945Z" fill="#3F3D56"/>
      <path d="M263.162 197.125H257.896V201.514H263.162V197.125Z" fill="#3F3D56"/>
      <path d="M263.162 205.025H257.896V209.414H263.162V205.025Z" fill="#3F3D56"/>
      <path d="M52.6465 115.052L48.2578 111.541L72.8359 89.5957H135.159V108.907L94.7807 136.119L52.6465 115.052Z" fill="#3F3D56"/>
      <path d="M146.522 101.906V90.0343H138.247V92.9418L135.159 89.5957L114.97 111.541L107.07 116.808L94.8203 206.302L113.294 224.776H156.226C162.804 191.685 168.02 157.691 156.226 112.418L146.522 101.906Z" fill="#CCCCCC"/>
      <path d="M48.2574 111.541H114.97V224.776H48.2574C40.9572 180.785 37.3919 139.989 48.2574 111.541Z" fill="#F2F2F2"/>
      <path d="M86.4414 197.125H74.1523V224.776H86.4414V197.125Z" fill="#CCCCCC"/>
      <path d="M60.9852 205.025V200.637H55.7188V205.025H60.5467H60.9852Z" fill="#3F3D56"/>
      <path d="M60.5467 208.537H55.7188V212.926H60.9852V208.537H60.5467Z" fill="#3F3D56"/>
      <path d="M69.7638 200.636H64.4971V205.025H69.7638V200.636Z" fill="#3F3D56"/>
      <path d="M96.5359 208.537H91.708V212.926H96.9745V208.537H96.5359Z" fill="#3F3D56"/>
      <path d="M105.753 200.636H100.486V205.025H105.753V200.636Z" fill="#3F3D56"/>
      <path d="M105.753 208.537H100.486V212.926H105.753V208.537Z" fill="#3F3D56"/>
      <path d="M133.842 117.246V112.857H128.575V117.246H133.403H133.842Z" fill="#3F3D56"/>
      <path d="M133.403 120.758H128.575V125.147H133.842V120.758H133.403Z" fill="#3F3D56"/>
      <path d="M142.62 112.857H137.354V117.246H142.62V112.857Z" fill="#3F3D56"/>
      <path d="M142.62 120.757H137.354V125.146H142.62V120.757Z" fill="#3F3D56"/>
      <path d="M133.842 141.825V137.436H128.575V141.825H133.403H133.842Z" fill="#3F3D56"/>
      <path d="M133.403 145.336H128.575V149.725H133.842V145.336H133.403Z" fill="#3F3D56"/>
      <path d="M142.62 137.436H137.354V141.825H142.62V137.436Z" fill="#3F3D56"/>
      <path d="M142.62 145.335H137.354V149.724H142.62V145.335Z" fill="#3F3D56"/>
      <path d="M133.842 166.403V162.014H128.575V166.403H133.403H133.842Z" fill="#3F3D56"/>
      <path d="M133.403 169.914H128.575V174.303H133.842V169.914H133.403Z" fill="#3F3D56"/>
      <path d="M142.62 162.014H137.354V166.403H142.62V162.014Z" fill="#3F3D56"/>
      <path d="M142.62 169.914H137.354V174.303H142.62V169.914Z" fill="#3F3D56"/>
      <path d="M133.842 190.981V186.592H128.575V190.981H133.403H133.842Z" fill="#3F3D56"/>
      <path d="M133.403 194.492H128.575V198.881H133.842V194.492H133.403Z" fill="#3F3D56"/>
      <path d="M142.62 186.592H137.354V190.981H142.62V186.592Z" fill="#3F3D56"/>
      <path d="M142.62 194.492H137.354V198.881H142.62V194.492Z" fill="#3F3D56"/>
      <path d="M55.7188 117.246H106.631V127.78C90.3118 123.276 73.2944 123.599 55.7188 127.78V117.246Z" fill="#3F3D56"/>
      <path d="M55.7188 138.313C71.6089 142.995 88.5795 142.995 106.631 138.313V148.847H55.7188V138.313Z" fill="#3F3D56"/>
      <path d="M55.7188 159.38H106.631V169.914C89.28 164.374 72.2915 164.112 55.7188 169.914V159.38Z" fill="#3F3D56"/>
      <path d="M55.7188 180.447C72.3227 186.846 89.3086 186.58 106.631 180.447V190.981H55.7188V180.447Z" fill="#3F3D56"/>
      <path d="M329.345 162.147H365.117V179.384L361.921 180.384L351.292 183.701L334.615 179.838L317.958 175.978L309.161 173.94L304.77 172.315C308.423 167.302 315.068 163.359 329.345 162.147Z" fill="#3F3D56"/>
      <path d="M365.116 172.315V224.777H304.769C304.75 224.744 304.737 224.715 304.718 224.683V224.68C304.704 224.643 304.687 224.607 304.667 224.573C303.725 222.677 302.881 220.786 302.134 218.9C295.801 202.937 296.488 187.396 304.769 172.315H365.116Z" fill="#F2F2F2"/>
      <path d="M342.953 197.125H330.664V224.776H342.953V197.125Z" fill="#CCCCCC"/>
      <path d="M317.498 205.025V200.637H312.231V205.025H317.059H317.498Z" fill="#3F3D56"/>
      <path d="M317.059 208.537H312.231V212.926H317.498V208.537H317.059Z" fill="#3F3D56"/>
      <path d="M326.276 200.636H321.009V205.025H326.276V200.636Z" fill="#3F3D56"/>
      <path d="M326.276 208.537H321.009V212.926H326.276V208.537Z" fill="#3F3D56"/>
      <path d="M353.487 205.025V200.637H348.221V205.025H353.049H353.487Z" fill="#3F3D56"/>
      <path d="M353.049 208.537H348.221V212.926H353.487V208.537H353.049Z" fill="#3F3D56"/>
      <path d="M362.265 200.636H356.998V205.025H362.265V200.636Z" fill="#3F3D56"/>
      <path d="M362.265 208.537H356.998V212.926H362.265V208.537Z" fill="#3F3D56"/>
      <path d="M317.959 141.779H325.524C332.986 141.779 340.142 144.743 345.419 150.02C350.695 155.296 353.659 162.452 353.659 169.914H317.959V141.779Z" fill="#3F3D56"/>
      <path d="M88.8174 65.3984H96.3828C103.845 65.3984 111.001 68.3626 116.277 73.6389C121.553 78.9151 124.517 86.0713 124.517 93.533H88.8174V65.3984Z" fill="#3F3D56"/>
      <path d="M209.753 136.051H217.318C224.78 136.051 231.936 139.015 237.212 144.291C242.489 149.567 245.453 156.724 245.453 164.185H209.753V136.051Z" fill="#3F3D56"/>
      <path d="M353.659 180.447H317.959V190.981H353.659V180.447Z" fill="#3F3D56"/>
      <path d="M365.116 224.34H2.30762V225.613H365.116V224.34Z" fill="#3F3D56"/>
      <path d="M35.7691 174.053H34.4961V224.337H35.7691V174.053Z" fill="#3F3D56"/>
      <path d="M47.5175 180.461C47.5986 198.044 35.228 212.356 35.228 212.356C35.228 212.356 19.3858 197.855 22.6449 180.576C25.9227 163.199 36.1382 148.755 34.9345 148.682C34.9345 148.682 47.4365 162.878 47.5175 180.461Z" fill="#F7B80B"/>
      <path d="M163.071 174.053H161.798V224.337H163.071V174.053Z" fill="#3F3D56"/>
      <path d="M174.819 180.461C175.967 198.007 162.53 212.356 162.53 212.356C162.53 212.356 150.028 198.159 149.947 180.576C149.866 162.993 162.236 148.682 162.236 148.682C161.03 153.501 173.891 166.265 174.819 180.461Z" fill="#F7B80B"/>
      <path d="M80.3643 92.3155L135.66 89.6104L158.856 108.972L154.755 112.675L118.023 111.848L80.3643 92.3155Z" fill="#3F3D56"/>
      <path d="M25.1902 182.169L22.2113 184.861L21.8485 185.186L21.0911 185.873L2.30775 185.453V163.264C1.69671 163.131 1.08566 163.003 0.474609 162.883L1.99587 162.806L2.30775 163.067L5.21659 165.492L13.4912 172.405L22.7333 180.119L25.1902 182.169Z" fill="#3F3D56"/>
      <path d="M201.301 165.514L256.597 162.809L279.793 182.17L275.692 185.873L238.96 185.046L201.301 165.514Z" fill="#3F3D56"/>
    </g>
    <defs>
      <clipPath id="clip0_446_51792">
        <rect width="364.642" height="230" fill="white" transform="translate(0.474609 0.474609)"/>
      </clipPath>
    </defs>
  </svg>

);