import { ApolloClient, InMemoryCache } from "@apollo/client";
import { loadStripe } from "@stripe/stripe-js";
import KEYS from "./keys";

export const client = new ApolloClient({
  uri: `${KEYS.API_URL || "https://api-norela-staging.vercel.app"}/graphql`,
  cache: new InMemoryCache(),
});

export const stripePromise = loadStripe(KEYS.STRIPE_KEY || "", {
  locale: "fr-FR",
});
