export const PauseIcon = () => (
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1236_56177)">
      <path
        d="M13.9868 2.82942C19.5098 2.82942 23.9868 7.30642 23.9868 12.8294C23.9868 18.3524 19.5098 22.8294 13.9868 22.8294C8.46385 22.8294 3.98685 18.3524 3.98685 12.8294C3.98685 7.30642 8.46385 2.82942 13.9868 2.82942ZM13.9868 4.82942C11.8651 4.82942 9.83028 5.67228 8.32999 7.17257C6.8297 8.67286 5.98685 10.7077 5.98685 12.8294C5.98685 14.9512 6.8297 16.986 8.32999 18.4863C9.83028 19.9866 11.8651 20.8294 13.9868 20.8294C16.1086 20.8294 18.1434 19.9866 19.6437 18.4863C21.144 16.986 21.9868 14.9512 21.9868 12.8294C21.9868 10.7077 21.144 8.67286 19.6437 7.17257C18.1434 5.67228 16.1086 4.82942 13.9868 4.82942ZM13.9868 6.82942C14.2318 6.82945 14.4682 6.91938 14.6512 7.08214C14.8343 7.2449 14.9512 7.46917 14.9798 7.71242L14.9868 7.82942V12.4154L17.6938 15.1224C17.8732 15.3024 17.9773 15.5439 17.9851 15.7978C17.9928 16.0518 17.9036 16.2991 17.7356 16.4897C17.5676 16.6803 17.3333 16.7997 17.0804 16.8238C16.8274 16.8479 16.5748 16.7748 16.3738 16.6194L16.2798 16.5364L13.2798 13.5364C13.1244 13.3809 13.0246 13.1784 12.9958 12.9604L12.9868 12.8294V7.82942C12.9868 7.56421 13.0922 7.30985 13.2797 7.12232C13.4673 6.93478 13.7216 6.82942 13.9868 6.82942Z"
        fill="#033349"
      />
    </g>
    <rect x="0.986847" y="12.8294" width="12" height="12" rx="6" fill="white" />
    <path
      d="M7.82018 21.8294H9.48685V15.8294H7.82018M4.48685 21.8294H6.15351V15.8294H4.48685V21.8294Z"
      fill="#033349"
    />
    <defs>
      <clipPath id="clip0_1236_56177">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(1.98685 0.829422)"
        />
      </clipPath>
    </defs>
  </svg>
);
